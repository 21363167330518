import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteDebugScenario } from 'actions/project/debuggingData'
import CreateModal from '../Commons/CreateModal'

const DeleteScenarioModal = ({ hide, scenario }) => {
  const { _id, name } = scenario
  const dispatch = useDispatch()
  const submit = () => dispatch(deleteDebugScenario(_id))

  return (
    <CreateModal
      title="Are you sure that you want to delete this scenario ?"
      hide={hide}
      submit={submit}
      canSubmit
    >
      {() =>  
        (
          <p>{`You are going to delete ${name} scenario`}</p>
        )}
    </CreateModal>
  )
}

export default DeleteScenarioModal