import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { select } from 'actions/selection'
import { compose, lifecycle } from 'recompose'
import { identity } from 'ramda'
import omitProps from './omitProps'

/**
 * When the component gets mounted it restores a selection
 * basedon a parameter from the URL.
 * This is the case when you want to store the selection both in the store
 * but also in the URL to have REST urls.
 * Example:
 *    URL form: `/user/:userName`
 *
 * In this case you will use it like this:
 *    @selectionFromRoute({ name: 'selectedUser', param: 'userName' })
 *    class MyComponentThatNeedsAUser extends Component {
 *
 * Then for this case:  `/user/pepe`
 * This HOC will automatically trigger a select('selectedUser')('pepe')
 *
 * @param {*} name: the selection name, param: the name of the param in the URL
 */
export const selectionFromRoute = ({ name, param = name, transform = identity, detectUpdates = false }) => compose(
  withRouter,
  connect(undefined, dispatch => ({ dispatch })),
  lifecycle({
    componentDidMount() {
      const value = getValueFromProps(param, this.props)
      if (value) {
        this.props.dispatch(select(name)(transform(value)))
      }
    },
    ...detectUpdates && {
      componentDidUpdate(prevProps) {
        const previousValue = getValueFromProps(param, prevProps)
        const currentValue = getValueFromProps(param, this.props)
        if (previousValue !== currentValue) {
          this.props.dispatch(select(name)(transform(currentValue)))
        }
      }
    }
  }),
  omitProps(['dispatch', 'match', 'computedMatch', 'history', 'location'])
)

const getValueFromProps = (param, props) => {
  const { computedMatch, match } = props
  return (computedMatch || match).params[param]
}