import moment from 'moment'

/**
 * A fetch fn wrapper that uses the localDB cache service to cache LUA files
 * in the browser and avoid re-fetching them every time we load the project.
 */
const fetchCacheWrapper = (fetch, db) => async (url, cb) => {
  const cached = await db.engineFile.get({ url })
  if (cached) {
    return cb(cached.content)
  } else {
    fetch(url, content => {
      db.engineFile.put({
        url,
        timestamp: moment.utc().toISOString(),
        // TODO: save it as an ArrayBuffer !
        //  see https://developers.google.com/web/fundamentals/instant-and-offline/web-storage/indexeddb-best-practices
        content,
      })
      cb(content)
    })
  }
}

export default fetchCacheWrapper