import React from 'react'
import { Icon } from 'antd'
import { getHumanReadableCode, Key } from 'utils/keyboard'

import styles from './keyboardDebug.scss'

const _modifier = e => (eventField, keyCode) => !!e[eventField] && e.which !== keyCode && [keyCode]

export default () => {
  let setEvent
  const instance = ({

    keyBindingFn: e => {
      const modifier = _modifier(e)
      if (setEvent) {
        setEvent({ event: {
          which: e.which,
          modifiers: [
            ...modifier('shiftKey', Key.SHIFT),
            ...modifier('altKey', Key.ALT),
            ...modifier('ctrlKey', Key.CTRL),
            ...modifier('metaKey', Key.META),
          ]
        } })
      }
    },

    registerComponent: c => { setEvent = ::c.setState },
    unregisterComponent: () => { setEvent = undefined },

    renderExtraComponent: key => (<KeyboardDebugFeedback key={key} source={instance} />)
  })

  return instance
}

class KeyboardDebugFeedback extends React.Component {
  state = {}
  componentDidMount() { this.props.source.registerComponent(this) }
  componenteWillUnmount() { this.props.source.unregisterComponent() }
  componentDidUpdate(prevProps, prevState) {
    // received an event, queue to remove it after 1 second
    if (prevState.event !== this.state.event && this.state.event) {
      setTimeout(() => { this.setState({ event: undefined }) }, 1000)
    }
  }
  render() {
    const { event } = this.state
    return event ? (
      <div className={styles.control}>
        <Icon type="download" />
        {event.modifiers
          .map(getHumanReadableCode)
          .concat(getHumanReadableCode(event.which)).join(' + ')
        }
      </div>
    ) : null
  }
}