import { linter as makeLinter } from '@codemirror/lint'
import { lang } from 'beanie-engine-api-js'
import { syntaxTree } from '@codemirror/language'
import { identity, isEmpty, isNil } from 'ramda'
import { analyzeAST, currentTextNode, NODE_REF_TYPE, COMPARE_TYPE, isIt } from './TreeUtils'
import { EMPTY_ARRAY } from 'utils/object'

import { currentTypingAndCheckContext, factsByName } from 'selectors/objects/facts'
import { objectsIndex } from 'selectors/apollo'

const {
  rule: {
    utils: { isValue },
    typing: { unifyTypes, infer, inferAndUnifyWith, SetType },
    parser: parse,
    utils: { makeSyntheticMatch, isVariable },
    error: { isStaticError }
  }
} = lang

const errorToDiagnostic = ({ data: { from, to, message } }, text, severity = 'error') => ({
  severity,
  from: from || 0,
  to: to || text.length,
  message,
})

// This is similar to our check problems but looking the code mirror tree (that have more data)
// TODO: rethink the analysis of expressions to unify them
const factCheck = (viewState, store) => {
  const diagnostics = []
  const factsIndex = factsByName(store.getState())
  const _check = ({ type, from, to, node }) => {
    if (type.name === 'FactApp') {
      const factName = currentTextNode(viewState, node.firstChild)
      if (isNil(factsIndex[factName])) {
        diagnostics.push({
          from,
          to,
          severity: 'warning',
          message: `The fact with name ${factName} doesn't exist`
        })
      }
    }
  }

  _check.diagnostics = diagnostics

  return _check
}

const nodeRefCheck = (viewState, store) => {
  const diagnostics = []

  const index = objectsIndex(store.getState())
  const _check = ({ type, from, to }) => {
    if (type.name === NODE_REF_TYPE) {
      const idWithDelimiters = currentTextNode(viewState, { from, to })
      const id = idWithDelimiters.slice(1, -1)
      if (!isIt(idWithDelimiters) && isNil(index[id])) {
        diagnostics.push({
          from,
          to,
          severity: 'warning',
          message: isEmpty(id) ?
            'Is not a valid id' :
            `The node with id ${id} doesn't exist`
        })
      }
    }
  }

  _check.diagnostics = diagnostics

  return _check
}

const typeOfElement = (source, typingContext) => {
  const parsed = parse(source)
  if (isValue(parsed) && !isVariable(parsed)) return new SetType([parsed.value])

  return infer(parsed, typingContext)
}

const factComparationCheck = (state, typingContext) => {
  const diagnostics = []

  const _check = ({ type, node }) => {
    if (type.name !== COMPARE_TYPE) return

    if (node && node.firstChild && node.lastChild) {
      const leftSource = currentTextNode(state, node.firstChild)
      const lefType = typeOfElement(leftSource, typingContext)
      if (isStaticError(lefType)) return
      
      const rightSource = currentTextNode(state, node.lastChild)
      const rightType = typeOfElement(rightSource, typingContext)
      if (isStaticError(rightType)) return

      const unifiedType = unifyTypes(lefType, rightType, parse(currentTextNode(state, node)))

      if (isStaticError(unifiedType)) {
        diagnostics.push(errorToDiagnostic(unifiedType, rightSource, 'warning'))
      }
    }
  }

  _check.diagnostics = diagnostics

  return _check
}

const linterChecks = (state, typingContext, store) => [
  factCheck(state, store),
  nodeRefCheck(state, store),
  factComparationCheck(state, typingContext, store)
]

const warningChecks = ({ enabled, state, store, typingContext }) =>
  (enabled ? analyzeAST(syntaxTree(state), linterChecks(state, typingContext, store)) : EMPTY_ARRAY)

export const lint = ({ expectedType, syntheticMatch, linterEnabled, store }) => view => {
  const typingContext = currentTypingAndCheckContext(store.getState())
  const { state } = view
  const text = state.doc.toString()
  if (isEmpty(text)) return EMPTY_ARRAY
  const parsed = (syntheticMatch ? makeSyntheticMatch : identity)(parse(text))
  const inferred = inferAndUnifyWith(parsed, expectedType, typingContext)
  return isStaticError(inferred) ? [errorToDiagnostic(inferred, text)] : warningChecks({ enabled: linterEnabled, typingContext, state, store })
}

export const linter = (context) => makeLinter(lint(context), { delay: 500 })