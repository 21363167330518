import React from 'react'
import { Icon } from 'antd'
import ReactDropzone from 'react-dropzone'

import styles from './SetStoryboardModal.scss'

const DropZone = ({ acceptedTypes, file, icon, onDrop = () => {} }) => (
  <ReactDropzone
    className={styles.dropZone}
    activeClassName={styles.dropActive}
    onDrop={acceptedFiles => {
      if (acceptedFiles.length > 0) {
        onDrop(acceptedFiles[0])
      }
    }}
    multiple={false}
    accept={acceptedTypes}
    style={{}}
    inputProps={{ contentEditable: false }}
  >
    <DropZoneContent file={file} icon={icon} isImage={acceptedTypes.startsWith('image')} />
  </ReactDropzone>
)

const DropZoneContent = ({ file, icon, isImage }) => (
  <div
    className={styles.preview} 
    {...(file && isImage) && { style: { backgroundImage: `url(${file.preview})` } }}
  >
    <div className={styles.overlay}>
      <Icon type={icon} />
      <span className={styles.message}>Drop here or <a>click to select</a></span>
    </div>
  </div>
)

export default DropZone