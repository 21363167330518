import React from 'react'
import classNames from 'classnames'

import IconButton from 'components/Commons/IconButton'
import { Disposition } from '../../preferences/Panes'

import ViewTitles from './ViewTitles'

const MinimizedViewPanel = ({ activeChild, children, className, disposition, styles, selectView, maximize }) => {
  const controls = (
    <div className={styles.controls}>
      <IconButton tooltip="Restore" type={`${disposition === Disposition.horizontal ? 'up' : 'left'}`} className={styles.maximizeButton} onClick={maximize} />
    </div>
  )
  return (
    <div className={classNames(styles.minimizedBar, styles[disposition], className)}>
      {disposition === Disposition.vertical && controls}
      <ViewTitles {...{ styles, activeChild, selectView }} itemStyle={styles.minimized} maximizeOnClick>
        {children}
      </ViewTitles>
      {disposition === Disposition.horizontal && controls}
    </div>
  )
}

export default MinimizedViewPanel