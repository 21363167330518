import { always } from 'ramda'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import makeForkingDecisionPathSelector from '../../selectors/objects/paths/makeForkingDecisionPathSelector'

import styles from './SearchObject.scss'

/**
 *
 */
const SearchObjectContextInfo = ({ object, node }) => {
  const pathSelector = useMemo(() => makeForkingDecisionPathSelector(always((node || object).id)), [node || object])
  const path = useSelector(pathSelector)
  return path.lane ? (
    <div className={styles.SearchObjectContextInfo}>
      {path.lane.name}
    </div>
  ) : null
}

export default SearchObjectContextInfo