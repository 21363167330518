import React from 'react'
import { connect } from 'react-redux'
import { prop, compose, not } from 'ramda'
import { contained } from 'ramda-adjunct'
import { EMPTY_ARRAY } from 'utils/object'

import autocompletePluginCreator from './AutocompletePluginCreator'
import { NODE_TYPES } from '../Constants'
import { markupDefinitions as markupDefinitionsSelector } from 'selectors/markups'
import { markupName } from '../markupUtils'
import { isValid } from '../editorUtils'
import { propEqIgnoreCase } from 'utils/string'

const shouldHandleNode = (editor, { type }) => type === NODE_TYPES.MARK_UP_PARAMETER_KEY && !editor.selectionIsExpanded()

export default () => autocompletePluginCreator({
  renderPortal: (Portal, props) => {
    const { editorRef: { current } } = props
    return (
      <MarkupKeys>
        {markupDefinitions => (
          <Portal
            {...props} 
            suggestions={isValid(current) && shouldHandleNode(current, current.currentNode()) ? 
              computeSuggestions(current, markupDefinitions) : 
              EMPTY_ARRAY} />
        )}
      </MarkupKeys>
    )
  },
  shouldHandleNode,

  onEnter: (suggestion, editor) => {
    editor.replaceCurrentText(suggestion)
    editor.moveToRightSiblingOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE)
  }

})

const _MarkupKeys = ({ markupDefinitions, children }) => children(markupDefinitions)
const MarkupKeys = connect(state => ({
  markupDefinitions: markupDefinitionsSelector(state)
}))(_MarkupKeys)

const computeSuggestions = (editor, markupDefinitions) => {
  const name = markupName(editor.currentMarkUp())
  return computeSuggestionsForMarkup(name, markupDefinitions)
    .filter(compose(not, contained(editor.currentMarkUpParameterNames())))
  
}

const computeSuggestionsForMarkup = (name, definitions) => {
  const definition = definitions.find(propEqIgnoreCase('name', name))
  return definition && definition.parameters ? definition.parameters.map(prop('name')) : EMPTY_ARRAY
}