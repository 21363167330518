exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".enabled__1idft,.disabled__1eWPZ{-webkit-transition:1s linear opacity;-o-transition:1s linear opacity;transition:1s linear opacity;color:#000 !important}.enabled__1idft{opacity:1}.disabled__1eWPZ{opacity:.3}", ""]);

// exports
exports.locals = {
	"enabled": "enabled__1idft",
	"disabled": "disabled__1eWPZ"
};