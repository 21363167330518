import React from 'react'
import { Empty } from 'antd'

import classNames from 'classnames'

import styles from './NoContent.scss'

export default ({ message, className }) => (
  <div className={classNames(styles.noContent, className)}>
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{message}</span>}
    />
  </div>)
