import { model, parseRef } from 'beanie-engine-api-js'
import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'

import { Creators } from 'actions/vm'

import { emptyObjectToArray } from '../../../../utils/object'
import ChoicePlayback from './ChoicePlayback'

import { choicesGetSelectedChoice, choicesIsExpired } from '../../../../model/ui/NodePlayback'

import styles from './ChoicesPlayback.scss'

const { types: { object: { getName }, choices: { getChoiceType } } } = model

const { reenterPlaybackNode } = Creators

/**
 *
 */
export class ChoicesPlayback extends React.Component {

  shouldComponentUpdate = nextProps => (
    this.props.playingObject !== nextProps.playingObject
    || this.props.node.state !== nextProps.node.state
    || this.props.choicesIds !== nextProps.choicesIds
    || choicesGetSelectedChoice(this.props.node) !== choicesGetSelectedChoice(nextProps.node)
    || choicesIsExpired(this.props.node) !== choicesIsExpired(nextProps.node)
  )

  tagColor(index) {
    const { node } = this.props
    const selectedChoice = choicesGetSelectedChoice(node)
    return selectedChoice !== undefined && selectedChoice === index ? '#108ee9' : undefined
  }

  render() {
    const { node, playingObject, choicesIds, firstPinnedChoiceId } = this.props
    const selectedChoice = choicesGetSelectedChoice(node)
    const hasExpired = choicesIsExpired(node)
    const choiceType = getChoiceType(playingObject)

    if (selectedChoice === undefined && !hasExpired) return null
    return (
      <div className={styles.choices}>
        {choiceType && (
          <div className={styles.choiceType}>{choiceType}</div>
        )}
        <div className={styles.choicesName}>
          {getName(playingObject) || '-empty-'}
          { hasExpired && (
            <div className={styles.expired}>timed out</div>
          )}
        </div>
        <ol className={styles.choicesList}>
          {
            // eslint-disable-next-line no-unused-vars
            choicesIds.map((id, index) => (
              <ChoicePlayback
                key={id}
                choiceId={id}
                index={index}
                choices={node}
                color={this.tagColor(index)}
                firstPinnedChoiceId={firstPinnedChoiceId} />
            ))
          }
        </ol>
        {choicesIds.length === 0 &&
          <div>No available options !</div>
        }
      </div>
    )
  }

  componentDidMount = () => {
    const { onContentAdded } = this.props
    onContentAdded()
  }

  componentDidUpdate = prevProps => {
    const { onContentAdded, reenterPlaybackNodeAction, node } = this.props

    onContentAdded()

    if (choicesGetSelectedChoice(this.props.node) !== choicesGetSelectedChoice(prevProps.node)) {
      reenterPlaybackNodeAction(node.playbackId, node.status)
    }
  }

}

/**
 * Kind of "custom" HOC that we reuse between the history/pianoRoll component (this one)
 * and CurrentChoicesPlayback where we show the choices to the user to select one.
 * This HOC only shows those enabled choices that exist in the choices
 */
export const withEnabledChoicesIds = () => withPropsOnChange(
  ['node'], ({ node: nodePlaybackObject }) => ({
    choicesIds: emptyObjectToArray(nodePlaybackObject.state.enabledChoices).map(parseRef)
  })
)

export default compose(
  connect(null, {
    reenterPlaybackNodeAction: reenterPlaybackNode
  }),
  withEnabledChoicesIds(),
)(ChoicesPlayback)