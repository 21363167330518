import { pipe } from 'ramda'
import { Sys } from 'beanie-engine-api-js'
import insertAfterId from './actions/node/insertAfterId'

export const addElementWithClip =
  (api, addElementFn) =>
    pipe(
      () => addElementFn(),
      element => insertAfterId(element.get_id(), Sys.clip)(api)
    )
