import {
  pipe,
  trim,
  concat,
  converge,
  pathOr,
  map,
  prop,
  identity,
  mergeDeepRight,
  objOf,
  find,
  propEq,
  path, range,
} from 'ramda'
import debounce from 'lodash/debounce'

import { withFeedback } from 'utils/ui'
import { EMPTY_ARRAY } from 'utils/object'
import { sequence } from 'utils/promises'
import { propFrom } from './object'

const _parseGraphQLErrors = error => (
  error.graphQLErrors ? error.graphQLErrors.map(parseQueryError) : [error]
)

// this is a raw first impl of trying to humanize graphql error messages
const networkErrorMessagesTransformations = [
  /Variable .* got invalid value .*; (.*)/
]
const parseNetworkErrorMessage = message => {
  const re = networkErrorMessagesTransformations.find(_ => _.exec(message))
  return re ? re.exec(message)[1] : message
}
const parseNetworkErrors = pipe(
  pathOr(EMPTY_ARRAY, ['networkError', 'result', 'errors']),
  map(pipe(prop('message'), parseNetworkErrorMessage))
)

export const parseGraphQLErrors = converge(concat, [_parseGraphQLErrors, parseNetworkErrors])

const textBetween = (start, end) => text => text.slice(text.indexOf(start) + start.length, end ? text.indexOf(end) : undefined)
const textAfter = start => textBetween(start)

/*
 * Error message from apollo is something like this
 * Error: GraphQL error: Variable "$input" got invalid value {"name":"arturo","email":"arturo@duro","password":"artic monkeys","roles":[]}.
 * In field "email": Expected type "Email", found "arturo@duro": Query error: Not a valid Email address
 */
const errorMessageTransformations = {
  'Query error: ': textAfter('Query error: '),
  E11000: pipe(textBetween('dup key: { :', '}'), trim, concat('Duplicated value ')),
  '[BusinessException] ': textAfter('[BusinessException] '),
  '[SecurityException] ': textAfter('[SecurityException] '),
}

const transformError = error => Object.keys(errorMessageTransformations)
  .reduce((acc, key) => (
    acc.indexOf(key) >= 0 ? errorMessageTransformations[key](acc) : acc
  ),
  error
  )

const parseQueryError = pipe(prop('message'), transformError)

export const debounceMutation = (okMessage, errorMessage) =>
  debounce(
    (mutation, variables) => withFeedback(okMessage, errorMessage, mutation(variables)),
    2000,
    { leading: true, trailing: false }
  )

export const input = (o, transform = identity, prev = {}) =>
  pipe(transform, i => ({ variables: { input: i } }), mergeDeepRight(prev))(o)

export const propToInputAs = (propName, inputProp) => pipe(prop(propName), objOf(inputProp))

const getFieldNameFor = operationType => pipe(
  prop('definitions'),
  find(propEq('operation', operationType)),
  // TODO: for the moment this only supports a query that has a single field/query
  //  anyway all of our queries are like that so we are fine, and this is only used
  //  to mock the backend in tests
  path(['selectionSet', 'selections', 0, 'name', 'value'])
)
export const getMutationName = getFieldNameFor('mutation')
export const getQueryName = getFieldNameFor('query')
export const getSubscriptionName = getFieldNameFor('subscription')

export const FetchPolicy = {
  /**
   * Executes the full query against your GraphQL server, without first checking the cache.
   * The query's result is stored in the cache.
   */
  NETWORK_ONLY: 'network-only',
  /**
   * Similar to 'network-only', except the query's result is not stored in the cache.
   */
  NO_CACHE: 'no-cache',
}

/**
 * To simulate creating a GQL client error as we receive them from Apollo Client and the server
 * For mocking in tests
 */
export const GQLError = message => {
  const error = new Error(message)
  error.graphQLErrors = [{ message }]
  return error
}

//
//
//

/**
 * Utility to consume all elements in a paginated query according to our backend
 * convention (Paging type).
 * It receives a query function that will be used to query for the paginated data multiple times
 * receiving the page number as param and must return a list of result and paging info.
 *
 * Also receive a consumer / mapper function to where to send incoming pages of elements.
 * There you process the data
 *
 * @param query a function (pageNr:Int) => PagedResult { list: Object, paging: Paging }
 * @param mapper a function (objects:List) => Void that receives each page of elements and does something
 */
export const fetchPaginated = async (query, mapper) => {
  // get paging info
  const { list, paging } = await query()
  // process first page
  mapper(list)

  if (paging.nrOfPages > 1) {
    // use paging info to get the remaining pages from page 2 on
    await sequence(
      range(2, paging.nrOfPages + 1).map(
        pageNr => async () => {
          const { list: pageList } = await query(pageNr)
          mapper(pageList)
        }
      )
    )
  }
}

//
// Util for mocking the server
//

/**
 * Given a "types spec" it generates a resolver that contains the `__resolveType`.
 * This uses convention. The incoming object must have a "type" field.
 * Then this looks-up that value into the spec.
 * Types spec has keys that are the String value of the type field, and whose values are the GQL Types. For ex:
 * {
 *   CAR: 'Car',
 *   PET: 'Pet',
 * }
 * And input `{ type: 'Car', ...others }` -> 'Car'
 */
export const resolveType = types => ({
  __resolveType: pipe(prop('type'), propFrom(types))
})