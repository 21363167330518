exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".cursors__8iif9{display:-ms-flexbox;display:flex;padding-left:0px;position:absolute;z-index:3;top:-15px;left:5px}.cursors__8iif9 li{padding-left:.2em;list-style-type:none}.cursors__8iif9 li span{width:1em;height:1em}", ""]);

// exports
exports.locals = {
	"cursors": "cursors__8iif9"
};