
const Groups = {
  Others: 'Others',

  Editing: 'Editing',
  Navigation: 'Navigation',
  Selection: 'Selection',
  Playback: 'Playback'
}

export default Groups