import { BEANIE_TYPES } from '../utils'
import { NodeTypeToNoteType } from '../../modelToText/lineMetadata'
import { NoteDelimiters } from '../../../../model/constants'
import { NOTE_TYPES } from '../../utils/Constants'

const extractDelimiters = (text, { left, right }) => text.slice(left.length, text.length - right.length)

const parseNoteText = ({ type, text }) => extractDelimiters(text, NoteDelimiters[NodeTypeToNoteType[type]])

const noteParser = node => ({
  type: BEANIE_TYPES[node.type],
  text: parseNoteText(node),
})

export default noteParser

export const isNoteNode = node => NOTE_TYPES.includes(node.type)