import { ifElse, reduce, prop, pipe, applySpec, toUpper, trim, not } from 'ramda'
import { isDirectorLine } from '../model/Line'
import { isMarkupNode, isInline } from '../utils/Constants'
import { isText } from '../utils/nodeUtils'
import parseMarkup from './markup/MarkupSerializer'
import parseNote, { isNoteNode } from './notes/NoteSerializer'
import { isEmptyOrNull } from 'utils/string'

const reduceMetadata = (accepts, affects, parse) => ({ offset, elements }, node) => 
  (accepts(node) ?
    { offset, elements: [...elements, { offset, ...parse(node) }] } :
    { offset: affects(node) ? offset + node.text.length : offset, elements })

const serializeMetadata = (accepts, affects, parse) => pipe(reduce(reduceMetadata(accepts, affects, parse), { offset: 0, elements: [] }), prop('elements'))

const isNotInline = pipe(isInline, not)
const markups = serializeMetadata(isMarkupNode, isNotInline, parseMarkup)
const notes = serializeMetadata(isNoteNode, isNotInline, parseNote)

// lines
const actorNameToModel = pipe(trim, toUpper)

const line = (line_id, actor_name, nodes) => ({
  line_id,
  actor_name: actorNameToModel(actor_name),
  text: nodes.filter(isText).reduce((text, currentN) => text + currentN.text, ''),
  ...parseNodes(nodes)
})

const parseNodes = applySpec({ notes, markups })

const dialogueLineToModel = ({ data, nodes }) => {
  const [actorPart, textPart] = nodes
  const actorName = actorPart.text
  // if no actor name, defaulting to DIR line.
  if (isEmptyOrNull(actorName)) return directorLineToModel({ data, nodes: textPart.nodes })

  return line(data.get('line_id'), actorName, textPart.nodes)
}
const directorLineToModel = ({ data, nodes }) => line(data.get('line_id'), 'DIR', nodes)
export const lineToModel = ifElse(isDirectorLine, directorLineToModel, dialogueLineToModel)
