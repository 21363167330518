import React from 'react'

import { selectionFromRoute } from 'hocs/selection'

import TreeView from 'components/TreeView/TreeView'
import TreeViewMenu from 'components/TreeViewMenu/TreeViewMenu'

import { simpleSelection } from 'selectors/nodeSelection'

import styles from './MainEditorPanel.scss'

const MainEditorPanel = () => (
  <div className={styles.panel}>
    <TreeViewMenu />
    <TreeView />
  </div>
)

export default selectionFromRoute({
  name: 'node',
  param: 'objectId',
  transform: e => [simpleSelection(e)] }
)(MainEditorPanel)