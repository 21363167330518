import React from 'react'
import Secure from 'components/Commons/Secure'

const ViewPanelPane = ({ pane, provideController }) => {
  const { id, component: Pane, visibleOn, props } = pane

  const content = <Pane id={id} provideController={provideController} {...props} />

  return visibleOn ? (
    <Secure check={visibleOn}>
      {content}
    </Secure>
  ) : content
}

export default ViewPanelPane