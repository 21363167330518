import { hotjar } from 'react-hotjar'
import { onlyInProduction } from './services-enabling'

const CONFIG = {
  hjid: 979979,
  hjsv: 6
}

const start = () => {
  hotjar.initialize(CONFIG.hjid, CONFIG.hjsv)
}
start.enabledOn = onlyInProduction

export default start