import memoize from 'memoize-state'
import { isNil } from 'ramda'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'

export const makeFolderHasChildrenCheck = ({ id }) => {
  const mSelector = memoize(objectIndex => {
    const { data: { child } } = objectIndex[id]

    return (isNil(child) ? OK_RESULT : {
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.WARNING,
      id: 'With child',
      message: 'The folder can\'t have a child'
    })
  })

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Folder with child',
    makeSelector: makeFolderHasChildrenCheck
  }
]