import React, { useMemo } from 'react'
import { lang } from 'beanie-engine-api-js'
import { any, isEmpty, isNil, pipe, toString } from 'ramda'
import { Tooltip } from 'antd'
import { exprLabelToString } from '../Language/Rules/ExprEditor/Extensions/Autocomplete'
import classnames from 'classnames'
import { truncate } from 'utils/string'

const SET_ELEMENT_MAX_LENGTH = 16

const truncateLabel = s => truncate(s, SET_ELEMENT_MAX_LENGTH)

const { rule: { error: { isError } } } = lang

import styles from './ExpressionTypeBadget.scss'

export const basicTypeTooltipByName = {
  Boolean: {
    explanation: 'If an expression is of type boolean means it will return either true or false',
    examples: ['door is "open"', 'credit > 0', 'not day is "monday"']
  },
  Number: {
    explanation: 'If an expression is of type Num means it will always return a Numeric value',
    examples: ['1, +1, -6, 3.1415, 0.14, .14', 'credit + 1', 'credit max 1000']
  },
  String: {
    explanation: 'If an expression is of type String means it will return either a simple text or an object ID',
    examples: ['"this is an example"', '"ID"', 'last_chosen "ID"']
  },
  NodeRef: {
    explanation: 'If an expression is of type NodeRef it means it will return a String that represents a node ID',
    examples: ['"5bc6864a-8ed8-492c-aa29-e0dcc71215ff"', 'last_chosen "5bc6864a-8ed8-492c-aa29-e0dcc71215ff"']
  },
  Void: {
    explanation: 'If the program represents an effect\'s call',
    examples: ['set variable to 42'],
  },
  Any: {
    explanation: 'If an expression is of type Any means that the expression type value could not be determined in this instance',
    examples: ['some_variable', 'node_prop of "ID"', 'fact_application()'],
  },
  Error: {
    explanation: 'If an expression is of type Error means this is not consistent with the language specification',
    examples: ['true + 42', 'last_execution 42'],
  }
}

const basicTypeTooltip = type => basicTypeTooltipByName[type?.name]

const unionTypeToolip = type => ({
  explanation: `Could take the value of any of the next types: ${type.types.map(t => t.name).join(', ')}`,
  examples: [],
})

const setTypeTooltip = type => {
  const elementsAsStrings = type.elements.map(pipe(toString, truncateLabel))
  return ({
    explanation: `Could take one of the next values: { ${elementsAsStrings.join(', ')} }`,
    examples: elementsAsStrings,
  })
}

export const TypeToolTip = {
  Basic: basicTypeTooltip,
  Union: unionTypeToolip,
  Set: setTypeTooltip,
}

export const BasicTypeRender = ({ type, showSuperType }) => (
  <span className={styles.BasicType}>
    {showSuperType ? type?.getSuperType?.().name : type?.name}
  </span>
)

export const UnionTypeRender = ({ type, showSuperType }) => {
  const hasSuperType = useMemo(() => any(subType => subType?.getSuperType?.() !== subType, type?.types), [type])
  return (
    <span className={classnames(styles.UnionType, { [styles.UnionTypeSuperType]: hasSuperType && showSuperType })}>
      {type.types.map((_type, i) => (
        <span key={_type.name} className={styles.UnionTypeElement}>
          <TypeLabel type={_type} showSuperType={showSuperType} />
          {i < type.types.length - 1 && <span className={styles.Or}>| </span>}
        </span>
  ))}
    </span>
  )
}

export const SetTypeRender = ({ type, showSuperType }) => (
  showSuperType ? <div className={styles.SuperClassSetTypeContainer}>
    <Tooltip
      overlayClassName={styles.Tooltip}
      title={<SetTypeAsLabel type={type} />
    }>
      <div className={styles.SuperClassSetType}>
        <TypeLabel type={type?.getSuperType?.()} />
      </div>
    </Tooltip>
  </div> : <SetTypeAsLabel type={type} />
)

const SetTypeAsLabel = ({ type }) => (
  <span className={styles.SetType}>
    <span className={styles.Element}>{'{'}</span>
      &nbsp;
    {type.elements.map((element, i) => {
        const label = truncateLabel(exprLabelToString(element))
        return (
          <span key={label}>
            <span className={styles.Element}>{label}</span>
            {i < type.elements.length - 1 && <span className={styles.Commma}>, </span>}
          </span>
      )
      })}
      &nbsp;
    <span className={styles.Element}>{'}'}</span>
  </span>
)

export const TYPE_LABEL_COMPONENT = {
  Basic: BasicTypeRender,
  Union: UnionTypeRender,
  Set: SetTypeRender,
}

const Example = ({ example }) => <span className={styles.Example}>{example}</span>

export const HelperTooltip = ({ type }) => {
  const { explanation, examples } = (isNil(type) || isError(type)) ?
    basicTypeTooltipByName.Error :
    TypeToolTip[type.type](type)

  return (<div className={styles.Description}>
    <div className={styles.Explanation}>{explanation}</div>
    {!isEmpty(examples) && <div className={styles.Examples}>
      <span className={styles.ExamplesTitle}>Examples:</span>
      <ul>
        {examples.map(example => (
          <li key={example}>
            <Example example={example} />
          </li>
          )
        )}
      </ul>
    </div>}
  </div>)
}

export const TypeLabel = ({ type, showSuperType = false }) => {
  if (isNil(type) || isError(type)) return <span className={styles.Error}>Error</span>

  const TypeCompo = TYPE_LABEL_COMPONENT[type.type]

  return <TypeCompo type={type} showSuperType={showSuperType} />
}

const ExpressionTypeBadget = ({ expressionType }) => (expressionType ?
  <div className={styles.TypeBadgeContainer}>
    <Tooltip
      overlayClassName={styles.Tooltip}
      placement="topLeft"
      trigger="hover"
      title={<HelperTooltip type={expressionType} />
    }>
      <div><TypeLabel type={expressionType} /></div>
    </Tooltip>
  </div> : null
)

export default ExpressionTypeBadget