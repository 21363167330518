import React from 'react'
import { List } from 'antd'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { equals } from 'ramda'

import { undoStack } from 'selectors/undo'

import { undo } from 'actions/edit/undo'
import { redo } from 'actions/edit/redo'

import { isRevert as isUndo } from 'model/ChangeSet'
import RelativeTime from '../Commons/RelativeTime'

import styles from './UndoHistory.scss'
import IconButton from '../Commons/IconButton'

const { Item } = List
const { Meta } = Item

const UndoHistory = ({ stack: { indexedChanges, undoStack: stack, nextUndoableCS, nextRedoableCS }, undoAction, redoAction }) => (
  <List
    className={styles.list}
    dataSource={stack}
    renderItem={cs => {
      const changeSet = isUndo(cs) ? indexedChanges[cs.reverts._id] : cs
      return (
        <Item
          key={changeSet._id}
          className={classNames({ [styles.undone]: isUndo(cs) })}>
          <Meta
            title={changeSet.description}
            description={<RelativeTime date={changeSet.timestamp} />}
          />
          {equals(nextUndoableCS, changeSet) && <IconButton type="undo" tooltip="Undo change" onClick={undoAction} />}
          {equals(nextRedoableCS, changeSet) && <IconButton type="redo" tooltip="Redo change" onClick={redoAction} />}
        </Item>)
        }}
  />
)

export default compose(
  connect(state => ({
    stack: undoStack(state)
  }), {
    undoAction: undo,
    redoAction: redo 
  }),
)(UndoHistory)