exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".minimizedPanel__3X4My{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.minimizedBadge__2P7oe{background:#c1c1c1;height:1rem;width:4rem;border-top-right-radius:5px;border-top-left-radius:5px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-bottom:-3px;-webkit-box-shadow:2px 2px 2px #565656;box-shadow:2px 2px 2px #565656;cursor:pointer}.content__16zzS{background:#c1c1c1;border-top-left-radius:5px;border-top-right-radius:5px;-webkit-box-shadow:2px 2px 2px #565656;box-shadow:2px 2px 2px #565656;padding-left:1rem;padding-right:1rem;padding-top:1rem}", ""]);

// exports
exports.locals = {
	"minimizedPanel": "minimizedPanel__3X4My",
	"minimizedBadge": "minimizedBadge__2P7oe",
	"content": "content__16zzS"
};