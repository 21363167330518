import { pipe } from 'ramda'
import { setCreatingState } from './HiddenNodesPlugin'
import { inlineNode } from '../slateMocks/inlineNode'
import { textNode } from '../slateMocks/textNode'

const newNote = (type, text) => inlineNode([textNode(text)], type)

const createNoteInRange = (editor, noteType, start, end) => {
  const noteText = editor.currentTextNode().text.slice(start, end)
  editor.selectInRanges(start, end)
  editor.delete()
  editor.insertInline(pipe(newNote, setCreatingState)(noteType, noteText))
}

const noteWithDelimiters = ({ openDelimiter, closeDelimiter }, noteType, shouldCallToOnKeyDown) => ({
  onBeforeInput (event, editor, next) {
    return onBeforeInput(editor, noteType, next)
  },
  onKeyDown (event, editor, next) {
    const parentBlock = editor.blockByPath(editor.parentPath())
    const { value: { selection: { focus: { offset } } } } = editor

    if (editor.currentNode().type === noteType &&
      event.key === closeDelimiter &&
      shouldOnlyMove(editor.currentTextNode(), offset, openDelimiter, closeDelimiter)) {
      event.preventDefault()
      event.stopPropagation()
      editor.moveForward()
      return false
    }
    
    if (shouldCallToOnKeyDown(editor, parentBlock)) {
      const currentText = editor.currentTextNode()
  
      if (event.key === closeDelimiter) {
        return closeNote(event, editor, currentText, offset, { openDelimiter, closeDelimiter }, noteType)
      }
  
      if (event.key === openDelimiter) {
        return openNote(event, editor, currentText, offset, { openDelimiter, closeDelimiter }, noteType)
      }
    }

    return next()
  },
})

const shouldOnlyMove = ({ text }, offset, openDelimiter, closeDelimiter) => text[offset] === closeDelimiter

const openNote = (event, editor, currentText, offset, { openDelimiter, closeDelimiter }, noteType) => {
  event.preventDefault()
  event.stopPropagation()
  const { text, length } = shouldOpen(currentText.text, offset, { openDelimiter, closeDelimiter }) ? 
    {
      text: openDelimiter,
      length: distanceToNextCloseDelimiter(currentText.text, offset, closeDelimiter) + 1
    } : {
      text: `${openDelimiter}${closeDelimiter}`,
      length: `${openDelimiter}${closeDelimiter}`.length
    }
  editor.insertText(text)
  createNoteInRange(editor, noteType, offset, offset + length)
  editor.moveToStartOfText()
  editor.moveTo(1)
  return false
}

const closeNote = (event, editor, currentText, offset, { openDelimiter, closeDelimiter }, noteType) => {
  event.preventDefault()
  event.stopPropagation()
  editor.insertText(closeDelimiter)
  if (shouldClose(currentText.text, offset, { openDelimiter, closeDelimiter })) {
    const length = distanceToNextOpenDelimiter(currentText.text, offset, openDelimiter)
    createNoteInRange(editor, noteType, offset - length, offset + 1)
    editor.moveToEnd()
  }
  return false
}

export const shouldOpen = (completeText, offset, { openDelimiter, closeDelimiter }) => {
  const texts = completeText.slice(offset).split(closeDelimiter)
  const first = texts[0]
  return texts.length > 1 && !first.includes(openDelimiter)
}

export const shouldClose = (completeText, offset, { openDelimiter, closeDelimiter }) => {
  const texts = completeText.slice(0, offset).split(openDelimiter)
  const first = texts[texts.length - 1]
  return texts.length > 1 && !first.includes(closeDelimiter)
}

export const distanceToNextCloseDelimiter = (completeText, offset, closeDelimiter) => completeText.slice(offset).indexOf(closeDelimiter) + 1 
export const distanceToNextOpenDelimiter = (completeText, offset, openDelimiter) => offset - completeText.slice(0, offset).split('').lastIndexOf(openDelimiter)

export const onBeforeInput = (editor, inlineType, next) => {
  const parentBlock = editor.blockByPath(editor.parentPath())
  if (parentBlock.type === inlineType) {
    const { value: { selection: { start: { offset } } } } = editor
    if (offset === 0) {
      editor.moveToEndOfPreviousText()
    }
  }
  // forces the cursor to be placed outside of the note, 
  // to avoid typing something left to the delimiter onBeforeInput
  return next()
}

export default noteWithDelimiters
