exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameSection__3b7HE{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.nameSection__3b7HE>div{width:48%}", ""]);

// exports
exports.locals = {
	"nameSection": "nameSection__3b7HE"
};