import { push } from 'connected-react-router'
import { pipe } from 'ramda'

import { INVITES_PATH, PASSWORD_RESET_PATH } from 'containers/App/routes'
import { ownURLForPath } from 'utils/browser'
import routes from 'components/Organizations/routes'

export const navigateToProject = (revisionId, match) => {
  const trimSlashesFrom = part => part
    .replace(/^(\/)(.*)/, '$2')
    .replace(/(.*)(\/)$/, '$1')

  const removeSelectedTreeNode = part => part
    .replace(/^(.*objects)(\/\d+)/, '$1')

  const matchUrl = match?.url || ''
  if (matchUrl === '/') {
    return push(`/p/${revisionId}/edit/`)
  } else {
    // TODO/FIXME: I dont like the use of window location here but was the best shorthand available at the time.
    let sectionPart = window.location.pathname.replace(matchUrl, '')
    if (sectionPart === window.location.pathname) {
      // if after replace its still the same then replace by other means
      sectionPart = window.location.pathname.replace(/\/p\/[^/]+/, '')
    }
    const section = trimSlashesFrom(removeSelectedTreeNode(sectionPart))
    return push(`/p/${revisionId}/${section || 'edit'}/`)
  }
}

export const navigateToOrganization = pipe(::routes.organizationFor, push)
export const navigateToTeam = pipe(::routes.teamForTeam, push)

const inviteTokenQueryParam = 'inviteT' // don't use 't' used for session token in URL
const forgotPasswordTokenQueryParam = 'forgotPasswordT'

const urlForToken = (urlPath, tokenParamName) => token => ownURLForPath(`${urlPath}?${tokenParamName}=${token}`)

export const urlForSignupInvite = urlForToken(`${INVITES_PATH}/signup`, inviteTokenQueryParam)
export const urlForResetPassword = urlForToken(`${PASSWORD_RESET_PATH}/reset`, forgotPasswordTokenQueryParam)

const extractParamFromUrl = paramName => () => new RegExp(`\\?${paramName}=(.*)$`).exec(window.location.toString())[1]
export const extractTokenFromInviteUrl = extractParamFromUrl(inviteTokenQueryParam)
export const extractTokenFromForgotPasswordUrl = extractParamFromUrl(forgotPasswordTokenQueryParam)
