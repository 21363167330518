exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".Flow__3p5l7 ul{list-style:none;font-size:.8rem}.Flow__3p5l7 ul>li{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;margin-bottom:.5rem}.Flow__3p5l7 ul>li>.anticon{margin-right:1rem;color:#a9a9a9}", ""]);

// exports
exports.locals = {
	"Flow": "Flow__3p5l7"
};