import React, { Fragment } from 'react'
import { compose, withHandlers } from 'recompose'
import { withRef } from 'utils/recompose'
import { readFileAsText } from 'utils/io'

import styles from './FileChooser.scss'

// TODO: support checks on file.type with mimetypes

const FileChooser = ({ inputRef, children, onClick, onFileSelected }) => (
  <Fragment>
    {children(onClick)}
    <form key="form" className={styles.hiddenForm} encType="multipart/form-data" method="post">
      <input type="file" ref={inputRef()} onChange={onFileSelected} />
    </form>
  </Fragment>
)

export default compose(
  withRef('inputRef'),
  withHandlers({
    onClick: ({ inputRef }) => () => {
      if (inputRef().current) inputRef().current.click()
    },
    onFileSelected: ({ inputRef, onFileSelected }) => async ({ target }) => {
      const { files } = target

      if (files.length === 0) { return }
      const [file] = files
      onFileSelected(await readFileAsText(file), file)

      // resetting the form allows us to upload the same file again
      inputRef().current.form.reset()
    }
  })
)(FileChooser)

