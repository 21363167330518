exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".forgotPasswordPanel__3NWS2{width:85%;text-align:center}.forgotPasswordPanel__3NWS2 h1{margin-bottom:1rem}", ""]);

// exports
exports.locals = {
	"forgotPasswordPanel": "forgotPasswordPanel__3NWS2"
};