import React, { useMemo } from 'react'
import { useStore } from 'react-redux'

import { MenuItem } from '@blueprintjs/core'
import { shortcutOf } from 'shortcuts/keymap'
import useCommand from 'hooks/useCommand'
import AntToBlueprintIcon from 'components/ContextMenu/AntToBlueprintIcon'
import withCommandPropsForMenu from 'hocs/withCommandPropsForMenu'
import { isEnabled } from 'actions/commands'
import { isFunction } from '../../utils/object'

/**
 * Blueprint menu component backed-up by a command.
 * It takes all info from the command definition
 */

const MenuItemCommand = ({ o, label, command, params = [], isVisible, isEnabled: enabled }) => {
  const boundedAction = useCommand(command)
  const store = useStore()
  const icon = useMemo(() =>
    AntToBlueprintIcon(isFunction(command.icon) ? command.icon(o) : command.icon),
  [command.icon, o]
  )
  return (
    isVisible ? (
      <MenuItem
        disabled={!enabled || !isEnabled(command, params, store.getState())}
        text={label}
        onClick={() => boundedAction(...params)}
        label={command.shortcut && shortcutOf(command.shortcut)}
        icon={icon}
      />
    ) : null
  )
}

export default withCommandPropsForMenu(MenuItemCommand)
