import React from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import IconButton from 'components/Commons/IconButton'
import { currentIsBranch } from 'selectors/project'
import EditMarkupCustomType from './EditMarkupCustomType'

import styles from './TypesTable.scss'

const { Column } = Table

const TypesTable = ({ types, deleteCustomType, isBranch }) => (
  <Table
    rowKey={record => record.name}
    dataSource={types}
    pagination={false}
  >
    <Column
      sorter
      title="Name"
      key="name"
      className={styles.nameColumn}
      dataIndex="name"
      width="20%"
    />
    <Column
      title="Values"
      dataIndex="values"
      key="values"
      width="30%"
      className={styles.valuesColumn}
      render={values => (
        <ol>
          {values.map(({ value }) => (
            <li key={value}>{value.toString()}</li>
          ))}
        </ol>
      )}
    />
    <Column
      title="Description"
      dataIndex="description"
      key="description"
      width="30%"
    />
    <Column
      title=""
      key="actions"
      render={type => (
        isBranch && <div className={styles.actionsColumn}>
          <EditMarkupCustomType customType={type} />
          <IconButton tooltip="Remove" type="delete" onClick={() => deleteCustomType(type)} />
        </div>
      )}
    />
  </Table>
)

export default compose(connect(state => ({ isBranch: currentIsBranch(state) }), {}))(TypesTable)