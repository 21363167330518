import React from 'react'
import { Tabs, Icon } from 'antd'

import OrganizationInvitesPane from './OrganizationInvitesPaneContainer'

import OrganizationMembersPane from './OrganizationMembersPane'

import styles from './OrganizationMembers.scss'

const { TabPane } = Tabs

const OrganizationMembers = ({ hasWriteAccess, ...props }) => (
  <div className={styles.container}>
    <Tabs>
      <TabPane tab={<span><Icon type="user" />Members</span>} key="1">
        <OrganizationMembersPane {...props} />
      </TabPane>
      {hasWriteAccess &&
        <TabPane tab={<span><Icon type="mail" />Invites</span>} key="2">
          <OrganizationInvitesPane organization={props.organization} refreshMembers={props.refreshItems} />
        </TabPane>
      }
    </Tabs>
  </div>
)

export default OrganizationMembers
