import { useMemo } from 'react'
import { useSelector, useStore } from 'react-redux'

import createContext from 'model/languague/rules/context'

/**
 * Returns a Context to evaluate a beanie expression.
 * By default the context is not reactive. That means that it won't get changes to
 * objects. This is useful for one-time on-demand evaluation
 *
 * NOTE: DON'T change the param value between renders !
 * it must be a fixed value. Otherwise we are violating the hooks contracts !
 */
const useEvaluationContext = (reactive = false, itValue) => {
  // TODO: good side is that it reacts to changes to the store,
  //  bad side is that it is creating new context on every change to the store !!
  //  We need to tweak/tune this !
  if (reactive) {
    return useSelector(state => createContext(state, undefined))
  }

  const store = useStore()
  return useMemo(() => {
    const state = store.getState()
    return createContext(state, itValue)
  }, [store])
}

export default useEvaluationContext
