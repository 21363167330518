import fetch from 'isomorphic-fetch'

/**
 * Engine callback to fetch LUA files within the Studio.
 * Includes the authentication header
 */
const fetchWithToken = getToken => async (verb, url, headers, body, cb) => {
  const authToken = headers.Authorization && getToken()

  let r
  try {
    r = await fetch(url, {
      method: verb,
      headers: {
        ...headers,
        ...authToken && { Authorization: `Bearer ${authToken}` },
      }
    })
  } catch (e) {
    cb({ status: '404', body: '' })
    return
  }

  if (url.includes('/fengari/')) {
    const contentAsArrayBuffer = await r.arrayBuffer()
    cb({ status: r.status.toString(), body: new Uint8Array(contentAsArrayBuffer) })
  } else {
    cb({ status: r.status.toString(), body: await r.text() })
  }

}


export default fetchWithToken
