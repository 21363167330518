import React from 'react'

import Preferences from 'preferences/Preferences'

import { ToolBarGroup, ToolBarItem, ToolBarSplitter } from 'components/ToolBar/ToolBar'
import TogglePreferenceButton from 'components/Commons/Preferences/TogglePreferenceButton'
import { COLLAPSE_ALL_LANES, COLLAPSE_ALL_ROOTS, EXPAND_ALL_ROOTS } from '../../commands/view'
import useCommand from '../../hooks/useCommand'

import ExecutionDecorationSwitch from './ExecutionDecorationsSwitch'
import DebuggingDecorationSwitch from './DebuggingDecorationSwitch'

import styles from './TreeViewMenu.scss'

const trackSelectionDescription = enabled => `${enabled ? 'Disable' : 'Enable'} Selection Tracking`

const TreeViewMenu = ({ collapseAllLanes, collapseAllRoots, expandAllRoots }) => (
  <div className={styles.actionsBar}>
    <ToolBarGroup className={styles.volumeGroup}>
      <ToolBarItem icon="minus-square" title="Collapse All Lanes" onClick={collapseAllLanes} />
      <ToolBarItem icon="minus-square-o" title="Collapse All Roots" onClick={collapseAllRoots} />
      <ToolBarItem icon="plus-square-o" title="Expand All Roots" onClick={expandAllRoots} />
    </ToolBarGroup>
    <ToolBarSplitter />

    <ToolBarGroup>
      <TogglePreferenceButton
        type="scan"
        description={trackSelectionDescription}
        preference={Preferences.GraphView.trackSelection}
      />
    </ToolBarGroup>

    <ToolBarSplitter />

    <ToolBarGroup>
      <ExecutionDecorationSwitch />
    </ToolBarGroup>

    <ToolBarSplitter />

    <ToolBarGroup>
      <DebuggingDecorationSwitch />
    </ToolBarGroup>

  </div>
)

const ConnectedTreeViewMenu = () => {
  const collapseAllRoots = useCommand(COLLAPSE_ALL_ROOTS)
  const collapseAllLanes = useCommand(COLLAPSE_ALL_LANES)
  const expandAllRoots = useCommand(EXPAND_ALL_ROOTS)

  return (
    <TreeViewMenu
      collapseAllLanes={collapseAllLanes}
      collapseAllRoots={collapseAllRoots}
      expandAllRoots={expandAllRoots}
    />
  )
}

export default ConnectedTreeViewMenu

