exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".player__38Nci{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.player__38Nci>div{-ms-flex-positive:1;flex-grow:1;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.player__38Nci .wave__3Vkyn{padding-left:.5em;padding-right:.5em;-ms-flex-positive:1;flex-grow:1}.player__38Nci .durationNumber__1xZh-{font-size:1.2em}.player__38Nci .durationUnit__1HO6T{padding-left:.3em;font-size:.8em}", ""]);

// exports
exports.locals = {
	"player": "player__38Nci",
	"wave": "wave__3Vkyn",
	"durationNumber": "durationNumber__1xZh-",
	"durationUnit": "durationUnit__1HO6T"
};