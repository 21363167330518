import { prop, pipe, applySpec, path, ifElse, toLower, equals, slice, filter, find, cond, identity, T } from 'ramda'
import { spreadProp } from 'ramda-adjunct'
import { included } from 'utils/ramda'
import { isNumberString } from '../../../../utils/string'
import { isMarkupParameter, isMarkupParameterValueList, isMarkupParameterValueListElement } from '../../utils/Constants'
import { hasParams } from '../../utils/plugins/MarkUpCreatorPlugin/queries'
import { markupName, markUpParams, paramKey, paramValue } from '../../utils/markupUtils'
import { isRichText } from '../../utils/plugins/Markup/MarkupRichTextValuePlugin'

// markups
const TRUE = 'true'
const FALSE = 'false'

const getListElements = pipe(prop('nodes'), filter(isMarkupParameterValueListElement))
const getListNode = pipe(prop('nodes'), find(isMarkupParameterValueList))

const isBoolean = pipe(toLower, included([TRUE, FALSE]))

const parseSimpleValue = cond([
  [isBoolean, pipe(toLower, equals(TRUE))],
  [isNumberString, parseFloat],
  [isRichText, slice(1, -1)],
  [T, identity]
])

const parseListValue = node => getListElements(node).map(parseParamValue).toJS()

export const isListValue = node => !!(node && node.nodes && getListNode(node))
export const parseParamValue = valueNode => (isListValue(valueNode) ? parseListValue(getListNode(valueNode)) : parseSimpleValue(valueNode.text))

const markUpArg = applySpec({
  name: pipe(paramKey, prop('text')),
  value: pipe(paramValue, parseParamValue)
})

const markUpWithParams = markUp => ({
  name: markupName(markUp).toUpperCase(),
  args: markUpParams(markUp).nodes
    .filter(isMarkupParameter)
    .map(markUpArg)
    .toJS()
})

const simpleMarkup = markUp => ({
  name: markupName(markUp).toUpperCase(),
  args: []
})

const parseMarkup = pipe(
  applySpec({
    length: path(['text', 'length']),
    params: ifElse(hasParams, markUpWithParams, simpleMarkup)
  }),
  spreadProp('params')
)

export default parseMarkup
