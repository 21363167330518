import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isNotNilOrEmpty } from 'ramda-adjunct'

import IconButton from 'components/IconButton/IconButton'
import { copyNodePropertiesToClipboard } from 'actions/clipboard'
import { clipboard, isNodePropertiesMetadata } from 'selectors/clipboard'
import { pasteNodeProperties } from 'engine/actions/clipboard'


const CopyPasteButtons = ({ dataSource, selected, lens, object }) => {
  const clipboardMetadata = useSelector(clipboard)
  const dispatch = useDispatch()

  const handleCopy = () => {
    const records = dataSource.filter(d => selected.includes(d.path))

    const values = records.reduce((accum, elem) => {
      accum[elem.name] = elem.value
      return accum
    }, {})

    dispatch(copyNodePropertiesToClipboard(values, lens))
  }

  const handlePaste = () => dispatch(pasteNodeProperties([object]))

  return (<>
    {clipboardMetadata && isNodePropertiesMetadata(clipboardMetadata) &&
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton icon="copy" onClick={handlePaste} />
        <span style={{ paddingLeft: '0.2rem' }}>Paste {clipboardMetadata.quantity} properties from clipboard</span>
      </div>
    }
    {isNotNilOrEmpty(selected) &&
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <IconButton icon="copy" onClick={handleCopy} />
        <span style={{ paddingLeft: '0.2rem' }}>Copy {selected.length} properties</span>
      </div>
    }
  </>)
}

export default CopyPasteButtons