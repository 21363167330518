exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".noContent__3PPCH{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.searchBox__2oyUs{padding-bottom:.5rem;padding-top:.5rem;padding-left:1em;padding-right:1em}.options__1KRpy{-webkit-box-shadow:2px 4px 8px rgba(0,0,0,.5);box-shadow:2px 4px 8px rgba(0,0,0,.5)}.fromClipboardTitle__3R0E_{display:-ms-flexbox;display:flex;font-size:.9em;color:rgba(92,112,128,.63);-ms-flex-pack:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"noContent": "noContent__3PPCH",
	"searchBox": "searchBox__2oyUs",
	"options": "options__1KRpy",
	"fromClipboardTitle": "fromClipboardTitle__3R0E_"
};