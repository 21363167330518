import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { isVisible, } from 'actions/commands'
import MenuItemCommand from './MenuItemCommand'
import SetPropertyMenuItem from './SetPropertyMenuItem'
import { MenuDivider } from '@blueprintjs/core'

import withSecurityScope from 'hocs/withSecurityScope'

const MenuGroupCommand = ({ children, isVisible: _isVisible }) => {

  let visibleChildrenCount = 0

  const visibleChildren = React.Children.map(children, child => {
    if (child === null || !_isVisible(child)) return false
    visibleChildrenCount += 1
    return child
  })

  return [visibleChildren]
    .concat(visibleChildrenCount > 0 && <MenuDivider key="divider" />)

}

export default compose(
  withSecurityScope,
  connect(
    (state, { scope }) => {
      return {
        isVisible: child => (child.type !== MenuItemCommand && child.type !== SetPropertyMenuItem)
        || isVisible(child.props.command, child.props.params, state, scope, child.props.singleSelectionOnly, child.props.multipleSelectionOnly)
      }
    }
  )
)(MenuGroupCommand)