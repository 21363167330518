exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__uMg09{color:#add8e6;cursor:pointer}.iconAsLink__uMg09:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__1kdeh{font-style:italic}.formFieldsVerticalGutter__5T50B{padding:.5rem}.formFieldsVerticalGutter__5T50B>*{margin:.5rem !important}.tableBackground__3je9E .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3je9E .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1bjOy .ant-notification-notice-close{display:none}@-webkit-keyframes actionLoading__1h734{from{color:#fff}to{font-size:#324356}}@keyframes actionLoading__1h734{from{color:#fff}to{font-size:#324356}}.Notification__Vz_tC{background-color:#273749;color:#d2d2d2;border-color:silver;background-color:rgba(50,67,86,.5);padding:.2rem .5rem;margin-bottom:1rem;border-radius:.3rem;min-width:10rem}.Notification__Vz_tC.unread__1g4w7{background-color:#3a4e65}.Notification__Vz_tC.unread__1g4w7:hover{cursor:pointer}.Notification__Vz_tC.unread__1g4w7 .title__1Ag0o{font-weight:bolder}.Notification__Vz_tC .title__1Ag0o{color:#fff}.Notification__Vz_tC .footer__1CUgn{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.Notification__Vz_tC .footer__1CUgn .time__22wtM{color:#d3d3d3;font-size:.5rem}.Notification__Vz_tC .footer__1CUgn .actions__39SC6 i{color:#fff}.Notification__Vz_tC .footer__1CUgn .actions__39SC6 .loading__2-C3_{-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-direction:alternate;animation-direction:alternate;-webkit-animation-name:actionLoading__1h734;animation-name:actionLoading__1h734}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__uMg09",
	"labelFromRef": "labelFromRef__1kdeh",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__5T50B",
	"tableBackground": "tableBackground__3je9E",
	"notificationNotClosable": "notificationNotClosable__1bjOy",
	"Notification": "Notification__Vz_tC",
	"unread": "unread__1g4w7",
	"title": "title__1Ag0o",
	"footer": "footer__1CUgn",
	"time": "time__22wtM",
	"actions": "actions__39SC6",
	"loading": "loading__2-C3_",
	"actionLoading": "actionLoading__1h734"
};