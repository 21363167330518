import React from 'react'
import { Tabs, Icon } from 'antd'

import styles from 'styles/panel.scss'


import classNames from 'classnames'
import BuildConfigsTab from './BuildConfigTab'


const { TabPane } = Tabs

const RuntimeSection = ({ className = {} }) => (
  <div>
    <div className={classNames(styles.panel, className)} >
      <Tabs>
        <TabPane tab={<span><Icon type="setting" />Build configs</span>} key="1">
          <BuildConfigsTab />
        </TabPane>
      </Tabs>
    </div>
  </div>
)

export default RuntimeSection
