import { Creators } from 'actions/network'
import { isConnected, isDisconnected } from '../../selectors/network'

const { reconnectionSucceeded, connectionError } = Creators

export const heartbeatSucceeded = () => (dispatch, getState) => {
  if (isDisconnected(getState())) {
    dispatch(reconnectionSucceeded())
  }
}

export const heartbeatFailed = message => (dispatch, getState) => {
  if (isConnected(getState())) {
    dispatch(connectionError(message))
  }
}