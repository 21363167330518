import { Icon, Menu } from 'antd'
import React from 'react'
import classNames from 'classnames'
import { EMPTY_ARRAY } from 'utils/object'
import { isEmptyOrNull } from '../../../../../utils/string'
import IconButton from '../../../../Commons/IconButton'

import MarkAsDirtyAudioButton from './MarkAsDirtyAudioButton'
import MarkAsNotDirtyAudioButton from './MarkAsNotDirtyAudioButton'
import RecordLineButton from './RecordLineButton'
import SyncTtsButton from './SyncTtsButton'
import UploadLineAudioModal from './UploadLineAudioModal'
import DownloadLineAudioButton from './DownloadLineAudioButton'
import CopyResourceIdButton from './CopyResourceIdButton'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'

import { ModalConsumer } from 'components/Commons/Modal'

import styles from '../../../SlateTextEditor.scss'

const LineAudioMenuContent = ({ lineId, node, isAudioDirty, audio, onItemSelected, readOnly, onAudioChanged }) => (

  <Menu onClick={onItemSelected} selectedKeys={EMPTY_ARRAY} className={styles.LineAudioMenuContent}>

    <Menu.Item disabled data-class="dropdown-header">
      <Header audio={audio} isAudioDirty={isAudioDirty} />
    </Menu.Item>

    {audio &&
      <Menu.Item>
        <CopyResourceIdButton resourceId={audio} />
        Copy resource ID
      </Menu.Item>
    }

    <Menu.Item disabled={readOnly}>
      <SyncTtsButton lineId={lineId} />
      {audio ? 'Re-create' : 'Create'} TTS audio
    </Menu.Item>

    <Menu.Item disabled={readOnly}>
      <ModalConsumer>
        {({ showModal }) => (
          <React.Fragment>
            <IconButton
              type="upload"
              onClick={() => { showModal(UploadLineAudioModal, { lineId, node, onFileProvided: onAudioChanged }) }}
            />
            Upload Audio
          </React.Fragment>
        )}
      </ModalConsumer>
    </Menu.Item>

    <Menu.Divider />

    {
      audio &&
      <Menu.Item>
        <DownloadLineAudioButton audio={audio} />
        Download Audio
      </Menu.Item>
    }

    <Menu.Item disabled={readOnly || !audio || !isAudioDirty}>
      <MarkAsNotDirtyAudioButton lineId={lineId} isAudioDirty={isAudioDirty} />
      Mark as updated
    </Menu.Item>
    <Menu.Item disabled={readOnly || !audio || isAudioDirty}>
      <MarkAsDirtyAudioButton lineId={lineId} isAudioDirty={isAudioDirty} />
      Mark as outdated
    </Menu.Item>

    {!readOnly && <Menu.Divider />}
    {!readOnly && (
      <Menu.Item disabled={readOnly}>
        <RecordLineButton className={styles.recordIcon} lineId={lineId} /> Record
      </Menu.Item>
    )}
    <ExtensionPoint
      className={styles.ExtensionPoint}
      point={EXTENSION_POINTS.LINE_AUDIO_MENU}
      renderExtension={({ id, label, action: extensionAction, icon }, { moreProps, extAsBneAction }) => (
        <Menu.Item key={id} disabled={readOnly} {...moreProps}>
          <IconButton type={icon} onClick={extAsBneAction(extensionAction, lineId, audio)} />
          {label}
        </Menu.Item>
      )}
    />
  </Menu>
)

const Header = ({ audio, isAudioDirty }) => {
  if (isEmptyOrNull(audio)) return 'No Audio'
  return (
    <React.Fragment>
      Audio is {isAudioDirty ? 'outdated' : 'updated'}
      <Icon type={isAudioDirty ? 'warning' : 'check'} theme="filled" className={classNames({ [styles.dirty]: isAudioDirty })} />
    </React.Fragment>
  )
}

export default LineAudioMenuContent