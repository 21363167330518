import { createActions } from 'reduxsauce'

import { startingNodeId, objects } from '../selectors/project'
import computeNextClips from './computeNextClips'

// ui calls

const onStartGame = () => (dispatch, getState, { engine }) => {
  engine.executeNodeWithId(startingNodeId(getState()))
}

const onPause = () => (dispatch, getState, { engine }) => {
  engine.getAPI().getExecution().pause()
}

const onResume = () => (dispatch, getState, { engine }) => {
  const api = engine.getAPI()
  if (api.isPaused()) {
    api.getExecution().resume()
  }
}

// engine calls

const onPlayClip = clipId => (dispatch, getState) => {
  const nextClips = computeNextClips(clipId, objects(getState()))
  dispatch(Creators.playClip(
    clipId,
    nextClips
  ))
}

// composing actions

export const { Types, Creators } = createActions({

  // engine calls

  onPlayClip,
  // low level
  playClip: ['clipId', 'nextClipIds'],
  endClip: ['clipId'],

  playChoices: ['choicesId', 'enabledChoices'],
  endChoices: ['choicesId'],

  onPresentationEnded: [],

  // ui calls

  onStartGame,
  onPause,
  onResume,

  onChoiceSelected: ['choiceIndex'],
  onChoiceExpired: [],

  onClipEnded: ['clipId'],


})