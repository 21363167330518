import React from 'react'
import { Form, Select } from 'antd'
import { compose, withProps } from 'recompose'
import { pathOr, differenceWith, eqProps } from 'ramda'
import { graphql } from 'react-apollo'

import withUserOrganizations from 'hocs/withUserOrganizations'
import secure from 'hocs/secure'

import { isSuperAdminCheck } from 'security/platform'
import OrganizationSelectOption from 'components/Organizations/Commons/OrganizationSelectOption'

import organizationsQuery from 'api/queries/organizations.graphql'

import styles from './SelectOrganizationField.scss'

const FormItem = Form.Item
const { OptGroup, Option } = Select

const organizationOption = o => (
  <Option key={o._id} value={o._id}><OrganizationSelectOption organization={o} /></Option>
)

// I'd like to split the select parts into smaller components but antd is pretty strict :(

const SelectOrganizationField = ({ form, organizations, isSuperAdmin, allOrgs, otherOrganizations }) => {
  const { getFieldDecorator } = form
  const inner = (
    <Select className={styles.formItemContent}>
      {!isSuperAdmin && 
        organizations.map(organizationOption)
      }
      {isSuperAdmin &&
        [
          <OptGroup key="yours" label="Yours">
            {organizations.map(organizationOption)}
          </OptGroup>,
          <OptGroup key="others" label="All others">
            {otherOrganizations.map(organizationOption)}
            {!allOrgs.loading && otherOrganizations.length === 0 &&
              <Option disabled key="None" label="None" />
            }
            {allOrgs.loading &&
              <Option disabled key="None" label="Loading..." />
            }
          </OptGroup>
        ]
      }
    </Select>)

  return (
    <FormItem label="Organization">
      {getFieldDecorator ? getFieldDecorator('organization', {
      rules: [{ required: true, message: 'Required!' }],
    })(inner) : inner}
    </FormItem>
  )
}

export default compose(
  withUserOrganizations,
  secure('isSuperAdmin', isSuperAdminCheck),
  graphql(organizationsQuery, { name: 'allOrgs', options: { variables: { page: 1, size: 500, query: '' } } }),
  withProps(({ allOrgs, organizations: ours }) => ({
    otherOrganizations: getAllOtherOrgs(allOrgs, ours)
  }))
)(SelectOrganizationField)

const getAllOtherOrgs = (fetchingAll, ours) => {
  const all = pathOr([], ['organizations', 'list'], fetchingAll)
  return differenceWith(eqProps('_id'), all, ours)
}