import { join, map, pipe, prop } from 'ramda'
import { NODE_TYPES } from '../utils/Constants'
import { EMPTY_STRING } from 'utils/string'
import { formatMarkup } from '../utils/markupUtils'

export const BEANIE_TYPES = {
  [NODE_TYPES.text]: 'text',
  [NODE_TYPES.PRODUCTION_NOTE]: 'prod',
  [NODE_TYPES.PERFORMANCE_NOTE]: 'perf',
  [NODE_TYPES.MARK_UP]: 'mark',
}

const BLOCK_TO_TEXT = {
  [NODE_TYPES.MARK_UP]: markup => `{${formatMarkup(markup).trimRight()}}`,
  text_node: prop('text')
}
export const blocksToText = inlineNode => (BLOCK_TO_TEXT[inlineNode.type] || BLOCK_TO_TEXT.text_node)(inlineNode)
export const subnodesToText = pipe(prop('nodes'), map(blocksToText), join(EMPTY_STRING))
