import React, { useMemo } from 'react'
import { Icon } from 'antd'
import { model, parseRef } from 'beanie-engine-api-js'

import useTransaction from '../../../../hooks/beanie/useTransaction'
import TreeChain from '../../TreeChain/TreeChain'
import CompositeNode from '../CompositeNode'
/* eslint import/no-webpack-loader-syntax: 0 */
import IdleCornerIcon from '-!svg-react-loader!./idle-corner.svg'

import styles from '../../TreeView.scss'
import IdleChainPlaceholder from './IdleChainPlaceholder'

const { types: { choices: { getChoiceType }, relationship: { Relationship } } } = model

const Choices2 = props => {
  const { node } = props

  const relationship = useMemo(() => Relationship.idleOf(node.id), [node.id])

  const unsetIdle = useTransaction(api => () => {
    api.obj.get(node.id).set_idle(null)
  }, [node.id], 'Unset idle')

  return (
    <CompositeNode
      {...props}
      className={styles.Choices}
      contentBeforeContents={
        <div className={styles.Choices2Idle}>
          <Icon component={IdleCornerIcon} />
          <div className={styles.Choices2IdleContent}>
            {node.data.idle ?
              <TreeChain
                id={parseRef(node.data.idle)}
                relationship={relationship}
                onRemoveFromReferencer={unsetIdle}
              />
              : <IdleChainPlaceholder node={node} relationship={relationship} />
            }
          </div>
        </div>
      }
      nodeType={getChoiceType(node)}
    />
  )
}
export default Choices2