import React from 'react'
import TeamMembersTable from './TeamMembersTable'
import TeamMembersHeader from './TeamMembersHeader'

import styles from '../../Section/Section.scss'

// TODO: unify with OrganizationTeams

const TeamMembers = ({ teamMembers, pageSize, onPageChanged, onSearch, refreshItems, handleTableChange }) => (
  <div className={styles.container}>
    <TeamMembersHeader team={teamMembers && teamMembers.team} onSearch={onSearch} refreshItems={refreshItems} />
    {teamMembers &&
      <TeamMembersTable data={teamMembers} pageSize={pageSize} handleTableChange={handleTableChange} onPageChanged={onPageChanged} refreshItems={refreshItems} />
    }
  </div>
)

export default TeamMembers