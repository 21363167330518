import React from 'react'
import classNames from 'classnames'
import { Editor } from 'slate-react'
import schema from './utils/schema/schema'
import pluginsCreator from './utils/plugins/Plugins'
import { compose, withHandlers, withState } from 'recompose'
import { isSaveShortCut } from 'utils/keyboard'

import styles from './SlateTextEditor.scss'
import withSecurityScope from 'hocs/withSecurityScope'

const SlateTextEditor = ({ className, newContentInComing, editorId, title, id, object, plugins, value, editorRef, onChange, renderBlock, renderInline, renderMark, onBlur, onKeyDown, readOnly = false, ...others }) => (
  <div
    id={editorId}
    className={classNames(styles.textEditor, className)}
  >
    <Editor
      schema={schema}
      value={value}
      readOnly={readOnly}
      plugins={plugins}
      onChange={onChange}
      renderBlock={renderBlock}
      renderInline={renderInline}
      renderMark={renderMark}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      ref={editorRef()}
      object={object}
      {...others}
    />
    {plugins
    .filter(({ component }) => !!component)
    .map(({ component: Component }, index) =>
      <Component key={index} state={value} editorRef={editorRef()} objectId={object.id} />)
  }
  </div>
)

export default compose(
  withSecurityScope,
  withState('plugins', 'setPlugins', ({ scope }) => pluginsCreator(scope)),
  withHandlers({
    onKeyDown: ({ onSave }) => (event, { value }, next) => {
      if (isSaveShortCut(event)) {
        event.preventDefault()
        event.stopPropagation()
        onSave(value)
      }

      return next()
    }
  })
)(SlateTextEditor)
