//
// I'VE COPIED THIS CODE TO THE BACKEND SO THAT IT GIVES THIS SUPPORT FOR TTS PROVIDER TTS
//

import { pipe, ifElse, always, trim, toLower, replace } from 'ramda'
import { isEmptyOrNull } from 'utils/string'

// text transformation to enhance text to speech

// [to baker] drop it => drop it
const removeBracketsComments = text => {
  const synthText = text.replace(/(\[[^\]]*\])/g, '');
  return synthText.length === 0 ? text : synthText
}

// <hello there> => hello there
const removeLessThanGreatThanComments = text => text.replace(/<(.+)>/g, '$1')

const toEmptyLine = ifElse(isEmptyOrNull, always('empty line'), trim)

// INT. -> Interior
const unfoldSceneTitle = pipe(
  replace(/^((INT|int)\.)/, 'INTERIOR'),
  replace(/^((EXT|ext)\.)/, 'EXTERIOR'),
  replace(/^((INT|int)\/(EXT|ext)\.)/, 'INTERIOR and EXTERIOR'),
  replace(/^((EXT|ext)\/(INT|int)\.)/, 'EXTERIOR and INTERIOR'),
)

export const transformText = pipe(
  trim,
  // inline notes
  removeBracketsComments,
  removeLessThanGreatThanComments,

  // scripting
  unfoldSceneTitle,
  // safe
  toEmptyLine,
  toLower,
)