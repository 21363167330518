import React from 'react'
import { compose } from 'recompose'

import connectState from 'hocs/connectState'
import withCycler from 'hocs/withCycler'
import { isPaused } from 'selectors/playback'

import LanguageResourceText from 'components/Commons/LanguageResourceText'
import splitLanguageResource from 'model/clip/captions/splitLanguageResource'

import styles from './CurrentClipPlayback.scss'

const LineText = ({ chunk, chunkIsLast, isAudioDirty }) => (
  <div className={styles.textBox}>
    {chunk && (
      <LanguageResourceText langResource={{ data: { text: chunk.text } }} className={styles.lineText} />
    )}
    {!chunkIsLast &&
      <span className={styles.continueDots}>...</span>
    }
    {isAudioDirty && <span className={styles.audioDirty}>(outdated audio)</span>}
  </div>
)

export default compose(
  connectState({
    paused: isPaused
  }),
  withCycler({
    sourcePropName: 'langRes',
    currentPropName: 'chunk',
    pausePropName: 'paused',
    chunkenizer: splitLanguageResource(50),
  })
)(LineText)
