import { mergeDeepRight, values, propEq } from 'ramda'

const GraphQLKind = {
  Document: 'Document',
  FragmentDefinition: 'FragmentDefinition'
}

export const gqlProps = (spec, dataProp = 'data') => ({
  props: ({ [dataProp]: data, ...otherProps }) => ({
    ...otherProps,
    [dataProp]: data,
    ...specToProps(spec, data, otherProps)
  })
})

const specToProps = (spec, data, otherProps) => Object.keys(spec).reduce((acc, key) => ({
  ...acc,
  [key]: (...args) => spec[key](...args, otherProps)(data)
}), {})

export const reQuery = newVariables => ({ fetchMore }) => fetchMore({
  variables: newVariables,
  updateQuery: replaceUpdateQuery
})

export const replaceUpdateQuery = (_, { fetchMoreResult }) => fetchMoreResult

// 
// mutation updates (callbacks to update cache)
//

const introspectFragmentFromMutation = mutation => ({
  kind: GraphQLKind.Document,
  definitions: mutation.definitions.filter(propEq('kind', GraphQLKind.FragmentDefinition)),
  loc: mutation.loc
})

export const updateFragment = mutation => (store, { data }) => {
  const fragment = introspectFragmentFromMutation(mutation)
  // data is  a single key:value where key is the name of the query (if we are just doing a single query/mutation of course !)
  const newValue = values(data)[0]
  const current = store.readFragment({
    id: newValue._id,
    fragment
  })
  store.writeFragment({
    id: newValue._id,
    fragment,
    data: mergeDeepRight(current, newValue)
  })
}