import React, { useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withState, withProps, withPropsOnChange } from 'recompose'
import { any, propEq } from 'ramda'
import { Input } from 'antd'

import { FormException } from 'utils/exceptions'
import { projectId } from 'selectors/project'
import { createState } from 'actions/state'
import { importSavegame as importSavegameAction } from 'engine/actions/state'
import CreateModal from 'components/Commons/CreateModal'
import { withTargetValue } from 'utils/antd'

import savegamesSummaryQuery from 'api/queries/savegamesSummary.graphql'

const NewSaveGameModal = ({ slotName, setSlot, hide, canSubmit, submit, onCreated, modalTitle }) => {
  const focusRef = useRef()
  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus()
      focusRef.current.select()
    }
  }, [focusRef.current])
  return (
    <CreateModal title={modalTitle} hide={hide} submit={submit} canSubmit={canSubmit} onCreated={onCreated}>
      {onCreate => (
        <div>
          <Input ref={focusRef} placeholder="Name" value={slotName} onChange={withTargetValue(setSlot)} onPressEnter={onCreate} />
        </div>
      )}
    </CreateModal>
  )
}


const nameAlreadyExists = newName => any(propEq('slot', newName))

export default compose(
  connect(
    state => ({ gameId: projectId(state) }),
    {
      createSavegame: createState,
      importSavegame: importSavegameAction
    }
  ),
  graphql(savegamesSummaryQuery, {
    name: 'savegames',
    options: ({ gameId }) => ({
      variables: { gameId },
      fetchPolicy: 'cache-only'
    })
  }),
  withState('slot', 'setSlot'),
  withPropsOnChange(['slot', 'suggestedSlot'], ({ slot, suggestedSlot }) => ({ slotName: slot || suggestedSlot })),
  withPropsOnChange(['slotName'], ({ slotName }) => ({
    canSubmit: !!slotName
  })),
  withProps(({ modalAction }) => ({ modalTitle: `${modalAction} Savegame` })),
  withHandlers({
    submit: ({ gameId, slotName, savegameData, createSavegame, savegames: { savegames }, importSavegame }) => async () => {
      if (nameAlreadyExists(slotName)(savegames)) {
        throw new FormException(`The savegame name "${slotName}" already exists.`)
      }

      if (savegameData) {
        await importSavegame(slotName, savegameData)
      } else {
        await createSavegame(gameId, slotName)
      }
    }
  })
)(NewSaveGameModal)
