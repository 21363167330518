import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { pathEq } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'
import IconButton from 'components/Commons/IconButton'
import CreateModal from 'components/Commons/CreateModal'
import { ModalConsumer } from 'components/Commons/Modal'
import { compose, withPropsOnChange } from 'recompose'
import { isCurrentRevisionWritable } from 'security/project'
import { revisionId as revisionIdSelector } from 'selectors/project'
import ManageEngineExtensionsView from './ManageEngineExtensionsView'
import fetchRevisionEngineExtensionsQuery from 'api/queries/fetchRevisionEngineExtensions.graphql'
import useSecureCheck from 'hooks/security/useSecureCheck'

export const isBuiltin = pathEq(['owner', 'type'], 'EngineExtensionOwner')
export const isCustom = pathEq(['owner', 'type'], 'RevisionExtensionOwner')

const ManageEngineExtensions = () => {
  const editable = useSecureCheck(isCurrentRevisionWritable)

  return (
    <ModalConsumer>
      {({ showModal }) => (
        <IconButton
          type="build"
          tooltip="Manage Extensions"
          onClick={() => showModal(ManageEngineExtensionsModal, { editable })}
      />
    )}
    </ModalConsumer>
  )
}

const _ManageEngineExtensionsModal = ({ hide, readOnly, extensions, ...props }) => {
  const extensionsToFilter = extensions.revisionWithId?.engineExtensions || EMPTY_ARRAY
  const [custom, setCustom] = useState([])

  useEffect(() => {
    setCustom(extensionsToFilter.filter(isCustom))
  }, [extensionsToFilter])

  return (
    <CreateModal
      title="Manage Engine Extensions"
      noFooter
      hide={hide}
      modalProps={{
        width: '90vw',
        keyboard: false,
        centered: true,
        style: { overflow: 'hidden' }
      }}
    >
      {() => (
        <ManageEngineExtensionsView
          custom={custom}
          setCustom={setCustom}
          readOnly={readOnly}
          loadingCustom={!extensions.revisionWithId}
          {...props}
        />
      )}
    </CreateModal>
  )
}

const ManageEngineExtensionsModal = compose(
  connect(state => ({
    revisionId: revisionIdSelector(state)
  })),
  graphql(fetchRevisionEngineExtensionsQuery, {
    name: 'extensions',
    options: ({ revisionId }) => ({
      variables: { revisionId },
      fetchPolicy: 'no-cache'
    }),
    skip: ({ revisionId }) => !revisionId,
  }),
  withPropsOnChange(['editable'], ({ editable }) => ({
    readOnly: !editable
  })),
)(_ManageEngineExtensionsModal)

export default ManageEngineExtensions
