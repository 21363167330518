import { DESTROY_REVISION_SESSION_DOESNT_EXIST_ERROR_PATTERN } from 'api/Errors'
import revisionSessionDestroyMutation from 'api/mutations/revisionSessionDestroy.graphql'
import onRevisionSessionDestroyedEvent from '../events/onRevisionSessionDestroyedEvent'

/**
 * Manually ask the server to destroy a session. Can only be done by admins or to your own sessions !
 */
const destroyRevisionSession = session => async (dispatch, getState, { getApolloClient: getClient }) => {
  try {
    await getClient().mutate({
      mutation: revisionSessionDestroyMutation,
      variables: { sessionId: session._id }
    })
  } catch (error) {
    // if it was destroyed already update the local state
    if (error.message.match(DESTROY_REVISION_SESSION_DOESNT_EXIST_ERROR_PATTERN)) {
      dispatch(onRevisionSessionDestroyedEvent({ session }))
    } else {
      throw error
    }
  }
}

export default destroyRevisionSession