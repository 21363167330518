import React from 'react'

import EntityHeader from '../EntityHeader/EntityHeader'
import EntityLeftPanel from './EntityLeftPanel'

import styles from './EntityLayout.scss'

const EntityLayout = ({ organization, team, organizationName, teamName, sectionName, sections, children }) => (
  <div className={styles.container}>
    <EntityHeader
      teamName={teamName}
      organizationName={organizationName}
      team={team}
      currentSection={sectionName}
      sections={sections}
    />
    <div className={styles.page}>
      <EntityLeftPanel organization={organization} team={team} />
      {children}
    </div>
  </div>
)

export default EntityLayout