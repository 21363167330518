/* eslint-disable no-useless-escape */
import { Document } from 'slate'
import { model, lang } from 'beanie-engine-api-js'

import { OBJECT_TYPES } from '../utils/Constants'
import { pipe, map } from 'ramda'
import { isEmpty, first, EMPTY_ARRAY } from 'utils/object'
import { customDialogueLine } from '../utils/slateMocks/dialogueLine'
import { customDirectorLine } from '../utils/slateMocks/directorLine'
import { NoteDelimiters, NoteTypes } from 'model/constants'
import { customActorPart } from '../utils/slateMocks/actorPart'
import { textNode } from '../utils/slateMocks/textNode'
import { customTextPart } from '../utils/slateMocks/actorTextPart'
import { performanceNote, productionNote } from '../utils/slateMocks/inlineNode'
import { extractAllMatchesAsObjects, EMPTY_STRING, NEW_LINE } from 'utils/string'
import markToNode from '../modelToText/MarkupSerializer'

const { types: { markup: { DELIM_START } } } = model
// REVIEWME: we are using the ancient regex parser instead of the Ohm ! :thinking:
const { markup: { parser: { regex: parse } } } = lang

const WHITE_LINE = `${NEW_LINE}${NEW_LINE}`

const removeSpaces = text => text.trimLeft()

export const isActorName = text => text && text.toUpperCase() === text

export const textToInline = text => (textToInlineFunctions[first(text)] || textNode)(text)

export const textToMarkup = markupText => {
  try {
    const mark = parse(markupText.substring(1, markupText.length - 1))
    return markToNode(mark)
  } catch (e) {
    return textNode(markupText)
  }
}

const textToInlineFunctions = {
  [NoteDelimiters[NoteTypes.Performance].left]: performanceNote,
  [NoteDelimiters[NoteTypes.Production].left]: productionNote,
  [DELIM_START]: textToMarkup,
}

const generateTexts = ({ offset, result, currentText }, { start, end, text: current }) => ({
  offset: end,
  result: [...result, ...(offset < start ? [currentText.substring(offset, start)] : EMPTY_ARRAY), current],
  currentText
})

const extractMatchesAndSubstrings = regex => text => {
  const { offset, result } = extractAllMatchesAsObjects(regex)(text)
    .reduce(generateTexts, { currentText: text, offset: 0, result: EMPTY_ARRAY })
  const lastSubString = text.substring(offset)
  return lastSubString === EMPTY_STRING ? result : [...result, lastSubString]
}

export const splitByDirectorLineNotes = extractMatchesAndSubstrings(/(\{[^}]*\}|\<[^>]*\>)/g)
export const splitByTextPartNotes = extractMatchesAndSubstrings(/(\{[^}]*\}|\<[^>]*\>|\[[^>]*\])/g)

export const textsToDialogueLine = (actorName, actorText) =>
  customDialogueLine(
    customActorPart([textNode(actorName)]),
    customTextPart(
      isEmpty(actorText) ? [textNode(actorText)] : splitByTextPartNotes(actorText).map(textToInline)
    ))

export const textToDirectorLine = directorLineText =>
  customDirectorLine(splitByDirectorLineNotes(directorLineText).map(textToInline))

export const slateSplit = text => text.split(WHITE_LINE).filter(t => t !== EMPTY_STRING)

export const textToLine = textLine => {
  const splited = textLine.split(NEW_LINE).filter(t => t !== EMPTY_STRING)
  return isActorName(splited[0]) && splited.length > 1 ?
    textsToDialogueLine(removeSpaces(splited[0]), removeSpaces(splited.slice(1).join(''))) :
    textToDirectorLine(removeSpaces(textLine))
}

export const textToFragment = text =>
  Document.create({
    object: OBJECT_TYPES.document,
    nodes: pipe(slateSplit, map(textToLine))(text),
  })
