import React from 'react'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { withRef } from 'utils/recompose'
import { Form } from 'antd'

import { noop } from 'utils/functions'
import closeProjectAction from 'actions/project/closeProject'
import transferProjectMutation from 'api/mutations/transferProject.graphql'
import ModalOpener from 'components/Commons/ModalOpener'
import SelectOrganizationField from './SelectOrganizationField'

const TransferProjectModal = ({ onTransfer, hideModal, transferFormRef }) => {
  const TransferForm = Form.create()(SelectOrganizationField)
  return (
    <ModalOpener
      title="Transfer to Organization"
      onOk={() => { 
        const form = transferFormRef().current
        form.validateFields(async (err, values) => {
          const { organization } = values
          if (err) { return }
          onTransfer(organization)
          form.resetFields()
        })
      }}
      onCancel={hideModal}>
      <TransferForm
        ref={transferFormRef()}
      />
    </ModalOpener>
  )
}

export default compose(
  withRef('transferFormRef'),
  graphql(transferProjectMutation, { name: 'transferProject' }),
  connect(null, { closeProject: closeProjectAction }),
  withHandlers({
    onTransfer: ({ transferProject, hideModal, project, closeProject, onTransferred = noop }) => async organizationId => {
      await transferProject({
        variables: { input: { projectId: project._id, organizationId } },
      })
      closeProject()
      hideModal()
      onTransferred()
    }
  }),
)(TransferProjectModal)
