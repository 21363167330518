import LogRocket from 'logrocket'

const service = () => {
  LogRocket.init('e3paqa/beanie')
}

service.onAuthorize = ({ user, sessionId }) => {
  if (service.enabledOn()) {
    LogRocket.identify(user._id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      sessionId
    })
  }
}

service.enabledOn = () => false

export default service