import { T, prop } from 'ramda'
import { nf } from 'utils/ui'
import logger from 'utils/logger'
import { Creators } from 'actions/ui'

const { notifyError } = Creators

const isBNEObject = o => o && o.id && o.sys

export const toBNEAction = (actionFn, desc = `${actionFn.name}`, { before = T, after, onError, notifyOnError = true } = { before: T, notifyOnError: true }) => (...args) => async (dispatch, getState, { synchronizer }) => {
  try {
    const beforeContext = before(dispatch, getState)
    nf()
    const value = await synchronizer.doSynchingBNE(
      desc,
      actionFn(...args, beforeContext),
      ...args.filter(isBNEObject)
    )
    nf()
    return after ?
      after(dispatch, getState, value)
      : value
  } catch (error) {
    logger.error(`Error in BNEAction ${desc}: ${prop('message', error)}`)
    logger.error(error.stack)

    if (notifyOnError) dispatch(notifyError(`Oops, there was a problem performing the action ${desc}. Please contact the administrator`))
    if (onError) onError(dispatch, getState, error)

    throw error
  }
}
