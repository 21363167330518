import memoize from 'memoize-state'
import { lang } from 'beanie-engine-api-js'
import { mapIndexed } from 'utils/ramda'
import { isEmpty, filter, propEq, reduce } from 'ramda'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { factsByName, factsTypes } from 'selectors/objects/facts'
import { checkRule } from './rule'

const { rule: { typing: { types } } } = lang

export const makeTruthTableRuleHeadersCheck = ({ id }) => {
  const mSelector = memoize((objectIndex, factIndex, factsTypesIndex) => {
    const { data: { headers } } = objectIndex[id]

    const headersCheckResult = mapIndexed(header =>
      checkRule({
        factIndex, objectIndex, factsTypesIndex, rule: { source: header.source, program: header.rule }, ruleType: types.Any, headerMessage: 'Column header'
      }), headers)
    const errors = filter(propEq('status', CHECK_RESULT_STATUS.ERROR), headersCheckResult)

    return isEmpty(errors) ? OK_RESULT : {
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.ERROR,
      id: 'With header errors',
      message: reduce((acc, { message }) => `${acc}${message}\n`, '', errors)
    }
  })

  const selector = ({ objectIndex, state }) => mSelector(objectIndex, factsByName(state), factsTypes(state))

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Truth table headers consistent',
    makeSelector: makeTruthTableRuleHeadersCheck
  }
]
