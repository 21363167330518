
/**
 * List of constants of well known extension points for documentation purpose
 */
export const EXTENSION_POINTS = {

  // panels controls section

  PLAYBACK_PANEL_CONTROL: 'PLAYBACK_PANEL_CONTROL',
  STATE_PANEL_CONTROL: 'STATE_PANEL_CONTROL',
  SEARCH_PANEL_CONTROL: 'SEARCH_PANEL_CONTROL',
  EXECUTION_PANEL_CONTROL: 'EXECUTION_PANEL_CONTROL',
  PROBLEMS_PANEL_CONTROL: 'PROBLEMS_PANEL_CONTROL',
  CONSOLE_PANEL_CONTROL: 'CONSOLE_PANEL_CONTROL',

  // graph context menus

  LANE_CONTEXT_MENU: 'LANE_CONTEXT_MENU',
  SINGLE_SELECTION_CONTEXT_MENU: 'SINGLE_SELECTION_CONTEXT_MENU',
  MULTIPLE_SELECTION_CONTEXT_MENU: 'MULTIPLE_SELECTION_CONTEXT_MENU',

  PROJECT_MENU: 'PROJECT_MENU',

  // clip editor menus

  LINE_HAMBURGER_MENU: 'LINE_HAMBURGER_MENU',
  LINE_AUDIO_MENU: 'LINE_AUDIO_MENU',

  OBJECT_ACTIONS: 'OBJECT_ACTIONS',
  ACTOR_ACTIONS: 'ACTOR_ACTIONS',

  // state panel

  FACT_ACTIONS: 'FACT_ACTIONS',
}

export const CLIENT_CONTEXT_MENU_PREFIX = 'CLIENT_CONTEXT_MENU'

/**
 *
 */
export default class ExtensionPoint {

  constructor(point) {
    this.point = point
    this.extensions = {} // { [extensionName]: data }
    this.observers = []
  }

  addExtension(id, data) {
    this.extensions[id] = {
      id,
      ...data
    }
    this.notify()
  }

  deleteExtension(id) {
    delete this.extensions[id]
    this.notify()
  }

  reset() {
    this.extensions = {}
    this.notify()
  }

  addObserver(obs) { this.observers.push(obs) }

  removeObserver(obs) { this.observers.splice(this.observers.indexOf(obs), 1) }

  getExtensions() {
    return Object.values(this.extensions)
  }

  notify() {
    const updatedExtensions = this.getExtensions()
    this.observers.forEach(obs => obs.update(updatedExtensions))
  }
}