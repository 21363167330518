import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spin } from 'antd'

import NoContent from 'components/Commons/NoContent'
import Notification from 'components/NotificationsIndicator/Notification'

import styles from './NotificationsList.scss'

import pdfJobReadyNotifier from './notifiers/pdfJobReadyNotifier'
import pdfJobErrorNotifier from './notifiers/pdfJobErrorNotifier'
import ttsJobFinishedNotifier from './notifiers/ttsJobFinishedNotifier'

// TODO: check how to dynamically load them
const notificationRenderers = [pdfJobReadyNotifier, pdfJobErrorNotifier, ttsJobFinishedNotifier]

const notificationRenderer = notification => {
  const foundRenderer = notificationRenderers.find(renderer => renderer.accepts(notification))

  if (!foundRenderer) return <Notification key={notification._id} notification={notification} />
  return foundRenderer.render(notification)
}

const NOTIFICATIONS_LIST = 'notificationsList'

const NotificationsList = ({ notifications: { notifications = { list: [], paging: { nrOfItems: 0 } } }, fetchMore, hasMore }) => (
  notifications.paging.nrOfItems > 0 ?
    (<div id={NOTIFICATIONS_LIST} className={styles.NotificationsList}>
      <InfiniteScroll
        scrollableTarget={NOTIFICATIONS_LIST}
        dataLength={notifications.list.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={<div className={styles.spinner}><Spin spinning /></div>}
      >
        {notifications.list.map(notificationRenderer)}
      </InfiniteScroll>
    </div>)
    : (
      <NoContent message={[<p className={styles.message}>Nothing to read.</p>, <p className={styles.message}>Woohoo!</p>]} className={styles.noContentDark} />
    )
)
export default NotificationsList