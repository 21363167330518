import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { updateBNEObject } from '../../engine/actions/actions'

import { toNestedPropertyValue } from '../../utils/object'

/**
 * Gives a function to update the given beanie object
 *
 * Signature
 *    async (path, value) => Void
 *
 * Example usage
 *
 *    const setCallback = useObjectUpdater(obj)
 *
 *    <div onClick=(() => setCallback("data.name", 'NEW NAME') />
 */
const useObjectUpdater = object => {
  const dispatch = useDispatch()

  return useCallback(async (path, value) => {
    const data = toNestedPropertyValue(path.substr('data.'.length), value)
    await dispatch(updateBNEObject(object.id, data))
  },
  [object]
  )
}

export default useObjectUpdater