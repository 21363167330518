import { Sys } from 'beanie-engine-api-js'
import { isBlocking } from '../../../model/taskContainer'
import { EMPTY_ARRAY } from '../../../utils/object'

import styles from '../TreeView.scss'

/**
 * Returns a list of classNames for the given node.
 * Used as an extension of TreeChainNode to define custom styles for specific types of nodes
 */
const nodeStylesForNode = node => {
  // eventually we will have more cases and a better declarative implementation

  if (node.sys === Sys.task_container) {
    return [isBlocking(node) ? styles.blocking : styles['non-blocking']]
  }
  return EMPTY_ARRAY
}

export default nodeStylesForNode