import React from 'react'

import { Popconfirm } from 'antd'
import IconButton from 'components/IconButton/IconButton'
import TableRowActions from 'components/Commons/TableRowActions'

import EditUser from '../EditUser'

const UsersRowActions = ({ currentUser, user, onUserUpdated, deleteUser, resetPassword }) => {
  const { email, forceResetPassword } = user
  const deleteEnabled = canDelete(currentUser, email)
  return (
    <TableRowActions>
      <EditUser user={user} onAccept={onUserUpdated} />
      <ConditionalUserAction tooltip="Force password reset" oppositeTooltip="Password reset in progress" iconName="login" condition={!forceResetPassword} action={() => resetPassword(user.id)} />
      <ConditionalUserAction tooltip="Delete user" oppositeTooltip="You can`t delete yourself" iconName="delete" condition={deleteEnabled} action={() => deleteUser(user.id)} />
    </TableRowActions>
  )
}

const canDelete = (user, email) => user.email !== email

const ConditionalUserAction = ({ tooltip, oppositeTooltip, iconName, condition, action }) => (
  <div>
    { condition &&
      <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => action()}>
        <IconButton icon={iconName} tooltip={tooltip} tooltipPlacement="bottom" />
      </Popconfirm>
    }
    { !condition &&
      <IconButton icon={iconName} disabled tooltip={oppositeTooltip} tooltipPlacement="bottom" />
    }
  </div>
)

export default UsersRowActions