import { handleMove, handleAddToSelectionKeys } from 'actions/nodes'
import Contexts from 'shortcuts/contexts'
import Groups from './Groups'

const command = (action, group) => shortcut => ({
  shortcut,
  action,
  params: (state, event) => event.code,
  context: Contexts.Tree,
  group,
})

const move = command(handleMove, Groups.Navigation)

// move
export const MOVE_LEFT = move('left')
export const MOVE_RIGHT = move('right')
export const MOVE_UP = move('up')
export const MOVE_DOWN = move('down')

// selection
const addToSelection = command(handleAddToSelectionKeys, Groups.Selection)
export const ADD_TO_SELECTION_LEFT = addToSelection('shift+left')
export const ADD_TO_SELECTION_RIGHT = addToSelection('shift+right')
export const ADD_TO_SELECTION_UP = addToSelection('shift+up')
export const ADD_TO_SELECTION_DOWN = addToSelection('shift+down')
