import React from 'react'
import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'

import { markupTypes as markupTypesSelector } from 'selectors/markups'
import { revisionId } from 'selectors/project'

import connectState from 'hocs/connectState'
import withFilteredElements from 'hocs/withFilteredElements'

import markupCustomTypeDeleteMutation from 'api/mutations/markupCustomTypeDelete.graphql'

import { markupCustomTypeDeleted as markupCustomTypeDeletedAction } from 'actions/project'

import TypesTabHeader from './TypesTabHeader'
import TypesTable from './TypesTable'
import CreateMarkupCustomType from './CreateMarkupCustomType'

const TypesTab = ({ elements, onSearch, deleteCustomType, editCustomType }) => (
  <div>
    <TypesTabHeader onSearch={onSearch}>
      <CreateMarkupCustomType />
    </TypesTabHeader>
    <TypesTable types={elements} deleteCustomType={deleteCustomType} editCustomType={editCustomType} />
  </div>
)

export default compose(
  connectState({
    markupTypes: markupTypesSelector
  }),
  connect(state => ({
    revision: revisionId(state)
  }), {
    markupCustomTypeDeleted: markupCustomTypeDeletedAction
  }),
  graphql(markupCustomTypeDeleteMutation, { name: 'markupCustomTypeDelete' }),
  withHandlers({
    deleteCustomType: ({ markupCustomTypeDelete, revision, markupCustomTypeDeleted }) => async customType => {
      const { data: { markupCustomTypeDelete: deleted } } = await markupCustomTypeDelete({ variables: { input: {
        projectId: revision,
        name: customType.name,
      } } })
      markupCustomTypeDeleted(deleted)
    },
  }),
  withFilteredElements({ propName: 'markupTypes', filter: (type, text) => type.name.toUpperCase().indexOf(text.toUpperCase()) >= 0 }),
)(TypesTab)