exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".dropdown__2Tysv{min-width:8rem}.allOption__3lOpg{border-bottom:1px solid #d3d3d3}", ""]);

// exports
exports.locals = {
	"dropdown": "dropdown__2Tysv",
	"allOption": "allOption__3lOpg"
};