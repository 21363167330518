import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Shortcut to use a redux thunk/action with hooks.
 * Reduces the boilerplate of doing useDispatch + useCallback
 */
const useAction = action => {
  const dispatch = useDispatch()
  return useCallback((...args) => dispatch(action(...args)), [dispatch])
}

export default useAction