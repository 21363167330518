import React from 'react'
import { projectAdminAccess } from 'security/project'
import { Link } from 'react-router-dom'

import withConfirm from 'hocs/withConfirm'

import Secure from 'components/Commons/Secure'
import LinkButton from 'components/Commons/LinkButton'
import routes from 'components/Organizations/routes'
import { ModalConsumer } from 'components/Commons/Modal'
import DeleteProjectModal from 'components/ProjectSettings/ProjectDangerZone/DeleteProjectModal'

import Sections from 'components/ProjectSettings/Sections'

import styles from './OrganizationProjectActions.scss'

const OrganizationProjectActions = ({ organization, project, refreshItems }) => (
  <div className={styles.actions}>
    <Secure context={{ organization, project }} check={projectAdminAccess}>
      <ModalConsumer>
        {({ showModal }) =>
          (<LinkButton
            tooltip="Delete the project"
            onClick={() => showModal(
            DeleteProjectModal, { onDeleted: refreshItems, project })}>
              Delete
          </LinkButton>)}
      </ModalConsumer>
      <Link to={routes.projectSettingsForSection(project.masterBranch._id, Sections.Access.name)}>Manage Access</Link>
    </Secure>
  </div>)

export default withConfirm(OrganizationProjectActions)