exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".option__2BSEx{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.option__2BSEx .ant-avatar{margin-right:.5rem}.option__2BSEx .description__2PWnE{opacity:.4}", ""]);

// exports
exports.locals = {
	"option": "option__2BSEx",
	"description": "description__2PWnE"
};