exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".form__E3vH6{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.emailInput__2ks2_{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;padding-bottom:1rem}.checkBox__1UXDq{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.suggestionIcon__R-w3F{padding-left:.5rem;color:gray;cursor:default}.suggestionIcon__R-w3F:hover{color:gray}", ""]);

// exports
exports.locals = {
	"form": "form__E3vH6",
	"emailInput": "emailInput__2ks2_",
	"checkBox": "checkBox__1UXDq",
	"suggestionIcon": "suggestionIcon__R-w3F"
};