import { push } from 'connected-react-router'
import { find, propEq } from 'ramda'
import { isNilOrEmpty } from 'ramda-adjunct'
import { REVISION_SESSION_EXPIRED_ERROR_PATTERN } from 'api/Errors'
import { RevisionSessionState } from '../../model/constants'
import { selectedRevisionId } from '../../selectors/selections'
import { doneTasks as doneTasksSelector } from 'selectors/tasks'
import { select } from '../selection'
import createSession from './load/tasks/createSession'
import { fetchRevisionHead, getBNEObjects } from './load/tasks/fetchRevision'
import loadProjectIntoBeanie from './load/tasks/loadProjectIntoBeanie'
import restartVM from './load/tasks/restartVM'

import receiveProjectRevisionEvent from 'actions/project/receiveProjectRevisionEvent'
import { onSessionDestroyed, onSessionConnecting } from 'actions/project/session'

import { loadRevisionEnd, loadRevisionError, projectFetched, restoreState, selectProject } from '../project'
import { onSessionConnected } from './session'
import { createJobAction, Jobs, runTask, Tasks, TaskState, updateTaskState } from '../tasks'
import executeExtensionsOnLoad from './load/tasks/executeExtensionsOnLoad'
import { STUDIO } from '../../components/ProjectSettings/Sections/Runtime/constants'
import { setUserTasks } from '../userTasks'

const selectRevision = select('revisionId')

/**
 * Does the actual loading of a project revision.
 * Not just load, but it creates a revision sessions that is connected to the studio API
 * to receive events in real-time and send its own events.
 * The session also gives us bi-directional communication with other sessions on the same
 * revision, for collaborating.
 */
const connectRevision = (onMessagingError, moreOptions = {}, job = Jobs.LOAD_PROJECT, subs) => async (dispatch, getState, { services, synchronizer, getApolloClient: getClient, getEditorModule }) => {
  try {
    const revisionId = selectedRevisionId(getState())
    const client = getClient()

    const run = runTask(dispatch, getState)
    dispatch(createJobAction(job))

    const doneTasks = doneTasksSelector(getState())

    doneTasks.forEach(doneTask => dispatch(updateTaskState(doneTask, TaskState.completed)))

    const hasToRun = task => task.runAlways || (job.tasks.includes(task) && !doneTasks.includes(task.type))

    let subscription = subs
    if (hasToRun(Tasks.CREATE_SESSION)) {
      //
      // establish a Session
      //
      dispatch(onSessionConnecting())

      const { session, subscription: s } = await run(
        Tasks.CREATE_SESSION,
        createSession(
          client,
          revisionId,
          {
            onEvent: event => dispatch(receiveProjectRevisionEvent(event)),
            onError: error => {
              if (error.message && error.message.startsWith(REVISION_SESSION_EXPIRED_ERROR_PATTERN)) {
                // we tried to resume the subscription/reconnect but server rejected due to session already destroyed
                // we need to destroy it locally, which will trigger recreating a new one
                dispatch(onSessionDestroyed())
              } else {
                onMessagingError(error)
              }
            }
          })
      )
      subscription = s
      dispatch(onSessionConnected({ ...session, state: RevisionSessionState.CONNECTED }))
    }

    let revision
    if (hasToRun(Tasks.FETCH)) {
      //
      // fetch revision data (header) first
      // TODO: review if changesets (which maybe a lot) come here too, if a lot we may want to use a Task.
      // I am trying to avoid a new Task at first as it was mentioned that there were too mnay (on the UI)
      //
      revision = await run(Tasks.FETCH, fetchRevisionHead(revisionId, services))
      await dispatch(selectProject(revision.project._id))

      if (!isNilOrEmpty(revision.buildConfigurations)) {
        const { buildConfigurations } = revision
        const studioBuildConfig = find(propEq('name', STUDIO), buildConfigurations)
        if (studioBuildConfig) {
          revision.buildConfig = studioBuildConfig
          moreOptions.buildConfig = studioBuildConfig
        }
      }
    }

    if (hasToRun(Tasks.DATA)) {
      //
      // load VM + fetch objs (in parallel)
      //
      const [, objects] = await Promise.all([
        ...hasToRun(Tasks.LOAD_VM) ? [run(Tasks.LOAD_VM, () => restartVM(revisionId, services, dispatch, getState, synchronizer, moreOptions, getEditorModule))] : [Promise.resolve()],
        run(Tasks.DATA, getBNEObjects(revisionId, services))
      ])
      revision.objects = objects
    }

    if (hasToRun(Tasks.LOAD_PROJECT)) {
      // load projects objects
      await run(Tasks.LOAD_PROJECT, () => loadProjectIntoBeanie(revision, dispatch, getState, synchronizer))
    }

    // save objects in store
    dispatch(projectFetched(revision))

    dispatch(setUserTasks(revision))

    if (hasToRun(Tasks.RESTORE_STATE)) {
      //
      // SAVEGAMES & SYNC_STATE
      //

      await Promise.all([
        // restore active preset state into redux and vm
        run(Tasks.RESTORE_STATE, restoreState(dispatch, getState)),
        // process enqueued changeSets
        synchronizer.getServerStore().applyEnqueuedChangesetsFromServer(),
      ])
    }

    if (hasToRun(Tasks.EXEC_EXT_ON_LOAD)) {
      await run(Tasks.EXEC_EXT_ON_LOAD, executeExtensionsOnLoad)
    }

    //
    // loadRevisionEnd unlocks synchronizer (stops enqueuing)
    //
    dispatch(loadRevisionEnd(revisionId))

    return subscription
  } catch (error) {
    if (push) dispatch(push('/'))
    dispatch(loadRevisionError(error))
    dispatch(selectRevision(undefined))
    throw error
  }
}

export default connectRevision
