import { assocPath, path } from 'ramda'
import { LUA_UNSET_VALUE, parseRef, ref } from 'beanie-engine-api-js'
import { selectionToLeftAndRight } from '../../../../selectors/nodeSelection'

/**
 * A generic connector for a single relationship.
 * I.e. choices2.data.idle -> node
 *
 * Knows how to set / unset it rewiring
 */
const OneToOneConnector = {

  connect: ({ api, resolve }, selection, { from, path: aPath }) => {
    const { left, right } = selectionToLeftAndRight(selection)
    const leftObj = api.obj.get(left)
    const rightObj = api.obj.get(right)

    const owner = resolve(from)
    const currentValue = path(aPath, owner)

    // set the new one
    api.obj.update(from, assocPath(aPath, ref(left), {}).data)

    // wire if we had some already
    leftObj.set_parent(null)
    if (currentValue) {
      api.obj.get(parseRef(currentValue)).set_parent(rightObj)
    }
  },

  // TODO: we might want more restrictions here
  // in the end I think they will depend on the schema and invariants defined there
  canConnect: () => true,

  /**
   * Removes the selection's left object from the object field
   * If there's something to the right of the selection (a range) then
   * it wires that to the relation instead of setting it to null, and updates its parent.
   * I.e.
   *   A
   *   |-<my_field>-> [B -> C] -> D
   *
   * Then it becomes
   *   A
   *   |-<my_field>-> D
   */
  disconnect: ({ api }, selection, { from, path: aPath }) => {
    const { right } = selectionToLeftAndRight(selection)

    const nextToWire = api.obj.get(right).get_child_node()
    const nextValue = nextToWire ? ref(nextToWire.get_id()) : LUA_UNSET_VALUE
    const delta = assocPath(aPath, nextValue, {})
    api.obj.update(from, delta.data)

    // disconnect right side
    if (nextToWire) {
      nextToWire.set_parent(null)
    }
  },

}

export default OneToOneConnector