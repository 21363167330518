import { NODE_TYPES } from '../../Constants'
// notes
import performanceNote from './performanceNote'
import productionNote from './productionNote'
// markups
import markUp from './markUp'
import markUpWithParams from './markUpWithParams'
import markUpName from './markUpName'
import markUpParameters from './markUpParameters'
import markUpParameter from './markUpParameter'
import markUpParameterKey from './markUpParameterKey'
import markUpParameterValue from './markUpParameterValue'
import markUpParameterValueList from './markup/values/list'
import markUpParameterValueListElement from './markup/values/listElement'

export default {

  // notes
  [NODE_TYPES.PERFORMANCE_NOTE]: performanceNote,
  [NODE_TYPES.PRODUCTION_NOTE]: productionNote,

  // markups
  [NODE_TYPES.MARK_UP]: markUp,
  [NODE_TYPES.MARK_UP_WITH_PARAMS]: markUpWithParams,
  [NODE_TYPES.MARK_UP_NAME]: markUpName,
  [NODE_TYPES.MARK_UP_PARAMETERS]: markUpParameters,
  [NODE_TYPES.MARK_UP_PARAMETER]: markUpParameter,
  [NODE_TYPES.MARK_UP_PARAMETER_KEY]: markUpParameterKey,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE]: markUpParameterValue,

  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST]: markUpParameterValueList,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]: markUpParameterValueListElement,

}