import { curryN } from 'ramda'
import { createMatchResult } from '../../searches'
import computeMatches from './computeMatches'

/**
 * Makes a Searcher function used with MatchSources searches so that
 * they can call a simplified function with "text" (String) and what to search for.
 * Abstracting them from internals and reusing code like `computeMatches(text, text)` that does
 * the actual string matching.
 * Curryfied to use it in a different way in the core code and unitary tests.
 */
const makeSearcher = curryN(4, (searchFor, pushResult, object, node) => {
  const internalSearcher = internalMakeSearcher(searchFor, pushResult)
  return (text, sourcePayload) => internalSearcher(
    text,
    ({ offset, length }) => createMatchResult(object, offset, length, node, sourcePayload)
  )
})

const internalMakeSearcher = (searchText, pushResult) => (inText, resultCreator) => {
  computeMatches(searchText, inText).forEach(match => pushResult(resultCreator(match)))
}

export default makeSearcher