exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".Console__2TnFc{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%}@-webkit-keyframes react-console-cursor-animation{0%{background:#503898}50%{background:#503898}51%{background:transparent}100%{background:transparent}}@keyframes react-console-cursor-animation{0%{background:#503898}50%{background:#503898}51%{background:transparent}100%{background:transparent}}.Console__2TnFc .react-console-container{height:100%;font-family:\"Menlo\",\"Consolas\",\"DejaVu Sans Mono\",monospace;padding:1em;padding-left:1.5rem;padding-right:1.5rem;white-space:pre;overflow:auto}.Console__2TnFc .react-console-container .react-console-prompt-box:nth-child(2){display:none}.Console__2TnFc .react-console-container .react-console-prompt-box .react-console-prompt-argument{color:#503898}.Console__2TnFc .react-console-container .react-console-prompt-label__15o3R{color:#dfdfdf}.Console__2TnFc .react-console-container .react-console-cursor:not(.react-console-cursor-idle){background:none;color:#503898;outline:.1em solid #503898;outline-offset:-0.1em}.Console__2TnFc .react-console-container .react-console-cursor-idle{background:#503898;-webkit-animation:react-console-cursor-animation 1s infinite;animation:react-console-cursor-animation 1s infinite}.Console__2TnFc .react-console-container .react-console-welcome{font-weight:bolder;padding-bottom:.5em}.Console__2TnFc .react-console-container .react-console-message:not(.react-console-welcome){color:#7a7a7a}.Console__2TnFc .react-console-container .react-console-message.react-console-message-error{color:#c55151}.Console__2TnFc .react-console-container .react-console-message-print{font-style:italic;color:#c5c4c4;padding-left:1rem}", ""]);

// exports
exports.locals = {
	"Console": "Console__2TnFc",
	"react-console-prompt-label": "react-console-prompt-label__15o3R"
};