import { pipe, map, join, ifElse, converge } from 'ramda'
import { repeatString } from 'utils/ramda'
import { getActorPart, getTextPart } from '../model/DialogueLine'
import { getLineText, isDirectorLine } from '../model/Line'
import { subnodesToText } from '../textToModel/utils'
import { NEW_LINE, EMPTY_STRING } from 'utils/string'

const directorLineToText = line => `${getLineText(line)}\n`

const SPACE = ' '
export const ACTOR_NAME_SPACES = repeatString(SPACE, 40)
export const ACTOR_TEXT_SPACES = repeatString(SPACE, 8)

const dialogueLineText = (actor, text) =>
  `${ACTOR_NAME_SPACES}${actor ? actor.text : EMPTY_STRING}${NEW_LINE}${ACTOR_TEXT_SPACES}${text ? subnodesToText(text) : EMPTY_STRING}\n`

const dialogueLineToText = converge(dialogueLineText, [getActorPart, getTextPart])

const lineToText = ifElse(isDirectorLine, directorLineToText, dialogueLineToText)
export const clipToPlainText = pipe(map(lineToText), join(NEW_LINE))
