import { RevisionSessionState } from '../../model/constants'
import { selectedRevisionId } from '../../selectors/selections'
import { loadRevisionEnd } from '../project'
import { onSessionConnected } from '../project/session'
import { createJobAction, Jobs, runTask, Tasks } from '../tasks'
import createSession from './load/tasks/createSession'

import receiveProjectRevisionEvent from './receiveProjectRevisionEvent'
import resyncRevision from './resyncRevision'

/**
 * Creates a new Revision Session whenever we detect that our session expired
 * This happens if you are offline for > 15min (depends on backend's expiration time).
 *
 * This actions starts a new task to create a new session.
 * After that it uses `resyncRevision` so we can get up to date with changes while we were offline.
 */
const recreateRevisionSession = (onMessagingError) => async (dispatch, getState, { getApolloClient: getClient }) => {
  const revisionId = selectedRevisionId(getState())

  const run = runTask(dispatch)
  dispatch(createJobAction(Jobs.RECREATE_REVISION_SESSION))

  // establish a Session
  const { session, subscription } = await run(
    Tasks.CREATE_SESSION,
    createSession(
      getClient(),
      revisionId,
      {
        onEvent: event => dispatch(receiveProjectRevisionEvent(event)),
        onError: onMessagingError
      })
  )
  dispatch(onSessionConnected({ ...session, state: RevisionSessionState.CONNECTED }))

  // get any missing changeSets
  await dispatch(resyncRevision())

  // TODO: ??? or maybe a specific action like "sessionRecreated()"
  dispatch(loadRevisionEnd(revisionId))

  return subscription
}

export default recreateRevisionSession
