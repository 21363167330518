import { head } from 'ramda'
import { parseRef } from 'beanie-engine-api-js'
import { Creators, NodeState } from 'actions/vm'

const { finishClip, recordNodeState, updateStoryboard } = Creators

/**
 * Handles playback events for clips.
 */
const clipHandler = {

  onBeginPresentNode: (bneNode, playbackId, dispatch) => {
    const firstLine = head(bneNode.get_lines().toJSONObject())

    if (firstLine) {
      const lineId = parseRef(firstLine)
      dispatch(recordNodeState(playbackId, NodeState.clip.lineId, lineId))
      dispatch(updateStoryboard(playbackId, lineId))
    } else {
      dispatch(finishClip(playbackId))
    }
  }

}

export default clipHandler