import { isNotNil } from 'ramda-adjunct'
import { filter, forEachObjIndexed, hasPath, isEmpty, isNil, length } from 'ramda'

export default class ChecksMemory {
  constructor() {
    this.checks = {} // { [objId]: { [checkName]: checkInstance } }
  }

  addNewCheck (checkInstance) {
    const { objId, name } = checkInstance.asData()
    if (isNil(this.checks[objId])) {
      this.checks[objId] = {}
    }

    this.checks[objId][name] = checkInstance
  }

  deleteCheck (checkInstance) {
    const { objId, name } = checkInstance.asData()
    if (hasPath([objId, name], this.checks)) {
      delete this.checks[objId][name]
    }
  }

  deleteAllForNode (id) {
    delete this.checks[id]
  }

  checksForNode (objId) {
    const checks = this.checks[objId]
    return checks ? Object.values(checks) : []
  }

  countOfNodesWithChecks() {
    return length(this.nodeIds())
  }

  isEmpty () {
    return isEmpty(this.checks)
  }

  nodeIds() {
    return filter(isNotNil, Object.keys(this.checks))
  }

  forEachCheck (func) {
    forEachObjIndexed((indexedChecks, objId) => {
      if (isNotNil(objId) && indexedChecks) {
        forEachObjIndexed(func, indexedChecks)
      }
    }, this.checks)
  }

  // Deprecated
  getAll () {
    const res = []

    Object.keys(this.checks).forEach(objId => {
      if (isNotNil(objId)) {
        this.checksForNode(objId).forEach(check => res.push(check))
      }
    })

    return res
  }

  clean () {
    this.checks = {}
  }
}