import React from 'react'

import styles from './GamepadChoiceButton.scss'
import { choiceIndexToButton } from 'utils/gamepad'

const GamepadChoiceButton = ({ choiceIndex, type }) => {
  const button = choiceIndexToButton(type, choiceIndex)
  return button ? (
    <img alt={choiceIndex} src={button.icon} className={styles.buttonIcon} />
  ) : null
}

export default GamepadChoiceButton