import SessionClientType from '../../model/constants/SessionClientType'
import { GENERATE_SEQUENCE, OPEN_SEQUENCE, PLAY_NODE } from './unreal/unreal'

/**
 * An object/index that declares and encapsulates which commands are supported
 * for each type of Client (Session) Type.
 * Used by the context menu to auto-generates itself based on this commands.
 * So when you want a new functionality for a node, you just declare it here under the appropriated
 * client type.
 * Or add a new client Type.
 *
 * This is an ad-hoc, temporary, easy solution as first stage.
 * If we really want to work on making the Studio automatically extendable by clients
 * then we need a lot more work, with
 *  - some kind of commands-schema-auto-discovery protocol
 *  - modifying each piece of the studio UI that we want to expose "extension points" like not just the context menu
 *     maybe also the context menu for lanes, or clip lines dropdown menus, or even the ProjectMenu.
 *  - and for that we should do it in a DDD-way (domain-driven design). The commands must be expressed in terms of its domain
 *    like "this command is for clips (first parameter) but user must also provide a text". Typing commands with a schema
 *    becomes completely necessary if we want the UI to be automatically generated !
 */

const CommandsByClientType = {

  // TODO: we might want to generate this dynamically to reduce the amount of boilerplate for each
  //   new command
  [SessionClientType.Unreal.code]: [
    OPEN_SEQUENCE,
    GENERATE_SEQUENCE,
    PLAY_NODE,
    // add more commands here to appear under the context menu
  ],

  // add for other types of clients
}

export default CommandsByClientType