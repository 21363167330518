import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import storeCreator from './store/storeCreator'

import VideoGamePlayerApp from './VideoGamePlayerApp'

const VideoGamePlayerAppContainer = () => {
  let store
  useEffect(() => {
    store = storeCreator()
  })

  return store ? (
    <Provider store={store}>
      <ApolloProvider store={store} client={store.apolloClient}>
        <VideoGamePlayerApp />
      </ApolloProvider>
    </Provider>
  ) : null
}

export default VideoGamePlayerAppContainer