import { pipe, prop, over, lensProp } from 'ramda'
import { isContained, isContainer } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'

import { makeDecisionPathSelector } from 'selectors/paths'

export const ForkingModelNodeType = {
  fork: 'fork',
  node: 'node'
}

const mergeForksInPath = pipe(
  list => list.reduce((acc, node) => {
    if (isContainer(node)) {
      return { ...acc, lastFork: { type: ForkingModelNodeType.fork, node } }
    } else if (isContained(node)) {
      return { ...acc, lastFork: undefined, result: [...acc.result, { ...acc.lastFork, branch: node }] }
    } else {
      return { ...acc, result: [...acc.result, { type: ForkingModelNodeType.node, node }] }
    }
  }, { result: [] }),
  prop('result')
)

/**
 * Combines container + contained into the concept of a "fork". Useful for
 * data visualization
 */
const makeForkingDecisionPathSelector = (idFromPropSelector, includeSelf) => createSelector(
  [makeDecisionPathSelector(idFromPropSelector, includeSelf)],
  over(lensProp('path'), mergeForksInPath)
)

export default makeForkingDecisionPathSelector