import React from 'react'
import { Spin, Form } from 'antd'
import { pathOr, T } from 'ramda'

import { ProjectGrantRole } from 'utils/authentication'

import ProjectGrantRoleComponent from 'components/Organizations/OrganizationProjects/ProjectGrantRole'
import Select from 'components/Commons/Forms/components/Select'
import TeamSelectOption from 'components/Organizations/Teams/Commons/TeamSelectOption'

import styles from './GrantTeamForm.scss'

const GrantTeamForm = ({ teamsData, filter, getFieldDecorator }) => (
  <Spin spinning={teamsData && !!teamsData.loading}>
    <Form layout="horizontal">
      <Select
        getFieldDecorator={getFieldDecorator}
        placeholder="Choose one..."
        fieldName="Team"
        options={
          pathOr([], ['organization', 'teams', 'list'], teamsData)
            .filter(filter || T)
            .map(t => ({
              value: t._id,
              label: <TeamSelectOption team={t} navigable={false} />
            }))
        }
      />
      <Select
        getFieldDecorator={getFieldDecorator}
        initialValue={ProjectGrantRole.READ}
        placeholder="Role"
        fieldName="Role"
        labelRenderer={role => <span className={styles.roleLabel}><ProjectGrantRoleComponent role={role} />{role}</span>}
        options={Object.values(ProjectGrantRole)
          .map(role => ({
            value: role,
            label: role
          }))
        }
      />
    </Form>
  </Spin>
) 

export default GrantTeamForm