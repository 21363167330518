exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".generalSection__2e5I4 .title__zqPiP{color:gray}.generalSection__2e5I4 .generalHeader__2y3EB .ant-divider{margin:0 !important;margin-bottom:1rem !important}.generalSection__2e5I4 .generalContainer__2OA7Y{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start}.generalSection__2e5I4 .propTitle__3lakp{font-size:1rem}.generalSection__2e5I4 .propSubtitle__2NiIC{font-weight:bold;font-size:1.2rem}.generalSection__2e5I4 .nameBox__1HRj2{padding-bottom:1rem}.generalSection__2e5I4 .editingPart__1ozVP{display:-ms-flexbox;display:flex;-ms-flex:2 1 0%;flex:2 1 0%;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"generalSection": "generalSection__2e5I4",
	"title": "title__zqPiP",
	"generalHeader": "generalHeader__2y3EB",
	"generalContainer": "generalContainer__2OA7Y",
	"propTitle": "propTitle__3lakp",
	"propSubtitle": "propSubtitle__2NiIC",
	"nameBox": "nameBox__1HRj2",
	"editingPart": "editingPart__1ozVP"
};