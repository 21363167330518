import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { all, path, pathOr, } from 'ramda'
import { Sys, model, parseRef } from 'beanie-engine-api-js'

import { setDisabledSubtitles as setDisabledSubtitlesAction } from 'engine/actions/clip'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'

import { selectedNodeObject } from 'selectors/selectors'
import { objectById } from 'selectors/objects'

import styles from './TextEditorControlButton.scss'

import IconButton from '../../Commons/IconButton'
// eslint-disable-next-line import/no-webpack-loader-syntax
import SubtitlesDisabledIcon from '-!svg-react-loader!../../Commons/SubtitlesIcon/subtitles-disabled.svg'
// eslint-disable-next-line import/no-webpack-loader-syntax
import SubtitlesEnabledIcon from '-!svg-react-loader!../../Commons/SubtitlesIcon/subtitles-enabled.svg'


const { types: { object: { Paths } } } = model

const isDisabled = selectedNode => !(selectedNode && selectedNode.sys === Sys.clip)

const isDirectorLine = (line, state) => {
  const actorId = path(Paths.line.actor, line)
  const actor = objectById(parseRef(actorId))(state)
  const actorName = path(Paths.actor.actorName, actor)
  return actorName === 'DIR'
}

const _hasDisabledSubtitles = (clip, state) => all(
  lineId => {
    const line = objectById(parseRef(lineId))(state)

    if (isDirectorLine(line, state)) return true // this is the same as saying "ignore director lines"

    return pathOr(false, Paths.line.disabled_subtitles)(line)
  },
  pathOr([], Paths.clip.lines, clip)
)

const SubtitlesButton = ({ hasWriteAccess, selectedNode, hasDisabledSubtitles, setDisabledSubtitles }) => (
  <IconButton
    component={hasDisabledSubtitles ? SubtitlesEnabledIcon : SubtitlesDisabledIcon}
    tooltip={`${hasDisabledSubtitles ? 'Enable' : 'Disable'} subtitles for all dialogue lines`}
    onClick={() => setDisabledSubtitles(!hasDisabledSubtitles)}
    disabled={!hasWriteAccess || isDisabled(selectedNode)}
    className={!hasWriteAccess || isDisabled(selectedNode) ? styles.disabled : styles.enabled}
  />
)

export default compose(
  connect(state => ({
    selectedNode: selectedNodeObject(state),
    hasDisabledSubtitles: _hasDisabledSubtitles(selectedNodeObject(state), state)
  }), ({
    setDisabledSubtitles: setDisabledSubtitlesAction,
  })),
  withHandlers({
    setDisabledSubtitles: ({ selectedNode, setDisabledSubtitles }) => value => {
      setDisabledSubtitles(selectedNode, value)
    }
  }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
)(SubtitlesButton)