import { isRef, model, parseRef } from 'beanie-engine-api-js'
import React, { useCallback, useMemo } from 'react'
import { Icon, Tooltip } from 'antd'
import classNames from 'classnames'
import * as jsondiffpatch from 'jsondiffpatch'
import { pipe } from 'ramda'
import { useDispatch } from 'react-redux'

import Diff from 'text-diff'

import arrayDiff from 'utils/arrayDiff'
import { isComplexObject, isArray, EMPTY_ARRAY } from 'utils/object'
import { truncate } from 'utils/string'
import { selectNode } from '../../../actions/nodes'
import useObject from '../../../hooks/beanie/useObject'
import { isNilOrEmpty } from '../../../utils/ramda'
import LinkButton from '../../Commons/LinkButton'

import styles from '../Change.scss'

const { types: { metadata: { isTimestampProp } } } = model

/**
 *
 */
const UpdatedChange = ({ change: { changes }, showEmpties, showTimestamps }) => {
  const visibleChanges = changes.length > 0 && changes
    .filter(({ prev, next }) => showEmpties || prev === undefined || !isNilOrEmpty(next))
    .filter(({ field }) => showTimestamps || !isTimestampProp(field))

  return changes.length > 0 ? (
    <div>
      {visibleChanges === 0 ?
        <span>no changes</span>
        : (
          <table className={styles.updateChanges}>
            <tbody>
              {visibleChanges.map(renderChange)}
            </tbody>
          </table>
        )
      }
    </div>
  ) : <span className={styles.filteredLabel}>&#45; filtered &#45;</span>
}

const renderChange = c => (
  <tr key={c.field}>
    <td>{c.field}</td>
    <td>{renderValue(c.field, c.prev, c.next)}</td>
  </tr>
)

//
// values
//

const isTextField = t => ['data.name', 'data.text'].includes(t)
const textDiff = new Diff()
const renderTextDiff = pipe(::textDiff.main, ::textDiff.prettyHtml);

const jsonDiff = jsondiffpatch.create({})

/* eslint react/no-danger: 0 */
const renderValue = (field, prev, next) => {
  if (isArray(prev) || isArray(next)) {
    return renderArrayChange(prev, next)
  } else if (isTextField(field)) {
    return (
      <Tooltip title={`"${prev}" -> "${next}"`}>
        <div className={styles.textDiff} dangerouslySetInnerHTML={{ __html: renderTextDiff(prev || '', next || '') }} />
      </Tooltip>
    )
  } else if (isComplexObject(prev) || isComplexObject(next)) {
    const delta = jsonDiff.diff(prev, next)
    return (
      <div dangerouslySetInnerHTML={{ __html: jsondiffpatch.formatters.html.format(delta, prev) }} />
    )
  } else {
    return (
      <div>
        <FieldValue field={field} value={prev} /> <Icon type="arrow-right" /> <FieldValue field={field} value={next} />
      </div>
    )
  }
}

const renderArrayChange = (prev, next) => {
  const diff = arrayDiff(prev || EMPTY_ARRAY, next || EMPTY_ARRAY)
  return (
    <div className={styles.arrayChange}>
      <div className={styles.leftBracket} />
      {diff.map(({ state, value }, i) => (
        <div key={i} className={classNames(styles.element, styles[state])}>
          <Tooltip title={JSON.stringify(value, null, 2)}>
            {truncate(JSON.stringify(value), 15)}
          </Tooltip>
        </div>
      ))}
      <div className={styles.rightBracket} />
    </div>
  )
}

const UNDEFINED = (<span className={styles.value_undefined}>undefined</span>)

const FieldValue = ({ value }) => {
  if (value && isRef(value)) {
    return (
      <NodeRef reference={value} />
    )
  }
  return value ? JSON.stringify(value) : UNDEFINED
}

const NodeRef = ({ reference }) => {
  const id = useMemo(() => parseRef(reference), [reference])
  const object = useObject(id)

  const dispatch = useDispatch()
  const goTo = useCallback(() => {
    dispatch(selectNode(object, false))
  })

  return object ? (
    <LinkButton onClick={goTo}>{id}</LinkButton>
  ) : (
    <span>{id}</span>
  )
}

export default UpdatedChange