import { pipe, reduce, prop, map, keys, last, dropLast, nth, mergeDeepRight, flatten } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

// TODO: support special cases: schema, commands, queries
const nonFunctionsFields = ['schema', 'commands', 'queries']

const composePlugins = (...plugins) => {
  const fields = flatten(map(keys)(plugins))
  return composeHandlers(fields, plugins)
}

const composeHandlers = (fields, plugins) => reduce(composeHandler(plugins), {})(fields)
const composeHandler = plugins => (result, field) => {
  const handlingPlugins = plugins.filter(pipe(prop(field), isNotNil))
  result[field] = composeHandlerValues(field, handlingPlugins.map(prop(field)))
  return result
}

const composeHandlerValues = (field, values) => (
  (nonFunctionsFields.includes(field) ? composeHandlerObject : composeHandlerFunction)(values)
)

const composeHandlerObject = values => reduce(mergeDeepRight, {})(values)

const composeHandlerFunction = fns => (...args) => {
  const params = dropLast(2, args)
  const editor = nth(-2, args)
  const originalNext = last(args)
  
  let i = 0
  const next = (...overrides) => {
    const fn = fns[i++]
    const _params = overrides.length ? overrides : params 
    return fn(..._params, editor, i === fns.length ? originalNext : next)
  }
  return next()
}

export default composePlugins