import React from 'react'
import { Icon as AntdIcon } from 'antd'
import { getMetadataForSys } from './metadata/UIMetadata'

const UNKNOWN = { label: 'Unknown', icon: 'file-unknown', color: 'black' }

export const getProviderFor = sys => {
  const provider = getMetadataForSys(sys)
  return (provider && provider.icon) ? provider : UNKNOWN
}

export const Icon = ({ sys }) => (
  <AntdIcon
    type={getMetadataForSys(sys).icon}
  />
)