

const UnrealCommandDefinition = {

  OpenSequence: {
    name: 'studio-unreal/open_sequence',
    // params: NodeId | Array[NodeId] where  NodeId = String, and Node.sys = clip
  },

  GenerateSequence: {
    name: 'studio-unreal/generate_sequence',
    // params: NodeId | Array[NodeId] where  NodeId = String, and Node.sys = clip
  },

  PlayNode: {
    name: 'studio-unreal/play_node',
    // params: String ( NodeId )
  },

  SetEngineState: {
    name: 'studio-unreal/set_engine_state',
    // params: { state: JSON } (engine state export)
  }

}

export default UnrealCommandDefinition