import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import { compose } from 'recompose'
import { DropTarget, DragSource } from 'react-dnd'
import classNames from 'classnames'

import styles from './styles.scss'

const Item = 'item'

class ListItem extends Component {
  render() {
    const {
      value,
      renderItem,
      connectDragSource,
      connectDropTarget,
      isDragging
    } = this.props

    return connectDragSource(
      connectDropTarget(
        <div className={classNames(styles.sortableListItem, {
          [styles.dragging]: isDragging
        })}>
          {renderItem(value)}
        </div>
      )
    )
  }
}

const cardSource = {
  beginDrag({ id, index, }) {
    return {
      id,
      index,
    }
  },
}

const collectDragSource = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})

const cardTarget = {

  hover(props, monitor, component) {
    if (!component) {
      return null
    }

    const { index, moveItem } = props

    const dragIndex = monitor.getItem().index
    const hoverIndex = index
    if (dragIndex === hoverIndex) {
      return null
    }
    // eslint-disable-next-line react/no-find-dom-node
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect()
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    const clientOffset = monitor.getClientOffset()
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return null
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return null
    }
    moveItem(dragIndex, hoverIndex)
    monitor.getItem().index = hoverIndex
  },

}

const collectDropTarget = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
})

const withSource = DragSource(
  Item,
  cardSource,
  collectDragSource
)

const withTarget = DropTarget(
  Item,
  cardTarget,
  collectDropTarget
)

export default compose(
  withSource,
  withTarget
)(ListItem)
