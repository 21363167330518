import React from 'react'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'

import { currentActors as currentActorsSelector, currentlySpeakingActor as currentlySpeakingActorSelector } from 'selectors/playback'
import { defaultActor as defaultActorSelector } from 'selectors/objects'

import { ActorIcon } from 'components/Commons/ActorAvatar'

import styles from './ActorsInScene.scss'

export const ActorsInScene = ({ actors, defaultActor, activeActorId }) => (
  <div className={styles.container}>
    {actors
      .filter(a => !defaultActor || a.id !== defaultActor.id)
      .map(actor => (
        <Tooltip key={actor.id} title={actor.data.actorName}>
          <ActorIcon
            actor={actor}
            size="default"
            label={false}
            active={actor.id === activeActorId}
          />
        </Tooltip>
      )
    )}
  </div>
)

export default connect(state => ({
  actors: currentActorsSelector(state),
  defaultActor: defaultActorSelector(state),
  activeActorId: currentlySpeakingActorSelector(state)
}))(ActorsInScene)