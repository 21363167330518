import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Modal, Icon } from 'antd'

import { Creators } from 'actions/vm'
import { playNode as playAudioAction } from 'actions/audio'

import ErrorPlayingLine from './ErrorPlayingLine/ErrorPlayingLine'

const { stopPlayback: stopPlaybackAction } = Creators

/**
 * Play a line as a component. Nothing visual just controlling audio sound
 */
const PlayCurrentLine = ({ node, langRes, actor, onPlayed }) => {
  const dispatch = useDispatch()
  const play = useCallback((...args) => dispatch(playAudioAction(...args)), [dispatch])
  const stop = useCallback(() => dispatch(stopPlaybackAction()), [dispatch])

  // do play
  useEffect(() => {
    const doIt = async () => {
      try {
        await play(actor, node, langRes)
        onPlayed(node)
      } catch (err) {
        /* eslint no-console: 0 */
        console.error(err)
        Modal.error({
          icon: <Icon type="warning" />,
          title: 'Oops',
          content: <ErrorPlayingLine clipId={node.nodeId} langRes={langRes} />,
        })
        await stop()
      }
    }

    doIt()
  }, [node.playbackId, langRes.id])

  return null
}

export default PlayCurrentLine
