import { pipe, prop, propEq, find, ifElse } from 'ramda'
import { NODE_TYPES } from './Constants'
import { propEqIgnoreCase } from 'utils/string'
import { ALL } from 'preferences/Preferences'
import { hasParams } from './plugins/MarkUpCreatorPlugin/queries'
import { isCreating } from './hiddenNodeUtils'

export const NODE_TYPES_ALLOWED_TO_MARKUP = [
  NODE_TYPES.TEXT_PART,
  NODE_TYPES.DIRECTOR_LINE
]

const formatParam = param => (param.text === '' ? '' : `${paramKey(param).text}: ${paramValue(param).text} `)
const formatMarkupWithParams = markup => `${markupName(markup)} ${markUpParams(markup).nodes.map(formatParam).join('')}`
const formatSimpleMarkup = markup => `${markupName(markup)}`
export const formatMarkup = markUp => ifElse(hasParams, formatMarkupWithParams, formatSimpleMarkup)(markUp)

export const nodeByType = type => pipe(
  prop('nodes'),
  find(propEq('type', type)),
)

export const markupNameNode = markup => (hasParams(markup) ? pipe(
  nodeByType(NODE_TYPES.MARK_UP_WITH_PARAMS),
  nodeByType(NODE_TYPES.MARK_UP_NAME)
) : nodeByType(NODE_TYPES.MARK_UP_NAME))(markup)

export const markUpParams = pipe(
  nodeByType(NODE_TYPES.MARK_UP_WITH_PARAMS),
  nodeByType(NODE_TYPES.MARK_UP_PARAMETERS)
)

export const markupName = pipe(markupNameNode, prop('text'))

export const isVisible = (visibleMarkups, markup) => 
  isCreating(markup) ||
  !visibleMarkups ||
  visibleMarkups === ALL ||
  !markup ||
  visibleMarkups?.some?.(propEqIgnoreCase('name', markupName(markup)))

export const paramKey = nodeByType(NODE_TYPES.MARK_UP_PARAMETER_KEY)
export const paramValue = nodeByType(NODE_TYPES.MARK_UP_PARAMETER_VALUE)
