import memoize from 'memoize-state'
import { createSelector } from 'selectors/reselect'
import { Sys, isContained, isContainer, parseRef, model, ref } from 'beanie-engine-api-js'
import {
  init,
  pipe,
  map,
  prop,
  flip,
  head,
  tail,
  nth,
  applySpec,
  dropLast,
  last,
  anyPass,
  filter,
  pathEq
} from 'ramda'
import { EMPTY_ARRAY, EMPTY_OBJECT, isEmptyObject, propFrom } from 'utils/object'


import { propFromProps } from 'selectors/props'
import { objectsIndex } from 'selectors/apollo'
import { objectsBySys, lanesFromProject } from 'selectors/objects'
import { selectedNode } from 'selectors/nodeSelection'

const { types: {
  object: { Paths },
  container: { hasComponent },
  node: { parentId },
  clip: { hasLineWithId: clipHasLine },
  choice: { hasLineWithId: choiceHasLine },
} } = model

export const createPathTo = id => state => pathTo(objectsIndex(state), objectsBySys(state))(id)

// this was tested at actions/view.spec
export const createExpandPathTo = id => state => {
  const pathToNode = createPathTo(id)(state)

  const getNode = flip(prop)(objectsIndex(state))
  const ifIsContainer = pipe(getNode, isContainer)
  const ifIsContained = pipe(getNode, isContained)
  // remove items which are container and are followed by a contained and the last item
  // we don't want to expand those. but don't remove if we are the first item (means a root container)
  // we need that to get the lane later !
  return dropLast(1, pathToNode.filter((nid, idx) => {
    const nextId = pathToNode[idx + 1]
    return idx === 0 || !(nextId && ifIsContained(nextId) && ifIsContainer(nid))
  }))
}

export const laneOf = lanes => root => lanes.find(lane => lane.roots.includes(root?.id))

export const prependLaneToPath = aPath => state => {
  const id = head(aPath)
  const lane = getLaneOfRoot(id)(state)
  return lane ? [lane, ...aPath] : aPath
}


export const makePathSelector = idFromPropSelector => createSelector(
  [idFromPropSelector, objectsIndex, objectsBySys],
  (id, index, sys) => pathTo(index, sys)(id)
)

export const pathToSelected = makePathSelector(selectedNode)

const pathTo = (index, indexBySys) => id => {
  const o = index[id]
  const parent = o && parentFor(o, indexBySys)
  return [
    ...(parent ? pathTo(index, indexBySys)(parent) : EMPTY_ARRAY),
    id
  ]
}

const lookupLineContainer = ({ id }, findInSys) => {
  const clip = findInSys(Sys.clip, clipHasLine(id))
  return clip || findInSys(Sys.choice, choiceHasLine(id))
}

const lookupInChoices2 = (o, choices) => choices.find(pathEq(Paths.choices2.idle, ref(o.id)))
const lookupInTruthTable = (o, truthTable) => truthTable.find(pathEq(Paths.truth_table.otherwise, ref(o.id)))

// TODO: this should be done generic using the schema ! <- +1
const lookupReferencingNode = (o, indexBySys) => (
  lookupInChoices2(o, Object.values(indexBySys[Sys.choices2] || EMPTY_OBJECT))?.id
  ||
  lookupInTruthTable(o, Object.values(indexBySys[Sys.truth_table] || EMPTY_OBJECT))?.id
)

export const parentFor = (o, indexBySys) => {

  const findInSys = (sys, pred) => {
    const containers = Object.values(indexBySys[sys] || EMPTY_OBJECT)
    const container = containers.find(pred)
    return container && container.id
  }
  const lookupContainerOf = sys => findInSys(sys, hasComponent(o))

  switch (o.sys) {
    case Sys.choice: return lookupContainerOf(Sys.choices) || lookupContainerOf(Sys.choices2)
    case Sys.sequence: return lookupContainerOf(Sys.sequencer)
    // TODO: now conditions can appear in other places as logic rules :S
    case Sys.condition: return lookupContainerOf(Sys.conditional)
    case Sys.folder_item: return lookupContainerOf(Sys.folder)
    case Sys.task_item: return lookupContainerOf(Sys.task_container)
    //
    case Sys.line: return lookupLineContainer(o, findInSys)
    case Sys.truth_table_row: return lookupContainerOf(Sys.truth_table)
    default: return parentId(o) || lookupReferencingNode(o, indexBySys)
  }
}

const chainSelectorMaker = (selectedSelector, name) => createSelector(`chain-${name}`,
  [selectedSelector, objectsIndex, objectsBySys],
  (id, index, sys) => {
    return id && !isEmptyObject(index) ? pipe(
      pathTo(index, sys),
      head,
      collectChain(index)
    )(id) : EMPTY_ARRAY
  }
)

// chain for selected node
export const currentChain = chainSelectorMaker(selectedNode, 'for-selected')
// chain for any node (based on props, receives the name of the prop to get the id from)
export const makeChainForObjectSelector = pipe(propFromProps, chainSelectorMaker)
makeChainForObjectSelector.noDebug = true

export const collectChain = index => id => {
  const obj = propFrom(index, id)
  const childId = model.types.node.getChildId(obj)
  return [
    id,
    ...childId ? collectChain(index)(childId) : EMPTY_ARRAY
  ]
}

export const makeSubchainSelector = () => createSelector('subchain',
  [propFromProps('object'), objectsIndex],
  (object, index) => pipe(
    prop('id'),
    collectChain(index),
    tail
  )(object)
)
makeSubchainSelector.noDebug = true

const filterNonDecisionNodes = filter(anyPass([isContainer, isContained]))
const pathToDecisionPath = (includeSelf = false) => (aPath) => {
  if (aPath.length === 1) return EMPTY_ARRAY
  const first = head(aPath)
  const rest = tail(aPath)
  const middle = init(rest)
  const theLast = last(rest)

  return [
    first,
    ...filterNonDecisionNodes(middle),
    ...includeSelf ? [theLast] : EMPTY_ARRAY
  ]
}


export const makeChoicesFromChoice = getIdFromProps => {
  const parentFrom = memoize(({ objectId, objectsIndexed, objectsIndexedBySys }) => {
    const node = objectsIndexed[objectId]

    if (!model.types.object.isChoice(node)) return

    const parent = parentFor(node, objectsIndexedBySys)
    return prop(parseRef(parent), objectsIndexed)
  })

  const selector = (state, props) => parentFrom({
    objectId: getIdFromProps(state, props),
    objectsIndexed: objectsIndex(state),
    objectsIndexedBySys: objectsBySys(state)
  })

  selector.memoizingSelector = parentFrom

  return selector
}

export const makeDecisionPathSelector = (idFromPropSelector, includeSelf) => createSelector(
  [makePathSelector(idFromPropSelector), objectsIndex, lanesFromProject],
  (aPath, index, lanes) => pipe(
    map(propFrom(index)),
    applySpec({
      lane: pipe(nth(0), laneOf(lanes)),
      path: pathToDecisionPath(includeSelf),
      node: last
    })
  )(aPath)
)


/**
 * Pure non-memoizing function. Util to use from a memoize selector
 * and avoid screwing reselect memoization!
 */
export const rootOf = (index, indexBySys, id) => {
  const obj = index[id]
  const theParentId = obj && parentFor(obj, indexBySys)
  return theParentId ? rootOf(index, indexBySys, theParentId) : obj
}

export const getLaneOfNode = id => state => {
  const aPath = createPathTo(id)(state)
  return aPath.length > 0 ? getLaneOfRoot(head(aPath))(state) : undefined
}

const getLaneOfRoot = id => state => laneOf(lanesFromProject(state))({ id })?.name
