import { createSelector } from 'selectors/reselect'
import { path, equals } from 'ramda'
import { Status } from 'model/network'

export const status = path(['network', 'status'])

const is = s => createSelector(`networkIs-${s}`, status, equals(s))
export const isDisconnected = is(Status.disconnected)
export const isConnected = is(Status.connected)
export const isConnecting = is(Status.connecting)
export const isReconnecting = is(Status.reconnecting)