import React from 'react'
import EditableValue from './EditableValue'
import { InputNumber } from 'antd'

import styles from './NumberValue.scss'

export class NumberValue extends EditableValue {

  handleChange(number) {
    this.setState({ value: number })
  }

  unit() {
    const { unit } = this.props
    return unit || ''
  }

  renderForEditing() {
    const { value } = this.state
    return (
      <div>
        <InputNumber
          size="small"
          value={value}
          onChange={::this.handleChange}
          defaultValue="0"
        />
        <span className={styles.unit}>{this.unit()}</span>
      </div>
    )
  }

  renderForDisplaying() {
    return <span>{`${this.state.value} ${this.unit()}`}</span>
  }

}

const numberValueFactory = (unit = '') => props => {
  return <NumberValue {...props} unit={unit} />
}
export default numberValueFactory
