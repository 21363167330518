import {
  pathOr,
  toLower,
  uniq,
  mapObjIndexed,
  groupBy,
  pipe,
  map,
  filter,
  zipObj,
  repeat,
  merge,
  path,
  sortBy,
  prop,
  toUpper,
  not,
  compose,
  assoc,
  find,
  propOr,
  equals,
  concat,
  mergeDeepWith,
  keys
} from 'ramda'
import { renameKeysWith, isNotNil } from 'ramda-adjunct'

import { properties, isEmptyObject, EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'
import { propEqIgnoreCase } from 'utils/string'

// selectors deps
import { actions, actions2, projectObject, selectedObject } from './objects'
import { createSelector } from 'selectors/reselect'
import { model, lang } from 'beanie-engine-api-js'

import { objectsIndex } from './apollo'

const { types: { object: { Paths } } } = model
const { rule: { utils: { isVariableAssignmentEffect } } } = lang

const safeToLower = s => s && toLower(s)

// all events including threading (BNE mixes threading state events with object state events)
export const events = pathOr(EMPTY_ARRAY, 'vm.playback.state.events'.split('.'))

export const objectEvents = createSelector('objectEvents',
  [events, objectsIndex],
  (allEvents, index) => allEvents.filter(e => !!index[e.nodeId])
)

const loweringVariables = (name, baseSelector) => createSelector(name, [baseSelector], mapObjIndexed(renameKeysWith(toLower)))

export const _current = pathOr(EMPTY_OBJECT, ['vm', 'playback', 'state', 'current'])
export const current = loweringVariables('state-current', _current)
export const isCurrentStateEmpty = createSelector('isCurrentStateEmpty',
  [_current],
  equals(EMPTY_OBJECT)
)

// the initial stat for the current play-through
export const initialState = pathOr(EMPTY_OBJECT, ['vm', 'playback', 'initial'])

const _dirty = pathOr(EMPTY_OBJECT, ['vm', 'playback', 'state', 'currentDirty'])
export const dirty = loweringVariables('state-dirty', _dirty)

export const isVariableAssignment = pipe(
  path(Paths.action2.effect),
  isVariableAssignmentEffect
)

//
// variables
//

const ASSIGNED_VALUE_PATH = [...Paths.action2.effect, 'id', 'value']

export const getAssignedVariable = path(ASSIGNED_VALUE_PATH)

export const staticAction2VariablesAssigments = createSelector('state-action2-staticVariablesAssignments',
  actions2,
  pipe(
    filter(isVariableAssignment),
    groupBy(pipe(
      getAssignedVariable,
      safeToLower
    ))
  )
)

export const staticActionVariablesAssigments = createSelector('state-action-staticVariablesAssignments',
  actions,
  groupBy(pipe(path(['data', 'state_key']), safeToLower)),
)

export const staticVariablesAssigments = createSelector('staticVariablesAssignments',
  [staticActionVariablesAssigments, staticAction2VariablesAssigments],
  mergeDeepWith(concat)
)

export const makeStaticVariableMutationsSelector = variablePropSelector => createSelector(
  [variablePropSelector, staticVariablesAssigments],
  (variableName, assignments) => {
    if (!variableName) return EMPTY_ARRAY
    const actionNodes = assignments[variableName.toLowerCase()] || EMPTY_ARRAY
    return groupBy(path(['data', 'mode']))(actionNodes)
  }
)

export const staticVariablesPossibleValues = createSelector('state-staticVariablesPossibleValues',
  staticActionVariablesAssigments,
  mapObjIndexed(pipe(
    map(path(['data', 'state_value'])),
    uniq
  ))
)

export const staticVariables = createSelector('state-staticVariables',
  staticVariablesAssigments,
  pipe(
    keys,
    filter(isNotNil),
    uniq
  )
)

export const staticState = createSelector('state-staticState',
  staticVariables,
  names => zipObj(names, repeat(null, names.length))
)

export const runtimeProjectState = createSelector('state-runtimeProjectState',
  projectObject, current,
  (project, perNodeStates) => (project ? perNodeStates[project.id] : EMPTY_OBJECT)
)

export const runtimeProjectVariable = (varName, state) => {
  const projectId = prop('id', projectObject(state))
  return path([projectId, toLower(varName)], _current(state))
}

export const projectCurrentState = createSelector('state-projectCurrentState',
  staticState, runtimeProjectState,
  merge
)

const sortedProperties = pipe(properties, sortBy(pipe(prop('name'), toUpper)))

export const projectCurrentStateList = createSelector('state-projectCurrentStateList',
  projectCurrentState,
  sortedProperties,
)

const VariableKind = {
  static: 'static',
  dynamic: 'dynamic'
}

export const allVariables = createSelector('state-allVariables',
  staticVariablesPossibleValues, projectCurrentStateList,
  (statics, allVariablesState) =>
    map(v => assoc('kind', statics[v.name] ? VariableKind.static : VariableKind.dynamic, v), allVariablesState)
)

export const variableValue = varName => createSelector('state-variable-value',
  allVariables,
  pipe(find(propEqIgnoreCase('name', varName)), propOr(undefined, 'value'))
)

const indexOrEmpty = (node, index) => (node ? index[node.id] || {} : {})

export const selectedNodeState = createSelector('state-selectedNodeState', [selectedObject, current], indexOrEmpty)

export const selectedNodeStateList = createSelector('state-selectedNodeStateList',
  [selectedNodeState],
  sortedProperties
)

export const isProjectVariableDirty = createSelector('state-isProjectVariableDirty',
  [projectObject, dirty],
  indexOrEmpty
)

export const isSelectedNodeVariableDirty = createSelector('state-isSelectedNodeVariableDirty',
  [selectedObject, dirty],
  indexOrEmpty
)

export const isDirty = createSelector('state-isDirty', [dirty], compose(not, isEmptyObject))

export const currentNodeVariableValue = (nodeId, varName) => createSelector('current-node-variable-value',
  [current],
  path([nodeId, varName])
)

//
// state presets (savegames)
//

export const currentSaveGame = path('vm.playback.state.savegame'.split('.'))

export const canSaveAsSaveGame = createSelector(
  'state-canSaveAsSaveGame',
  [currentSaveGame],
  (_currentSaveGame) => _currentSaveGame && !isEmptyObject(_currentSaveGame)
)

export const canDeleteSaveGame = createSelector(
  'state-canDeleteSaveGame',
  [isDirty, canSaveAsSaveGame],
  (_isDirty, _canSaveAsSaveGame) => !_isDirty && _canSaveAsSaveGame
)
