exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3s1Bu{color:#add8e6;cursor:pointer}.iconAsLink__3s1Bu:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3RvAq{font-style:italic}.formFieldsVerticalGutter__3qM1i{padding:.5rem}.formFieldsVerticalGutter__3qM1i>*{margin:.5rem !important}.tableBackground__83aAV .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__83aAV .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2qcAq .ant-notification-notice-close{display:none}.NotificationsList__D5qCo{overflow-y:scroll;padding-left:.5rem;padding-right:.5rem;padding-top:.5rem}.NotificationsList__D5qCo .spinner__3NPAA{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:center;justify-content:center}.noContentDark__1RNh2{-ms-flex-pack:center;justify-content:center}.noContentDark__1RNh2 .message__37Ht2{background-color:#273749;color:#d2d2d2;border-color:silver}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3s1Bu",
	"labelFromRef": "labelFromRef__3RvAq",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3qM1i",
	"tableBackground": "tableBackground__83aAV",
	"notificationNotClosable": "notificationNotClosable__2qcAq",
	"NotificationsList": "NotificationsList__D5qCo",
	"spinner": "spinner__3NPAA",
	"noContentDark": "noContentDark__1RNh2",
	"message": "message__37Ht2"
};