import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { isEnabled as isEnabledSelector, getDebuggableContainerContentId } from 'selectors/debuggingData'
import { Tag } from 'antd'
import { makeChoiceLangResourceSelector } from 'selectors/choices'

import styles from './ChoicesPlayback.scss'

const textOrIndex = (langRes, idx) => langRes?.data?.text || `[${idx + 1}]`

/**
 * A choice in the History/PianoRoll view
 */
const ChoicePlayback = ({ choices, choiceId, color, index }) => {
  const langResSelector = useMemo(() => makeChoiceLangResourceSelector(choiceId), [choiceId])
  const langRes = useSelector(langResSelector)

  const firstPinnedChoiceIdSelector = useMemo(() => getDebuggableContainerContentId(choices.nodeId), [choices.nodeId])
  const firstPinnedChoiceId = useSelector(firstPinnedChoiceIdSelector)
  const isEnabledDebugMode = useSelector(isEnabledSelector)

  const choiceIsTheFirstPinned = isEnabledDebugMode &&
    firstPinnedChoiceId &&
    choiceId === firstPinnedChoiceId

  return (
    <li
      className={classNames(styles.choice)}>
      <div className={styles.choiceContainer}>
        <Tag
          className={classNames({
            [styles.pinnedChoice]: choiceIsTheFirstPinned
          })}
          color={color}>{textOrIndex(langRes, index)}</Tag>
        {choiceIsTheFirstPinned && <span className={styles.pinnedLabel}>pinned</span>}
      </div>
    </li>
  )
}

export default ChoicePlayback