import React from 'react'
import { Checkbox, Input } from 'antd'
import { withTargetValue } from '../../../utils/antd'

import styles from './ChangesFilter.scss'

const ChangesFilter = ({ searchText, setSearchText, onSearch, showEmpties, setShowEmpties, showTimestamps, setShowTimestamps }) => (
  <div className={styles.ChangesFilter}>
    <Input placeholder="Filter by id" value={searchText} onChange={withTargetValue(setSearchText)} onPressEnter={onSearch} />
    <Checkbox checked={showEmpties} onChange={e => setShowEmpties(e.target.checked)}>Show Empty</Checkbox>
    <Checkbox checked={showTimestamps} onChange={e => setShowTimestamps(e.target.checked)}>Show Timestamps</Checkbox>
  </div>
)

export default ChangesFilter