exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".listContainer__opmZY{height:20rem;overflow:auto}.listContainer__opmZY .list__25g6T{padding-right:2rem}.listContainer__opmZY .list__25g6T .ant-list-item{padding-top:.2rem;padding-bottom:.2rem}.listContainer__opmZY .list__25g6T .ant-list-item-meta{-ms-flex-align:center !important;align-items:center !important}.content__gy7pO.collapsed__1EqB7 .ant-avatar:not(:first-child){margin-left:-1.2rem}.content__gy7pO:not(.collapsed__1EqB7) .ant-avatar:not(:first-child){margin-left:.2rem}", ""]);

// exports
exports.locals = {
	"listContainer": "listContainer__opmZY",
	"list": "list__25g6T",
	"content": "content__gy7pO",
	"collapsed": "collapsed__1EqB7"
};