import React from 'react'
import { intersperse } from 'utils/object'

import PropertyValue from 'components/PropertiesEditor/Value/PropertyValue'
import styles from './ArrayValue.scss'

const ArrayValue = ({ value, path, onNavigateRef, onValueChanged, customValueProvider }) => (
  <div className={styles.arrayValue}>
    [{
      intersperse(
        value.map((child, i) => (
          <PropertyValue
            key={i}
            path={`${path}.${i}`}
            value={child}
            onNavigateRef={onNavigateRef}
            onPropertyChanged={onValueChanged}
            isEditable={false}
            customValueProvider={customValueProvider}
          />
        )),
        ', '
      )
    }]
  </div>
)

export default ArrayValue