import React from 'react'
import classNames from 'classnames'
import { Tooltip } from 'antd'

import switchComponent from 'hocs/switchComponent'
import { withProps } from 'recompose'

import { ForkingModelNodeType } from 'selectors/objects/paths/makeForkingDecisionPathSelector'

import _NodeText from 'components/Commons/NodeText'

import styles from './PathElement.scss'

export const MAX_TEXT_LENGTH = 15

const NodeText = withProps({ truncateTextAt: MAX_TEXT_LENGTH })(_NodeText)

const NodePathElement = ({ element: { node }, onNodeSelected, tooltip = true, label = false }) => {
  const content = (
    <div className={classNames(styles.element, styles[node.sys], { [styles.withLabel]: label })} onClick={() => onNodeSelected(node.id)}>
      {label && <NodeText node={node} />}
    </div>
  )
  
  return tooltip ? (
    <Tooltip title={<NodeText node={node} />}>
      {content}
    </Tooltip>
  ) : content
}

const ContainerTooltip = ({ node, branch }) => (
  <div>
    <NodeText key="container" node={node} /> &#47; <NodeText key="element" node={branch} />
  </div>
)
const ShortForkPathElement = ({ element: { node, branch }, onNodeSelected }) => (
  <Tooltip title={<ContainerTooltip node={node} branch={branch} />}>
    <div className={classNames(styles.fork, styles[node.sys])}>
      <div className={styles.branch}>
        <NodePathElement element={{ node: branch }} onNodeSelected={onNodeSelected} tooltip={false} />
      </div>
      <NodePathElement element={{ node }} onNodeSelected={onNodeSelected} tooltip={false} />
    </div>
  </Tooltip>
)

const ForkElement = ({ element: { node }, onNodeSelected, label = false, type }) => (
  <div className={classNames(styles.element, styles[node.sys], { [styles.withLabel]: label }, styles[type])} onClick={() => onNodeSelected(node.id)}>
    {label && <NodeText node={node} />}
  </div>
)

const FullShortPathElement = ({ element: { node, branch }, onNodeSelected, label }) => (
  <div className={classNames(styles.fullFork, styles[node.sys])}>
    <ForkElement element={{ node }} onNodeSelected={onNodeSelected} label={label} type="container" />
    <ForkElement element={{ node: branch }} onNodeSelected={onNodeSelected} label={label} type="element" />
  </div>
)

const ForkPathElement = props => {
  const E = props.containers ? FullShortPathElement : ShortForkPathElement
  return <E {...props} />
}

export default switchComponent({
  path: ['element', 'type'],
  cases: {
    [ForkingModelNodeType.node]: NodePathElement,
    [ForkingModelNodeType.fork]: ForkPathElement,
  },
  otherwise: NodePathElement
})

