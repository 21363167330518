import { compose, lifecycle, withState, branch, renderComponent } from 'recompose'
import { pipe, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import TreeErrorElement from './TreeErrorElement'
import sentry from 'services/sentry'

const TreeElementErrorHandler = compose(
  withState('error', 'setError'),
  lifecycle({
    componentDidCatch(error, errorInfo) {
      const { setError } = this.props
      setError({ error, errorInfo })
      sentry.handleError(error, errorInfo)
    }
  }),
  branch(
    pipe(prop('error'), isNotNil),
    renderComponent(TreeErrorElement),
  )
)

export default TreeElementErrorHandler

