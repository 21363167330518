import { pipe, mergeDeepLeft, over, lensProp } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { NODE_TYPES } from '../../Constants'
import { shouldOpen, shouldClose, distanceToNextCloseDelimiter, distanceToNextOpenDelimiter } from '../NoteWithDelimiters'
import { emptyMarkUp, markUpWithParams } from '../../slateMocks/markUp'
import { hasParams, lastMarkUpKey, markUpWithNewParam, firstMarkUpKey, isLastValue, parameterByIndex, parameterIndexByNode } from './queries'
import { findDescendentOfType } from '../../nodeUtils'
import { markupNameNode } from '../../markupUtils'
import { textToMarkup } from 'components/SlateTextEditor/textToFragment/textToFragment'
import { setCreatingState } from '../HiddenNodesPlugin'

const { types: { markup: { DELIM_END, DELIM_START } } } = model

export const isInMarkup = (editor, node) => !!(editor.parentOfType(NODE_TYPES.MARK_UP, node))

export const addParameter = (event, editor) => {
  event.preventDefault()
  event.stopPropagation()
  const markUp = editor.currentMarkUp();
  ((hasParams(markUp)) ? createrNewParameter : createNewParameters)(editor, markUp)
  return false
}

export const newMark = editor => {
  editor.insertInline(pipe(emptyMarkUp, setCreatingState)())
  editor.moveToStartOfPreviousText()
  return false
}

export const openNote = (event, editor, currentText, offset) => {
  event.preventDefault()
  event.stopPropagation()
  return (shouldOpen(currentText.text, offset, { openDelimiter: DELIM_START, closeDelimiter: DELIM_END }) ?
    reconstructMarkToRight : newMark)(editor, currentText, offset)
}

export const closeNote = (event, editor, currentText, offset) => {
  event.preventDefault()
  event.stopPropagation()
  editor.insertText(DELIM_END)
  return shouldClose(currentText.text, offset, { openDelimiter: DELIM_START, closeDelimiter: DELIM_END }) ?
    reconstructMarkToLeft(editor, currentText, offset) : false
}

const createrNewParameter = (editor, markUp) => {
  const path = editor.pathByKey(markUp.key)
  editor.replaceBlockByKey(markUp.key, pipe(markUpWithNewParam(editor), copyData(markUp), setCreatingState)(markUp))
  editor.moveToEndOfNode(lastMarkUpKey(editor, path))
}

const copyData = node => over(lensProp('data'), mergeDeepLeft(node.data.toJS()))

const createNewParameters = (editor, markUp) => {
  const { text } = editor.getInlinesByTypeRecursively(markUp, NODE_TYPES.MARK_UP_NAME).first()
  const path = editor.pathByKey(markUp.key)
  editor.replaceBlockByKey(markUp.key, pipe(markUpWithParams, copyData(markUp), setCreatingState)(text))
  editor.moveToEndOfNode(firstMarkUpKey(editor, path))
}

const moveAndSelectNextParamKey = (editor, node, markup) => {
  if (!hasParams(markup) || isLastValue(editor, markup, node.key)) {
    editor.moveToEndOfNode(node)
  } else {
    const currentIndex = parameterIndexByNode(editor, markup, node)
    const nextParam = parameterByIndex(markup, currentIndex + 1)
    const paramKeyNode = findDescendentOfType(NODE_TYPES.MARK_UP_PARAMETER_KEY)(nextParam)
    editor.selectCompleteNode(paramKeyNode)
  }
}

const moveAndSelectNextParamKeyFromListItem = (editor, node, markup) => {
  const value = editor.findAncestorOfType(node, NODE_TYPES.MARK_UP_PARAMETER_VALUE)
  return moveAndSelectNextParamKey(editor, value, markup)
}

const moveAndSelectNextParamValue = (editor, node, markup) => {
  const param = parameterByIndex(markup, parameterIndexByNode(editor, markup, node))
  const paramValueNode = findDescendentOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE)(param)
  editor.selectCompleteNode(paramValueNode)
}

const moveAndSelectPreviousParamValueOrMarkupName = (editor, node, markup) => {
  const paramIndex = parameterIndexByNode(editor, markup, node)

  const newNode = (paramIndex <= 0) ? markupNameNode(markup) :
    findDescendentOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE)(parameterByIndex(markup, paramIndex - 1))

  editor.selectCompleteNode(newNode)
}

const moveAndSelectPreviousParamKey = (editor, node, markup) => {
  const param = parameterByIndex(markup, parameterIndexByNode(editor, markup, node))
  const paramKeyNode = findDescendentOfType(NODE_TYPES.MARK_UP_PARAMETER_KEY)(param)
  editor.selectCompleteNode(paramKeyNode)
}

const moveAndSelectPreviousParamKeyFromListItem = (editor, node, markup) => {
  const value = editor.findAncestorOfType(node, NODE_TYPES.MARK_UP_PARAMETER_VALUE)
  return moveAndSelectPreviousParamKey(editor, value, markup)
}

export const TAB_INTERACTION_BY_NODE_TYPE = {
  [NODE_TYPES.MARK_UP_NAME]: moveAndSelectNextParamKey,
  [NODE_TYPES.MARK_UP_PARAMETER_KEY]: moveAndSelectNextParamValue,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE]: moveAndSelectNextParamKey,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]: moveAndSelectNextParamKeyFromListItem,
  DEFAULT: (editor, node, markup, next) => next()
}

export const SHIFT_TAB_INTERACTION_BY_NODE_TYPE = {
  [NODE_TYPES.MARK_UP_NAME]: (editor, node) => editor.moveToStartOfNode(node),
  [NODE_TYPES.MARK_UP_PARAMETER_KEY]: moveAndSelectPreviousParamValueOrMarkupName,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE]: moveAndSelectPreviousParamKey,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]: moveAndSelectPreviousParamKeyFromListItem,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST]: moveAndSelectPreviousParamKeyFromListItem,
  DEFAULT: (editor, node, markup, next) => next()
}

const insertMarkupOrText = (editor, markUpText) => {
  const node = textToMarkup(markUpText)
  if (node.type) {
    editor.insertInline(setCreatingState(node))
  } else {
    editor.insertText(markUpText)
  }
}

const reconstructMarkToRight = (editor, { text }, offset) => {
  const currentPath = editor.currentPath()
  const length = distanceToNextCloseDelimiter(text, offset, DELIM_END)
  const markUpText = `${DELIM_START}${text.slice(offset, offset + length)}`

  editor.deleteForward(length)
  insertMarkupOrText(editor, markUpText)
  editor.moveTo(currentPath, offset)
  editor.moveCursorToRight()

  return false
}

const reconstructMarkToLeft = (editor, { text }, offset) => {
  const length = distanceToNextOpenDelimiter(text, offset, DELIM_START)
  const markUpText = `${text.slice(offset - length, offset)}${DELIM_END}`

  editor.deleteBackward(length + 1)
  insertMarkupOrText(editor, markUpText)
  editor.moveCursorToRight()
  return false
}
