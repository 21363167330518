import React, { useCallback } from 'react'
import { Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { noop } from 'utils/functions'
import { Creators } from 'actions/ui'
import projectRevisionDelete from 'actions/project/projectRevisionDelete'
import useSecureCheck from '../../hooks/security/useSecureCheck'
import { projectAdminAccess } from '../../security/project'

const { confirmAction } = Creators

/**
 * A button to delete a ProjectRevision.
 * Popups a confirmation dialogue just in case.
 *
 * Currently only super admins & project admins can delete revisions
 */
const DeleteRevisionButton = ({ id, onPressed = noop }) => {
  const dispatch = useDispatch()

  const deleteRevision = useCallback(() => dispatch(projectRevisionDelete(id)), [dispatch])

  const onDelete = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    onPressed()
    dispatch(confirmAction(
      async () => {
        await deleteRevision()
      }, (
        <div>
          This action cannot be undone. Are you sure ?
        </div>
      )
    ))
  }, [id, onPressed, dispatch])

  const canDelete = useSecureCheck(projectAdminAccess)

  return canDelete ? (
    <Icon
      type="delete"
      onClick={onDelete}
    />
  ) : null
}

export default DeleteRevisionButton