import React from 'react'
import { urlForImage } from 'model/resources'
import useProjectImage from '../../hooks/beanie/useProjectImage'

/**
 *
 */
const ProjectImage = ({ path, alt, srcImage, urlResolver = urlForImage }) => {
  const url = useProjectImage(path, srcImage, urlResolver)
  return (
    <img src={url} alt={alt} />
  )
}

export default ProjectImage