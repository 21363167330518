exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__27gFq{background-color:#eff2f5;overflow:hidden;min-height:100%;height:100%}.container__27gFq .page__1nJl8{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;min-height:100%}.container__27gFq .page__1nJl8>div:first-child{width:20vw;background-color:#fff;border-right:1px solid #e1e4e8}.container__27gFq .page__1nJl8>div:nth-child(2){display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1}", ""]);

// exports
exports.locals = {
	"container": "container__27gFq",
	"page": "page__1nJl8"
};