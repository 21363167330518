import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { analysisIsLoading, countOfIssues } from 'selectors/staticIssues'
import { Icon } from 'antd'

import styles from './ProjectProblems.scss'

const _Counter = ({ count, isLoading }) => (isLoading || count > 0 ? (
  <span className={styles.Counter}>({isLoading ? <Icon type="loading" /> : count})</span>
) : null)

const Counter = compose(
  connect(state => ({
    count: countOfIssues(state),
    isLoading: analysisIsLoading(state)
  }))
)(_Counter)

const ProblemsTitle = () => <div className={styles.ProblemsTitle}><span>Problems</span><Counter /></div>

export default ProblemsTitle
