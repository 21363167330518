import { compose, withState } from 'recompose'

import paginatedQuery from 'hocs/paginatedQuery'
import query from 'api/queries/teamProjects.graphql'

import TeamProjects from './TeamProjects'

export default compose(
  withState('archived', 'setArchived', false),
  paginatedQuery({
    query,
    name: 'projects',
    pageSize: 20,
    variables: ({ organizationName, teamName, archived }) => ({
      organizationName,
      teamName,
      archived
    }),
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  })
)(TeamProjects)