import React from 'react'
import { Tooltip } from 'antd'
import ErrorList from './ErrorList'

import styles from './ErrorsPopover.scss'

const ErrorsPopover = ({ errors, children }) => (
  <Tooltip
    title={<ErrorList errors={errors} />}
    overlayClassName={styles.errorsPopover}
  >{children}</Tooltip>
)

export default ErrorsPopover