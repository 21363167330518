import { hasPath, map } from 'ramda'
import { lang, model, Sys } from 'beanie-engine-api-js'
import {
  truthTableRows,
  truthTables,
  nodesIndex,
  facts,
} from 'selectors/objects'

const { types: { object: { Paths } } } = model
const { rule: { utils: { changeFactReferences } } } = lang

// This function works as a mapper of each rule expression on a project.
// Receives the engine api, the function getState to has acces to the store and
// a function that map each rule expression ({ source, rule }) to another one
export const mapProjectExpressions = (api, getState, func) => {
  const nodesIdx = nodesIndex(getState())

  Object.values(nodesIdx).forEach(obj => {
    if (hasPath(Paths.object.enabled_rule, obj)) {
      const node = api.obj.get(obj.id)
      const enabled_rule = node.get_enabled_rule()
      node.set_enabled_rule(func(node, enabled_rule))
    }
  })

  truthTables(getState()).forEach(({ id, data }) => {
    const tt = api.obj.get(id)
    const headers = map(header => func(tt, header), data.headers)

    tt.get_data().headers = headers
  })

  truthTableRows(getState()).forEach(({ id, data }) => {
    const row = api.obj.get(id)
    const cells = map(cell => func(row, cell), data.cells)

    row.set_cells(cells)
  })

  facts(getState()).forEach(({ id }) => {
    const fact = api.obj.get(id)
    const expr = fact.get_expr()
    fact.set_expr(func(fact, expr))
  })
}

export const renameFactReferences = (api, getState, prevName, newName) =>
  mapProjectExpressions(api, getState, ({ sys }, expr) =>
    changeFactReferences(prevName, newName, expr, sys === Sys.truth_table_row)
  )