import { createActions } from 'reduxsauce'
import { path, over, lensPath, indexBy, prop, pipe } from 'ramda'

import videoGameQuery from '../api/videoGame.graphql'

import { objectsList } from '../selectors/project'

// other actions
import videoHostPresenter from './videoHostPresenter'

// actions

const loadProject = (revisionId, source) => async (dispatch, getState, { client }) => {
  if (source) { dispatch(Creators.setSource(source)) }
  dispatch(Creators.loadingProject())

  // TODO: handle response error
  const response = await client.query({ query: videoGameQuery, variables: { revisionId }, fetchPolicy: 'no-cache' })
  // TODO: refactor this and avoid transforming
  // it was a mismatch between back and frontend work in parallel
  const project = pipe(
    over(lensPath(['video', 'timeline']), indexBy(prop('clipId'))),
  )(response.data.video)

  dispatch(Creators.loadingProjectSuccess(project))

  await dispatch(loadBeanie())
}

const beanieBasePath = `${path(['location', 'origin'], window)}/api/engine/latest/`
const ENGINE_POLLING_FREQ = 10

const loadBeanie = () => async (dispatch, getState, { engine }) => {
  // init engine
  await engine.init({
    jobsUpdateInterval: ENGINE_POLLING_FREQ,
    beanieBasePath,
    fetchResource: async (file, cb) => {
      const r = await fetch(file, {
        method: 'GET',
      })
      cb(await r.text())
    },
    presenter: videoHostPresenter(dispatch, getState),
  })

  // load project
  const objects = objectsList(getState())
  engine.getAPI().createObjects(...objects)

  dispatch(Creators.loadingBeanieSuccess())
}

// composing

export const { Types, Creators } = createActions({
  // player
  setSource: ['source'],
  // load/fetch project
  loadProject,
  loadingProject: [],
  loadingProjectSuccess: ['project'],
  loadingProjectError: ['error'],

  // load beanie
  loadBeanie,
  loadingBeanie: [],
  loadingBeanieSuccess: [],
})
