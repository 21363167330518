import React, { useCallback } from 'react'
import { identity } from 'ramda'
import { DropTarget } from 'react-dnd'
import ReactDOM from 'react-dom'
import CodeMirror from 'react-codemirror'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { withRef } from 'utils/recompose'
import classNames from 'classnames'

import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/hint/show-hint'
import 'codemirror/addon/hint/show-hint.css'
import { DnDTypes } from '../../../../dnd/model-dnd'

import { hint } from './contentAssist/contentAssistUtils'
import ContentAssistItem from './contentAssist/ContentAssistItem'

import styles from './AbstractDSLEditor.scss'

/**
 *
 */
const AbstractDSLEditor = ({ mode, onCancel, codeMirrorRef, onChange, onEnter, value = '', className, autoComplete, connectDropTarget, autoFocus, innerRef, ...others }) => {
  const ref = useCallback(e => {
    codeMirrorRef().current = e
    if (innerRef) {
      innerRef.current = e
    }
  }, [codeMirrorRef(), innerRef])

  return connectDropTarget(
    <div>
      <CodeMirror
        autoFocus={autoFocus}
        className={classNames(styles.editor, className)}
        ref={ref}
        value={value}
        onChange={onChange}
        options={{
          mode,
          extraKeys: {
            'Ctrl-Space': autoComplete,
            Esc: onCancel,
            Enter: onEnter
          }
        }}
        {...others}
      />
    </div>
  )
}

const targetCollect = (conn, monitor) => {
  const canDrop = monitor.isOver() && monitor.canDrop()
  return ({
    connectDropTarget: conn.dropTarget(),
    canDrop,
  })
}

export default compose(
  withRef('codeMirrorRef'),
  withState('mode', 'setMode', ({ mode }) => mode || 'null'),
  withHandlers({
    onBlur: ({ onBlur }) => editor => {
      //  when show-hint addon gets closed, this editor attribute is null
      if (editor.state.completionActive) return
      onBlur?.()
    },
    onChange: ({ setValue }) => value => {
      setValue(value)
    },
    onEnter: ({ onApply }) => cm => {
      const value = cm.getDoc().getValue()
      onApply?.(value)
    },
    autoComplete: ({ codeMirrorRef, proposeCompletions }) => cm => {
      const codeMirror = codeMirrorRef().current.getCodeMirrorInstance()
      codeMirror.showHint(cm, codeMirror.hint, {
        completeSingle: true,
        completeOnSingleClick: true,
        hint: hint(proposeCompletions(cm))
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { codeMirrorRef, onBlur } = this.props
      const codeMirror = codeMirrorRef().current.getCodeMirror()

      if (onBlur && codeMirror && !codeMirror.blur) {
        codeMirror.on('blur', onBlur)
      }
    },
    componentDidUpdate() {
      const { codeMirrorRef, value } = this.props
      const currentCM = codeMirrorRef().current.getCodeMirror()
      const cmValue = currentCM.getDoc().getValue()
      if (cmValue !== value) {
        currentCM.setValue(value || '')
      }
    }
  }),
  DropTarget(
    [DnDTypes.Node],
    {
      drop({ codeMirrorRef }, monitor) {
        codeMirrorRef().current.getCodeMirror().doc.replaceSelection(`"${monitor.getItem().left.id}"`)
      },
    },
    targetCollect,
  )
)(AbstractDSLEditor)

export const createCompletion = ({ className, icon, description, transformText = identity }) => text => ({
  text: transformText(text),
  className: classNames(styles.completionItem, className),
  render: (dom, _, data) => {
    ReactDOM.render(
      <ContentAssistItem text={data.text} icon={icon} description={description} />,
      dom
    )
  }
})
