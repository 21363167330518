import { createSelector } from 'selectors/reselect'
import { sortBy, path, pipe, map } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

import { currentlyVisibleClipId, nextClipsIds } from '../../selectors/engine'
import { timeline } from '../../selectors/project'

export const segments = createSelector(
  [currentlyVisibleClipId, nextClipsIds, timeline],
  (id, nextIds, tline) => clipIdsToSegments(tline, id)(idsArray(id, nextIds))
)

const idsArray = (id, nextIds) => (nextIds || EMPTY_ARRAY).concat(id ? [id] : EMPTY_ARRAY)

const createSegment = (tline, id) => clipId => ({
  id: clipId,
  timelineItem: tline[clipId],
  active: clipId === id, // current || last clip
})
const clipIdsToSegments = (tline, id) => pipe(
  map(createSegment(tline, id)),
  sortBy(path(['timelineItem', 'start']))
)