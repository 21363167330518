import { mergeDeepRight, propEq } from 'ramda'
import uuid from 'uuid/v4'
import { user } from '../../selectors/auth'
import { assignTypeNameToChange } from '../../model/ChangeSet'
import { createRevertChangeSetFromSameAuthor } from '../../model/changeSet/undoRedoChangeSet'
import { sessionId } from '../../selectors/project'
import { getMutationName, getQueryName } from '../../utils/graphql'
import { EMPTY_OBJECT } from '../../utils/object'

/**
 * Makes an apolloClientFactory that creates a client mock
 * that is it just has ApolloClient API but does no networking
 * Instead it delegates the mutations to a "mock" object.
 *
 * So the mock ack as a backend mock.
 *
 * There is a different approach in "MockApolloClientProvider"
 * which uses an apollo graphql utility to mock using the REAL schema.
 * But the problem with that one is that it still works with a mock anyway
 * but we need a copy of graphql "schema". We can generate one from the backend with a script
 * and then copy it here, but it is a manual task and we sometimes forget.
 */
const createApolloClientWithMock = (_mock = EMPTY_OBJECT) => ({ getState }) => {
  const mock = mergeDeepRight(createDefaultBackendMock(getState), _mock)
  const delegateToMock = (optionName, nameResolver) => ({ [optionName]: queryOrMutation, variables }) => {
    const name = nameResolver(queryOrMutation)

    const method = mock[name]
    if (!method) { throw new Error(`No API mock set for ${optionName}: ${name}`) }
    return Promise.resolve({
      data: {
        [name]: method(null, variables)
      }
    })
  }
  const subscriptions = {}

  return {
    mutate: delegateToMock('mutation', getMutationName),
    query: delegateToMock('query', getQueryName),

    // TODO: this is not finished yet. It is mocking just not to blow up
    //  but we need a full impl that allows the test to: force events, reject subscription
    //  send errors, etc
    subscribe: (/* { query, variables } */) => {
      // const subscriptionName = getSubscriptionName(query)
      return {
        subscribe: (/* { next, error } */) => {
          const id = uuid()
          subscriptions[id] = {
            unsubscribe: () => {
              delete subscriptions[id];
            }
          }
          return subscriptions[id]
        }
      }
    },

    readQuery() { throw new Error('Should be necessary anymore to sync') },
    writeQuery() { throw new Error('Should be necessary anymore to sync') },

    getMock() { return mock }
  }
}

export default createApolloClientWithMock

// a default server impl (resolvers)

const createDefaultBackendMock = getState => {
  const mock = {

    changeSets: [],

    applyChangeSet: (_, { input }) => {
      const state = getState()
      // we need to mimic server that injects the author here
      const changeSet = {
        _id: `TEST-CS-${mock.changeSets.length}`,
        ...input,
        author: {
          user: user(state),
          sessionId: sessionId(state)
        },
        changes: input.changes.map(assignTypeNameToChange)
      }
      mock.changeSets.push(changeSet)
      return changeSet
    },

    updateCurrentObject: () => true,

    revertChangeSet: (_, { changeSetId }) => {
      const cs = mock.changeSets.find(propEq('_id', changeSetId))
      return {
        _id: `TEST-CS-UNDOES-${changeSetId}`,
        ...createRevertChangeSetFromSameAuthor(cs)
      }
    },

    reapplyChangeSet: (_, { changeSetId }) => {
      const cs = mock.changeSets.find(propEq('_id', changeSetId))
      return {
        ...cs,
        _id: `TEST-CS-REDOES-${changeSetId}`,
        reapplies: changeSetId,
      }
    },

    //

    getReceivedChangeSets() { return mock.changeSets }

  }
  return mock
}