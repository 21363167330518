import { model } from 'beanie-engine-api-js'
import { replace } from 'ramda'

const { types: { metadata: { TimestampedMetadata } } } = model

export const expectMetadataTimestampChange = (id, field) => ({
  id,
  changes: [
    { field: `${TimestampedMetadata.pathAsString}.${field}`, next: expect.any(String) }
  ]
})

// test util function because tests run against node_modules/@brunerhouse/beanie-engine which does
// not have bytecode/src and vm subdirs (those are generated when the runtime is distrubuted to s3)
export const removeBytecodeFromPath = replace('bytecode/fengari/', '')