import { soundManager } from 'soundmanager2'
import { concat } from 'ramda'
import uuid from 'uuid/v4'

import { urlForAudio } from './resources'

soundManager.setup({
  debugMode: false,
  ignoreMobileRestrictions: true // enables concurrent audio playback
})

export const stopAllSounds = () => {
  soundManager.stopAll()
}

export const pauseAllSounds = () => {
  soundManager.pauseAll()
}

export const resumeAllSounds = () => {
  soundManager.resumeAll()
}

const destroyPromisedSound = (resolve, id) => () => {
  soundManager.destroySound(id)
  resolve()
}

const toBase64Url = concat('data:audio/mpeg;base64,')
export const playBase64 = base64Audio => new Promise((resolve, reject) => {
  const id = uuid()
  soundManager.createSound({
    id,
    url: toBase64Url(base64Audio),
    autoLoad: true,
    autoPlay: true,
    onfinish: destroyPromisedSound(resolve, id),
    onstop: destroyPromisedSound(resolve, id),
    onerror: (code, description) => {
      soundManager.destroySound(id)
      reject(new Error(`Error trying to play actor preview with code: ${code} - description: ${description}`))
    }
  })
})

export const stopSound = soundManager.stop
export const playSound = (token, revisionId, audioFile, lineId) => new Promise((resolve, reject) => {
  const url = urlForAudio(token, revisionId, audioFile)

  let errored = false
  const resolution = () => {
    if (!errored) resolve()
  }

  // TODO: cache sounds by
  // - removing the destroySound
  // - onplay check if the sound exist
  // - if not create
  // - if so then play(id, { onfinish: ... }) (check "Demo 4a")
  soundManager.createSound({
    id: lineId,
    url,
    autoLoad: true,
    autoPlay: true,
    onfinish: destroyPromisedSound(resolution, lineId),
    onstop: destroyPromisedSound(resolution, lineId),
    onerror: (code, description) => {
      // order here matters ! reject first ! then destroy. Otherwise destroy calls onfinish/onstop which resolves
      // it successfully even if there was an error !
      errored = true
      reject(new Error(`Error trying to play lineId: ${lineId} with url: ${url} code: ${code} description ${description}`))
      soundManager.destroySound(lineId)
    }
  })
})
