import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { Button, Table } from 'antd'
import { always, pick } from 'ramda'

import videoJobsForProjectQuery from 'api/queries/videoJobsForProject.graphql'

import { revisionId as revisionIdSelector } from 'selectors/project'
import paginatedQuery from 'hocs/paginatedQuery'
import { isSuperAdminCheck } from 'security/platform'
import { VideoJobStatus } from 'model/constants'
import { formatTimestamp, humanizedDiff } from 'utils/dates'

import Secure from 'components/Commons/Secure'
import SectionElementsTable from 'components/Organizations/Section/SectionElementsTable/SectionElementsTable'
import FilterStatusDropdown from '../TtsSection/TtsJobsMonitor/FilterStatusDropdown'
import Sources from './Sources/Sources'

import styles from '../TtsSection/TtsJobsMonitor/TtsJobs.scss'

const { Column } = Table

const VideoSection = ({ setFilterStatus, filterStatus, refreshItems, pageSize, onPageChanged, handleTableChange, videoJobs }) => (
  <Fragment>
    <h2>Video</h2>
    <Secure check={isSuperAdminCheck}>

      <Sources />

      <h3>Jobs</h3>
      <div className={styles.filterToolbar}>
        <FilterStatusDropdown
          setFilterStatus={setFilterStatus}
          refreshItems={refreshItems}
          defaultValue={filterStatus}
          options={VideoJobStatus}
          allowAll
        />
        <Button type="primary" icon="search" onClick={() => refreshItems()}>Search</Button>
      </div>
      <SectionElementsTable
        data={videoJobs}
        dataPath={['revisionWithId', 'videoJobs']}
        pageSize={pageSize}
        scroll={{ x: '30vw', y: '55vh' }}
        onPageChanged={onPageChanged}
        refreshItems={refreshItems}
        handleTableChange={handleTableChange}
      >
        <Column
          title="Job id"
          key="_id"
          sorter
          dataIndex="_id"
          width="15vw"
        />
        <Column
          title="Status"
          key="status"
          sorter
          dataIndex="status"
          width="15vw"
        />
        <Column
          title="Last updated"
          key="updatedAt"
          sorter
          width="15vw"
          render={({ updatedAt }) => (<span>{formatTimestamp(updatedAt)}</span>)}
        />
        <Column
          title="Elapsed"
          width="15vw"
          render={({ createdAt, updatedAt }) => (
            <span>{humanizedDiff(updatedAt, createdAt)}</span>
          )}
      />
      </SectionElementsTable>
    </Secure>
  </Fragment>
)

// export default VideoSection
export default compose(
  connect(state => ({
    revisionId: revisionIdSelector(state)
  })),
  withState('filterStatus', 'setFilterStatus', null),
  withState('spinningClips', 'setSpinningClips', false),
  withState('spinningProject', 'setSpinningProject', false),
  paginatedQuery({
    query: videoJobsForProjectQuery,
    name: 'videoJobs',
    variables: pick(['revisionId', 'filterStatus']),
    options: always({ fetchPolicy: 'cache-and-network' })
  })
)(VideoSection)
