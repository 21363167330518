exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__G2c_X{display:inline-block;position:relative}.container__G2c_X .overlay__5sT_F{position:absolute}.container__G2c_X .bottomRight__1eTTj{right:2px;bottom:-5px}.container__G2c_X .bottomLeft__3Sn9Y{left:2px;bottom:-5px}.container__G2c_X .topLeft__1PJ3Q{left:2px;top:-5px}.container__G2c_X .topRight__3w8He{right:2px;top:-5px}", ""]);

// exports
exports.locals = {
	"container": "container__G2c_X",
	"overlay": "overlay__5sT_F",
	"bottomRight": "bottomRight__1eTTj",
	"bottomLeft": "bottomLeft__3Sn9Y",
	"topLeft": "topLeft__1PJ3Q",
	"topRight": "topRight__3w8He"
};