exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".GrantAvatarList__F2HpK .ant-avatar{border:1px solid #fff}.title__Hg6hV{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.title__Hg6hV>div:first-child{margin-right:1rem;-ms-flex-positive:1;flex-grow:1}", ""]);

// exports
exports.locals = {
	"GrantAvatarList": "GrantAvatarList__F2HpK",
	"title": "title__Hg6hV"
};