// I didn't find a way to get this value from draftjs

export const ChangeType = {
  insertCharacters: 'insert-characters',
  insertFragment: 'insert-fragment',
  removeRange: 'remove-range',
}

export const Command = {
  backspace: 'backspace',
  enter: 'split-block',
}

export const CommandResult = {
  handled: 'handled',
  notHandled: 'not-handled'
}
