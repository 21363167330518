exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2w5lp{color:#add8e6;cursor:pointer}.iconAsLink__2w5lp:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__cldNf{font-style:italic}.formFieldsVerticalGutter__3paOd{padding:.5rem}.formFieldsVerticalGutter__3paOd>*{margin:.5rem !important}.tableBackground__2qxgc .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2qxgc .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__3h0Wd .ant-notification-notice-close{display:none}.imageValue__2K6dl{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding-left:.5em}.imageValue__2K6dl .value__3VHKm{-ms-flex-item-align:center;align-self:center;padding-left:.5em}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2w5lp",
	"labelFromRef": "labelFromRef__cldNf",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3paOd",
	"tableBackground": "tableBackground__2qxgc",
	"notificationNotClosable": "notificationNotClosable__3h0Wd",
	"imageValue": "imageValue__2K6dl",
	"value": "value__3VHKm"
};