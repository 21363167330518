import { OneToManyOperations } from '../../Operations'
import OneToManyCustomMutations from './OneToManyCustomMutations'
import OneToManyDefaultMutations from './OneToManyDefaultMutations'

const getMutationFor = (object, path) => OneToManyCustomMutations?.[object.sys]?.[path.join('.')]

/**
 * high-order fun to create fn impls for operations which first try to find
 * a custom impl in `OneToManyCustomMutations` by sys+path
 * otherwise just uses `OneToManyDefaultMutations` impl.
 */
const customOrDefaultMutation = methodName => (api, object, path, ...args) => {
  const customMutation = getMutationFor(object, path)?.[methodName];
  (customMutation || OneToManyDefaultMutations[methodName])(api, object, path, ...args)
}

export const insertOperation = customOrDefaultMutation(OneToManyOperations.INSERT)
export const appendOperation = customOrDefaultMutation(OneToManyOperations.APPEND)