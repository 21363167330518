import { toBNEAction } from './utils'
import { DEFAULT_ACTOR } from 'beanie-engine-api-js'

const isNewLine = line => !line.line_id

// markups & notes

const updateArray = name => (api, line, metadata) => {
  const res = line.get_language_resource() // uses default locale and selected_take
  if (!metadata && !res.get_data()[name]) {
    return
  }
  api.update(res.get_id(), { [name]: metadata })
}
const updateMarkups = updateArray('markups')
const updateNotes = updateArray('notes')

export const editClipImpl = (clipObject, lines) => api => { // exported just for tests
  const clip = api.obj.get(clipObject.id)
  clip.clear_lines()

  lines.forEach(line => {
    let luaLine
    if (isNewLine(line)) {
      luaLine = clip.add_line(line.actor_name, line.text, { trim: false })
    } else {
      luaLine = api.obj.get(line.line_id)
      luaLine.set_text(line.text, { trim: false })
      luaLine.set_actor(resolveActor(line, api))
      // add it
      clip.add_line(line.line_id)
    }
    updateMarkups(api, luaLine, line.markups)
    updateNotes(api, luaLine, line.notes)
  })
}
const resolveActor = (line, api) => (line.actor_name ? api.actor.find(line.actor_name, true) : api.actor.find(DEFAULT_ACTOR, true))

export const editClip = toBNEAction(editClipImpl, 'Edit Clip', { notifyOnError: false })

export const setDisabledSubtitles = toBNEAction(
  (clipObject, value) => api => {
    const clip = api.obj.get(clipObject.id)
    clip.set_disabled_subtitles(value)
  },
  'Set clip disabled_subtitles'
)
