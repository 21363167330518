import { pipe, path } from 'ramda'
import { parseRef, DEFAULT_LOCALE, model } from 'beanie-engine-api-js'
import { objectsIndex } from './apollo'
import { makeMemoizeSelector } from './memoize'

const { types: { object: { Paths } } } = model

/**
 * Given a choice it returns its associated language resource object
 * (uses the default locale)
 */
export const makeChoiceLangResourceSelector = choiceId => makeMemoizeSelector(
  { index: objectsIndex }, ({ index }) => {
    const resolving = aPath => obj => {
      if (!obj) return undefined
      const aRef = path(aPath, obj)
      return aRef ? index[parseRef(aRef)] : undefined
    }

    const choice = index[choiceId]
    return pipe(
      resolving(Paths.choice.line),
      resolving(Paths.line.selected_take),
      resolving(['data', 'locales', DEFAULT_LOCALE])
    )(choice)
  })
