import { allPass, propEq } from 'ramda'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { Menu } from 'antd'
import { revisionId } from 'selectors/project'
import { nodeFromClipboard } from 'utils/clipboard'
import { selectVisibleObject } from 'actions/nodes'
import SearchObject from 'components/SearchObject/SearchObject'
import NoContent from 'components/Commons/NoContent'
import useStateWithMountCheck from 'hooks/useStateWithMountCheck'
import queryToPredicate from '../../../../../model/project/searches/query/queryToPredicate'

const { SubMenu, Item, Divider } = Menu

import styles from './Menu.scss'

const renderNoContent = label => () => (<NoContent message={`no ${label}`} className={styles.noContent} />)

const NodeAutoCompleteSelectionAntd = ({
  key,
  title,
  currentRevisionId,
  elementLabel,
  filter,
  onSelect,
  node,
  selectVisibleObjectAction,
  onEnter,
  onCloseMenu,
  ...props }) => {

  const [nodeCopied, setNodeCopied] = useStateWithMountCheck()
  useEffect(() => {
    nodeFromClipboard(allPass([queryToPredicate(filter), propEq('project', currentRevisionId)]))
      .then(setNodeCopied)
  }, [])

  return (
    <SubMenu
      key={key}
      className={styles.subMenu}
      title={title}
      {...props}>

      {nodeCopied && nodeCopied.project === currentRevisionId ?
        <Item
          className={styles.item}
          onClick={() => onSelect(nodeCopied.id)}>
          {nodeCopied.data.name}&nbsp;&nbsp;(from clipboard)
        </Item> :
        <Item
          className={styles.item}
          disabled >{`(no ${elementLabel} in clipboard)`}</Item>
      }

      <Divider />
      <SearchObject
        searchAtInit
        showInline
        placeholder={`Search ${elementLabel}`}
        minLengthToSearch={0}
        debounceTime={200}
        autoCompleteClassName={styles.searchBox}
        dropdownClassName={styles.options}
        renderNoContent={renderNoContent(elementLabel)}
        onValueChanged={onSelect}
        filterOption={filter}
        optionProps={{ showContextInfo: false }}
      />
    </SubMenu>
  )
}

export default compose(
  connect(
    () => state => ({ currentRevisionId: revisionId(state) }),
    dispatch => ({
      selectVisibleObjectAction: id => dispatch(selectVisibleObject(id)),
    })
  ),
  withHandlers({
    onSelect: ({ onEnter, onCloseMenu, selectVisibleObjectAction, node, connectionFn }) => async nodeId => {
      const createdObjectId = await onEnter(nodeId, node.id, connectionFn)
      if (createdObjectId) { selectVisibleObjectAction(createdObjectId) }
      onCloseMenu()
    }
  })
)(NodeAutoCompleteSelectionAntd)