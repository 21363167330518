import React from 'react'
import { connect } from 'react-redux'
import { Icon } from 'antd'
import classnames from 'classnames'

import { currentStoryboard as currentStoryboardSelector, isPlaying as isPlayingSelector } from 'selectors/playback'

import Preferences, { PREF_SHOW_STORYBOARD } from 'preferences/Preferences'
import { preference } from 'selectors/view'
import StoryboardImage from 'components/Commons/StoryboardImage'
import ActorsInScene from 'components/ActorsInScene/ActorsInScene'

import styles from './PlaybackStoryboard.scss'

class PlaybackStoryboard extends React.Component {
  shouldComponentUpdate = nextProps => {
    const { previousStoryboard, isPlaying: wasPlaying } = this.props
    const { currentStoryboard, isPlaying } = nextProps
    const currentStoryboardChange = !currentStoryboard || currentStoryboard !== previousStoryboard
    const startedPlaybacking = !wasPlaying && isPlaying
    return currentStoryboardChange || startedPlaybacking
  }

  render = () => {
    const { currentStoryboard, show } = this.props
    return (
      <div className={classnames(styles.storyboardPanel, { [styles.storyboardOnly]: show === PREF_SHOW_STORYBOARD })}>
        {currentStoryboard ?
          <StoryboardImage path={currentStoryboard} alt={currentStoryboard} />
          : <NoImage />
        }
        <ActorsInScene />
      </div>
    )
  }
}

const NoImage = () => (
  <div className={styles.noImage}>
    <Icon type="picture" />
    No Storyboard
  </div>
)

export default connect(
  () => {
    const playbackPanelCycleViewSelector = preference(Preferences.Playback.showStoryboardAndHistory)
    return state => ({
      currentStoryboard: currentStoryboardSelector(state),
      isPlaying: isPlayingSelector(state),
      show: playbackPanelCycleViewSelector(state)
    })
  }
)(PlaybackStoryboard)