import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MenuItem } from '@blueprintjs/core'
import { SET_BLOCK } from 'commands/edition'
import withCommandPropsForMenu from 'hocs/withCommandPropsForMenu'
import { Sys } from 'beanie-engine-api-js'
import { setTaskAsync } from 'engine/actions/sequencer'
import { isBlocking } from '../../model/taskContainer'

/**
 *
 */
const SetTaskAsyncMenuItem = ({ isVisible, o, _setTaskAsync }) => {
  if (o.sys !== Sys.task_container) { return null }

  const dispatch = useDispatch()
  const setTrue = useCallback(() => dispatch(setTaskAsync(o, false)), [o, _setTaskAsync])
  const setFalse = useCallback(() => dispatch(setTaskAsync(o, true)), [o, _setTaskAsync])

  const blocking = isBlocking(o)

  return isVisible ? (
    <MenuItem
      text={SET_BLOCK.label}
      icon={o.data.async ? 'caret-right' : 'caret-down'} >
      <MenuItem
        key="true"
        text="true"
        onClick={setTrue}
        icon={blocking ? 'tick' : 'blank'}
        disabled={o.data.async === false} />
      <MenuItem
        key="false"
        text="false"
        onClick={setFalse}
        icon={!blocking ? 'tick' : 'blank'}
        disabled={o.data.async === true} />
    </MenuItem>
  ) : null
}

export default withCommandPropsForMenu(SetTaskAsyncMenuItem)
