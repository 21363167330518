exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SearchViewPanel__3Zlxl{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%;height:100%;padding-left:.5rem;padding-right:.5rem;padding-top:.3rem;padding-bottom:.5rem}.SearchViewPanel__3Zlxl>div:first-child{margin-bottom:.3rem}", ""]);

// exports
exports.locals = {
	"SearchViewPanel": "SearchViewPanel__3Zlxl"
};