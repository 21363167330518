import React from 'react'
import { Alert } from 'antd'

import { ModalConsumer } from 'components/Commons/Modal'
import CreateModal from 'components/Commons/CreateModal'
import { valueWithChangeAnnotations } from './utils/changes/decorations'
import { changes as calculateChanges } from './utils/changes/lineChange'
import ReadOnlyDiffEditor from './ReadOnlyDiffEditor'

export const RECOVER_MESSAGE = 'Recover your changes'
export const ON_SAVE_ERROR_MESSAGE = 'An error occurred while saving your changes.\nWe have restored how things were before your changes.'
export const OVERRIDEN_MESSAGE = 'Oh no ! someone did a change to this clip while you were editing!'

const RecoverTextModal = (value, changes, linkMessage) => ({ hide }) => (
  <CreateModal title={linkMessage} hide={hide} noFooter>
    <div>
      <Alert type="info" message="Read only editor" banner />
      <ReadOnlyDiffEditor value={valueWithChangeAnnotations(value, changes)} />
    </div>
  </CreateModal>
)

const RecoverMessage = (baseValue, currentValue, message, linkMessage = RECOVER_MESSAGE) => (
  <span>
    {message}
    &nbsp;
    <ModalConsumer>
      {({ showModal }) => (
        <a onClick={() => showModal(RecoverTextModal(currentValue, calculateChanges(baseValue, currentValue), linkMessage))}>
          See your changes
        </a>
      )}
    </ModalConsumer>
    .
  </span>
)

export default RecoverMessage
