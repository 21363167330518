import { packSelectionAsRange } from 'actions/nodes'
import { isContiguosSelectionRoughVersion, selectedNodes } from 'selectors/nodeSelection'

const canDrag = node => (dispatch, getState) => {
  packSelectionAsRange()(dispatch, getState)

  const state = getState()

  return isContiguosSelectionRoughVersion(state)
    // node is in selection
    && selectedNodes(state).includes(node.id)
}

export default canDrag