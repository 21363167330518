import { lensProp, map, over, propEq } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { markupModel as markupModelSelector } from 'selectors/project'

const { types: { markup: { getType } } } = model

// state => lines => lines
export default state => map(mapLine(markupModelSelector(state)))

const mapLine = markupModel => over(lensProp('markups'), map(mapMarkup(markupModel)))

const mapMarkup = markupModel => markup => {
  if (!markup.name) return markup

  const definition = markupModel.markups.find(propEq('name', markup.name))
  return definition ? over(lensProp('args'), map(mapMarkupArg(markupModel, definition)))(markup) : markup
}

const mapMarkupArg = (markupModel, definition) => arg => {
  const param = definition.parameters.find(propEq('name', arg.name))
  return param ? { ...arg, value: convertArgIfNeeded(param, arg) } : arg
}

// we might have more rules here in the future (?)
const convertArgIfNeeded = (param, arg) => {
  const type = getType(param.type)
  return type && type.coerse ? type.coerse(arg.value) : arg.value
}
