
/**
 * Given a `SearchMatchResult` (contains offset + length) and a list of Slate "Text" nodes
 * it creates decorations by calling `appendDecoration` in the expected position.
 * @param lineResult the `SearchMatchResult` matching this line.
 * @param appendDecoration a fn to push new decorations.
 * @param textNodes Slate Text nodes of this line.
 * @param adjustOffsets allows to shift the created decorations optionally. Used for showing notes that
 * have a start delimiter.
 */
const createDecorationsForTextMatches = (lineResult, appendDecoration, textNodes, adjustOffsets = 0) => {
  const { offset: start, length, end = start + length } = lineResult

  let cursor = 0
  for (const textNode of textNodes) {
    const { text } = textNode
    const partLength = text.length

    if (start < cursor + partLength) {
      const startAbsOffset = Math.max(cursor, start)
      const endAbsOffset = Math.min(cursor + text.length, end)

      appendDecoration(textNode, (startAbsOffset - cursor) + adjustOffsets, (endAbsOffset - cursor) + adjustOffsets)

      if (end <= cursor + text.length) break
    }
    cursor += text.length
  }
}

export default createDecorationsForTextMatches