import React, { Children, cloneElement, useCallback } from 'react'
import classNames from 'classnames'
import { Tooltip, Icon } from 'antd'

import styles from './ToolBar.scss'

export const ToolBarSplitter = () => <div className={styles.toolBarSplitter} />

export const ToolBarGroup = ({ children, className, disabled = false }) => (
  <div className={classNames(styles.toolBarGroup, className)}>
    {disabled ? Children.toArray(children).map(c => cloneElement(c, { disabled })) : children}
  </div>
)

export const ToolBarItem = ({ title, icon, onClick, disabled = false }) => {
  // antd Icon seems to pass an array of "things" to the onClick
  // we don't want to dragg that around.
  const _onClick = useCallback(() => {
    if (onClick && !disabled) {
      onClick()
    }
  }, [onClick, disabled])
  return (
    <div className={classNames(styles.item, { [styles.disabled]: disabled })}>
      <Tooltip title={title} placement="bottom">
        <Icon
          type={icon}
          onClick={_onClick}
        />
      </Tooltip>
    </div>
  )
}