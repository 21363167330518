import React from 'react'

import { compose } from 'recompose'
import { Provider, connect } from 'react-redux'

import { selectIfNotInSelection } from 'actions/nodes'
import { isMultipleSelection } from 'selectors/nodeSelection'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import SingleSelectionContextMenu from './SingleSelectionContextMenu'
import MultipleSelectionContextMenu from './MultipleSelectionContextMenu'
import RevisionExtensions from 'providers/RevisionExtensions'

const SelectionContextMenu = props => {
  const { o, store, selectIfNotInSelectionAction, isMultiple, } = props
  const isSingleSelection = !isMultiple

  // force selection if not selected
  selectIfNotInSelectionAction(o.id)

  const ContextMenuComp = isSingleSelection ?
    SingleSelectionContextMenu :
    MultipleSelectionContextMenu

  return (
    <Provider store={store} >
      <RevisionExtensions>
        <ContextMenuComp {...props} />
      </RevisionExtensions>
    </Provider>
  )
}

export default compose(
  secure('hasWriteAccess', isCurrentRevisionWritable),
  connect(
    state => ({
      isMultiple: isMultipleSelection(state)
    }),
    ({ selectIfNotInSelectionAction: selectIfNotInSelection })
  )
)(SelectionContextMenu)

