exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".bullet__3bkg6{background-color:#9362dd;width:10px;height:10px;border-radius:10px}", ""]);

// exports
exports.locals = {
	"bullet": "bullet__3bkg6"
};