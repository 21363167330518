import { Route, Redirect } from 'react-router-dom'
import React from 'react'
import { T } from 'ramda'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'

import { currentProject } from 'selectors/project'

import UnAuthorized from 'components/Unauthorized/Unauthorized'
import SecureScope from 'components/Commons/SecureScope'

const AuthorizedRoute = props => {
  const { scope, isAllowed, requiresProject, project, component: Component, logged, render, location, history, match, ...rest } = props
  return (
    <Route
      {...rest}
      render={
        () => {
          if (!logged.token) {
            return <Redirect push to={`/login?then=${location.pathname}`} />
          } else if ((requiresProject && !project) || isAllowed) {
            return (
              <SecureScope scope={{ currentUser: logged.user }}>
                {render ? render(props) : <Component {...props} />}
              </SecureScope>
            )
          } else {
            return <UnAuthorized />
          }
        }
      }
    />
  )
}

export default compose(
  connect(state => ({
    logged: state.login,
    // hack until we refactor all the routes to make it a hierarchical structure
    // as we did for Organizations / Teams so each subcomponent could be defining scopes
    project: currentProject(state),
  })),
  withProps(({ logged, project, requires = T }) => ({
    isAllowed: requires({ currentUser: logged.user, project }),
  }))
)(AuthorizedRoute)