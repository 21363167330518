import { propEq } from 'ramda'

export const NODE_TYPES = {
  // clip & lines
  CLIP: 'clip',
  DIRECTOR_LINE: 'director_line',
  DIALOGUE_LINE: 'dialogue_line',
  TEXT_LINE: 'text_line', // I think this is not used anymore
  ACTOR_PART: 'actor_part',
  TEXT_PART: 'text_part',

  // notes
  PERFORMANCE_NOTE: 'performance_note',
  PRODUCTION_NOTE: 'production_note',

  // markups
  MARK_UP: 'mark_up',
  MARK_UP_NAME: 'mark_up_name',
  MARK_UP_WITH_PARAMS: 'mark_up_with_parameters',

  MARK_UP_PARAMETERS: 'mark_up_parameters',
  MARK_UP_PARAMETER: 'mark_up_parameter',
  MARK_UP_PARAMETER_KEY: 'mark_up_parameter_key',
  MARK_UP_PARAMETER_VALUE: 'mark_up_parameter_value',

  MARK_UP_PARAMETER_VALUE_LIST: 'mark_up_parameter_value_list',
  MARK_UP_PARAMETER_VALUE_LIST_ELEMENT: 'mark_up_parameter_value_list_e'
}

export const OBJECT_TYPES = {
  block: 'block',
  inline: 'inline',
  text: 'text',
  leaf: 'leaf',
  annotation: 'annotation',
  document: 'document',
  value: 'value',
}

export const LineNodeTypes = [
  NODE_TYPES.DIRECTOR_LINE,
  NODE_TYPES.DIALOGUE_LINE
]

export const PhysicalLineNodeTypes = [
  NODE_TYPES.DIRECTOR_LINE,
  NODE_TYPES.ACTOR_PART,
  NODE_TYPES.TEXT_PART
]

export const NOTE_TYPES = [
  NODE_TYPES.PERFORMANCE_NOTE,
  NODE_TYPES.PRODUCTION_NOTE,
]

export const MARK_TYPES = {
}

export const ANNOTATION_TYPES = {
  ADD_LINE_ANNOTATION: 'ADD_LINE_ANNOTATION',
  DELETE_LINE_ANNOTATION: 'DELETE_LINE_ANNOTATION',
  INSERT_TEXT_ANNOTATION: 'INSERT_TEXT_ANNOTATION',
  REMOVE_TEXT_ANNOTATION: 'REMOVE_TEXT_ANNOTATION',
  REPLACE_TEXT_ANNOTATION: 'REPLACE_TEXT_ANNOTATION',
}

export const DECORATION_TYPES = {
  highlight: 'highlight',
}

const isOfType = propEq('type')
export const isInline = propEq('object', OBJECT_TYPES.inline)
export const isMarkupNode = isOfType(NODE_TYPES.MARK_UP)
export const isMarkupParameter = isOfType(NODE_TYPES.MARK_UP_PARAMETER)
export const isMarkupParameterValueList = isOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST)
export const isMarkupParameterValueListElement = isOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)

export const isNoteInline = inline => NOTE_TYPES.includes(inline.type)