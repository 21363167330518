import autorefresh from 'jwt-autorefresh'
import { path, anyPass } from 'ramda'

import { isTokenExpired } from 'utils/jwt'
import { refreshExpires } from '../selectors/auth'
import { onlyInProduction, onlyInDev } from './services-enabling'
import logger from 'utils/logger'
import onwakeup from 'utils/onwakeup'
import { apiFetch } from 'actions/utils'
import { loginTokenRefreshed, logout } from 'actions/login'
import setupTokenWillExpire, { teardown as teardownTokenWillExpire } from '../tokenWillExpire'

import { token as tokenSelector } from 'selectors/auth'
import { noop } from 'utils/functions'

// export just for test
export const refresh = store => () => {
  const [previousToken, refreshToken] = ['token', 'refreshToken']
    .map(name => path(['login', name], store.getState()))

  const opts = {
    method: 'POST',
    headers: {
      ...refreshToken && { 'refresh-token': refreshToken },
      ...previousToken && { Authorization: `Bearer ${previousToken}` }
    }
  }
  return apiFetch('api/auth/authenticate', opts)
    .then(res => res.json())
    .then(({ status, error, token, user }) => {
      if (status === 'error') throw new Error(`Error refreshing token ${error}.`)
      store.dispatch(loginTokenRefreshed(token, user))
      setupTokenWillExpire({
        reduxStore: store,
        expires: refreshExpires(store.getState())
      })
      return token
    })
    .catch(e => {
      logger.error(`Error authenticating ${JSON.stringify(e)}`)
      store.dispatch(logout(true))
    })
}

const THREE_MINUTES = 3 * 60
/** You supply a leadSeconds number or function that generates a number of seconds that the refresh should occur prior to the access token expiring */
export const leadSeconds = () => {
  /** Generate random additional seconds (up to 30 in this case) to append to the lead time to ensure multiple clients dont schedule simultaneous refresh */
  const jitter = Math.floor(Math.random() * 30)

  /** Schedule autorefresh to occur 60 to 90 seconds prior to token expiration */
  return THREE_MINUTES + jitter
}

const start = reduxStore => autorefresh({ refresh: refresh(reduxStore), leadSeconds })
let reduxStore
let cancel = () => {}

const tokenRefresher = ({ reduxStore: store }) => {
  reduxStore = store
  tokenRefresher.reset(reduxStore)
    .then(() => {
      onwakeup(tokenRefresher.reset.bind(tokenRefresher, reduxStore))
    })
}
tokenRefresher.enabledOn = anyPass([onlyInDev, onlyInProduction])

const EnabledState = {
  onAuthorize: loginState => {
    cancel()
    cancel = start(reduxStore)(loginState.token)
    setupTokenWillExpire(({
      reduxStore,
      expires: refreshExpires(loginState)
    }))
  },

  onDeauthorize: () => {
    cancel()
    teardownTokenWillExpire()
  },

  // cancel current and setup again
  reset: async store => {
    const token = tokenSelector(store.getState())
    if (token) {
      if (isTokenExpired(token)) {
        await refresh(store)()
      }
      cancel()
      cancel = start(store)(token)
      setupTokenWillExpire({
        reduxStore,
        expires: refreshExpires(store.getState())
      })
    }
  }
}

const DisabledState = {
  onAuthorize: noop,
  onDeauthorize: noop,
  reset: noop
}

export default Object.assign(tokenRefresher, tokenRefresher.enabledOn() ? EnabledState : DisabledState)