import React from 'react'

import ManageExtensionTables from './ManageExtensionTables'

const ManageExtensionTablesWithCallbacks = props => {
  return (<ManageExtensionTables
    {...props}
/>)
}

export default ManageExtensionTablesWithCallbacks
