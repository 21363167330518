import React from 'react'
import { Icon } from 'antd'

// eslint-disable-next-line import/no-webpack-loader-syntax
import SVG from '-!svg-react-loader!../../../RevisionSessions/decoration-unreal.svg'

const UnrealIcon = props => (
  <Icon component={SVG} {...props} />
)

export default UnrealIcon