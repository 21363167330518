import React from 'react'
import { compose, branch, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { identity, prop } from 'ramda'

import { DropTarget } from 'react-dnd'
import { message } from 'antd'
import { DnDTypes } from 'dnd/model-dnd'
import { target, targetConnector, canDropIfItem } from 'utils/dnd'

import { DataFields, withData } from 'dom/dom'

import { clickOnEmptySpace } from 'actions/lanes'

import collapsable from 'hocs/collapsable'
import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import { setLane, renameLane } from 'engine/actions/lanes'
import { isVisibleRootObject } from 'model/constants'

import TreeLaneTitle from './TreeLaneTitle'
import LaneRoots from './LaneRoots'

import styles from './TreeLane.scss'

const TreeLane = ({ name, collapsed, isOverCurrent, canDrop, connectDropTarget = identity, toggleCollapse, handleRename, onClickOnEmptySpace }) => connectDropTarget(
  <div
    className={classNames(
      styles.lane,
      {
        [styles.collapsed]: collapsed,
        [styles.nodeHover]: isOverCurrent,
        [styles.isDropAllowed]: canDrop
      }
    )}
    {...withData(DataFields.laneName, name)}
  >
    <TreeLaneTitle
      name={name}
      collapsed={collapsed}
      handleRename={handleRename}
      toggleCollapse={toggleCollapse}
    />
    {!collapsed &&
      [
        <div key="content" className={styles.content} onClick={onClickOnEmptySpace}>
          <LaneRoots name={name} />
        </div>
      ]
    }
  </div>
)

export default compose(
  collapsable({ id: ({ name }) => name, defaultToCollapsed: true }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
  branch(
    prop('hasWriteAccess'),
    compose(
      connect(undefined, { moveToLane: setLane, renameLaneAction: renameLane }),
      DropTarget(DnDTypes.Node,
        target(canDropIfItem(isVisibleRootObject)),
        targetConnector({ overOptions: { shallow: true } })
      )
    )
  ),
  connect(
    null,
    {
      onClickOnEmptySpace: clickOnEmptySpace,
    }
  ),
  withHandlers({

    toggleCollapse: ({ toggleCollapse, revisionId, name }) => () => toggleCollapse(revisionId, name),

    handleRename: ({ name, renameLaneAction }) => async (newName) => {
      if (!await renameLaneAction(name, newName)) message.error('Can`t rename to an existing name.')
    },

    onDrop: ({ name, isOverCurrent, moveToLane }) => monitor => {
      if (!isOverCurrent) return;
      moveToLane(monitor.getItem(), name)
    },

    onHover: () => () => {},

  }),
)(TreeLane)
