import React from 'react'
import { Spin } from 'antd'
import { Switch, Route, Redirect } from 'react-router-dom'
import { values } from 'ramda'

import Sections from './TeamSections'
import routes from '../routes'
import TeamError from './TeamErrorLoading'

import SectionSwitcher from '../SectionSwitcher'

// TODO: use the styles to see correctly the Spin // but..., ain't this style useful everywhere.. maybe it should be wider!
const TeamRouter = ({ match, loading, error, team }) => (
  <Spin tip="Loading" spinning={!!loading}>
    {!error &&
      <Switch>
        <Route path={`${routes.team}/:sectionName`} render={props => <SectionSwitcher {...props} team={team} sections={values(Sections)} />} />
        <Redirect to={`${match.url}/${Sections.Members.name}`} />
      </Switch>
    }
    {error &&
      <TeamError error={error} />
    }
  </Spin>
)

export default TeamRouter