import { propEq } from 'ramda'
import { connect } from 'react-redux'
import { branch, compose } from 'recompose'

import Preferences from 'preferences/Preferences'

import { makeNodeExecutionStateSelector } from 'selectors/execution'
import makeNodeExecutionPathSelector from 'selectors/execution/makeNodeExecutionPathSelector'
import withSinglePreference from '../withSinglePreference'

/**
 * Injects execution feedback information for a given node (takes is id from prop with a selector
 * passed as arg "idFromProp".
 *
 * Injects the following props:
 *   - trackExecutionEnabled (Boolean): whether tracking feedback is currently enabled
 *   - executionState ({ execution_count: Int, status: NodeExecutionStateStatus }):
 *   - executionPath ({ pathBefore: Boolean, pathAfter: Boolean } ): execution info about previous/following node useful for defining continuous styles
 *
 * @param idFromProp the prop selector to get the node id
 */
const withNodeExecutionTracking = idFromProp => compose(
  withSinglePreference('trackExecutionEnabled', Preferences.GraphView.trackExecution),
  branch(
    propEq('trackExecutionEnabled', true),
    compose(
      connect(
        () => {
          const executionStateSelector = makeNodeExecutionStateSelector(idFromProp)
          const executionPathSelector = makeNodeExecutionPathSelector(idFromProp)
          return (state, props) => ({
            executionState: executionStateSelector(state, props),
            executionPath: executionPathSelector(state, props)
          })
        }
      ),
    ),
  ),
)

export default withNodeExecutionTracking