exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SynchIndicator__lzak3{display:-ms-flexbox;display:flex;padding-right:.3rem;padding-left:.3rem;-ms-flex-align:center;align-items:center}@-webkit-keyframes colorInbound__3gciQ{from{color:#3e3e3e}to{color:red}}@keyframes colorInbound__3gciQ{from{color:#3e3e3e}to{color:red}}@-webkit-keyframes colorOutbound__3cCx9{from{color:#3e3e3e}to{color:#9acd32}}@keyframes colorOutbound__3cCx9{from{color:#3e3e3e}to{color:#9acd32}}.icon__27yc7{color:#3e3e3e;font-size:1.2rem}.icon__27yc7.inbound__3iOzb{width:.1rem}.icon__27yc7.synching__W9bU5{-webkit-animation-duration:.2s;animation-duration:.2s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-direction:alternate;animation-direction:alternate}.icon__27yc7.synching__W9bU5.inbound__3iOzb{-webkit-animation-name:colorInbound__3gciQ;animation-name:colorInbound__3gciQ}.icon__27yc7.synching__W9bU5.outbound__14-LW{-webkit-animation-name:colorOutbound__3cCx9;animation-name:colorOutbound__3cCx9}", ""]);

// exports
exports.locals = {
	"SynchIndicator": "SynchIndicator__lzak3",
	"icon": "icon__27yc7",
	"inbound": "inbound__3iOzb",
	"synching": "synching__W9bU5",
	"colorInbound": "colorInbound__3gciQ",
	"outbound": "outbound__14-LW",
	"colorOutbound": "colorOutbound__3cCx9"
};