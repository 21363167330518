exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".State__1pRgR th,.State__1pRgR td{width:25%}.State__1pRgR .valueColumn__1XhYn{-ms-flex-positive:1;flex-grow:1}.State__1pRgR .potentialValuesColumn__YJs2B ol{display:-ms-flexbox;display:flex;list-style:none;padding:0;margin:0}.State__1pRgR .potentialValuesColumn__YJs2B ol li:not(:last-child)::after{content:\"|\";margin:.25em;color:rgba(10,10,10,.1)}.State__1pRgR .ant-table-empty .ant-table-body{display:none}.State__1pRgR .ant-table-thead>tr>th{border-bottom:1px solid #eaeaea}", ""]);

// exports
exports.locals = {
	"State": "State__1pRgR",
	"valueColumn": "valueColumn__1XhYn",
	"potentialValuesColumn": "potentialValuesColumn__YJs2B"
};