import { Sys } from 'beanie-engine-api-js'
import insertAfterId from './insertAfterId'

const breakScene = id => api => {
  const object = api.obj.get(id)
  const parent = object.get_parent()

  const jump = insertAfterId(parent.get_id(), Sys.jump)(api)

  jump.set_target(object.get_id())
  // un-parent
  object.set_parent(undefined)
  return object
}

export default breakScene