import React from 'react'
import { Icon } from 'antd'

import CurrentNode from './CurrentNode'

import styles from './CurrentNodePlayback.scss'

const VisibleCurrentNodePlayback = ({ nodeIds, nosound, toggleVisible }) => {
  return (
    <React.Fragment>
      <div className={styles.main}>
        {nodeIds.map(playbackId =>
          <CurrentNode key={playbackId} playbackId={playbackId} nosound={nosound} />
        )}
      </div>
      <div className={styles.minimizeButton}><Icon type="down" onClick={toggleVisible} /></div>
    </React.Fragment>
  )
}

export default VisibleCurrentNodePlayback