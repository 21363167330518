import { createReducer } from './utils'
import initState from './vm/init'
import LifeCycle from './vm/lifecycle'
import Playback from './vm/playback'
import State from './vm/state'
import Transactions from './vm/transactions'
import { LOGOUT } from '../actions/login'
import { RESET_PROJECT } from '../actions/project'

const Handlers = {
  ...LifeCycle,
  ...Playback,
  ...State,
  ...Transactions
}

function _vm(state = initState, action) {
  switch (action.type) {
    // RESET if: logout | project changes. Maybe we should reset the whole redux state ?
    case LOGOUT: return initState
    case RESET_PROJECT: return initState

    default: {
      const handler = Handlers[action.type]
      return handler ? handler(action, state) : state
    }
  }
}

export const vm = createReducer(_vm)
