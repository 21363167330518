import { connect } from 'react-redux'
import { applySpec, pickBy, when, keys, isNil } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

// TODO: support selectors spec with declarative scoping
// i.ex
//
//   connectSelectors({
//     project: projectSelector,  // global/custom selector
//     markupColor: [makeMarkupColorSelector, PER_COMPONENT]
//   })
//
// This would be the same as this boilerplate
//   connect(() => {
//     const markupColorSelector = makeMarkupColorSelector()
//
//     return (state, props) => ({
//       project: projectSelector,  // global/custom selector
//       markupColor: [markupColorSelector, PER_COMPONENT]
//     })
//
//   })
//
//

const connectState = spec => {
  when(isNotEmpty,
    unmet => { throw new Error(`Missing selectors for some keys: ${keys(unmet).join(', ')}`) }
  )(pickBy(isNil, spec))

  return connect(state => applySpec(spec, state))
}

export default connectState