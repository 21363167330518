import { flipObject } from 'utils/object'

export const GamepadButton = {
  A: 'A',
  B: 'B',
  X: 'X',
  Y: 'Y'
}

export const createGamepadDefinition = ({ type, matches, buttons, apiToButtonMapping, buttonToChoiceMapping }) => ({
  type,
  matches,
  buttons,
  apiToButtonMapping,
  buttonToChoiceMapping,
  choiceIndexToButtonMapping: flipObject(buttonToChoiceMapping),
})