import React from 'react'
import { formatTimestamp } from 'utils/dates'
import NodeId from '../Commons/NodeId'
import UserAvatar from '../RevisionSessions/UserAvatar'

import styles from './ChangeSetPanel.scss'

/**
 *
 */
const ChangeSetInfo = ({ changeSet }) => {

  const { _id, timestamp, author: { user } } = changeSet || {
    _id: '',
    timestamp: null,
    author: { user: { name: '' } }
  }
  return (
    <div className={styles.changeSetInfo}>
      <div className={styles.changeSetInfoRow}>
        <UserAvatar user={user} contextMenuEnabled={false} showPopOver={false} />
        <div className={styles.title}>
          <span className={styles.authorName}>{user.name}</span>
          <span className={styles.timestamp}>{timestamp && formatTimestamp(timestamp)}</span>
        </div>

        <div className={styles.changeId}>
          <span>ChangeSet ID</span>
          <NodeId id={_id} />
        </div>
      </div>
    </div>
  )
}

export default ChangeSetInfo