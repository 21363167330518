import React from 'react'
import { Tooltip } from 'antd'

/**
 * Shows the name of a variable. If it is dirty then it shows feedback
 */
const StateVariableName = ({ name, dirties }) => (dirties[name] ? (
  <span><Tooltip title="Unsaved change">*</Tooltip>{name}</span>
) : name
)
export default StateVariableName