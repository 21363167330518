exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__3YTp7{display:-ms-flexbox;display:flex;position:relative;-ms-flex-align:center;align-items:center;padding-left:.8rem}.content__3YTp7.isDropHover__1G9ww{background-color:red}.content__3YTp7 .noImage__2hJ0c{width:9vw;height:13vh;background-color:#f1f1f1;border-radius:10px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.content__3YTp7:not(.readOnly__lUe0i):hover{cursor:pointer}.content__3YTp7:not(.readOnly__lUe0i):hover>img{opacity:.5}.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-positive:1;flex-grow:1;color:#000}.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .changeCaption__1pLH4{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-positive:1;flex-grow:1}.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .upperLeftCorner__3036a{position:absolute;top:2px;left:5px}.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .upperRightCorner__1AgWf{position:absolute;top:2px;right:5px}.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .upperRightCorner__1AgWf .anticon:hover,.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .upperLeftCorner__3036a .anticon:hover,.content__3YTp7:not(.readOnly__lUe0i):hover .overlay__3FV93 .changeCaption__1pLH4:hover{color:#1890ff}.content__3YTp7 .overlay__3FV93{position:absolute;display:none;width:100%;height:100%}.content__3YTp7 .overlay__3FV93 span{font-size:.6em;font-style:normal}", ""]);

// exports
exports.locals = {
	"content": "content__3YTp7",
	"isDropHover": "isDropHover__1G9ww",
	"noImage": "noImage__2hJ0c",
	"readOnly": "readOnly__lUe0i",
	"overlay": "overlay__3FV93",
	"changeCaption": "changeCaption__1pLH4",
	"upperLeftCorner": "upperLeftCorner__3036a",
	"upperRightCorner": "upperRightCorner__1AgWf"
};