import { pipe, without, remove, prop, pathOr, propEq } from 'ramda'
import { resolve } from 'utils/promises'
import { emptyObjectToArray, EMPTY_ARRAY } from 'utils/object'
import { noop } from '../../utils/functions'
import insertAfterId, { connectToChildren } from './node/insertAfterId'

import { toBNEAction } from './utils'

import { Creators } from 'actions/ui'

import { selectNode, clearSelectedNodes } from 'actions/nodes'
import selectClosestNode from 'actions/nodes/selectClosestNode'

import { selectedObjects, rootObjects, projectObject, lanesFromProject } from 'selectors/objects'
import { simpleSelection } from 'selectors/selectors'
import makeComputeClosestNodeToSelectWhenDeleting from 'selectors/selection/nodes/closestNodeToSelectWhenDeleting'

const { confirmAction } = Creators

export const deleteSelectedNodes = () => (dispatch, getState) =>
  dispatch(deleteNodes(selectedObjects(getState())))

export const deleteLane = laneName => (dispatch, getState, { synchronizer }) =>
  synchronizer.doSynchingBNE(`Delete Lane ${laneName}`, api => {
    const { id: projectId } = projectObject(getState())
    const lanes = lanesFromProject(getState())
    const idx = lanes.findIndex(propEq('name', laneName))

    emptyObjectToArray(lanes[idx].roots).forEach(id => api.obj.delete(id))

    api.update(projectId, { editor: { lanes: remove(idx, 1, lanes) } })
    const selected = selectedObjects(getState())
    dispatch(selectClosestNode(selected))
  })

export const deleteNodes = nodesToDelete => async (dispatch, getState, args) => {
  const doDelete = () => deleteNodesWithoutConfirm(nodesToDelete)(dispatch, getState, args)
  const ns = nodesToDelete.filter(n => !n.data || (!n.data.parent && !!n.data.child))
  const needWarn = ns.length > 0
  const wrapping = !needWarn ? doDelete : pipe(
    fn => confirmAction(fn, 'Deleting a root node will delete the whole chain.'),
    dispatch,
    resolve
  )
  return wrapping(doDelete)
}

const deleteNodesFromProjectMetadata = (deletedNodes, rootNodes, project) => {
  const deletedIds = deletedNodes.map(prop('id'))
  const deletedRootIds = rootNodes
    .filter(root => deletedIds.includes(root.id))
    .map(prop('id'))
  return pathOr(EMPTY_ARRAY, ['data', 'editor', 'lanes'], project)
    .map(
      ({ name, roots }) => ({ name, roots: without(deletedRootIds, roots) })
    )
}

export const deleteNodesWithoutConfirm = nodesToDelete => async (dispatch, getState, { synchronizer }) => {
  const state = getState()
  const selected = selectedObjects(state)
  const closestNodeToSelect = makeComputeClosestNodeToSelectWhenDeleting(selected)(state)
  // delete
  await synchronizer.doSynchingBNE('Delete nodes', api => {
    const project = projectObject(state)
    const lanes = deleteNodesFromProjectMetadata(nodesToDelete, rootObjects(state), project)
    api.update(project.id, { editor: { lanes } })
    nodesToDelete.forEach(({ id }) => api.obj.delete(id))
    // clear selection to avoid inconsistent state (selection points to deleted object)
    dispatch(clearSelectedNodes())
  }, ...nodesToDelete)
  // now update back the selection (try to move to the "closest" node)
  return dispatch(closestNodeToSelect ? selectNode(simpleSelection(closestNodeToSelect)) : clearSelectedNodes())
}

const _addWithTemplate = (parent, template, connectionFn = connectToChildren) => api => {
  const created = template(api)
  connectionFn(api, created, api.obj.get(parent.id))
  return created
}
export const addWithTemplate = toBNEAction(_addWithTemplate)

/**
 * Action form to do `BNEApi.update(id, data)` which in turn does
 * `bne.obj.update( id, data )`. Does array and null/nil fixing
 */
export const _updateBNEObject = (id, deltaObject) => api => api.update(id, deltaObject)
export const updateBNEObject = toBNEAction(_updateBNEObject)

export const _insertNode = (afterNodeId, sys, initFn = noop, connectionFn) => api => {
  const object = insertAfterId(afterNodeId, sys, connectionFn)(api)
  initFn(object, api)
  return object
}
export const insertNode = toBNEAction(_insertNode)

export const evaluateScript = toBNEAction(
  script => api => api.evaluateScript(script),
  'Evaluate Script'
)
