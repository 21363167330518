exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ChangesFilter__2gJxI{padding-bottom:.5rem;display:-ms-flexbox;display:flex;padding-left:1rem;padding-right:1rem;width:100%;-ms-flex-align:center;align-items:center}.ChangesFilter__2gJxI>.ant-input{margin-right:1rem;max-width:50%}.ChangesFilter__2gJxI .ant-checkbox-wrapper>span:nth-child(2){font-size:.8rem}", ""]);

// exports
exports.locals = {
	"ChangesFilter": "ChangesFilter__2gJxI"
};