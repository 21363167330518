import { onError } from 'apollo-link-error'
import { Creators as NetworkActions } from 'actions/network'

const { connectionError } = NetworkActions

/**
 * Detects and error while doing a query/mutation because we got disconnected
 * or we could not reach the backend !
 * Dispatches a redux action in that case to handle network state 
 */
const createLink = ({ store }) => onError(e => {
  const { networkError } = e
  if (isNetworkDown(networkError)) {
    store.dispatch(connectionError('GraphQL disconnected'))
  }
})

// detect disconnection on any graphql query/mutation

// unfortunately there is no way to inspect the error from apollo.
// the toString() is the only way to get a message :(
const FAILED_FETCH_REGEX = /Failed to fetch/
const isNetworkDown = e => e && !e.statusCode && !e.result && FAILED_FETCH_REGEX.test(e.toString())

export default createLink

