import React, { useMemo } from 'react'
import AceEditor from '../AceEditor/AceEditor'

/**
 *
 */
const JsonEditor = ({ id, object }) => {
  const value = useMemo(() => JSON.stringify(object, null, 2), [object])
  return (
    <AceEditor
      mode="json"
      id={id}
      value={value}
      readOnly
      height="100%"
      width="100%"
    />
  )
}

export default JsonEditor