import { path, equals } from 'ramda'
import { createSelector } from './reselect'
import { nodeId, nodePath } from './props'

//

export const blockingTask = path(['ui', 'blockingTask'])
export const downloads = path(['ui', 'downloads'])
export const isEditing = path(['ui', 'editingNode'])

export const isDebugOn = path(['ui', 'debug'])

export const gamepadType = path(['ui', 'gamepad', 'type'])

export const makeIsEditingSelector = (idFromPropSelector = nodeId, pathFromPropSelector = nodePath) => createSelector(
  [idFromPropSelector, pathFromPropSelector, isEditing],
  (idNode, pathNode, editing) => {
    if (!editing) return false

    const { id, path: editingPath } = editing
    if (!pathNode) return equals(id, idNode)

    return equals(id, idNode) && equals(editingPath, pathNode)
  }
)

export const isGlobalSearchActive = path(['ui', 'globalSearchVisible'])

export const propertiesConflictDialog = path(['ui', 'propertiesConflictDialog'])
