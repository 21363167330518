import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'

import { isSynchingOutbound } from 'selectors/sync'
import withDebouncedProp from 'hocs/withDebouncedProp'

/* eslint-disable import/no-webpack-loader-syntax */
import SynchOutboundIcon from '-!svg-react-loader!./synch-outbound.svg'

import SynchingIndicatorIcon from './SynchIndicatorIcon'

const OutboundSynchIndicator = compose(
  connect(state => ({ isSynching: isSynchingOutbound(state) })),
  withDebouncedProp('isSynching'),
  withProps(({ debounced }) => ({ iconType: SynchOutboundIcon, iconStyle: 'outbound', isIconActive: debounced.value })),
)(SynchingIndicatorIcon)

export default OutboundSynchIndicator
