import React from 'react'
import classNames from 'classnames'

import styles from './TruthTable.scss'

export const DropZone = {
  Left: 'Left',
  Right: 'Right',
}

const DropFeedback = ({ className, zone = DropZone.Right }) => (
  <div className={styles.DropFeedback} >
    <div className={classNames(
      styles.handle,
      {
        [styles.leftZone]: zone === DropZone.Left,
        [styles.rightZone]: zone === DropZone.Right,
      },
      className
    )}
    />
  </div>
)

export default DropFeedback