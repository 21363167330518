import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withProps } from 'recompose'
import { Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import { pipe, path, applySpec } from 'ramda'

import { Creators } from './actions/player'
import { isLoading as isLoadingSelector } from './selectors/player'

import Header from './components/Header'
import VideoPlayer from './components/VideoPlayer/VideoPlayer'
import styles from './VideoGamePlayerApp.scss'

const { loadProject: loadProjectAction } = Creators

const VideoGamePlayerApp = ({ isLoading }) => (
  <div className={styles.page}>
    <Spin spinning={isLoading}>
      <Header />
      <VideoPlayer />
    </Spin>
  </div>
)

// TODO: improve
const parseSource = s => {
  const match = s.match(/source=(.*)/)
  return match.length > 1 ? match[1] : undefined
}

export default compose(
  connect(state => ({
    isLoading: isLoadingSelector(state)
  }), ({
    loadProject: loadProjectAction
  })
  ),
  withRouter,
  withProps(applySpec({
    projectId: pipe(path(['match', 'params', 'revisionId'])),
    source: pipe(path(['location', 'search']), parseSource),
  })),
  lifecycle({
    componentDidMount() {
      const { loadProject, projectId, source } = this.props
      loadProject(projectId, source)
    }
  })
)(VideoGamePlayerApp)