import React from 'react'
import { Form } from 'antd'

import InputEditComponent from './InputEditComponent'

const FormItem = Form.Item

const EditableContext = React.createContext()

const EditableRow = ({ form, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)

export const EditableFormRow = Form.create()(EditableRow)

// kind of a hacker impl
const isClickOnEditComponent = e => {
  // is choosing from an autocomplete option
  return e.target.classList.contains('ant-select-dropdown-menu-item')
}

export class TableEditableCell extends React.Component {
  state = {
    editing: !!this.props.alwaysEditing,
  }

  componentDidMount() {
    const { alwaysEditing, editable } = this.props
    if (!alwaysEditing && editable) {
      document.addEventListener('click', this.handleClickOutside, true)
    }
  }

  componentWillUnmount() {
    const { alwaysEditing, editable } = this.props
    if (!alwaysEditing && editable) {
      document.removeEventListener('click', this.handleClickOutside, true)
    }
  }

  toggleEdit = () => {
    if (this.props.alwaysEditing) { return }
    const editing = !this.state.editing
    const comp = this.editComponent
    this.setState({ editing }, () => {
      if (editing && comp && comp.focus) {
        comp.focus()
      }
    })
  }

  handleClickOutside = e => {
    const { editing } = this.state
    if (editing && this.cell !== e.target && !this.cell.contains(e.target) && !isClickOnEditComponent(e)) {
      this.save()
    }
  }

  save = () => {
    const { record, handleSave } = this.props
    this.form.validateFields((error, values) => {
      if (error) { return }
      this.toggleEdit()
      handleSave({ ...record, ...values })
    })
  }

  render() {
    const { editing } = this.state
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSave,
      editComponent: EditComponent = InputEditComponent,
      alwaysEditing,
      ...restProps
    } = this.props

    const onClick = !alwaysEditing && !editing ? this.toggleEdit : undefined

    /* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
    return (
      <td
        ref={node => { this.cell = node }}
        {...restProps}
        onClick={onClick}
      >
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form
              return (
                editing ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      initialValue: record[dataIndex],
                    })(
                      <EditComponent
                        ref={node => { this.editComponent = node }}
                        save={this.save}
                        cancel={this.toggleEdit}
                      />
                    )}
                  </FormItem>
                ) : (
                  <div className="editable-cell-value-wrap">
                    {restProps.children}
                  </div>
                )
              )
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    )
  }
}

export default TableEditableCell