import React from 'react'
import { Popover } from 'antd'

/*
 * Abstract base class
 */
export default class AbstractPopover extends React.Component {
  state = {
    visible: false
  }
  mounted = true

  componentWillUnmount() {
    this.mounted = false
  }

  setVisible = visible => {
    if (this.mounted) {
      this.setState(prev => ({ ...prev, visible }), this.onHidden)
    }
  }
  onHidden() {}

  hide = () => { this.setVisible(false) }
  handleVisibleChange(visible) {
    this.setVisible(visible)
  }

  handleValueChanged = async (...args) => {
    await this.props.onChanged(...args)
    this.hide()
  }

  render() {
    const { disabled, children } = this.props
    return disabled ? children : (
      <Popover
        content={this.renderPopContent()}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={::this.handleVisibleChange}
        {...this.props}
        {...this.getPopOverProps()}
      >
        {children}
      </Popover>
    )
  }

  renderPopContent() { throw new Error('subclass responsibility') }

  getPopOverProps() { return {} }

}