exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__2UCfn{position:relative;width:100%;height:100%;background-color:#fff}.content__2UCfn>.ant-tabs{height:100%}.content__2UCfn>.ant-tabs>.ant-tabs-content{height:100%}.content__2UCfn>.ant-tabs>.ant-tabs-bar{margin-bottom:0}", ""]);

// exports
exports.locals = {
	"content": "content__2UCfn"
};