import React from 'react'
import { graphql } from 'react-apollo'
import { compose, branch, withHandlers } from 'recompose'
import TeamAvatar from 'components/Organizations/Teams/Commons/TeamAvatar'
import OrganizationAvatar from 'components/Organizations/Commons/OrganizationAvatar'
import StringValue from 'components/PropertiesEditor/Value/StringValue'
import { IconDisposition } from 'components/PropertiesEditor/Value/EditableValue'

import { organizationUpdate } from 'api/mutations/organizationUpdate.graphql'
import { teamUpdate } from 'api/mutations/teamUpdate.graphql'

import secure from 'hocs/secure';
import { orgWriteAccess } from 'security/organization'
import { teamWriteAccess } from 'security/team'

import styles from './EntityLeftPanel.scss'

const EntityLeftPanel = ({ organization, team, handleDescriptionChanged, hasWriteAccess, entity = team || organization }) => (
  <div className={styles.leftPanel}>
    {team ?
      <TeamAvatar team={team} />
      : <OrganizationAvatar organization={organization} />
    }
    <div className={styles.info}>
      <h1>{entity ? entity.name : 'Loading'}</h1>
      <div className={styles.description}>
        <StringValue
          isEditable={!!entity && hasWriteAccess}
          showAcceptCancelIcons={false}
          value={entity ? entity.description : 'Loading'}
          placeholder={'< description >'}
          onValueChanged={handleDescriptionChanged}
          iconDisposition={IconDisposition.After}
        />
      </div>
    </div>
  </div>
)


const createDescriptionHandler = prop => ({ [prop]: container, updateDescription }) => async description => {
  await updateDescription({
    variables: {
      input: {
        [`${prop}Id`]: container._id,
        description
      }
    }
  })
}
export default compose(
  branch(
    ({ team }) => !!team,
    compose(
      secure('hasWriteAccess', teamWriteAccess),
      graphql(teamUpdate, { name: 'updateDescription' }),
      withHandlers({
        handleDescriptionChanged: createDescriptionHandler('team')
      })
    ),
    compose(
      secure('hasWriteAccess', orgWriteAccess),
      graphql(organizationUpdate, { name: 'updateDescription' }),
      withHandlers({
        handleDescriptionChanged: createDescriptionHandler('organization')
      })
    )
  ),
)(EntityLeftPanel)