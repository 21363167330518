exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".filterToolbar__2FRIR{padding-bottom:1rem}.filterToolbar__2FRIR>*:not(:last-child){margin-right:1rem}", ""]);

// exports
exports.locals = {
	"filterToolbar": "filterToolbar__2FRIR"
};