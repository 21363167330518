import { Value } from 'slate'
import { pipe } from 'ramda'
import { Sys } from 'beanie-engine-api-js'
import { clipToText } from './ClipSerializer'
import { emptyDirectorLine } from '../utils/slateMocks/directorLine'

export const parseModel = clip => {
  const { sys, data } = clip
  return (data && sys ? (serializers[sys] || emptySerializer)(clip) : emtpyNode)
}

export const modelToText = pipe(
  parseModel,
  ::Value.fromJSON
)

const serializers = {
  [Sys.clip]: clipToText,
}

const emptySerializer = () => emtpyNode

export const emtpyNode = { document: { nodes: [emptyDirectorLine] } }