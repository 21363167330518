
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"OthersRevisionSessionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RevisionSession"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"RevisionSessionFragment"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profileImage"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":343}};
    doc.loc.source = {"body":"#import \"./RevisionSession.graphql\"\n# A fragment for fetching/receiving session information for \"other\" sessions (not ours)\n# we need more details about the user\nfragment OthersRevisionSessionFragment on RevisionSession {\n    ...RevisionSessionFragment\n    user {\n      _id\n      email\n      firstName\n      lastName\n      profileImage\n    }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("./RevisionSession.graphql").definitions));


      module.exports = doc;
    
