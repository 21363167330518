import React from 'react'
import { Tabs, Icon } from 'antd'

import MarkupsTab from './MarkupsTab/MarkupsTab'
import TypesTab from './TypesTab/TypesTab'

import styles from 'styles/panel.scss'

const { TabPane } = Tabs

const MarkupsSection = () => (
  <div>
    <div className={styles.panel}>
      <Tabs>
        <TabPane tab={<span><Icon type="tags" />Markups</span>} key="1">
          <MarkupsTab />
        </TabPane>
        <TabPane tab={<span><Icon type="file-excel" />Types</span>} key="2">
          <TypesTab />
        </TabPane>
      </Tabs>
    </div>
  </div>
)

export default MarkupsSection
