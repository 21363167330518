import { pipe, prop, map } from 'ramda'
import BlockType from 'components/TextEditor/BlockType'
import { IntermediateModelTypes } from '../choicesEditorModel'

// CHOICES

const parseChoicesBlock = block => (blockParsers[block.type] || parseUnknown)(block)

const parseTitle = block => ({
  type: IntermediateModelTypes.title,
  text: block.text
})

const parseChoice = block => ({
  type: IntermediateModelTypes.choice,
  choice: block.data.choice,
  text: block.text
})

const parseUnknown = ({ text }) => ({ type: 'unknown', text })

const blockParsers = {
  [BlockType.choicesTitle]: parseTitle,
  [BlockType.choice]: parseChoice
}

const choicesParser = pipe(prop('blocks'), map(parseChoicesBlock))
export default choicesParser