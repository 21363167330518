exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".eventsTable__3ye7b tbody tr td{padding:2px !important}.eventsTable__3ye7b tbody tr td:first-child{padding-left:1rem !important}.eventsTable__3ye7b .ant-table-thead th:not(:last-child){border-right:1px solid #eaeaea}.eventsTable__3ye7b .ant-table-footer{padding-top:0;padding-bottom:0}.timestampColumn__VWF0N{width:15%}.nodeColumn__2b4xJ{width:30%}.nodeColumn__2b4xJ>div{font-size:.9em;max-width:130px;min-width:130px;cursor:pointer}.fieldColumn__tX9Wv{width:30%}.valueColumn__3bsbf{width:25%}.selectedRow__vMsof{background-color:#e5e5f0;font-weight:bold}", ""]);

// exports
exports.locals = {
	"eventsTable": "eventsTable__3ye7b",
	"timestampColumn": "timestampColumn__VWF0N",
	"nodeColumn": "nodeColumn__2b4xJ",
	"fieldColumn": "fieldColumn__tX9Wv",
	"valueColumn": "valueColumn__3bsbf",
	"selectedRow": "selectedRow__vMsof"
};