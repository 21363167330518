import React, { useState, useCallback } from 'react'

import { Icon, Tooltip } from 'antd'
import classNames from 'classnames'
import useEnabledRule from 'hooks/useEnabledRule'
import { model } from 'beanie-engine-api-js'
import NodeRuleEditor from '../TreeView/TreeChain/NodeWithEnabledRule/NodeRuleEditor'
import IconButton from '../IconButton/IconButton'
import Rule from '../Language/Rules/Rule/Rule'

import styles from './PropertiesEditor.scss'

const { types: { object: { hasRule } } } = model

const EnabledRuleProp = ({ node, editable }) => {
  const [editingMode, setEditingMode] = useState(false)
  const { setValue, value, onEnter } = useEnabledRule(node.id)

  const onCancel = useCallback(() => {
    setEditingMode(false)
  }, [editingMode, setEditingMode])

  const onEnterRule = useCallback(async () => {
    await onEnter()
    setEditingMode(false)
  }, [editingMode, setEditingMode, onEnter])

  const onEdit = useCallback(() => {
    if (editable) {
      setEditingMode(true)
    }
  }, [editable, editingMode, setEditingMode])

  const { data: { enabled_rule: { source } } } = node
  return (<div className={styles.exprField}>
    {editingMode ?
      <NodeRuleEditor
        containerClassName={styles.ruleEditor}
        setValue={setValue}
        value={value}
        node={node}
        onCancel={onCancel}
        onApply={onEnterRule}
        onBlur={onEnterRule} /> :
      <div className={classNames(styles.rule)} onDoubleClick={onEdit}>
        <Rule source={source} />
        {editable ?
          <IconButton className={styles.icon} tooltip="Edit" icon="edit" onClick={onEdit} /> :
          <Tooltip title="Non modifiable">
            <Icon type="lock" />
          </Tooltip>
          }
      </div>
      }
  </div>
  )
}

const EnabledRuleProperty = ({ object, editable }) => (
  <div className={styles.enabledRuleProperty}>
    {hasRule(object) ?
      <EnabledRuleProp editable={editable} node={object} /> :
      <div className={styles.noExprPlaceholder}>No rule</div>
    }
  </div>
)

export default EnabledRuleProperty