import React from 'react'
import { formatTimestamp } from 'utils/dates'
import { Tooltip } from 'antd'

const AbsoluteTime = ({ date }) => date && (
  <Tooltip title="">
    <span>{formatTimestamp(date)}</span>
  </Tooltip>
)

export default AbsoluteTime