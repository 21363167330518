import React from 'react'
import { Sys } from 'beanie-engine-api-js'
import { Icon } from 'model/label'

import styles from '../TextViewItem.scss'

const StopTextView = () => (
  <h3 className={styles.StopTextView}><Icon sys={Sys.stop} /></h3>
)

export default StopTextView