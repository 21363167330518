import React from 'react'

import { compose } from 'recompose'

import withProject from 'hocs/withProject'

import BranchSelector from 'components/BranchSelector/BranchSelector'
import RevisionSelector from 'components/RevisionSelector/RevisionSelector'
import ReadOnlyRevisionMessage from 'components/ReadOnlyRevisionMessage/ReadOnlyRevisionMessage'

import ProjectTitle from './ProjectTitle'

import styles from './CurrentProjectTitle.scss'

const MAX_PROJECT_TITLE_LENGTH = 10

export const CurrentProjectTitle = ({ project }) => (
  <div className={styles.container}>
    <ProjectTitle project={project} maxLength={MAX_PROJECT_TITLE_LENGTH} />

    <BranchSelector project={project} />
    <RevisionSelector project={project} />
    <ReadOnlyRevisionMessage />
  </div>
)

export default compose(withProject)(CurrentProjectTitle)
