import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import sentry from 'services/sentry'

import { Creators } from 'actions/vm'

import Notifications from 'utils/Notifications'
import { getTimeAsString } from 'utils/dates'

import PlaybackHistory from './PlaybackHistory/PlaybackHistory'
import PlaybackStoryboard from './PlaybackStoryboard/PlaybackStoryboard'
import Preferences, { PREF_SHOW_HISTORY, PREF_SHOW_STORYBOARD, PREF_SHOW_STORYBOARD_AND_HISTORY } from 'preferences/Preferences'
import { preference } from 'selectors/view'

import styles from './PlaybackView.scss'

const { stopPlayback } = Creators

const isShowStoryboard = ::[PREF_SHOW_STORYBOARD_AND_HISTORY, PREF_SHOW_STORYBOARD].includes
const isShowHistory = ::[PREF_SHOW_STORYBOARD_AND_HISTORY, PREF_SHOW_HISTORY].includes

const PlaybackView = ({ show }) => (
  <div className={styles.playbackCanvas}>
    {isShowStoryboard(show) &&
      <PlaybackStoryboard />
    }
    {isShowHistory(show) &&
      <PlaybackHistory />
    }
  </div>
)

export default compose(
  connect(() => {
    const playbackPanelCycleViewSelector = preference(Preferences.Playback.showStoryboardAndHistory)
    return state => ({
      show: playbackPanelCycleViewSelector(state)
    })
  }, {
    stopPlaybackAction: stopPlayback
  }),
  lifecycle({
    componentDidCatch(error, errorInfo) {
      stopPlayback()
      sentry.handleError(error, errorInfo)
      Notifications.notify({
        type: 'error',
        message: 'Playback stopped',
        description: 'Playback was stopped due to an application error.',
        key: getTimeAsString()
      })
    }
  })
)(PlaybackView)

