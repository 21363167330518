import { isNotEmpty } from 'ramda-adjunct'
import { map, without, pluck } from 'ramda'
import makeComputeClosestNodeToSelectWhenDeleting from 'selectors/selection/nodes/closestNodeToSelectWhenDeleting'
import { simpleSelection, selectedNodes } from 'selectors/nodeSelection'
import { selectNodes, selectNode } from '../nodes'

export const updateSelection = async (removedNodes, dispatch, getState) => {
  // calculate the next selection if the selection will be empty

  const closestNodeToSelect = makeComputeClosestNodeToSelectWhenDeleting(removedNodes)(getState())

  const selectedIds = selectedNodes(getState())

  const difference = map(id => ({ id }))(without(pluck('id', removedNodes), selectedIds))

  if (isNotEmpty(difference)) {
    return dispatch(selectNodes(difference))
  } else if (closestNodeToSelect) {
    return dispatch(selectNode(simpleSelection(closestNodeToSelect), false))
  }
}
