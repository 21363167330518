import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, lifecycle, withState, withHandlers, withProps } from 'recompose'
import { defaultTo, prop, pipe, pick, propOr, replace } from 'ramda'
import { flattenPath } from 'ramda-adjunct'
import { Select, Spin } from 'antd'
import { model, Sys } from 'beanie-engine-api-js'
import { firstUpperCase } from 'utils/string'
import { handlerSet } from 'utils/recompose'

import { playBase64 } from 'model/sound'
import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import { revisionId as revisionIdSelector } from 'selectors/project'

import IconButton from 'components/Commons/IconButton'
import { renderOption, groupByLocale } from 'components/PropertiesEditor/Value/VoiceValue'
import { renderOptionsOrGroups } from 'components/PropertiesEditor/Value/ChooseValue'
import { getMetadataFor } from '../../../model/metadata/UIMetadata'
import SpeechSlider from './SpeechSlider'
import Title from './Title'

import ttsVoicesQuery from 'api/queries/ttsVoices.graphql'
import ttsJobPreviewMutation from 'api/mutations/ttsJobPreview.graphql'

import styles from '../ActorDetail.scss'

const { types: { object: { Paths } } } = model

const transformActorNameForTagLine = pipe(
  firstUpperCase,
  replace(/_/g, ' ')
)
const TAGLINE = name => `Hi, my name is ${(transformActorNameForTagLine(name))} and this is a sample`

const speechRateMetadata = getMetadataFor(Sys.actor, Paths.actor.speechRate).type
const speechPitchMetadata = getMetadataFor(Sys.actor, Paths.actor.speechPitch).type

const TextToSpeechDetails = ({ voice, pitch, rate, modified, playing, handleConfirm, handleDiscard, promptTagline, enabled, handleVoiceChanged, handleVoiceRateChanged, handleVoicePitchChanged, availableVoices, setPitch, setRate }) => (
  <Fragment>
    <Title>
      <span>Text to Speech</span>
      <IconButton type="sound" disabled={playing || !voice} onClick={() => promptTagline()} tooltip="Preview Voice" />
      <IconButton type="close" disabled={!modified || playing} onClick={() => handleDiscard()} tooltip="Discard changes" />
      <IconButton type="check" disabled={!modified || playing} onClick={() => handleConfirm()} tooltip="Apply changes" />
    </Title>
    <div className={styles.item}>
      <div>Voice</div>
      <Spin tip="Getting TTS voices" spinning={availableVoices.loading}>
        <Select
          value={voice}
          onSelect={handleVoiceChanged}
          placeholder="None"
          disabled={!enabled}
          groupBy={groupByLocale}
          >
          {availableVoices.ttsVoices && renderOptionsOrGroups(voice, availableVoices.ttsVoices, groupByLocale, { keyFn: prop('_id'), labelFn: renderOption, valueFn: prop('_id') })}
        </Select>
      </Spin>
    </div>
    <SpeechSlider
      name="Rate"
      value={rate}
      disabled={!voice || !enabled}
      className={styles.slider}
      {...speechRateMetadata}
      onChange={setRate}
      onAfterChange={handleVoiceRateChanged}
    />
    <SpeechSlider
      name="Pitch"
      value={pitch}
      disabled={!voice || !enabled}
      className={styles.slider}
      {...speechPitchMetadata}
      onChange={setPitch}
      onAfterChange={handleVoicePitchChanged}
    />
  </Fragment>
)

const withDefault = (propName, defaultValue) => pipe(prop(propName), defaultTo(defaultValue))
export default compose(
  connect(state => ({ revisionId: revisionIdSelector(state) })),
  graphql(ttsVoicesQuery, { name: 'availableVoices' }),
  graphql(ttsJobPreviewMutation, { name: 'ttsJobPreview' }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
  withProps(pipe(
    propOr({ data: { editor: {} } }, 'actor'),
    flattenPath(['data', 'editor']),
    pick(['speechVoice', 'speechPitch', 'speechRate']),
  )),
  withState('playing', 'setPlaying', false),
  withState('voice', 'setVoice', prop('speechVoice')),
  withState('pitch', 'setPitch', withDefault('speechPitch', speechRateMetadata.default)),
  withState('rate', 'setRate', withDefault('speechRate', speechPitchMetadata.default)),
  withProps(({ playing, hasWriteAccess, speechVoice, voice, speechPitch, pitch, speechRate, rate }) => ({
    enabled: !playing && hasWriteAccess,
    modified: speechVoice !== voice
      || (speechPitch || speechPitchMetadata.default) !== pitch
      || (speechRate || speechRateMetadata.default) !== rate
  })),
  withHandlers({
    rollbackChanges: ({ setVoice, setPitch, setRate, speechVoice, speechPitch, speechRate }) => () => {
      setVoice(speechVoice)
      setPitch(speechPitch)
      setRate(speechRate)
    }
  }),
  withHandlers({
    promptTagline: ({ revisionId, actor, setPlaying, ttsJobPreview, voice, pitch, rate, rollbackChanges }) => async () => {
      setPlaying(true)
      try {
        const { data: { ttsJobPreview: { audio } } } = await ttsJobPreview({ variables: { input: {
          revisionId,
          text: TAGLINE(actor.data.actorName),
          speechVoice: voice,
          speechRate: rate,
          speechPitch: pitch
        } } })
        await playBase64(audio)
      } catch (e) {
        rollbackChanges()
      }
      setPlaying(false)
    },
  }),
  withHandlers({
    handleConfirm: ({ rollbackChanges, voiceChanged, voice: speechVoice, pitch: speechPitch, rate: speechRate, setVoice, setPitch, setRate }) => async () => {
      setVoice(speechVoice)
      setPitch(speechPitch)
      setRate(speechRate)
      try {
        await voiceChanged(speechVoice, speechPitch, speechRate)
      } catch (err) {
        rollbackChanges()
        throw err
      }
    },
    handleDiscard: ({ rollbackChanges }) => () => {
      rollbackChanges()
    }
  }),
  withHandlers({
    handleVoiceChanged: handlerSet('voice'),
    handleVoiceRateChanged: handlerSet('rate'),
    handleVoicePitchChanged: handlerSet('pitch'),
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { actor, setVoice, setPitch, setRate } = prevProps
      if (actor.id !== this.props.actor.id) {
        setVoice(this.props.speechVoice)
        setPitch(withDefault('speechPitch', speechPitchMetadata.default)(this.props))
        setRate(withDefault('speechRate', speechRateMetadata.default)(this.props))
      }
    }
  })
)(TextToSpeechDetails)

