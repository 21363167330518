exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".label__2T44e{display:inline-block;cursor:pointer !important;-webkit-transition:max-width 1s,max-height 1s;-o-transition:max-width 1s,max-height 1s;transition:max-width 1s,max-height 1s}.label__2T44e:not(.selected__2Dc-L){overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis;max-width:10em}.label__2T44e:not(.selected__2Dc-L).hoveringToNavigate__2RiCo{text-decoration:underline;color:#6363a9}.label__2T44e.selected__2Dc-L{max-width:800px;max-height:800px}.label__2T44e.selected__2Dc-L.hoveringToNavigate__2RiCo{text-decoration:underline}.label__2T44e.empty__3ai9P{opacity:.5;padding-left:.4rem}.label__2T44e.error__3yNfK{color:#e00a0a;text-decoration:none}.label__2T44e.error__3yNfK::before{content:\"\\26A0\\FE0F   \"}", ""]);

// exports
exports.locals = {
	"label": "label__2T44e",
	"selected": "selected__2Dc-L",
	"hoveringToNavigate": "hoveringToNavigate__2RiCo",
	"empty": "empty__3ai9P",
	"error": "error__3yNfK"
};