import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { identity, pipe, pathEq, anyPass } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { compose, withHandlers, shouldUpdate } from 'recompose'
import { Icon, Spin } from 'antd'
import classNames from 'classnames'
import { hasText, model } from 'beanie-engine-api-js'

import { arePositionsDifferent } from 'utils/dnd/position'
import { nodeIdFor, DataFields, withData } from 'dom/dom'
import { whenAnyChangesOn } from 'utils/shouldUpdate'
import { anyIsError, anyIsWarning } from 'providers/Checker/Checker'

// hocs
import withNodeSelection from 'hocs/node/withNodeSelection'
import withNodeEditing from 'hocs/node/withNodeEditing'
import withNodeIsEnabled from 'hocs/withNodeIsEnabled'
import withStaticAnalysis from 'hocs/withStaticAnalysis'
import { getMetadataForSys } from '../../../model/metadata/UIMetadata'
import { makeIssuesForNodeSelector } from '../../../selectors/state/executions'
import withNodeDragAndDrop from '../dnd/withNodeDragAndDrop'

// selectors
import { makeGetNodeMatches } from 'selectors/walklist'
import { isDebuggable } from 'selectors/debuggingData'

import TreeNodeDecorations from '../TreeNodeDecorations/TreeNodeDecorations'
import TreeNodeCursors from '../TreeNodeCursors/TreeNodeCursors'
import NodeLabel from '../../Commons/NodeLabel'
import TreeNodeEdit from '../TreeNodeEdit'
import TreeNodeExecutionDecoration from '../TreeNodeExecutionDecoration/TreeNodeExecutionDecoration'
import NodeIssuesIcon from './NodeIssuesIcon'
import nodeStylesForNode from './nodeStylesForNode'
import WalkListNodeBadge from './WalkListNodeBadge'
import StaticProblemsFeedback from './StaticProblemsFeedback'
import { DebugIcon } from 'components/Commons/DebugIcon'

import '../EditableText.css'

import styles from '../TreeView.scss'
import domStyles from 'dom/dom.scss'

const { types: { object: { isDisabled, isChoice, Paths } } } = model

const TreeChainNode = props => {

  const {
    node, nodeType, isRoot, onClicked, handleNodeDoubleClick, isCursorAtIt,
    // selection
    isSelected, onNodeSelectedAction,
    //
    isUnderSyncOperation, isEditing, editEndAction, onEditCompleted,
    //
    onToggleCollapse, expanded,
    // dnd - drag
    dndWrapper, isDragging,
    // dnd - drop
    connectDropTarget, droppingLeft, droppingRight, droppingAbove, droppingBelow,

    //
    isEnabled, isForcedToShowDisabled, staticIssues,
  } = props

  const wrapper = pipe(
    dndWrapper,
    isUnderSyncOperation ? e => <Spin tip="Saving..." spinning>{e}</Spin> : identity,
  )

  const isDebuggableSelector = useMemo(() => isDebuggable(node.id), [node.id])
  const debuggable = useSelector(isDebuggableSelector)

  const anyErrorIssue = anyIsError(staticIssues)
  const anyWarningIssue = anyIsWarning(staticIssues)

  const runtimeIssuesSelector = useMemo(() => makeIssuesForNodeSelector(node.id), [node.id])
  const runtimeIssues = useSelector(runtimeIssuesSelector)

  const isDisabledNode = (isDisabled(node) || !isEnabled) && !debuggable
  const metadata = getMetadataForSys(node.sys)

  const walkListMatchSelector = useMemo(() => makeGetNodeMatches(() => node.id), [node.id])
  const walkListMatch = useSelector(walkListMatchSelector)

  return (
    <TreeNodeExecutionDecoration id={node.id}>
      {wrapper(
        <div
          className={classNames(
            styles.bneContainerRow,
            styles.bneNode,
            domStyles.node,
            {
              [styles.root]: isRoot,
              [styles.disabledNode]: isForcedToShowDisabled || isDisabledNode,
              [styles.selected]: isSelected,
              [styles.cursorOver]: isCursorAtIt,
              [styles.editing]: isEditing,
              [styles.runtimeIssues]: runtimeIssues && isNotEmpty(runtimeIssues),

              ...!!connectDropTarget && {
                [styles.dragging]: isDragging,

                [styles.droppingLeft]: droppingLeft,
                [styles.droppingRight]: droppingRight,
                [styles.droppingAbove]: droppingAbove,
                [styles.droppingBelow]: droppingBelow,
              },
              ...walkListMatch && {
                [styles.walklistMatch]: true,
                [styles.walklistCurrentItem]: walkListMatch.current || walkListMatch.currentIndex >= 0,
              },
              ...staticIssues && isNotEmpty(staticIssues) && {
                [styles.issueError]: anyErrorIssue,
                [styles.issueWarning]: anyWarningIssue,
                [styles.issueInfo]: !anyErrorIssue && !anyWarningIssue,
              },
              // choice
              [styles.autoChoice]: isChoice(node) && pathEq(Paths.choice.auto_choice, true, node)
            },
            styles[node.sys],
            ...nodeStylesForNode(node),
          )}
          id={nodeIdFor(node.id)}
          {...withData(DataFields.nodeId, node.id)}
          onClick={onClicked}
          onDoubleClick={handleNodeDoubleClick}
          tabIndex="-1"
          role="button"
        >

          {runtimeIssues && isNotEmpty(runtimeIssues) &&
            <NodeIssuesIcon issues={runtimeIssues} />
          }

          {nodeType && (
            <div className={styles.NodeType}>{nodeType}</div>
          )}
          <DebugIcon debuggable={debuggable} />
          <TreeNodeCursors node={node} />
          <TreeNodeDecorations node={node} />
          {onToggleCollapse &&
            <div className={classNames(styles.toggleCollapse, { [styles.collapsed]: !expanded })} role="button" tabIndex={-1} onClick={onToggleCollapse}>
              <Icon type="down" />
            </div>
          }

          {metadata?.icon && <Icon type={metadata.icon} />}
          {hasText(node) &&
            (isEditing ?
              <TreeNodeEdit node={node} onEditCompleted={onEditCompleted} onCancel={editEndAction} />
            : <NodeLabel node={node} showTooltip={!isDragging} isSelected={isSelected} onNodeSelectedAction={onNodeSelectedAction} />
            )
          }
          {walkListMatch && <WalkListNodeBadge nodeId={node.id} walkListMatch={walkListMatch} />}
          {staticIssues && isNotEmpty(staticIssues) &&
            <StaticProblemsFeedback isError={anyErrorIssue} isWarning={anyWarningIssue} issues={staticIssues} />
          }
        </div>
      )}
    </TreeNodeExecutionDecoration>
  )
}

export const handlersHOCs = [
  withNodeSelection(),
  withNodeEditing(),

  // edit and selection
  withHandlers({
    handleNodeDoubleClick: ({ selectToEndAction, selectToBeginningAction, isEditing }) => event => {
      event.stopPropagation()
      if (isEditing) { return }
      if (event.altKey) {
        selectToBeginningAction()
      } else {
        selectToEndAction()
      }
    },
  }),
  withNodeDragAndDrop,
]

export default compose(
  withNodeIsEnabled(),
  withStaticAnalysis(),
  // TODO: check if there is the performance need of stop re-render here for certain props regarding handlerHOCs!
  ...handlersHOCs,
  shouldUpdate(
    anyPass([
      arePositionsDifferent,
      whenAnyChangesOn([
        'node',
        'relationship',

        'isSelected',
        'expanded',

        'isEditing',
        'isUnderSyncOperation',

        // DND
        'isDragging',
        'droppingLeft', 'droppingRight', 'droppingAbove', 'droppingBelow',

        //
        'staticIssues',

        'isEnabled',
        'isForcedToShowDisabled',
      ])
    ])
  )
)(TreeChainNode)

