import React from 'react'
import { useDispatch } from 'react-redux'
import { Popconfirm } from 'antd'
import IconButton from 'components/Commons/IconButton'
import TableRowActions from 'components/Commons/TableRowActions'

import { copySomethingToClipboard } from 'actions/clipboard'
import { urlForSignupInvite } from 'actions/routing'

const InvitesRowActions = ({
  invite, deleteInvite, resendInvite,
  // derived
  inviteURL = urlForSignupInvite(invite.token),
  accepted = !!invite.accepted_at
}) => {
  const dispatch = useDispatch()
  return (
    <TableRowActions>

      <IconButton type="reload" tooltip="Resend email" disabled={accepted} onClick={() => resendInvite(invite)} />
      <IconButton type="copy" tooltip="Copy URL" disabled={accepted} onClick={() => dispatch(copySomethingToClipboard(inviteURL, `Copied ${inviteURL}`))} />

      <Popconfirm title="Are you sure" okText="Yes" cancelText="No" onConfirm={() => deleteInvite(invite)} disabled={accepted}>
        <IconButton type="delete" tooltip="Delete" disabled={accepted} />
      </Popconfirm>
    </TableRowActions>
  )
}

export default InvitesRowActions