import { Annotation, KeyUtils, Value } from 'slate'
import { reduce, concat, map, max, min, pipe } from 'ramda'
import { CHANGE_TYPE, isInsertEdition, isReplaceEdition } from './lineChange'
import { ANNOTATION_TYPES, NODE_TYPES, OBJECT_TYPES } from '../Constants'
import { EMPTY_ARRAY } from 'utils/object'

const mostLeftTextNodeFromNode = (node, path = []) => ((node.nodes) ?
  mostLeftTextNodeFromNode(node.nodes.first(), [...path, 0]) : ({ textNode: node, path }))

const mostRightTextNodeFromNode = (node, path = []) => ((node.nodes) ?
  mostRightTextNodeFromNode(node.nodes.last(), [...path, node.nodes.size - 1]) : ({ textNode: node, path }))

const annotationFromRange = (anchor, focus, type, data = {}) => Annotation.fromJSON({
  key: KeyUtils.create(),
  object: OBJECT_TYPES.annotation,
  anchor,
  focus,
  type,
  data
})

const annotationsFromAddDialogueLine = (lineIndex, diagLine) => {
  const actorPart = diagLine.nodes.get(0)
  const textPart = diagLine.nodes.get(1)
  return [addAnnotationFromPhysicalLine([lineIndex, 0], actorPart), addAnnotationFromPhysicalLine([lineIndex, 1], textPart)]
}

const addAnnotationFromPhysicalLine = (prevPath, physicalLine) => {
  const { textNode: firstTextNode, path: firstTextPath } = mostLeftTextNodeFromNode(physicalLine, prevPath)
  const { textNode: lastTextNode, path: lastTextPath } = mostRightTextNodeFromNode(physicalLine, prevPath)
  return annotationFromRange({
    path: firstTextPath,
    key: firstTextNode?.key,
    offset: 0,
  }, {
    path: lastTextPath,
    key: lastTextNode?.key,
    offset: lastTextNode?.text.length
  }, ANNOTATION_TYPES.ADD_LINE_ANNOTATION)
}

const annotationsFromAddDirectorLine = (lineIndex, dirLine) => [addAnnotationFromPhysicalLine([lineIndex], dirLine)]

export const annotationsFromAddLine = ({ lineIndex, data: { node } }) => 
  (node.type === NODE_TYPES.DIALOGUE_LINE ? annotationsFromAddDialogueLine : annotationsFromAddDirectorLine)(lineIndex, node)

const deleteAnnotationRangeForDialogueLine = (index, aboveNode) => {
  const node = aboveNode.nodes.get(1)
  const { textNode: firstTextNode, path: firstTextPath } = mostLeftTextNodeFromNode(node, [index, 1])
  const { textNode: lastTextNode, path: lastTextPath } = mostRightTextNodeFromNode(node, [index, 1])
  return ({
    anchor: {
      path: firstTextPath,
      key: firstTextNode.key,
      offset: 0,
    },
    focus: {
      path: lastTextPath,
      key: lastTextPath.key,
      offset: lastTextNode?.text.length,
    }
  })
}

export const deleteLineAnnotationRangeForDirectorLine = (index, node) => {
  const { textNode: firstTextNode, path: firstTextPath } = mostLeftTextNodeFromNode(node, [index])
  const { textNode: lastTextNode, path: lastTextPath } = mostRightTextNodeFromNode(node, [index])
  return ({
    anchor: {
      path: firstTextPath,
      key: firstTextNode.key,
      offset: 0,
    },
    focus: {
      path: lastTextPath,
      key: lastTextPath.key,
      offset: lastTextNode?.text.length,
    }
  })
}

export const annotationFromDeleteline = ({ lineIndex }, { document: { nodes } }) => {
  const index = max(0, lineIndex - 1)
  const aboveNode = nodes.get(index) || nodes.last()

  const { anchor, focus } = (aboveNode.type === NODE_TYPES.DIALOGUE_LINE ?
    deleteAnnotationRangeForDialogueLine : deleteLineAnnotationRangeForDirectorLine)(index, aboveNode)

  return ([annotationFromRange(anchor, focus, ANNOTATION_TYPES.DELETE_LINE_ANNOTATION)])
}

const findChildWithOffset = (offset, nodes) => {
  let currentOffset = offset
  let child
  let index

  if (nodes.size === 1) return { index: 0, child: nodes.get(0), offset }

  for (index = 0; index < nodes.size; index++) {
    child = nodes.get(index)
    if (child.text.length < currentOffset) {
      currentOffset -= child.text.length
    } else {
      break
    }
  }

  if (index === nodes.size) { index-- }

  return { index, child, offset: currentOffset }
}

export const textDataByPoint = (offset, node, path) => {
  if (node.object === OBJECT_TYPES.text) return { key: node.key, path, offset: min(offset, node.text.length) }

  const { index, child, offset: newOffset } = findChildWithOffset(offset, node.nodes)

  return textDataByPoint(newOffset, child, [...path, index])
}

const findRangeForEditedTexts = (physicalPath, { start, value, currentNode }) => {
  const { key: anchorTextKey, path: anchorTextPath, offset: anchorOffset } = textDataByPoint(start, currentNode, physicalPath)
  const { key: focusTextKey, path: focusTextPath, offset: focusOffset } = textDataByPoint(start + value.length, currentNode, physicalPath)

  return {
    anchor: {
      key: anchorTextKey,
      path: anchorTextPath,
      offset: anchorOffset
    },
    focus: {
      key: focusTextKey,
      path: focusTextPath,
      offset: focusOffset
    }
  }
}

const dialoguePhysicalLinePath = (lineIndex, type) => [lineIndex, type === NODE_TYPES.ACTOR_PART ? 0 : 1] 

export const annotationsFromEditLine = ({ data: { editions, typeLineSwitched }, lineIndex }, { document: { nodes: lines } }) => {
  // If the type's line changed, then we need show the complete line as new content
  if (typeLineSwitched) return annotationsFromAddLine({ lineIndex, data: { node: lines.get(lineIndex) } }) 

  return map(edit => {
    const physicalLinePath = edit.physicalLineTypeEdited === NODE_TYPES.DIRECTOR_LINE ? [lineIndex] :
      dialoguePhysicalLinePath(lineIndex, edit.physicalLineTypeEdited)

    const { anchor, focus } = findRangeForEditedTexts(physicalLinePath, edit)
    return annotationFromRange(anchor, focus,
      isReplaceEdition(edit) ?
        ANNOTATION_TYPES.REPLACE_TEXT_ANNOTATION : isInsertEdition(edit) ?
          ANNOTATION_TYPES.INSERT_TEXT_ANNOTATION : ANNOTATION_TYPES.REMOVE_TEXT_ANNOTATION,
      edit)
  })(editions)
}

const SIMPLE_ANNOTATIONS = {
  [CHANGE_TYPE.ADD]: annotationsFromAddLine,
  [CHANGE_TYPE.DELETE]: annotationFromDeleteline,
  [CHANGE_TYPE.EDIT]: annotationsFromEditLine,
}

export const valueWithChangeAnnotations = (value, changes) => pipe(
  reduce((currentAnnotations, change) =>
    concat(currentAnnotations, SIMPLE_ANNOTATIONS[change.type](change, value)), EMPTY_ARRAY),
  reduce((v, annotation) => v.addAnnotation(annotation), Value.fromJS(value.toJS())))(changes)
