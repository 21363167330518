import React from 'react'

/**
 *
 */
const ConsoleMessage = ({ type, value }) => (
  <div className={`react-console-message${type ? ` react-console-message-${type}` : ''}`}>
    {value.map(val => (
      typeof val === 'string' ? val : JSON.stringify(val)
    )).join('\n')}
  </div>
)

ConsoleMessage.defaultProps = {
  type: null,
  value: [],
}

export default ConsoleMessage