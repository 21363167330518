import React from 'react'
import { pipe, prop } from 'ramda'
import { compose, withStateHandlers, withHandlers, shouldUpdate } from 'recompose'
import { connect } from 'react-redux'

import { selectedObject as selectedObjectSelector } from 'selectors/objects'
import { localGraphForSelected as graphSelector, getRadiusBySys, DEFAULT_RADIUS } from 'selectors/graph'

import { clientHeight, clientWidth } from 'utils/browser'

const GraphView = ({ object, graph, highlightNodes, highlightLink, handleNodeClick, handleNodeHover, handleLinkHover }) => {

  // PERFORMANCE: we don't want to globally import this lib because it sets global state into windows
  // Seems like ThreeJS does. So we do it on demand.
  // eslint-disable-next-line global-require
  const { ForceGraph2D } = require('react-force-graph')

  return (
    <ForceGraph2D
      width={clientWidth * 0.45}
      height={clientHeight * 0.8}
      graphData={graph}
      nodeRelSize={DEFAULT_RADIUS}
      linkWidth={link => (link === highlightLink ? 5 : 1)}
      linkDirectionalParticles={4}
      linkDirectionalParticleWidth={link => (link === highlightLink ? 4 : 0)}
      nodeCanvasObject={paintNode(highlightNodes, object)}
      enableNavigationControls
      enablePointerInteraction
      onNodeClick={handleNodeClick}
      onNodeHover={handleNodeHover}
      onLinkHover={handleLinkHover}
    />
  )
}

// paiting nodes

const highlightColor = '#1890ff'
const paintNode = (highlightNodes, selected) => (node, ctx) => {
  const radius = (selected && node.id === selected.id ? 5 : 1) * getRadiusBySys(node.sys)

  if (highlightNodes.indexOf(node.id) !== -1) { // add ring
    ctx.beginPath()
    ctx.arc(node.x, node.y, radius + (DEFAULT_RADIUS * 0.4), 0, 2 * Math.PI, false)
    ctx.fillStyle = highlightColor
    ctx.fill()
  }

  ctx.beginPath()
  ctx.arc(node.x, node.y, radius, 0, 2 * Math.PI, false)
  ctx.fillStyle = node.fill
  ctx.fill()
}

// containers

const id = prop('id')
export default compose(
  shouldUpdate(({ object }, { object: newObject }) => id(object) !== id(newObject)),
  connect(state => {
    const _graphSelector = graphSelector()
    return {
      object: selectedObjectSelector(state),
      graph: _graphSelector(state)
    }
  }),
  withStateHandlers(
    ({ object }) => ({ highlightNodes: object ? [object.id] : [], highlightLink: null }),
    {
      handleNodeHover: prev => node => ({ ...prev, highlightNodes: node ? [node.id] : [] }),
      handleLinkHover: prev => link => ({
        ...prev,
        highlightLink: link,
        highlightNodes: link ? [link.source, link.target] : []
      })
    }
  ),
  withHandlers({
    handleNodeClick: ({ onSelect }) => pipe(prop('id'), onSelect)
  })
)(GraphView)
