import React from 'react'
import { connect } from 'react-redux'
import { compose, withContext, lifecycle } from 'recompose'
import PropTypes from 'prop-types'

import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import { Creators as appMetaCreators } from 'actions/appMeta'

import AuthorizedRoute from 'containers/Auth/AuthorizedRoute'
import Login from 'components/Login/Login'

import SecureContext from 'components/Commons/SecureContext'

import WithRevisionLayout from 'components/ProjectLayout/WithRevisionLayout'
import HomepageLayout from 'components/Homepage/Homepage'
import InvitesAccess from 'components/InvitesAccess/InvitesAccessContainer'
import ForgotPasswordAccess from 'components/ForgotPassword/ForgotPasswordAccessContainer'
import OrganizationRouter from 'components/Organizations/OrganizationRouterContainer'
import organizationRoutes from 'components/Organizations/routes'
import PlatformRouter from 'components/Platform/ManagePlatformContainer'
import platformRoutes from 'components/Platform/routes'
import ConnectedSystemClipboard from 'components/SystemClipboardListener/ConnectedSystemClipboard'
import { INVITES_PATH, PASSWORD_RESET_PATH } from './routes'

import keymap from 'shortcuts/keymap'
import { ShortcutManager } from 'react-shortcuts'
import { EMPTY_OBJECT } from 'utils/object'

import { isPriviledgedUser } from 'utils/authentication'

// dnd
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContextProvider } from 'react-dnd'

// modal
import { ModalProvider } from 'components/Commons/Modal'
import VideoGamePlayerAppContainer from 'components/VideoGamePlayerApp/VideoGamePlayerAppContainer'
import { appVersion } from 'selectors/appMeta'

const shortcutManager = new ShortcutManager(keymap)

export const AppProviders = ({ history, children }) => (
  //  <Suspense maxDuration={1000} fallback={'Loading...'}>
  <DragDropContextProvider backend={HTML5Backend}>
    {/* <React.StrictMode> */}
    <SecureContext.Provider value={EMPTY_OBJECT}>
      <ConnectedRouter history={history}>
        <ConnectedSystemClipboard>
          {children}
        </ConnectedSystemClipboard>
      </ConnectedRouter>
    </SecureContext.Provider>
    {/* </React.StrictMode> */}
  </DragDropContextProvider>
  //   </Suspense>
)

const App = ({ history }) => (
  <AppProviders history={history}>
    <ModalProvider>
      <Switch>
        {/* DEMO! */}
        <Route path="/video/:revisionId" component={VideoGamePlayerAppContainer} />

        <Route exact path="/login" component={Login} />
        <Route path={PASSWORD_RESET_PATH} component={ForgotPasswordAccess} />
        <Route path={INVITES_PATH} component={InvitesAccess} />

        <AuthorizedRoute path={organizationRoutes.organization} component={OrganizationRouter} />

        <AuthorizedRoute path={platformRoutes.platform} component={PlatformRouter} requires={isPriviledgedUser} />

        <AuthorizedRoute path={organizationRoutes.oldProjectRoute} component={WithRevisionLayout} />

        <AuthorizedRoute exact path="/" component={HomepageLayout} />
      </Switch>
    </ModalProvider>
  </AppProviders>
)

export default compose(
  connect(
    state => ({
      version: appVersion(state)
    }),
    { setMetaAtStartup: appMetaCreators.setMetaAtStartup }
  ),
  withContext(
    { shortcuts: PropTypes.object.isRequired },
    () => ({ shortcuts: shortcutManager })
  ),
  lifecycle({
    componentDidMount() {
      const { setMetaAtStartup } = this.props
      setMetaAtStartup()
    }
  })
)(App)
