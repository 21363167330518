import React from 'react'
import { message } from 'antd'

import { NODE_TYPES } from './Constants'
import { textToModel } from '../textToModel/TextSerializer'
import { modelToText } from '../modelToText/ModelSerializer'
import { isDirty } from './editorUtils'
import sentry from 'services/sentry'
import { safeToJS } from 'utils/object'

import DirectorLine from '../Components/DirectorLine/DirectorLine'
import DialogueLineBlock from '../Components/DialogueLineBlock/DialogueLineBlock'
import ActorPart from '../Components/ActorPart/ActorPart'
import TextPart from '../Components/TextPart/TextPart'
import PerformanceNote from '../Components/Note/PerformanceNote/PerformanceNote'
import ProductionNote from '../Components/Note/ProductionNote/ProductionNote'
import MarkUp from '../Components/MarkUp/MarkUp'
import MarkUpName from '../Components/MarkUp/MarkUpName'
import MarkUpParameterName from '../Components/MarkUp/MarkUpParameterName'
import MarkUpParameterValue from '../Components/MarkUp/MarkUpParameterValue'
import MarkupParameterValueList from '../Components/MarkUp/MarkupParameterValueList'
import MarkupParameterValueListElement from '../Components/MarkUp/MarkupParameterValueListElement'
import RecoverMessage, { ON_SAVE_ERROR_MESSAGE } from 'components/SlateTextEditor/RecoverMessage'

import decorateNode from '../handlers/decorateNode'
import renderDecoration from '../handlers/renderDecoration'

const noopStep = (obj, model) => model
const performChecksDefault = noopStep
const commitChangesDefault = noopStep
const modelToModelDefault = noopStep

export const handlers = {

  onBlur: props => (event, editor, next) => {
    handlers.onSave(props)(editor.value)
    return next()
  },

  onChange: ({ setValue, onChange, setInitialized, setDirty, initialized, value: prevValue, dirty }) => editor => {
    const { value } = editor

    if (prevValue !== value) {
      setValue(value)
      if (initialized && !dirty && isDirty(prevValue, value)) { setDirty(true) }
      if (initialized && onChange) { onChange(value) }

      if (!initialized && prevValue.document.text === value.document.text) {
        setInitialized(true)
      }
    }
  },

  onSave: ({ applyingChanges, object, setValue, dirty, setDirty, setMessage, commitChangesAction = commitChangesDefault, modelToModelAction = modelToModelDefault, setApplyingChanges, performChecksAction = performChecksDefault }) => value => {
    if (dirty && !applyingChanges) {
      setApplyingChanges(true, async () => {
        let model
        let transformedModel
        let checkedModel
        try {
          model = textToModel(value)
          transformedModel = modelToModelAction(object, model)
          checkedModel = await performChecksAction(object, transformedModel)
          await commitChangesAction(object, checkedModel)
        } catch (error) {
          const baseValue = modelToText(object)
          message.error(error.message)
          // rescue overriden content at 'value' variable
          setMessage({ type: 'error', message: RecoverMessage(baseValue, value, ON_SAVE_ERROR_MESSAGE) })
          setValue(baseValue)
          const extraInfo = { object, value: safeToJS(value), dirty, model: safeToJS(model), transformedModel: safeToJS(transformedModel), checkedModel: safeToJS(checkedModel) }
          sentry.handleError(error, { desc: 'Error saving text editor captured', ...extraInfo })
        } finally {
          setApplyingChanges(false)
          setDirty(false)
        }
      })
    }
  },
  renderBlock: () => (props, editor, next) => {
    const { type } = props.node
    const Component = componentsByBlockType[type]
    return Component ? <Component {...props} /> : next()
  },

  renderInline: () => (props, editor, next) => {
    const { type } = props.node
    const Component = componentsByInlineType[type]
    return Component ? <Component {...props} /> : next()
  },

  renderMark: () => (props, editor, next) => {
    const { type } = props.mark
    const Component = componentByMarkType[type]
    return Component ? <Component {...props} /> : next()
  },
  renderDecoration,
  decorateNode,

  /* eslint no-unused-vars: 0 */
  onDrop: () => (event, editor, next) => {
    // cuts execution (doesn't call next) because dragging storyboards
    // was making slate fail. I'm not sure how to just filter that particular drop event
  }
}

export const componentsByInlineType = {
  [NODE_TYPES.PERFORMANCE_NOTE]: PerformanceNote,
  [NODE_TYPES.PRODUCTION_NOTE]: ProductionNote,
  [NODE_TYPES.MARK_UP]: MarkUp,
  [NODE_TYPES.MARK_UP_NAME]: MarkUpName,
  [NODE_TYPES.MARK_UP_PARAMETER_KEY]: MarkUpParameterName,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE]: MarkUpParameterValue,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST]: MarkupParameterValueList,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]: MarkupParameterValueListElement,
}

export const componentsByBlockType = {
  [NODE_TYPES.DIRECTOR_LINE]: DirectorLine,
  [NODE_TYPES.DIALOGUE_LINE]: DialogueLineBlock,
  [NODE_TYPES.ACTOR_PART]: ActorPart,
  [NODE_TYPES.TEXT_PART]: TextPart,
}

const componentByMarkType = {}
