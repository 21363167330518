import React from 'react'
import { Popover } from 'antd'

import LinkButton from 'components/Commons/LinkButton'
import AssignmentsPopover from './AssignmentsPopover'

import styles from './AssignmentsPopover.scss'

/**
 * Number of assignments for a given variable. When clicked it shows a modal with
 * details of those assignments.
 */
const VariableAssignmentsCount = ({ variable, assignments }) => (
  <Popover
    title={`Assignments for variable "${variable.name}"`}
    trigger="hover"
    overlayClassName={styles.popover}
    content={
      <AssignmentsPopover variable={variable} assignments={assignments} />
    }
  >
    <LinkButton>{assignments.length}</LinkButton>
  </Popover>
)

export default VariableAssignmentsCount