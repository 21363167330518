import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import promisedScrollIntoView from 'smooth-scroll-into-view-if-needed'
import { ScrollAlign } from 'dom/dom'

import { makeLineResults } from 'selectors/walklist'
import { propFromProps } from 'selectors/props'

import styles from '../../SlateTextEditor.scss'

const SearchHighlightDecoration = ({ children, attributes, isCurrent }) => {
  const ref = useRef()
  useEffect(() => {
    if (isCurrent && ref.current) {
      promisedScrollIntoView(ref.current, {
        scrollMode: 'if-needed',
        block: ScrollAlign.center,
        inline: ScrollAlign.center,
        behavior: 'smooth'
      })
    }
  }, [isCurrent])

  return (
    <span
      ref={ref}
      className={classNames(
        styles.SearchHighlightDecoration,
        {
          [styles.current]: isCurrent
        }
      )}
      {...attributes}
    >
      {children}
    </span>
  )
}

const getAttribute = name => (state, props) => props.decoration.data.get(name)

export default connect(() => {
  const lineMatchSelector = makeLineResults(
    propFromProps('clipId'),
    getAttribute('line_id'),
    getAttribute('line_result_index'),
  )
  return (state, props) => ({
    isCurrent: lineMatchSelector(state, props)
  })
})(SearchHighlightDecoration)

