import React from 'react'
import debounce from 'lodash/debounce'
import { Select, Form, Spin } from 'antd'

const { Item: FormItem } = Form
const { Option } = Select

const AutocompleteSelectorForm = ({ fieldName, search, getFieldDecorator, placeholder }) => (
  <Form layout="horizontal">
    <FormItem label={fieldName}>
      {getFieldDecorator(fieldName, {
        rules: [
          { required: true, message: 'Please select at least one', type: 'array' },
        ],
      })(
        <MultiSelect search={search} placeholder={placeholder} />
      )}
    </FormItem>
  </Form>
)

class MultiSelect extends React.Component {
  state = {
    options: [],
    value: [],
    fetching: false,
  }
  lastFetchId = 0

  /* eslint react/no-did-update-set-state: 0 */
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })
    }
  }

  fetch = debounce(async value => {
    const { search } = this.props
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ options: [], fetching: true })

    const options = await search(value)
    if (fetchId !== this.lastFetchId) { return }

    this.setState({ options, fetching: false })    
  }, 800)

  handleChange = value => {
    const { onChange } = this.props
    this.setState({ value, options: [], fetching: false })
    onChange(value)
  }

  render() {
    const { fetching, options, value } = this.state
    const { placeholder } = this.props
    return (
      <Select
        mode="multiple"
        labelInValue
        value={value}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : <span>No match</span>}
        filterOption={false}
        onSearch={this.fetch}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {options.map(d => <Option key={d.value}>{d.text}</Option>)}
      </Select>
    );
  }

}

export default AutocompleteSelectorForm