import { notification } from 'antd'
import { user } from 'selectors/auth'
import { isSessionFromUs } from 'model/constants/SessionClientType'
import { origin } from '../../../model/constants/SessionClientType'
import { sessionId } from '../../../selectors/project'

export const ON_REVISION_SESSION_CREATED_EVENT = 'ON_REVISION_SESSION_CREATED_EVENT'

const onRevisionSessionCreatedEvent = event => (dispatch, getState) => {
  const state = getState()
  const ourUser = user(state)
  const ourSessionId = sessionId(state)

  // our own session ! ignore !
  if (event.session._id === ourSessionId) {
    return
  }

  // This is hacker UI code, but the easiest way. We will revisit the UX for the whole "session binding"
  // for sure so ...
  if (isSessionFromUs(ourUser, event.session)) {
    notification.info({
      message: 'Connected !',
      description: `You connected from ${origin(event.session) || event.session.clientType} !`
    })
  }

  // data behavior
  dispatch(sessionCreatedAction(event))
}

export const sessionCreatedAction = event => ({
  type: ON_REVISION_SESSION_CREATED_EVENT,
  event
})

export default onRevisionSessionCreatedEvent