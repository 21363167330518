import React, { Component } from 'react'
import { Progress } from 'antd'
import { always } from 'ramda'
import { isCompleted, isLoading, TaskState, Tasks } from 'actions/tasks'

const PROGRESS = {
  [TaskState.completed]: always(100),
  [TaskState.running]: ({ progress }) => progress || 10,
  DEFAULT: always(0)
}

const ACTIVE = 'active'
const SUCCESS = 'success'

class TaskStatus extends Component {
  shouldComponentUpdate = nextProps => this.props.task !== nextProps.task
  render() {
    const { task } = this.props
    return (
      <div>
        <span>{Tasks[task.type] ? Tasks[task.type].description : task.type}</span>
        <Progress 
          percent={this.progress(task)}
          showInfo={isCompleted(task)}
          status={isLoading(task) ? ACTIVE : SUCCESS} />  
      </div>
    )
  }
  progress(task) {
    return (PROGRESS[task.state] || PROGRESS.DEFAULT)(task)
  }
}

export default TaskStatus