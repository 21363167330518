import React from 'react'

import styles from '../ActorDetail.scss'

const Title = ({ children }) => (
  <div className={styles.titleSection}>
    <h2 className={styles.title}>{children}</h2>
    <div className={styles.underline} />
  </div>
)

export default Title