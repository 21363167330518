import { equals, propOr } from 'ramda'
import { createSelector } from './reselect'
import { executingNodeIds } from './state/executions'
import { _current } from './state'
import { nodeId } from './props'
import { BNEStateVariables } from 'model/constants'
import { EMPTY_OBJECT } from 'utils/object'
import { isPaused } from './playback'

export const NodeExecutionStateStatus = {
  executing: 'executing',
  paused: 'paused',
  not_executing: 'not_executing'
}

const nodeState = (state, id) => state[id] || EMPTY_OBJECT
export const makeNodeStateSelector = (aPropSelector = nodeId) => 
  createSelector(
    '[nodeStateSelector]',
    [_current, aPropSelector],
    nodeState
  )

export const executionCount = propOr(0, BNEStateVariables.execution_count)

export const defaultNodeState = {
  status: NodeExecutionStateStatus.not_executing,
  execution_count: 0
}

const ensureDefaultState = state => (equals(state, defaultNodeState) ? defaultNodeState : state)
export const makeNodeExecutionStateSelector = (aPropSelector = nodeId) => createSelector('[nodeExecutionStateSelector]',
  [aPropSelector, executingNodeIds, isPaused, makeNodeStateSelector(aPropSelector)],
  (id, executingIds, paused, state) => {
    
    const status = (executingIds.length > 0 && executingIds.includes(id) ? (paused ? NodeExecutionStateStatus.paused : NodeExecutionStateStatus.executing) : NodeExecutionStateStatus.not_executing)
    return ensureDefaultState({
      status,
      execution_count: executionCount(state)
    })
  }
)

