import React from 'react'
import { stopPropagation } from 'dom/events'
import { Input } from 'antd'
import { withHandlers } from 'recompose'

import { EMPTY_LABEL } from 'utils/ui'

const { TextArea } = Input

const DefaultEditor = ({ text, onPressEnter, onBlur }) => (
  <TextArea 
    placeholder={text || EMPTY_LABEL}
    autosize
    defaultValue={text}
    onClick={stopPropagation}
    onPressEnter={onPressEnter}
    onBlur={onBlur}
  />
)

export default withHandlers({
  onPressEnter: ({ onApply }) => e => {
    e.preventDefault();
    onApply(e.target.value)
  },
  onBlur: ({ onApply }) => e => {
    e.preventDefault();
    onApply(e.target.value)
  }
})(DefaultEditor)