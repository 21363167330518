import { all, any, concat, equals, reduce, forEach, propEq, values, keys, append, isNil, includes } from 'ramda'
import { isTrue, isFalse } from 'ramda-adjunct'
import { objectsIndex } from 'selectors/apollo'
import { objectsBySys } from 'selectors/objects'
import { applyScheduledFunctions } from 'utils/functions'
import ChecksMemory from './ChecksMemory'
import Check from './Check'
import { classByModeLabel } from './CheckerMode'
import { allChecksOfType, checkByTypeAndName, CHECK_RESULT_STATUS, CHECK_SEVERITY_LEVEL } from './CheckSelectors/CheckSelectors'
import { EMPTY_ARRAY } from 'utils/object'

export const OFF = false
export const ON = true

export const EVENT_TYPE = {
  NEW_ISSUE: 'NEW_ISSUE',
  INVALIDATE_ISSUE: 'INVALIDATE_ISSUE',
  START_ANALYSIS: 'START_ANALYSIS',
  END_ANALYSIS: 'END_ANALYSIS',
  UPDATE_PROGRESS: 'UPDATE_PROGRESS',
  CLEAN_ISSUES: 'CLEAN_ISSUES'
}

const anyIs = type => any(propEq('severity', type))
export const anyIsError = anyIs(CHECK_SEVERITY_LEVEL.ERROR)
export const anyIsWarning = anyIs(CHECK_SEVERITY_LEVEL.WARNING)

// EVENT API

const issueEvent = type => ({ objId, name, result }) => ({
  type,
  issue: {
    issueHash: `${name} - ${result.id} - ${objId}`,
    objId,
    ...result
  }
})

export const onNewIssue = issueEvent(EVENT_TYPE.NEW_ISSUE)
export const onInvalidateIssue = issueEvent(EVENT_TYPE.INVALIDATE_ISSUE)
export const cleanIssuesEvent = { type: EVENT_TYPE.CLEAN_ISSUES }

export const newAnalysis = () => ({
  type: EVENT_TYPE.START_ANALYSIS
})
export const endAnalysis = () => ({
  type: EVENT_TYPE.END_ANALYSIS
})

export const updateProgress = progress => ({
  type: EVENT_TYPE.UPDATE_PROGRESS,
  progress
})

const getPreferencesTypeDiffs = (type, prevTypePref, currentTypePref) => {
  if (equals(prevTypePref.ebabled, currentTypePref.enabled)) {
    return { checksToAdd: EMPTY_ARRAY, checksToDel: EMPTY_ARRAY }
  }

  return reduce((acc, checkName) => {
    const prevConfig = prevTypePref.checks[checkName]
    const currConfig = currentTypePref.checks[checkName]
    return ({
      checksToAdd: isFalse(prevConfig) && isTrue(currConfig) ?
        append({ type, checkName }, acc.checksToAdd) :
        acc.checksToAdd,
      checksToDel: isTrue(prevConfig) && isFalse(currConfig) ?
        append(checkName, acc.checksToDel) :
        acc.checksToDel
    })
  }, {
    checksToAdd: EMPTY_ARRAY,
    checksToDel: EMPTY_ARRAY
  }, keys(currentTypePref.checks)
  )
}

export const getPreferencesDiff = (prev, current) =>
  reduce((acc, type) => {
    const { checksToAdd, checksToDel } = getPreferencesTypeDiffs(type, prev[type], current[type])
    return ({
      add: concat(acc.add, checksToAdd),
      del: concat(acc.del, checksToDel),
    })
  }, {
    add: EMPTY_ARRAY,
    del: EMPTY_ARRAY
  }, keys(current)
  )

export default class Checker {
  constructor(store, mode, checkPreferences) {
    this.store = store
    this.observers = []
    this.checksMemory = new ChecksMemory()
    this.status = OFF
    this.checkPreferences = checkPreferences

    const Mode = classByModeLabel(mode)
    this.mode = new Mode(this)
  }

  // State API

  async setMode(mode) {
    await this.mode.setNewMode(mode)
  }

  async onChangeChecksPreferences (preferences) {
    const prevPreferences = this.checkPreferences
    this.checkPreferences = preferences 
    await this.changePreferences(prevPreferences, preferences)
  }

  async changePreferences (prev, current) {
    if (equals(this.status, ON)) {
      const { add, del } = getPreferencesDiff(prev, current)

      await this.mode.applyChecksUpdate(del, add)
    }
  }

  async on() { await this.mode.on() }

  off() { this.status = OFF }

  addObserver(obs) { this.observers.push(obs) }
  removeObserver(obs) { this.observers.splice(this.observers.indexOf(obs), 1) }

  // ChangeSet API

  async refreshChecks() {
    await this.mode.refreshChecks()
  }

  async runAllAsTransaction() {
    await this.withAnalysisTransaction(async () => this.runAllChecks())
  }

  async withAnalysisTransaction (f) {
    this.notifyNewCheckEvent(newAnalysis())
    await f()
    this.notifyNewCheckEvent(endAnalysis())
  }

  async receiveNewChangeSet(changeSet) {
    // TODO: CONECT CHANGESET WITH CURRENT ISSUES
    await this.mode.receiveNewChangeSet(changeSet)
  }

  async reRunAllWith({ added, deleted }) {
    await this.withAnalysisTransaction(async () => {
      const state = this.store.getState()
      const objectIndex = objectsIndex(state)
      const objectsIndexedBySys = objectsBySys(state)

      const allFunctions = []

      // Remove the deleted checks
      forEach(deletedNode => {
        allFunctions.push(() => this.deleteChecksForNode(deletedNode.id, objectIndex, objectsIndexedBySys))
      }, deleted)

      // Run the checks again to update
      this.checksMemory.forEachCheck(check => {
        if (all(({ id: objDeletedId }) => objDeletedId !== check.objId, deleted)) {
          allFunctions.push(() => this.updateCheck(check, objectIndex, objectsIndexedBySys, state))
        }
      })

      // Add the new checks
      forEach(addedNode => {
        allFunctions.push(() => this.addChecksForNode(addedNode, objectIndex, objectsIndexedBySys))
      }, added)

      return applyScheduledFunctions(allFunctions, { chunkScheduler: 'animationFrame' })
    })
  }

  async updateChecksByConfig(delCheckNames, addCheckNames) {
    await this.withAnalysisTransaction(async () => {
      const state = this.store.getState()
      const objectIndex = objectsIndex(state)
      const objectsIndexedBySys = objectsBySys(state)

      const allFunctions = []
  
      // delete checks
      this.checksMemory.forEachCheck(check => {
        if (includes(check.name, delCheckNames)) {
          allFunctions.push(() => this.deleteCheck(check))
        }
      })
  
      // add checks
      forEach(({ type, checkName }) => {
        const check = checkByTypeAndName(type, checkName)
        forEach(obj => {
          if (type === 'node' || obj.sys === type) {
            allFunctions.push(
              () => this.addCheckForNode(check, obj, objectIndex, objectsIndexedBySys, state)
            )
          }
        }, values(objectIndex))
      }, addCheckNames) // [{ type, checkName }]
  
      return applyScheduledFunctions(allFunctions, { chunkScheduler: 'animationFrame' })
    })
  }

  // AUX METHODS

  enableChecksByType(type) {
    const prefElem = this.checkPreferences[type]
    if (isNil(prefElem)) return EMPTY_ARRAY

    return isTrue(prefElem.enabled) ? allChecksOfType(type) :
      reduce((checks, checkName) => (prefElem.checks[checkName] ?
        append(checkByTypeAndName(type, checkName), checks) :
        checks), EMPTY_ARRAY, keys(prefElem.checks)
      )
  }

  checksByPreferences({ sys }) {
    return concat(this.enableChecksByType('node'), this.enableChecksByType(sys))
  }

  async runAllChecks() {
    if (equals(this.status, ON)) {
      const state = this.store.getState()
      const objectIndex = objectsIndex(state)
      const objectsIndexedBySys = objectsBySys(state)
      const allFunctions = []

      allFunctions.push(() => {
        this.notifyNewCheckEvent(cleanIssuesEvent)
        this.checksMemory.clean()
      })

      forEach(obj => {
        allFunctions.push(() => this.addChecksForNode(obj, objectIndex, objectsIndexedBySys))
      }, values(objectIndex))

      return applyScheduledFunctions(allFunctions, { chunkScheduler: 'animationFrame' })
    }
  }

  addCheckForNode({ makeSelector, name }, obj, objectIndex, objectsIndexedBySys, state) {
    const check = new Check(name, obj.id, makeSelector(obj))
    check.run({ objectIndex, objectsIndexedBySys, state })

    this.checksMemory.addNewCheck(check)

    if (equals(check.result.status, CHECK_RESULT_STATUS.ERROR)) {
      this.notifyNewCheckEvent(onNewIssue(check.asData()))
    }
  }

  addChecksForNode(obj, objectIndex, objectsIndexedBySys) {
    const state = this.store.getState()
    const checks = this.checksByPreferences(obj)

    forEach(check =>
      this.addCheckForNode(check, obj, objectIndex, objectsIndexedBySys, state), checks)
  }


  deleteCheck(check) {
    if (equals(check.result.status, CHECK_RESULT_STATUS.ERROR)) {
      this.notifyNewCheckEvent(onInvalidateIssue(check.asData()))
      this.checksMemory.deleteCheck(check)
    } 
  }

  deleteChecksForNode(id) {
    forEach(
      check => {
        if (equals(check.result.status, CHECK_RESULT_STATUS.ERROR)) {
          this.notifyNewCheckEvent(onInvalidateIssue(check.asData()))
        } 
      }, this.checksMemory.checksForNode(id)
    )

    this.checksMemory.deleteAllForNode(id)
  }

  updateCheck(check, objectIndex, objectsIndexedBySys, state) {
    const prevResult = check.result
    const prevAsData = check.asData()

    check.run({ objectIndex, objectsIndexedBySys, state })

    const prevWasOk = equals(prevResult.status, CHECK_RESULT_STATUS.OK)
    const currIsOk = equals(check.result.status, CHECK_RESULT_STATUS.OK)

    const checkData = check.asData()

    // prev ok and curr error
    if (prevWasOk && !currIsOk) {
      this.notifyNewCheckEvent(onNewIssue(checkData))
    }

    // prev error and curr ok
    if (!prevWasOk && currIsOk) {
      this.notifyNewCheckEvent(onInvalidateIssue(prevAsData))
    }

    // prev error and curr error and are differents
    if ((!prevWasOk && !currIsOk) && !equals(prevResult, check.result)) {
      this.notifyNewCheckEvent(onInvalidateIssue(prevAsData))
      this.notifyNewCheckEvent(onNewIssue(checkData))
    }
  }

  notifyNewCheckEvent(event) {
    const NOTIFY_BY_TYPE = {
      [EVENT_TYPE.START_ANALYSIS]: obs => obs.startAnalysis(event),
      [EVENT_TYPE.END_ANALYSIS]: obs => obs.endAnalysis(event),
      [EVENT_TYPE.UPDATE_PROGRESS]: () => {},
      [EVENT_TYPE.NEW_ISSUE]: obs => obs.newIssueEvent(event),
      [EVENT_TYPE.INVALIDATE_ISSUE]: obs => obs.newIssueEvent(event),
      [EVENT_TYPE.CLEAN_ISSUES]: obs => obs.cleanIssues(event)
    }

    this.observers.forEach(NOTIFY_BY_TYPE[event.type])
  }
}
