import React from 'react'

import Wavesurfer from './Wavesurfer'

import styles from './SoundPlayer.scss'

const Waveform = ({ children, volume, audioURL, pos, handlePosChange, playing, options, onFinish }) => {
  return (
    <Wavesurfer
      className={styles.wave}
      audioFile={audioURL}
      volume={volume}
      pos={pos}
      onPosChange={handlePosChange}
      playing={playing}
      options={{
        waveColor: '#a9d4f3',
        progressColor: '#108ee9',
        cursorColor: 'rgba(51, 51, 51, 0.26)',
        barWidth: 2,
        scrollParent: false,
        hideScrollbar: true,
        height: 50,
        ...options,
      }}
      onFinish={() => onFinish()}
    >
      {children}
    </Wavesurfer>
  )
}

export default Waveform
