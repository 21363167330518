import React from 'react'

import FileUploaderModal from '../Commons/FileUploaderModal'

const SetStoryboardModal = props => (
  <FileUploaderModal
    title="Set Storyboard"
    inputPlaceHolder="Image URL"
    acceptedTypes="image/*"
    icon="picture"
    {...props}
  />
)

export default SetStoryboardModal
