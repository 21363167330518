import sentry from 'services/sentry'
import { lastChangeSet as lastChangeSetSelector } from 'selectors/project'

const errorReporter = getState => (context, error) => {
  const lastChangeSet = lastChangeSetSelector(getState())
  sentry.handleError(error, {
    component: 'StateSynchronizer',
    lastChangeSet,
    ...context,
    error
  })
}

export default errorReporter