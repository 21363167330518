export default class IssuesObserver {
  constructor() {
    this.issueEvents = []
    this.replace = false
  }
  
  async handleStartAnalysis({ startCheckAnalysis }) {
    startCheckAnalysis()
  }

  async handleEndAnalysis({ endCheckAnalysis }) {
    endCheckAnalysis(this.issueEvents, this.replace)
    this.issueEvents = []
    this.replace = false
  }

  async handleProgress({ updateProgress }, _event) {
    updateProgress(_event.progress)
  }

  async handleIssueEvent(_, _event) {
    this.issueEvents.push(_event)
  }

  async handleCleanIssues() {
    this.replace = true
  }
}
