import { executionCount, makeNodeStateSelector } from 'selectors/execution'
import { executingNodeIds } from 'selectors/state/executions'
import parentId from 'selectors/objects/nodes/parentId'
import { createSelector } from 'selectors/reselect'
import { _current } from 'selectors/state'
import { model } from 'beanie-engine-api-js'
import makeObjectByIdSelector from '../objects/makeObjectByIdSelector'

const { types: { node: { getChildId } } } = model

const makeParentExecutionSelector = parentIdSelector => createSelector('parentExecution',
  [parentIdSelector, makeNodeStateSelector(parentIdSelector)],
  (id, state) => id && ({ id, state })
)

const makeChildExecutionSelector = childSelector => createSelector('childExecution',
  [childSelector, _current, executingNodeIds],
  (childId, wholeState, executingIds) =>
    executingIds.includes(childId) || executionCount(wholeState[childId]) > 0
)

const childId = idSelector => createSelector(
  [makeObjectByIdSelector(idSelector)],
  getChildId
)

const makeNodeExecutionPathSelector = idFromProp => {
  const parentIdSelector = parentId(idFromProp)
  const childIdSelector = childId(idFromProp)
  return createSelector('nodeExecutionPath',
    [executingNodeIds, makeParentExecutionSelector(parentIdSelector), makeChildExecutionSelector(childIdSelector)],
    (executingIds, parentData, pathAfter) => ({
      pathBefore: parentData ? (executingIds.includes(parentData.id) || executionCount(parentData.state) > 0) : false,
      pathAfter,
    })
  )
}

export default makeNodeExecutionPathSelector