import React, { useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { Icon, Tooltip } from 'antd'

import { createObjectByPlaybackIdSelector } from 'selectors/objects'
import { copySomethingToClipboard } from 'actions/clipboard'
import { EMPTY_OBJECT } from 'utils/object'
import { getMetadataForSys } from '../../model/metadata/UIMetadata'
import NodeLabel from './NodeLabel'

import styles from 'components/TreeView/TreeView.scss'
import domStyles from 'dom/dom.scss'

const DeletedNode = ({ nodeId, className }) => {
  const dispatch = useDispatch()

  return (
    <Tooltip title="Copy node id">
      <div
        className={classNames(
        styles.bneContainerRow,
        styles.bneNode,
        domStyles.node,
        styles.simpleNode,
        styles.error,
        {
          [styles.clickeable]: true,
        },
        className
      )}
        onClick={() => dispatch(copySomethingToClipboard(nodeId))}
        tabIndex="-1"
        role="button"
      >
        DELETED NODE
      </div>
    </Tooltip>
  ) 
}

const SimpleNode = ({ node, nodeId, onClick, children, className, showLabel = true }) => {
  if (!node) return (<DeletedNode nodeId={nodeId} className={className} />)

  const { sys } = node
  const metadata = useMemo(() => node && getMetadataForSys(node.sys), [sys])
  return (
    <div
      className={classNames(
        styles.bneContainerRow,
        styles.bneNode,
        domStyles.node,
        sys ? styles[sys] : EMPTY_OBJECT,
        styles.simpleNode,
        {
          [styles.clickeable]: !!onClick,
        },
        className
      )}
      onClick={onClick}
      tabIndex="-1"
      role="button"
    >
      {metadata && <Icon type={metadata.icon} />}
      {children}
      {showLabel && <NodeLabel isSelected node={node} />}
    </div>
  )
}

export const SimpleNodeContainer = connect(() => {
  const objectSelector = createObjectByPlaybackIdSelector()
  return (state, props) => ({
    node: objectSelector(state, props),
  })
})(SimpleNode)

export default SimpleNode