import React from 'react'
import useImageURL from '../../../hooks/beanie/resources/useImageURL'
import useSecureCheck from '../../../hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from '../../../security/project'
import AvatarUpload from '../AvatarUpload'

const ActorBigAvatar = ({ actor, onChanged }) => {
  const url = useImageURL(actor.data.actorIcon)
  const hasWriteAccess = useSecureCheck(isCurrentRevisionWritable)

  return (
    <AvatarUpload
      title={actor.data.actorName}
      imageFile={actor.data.actorIcon ? url : undefined}
      uploadURL={url}
      onChange={onChanged}
      disabled={!hasWriteAccess}
      size={128}
    />
  )
}

export default ActorBigAvatar