import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import teamRemoveMemberMutation from 'api/mutations/teamRemoveMember.graphql'
import teamSetMemberRoleMutation from 'api/mutations/teamSetMemberRole.graphql'

import TeamAction from 'components/Organizations/Teams/Commons/TeamAction'
import TeamActionLink from 'components/Organizations/Teams/Commons/TeamActionLink'
import ChangeRoleModal from 'components/Organizations/Commons/ChangeRoleModal'

import styles from './TeamMemberActions.scss'
import { TeamMembershipRole } from 'utils/authentication'
import withConfirm from 'hocs/withConfirm'

const TeamMemberActions = ({ member, team, remove, changeRole }) => (
  <div className={styles.actions}>
    <TeamAction team={team}>
      <ChangeRoleModal container={team} member={member} onChange={changeRole} roles={TeamMembershipRole} />
    </TeamAction>
    <TeamActionLink team={team} label="Remove" tooltip="Remove from team" onClick={remove} />
  </div>
)

export default compose(
  withConfirm,
  graphql(teamSetMemberRoleMutation, { name: 'setMemberRole' }),
  graphql(teamRemoveMemberMutation, { name: 'removeMember' }),
  withHandlers({
    removeMember: ({ removeMember, team, member, refreshItems }) => async () => {
      await removeMember({
        variables: { input: { teamId: team._id, userId: member.user.id } }
      })
      refreshItems()
    },
    changeRole: ({ setMemberRole, team, member, refreshItems }) => async ({ Role: role }) => {
      await setMemberRole({ 
        variables: { input: { teamId: team._id, userId: member.user.id, role } }
      })
      refreshItems()
    }
  }),
  withHandlers({
    remove: ({ team, member, confirm, removeMember }) => () => {
      confirm(removeMember, <RemoveConfirmMessage team={team} member={member} />)
    }
  })
)(TeamMemberActions)

const RemoveConfirmMessage = ({ team, member }) => (
  <div>This will remove <strong>{member.user.firstName} {member.user.lastName}</strong> from <strong>{team.name}</strong>
    <br />Are you sure ?
  </div>
)