import React from 'react'

import ViewTitles from './ViewTitles'
import ViewControls from './ViewControls'

const ViewHeader = ({ styles, disposition, activeChild, children, toggleFullScreen, minimize, selectView, fullScreen }) => (
  <div className={styles.title}>
    <ViewTitles styles={styles} activeChild={activeChild} selectView={selectView}>
      {children}
    </ViewTitles>

    <div className={styles.separator} />
    <ViewControls
      styles={styles}
      controls={activeChild.props.pane.controls}
      disposition={disposition}
      minimize={minimize}
      toggleFullScreen={toggleFullScreen}
      fullScreen={fullScreen}
    />
  </div>
)

export default ViewHeader