exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".playerCanvas__2Oc0E{position:relative;background-color:#252327}.playerCanvas__2Oc0E .playerPlaceholder__1Yjdo{width:100vw;height:90vh}", ""]);

// exports
exports.locals = {
	"playerCanvas": "playerCanvas__2Oc0E",
	"playerPlaceholder": "playerPlaceholder__1Yjdo"
};