import { pipe, match, nth } from 'ramda'

export const extension = pipe(match(/(?:\.([^.]+))?$/), nth(1))

export const readFileAsText = file => new Promise(resolve => {
  const reader = new FileReader()
  reader.addEventListener('load', function() {
    // this.result is black magic from FileReader :(
    resolve(this.result)
  })
  reader.readAsText(file)
})

export const readBlobAsText = blob => new Promise(resolve => {
  const reader = new FileReader()
  reader.addEventListener('loadend', () => {
    resolve(reader.result)
  })
  reader.readAsText(blob)
})
