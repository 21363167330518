exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".membersTable__2DB0T{background-color:#fff}.membersTable__2DB0T .userColumn__2DfIB .ant-avatar{margin-right:.5rem}", ""]);

// exports
exports.locals = {
	"membersTable": "membersTable__2DB0T",
	"userColumn": "userColumn__2DfIB"
};