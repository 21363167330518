exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".objectTextEditorWithError__2YcOM{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;text-align:center}.objectTextEditorWithError__2YcOM>*{margin-bottom:.5rem}.objectTextEditorWithError__2YcOM .icon__1Z5tP{font-size:5rem;padding-bottom:.5rem}", ""]);

// exports
exports.locals = {
	"objectTextEditorWithError": "objectTextEditorWithError__2YcOM",
	"icon": "icon__1Z5tP"
};