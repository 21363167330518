exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".preset__1pe4S{color:#fff;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;opacity:.4}.preset__1pe4S.assoced__1HYDK{opacity:1}.activePreset__tTZvu{background-color:#fff}.activePreset__tTZvu .preset__1pe4S{color:#000;font-weight:600}", ""]);

// exports
exports.locals = {
	"preset": "preset__1pe4S",
	"assoced": "assoced__1HYDK",
	"activePreset": "activePreset__tTZvu"
};