import React from 'react'
import { Modal, Icon } from 'antd'
import { Creators } from 'actions/vm'

const { playbackStop } = Creators

/**
 * Called when the engine detects an error while playing
 * @param {*} dispatch - this was bound before starting BNE to be able to dispatch more actions
 */
const onPresentationError = dispatch => error => {
  dispatch(playbackStop())
  // REVIEWME: kind of hacky to open UI elements from here, but it is better than showing nothing :P
  // eslint-disable-next-line no-console
  console.error('There was an error while playing', error)
  Modal.error({
    icon: <Icon type="warning" />,
    title: 'Oops',
    content: `There was an error while playing: ${error.message}`,
  })
}

export default onPresentationError