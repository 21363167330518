
export const RevisionStateType = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR'
}

const RevisionState = {
  INIT: { type: RevisionStateType.INIT },
  LOADING: revisionId => ({ type: RevisionStateType.LOADING, revisionId }),
  LOADED: { type: RevisionStateType.LOADED },
  ERROR: error => ({ type: RevisionStateType.ERROR, error }),
}

export default RevisionState