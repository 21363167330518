import React from 'react'
import { Icon } from 'antd'

import Secure from 'components/Commons/Secure'
import { isSuperAdminCheck } from 'security/platform'

import styles from './ErrorPanel.scss'

const ErrorPanel = ({ error, errorInfo }) => (
  <div className={styles.errorPanel}>
    <h1><Icon type="exclamation-circle" />Ooops.. something went wrong!</h1>
    <h2>We currently cannot show the project content</h2>
    <p>
      Please contact the administrator to investigate this issue
      and recover the project
    </p>
    <Secure check={isSuperAdminCheck}>
      <details>
        {error.toString()}
        <br />
        {errorInfo && errorInfo.componentStack.split('\n').map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </details>
    </Secure>
  </div>
)

export default ErrorPanel