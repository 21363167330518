import React from 'react'

import { Popover, List, Avatar } from 'antd'
import { prop, map, take, pipe } from 'ramda'
import classNames from 'classnames'

import styles from './AvatarList.scss'

const DEFAULT_COLLAPSE_AT = 5

// TODO: rowKey should be provided depends on the list of objects

const DEFAULT_ROW_KEY = prop('_id')

/**
 * Dropdown with extra avatars
 */
const AvatarListPopoverContent = ({ rowKey = DEFAULT_ROW_KEY, list, renderTitle, renderAvatar }) => (
  <div className={styles.listContainer}>
    <List
      dataSource={list}
      rowKey={rowKey}
      className={styles.list}
      renderItem={elem => (
        <List.Item key={rowKey(elem)}>
          <List.Item.Meta
            avatar={renderAvatar(elem)}
            title={renderTitle ? renderTitle(elem) : undefined}
          />
        </List.Item>
      )} />
  </div>)

const OthersAvatar = ({ additionalAvatars, ...others }) => (
  <Popover content={<AvatarListPopoverContent {...others} />}>
    <Avatar >{`${additionalAvatars}+`}</Avatar>
  </Popover>
)

const AvatarList = ({ list, rowKey, collapseAt = DEFAULT_COLLAPSE_AT, renderAvatar, renderTitle, className }) => (
  <div
    className={classNames(className, styles.content, { [styles.collapsed]: list.length > collapseAt })}>
    {pipe(take(collapseAt), map(renderAvatar))(list)}
    {list.length > collapseAt &&
      <OthersAvatar
        additionalAvatars={list.length - collapseAt}
        renderAvatar={renderAvatar}
        renderTitle={renderTitle}
        list={list}
        rowKey={rowKey}
      />
    }
  </div>
)

export default AvatarList