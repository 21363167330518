import React, { useCallback } from 'react'
import { compose } from 'recompose'
import { Tooltip } from 'antd'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'

import { manualDisconnect } from 'graphql/links/subscriptions/WebSocketLink'

import { selectionFromRoute } from 'hocs/selection'

import { user as userSelector } from 'selectors/auth'
import { projectId } from 'selectors/project'
import ApplicationComponents from '../ApplicationComponents/ApplicationComponents'
import CurrentUserInfo from './CurrentUserInfo'

import ProjectEvents from 'components/ProjectEvents/ProjectEvents'
import RevisionSessions from 'components/RevisionSessions/RevisionSessions'
import NotificationsIndicator from 'components/NotificationsIndicator/NotificationsIndicator'
import IconButton from 'components/Commons/IconButton'
import LinkButton from 'components/Commons/LinkButton'
import PlaybackControls from 'components/PlaybackView/PlaybackControls/PlaybackControls'
import SynchIndicator from 'components/SynchIndicator/SynchIndicator'

import ProjectTitle from './CurrentProjectTitle/CurrentProjectTitle'

import styles from './Header.scss'

const { Fragment } = React

const SHOW_DISCONNECT_BUTTON = false

/**
 *
 */
const Header = ({ push, user, project, disconnect, isInEditSection, projectInformation = true }) => {
  const goToHome = useCallback(() => push('/'), [push])

  return (
    <div className={styles.Header}>

      <ApplicationComponents user={user} project={project} />

      {project && (
        <React.Fragment>
          <ProjectEvents project={project} />
        </React.Fragment>
      )}
      <div className={styles.logo}>
        <Tooltip placement="bottomRight" title="Back to homepage">
          <LinkButton onClick={goToHome}>
            <img alt="logo" src="/images/logo-mark-white.png" />
          </LinkButton>
        </Tooltip>
      </div>

      <div className={styles.content}>
        { projectInformation &&
        <React.Fragment>
          <ProjectTitle />

          <SynchIndicator />

          {project &&
          <RevisionSessions />
          }

          {project && isInEditSection &&
          <PlaybackControls />
          }

          { SHOW_DISCONNECT_BUTTON &&
          <IconButton key="dis" tooltip="Disconnect" type="disconnect" onClick={disconnect} />
          }
        </React.Fragment>
        }
      </div>

      <div className={styles.userCorner}>
        {user &&
        <Fragment>
          <NotificationsIndicator />
          <CurrentUserInfo push={push} user={user} />
        </Fragment>
        }
      </div>
    </div>
  )
}

export default compose(
  connect(
    state => ({
      user: userSelector(state),
      project: projectId(state)
    }),
    {
      disconnect: manualDisconnect,
      push: pushAction
    }
  ),
  selectionFromRoute({ name: 'project', param: 'projectName' })
)(Header)