import React from 'react'
import { compose, withState } from 'recompose'
import { pick } from 'ramda'

import ProjectTabHeader from './ProjectTabHeader'
import Projects from './Projects'
import styles from './HomeProjectsContent.scss'
import projectsQuery from 'api/queries/homePageProjects.graphql'
import withFetchMoreForPaging from 'hocs/withFetchMoreForPaging'

export const PROJECTS_PAGE_SIZE = 3 * 15 // 15 rows of 3

const HomeProjectsContent = ({ projects, fetchMore, hasMore, archived, setArchived, setQuery }) => (
  <div className={styles.content}>
    <ProjectTabHeader
      refresh={projects.refetch}
      onSearch={text => setQuery(text)}
      archived={archived}
      setArchived={setArchived}
    />
    <Projects refresh={projects.refetch} projects={projects} fetchMore={fetchMore} hasMore={hasMore} />
  </div>
)

export default compose(
  withState('query', 'setQuery', ''),
  withState('archived', 'setArchived', false),
  withFetchMoreForPaging({
    query: projectsQuery,
    name: 'projects',
    dataPath: ['myGroupedProjects'],
    pageSize: PROJECTS_PAGE_SIZE,
    variables: pick(['query', 'archived'])
  })
)(HomeProjectsContent)
