import React from 'react'
import { model } from 'beanie-engine-api-js'
import { togglePinUnpinSelectedNode } from 'actions/project/debuggingData'
import { toggleEnableDisableSelectedNode } from 'engine/actions/nodes'
import { insertRowAtPosition, moveRowToPosition, removeTruthTableRow } from 'engine/actions/truthTable'
import useIsPinned from '../../../../../hooks/beanie/useIsPinned'
import TruthTableRowMenu from '../Menu/TruthTableRowMenu'
import DropdownMenu from '../DropdownMenu'
import useDropdownAction from './useDropdownAction'

import styles from '../DropdownMenu.scss'

const { types: { truthTable: { rows } } } = model

/**
 *
 */
const RowDropdown = ({ row, truthTable, rowIndex, setDropdownVisibility }) => {
  const pinned = useIsPinned(row.id)

  const insertRowAbove = useDropdownAction(setDropdownVisibility, () =>
    insertRowAtPosition(truthTable, rowIndex), [truthTable, rowIndex]
  )

  const insertRowBelow = useDropdownAction(setDropdownVisibility, () =>
    insertRowAtPosition(truthTable, rowIndex + 1), [truthTable, rowIndex]
  )

  const moveRowUp = useDropdownAction(setDropdownVisibility, () =>
    moveRowToPosition(truthTable, row, rowIndex - 1), [truthTable, row, rowIndex]
  )

  const moveRowDown = useDropdownAction(setDropdownVisibility, () =>
    moveRowToPosition(truthTable, row, rowIndex + 1), [truthTable, row, rowIndex]
  )

  const moveRowToTop = useDropdownAction(setDropdownVisibility, () =>
    moveRowToPosition(truthTable, row, 0), [truthTable, row]
  )

  const moveRowToBottom = useDropdownAction(setDropdownVisibility, () =>
    moveRowToPosition(truthTable, row, rows(truthTable).length), [truthTable, row]
  )

  const deleteRow = useDropdownAction(setDropdownVisibility, () =>
    removeTruthTableRow(truthTable, rowIndex), [truthTable, rowIndex]
  )

  const toggleEnableDisable = useDropdownAction(setDropdownVisibility, () =>
    toggleEnableDisableSelectedNode(row), [row]
  )

  const toggleDebugging = useDropdownAction(setDropdownVisibility, () =>
    togglePinUnpinSelectedNode(row), [row]
  )

  return (
    <DropdownMenu
      iconClassName={styles.rowIcon}
      setDropdownVisibility={setDropdownVisibility}
    >
      <TruthTableRowMenu
        node={row}
        pinned={pinned}
        insertRowAbove={insertRowAbove}
        insertRowBelow={insertRowBelow}
        moveRowUp={moveRowUp}
        moveRowDown={moveRowDown}
        moveRowToTop={moveRowToTop}
        moveRowToBottom={moveRowToBottom}
        deleteRow={deleteRow}
        toggleEnableDisable={toggleEnableDisable}
        toggleDebugging={toggleDebugging}

        setDropdownVisibility={setDropdownVisibility}
      />
    </DropdownMenu>
  )
}


export default RowDropdown