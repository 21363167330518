import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { identity } from 'ramda'
import classnames from 'classnames'
import { graphql } from 'react-apollo'

import savegamesSummaryQuery from 'api/queries/savegamesSummary.graphql'
import { user as userSelector } from 'selectors/auth'
import { projectId as projectIdSelector } from 'selectors/project'
import { Creators as presetsCreators } from 'actions/presets'
import { makeSavegameForPreset, activePreset as activePresetSelector } from 'selectors/presets'

import styles from './PresetDropdown.scss'
import FilteredDropdown from './FilteredDropdown'

const PresetDropdown = ({ preset, activePreset, hasSavegame, savegamesSummary, savegameForPreset, togglePreset, assocPreset, dissocPreset }) => {
  let wrapper = identity
  if (savegamesSummary) {
    const { savegames, loading, error } = savegamesSummary
    wrapper = content => (
      <FilteredDropdown
        placeholder="No Savegame Selected"
        theme="dark"
        trigger={['contextMenu']}
        elements={savegames}
        loading={loading}
        error={error}
        selected={savegameForPreset}
        elProp="slot"
        onClick={togglePreset}
        onSelected={assocPreset}
        onClear={dissocPreset}
        allowClear
      >{content}</FilteredDropdown>
    )
  }

  return wrapper(
    <div className={classnames({ [styles.activePreset]: hasSavegame && activePreset === preset })}>
      <span className={classnames(styles.preset, { [styles.assoced]: hasSavegame })}>{preset.replace('preset', '')}</span>
    </div>
  )
}

export default compose(
  connect(
    () => {
      const savegameForPresetSelector = makeSavegameForPreset()
      return (state, props) => {
        const savegameForPreset = savegameForPresetSelector(state, props)
        return ({
          hasSavegame: !!savegameForPreset,
          savegameForPreset,
          activePreset: activePresetSelector(state),
          projectId: projectIdSelector(state),
          user: userSelector(state)
        })
      }
    },
    (dispatch, { preset }) => ({ togglePreset: () => dispatch(presetsCreators.togglePreset(preset)) })
  ),
  connect(undefined,
    (dispatch, { preset, projectId, user: { _id: userId } }) => ({
      assocPreset: savegame => dispatch(presetsCreators.assocPreset(preset, projectId, userId, savegame)),
      dissocPreset: () => dispatch(presetsCreators.dissocPreset(preset, projectId, userId))
    })
  ),
  graphql(savegamesSummaryQuery, {
    name: 'savegamesSummary',
    options: ({ projectId }) => ({ variables: { gameId: projectId } }),
    skip: ({ projectId }) => !projectId
  }),
)(PresetDropdown)
