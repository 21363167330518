import React from 'react'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ImportLuaScriptButton from './ImportLuaScriptButton'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'
import ManageEngineExtensions from './ManageEngineExtensions'
import SetBneStateButton from './SetBneState/SetBneStateButton'

import styles from './ImportLuaScriptButton.scss'

/**
 * Panel Controls for the Console Panel
 */
const ConsolePanelControls = () => (<div className={styles.PanelControls}>
  <ManageEngineExtensions />
  <ImportLuaScriptButton />
  <SetBneStateButton />
  <ExtensionPoint
    point={EXTENSION_POINTS.CONSOLE_PANEL_CONTROL}
  />
</div>)

export default ConsolePanelControls