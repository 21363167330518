import { path } from 'ramda'
import revisionSessionCreateMutation from 'api/mutations/revisionSessionCreate.graphql'
import projectRevisionEventsSubscriptionQuery from 'api/subscriptions/projectRevisionEvents.graphql'
import SessionClientType from 'model/constants/SessionClientType'

/**
 * Creates a new revision session doing the hand-shake with the backend and subscribing
 * for events.
 * Returns the session object and the subscription so you can unsubscribe later
 * Receives handlers for the incoming events
 */
const createSession = (client, revisionId, { onEvent, onError }) => async () => {
  // init session
  const { data: { revisionSessionCreate: session } } = await client.mutate({
    mutation: revisionSessionCreateMutation,
    variables: {
      input: {
        revision: revisionId,
        clientType: SessionClientType.StudioFrontend.code,
      }
    },
    fetchPolicy: 'no-cache'
  })

  // TODO: handle errors ?

  // connect WS to confirm the session
  const subscription = client
    .subscribe({
      query: projectRevisionEventsSubscriptionQuery,
      variables: { sessionId: session._id },
    })
    .subscribe({
      next: message => {
        return onEvent(path(['data', 'projectRevisionEvents'], message))
      },
      error: error => {
        onError(error)
      }
    })

  return { session, subscription }
}

export default createSession