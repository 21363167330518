
const internalDescribe = (describeFn, bodyCaller) => (title, body) =>
  describeFn(title, () => bodyCaller(body))

const createCustomDescribe = bodyCaller => {
  const customDescribe = internalDescribe(describe, bodyCaller)
  customDescribe.only = internalDescribe(describe.only, bodyCaller)
  customDescribe.skip = internalDescribe(describe.skip, bodyCaller)
  return customDescribe
}

export default createCustomDescribe