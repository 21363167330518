import { pipe } from 'ramda'
import { EditorState, SelectionState, convertFromRaw, CharacterMetadata } from 'draft-js'

// general utilities
const EMPTY_CONTENT = ''

export const createEditorStateFromRawContent = pipe(convertFromRaw, EditorState.createWithContent)

export const createSelectionOnAllBlockContent = block => createSelectionAt(block.getKey(), 0, block.getLength())

export const createSelectionOnBlock = (blockKey, body) => SelectionState
  .createEmpty(blockKey)
  .merge(body)

export const transformSelection = (transformer, selection) =>
  selection.merge({
    ...transformer.anchorOffset && { anchorOffset: transformer.anchorOffset(selection.getAnchorOffset()) },
    ...transformer.focusOffset && { focusOffset: transformer.focusOffset(selection.getFocusOffset()) },
  })

export const createSelectionAt = (blockKey, start, end) => SelectionState
  .createEmpty(blockKey)
  .merge({ anchorOffset: start, focusOffset: end || start })

// mutations

export const Mutations = {
  setCursorAt: (blockKey, startOffset, endOffset) => state => EditorState.forceSelection(state, createSelectionAt(blockKey, startOffset, endOffset)),
  moveCursor: delta => state => Mutations.setCursorAt(
    state.getSelection().getAnchorKey(),
    state.getSelection().getStartOffset() + delta
  )(state),
  forceSelection: selection => state => EditorState.forceSelection(state, selection)
}

// tokens

export const EntityMutability = {
  MUTABLE: 'MUTABLE',
  IMMUTABLE: 'IMMUTBALE'
}

const token = mutability => (bne_id, sys, extraData) => ({
  type: 'TOKEN',
  mutability,
  data: { bne_id, sys, ...(extraData || {}) }
})
export const immutableToken = token(EntityMutability.IMMUTABLE)
export const mutableToken = token(EntityMutability.MUTABLE)

// utilities to manually create draft AST

export const block = (inlinedText, data = {}, type = 'line') => ({
  type,
  ...parseInlinedBlock(inlinedText),
  data
})

const { create, applyStyle } = CharacterMetadata

// util methods (factories and predicates)

export const createEmptyCharacterMetadata = () => create()
export const createStyledCharacterMetadata = style => () => applyStyle(create(), style)

// Block Builder

export class BlockBuilder {
  constructor(content, type, data) {
    this.type = type
    this.content = content
    this.data = data
    this.out = ''
    this.inlineStyleRanges = []
    this.entityRanges = []
    this.entitiesIndex = 0
  }
  append({ text = '', inlineStyle, entity, suffix, styleSuffix }) {
    const start = this.out.length
    this.out += text
    if (inlineStyle) {
      this.inlineStyleRanges.push({
        style: inlineStyle,
        offset: start,
        length: text.length
      })
    }
    if (entity) {
      this.entity(entity)
      this.entityRanges.push({
        key: entity.id,
        offset: start,
        length: text.length + (suffix ? suffix.length : 0)
      })
    }
    if (suffix) {
      // order matters
      this.inlineStyleRanges.push({ style: styleSuffix, offset: this.out.length, length: suffix.length - 1 })
      this.out += suffix
    }
  }
  entity(e) {
    this.content.entityMap[e.id] = e
  }

  parseInlineStyles(parser) {
    parser(this.out, this.inlineStyleRanges)
  }

  pushIt() {
    this.content.blocks.push({
      text: this.out.length === 0 ? EMPTY_CONTENT : this.out,
      type: this.type,
      inlineStyleRanges: this.inlineStyleRanges,
      entityRanges: this.entityRanges,
      data: this.data
    })
  }
}

// inlined entities parser. Util to write shorter tests (blocks)

const REGEX = () => /(\(\d*\s[^)]*\))/gm
const PART_REGEX = () => /\((\d*)\s([^)]*)\)/gm

export const parseInlinedBlock = text => {
  /* eslint no-cond-assign: 0 */
  let cleanText = text
  const entityRanges = []

  let match
  while ((match = REGEX().exec(cleanText)) != null) {
    const [, entityId, content] = PART_REGEX().exec(match[0])
    entityRanges.push({
      offset: match.index,
      length: content.length,
      key: parseInt(entityId)
    })
    cleanText = cleanText.slice(0, match.index) + content + cleanText.slice(match.index + match[0].length)
  }

  return ({
    text: cleanText,
    entityRanges
  })
}