import React from 'react'

import styles from './Rule.scss'


export const FactApp = ({ node: { fact } }) => {
  return (
    <span className={styles.factAppContainer}>
      <span>{fact}()&nbsp;</span>
    </span>
  ) 
}

export default FactApp