import { assoc, clone, indexBy, prop, propOr } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import StateSynchronizer from 'sync/StateSynchronizer'
import { select } from 'actions/selection'
import { projectFetched, loadRevisionStart, loadRevisionEnd } from 'actions/project'
import { LOGIN_SUCCESS } from 'actions/login'
import { noop } from 'utils/functions'

import { lastChangeSet } from 'selectors/project'
import { sortChanges } from 'beanie-engine-api-js'

import storeCreator from 'storeCreator.js'
import { assignTypeNameToChange } from '../../../model/ChangeSet'
import { objectsIndex } from '../../../selectors/apollo'
import EngineStore from '../../../sync/reactive-stores/EngineStore'
import LocalStore from '../../../sync/reactive-stores/LocalStore'
import ServerStore from '../../../sync/reactive-stores/ServerStore'
import { getMutationName } from '../../../utils/graphql'
import { EMPTY_ARRAY } from 'utils/object'
import { indexById } from '../../../utils/ramda'

export const TOKEN = 'myToken'

export const PROJECT_ID = '5cdacce02db7ea92bb41ad48'
export const PROJECT_NAME = 'wd301'
export const REVISION_ID = '5cdacce02db7ea92bb41ad59'
export const USER_ID = 'testUser'

/*
 * Setups almost any piece of the architecture to be able to make integration tests.
 * It is commonly used to create a store with a loaded project with some objects
 * So it creates
 * - a real engine
 * - a real StateSynchronizer
 * - a real apollo client (depends on the given factory)
 */
export const createMockingContext = ({
  initialObjects,
  bneStore,
  baseState,
  apolloClientFactory = hackyApolloClient,
  history,
  storeEnhancer,
  // false just for backward compat with tests. It should be true
  loadIntoBeanie = false
}) => {
  if (!bneStore) {
    throw new Error('No BNEStore instance provided to the mocking context')
  }

  const engineStore = new EngineStore(bneStore)
  const stateSynchronizer = new StateSynchronizer(engineStore)

  const store = storeCreator({
    baseState,
    history: history || { push: noop },
    apolloFactory: apolloClientFactory,
    synchronizer: stateSynchronizer,
    storeEnhancer
  })

  const mockApolloClient = store.apolloClient

  stateSynchronizer.setLocalStore(new LocalStore(store))
  stateSynchronizer.setServerStore(new ServerStore(store, mockApolloClient))
  engineStore.setStore(store)

  if (initialObjects) {
    // simulate user logged-in
    simulateLogin(store)
    // simulate project loaded
    store.dispatch(select('project')(PROJECT_NAME))
    store.dispatch(loadRevisionStart(PROJECT_NAME))
    store.dispatch(loadRevisionEnd(PROJECT_NAME))
    // populate objects
    store.dispatch(projectFetched({
      _id: REVISION_ID,
      objects: indexBy(prop('id'), initialObjects),
      project: {
        _id: PROJECT_ID,
        name: PROJECT_NAME,
      }
    }))
  }

  // TODO: we should be doing this by default, but now it will affect all f**ing tests !
  // load them into beanie
  if (isNotNil(initialObjects) && loadIntoBeanie && initialObjects.length > 0) {
    engineStore.getEngine().getAPI().createObjects(...initialObjects)
  }

  return {
    // engine
    engine: bneStore.getEngine(),
    engineStore,

    // redux state
    reduxStore: store, // deprecated, use "store"
    store,
    dispatch: ::store.dispatch,
    getState: ::store.getState,
    getLastChangeSet: () => sortedLastChangeSet(store.getState()),
    findById: id => objectsIndex(store.getState())[id],

    // server and global arch
    mockApolloClient, // deprecated use "client"
    client: mockApolloClient,
    stateSynchronizer,
    synchronizer: stateSynchronizer,
  }
}

export const simulateLogin = store => {
  store.dispatch({ type: LOGIN_SUCCESS, data: { user: { _id: USER_ID }, token: TOKEN } })
}

export const createStore = initialObjects => {
  const store = storeCreator({ history: { push: () => {} } })
  store.dispatch(select('project')(PROJECT_ID))
  store.dispatch(projectFetched({
    _id: REVISION_ID,
    version: 'master',
    modifiable: true,
    objects: indexById(clone(initialObjects)),
    project: {
      _id: PROJECT_ID,
      name: PROJECT_NAME,
    }
  }))
  return store
}

const sortedLastChangeSet = state => {
  const lastCS = lastChangeSet(state)

  if (!lastCS?.changes) return lastCS

  return assoc('changes', sortChanges(propOr(EMPTY_ARRAY, 'changes', lastCS)), lastCS)
}

// utils

// deprecated: we should use a more complete mock
// like createApolloClientWithMock
export const hackyApolloClient = () => {
  const updates = []
  const mockApolloClient = {
    updates,

    // mocks the applyChangeSet response
    mutate: ({ mutation, variables }) => {
      const name = getMutationName(mutation)
      if (name === 'applyChangeSet') {
        const response = mockApolloClient[name](variables)
        // update(mockApolloClient, response)
        return response
      }

      return Promise.resolve({ data: {} })
    },

    readQuery() { throw new Error('Should be necessary anymore to sync') },
    writeQuery() { throw new Error('Should be necessary anymore to sync') },

    applyChangeSet: ({ input }) => ({
      data: {
        applyChangeSet: {
          ...input,
          changes: input.changes.map(assignTypeNameToChange)
        }
      }
    }),
  }
  return mockApolloClient
}