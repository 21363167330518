import { T } from 'ramda'
import { GamepadButton, createGamepadDefinition } from '../api'

const DefaultButton = {
  // TODO: get new images for default
  A: { name: 'A', icon: '/images/gamepads/button-switch-a.jpeg' },
  B: { name: 'B', icon: '/images/gamepads/button-switch-b.jpeg' },
  X: { name: 'X', icon: '/images/gamepads/button-switch-x.jpeg' },
  Y: { name: 'Y', icon: '/images/gamepads/button-switch-y.jpeg' },
}

//
// gamepad def
// 

const DefaultGamepad = createGamepadDefinition({
  type: 'Default',
  matches: T,
  buttons: DefaultButton,
  apiToButtonMapping: {
    [GamepadButton.A]: DefaultButton.A,
    [GamepadButton.B]: DefaultButton.B,
    [GamepadButton.X]: DefaultButton.X,
    [GamepadButton.Y]: DefaultButton.Y,
  },
  buttonToChoiceMapping: {
    [DefaultButton.A.name]: 0,
    [DefaultButton.B.name]: 1,
    [DefaultButton.X.name]: 2,
    [DefaultButton.Y.name]: 3,
  },
})

export default DefaultGamepad