import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { objectsList } from '../../selectors/project'
import { currentChoices, enabledChoicesIds as enabledChoicesIdsSelector, currentChoicesExpirationTime } from '../../selectors/engine'

import { Creators as EngineActions } from '../../actions/engine'

import { CurrentChoicesPlaybackComp } from '../../../PlaybackView/CurrentPlayback/nodes/choices/CurrentChoicesPlayback'

import styles from './VideoPlayerChoicesControl.scss'

/**
 * JF: not sure if this will keep working as it is. <CurrentChoicesPlaybackComp> has changed.
 * And we don't use the old selector for choices and de-normalizing. Yet might be easy to fix.
 * Passing "enabledChoicesIds" might not be the right change
 */
const VideoPlayerChoicesControl = ({ enabledChoicesIds, expirationTime, onSelected, onExpired }) => (
  <div className={styles.container}>
    {enabledChoicesIds &&
      <CurrentChoicesPlaybackComp
        choicesIds={enabledChoicesIds}
        focusOnSelectedNode={() => {}}
        initialTime={expirationTime}
        onSelected={onSelected}
        onExpired={onExpired}
      />
    }
  </div>
)

export default compose(
  connect(state => ({
    choices: currentChoices(state),
    enabledChoicesIds: enabledChoicesIdsSelector(state),
    projectObjects: objectsList(state),
    expirationTime: currentChoicesExpirationTime(state)
  }), {
    onSelected: EngineActions.onChoiceSelected,
    onExpired: EngineActions.onChoiceExpired,
  }),
)(VideoPlayerChoicesControl)