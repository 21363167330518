import { over, lensProp, mergeDeepLeft } from 'ramda'

import { Types } from 'actions/vm'

const { SET_TX_OPTIONS } = Types


// LifeCycle
const Transactions = {
  [SET_TX_OPTIONS]: ({ options }) => over(lensProp('transactions'), mergeDeepLeft({ options }))
}

export default Transactions