import React from 'react'
import { getComponentFor } from './Rule'

import styles from './Rule.scss'

export const Assign = ({ node: { id, expr }, level = 0, ...others }) => {
  const Id = getComponentFor(id)
  const Expr = getComponentFor(expr)
  return (
    <span className={styles.effectContainer}>
      <span>set&nbsp;</span>
      <Id node={id} {...others} level={level + 1} />
      <span>to&nbsp;</span>
      <Expr node={expr} {...others} level={level + 1} />
    </span>
  )
}

export default Assign