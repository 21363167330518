exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".arrayValue__3FcZH{display:-ms-flexbox;display:flex}", ""]);

// exports
exports.locals = {
	"arrayValue": "arrayValue__3FcZH"
};