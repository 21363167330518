exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".item__3RXbm{display:-ms-flexbox !important;display:flex !important;height:2rem !important;font-size:small !important}.subMenu__2Vc35 .ant-menu-submenu-title{display:-ms-flexbox !important;display:flex !important;height:2rem !important;font-size:small !important}.noContent__1OpVc{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.searchBox__BI9aW{font-size:small;padding-bottom:.5rem;padding-top:.5rem;padding-left:1em;padding-right:1em}.options__2L0KA{-webkit-box-shadow:2px 4px 8px rgba(0,0,0,.5);box-shadow:2px 4px 8px rgba(0,0,0,.5)}.fromClipboardTitle__2a3sn{display:-ms-flexbox;display:flex;font-size:.9em;color:rgba(92,112,128,.63);-ms-flex-pack:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"item": "item__3RXbm",
	"subMenu": "subMenu__2Vc35",
	"noContent": "noContent__1OpVc",
	"searchBox": "searchBox__BI9aW",
	"options": "options__2L0KA",
	"fromClipboardTitle": "fromClipboardTitle__2a3sn"
};