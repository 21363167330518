import { useRef, useEffect } from 'react'

const useIsMountedRef = () => {
  const ref = useRef(true)

  useEffect(() => {
    return () => {
      ref.current = false
    }
  }, [])

  return ref
}

export default useIsMountedRef