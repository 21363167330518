import { createActions } from 'reduxsauce'
import { isPausePoint } from '../../../selectors/playback/debug'

export const { Types, Creators } = createActions({

  /**
   * Toggles a pause-point for a node.
   * Playback will automatically pause when reaching a pause-point
   */
  togglePausePoint: ({ id }) => (dispatch, getState) => {
    const action = isPausePoint(id, getState()) ? Creators.unsetPausePoint : Creators.setPausePoint
    dispatch(action(id))
  },

  setPausePoint: ['nodeId'],
  unsetPausePoint: ['nodeId'],
})