import React from 'react'

import classNames from 'classnames'
import { EMPTY_STRING } from 'utils/string'

import styles from '../../SlateTextEditor.scss'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'

const ActorPart = ({ attributes, children, node }) => {
  const contentEditable = useSecureCheck(isCurrentRevisionWritable)

  return (
    <div contentEditable={contentEditable} suppressContentEditableWarning {...attributes} className={classNames(styles.ActorPart, { [styles.empty]: node.getText() === EMPTY_STRING })}>{children}</div>
  )
}

export default ActorPart