import { compose, lifecycle, withState } from 'recompose'
import cycler from 'utils/cycler'

/**
 * Takes an input prop and a fn that know how to split it into chunks
 * using a `cycler` (@see utils/cycler.js), then it will be cycling between
 * each chunk injecting it as a prop (named by `currentPropName`).
 *
 * For example, given a prop named 'words' that has a text
 * and a function that splits it (notice that it creates chunks that have any prop name, plus a `time` prop which indicates for how long
 * this item should be presented. In this case all words will be shown for 5 seconds
 *
 * Then you can use it like this
 *
 * ```js
 * withCycler({
 *  sourcePropName: 'words',
 *  currentPropName: 'word',
 *  chunkenizer: string => string.toArray().map(text => ({ text, time: 5 }))
 * })
 * Then your component can be
 * const MyComponent ({ word }) => (
 *  <div>{word.text}</div>
 * )
 *
 * ```
 *
 * You can also setup a boolean propName to indicate if it is paused. When that changes then
 * the cycler will pause / resume.
 *
 */
// TODO: migrate this to a hook which will have better performance avoiding composition
const withCycler = ({
  sourcePropName,
  currentPropName,
  chunkenizer,
  pausePropName = 'paused',

  // internals
  currentSetterName = `set${currentPropName}`,
  isLastPropName = `${currentPropName}IsLast`,
  isLastSetterName = `set${currentSetterName}IsLast`,
}) =>
  compose(
    withState(currentPropName, currentSetterName, null),
    withState(isLastPropName, isLastSetterName, false),
    lifecycle({
      componentDidMount() {
        const { [currentSetterName]: setCurrent, [sourcePropName]: input, [isLastSetterName]: setIsLast } = this.props
        this.cycler = cycler({
          chunkenizer,
          chunkChanged: (current, isLast) => {
            setCurrent(current)
            setIsLast(isLast)
          }
        })
        if (input) {
          this.cycler.setInput(input)
        }
      },
      componentDidUpdate(prevProps) {
        const { [sourcePropName]: input, [pausePropName]: paused } = this.props
        if (input !== prevProps[sourcePropName]) {
          this.cycler.setInput(input)
        }
        // if pausable
        if (sourcePropName) {
          if (prevProps[pausePropName] && !paused) {
            this.cycler.resume()
          } else if (!prevProps[pausePropName] && paused) {
            this.cycler.pause()
          }
        }
      },
      componentWillUnmount() {
        this.cycler.clear()
      }
    })
  )

export default withCycler