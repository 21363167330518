exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".errorsPopover__27_2w .ant-tooltip-inner{background-color:rgba(239,239,239,.75)}.errorsPopover__27_2w .ant-tooltip-arrow{border-top-color:rgba(239,239,239,.75)}.errorsPopover__27_2w .error__3wUy9{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.errorsPopover__27_2w .error__3wUy9 .anticon{padding-right:.4rem}", ""]);

// exports
exports.locals = {
	"errorsPopover": "errorsPopover__27_2w",
	"error": "error__3wUy9"
};