import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useSelectorWithPreviousValue from './useSelectorWithPreviousValue'

/**
 * Given 2 selectors it will give the last one that has changed
 * Order matters, the first selector has priority over the second.
 * So initial value will be A (if not undefined)
 * Then if B changes, it will be B.
 * If A gets a value it will use A
 */
const useLastBetweenSelectors = (selectorA, selectorB) => {
  const currentA = useSelector(selectorA)
  const [currentB, previousB] = useSelectorWithPreviousValue(selectorB)

  const value = useMemo(
    () => (!!currentA && currentB === previousB ? currentA : currentB),
    [currentA, currentB]
  )

  return value
}

export default useLastBetweenSelectors