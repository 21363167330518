import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { Button } from 'antd'

import { Item } from 'components/Commons/DangerZone/DangerZone'

import transferProjectMutation from 'api/mutations/transferProject.graphql'

import ModalButton from 'components/Commons/ModalButton'
import SelectOrganizationField from './SelectOrganizationField'

const TransferProjectItem = ({ onTransfer }) => (
  <Item
    title="Transfer Project" 
    paragraph={`
      Transfer this project to one of your organizations. You will loose ownership !
      Still you might have access to the project if the organization setup the right
      permissions in terms of teams access
    `}
  >
    <ModalButton
      title="Transfer to Organization"
      onOk={({ organization }) => onTransfer(organization)}
    >
      <Button type="danger">Transfer</Button>
      <SelectOrganizationField />
    </ModalButton>
  </Item>
)

export default compose(
  graphql(transferProjectMutation, { name: 'transferProject' }),
  withHandlers({
    onTransfer: ({ transferProject, project, closeProject }) => async organizationId => {
      await transferProject({
        variables: { input: { projectId: project._id, organizationId } },
      })
      closeProject()
    }
  }),
)(TransferProjectItem)