import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'
import { isNil } from 'ramda'
import { Spin, AutoComplete } from 'antd'
import debounce from 'lodash.debounce'

import { EMPTY_STRING } from 'utils/string'
import { noop } from 'utils/functions'
import { isEmpty, EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'
import { TabIndexes } from 'utils/keyboard'
import { sleep } from 'utils/promises'
import { keyForResult } from 'model/project/searches/searches'

import searchMatchingTextAction from 'actions/nodes/search/searchMatchingText'

import SearchObjectItem from './SearchObjectItem'

import styles from './SearchObject.scss'


const { Option } = AutoComplete

// TODO: this has duplicated code from ObjectRefValue

const MIN_LENGTH_TO_SEARCH = 3
const DEBOUNCE_TIME = 800

/**
 *
 */
class SearchObject extends React.Component {

  state = {
    searchText: EMPTY_STRING,
    fetching: false,
    dataSource: this.props.searchAtInit ? this.props.search(EMPTY_STRING, { filterOption: this.props.filterOption }).result : EMPTY_ARRAY
  }
  lastFetchId = 0
  id = new Date().getUTCMilliseconds()

  render() {
    const { dataSource, searchText, fetching } = this.state
    const { dropdownClassName = styles.options, autoCompleteClassName = styles.searchBox, placeholder, showInline, inlineContainerClassName, autoFocus, dropdownRender, getPopupContainer } = this.props
    return (
      <Fragment>
        <AutoComplete
          className={autoCompleteClassName}
          tabIndex={TabIndexes.Layout.searchElement}

          onSelect={this.onSelect}
          onSearch={this.onSearch}
          onChange={this.onChange}

          defaultActiveFirstOption={false}

          notFoundContent={this.renderNotFoundContent()}
          placeholder={placeholder || 'text or id'}
          value={searchText}
          autoFocus={autoFocus}

          dropdownClassName={dropdownClassName}
          getPopupContainer={getPopupContainer}
          {...(showInline ? {
            getPopupContainer: () => document.getElementById(`searchObject-items-${this.id}`),
            open: true
          } : EMPTY_OBJECT)}
          dropdownRender={dropdownRender}
        >
          {!fetching && !isEmpty(dataSource) && dataSource.map(this.renderOption)}

        </AutoComplete>
        {showInline && <div className={classNames(styles.dropdownInlineContainer, inlineContainerClassName)} id={`searchObject-items-${this.id}`} />}
      </Fragment>
    )
  }
  renderNotFoundContent = () => {
    const { fetching } = this.state
    const { renderNoContent = this.renderNoContent } = this.props
    return fetching ? <Spin size="small" /> : renderNoContent()
  }

  renderNoContent = () => <div>No matches</div>

  renderOption = item => {
    const { searchText } = this.state
    const { optionProps: { withStyle, showContextInfo } = { withStyle: false } } = this.props
    const key = `${keyForResult(item)}`
    return (
      <Option key={key} className={styles.option} item={item} value={key}>
        <SearchObjectItem item={item} searchText={searchText} withStyle={withStyle} showContextInfo={showContextInfo} />
      </Option>
    )
  }

  onChange = searchText => {
    this.setState(prev => {
      if (searchText !== prev.lastSelectedId) {
        (this.props.onFound || noop)(EMPTY_ARRAY)
        return {
          searchText,
          dataSource: EMPTY_ARRAY,
        }
      } else {
        return undefined
      }
    })
  }

  /* eslint react/no-unused-state: 0 */
  onSelect = (newId, option) => {
    const selectedResult = option.props.item
    const { onValueChanged } = this.props
    if (onValueChanged) onValueChanged(selectedResult.object.id)
    this.setState({ searchText: EMPTY_STRING, dataSource: EMPTY_ARRAY, lastSelectedId: newId });
    (this.props.onFound || noop)(EMPTY_ARRAY)
  }

  onSearch = debounce(value => {
    const { minLengthToSearch = MIN_LENGTH_TO_SEARCH } = this.props

    if (isNil(value) || value.length < minLengthToSearch) { return }
    this.lastFetchId += 1
    const fetchId = this.lastFetchId

    this.setState({ dataSource: EMPTY_ARRAY, fetching: true }, this.doSearch(fetchId, value))
  }, (this.props.debounceTime || DEBOUNCE_TIME))

  doSearch = (fetchId, value) => async () => {
    const { search, filterOption } = this.props
    await sleep(80) // time to render the spinner
    const dataSource = search(value, { filterOption }).result
    if (fetchId === this.lastFetchId) {
      this.setState({ dataSource, fetching: false }, () => {
        (this.props.onFound || noop)(dataSource, this.state.searchText)
      })
    }
  }
}

export default compose(
  connect(null, {
    search: searchMatchingTextAction
  })
)(SearchObject)