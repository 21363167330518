import { compose, withState, withHandlers } from 'recompose'
import { firstUpperCase } from 'utils/string'

export default (name, trueSetter, falseSetter, defaultValue = false, toggle = `toggle${firstUpperCase(name)}`) => {
  const setterName = `set${firstUpperCase(name)}`
  return compose(
    withState(name, setterName, defaultValue),
    withHandlers({
      [trueSetter]: props => () => { props[setterName](true) },
      [falseSetter]: props => () => { props[setterName](false) },
      [toggle]: props => () => {
        props[setterName](!props[name])
      }
    })
  )
}
