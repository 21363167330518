exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "th.actionsColumn__10lui{text-align:center !important}td.actionsColumn__10lui{display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}", ""]);

// exports
exports.locals = {
	"actionsColumn": "actionsColumn__10lui"
};