import React from 'react'
import { Tabs, Icon } from 'antd'

import styles from 'styles/panel.scss'

import UsersTab from './UsersTab'
import InvitesPanel from './InvitesPanel/InvitesPanelContainer'

import classNames from 'classnames'


const { TabPane } = Tabs

const Users = ({ className = {} }) => (
  <div>
    <div className={classNames(styles.panel, className)} >
      <Tabs>
        <TabPane tab={<span><Icon type="user" />Users</span>} key="1">
          <UsersTab />
        </TabPane>
        <TabPane tab={<span><Icon type="mail" />Invites</span>} key="2">
          <InvitesPanel />
        </TabPane>
      </Tabs>
    </div>
  </div>
)

export default Users