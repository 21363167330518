import { SET_CLIPBOARD_META, RESET_CLIPBOARD_META } from 'actions/clipboard'
import { EMPTY_OBJECT } from 'utils/object'

const initialState = EMPTY_OBJECT

export const clipboard = (state = initialState, { type, clipboardMeta }) => {
  switch (type) {
    case SET_CLIPBOARD_META: return clipboardMeta
    case RESET_CLIPBOARD_META: return initialState
    default: return state
  }
}