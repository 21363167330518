import React from 'react'
import { Input } from 'antd'
import Linkify from 'linkifyjs/react'

import { truncate } from 'utils/string'

import EditableValue from './EditableValue'
import inferValue from './inferValue'

const URL_CAP = 20
const SCHEMA_REGEX = new RegExp('(http://||https://)')
const WWW_REGEX = /^www\./
const shortenLink = raw => {
  const shortened = raw
    .replace(SCHEMA_REGEX, '')
    .replace(WWW_REGEX, '')
  return truncate(shortened, URL_CAP)
}
const linkifyOptions = {
  format: (raw, type) => (type !== 'url' ? raw : shortenLink(raw))
}

/**
 *
 */
export default class StringValue extends EditableValue {

  handleChange = e => {
    const { coerceInferringTypeByValue } = this.props
    const { value } = e.target
    if (this.mounted) {
      this.setState({
        value: coerceInferringTypeByValue ? inferValue(value) : value
      })
    }
  }

  renderForEditing() {
    const { saveOnChange = false, placeholder } = this.props
    const onBlur = this.handleSave
    return (
      <Input
        size="small"
        placeholder={placeholder}
        value={this.state.value}
        onChange={this.handleChange}
        {...saveOnChange && { onBlur }}
      />
    )
  }

  transformValueBeforeDisplaying(value) {
    return (
      <Linkify options={linkifyOptions}>{super.transformValueBeforeDisplaying(value)}</Linkify>
    )
  }

}
