exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3G7Pl{color:#add8e6;cursor:pointer}.iconAsLink__3G7Pl:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__28OUR{font-style:italic}.formFieldsVerticalGutter__1fCgR{padding:.5rem}.formFieldsVerticalGutter__1fCgR>*{margin:.5rem !important}.tableBackground__3s1SS .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3s1SS .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__kbIlf .ant-notification-notice-close{display:none}.container__2a4_6{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin:1rem 4rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3G7Pl",
	"labelFromRef": "labelFromRef__28OUR",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1fCgR",
	"tableBackground": "tableBackground__3s1SS",
	"notificationNotClosable": "notificationNotClosable__kbIlf",
	"container": "container__2a4_6"
};