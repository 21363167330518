import React from 'react'

import { Icon, Spin } from 'antd'
import classNames from 'classnames'

import styles from './LoadingModal.scss'

const LoadingCheck = ({ loading }) => (
  <Spin spinning={loading} wrapperClassName={styles.loadingCheck}>
    <div className={classNames(styles.loadCheck, { [styles.completed]: !loading })}>
      <Icon type="check-circle" />
    </div>
  </Spin>
)

export default LoadingCheck