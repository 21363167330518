import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { anyPass, isNil } from 'ramda'
import { isNumber } from 'ramda-adjunct'

import classNames from 'classnames'

import { DEFAULT_ACTOR, model } from 'beanie-engine-api-js'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'

import { updateBNEObject } from 'engine/actions/actions'
import StringValue from 'components/PropertiesEditor/Value/StringValue'

import ActorBigAvatar from './Detail/ActorBigAvatar'

import TextToSpeechDetails from './Detail/TextToSpeechDetails'

import sectionStyles from './ActorSection.scss'
import styles from './ActorDetail.scss'
import BneActorPropertiesEditor from './Detail/BneActorPropertiesEditor'

const { types: { object: { Paths } } } = model

const validSpeechParam = anyPass([isNumber, isNil])

class ActorDetail extends React.Component {

  updateActor = async delta => {
    const { updateBNEObjectAction, actor } = this.props
    await updateBNEObjectAction(actor.id, delta)
  }

  voiceChanged = async (speechVoice, speechPitch, speechRate) => {
    if (!validSpeechParam(speechPitch) || !validSpeechParam(speechRate)) throw new Error('speechRate and speechPitch must be Float, instead received:', speechRate, speechPitch)
    await this.updateActor({ editor: { speechVoice, speechPitch, speechRate } })
  }

  handleAvatarChanged = actorIcon => this.updateActor({ actorIcon })

  handleNameChanged = actorName => this.updateActor({ actorName: actorName.toUpperCase() })
  validateNameChanged = newName => {
    const { validateNewName } = this.props
    if (newName === '') return { isOk: false, message: 'Invalid actor name. You MUST provide a name.' }
    return validateNewName(newName) ? { isOk: true } : { isOk: false, message: 'Invalid actor name. Name already exists.' }
  }

  render() {
    const { actor, hasWriteAccess, onCopyProperties } = this.props
    return (
      <div className={classNames(sectionStyles.actorsSectionCol, sectionStyles.actorDetail)}>
        {actor &&
          <div>
            <div className={styles.principal}>
              <div className={styles.avatar}>
                <ActorBigAvatar actor={actor} onChanged={this.handleAvatarChanged} />
              </div>
              <div className={styles.actorName}>
                <StringValue
                  isEditable={hasWriteAccess && actor.data.actorName !== DEFAULT_ACTOR}
                  showAcceptCancelIcons={false}
                  value={actor && actor.data.actorName}
                  onValueChanged={this.handleNameChanged}
                  validateFn={this.validateNameChanged}
                />
              </div>
            </div>

            <TextToSpeechDetails actor={actor} voiceChanged={this.voiceChanged} />

            <BneActorPropertiesEditor actor={actor} lens={Paths.node.user} onCopyProperties={onCopyProperties} />

          </div>
        }
      </div>
    )
  }
}

export default compose(
  connect(null, {
    updateBNEObjectAction: updateBNEObject
  }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
)(ActorDetail)
