import React from 'react'
import ReactPlayer from 'react-player'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { withRef } from 'utils/recompose'

export const Status = {
  LOADING: 'LOADING',
  PAUSED: 'PAUSED',
  READY: 'READY',
  PLAYING: 'PLAYING',
  STOPPED: 'STOPPED' 
}

// We cannot seek exactly to the start of the segment
// because it will actually show the keyframe that is "before" that.
// that is the silence in our case
// so after try/fail I discovered that positioning a little bit after, 
// works.
const START_OFFSET = 0.03;
/*
 * When to consider the player to have ended the segment.
 * if we currentime >= segment.end - END_THRESHOLD
 * we will considered as ended.
 * Higher the value will make sure we don't overflow to the next clip, but it will stop ahead of time.
 */
const END_THRESHOLD = 0.05;
const VIDEO_POLLING = 10;

const _SegmentVideoPlayer = ({ playerRef, url, seeking, onReady, onStart, onPause, onPlay, onProgress, onEnded, onSeek, onBuffer, onBufferEnd, playerStatus, height = '90vh', width = '100vw' }) => (
  <ReactPlayer
    ref={playerRef()}
    url={url}
    
    playing={playerStatus === Status.PLAYING}
    muted={seeking}

    progressInterval={VIDEO_POLLING}
    width={width}
    height={height}
    config={{
      youtube: {
        playerVars: {
          rel: 0,
          // controls: 1,
          // modestbranding: 0,
          disablekb: 1, // disable keyboard
        }
      }
    }}
    // events
    onReady={onReady}
    onStart={onStart}
    onEnded={onEnded}

    onPause={onPause}
    onPlay={onPlay}
    onProgress={onProgress}

    onSeek={onSeek}
    onBuffer={onBuffer}
    onBufferEnd={onBufferEnd}

  />
)

let playersId = 0

const SegmentVideoPlayer = compose(
  withRef('playerRef'),
  withState('playerId', 'setPlayerId', () => playersId++),
  withState('playerStatus', 'setPlayerStatus', Status.LOADING),
  withState('seeking', 'setSeeking', false),
  // seeking nightmare !
  withHandlers({
    seekTo: ({ playerRef, setPlayerStatus, setSeeking }) => seconds => {
      const player = playerRef().current
      setSeeking(true)
      // play to actully seek onProgress will pause when reaches the start
      setPlayerStatus(Status.PLAYING, () => {
        player.seekTo(seconds, 'seconds')
      })
    },
    seekingEnded: ({ playerRef, setSeeking, playing, segment, setPlayerStatus, onReady }) => playedSeconds => {
      setSeeking(false)
      // seek to the exact time, maybe we went ahead for a bit
      const player = playerRef().current
      player.seekTo(segment.start + START_OFFSET, 'seconds')

      // const msg = `[SegmentedVideoPlayer] onProgress ${id} End of seeking (${truncateFraction(3)(playedSeconds)} >= ${segment.start}) -> `
      if (playing) {
        // console.log(`${msg} -> PLAYING`)
        setPlayerStatus(Status.PLAYING)
      } else {
        // console.log(`${msg} -> READY`)
        onReady(playedSeconds)
        setPlayerStatus(Status.READY)
      }
    }
  }),

  // lifecycle and events
  withHandlers({
    onReady: ({ segment, seekTo, onReady, setPlayerStatus, playerStatus }) => () => {
      // console.log(`[SegmentedVideoPlayer] onReady (${playerStatus}) ${id}`)
      if (playerStatus === Status.LOADING) {
        if (segment.start > 0) {
          // console.log(`[SegmentedVideoPlayer] onReady (${playerStatus}) ${id} -> SEEKING`)
          seekTo(segment.start)
        } else {
          // console.log(`[SegmentedVideoPlayer] onReady (${playerStatus}) ${id} (no seeking) -> READY`)
          onReady()
          setPlayerStatus(Status.READY)
        }
      }
    },
    onBufferEnd: () => () => {
      // console.log(`[SegmentedVideoPlayer] onBufferEnd ${id}`)
    },
    onStart: ({ setPlayerStatus }) => () => {
      // console.log(`[SegmentedVideoPlayer] onStart ${id}`)
      // TODO: control here, if the user hits play then we should bubble that up
      // maybe we shouldn't be playing !
      setPlayerStatus(Status.PLAYING)
    },
    onEnded: ({ playerStatus, setPlayerStatus, onCompleted }) => () => {
      if (playerStatus !== Status.STOPPED) {
        onCompleted()
        setPlayerStatus(Status.STOPPED)
      }
    },
    onPause: ({ playerStatus, setPlayerStatus, onPaused }) => () => {
      // console.log(`[SegmentedVideoPlayer] onPause ${id}`)
      if (playerStatus === Status.PLAYING) {
        setPlayerStatus(Status.PAUSED)
        onPaused()
      }
    },
    onPlay: ({ seeking, playerStatus, setPlayerStatus, onPlaying }) => () => {
      // console.log(`[SegmentedVideoPlayer] onPlay ${id}`)
      if (seeking) {
        // should we just cut seeking at this point instead of in onProgress ?
        setPlayerStatus(Status.PLAYING)
        return
      }
      if (playerStatus !== Status.PLAYING) {
        setPlayerStatus(Status.PLAYING)
        onPlaying()
      }
    },
    onProgress: ({ segment, playing, seekingEnded, onCompleted, setPlayerStatus, playerStatus, seeking }) => ({ playedSeconds }) => {
      // console.log(`[SegmentedVideoPlayer] onProgress ${id} ${truncateFraction(3)(playedSeconds)}secs (start ${segment.start}, end ${segment.end})`)
     
      // seeking + buffering (reaching start)
      if (seeking) {
        if (playedSeconds >= segment.start) {
          seekingEnded(playedSeconds)
        }
        return
      }

      // don't allow to play manually
      if (!playing) {
        setPlayerStatus(Status.PAUSED)
        return
      }

      // reaching end
      if (!seeking && playerStatus === Status.PLAYING && playedSeconds >= (segment.end - END_THRESHOLD)) {
        // console.log(`[SegmentedVideoPlayer] onProgress ${id} ${truncateFraction(3)(playedSeconds)}secs (start ${segment.start}, end ${segment.end}) END REACHED !`)
        setPlayerStatus(Status.STOPPED)
        onCompleted()
      }
    },
  }),
  lifecycle({
    componentWillUnmount() {
      const { setPlayerStatus } = this.props
      setPlayerStatus(Status.STOPPED)
    },
    componentDidUpdate(prevProps) {
      const { setPlayerStatus, playing, playerStatus } = this.props

      // outside asked us to play
      if (!prevProps.playing && playing) {
        // console.log(`[SegmentVideoPlayer] componentDidUpdate() ${id} was not playing => PLAYING !`)
        setPlayerStatus(Status.PLAYING)
        return
      }

      // we were supposed to be playing but were loading, now we are READY, so go ahead
      if (prevProps.playerStatus === Status.LOADING && playerStatus === Status.READY && playing) {
        // console.log(`[SegmentVideoPlayer] ${id} READY => PLAYING`, segment)
        setPlayerStatus(Status.PLAYING)
        // return
      }
      // console.log(`[SegmentVideoPlayer] componentDidUpdate() ${id} state ${playerStatus} !`)
    }
    
  })
)(_SegmentVideoPlayer)


// { [id], url, segment, playing = true / false, onCompleted, onPaused, onPlaying, onReady }
export default SegmentVideoPlayer