exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".modalContainer__zwcpr{min-width:80vw}.shortcuts__eMzZP>.ant-row:not(:last-child){padding-bottom:1rem}.shortcuts__eMzZP .groupCard__gaFP3 .ant-row{padding-bottom:.2em;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.shortcuts__eMzZP .groupCard__gaFP3 .shortcut__3A7hZ{text-transform:uppercase;display:inline-block;background-color:#b6b5b5;padding:.5em;border-radius:5px;color:#fff;font-family:\"Trebuchet MS\",\"Lucida Sans Unicode\",\"Lucida Grande\",\"Lucida Sans\",Arial,sans-serif}", ""]);

// exports
exports.locals = {
	"modalContainer": "modalContainer__zwcpr",
	"shortcuts": "shortcuts__eMzZP",
	"groupCard": "groupCard__gaFP3",
	"shortcut": "shortcut__3A7hZ"
};