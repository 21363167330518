import React from 'react'
import { ChangeType, changeType } from 'beanie-engine-api-js'

import AddedChange from './type/AddedChange'
import DeletedChange from './type/DeletedChange'
import UpdatedChange from './type/UpdatedChange'

/**
 * Shows a ChangeSet's individual "Change". Uses different components
 * for the different types of changes: added, deleted, updated.
 */
const Change = ({ change, ...others }) => {
  const C = getComponent(changeType(change))
  return (
    <C change={change} {...others} />
  )
}

const getComponent = type => {
  switch (type) {
    case ChangeType.UPDATED: return UpdatedChange
    case ChangeType.ADDED: return AddedChange
    case ChangeType.DELETED: return DeletedChange
    default: throw new Error(`Unknown change object type: ${type}`)
  }
}

export default Change
