exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".appOverlay__31CTq{background:#000;opacity:.4}.content__2wIJT{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.content__2wIJT .preview__3inOz{margin-top:.5em;background:#f3f3f3;border:1px dashed #c7c7c7;border-radius:10px;background-size:contain;background-repeat:no-repeat;background-position:center;cursor:pointer;height:16em;width:27em;position:relative}.content__2wIJT .preview__3inOz .overlay__3xFRS{position:absolute;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-positive:1;flex-grow:1;width:100%;height:100%;color:#ababab}.content__2wIJT .preview__3inOz .overlay__3xFRS .anticon{font-size:2.5em}.content__2wIJT .preview__3inOz .overlay__3xFRS span{font-size:.8em}.content__2wIJT .preview__3inOz .overlay__3xFRS span a{color:gray;text-decoration:underline}.content__2wIJT .preview__3inOz .message__2Gm87{padding-top:1rem}.content__2wIJT svg{margin-left:.5em;margin-right:.5em}.content__2wIJT svg line{stroke:#cfcfcf;stroke-width:2}.content__2wIJT>span{font-size:.8em;padding:1em;color:#ccc}", ""]);

// exports
exports.locals = {
	"appOverlay": "appOverlay__31CTq",
	"content": "content__2wIJT",
	"preview": "preview__3inOz",
	"overlay": "overlay__3xFRS",
	"message": "message__2Gm87"
};