import { batch } from 'react-redux'
import { T, cond, always } from 'ramda'
import { Sys, sysIsEditable, isContained, model } from 'beanie-engine-api-js'
import { addRootObject } from 'engine/actions/nodes'
import { selectedObject, objectsBySys } from 'selectors/objects'
import { parentFor } from 'selectors/paths'
import { objectsIndex } from 'selectors/apollo'

import { insertNode, addWithTemplate as addWithTemplateAction } from 'engine/actions/actions'
import { addComponent, addComponentEnabled } from 'engine/actions/composites'
import { addTruthTableRow, addTruthTableRowAfter } from 'engine/actions/truthTable'
import { onNodeSelected, selectVisibleObject } from 'actions/nodes'
import { Creators } from 'actions/ui'

const { types: { object: { Paths } } } = model
const { editStart } = Creators

//
// content edition
//

export const onInsertNode = (sys, initFn, parentNode, connectionFn, afterAction) => async (dispatch, getState) => {
  const parent = parentNode || selectedObject(getState())
  return batch(async () => {
    const node = await dispatch(insertNode(parent.id, sys, initFn, connectionFn))
    await dispatch(sys === Sys.clip ? onNodeSelected(node.get_id()) : selectAndEdit(node))

    if (afterAction) dispatch(afterAction(node))

    return node
  })
}

export const addTruthTable = o => async (dispatch, getState) => {
  const tt = await onInsertNode(Sys.truth_table, (_tt, api) => {
    const otherwise_row = api.obj.new(Sys.truth_table_otherwise_row)
    _tt.set_otherwise(otherwise_row)
  }, o)(dispatch, getState)

  dispatch(editStart(tt.get_id(), [...Paths.truth_table.headers, 0]))
}

export const addWithTemplate = (o, template, connectionFn) => async dispatch => {
  const newOne = await dispatch(addWithTemplateAction(o, template, connectionFn))
  dispatch(selectAndEdit(newOne))
}

export const insertRootObject = (sys, lane) => async dispatch => {
  const object = await dispatch(addRootObject(sys, lane))
  dispatch(selectVisibleObject(object.get_id()))
  dispatch(editStart(object.get_id(), Paths.node.name))
}

//
// containers
//
const getSys = nodeOrObj => (nodeOrObj.get_sys ? nodeOrObj.get_sys() : nodeOrObj.sys)

const isTT = node => getSys(node) === 'truth_table'
const isTTRow = node => getSys(node) === 'truth_table_row'

const addRow = tt => addTruthTableRow(tt)

const addRowBelow = (row, tt) => addTruthTableRowAfter(tt, row)

const CompositeAdd = cond([
  [isTT, addRow],
  [isTTRow, addRowBelow],
  [T, (object, container) => addAndEditComponent(container || object)]
])

export const onInsertComponent = () => async (dispatch, getState) => {
  const state = getState()
  const object = selectedObject(state)
  let container
  if (isContained(object)) {
    const containerId = parentFor(object, objectsBySys(state))
    container = containerId && objectsIndex(state)[containerId]
  }

  return dispatch(selectAndEdit(await dispatch(CompositeAdd(object, container))))
}

export const addAndEditComponent = (composite, component) => async dispatch => {
  if (addComponentEnabled(composite)) {
    return dispatch(selectAndEdit(await dispatch(addComponent(composite, component))))
  }
}

const EditablePath = cond([
  [isTTRow, always([...Paths.truth_table_row.cells, 0])],
  [T, always(Paths.node.name)]
])

export const selectAndEdit = node => dispatch => {
  if (node) {
    dispatch(onNodeSelected(node.get_id()))
    if (sysIsEditable(node.get_sys())) {
      dispatch(editStart(node.get_id(), EditablePath(node)))
    }
  }
}

export const CLEAR_UNDO_HISTORY = 'CLEAR_UNDO_HISTORY'
export const clearUndoHistory = () => ({ type: CLEAR_UNDO_HISTORY })
