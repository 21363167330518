import { pipe, multiply } from 'ramda'
import { sleep } from 'utils/promises'
import calculateTimeToPlay from '../model/clip/captions/calculateTimeToPlay'

/**
 * Does nothing for a given amount of time that is what is supposed to take
 * to display the language-resource text in screen and be read by the user
 */
const noopSay = pipe(calculateTimeToPlay, multiply(1000), sleep)

export default noopSay