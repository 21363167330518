exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameRow__2hl__ .ant-tag{margin-left:.5rem}", ""]);

// exports
exports.locals = {
	"nameRow": "nameRow__2hl__"
};