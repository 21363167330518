import React from 'react'
import { prop } from 'ramda'
import Preferences from 'preferences/Preferences'

import TogglePreferenceButton from 'components/Commons/Preferences/TogglePreferenceButton'
import MultiOptionsPreferenceButton from 'components/Commons/Preferences/MultiOptionsPreferenceButton'

import { safeToUpperCase } from 'utils/string'
import { markupProposals } from 'selectors/markups'
import { noteProposals } from './utils/note-utils'

import styles from './TextEditorControls.scss'
import SyncVideoButton from './SyncVideoButton'
import SubtitlesButton from './SubtitlesButton'

const Functions = {
  trackSelecction: enabled => `${enabled ? 'Disable' : 'Enable'} Selection Tracking`,
  showStoryboards: enabled => `${enabled ? 'Hide' : 'Show'} Storyboards`,

  name: prop('name'),
}

const renderMarkup = markup => (
  <span className={styles[markup.type]}>{safeToUpperCase(markup.name)}</span>
)

const renderNotes = note => <span>{note.name}</span>

const TextEditorControls = () => [
  <SubtitlesButton key="subtitlesButton" />,
  <SyncVideoButton key="syncVideoButton" />,
  <TogglePreferenceButton
    key="toggleTrackSelection"
    type="scan"
    description={Functions.trackSelecction}
    preference={Preferences.TextEditor.trackSelection}
  />,
  <TogglePreferenceButton
    key="toggleStoryboard"
    type="picture"
    description={Functions.showStoryboards}
    preference={Preferences.TextEditor.showStoryboards}
  />,
  <MultiOptionsPreferenceButton
    key="notesVisibility"
    icon="highlight"
    preference={Preferences.TextEditor.visibleNotes}
    description="Show/Hide Notes"
    allSelector={noteProposals}
    optionKey={Functions.name}
    renderOption={renderNotes}
  />,
  <MultiOptionsPreferenceButton
    key="markupsVisibility"
    icon="tag"
    preference={Preferences.TextEditor.visibleMarkups}
    description="Show/Hide Markups"
    allSelector={markupProposals}
    optionKey={Functions.name}
    renderOption={renderMarkup}
  />
]


export default TextEditorControls