exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".buttonIcon__114yJ{width:1.5rem;margin-right:.5rem}", ""]);

// exports
exports.locals = {
	"buttonIcon": "buttonIcon__114yJ"
};