import { pipe, always, prop, cond, all, T } from 'ramda'
import { model } from 'beanie-engine-api-js'

const { types: { markup: { isErrorSeverity, isWarningSeverity } } } = model

export const DEFAULT_COLOR = { r: 238, g: 130, b: 238, a: 1 }
const ERROR_COLOR = { r: 232, g: 58, b: 58, a: 1 }
const WARNING_COLOR = { r: 222, g: 196, b: 52, a: 1 }

export const colorByErrors = pipe(prop('errors'), cond([
  [all(isWarningSeverity), always(WARNING_COLOR)],
  [all(isErrorSeverity), always(ERROR_COLOR)],
  // mixed
  [T, always(ERROR_COLOR)]
]))

export const colorToCSS = ({ r, g, b, a }) => `rgba(${r}, ${g}, ${b}, ${a})`

export const ERROR_COLOR_STRING = colorToCSS(ERROR_COLOR)
export const WARNING_COLOR_STRING = colorToCSS(WARNING_COLOR)
