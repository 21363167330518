import React, { useMemo } from 'react'
import { Icon } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { selectNode } from 'actions/nodes'

import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'
import createChainExitsSelector from './createChainExitsSelector'

import styles from './ChainExits.scss'
import PropertiesEditorStyles from '../PropertiesEditor.scss'

/**
 * Renders a lit of outgoing paths to other chains
 */
const ChainExits = ({ nodeId }) => {
  const outboundFlowsSelector = useMemo(createChainExitsSelector, [nodeId])
  const outboundFlows = useSelector(state => outboundFlowsSelector(state, { nodeId }))
  const dispatch = useDispatch()
  return (
    <div className={styles.Flow}>
      {outboundFlows.length === 0 && (
        <div className={PropertiesEditorStyles.noContentPlaceholder}>No jumps to other chains</div>
      )}
      {outboundFlows.length > 0 && (
        <ul>
          {outboundFlows.map(id => (
            <li key={id}>
              <Icon type="arrow-right" /><SimpleNode nodeId={id} onClick={() => dispatch(selectNode(id))} />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default ChainExits