import { over, lensProp, lensPath, append, prepend, reject, partial, adjust, always } from 'ramda'
import { isArray } from '../utils/object'

export const updateQueryValue = (querySpec, transform) => (store, { data }) => {
  const query = querySpec.query || querySpec
  const { variables } = querySpec
  const args = { query, variables }

  const previous = safelyReadQuery(store, args)

  if (previous) {
    const updated = transform(previous, data)
    store.writeQuery({ ...args, data: updated })
  }
}

// this is hack to solve https://github.com/apollographql/apollo-client/issues/2007
const safelyReadQuery = (store, params) => {
  try {
    return store.readQuery(params)
  } catch (err) {
    return undefined
  }
}

export const updateFragment = ({ fragment, id, fragmentName, transform }) => store => {
  const params = { id, fragment, fragmentName }
  const previous = store.readFragment(params)
  const updated = transform(previous)
  store.writeFragment({ ...params, data: updated })
}

// object updates

export const replace = matcher => (queryName, mutationName) => (previous, data) => {
  const index = previous[queryName].findIndex(matcher)
  return over(lensProp(queryName), adjust(index, always(data[mutationName])), previous)
}

// array updates
export const pushTransform = (queryPath, mutationName, pushFront = false) => (previous, data) =>
  over(lensPath(queryPath), (pushFront ? prepend : append)(data[mutationName]), previous)

const defaultEquals = (toRemove, e) => e._id === toRemove._id
export const pullTransform = (equals = defaultEquals) => (queryNameOrPath, mutationName) => (previous, data) => {
  const toPull = data[mutationName]
  const lensToUpdate = isArray(queryNameOrPath) ? lensPath : lensProp
  return over(lensToUpdate(queryNameOrPath), reject(partial(equals, [toPull])), previous)
}
