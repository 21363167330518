import { withApollo } from 'react-apollo'
import { compose, lifecycle, withHandlers } from 'recompose'
import { always, identity, T } from 'ramda'

import { isFunction } from 'utils/object'
import withBooleanState from 'hocs/withBooleanState'

// hoc for components that register
// to graphql subscriptions.

const emptyVariables = always({})
const call = (f, args) => (isFunction(f) ? f(args) : f)

const SubscribedHoc = ({ variables, query, subscriptionEnabled = T, onSubscriptionEvent = always(identity), onSubscriptionError = always(identity) }) => compose(
  withApollo,
  withBooleanState('subscribed', 'markSubscribed', 'markUnsubscribed'),
  withHandlers(() => {
    let subscription = null
    const subscribe = props => () => {
      return props.client
        .subscribe({ query, variables: call(variables || emptyVariables, props) })
        .subscribe({
          next: onSubscriptionEvent(props),
          error: onSubscriptionError(props)
        })
    }
    return {
      subscribe,
      tryToSubscribe: props => () => {
        const { subscribed, markSubscribed } = props
        if (!subscribed && subscriptionEnabled(props)) {
          subscription = subscribe(props)()
          markSubscribed()
        }
      },
      tryToUnsubscribe: () => () => {
        if (subscription) {
          subscription.unsubscribe()
          subscription = null
        }
      }
    }
  }),
  lifecycle({
    componentDidMount() { this.props.tryToSubscribe() },
    componentDidUpdate() {
      const { subscribed, markUnsubscribed, tryToSubscribe, tryToUnsubscribe } = this.props
      if (subscribed && !subscriptionEnabled(this.props)) {
        tryToUnsubscribe()
        markUnsubscribed()
      } else {
        tryToSubscribe()
      }
    },
    componentWillUnmount() { this.props.tryToUnsubscribe() }
  })
)

export default SubscribedHoc
