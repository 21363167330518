import { allPass, propEq, pipe, prop, hasPath } from 'ramda'
import { Creators } from 'actions/ui'
import { Sys, isEditable, model, isContained } from 'beanie-engine-api-js'
import { isCurrentRevisionWritable } from 'security/project'
import Contexts from 'shortcuts/contexts'

import { newLaneName } from 'selectors/objects'
import { selectedNodeObject, isEditing } from 'selectors/selectors'
import { isGlobalSearchActive } from 'selectors/ui'
import { active as isWalklistActive } from 'selectors/walklist'
import { currentDebugScenarioSelected, idIsPinned } from '../selectors/debuggingData'

// actions
import { Creators as WalkListCreators } from 'actions/walklist'
import { onInsertNode, addAndEditComponent, addWithTemplate, insertRootObject, addTruthTable } from 'actions/edit'
import { deleteSelectedNodes, deleteLane } from 'engine/actions/actions'
import { breakToNewRootMarker, addEnabledRule, addLane, toggleEnableDisableSelectedNode, enableSelectedNodes, disableSelectedNodes, } from 'engine/actions/nodes'
import { togglePinUnpinSelectedNode, putPinToNodes, removePinToNodes } from 'actions/project/debuggingData'
import { breakToNewLane } from 'engine/actions/lanes'
import { setMode } from 'engine/actions/sequencer'
import { setBlock } from 'engine/actions/taskContainer'
import { noop } from 'utils/functions'

import createConditionalTemplate from '../engine/actions/templates/createConditionalTemplate'
import { onInsertComponent } from '../actions/edit'
import createTimedChoiceTemplate from '../engine/actions/templates/createTimedChoiceTemplate'
import { addAndEditTruthTableColumn, addOtherwiseRow, addTruthTableRow } from '../engine/actions/truthTable'
import { getMetadataForSys } from '../model/metadata/UIMetadata'
import Groups from './Groups'

const { types: { object: { sysHasMode, isDisabled, Paths }, node: { hasNoChild }, truthTable: { otherwise } } } = model

const { editSelectedStart, editEnd, closeGlobalSearch, focusOnTextEditor } = Creators
const { close: closeWalkList } = WalkListCreators

export const ENABLE_DISABLE_TOGGLE = {
  label: o => (isDisabled(o) ? 'Enable' : 'Disable'),
  icon: o => (isDisabled(o) ? 'tick-circle' : 'ban-circle'),
  group: Groups.Editing,
  action: toggleEnableDisableSelectedNode,

  check: isCurrentRevisionWritable
}
export const ENABLE = {
  label: 'Enable selected',
  group: Groups.Editing,
  icon: 'tick-circle',
  action: enableSelectedNodes,
}

export const DISABLE = {
  label: 'Disable selected',
  group: Groups.Editing,
  icon: 'ban-circle',
  action: disableSelectedNodes,
}

export const DEBUGGING_PIN_TOGGLE = {
  label: (o, state) => (idIsPinned(o.id, state) ? 'Remove debugging pin' : 'Put debugging pin'),
  icon: (o, state) => (idIsPinned(o.id, state) ? 'unpin' : 'pin'),
  group: Groups.Editing,
  enabled: (obj, state) => idIsPinned(obj.id, state) || isContained(obj) || hasPath(Paths.object.enabled_rule, obj),
  action: togglePinUnpinSelectedNode,
}

export const PUT_DEBUGGING_PIN = {
  label: 'Put debugging pin',
  group: Groups.Editing,
  icon: 'pin',
  action: putPinToNodes,
}

export const REMOVE_DEBUGGING_PIN = {
  label: 'Remove debugging pin',
  group: Groups.Editing,
  icon: 'unpin',
  enabled: state => !!currentDebugScenarioSelected(state),
  action: removePinToNodes,
}

export const DELETE = {
  label: 'Delete',
  icon: 'anticon-delete',
  group: Groups.Editing,
  shortcut: { osx: 'backspace', windows: 'delete', linux: 'delete' },
  action: deleteSelectedNodes,
  context: Contexts.Tree,

  check: isCurrentRevisionWritable
}

// edit
export const EDIT = ({
  label: 'Edit',
  icon: 'anticon-edit',
  group: Groups.Editing,
  shortcut: ['f2', 'enter'],
  preventDefault: true,
  action: editSelectedStart,
  visible: isEditable,
  context: Contexts.Tree,

  check: isCurrentRevisionWritable
})

export const CANCEL_EDIT = {
  shortcut: 'esc',
  group: Groups.Editing,
  action: (...args) => (dispatch, getState) => {
    const state = getState()
    if (isEditing(state)) {
      return dispatch(editEnd(...args))
    }
    if (isWalklistActive(state)) {
      return dispatch(closeWalkList())
    }
    if (isGlobalSearchActive(state)) {
      return dispatch(closeGlobalSearch())
    }
  },
  context: Contexts.Tree,
}

// adds
const iconAndLabelFor = sys => {
  const metadata = getMetadataForSys(sys)
  return (metadata ? {
    icon: metadata.icon ? `anticon-${metadata.icon}` : undefined,
    label: metadata.label
  } : {})
}

const makeAddShortcut = suffix => ({ osx: `command+i ${suffix}`, windows: `ctrl+i ${suffix}`, linux: `ctrl+i ${suffix}` })
const _add = (sys, shortcutSuffix, initFn = noop, afterAction) => ({
  ...(shortcutSuffix ? { shortcut: makeAddShortcut(shortcutSuffix) } : {}),
  ...iconAndLabelFor(sys),
  action: (parent, connectionFn) => onInsertNode(sys, initFn, parent, connectionFn, afterAction),
  context: Contexts.Tree,

  check: isCurrentRevisionWritable
})
export const ADD_MARKER = _add(Sys.marker, 'm')
export const ADD_CLIP = _add(Sys.clip, 'c', undefined, focusOnTextEditor)
export const ADD_CHOICES = _add(Sys.choices, 'h')
export const ADD_CHOICES2 = _add(Sys.choices2)
export const ADD_JUMP = _add(Sys.jump, 'j')
export const ADD_SEQUENCER = _add(Sys.sequencer, 's')
export const ADD_STOP = _add(Sys.stop, '.')
export const ADD_REFERENCE = _add(Sys.reference, 'r')
export const ADD_TASK_CONTAINER = _add(Sys.task_container, 'k')
export const canFolderBeAddedToFolderItem = allPass([
  propEq('sys', Sys.folder_item),
  hasNoChild
])
export const ADD_FOLDER = _add(Sys.folder, 'f')
export const ADD_LUA = _add(Sys.lua, ';')
export const ADD_TIMER = _add(Sys.timer, 'i')
export const ADD_ACTION = _add(Sys.action, 'a')
export const ADD_ACTION2 = _add(Sys.action2, 'e')
export const ADD_CONDITIONAL = _add(Sys.conditional, 'o')

export const ADD_TRUTH_TABLE = {
  ..._add(Sys.truth_table, 't'),
  action: addTruthTable,
}

// templates

const _addWithTemplate = (label, icon, template, i) => ({
  label,
  icon,
  action: (parent, connectionFn) => addWithTemplate(parent, template, connectionFn),
  params: selectedNodeObject,
  shortcut: makeAddShortcut(i),

  check: isCurrentRevisionWritable
})

const timedChoiceTemplateWith = nrOfChoices => _addWithTemplate(`Timed Choice ${nrOfChoices}`, 'anticon-fork', createTimedChoiceTemplate(nrOfChoices), nrOfChoices)
export const ADD_TEMPLATE_TIMED_CHOICE_1 = timedChoiceTemplateWith(1)
export const ADD_TEMPLATE_TIMED_CHOICE_2 = timedChoiceTemplateWith(2)
export const ADD_TEMPLATE_TIMED_CHOICE_3 = timedChoiceTemplateWith(3)
export const ADD_TEMPLATE_TIMED_CHOICE_4 = timedChoiceTemplateWith(4)
export const ADD_TEMPLATE_CONDITIONAL = _addWithTemplate('Conditional Template', 'anticon-question', createConditionalTemplate, 5)

// adding roots
const addRoot = sys => ({
  ...iconAndLabelFor(sys),
  action: lane => insertRootObject(sys, lane),
  context: Contexts.Tree,
  check: isCurrentRevisionWritable
})

export const ADD_ROOT_MARKER = addRoot(Sys.marker)
export const ADD_ROOT_FOLDER = addRoot(Sys.folder)

// add container components

// IMPORTANT ! don't delete this although not directly referenced it is used by the keyboard shortcuts
// when doing "shift+enter". ALSO THIS MUST BE DECLARED BEFORE the next commands having the same shortcut !
export const ADD_COMPONENT = {
  shortcut: 'shift+enter',
  action: onInsertComponent,
  context: Contexts.Tree,
  check: isCurrentRevisionWritable
}

const _addAndEditComponent = (label, sys) => ({
  label: `Add ${label}`,
  shortcut: 'shift+enter',
  action: addAndEditComponent,
  visible: propEq('sys', sys),
  context: Contexts.Tree,
  icon: 'small-plus',
  check: isCurrentRevisionWritable
})

export const ADD_AND_EDIT_TT_COLUMN = ({
  label: 'Add Column',
  shortcut: 'shift+c',
  action: addAndEditTruthTableColumn,
  params: selectedNodeObject,
  visible: propEq('sys', Sys.truth_table),
  context: Contexts.Tree,
  check: isCurrentRevisionWritable
})

export const ADD_TT_ROW = ({
  label: 'Add Row',
  shortcut: 'shift+r',
  action: addTruthTableRow,
  params: selectedNodeObject,
  visible: propEq('sys', Sys.truth_table),
  context: Contexts.Tree,

  check: isCurrentRevisionWritable
})

export const ADD_TT_DEFAULT_ROW = ({
  label: 'Add Otherwise row',
  shortcut: 'shift+d',
  action: addOtherwiseRow,
  params: selectedNodeObject,
  visible: obj => obj.sys === Sys.truth_table && !otherwise(obj),
  context: Contexts.Tree,

  check: isCurrentRevisionWritable
})

export const ADD_AND_EDIT_CHOICE = _addAndEditComponent('Choice', Sys.choices)
export const ADD_AND_EDIT_CHOICE_TO_CHOICES2 = _addAndEditComponent('Choice', Sys.choices2)
export const ADD_AND_EDIT_CONDITION = _addAndEditComponent('Condition', Sys.conditional)
export const ADD_AND_EDIT_LOGIC_CONDITION = _addAndEditComponent('Condition', Sys.logic)
export const ADD_AND_EDIT_FOLDER_ITEM = _addAndEditComponent('Folder Item', Sys.folder)
export const ADD_AND_EDIT_SEQUENCE = _addAndEditComponent('Sequence', Sys.sequencer)
export const ADD_AND_EDIT_TASK_CONTAINER = _addAndEditComponent('Task Item', Sys.task_container)

export const ADD_ENABLED_RULE = ({
  label: 'Add Enabled Rule',
  action: addEnabledRule,
  visible: o => !(o.sys === Sys.folder || o.sys === Sys.logic),
  enabled: o => !o.data.logic_node && !o.data.enabled_rule,
  icon: 'switch',
  check: isCurrentRevisionWritable
})

// modes
export const SET_MODE = ({
  label: 'Behavior...',
  visible: pipe(prop('sys'), sysHasMode),
  action: setMode,
  check: isCurrentRevisionWritable
})

// async

export const SET_BLOCK = ({
  label: 'Blocking...',
  visible: propEq('sys', Sys.task_container),
  action: setBlock,
  check: isCurrentRevisionWritable
})

// breaks

export const BREAK_TO_ROOT = ({
  label: 'to new root Marker',
  enabled: o => !!o.data.name,
  action: breakToNewRootMarker,
  check: isCurrentRevisionWritable
})
export const BREAK_TO_LANE = ({
  label: 'to new Lane',
  enabled: o => !!o.data.name,
  action: breakToNewLane,
  check: isCurrentRevisionWritable
})

// lanes

export const ADD_LANE = ({
  label: 'Add Lane',
  action: addLane,
  params: newLaneName,
  check: isCurrentRevisionWritable
})

export const DELETE_LANE = ({
  label: 'Delete Lane',
  action: deleteLane,
  check: isCurrentRevisionWritable
})
