import { connect } from 'react-redux'
import { applySpec } from 'ramda'

import { volume } from 'selectors/view'
import { setVolume } from 'actions/view'

import VolumeControl from './VolumeControl'

const VolumeControlContainer = connect(
  applySpec({ value: volume }),
  { onChange: setVolume }
)(VolumeControl)

export default VolumeControlContainer