exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".box__JZmm6{position:absolute;bottom:1rem;-ms-flex-item-align:center;align-self:center;pointer-events:all;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;background-color:#000;opacity:.7;border:1px solid gray;border-radius:5px;padding:1rem;padding-top:.2rem;padding-bottom:.2rem;font-size:.7rem}.box__JZmm6 .title__1b4SK{cursor:default;text-transform:uppercase;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.box__JZmm6 .subtitle__3Zx_p{text-transform:uppercase;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.box__JZmm6 .line__2ck8z{stroke-width:8px;cursor:pointer}.box__JZmm6 .line__2ck8z.current__1afVR{stroke:#06f7bf;stroke-width:15px}", ""]);

// exports
exports.locals = {
	"box": "box__JZmm6",
	"title": "title__1b4SK",
	"subtitle": "subtitle__3Zx_p",
	"line": "line__2ck8z",
	"current": "current__1afVR"
};