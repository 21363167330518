import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { T } from 'ramda'
import { Icon, Input, Button } from 'antd'
import { getDisplayName } from 'hocs/utils'
import { EMPTY_ARRAY } from 'utils/object'

import styles from './filteringTable.scss'

const filterRegEx = searchText => new RegExp(searchText, 'gi')
const getFilteringFunction = (searchText, propExtractor) => item => propExtractor(item)?.match(filterRegEx(searchText))

const FilterDropdown = ({ searchInputRef, setSearchText, setVisible, setFiltered, setData, searchText, propExtractor, allItems }) => {
  const [value, setValue] = useState(searchText || '')

  const doSearch = useCallback(() => {
    if (value === searchText) return
    setSearchText(value)
    setVisible(false)
    setFiltered(!!value)
    setData(allItems.filter(getFilteringFunction(searchText, propExtractor)))
  }, [value, allItems, propExtractor, searchText, setSearchText, setVisible, setFiltered, setData])

  return (
    <div className={styles.filterDropdown}>
      <Input
        ref={searchInputRef}
        placeholder="Matching"
        value={value}
        onChange={e => setValue(e.target.value)}
        onPressEnter={doSearch}
      />
      <Button type="primary" onClick={doSearch}>Filter</Button>
    </div>
  )
}

const filtering = props => {
  const { visible, searchText, filtered, searchInputRef, setVisible, propExtractor, columnTitle, setFiltered, setData, setSearchText, allItems } = props

  const doClear = () => {
    setFiltered(false)
    setData(allItems)
    setSearchText('')
  }

  return ({
    title: <ColumnTitle searchText={searchText} title={columnTitle} onClearSearch={doClear} />,
    filterDropdown: (
      <FilterDropdown
        searchText={searchText}
        setSearchText={setSearchText}
        setVisible={setVisible}
        setFiltered={setFiltered}
        setData={setData}
        allItems={allItems}
        searchInputRef={searchInputRef}
        propExtractor={propExtractor}
      />
    ),
    filterIcon: (
      <Icon type="filter" style={{ color: filtered ? '#108ee9' : '#aaa' }} />
    ),
    filterDropdownVisible: visible,
    onFilterDropdownVisibleChange: _visible => {
      setVisible(_visible)
      if (searchInputRef.current) searchInputRef.current.focus()
    },
    filtered: true
  })
}

export const filterHoc = (dataPropName, propExtractor, columnTitle = 'Name') => Component => {

  const TableFiltering = props => {
    const { [dataPropName]: _dataFromProps, ...others } = props

    const allItems = _dataFromProps || EMPTY_ARRAY
    const searchInputRef = useRef()
    const [visible, setVisible] = useState(false)
    const [filtered, setFiltered] = useState(false)
    const [searchText, setSearchText] = useState('')
    const filter = useMemo(() => (filtered ? getFilteringFunction(searchText, propExtractor) : T), [searchText, filtered, propExtractor])

    const [data, setData] = useState(filter ? allItems.filter(filter) : allItems)

    useEffect(() => {
      setData(allItems.filter(filter))
    }, [allItems, filter, setData])

    const filteringProps = useMemo(() => () => filtering({
      filtered,
      setFiltered,
      searchInputRef,
      setData,
      searchText,
      setSearchText,
      allItems,
      visible,
      setVisible,
      propExtractor,
      columnTitle
    }), [propExtractor, columnTitle, filtered, setFiltered, searchInputRef, setData, searchText, setSearchText, allItems, visible, setVisible])

    return (
      <Component
        {...others}
        searchText={searchText}
        filteringProps={filteringProps}
        {...{ [dataPropName]: data }}
      />
    )
  }

  TableFiltering.displayName = `TableFiltering(${getDisplayName(Component)})`
  return TableFiltering
}

export default filterHoc

export const ColumnTitle = ({ searchText, title, onClearSearch }) => (
  <div className={styles.columnTitle}>
    {title}
    {searchText && (
      <span>({searchText} <Icon type="close" onClick={onClearSearch} />)</span>
    )}
  </div>
)