import React from 'react'
import { Icon, Table, Tooltip } from 'antd'

import SearchBox from 'components/Commons/SearchBox'
import SectionElementsTable from '../Section/SectionElementsTable/SectionElementsTable'
import InviteToOrganizationButton from './InviteToOrganizationButton'
import Secure from 'components/Commons/Secure'
import { orgWriteAccess } from 'security/organization'
import InvitesRowActions from 'components/Users/InvitesPanel/InvitesRowActions'
import RelativeTime from 'components/Commons/RelativeTime'
import Date from 'components/Commons/Date'
import UserAvatar from 'components/RevisionSessions/UserAvatar'

import styles from './OrganizationMembersPane.scss'

const { Column } = Table

const OrganizationInvitesPane = ({ organization, organizationInvites, pageSize, onPageChanged, handleTableChange, onSearch, refreshItems, refreshMembers, resendInvite, deleteInvite }) => (
  <div className={styles.container}>
    <Header organization={organization} onSearch={onSearch} refreshItems={refreshItems} refreshMembers={refreshMembers} />
    {organizationInvites &&
      <SectionElementsTable
        className={styles.invitesTable}
        data={organizationInvites}
        dataPath={['organization', 'invites']}
        pageSize={pageSize}
        scroll={{ x: '30vw', y: '55vh' }}
        onPageChanged={onPageChanged}
        handleTableChange={handleTableChange}
        refreshItems={refreshItems}
        actions={invite => <InvitesRowActions invite={invite} resendInvite={resendInvite} deleteInvite={deleteInvite} />}
        >

        <Column
          title="Email"
          key="email"
          sorter
          dataIndex="email"
          width="20vw"
        />
        <Column
          title="Role"
          key="organizationRole"
          dataIndex="organizationRole"
          width="8vw"
        />
        <Column
          title="Created At"
          key="created_at"
          sorter
          render={invite => (<RelativeTime date={invite.created_at} />)}
          width="8vw"
        />
        <Column
          title="Invited by"
          key="invitedBy"
          dataIndex="invitedBy"
          render={inviter => (<UserAvatar user={inviter} contextMenuEnabled={false} />)}
          width="8vw"
        />
        <Column
          title="Accepted?"
          key="accepted_at"
          dataIndex="accepted_at"
          render={accepted_at => (accepted_at ? <Tooltip title={<Date d={accepted_at} />}><Icon type="check" /></Tooltip> : null)}
          width="8vw"
        />

      </SectionElementsTable>
    }
  </div>
)

const Header = ({ organization, onSearch, refreshItems, refreshMembers }) => (
  <div className={styles.header}>
    <SearchBox onSearch={onSearch} />
    <Secure context={{ organization }} check={orgWriteAccess}>
      <InviteToOrganizationButton organization={organization} onInvited={refreshItems} refreshMembers={refreshMembers} />
    </Secure>
  </div>
)

export default OrganizationInvitesPane