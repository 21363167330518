import { DropTarget } from 'react-dnd'

/**
 * TODO: this makes no sense anymore
 */
const withCustomDrop = ({ dropTypes, target, collect }) =>
  DropTarget(dropTypes, target, collect || genericDropCollect)

const genericDropCollect = (c, monitor) => ({
  connectDropTarget: c.dropTarget(),
  isOver: monitor.isOver(),
  currentItemType: monitor.getItem()?.itemType
})

export default withCustomDrop