import React from 'react'
import { cond, propEq, always, T } from 'ramda'
import { isBoolean } from 'ramda-adjunct'
import { model } from 'beanie-engine-api-js'
import { isComplexObject } from 'utils/object'

import { SimpleNodeContainer } from 'components/Commons/SimpleNode'

const { types: { choices: { StateFields } } } = model

// components

const RefToNodeValue = ({ value, onClick }) => (
  <SimpleNodeContainer nodeId={value} onClick={() => onClick(value)} />
)

const BooleanTypeRenderer = ({ value }) => JSON.stringify(value)
const ComplexTypeRenderer = ({ value }) => <pre>{JSON.stringify(value)}</pre>
const DefaultBasicTypeRenderer = ({ value }) => value

const componentForEventValue = cond([
  [isBoolean, always(BooleanTypeRenderer)],
  [isComplexObject, always(ComplexTypeRenderer)],
  [T, always(DefaultBasicTypeRenderer)]
])

const DefaultStateValue = ({ value }) => {
  const C = componentForEventValue(value)
  return <C value={value} />
}

// dispatch

const componentForEvent = cond([
  [propEq('field', StateFields.LAST_SELECTED_CHOICE_NODE_ID), always(RefToNodeValue)]
])

const StateFieldValue = ({ event, onClick }) => {
  const C = componentForEvent(event) || DefaultStateValue
  return <C value={event.value} onClick={onClick} />
}

export default StateFieldValue

