import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { compose } from 'recompose'
import { model } from 'beanie-engine-api-js'

import { mapIndexed } from 'utils/ramda'

import makeChildrenChainSelector from 'selectors/objects/makeChildrenChainSelector'
import { propFromProps } from 'selectors/props'
import { removeChainElement } from '../../../engine/actions/node/removeChainElement'
import useTransaction from '../../../hooks/beanie/useTransaction'
import { curriedIntersperse } from '../../../utils/object'

import TreeChainElement from './TreeChainElement'

import CollapseChainButton from './CollapseChainButton'
import ExpandChainButton from './ExpandChainButton'
import withOnCollapseOnExpand from 'hocs/withOnCollapseOnExpand'

import styles from './TreeChain.scss'

const { types: { relationship: { Relationship } } } = model

/**
 * Component for a Tree "chain" that is a set of nodes connected through children/parent relationship.
 * (or other kind of relationships like "rootOfLane" which is a rel between a root marker and the project's object lanes prop)
 *   I.e.:   A -> B -> C -> D
 * Given a node "id" it computes the chain
 */

const TreeChain = ({ id, children, relationship, onCollapse, onExpand, onRemoveFromReferencer, isRoot, isForcedToShowDisabled }) => {

  /** Removes a node from the chain (children relationship) */
  const removeChildRef = useTransaction(api => elementIndex => (
    removeChainElement(api)(id, children.chain, elementIndex)
  ), [id, children.chain], 'Remove children')

  return (
    <div className={styles.TreeChain}>
      <TreeChainElement
        key={id}
        id={id}
        relationship={relationship}

        isForcedToShowDisabled={isForcedToShowDisabled}
        onRemoveFromReferencer={onRemoveFromReferencer}
        isRoot={isRoot}
      />

      {isNotEmpty(children.chain) &&
      <CollapseChainButton id={id} onCollapse={onCollapse} />
      }

      {pipe(
        mapIndexed(({ id: childId, parent }, i) => (
          <IndexedElement
            i={i}
            key={childId}
            childId={childId}
            parent={parent}

            removeFromParent={removeChildRef}
            isForcedToShowDisabled={isForcedToShowDisabled}
          />
        )),
        curriedIntersperse(i => <CollapseChainButton key={`collapse-${i}`} id={children.chain[i].id} onCollapse={onCollapse} />)
      )(children.chain)}

      {children.remaining > 0 &&
      <ExpandChainButton count={children.remaining} onExpand={onExpand} />
      }
    </div>
  )
}

/**
 * This is just a wrapper to try to reduce the amount of re-renders
 * Cause we use the array index while iterating with "mapIndexed"
 */
const IndexedElement = ({ childId, parent, i, removeFromParent, isForcedToShowDisabled }) => {
  const relationship = useMemo(() => Relationship.child(parent), [parent])
  const onRemoveFromReferencer = useCallback(() => removeFromParent(i), [i])
  return (
    <TreeChainElement
      id={childId}
      relationship={relationship}
      onRemoveFromReferencer={onRemoveFromReferencer}

      isForcedToShowDisabled={isForcedToShowDisabled}
    />
  )
}

export default compose(
  connect(() => {
    const selector = makeChildrenChainSelector(propFromProps('id'))
    return (state, props) => ({
      children: selector(state, props)
    })
  }),
  withOnCollapseOnExpand(),
)(TreeChain)