import { findIndex, equals, update, insert, append, without, adjust, propEq, pipe, over, lensProp, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { model, Sys, lang } from 'beanie-engine-api-js'
import breakScene from './node/breakScene'
import { toBNEAction } from './utils'
import { EMPTY_STRING } from 'utils/string'

import { createPathTo } from 'selectors/paths'
import { currentLaneName } from 'selectors/lanes'
import { projectObject } from 'selectors/objects'
import { revisionId } from 'selectors/project'
import { selectedNodes } from 'selectors/nodeSelection'
import { emptyObjectToArray, first } from 'utils/object'
import { Creators } from 'actions/ui'
import { enableObjects, disableObjects } from 'engine/actions/objects'

import { expand } from 'actions/view'

const { types: { object: { Paths, isDisabled }, projectObject: { findLaneForMarker, getLaneIdxByMarker, getLane, insertPosition } } } = model
const { rule: { parser } } = lang

const { editStart } = Creators

export const breakToNewRootMarker = toBNEAction(
  o => (api, dispatch, getState) => {
    const project = projectObject(getState())
    const rootMarkerId = first(createPathTo(o.id)(getState()))
    const lane = findLaneForMarker(rootMarkerId, project)

    if (isNotNil(lane)) {
      updateProjectLanes(addRootToLane(lane.name, o.id, rootMarkerId, true))(api, dispatch, getState)
    }
    breakScene(o.id)(api)
  },
  'Break to new root marker'
)

export const newObject = toBNEAction(sys => api => api[sys].new(), 'New object')

export const insertParentNode = toBNEAction(
  (childId, parentId) => api => {
    const parent = api.obj.get(parentId)
    api.obj.get(childId).insert_after(parent)
  }
)

export const addRootObject = toBNEAction(
  (sys = Sys.marker, laneName) => (api, dispatch, getState) => {
    const state = getState()
    const newRoot = api[sys].new()
    const targetLane = laneName || currentLaneName(state)
    const project = projectObject(getState())
    if (!project || !project.data || !project.data.editor || !project.data.editor.lanes) throw new Error('Project has no project bne object or it has no lanes data in it.')
    updateProjectLanes(addRootToLane(targetLane, newRoot.get_id()))(api, dispatch, getState)

    return newRoot
  }, 'Add root object'
)

export const addLane = toBNEAction(
  name => (api, dispatch, getState) => {
    updateProjectLanes(lanes => append({ name, roots: [] }, lanes))(api, dispatch, getState)
    dispatch(expand(revisionId(getState()), name))
  }
)

export const EMPTY_RULE = { source: EMPTY_STRING }

export const EMPTY_RULE_WITH_PARSE_ERROR = { source: EMPTY_STRING, rule: parser(EMPTY_STRING) }

export const addEnabledRule = toBNEAction(
  node => (api, dispatch) => {
    api.update(node.id, { enabled_rule: EMPTY_RULE })
    dispatch(editStart(node.id, Paths.object.enabled_rule))
  },
  'Add Enabled Rule'
)

// lanes

// If it finds the rootId to be a root marker in a lane, then it will stop being.
// If the rootId is not a root marker of a lane (not found) then the same lanes meta will be returned
export const removeRootFromLane = rootId => lanes => adjust(
  getLaneIdxByMarker(rootId, lanes),
  over(
    lensProp('roots'),
    pipe(emptyObjectToArray, without([rootId]))
  )
)(lanes)

const insertNewRootPosition = (afterId, insertAfter, roots) =>
  insertPosition(findIndex(equals(afterId))(roots), insertAfter)

export const addRootToLane = (laneName, rootId, afterId, insertAfter = false) => lanes => {
  const fn = !getLane(laneName, lanes) ?
    append({ name: laneName, roots: [rootId] })
    : updateLaneRoots(laneName,
      roots => insert(
        insertNewRootPosition(afterId, insertAfter, roots),
        rootId,
        roots
      )
    )
  return fn(lanes)
}

export const updateProjectLanes = updateFn => (api, dispatch, getState) => {
  const { id, data: { editor: { lanes } } } = projectObject(getState())
  return api.update(id, {
    editor: {
      lanes: updateFn(lanes)
    }
  })
}

export const updateLane = (name, updateFn) => lanes => {
  const i = findIndex(propEq('name', name), lanes)
  return i >= 0 ? adjust(i, updateFn, lanes) : lanes
}

export const updateLaneRoots = (laneName, updateFn) => updateLane(
  laneName,
  over(lensProp('roots'), pipe(
    emptyObjectToArray,
    updateFn
  ))
)

export const insertRootAtLaneAndIndex = (laneName, index, root) => updateLaneRoots(
  laneName,
  insert(index, root)
)

/** Id => Lane => Integer */
export const findRootIndexOnLane = rootId => pipe(prop('roots'), findIndex(equals(rootId)))

// overwrites an id with another
export const updateRootAtLane = (laneName, rootId, newRootId) => updateLaneRoots(
  laneName,
  roots => update(
    findIndex(equals(rootId), roots),
    newRootId,
    roots
  )
)

// checks whether to call enable or disable, then gets all selected node ids and passes to enable/disable actions
// TODO: rename to be explicit of use from contexmenu? meaning, the node arg just makes sense for that right?
export const toggleEnableDisableSelectedNode = node => dispatch => {
  // only supposed to be used when single selection
  const action = isDisabled(node) ? enableObjects : disableObjects
  return dispatch(action([node.id]))
}

export const enableSelectedNodes = () => (dispatch, getState) =>
  dispatch(enableObjects(selectedNodes(getState())))

export const disableSelectedNodes = () => (dispatch, getState) =>
  dispatch(disableObjects(selectedNodes(getState())))