import React from 'react'
import UserName from 'components/Commons/UserName'
import LinkButton from 'components/Commons/LinkButton'
import userChangeProfileImageMutation from 'api/mutations/userChangeProfileImage.graphql'

import { updateUserData } from 'actions/login'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { token as tokenSelector, user as currentUserSelector } from 'selectors/auth'
import { userImageGetURL, userImagePostURL } from 'model/resources'

import styles from './HomeUserInfo.scss'
import AvatarUpload from '../ActorSection/AvatarUpload'

/* eslint no-undef: 0 */
const HomeUserInfo = ({ user, token, logout, handleChangePassword, handleProfileImageChanged }) => {
  const imageFile = user.profileImage ? userImageGetURL(user._id || user.id, user.profileImage, token) : undefined
  return (
    <div className={styles.content}>
      <div className={styles.avatar}>
        <AvatarUpload
          imageFile={imageFile}
          size={150}
          uploadURL={userImagePostURL(user._id, token)}
          onChange={handleProfileImageChanged}
        />
      </div>
      <div className={styles.fullName}><UserName user={user} /></div>
      <div className={styles.email}>{user.email}</div>
      {handleChangePassword && <LinkButton onClick={handleChangePassword}>Change Password</LinkButton>}
      {logout && <LinkButton onClick={logout}>Log out</LinkButton>}
      <div className={styles.beanieVersion}>{`v${VERSION}`}</div>
    </div>
  )
}

export default compose(
  graphql(userChangeProfileImageMutation, { name: 'changeProfileImage' }),
  connect(state => ({
    user: currentUserSelector(state),
    token: tokenSelector(state)
  }), ({ updateUserDataAction: updateUserData })),
  withHandlers({
    handleProfileImageChanged: ({ changeProfileImage, user, updateUserDataAction }) => fileName => {
      changeProfileImage({ variables: { input: {
        id: user._id,
        fileName
      } } }).then(({ data: { userChangeProfileImage } }) => {
        updateUserDataAction(userChangeProfileImage)
      })
    }
  })
)(HomeUserInfo)