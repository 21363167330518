import React from 'react'
import { compose } from 'recompose'

import paginatedQuery from 'hocs/paginatedQuery'
import teamMembersQuery from 'api/queries/teamMembers.graphql'

import TeamMembers from './TeamMembers'

const WithQuery = compose(
  paginatedQuery({
    query: teamMembersQuery,
    name: 'teamMembers',
    pageSize: 20,
    variables: ({ team: { organization: { name: organizationName }, name: teamName } }) => ({
      organizationName, teamName
    })
  })
)(TeamMembers)

const TeamMembersContainer = ({ team }) => (team ? <WithQuery team={team} /> : <TeamMembers />)

export default TeamMembersContainer