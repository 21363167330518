import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import { debounceMutation } from 'utils/graphql'
import userInvitesDelete from 'api/mutations/userInvitesDelete.graphql'
import userInvitesResend from 'api/mutations/userInvitesResend.graphql'
import paginatedQuery from 'hocs/paginatedQuery'
import organizationInvitesQuery from 'api/queries/organizationInvites.graphql'

import OrganizationInvitesPane from './OrganizationInvitesPane'

const resendInviteDebouncing = debounceMutation(
  'Invite resent succesfully',
  'There was a problem resending the invite, try again later.'
)

const WithQuery = compose(
  paginatedQuery({
    query: organizationInvitesQuery,
    name: 'organizationInvites',
    variables: ({ organization: { name: organizationName } }) => ({ organizationName })
  }),
  graphql(userInvitesDelete, { name: 'deleteInviteMutation' }),
  graphql(userInvitesResend, { name: 'resendInviteMutation' }),
  withHandlers({
    resendInvite: ({ resendInviteMutation }) => invite => resendInviteDebouncing(resendInviteMutation, ({ variables: { input: { _id: invite._id } } })),
    deleteInvite: ({ deleteInviteMutation, refreshItems }) => async invite => {
      await deleteInviteMutation(({ variables: { input: { _id: invite._id } } }))
      await refreshItems()
    }
  })
)(OrganizationInvitesPane)

const OrganizationInvitesContainer = ({ organization, refreshMembers }) => (organization ? <WithQuery organization={organization} refreshMembers={refreshMembers} /> : <OrganizationInvitesPane />)

export default OrganizationInvitesContainer
