import React, { useMemo } from 'react'
import { isNil } from 'ramda'
import classNames from 'classnames'
import { lang } from 'beanie-engine-api-js'

import BeanieLanguageEditor from 'components/Language/Rules/BeanieLanguageEditor'
import { ignoreEvent } from 'dom/events'
import useExpressionEvaluator from '../../../../hooks/beanie/language/useExpressionEvaluator'

import BulletResult from './BulletResult'
import ExpressionValidFeedback from './ExpressionValidFeedback'

import styles from './NodeRuleEditor.scss'

const { rule: { interpreter: { evalBooleanRule }, error: { isError, createErrorMessage, isStaticError }, typing: { inferAndUnifyWith, types } } } = lang

/**
 *
 */
export const NodeRuleEditor = ({ containerClassName, value: expressionString, setValue: _setExpressionString, onCancel, onApply, itValue = null }) => {
  const { parsedValue, result, shouldEval, setValue, isDirty, error, typingContext } = useExpressionEvaluator(expressionString, _setExpressionString, evalBooleanRule, itValue)

  const isValid = useMemo(() => parsedValue && !isStaticError(inferAndUnifyWith(parsedValue, types.Bool, typingContext)), [parsedValue])

  return (
    <div
      onClick={ignoreEvent}
      onDoubleClick={ignoreEvent}
      className={classNames(styles.editingContainer, containerClassName)}
    >
      <div className={classNames(styles.nodeEditor, { [styles.withError]: shouldEval && !isNil(result) && isError(result) })}>
        <div className={styles.resultAndEditor}>
          <BulletResult result={result} shouldEval={shouldEval} isDirty={isDirty} />
          <BeanieLanguageEditor

            className={styles.editor}
            expectedType={types.Bool}
            itValue={itValue}
            autoFocus

            value={expressionString}
            setValue={setValue}

            onApply={onApply}
            onBlur={onApply}
            onCancel={onCancel}
          />
        </div>
        <ExpressionValidFeedback isValid={isValid} />
      </div>
      <ErrorMessage error={error} />
    </div>
  )
}

const ErrorMessage = ({ error }) => (
  error && !isStaticError(error) ?
    <div className={styles.errorMessage} >
      <span>{createErrorMessage(error)}</span>
    </div> :
    null
)


/**
 *
 */
export default NodeRuleEditor
