import React from 'react'
import { Icon } from 'antd'
import { path, T } from 'ramda'
import { Sys, schema } from 'beanie-engine-api-js'

import AudioValue from 'components/PropertiesEditor/Value/AudioValue'
import VideoValue from 'components/PropertiesEditor/Value/VideoValue'
import ImageValue from 'components/PropertiesEditor/Value/ImageValue'
import NumberValue from 'components/PropertiesEditor/Value/NumberValue'
import VoiceValue from 'components/PropertiesEditor/Value/VoiceValue'
import NumberSliderValue from 'components/PropertiesEditor/Value/NumberSliderValue'
import LuaValue from 'components/PropertiesEditor/Value/LuaValue'
import BoolValue from '../../components/PropertiesEditor/Value/BoolValue'

const component = (comp, condition = T) => ({ component: comp, condition })

/**
 * UI metadata for the beanie object model.
 * Declares information for fields related to the UI.
 * It is like a schema for UI hints.
 * Then generic UI components use this to provide an "out of the box" UI for objects
 */
const metadataBySys = {
  // REVIEWME: object is not exported as a Sys from the engine. I think we have there
  // confused concepts into the same "thing" called Sys. Sometimes we refer to them as bne.** systems
  // but some others as just "types".
  object: {
    sys: {
      icon: <Icon type="flag" />
    },
    data: {
      disabled: {
        component: component(BoolValue)
      },
    }
  },
  [Sys.node]: {
    data: {
      parent: {
        icon: <Icon type="link" />
      },
      children: {
        icon: <Icon type="share-alt" />
      },
    }
  },
  [Sys.marker]: {
    _metadata: { label: 'Marker', icon: 'tag-o', color: 'rgba(86, 42, 153, 0.5)' }
  },
  [Sys.node_container]: {
    _metadata: { label: 'Node Container', icon: 'book', color: 'rgba(178, 156, 34, 0.5)' },
  },

  [Sys.clip]: {
    _metadata: { label: 'Clip', icon: 'video-camera', color: 'white' },
    data: {
      lines: {
        icon: <Icon type="message" />
      },
    }
  },

  [Sys.language_resource]: {
    _metadata: { label: 'Resource', icon: 'file' },
    data: {
      text: {
        icon: <Icon type="message" />
      },
      audio: {
        icon: <Icon type="sound" />,
        component: component(AudioValue),
      },
      video: {
        component: component(VideoValue),
      }
    }
  },
  [Sys.jump]: {
    _metadata: { label: 'Jump', icon: 'export', color: 'rgba(209, 161, 211, 1)' },
    data: {
      target: {
        icon: <Icon type="link" />
      },
    }
  },
  [Sys.logic]: {
    _metadata: { label: 'Logic', icon: 'bars', color: 'rgb(248, 153, 167)' },
  },
  [Sys.action]: {
    _metadata: { label: 'Action', icon: 'setting', color: 'rgb(248, 153, 167)' },
  },
  [Sys.action2]: {
    _metadata: { label: 'Action2', icon: 'setting', color: 'rgb(143, 193, 208)' },
  },
  [Sys.reference]: {
    _metadata: { label: 'Reference', icon: 'link' },
  },
  [Sys.stop]: {
    _metadata: { label: 'Stop', icon: 'close', color: 'rgb(182, 83, 83)' },
  },
  [Sys.lua]: {
    _metadata: { label: 'Lua', icon: 'code-o' },
  },

  [Sys.timer]: {
    _metadata: { label: 'Timer', icon: 'hourglass', color: 'black' },
    data: {
      time: {
        icon: <Icon type="clock-circle-o" />,
        component: component(NumberValue('seconds')),
      },
    }
  },

  [Sys.line]: {
    _metadata: { label: 'Line', icon: 'message' },
    data: {
      actor: {
        icon: <Icon type="user" />
      },
      selected_take: {
        icon: <Icon type="video-camera" />,
      },
      takes: {
        icon: <Icon type="video-camera" />
      },
    }
  },

  [Sys.actor]: {
    _metadata: { label: 'Actor', icon: 'smile-o', color: 'rgb(0, 0, 0)' },
    data: {
      actorIcon: {
        component: component(ImageValue),
      },
      editor: {
        speechVoice: {
          icon: <Icon type="notification" />,
          component: component(VoiceValue),
        },
        speechPitch: {
          component: component(NumberSliderValue(0, 2, 0.01), val => typeof val === 'number'),
          // TODO: this should be in the engine schema !
          type: {
            min: 0,
            max: 2,
            step: 0.01,
            default: 1
          }
        },
        speechRate: {
          component: component(NumberSliderValue(0.4, 1.6, 0.01), val => typeof val === 'number'),
          // TODO: this should be in the engine schema !
          type: {
            // theoretic limits are 0 .. 10 but we just use a range in between with more resolution
            min: 0.4,
            max: 1.6,
            step: 0.01,
            default: 1
          }
        },
      }
    }
  },
  [Sys.choices2]: {
    _metadata: { label: 'Choices', icon: 'fork', color: 'rgba(178, 156, 34, 0.5)' },
    data: {
      timeout: {
        icon: <Icon type="clock-circle-o" />,
        component: component(NumberValue('seconds')),
      },
    }
  },
  [Sys.choices]: {
    _metadata: { label: 'Choices (old)', icon: 'fork', color: 'rgba(178, 156, 34, 0.5)' },
  },
  [Sys.choice]: {
    _metadata: { label: 'Choice', icon: 'ellipsis', color: 'rgba(196, 199, 125, 0.7)' },
    data: {
      auto_choice: {
        component: component(BoolValue)
      },
    }
  },
  [Sys.folder]: {
    _metadata: { label: 'Folder', icon: 'folder', color: 'rgba(178, 156, 34, 0.5)' },
  },
  [Sys.folder_item]: {
    _metadata: { label: 'Folder Item', icon: 'folder-open', color: 'rgba(196, 199, 125, 0.7)' },
  },
  [Sys.conditional]: {
    _metadata: { label: 'Conditional', icon: 'question-circle-o', color: 'rgb(215, 255, 206)' },
  },
  [Sys.condition]: {
    _metadata: { label: 'Condition', icon: 'question', color: 'rgb(215, 255, 206)' },
    data: {
      lua_code: {
        component: component(LuaValue),
      },
    }
  },
  [Sys.sequencer]: {
    _metadata: { label: 'Sequencer', icon: 'bars', color: 'rgb(141, 223, 141)' }
  },
  [Sys.sequence]: {
    _metadata: { label: 'Sequence', icon: 'ellipsis', color: 'rgb(141, 223, 141)' },
  },
  [Sys.task_container]: {
    _metadata: { label: 'Task Container', icon: 'select', color: 'rgb(141, 223, 141)' },
    data: {
      async: {
        component: component(BoolValue),
      }
    }
  },
  [Sys.task_item]: {
    _metadata: { label: 'Task Item', icon: 'border', color: 'rgb(141, 223, 141)' },
  },
  [Sys.truth_table]: {
    _metadata: { label: 'Truth table', icon: 'table', color: 'rgb(213, 190, 155)' },
  },
  [Sys.truth_table_row]: {
    _metadata: { label: 'Truth table row', icon: 'ellipsis', color: 'rgb(235, 226, 211)' },
  },
  [Sys.truth_table_otherwise_row]: {
    _metadata: { label: 'Truth table otherwise row', icon: 'ellipsis', color: 'rgb(235, 226, 211)' },
  },
  [Sys.lua]: {
    _metadata: { label: 'Lua', icon: 'code-o', color: 'aliceblue' },
    data: {
      lua_code: {
        component: component(LuaValue),
      },
    }
  }
}

//
// querying methods
//

export const getMetadataForSys = sys => sys && path([sys, '_metadata'], metadataBySys)

// TODO: maybe we want a cache here to avoid recomputing every time
export const getMetadataFor = (sys, _path) => {
  const types = schema.getInheritanceChainOf(sys)
  const pathOf = path(Array.isArray(_path) ? _path : _path.split('.'))
  for (const type of types) {
    const metadata = metadataBySys[type]
    if (metadata) {
      const fieldMetadata = pathOf(metadata)
      if (fieldMetadata) {
        return fieldMetadata
      }
    }
  }
  return undefined
}

export const getComponentFor = (sys, _path) => {
  const metadata = getMetadataFor(sys, _path)
  return metadata?.component
}

export const getIconFor = (sys, _path) => {
  const metadata = getMetadataFor(sys, _path)
  return metadata?.icon
}