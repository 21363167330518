import React, { Fragment } from 'react'
import { compose } from 'recompose'
import { take } from 'ramda'
import { Icon, Tooltip } from 'antd'

import OrganizationSelectOption from 'components/Organizations/Commons/OrganizationSelectOption'
import UserSelectOption from 'components/Commons/UserSelectOption'
import withBooleanState from 'hocs/withBooleanState'
import CopyProject from 'components/Project/CopyProject'
import ProjectDropdown from './ProjectDropdown'

import styles from './CurrentProjectTitle.scss'

const ProjectTitle = props => {
  const { project } = props
  return (
    <Fragment>
      <ProjectDropdown {...props}>
        <div className={styles.currentProject} >
          <div>{project && project.owner && owner(project.owner)}</div>
          <div>{project && <ProjectName {...props} />}</div>
          <Icon type="down" />
        </div>
      </ProjectDropdown>
      {props.isCopyModalVisible && <CopyProject hide={props.stopShowingCopyProject} />}
    </Fragment>
  )
}

const ProjectName = ({ project: { name }, maxLength }) => ((name.length > maxLength) ?
  <Tooltip title={name}>{`${take(maxLength, name)}...`}</Tooltip> : <span>{name}</span>)

const owner = o => (o.__typename === 'Organization' ?
  <OrganizationSelectOption organization={o} nameEnabled={false} descriptionEnabled={false} />
  : <UserSelectOption user={o} nameEnabled={false} />
)

export default compose(
  withBooleanState('isCopyModalVisible', 'handleCopyProject', 'stopShowingCopyProject'),
)(ProjectTitle)
