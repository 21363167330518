exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".filterDropdown__FCrsz{padding:8px;border-radius:6px;background:#fff;-webkit-box-shadow:0 1px 6px rgba(0,0,0,.2);box-shadow:0 1px 6px rgba(0,0,0,.2)}.filterDropdown__FCrsz input{width:130px;margin-right:8px}.columnTitle__1ilPG>span{padding-left:.5rem;color:gray;font-style:italic}.columnTitle__1ilPG .anticon{color:#108ee9}", ""]);

// exports
exports.locals = {
	"filterDropdown": "filterDropdown__FCrsz",
	"columnTitle": "columnTitle__1ilPG"
};