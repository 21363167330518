import { path, groupBy, flatten, pathEq } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { Sys, isNode, model } from 'beanie-engine-api-js'
import { title as queryTitle } from 'model/project/searches/searches'
import { createSelector, createDeepEqualSelector } from 'selectors/reselect'
import { createObjectByIdSelector } from 'selectors/objects'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'

const { types: { choice: { getLineId }, clip: { getLinesIds } } } = model

export const currentWalkList = path(['walklist', 'list'])
export const active = createSelector([currentWalkList], isNotNil)
export const currentWalkListSearch = path(['walklist', 'list', 'search'])

export const title = createSelector(
  [currentWalkList],
  list => (list ? queryTitle(list.search) : undefined)
)

export const currentIndex = state => state.walklist?.current

//
// dispatch by type of walk-list
//

export const currentMatchesIndexed = createSelector(
  'walklists.currentMatchesIndexed',
  [currentWalkList],
  list => {
    if (!list) return undefined
    return groupBy(path(['object', 'id']), list.results)
  }
)

export const length = createSelector(
  'walklists.length',
  [currentWalkList],
  list => {
    // REVIEWME
    if (!list) return undefined
    return list.results.length
  }
)

export const currentResult = createSelector(
  'walklists.currentResult',
  [currentIndex, currentWalkList],
  (i, walklist) => {
    if (!walklist) return undefined
    return walklist.results[i]
  }
)

export const currentNode = createSelector(
  'walklists.currentNode',
  [currentResult],
  result => {
    if (!result) return undefined
    // the whole "node" in results seems like a hack / ad-hoc
    return isNode(result.object.sys) ? result.object : result.node
  }
)

//
// selected objects selectors
//

const makeIsCurrent = idFromProp => createSelector(
  'walklists.makeIsCurrent',
  [idFromProp, currentResult],
  (id, result) => (result ? result.object.id === id : false),
)

export const makeElementMatchData = objectSelector => createSelector(
  'walklists.makeElementMatchData',
  [objectSelector, currentResult, currentMatchesIndexed],
  (obj, result, matchesByObjId) => {
    if (!matchesByObjId) return undefined
    const results = getAllResultsForNode(obj, matchesByObjId)
    return {
      results,
      currentIndex: result ? results.indexOf(result) : -1,
    }
  }
)

// returns all results for this node and any "inner" object in the three
// currently and ad-hoc impl for clips
const getAllResultsForNode = (obj, matchesByObjId) => [
  ...flatten(matchesByObjId[obj.id] || EMPTY_ARRAY),

  ...(obj.sys === Sys.clip ?
    getLinesIds(obj).reduce((acc, id) => {
      const lineResults = matchesByObjId[id]
      return lineResults ? acc.concat(lineResults) : acc
    }, EMPTY_ARRAY)
    : EMPTY_ARRAY
  )
]

//
// selectors for nodes / lines
// kind of pretty tightly fit what the view needs
//

/**
 *
 */
export const makeGetNodeMatches = idFromProp => {
  const objectSelector = createObjectByIdSelector(idFromProp)()
  return createDeepEqualSelector(
    'walklists.makeGetNodeMatches',
    [objectSelector, currentMatchesIndexed, makeIsCurrent(idFromProp), makeElementMatchData(objectSelector)],
    (obj, matchesIndex, isCurrent, elementData) => {
      if (!matchesIndex) return undefined
      const matches = computeMatches(obj, matchesIndex, elementData)

      // TODO: test
      if (!matches) return undefined

      return {
        current: isCurrent,
        ...elementData || EMPTY_OBJECT
      }
    }
  )
}

// TODO: rethink
const computeMatches = (obj, matches, elementData) =>
  !!matches[obj.id]
  || elementData.results.length > 0
  || (obj.sys === Sys.choice && !!matches[getLineId(obj)])


const indexOfFirstLineResult = (clipState, lineId) => clipState.results.findIndex(pathEq(['object', 'id'], lineId))
/**
 * "clipId + lineId + matchIndex" is current ? true/false
 */
export const makeLineResults = (clipIdSelector, lineIdSelector, matchLineRelativeIndexSelector) => createSelector(
  [lineIdSelector, matchLineRelativeIndexSelector, makeGetNodeMatches(clipIdSelector)],
  (lineId, lineRelativeIndex, clipState) => (
    clipState &&
    clipState.currentIndex === indexOfFirstLineResult(clipState, lineId) + lineRelativeIndex
  )
)