import { objects, objectsIndex } from './apollo'
import { createSelector } from 'selectors/reselect'
import { scaleOrdinal } from 'd3-scale'
import { schemeSet3 } from 'd3-scale-chromatic'
import { reduce, pathOr, path, pipe, uniq, map, flatten, when, flip, propOr, concat } from 'ramda'
import { Sys, schema, parseRef } from 'beanie-engine-api-js'

import { selectedObject } from 'selectors/objects'
import { propFrom, isEmpty } from 'utils/object'

export const colorScale = scaleOrdinal(schemeSet3)

export const emptyData = () => ({ nodes: [], links: [], indexes: { nodes: {}, links: { source: {}, target: {} } } })

export const graph = () => createSelector(
  [objects, objectsIndex],
  (all, index) => reduce((data, o) => {
    gatherNodeAndLinks(o, index, data)
    return data
  }, emptyData())(all)
)

const createNode = o => ({
  id: o.id,
  label: pathOr(o.id, ['data', 'name'])(o),
  fill: colorScale(o.sys),
  sys: o.sys
})

// exported just to test
export const gatherNodeAndLinks = (o, index, data) => {
  // node
  const node = createNode(o)
  data.nodes.push(node)
  data.indexes.nodes[o.id] = node
  // links
  const newLinks = schema.propertyNamesOf(o.sys).reduce(gatherLinks(o, index), [])
  data.links = data.links.concat(newLinks)

  newLinks.forEach(saveLinkOnIndex(data))
}

const saveLinkOnIndex = data => l => {
  saveLinkOnIndexFor('source', data, l)
  saveLinkOnIndexFor('target', data, l)
}
const saveLinkOnIndexFor = (prop, data, l) => {
  if (data.indexes.links[prop][l[prop]]) {
    data.indexes.links[prop][l[prop]].push(l)
  } else {
    data.indexes.links[prop][l[prop]] = [l]
  }
}

const gatherLinks = (o, index) => (links, propPath) => {
  const value = path(propPath.split('.'), o)
  if (!value) return links

  const appendLink = ref => {
    const target = parseRef(ref)
    if (index[target]) {
      links.push({
        source: o.id,
        path: propPath,
        target
      })
    }
    // TODO: else draw a link to a new node like an error
  }
  if (schema.isRef(o.sys, propPath)) {
    appendLink(value)
  }
  if (schema.isArrayOfRef(o.sys, propPath) && value.forEach) {
    value.forEach(ref => {
      // TODO: include the index
      appendLink(ref)
    })
  }
  // TODO: isMapOfRef
  return links
}

const nLevelLinks = (id, data, processedIds = [], level = 3) => {
  if (processedIds.includes(id) || level === 0) {
    return []
  }
  processedIds.push(id)
  const links = [
    ...pathOr([], ['indexes', 'links', 'source', id], data),
    ...pathOr([], ['indexes', 'links', 'target', id], data),
  ]
  return concat(links, links.reduce((acc, l) => acc.concat([
    ...nLevelLinks(l.source, data, processedIds, level - 1),
    ...nLevelLinks(l.target, data, processedIds, level - 1),
  ]), []))
}

export const localGraphForSelected = (levels = 3) => createSelector(
  [graph(), selectedObject],
  (data, selected) => {
    const links = selected ? nLevelLinks(selected.id, data, [], levels) : []
    return {
      nodes: pipe(
        map(l => [l.source, l.target]),
        flatten(),
        uniq,
        map(propFrom(data.indexes.nodes)),
        when(l => isEmpty(l) && !!selected, () => [createNode(selected)])
      )(links),
      links
    }
  }
)

const radiusBySys = {
  [Sys.clip]: 4,
  [Sys.choices]: 4,
  [Sys.marker]: 3,
  [Sys.language_resource]: 1,
  [Sys.take]: 2,
  [Sys.line]: 2,
  [Sys.actor]: 4,
}
export const DEFAULT_RADIUS = 2

export const getRadiusBySys = flip(propOr(DEFAULT_RADIUS))(radiusBySys)

