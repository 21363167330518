import { ContentBlock, genKey } from 'draft-js'
import { Map, List } from 'immutable'
import BlockType from './BlockType'

export const IntermediateModelTypes = {
  title: 'title',
  choice: 'choice'
}

export const createChoiceBlock = () => new ContentBlock({
  key: genKey(),
  type: BlockType.choice,
  text: '',
  data: new Map({}),
  characterList: List([]),
})