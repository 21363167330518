
// type WalkList = {
//   search: Search,
//   results?
// }
// were
//  type Search = {
//    type: match|find,  // (Find | Match)
//    [title]: String
//  }
//  type Find = { query }
//  type Match = { text, query }

export const createWalkList = (search, results) => ({
  search,
  results
})