import ReactDOM from 'react-dom'
import { batch, connect } from 'react-redux'
import { withHandlers, compose, lifecycle } from 'recompose'

import { always } from 'ramda'
import { focusForNode } from 'dom/dom'

import { createIsNodeUnderSyncOutboundOperation } from 'selectors/sync'
import { makeIsEditingSelector } from 'selectors/ui'
import { nodeId } from 'selectors/props'

import { Creators } from 'actions/ui'
import { model } from 'beanie-engine-api-js'
import editText from '../../engine/actions/node/editText'

const { types: { object: { Paths } } } = model

const { editEnd } = Creators

const withNodeEditing = (nodePropPath = Paths.node.name) => compose(
  connect(
    () => {
      const isNodeUnderSyncOperation = createIsNodeUnderSyncOutboundOperation()
      const isEditing = makeIsEditingSelector(nodeId, always(nodePropPath))
      return (state, props) => ({
        isEditing: isEditing(state, props),
        isUnderSyncOperation: isNodeUnderSyncOperation(state, props),
      })
    },
    {
      // actions
      editTextAction: editText,
      editEndAction: editEnd,
    }
  ),
  withHandlers({
    onEditCompleted: ({ node, editTextAction, editEndAction }) => async value => {
      await batch(async () => {
        await editTextAction(node, value)
        editEndAction()
      })
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { node, isEditing } = this.props
      if (isEditing && !prevProps.isEditing) {
        /* eslint react/no-find-dom-node: 0 */
        const control = ReactDOM.findDOMNode(this).querySelector('input, textarea')
        if (control) {
          control.focus()
          control.select()
        }
      }
      const mustFocus = prevProps.isEditing && !isEditing
      if (mustFocus) {
        focusForNode(node.id)
      }
    }
  }),
)

export default withNodeEditing