exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editor__3THEN{display:-ms-flexbox;display:flex;width:100%;height:100%;padding-top:0 !important;padding-bottom:0 !important}.spinner__2Z-hb{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1}.spinner__2Z-hb>.ant-spin-container{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1}.spinner__2Z-hb .LuaEditor__2XiD7{border:0;border-radius:unset}", ""]);

// exports
exports.locals = {
	"editor": "editor__3THEN",
	"spinner": "spinner__2Z-hb",
	"LuaEditor": "LuaEditor__2XiD7"
};