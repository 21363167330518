import React, { Fragment } from 'react'

import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import TtsJobDetail from './TtsJobsMonitor/TtsJobDetail'
import TtsJobs from './TtsJobsMonitor/TtsJobs'
import Secure from 'components/Commons/Secure'
import { isSuperAdminCheck } from 'security/platform'
import routes from 'components/Organizations/routes'
import { dropTrailingSlash as dts } from 'utils/string'

const TtsSectionRouter = ({ match, ...other }) => (
  <Switch>
    <Route path={routes.ttsJob} render={props => <TtsJobDetail {...props} {...other} />} />
    <Route path={`${routes.projectSettings}/tts`} render={props => <TtsSection {...props} {...other} />} />
    <Redirect to={`${dts(match.url)}/tts`} />
  </Switch>
)

const TtsSection = () => (
  <Fragment>
    <h2>Tts</h2>
    <Secure check={isSuperAdminCheck}>
      <TtsJobs />
    </Secure>
  </Fragment>
)

export default withRouter(TtsSectionRouter)