import { graphql } from 'react-apollo'
import { compose, withHandlers, withPropsOnChange } from 'recompose'
import { pipe, assocPath, over, lensPath, concat, flip, path } from 'ramda'

/**
 * query: graphql query
 * name: gives a prop name to the graphql query
 * pageSize,
 * dataPath: the field from graphql data where the results will come in.
 */
const withFetchMoreForPaging = ({ query, name, pageSize, dataPath = [name], variables, queryOptions = {}, graphqlProps = {} }) => compose(
  graphql(query, {
    name,
    options: props => ({
      variables: {
        size: pageSize,
        ...variables && variables(props)
      },
      fetchPolicy: 'network-only',
      ...queryOptions
    }),
    ...graphqlProps,
  }),
  withHandlers({
    fetchMore: props => async () => {
      const { [name]: data } = props
      await data.fetchMore({
        variables: {
          query: data.variables.query,
          page: path(dataPath, data).paging.page + 1,
          size: pageSize,
          ...variables && variables(props)
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          const field = path(dataPath, fetchMoreResult)
          return pipe(
            assocPath([...dataPath, 'paging'], field.paging),
            over(lensPath([...dataPath, 'list']), flip(concat)(field.list))
          )(prev)
        }
      })
    },
  }),
  withPropsOnChange(
    [name],
    ({ [name]: data }) => {
      if (!data) { return { hasMore: false } }
      const field = path(dataPath, data)
      return ({
        hasMore: field ? field.paging.page < field.paging.nrOfPages : true
      })
    }
  )
)

export default withFetchMoreForPaging