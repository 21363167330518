import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, pure } from 'recompose'
import { heartbeatSucceeded as heartbeatSucceededAction, heartbeatFailed as heartbeatFailedAction } from '../../actions/network/heartbeat'

import VersionChecker from '../VersionChecker/VersionChecker'
import { user as userSelector } from 'selectors/auth'

import heartbeatMutation from 'api/mutations/heartbeat.graphql'

const POLL_INTERVAL_IN_SECONDS = 60

const isUnauthorizedError = e => e && e.message && /Received status code 401/.test(e.message)

class Heartbeat extends React.Component {
  shouldComponentUpdate = () => false

  componentDidMount = () => { this.start() }
  componentDidUpdate = () => { this.start() }

  ping = () => {
    const { mutate, heartbeatSucceeded, heartbeatFailed } = this.props
    /* eslint no-undef: 0 */
    return mutate({ variables: { clientVersion: VERSION } })
      .then(() => heartbeatSucceeded())
      .catch(e => {
        if (!isUnauthorizedError(e)) {
          heartbeatFailed(`heartbeat error: ${e.message}`)
        }
      })
  }

  componentWillUnmount = () => {
    this.stop()
  }

  start = () => {
    if (this.props.user && !this.timer) {
      this.ping()
      this.timer = setInterval(this.ping, POLL_INTERVAL_IN_SECONDS * 1000)
    }
  }
  stop = () => {
    clearInterval(this.timer)
    this.timer = undefined
  }

  render = () => (
    <VersionChecker />
  )

}

export default compose(
  pure,
  graphql(heartbeatMutation),
  connect(state => ({
    user: userSelector(state)
  }),
  {
    heartbeatSucceeded: heartbeatSucceededAction,
    heartbeatFailed: heartbeatFailedAction
  }),
)(Heartbeat)
