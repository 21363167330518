import { times } from 'ramda'
import { Sys } from 'beanie-engine-api-js'
import { addElementWithClip } from '../../templates'

/**
 * Creates a conditional with 2 conditions
 */
const createConditionalTemplate = api => {
  const conditional = api.createObject({ sys: Sys.conditional })
  const addCondition = addElementWithClip(api, () => conditional.add_condition())
  times(addCondition, 2)

  return conditional
}

export default createConditionalTemplate