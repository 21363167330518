import { buildConfigRenameExt } from 'actions/project'
import { install, isBeingUsed } from './onEngineExtensionAdded'

const onEngineExtensionRenamed = ({ oldName, newName, version, vm }) => async (dispatch, getState, { synchronizer }) => {
  if (!isBeingUsed(getState, oldName, version, vm)) return

  const api = synchronizer.getEngineStore().getEngine().getAPI()
  api.extension.uninstall(oldName)

  await install(dispatch)(api, newName)
  dispatch(buildConfigRenameExt(oldName, newName))
}

export default onEngineExtensionRenamed
