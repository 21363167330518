import React from 'react'
import { Tooltip } from 'antd'
import TeamAvatar from 'components/Organizations/Teams/Commons/TeamAvatar'
import UserAvatar from 'components/RevisionSessions/UserAvatar'

const GrantAvatar = ({ grant: { role, to } }) => (
  <Tooltip title={`Has ${role} access`}><UserOrTeamAvatar entity={to} /></Tooltip>
)

const UserOrTeamAvatar = ({ entity }) => (
  entity.__typename === 'User' ?
    <UserAvatar user={entity} contextMenuEnabled={false} showPopOver />
    : <TeamAvatar size={32} team={entity} disabled />
)

export default GrantAvatar