import { applySpec } from 'ramda'

// general

const relative = path => `${window.location.origin}/${path}`

// 
// project element images
//

export const ResourceType = {
  image: 'image',
  audio: 'audio',
  video: 'video',
  storyboard: 'storyboard',
  pdf: 'pdf'
}

export const urlForResource = type => (token, revisionId, file = '') =>
  relative(`api/resources/${revisionId}/${type}/${file}${token ? `?t=${token}` : ''}`)

export const urlForEntity = entity => (token, entityId, file = '') =>
  relative(`api/resources/${entity}/${entityId}/${file}${token ? `?t=${token}` : ''}`)

export const urlForVideo = urlForResource(ResourceType.video)
export const urlForAudio = urlForResource(ResourceType.audio)
export const urlForImage = urlForResource(ResourceType.image)
export const urlForStoryboard = urlForResource(ResourceType.storyboard)
export const urlForPdf = urlForResource(ResourceType.pdf)

// this one is used with asyncFetch which already appends the server, port, token (header), etc
export const resourceUrl = (type, file = '') => (token, revisionId) => `api/resources/${revisionId}/${type}/${file}`

//
// global resources
//
const imagePostURLForEntity = type => (id, token) => urlForEntity(type)(token, id)
const imageGetURLForEntity = type => (id, imageFile, token) => urlForEntity(type)(token, id, imageFile)

export const userImagePostURL = imagePostURLForEntity('users')
export const userImageGetURL = imageGetURLForEntity('users')

const getAndPostFor = applySpec({
  get: imageGetURLForEntity,
  post: imagePostURLForEntity
})

export const teams = getAndPostFor('teams')

export const organizations = getAndPostFor('orgs')
