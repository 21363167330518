
const mapping = {
  //
  // actions/commands
  //

  'anticon-eye': 'disable',
  // copy
  'anticon-copy': 'circle-arrow-up',
  // paste / jump
  'anticon-export': 'circle-arrow-down',
  // edit
  'anticon-edit': 'edit',
  // delete
  'anticon-delete': 'trash',

  // toggle pause-point
  'anticon-pause-circle': 'pause',

  //
  // nodes
  //

  // action
  'anticon-setting': 'annotation',
  // clip
  'anticon-video-camera': 'mobile-video',
  // choices
  'anticon-fork': 'fork',
  // conditional
  'anticon-question-circle-o': 'help',
  // folder
  'anticon-folder': 'folder-open',
  // lua
  'anticon-code-o': 'code',
  // marker
  'anticon-tag-o': 'tag',
  // sequencer
  'anticon-bars': 'properties',
  // stop
  'anticon-close': 'cross',
  // task container
  'anticon-select': 'comparison',
  // timer
  'anticon-hourglass': 'time',
  // truth table
  'anticon-TableOutlined': 'th',
}

export default iconName => (!iconName || iconName.startsWith('anticon') ?
  (mapping[iconName] || 'blank')
  : iconName
)
