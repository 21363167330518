exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".loadingCheck__3pcr6{margin-top:1em}.loadingCheck__3pcr6 .loadCheck__1dCW0{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;font-size:2em}.loadingCheck__3pcr6 .loadCheck__1dCW0 .anticon{-webkit-transition:all .5s;-o-transition:all .5s;transition:all .5s}.loadingCheck__3pcr6 .loadCheck__1dCW0:not(.completed__2UTle) .anticon{color:gray;opacity:.2}.loadingCheck__3pcr6 .loadCheck__1dCW0.completed__2UTle .anticon{color:#503898}.taskList__1lgdC li{list-style-type:none}.taskList__1lgdC li .ant-progress-bg{background-color:#7b61ca;-webkit-transition:width .1s;-webkit-transition:width .1s linear;-o-transition:width .1s linear;transition:width .1s linear}.taskList__1lgdC li .ant-progress-text{color:#7b61ca}", ""]);

// exports
exports.locals = {
	"loadingCheck": "loadingCheck__3pcr6",
	"loadCheck": "loadCheck__1dCW0",
	"completed": "completed__2UTle",
	"taskList": "taskList__1lgdC"
};