import { Col, Row } from 'antd'
import React, { useMemo } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { makeLanguageResourceForLineSelector } from '../../../../../selectors/lines'
import { propFromProps } from '../../../../../selectors/props'

import FileUploaderModal from '../../../../Commons/FileUploaderModal'
import { SoundPlayer } from '../../../../SoundPlayer/SoundPlayer'
import { getActorPart } from '../../../model/DialogueLine'
import { getLineText, isDialogueLine } from '../../../model/Line'

import styles from './UploadLineAudioModal.scss'

const UploadLineAudioModal = props => (
  <FileUploaderModal
    title="Upload Line Audio"
    acceptedTypes="audio/*"
    urlSource={false}
    icon="sound"
    header={(
      <LineText lineId={props.lineId} node={props.node} />
    )}
    preview={file => (
      file ? (
        <div className={styles.uploadAudioPreview}>
          <div className={styles.title}>Preview</div>
          <SoundPlayer
            className={styles.player}
            audioURL={file.preview}
            options={{
            }}
          />
          <div className={styles.fileName}>{file.name}</div>
        </div>
      ) : null
    )}
    {...props}
  />
)

const LineText = ({ lineId, node }) => {
  const lineText = useMemo(() => getLineText(node), [node])
  const isDialogue = useMemo(() => isDialogueLine(node), [node])
  return (
    <div className={styles.header}>
      <IdInfo lineId={lineId} />
      <div className={classNames(styles.line, { [styles.dialogue]: isDialogue })}>
        {isDialogue && (
          <div className={styles.actorPart}>
            {getActorPart(node).getText()}
          </div>
        )}
        <div className={styles.lineText}>{lineText}</div>
      </div>
    </div>
  )
}

const _IdInfo = ({ lineId, langRes }) => {
  return (
    <div className={styles.idInfo}>
      <Row>
        <Col span={6}>Line</Col>
        <Col span={28}>{lineId}</Col>
      </Row>
      <Row>
        <Col span={6}>Resource</Col>
        <Col span={18}>{langRes.id}</Col>
      </Row>
    </div>
  )
}
const IdInfo = connect(
  () => {
    const langResSelector = makeLanguageResourceForLineSelector(propFromProps('lineId'))
    return (state, props) => ({
      langRes: langResSelector(state, props)
    })
  }
)(_IdInfo)

export default UploadLineAudioModal
