import React, { useState } from 'react'
import { Input, Spin } from 'antd'
import { withTargetValue } from 'utils/antd'
import IconButton from 'components/Commons/IconButton'

import styles from './ManageEngineExtensions.scss'

const extensionTemplate = fileName => `return {
  name = "${fileName}",  -- optional
  description = "This is my Extension",   -- optional

  declaration = "${fileName}",

  -- ON_LOAD: Uncomment next line if you want op() to run on project loaded.
  -- autorun_on_project_loaded = true,

  on_installed = function() -- optional
    -- do something when installed, just runs once
  end,

  on_uninstalled = function() -- optional
    -- do something when the systems uninstalls this extension
    -- useful for cleaning up any side-effect done during installation
  end,

  op = function()
    -- your custom code here
  end
}`

const CreateExtension = ({ readOnly, createCustomExtension, loading }) => {
  const [name, setName] = useState('')
  const submit = () => {
    createCustomExtension(name, extensionTemplate(name))
    setName('')
  }

  return (
    <Spin tip="Loading" spinning={loading}>
      <Input.Group className={styles.CreateExtensionGroup}>
        <Input
          addonAfter={<div>
            <IconButton
              className={styles.AddExtensionIcon}
              type="plus"
              disabled={readOnly}
              onClick={submit}
              tooltip="Create extension" />
          </div>}
          value={name}
          onPressEnter={submit}
          onChange={withTargetValue(setName)}
        />
      </Input.Group>
    </Spin>
  )
}

export default CreateExtension
