import { Icon } from 'antd'
import React from 'react'
import classNames from 'classnames'
import { isSuperAdminCheck } from 'security/platform'
import Secure from './Secure'

import styles from './QueryError.scss'

const QueryError = ({ error, className }) => (
  <div className={classNames(styles.QueryError, className)}>
    <h2><Icon type="exclamation-circle" />There was a problem fetching the history</h2>
    <Secure check={isSuperAdminCheck}>
      <pre>
        {JSON.stringify(error, null, 2)}
      </pre>
    </Secure>
  </div>
)

export default QueryError