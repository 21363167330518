import React from 'react'
import { List, Icon } from 'antd'
import { model } from 'beanie-engine-api-js'

import { ERROR_ICON, WARNING_ICON } from './IssueIcon'

import styles from './ErrorsPopover.scss'

const { types: { markup: { MarkupErrorSeverity } } } = model

const ErrorList = ({ errors }) => (
  <List
    size="small"
    dataSource={errors}
    renderItem={item => (
      <div className={styles.error}>
        {iconFor(item)}
        {item.message}
        {item.offset !== undefined && ` at position ${item.offset}`}
        {item.arg !== undefined && ` at argument "${item.arg}"`}</div>
    )}
  />
)

const iconFor = ({ severity = MarkupErrorSeverity.error }) => {
  switch (severity) {
    case MarkupErrorSeverity.error: return ERROR_ICON()
    case MarkupErrorSeverity.warning: return WARNING_ICON()
    default: return <Icon type="question-circle" theme="filled" style={{ color: 'blue' }} />
  }
}

export default ErrorList
