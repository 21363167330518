import React, { useMemo } from 'react'
import { compose, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { forceNotDirty as forceNotDirtyAction } from 'engine/actions/objectsMeta'
import { Tooltip, Spin } from 'antd'
import { truncate } from 'utils/string'

import { createIsObjectDirtySelector } from 'selectors/objectsMeta'
import { takes, lines } from 'selectors/objects'

import IconButton from 'components/Commons/IconButton'
import { getIconFor } from '../../model/metadata/UIMetadata'
import { pathFromProps } from '../../selectors/props'

import styles from './PropertiesEditor.scss'

const _PropertyLabel = ({ object, text, record, isEditable, isDirty, onRemove, doForceNotDirty, isSpinning }) => {
  const icon = useMemo(() => getIconFor(object?.sys, record.path), [record.path])
  return (
    <Spin spinning={isSpinning}>
      <span className={styles.name}>
        {icon || <div className={styles.noIcon} />}
        <Tooltip title={text}>
          {truncate(text, 20)}
          {isDirty && <span><b> * </b></span>}
        </Tooltip>
        <span>
          {isDirty &&
            <span className={styles.removeField}>
              <IconButton type="issues-close" tooltip="Mark as not dirty" onClick={doForceNotDirty} />
            </span>
          }
          {isEditable &&
            <span className={styles.removeField}>
              <IconButton type="close" tooltip="Remove field" onClick={onRemove} />
            </span>
          }
        </span>
      </span>
    </Spin>
  )
}

export default compose(
  connect(
    () => {
      const isObjectDirtySelector = createIsObjectDirtySelector(pathFromProps(['object', 'id']), pathFromProps(['record', 'path']), [takes, lines])
      return (state, props) => ({
        isDirty: isObjectDirtySelector(state, props)
      })
    },
    { forceNotDirty: forceNotDirtyAction }
  ),
  withState('isSpinning', 'setSpinning', false),
  withHandlers({
    doForceNotDirty: ({ forceNotDirty, object, record, setSpinning }) => async () => {
      setSpinning(true)
      await forceNotDirty(object, record.path)
      setSpinning(false)
    }
  })
)(_PropertyLabel)