import React from 'react'
import { Form, Input, Button, Spin } from 'antd'

import { Errors } from 'components/Commons/Error'

import styles from './CreateLocalUserForm.scss'

const { Item: FormItem } = Form

// antd form utils
const required = { required: true, message: 'required.' }
const hasErrors = fieldsError => Object.keys(fieldsError).some(field => fieldsError[field])

class CreateLocalUserForm extends React.Component {
  state = {
    confirmDirty: false,
  }

  handleConfirmBlur = ({ target: { value } }) => {
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  checkPassword = (rule, value, callback) => {
    const { form } = this.props
    callback(value && value !== form.getFieldValue('password') ? 'Passwords are inconsistent!' : undefined)
  }

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true })
    }
    callback()
  }

  render() {
    const { onCreate, form, loading, errors } = this.props
    const { getFieldDecorator, getFieldsError } = form

    return (
      <div>
        <Spin spinning={loading}>
          <Form layout="vertical">
            <div className={styles.nameSection}>
              <FormItem label="First Name">
                { getFieldDecorator('firstName', { rules: [required] })(
                  <Input />
                )}
              </FormItem>
              <FormItem label="Last Name">
                { getFieldDecorator('lastName', { rules: [required] })(
                  <Input />
                )}
              </FormItem>
            </div>
            <FormItem label="Password">
              {getFieldDecorator('password', { rules: [required, { validator: this.checkConfirm }] })(
                <Input type="password" />
              )}
            </FormItem>
            <FormItem label="Confirm Password">
              {getFieldDecorator('confirmPassword', { rules: [required, { validator: this.checkPassword }] })(
                <Input type="password" onBlur={this.handleConfirmBlur} />
              )}
            </FormItem>
            {errors && errors.length > 0 &&
              <Errors items={errors} />
            }
          </Form>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
              onClick={onCreate}
            >Signup</Button>
          </div>
        </Spin>
      </div>
    )
  }
}

export default Form.create()(CreateLocalUserForm)