exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2v7E7>span:first-child{padding-right:.5rem;color:#ffb6c1;font-weight:bold}.container__2v7E7>span:nth-child(2){color:#cac3c3}", ""]);

// exports
exports.locals = {
	"container": "container__2v7E7"
};