import React from 'react'
import { Table, Icon } from 'antd'
import { remove, update } from 'ramda'
import { compose, withHandlers, withProps } from 'recompose'

import { model } from 'beanie-engine-api-js'

import { EMPTY_ARRAY } from 'utils/object'

import IconButton from 'components/Commons/IconButton'
import LinkButton from 'components/Commons/LinkButton'
import { TableEditableCell, EditableFormRow } from 'components/EditableTable/TableEditableCell'
import CheckboxEditComponent from 'components/EditableTable/CheckboxEditComponent'

import MarkupTypeAutocomplete from './MarkupTypeAutocomplete'

import styles from './MarkupParametersTable.scss'

const { types: { markup: { MarkupBasicTypesIndex } } } = model

const { Column } = Table

// Must be a class to be used in Antd Forms
class MarkupParametersTable extends React.Component {

  render() {
    const { parameters, addParameter, removeParameter, editParameter } = this.props

    return (
      <div>
        <Table
          rowKey={record => record.name}
          dataSource={parameters}
          pagination={false}
          components={{
            body: {
              row: EditableFormRow,
              cell: TableEditableCell,
            }
          }}
        >
          <Column
            title="#"
            key="order"
            className={styles.orderColumn}
            width="1.5rem"
            render={(_, __, i) => i + 1}
          />
          <Column
            title="Name"
            key="name"
            className={styles.nameColumn}
            dataIndex="name"
            width="20%"
            onCell={record => ({
              record,
              editable: true,
              dataIndex: 'name',
              title: 'Name',
              handleSave: editParameter,
            })}
          />
          <Column
            title="Required"
            dataIndex="required"
            key="required"
            width="10%"
            className={styles.isRequiredColumn}
            onCell={record => ({
              record,
              editable: true,
              alwaysEditing: true,
              dataIndex: 'required',
              title: 'Required',
              editComponent: CheckboxEditComponent,
              handleSave: editParameter,
            })}
          />
          <Column
            title="List"
            dataIndex="isList"
            key="isList"
            width="10%"
            className={styles.isListColumn}
            onCell={record => ({
              record,
              editable: true,
              alwaysEditing: true,
              dataIndex: 'isList',
              title: 'List',
              editComponent: CheckboxEditComponent,
              handleSave: editParameter,
            })}
          />
          <Column
            title="Type"
            dataIndex="type"
            key="type"
            width="40%"
            className={styles.typeColumn}
            onCell={record => ({
              record,
              editable: true,
              dataIndex: 'type',
              title: 'Type',
              editComponent: MarkupTypeAutocomplete,
              handleSave: editParameter,
            })}
          />
          <Column
            title="Description"
            dataIndex="description"
            key="description"
            className={styles.descriptionColumn}
            width="30%"
            onCell={record => ({
              record,
              editable: true,
              dataIndex: 'description',
              title: 'Description',
              handleSave: editParameter,
            })}
          />
          <Column
            title=""
            key="actions"
            className={styles.actionsColumn}
            render={(param, _, i) => (
              <div>
                <IconButton tooltip="Remove" type="delete" onClick={() => removeParameter(i)} />
              </div>
            )}
          />
        </Table>
        <LinkButton onClick={addParameter}><Icon type="plus" />Add</LinkButton>
      </div>
    )
  }
}

export default compose(
  withHandlers({
    addParameter: ({ value = EMPTY_ARRAY, onChange }) => () => {
      onChange(value.concat({
        name: `param${value.length + 1}`,
        type: MarkupBasicTypesIndex.Text.name,
        isList: false,
      }))
    },
    removeParameter: ({ value, onChange }) => i => {
      onChange(remove(i, 1, value))
    },
    editParameter: ({ value, onChange }) => ({ index, ...param }) => {
      onChange(update(index, param, value))
    }
  }),
  withProps(({ value = EMPTY_ARRAY }) => ({
    parameters: value.map((param, index) => ({ ...param, index }))
  })),
)(MarkupParametersTable)