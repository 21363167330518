import { NODE_TYPES } from '../../Constants'
import { SPACE } from './../schema-utils'
import { markUpParametersNormalize } from '../normalizers/markUp'

export default {
  nodes: [
    {
      match: [SPACE, { type: NODE_TYPES.MARK_UP_PARAMETER }],
      min: 1,
    },
  ],
  normalize: markUpParametersNormalize
}