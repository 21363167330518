import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { current } from 'selectors/state'
import { currentProject, revisionVersion } from 'selectors/project'
import { isPlayEnabled } from 'selectors/playback'

import { clearState as clearStateAction } from 'engine/actions/state'

import Button from 'components/Commons/Button'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'
import StateFileChooserButton from 'components/State/StateFileChooserButton'
import NewSaveGameModal from 'components/StateToolBar/NewSaveGameModal'

import { exportState } from 'utils/state/importExport'

const StateControls = ({ project, version, state, clearState, clearEnabled }) => [
  <Button key="clear" type="close" description="Clear" onClick={clearState} disabled={clearEnabled} />,

  // import & export
  <Button key="download" type="download" description="Export" onClick={() => exportState(project, version, state)} />,
  <StateFileChooserButton modal={NewSaveGameModal} description="Import Savegame" />,

  <ExtensionPoint
    key="extensions"
    point={EXTENSION_POINTS.STATE_PANEL_CONTROL}
    params={[state]}
  />
]

export default compose(
  connect(state => ({
    project: currentProject(state),
    version: revisionVersion(state),
    state: current(state),
    clearEnabled: !isPlayEnabled(state)
  }), {
    clearState: clearStateAction
  })
)(StateControls)
