import React from 'react'
import IconButton from 'components/Commons/IconButton'

import styles from './../../SlateTextEditor.scss'

const ENABLE_DISABLE_ICON_PROPS = {
  disable: (editor, lineId) => ({
    className: styles.EnableDisableIcon,
    type: 'eye',
    theme: '',
    tooltip: 'Enable line',
    onClick: () => editor.props.enableLines([lineId])
  }),
  enable: (editor, lineId) => ({
    className: styles.EnableDisableIcon,
    type: 'eye-invisible',
    theme: '',
    tooltip: 'Disable line',
    onClick: () => editor.props.disableLines([lineId])
  })
}

const EnableDisableLineIcon = ({ isDisabled, mouseOver, editor, lineId }) => {
  const iconProps = ENABLE_DISABLE_ICON_PROPS[isDisabled ? 'disable' : 'enable']
  return (<div contentEditable={false} className={styles.EnableDisableIconContainer}>
    {mouseOver ? <IconButton {...iconProps(editor, lineId)} /> : null}
  </div>)
}

export default EnableDisableLineIcon

