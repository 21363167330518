import { OrganizationMembershipRole } from 'utils/authentication'

import { isSuperAdmin } from './platform'
import { isMemberWithRole } from './commons'

// scope => Boolean
export const isOrgOwner = isMemberWithRole('organizationsMemberships', 'organization', 'organization', OrganizationMembershipRole.OWNER)

export const orgWriteAccess = scope => scope.organization && (
  isSuperAdmin(scope.currentUser)
  || isOrgOwner(scope)
)
