exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".increments__2NOXC .anticon,.decrements__3k_iF .anticon{font-size:.5rem}.increments__2NOXC .anticon{color:green}.decrements__3k_iF .anticon{color:red}.coinFlip__23SLE .anticon{color:#d1d144}.NoValue__2AcNU{color:#d3d3d3;font-style:italic}", ""]);

// exports
exports.locals = {
	"increments": "increments__2NOXC",
	"decrements": "decrements__3k_iF",
	"coinFlip": "coinFlip__23SLE",
	"NoValue": "NoValue__2AcNU"
};