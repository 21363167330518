import React from 'react'
import { Icon } from 'antd'
import GrantAvatar from './GrantAvatar'
import AvatarList from 'components/AvatarList/AvatarList'
import UserName from 'components/Commons/UserName'
import { keyForGrant } from './GrantAvatarListPopoverContent'
import ProjectGrantRole from './ProjectGrantRole'

import styles from './GrantAvatarList.scss'

const renderGrantTitle = grant => (grant.to ?
  <div className={styles.title}>
    <div>{grant.to.__typename === 'User' ? <UserName user={grant.to} /> : grant.to.name}</div>
    <div><ProjectGrantRole role={grant.role} /></div>
  </div>
  : <div className={styles.title}>Unknown</div>
)

const renderGrant = grant => (grant.to ?
  <GrantAvatar key={keyForGrant(grant)} grant={grant} />
  : <Icon type="warning" />
)

const GrantAvatarList = ({ grants }) => (
  <AvatarList
    renderAvatar={renderGrant}
    renderTitle={renderGrantTitle}
    list={grants}
    rowKey={keyForGrant}
    className={styles.GrantAvatarList} />
)

export default GrantAvatarList