// TODO: migrate to redux-sauce

export const SELECT_ACTION = 'COMMON_SELECT_ACTION' 
export const SELECT_MULTIPLE_ACTION = 'SELECT_MULTIPLE_ACTION'
export const CLEAR_ACTION = 'CLEAR_SELECTION_ACTION'
export const ADD_TO_SELECTION_ACTION = 'ADD_TO_SELECTION_ACTION'
export const REMOVE_FROM_SELECTION_ACTION = 'REMOVE_FROM_SELECTION_ACTION'
// UPDATE THIS IF YOU ADD MORE TYPES !
const ACTION_TYPES = [
  SELECT_ACTION, CLEAR_ACTION, ADD_TO_SELECTION_ACTION, REMOVE_FROM_SELECTION_ACTION
]

export const isSelectionAction = action => ACTION_TYPES.includes(action.type)

export const select = name => value => ({ type: SELECT_ACTION, name, value })
export const selectAsMultiple = name => value => ({ type: SELECT_ACTION, name, value: (value === undefined || value === null) ? [] : [value] })
export const selectMultiple = name => values => ({ type: SELECT_MULTIPLE_ACTION, name, values })
export const clear = name => ({ type: CLEAR_ACTION, name })
export const addToSelection = name => value => ({ type: ADD_TO_SELECTION_ACTION, name, value })
export const removeFromSelection = name => value => ({ type: REMOVE_FROM_SELECTION_ACTION, name, value })