import { NODE_TYPES } from '../Constants'
import { EMPTY_ARRAY } from 'utils/object'
import { map, reduce, concat, addIndex, without, append, indexOf, prop, prepend, pipe, identity, propEq, max, filter, difference, both, find, assocPath, ifElse } from 'ramda'
import * as jsdiff from 'diff'
import { propLteTo, propLtTo } from 'utils/ramda'

export const lineIdFromNode = node => node.data.get('line_id')

export const EDIT_TYPE = {
  INSERT_TEXT: 'INSERT_TEXT',
  REMOVE_TEXT: 'REMOVE_TEXT',
  REPLACE_TEXT: 'REPLACE_TEXT'
}

export const CHANGE_TYPE = {
  ADD: 'ADD_LINE',
  DELETE: 'DELETE_LINE',
  EDIT: 'EDIT_LINE'
}

// Warning!!! We are assumming that the baseLines have data: { lineId }

export const changes = ({ document: { nodes: prevLines } }, { document: { nodes: nextLines } }) => {
  const { changes: allChanges, baseLines: missingLines } = addIndex(reduce)((acc, nextLine, nextIndex) => {
    const { changes: currentChanges, baseLines: currentBaseLines } = acc
    
    const prevIndex = prevLines.findIndex(isLineForId(lineIdFromNode(nextLine)))
    const prevLine = prevIndex > -1 ? prevLines.get(prevIndex) : undefined

    if (prevLine) {
      // line edited
      return {
        changes: lineHasChanged(prevIndex, prevLine, nextIndex, nextLine) ?
          append(editLineChange(prevLine, nextLine, prevIndex, nextIndex), currentChanges)
          : currentChanges,
        baseLines: without([prevLine], currentBaseLines)
      }
    } else {
      // line added
      return {
        changes: append(addLineChange(nextLine, nextIndex), currentChanges),
        baseLines: currentBaseLines
      }
    }

  }, { changes: [], baseLines: prevLines })(nextLines)

  // Remaining lines from prev must be deleted
  const deleteChanges = map(line => deleteLineChange(line, indexOf(line, prevLines)))(missingLines).toJS()

  return concat(allChanges, deleteChanges)
}

const isLineForId = id => line => lineIdFromNode(line) === id
const lineHasChanged = (prevIndex, prevNode, nextIndex, nextNode) => (
  nextNode.text !== prevNode.text
  || nextNode.type !== prevNode.type
  || nextIndex !== prevIndex
)

/*
// We have three types of changes:

  - ADD LINE: {
    type: ADD_LINE,
    lineIndex: n,
    lineId: id | undefined,
    data: {
      node: SlateNode // node created,
    }
  }

  - DELETE_LINE: {
    type: DELETE_LINE,
    lineIndex: n,
    lineId: id | undefined,
    data: {
      node: SlateNode // node removed,
    }
  }

  // NOTE: At the moment, the edition's scopes will be limited to actorPart | textPart and directorLine (PhysicalLines)

  - EDIT_LINE: {
    type: EDIT_LINE,
    lineIndex: n,
    lineId: id | undefined,
    data: {
      typeLineSwitched: true | false
      previousIndex
      editions: [ {
          physicalLineTypeEdited: actorPart | textPart | directorLine
          editType: insertText | removeText | replaceText
          value: text (Removed | inserted)
          start: n
          length: n,
          previousNode: SlateNode,
          currentNode: SlateNode
        } ...
      ]
    }
  }
*/

const baseChange = type => (slateLine, lineIndex) => ({
  type,
  lineIndex,
  lineId: lineIdFromNode(slateLine),
  data: { node: slateLine }
})

export const changeIsOfType = type => propEq('type', type)

export const isAddChange = changeIsOfType(CHANGE_TYPE.ADD)
export const isDeleteChange = changeIsOfType(CHANGE_TYPE.DELETE)
export const isEditChange = changeIsOfType(CHANGE_TYPE.EDIT)

export const addLineChange = baseChange(CHANGE_TYPE.ADD)
export const deleteLineChange = baseChange(CHANGE_TYPE.DELETE)

export const editLineChange = (previousLine, currentLine, previousIndex, currentIndex) => ({
  type: CHANGE_TYPE.EDIT,
  lineIndex: currentIndex,
  lineId: lineIdFromNode(previousLine),
  data: {
    previousIndex,
    typeLineSwitched: previousLine.type !== currentLine.type,
    editions: (previousLine.type !== currentLine.type ? calculateSwitchedLineEditions : calculateSimpleEditions)(previousLine, currentLine)
  }
})

// Each particular possible edit change

const calculateSwitchedLineEditions = (previousLine, currentLine) => 
  (previousLine.type === NODE_TYPES.DIRECTOR_LINE ? editionFromDirectorToDialogue : editionFromDialogueToDirector)(previousLine, currentLine)

const calculateSimpleEditions = (previousLine, currentLine) => (previousLine.type === NODE_TYPES.DIRECTOR_LINE ?
  editionsFromPhysicalLine(NODE_TYPES.DIRECTOR_LINE) : simpleEditionsFromDialogLines)(previousLine, currentLine)

const simpleEdition = (editType, start, value, physicalLineTypeEdited, previousNode, currentNode) => ({
  physicalLineTypeEdited,
  editType,
  value,
  start,
  length: value.length,
  previousNode,
  currentNode
})

export const isRemoveEdition = propEq('editType', EDIT_TYPE.REMOVE_TEXT)
export const isInsertEdition = propEq('editType', EDIT_TYPE.INSERT_TEXT)
export const isReplaceEdition = propEq('editType', EDIT_TYPE.REPLACE_TEXT)

const areJoinable = ({ physicalLineTypeEdited: removePhysicalLine, start: removeStart, length: removeLength }, { start: insertStart, physicalLineTypeEdited: insertPhysicalLine }) =>
  (removePhysicalLine === insertPhysicalLine) && insertStart === (removeStart + removeLength)

const replaceEdition = ({ previousNode, currentNode, start: removeStart, length: removeLength, value: removedValue }, { physicalLineTypeEdited, length: insertLength, value: insertedValue }) => ({
  physicalLineTypeEdited,
  editType: EDIT_TYPE.REPLACE_TEXT,
  start: removeStart,
  length: max(removeLength, insertLength),
  value: insertedValue,
  removedValue,
  previousNode,
  currentNode,
})

const joinReplaceEditions = editions => {
  let customEditions = EMPTY_ARRAY
  let offsetToMinus = 0
  for (let i = 0; i < editions.length; i++) {
    const currentEdition = editions[i]
    const nextEdition = editions[i + 1]
    const newStart = currentEdition.start - offsetToMinus
    if (nextEdition && isRemoveEdition(currentEdition) && isInsertEdition(nextEdition) && areJoinable(currentEdition, nextEdition)) {
      customEditions = append({ ...replaceEdition(currentEdition, nextEdition), start: newStart }, customEditions)
      offsetToMinus += nextEdition.length
      i++
    } else {
      customEditions = append({ ...currentEdition, start: newStart }, customEditions)
    }
  }

  return customEditions
}

const editionsFromPhysicalLine = type => (previousLine, currentLine) => {
  // TODO: at the moment, we build the changes based on simple text,
  // In the future, we could use the nodes to be more specific

  const delta = jsdiff.diffWordsWithSpace(previousLine.text, currentLine.text)

  return pipe(
    reduce(({ offset, editions }, deltaItem) => {
      const { value, added, removed } = deltaItem

      return {
        offset: offset + value.length,
        editions: (added || removed ? append(simpleEdition(added ? EDIT_TYPE.INSERT_TEXT : EDIT_TYPE.REMOVE_TEXT, offset, value, type, previousLine, currentLine)) : identity)(editions)
      }
    }, { offset: 0, editions: [] }),
    prop('editions'),
    joinReplaceEditions,
  )(delta)
}

const simpleEditionsFromDialogLines = (previousLine, currentLine) => {
  const { nodes: [prevActorPart, prevTextPart] } = previousLine
  const { nodes: [currentActorPart, currentTextPart] } = currentLine

  return concat(
    (prevActorPart.text !== currentActorPart.text) ? editionsFromPhysicalLine(NODE_TYPES.ACTOR_PART)(prevActorPart, currentActorPart) : EMPTY_ARRAY,
    (prevTextPart.text !== currentTextPart.text) ? editionsFromPhysicalLine(NODE_TYPES.TEXT_PART)(prevTextPart, currentTextPart) : EMPTY_ARRAY
  )
}

const editionFromDirectorToDialogue = (previousDirLine, currentDiagline) => {
  const { nodes: [actorPart, textPart] } = currentDiagline
  const textChanges = (previousDirLine.text !== textPart.text) ? editionsFromPhysicalLine(NODE_TYPES.TEXT_PART)(previousDirLine, textPart) : EMPTY_ARRAY

  return prepend(simpleEdition(EDIT_TYPE.INSERT_TEXT, 0, actorPart.text, NODE_TYPES.ACTOR_PART, undefined, actorPart), textChanges)
}

const editionFromDialogueToDirector = (prevDiagLine, currentDirline) => {
  const { nodes: [actorPart, textPart] } = prevDiagLine
  const textChanges = (textPart.text !== currentDirline.text) ? editionsFromPhysicalLine(NODE_TYPES.TEXT_PART)(textPart, currentDirline) : EMPTY_ARRAY

  return prepend(simpleEdition(EDIT_TYPE.REMOVE_TEXT, 0, actorPart.text, NODE_TYPES.ACTOR_PART, actorPart, undefined), textChanges)
}

const indexLteTo = propLteTo('lineIndex')
const indexLtTo = propLtTo('lineIndex')
const indexLtOrLteTo = index => ifElse(isAddChange, indexLteTo(index), indexLtTo(index))

const startLteTo = propLteTo('start')

const lineOffsetCausedBy = change => {
  if (isAddChange(change)) return 1

  if (isDeleteChange(change)) return -1
 
  return 0
}

const textOffsetCausedBy = edit => {
  if (isInsertEdition(edit)) return edit.value.length

  if (isRemoveEdition(edit)) return -edit.value.length

  // replace
  return -(edit.removedValue.length - edit.value.length)
}

const offsetGeneratedByChanges = reduce((offset, change) => offset + lineOffsetCausedBy(change), 0)
const offsetGeneratedByEditions = reduce((offset, edit) => offset + textOffsetCausedBy(edit), 0)

const updateEditionShiftingOffsets = (edit, offsetToShift) => assocPath(['start'], edit.start + offsetToShift)(edit)

const updateChangeShifting = (change, offsetToShift) => ({
  ...change,
  lineIndex: change.lineIndex + offsetToShift,
  data: {
    ...change.data,
    ...(change.data.previousIndex ? { previousIndex: change.data.previousIndex + offsetToShift } : {})
  }
})

const updateEditionIndexesWith = ({ data: { editions } }) => change => assocPath(['data', 'editions'], 
  pipe(reduce(({ updatedEditions, offsetObject, editionsToConsume }, edition) => {
  // Here, the need keep an object with three offsets because each edition could be actor part, text part or director line.

    const editionsToConsumeNow = filter(both(startLteTo(edition.start),
      propEq('physicalLineTypeEdited', edition.physicalLineTypeEdited)), editionsToConsume)

    const updatedOffsetObject = {
      ...offsetObject,
      [edition.physicalLineTypeEdited]: offsetObject[edition.physicalLineTypeEdited] + offsetGeneratedByEditions(editionsToConsumeNow)
    }

    return {
      updatedEditions: append(updateEditionShiftingOffsets(edition, updatedOffsetObject[edition.physicalLineTypeEdited]), updatedEditions),
      offsetObject: updatedOffsetObject,
      editionsToConsume: difference(editionsToConsume, editionsToConsumeNow),
    }

  }, 
  { updatedEditions: EMPTY_ARRAY,
    offsetObject: { [NODE_TYPES.ACTOR_PART]: 0, [NODE_TYPES.TEXT_PART]: 0, [NODE_TYPES.DIRECTOR_LINE]: 0 },
    editionsToConsume: editions
  }), prop('updatedEditions'))(change.data.editions))(change)

const areEditionsForSamePhysicalLine = (change1, change2) =>
  change1.lineId === change2.lineId && isEditChange(change1) && isEditChange(change2)

// TODO: We asume that the changesToUpdate come in order
export const updateIndexesWithBaseChanges = (changesToUpdate, baseChanges) =>
  pipe(reduce(({ updatedChanges, offsetToShift, changesToConsume }, changeToUpdate) => {
    const changesToConsumeNow = filter(indexLtOrLteTo(changeToUpdate.lineIndex), changesToConsume)
    const updatedOffsetToShift = offsetToShift + offsetGeneratedByChanges(changesToConsumeNow)

    // Updates the edition indexes
    const changeForSameLine = find(propEq('lineId', prop('lineId', changeToUpdate)), changesToConsume)

    const updatedChange = (changeForSameLine && areEditionsForSamePhysicalLine(changeToUpdate, changeForSameLine) ?
      updateEditionIndexesWith(changeForSameLine) : identity)(updateChangeShifting(changeToUpdate, updatedOffsetToShift))

    return {
      updatedChanges: append(updatedChange, updatedChanges),
      offsetToShift: updatedOffsetToShift,
      changesToConsume: difference(changesToConsume, changesToConsumeNow),
    }
  }, { updatedChanges: EMPTY_ARRAY, offsetToShift: 0, changesToConsume: baseChanges }), prop('updatedChanges'))(changesToUpdate)
