import React from 'react'
import { F } from 'ramda'
import SuggestionPortal from '../../Components/Autocomplete/SuggestionPortal'
import { noop } from 'utils/functions'

const matchTrigger = (editor, shouldHandleNode) => editor.currentPath() && shouldHandleNode(editor, editor.currentNode())

const autocompletePluginCreator = opts => {
  const { shouldHandleNode, onEnter, renderPortal } = opts
  const callback = { onEnter, onKeyDown: noop, isOpen: F }

  const onKeyDown = (event, editor, next) => (matchTrigger(editor, shouldHandleNode) ? callback.onKeyDown(event, editor, next) : next())

  const onChange = (editor, next) => {
    const { closePortal, isOpen } = callback
    callback.editor = editor
    if (matchTrigger(editor, shouldHandleNode) && callback.onChange) {
      return callback.onChange(editor, next)
    } else if (isOpen()) { closePortal() }

    return next()
  }

  return {
    onChange,
    onKeyDown,
    component: props => (
      renderPortal ? renderPortal(SuggestionPortal, { ...props, ...opts, callback })
        : 
        (<SuggestionPortal
          {...props}
          {...opts}
          callback={callback}
        />)
    )
  }
}

export default autocompletePluginCreator