import { setTimeout as setPauseableTimeout } from 'pauseable'

const cycler = ({ chunkChanged, chunkenizer }) => ({

  setInput(input) {
    this.chunks = chunkenizer(input)
    this.i = -1
    this.nextChunk()
  },

  getCurrentChunk() { return this.chunks[this.i] },

  async queueNextTick() {
    if (this.i < this.chunks.length - 1) {
      this.timer = setPauseableTimeout(::this.nextChunk, this.getCurrentChunk().time * 1000)
    }
  },

  nextChunk() {
    this.i++
    chunkChanged(this.getCurrentChunk(), this.isLastChunk())
    this.queueNextTick()
  },

  isLastChunk() {
    return this.i === this.chunks.length - 1
  },

  pause() {
    if (this.timer) {
      this.timer.pause()
    }
  },
  resume() {
    if (this.timer) {
      this.timer.resume()
    }
  },

  clear() {
    if (this.timer) {
      this.timer.clear()
    }
  }

})

export default cycler