import React from 'react'
import { Form } from 'antd'

import Select from './components/Select'

const SelectForm = props => (
  <Form layout="horizontal">
    <Select {...props} />
  </Form>
)

export default SelectForm