import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { currentClipId as currentClipIdSelector } from '../../selectors/engine'

import styles from './VideoPlayerTimeline.scss'

/* eslint jsx-a11y/mouse-events-have-key-events: 0 */
const TimelineSegment = ({ clip: { id, time, ui: { x1, x2, color } }, currentClipId, onHover }) => (
  <line
    x1={x1}
    y1="0"
    x2={x2}
    y2="0"
    className={classNames(styles.line, { [styles.current]: currentClipId === id })}
    {...(currentClipId !== id) && { style: { stroke: color } }}
    onMouseOver={() => { onHover({ id, ...time }) }}
  />
)

export default compose(
  connect(state => ({
    currentClipId: currentClipIdSelector(state),
  })),
)(TimelineSegment)