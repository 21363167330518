import debounce from 'lodash.debounce'
import { toPromise } from './promises'

export const polling = ({ until, onSuccess, onTimedOut, every = 1000, timeout = 120000 }) => {
  let i = 0
  const interval = setInterval(() => {
    if (until()) {
      clearInterval(interval)
      onSuccess()
    } else if (i * every >= timeout) {
      clearInterval(interval)
      onTimedOut()
    } else {
      i++
    }
  }, every)
}

export const debounceEventHandler = (f, wait) => {
  const debounced = debounce(f, wait)
  return e => {
    e.persist()
    return debounced(e)
  }
}

export const CONCURRENT_ERROR = new Error('Concurrent execution error')
const failOnConcurrent = () => Promise.reject(CONCURRENT_ERROR)

export const synchronizeFunction = (f, onConcurrency = failOnConcurrent) => {
  let promise
  return (...args) => {
    if (!promise) {
      promise = toPromise(f(...args)).then(result => { 
        promise = undefined
        return result
      }).catch(err => {
        promise = undefined
        throw err
      })

      return promise
    } else {
      return onConcurrency(...args)
    }
  }
}
