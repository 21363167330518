import { NODE_TYPES, OBJECT_TYPES } from '../Constants'
import { textNode } from './textNode'

export const customDirectorLineWithCustomData = (nodes, data) => ({
  data,
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.DIRECTOR_LINE,
  nodes,
})

export const customDirectorLine = (nodes, line_id = undefined) => customDirectorLineWithCustomData(nodes, { ...line_id && { line_id } })

export const emptyDirectorLine = customDirectorLine([textNode('')])