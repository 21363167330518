import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { pipe, pathOr } from 'ramda'
import { truncate } from 'utils/string'

// import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'

// TODO: we could show the SimpleNode but as this is being opened in a modal
// it doesn't have redux store in the context.
// We should fix that

const ErrorPlayingLine = ({ clipId, text }) => (
  <div>
    <p>
      Error playing line audio. Playback was forced to stop.
    </p>
    <p>
      The line is from clip <strong>{clipId}</strong>
    </p>
    <pre>{text}</pre>
  </div>
)

export default compose(
  withPropsOnChange(
    ['langRes'],
    ({ langRes }) => ({
      text: pipe(
        pathOr('', ['data', 'text']),
        s => truncate(s, 20)
      )(langRes)
    })
  )
)(ErrorPlayingLine)

