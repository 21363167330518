import { filter, pipe, prop, any, F, reject, pathEq, intersection, pluck, identity } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { ChangeType } from '../ChangeSet'
import { createRevertChangeSetFromSameAuthor } from './undoRedoChangeSet'

// CHANGESET CONFLICT DETECTION

const areForSameObject = (c1, c2) => objId(c1) === objId(c2) 
const objId = c => (c.added || c.deleted || c).id

const updateChangesHaveConflicts = (c1, c2) => isNotEmpty(
  intersection(
    pluck('field', c1),
    pluck('field', c2),
  ))

const DELETED_CONFLICT_DETECTION = {
  added: areForSameObject,
  updated: areForSameObject,
}

const ADDED_CONFLICT_DETECTION = {
  deleted: areForSameObject,
}

const UPDATED_CONFLICT_DETECTION = {
  deleted: areForSameObject,
  updated: ({ id: id_1, changes: changes_1 }, { id: id_2, changes: changes_2 }) =>
    id_1 === id_2 && updateChangesHaveConflicts(changes_1, changes_2),
}

const CHANGE_CONFLICT_DETECTION = {
  added: ADDED_CONFLICT_DETECTION,
  deleted: DELETED_CONFLICT_DETECTION,
  updated: UPDATED_CONFLICT_DETECTION
}

export const changesHaveConflicts = (changeToCompare, change) => {
  const changeToCompareType = ChangeType[changeToCompare.__typename]
  const changeType = ChangeType[change.__typename]
  const func = CHANGE_CONFLICT_DETECTION[changeToCompareType][changeType] || F
  return func(changeToCompare, change)
}

export const changeSetsHaveConflicts = ({ changes }) => pipe(
  prop('changes'),
  any(change => any(changeToCompare => changesHaveConflicts(changeToCompare, change))(changes))
)

export const conflictingChangeSets = (futureHistory, changeSet) => filter(changeSetsHaveConflicts(changeSet))(futureHistory)

const removeChangeSetsWithSameSession = ({ author }, changeSets) => reject(pathEq(['author', 'sessionId'], author.sessionId), changeSets)
const computeConflictingChangeSets = csMapper => (futureHistory, changeSet) =>
  conflictingChangeSets(removeChangeSetsWithSameSession(changeSet, futureHistory), csMapper(changeSet))


export const undoConflictingChangeSets = computeConflictingChangeSets(createRevertChangeSetFromSameAuthor)
export const redoConflictingChangeSets = computeConflictingChangeSets(identity)
