// utility to reuse code between org/team
import { compose, withHandlers, renameProp, withPropsOnChange } from 'recompose'
import { graphql } from 'react-apollo'

import { updateFragment } from 'api/queries/utils'

import secure from 'hocs/secure'
import withToken from 'hocs/withToken'

import EntityAvatar from 'components/Organizations/Commons/EntityAvatar'

const EntityAvatarContainer = (propName, mutation, variableName, securityCheck, urlProvider, noImageIcon) => compose(
  withToken,
  renameProp(propName, 'entity'),
  graphql(mutation, { name: 'setAvatarMutation' }),
  withHandlers({
    onImageChanged: ({ setAvatarMutation, entity }) => fileName =>
      setAvatarMutation({
        variables: { input: { [variableName]: entity._id, fileName } },
        update: updateFragment(mutation)
      })
  }),
  secure(
    'canUpload',
    ({ disabled, ...scope }) => disabled !== true && securityCheck(scope),
    ['disabled']
  ),
  withPropsOnChange([], () => ({
    urlProvider,
    noImageIcon
  })),
)(EntityAvatar)

export default EntityAvatarContainer