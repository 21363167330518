import React from 'react'
import { Select } from 'antd'
import { identity, groupBy as group } from 'ramda'
import { properties } from 'utils/object'
import EditableValue from './EditableValue'

const { Option, OptGroup } = Select

const defaultFns = {
  keyFn: identity,
  valueFn: identity,
  labelFn: identity
}

export const renderOptionsOrGroups = (currentSelected, options, groupBy, fns = defaultFns) => {
  return groupBy ? renderGroups(currentSelected, options, groupBy, fns)
    : renderOptions(currentSelected, options, fns)
}

export const renderOptions = (currentSelected, options, fns) => {
  const { keyFn, valueFn, labelFn } = fns
  return options.map(o =>
    <Option key={keyFn(o)} value={valueFn(o)} disabled={currentSelected === valueFn(o)}>{labelFn(o)}</Option>
  )
}

const renderGroups = (currentSelected, options, groupBy, fns) => {
  return properties(group(groupBy)(options)).map(aGroup => (
    <OptGroup key={aGroup.name} label={aGroup.name}>
      {renderOptions(currentSelected, aGroup.value, fns)}
    </OptGroup>
  ))
}

export class ChooseValue extends EditableValue {

  handleChange(value) {
    const { saveOnChange = false } = this.props
    this.setState({ value })

    if (saveOnChange) {
      this.handleSaveWithValue(value)
    }
  }

  renderForEditing() {
    const { options, groupBy, keyFn = identity, valueFn = identity, labelFn = identity } = this.props
    return (
      <Select dropdownMatchSelectWidth={false} defaultValue={this.state.value} onSelect={::this.handleChange}>
        {renderOptionsOrGroups(this.state.value, options, groupBy, { keyFn, valueFn, labelFn })}
      </Select>
    )
  }

}

const ChooseValueFactory = options => props => <ChooseValue {...props} options={options} />

export default ChooseValueFactory
