import React from 'react'
import States from './InvitesState'
import { Starting, InviteValidating, InviteInvalid, TakeInvite, InviteAccepted, UnknownState } from './states/InviteStateViews'
import InvitePage from './InvitePage'

const InvitesAccess = props => {
  const Content = resolvePageByState(props.state)
  return (
    <InvitePage><Content {...props} /></InvitePage>
  )
}

const componentByState = {
  [States.Starting]: Starting,
  [States.Validating]: InviteValidating,
  [States.InvalidToken]: InviteInvalid,
  [States.Taking]: TakeInvite,
  [States.Accepted]: InviteAccepted,
}
const resolvePageByState = state => componentByState[state] || UnknownState

export default InvitesAccess
