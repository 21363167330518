import React from 'react'
import { List, Tooltip, Icon } from 'antd'
import { compose } from 'recompose'
import pluralize from 'pluralize'

import { isAddedForObject, isDeletedForObject, getUpdateForObject } from 'model/ChangeSet'

import withBooleanState from 'hocs/withBooleanState'

import RelativeTime from 'components/Commons/RelativeTime'
import SimpleAvatar from 'components/Commons/SimpleAvatar/SimpleAvatar'
import FieldChange from '../FieldChange/FieldChange'

import styles from './ObjectHistoryItem.scss'

const ObjectHistoryItem = ({ object, changeSet }) => (
  <List.Item key={changeSet._id}>
    <List.Item.Meta
      avatar={<SimpleAvatar user={changeSet.author.user} />}
      title={<Title changeSet={changeSet} object={object} />}
      description={
        <div className={styles.secondaryTitle}>
          <Tooltip title={changeSet.timestamp}><RelativeTime date={changeSet.timestamp} /></Tooltip>
        </div>
      }
    />
    <div className={styles.content}>
      <Description changeSet={changeSet} object={object} />
    </div>
  </List.Item>
)

const Title = ({ changeSet, object }) => {
  if (isAddedForObject(changeSet, object)) return 'Created'
  else if (isDeletedForObject(changeSet, object)) return 'Deleted'
  else return changeSet.description
}

const Description = ({ changeSet, object }) => {
  const update = getUpdateForObject(object.id, changeSet)
  return update ? <UpdateDescription update={update} /> : null
}

const _UpdateDescription = ({ update, expanded, toggleExpanded }) => (
  <div className={styles.UpdateDescription}>
    <div className={styles.summary}>
      <span>{update.changes.length}</span> {pluralize('change', update.changes.length)} <Icon type={expanded ? 'up' : 'down'} onClick={toggleExpanded} />
    </div>
    {expanded && (
      <ChangesDetail update={update} />
    )}
  </div>
)

const UpdateDescription = compose(
  withBooleanState('expanded', 'setExpanded', false)
)(_UpdateDescription)

const ChangesDetail = ({ update }) => update.changes.map(({ field, prev, next }) => (
  <div key={field} className={styles.ChangesDetail}>
    <FieldChange field={field} prev={prev} next={next} />
  </div>
))

export default ObjectHistoryItem