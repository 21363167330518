exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameRow__3rMZH .ant-tag{margin-left:.5rem}", ""]);

// exports
exports.locals = {
	"nameRow": "nameRow__3rMZH"
};