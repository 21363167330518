import { curryN, path } from 'ramda'
import { shouldUpdate } from 'recompose'

import { dotSplit } from 'utils/string'

/**
 * To be used as part of a recompose shouldUpdate, because it returns Boolean.
 *
 * @param {[]} paths - an array of strings which are object paths, dot separated (ognl-like)
 * @returns (old, next) => {Boolean} - true if any of the specified paths are different between old and next objects
 */
export const whenAnyChangesOn = paths => curryN(2, (old, next) =>
  paths.reduce((accum, prop) => (accum || path(dotSplit(prop), old) !== path(dotSplit(prop), next)), false)
)

/**
 * This should be used as a shouldUpdate shorthand/replacement.
 *
 * @param {[]} paths - an array of strings which are object paths, dot separated (ognl-like)
 * @returns a HOC that decides wether the component should update or not, like shouldUpdate.
 */
export const onlyUpdateForPaths = paths =>
  shouldUpdate(whenAnyChangesOn(paths))
