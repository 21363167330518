exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".choicesTitle__30GFn{font-size:1.2rem;text-align:center;padding-bottom:.5rem}", ""]);

// exports
exports.locals = {
	"choicesTitle": "choicesTitle__30GFn"
};