import React from 'react'
import { connect } from 'react-redux'
import { onlyUpdateForKeys, compose, withHandlers } from 'recompose'
import classNames from 'classnames'

import { Creators } from 'actions/vm'
import { playingNodesIds, isPlaying, isPaused } from 'selectors/playback'

import Preferences from 'preferences/Preferences'
import { togglePreference as togglePreferenceAction } from 'actions/view'
import withSinglePreference from '../../../hocs/withSinglePreference'
import { EMPTY_ARRAY } from '../../../utils/object'
import { AvatarCacheContext, useAvatarCacheContext } from './AvatarCache'

import MinimizedCurrentNodePlayback from './MinimizedCurrentNodePlayback'
import VisibleCurrentNodePlayback from './VisibleCurrentNodePlayback'

import styles from './CurrentNodePlayback.scss'

const { startPlaybackOnSelection, stopPlayback: stopPlaybackAction } = Creators

/**
 * The overlay that shows the currently playing content like lines of dialogue (subtitles)
 * and the choices component
 */
export const CurrentNodePlayback = ({ playing, nodes, nosound, currentPlaybackVisible, toggleVisible }) => {
  const Component = currentPlaybackVisible ? VisibleCurrentNodePlayback : MinimizedCurrentNodePlayback

  const avatarCache = useAvatarCacheContext(playing)

  return nodes.length > 0 ? (
    <div className={classNames(styles.currentNodesCanvas, { [styles.minimized]: !currentPlaybackVisible })}>
      <AvatarCacheContext.Provider value={avatarCache}>
        <Component nodeIds={nodes} nosound={nosound} toggleVisible={toggleVisible} />
      </AvatarCacheContext.Provider>
    </div>
  ) : null
}

export default compose(
  connect(
    state => ({
      playing: isPlaying(state),
      nodes: isPlaying(state) || isPaused(state) ? playingNodesIds(state) : EMPTY_ARRAY,
    }), {
      startPlayback: startPlaybackOnSelection,
      stopPlayback: stopPlaybackAction,
      togglePreference: togglePreferenceAction
    }
  ),
  withSinglePreference('currentPlaybackVisible', Preferences.Playback.currentPlaybackVisible),
  onlyUpdateForKeys(['nodes', 'currentPlaybackVisible']),
  withHandlers({
    toggleVisible: ({ togglePreference, currentPlaybackVisible }) => () => {
      togglePreference(Preferences.Playback.currentPlaybackVisible, !currentPlaybackVisible)
    }
  }),
)(CurrentNodePlayback)
