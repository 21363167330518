import React from 'react'
import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'

import { markupDefinitions as markupDefinitionsSelector } from 'selectors/markups'
import { revisionId } from 'selectors/project'

import connectState from 'hocs/connectState'
import withFilteredElements from 'hocs/withFilteredElements'

import markupDefinitionDeleteMutation from 'api/mutations/markupDefinitionDelete.graphql'

import { markupDefDeleted as markupDefDeletedAction, markupDefEdited as markupDefEditedAction } from 'actions/project'
import useWalkAction from '../../../hooks/beanie/io/useWalkAction'

import { lineHasMarkup } from '../../../model/project/searches/query/bneQueries'
import { findSearch } from '../../../model/project/searches/searches'

import MarkupsTabHeader from './MarkupsTabHeader'
import MarkupsTable from './MarkupsTable'
import CreateMarkup from './CreateMarkup'

const MarkupsTab = ({ elements, onSearch, deleteMarkup, markupDefEdited }) => {
  const onWalkMarkupUsages = useWalkAction(markup =>
    findSearch(lineHasMarkup(markup.name), `Usages of "${markup.name}"`)
  )

  return (
    <div>
      <MarkupsTabHeader onSearch={onSearch}>
        <CreateMarkup />
      </MarkupsTabHeader>
      <MarkupsTable
        markups={elements}
        deleteMarkup={deleteMarkup}
        markupDefEdited={markupDefEdited}
        onWalkMarkupUsages={onWalkMarkupUsages}
      />
    </div>
  )
}

export default compose(
  connectState({
    markupDefinitions: markupDefinitionsSelector
  }),
  connect(state => ({
    revision: revisionId(state)
  }), {
    markupDefDeleted: markupDefDeletedAction,
    markupDefEdited: markupDefEditedAction
  }),
  withFilteredElements({ propName: 'markupDefinitions', filter: (markup, text) => markup.name.toUpperCase().indexOf(text.toUpperCase()) >= 0 }),
  graphql(markupDefinitionDeleteMutation, { name: 'markupDefinitionDelete' }),
  withHandlers({
    deleteMarkup: ({ markupDefinitionDelete, revision, markupDefDeleted }) => async markup => {
      const { data: { markupDefinitionDelete: deleted } } = await markupDefinitionDelete({ variables: { input: {
        projectId: revision,
        name: markup.name,
      } } })
      markupDefDeleted(deleted)
    }
  })
)(MarkupsTab)