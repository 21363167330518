exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".errorPanel__pi_vg{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-item-align:center;align-self:center;-ms-flex-align:center;align-items:center;-ms-flex-positive:1;flex-grow:1;padding-top:2em}.errorPanel__pi_vg .anticon{padding-right:.5em;font-size:1.5em}.errorPanel__pi_vg details{width:80%;margin-top:2em;padding-top:1em;padding-left:1em;padding-right:1em;padding-bottom:1em;background-color:#fafafa;border:1px solid #d3d3d3;border-radius:10px}.errorPanel__pi_vg h1{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.errorPanel__pi_vg h2{padding-left:3em}", ""]);

// exports
exports.locals = {
	"errorPanel": "errorPanel__pi_vg"
};