import React, { useCallback } from 'react'

import { Tooltip, Icon, Dropdown, Menu } from 'antd'
import styles from './Note.scss'
import { NODE_TYPES } from '../../utils/Constants'

const formatNote = ({ text }) => text.slice(1, text.length - 1)

const NOTE_COLOR = {
  [NODE_TYPES.PERFORMANCE_NOTE]: styles.performanceNoteColor,
  [NODE_TYPES.PRODUCTION_NOTE]: styles.productionNoteColor
}

export const renderNote = ({ editor, node, visible, attributes, children, styleComp, contentEditable }) => {
  const note = renderVisibleNote(editor, node, visible, attributes, children, styleComp)
  return (
    <span contentEditable={contentEditable} suppressContentEditableWarning className={styles.NoteContainer}>{visible ? note : <HiddenNote node={node} note={note} editor={editor} />}</span>
  )
}

const NoteDropDownMenu = ({ editor, node }) => {
  const convertToRegularText = useCallback(() => {
    editor.convertNoteToPlainText(node)
  }, [editor, node])
  const deleteNote = useCallback(() => {
    editor.deleteNote(node)
  }, [editor, node])
  return (
    <Menu>
      <Menu.Item key="convertToText" onClick={convertToRegularText}>Convert to regular text</Menu.Item>
      <Menu.Item key="delete" onClick={deleteNote}>Delete Note</Menu.Item>
    </Menu>
  )
}

const renderNoteDropdownMenu = (editor, node) => (
  <NoteDropDownMenu editor={editor} node={node} />
)

const renderVisibleNote = (editor, node, visible, attributes, children, styleComp) => {
  const overlay = useCallback(() => renderNoteDropdownMenu(editor, node), [editor, node])
  return (
    <Dropdown overlay={overlay} trigger={['contextMenu']}>
      <span {...attributes} hidden={!visible} className={styleComp}>{children}</span>
    </Dropdown>
  )
}

const HiddenNote = ({ note, node }) => (
  <Tooltip title={formatNote(node)}><span
    className={styles.hiddenIcon}
    contentEditable={false} >
    <Icon
      type="down-square"
      className={styles.noteIndicator}
      theme="filled"
      {...{ style: { color: NOTE_COLOR[node.type] } }}
    />
    {note}
  </span>
  </Tooltip>
)

export default HiddenNote