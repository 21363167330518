import { connectToChildren } from './node/insertAfterId'

/**
 * High-order function to create an action
 * that creates some nodes, for an input node and inserts it into a parent
 * through a connectionFn.
 * Sample usages: truthTableFromChoicesAction, conditionalFromChoicesAction
 *
 * @param sourceId a source node from where to create content, for example a choices
 * @param parentId where the new content needs to be connected to
 * @param connectionFn how to connect them (api, newObject: Lua, parentObject: Lua)
 */
const createFromNodeInto = creator => (sourceId, parentId, connectionFn = connectToChildren) => api => {
  if (api.obj.get(sourceId)) {
    // create the content
    const createdObject = creator(sourceId)(api)
    // now connect it to the parent
    connectionFn(api, createdObject, api.obj.get(parentId))
    // returns the created id (could be the root of a whole chain !
    return createdObject.get_id()
  }
}

export default createFromNodeInto