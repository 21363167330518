import { createSelector } from 'reselect'
import { projectSelector } from './project'
import { path, pathOr, pipe, any } from 'ramda'
import { TaskState } from 'actions/tasks'
import { EMPTY_ARRAY } from 'utils/object'

const isTaskRunningOrWaiting = t => t.state === TaskState.waiting || t.state === TaskState.running

export const loadingTasks = createSelector([projectSelector], pathOr(EMPTY_ARRAY, ['job', 'tasks']))

export const doneTasks = createSelector([projectSelector], pathOr(EMPTY_ARRAY, ['doneTasks']))

export const currentJobName = createSelector([projectSelector], path(['job', 'name']))

export const isAnyTaskRunning = pipe(loadingTasks, any(isTaskRunningOrWaiting))
