import React from 'react'
import classNames from 'classnames'
import { compose, withProps } from 'recompose'
import { applySpec, path } from 'ramda'
import Preferences from 'preferences/Preferences'
import withPreference from 'hocs/withPreference'
import withModel from 'hocs/withModel'
import connectState from 'hocs/connectState'
import { isEmpty } from 'utils/object'

import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'

import ErrorsPopover from './ErrorsPopover'
import issueIcon from './IssueIcon'

import HiddenMarkup from './HiddenMarkup'

import styles from './MarkUp.scss'
import { isMarkupVisible as visibleMarkupSelector } from './selectors'

const MarkUp = ({ editor, attributes, children, node, errors, visible }) => {
  const contentEditable = useSecureCheck(isCurrentRevisionWritable)

  const markup = render(visible, attributes, children, node, errors)
  return (<span contentEditable={contentEditable} suppressContentEditableWarning className={styles.markUpContainer}>{visible ? markup : <HiddenMarkup markup={markup} node={node} editor={editor} />}</span>)
}

const render = (visible, attributes, children, node, errors) => (
  visible && !isEmpty(errors) ?
    <span {...attributes} className={classNames(styles.markup, styles.hasError)}>
      <ErrorsPopover errors={errors}>{issueIcon(errors)}</ErrorsPopover>{children}
    </span>
    : <span hidden={!visible} {...attributes} className={styles.markUp}>{children}</span>
)

export default compose(
  withPreference({ visibleMarkups: Preferences.TextEditor.visibleMarkups }),
  withModel('markup'),
  connectState({
    visible: visibleMarkupSelector
  }),
  withProps(applySpec({
    errors: path(['markup', 'errors'])
  }))
)(MarkUp)