import React from 'react'
import Cursor from './Cursor'

/**
 * The current line prompt value.
 * This handles the cursor position (point)
 */
const Prompt = ({ cursorRef, point, value }) => {
  if (point < 0) {
    return [value]
  } else if (point === value.length) {
    return [
      value,
      <Cursor key="cursor" cursorRef={cursorRef}>&nbsp;</Cursor>
    ]
  } else {
    return [
      value.substring(0, point),
      <Cursor key="cursor" cursorRef={cursorRef}>{value.substring(point, point + 1)}</Cursor>,
      value.substring(point + 1)
    ]
  }
}

export default Prompt