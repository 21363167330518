import React from 'react'
import classNames from 'classnames'
import NodeText from 'components/Commons/NodeText'
import NodeIcon from 'components/Commons/nodes/NodeIcon'

import styles from './DefaultSearchResultItem.scss'

const DefaultSearchResultItem = ({ item, className }) => (
  <div className={classNames(styles.DefaultSearchResultItem, className)}>
    <NodeIcon node={item.object} />
    <NodeText node={item.object} />
  </div>
)

export default DefaultSearchResultItem