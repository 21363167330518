import { drop } from 'ramda'
import createLineHighlightDecoration from './createLineHighlightDecoration'

/**
 * Creates a highlight for the whole line.
 * This is used to implement the highlight for a search that matched the whole line object (a find ! not a match search)
 * and not a specific part of its text.
 */
const createWholeLineHighlightDecoration = (node, editor, lineId) => {
  const nodePath = editor.pathByKey(node)
  return Array.from(node.texts())
    .map(([text]) => {
      const relativePath = drop(nodePath.size, editor.pathByKey(text.key)).toJS()
      return createLineHighlightDecoration(
        lineId,
        0, // REVIEWME: 0 ???
        relativePath,
        text.key,
        0,
        text.text.length,
      )
    })
}

export default createWholeLineHighlightDecoration