import React from 'react'
import { Table, Icon } from 'antd'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'

import { selectNode } from 'actions/nodes'

import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'
import ShortId from 'components/Commons/ShortId'

import { fullTable } from 'styles/table.scss'
import { EMPTY_ARRAY } from 'utils/object'
import styles from './ChainExecutionsView.scss'

const { Column } = Table

const NodeExecutionsTable = ({ chainExecution }) => {
  const dispatch = useDispatch()
  return (
    <div className={classNames(fullTable, styles.NodeExecutionsTable)}>
      <Table
        dataSource={chainExecution ? chainExecution.node_executions : EMPTY_ARRAY}
        rowKey="id"
        defaultExpandAllRows
        pagination={false}
        scroll={{ y: true }}
        locale={{
          emptyText: chainExecution ? 'No node executions' : <span className={styles.noDataSelectJob}><Icon type="arrow-left" />Select an execution from the left to see its nodes here</span>
        }}
      >
        <Column
          title="Execution ID"
          dataIndex="id"
          key="id"
          className={styles.idColumn}
          render={id => <ShortId id={id} />}
        />
        <Column
          title="Node"
          key="node"
          dataIndex="node"
          render={id => (id ? <SimpleNode nodeId={id} onClick={() => dispatch(selectNode(id))} /> : <span className={styles.unknownThread}><Icon type="warning" />Unknown thread!</span>)}
        />
      </Table>
    </div>
  )
}

export default NodeExecutionsTable