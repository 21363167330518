exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".RecordLineButton__3Waj5{display:-ms-flexbox;display:flex}.micButton__1Nv6w>div{width:14px;height:14px;background-color:transparent;-webkit-box-shadow:none;box-shadow:none}.micButton__1Nv6w>div:hover{-webkit-box-shadow:none;box-shadow:none}.micButton__1Nv6w>div>img{width:16px;height:16px;-webkit-filter:grayscale(100%) brightness(30%);filter:grayscale(100%) brightness(30%)}.recording__2BBrb>div{background-color:#ff4931 !important}.recording__2BBrb>div>img{-webkit-filter:none;filter:none}", ""]);

// exports
exports.locals = {
	"RecordLineButton": "RecordLineButton__3Waj5",
	"micButton": "micButton__1Nv6w",
	"recording": "recording__2BBrb"
};