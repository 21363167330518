import handlersBySys from './handlersBySys'

/**
 *
 */
const onEndExecuteNode = (dispatch, getState) => (bneNode, playbackId, speculative) => {
  if (speculative === undefined) speculative = false
  const sys = bneNode.get_sys()

  handlersBySys[sys]?.onEndExecuteNode?.(bneNode, playbackId, dispatch, getState)
}

export default onEndExecuteNode