// this is temporary (cof) file to hold just the ids and void circular dependencies
// between reducers/selectors and UI which I introduced in Panes.js :(

export const PLAYBACK = 'playback'

export const TEXT = 'text'
export const CHAIN = 'chain'

export const EXECUTIONS = 'executions'
export const PROBLEMS = 'problems'
export const PROPERTIES = 'props'
export const DEV_PROPERTIES = 'devprops'
export const VARIABLES = 'variables'
export const OBJECT_HISTORY = 'objectHistory'
export const UNDO = 'undo'
export const SEARCH = 'search'
export const STATE = 'state'
export const CONSOLE = 'console'
export const PINS = 'pins'
export const TASKS = 'tasks'
