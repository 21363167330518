import React from 'react'
import { connect } from 'react-redux'
import { propEq, reject, pipe, isNil, isEmpty } from 'ramda'
import { isNotNilOrEmpty, isNotNil } from 'ramda-adjunct'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import { Modal, Form, Input, Select, Button, Tabs } from 'antd'
import { sentenceCase } from 'change-case'
import { removeDuplicates } from 'utils/object'

import buildConfigCreate from 'api/mutations/buildConfigCreate.graphql'
import buildConfigUpdate from 'api/mutations/buildConfigUpdate.graphql'

import { Errors } from 'components/Commons/Error'
import { parseGraphQLErrors } from 'utils/graphql'

import withRevisionId from 'hocs/withRevisionId'

import { HEAD, SOURCE } from 'actions/extensions'
import { ALLOWED_PACKAGES, RUNTIME, STUDIO } from './constants'

import IconButton from 'components/IconButton/IconButton'
import SortableList from 'components/SortableList'

import styles from './EditBuildConfig.scss'
import { buildConfigSet } from 'actions/project'

const { Option } = Select
const { TextArea } = Input
const { TabPane } = Tabs
const { Item: FormItem } = Form


const PackageSelect = ({ pkgType, form, initialValue, k, packages, remove, hideVersionIfOnly1 }) => {
  const { getFieldDecorator, getFieldValue } = form

  const selectedPackage = getFieldValue(`${pkgType}s[${k}].name`) || initialValue.name

  const versions = packages.find(p => p.name === selectedPackage)?.versions || []

  const hideVersion = hideVersionIfOnly1 && versions.length < 2

  const keys = form.getFieldValue(`${pkgType}s-keys`)

  const selectedPackages = keys.map(key => getFieldValue(`${pkgType}s[${key}].name`))

  return (<div className={styles.packageSelect}>
    <FormItem
      label={`${sentenceCase(pkgType)}`}
      colon={false}
    >
      {getFieldDecorator(`${pkgType}s[${k}].name`, {
        initialValue: initialValue.name,
        validateTrigger: ['onChange', 'onBlur'],
        rules: [
          {
            required: true,
            message: "Please input package's name",
          },
        ],
      })(
        <Select>
          {
            packages
              .filter(({ name }) => name === selectedPackage || !selectedPackages.includes(name))
              .map(({ name }) => (
                <Option value={name}>{name}</Option>
            ))
          }
        </Select>
      )}
    </FormItem>

    {
      !hideVersion && (
        <FormItem
          label="Version"
          colon={false}
        >
          {getFieldDecorator(`${pkgType}s[${k}].v`, { initialValue: initialValue.v })(
            <Select allowClear mode="single">
              {
                versions.map(({ version }) => (
                  <Option value={version}>{version}</Option>
                ))
              }
            </Select>
          )}
        </FormItem>
      )
    }

    <div className={styles.alignSelfEnd}>
      <div className={styles.packageActions}>
        <IconButton
          className={styles.packageActionsIcon}
          icon="drag"
        />
        <IconButton
          className={styles.packageActionsIcon}
          icon="delete"
          onClick={() => remove(k)}
        />
      </div>
    </div>
  </div>)
}

class Packages extends React.Component {

  remove = k => {
    const { form, pkgType } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue(`${pkgType}s-keys`)

    // can use data-binding to set
    form.setFieldsValue({
      [`${pkgType}s-keys`]: keys.filter(key => key !== k),
    })
  }

  add = () => {
    const { form, pkgType } = this.props
    // can use data-binding to get
    const keys = form.getFieldValue(`${pkgType}s-keys`)
    const nextKeys = keys.concat(this.state.id)
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      [`${pkgType}s-keys`]: nextKeys,
    })

    this.setState({
      id: this.state.id + 1
    })
  }

  constructor(props) {
    super(props)

    const { initialValue = [] } = this.props

    this.state = { id: initialValue.length }
  }

  render() {
    const { packages, form, pkgType, initialValue = [], hideVersionIfOnly1 } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const fieldName = `${pkgType}s-keys`

    getFieldDecorator(fieldName, { initialValue: initialValue.map((v, i) => i) })

    const keys = getFieldValue(fieldName)
    const hasMorePackages = keys.length < packages.length

    return (
      <div className={styles.packageTab}>
        <SortableList
          setValues={values => {
            form.setFieldsValue({
              [fieldName]: values
            })
          }}
          values={keys}
          renderItem={(k) => (
            <Form.Item
              required={false}
              key={k}
            >
              <PackageSelect initialValue={(isNotNilOrEmpty(initialValue) && initialValue[k]) || {}} k={k} packages={packages} form={form} pkgType={pkgType} remove={this.remove.bind(this)} hideVersionIfOnly1={hideVersionIfOnly1} />
            </Form.Item>
          )}
        />

        {hasMorePackages && (
          <Button type="dashed" onClick={this.add}>
            Add {sentenceCase(pkgType)}
          </Button>
        )}
      </div>
    )
  }
}

class EditBuildConfigForm extends React.Component {

  state = {
    confirmDirty: false,
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  render() {
    const { buildConfig = {}, onAccept, onCancel, form, visible, loading, serverErrors, runtimeData } = this.props

    const maybeAddRuntime = libs => (buildConfig.runtime ? [{ name: RUNTIME, v: buildConfig.runtime }, ...libs] : libs)
    const maybeAddStudio = libs => (buildConfig.studio ? [{ name: STUDIO, v: buildConfig.studio }, ...libs] : libs)

    const allVersions = removeDuplicates(runtimeData.packages.reduce((acc, e) => {
      return acc.concat(e.versions.map(({ version }) => version))
    }, []))

    const { getFieldDecorator } = form
    const required = { required: true, message: 'required.' }
    return (<Modal wrapClassName={styles.addEditModal} confirmLoading={loading} onCancel={onCancel} onOk={onAccept} title={isEmpty(buildConfig) ? 'Add Build Config' : 'Edit Build Config'} visible={visible} okText="Save">
      <Form>
        <div className={styles.nameSection}>
          <div className={styles.twoColumns}>
            <FormItem label="Name" colon={false}>
              { getFieldDecorator('name', {
                rules: [required],
                initialValue: buildConfig.name
              })(
                <Input />
              )}
            </FormItem>

            <FormItem label="Version" colon={false}>
              { getFieldDecorator('v', {
                rules: [required],
                initialValue: buildConfig.v,
              })(
                <Select>
                  {allVersions.map(
                    v => (
                      <Option value={v}>{v}</Option>
                    )
                  )}
                </Select>
              )}
            </FormItem>
          </div>

          <FormItem label="Description" colon={false}>
            { getFieldDecorator('description', {
              initialValue: buildConfig.description
            })(
              <TextArea rows={6} />
            )}
          </FormItem>
        </div>

        <Tabs type="card">
          <TabPane tab="Packages" key="1">
            <Packages initialValue={pipe(maybeAddStudio, maybeAddRuntime)([])} form={form} pkgType="package" packages={this.props.runtimeData.packages.filter(({ name }) => ALLOWED_PACKAGES.includes(name))} />
          </TabPane>
          <TabPane tab="Runtime Extensions" key="2">
            <Packages initialValue={buildConfig.libs} form={form} pkgType="runtimeExtension" packages={this.props.runtimeData.runtimeExtensions} />
          </TabPane>
          <TabPane tab="Project Extensions" key="3">
            <Packages hideVersionIfOnly1 initialValue={buildConfig.exts} form={form} pkgType="projectExtension" packages={this.props.runtimeData.projectExtensions} />
          </TabPane>
        </Tabs>

        {serverErrors && serverErrors.length > 0 &&
          <Errors items={serverErrors} />
        }
      </Form>
    </Modal>
    )
  }
}

const WrappedEditBuildConfigForm = Form.create()(EditBuildConfigForm)

class AddEditBuildConfig extends React.Component {
  state = {
    loading: false,
    visible: false
  }

  saveFormRef = form => {
    this.form = form
  }

  setVisible = visible => { this.setState({ visible }) }
  openModal = () => { this.setVisible(true) }
  closeModal = () => { this.setVisible(false) }

  render() {
    const { buildConfig, Button: ButtonCompo, runtimeData } = this.props

    const { visible, loading, errors } = this.state
    return (
      <div>
        {
          ButtonCompo ? <ButtonCompo onClick={this.openModal} /> : <IconButton icon="edit" tooltip="Edit" tooltipPlacement="bottom" onClick={this.openModal} />
        }
        {visible &&
          <WrappedEditBuildConfigForm
            ref={this.saveFormRef}
            runtimeData={runtimeData}
            buildConfig={buildConfig}
            visible={visible}
            onCancel={this.closeModal}
            onAccept={this.perform}
            loading={loading}
            serverErrors={errors}
          />
        }
      </div>
    )
  }

  perform = () => {
    const { callback, buildConfig, buildConfigCreate: buildConfigCreateMutation, buildConfigUpdate: buildConfigUpdateMutation, revisionId, onAccept, setBuildConfig } = this.props
    const { form } = this
    form.validateFields((err, values) => {
      if (err) return
      this.setState({ loading: true })
      const { name, description, v, packages } = values

      const sortByFormKeys = field => (values[`${field}-keys`] || []).map(k => values[field][k])
      const [runtimeExtensions, projectExtensions] = ['runtimeExtensions', 'projectExtensions'].map(sortByFormKeys)

      const isRuntime = propEq('name', RUNTIME)
      const runtime = packages?.find(isRuntime)?.v
      const isStudio = propEq('name', STUDIO)
      const studio = packages?.find(isStudio)?.v

      // TODO add a new array for actual packages like 'core'
      // const packages = reject(anyPass([isNil, isRuntime, isStudio]), packages)
      const libs = reject(isNil, runtimeExtensions || [])
      const exts = reject(isNil, projectExtensions || [])
        .map(({ name: n, v: ver, bytecode }) => ({ name: n, v: ver || HEAD, bytecode: bytecode || SOURCE }))

      const input = {
        revision: revisionId,
        ...(buildConfig && ({ id: buildConfig._id || buildConfig.id })),
        ...(name && { name }),
        ...(description && { description }),
        ...(v && { v }),
        ...(runtime && { runtime }),
        ...(studio && { studio }),
        ...(isNotNil(libs) && { libs }),
        ...(isNotNil(exts) && { exts }),
      }

      const fn = buildConfig ? buildConfigUpdateMutation : buildConfigCreateMutation
      fn({ variables: { input } })
        .then(() => {
          this.setState({ loading: false, errors: [] })
          form.resetFields()
          this.setState({ visible: false })
          this.closeModal()
          if (onAccept) { onAccept() }
          if (callback) { callback() }
          if (name === STUDIO) setBuildConfig(input)
        })
        .catch(error => {
          this.setState({ loading: false, errors: parseGraphQLErrors(error) })
        })
    })
  }

}

export default compose(
  withRevisionId,
  graphql(buildConfigCreate, { name: 'buildConfigCreate' }),
  graphql(buildConfigUpdate, { name: 'buildConfigUpdate' }),
  connect(null, (dispatch) => ({
    setBuildConfig: bc => dispatch(buildConfigSet(bc))
  })),
)(AddEditBuildConfig)
