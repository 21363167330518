import React from 'react'
import { Icon, Tooltip, Switch } from 'antd'

import { isEnabled } from 'selectors/debuggingData'
import { useDispatch, useSelector } from 'react-redux'
import { updateDebugMode } from 'actions/project/debuggingData'

import styles from './DecorationsSwitch.scss'

const ExecutionDecorationSwitch = () => {
  const _isEnabled = useSelector(isEnabled)
  const dispatch = useDispatch()
  const tooltip = `${_isEnabled ? 'Disable' : 'Enable'} debug mode`
  return (
    <div className={styles.container}>
      <Tooltip title={tooltip}><Icon type="pushpin" /></Tooltip>
      <Switch
        checked={_isEnabled}
        onChange={() => dispatch(updateDebugMode(!_isEnabled))}
      />
    </div>
  )
}

export default ExecutionDecorationSwitch