import React from 'react'
import { Icon, Tooltip } from 'antd'

import Preferences from 'preferences/Preferences'

import TogglePreferenceSwitch from 'components/Commons/Preferences/TogglePreferenceSwitch'

import styles from './DecorationsSwitch.scss'

const ExecutionDecorationSwitch = () => (
  <div className={styles.container}>
    <Tooltip title="Enable/disable Execution Decorations"><Icon type="highlight" /></Tooltip>
    <TogglePreferenceSwitch
      preference={Preferences.GraphView.trackExecution}
    />
  </div>
)

export default ExecutionDecorationSwitch