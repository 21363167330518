import { schema, Sys } from 'beanie-engine-api-js'
import { toBNEAction } from './utils'

const DEFAULT_PROPERTY_NAME = 'container_contents'

// this is prepared to be able to support container actions on components node.
// For example to be able to add condition when you have another condition (and not conditional)
// object selected. But as the data model doesn't have a reference to the container
// object then we cannot access it :(
const propertyMapping = {}
export const isContainer = sys => Object.keys(propertyMapping).includes(sys)
const registerContainer = (sys, method, propertyName = DEFAULT_PROPERTY_NAME) => {
  propertyMapping[sys] = { method, removeMethod: method.replace(/^add_/, 'remove_'), property: schema.propertyOf(sys, propertyName) }
}

registerContainer(Sys.conditional, 'add_condition')
registerContainer(Sys.sequencer, 'add_sequence')
registerContainer(Sys.task_container, 'add_task_item')
registerContainer(Sys.choices, 'add_choice')
registerContainer(Sys.choices2, 'add_choice')
registerContainer(Sys.folder, 'add_folder_item')
registerContainer(Sys.logic, 'add_condition_node')
registerContainer(Sys.truth_table, 'add_row')

export const _addComponent = (composite, componentId) => api => {
  if (!propertyMapping[composite.sys]) {
    throw new Error(`Cannot add component to non-composite node of sys = ${composite.sys}`)
  }
  return api.obj.get(composite.id).call(propertyMapping[composite.sys].method, componentId)
}

export const addComponent = toBNEAction(_addComponent)
export const addComponentEnabled = node => isContainer(node.sys)
