/* eslint no-undef: 0, no-mixed-operators: 0, react/require-default-props: 0, react/forbid-prop-types: 0, no-shadow: 0 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { loadMediaElt, loadAudio, EVENTS, audioPropType, hookEventListeners, positiveIntegerProptype, resizeThrottler } from './utils'

import WaveSurfer from 'wavesurfer.js'

class Wavesurfer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReady: false
    }

    const { responsive, pos, playing } = this.props

    this._seekTo = this._seekTo.bind(this)

    if (responsive) {
      this._handleResize = resizeThrottler(() => {
        if (playing) {
          this._wavesurfer.pause()
        }
        this._wavesurfer.drawBuffer()

        if (this.state.isReady) {
          this._seekTo(pos)
        }

        if (playing) {
          this._wavesurfer.play()
        }
      })
    }
  }

  componentDidMount() {
    const { mediaElt, pos, volume, playing, zoom, audioFile, audioPeaks, responsive } = this.props
    
    const options = {
      ...this.props.options,
      container: this.wavesurferEl,
      ...(mediaElt && { backend: 'MediaElement' })
    }

    this._wavesurfer = new WaveSurfer(options)
    this._wavesurfer.init()

    this._wavesurfer.on('ready', () => {
      this.setState({
        isReady: true,
        pos
      })

      if (pos) { this._seekTo(pos) }
      if (volume) { this._wavesurfer.setVolume(volume) }
      if (playing) { this._wavesurfer.play() }
      // set initial zoom
      if (zoom) { this._wavesurfer.zoom(zoom) }
    })

    this._wavesurfer.on('audioprocess', pos => {
      this.setState({ pos })
      this.props.onPosChange({
        wavesurfer: this._wavesurfer,
        originalArgs: [pos]
      })
    })

    // `audioprocess` is not fired when seeking, so we have to plug into the
    // `seek` event and calculate the equivalent in seconds (seek event
    // receives a position float 0-1) – See the README.md for explanation why we
    // need this
    this._wavesurfer.on('seek', pos => {
      if (this.state.isReady) {
        const formattedPos = this._posToSec(pos)
        /* eslint react/no-unused-state: 0 */
        this.setState({ formattedPos })
        this.props.onPosChange({
          wavesurfer: this._wavesurfer,
          originalArgs: [formattedPos]
        })
      }
    })

    // hook up events to callback handlers passed in as props
    hookEventListeners(this.props, this._wavesurfer)

    if (audioFile) {
      loadAudio(this._wavesurfer, audioFile, audioPeaks)
    }

    if (mediaElt) {
      loadMediaElt(this._wavesurfer, mediaElt, audioPeaks)
    }

    if (responsive) {
      window.addEventListener('resize', this._handleResize, false)
    }
  }

  // update wavesurfer rendering manually
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { options = {} } = this.props
    const changed = name => this.props[name] !== nextProps[name]

    let newSource = false
    let seekToInNewFile

    // update audioFile
    if (changed('audioFile')) {
      this.setState({ isReady: false })
      loadAudio(this._wavesurfer, nextProps.audioFile, nextProps.audioPeaks)
      newSource = true
    }

    // update mediaElt
    if (changed('mediaElt')) {
      this.setState({ isReady: false })
      loadMediaElt(this._wavesurfer, nextProps.mediaElt, nextProps.audioPeaks);
      newSource = true
    }

    // update peaks
    if (changed('audioPeaks')) {
      if (nextProps.mediaElt) {
        loadMediaElt(this._wavesurfer, nextProps.mediaElt, nextProps.audioPeaks);
      } else {
        _loadAudio(this._wavesurfer, nextProps.audioFile, nextProps.audioPeaks);
      }
    }

    // update position
    if (nextProps.pos !== undefined && this.state.isReady && nextProps.pos !== this.props.pos && nextProps.pos !== this.state.pos) {
      if (newSource) {
        seekToInNewFile = this._wavesurfer.on('ready', () => {
          this._seekTo(nextProps.pos)
          seekToInNewFile.un()
        })
      } else {
        this._seekTo(nextProps.pos)
      }
    }

    // update playing state
    if (!newSource && (this.props.playing !== nextProps.playing || this._wavesurfer.isPlaying() !== nextProps.playing)) {
      if (nextProps.playing) {
        this._wavesurfer.play()
      } else {
        this._wavesurfer.pause()
      }
    }

    if (changed('volume')) { this._wavesurfer.setVolume(nextProps.volume) }
    if (changed('zoom')) { this._wavesurfer.zoom(nextProps.zoom) }

    // update audioRate
    if (nextProps.options && options.audioRate !== nextProps.options.audioRate) {
      this._wavesurfer.setPlaybackRate(nextProps.options.audioRate);
    }

    if (changed('responsive')) {
      if (nextProps.responsive) {
        window.addEventListener('resize', this._handleResize, false)
      } else {
        window.removeEventListener('resize', this._handleResize)
      }
    }
  }

  componentWillUnmount() {
    EVENTS.forEach(e => { this._wavesurfer.un(e) })
    this._wavesurfer.destroy()
    if (this.props.responsive) {
      window.removeEventListener('resize', this._handleResize)
    }
  }

  _secToPos = (sec) => 1 / this._wavesurfer.getDuration() * sec

  // receives position as a float 0-1 and transforms this to seconds
  _posToSec = (pos) => pos * this._wavesurfer.getDuration()

  // pos is in seconds, the 0-1 proportional position we calculate here …
  _seekTo = (sec) => {
    const { options } = this.props
    const pos = this._secToPos(sec)
    const seekMethod = options && options.autoCenter ? 'seekAndCenter' : 'seekTo'
    this._wavesurfer[seekMethod](pos)
  }

  render() {
    const { children, className } = this.props
    const childrenWithProps = children
      ? React.Children.map(children, child =>
        React.cloneElement(child, {
          wavesurfer: this._wavesurfer,
          isReady: this.state.isReady
        })
      )
      : false;
    return (
      <div>
        <div {...className && { className }} ref={c => { this.wavesurferEl = c; }} />
        {childrenWithProps}
      </div>
    )
  }
}

Wavesurfer.propTypes = {
  playing: PropTypes.bool,
  pos: PropTypes.number,
  audioFile: audioPropType,
  mediaElt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(window.HTMLElement)
  ]),
  audioPeaks: PropTypes.array,
  volume: PropTypes.number,
  zoom: PropTypes.number,
  responsive: PropTypes.bool,
  onPosChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  options: PropTypes.shape({
    audioRate: PropTypes.number,
    backend: PropTypes.oneOf(['WebAudio', 'MediaElement']),
    barWidth: (props, propName, componentName) => {
      const prop = props[propName];
      if (prop !== undefined && typeof prop !== 'number') {
        return new Error(`Invalid ${propName} supplied to ${componentName}
          expected either undefined or number`);
      }
      return null
    },
    cursorColor: PropTypes.string,
    cursorWidth: positiveIntegerProptype,
    dragSelection: PropTypes.bool,
    fillParent: PropTypes.bool,
    height: positiveIntegerProptype,
    hideScrollbar: PropTypes.bool,
    interact: PropTypes.bool,
    loopSelection: PropTypes.bool,
    mediaControls: PropTypes.bool,
    minPxPerSec: positiveIntegerProptype,
    normalize: PropTypes.bool,
    pixelRatio: PropTypes.number,
    progressColor: PropTypes.string,
    scrollParent: PropTypes.bool,
    skipLength: PropTypes.number,
    waveColor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(window.CanvasGradient)
    ]),
    autoCenter: PropTypes.bool
  })
};

Wavesurfer.defaultProps = {
  playing: false,
  pos: 0,
  options: WaveSurfer.defaultParams,
  responsive: true,
  onPosChange: () => {}
}

export default Wavesurfer