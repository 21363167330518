import { parseRef } from 'beanie-engine-api-js'
import { pipe, path, when } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { createSelector } from 'selectors/reselect'
import makeObjectByIdSelector from '../makeObjectByIdSelector'

const parentId = idSelector => createSelector(
  [makeObjectByIdSelector(idSelector)],
  pipe(
    path(['data', 'parent']),
    when(isNotNil, parseRef)
  )
)

export default parentId