import { identity } from 'ramda'
import { NODE_TYPES } from '../../../utils/Constants'
import createSearchHighlightDecorationsForLineText from './createSearchHighlightDecorationsForLineText'
import { EMPTY_ARRAY } from 'utils/object'

const DecorationFunctionByTypeOfNode = {
  // dir lines only have one text
  [NODE_TYPES.DIRECTOR_LINE]: createSearchHighlightDecorationsForLineText(identity),
  // in dialogue we only match the text part for the moment, skip actor name part
  [NODE_TYPES.DIALOGUE_LINE]: createSearchHighlightDecorationsForLineText(node => node.nodes.get(1)),
}

/**
 *
 */
const matchResultDecorationsFor = (editor, lineResults, node, lineId) => {
  const fn = DecorationFunctionByTypeOfNode[node.type]
  return fn ? fn(editor, lineResults, node, lineId) : EMPTY_ARRAY
}

export default matchResultDecorationsFor