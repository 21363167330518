/* eslint-disable no-useless-escape */
import { OBJECT_TYPES } from '../../Constants'
import { productionNormalize } from '../normalizers/productionNote'

export default {
  nodes: [
    {
      match: { object: OBJECT_TYPES.text, text: /^\<.*\>$/ }
    }
  ],
  normalize: productionNormalize
}