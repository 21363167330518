import { equals, flip, inc, lensPath, mergeDeepRight, modulo, over, path, pipe, prepend, when } from 'ramda'

import { updateQueryValue } from 'graphql/updates'
import notificationsQuery from 'api/queries/notifications.graphql'

export const NOTIFICATIONS_QUERY_PAGE_SIZE = 10

export const updateOnMarkAsRead = updateQueryValue(
  { query: notificationsQuery, variables: { page: 1, size: NOTIFICATIONS_QUERY_PAGE_SIZE } },
  (prev, data) => mergeDeepRight(prev, {
    // TODO: can notifications (name) be taken from the notificationsQuery variable?
    notifications: {
      totalUnreadCount: prev.notifications.totalUnreadCount - data.notificationMarkAsRead.length
    }
  })
)

export const updateOnNewNotification = (prevQueryResult, { subscriptionData: { data: { notification } } }) => {
  const nrOfItems = ['notifications', 'paging', 'nrOfItems']
  const needsNewPage = pipe(path(nrOfItems), inc, flip(modulo)(NOTIFICATIONS_QUERY_PAGE_SIZE), equals(1))
  return pipe(
    over(lensPath(nrOfItems), inc),
    over(lensPath(['notifications', 'paging', 'nrOfPages']), when(() => needsNewPage(prevQueryResult), inc)),
    over(lensPath(['notifications', 'list']), prepend(notification)),
    over(lensPath(['notifications', 'totalUnreadCount']), inc),
  )(prevQueryResult)
}
