import moment from 'moment'
import { pipe, reverse, map, path } from 'ramda'

import { sequence } from 'utils/promises'

import getChangeSetsQuery from 'api/queries/getChangeSetsQuery.graphql'

import { projectTimestamp, revisionId as revisionIdSelector } from 'selectors/project'
import { fetchPaginated } from '../../utils/graphql'
import { syncOnlineSessions } from './resyncSessions'
import { onSessionReconnected, onSessionReconnecting } from './session'

/**
 * Gets any changeSet that occurred since the last one we were aware of (or the project loading time)
 * and apply them locally. This makes our state up to date with the server.
 */
const resyncRevision = () => async (dispatch, getState, { getApolloClient: getClient, synchronizer }) => {
  // mark as reconnecting
  dispatch(onSessionReconnecting())

  const state = getState()

  await Promise.all([
    // sync bne objects retrieving any missing changeSets
    syncChangeSets(state, getClient, synchronizer),
    // also update online sessions !
    syncOnlineSessions(getClient(), dispatch)
  ])

  // mark as done
  dispatch(onSessionReconnected())
}

const syncChangeSets = async (state, getClient, synchronizer) => {
  const revisionId = revisionIdSelector(state)
  const after = projectTimestamp(state)

  const processChangeSetPage = _processChangeSetPage(synchronizer)
  // we fetch all changesets in order [NEWEST ... LATEST]
  const changeSets = await fetchAllChangeSets(_getChangeSets(getClient, revisionId, after))

  // now apply them !
  await processChangeSetPage(changeSets)
}

const fetchAllChangeSets = async getChangeSets => {
  const changeSets = []
  await fetchPaginated(getChangeSets, list => {
    changeSets.push(...list)
  })
  return changeSets
}


const getISOStringDate = obj => ((moment.isMoment(obj) || obj instanceof Date) ? obj.toISOString() : obj)
// page size is not so small as this is a per-reconnection operation and we wish there were few pages.
const _getChangeSets = (getClient, revisionId, after) => async (page = 1, size = 200) =>
  path(
    ['data', 'revisionWithId', 'changeSets'],
    await getClient().query({
      query: getChangeSetsQuery,
      variables: { revisionId, page, size, after: getISOStringDate(after) }
    })
  )


const _processChangeSetPage = synchronizer => list => pipe(
  reverse,
  // REVIEWME: because of ServerStore API (and furthermore StateSynchronizer) we are pushing changeSets
  // 1 by 1 (ok makes sense), but that triggers a pair of StateSync "SYNC_STARTED" + "SYNC_FINISHED" actions for each CS.
  // in this case while getting up to date it makes no sense. Seems better to batch it I guess
  map(changeSet => () => synchronizer.getServerStore().receiveFromServer(changeSet)),
  sequence
)(list)

export default resyncRevision
