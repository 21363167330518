import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { saveJobsState } from 'engine/actions/state'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ExtensionPoint from '../ExtensionPoints/ExtensionPoint'

import Button from 'components/Commons/Button'

const ChainExecutionsViewControls = ({ saveJobsStateAction }) => [
  <Button key="reload" type="reload" description="Refresh" onClick={saveJobsStateAction} />,
  <ExtensionPoint key="extensions" point={EXTENSION_POINTS.EXECUTION_PANEL_CONTROL} />
]

export default compose(
  connect(null, {
    saveJobsStateAction: saveJobsState
  })
)(ChainExecutionsViewControls)