import React from 'react'
import { graphql } from 'react-apollo'
import { mergeDeepRight } from 'ramda'
import { compose, withStateHandlers, withHandlers } from 'recompose'
import { Form, Input, Icon, Button, Alert } from 'antd'

import { parseGraphQLErrors } from 'utils/graphql'
import LinkButton from 'components/Commons/LinkButton'

import userForgotPasswordRequest from 'api/mutations/userForgotPasswordRequest.graphql'

import loginStyles from 'components/Login/Login.scss'

const FormItem = Form.Item

const ForgotPasswordForm = ({ waiting, error, toLogin, handleEmailOnChange, handleReset, form: { getFieldDecorator } }) => (
  <div className={loginStyles.body}>
    {!!error &&
      <Alert type="error" message={error} />
    }
    <h1>It&#39;s ok, we forgot things all the time</h1>
    <p>We will email you a link to reset your password.</p>
    <Form onSubmit={handleReset}>
      <FormItem>
        <div className={loginStyles.fieldWithLabel}>
          <Icon type="user" />
          {
            getFieldDecorator('name', {
              rules: [
                { type: 'email', message: 'Not a valid E-mail.' }, { required: true, message: 'E-mail is required.' },
              ],
            })(
              <Input
                type="text"
                placeholder="user@brunerhouse.com"
                onChange={handleEmailOnChange}
                ref={e => { if (e) { e.focus() } }}
              />
            )
          }
        </div>
      </FormItem>
      <FormItem>
        <Button loading={waiting} type="primary" htmlType="submit" className={loginStyles.loginButto}>
          Reset
        </Button>
      </FormItem>
    </Form>
    <div className={loginStyles.navigateLink}>
      <LinkButton type="primary" onClick={toLogin}>
        <Icon type="arrow-left" /> <span>Try log in again</span>
      </LinkButton>
    </div>
  </div>
)

export default compose(
  Form.create(),
  withStateHandlers(
    { email: undefined, error: false, waiting: false },
    {
      handleEmailOnChange: prev => ({ target: { value: email } }) => mergeDeepRight(prev, { email }),
      setError: prev => error => mergeDeepRight(prev, { error }),
      setWaiting: prev => waiting => mergeDeepRight(prev, { waiting })
    }
  ),
  graphql(userForgotPasswordRequest, { name: 'userForgotPasswordRequestMutation' }),
  withHandlers({
    handleReset: ({ setWaiting, setError, toLogin, userForgotPasswordRequestMutation, form, email }) => e => {
      e.preventDefault()
      form.validateFields(async err => {
        try {
          if (err) return
          setWaiting(true)
          await userForgotPasswordRequestMutation({ variables: { input: { email } } })
          toLogin({ sentResetLink: true })
        } catch (ex) {
          setError(parseGraphQLErrors(ex))
        } finally {
          setWaiting(false)
        }
      })
    }
  })
)(ForgotPasswordForm)
