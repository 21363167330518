import React from 'react'
import classNames from 'classnames'

import NodeText from '../../Commons/NodeText'

import styles from './ContainerTextView.scss'

const NameTitle = ({ object }) => (
  <div className={classNames(styles.containerNameTitle, styles[object.sys])}><NodeText node={object} /></div>
)

export default NameTitle