import React from 'react'
import Console from '../../../CommandLineSection/Console'

/**
 * The bottom panel that embeds a console
 */
const ConsolePanel = () => (
  <Console />
)

export default ConsolePanel