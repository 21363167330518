import React from 'react'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Spin, Alert } from 'antd'
import { prop, path } from 'ramda'

import { editorIdFor } from 'dom/dom'
import { withRef } from 'utils/recompose'
import { isComplexObject } from 'utils/object'

import withProjectPreference from 'hocs/withProjectPreference'
import { TextEditorAutoMergePreference } from 'model/project/preferences'
import withBooleanState from 'hocs/withBooleanState'

import { makeGetNodeMatches } from 'selectors/walklist'
import { pathFromProps } from 'selectors/props'

import { modelToText } from './modelToText/ModelSerializer'
import { handlers } from './utils/Handlers'

import { Creators } from 'actions/vm'

const { setTxOptions } = Creators

import { Direction } from '../TextView/CursorInfo'
import SlateTextEditor from './SlateTextEditor'
import RecoverMessage, { OVERRIDEN_MESSAGE } from './RecoverMessage'
import UpdateEditorContentFeeback from './UpdateEditorContentFeeback'

import styles from './ObjectTextEditor.scss'
import { connect } from 'react-redux'
import { projectTextReviewAccess } from 'security/project'
import secure from 'hocs/secure'

const NEW_CONTENT_MESSAGE_TIME = 3000


const getMessage = message => {
  // if its an object which is not a react component then its a descriptor, unpack
  if (isComplexObject() && !React.isValidElement(message)) {
    return ({ message })
  }
  return ({ message: message.message, type: message.type })
}

const ObjectTextEditor = props => (
  <div className={styles.objectTextEditor}>
    <Spin spinning={props.applyingChanges} wrapperClassName={styles.spinner}>
      {props.message && (
        <Alert className={styles.message} banner closable onClose={() => props.setMessage(undefined)} {...getMessage(props.message)} />
      )}
      <UpdateEditorContentFeeback className={styles.contentUpdatedMessage} newContentInComing={props.newContentInComing} />
      <SlateTextEditor className={styles.editor} {...props} />
    </Spin>
  </div>
)

export default compose(
  withProjectPreference({ autoMergeEnabled: TextEditorAutoMergePreference }),
  withBooleanState('newContentInComing', 'enableNewContentMessage', 'disableNewContentMessage'),
  withRef('editorRef'),
  withState('editorId', 'setEditorId', ({ object }) => editorIdFor(object.id)),
  withState('value', 'setValue', ({ object }) => modelToText(object)),
  withState('message', 'setMessage'),
  withState('dirty', 'setDirty', false),
  withState('initialized', 'setInitialized', false),
  withState('applyingChanges', 'setApplyingChanges', false),
  withHandlers(handlers),
  secure('hasProjectTextReviewAccess', projectTextReviewAccess),
  // walklist (used by decorations)
  connect(() => {
    const matchSelector = makeGetNodeMatches(pathFromProps(['object', 'id']))
    return (state, props) => ({
      searchMatch: matchSelector(state, props),
    })
  }, (dispatch, { hasProjectTextReviewAccess }) => ({
    _setTxOptions: () => dispatch(setTxOptions({
      use_dirty_interceptor: !hasProjectTextReviewAccess
    }))
  })),
  withHandlers({
    newContent: ({ enableNewContentMessage, disableNewContentMessage }) => () => {
      enableNewContentMessage()
      const timer = setTimeout(() => {
        disableNewContentMessage()
        clearTimeout(timer)
      }, NEW_CONTENT_MESSAGE_TIME)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props._setTxOptions()
    },
    componentDidUpdate(prevProps) {
      const { object } = prevProps
      const {
        setValue, setDirty, dirty,
        object: current, editorRef, newContent, cursorInfo,
        applyingChanges, setApplyingChanges, setMessage,
        autoMergeEnabled
      } = this.props

      let editor

      // object changed
      if (object !== current) {
        editor = editorRef().current
        const prevContent = modelToText(object)
        const content = modelToText(current)

        if (prop('id', current) !== prop('id', object)) {
          // Differents clip/choices
          setValue(content)
          setDirty(false)
          setMessage(undefined)
        // same object
        } else if (applyingChanges) {
          // Our own change (from this editor)
          editorRef().current.updateContent(content)
          setValue(editorRef().current.value)
          setApplyingChanges(false)
        } else {
          // change from outside (either other user or another component)
          const { value, merge, areMergeable, hasChanges } = editor
          if (hasChanges(prevContent)) {
            if (autoMergeEnabled && areMergeable(prevContent, value, content)) {
              // to current content applies the new content (MERGE)
              newContent()
              merge(prevContent, content)
              setValue(editor.value)
            } else {
              // as there changes but are not mergeables, we show the modal
              editorRef().current.updateContent(content)
              setValue(editorRef().current.value)
              setMessage({ type: 'warning', message: RecoverMessage(prevContent, value, OVERRIDEN_MESSAGE) })
            }
          } else {
            // in case of there no changes, we update the content by the new one
            newContent()
            editorRef().current.updateContent(content)
            setValue(editorRef().current.value)
            setDirty(false)
          }
        }
      }

      if (!prevProps.dirty && dirty && this.props.message) {
        setMessage(undefined)
      }

      editor = editorRef().current
      const { isBlurred, isFocused } = editor.currentSelection()

      if (editor && cursorAt(prevProps) !== cursorAt(this.props)) {
        // getting focus
        if (gotFocus(prevProps, this.props) && isBlurred) {
          const fn = cursorInfo.direction === Direction.DOWN ? 'traverseFromTop' : 'traverseFromBottom'
          editor[fn](cursorInfo.lineOffset)
        }

        // removing focus
        if (lostFocus(prevProps, this.props) && isFocused) {
          editor.blur()
        }
      }
    }
  })
)(ObjectTextEditor)

export const cursorAt = path(['cursorInfo', 'cursorAt'])
const gotFocus = (prevProps, props) => cursorAt(prevProps) !== props.object.id && cursorAt(props) === props.object.id
const lostFocus = (prevProps, props) => cursorAt(prevProps) === props.object.id && cursorAt(props) !== props.object.id
