import { compose, defaultProps, withPropsOnChange } from 'recompose'
import { identity, pipe } from 'ramda'
import { connect } from 'react-redux'

import whenRevisionModifiable from '../../../hocs/project/revision/whenRevisionModifiable'
import { _withNodeDragBehavior, dragActions } from './withNodeDragBehavior'
import { _withNodeDropBehavior, dropActions } from './withNodeDropBehavior'


/**
 * HOC to make a component behave as a drop target for nodes but also as a drag source
 */
const withNodeDragAndDrop = whenRevisionModifiable(
  // when enabled
  compose(
    // we use the private HOCs for withNodeXXXBehavior so we can make just 1 connect instead of 2
    connect(null, {
      ...dragActions,
      ...dropActions,
    }),
    _withNodeDragBehavior,
    ..._withNodeDropBehavior,
    withPropsOnChange(['connectDropTarget', 'connectDragSource'], ({ connectDropTarget, connectDragSource }) => ({
      dndWrapper: pipe(connectDropTarget, connectDragSource)
    }))
  ),
  // when disabled
  defaultProps({
    dndWrapper: identity,
    connectDragSource: identity,
    connectDropTarget: identity
  }),
)

export default withNodeDragAndDrop
