exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".passwordError__QNZHp input,.passwordError__QNZHp input:focus{border-color:rgba(255,0,0,.6) !important;-webkit-box-shadow:0 0 0 2px rgba(255,0,0,.1) !important;box-shadow:0 0 0 2px rgba(255,0,0,.1) !important}.extendButton__3B_i6{margin-top:1rem;float:right}", ""]);

// exports
exports.locals = {
	"passwordError": "passwordError__QNZHp",
	"extendButton": "extendButton__3B_i6"
};