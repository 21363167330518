import React from 'react'

import { Tabs, Icon } from 'antd'
import HomeProjectsContent from './projectView/HomeProjectsContent'
import HomeOrganizationsContent from './organizationView/HomeOrganizationsContent'

import styles from './HomeContent.scss'

const { TabPane } = Tabs

export default () => (
  <div className={styles.content}>
    <Tabs size="large">
      <TabPane tab={<span><Icon type="book" />Projects</span>} key="1">
        <HomeProjectsContent />
      </TabPane>
      <TabPane tab={<span><Icon type="bank" />Organizations</span>} key="2">
        <HomeOrganizationsContent />
      </TabPane>
    </Tabs>
  </div>
)

