exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".organizationNameColumn__oCJ7t a{padding:.1rem}.organizationNameColumn__oCJ7t a>*{margin:.1rem}.noDescription__5ZomR{font-style:italic;color:#9d9a9a}", ""]);

// exports
exports.locals = {
	"organizationNameColumn": "organizationNameColumn__oCJ7t",
	"noDescription": "noDescription__5ZomR"
};