exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".modalContent__1jLqj>*:not(:last-child){margin-bottom:1rem}.modalContent__1jLqj .ant-select{width:100%}.modalContent__1jLqj .openRightAway__kdfKS{font-size:.8rem;color:#959393}.modalRow__1KqXe{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.name__z2WJK .anticon{margin-left:.5rem}.rowTitle__xcG-s{-ms-flex:1 1 0%;flex:1 1 0%}.input__1i_yo{-ms-flex:2 1 0%;flex:2 1 0%}.helpIcon__3W0ru{padding-left:.5rem}.version__16S4b{-ms-flex:2 1 0%;flex:2 1 0%;font-weight:bold}.bold__-QDmR{font-weight:bold}.centeredTitle__1rjDL{text-align:center}", ""]);

// exports
exports.locals = {
	"modalContent": "modalContent__1jLqj",
	"openRightAway": "openRightAway__kdfKS",
	"modalRow": "modalRow__1KqXe",
	"name": "name__z2WJK",
	"rowTitle": "rowTitle__xcG-s",
	"input": "input__1i_yo",
	"helpIcon": "helpIcon__3W0ru",
	"version": "version__16S4b",
	"bold": "bold__-QDmR",
	"centeredTitle": "centeredTitle__1rjDL"
};