import { path, pick } from 'ramda'

export const ClipboardContentType = {
  Nodes: 'nodes',
  Lane: 'lane',
  NodeProperties: 'node_properties'
}

const clipboardManifest = ({ sourceRevId, sourceRevName, sourceProjId, sourceProjName }) => ({
  manifest: {
    // TODO: test and use
    sourceRevision: {
      id: sourceRevId,
      name: sourceRevName
    },
    sourceProject: {
      id: sourceProjId,
      name: sourceProjName
    },
    copyTime: new Date().toISOString(),
    copySource: 'tree-graph-component',
    version: '1.0'
  }
})

export const nodesClipboardContent = ({ headId, tailId, sourceRevId, sourceRevName, sourceProjName = '', sourceProjId = '', content = {}, context = {}, referencesMap = {}, provisionalRefs = {} }) => ({
  contentType: ClipboardContentType.Nodes,
  ...clipboardManifest({ sourceRevId, sourceRevName, sourceProjId, sourceProjName }),
  headId,
  tailId,
  content,
  context,
  // References Map = Maps an old id to a number of paths were it was used
  // {
  //   [referenced_id]: [
  //     { referencer_id, reference_path },
  //     { referencer_id, reference_path },
  //     ...
  //   ]
  // }
  referencesMap,
  // same shape as referencesMap
  // only difference is that these ones will only end up in the referencesMap
  // iff they are references TO AND FROM objects which EXIST ON context and/or content
  // this can only be told at the end of the crawling process, so we keep this here meanwhile.
  provisionalRefs
})

export const nodePropertiesClipboardContent = ({ content, quantity, sourceRevId, sourceRevName, sourceProjName = '', sourceProjId = '' }) => ({
  contentType: ClipboardContentType.NodeProperties,
  ...clipboardManifest({ sourceRevId, sourceRevName, sourceProjId, sourceProjName }),
  content,
  quantity
})

// nodesClipboard => nodesClipboardHeader
export const nodesClipboardHeader = ({ contentType, headId, tailId, manifest, content }) => ({
  contentType,
  manifest,
  headId,
  tailId,
  headNode: path([headId], content),
  tailNode: path([tailId], content),
})

export const nodePropertiesClipboardHeader = ({ contentType, manifest, quantity }) => ({
  contentType,
  manifest,
  quantity
})

export const laneClipboardContent = (lane, chainClipboards, roots, sourceRevId = '', sourceRevName = '', sourceProjId = '', sourceProjName = '') => ({
  contentType: ClipboardContentType.Lane,
  ...clipboardManifest({ sourceRevId, sourceRevName, sourceProjId, sourceProjName }),
  lane,
  chainClipboards,
  roots,
})

export const laneClipboardHeader = pick(['contentType', 'manifest'])