exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".leftPanel__2ppJp{padding-left:2rem;padding-right:2rem;padding-top:4rem;display:-ms-flexbox;display:flex;-ms-flex-align:start;align-items:flex-start;-ms-flex-direction:column;flex-direction:column}.leftPanel__2ppJp .info__1VvIW{padding-top:1rem}.leftPanel__2ppJp .ant-avatar{background-color:#d3d3d35c}.leftPanel__2ppJp .description__3GNGK .anticon{padding-left:.5rem}", ""]);

// exports
exports.locals = {
	"leftPanel": "leftPanel__2ppJp",
	"info": "info__1VvIW",
	"description": "description__3GNGK"
};