import React from 'react'
import { makeInferExprTypeSelector } from 'selectors/objects/facts'
import useSelectorMaker from 'hooks/selectors/useSelectorMaker'
import { TypeLabel } from './ExpressionTypeBadget'

/**
 * A component that knows how to type a fact expression
 */
const FactType = ({ expression }) => {
  const _type = useSelectorMaker(makeInferExprTypeSelector, [expression])

  return expression ? <TypeLabel type={_type} showSuperType /> : null
}

export default FactType