import { Sys } from 'beanie-engine-api-js'

import switchComponent from 'hocs/switchComponent'

import DefaultSearchResultItem from './DefaultSearchResultItem'
import LineSearchResultItem from './LineSearchResultItem'

const SearchResultItemContent = switchComponent({
  path: ['item', 'object', 'sys'],
  cases: {
    [Sys.line]: LineSearchResultItem,
  },
  otherwise: DefaultSearchResultItem
})

export default SearchResultItemContent