exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".DefaultFieldChange__u7z-G>span{font-weight:bold}.DefaultFieldChange__u7z-G .delta__1Hr7_{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.DefaultFieldChange__u7z-G .delta__1Hr7_ .separator__2izKl{margin-left:.5rem;margin-right:.5rem}.DefaultFieldChange__u7z-G .delta__1Hr7_ pre{background:#eee;border-radius:5px;padding:.2rem;min-width:1rem;line-height:1.5rem;min-height:1.8rem;margin:0}", ""]);

// exports
exports.locals = {
	"DefaultFieldChange": "DefaultFieldChange__u7z-G",
	"delta": "delta__1Hr7_",
	"separator": "separator__2izKl"
};