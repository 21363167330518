import { graphql } from 'react-apollo'
import { compose, withProps, withHandlers } from 'recompose'
import InvitesPanel from './InvitesPanel'
import paginatedQuery from 'hocs/paginatedQuery'

import { debounceMutation } from 'utils/graphql'
import userInvites from 'api/queries/userInvites.graphql'
import userInvitesDelete from 'api/mutations/userInvitesDelete.graphql'
import userInvitesResend from 'api/mutations/userInvitesResend.graphql'

const PAGE_SIZE = 10

const resendInviteDebouncing = debounceMutation(
  'The invitation was resent successfully.',
  'There was a problem sending the invitation email.'
)

const varsFrom = invite => ({ variables: { input: { _id: invite._id } } })

export default compose(
  paginatedQuery({
    query: userInvites,
    name: 'invitesData',
    pageSize: PAGE_SIZE,
  }),
  withProps({ pageSize: PAGE_SIZE }),
  graphql(userInvitesDelete, { name: 'deleteInviteMutation' }),
  graphql(userInvitesResend, { name: 'resendInviteMutation' }),
  withHandlers({
    resendInvite: ({ resendInviteMutation }) => invite => resendInviteDebouncing(resendInviteMutation, varsFrom(invite)),
    deleteInvite: ({ deleteInviteMutation, refreshItems }) => async invite => {
      await deleteInviteMutation(varsFrom(invite))
      await refreshItems()
    }
  })
)(InvitesPanel)