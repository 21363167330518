import React from 'react'

import { Upload, Icon, message } from 'antd'

import Avatar from './Avatar'

import styles from './AvatarUpload.scss'

const beforeUpload = file => {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isLt2M
}

export default class AvatarUpload extends React.Component {

  state = {
    loading: false
  }

  shouldComponentUpdate = (newProps, newState) => (
    this.props.title !== newProps.title
    || this.props.imageFile !== newProps.imageFile
    || this.props.shape !== newProps.shape
    || this.props.noImageIcon !== newProps.noImageIcon
    || this.props.size !== newProps.size
    || this.state.loading !== newState.loading
  )

  handleChange = async info => {
    const { onChange } = this.props
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      await onChange(info.file.name)
      this.setState({ loading: false })
    }
  }

  render = () => {
    const { loading } = this.state
    const { title, imageFile, uploadURL, size, shape, noImageIcon, disabled = false } = this.props

    const content = (
      <Avatar title={title} imageFile={imageFile} size={size} shape={shape} noImageIcon={noImageIcon} />
    )

    return disabled ? content : (
      <Upload
        name="file"
        listType="picture"
        className={styles.avatarUpload}
        showUploadList={false}
        action={uploadURL}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
      >
        <UploadAvatarContent loading={loading}>
          {content}
        </UploadAvatarContent>
      </Upload>
    )
  }
}

const UploadAvatarContent = ({ loading, children }) => (
  <div className={styles.avatarContainer}>
    {children}
    <div className={styles.changeAvatarOverlay}>
      <Icon type={loading ? 'loading' : 'camera-o'} style={{ fontSize: 48 }} />
      <div className={styles.changeAvatarText}>Change</div>
    </div>
  </div>
)