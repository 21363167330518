import { model } from 'beanie-engine-api-js'
import describeBNEAction from '../../../../__tests__/utils/describeBNEAction'
import { playbackIndex } from '../../../../selectors/playback'
import clipHandler from './clip'

const { factory: { objects: { withObjects, clip, withLines } } } = model

describeBNEAction('host / playback / handlers / clip', ({ getAPI, createIntegrationContext }) => {

  it('should record the node state with the first line id', () => {
    const { dispatch, getState } = createIntegrationContext({
      objects: withObjects({
        CLIP: [clip, withLines('L1', 'L2')]
      })
    })
    clipHandler.onBeginPresentNode(getAPI().obj.get('CLIP'), 'PLAYBACK_ID', dispatch)

    expect(playbackIndex(getState())).toEqual({
      PLAYBACK_ID: {
        state: {
          lineId: 'L1'
        }
      }
    })
  })

  it('should not fail if the clip has no lines', () => {
    const { dispatch, getState } = createIntegrationContext({
      objects: withObjects({
        CLIP: [clip]
      })
    })
    clipHandler.onBeginPresentNode(getAPI().obj.get('CLIP'), 'PLAYBACK_ID', dispatch)

    expect(playbackIndex(getState())).toEqual({
      PLAYBACK_ID: {
        status: 'not presenting'
      }
    })
  })

})