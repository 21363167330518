import search from './search'

/**
 * Given a search and optionally a visitor it does the search over all the objects.
 * Returns { search, result } the original search + the result (list of SearchResult)
 */
const performSearch = (aSearch, visitor) => (dispatch, getState) => {
  const result = search(visitor)(getState, aSearch)
  return {
    search: aSearch,
    result
  }
}

export default performSearch