import { makeSelector } from '@taskworld.com/rereselect'
import { path, pipe, nth, always, ifElse, isEmpty, when, prop, pathOr } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { DEFAULT_LOCALE, schema, Sys, parseRef, model } from 'beanie-engine-api-js'
import { EMPTY_STRING } from 'utils/string'

import { resolver } from './rereselect'

const { types: { object: { Paths } } } = model

export const LABEL_REF_ERROR = 'REF!'

const resolvePath = aPath => (obj, resolve, nestingLevel) => {
  const ref = path(aPath, obj)
  if (!ref) return [undefined, resolve, nestingLevel]
  const resolved = resolve(ref)
  return [resolved, resolve, nestingLevel]
}

export const LabelType = {
  OWN: 'own',
  COMPOSITION: 'composition',
  REFERENCE: 'reference'
}

const ownLabel = value => ({ type: LabelType.OWN, value })
const referencedLabel = (value, to, error) => ({ type: LabelType.REFERENCE, value, to, error })
const compositionLabel = value => ({ type: LabelType.COMPOSITION, value })

const MAX_NESTED_LEVEL_TO_CONCLUDE_CIRCULARITY = 50
const MAX_RECURSIVITY_REACHED_ERROR = new Error()

// recursive fn
// (object, resolve, nestingLevel) => { value: String, type: LabelType }
export const getLabel = (object, resolve, nestingLevel = 0) => {
  if (nestingLevel >= MAX_NESTED_LEVEL_TO_CONCLUDE_CIRCULARITY) {
    throw MAX_RECURSIVITY_REACHED_ERROR
  }
  const provider = labelProviders[object.sys]
  try {
    return provider ? provider(object, resolve, nestingLevel) : name(object)
  } catch (err) {
    if (err === MAX_RECURSIVITY_REACHED_ERROR) {
      throw new Error(`Detected circularity in model computing label for ${object.id}`)
    } else {
      throw err
    }
  }
}

const makeObjectLabelSelector = id => makeSelector(query => {
  const resolve = resolver(query)
  const resolved = resolve(id)
  return resolved ? getLabel(resolved, resolve, 0) : undefined
})

const trying = (...providers) => (object, resolve, nestingLevel) => {
  for (const provider of providers) {
    const r = provider(object, resolve, nestingLevel)
    if (r !== undefined) {
      return r
    }
  }
  return undefined
}

//
// resolver functions utils
//

const _transitive = aPath => pipe(
  resolvePath(aPath),
  ifElse(
    pipe(nth(0), isNotNil),
    ([obj, resolve, nestingLevel]) => getLabel(obj, resolve, nestingLevel + 1),
    always(undefined)
  )
)
const composed = aPath => pipe(
  _transitive(aPath),
  when(isNotNil, pipe(prop('value'), compositionLabel))
)

const referenced = aPath => (object, resolve, nestingLevel) => {
  const refVal = path(aPath, object)
  const refId = parseRef(refVal)

  if (!refId) return undefined
  if (schema.isRef(object.sys, aPath) && !resolve(refId)) return referencedLabel(LABEL_REF_ERROR, refId, true)

  const r = _transitive(aPath)(object, resolve, nestingLevel)
  if (!r) return undefined
  return referencedLabel(r.value, refId)
}

const own = fn => (object, resolve, nestingLevel) => {
  const r = fn(object, resolve, nestingLevel)
  return r !== undefined ? ownLabel(r) : undefined
}

const name = obj => {
  const aName = path(['data', 'name'], obj)
  return aName ? ownLabel(aName) : undefined
}

//
// declarations for each type of sys
//

const labelProviders = {
  // simple cases
  [Sys.language_resource]: own(path(['data', 'text'])),
  [Sys.project]: own(always('Project')),
  [Sys.actor]: own(path(['data', 'actorName'])),
  [Sys.truth_table]: own(path(['data', 'headers', 0, 'source'])),
  [Sys.truth_table_row]: own(path(['data', 'cells', 0, 'source'])),

  // referencing
  [Sys.jump]: referenced(['data', 'target']),

  // referencing by convention
  [Sys.conditional]: trying(
    referenced(['data', 'name']),
    name,
  ),
  [Sys.condition]: trying(
    referenced(['data', 'state_node']),
    name,
  ),
  [Sys.stop]: trying(
    referenced(['data', 'name']),
    name,
    own(always(' ')),
  ),

  // transitives
  [Sys.take]: composed(['data', 'locales', DEFAULT_LOCALE]),
  [Sys.line]: composed(['data', 'selected_take']),
  [Sys.clip]: trying(
    // '' -> undefined for backwards compat
    pipe(name, when(isEmpty, always(undefined))),
    composed(['data', 'lines', 0]),
  ),
  [Sys.choice]: composed(['data', 'line']),

  [Sys.action2]: own(pathOr(EMPTY_STRING, Paths.action2.source)),

}

export default makeObjectLabelSelector