import React from 'react'

import { Modal } from 'antd'
import { groupCommands } from 'shortcuts/keymap'
import * as all from 'commands/all'
import ShortCuts from './ShortCuts'

import styles from './ShortcutsModal.scss'

const ShortcutsModal = () => {
  Modal.info({
    title: 'Keyboard Shortcuts',
    content: (
      <ShortCuts groupedCommands={groupCommands(all)} />
    ),
    onOk() {},
    className: styles.modalContainer,
    closable: true
  })
}

export default ShortcutsModal