import React from 'react'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { selectNode } from 'actions/nodes'
import { SimpleNodeContainer } from 'components/Commons/SimpleNode'
import { isIt } from './Extensions/TreeUtils'

import styles from './ExprEditor.scss'

const NodeComponentRef = ({ nodeId, container, itValue }) => {
  if (!container) return null
  const dispatch = useDispatch()

  const id = isIt(nodeId) ? itValue : nodeId

  return ReactDOM.createPortal(
    id ? <SimpleNodeContainer
      className={styles.nodeReference}
      nodeId={id}
      onClick={() => dispatch(selectNode(nodeId))} /> : nodeId,
    container
  )
}

export default NodeComponentRef