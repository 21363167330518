import { contains, equals, ifElse, identity, append, without, concat, differenceWith, take, drop } from 'ramda'
import { EMPTY_ARRAY } from './object'

/* (e => array) => array */
export const concatIfNotInclude = e => ifElse(contains(e), identity, append(e))

export const getRelativeElement = (array, element, offset) => (
  array[array.findIndex(equals(element)) + offset]
)

const removeAppearance = indexFinderName => array => element => {
  const idx = array[indexFinderName](element)
  if (idx >= 0) {
    array.splice(idx, 1)
  }
}
export const removeFirstAppearance = removeAppearance('indexOf')
export const removeLastAppearance = removeAppearance('lastIndexOf')

const mapIfLast = fn => (e, i, list) => (i === list.length - 1 ? fn(e) : e)
export const mapLast = fn => array => array.map(mapIfLast(fn))

export const toggleElement = e => list => (list.includes(e) ? without([e], list) : concat(list, [e]))

export const compareBy = lens => (leftList, rightList) => {
  // TODO: not performant. Merge this three computations with a single utility function
  // that iterates just once (reduce) and computes all three sets (justInA, intersection, justInB)
  const comparator = (a, b) => lens(a) === lens(b)
  const onlyInLeft = differenceWith(comparator, leftList, rightList)
  const onlyInRight = differenceWith(comparator, rightList, leftList)
  const intersection = leftList.reduce((acc, left) => {
    const leftValue = lens(left)
    const right = rightList.find(r => lens(r) === leftValue)
    if (right) {
      acc.push([left, right])
    }
    return acc
  }, [])

  return [onlyInLeft, intersection, onlyInRight]
}

export const nextIndex = (list, i, delta) => {
  const newIndex = i + delta
  return newIndex < 0 ? list.length - 1 : (newIndex >= list.length ? newIndex % list.length : newIndex)
}

export const lengthIsGreaterThan = limit => array => (array || EMPTY_ARRAY).length > limit

export const iteratorToArray = iterator => {
  const array = []
  let r;
  do {
    r = iterator.next()
    if (r.value !== undefined) array.push(r.value)
  } while (!r.done)
  return array
}

export const previousAndNextElements = index => elements => [
  take(index, elements),
  drop(index + 1, elements)
]

export const clear = array => { array.length = 0 }
