import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Query } from 'react-apollo'
import { ascend, concat, descend, sortWith, when, prop } from 'ramda'
import { Input, Icon } from 'antd'
import classnames from 'classnames'
import { withHandlers } from 'recompose'

import savegamesSummaryQuery from 'api/queries/savegamesSummary.graphql'
import { projectId } from 'selectors/project'
import { currentSaveGame as currentSaveGameSelector, isDirty as isDirtySelector, canDeleteSaveGame, canSaveAsSaveGame } from 'selectors/state'
import { saveState, deleteState } from 'actions/state'
import { loadSavegame } from 'engine/actions/state'

import NewSaveGameModal from 'components/StateToolBar/NewSaveGameModal'
import IconButton from 'components/Commons/IconButton'
import { ModalConsumer } from 'components/Commons/Modal'
import FilteredDropdown from 'components/PlaybackView/PlaybackControls/PresetControls/FilteredDropdown.jsx'
import { EMPTY_ARRAY } from '../../utils/object'
import RelativeTime from '../Commons/RelativeTime'

import commonStyles from '../Commons/Commons.scss'
import styles from './StateToolBar.scss'

/* eslint import/no-webpack-loader-syntax: 0 */
import SaveAsIcon from '-!svg-react-loader!./icons/saveas.svg'

// TODO: handle error with a notification message !
const StateToolbar = ({ canDelete, currentSaveGame, project: gameId, canSave, canSaveAs, onSelect, onDelete, onSave, onSaveAs }) => (
  <div className={styles.toolbar}>
    <Query
      query={savegamesSummaryQuery}
      variables={{ gameId }}
      skip={!gameId}
    >
      {({ loading, error, data }) => {
        const savegames = data.savegames ?
          sortWith([descend(prop('updatedAt')), ascend(prop('slot'))], data.savegames)
          : EMPTY_ARRAY;
        return (
          <ModalConsumer>
            {
              ({ showModal }) => (
                <FilteredDropdown
                  theme="dark"
                  elements={savegames}
                  error={error}
                  loading={loading}
                  selected={currentSaveGame}
                  elProp="slot"
                  renderElement={savegame => (
                    <div>{savegame.slot} <RelativeTime className={commonStyles.ShortTimestamp} date={savegame.updatedAt} /></div>
                  )}
                  onSelected={onSelect}
                  placeholder="No Savegame"
                  selectedActions={
                    canSave || canDelete ?
                      [
                        <IconButton key="save" title="Save" type="save" disabled={!canSave} onClick={() => { onSave(showModal) }} className={classnames(styles.button, { [styles.disabled]: !canSave })} />,
                        <IconButton key="saveas" title="Save As..." component={SaveAsIcon} disabled={!canSaveAs} onClick={() => { onSaveAs(showModal) }} className={classnames(styles.button, { [styles.disabled]: !canSaveAs })} />,
                        <IconButton key="delete" title="Delete" type="delete" disabled={!canDelete} onClick={() => onDelete(currentSaveGame)} className={classnames(styles.button, { [styles.disabled]: !canDelete })} />
                      ]
                      : undefined
                  }
                >
                  <div className={styles.dropdownInput}>
                    { /* TODO: think about replacing * with bolder font style or color */ }
                    <Input
                      readOnly
                      suffix={<Icon className={styles.down} type="down" />}
                      value={currentSaveGame === undefined ? 'No Savegame' : when(() => canSave, concat('* '), currentSaveGame.slot)}
                    />
                  </div>
                </FilteredDropdown>
              )
            }
          </ModalConsumer>
        )
      }}
    </Query>
  </div>
)

export default compose(
  connect(state => ({
    project: projectId(state),
    currentSaveGame: currentSaveGameSelector(state),
    canSave: isDirtySelector(state), // if isDirty => can save SaveGame
    canDelete: canDeleteSaveGame(state),
    canSaveAs: canSaveAsSaveGame(state)
  }), {
    onSelect: loadSavegame,
    onSave: saveState,
    onDelete: deleteState,
  }),
  withHandlers({
    onSave: ({ currentSaveGame, onSave }) => showModal => {
      if (currentSaveGame) {
        onSave(currentSaveGame)
      } else {
        showModal(NewSaveGameModal, { modalAction: 'Create' })
      }
    },
    onSaveAs: () => showModal => showModal(NewSaveGameModal, { modalAction: 'Save as' })
  })
)(StateToolbar)