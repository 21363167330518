import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { token as tokenSelector } from 'selectors/auth'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { urlForImage } from 'model/resources'

/**
 * Returns the API url for the given image path.
 * It generates an URL with the token and path to revision.
 * If you provide `srcImage` then it returns that value as it is.
 * Otherwise it uses `path` and `urlResolver` to compute the URL (+ token)
 */
const useProjectImage = (path, srcImage, urlResolver = urlForImage) => {
  const revisionId = useSelector(revisionIdSelector)
  const token = useSelector(tokenSelector)

  return useMemo(() => srcImage || urlResolver(token, revisionId, path), [srcImage, path, token, revisionId, urlResolver])
}

export default useProjectImage