import React from 'react'
import { connect } from 'react-redux'
import EditableValue from './EditableValue'
import { parseRef, ref } from 'beanie-engine-api-js'
import { containsIgnoreCase, truncate as _truncate, isEmptyOrNull, isUUID } from 'utils/string'
import { EMPTY_ARRAY } from 'utils/object'
import { AutoComplete, Tooltip, Icon } from 'antd'
import { getProviderFor } from 'model/label'
import debounce from 'lodash.debounce'
import { compose, withPropsOnChange } from 'recompose'

import { objectLabelList, objectLabelIndex } from 'selectors/labels'

import HighlightText from 'components/Commons/HighlightText'

const { Option } = AutoComplete

import styles from './ObjectRefValue.scss'

const truncateId = id => _truncate(id, 5)


class ObjectRefValue extends EditableValue {

  saveOnEnter = false

  onSelect = newValue => {
    const { onValueChanged } = this.props
    onValueChanged(ref(newValue))
  }

  onSearch = debounce(value => {
    this.setState({
      searchText: value,
      dataSource: isEmptyOrNull(value) ? EMPTY_ARRAY : this.findMatches(value)
    })
  }, 500)

  findMatches = value => {
    const { objects, index } = this.props
    if (isUUID(value)) {
      const resolved = index[value]
      return resolved ? [resolved] : EMPTY_ARRAY
    } else {
      return objects.filter(this.matches(value))
    }
  }

  matches = text => o => o.id.startsWith(text) || containsIgnoreCase(o.name, text)

  renderForEditing() {
    const { value, onBlur } = this.props
    const { dataSource } = this.state
    return (
      <AutoComplete
        defaultValue={value}
        dataSource={(dataSource || []).map(::this.renderOption)}
        style={{ width: 200 }} // TODO: how to style this from outside? , height: 15
        onSelect={this.onSelect}
        onSearch={this.onSearch}
        size="small"
        placeholder="Type name or id"
        optionLabelProp="text"
        onBlur={onBlur}
      />
    )
  }

  renderOption(item) {
    const provider = getProviderFor(item.sys)
    return (
      <Option key={`${item.id}`} className={styles.option} text={`${item.id}`}> 
        <Tooltip title={item.sys}>
          <Icon type={provider.icon} style={{ color: provider.color }} />
        </Tooltip>
        <span className={styles.id}>{truncateId(item.id)}</span>
        <HighlightText text={item.name} search={this.state.searchText} />
      </Option>
    )
  }

  renderForDisplaying() {
    const { onNavigateRef, truncate = true } = this.props
    const { value } = this.state

    const id = parseRef(value)
    const truncated = truncate ? truncateId(id) : value
    return onNavigateRef ? (
      <Tooltip title={`Go to ${id}`}>
        <a
          role="link"
          tabIndex={0}
          onClick={() => onNavigateRef(id)}
        >
          {truncated}
        </a>
      </Tooltip>
    ) : truncated
  }

}

export default compose(
  connect(state => ({
    labels: objectLabelList(state),
    index: objectLabelIndex(state),
  })),
  withPropsOnChange(
    ['labels', 'filter'],
    ({ labels, filter }) => ({
      objects: filter ? labels.filter(filter) : labels
    })
  ),
)(ObjectRefValue)

