import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import { onlyInProduction } from './services-enabling'
import { isDev } from 'utils/browser'
import { user as userSelector } from 'selectors/auth'
import { sessionId as sessionIdSelector } from 'selectors/project'

const service = ({ reduxStore: store }) => {
  Sentry.init({
    /* eslint no-undef: 0 */
    release: `v${VERSION}`,
    environment: isDev() ? 'dev' : 'production',
    dsn: 'https://fc54eefe169f435788d8507f7576741b@sentry.io/1725498',

    integrations: [new Integrations.BrowserTracing()],
    // @see https://docs.sentry.io/platforms/javascript/configuration/sampling/
    tracesSampleRate: isDev() ? 1.0 : 0.25,
  })

  // restore user scope from store
  const state = store.getState()
  const user = userSelector(state)

  if (user) {
    const sessionId = sessionIdSelector(state)
    setUserInScope(user, sessionId)
  }
}

service.enabledOn = onlyInProduction
// service.enabledOn = () => true

service.onAuthorize = ({ user, sessionId }) => {
  setUserInScope(user, sessionId)
}

service.handleError = (error, errorInfo) => {
  if (!service.enabledOn()) return;
  Sentry.withScope(scope => {
    Object.keys(errorInfo).forEach(key => {
      scope.setExtra(key, errorInfo[key])
    })
    Sentry.captureException(error)
  })
}

export default service

// helpers

const setUserInScope = (user, sessionId) => {
  Sentry.configureScope(scope => {
    scope.setUser({
      username: `${user.firstName} ${user.lastName}`,
      email: user.email,
      id: user._id
    })
    scope.setExtra('sessionId', sessionId)
  })
}