import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { head } from 'ramda'
import { DEFAULT_LOCALE } from 'beanie-engine-api-js'

import { denormalizing } from 'hocs/denormalizing'
import { createObjectByPlaybackIdSelector } from 'selectors/objects'
import { actorAndResources as actorAndResourcesSelector, getCurrentLineIndex, hasLines } from 'selectors/clip'
import { Creators } from 'actions/vm'
import { createIsAudioDirtySelector } from 'selectors/objectsMeta'
import { pathFromProps } from 'selectors/props'

import withSinglePreference from '../../../../../hocs/withSinglePreference'
import Preferences from '../../../../../preferences/Preferences'

const { finishClipLine: finishClipLineAction, finishClip: finishClipAction } = Creators

const CurrentClipPlaybackContainerCreator = compose(
  connect(() => {
    const objectSelector = createObjectByPlaybackIdSelector()
    const isAudioDirtySelector = createIsAudioDirtySelector(pathFromProps(['node', 'state', 'lineId']))
    return (state, props) => ({
      playbackCurrentObject: objectSelector(state, props),
      isAudioDirty: isAudioDirtySelector(state, props)
    })
  }, {
    finishClipLine: finishClipLineAction,
    finishClip: finishClipAction
  }),
  denormalizing({
    prop: 'playbackCurrentObject',
    recurse: true
  }),
  withSinglePreference('playDirectorComments', Preferences.Playback.playDirectorComments),
  withHandlers({
    onFinishedLine: ({ playbackCurrentObject, finishClipLine }) => node => {
      const { playbackId, state: { lineId } } = node
      finishClipLine(playbackId, playbackCurrentObject, lineId)
    },
    finishClip: ({ finishClip, node }) => () => {
      const { playbackId } = node
      finishClip(playbackId)
    }
  })
)

export default CurrentClipPlaybackContainerCreator

// utils

export const getActorAndResources = (node, playbackCurrentObject) => {
  const index = getCurrentLineIndex(playbackCurrentObject, node.state.lineId)
  if (!hasLines(playbackCurrentObject) || index < 0) return { actor: null, langRes: null }
  const actorAndLangRes = head(actorAndResourcesSelector(playbackCurrentObject, DEFAULT_LOCALE, index, index + 1))
  const [actor, langRes, , isDisabled, disabledSubtitles] = actorAndLangRes
  return { actor, langRes, isDisabled, disabledSubtitles }
}
