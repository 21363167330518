import { isNode } from 'beanie-engine-api-js'
import { allPass, anyPass, complement, pathEq, propEq } from 'ramda'
import QueryType from './QueryType'
import lineHasMarkupResolver from './resolvers/lineHasMarkupResolver'

/**
 * Translates from a query which is a data-representation of a filtering operation
 * into a Predicate, which is an executable piece that knows how to evaluate an object.
 * This is like the interpreter rule for queries.
 * Creating resolvers allows to do some computations search-wide globally when
 * starting to search, and then reuse that in resolvers.
 * Still this could be refactor to be more like a classic-interpreter
 * instead of this more high-order function based. I'm not 100% sure.
 * This design just "emerged".
 *
 * Query -> Predicate
 *
 * Predicate = Object -> Boolean
 */
export const queryToPredicate = query => {
  const resolver = resolverFactories[query.type]
  if (!resolver) throw new Error(`Unknown query type [${query.type}] (query ${JSON.stringify(query)})`)
  return resolver(query)
}

export default queryToPredicate

//
//
//

const compositeResolver = fn => ({ queries }) => fn(
  queries.map(q => queryToPredicate(q))
)

const andResolver = compositeResolver(allPass)
const orResolver = compositeResolver(anyPass)

const notResolver = ({ query }) => complement(queryToPredicate(query))

const propEqResolver = ({ name, value }) => propEq(name, value)
const pathEqResolver = ({ path, value }) => pathEq(path, value)

const idResolver = ({ text }) => ({ id }) => id.toLowerCase().startsWith(text)

const isBNENodeResolver = () => ({ sys }) => isNode(sys)

// registry

const resolverFactories = {
  // basic types
  [QueryType.AND]: andResolver,
  [QueryType.OR]: orResolver,
  [QueryType.NOT]: notResolver,

  //
  [QueryType.PROP_EQ]: propEqResolver,
  [QueryType.PATH_EQ]: pathEqResolver,
  [QueryType.ID]: idResolver,
  [QueryType.IS_BNE_NODE]: isBNENodeResolver,

  [QueryType.LINE_HAS_MARKUP]: lineHasMarkupResolver,
}
