import { createSelector } from 'reselect'
import { isNil, path } from 'ramda'

import { takes as takesSelector, lines as linesSelector } from 'selectors/objects'
import { objectsIndex } from 'selectors/apollo'
import { model } from 'beanie-engine-api-js'
import dirtiedByNextValue from '../model/changeSet/dirtiedByNextValue'
import { languageResourceForLine } from './lines'

const { types: { object: { Paths }, metadata: { DirtyMetadata } } } = model

const AUDIO_PATH = Paths.language_resource.audio.join('.')

// exported for test
export const isPropDirty = (obj, context) => propPathString => {
  const propValue = path(propPathString.split('.'), obj)
  if (propValue === undefined) {
    // no value cannot be dirty !
    return false
  }

  // If next (calculated) value is newer than the current stored one then its now dirty
  const dependencyCurrentHigherTimestamp = dirtiedByNextValue(obj, propPathString, context)
  const propLastDependencyTimestamp = DirtyMetadata.propNameToMetaValue(obj, propPathString)

  // has no recorded timestamp i.ex. manually marked as dirty (cleared)
  if (!isNil(dependencyCurrentHigherTimestamp) && isNil(propLastDependencyTimestamp)) {
    return true
  }

  return obj && dependencyCurrentHigherTimestamp > propLastDependencyTimestamp
}

export const createIsObjectDirtySelector = (idFromProps, dirtyPathFromProps, contextSelectors) => createSelector(
  [idFromProps, dirtyPathFromProps, objectsIndex].concat(contextSelectors),
  (objId, propToCheck, index, ...selectors) =>
    objId && index[objId] && isPropDirty(index[objId], { index, selectors })(propToCheck)
)

export const isLangResAudioDirty = (langRes, index, takes, lines) => {
  const context = { index, selectors: [takes, lines] }
  return isPropDirty(langRes, context)(AUDIO_PATH)
}
export const isLineAudioDirty = (takes, lines, lineId, index) => {
  const langRes = languageResourceForLine(lineId, index)
  if (isNil(langRes)) return false
  return isLangResAudioDirty(langRes, index, takes, lines)
}
export const createIsAudioDirtySelector = idFromProps => createSelector(
  [takesSelector, linesSelector, idFromProps, objectsIndex],
  isLineAudioDirty
)
