import { merge, propEq, adjust, flip, pipe, over, lensProp, append } from 'ramda'
import { model } from 'beanie-engine-api-js'

import { removeRootFromLane, addRootToLane, updateProjectLanes } from 'engine/actions/nodes'

import { lanesFromProject, newLaneName as newLaneNameSelector } from 'selectors/objects'
import { revisionId } from 'selectors/project'

import breakScene from './node/breakScene'

import { toBNEAction } from './utils'
import { elementRenamed } from 'actions/view'

const { types: { projectObject: { getLane, moveLane } } } = model

const _setLane = (item, laneName) => async (api, dispatch, getState) => {
  updateProjectLanes(pipe(
    removeRootFromLane(item.id),
    addRootToLane(laneName, item.id)
  ))(api, dispatch, getState)

  api.obj.get(item.id).set_parent(undefined)
}

export const setLane = toBNEAction(_setLane, 'Move to lane')

export const updateLane = (name, fn) => (api, dispatch, getState) => {
  const lanes = lanesFromProject(getState())
  return updateProjectLanes(
    adjust(
      lanes.findIndex(propEq('name', name)),
      fn
    )
  )(api, dispatch, getState)
}

export const renameLane = toBNEAction(
  (prevName, newName) => (api, dispatch, getState) => {
    const lanes = lanesFromProject(getState())

    // exit if renaming to an existing name
    if (lanes.some(propEq('name', newName))) return false

    updateLane(prevName, flip(merge)({ name: newName }))(api, dispatch, getState)

    dispatch(elementRenamed(revisionId(getState()), prevName, newName))
    return true

  }, 'Rename lane'
)

export const _breakToNewLane = o => (api, dispatch, getState) => {
  const newLaneName = newLaneNameSelector(getState())
  breakScene(o.id)(api)
  _setLane(o, newLaneName)(api, dispatch, getState)
}
export const breakToNewLane = toBNEAction(_breakToNewLane, 'Break to new lane')

export const _reorderLane = (laneName, beforeName, insertAfter = false) => (api, dispatch, getState) => {
  const laneToMove = getLane(laneName, lanesFromProject(getState()))
  return updateProjectLanes(moveLane(laneToMove, beforeName, insertAfter))(api, dispatch, getState)
}

export const reorderLane = toBNEAction(_reorderLane, 'Reorder Lane')

export const appendRootToLane = (lane, rootId) => updateLane(
  lane,
  over(lensProp('roots'), append(rootId))
)