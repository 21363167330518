import { buildConfigDeleteExt } from 'actions/project'
import { isBeingUsed } from './onEngineExtensionAdded'

const onEngineExtensionDeleted = ({ extension, version, vm }) => (dispatch, getState, { synchronizer }) => {
  if (!isBeingUsed(getState, extension, version, vm)) return

  const api = synchronizer.getEngineStore().getEngine().getAPI()
  // they must not modify objects on uninstall !
  api.extension.uninstall(extension)

  dispatch(buildConfigDeleteExt(extension))
}

export default onEngineExtensionDeleted
