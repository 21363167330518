import React from 'react'
import EditableValue from './EditableValue'
import { Slider } from 'antd'
import { formatWith2Decimals } from 'utils/numbers'

import styles from './NumberSliderValue.scss'

export class NumberSliderValue extends EditableValue {

  handleChange = (number) => {
    this.setState({ value: number })
  }

  unit() {
    const { unit } = this.props
    return unit || ''
  }

  renderForEditing = () => this.renderControl(false)

  renderForDisplaying = () => this.renderControl(true)

  renderControl(disabled) {
    const { min, max, step } = this.props
    const { value } = this.state
    return (
      <div className={styles.slider}>
        <Slider 
          min={min}
          max={max}
          step={step}
          onChange={this.handleChange}
          value={value}
          disabled={disabled}
          tipFormatter={formatWith2Decimals}
        />
        <span>{this.unit()}</span>
      </div>
    )
  }

}

const sliderValueFactory = (min, max, step = 1, unit = '') => props => {
  return <NumberSliderValue {...props} unit={unit} min={min} max={max} step={step} />
}
export default sliderValueFactory
