import React from 'react'

import { Checkbox, Select } from 'antd'
import { map } from 'ramda'

import { ChangeSetDescKind } from 'model/ChangeSet'

import UserAvatar from '../RevisionSessions/UserAvatar'
import SearchBox from '../Commons/SearchBox'

import styles from './HistoryFilterPanel.scss'
import UserName from '../Commons/UserName'

const { Option } = Select
const { Group: CheckboxGroup } = Checkbox

const EXCLUDE_BY_KIND_OPTS = [
  { label: 'TTS Audio Synth', value: ChangeSetDescKind.TtsChangeSet }
]

const HistoryFilterPanel = ({ onSearchByDescription, editors = [], onSelectEditor, onDeselectEditor, onBlurDescription, onExcludeByDescKind, excludeByDescKind }) => (
  <div>
    <h1 className={styles.title}>Filters</h1>
    <div className={styles.filters}>
      <Filter title="Description">
        <div className={styles.searchBox}>
          <SearchBox
            onSearch={onSearchByDescription}
            onBlur={({ currentTarget: { value } }) => onBlurDescription(value)} />
        </div>
      </Filter>
      <Filter title="Author/s">
        <Select
          mode="multiple"
          showSearch
          filterOption
          style={{ width: '100%' }}
          dropdownMatchSelectWidth
          placeholder="Select an author"
          defaultValue={[]}
          onSelect={onSelectEditor}
          onDeselect={onDeselectEditor}
          optionLabelProp="label"
          optionFilterProp="label">
          {map(AuthorToOption, editors)}
        </Select>
      </Filter>
      <Filter title="Exclude">
        <CheckboxGroup value={excludeByDescKind} options={EXCLUDE_BY_KIND_OPTS} onChange={onExcludeByDescKind} />
      </Filter>

    </div>
  </div>
)

const AuthorToOption = editor => {
  const { _id } = editor
  const fullName = UserName({ user: editor })
  return (
    <Option value={_id} label={fullName} key={_id}>
      <UserAvatar user={editor} contextMenuEnabled={false} showPopOver={false} showCursorColor={false} />
      <span className={styles.optionName}>{fullName}</span>
    </Option>
  )
}

const Filter = ({ title, children }) => (
  <div className={styles.filter}>
    <div className={styles.filterTitle}>
      <h4>{title}</h4>
    </div>
    <div className={styles.filterContent}>
      {children}
    </div>
  </div>
)

export default HistoryFilterPanel