import React from 'react'
import classNames from 'classnames'
import Preferences, { PREF_SHOW_HISTORY, PREF_SHOW_STORYBOARD, PREF_SHOW_STORYBOARD_AND_HISTORY } from 'preferences/Preferences'

import TogglePreferenceButton from 'components/Commons/Preferences/TogglePreferenceButton'

import VolumeControl from 'components/VolumeControl/VolumeControlContainer'
import { EMPTY_ARRAY } from '../../../utils/object'
import UnrealPlaybackMenu from '../../Commons/Clients/unreal/UnrealPlaybackMenu'
import PlayPauseResumeButton from './PlayPauseResumeButton'
import RewindButton from './RewindButton'
import StopButton from './StopButton'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'

import styles from './PlaybackViewControls.scss'

const viewResource = {
  [PREF_SHOW_HISTORY]: { icon: 'idcard', text: 'Switch to both' },
  [PREF_SHOW_STORYBOARD]: { icon: 'file-text', text: 'Switch to History only' },
  [PREF_SHOW_STORYBOARD_AND_HISTORY]: { icon: 'picture', text: 'Switch to Storyboard only' }
}

const Functions = {
  lockScroll: {
    type: locked => (locked ? 'unlock' : 'lock'),
    description: locked => `${locked ? 'Unlock' : 'Lock'} Scrolling`,
  },
  showStoryboardAndHistory: {
    type: value => fromView(value).icon || 'picture',
    description: value => fromView(value).text || ''
  },
  playDirectorComments: {
    type: value => fromView(value).icon || 'notification',
    description: value => (value ? "Don't play DIR lines" : 'Play DIR lines'),
  },
  errorOnMissingAudio: {
    type: () => 'exclamation-circle-o',
    description: value => (value ? 'TTS fallback on missing audio' : 'Error on missing audio')
  }
}

const fromView = prefValue => viewResource[prefValue] || {}

const PlaybackViewControls = ({ fullScreen }) => [
  <TogglePreferenceButton
    key="lockScroll"
    type={Functions.lockScroll.type}
    description={Functions.lockScroll.description}
    preference={Preferences.Playback.lockScroll}
  />,
  <TogglePreferenceButton
    cycle
    key="cyclePlaybackView"
    type={Functions.showStoryboardAndHistory.type}
    description={Functions.showStoryboardAndHistory.description}
    preference={Preferences.Playback.showStoryboardAndHistory}
  />,
  <TogglePreferenceButton
    key="playDirComments"
    type={Functions.playDirectorComments.type}
    description={Functions.playDirectorComments.description}
    preference={Preferences.Playback.playDirectorComments}
  />,
  <TogglePreferenceButton
    key="errorOnMissingAudio"
    type={Functions.errorOnMissingAudio.type}
    description={Functions.errorOnMissingAudio.description}
    preference={Preferences.Playback.errorOnMissingAudio}
  />,
  <UnrealPlaybackMenu
    key="UnrealPlaybackMenu"
  />,
  <ExtensionPoint key="extensions" point={EXTENSION_POINTS.PLAYBACK_PANEL_CONTROL} />,
  ...fullScreen ? [
    <RewindButton key="rewind" className={classNames(styles.firstButton, styles.playbackButton)} />,
    <PlayPauseResumeButton key="playPause" className={styles.playbackButton} />,
    <StopButton key="stop" className={styles.playbackButton} />,
    <VolumeControl key="volume" asIcon className={classNames(styles.volume, styles.playbackButton)} />,
  ] : EMPTY_ARRAY
]

export default PlaybackViewControls
