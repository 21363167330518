import { identity, set, lensPath, pipe, omit, over, lensProp, assocPath } from 'ramda'
import { STATE_EVENTS, Types } from 'actions/state'
import { EMPTY_OBJECT } from '../../utils/object'
import initial from './init'

import onStateEvents from './state/onStateEvents'

const { CLEAR_STATE, SAVEGAME_SAVED, SET_CURRENT_SAVEGAME, SET_STATE, SET_STATE_DIRTY, SET_EVENTS, SET_INITIAL_STATE } = Types

const currentDirtyLens = lensProp('currentDirty')

//
// State & Savegames management
//
const State = {

  [STATE_EVENTS]: onStateEvents,

  [CLEAR_STATE]: () => assocPath(['playback', 'state'], initial.playback.state),
  // savegames
  [SET_CURRENT_SAVEGAME]: action => over(lensPath(['playback', 'state']), pipe(
    action.savegame ? set(lensProp('current'), action.savegame.data) : identity,
    set(currentDirtyLens, {}),
    set(lensProp('savegame'), action.savegame && omit('data', action.savegame)),
  )),

  // watch out, this is raw set... so events should be passed in REVERSE order already
  [SET_EVENTS]: ({ events }) => assocPath(['playback', 'state', 'events'], events),

  [SET_STATE]: ({ state }) => over(lensPath(['playback', 'state']), pipe(
    set(lensProp('current'), state),
    set(currentDirtyLens, EMPTY_OBJECT)
  )),

  [SET_STATE_DIRTY]: ({ stateDirty = {} }) => assocPath(['playback', 'state', 'currentDirty'], stateDirty),
  [SAVEGAME_SAVED]: () => assocPath(['playback', 'state', 'currentDirty'], EMPTY_OBJECT),

  [SET_INITIAL_STATE]: ({ newInitial }) => assocPath(['playback', 'initial'], newInitial)
}

export default State