import { path, pathOr, propEq, pipe, inc, gt } from 'ramda'
import { createSelector } from 'model/utils'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../utils/object';

// notice: this is not a reselect selector, it is our own
// does not memoize. Just a handy way to write reducers/mappers

export const actorAndResources = (clip, locale, begin = 0, end) => createSelector(
  (clip.data.lines && clip.data.lines.slice(begin, end)) || EMPTY_ARRAY,
  line => [line.data.actor, line.data.selected_take, line.data.disabled === true, line.data.disabled_subtitles],
  ([actor, take, isDisabledLine, disabledSubtitles]) => [
    actor,
    take.data.locales[locale] || take.data.locales.english || EMPTY_OBJECT,
    take.data.locales,
    isDisabledLine,
    disabledSubtitles
  ]
)()

export const hasLines = clip => {
  const lines = getLines(clip)
  return lines && lines.length > 0
}

export const getLines = pathOr(EMPTY_ARRAY, ['data', 'lines'])
export const getCurrentLineIndex = (object, lineId) => getLines(object).findIndex(propEq('id', lineId))
export const getNextLineId = (object, lineId) =>
  path(['data', 'lines', inc(getCurrentLineIndex(object, lineId)), 'id'], object)
export const isLastLine = (object, lineId) => !pipe(getNextLineIndex, gt(getLines(object).length))(object, lineId)

export const getNextLineIndex = pipe(getCurrentLineIndex, inc)
export const getLangResText = path(['data', 'text'])
export const getActorIcon = path(['data', 'actorIcon'])
export const getActorName = path(['data', 'actorName'])
