import { fromEvent, merge } from 'rxjs'
import { map, startWith, distinctUntilChanged } from 'rxjs/operators'
import { isAltKeyOnly } from 'utils/keyboard'

/**
 * Keyboard has ALT/OPT pressed
 */
export const onReadyToNavigate = merge(
  fromEvent(document, 'keydown'),
  fromEvent(document, 'keyup'),
).pipe(
  map(isAltKeyOnly),
  startWith(false),
  distinctUntilChanged()
)

