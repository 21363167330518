import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import { prop } from 'ramda'

import projectBranchesQuery from 'api/queries/projectVersions.graphql'

const withVersions = Compo => compose(
  graphql(projectBranchesQuery, {
    name: 'projectBranches',
    options: ({ project }) => ({ variables: { projectId: prop('_id')(project) } })
  }),
  withProps(({ projectBranches }) => ({
    loading: projectBranches.loading
  })),
  withProps(({ project, projectBranches }) => {
    if (!project || !projectBranches || projectBranches.loading || !projectBranches.projectWithId) return { versions: [] }
    const { versions } = projectBranches.projectWithId
    return {
      versions
    }
  })
)(Compo)

export default withVersions