exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__2uaXL{-ms-flex-positive:1;flex-grow:1;width:100%;height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.content__2uaXL .innerContent__ft5Yl{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;height:100%;-ms-flex-positive:1;flex-grow:1}.statePart__1C2ha{display:-ms-flexbox;display:flex;width:calc(50% - 1rem);height:100%}.statePart__1C2ha .title__3r0lQ{display:-ms-flexbox;display:flex;cursor:default;-webkit-writing-mode:vertical-lr;-ms-writing-mode:tb-lr;writing-mode:vertical-lr;padding-top:1rem;padding-left:.8rem;padding-right:.2rem;text-transform:uppercase}.statePart__1C2ha .toolbar__DdGAY{padding-top:2rem}.statePart__1C2ha .toolbar__DdGAY a[role=button]:not(:first-child){margin-top:1rem}.statePart__1C2ha .ant-table{border-right:1px solid #eaeaea;border-left:1px solid #eaeaea;border-top:0;border-bottom:0;border-radius:0}.statePart__1C2ha .ant-table .ant-table-header>tr>th{border-bottom:0;border-left:0;border-top:0;border-right:1px solid #eaeaea}.statePart__1C2ha .ant-table .ant-table-footer{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;height:2rem;border-top:2px solid #eaeaea;border-radius:0;background:inherit}", ""]);

// exports
exports.locals = {
	"content": "content__2uaXL",
	"innerContent": "innerContent__ft5Yl",
	"statePart": "statePart__1C2ha",
	"title": "title__3r0lQ",
	"toolbar": "toolbar__DdGAY"
};