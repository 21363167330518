import { isNil, pathOr, propSatisfies } from 'ramda'
import React from 'react'
import { connect } from 'react-redux'
import { branch, compose } from 'recompose'

import { EMPTY_ARRAY } from 'utils/object'
import { makeObjectFromIdSelector } from 'selectors/objects'
import { model } from 'beanie-engine-api-js'

import RowCell from '../../row/RowCell'
import styles from '../../TruthTable.scss'

const { types: { object: { Paths } } } = model

/**
 *
 */
const TruthTableDrawRow = ({ id, node, index, truthTable, ...props }) => {
  const cells = pathOr(EMPTY_ARRAY, Paths.truth_table_row.cells, node)

  return (
    <tr className={styles.row}>
      {(cells.map((cell, i) => (
        <RowCell
          key={`row_${index}_cell_${i}`}
          rowIndex={index}
          columnIndex={i}
          truthTable={truthTable}
          node={node}
          cell={cell}
          {...props}
        />
      )))}
    </tr>
  )
}

export default compose(
  branch(
    propSatisfies(isNil, 'node'),
    connect(() => {
      const nodeSelector = makeObjectFromIdSelector()
      return (state, props) => ({
        node: nodeSelector(state, props),
      })
    }),
  ),
)(TruthTableDrawRow)