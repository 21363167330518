/* eslint-disable no-useless-escape */
import { ifElse, pipe, join, repeat } from 'ramda'
import { getActorPart, getTextPart } from '../model/DialogueLine'
import { isDirectorLine } from '../model/Line'
import { NODE_TYPES } from '../utils/Constants'
import { EMPTY_STRING } from 'utils/string'
import { formatMarkup } from '../utils/markupUtils'


// CSS styles
export const actorNameStyle = 'align-self: center; display: flex; color: rgb(156, 156, 209); text-transform: uppercase;'
export const directorLineStyle = 'font-style: italic; color: darkgray; font-family: "Courier Prime", Courier, monospace;'
export const actorTextStyle = 'position: relative;'
export const performanceNoteStyle = 'color: lightgrey; font-family: "Courier Prime", Courier, monospace; font-weight: bold;'
export const productionNoteStyle = 'color: rgb(155, 197, 152); font-family: "Courier Prime", Courier, monospace; font-weight: bold;'
export const markupStyle = 'color: #6babc5; font-style: normal;'
//

export const tab = pipe(repeat('&nbsp;&nbsp;'), join(EMPTY_STRING)) // each tab has 2 spaces

const productionNoteToHtml = ({ text }) => `<span style="${productionNoteStyle}">&lt;${text.substring(1, text.length - 1)}&gt;</span>`
const performanceNoteToHtml = ({ text }) => `<span style="${performanceNoteStyle}">${text}</span>`
const markupToHtml = markup => `<span style="${markupStyle}">{${formatMarkup(markup).trimRight()}}</span>`
const textToHtml = ({ text }) => `<span>${text}</span>`

const INLINE_TO_HTML = {
  [NODE_TYPES.PERFORMANCE_NOTE]: performanceNoteToHtml,
  [NODE_TYPES.PRODUCTION_NOTE]: productionNoteToHtml,
  [NODE_TYPES.MARK_UP]: markupToHtml,
  text_node: textToHtml
}

const inlineToHtml = inlineNode => (INLINE_TO_HTML[inlineNode.type] || INLINE_TO_HTML.text_node)(inlineNode)

const directorLineToHtml = ({ nodes }) => 
  `<div style="${directorLineStyle}">${nodes.map(inlineToHtml).join(EMPTY_STRING) || EMPTY_STRING}</div><br />`
    .replace('\n', '<br />')

const dialogueLineToHtml = line => {
  const actor = getActorPart(line)
  const text = getTextPart(line)
  return `<div>${actorNameToHtml(actor)}${actorTextToHtml(text)}</div><br />`
}

const actorNameToHtml = actor =>
  `<div style="${actorNameStyle}">${tab(20)}${actor ? actor.text : EMPTY_STRING}</div>`

const actorTextToHtml = text => 
  `<div style="${actorTextStyle}">${tab(4)}${text ? 
    text.nodes.map(inlineToHtml).join(EMPTY_STRING) : EMPTY_STRING}</div>`
    .replace('\n', '<br />')

const lineToHtml = ifElse(isDirectorLine, directorLineToHtml, dialogueLineToHtml)
export const clipToHtml = lines => `<div>${lines.map(lineToHtml).join(EMPTY_STRING)}</div>`
