import React from 'react'

import NavigableTextView from './NavigableTextView'
import ContainerTitle from './ContainerTitle'

const NavigableContainerTitle = ({ object, hasNext = true, hasPrevious = true, moveSelectedRoot }) => (
  <NavigableTextView
    hasNext={hasNext}
    hasPrevious={hasPrevious}
    moveLeft={() => moveSelectedRoot(-1)}
    moveRight={() => moveSelectedRoot(+1)}
  >
    <ContainerTitle object={object} />
  </NavigableTextView>
)

export default NavigableContainerTitle