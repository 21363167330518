import React from 'react'
import UserAvatar from 'components/RevisionSessions/UserAvatar'

import styles from './UserSelectOption.scss'

const UserSelectOption = ({ user, nameEnabled = true }) => (
  <div className={styles.option}>
    <UserAvatar user={user} contextMenuEnabled={false} showPopOver={false} />
    { nameEnabled &&
      <React.Fragment>
        {user.firstName} {user.lastName} {user.email && <span className={styles.email}>&lt;{user.email}&gt;</span>}
      </React.Fragment>
    }
  </div>
)

export default UserSelectOption