import React from 'react'
import { onlyUpdateForKeys } from 'recompose'
import IconButton from 'components/Commons/IconButton'

import styles from './TreeChain.scss'

const ExpandChainButton = ({ count, onExpand }) => (
  <div className={styles.expandContainer}>
    <IconButton type="plus-square" onClick={onExpand} />
    <span>{count} more</span>
  </div>
)

export default onlyUpdateForKeys(['count'])(ExpandChainButton)