import React from 'react'
import { graphql } from 'react-apollo'

import { Modal, message } from 'antd'
import ChangePasswordForm from './ChangePasswordForm'

import userChangeOwnPassword from 'api/mutations/userChangeOwnPassword.graphql'

class ChangePasswordModal extends React.Component {

  onCancel = () => {
    // reset form if necessary
    // +
    this.props.onFinish()
  }
  onPasswordChanged = async (password) => {
    const { data } = await this.props.mutate({ variables: { input: { password } } })
    // TODO: error handling!
    if (!data.error) {
      message.success('Your password has been changed.')
      this.props.onFinish()
    }
  }

  render = () => {
    const { visible, mandatory } = this.props
    if (!visible) return null

    return (
      <Modal
        title="Change your password"
        visible={visible}
        footer={null}
        destroyOnClose
        closable={false}
      >
        <ChangePasswordForm mandatory={mandatory} onCancel={this.onCancel} onPasswordChanged={this.onPasswordChanged} />
      </Modal>
    )
  }

}

export default graphql(userChangeOwnPassword)(ChangePasswordModal)