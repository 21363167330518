import { useEffect } from 'react'
import { DRAG_PARENT_ID } from '../../TreeView'

/**
 * Not completely sure what this does :S
 * But it "binds" this component's drags preview to the custom drag placeholder we have
 * in the TreeView.
 * A custom "drag layer" (another component) then renders there the content (through a portal).
 * And this `connectDragPreview` makes react-dnd take a snapshot of it to use it.
 */
const useCustomDragPreview = (connectDragPreview, currentOffset) => {
  useEffect(() => {
    if (!connectDragPreview) return
    const elem = document.getElementById(DRAG_PARENT_ID)
    if (elem) {
      if (currentOffset) {
        elem.style.left = `${currentOffset.x}px`
        elem.style.top = `${currentOffset.y}px`
      }
      elem.style.visibility = 'visible'
      connectDragPreview(elem)
    }
  }, [])
}

export default useCustomDragPreview