exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SearchWalkPopover__1u0Tp{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;position:absolute;top:-0.4rem;z-index:10;left:0;right:0;margin-left:auto;margin-right:auto;width:40vw;background-color:#f3eeba;-webkit-box-shadow:0 4px 30px 0 #b5b5b5;box-shadow:0 4px 30px 0 #b5b5b5;border:2px solid #e5dea3;-webkit-transition:height .5s ease;-o-transition:height .5s ease;transition:height .5s ease}.SearchWalkPopover__1u0Tp.hidden__3OeEn{visibility:hidden}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;line-height:2rem;padding-left:.5rem;padding-right:.5rem;padding-top:.2rem;padding-bottom:.2rem;color:#93928d;font-size:1rem}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j.showingDropDown__3P6pJ{border-bottom:2px solid #e5dea3}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j .content__1rdji{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-positive:1;flex-grow:1;-ms-flex-align:center;align-items:center}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j .content__1rdji>.anticon:first-child{margin-right:.5rem;color:#d8ce79}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j .controls__1QRyb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:end;justify-content:flex-end}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j .controls__1QRyb>*{color:#d8ce79}.SearchWalkPopover__1u0Tp .primaryContent__1gs0j .controls__1QRyb>*:not(:last-child){margin-right:.5rem}.SearchWalkWrapperForDropdown__14j5g{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"SearchWalkPopover": "SearchWalkPopover__1u0Tp",
	"hidden": "hidden__3OeEn",
	"primaryContent": "primaryContent__1gs0j",
	"showingDropDown": "showingDropDown__3P6pJ",
	"content": "content__1rdji",
	"controls": "controls__1QRyb",
	"SearchWalkWrapperForDropdown": "SearchWalkWrapperForDropdown__14j5g"
};