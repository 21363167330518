import React, { useEffect, useState } from 'react'
import { compose, withProps, onlyUpdateForKeys, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { equals, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import classNames from 'classnames'

import { model, lang } from 'beanie-engine-api-js'

import { EMPTY_STRING } from 'utils/string'

import { anyIsError, anyIsWarning } from 'providers/Checker/Checker'

import { isInitial } from 'selectors/playback'
import { makeInheritedRuleSelector, makeRowCellValueSelector } from 'selectors/truthTables'
import { updateTruthTableCell } from '../../../../../engine/actions/truthTable'
import DebugIcon from '../../../../Commons/DebugIcon'

import withNodeDragAndDrop from '../../../dnd/withNodeDragAndDrop'

import StaticProblemsFeedback from '../../../TreeChain/StaticProblemsFeedback'
import useCustomDragPreview from '../useCustomDragPreview'
import RowBulletResult from './RowBulletResult'
import TruthTableCell from '../cell/TruthTableCell'
import RowDropdown from './RowDropdown'
import DropFeedback from '../DropFeedback'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'

import treeChainStyles from '../../../TreeView.scss'
import styles from '../TruthTable.scss'

const { types: { object: { isDisabled } } } = model
const { rule: { typing: { types }, utils: { ruleDependsOnItValue, makeSyntheticMatch }, error: { isError } } } = lang

/**
 *
 */
// TODO: avoid spreading props !
export const _RowCell = ({
  node, // the row
  cell,
  rowIndex,
  affectedPath,

  isFirst,
  isLast,
  rowIsHovered,

  truthTable,
  truthTableSelected,
  truthTableEnabled,
  truthTableIsHovered,
  truthTableExecuted,

  rowWasExecuted,
  trackExecutionEnabled,
  hasOtherwiseRow,
  isLastRow,
  rowSelected,
  rowWasNotExecuted,
  currentItemType,
  columnIndex,

  // dnd
  connectDragSource,
  connectDropTarget,
  connectDragPreview,
  currentOffset,
  isDragging,
  isOverRow,
  isDraggingRow,
  setIsDraggingRow,
  isDraggingColumn,
  setIsOverRow,
  droppingRight,
  droppingAbove,
  droppingBelow,

  staticIssues,
  result,
  shouldEval,

  rowIsDebuggable,
  truthTableDebuggable,
  debuggableRow,

  ...props
}) => {
  const anyErrorIssue = isLast && anyIsError(staticIssues)
  const anyWarningIssue = isLast && anyIsWarning(staticIssues)
  const hasWriteAccess = useSecureCheck(isCurrentRevisionWritable)

  // on mount DND preview
  useCustomDragPreview(connectDragPreview, currentOffset)

  useEffect(() => {
    // TODO: if is 'cause whe reuse this component in the drag preview layer but incomplete
    //  weh shouldn't be using the full component (only a shadow one without dnd)
    if (setIsDraggingRow) setIsDraggingRow(isDragging)
  }, [isDragging, setIsDraggingRow])

  useEffect(() => {
    // if is 'cause whe reuse this component in the drag preview layer but incomplete
    if (setIsOverRow) setIsOverRow(droppingRight)
  }, [droppingRight, setIsOverRow])

  const [visibleDropdown, setDropdownVisibility] = useState(false)

  const disabled =
    (debuggableRow && debuggableRow !== node.id) ||
    (isDisabled(node) && !rowIsDebuggable) ||
    (truthTableEnabled === false && !truthTableDebuggable)

  return connectDropTarget(connectDragSource(
    <td
      className={classNames(styles.rowCellContainer, truthTableExecuted && trackExecutionEnabled ? {
        [styles.truthTableExecutedBottomBorder]: !hasOtherwiseRow && isLastRow,
        [styles.truthTableExecutedLeftBorder]: isFirst,
        [styles.truthTableExecutedRightBorder]: isLast,

        [styles.rowExecutedTopAndBottomBorder]: rowWasExecuted,
        [styles.rowExecutedInitialBorder]: rowWasExecuted && isFirst,
        [styles.rowExecutedEndBorder]: rowWasExecuted && isLast
      } : null)}
    >

      {isFirst && <DebugIcon nodeId={node.id} />}

      {truthTableExecuted && trackExecutionEnabled && rowWasExecuted && isFirst &&
        <div className={classNames(styles.rowExecutedStartComp)} />
      }

      <TruthTableCell
        expectedType={types.Bool}
        withSyntheticMatch
        node={node} // row
        rule={cell}
        index={columnIndex}
        rowIndex={rowIndex}


        truthTable={truthTable}
        affectedPath={affectedPath}

        cellStyle={classNames(styles.rowCell, {
          [treeChainStyles.disabledNode]: disabled,
          [styles.droppingAbove]: droppingAbove,
          [styles.droppingBelow]: droppingBelow,
          [styles.dragging]: isDraggingRow || isDraggingColumn,
          [styles.rowUnselectedCellStyle]: rowWasNotExecuted,
          ...(rowSelected ? {
            [styles.rowSelected]: rowSelected,
            [styles.cellLeftSelectedBorder]: isFirst,
            [styles.cellRightSelectedBorder]: isLast,
          } : null),
          ...(truthTableSelected ? {
            [styles.cellLeftSelectedBorder]: isFirst,
            [styles.cellRightSelectedBorder]: isLast,
            [styles.cellBottomSelectedBorder]: !hasOtherwiseRow && isLastRow
          } : null),
        })}
        issuesComponent={isLast && staticIssues ? (
          <StaticProblemsFeedback
            className={styles.cellProblemsFeedback}
            issues={staticIssues}
            isError={anyErrorIssue}
            isWarning={anyWarningIssue}
            node={node}
          />
        ) : null}

        shouldEval={shouldEval}
        result={result}
        resultErrorStyle={styles.resultErrorRowCell}
        iconComponent={hasWriteAccess && (rowIsHovered || visibleDropdown) && isFirst ? (
          <RowDropdown
            row={node}
            rowIndex={rowIndex}
            truthTable={truthTable}
            setDropdownVisibility={setDropdownVisibility}
          />
        ) : null}

        {...props}
      />
      {isLast && isOverRow &&
        <DropFeedback />
      }

      {truthTableIsHovered && isNotNil(result) && !isError(result) &&
        <RowBulletResult result={result} />
      }

    </td>
  ))
}

const RowCell = withNodeDragAndDrop(_RowCell)

export default compose(
  connect((_, { rowIndex, columnIndex, truthTable }) => {
    const inheritedRuleSelector = makeInheritedRuleSelector(prop('id', truthTable), rowIndex, columnIndex)
    const columnHeaderValueSelector = makeRowCellValueSelector(prop('id', truthTable), rowIndex, columnIndex)
    return (state, props) => {
      const shouldEval = !isInitial(state)
      return ({
        inheritedSource: inheritedRuleSelector(state, props).source,
        result: shouldEval ? columnHeaderValueSelector(state, props) : null,
        shouldEval,
      })
    }
  }, {
    editAction: updateTruthTableCell
  }),
  withProps(({ columnIndex, cell }) => ({
    affectedPath: ['data', 'cells', columnIndex],
    cellLabelStyle: equals(EMPTY_STRING, cell.source) ? styles.inherited : null
  })),
  withHandlers({
    editRuleToInsert: () => obj => {
      const { rule, source } = obj
      if (equals(EMPTY_STRING, source)) return ({ source })

      return ruleDependsOnItValue(rule) ? obj : { rule: makeSyntheticMatch(rule), source }
    },
    sourceToShow: ({ inheritedSource }) => source => {
      return (equals(EMPTY_STRING, source) ? inheritedSource : source)
    }
  }),
  onlyUpdateForKeys([
    'node',
    'cell',
    'isFirst',
    'isOver',
    'isOverRow',
    'currentItemType',
    'isLastRow',
    'shouldEval',
    'result',
    'rowIndex',
    'rowIsHovered',
    'isDraggingRow',
    'rowWasExecuted',
    'rowWasNotExecuted',
    'trackExecutionEnabled',
    'isDraggingColumn',
    'hasOtherwiseRow',
    'staticIssues',
    'isLast',
    'rowSelected',
    'truthTableIsHovered',
    'truthTableSelected',
    'truthTableEnabled',
    'truthTableExecuted',
    'inheritedSource',

    'rowIsDebuggable',
    'truthTableDebuggable',
    'debuggableRow',
  ]),
)(RowCell)
