import uuid from 'uuid/v4'

export default class NodeWidget {
  constructor(nodeId, from, to, register) {
    this.register = register
    this.nodeId = nodeId
    this.from = from
    this.to = to
    this.id = uuid()
  }

  key() {
    return `${this.nodeId}-${this.from}-${this.to}`
  }

  toDOM() {
    const container = document.createElement('span')
    container.setAttribute('id', this.id)
    container.style.display = 'inline-block'
    this.register(this.key(), { nodeId: this.nodeId, widgetId: this.id, container })
    return container
  }

  destroy() {}

  ignoreEvent() { return false }

  updateDOM() { return true }

  eq(other) { return other?.key?.() === this.key() }

  compare(other) { return this.eq(other) }

  estimatedHeight() { return -1 }
}