exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__31FL1{color:#add8e6;cursor:pointer}.iconAsLink__31FL1:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__dNeyZ{font-style:italic}.formFieldsVerticalGutter__14_-V{padding:.5rem}.formFieldsVerticalGutter__14_-V>*{margin:.5rem !important}.tableBackground__t_L_7 .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__t_L_7 .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__20SsP .ant-notification-notice-close{display:none}.storyboardPanel__1R0G8{-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;border-right:1px solid #eaeaea;-ms-flex:1 0 auto;flex:1 0 auto;min-width:30vw}.storyboardPanel__1R0G8.storyboardOnly__1vX13>img{max-width:70vw}.storyboardPanel__1R0G8>img{-o-object-fit:contain;object-fit:contain;max-height:90%;max-width:30vw}.storyboardPanel__1R0G8 .noImage__2b4DG{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1;height:90%;max-width:24vw;max-height:24vw;-ms-flex-pack:center;justify-content:center;border-radius:10px;background-color:#eaeaea;text-transform:uppercase;text-align:center;color:#bdbdbd;font-size:1.2em}.storyboardPanel__1R0G8 .noImage__2b4DG .anticon{-ms-flex-item-align:center;align-self:center;font-size:2.5em}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__31FL1",
	"labelFromRef": "labelFromRef__dNeyZ",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__14_-V",
	"tableBackground": "tableBackground__t_L_7",
	"notificationNotClosable": "notificationNotClosable__20SsP",
	"storyboardPanel": "storyboardPanel__1R0G8",
	"storyboardOnly": "storyboardOnly__1vX13",
	"noImage": "noImage__2b4DG"
};