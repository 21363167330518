import React from 'react'
import { compose } from 'recompose'
import { orgWriteAccess } from 'security/organization'
import secure from 'hocs/secure'

import paginatedQuery from 'hocs/paginatedQuery'
import organizationMembersListQuery from 'api/queries/organizationMembersList.graphql'
import OrganizationMembers from './OrganizationMembers'

const WithSecure = compose(
  secure('hasWriteAccess', orgWriteAccess)
)(OrganizationMembers)

const WithQuery = compose(
  paginatedQuery({
    query: organizationMembersListQuery,
    name: 'organizationMembersList',
    variables: ({ organization: { name: organizationName } }) => ({ organizationName }),
  })
)(WithSecure)

const OrganizationMembersContainer = ({ organization }) => (organization ? <WithQuery organization={organization} /> : <WithSecure />)

export default OrganizationMembersContainer