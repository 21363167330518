import { isNil, path, pipe, prop, reject, values, keys, chain, equals, length, reduce } from 'ramda'
import { createSelector } from 'reselect'
import { StaticAnalysisEnablePreference } from 'model/project/preferences'
import { projectPreference } from './project'
import memoize from 'memoize-state'
import { ANALYSIS_STATE } from 'reducers/project'
import { EMPTY_ARRAY } from 'utils/object'
import { objectsIndex } from './apollo'

export const indexedIssues = path(['project', 'staticAnalysis', 'issues'])
export const analyzerState = path(['project', 'staticAnalysis', 'state'])
export const staticAnalysisIsEnabled = projectPreference(StaticAnalysisEnablePreference)

export const makeNodeIssuesSelector = nodeId => createSelector(
  [indexedIssues, staticAnalysisIsEnabled],
  (index, isEnabled) => (isEnabled ? pipe(prop(nodeId), values, reject(isNil))(index) : EMPTY_ARRAY)
)

export const projectIssuesSelector = memoize(state => {
  const isEnabled = staticAnalysisIsEnabled(state)
  if (isEnabled) {
    const allIssues = indexedIssues(state)
    return chain(objId => makeNodeIssuesSelector(objId)(state), keys(allIssues))
  }

  return EMPTY_ARRAY
})

export const analysisIsLoading = createSelector(
  [analyzerState], equals(ANALYSIS_STATE.LOADING)
)

export const countOfIssues = createSelector(
  [projectIssuesSelector], length
)

export const objectsWithIssuesSelector = createSelector(
  [projectIssuesSelector, objectsIndex],
  (issues, index) => reduce((acc, { objId }) => {
    // To avoid copy the object for each obj with issues
    acc[objId] = index[objId]
    return acc
  }, {}, issues)
)