import React from 'react'
import { pathEq, none } from 'ramda'
import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import { Button } from 'antd'

import { ProjectGrantToType, ProjectOwnerType } from 'utils/authentication'

import organizationMembersQuery from 'api/queries/organizationMembersList.graphql'

import ModalButton from 'components/Commons/ModalButton'
import GrantUserForm from './GrantUserForm'

const _UserGrantsMenu = ({ grantAccess, project, organization, usersData, isOrgProject }) => (isOrgProject ? (
  <ModalButton
    title="Add User"
    onOk={async ({ User, Role }) => grantAccess(project, User, ProjectGrantToType.User, Role)}
  >
    <Button type="primary">Add User</Button>
    <GrantUserForm
      organization={organization}
      filter={member => none(pathEq(['to', '_id'], member.user._id), project.grants)}
      usersData={usersData}
    />
  </ModalButton>
) : null)

export default compose(
  withProps(({ project }) => ({
    isOrgProject: project && project.owner.__typename === ProjectOwnerType.Organization,
    organization: project && project.owner.__typename === ProjectOwnerType.Organization && project.owner
  })),
  graphql(organizationMembersQuery, {
    name: 'usersData',
    skip: ({ isOrgProject }) => !isOrgProject,
    options: ({ organization: { name: organizationName } }) => ({
      variables: {
        organizationName,
        // TERRIBLE HACK, otherwise the backend just returns 10
        // we should make this a dropdown with a search query on-deman
        // instead of this, and show some feedback is there are too many results
        // then the user is the one that would need to filter it better by typing
        // and we don't need paging then
        size: 500
      }
    })
  })
)(_UserGrantsMenu)