import { parseRef } from 'beanie-engine-api-js'

/**
 * Gets the first timer node down the children line starting from the ginve nodeId
 * @deprecated this was used for choices1 which we will delete
 */
const getNextTimerOf = (nodeId, engine) => {
  const object = engine.getAPI().obj.get(parseRef(nodeId))
  const timerObject = object.find_sys_downstream('timer')
  return !timerObject ? 0 : timerObject.get_time()
}

export default getNextTimerOf