import React from 'react'
import { formatSeconds } from 'utils/dates'

import styles from './HoverDetail.scss'

const HoverDetail = ({ hoverClip }) => (hoverClip ?
  <div className={styles.container}><span>{hoverClip.id}</span><span>({formatSeconds(hoverClip.start)} &rarr; {formatSeconds(hoverClip.end)})</span></div>
  : null
)

export default HoverDetail