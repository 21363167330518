import { model } from 'beanie-engine-api-js'
import { pinSimilarConditions } from 'actions/project/debuggingData'
import { unpinSimilarConditions } from '../actions/project/debuggingData'

const { types: { object: { isCondition } } } = model

export const PIN_SIMILAR_CONDITIONS = ({
  label: () => 'Pin similar conditions',
  icon: () => 'pin',
  action: pinSimilarConditions,
  visible: isCondition,
  enabled: isCondition,

})

export const UNPIN_SIMILAR_CONDITIONS = ({
  label: () => 'Unpin similar conditions',
  icon: () => 'pin',
  action: unpinSimilarConditions,
  visible: isCondition,
  enabled: isCondition,

})