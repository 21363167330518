import { DEFAULT_LOCALE, parseRef, model } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'
import { countBy, pipe, path, add, ifElse, isNil, always, merge, curry, reduce } from 'ramda'
import { lines, clips, actors } from './objects'
import { objectsIndex } from './apollo'
import { measure } from 'utils/timing'
import { EMPTY_ARRAY, propFrom } from 'utils/object'

const { types: { line: { audioType } } } = model

const actorIdFromLine = pipe(path(['data', 'actor']), parseRef)

export const linesByActor = createSelector('linesByActor',
  [lines, actors],
  measure('linesByActor', (_lines, _actors) => pipe(
    countBy(actorIdFromLine),
    merge(actorsAsCounters(_actors))
  )(_lines))
)

export const clipsByActor = createSelector('clipsByActor',
  [clips, actors, objectsIndex],
  measure('clipsByActor',
    (_clips, _actors, _index) => pipe(
      groupBy(actorsOnClip(_index), ifElse(isNil, always(1), add(1))),
      merge(actorsAsCounters(_actors))
    )(_clips)
  )
)

export const makeAudioForLineSelector = idFromProps => createSelector(
  [idFromProps, objectsIndex],
  (lineId, index) => {
    const resolve = ref => index[parseRef(ref)]
    const line = index[lineId]
    if (!line) { return undefined }
    const takeRef = path(['data', 'selected_take'], line)
    if (!takeRef) { return undefined }
    const langResRef = path(['data', 'locales', DEFAULT_LOCALE], resolve(takeRef))
    if (!langResRef) { return undefined }
    return path(['data', 'audio'], resolve(langResRef))
  }
)

// utils

const actorsOnClip = index => clip => (clip.data.lines || EMPTY_ARRAY).map(pipe(
  parseRef,
  propFrom(index),
  path(['data', 'actor']),
  parseRef
))

const actorsAsCounters = reduce((acc, actor) => {
  acc[actor.id] = 0
  return acc
}, {})

const _groupBy = (keyFn, valueReducer, list) => list.reduce((acc, e) => {
  const keys = keyFn(e)
  keys.forEach(key => {
    acc[key] = valueReducer(acc[key], e, key)
  })
  return acc
}, {})
const groupBy = curry(_groupBy)

export const languageResourceForLine = (lineId, index) => {
  if (!lineId) { return undefined }
  const line = index[lineId]
  if (!line) return undefined
  const { data: { selected_take } } = line
  const { data: { locales: { [DEFAULT_LOCALE]: lang_res } } } = index[parseRef(selected_take)]
  return index[parseRef(lang_res)]
}

export const makeLanguageResourceForLineSelector = lineIdFromProps => createSelector(
  [lineIdFromProps, objectsIndex],
  languageResourceForLine,
)

export const makeLanguageResourceForLineIdSelector = lineId => createSelector(
  [objectsIndex],
  index => languageResourceForLine(lineId, index)
)

export const makeLineTypeOfAudioSelector = lineIdFromProps => createSelector(
  [makeLanguageResourceForLineSelector(lineIdFromProps)],
  audioType
)