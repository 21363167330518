import React from 'react'

import styles from './InvitesPage.scss'

const InvitePage = ({ children }) => (
  <div className={styles.page}>
    <img className={styles.logo} src="/images/logo-horiz-white.png" alt="Beanie Studio" />
    <div className={styles.panel}>
      {children}
    </div>
  </div>
)

export default InvitePage