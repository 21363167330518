import { T, F, identity as _identity, length, isEmpty } from 'ramda'
import { createScheduler } from 'lrt'
import { EMPTY_OBJECT } from './object'

export const noop = () => {}
export const identity = _identity
export const trueFunction = T
export const falseFunction = F

export const isFunction = o => o && typeof o === 'function'

export const iterator = (array, i = 0) => () => array[i++]

export const dispatchFunction = fnResolver => (...args) => fnResolver(...args)(...args)

export const applyScheduledFunctions = (fns, options = EMPTY_OBJECT) => {
  if (isEmpty(fns)) return

  const scheduler = createScheduler(options)

  const it = {
    next(i = 0) {
      fns[i]()

      return {
        done: i === length(fns) - 1,
        value: i + 1
      }
    }
  }

  return scheduler.runTask(it)
}

export const callOrValue = f => (isFunction(f) ? f() : f)