exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".Preferences__1TvzK>div>span{margin-right:.5rem}.Preferences__1TvzK>div .ant-alert{margin-top:.5rem}.subTitle__HK2e8{color:gray}.PreferencesGroup__3yUdo{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;border:solid 1px #d3d3d3;padding:2rem}.PreferencesGroup__3yUdo:last-child{border-bottom-left-radius:1rem;border-bottom-right-radius:1rem}.PreferencesGroup__3yUdo:first-child{border-top-left-radius:1rem;border-top-right-radius:1rem}.PreferencesGroup__3yUdo:not(:first-child){border-top:0}.PreferencesGroup__3yUdo>h3{display:-ms-flexbox;display:flex;width:20%}.preferences__oHUB9{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:3 1 0%;flex:3 1 0%}.preference__3G6d0{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;padding-bottom:1rem}.preference__3G6d0 .preferenceTitle__dVM85{width:16rem;padding-right:1rem;-ms-flex-negative:0;flex-shrink:0}.preference__3G6d0 .ant-select{width:10rem}.selectEditor__2NgWr{display:-ms-flexbox;display:flex;padding-right:2rem}.preferenceControl__2CGY2{display:-ms-flexbox;display:flex;-ms-flex-negative:0;flex-shrink:0;padding-right:2rem}.alert__310ab{display:-ms-flexbox;display:flex}.alert__310ab .ant-alert{padding-top:0;margin-top:0;font-size:.8rem;background-color:transparent;border:0px}.alert__310ab .ant-alert .ant-alert-message{color:#ad9956}", ""]);

// exports
exports.locals = {
	"Preferences": "Preferences__1TvzK",
	"subTitle": "subTitle__HK2e8",
	"PreferencesGroup": "PreferencesGroup__3yUdo",
	"preferences": "preferences__oHUB9",
	"preference": "preference__3G6d0",
	"preferenceTitle": "preferenceTitle__dVM85",
	"selectEditor": "selectEditor__2NgWr",
	"preferenceControl": "preferenceControl__2CGY2",
	"alert": "alert__310ab"
};