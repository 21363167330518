import React from 'react'
import { Icon } from 'antd'

import styles from './Unauthorized.scss'

const UnAuthorized = () => (
  <div className={styles.unauthorized}>
    <h2><Icon type="exclamation-circle" /> You do not have permission to see this section.</h2>
  </div>
)

export default UnAuthorized