import revisionSessionGetSessionsQuery from 'api/queries/revisionSessionGetSessions.graphql'
import { onRevisionSessionsSynched } from '../project'

/**
 * Fetches all current sessions for this revision and sync the local state with that.
 * Used when we reconnect or through a manual re-sync action
 */
const resyncSessions = () => async (dispatch, getState, { getApolloClient }) => {
  await syncOnlineSessions(getApolloClient(), dispatch)
}

// export to be used by resyncRevision without overhead of dispatching
export const syncOnlineSessions = async (client, dispatch) => {
  // TODO: error handling ?
  const { data: { revisionSessionGetSessions } } = await client.query({
    query: revisionSessionGetSessionsQuery,
    variables: {},
    fetchPolicy: 'no-cache',
  })
  dispatch(onRevisionSessionsSynched(revisionSessionGetSessions))
}

export default resyncSessions