import React from 'react'
import { concat, pipe, toString } from 'ramda'
import classNames from 'classnames'

import connectState from 'hocs/connectState'
import { presetIds, anyPresetAssociated } from 'selectors/presets'

import PresetDropdown from './PresetDropdown'

import styles from './StatePresetButtons.scss'

const StatePresetButtons = ({ anyAssociated }) => (
  <div className={classNames(styles.statePresetButtons, { [styles.anyAssociated]: anyAssociated })}>
    {presetIds
      .map(pipe(toString, concat('preset')))
      .map(preset => (<PresetDropdown key={`PresetDropdown-${preset}`} preset={preset} />))
    }
  </div>
)

export default connectState({
  anyAssociated: anyPresetAssociated
})(StatePresetButtons)