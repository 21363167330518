import { append, drop, lensPath, lensProp, over, propEq, reject } from 'ramda'

import { createReducerFlipped } from 'reducers/utils'
import { SYNC_STARTED, SYNC_FINISHED, SYNC_ENQUEUE, SYNC_DEQUEUE, SyncDirection } from 'actions/sync'

const initialState = {
  [SyncDirection.OUTBOUND]: {
    operations: []
  },
  [SyncDirection.INBOUND]: {
    operations: []
  },
  changeSetQueue: [],
}

const syncStarted = ({ direction = SyncDirection.OUTBOUND, operationId: id, objects }) => over(
  lensPath([direction, 'operations']),
  append({ id, ...objects && { objects } })
)

const syncFinished = ({ direction = SyncDirection.OUTBOUND, operationId }) => over(
  lensPath([direction, 'operations']),
  reject(propEq('id', operationId))
)

const syncEnqueue = ({ changeSet }) => over(lensProp('changeSetQueue'), append(changeSet))
const syncDequeue = () => over(lensProp('changeSetQueue'), drop(1))

export const sync = createReducerFlipped({
  [SYNC_STARTED]: syncStarted,
  [SYNC_FINISHED]: syncFinished,
  [SYNC_ENQUEUE]: syncEnqueue,
  [SYNC_DEQUEUE]: syncDequeue,
}, initialState)