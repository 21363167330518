exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__GmVJc{color:#add8e6;cursor:pointer}.iconAsLink__GmVJc:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__U4ltk{font-style:italic}.formFieldsVerticalGutter__2Xyn8{padding:.5rem}.formFieldsVerticalGutter__2Xyn8>*{margin:.5rem !important}.tableBackground__1pB3s .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1pB3s .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2UPGV .ant-notification-notice-close{display:none}.toolbar__2i16W{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.toolbar__2i16W>*{padding-right:.7em}.toolbar__2i16W .anticon{font-size:1.2em;color:#d2d2d2}.button__29SRo{color:#d3d3d3}.button__29SRo:hover{color:#fff}.button__29SRo.disabled__11QnR{color:gray !important;cursor:default}.lastUpdated__10m8T{padding-left:1em;font-size:.8em;color:gray}.dropdownInput__2yIaW{width:12rem;-ms-flex-item-align:center;align-self:center}.dropdownInput__2yIaW .ant-icon{font-size:.8em;color:#d2d2d2}.dropdownInput__2yIaW input{background-color:#273749;color:#d2d2d2;border:0;border-radius:10px}.dropdownInput__2yIaW .down__uRLEX{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;color:#adadb0}.buttonDisabled.buttonDisabled{opacity:.5}.buttonDisabled.buttonDisabled:hover{cursor:default}.buttonDisabled.buttonDisabled>.anticon:hover{cursor:default}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__GmVJc",
	"labelFromRef": "labelFromRef__U4ltk",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__2Xyn8",
	"tableBackground": "tableBackground__1pB3s",
	"notificationNotClosable": "notificationNotClosable__2UPGV",
	"toolbar": "toolbar__2i16W",
	"button": "button__29SRo",
	"disabled": "disabled__11QnR",
	"lastUpdated": "lastUpdated__10m8T",
	"dropdownInput": "dropdownInput__2yIaW",
	"down": "down__uRLEX"
};