import React from 'react'
import { isNil } from 'ramda'
import { origin } from 'model/constants/SessionClientType'

const RevisionSessionOrigin = ({ session, className }) => {
  const maybeOrigin = origin(session)
  if (isNil(maybeOrigin)) return null
  return (
    <span className={className}>({maybeOrigin})</span>
  )
}

export default RevisionSessionOrigin