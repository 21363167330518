import { createSelector, createArraySelector } from 'selectors/reselect'
import memoize from 'memoize-state'
import {
  converge,
  isEmpty,
  path,
  filter,
  propSatisfies,
  propEq,
  prop,
  pipe,
  pathOr,
  when,
  map,
  last,
  uniqBy,
  findLast,
  pathEq,
  identity
} from 'ramda'
import { isNotNil, isTrue } from 'ramda-adjunct'
import { Sys, NodePlaybackStatus, parseRef, lang } from 'beanie-engine-api-js'

import { PlaybackStatus } from 'model/constants'
import { EMPTY_ARRAY, propFrom } from 'utils/object'

import { selectedNode, selectedNodeObject } from 'selectors/nodeSelection'
import { objectsIndex } from 'selectors/apollo'
import { activePreset } from 'selectors/presets'
import { isDirty as isStateDirty, events as stateEvents } from 'selectors/state'
import { createContextSource, makeContextFromSource } from 'model/languague/rules/context'
import { isDisabledFromId } from 'selectors/objects'
import { isEnabled } from 'selectors/debuggingData'
import { idIsPinned } from './debuggingData'
import { createDeepEqualSelector } from './reselect'

const { rule: { interpreter: { evalBooleanRule }, error: { isStaticError } } } = lang

const resolveRef = index => pipe(parseRef, propFrom(index))
export const PLAYBACK_PATH = ['vm', 'playback']
export const playback = path(PLAYBACK_PATH)
export const playbackStartTime = pipe(playback, prop('startTime'))

export const playbackStatus = createSelector('playbackStatus', playback, prop('status'))
export const playbackIndex = createSelector('playbackIndex', playback, prop('playbackIndex'))
export const playbackQueue = createSelector('playbackQueue', playback, prop('playbackQueue'))
export const playbackQueueIsEmpty = createSelector('playbackQueueIsEmpty', playbackQueue, isEmpty)

export const playbackNodes = createArraySelector('playbackNodes', [playbackQueue, playbackIndex], (e, idx) => idx[e])

export const makePlaybackNodeById = id => createSelector('playbackNodeById',
  [playbackIndex],
  idx => idx[id]
)

export const playbackNode = id => state => playbackIndex(state)[id]

const isPresentingStatus = e => NodePlaybackStatus.PRESENTING_SOMETHING_REGEX.test(e || '')

// we split this and need 2 selectors to improve memoization. Otherwise
// the filter makes it always recompute.
// we also don't use "playbackNodes" here which could reuse more code and make it more readable
// on purpose, because this is a better impl in terms of performance of the memoization
const _playingNodesIds = createSelector('_playingNodes',
  [playbackQueue, playbackIndex],
  (elementIds, idx) => elementIds.reduce((acc, elementId) => {
    const element = idx[elementId]
    if (propSatisfies(isPresentingStatus, 'status', element)) {
      acc.push(elementId)
    }
    return acc
  }, [])
)
export const playingNodesIds = createDeepEqualSelector('playingNodesIds',
  [_playingNodesIds],
  identity,
)

export const playingNodes = createSelector('playingNodes',
  [playbackNodes],
  filter(propSatisfies(isPresentingStatus, 'status')),
)

export const clipNodes = createSelector('clipNodes', [playbackNodes], filter(propEq('sys', Sys.clip)))
export const makeCurrentlyPlayingChoicesSelector = () => createSelector(
  [playingNodes],
  filter(propEq('sys', Sys.choices))
)

export const makeIsEnabledNode = id => {
  const evaluateRule = memoize(({ source, initial }) => {
    const node = source.index[id]
    const rule = path(['data', 'enabled_rule', 'rule'], node)

    if (!rule) return true

    const result = evalBooleanRule(makeContextFromSource(source), rule)

    if (initial && !isStaticError(result)) return true

    /* eslint-disable space-infix-ops, no-unused-expressions, no-undef */

    // Access to avoid compute root path objects
    source.index.___fix_memoize___
    source.indexBySys.___fix_memoize___
    source.factIndex.___fix_memoize___

    /* eslint-enable space-infix-ops, no-unused-expressions, no-undef */

    return result
  })

  const contextSelector = createContextSource(() => id)

  const selector = state => evaluateRule({
    initial: isInitial(state),
    source: contextSelector(state)
  })

  selector.memoizingSelector = evaluateRule
  return selector
}

export const makeIsForcedExecution = id => {
  const isEnabledNodeSelector = makeIsEnabledNode(id)
  const isDisableSelector = isDisabledFromId(id)

  const executionIsForced = memoize(state =>
    isEnabled(state)
    && idIsPinned(id, state)
    && (isDisableSelector(state) || isEnabledNodeSelector(state) === false)
  )

  const selector = executionIsForced
  selector.memoizingSelector = executionIsForced
  return selector
}

export const isInitial = pathEq(PLAYBACK_PATH.concat('status'), PlaybackStatus.INITIAL)
export const isPlaying = pathEq(PLAYBACK_PATH.concat('status'), PlaybackStatus.PLAYING)
export const isPaused = pathEq(PLAYBACK_PATH.concat('status'), PlaybackStatus.PAUSED)
export const isStopped = pathEq(PLAYBACK_PATH.concat('status'), PlaybackStatus.STOPPED)
export const isRewindEnabled = createSelector('isRewindEnabled',
  [playbackStatus, isStateDirty, activePreset, playbackQueueIsEmpty, stateEvents],
  (status, isDirty, preset, isPausedAtInitial, events) =>
    !(status === PlaybackStatus.INITIAL || (status === PlaybackStatus.PAUSED && !!preset && isPausedAtInitial) || (isEmpty(events) && !isDirty))
)

export const isPlayEnabled = createSelector('isPlayEnabled', [selectedNode, isPlaying, isRewindEnabled], (selection, playing, rewindEnabled) => selection && !playing && !rewindEnabled)

const hasPlayableNode = node => !!node && node.sys !== Sys.folder
const _isPlayPauseResumeEnabled = (status, hasActivePreset, node, events, isDirty) =>
  !!status &&
  (
    (status === PlaybackStatus.INITIAL && !hasActivePreset && hasPlayableNode(node))
    || ![PlaybackStatus.STOPPED, PlaybackStatus.INITIAL].includes(status)
    || (isEmpty(events) && hasPlayableNode(node) && !isDirty)
  )

export const isPlayPauseResumeEnabled = createSelector(
  [playbackStatus, activePreset, selectedNodeObject, stateEvents, isStateDirty],
  (status, aPreset, node, events, isDirty) => _isPlayPauseResumeEnabled(status, !!aPreset, node, events, isDirty)
)

export const createIsPlayingNodeSelector = playbackItemSelector => createSelector('isPlayingNode',
  [playbackItemSelector, playbackIndex],
  (playbackItem, index) => {
    const item = index[playbackItem.playbackId]
    return item && isPresentingStatus(item.status)
  }
)
createIsPlayingNodeSelector.noDebug = true

export const currentStoryboard = path(['vm', 'playback', 'currentStoryboard', 'storyboard'])
export const currentPlaybackId = path(['vm', 'playback', 'currentPlaybackId'])

export const lastClip = createSelector('lastClip',
  [clipNodes, objectsIndex],
  (clips, index) => pipe(
    last,
    when(isNotNil, pipe(prop('nodeId'), resolveRef(index)))
  )(clips)
)

export const currentActors = createSelector('currentActors',
  [lastClip, objectsIndex],
  (clip, index) => (clip ? actorsOnClip(resolveRef(index))(clip) : EMPTY_ARRAY)
)

const actorsOnClip = resolver => pipe(
  pathOr(EMPTY_ARRAY, ['data', 'lines']),
  map(actorFromLineRef(resolver)),
  uniqBy(prop('id'))
)

const actorFromLineRef = resolver => pipe(
  resolver,
  path(['data', 'actor']),
  when(isNotNil, resolver)
)

export const currentlySpeakingActor = createSelector('currentlySpeakingActor',
  [lastClip, playingNodes, objectsIndex],
  (clip, nodes, index) => {
    if (!clip) { return undefined }
    const clipNode = findLast(propEq('nodeId', clip.id), nodes)
    const actorId = clipNode && clipNode.state && clipNode.state.lineId && index[clipNode.state.lineId].data.actor
    return actorId && parseRef(actorId)
  }
)

const restoreInspecting = path([...PLAYBACK_PATH, 'restoreInspecting'])
export const isRestoreInspecting = createSelector('isRestoreInspecting',
  [restoreInspecting],
  isTrue
)

// state -> playbackNode
export const currentlyPlayingNode = converge(
  prop,
  [currentPlaybackId, playbackIndex]
)
