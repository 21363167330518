import { model } from 'beanie-engine-api-js'
import { OBJECT_TYPES, NODE_TYPES } from '../../Constants'
import { prop, equals } from 'ramda'
import { markupNameNode, markupName } from '../../markupUtils'
import { hasParams } from '../../plugins/MarkUpCreatorPlugin/queries'

const { types: { markup: { DELIM_START } } } = model

export const toRegularText = (editor, textNodeKey, toReplacekey) => {
  const node = editor.nodeByKey(textNodeKey)
  if (node) {
    const { text } = node

    editor.replaceNodeByKey(toReplacekey, { object: OBJECT_TYPES.text, text })
  }
}

const markupTextWithActorName = markup => {
  const { text } = markup
  const position = markupName(markup).length + (equals(text[0], DELIM_START) ? 1 : 0)
  return `${text.slice(0, position)} ${text.slice(position)}`
}

const partialMarkupToText = markup => ((markupNameNode(markup) && hasParams(markup)) ?
  markupTextWithActorName(markup) :
  prop('text', markup))

const TEXT_FROM_NODE = {
  [NODE_TYPES.MARK_UP]: partialMarkupToText,
  default: prop('text')
}

export const convertInlineNodeInText = (editor, node) => {
  if (!node) { return }
  const { type, key } = node
  const text = (TEXT_FROM_NODE[type] || TEXT_FROM_NODE.default)(node)
  const { value: { selection: previousSelection } } = editor.props
  const { start, isBackward } = (previousSelection || editor.currentSelection())
  const isInNode = editor.isInNode(start, node)
  editor.replaceNodeByKey(key, { object: OBJECT_TYPES.text, text })
  editor.collapse()
  if (isBackward && isInNode && !start.isAtStartOfNode(node)) {
    editor.moveToEndOfNextText()
  }
}