import React from 'react'
import classNames from 'classnames'
import { Tooltip, Badge } from 'antd'

import styles from './UpdateEditorContentFeeback.scss'

const UpdateEditorContentFeeback = ({ className, newContentInComing }) => (
  <div className={classNames(className, styles.UpdateEditorContentFeeback, { [styles.showing]: newContentInComing })}>
    <Tooltip title="Content Updated!" placement="topLeft">
      <Badge color="#9dd084" status="processing" />
    </Tooltip>
  </div>)

export default UpdateEditorContentFeeback