import { Spin } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { compose, onlyUpdateForKeys } from 'recompose'
import classNames from 'classnames'

import withHasFocus from 'hocs/withHasFocus'

import ContextMenu from 'components/ContextMenu/ContextMenu'

import { lanes as lanesSelector } from 'selectors/objects'

import { TREE_VIEW_DOM_ID, isFocusWithinTreeView } from 'dom/dom'

import onContextMenuCloseAction from 'actions/ui/tree/TreeView/onContextMenuClose'
import withDeferRender from '../../hocs/react/withDeferRender'

import TreeLane from './TreeLane/TreeLane'
import FocusCursor from './FocusCursor'

import styles from './TreeView.scss'

export const DRAG_PARENT_ID = 'DRAG_PARENT_ID'

/* eslint jsx-a11y/no-noninteractive-tabindex: 0,  */

const TreeView = ({ hasFocus, lanes, onContextMenuClose }) => (
  <ContextMenu onClose={onContextMenuClose}>
    <div id={TREE_VIEW_DOM_ID} className={classNames(styles.container, { [styles.hasFocus]: hasFocus })} tabIndex="-1">
      <div className={styles.dragParent} id={DRAG_PARENT_ID} />
      <div className={styles.content}>
        <FocusCursor />
        <div>
          {lanes.map(name => (
            <TreeLane key={name} name={name} />
          ))}
        </div>
      </div>
    </div>
  </ContextMenu>
)

export default compose(
  connect(
    state => ({
      lanes: lanesSelector(state),
    }),
    {
      onContextMenuClose: onContextMenuCloseAction,
    }
  ),
  withHasFocus({ queryDOMFunction: isFocusWithinTreeView }),
  onlyUpdateForKeys(['lanes', 'hasFocus']),
  withDeferRender({
    // maybe this should only be deferred if the project "is big" like checking
    // the size of all bneobjects, but I don't want to connect that and force more re-renders
    shouldDefer: ({ lanes }) => lanes.length > 3,
    Loading: (
      <div id={TREE_VIEW_DOM_ID} className={classNames(styles.container)} tabIndex="-1">
        <div className={styles.Loading}><Spin /> Loading...</div>
      </div>
    )
  })
)(TreeView)
