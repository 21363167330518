import React from 'react'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import { identity, equals } from 'ramda'

import { input } from 'utils/graphql'

import IconButton from 'components/Commons/IconButton'
import { updateOnMarkAsRead as update } from 'components/NotificationsIndicator/updateNotificationsCache'

import notificationMarkAsReadMutation from 'api/mutations/notificationMarkAsRead.graphql'

import styles from './NotificationsListHeader.scss'

const markAllAsRead = markAsRead => () => markAsRead(
  input(
    { doItForAll: true },
    identity,
    { update }
  )
)

const whenAllRead = equals(0)

const _NotificationsListHeader = ({ markAsRead, unreadCount }) => (
  <div className={styles.NotificationsListHeader}>
    <span className={styles.title}>Notifications</span>
    <IconButton disabled={whenAllRead(unreadCount)} type="check" onClick={markAllAsRead(markAsRead)} className={styles.markAllAsRead} />
  </div>
)

const NotificationsListHeader = compose(
  graphql(notificationMarkAsReadMutation, { name: 'markAsRead' })
)(_NotificationsListHeader)

export default NotificationsListHeader