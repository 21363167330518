import React from 'react'
import { isNil } from 'ramda'
import { Menu } from 'antd'

const { SubMenu, Divider, Item } = Menu

import styles from './Menu.scss'

/**
 *
 */
const TruthTableColumnMenu = ({
  insertColumnLeft,
  insertColumnRight,
  moveColumnLeft,
  moveColumnRight,
  moveColumnToLeftMost,
  moveColumnToRightMost,
  deleteColumn
}) => {
  return (
    <Menu
      className={styles.menu}
    >
      {(insertColumnLeft || insertColumnRight) &&
      <SubMenu
        className={styles.subMenu}
        key="insertColumn"
        title="Insert Column..."
      >
        <Item
          className={styles.item}
          key="Left"
          disabled={isNil(insertColumnLeft)}
          onClick={insertColumnLeft}
        >
          Left
        </Item>
        <Item
          className={styles.item}
          key="Right"
          disabled={isNil(insertColumnRight)}
          onClick={insertColumnRight}
        >
          Right
        </Item>
      </SubMenu>}
      {(moveColumnLeft || moveColumnRight || moveColumnToLeftMost || moveColumnToRightMost) &&
      <SubMenu
        key="moveColumn"
        className={styles.subMenu}
        title="Move Column..."
      >
        <Item
          className={styles.item}
          key="Left"
          disabled={isNil(moveColumnLeft)}
          onClick={moveColumnLeft}
        >
          Left
        </Item>
        <Item
          className={styles.item}
          key="Right"
          disabled={isNil(moveColumnRight)}
          onClick={moveColumnRight}
        >
          Right
        </Item>
        <Divider />
        <Item
          className={styles.item}
          key="LeftMost"
          disabled={isNil(moveColumnToLeftMost)}
          onClick={moveColumnToLeftMost}
        >
          LeftMost
        </Item>
        <Item
          className={styles.item}
          key="RightMost"
          disabled={isNil(moveColumnToRightMost)}
          onClick={moveColumnToRightMost}
        >
          RightMost
        </Item>
      </SubMenu>}
      <Divider />
      <Item
        className={styles.item}
        key="delete column"
        disabled={isNil(deleteColumn)}
        onClick={deleteColumn}
      >
        Delete column
      </Item>
    </Menu>
  )
}

export default TruthTableColumnMenu
