import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

/**
 * Util hook for all the handlers here that need to:
 *  - dispatch an action
 *  - close the menu
 */
const useDropdownAction = (setDropdownVisibility, fn, dependencies) => {
  const dispatch = useDispatch()

  return useCallback((...args) => {
    dispatch(fn(...args))
    setDropdownVisibility(false)
  }, [...dependencies, setDropdownVisibility])
}

export default useDropdownAction