import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { compose, withProps, withState } from 'recompose'
import { Button, Spin, Table, Tooltip, Icon } from 'antd'
import { always, pick } from 'ramda'

import paginatedQuery from 'hocs/paginatedQuery'
import ttsJobDetailQuery from 'api/queries/ttsJobDetail.graphql'
import SectionElementsTable from 'components/Organizations/Section/SectionElementsTable/SectionElementsTable'
import FilterStatusDropdown from './FilterStatusDropdown'
import { TtsJobItemStatus } from 'model/constants'
import { formatTimestamp, humanizedDiff } from 'utils/dates'

import styles from './TtsJobDetail.scss'

const { Column } = Table

const ErrorIcon = ({ message }) => (
  <Tooltip title={message}>
    <Icon className={styles.errorIcon} type="warning" />
  </Tooltip>
)

const TtsJobDetail = ({ ttsJobId, ttsJobDetail, goBack, pageSize, refreshItems, onPageChanged, handleTableChange, setFilterStatus, filterStatus }) => (
  <Fragment>
    <Spin spinning={ttsJobDetail.loading}>
      <h2><Icon type="arrow-left" onClick={() => goBack()} />Job {ttsJobId}</h2>
      <h3>Job items</h3>
      <div className={styles.filterToolbar}>
        <FilterStatusDropdown
          setFilterStatus={setFilterStatus}
          refreshItems={refreshItems}
          defaultValue={filterStatus}
          options={TtsJobItemStatus}
          allowAll
        />
        <Button type="primary" icon="search" onClick={() => refreshItems()}>Search</Button>
      </div>
      <SectionElementsTable
        data={ttsJobDetail}
        dataPath={['ttsJob', 'jobItems']}
        pageSize={pageSize}
        scroll={{ x: '30vw', y: '55vh' }}
        onPageChanged={onPageChanged}
        refreshItems={refreshItems}
        handleTableChange={handleTableChange}
      >
        <Column
          title="Job Item id"
          key="_id"
          dataIndex="_id"
          width="20vw"
        />
        <Column
          title="Status"
          key="status"
          sorter
          width="20vw"
          render={({ status, errorMessage }) => (
            <span>{status}{errorMessage && <ErrorIcon message={errorMessage} />}</span>
          )}
        />
        <Column
          title="Last updated"
          sorter
          key="updatedAt"
          width="20vw"
          render={({ updatedAt }) => (<span>{formatTimestamp(updatedAt)}</span>)}
        />
        <Column
          title="Created at"
          sorter
          key="createdAt"
          width="20vw"
          render={({ createdAt }) => (<span>{formatTimestamp(createdAt)}</span>)}
        />
        <Column
          title="Elapsed"
          width="20vw"
          render={({ createdAt, updatedAt }) => (
            <span>{humanizedDiff(updatedAt, createdAt)}</span>
          )}
        />
      </SectionElementsTable>
    </Spin>
  </Fragment>
)

export default compose(
  withRouter,
  withProps(({ match: { params: { ttsJobId } }, history: { goBack } }) => ({ ttsJobId, goBack })),
  withState('filterStatus', 'setFilterStatus', null),
  paginatedQuery({
    query: ttsJobDetailQuery,
    name: 'ttsJobDetail',
    variables: pick(['ttsJobId', 'filterStatus']),
    options: always({ fetchPolicy: 'cache-and-network' })
  })
)(TtsJobDetail)