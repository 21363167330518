import { NODE_TYPES } from '../Constants'
import { NoteDelimiters, NoteTypes } from 'model/constants'
import noteWithDelimiters from './NoteWithDelimiters'

const NODE_TYPES_ALLOWED = [
  NODE_TYPES.TEXT_PART,
  NODE_TYPES.DIRECTOR_LINE
]

export default () => noteWithDelimiters(
  {
    openDelimiter: NoteDelimiters[NoteTypes.Production].left,
    closeDelimiter: NoteDelimiters[NoteTypes.Production].right,
  }, 
  NODE_TYPES.PRODUCTION_NOTE,
  (editor, parentBlock) => NODE_TYPES_ALLOWED.includes(parentBlock.type)
)
