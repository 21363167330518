import { path } from 'ramda'
import { model } from 'beanie-engine-api-js'

import { postResource, deleteResource, ResourceType } from 'actions/resources'
import { playSound, stopAllSounds, pauseAllSounds, resumeAllSounds } from 'model/sound'
import { textToSpeech, stopAllTts, pauseAllTts, resumeAllTts } from 'tts/tts'
import { sleep } from 'utils/promises'
import { isEmptyOrNull } from 'utils/string'
import { EMPTY_OBJECT, isEmpty } from 'utils/object'

import Preferences from 'preferences/Preferences'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { volume, preference } from 'selectors/view'
import { token as tokenSelector } from 'selectors/auth'

import { objectsIndex } from 'selectors/apollo'
import { takes as takesSelector, lines as linesSelector } from 'selectors/objects'
import { isLangResAudioDirty } from '../selectors/objectsMeta'

const { types: { object: { isDisabled }, actor: { isDefaultActor }, line: { getStoryboard } } } = model

const DIR_LINE_STORYBOARD_TIME = 1000

export const playNode = (actor, node, langRes) => async (dispatch, getState) => {
  const state = getState()
  const lineId = path(['state', 'lineId'], node)

  // DIR lines special treatment
  const playDirectorLines = preference(Preferences.Playback.playDirectorComments)(state)

  const index = objectsIndex(state)
  const line = index[lineId]

  if (isDisabled(line)) return

  if (!playDirectorLines && isDefaultActor(actor)) {
    if (getStoryboard(line)) {
      await sleep(DIR_LINE_STORYBOARD_TIME)
    }
    return
  }

  return playLine(
    tokenSelector(state),
    revisionIdSelector(state),
    langRes.data,
    { ...(actor.data.editor || EMPTY_OBJECT), volume: volume(state) },
    node.playbackId,
    lineId,
    preference(Preferences.Playback.errorOnMissingAudio)(state),
    preference(Preferences.Playback.playDirtyAudio)(state),
    isLangResAudioDirty(
      langRes,
      objectsIndex(state),
      takesSelector(state),
      linesSelector(state),
    )
  )
}

// This one is different from playLine because it only plays a line if it has audio file associated
// playLine fallbacks to webAPI TTS
export const playLineAudio = (lineId, audio) => (dispatch, getState) => {
  const state = getState()
  const aToken = tokenSelector(state)
  const revisionId = revisionIdSelector(state)

  return playSound(aToken, revisionId, audio, lineId)
}

export const stopAll = (clearingClips = true) => async () => {
  // this sleep here prevents that the next line gets executed before the next node is started
  await sleep(80)
  stopAllTts(clearingClips)
  stopAllSounds()
}

export const pauseAll = () => () => {
  pauseAllSounds()
  pauseAllTts()
}

export const resumeAll = () => () => {
  resumeAllSounds()
  resumeAllTts()
}

const hasMetadata = ({ markups, notes }) => !isEmpty(markups) || !isEmpty(notes)
const playLine = async (aToken, revisionId, langRes, speechPrefs = {}, playbackId, lineId, errorWhenAudioNotFound = false, playDirty = false, isDirty = false) => {
  const { audio, text } = langRes
  // maybe this could be configurable ? to omit lines that have pure metadata
  if (isEmptyOrNull(text) && hasMetadata(langRes)) {
    return Promise.resolve()
  }

  if (audio && (playDirty || (!playDirty && !isDirty))) {
    try {
      await playSound(aToken, revisionId, audio, lineId)
      return
    } catch (e) {
      if (errorWhenAudioNotFound) {
        // error playing sound, we fail fast and report
        throw e;
      }
    }
  }

  return textToSpeech(text, speechPrefs, playbackId)
}

export const uploadAudio = postResource(ResourceType.audio)
export const deleteAudio = deleteResource(ResourceType.audio)
