exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameColumn__mENNP .name__1Aoiv{font-weight:bolder;padding-right:.5rem}.nameColumn__mENNP .separator__1M1LX{color:gray;padding-right:.2rem}.actions__2O9qL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:space-evenly;justify-content:space-evenly}", ""]);

// exports
exports.locals = {
	"nameColumn": "nameColumn__mENNP",
	"name": "name__1Aoiv",
	"separator": "separator__1M1LX",
	"actions": "actions__2O9qL"
};