import { getProviderFor } from 'model/label'
import { length, split } from 'ramda'

export const applyLabel = (label, withSpace) => (withSpace ? ' ' : '') + label

export const Types = {
  Variable: 'variable',
  NodeRef: 'nodeRef',
  Value: 'value',
  Fact: 'fact',
  Operator: 'operator',
  Keyword: 'keyword', // Default
}

export const iconTypeByCompletion = obj => {

  // TODO: review this
  const IconByType = {
    [Types.Variable]: () => 'font-size',
    [Types.NodeRef]: ({ detail }) => getProviderFor(detail)?.icon,
    [Types.Value]: () => 'italic',
    [Types.Fact]: () => 'font-colors',
    [Types.Keyword]: () => 'highlight',
  }
  
  return (IconByType[obj.type] || IconByType.keyword)(obj)
}

// Completion objects
export const objCompletion = ({ id, label, sys }, withSpace) => ({
  label: label || id,
  apply: applyLabel(`"${id}"`, withSpace),
  detail: sys,
  type: Types.NodeRef,
  boost: 99
})

export const objCompletionWithDelimiters = ({ id, label, sys }, withSpace) => ({
  label: `"${label || id}"`,
  apply: applyLabel(`"${id}"`, withSpace),
  detail: sys,
  type: Types.NodeRef,
  boost: 99
})

export const factCompletion = (label, withSpace) => ({
  label,
  apply: applyLabel(`${label}()`, withSpace),
  detail: 'fact',
  type: Types.Fact,
  boost: 50,
})

export const variableCompletion = ({ name, kind }, withSpace) => ({
  label: name,
  apply: applyLabel(length(split(' ', name)) > 1 ? `<<${name}>>` : name, withSpace),
  detail: `${kind} variable`,
  type: Types.Variable,
  boost: 50,
})

export const keywordCompletion = (upperCase, withSpace) => keyword => {
  const label = upperCase ? keyword.toUpperCase() : keyword
  return ({
    label,
    apply: applyLabel(label, withSpace),
    detail: 'keyword',
    type: Types.Keyword,
    boost: -99
  }) 
}

export const valueCompletion = (transform, detail, type, withSpace) => option =>
  labelOption(transform(option), detail, type, withSpace)

export const labelOption = (label, detail, type, withSpace) => ({
  label,
  apply: applyLabel(label, withSpace),
  detail,
  type,
  boost: 99
})