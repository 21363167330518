import React from 'react'
import { propOr } from 'ramda'
import { isNumber, isBoolean } from 'ramda-adjunct'
import StringValue from 'components/PropertiesEditor/Value/StringValue'
import { NumberValue } from 'components/PropertiesEditor/Value/NumberValue'
import BoolValue from '../PropertiesEditor/Value/BoolValue'

// TODO: if we want to be clever we would need a type system
//  for the state.
const componentFor = value => {
  if (isNumber(value)) return NumberValue
  else if (isBoolean(value)) return BoolValue
  return StringValue
}

const VariableValue = ({ variable, onChange }) => {
  const ValueEditor = componentFor(variable.value)
  return (
    <ValueEditor
      isEditable
      value={propOr('', 'value', variable)}
      onValueChanged={newValue => { onChange(variable.name, newValue) }}

      coerceInferringTypeByValue
    />
  )
}

export default VariableValue