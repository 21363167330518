import { LRLanguage, LanguageSupport } from '@codemirror/language'
import { autocompletion } from '@codemirror/autocomplete'
import { parserWithMetadata } from '../Parser/ExtendedParser'
import { autocompleteIcon } from '../AutocompleteComponent'
import { keywordList } from './TreeUtils'
import {
  contextCompletionAssignments,
  contextCompletionVariables,
  contextCompletionOptions,
  completeConstantValues,
  completionTemplates,
  completeKeywords,
  factValuesCompletion,
} from './Autocomplete'

export const ruleLanguage = LRLanguage.define({
  parser: parserWithMetadata,
  languageData: {
    closeBrackets: { brackets: ['(', '<<', '[', '{', "'", '"', '`'] },
    indentOnInput: /^\s*(?:case |default:|\{|\}|<\/)$/,
    wordChars: '$'
  }
})

export const constantValues = ['true', 'false', 'nothing']

export const AUTOCOMPLETE_ICON_POSITION = 20

const autocompleteExtension = ({
  store,
  factNames,
  variables,
  findMatchRefsPromise,
  registerAutocompletion,
  factTypesIndex,
  possibleStaticVariablesValues,
}) => autocompletion({
  icons: false,
  override: [
    completeConstantValues,
    completionTemplates,
    completeKeywords(keywordList, false),
    contextCompletionVariables(variables, factNames),
    contextCompletionAssignments(store, possibleStaticVariablesValues, variables),
    factValuesCompletion(factTypesIndex),
    contextCompletionOptions(store, possibleStaticVariablesValues, findMatchRefsPromise),
  ],
  addToOptions: [{
    position: AUTOCOMPLETE_ICON_POSITION,
    render: autocompleteIcon(registerAutocompletion)
  }],
})

export default config => new LanguageSupport(ruleLanguage, autocompleteExtension(config))