import { mergeDeepRight } from 'ramda'
import { compose, withProps } from 'recompose'
import withSecurityScope from 'hocs/withSecurityScope'

const Secure = ({ scope, check, children, placeholder = null }) => (check(scope) ? children : placeholder)

// we still give support (backward compat) to the "context" prop
// there you can manually override any context-scope property

export default compose(
  withSecurityScope,
  withProps(({ scope = {}, context = {} }) => ({
    scope: mergeDeepRight(scope, context)
  }))
)(Secure)
