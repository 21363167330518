import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil/dist'
import { currentSearchState, searchesState } from '../../state/search/searches'
import { EMPTY_ARRAY } from '../../utils/object'

// clears global search state (recoil atoms) when the project changes

const SearchHistory = ({ revisionId }) => {
  const setSearches = useSetRecoilState(searchesState)
  const setCurrentSearch = useSetRecoilState(currentSearchState)

  useEffect(() => {
    setSearches(EMPTY_ARRAY)
    setCurrentSearch(undefined)
  }, [revisionId])

  return null
}

export default SearchHistory