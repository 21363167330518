import React, { useCallback, useMemo } from 'react'
import { path } from 'ramda'
import { useDispatch } from 'react-redux'
import { MenuItem } from '@blueprintjs/core'

import { schema } from 'beanie-engine-api-js'
import { dotSplit } from 'utils/string'

/**
 * A generic menu item used to pick 1 of multiple values to be set to a property/path.
 * For example to set "data.mode" with one of the possible enumeration values like: Jump & Jump And Return.
 * It uses the schema to
 *  - find out what the possible values are
 *  - find out the default value and mark it as the current one if there is none.
 * It is also based on a command to specify when it is enabled, which label to use, etc.
 *
 * The Jump example will look like this
 *
 * `---------`
 * |  Mode  > `------------`
 * `---------`| (*) Jump   |
 *            | ( ) Return |
 *            `------------`
 *
 */
/**
 * @param { ..., property } - property must be a string, dot separated path to the property
 */
const SetPropertyMenuItem = ({ o, command, property }) => {
  const propertyValue = path(dotSplit(property), o)
  const defaultValue = useMemo(() => schema.defaultValueOf(o.sys, property), [o, property])
  const currentValue = useMemo(() => propertyValue || defaultValue, [propertyValue, defaultValue])
  const dispatch = useDispatch()

  const boundedAction = useCallback((...args) => {
    dispatch(command.action(...args))
  }, [dispatch, command])


  return (!command.visible || command.visible(o)) ? (
    <MenuItem text={command.label} icon="build" /* BEHAVIOR ICON */>
      {schema.enumValuesOf(o.sys, property).map(option => (
        <MenuItem
          {...{ className: `anticon ${option === propertyValue ? 'anticon-check' : ''}` }}
          key={option}
          text={option}
          disabled={option === propertyValue}
          onClick={() => {
            boundedAction(o, option)
          }}
          icon={option === currentValue ? 'tick' : 'blank'} // Temp to make blueprint.js icons work - kb
        />
      ))}
    </MenuItem>
  ) : null
}

export default SetPropertyMenuItem
