import React from 'react'
import { Table } from 'antd'
import { pipe } from 'ramda'

import { model } from 'beanie-engine-api-js'

import { safeToUpperCase, sorterBy } from '../../utils/string'
import styles from './StateFacts.scss'

const { types: { object: { getName } } } = model

const { Column } = Table
const nameSorter = sorterBy(pipe(getName, safeToUpperCase))

/**
 * Shows the facts as a flat list.
 * THe other option is to use HierarchicalStateFacts.
 * This is the original view.
 * We are sharing code between the two impls as far as we can
 */
const ListStateFacts = ({ facts, filteringProps, renderFactName, renderFactValue, renderFactType, renderFactActions, renderFooter }) => {
  return (
    <Table
      dataSource={facts}
      rowKey="id"
      size="small"
      footer={renderFooter}
      pagination={false}
      scroll={{ y: true }}
      locale={{
        emptyText: 'No Facts'
      }}
    >
      <Column
        className={styles.nameColumn}
        title="Name"
        key="name"
        sorter={nameSorter}
        {...filteringProps()}
        render={renderFactName}
      />
      <Column
        className={styles.typeColumn}
        title="Type"
        key="Type"
        render={renderFactType}
      />
      <Column
        className={styles.valueColumn}
        title="Value"
        key="value"
        render={renderFactValue}
      />
      <Column
        className={styles.actionsColumn}
        title="Actions"
        key="actions"
        render={renderFactActions}
      />
    </Table>
  )
}

export default ListStateFacts