import { path, T, } from 'ramda'
import computeHoverPosition from './dnd/computeHoverPosition'

const extractComponent = c => (c ? c.decoratedComponentInstance || c : undefined)

const fromPropOrComponent = (methodName, defaultFn) => (props, monitor, component) => {
  if (props[methodName]) return props[methodName](monitor)
  const comp = extractComponent(component)
  if (comp && comp[methodName]) return comp[methodName](monitor)

  return defaultFn && defaultFn(monitor)
}

// this now supports props or component.method or nothing
export const source = (canDrag = T) => ({
  beginDrag: fromPropOrComponent('onBeginDrag'),
  endDrag: fromPropOrComponent('onEndDrag', T),
  canDrag
})

/**
 * @deprecated this is pretty tight to components as classes which we don't follow anymore
 * And it makes the code very tight/custom with things that can be easily done with hocs or better
 * with hooks
 */
export const target = (canDrop = T, hoverPositionPropElementId) => ({
  drop: (props, monitor, component) => component.onDrop(monitor),
  canDrop,
  hover: (props, monitor, component) => {
    if (hoverPositionPropElementId) {
      const elementId = path(hoverPositionPropElementId, component.props)
      const { state: { hoverPosition } } = component
      const setHoverPosition = pos => {
        component.setState({ hoverPosition: pos })
      }
      computeHoverPosition(elementId, hoverPosition, setHoverPosition, monitor, component)
    }
    return component?.onHover?.(monitor)
  }
})

export const sourceConnector = (conn, monitor) => ({
  connectDragSource: conn.dragSource(),
  isDragging: monitor.isDragging() || false
})

export const targetConnector = () => (conn, monitor) => ({
  connectDropTarget: conn.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.isOver() && monitor.canDrop(),
  ...monitor.isOver() && {
    hoveringItem: monitor.getItem()
  }
})

// utilities

export const canDropIfItem = fn => (props, monitor) => fn(monitor.getItem())
