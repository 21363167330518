import { ResourceType } from 'model/resources'

export const LoadingState = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR'
}

export const VideoPlayerStatus = {
  LOADING: 'LOADING',
  PAUSED: 'PAUSED',
  READY: 'READY',
  PLAYING: 'PLAYING',
  STOPPED: 'STOPPED' 
}

export const ChoicesResultType = {
  EXPIRED: 'EXPIRED',
  SELECTED: 'SELECTED'
}

export const VideoSources = {
  studio: 'studio',
}

// TODO: use model/resources
// in part duplicated with model/resources.js
const relative = path => `${window.location.origin}/${path}`
const urlForResource = type => (project, file = '') => relative(`api/resources/${project}/${type}/${file}`)
/* (project, file) */
const urlForVideo = urlForResource(ResourceType.video)

export const studioVideoUrl = projectName => urlForVideo(projectName, 'movie.mp4')
