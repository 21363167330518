import React, { useState, useEffect } from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { prop, identity } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import classNames from 'classnames'

import { ignoreEvent } from 'dom/events'
import { EMPTY_OBJECT } from 'utils/object'
import { EMPTY_STRING } from 'utils/string'
import { isCurrentRevisionWritable } from 'security/project'

import { makeIsEditingSelector } from 'selectors/ui'

import { Creators } from 'actions/ui'

import withUser from 'hocs/withUser'
import { pathFromProps } from 'selectors/props'

import BeanieLanguageEditor from 'components/Language/Rules/BeanieLanguageEditor'
import { lang } from 'beanie-engine-api-js'
import useSecureCheck from '../../../../../hooks/security/useSecureCheck'
import CellLabel from './CellLabel'

import styles from '../TruthTable.scss'

const { rule: { parser, error: { isError, isStaticError } } } = lang

const { editStart, editEnd } = Creators
import { focusOnSelectedNode, forceFocusOnSelectedNode } from 'actions/nodes'

/**
 *
 */
const _TruthTableCell = ({
  isEditing,
  iconComponent,
  issuesComponent,
  value,
  setValue,
  cellStyle,
  onDoubleClick,
  onEnter,
  onCancel,
  onContextMenu,
  expectedType,
  withSyntheticMatch,
  rule: { source, rule },
  sourceToShow = identity,
  cellLabelStyle,
  shouldEval,
  resultErrorStyle,
  result,
}) => (
  <div
    onContextMenu={onContextMenu}
    onDoubleClick={onDoubleClick}
    onClick={isEditing ? ignoreEvent : null}
    className={classNames(styles.truthTableCell, cellStyle,
      {
        [styles.editing]: isEditing
      },
      !isEditing && shouldEval && isNotNil(result) && isError(result) && !isStaticError(result) ? resultErrorStyle : EMPTY_OBJECT,
    )}>
    {isEditing ?
      <BeanieLanguageEditor
        expectedType={expectedType}
        withSyntheticMatch={withSyntheticMatch}
        className={styles.editor}
        autoFocus

        setValue={setValue}
        value={value}

        onBlur={onEnter}
        onCancel={onCancel}
        onApply={onEnter}
      /> :
      <CellLabel
        source={sourceToShow(source)}
        rule={rule}
        shouldEval={shouldEval}
        result={result}
        iconComponent={iconComponent}
        issuesComponent={issuesComponent}
        cellLabelCustomStyle={cellLabelStyle}
      />
    }
  </div>
)

const TruthTableCell = compose(
  withUser,
  connect(() => {
    const isEditingSelector = makeIsEditingSelector(pathFromProps(['node', 'id']), (_, { affectedPath }) => affectedPath)
    return (state, props) => ({
      isEditing: isEditingSelector(state, props),
    })
  }, dispatch => ({
    editEndAction: () => {
      dispatch(editEnd())
      dispatch(forceFocusOnSelectedNode())
    },
    editStartAction: (...params) => {
      dispatch(editStart(...params))
      dispatch(focusOnSelectedNode())
    },
  })),
  withHandlers({
    edit: ({ isEditing, editStartAction, node, affectedPath }) => () => {
      if (!isEditing) {
        editStartAction(node.id, affectedPath)
      }
    },
    onEnter: ({ value: source, node, editAction, editRuleToInsert = identity, editEndAction, index }) => async () => {
      const rule = parser(source)
      await editAction(node, index, editRuleToInsert({ source, rule }))
      editEndAction()
    },
    onCancel: ({ setValue, rule, editEndAction }) => async () => {
      setValue(prop('source', rule))
      editEndAction()
    },
  }),
  withHandlers({
    onDoubleClick: ({ edit, isEditing, writeAccess }) => event => {
      event.preventDefault()
      event.stopPropagation()
      if (writeAccess && !isEditing) edit()
    },
  })
)(_TruthTableCell)

export default props => {
  const { rule } = props

  const [value, setValue] = useState(rule?.source || EMPTY_STRING)

  const writeAccess = useSecureCheck(isCurrentRevisionWritable)

  useEffect(() => {
    setValue(rule?.source || EMPTY_STRING)
  }, [rule?.source])

  return (
    <TruthTableCell
      value={value}
      setValue={setValue}
      writeAccess={writeAccess}
      {...props}
    />
  )
}
