import WaitingSubject from 'observables/WaitingSubject'
import { receiveObjectChangeSet } from 'actions/project'
import { objects } from '../../selectors/apollo'

/**
 * Store implementation on top of the Redux store
 */
class LocalStore {

  constructor(store) {
    this.store = store
    this.subject = new WaitingSubject()
  }

  getReduxStore() { return this.store }

  apply(changeSet) {
    return this.store.dispatch(receiveObjectChangeSet(changeSet))
  }

  subscribe(...args) { this.subject.asObservable().subscribe(...args) }

  // only used for tests
  toArray() {
    return objects(this.getReduxStore().getState())
  }

}

export default LocalStore