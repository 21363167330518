import React, { useCallback } from 'react'
import { Icon, notification } from 'antd'

import notificationsBroadcastSubscriptionQuery from 'api/subscriptions/notificationsBroadcast.graphql'
import useSubscription from '../../hooks/graphql/useSubscription'

/**
 * Subscribes to studio broadcast messages and show them as popups on the right
 */
const NotificationsBroadcastSubscription = () => {

  const onEvent = useCallback(({ data: { notificationBroadcast: { title: message, description } } }) => {
    notification.open({
      message,
      description,
      icon: <Icon type="bell" style={{ color: '#6363a9' }} />,
      duration: 0 // duration Infinity was useful for shutdown broadcast message
    })
  }, [])

  const onError = useCallback(error => {
    notification.open({
      message: 'Communication Error',
      description: `${JSON.stringify(error)}`,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
    })
  }, [])

  useSubscription({
    query: notificationsBroadcastSubscriptionQuery,
    active: true,
    onEvent,
    onError,
  })

  return null
}

export default NotificationsBroadcastSubscription