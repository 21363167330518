import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { message, Drawer, Button, Icon } from 'antd'
import { update, findIndex, without, find, propEq, prepend, all } from 'ramda'

import ManageExtensionTablesWithCallbacks from './ManageExtensionTablesWithCallbacks'
import ExtensionAceEditorWithCallbacks from './ExtensionAceEditorWithCallbacks'

import { addExtension, getExtensionContent, deleteExtension, changeExtensionName, publishExtension } from 'actions/extensions'

import styles from './ManageEngineExtensions.scss'

const isOk = propEq('status', 'ok')

export const LUA_FILE_EXTENSION = 'lua'

const ManageEngineExtensionsView = props => {
  const { custom, setCustom, loadingCustom } = props
  const dispatch = useDispatch()
  const [selected, setSelected] = useState()
  const [loading, setLoading] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [visibleList, setVisibleList] = useState(false)

  const withLoading = useCallback(async func => {
    setLoading(true); await func(); setLoading(false)
  }, [setLoading])

  const findExtensionIndex = useCallback(fileName =>
    findIndex(elem => elem.fileName === fileName, custom), [custom])

  const loadExtensionContent = extension => withLoading(async () => {
    try {
      const content = await dispatch(getExtensionContent(extension))
      const updated = { ...extension, content }
      const index = findExtensionIndex(extension.fileName)
      setCustom(update(index, updated, custom))
      setSelected({ ...extension, content })
    } catch (e) {
      message.error(`Error trying to read extension: ${e.message}`, 3)
    }
  })

  const updateFileName = async (oldName, newName) => {
    const current = find(propEq('fileName', newName), custom)

    if (!current) {
      const index = findExtensionIndex(oldName)
      const updated = { ...custom[index], fileName: newName }
      await withLoading(async () => {
        const response = await dispatch(changeExtensionName({ fileName: oldName }, newName))
        if (all(isOk, response)) {
          setCustom(update(index, updated, custom))
          if (selected?.fileName === oldName) {
            setSelected({ ...selected, fileName: newName })
          }
        }
      })
    } else {
      message.error(`There is already an extension with name ${newName}`, 3)
      setSelected(current)
    }
  }

  const updateFileContent = async ({ fileName }, newContent) => {
    const index = findExtensionIndex(fileName)
    const updated = { fileName, content: newContent }
    withLoading(async () => {
      const response = await dispatch(addExtension(updated))
      if (isOk(response)) {
        setCustom(update(index, updated, custom))
        setSelected({ ...selected, content: newContent })
      } else {
        message.error(`There was an error saving the extension: ${response.message}`, 3)
      }
    })
  }

  const publishCustomExtension = async extension => {
    withLoading(async () => {
      const response = await dispatch(publishExtension(extension))
      if (!isOk(response)) {
        message.error(`There was an error publishing the extension: ${response.message}`, 3)
      }
    })
  }

  const createCustomExtension = async (fileName, content) => {
    const index = findExtensionIndex(fileName)
    if (index !== -1) {
      message.error(`There is already an extension with name ${fileName}`, 3)
      return
    }

    const extension = { fileName, content }

    withLoading(async () => {
      const response = await dispatch(addExtension(extension))
      if (isOk(response)) {
        setCustom(prepend(extension, custom))
        setSelected(extension)
      } else {
        message.error(`There was an error creating the extension: ${response.message}`, 3)
      }
    })
  }

  const deleteCustomExtension = async extension => {
    withLoading(async () => {
      const response = await dispatch(deleteExtension(extension?.fileName))
      if (isOk(response)) {
        setCustom(without([extension], custom))
        if (selected === extension) {
          setSelected(undefined)
        }
      } else {
        message.error(`There was an error deleting the extension: ${response.message}`, 3)
      }
    })
  }

  return (
    <div className={styles.manageEngineExtensionsModalContainer}>
      <div style={{ width: '2rem', height: 100 }}>
        <Button type="primary" onClick={() => setVisibleList(true)}>
          <Icon type="unordered-list" />
        </Button>
      </div>

      <Drawer
        title="List"
        placement="left"
        closable
        onClose={async () => {
          setVisibleList(false)
          if (selected?.fileName && !selected.content) {
            await loadExtensionContent(selected)
          }
        }}
        visible={visibleList}
        getContainer={false}
        style={{ position: 'absolute', left: 0 }}
        bodyStyle={{ width: 500 }}
        width="auto"
      >
        <ManageExtensionTablesWithCallbacks
          selected={selected}
          setSelected={setSelected}
          hasChanges={hasChanges}
          loadExtensionContent={loadExtensionContent}
          setVisibleList={setVisibleList}
          loading={loadingCustom || loading}
          setLoading={setLoading}
          updateFileName={updateFileName}
          createCustomExtension={createCustomExtension}
          deleteCustomExtension={deleteCustomExtension}
          {...props}
        />
      </Drawer>

      <ExtensionAceEditorWithCallbacks
        createCustomExtension={createCustomExtension}
        selected={selected}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        updateFileContent={updateFileContent}
        publishCustomExtension={publishCustomExtension}
        loading={loadingCustom || loading}
        setLoading={setLoading}
        {...props}
      />
    </div>
  )
}

export default ManageEngineExtensionsView
