import fs from 'fs'
import ospath from 'path'

import { removeBytecodeFromPath } from '../utils'

const readFile = (fname, cb) => {
  const filePath = removeBytecodeFromPath(fname)
  try {
    cb(
      fname,
      fs.readFileSync(
        ospath.join(__dirname, '..', '..', '..', 'node_modules', '@brunerhouse', 'beanie-engine', 'lua', filePath),
      ).toString('utf8')
    )
  } catch (err) {
    cb(err.message, null)
  }
}

export default readFile
