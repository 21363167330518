exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".TypeBadgeContainer__3ygSt{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;height:100% !important;-ms-flex-align:center;align-items:center;overflow-y:auto;cursor:help;padding-left:2rem !important;min-height:1.2em;padding:1rem;color:rgba(128,128,128,.77)}.Tooltip__14Ng8 .ant-tooltip-inner{background-color:#fff !important;color:rgba(0,0,0,.65)}.Description__rFZm4 .Explanation__k-6XA{margin-bottom:.5rem}.Description__rFZm4 .Examples__3MGPS .ExamplesTitle__2FQgo{font-weight:bold;margin-bottom:.5rem}.Description__rFZm4 .Examples__3MGPS .Example__Mk9an{font-style:italic}.Error__2AKhP{color:#f67a7a}.BasicType__1DMuq{display:-ms-flexbox;display:flex}.UnionType__7GkBY{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}.UnionType__7GkBY .UnionTypeElement__2ZOze{display:contents;-ms-flex-align:baseline;align-items:baseline;-ms-flex-wrap:wrap;flex-wrap:wrap}.UnionType__7GkBY .UnionTypeElement__2ZOze .Or__3D9pW{display:table-cell !important;padding-left:.1rem;padding-right:.1rem}.SetType__2gl7a{display:-ms-flexbox;display:flex;-ms-flex-align:baseline;align-items:baseline;-ms-flex-wrap:wrap;flex-wrap:wrap}.SetType__2gl7a .Element__aA8AT{display:table-cell !important;font-family:\"Courier New\",Courier,monospace}.SetType__2gl7a .Commma__yHn-x{display:table-cell !important;padding-right:.5rem;font-weight:normal}.UnionTypeSuperType__1Uawt{display:-ms-flexbox;display:flex;color:#137cbd}.SuperClassSetType__pFF6Z{display:-ms-flexbox;display:flex;color:#137cbd}", ""]);

// exports
exports.locals = {
	"TypeBadgeContainer": "TypeBadgeContainer__3ygSt",
	"Tooltip": "Tooltip__14Ng8",
	"Description": "Description__rFZm4",
	"Explanation": "Explanation__k-6XA",
	"Examples": "Examples__3MGPS",
	"ExamplesTitle": "ExamplesTitle__2FQgo",
	"Example": "Example__Mk9an",
	"Error": "Error__2AKhP",
	"BasicType": "BasicType__1DMuq",
	"UnionType": "UnionType__7GkBY",
	"UnionTypeElement": "UnionTypeElement__2ZOze",
	"Or": "Or__3D9pW",
	"SetType": "SetType__2gl7a",
	"Element": "Element__aA8AT",
	"Commma": "Commma__yHn-x",
	"UnionTypeSuperType": "UnionTypeSuperType__1Uawt",
	"SuperClassSetType": "SuperClassSetType__pFF6Z"
};