import React from 'react'
import Draft, { DraftEditorBlock } from 'draft-js'
import Immutable from 'immutable'

import ChoiceBlock from 'components/TextEditor/Blocks/choices/ChoiceBlock'
import ChoicesWrapper from 'components/TextEditor/Blocks/choices/ChoicesWrapper'
import BlockType from './BlockType'

export const blockRenderer = contentBlock => {
  const type = contentBlock.getType()
  switch (type) {
    // choices
    case BlockType.choice: return {
      element: 'li',
      component: ChoiceBlock,
      wrapper: <ChoicesWrapper />
    }
    default: return {
      component: DraftEditorBlock,
    }
  }
}

export const blockRenderMap = Draft.DefaultDraftBlockRenderMap.merge(Immutable.Map({
  [BlockType.choice]: { element: 'li', wrapper: <ChoicesWrapper /> }
}))