import { isNil } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { lang } from 'beanie-engine-api-js'

import useBooleanState from '../../react/useBooleanState'
import useDebouncedCallback from '../../react/useDebouncedCallback'
import useEvaluationContext from './useEvaluationContext'

import { isInitial } from 'selectors/playback'
import { currentTypingContext } from 'selectors/objects/facts'

const { rule: { typing: { infer }, parser, interpreter: { evaluate }, error: { isError } } } = lang

const EVAL_DEBOUNCE_TIME = 1000

/**
 * A "big" hook that does beanie language expressions evaluation.
 * It does both things:
 *  - validate the expression statically (parses it)
 *  - execute the expression if that's possible (we have a state)
 */
const useExpressionEvaluator = (expressionString, _setExpressionString, evaluatorFunction = evaluate, itValue = null) => {
  // parsing
  const [parsedValue, setParsedValue] = useState(expressionString ? parser(expressionString) : undefined)
  const staticError = useMemo(() => (isError(parsedValue) ? parsedValue : undefined), [parsedValue])

  // typing
  const typingContext = useSelector(currentTypingContext)
  const [expressionType, setExpressionType] = useState(parsedValue ? infer(parsedValue, typingContext) : undefined)

  // runtime evaluation
  const [result, setResult] = useState()
  const runtimeError = useMemo(() => (isError(result) ? result : undefined), [result])
  const [isDirty, setDirty, unsetDirty] = useBooleanState(false)
  const isPlaying = useSelector(state => !isInitial(state))

  const context = useEvaluationContext(false, itValue)

  const shouldEval = isPlaying

  const onChanged = useDebouncedCallback(newValue => {
    const parsed = parser(newValue)
    setParsedValue(parsed)
    setExpressionType(infer(parsed, typingContext))
    if (shouldEval) {
      setResult(evaluatorFunction(context, parsed))
    }
    unsetDirty()
  },
  [context, setResult, unsetDirty, shouldEval, setParsedValue],
  EVAL_DEBOUNCE_TIME
  )

  const setValue = useCallback(nextValue => {
    _setExpressionString(nextValue)
    setDirty()
    onChanged(nextValue)
  }, [onChanged, _setExpressionString, setDirty])

  // on mount
  useEffect(() => {
    if (expressionString) {
      onChanged(expressionString)
    }
  }, [])

  // playing turn on
  useEffect(() => {
    if (shouldEval && isNil(result) && expressionString) {
      onChanged(expressionString)
    }
  }, [shouldEval, result, expressionString])

  return {
    setValue,

    parsedValue,
    staticError,
    isDirty,

    // typing
    expressionType,

    // runtime
    result,
    shouldEval,
    runtimeError,

    typingContext,

    // errors
    error: staticError || runtimeError,
  }
}

export default useExpressionEvaluator