import MatchSourceType from './MatchSourceType'

//
// LabelMatchSource
//

export const LABEL_MATCH_SOURCE = { type: MatchSourceType.label }

/**
 * The result "source" for label searches
 */
export const LABEL_MATCH_SOURCE_DATA = {
  type: MatchSourceType.label
}

//
// DerivedMatchSource
//

/**
 * Creates a new DerivedMatchSource implementation of MatchSource.
 * It specifies a list of paths as a breadcrumb to navigate objects
 * to reach text (or a list of texts).
 *
 * Simplest example is to specify a text field within the object itself
 *     paths = [ ['description'] ]
 *
 * A variant is with a nested field
 *
 *     paths = [ ['address', 'street'] ]
 *
 * Which can also be specified with > 1 path element instead of a composed, although it doesn't make much sense
 *
 *     paths = [ ['address'], ['street'] ]
 *
 * Both this examples will work fine for an object like this
 *
 * {
 *   id: 'x',
 *   address: {
 *     street: 'Evergreen'
 *   }
 * }
 * Resolving the "Evergreen" value
 *
 * Multiple paths make more sense considering that DerivedMatchSource supports **reference resolution**. In case
 * the object has a field referencing another object the interpreter knows how to resolve them (not need to have the schema)
 * This resolution is only considered between the output of a path element and the input of the next path element.
 *
 *     path = [ ['owner'], ['data', 'name'] ]
 *
 * This will work fine for this example object
 *    {
 *      id: 'A'
 *      owner: 'bne_obj_id_B',
 *    }
 * where
 *    {
 *      id: 'B'
 *      data: { name: 'John' }
 *    }
 * It will derive the 'John' value.
 *
 * The other main feature DerivedMatchSource supports are **array fields**.
 * If a path element points to an array field then computing it (the interpreter) will collect every element.
 * So the chain of paths is automatically "unboxes" arrays to map their elements.
 * This will produce an array of values as a result.
 *
 * Example
 *     paths = [
 *        ['data.notes'],  // -> array of objects
 *        ['data.text]     // -> get the data.text of each note object
 *     ]
 * Which for this
 * {
 *   id: 'x',
 *   data: {
 *     notes: [
 *       { text: 'a note' },
 *       { text: 'a note 2' },
 *       { text: 'a note 3' },
 *     ]
 *   }
 * }
 * Will produce: ['a note', 'a note 2', 'a note 3']
 *
 * This will also work with following arrays mapping. Like `notes -> comments ->`
 *
 * And this also works for array of references in case the array elements are normalized instead of embedded.
 */
export const derivedSource = (name, paths) => ({
  type: MatchSourceType.derived,
  name,
  paths
})

export const derivedMatchSourceData = (name, aPath) => ({
  type: MatchSourceType.derived,
  name,
  path: aPath
})