/* eslint-disable no-undef */
import { isEmpty } from 'utils/object'
import { lineToText } from './LineSerializer'
import { NODE_TYPES, OBJECT_TYPES } from '../utils/Constants'
import { emptyDirectorLine } from '../utils/slateMocks/directorLine'

export const clipToText = clip => ({
  object: 'value',
  isVoid: false,
  document: {
    data: { clip_id: clip.id, type: NODE_TYPES.CLIP },
    object: OBJECT_TYPES.document,
    nodes: isEmpty(clip.data.lines) ? [emptyDirectorLine] : clip.data.lines.map(lineToText)
  }
})