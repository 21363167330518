import { compose, lifecycle } from 'recompose'
import { DragSource } from 'react-dnd'

import { DnDTypes } from 'dnd/model-dnd'

import { DRAG_PARENT_ID } from './TreeView'

/**
 * Just a shortcut to DragSource which binds the drag preview with the TreeView DRAG_PARENT_ID
 * TODO: This is still kind of ad-hoc since just by using this HOC is not enough, you still need
 *   a custom draglayer specifically for customizing the view (instead of using here `connectDragPreview`)
 */
const withCustomDrag = ({ dragType = DnDTypes.Node, source, collect }) => compose(
  DragSource(dragType, source, collect || genericDragCollect),
  lifecycle({
    componentDidMount() {
      // REVIEWME: this is sort of dup with `useCustomDragPreview`.
      // But I couldn't reuse this HOC, using it in RowCell fails. Actually just using a lifecycle makes it fail !
      // there are some crazy things there
      const { connectDragPreview, currentOffset } = this.props

      const elem = document.getElementById(DRAG_PARENT_ID)
      if (elem) {
        if (currentOffset) {
          elem.style.left = `${currentOffset.x}px`
          elem.style.top = `${currentOffset.y}px`
        }
        elem.style.visibility = 'visible'
        connectDragPreview(elem)
      }
    },
  }),
)

const genericDragCollect = (c, monitor) => ({
  connectDragSource: c.dragSource(),
  connectDragPreview: c.dragPreview(),
  isDragging: monitor.isDragging(),
  currentOffset: monitor.getSourceClientOffset(),
})

export default withCustomDrag