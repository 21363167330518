import { propEq, allPass, pathEq, anyPass } from 'ramda'
import { isSuperAdmin } from './platform'
import { ProjectGrantRole, ProjectGrantToType, ProjectOwnerType } from 'utils/authentication'
import { EMPTY_ARRAY } from 'utils/object'
import { isOrgOwner } from './organization'

const isOwner = (user, project) => pathEq(['owner', '_id'], user._id, project)
export const isOrgOwnedProject = pathEq(['owner', '__typename'], ProjectOwnerType.Organization)

// grant checks
const isGrantOfRole = propEq('role')
const isGrantForAndRole = (to, role) => allPass([pathEq(['to', '_id'], to._id), isGrantOfRole(role)])

const isTeamGrant = pathEq(['to', '__typename'], ProjectGrantToType.Team)

const hasUserGrantOfRole = role => (user, project) => (project.grants || EMPTY_ARRAY).some(isGrantForAndRole(user, role))

const belongsToTeamWithGrantOfRole = role => (user, project) => (project.grants || EMPTY_ARRAY)
  .filter(allPass([isGrantOfRole(role), isTeamGrant]))
  .some(grant => user.teamsMemberships.some(pathEq(['team', '_id'], grant.to._id)))

const hasOrInheritsGrantOfRole = role => anyPass([
  hasUserGrantOfRole(role),
  belongsToTeamWithGrantOfRole(role)
])

const hasAdminGrant = (user, project) => (
  isOwner(user, project)
  || (isOrgOwnedProject(project) && isOrgOwner({ currentUser: user, organization: project.owner }))
  || hasOrInheritsGrantOfRole(ProjectGrantRole.ADMIN)(user, project)
)

export const projectAdminAccess = ({ currentUser, project }) => project && (isSuperAdmin(currentUser) || hasAdminGrant(currentUser, project))

export const projectWriteAccess = ({ currentUser, project }) => (
  project && (
    isSuperAdmin(currentUser)
    || hasAdminGrant(currentUser, project)
    || hasOrInheritsGrantOfRole(ProjectGrantRole.WRITE)(currentUser, project)
  )
)

export const projectTextReviewAccess = ({ currentUser, project }) => (
  project && hasOrInheritsGrantOfRole(ProjectGrantRole.TEXT_REVIEW)(currentUser, project)
)

export const isCurrentRevisionWritable = ({ currentUser, project, revision }) => (
  !!project && !!revision && projectWriteAccess({ currentUser, project }) && !!revision.modifiable
)

export const isTextEditable = ({ currentUser, project, revision }) => (
  !!project &&
  !!revision &&
  !!revision.modifiable &&
  (
    projectWriteAccess({ currentUser, project }) ||
      projectTextReviewAccess({ currentUser, project })
  )
)