import makeCollector from 'model/project/searches/makeCollector'
import graphOrderedVisitor from './visitors/graphOrderedVisitor'

/**
 * Performs a search by using
 * - a "visitor": knows how to traverse the state/objects
 * - a "search": a specification (data) of the type of search we will do: match | find
 * - a "getState": fn that knows how to access the state.
 */
const search = (visitor = graphOrderedVisitor) => (getState, aSearch) => {
  const results = []

  const collect = makeCollector(aSearch, getState, result => results.push(result))
  visitor(getState, collect)
  return results
}

export const searchAction = aSearch => (dispatch, getState) => search()(getState, aSearch)

export default search