import { curry, concat, path, reduce, pipe, __ } from 'ramda'

// TODO: reorganize/move this file as there is stuff not related with Organizations

const interpolate = curry((string, value) => string.replace(/:([^/]*)/, value))
const interpolateAll = baseRoute => reduce(interpolate, baseRoute)
const _ = (baseRoute, ...paths) => obj => interpolateAll(baseRoute)(paths.map(p => path(p.split('.'), obj)))

const projectSettings = '/p/:revisionId/settings'
const routes = {
  // depreacted
  oldProjectRoute: '/p/:revisionId',
  projectSettings,
  ttsJob: `${projectSettings}/tts/:ttsJobId`,

  // new form
  project: '/projects/:ownerName/:projectName/',

  organization: '/orgs/:organizationName/',
  team: '/orgs/:organizationName/teams/:teamName',
}

routes.oldProjectRouteForId = pipe(interpolate(routes.oldProjectRoute), concat(__, '/edit/'))

routes.projectSettingsForSection = (project, section) => interpolate(concat(`${routes.projectSettings}/`, section), project)
routes.ttsJobFor = _(routes.ttsJob, 'project._id', '_id')
routes.organizationFor = interpolate(routes.organization)
routes.teamForTeam = _(routes.team, 'organization.name', 'name')
routes.teamFor = interpolateAll(routes.team)

export default routes

