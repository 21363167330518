import { isEmptyOrNull } from 'utils/string'
import moment from 'moment'
import { LABEL_MATCH_SOURCE_DATA } from './collectors/match/sources/MatchSource'
import MatchSourceType from './collectors/match/sources/MatchSourceType'
import { idQuery, orQuery } from './query/queries'
import queryToText from './query/queryToText'

//
// searches
//

export const searchHistoryItem = (search, timestamp) => ({
  search,
  timestamp: timestamp || moment(),
})


export const SearchType = {
  match: 'match',
  find: 'find',
  composite: 'composite',
}

const search = (type, fields) => ({
  type,
  ...fields,
})

export const findSearch = (query, title) => search(SearchType.find, {
  query,
  ...title && { title }
})

export const matchSearch = (text, query) => {
  if (isEmptyOrNull(text) && query) return findSearch(query)
  return search(SearchType.match, {
    // what to match
    text: text.trim(),
    // optionally to filter scope on which objects to look up for matches
    query
  })
}

export const compositeSearch = (...searches) => (searches.length === 1 ? searches[0] : search(SearchType.composite, {
  searches,
}))

//
// high-level searches factory for common cases, wrote on top of the basic ones (above)
//

export const findByNodeIds = (aTitle, ids) => findSearch(orQuery(...ids.map(idQuery)), aTitle)

// behavior

export const title = aSearch => {
  if (aSearch.title) return aSearch.title
  switch (aSearch.type) {
    // TODO: this will need more work in the future
    case SearchType.match: return `matches "${aSearch.text}"`
    case SearchType.find: return `find "${queryToText(aSearch.query)}"`
    case SearchType.composite: return aSearch.searches.map(title).join(' or ')
    default: throw new Error(`Unknown search type ${aSearch.type}`)
  }
}

//
// Results
//

export const ResultType = {
  match: 'match',
  object: 'object'
}

// ObjectResult

export const createObjectResult = (object, node) => ({
  type: ResultType.object,
  object,
  ...node && { node }
})

// MatchResult

export const createMatchResult = (object, offset, length, node, source) => ({
  type: ResultType.match,
  // matched this object
  object,
  ...node && { node }, // optionally have info of the node that referred to this object.

  // specifically this "element" of the object (@see MatchElementType)
  source,
  offset, // at this offset (relative to the element)
  length, // and with this length (also relative)
})

// just a shortcut
export const createLabelMatchResult = (object, offset, length, node) => createMatchResult(object, offset, length, node, LABEL_MATCH_SOURCE_DATA)

const keyForMatchSourceData = source => {
  switch (source.type) {
    case MatchSourceType.label: return source.type
    case MatchSourceType.derived: return `${source.type}[${source.name}]`
    // nothing extra by default
    default: return source.type
  }
}

// eslint-disable-next-line prefer-template
const baseKey = result => `${result.node ? result.node.id + '-' : ''}${result.object.id}`
export const keyForResult = result => {
  switch (result.type) {
    case ResultType.match: return `${baseKey(result)}-${keyForMatchSourceData(result.source)}@${result.offset}`
    case ResultType.object: return baseKey(result)
    default: throw new Error(`unknown result type ${result.type}`)
  }
}
