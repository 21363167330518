import React from 'react'

import AvatarUpload from 'components/ActorSection/AvatarUpload'

const EntityAvatar = ({ shape = 'square', withoutTitle = false, entity, urlProvider, size = 128, noImageIcon, token, onImageChanged = () => {}, disabled = false, canUpload }) => (
  <AvatarUpload
    title={!withoutTitle && entity ? entity.name : ''}
    imageFile={entity && entity.avatar && urlProvider.get(entity._id, entity.avatar, token)}
    uploadURL={entity && urlProvider.post(entity._id, token)}
    onChange={entity && onImageChanged}
    shape={shape}
    size={size}
    noImageIcon={noImageIcon}
    disabled={disabled || !canUpload}
  />
)

export default EntityAvatar
