exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "@font-face{font-family:\"Muli\";src:url(\"/fonts/muli/Muli.ttf\");font-weight:normal;font-style:normal;font-display:fallback}@font-face{font-family:\"Muli\";src:url(\"/fonts/muli/Muli-SemiBold.ttf\");font-weight:bold;font-style:normal;font-display:fallback}@font-face{font-family:\"Muli\";src:url(\"/fonts/muli/Muli-Bold.ttf\");font-weight:700;font-style:normal;font-display:fallback}@font-face{font-family:\"Muli\";src:url(\"/fonts/muli/Muli-Light.ttf\");font-weight:lighter;font-style:normal;font-display:fallback}body{font-family:\"Muli\",\"OpenSans\",sans-serif}@font-face{font-family:\"Catamaran\";src:url(\"/fonts/catamaran/Catamaran-Regular.ttf\");font-weight:normal;font-style:normal;font-display:fallback}@font-face{font-family:\"Courier Prime\";src:url(\"/fonts/courier-prime/Courier Prime.ttf\");font-weight:normal;font-style:normal;font-display:fallback}@font-face{font-family:\"Courier Prime\";src:url(\"/fonts/courier-prime/Courier Prime Italic.ttf\");font-weight:normal;font-style:italic;font-display:fallback}@font-face{font-family:\"Courier Prime\";src:url(\"/fonts/courier-prime/Courier Prime Bold Italic.ttf\");font-weight:bold;font-style:italic;font-display:fallback}@font-face{font-family:\"Inconsolata\";src:url(\"/fonts/inconsolata/Inconsolata-Regular.ttf\");font-weight:normal;font-style:normal;font-display:fallback}@font-face{font-family:\"Inconsolata\";src:url(\"/fonts/inconsolata/Inconsolata-Bold.ttf\");font-weight:bold;font-style:normal;font-display:fallback}.page__1ZZ_h{background-color:#12005e;height:100%}.page__1ZZ_h *{color:#fff;font-family:\"Muli\",\"OpenSans\"}", ""]);

// exports
exports.locals = {
	"page": "page__1ZZ_h"
};