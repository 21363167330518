import { isEmpty, pathOr } from 'ramda'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { List, Spin } from 'antd'

import { EMPTY_ARRAY } from 'utils/object'

import withFetchMoreForPaging from 'hocs/withFetchMoreForPaging'

import objectsHistoryQuery from 'api/queries/objectHistory.graphql'

import { revisionId as revisionIdSelector } from 'selectors/project'

import LoadingMore from 'components/Homepage/projectView/LoadingMore'
import QueryError from 'components/Commons/QueryError'
import ObjectHistoryItem from './ObjectHistoryItem/ObjectHistoryItem'

import styles from './ObjectHistory.scss'

const CONTAINER_ID = 'OBJECT_HISTORY'

const list = pathOr(EMPTY_ARRAY, ['revisionWithId', 'changeSets', 'list'])

const ObjectHistory = ({ object, revision, fetchMore, hasMore }) => (object ? (
  <div id={CONTAINER_ID} className={styles.ObjectHistory}>
    {revision?.loading && isEmpty(list(revision)) &&
      <div className={styles.loading}><Spin />Loading</div>
    }
    {revision?.revisionWithId && (
      <InfiniteScroll
        scrollableTarget={CONTAINER_ID}
        dataLength={list(revision).length}
        next={fetchMore}
        hasMore={hasMore}
        loader={<LoadingMore />}
      >
        <List
          dataSource={list(revision)}
          renderItem={changeSet => (
            <ObjectHistoryItem object={object} changeSet={changeSet} />
          )}
        />
      </InfiniteScroll>
    )}
    {revision.error && <QueryError error={revision.error} />}
  </div>
) : null)

export default compose(
  connect(state => ({
    revisionId: revisionIdSelector(state),
  })),
  withFetchMoreForPaging({
    query: objectsHistoryQuery,
    name: 'revision',
    dataPath: ['revisionWithId', 'changeSets'],
    pageSize: 40,
    variables: ({ object, revisionId }) => ({ revisionId, objectIds: [object.id] }),
    graphqlProps: {
      skip: ({ object }) => !object
    }
  })
)(ObjectHistory)