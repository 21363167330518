import FunctionStats from './FunctionStats'
import { sortBy, take, values, pipe, map, reduce, prop, sortWith, descend, mapObjIndexed, always, filter } from 'ramda'
import { EMPTY_OBJECT, EMPTY_ARRAY } from '../object'

const LOG = false

export default class Stats {

  constructor() {
    this.selectors = {}
    this.prevSnapshot = {}
  }
  record(name, elapsed, args) {
    const stat = this.selectors[name] || new FunctionStats(name)
    stat.record(elapsed, args)
    this.selectors[name] = stat
    if (LOG) {
      /* eslint no-console: 0 */
      console.log(`${name}() took: ${elapsed.toFixed(3)} accumulated: ${this.selectors[name].toFixed(3)}`)
    }
  }
  flush() {
    this.selectors = {}
  }

  get(name) { return this.selectors[name] }

  // stats
  maxTimes() {
    return pipe(
      values,
      map(stat => ({ name: stat.name, max: stat.maxTime() })),
      sortBy(prop('max'))
    )(this.selectors)
  }
  minTimes() {
    return pipe(
      values,
      map(t => ({ name: t.name, min: t.minTime() })),
      sortBy(prop('min'))
    )(this.selectors)
  }
  mostCalled(n) { return take(n || 5, sortWith([descend(t => t.nrOfRuns())], values(this.selectors))) }

  totalAccumulatedTime() {
    return pipe(
      values,
      reduce((acc, t) => acc + t.totalAccumulatedTime(), 0)
    )(this.selectors)
  }

  logTable(regex) {
    console.table(createLogData(this.selectors, this.prevSnapshot, regex))
    this.snapshot()
  }

  snapshot() {
    this.prevSnapshot = mapObjIndexed(fs => fs.clone(), this.selectors)
  }

}

//
// logging data (output with logTable)
//

const formatElapsedTime = t => parseFloat(t.toFixed(3))

// this could be splitted and tested :P
const createLogData = (stats, prevStats, regex) => {

  const calcDifference = (fStats, method, { format }) => {
    const prevStat = prevStats[fStats.name]
    if (!prevStat) return EMPTY_OBJECT
    const rawDelta = fStats[method]() - prevStat[method]()
    const delta = format ? formatElapsedTime(rawDelta) : rawDelta
    return delta > 0 ? { [`(${method}) +/-`]: delta } : EMPTY_OBJECT
  }
  const difference = prevStats ? calcDifference : always(EMPTY_OBJECT)
  
  const numericField = (label, method, options = { format: true }) => fStats => {
    const value = fStats[method]()
    return {
      [label]: options.format ? formatElapsedTime(value) : value,
      ...difference(fStats, method, options)
    }
  }

  return pipe(
    Object.values,
    ...regex ? [filter(stat => regex.test(stat.name))] : EMPTY_ARRAY,
    
    map(fstats => ({
      name: fstats.name,
      
      ...numericField('runs', 'nrOfRuns', { format: false })(fstats),

      minT: formatElapsedTime(fstats.minTime()),
      maxT: formatElapsedTime(fstats.maxTime()),
      median: formatElapsedTime(fstats.medianTime()),

      ...numericField('acc', 'totalAccumulatedTime')(fstats)
    }))
  )(stats)
}