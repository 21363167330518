import { findDescendentOfType } from '../../nodeUtils'

export default () => ({

  queries: {

    rightNodeOfType: (editor, node, type) => {
      const parent = editor.parent(node)
      if (!parent) { return undefined }
      const found = findDescendentOfType(type)(parent)
      return found || editor.rightNodeOfType(parent, type)
    },

  },

  commands: {

    moveToRightSiblingOfType: (editor, type) => {
      const found = editor.rightNodeOfType(editor.currentNode(), type)
      if (found) {
        editor.moveToPath(editor.pathByKey(found.key))
      }
    }

  }

})