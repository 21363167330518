import React, { useMemo } from 'react'
import { Sys } from 'beanie-engine-api-js'
import switchComponent from 'hocs/switchComponent'
import { useSelector } from 'react-redux'
import { makePlaybackNodeById } from '../../../../selectors/playback'

import MinimizedClipPlayback from './MinimizedClipPlayback'
import CurrentChoicesPlayback from '../nodes/choices/CurrentChoicesPlayback'
import NonPresentablePlayback from '../nodes/CurrentNonPresentablePlayback'
import CurrentChoices2Playback from '../nodes/choices2/CurrentChoices2Playback'

/**
 * As react components are the ones that actually control node presentation here in the front
 * we need to register components to "present" them even when minimized and we are not presenting
 * them visually, if we need to produce sounds like in a clip, we still need a component here
 * For interactive components like choices we force presenting them so we are using the same components
 * as when non-minimized
 */
const Comp = switchComponent({
  path: ['node', 'sys'],
  cases: {
    [Sys.clip]: MinimizedClipPlayback,
    [Sys.choices]: CurrentChoicesPlayback,
    [Sys.choices2]: CurrentChoices2Playback,
  },
  otherwise: NonPresentablePlayback
})

const MinimizedNode = props => {
  const { playbackId } = props
  const selector = useMemo(() => makePlaybackNodeById(playbackId), [playbackId])
  const playbackNode = useSelector(selector)

  return (
    <Comp node={playbackNode} {...props} />
  )
}

export default MinimizedNode