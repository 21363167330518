import { model, lang } from 'beanie-engine-api-js'
import { makeExpressionCheckSelector } from './rule'

const { types: { object: { Paths } } } = model
const { rule: { typing: { types } } } = lang

export const makeFactErrorCheck = makeExpressionCheckSelector(Paths.fact.expr, 'Fact expression', types.Any)

export default [
  {
    name: 'Fact expression error',
    makeSelector: makeFactErrorCheck
  }
]