exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".problemsConfig__2RMJ6{display:-ms-flexbox;display:flex;width:100%}.problemsConfig__2RMJ6 .problemsConfigTable__o0Flj{display:-ms-flexbox;display:flex;width:100%}.problemsConfig__2RMJ6 .problemsConfigTable__o0Flj .ant-spin-nested-loading{width:100%}.problemsTable__32KMS .ant-spin-nested-loading{width:100%}.problemsTable__32KMS>.ant-spin-spinning{width:100%;padding-top:1rem}.problemsTable__32KMS .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content tr{line-height:0 !important}.problemsTable__32KMS .ant-table-thead th:first-child{width:10%}.problemsTable__32KMS .ant-table-thead th{width:45%}.problemsTable__32KMS .ant-table-body tr td:first-child{width:10%}.problemsTable__32KMS .ant-table-body tr td{width:45%}.checkBox__1YW_Z{display:-ms-flexbox;display:flex;padding-left:inherit}.objectsTitleContainer__1VMgP{display:-ms-flexbox;display:flex}.objectsTitleContainer__1VMgP .objectsTitle__1-Qqb{display:-ms-flexbox;display:flex;padding-left:1rem;-ms-flex-item-align:center;align-self:center}.objectsTitleContainer__1VMgP .filterDisableNodes__1mITi{display:-ms-flexbox;display:flex;font-size:1rem;color:gray}.ProblemsTitle__2ozyN .Counter__2OI1m{padding-left:.5rem}.description__jnUKn{line-height:normal}", ""]);

// exports
exports.locals = {
	"problemsConfig": "problemsConfig__2RMJ6",
	"problemsConfigTable": "problemsConfigTable__o0Flj",
	"problemsTable": "problemsTable__32KMS",
	"checkBox": "checkBox__1YW_Z",
	"objectsTitleContainer": "objectsTitleContainer__1VMgP",
	"objectsTitle": "objectsTitle__1-Qqb",
	"filterDisableNodes": "filterDisableNodes__1mITi",
	"ProblemsTitle": "ProblemsTitle__2ozyN",
	"Counter": "Counter__2OI1m",
	"description": "description__jnUKn"
};