import { dropLeadingSlash as dls } from 'utils/string'

import { urlForVideo } from 'model/resources'

import { Creators as UICreators } from 'actions/ui'
import { Creators as presetsCreators } from 'actions/presets'
import onRevisionLoaded from './project/onRevisionLoaded'
import { select, SELECT_ACTION } from './selection'

import { token as tokenSelector } from 'selectors/auth'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { activePreset } from 'selectors/presets'

export const selectRevision = select('revisionId')
export const selectProject = select('projectId')

export const RESET_PROJECT = 'RESET_PROJECT'

export const resetProject = () => ({
  type: RESET_PROJECT,
})

// project fetching

export const PROJECT_FETCHED = 'PROJECT_FETCHED'
export const projectFetched = revision => ({ type: PROJECT_FETCHED, revision })
export const CHANGE_SET = 'CHANGE_SET'
export const receiveObjectChangeSet = changeSet => ({ type: CHANGE_SET, changeSet })

// Load Revision

export const LOAD_REVISION_START = 'LOAD_REVISION_START'
export const loadRevisionStart = revision => ({ type: LOAD_REVISION_START, revision })
export const LOAD_REVISION_END = 'LOAD_REVISION_END'
export const loadRevisionEnd = revision => dispatch => {
  // trigger any behavior
  dispatch(onRevisionLoaded())
  // mark as ended
  dispatch({
    type: LOAD_REVISION_END, revision
  })
}

export const ON_REVISION_SESSIONS_SYNCHED = 'ON_REVISION_SESSIONS_SYNCHED'
export const onRevisionSessionsSynched = sessions => ({ type: ON_REVISION_SESSIONS_SYNCHED, sessions })

export const LOAD_REVISION_ERROR = 'LOAD_REVISION_ERROR'
export const loadRevisionError = error => ({
  type: LOAD_REVISION_ERROR,
  error: error.message,
  stack: JSON.stringify(error.stack || '')
})

// exported for test
export const restoreState = (dispatch, getState) => async () => {
  const preset = activePreset(getState())
  if (preset) return dispatch(presetsCreators.activatePreset(preset))
}

// markups cache updating

export const MARKUP_DEF_CREATED = 'MARKUP_DEF_CREATED'
export const markupDefCreated = markupDef => ({ type: MARKUP_DEF_CREATED, markupDef })

export const MARKUP_DEF_EDITED = 'MARKUP_DEF_EDITED'
export const markupDefEdited = markupDef => ({ type: MARKUP_DEF_EDITED, markupDef })

export const MARKUP_DEF_DELETED = 'MARKUP_DEF_DELETED'
export const markupDefDeleted = markupDef => ({ type: MARKUP_DEF_DELETED, markupDef })

export const MARKUP_CUSTOM_TYPE_CREATED = 'MARKUP_CUSTOM_TYPE_CREATED'
export const markupCustomTypeCreated = customType => ({ type: MARKUP_CUSTOM_TYPE_CREATED, customType })

export const MARKUP_CUSTOM_TYPE_EDITED = 'MARKUP_CUSTOM_TYPE_EDITED'
export const markupCustomTypeEdited = customType => ({ type: MARKUP_CUSTOM_TYPE_EDITED, customType })

export const MARKUP_CUSTOM_TYPE_DELETED = 'MARKUP_CUSTOM_TYPE_DELETED'
export const markupCustomTypeDeleted = customType => ({ type: MARKUP_CUSTOM_TYPE_DELETED, customType })

export const downloadVideoFile = (name, type) => () => (dispatch, getState) => {
  const state = getState()
  const authToken = tokenSelector(state)
  const revisionId = revisionIdSelector(state)
  dispatch(UICreators.downloadFile({
    path: dls(new URL(urlForVideo(authToken, revisionId, name)).pathname),
    query: {},
    description: `Downloading project ${type}. Please wait, this might take a while...`
  }))
}

export const downloadVideo = downloadVideoFile('movie.mp4', 'movie')
export const downloadSubtitle = downloadVideoFile('movie.srt', 'subtitle')

// project events

export const UPDATE_PROJECT_DATA = 'UPDATE_PROJECT_DATA'
export const updateProjectData = data => ({
  type: UPDATE_PROJECT_DATA,
  data
})

export const UPDATE_PROJECT_PREFERENCES = 'UPDATE_PROJECT_PREFERENCES'
export const updateProjectPreferences = preferences => ({
  type: UPDATE_PROJECT_PREFERENCES,
  preferences
})

export const PREJECT_PREFERENCE_SET = 'PREJECT_PREFERENCE_SET'
export const projectPrefenceSet = preference => ({
  type: PREJECT_PREFERENCE_SET,
  preference
})

export const BUILD_CONFIG_SET = 'BUILD_CONFIG_SET'
export const buildConfigSet = buildConfig => ({
  type: BUILD_CONFIG_SET,
  buildConfig
})

export const BUILD_CONFIG_RENAME_EXT = 'BUILD_CONFIG_RENAME_EXT'
export const buildConfigRenameExt = (extension, newName) => ({
  type: BUILD_CONFIG_RENAME_EXT,
  extension,
  newName
})

export const BUILD_CONFIG_DELETE_EXT = 'BUILD_CONFIG_DELETE_EXT'
export const buildConfigDeleteExt = extension => ({
  type: BUILD_CONFIG_DELETE_EXT,
  extension
})

// utilities for project lifecycle handling (hacking) in reducers

export const isRevisionChangedAction = action => action.type === SELECT_ACTION && action.name === 'revisionId'
export const isRevisionClearedAction = action => action.name === 'revisionId'
