import { Creators as VMCreators } from 'actions/vm'

const { vmDisposed } = VMCreators

/**
 * Tears down the BNE engine instance (sometimes we also call it VM)
 */
const disposeVM = () => (dispatch, getState, { synchronizer: { engineStore, store } }) => {
  // dispose the engine
  engineStore.getEngine().dispose()

  // dispose extensions manager
  store.extensionsContainer.resetExtensions()

  // trigger event
  dispatch(vmDisposed())
}

export default disposeVM