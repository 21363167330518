import React from 'react'
import { lang } from 'beanie-engine-api-js'
import LabelContent from '../../../../TreeChain/NodeWithEnabledRule/LabelContent'

const { rule: { utils: { valueToString }, error: { isError } } } = lang

import styles from '../../HeaderTruthTable.scss'

const ColumnValueFeedback = ({ truthTableIsHovered, shouldEval, result }) => {
  const shouldShowResult = truthTableIsHovered && shouldEval && !isError(result)
  return shouldShowResult ? (
    <div className={styles.cellValueContainer}>
      <LabelContent source={valueToString(result)} maxSize={15} className={styles.cellValue} />
    </div>
  ) : <div />
}

export default ColumnValueFeedback
