import React from 'react'
import { isNoteMatchResultSource } from '../../model/project/searches/collectors/match/MatchSourcesBySys'
import { splitMatch } from '../../utils/string'
import NoteMatchResultText from './NoteMatchResultText'

import styles from './SearchObject.scss'

/**
 * Knows how to show a `SearchMatchResult` (element).
 * Match searches are those that look up a piece of text in the objects.
 * Here we have different strategies (UIs) depending on the type of source of the result.
 */
const MatchResultText = ({ result, text }) => {
  const { source, offset, length } = result

  // Notes Matches
  if (isNoteMatchResultSource(source)) {
    return (
      <NoteMatchResultText result={result} />
    )
  }

  // Label Matches (or others / default)
  const [before, highlight, after] = splitMatch(text, offset, length)
  return (
    <div className={styles.MatchResultText}>
      <span>{before}</span>
      <span className={styles.matchToken}>{highlight}</span>
      <span>{after}</span>
    </div>
  )
}

export default MatchResultText