import React from 'react'

import Secure from 'components/Commons/Secure'
import { teamWriteAccess } from 'security/team'

const TeamAction = ({ team, children }) => (
  <Secure context={{ team }} check={teamWriteAccess}>
    {children}
  </Secure>
)

export default TeamAction