import React, { useCallback } from 'react'
import { isNil } from 'ramda'
import { Menu } from 'antd'

import { isCurrentRevisionWritable } from 'security/project'
import Secure from 'components/Commons/Secure'
import { model } from 'beanie-engine-api-js'
import InsertChildSubMenu from './InsertChildSubMenu'

import styles from './Menu.scss'

const { types: { object: { isDisabled } } } = model

const { SubMenu, Divider, Item } = Menu

/**
 *
 */
export const TruthTableRowMenu = ({
  node, // TODO: the row, rename it to "row"
  deleteRow,
  insertRowAbove,
  insertRowBelow,
  moveRowUp,
  moveRowDown,
  moveRowToTop,
  moveRowToBottom,
  toggleEnableDisable,
  toggleDebugging,
  pinned,
  setDropdownVisibility,
}) => {
  const onCloseMenu = useCallback(() => {
    setDropdownVisibility(false)
  }, [setDropdownVisibility])

  return (
    <Secure check={isCurrentRevisionWritable}>
      <Menu className={styles.menu}>
        {(insertRowAbove || insertRowBelow) &&
        <SubMenu
          className={styles.subMenu}
          key="insertRow"
          title="Insert Row..."
        >
          {
            [
              ['Above', insertRowAbove],
              ['Below', insertRowBelow]
            ].map(renderItem)
          }
        </SubMenu>
        }

        <InsertChildSubMenu
          node={node}
          onCloseMenu={onCloseMenu}
        />

        {(moveRowUp || moveRowDown || moveRowToTop || moveRowToBottom) &&
        <SubMenu
          key="moveRow"
          className={styles.subMenu}
          title="Move Row..."
        >
          {
            [
              ['Up', moveRowUp],
              ['Down', moveRowDown],
              ['Top', moveRowToTop],
              ['Bottom', moveRowToBottom],
            ].map(renderItem)
          }
        </SubMenu>
        }
        <Divider />
        {toggleDebugging &&
          <Item
            className={styles.item}
            key="debugging"
            onClick={toggleDebugging}
          >
            {pinned ? 'Remove debugging pin' : 'Put debugging pin'}
          </Item>
        }
        {toggleEnableDisable &&
          <Item
            className={styles.item}
            key="enableDisable"
            onClick={toggleEnableDisable}
          >
            { isDisabled(node) ? 'Enable' : 'Disable' }
          </Item>
        }
        <Item
          className={styles.item}
          disabled
          key="add_enabled_rule">
          Add Enabled Rule
        </Item>
        <Divider />
        {renderItem(['Delete Row', deleteRow])}
      </Menu>
    </Secure>
  )
}

const renderItem = ([label, onClick]) => (
  <Item
    className={styles.item}
    key={label}
    disabled={isNil(onClick)}
    onClick={onClick}>
    {label}
  </Item>
)

export default TruthTableRowMenu
