import { compose, withPropsOnChange } from 'recompose'
import withSecurityScope from './withSecurityScope'
import omitProps from './omitProps'
import { EMPTY_ARRAY } from 'utils/object'
import { pick } from 'ramda'
import withWrappingDisplayName from './recompose/withWrappingDisplayName'

/**
 * Allows to run an access rule using the currently logged in user
 * and the props as context !! It sets the result into the given prop as a boolean
 * value.
 *
 * If you want to show / hide a component based on a rule
 * You should better use <Secure check={}> ... my component here </Secure>
 *
 * This hoc is useful to use in other components props, like disabled={}
 * ie
 *
 * secure('hasWriteAccessToOrg', isOrgMember)(MyOrgName)
 *
 * const MyOrgName = ({ hasWriteAccessToOrg, organization }) => (
 *  <input disabled={!hasWriteAccessToOrg}>{organization.Name}</input>
 * )
 */
const secure = (propName, check, propNamesToScope = EMPTY_ARRAY) => compose(
  withSecurityScope,
  withWrappingDisplayName('secure'),
  withPropsOnChange(
    ['scope', ...propNamesToScope],
    ({ user, scope, ...others }) => {
      const newScope = propNamesToScope.length > 0 ?
        { ...scope, ...pick(propNamesToScope, others) }
        : scope
      return ({
        [propName]: check(newScope)
      })
    }
  ),
  omitProps(['scope'])
)

export default secure