import { pipe, flatten, map, assoc } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

const createOtherRoutes = ({ otherRoutes, ...item }) => otherRoutes.map(goTo =>
  // alternates routes are the same, just with a different 'goTo' (the actual path)
  // it is important to keep the same "KEY" !!! see https://github.com/ReactTraining/react-router/issues/4578
  assoc('goTo', goTo, item)
)

export const createMenuItemsWithAlternateRoutes = pipe(
  // order matters here: specific rules (others) should come first
  // like /edit/:blah  and then /edit/
  map(item => [...item.otherRoutes ? createOtherRoutes(item) : EMPTY_ARRAY].concat(item)),
  flatten,
)

export const getCurrentProjectSection = location => {
  const result = location.pathname.match('/p/[^/]+/([^/]+)/{0,1}')
  return result && result.length === 2 ? result[1] : undefined
}