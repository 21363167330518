import { applyChanges } from './applyChanges'
import { updateIndexesWithBaseChanges } from './lineChange'

export const merge = (editor, initialValue, valueToMerge) => {
  // initialValue is the value that we going to use to compare our changes and the incoming changes
  // valueToMerge is the incoming value, in this case, the value that we want "merge"
  // editor.value is our current local changes that we'll use to update the incoming changes
  const myChanges = editor.changes(initialValue, editor.value)
  const changesToMerge = editor.changes(initialValue, valueToMerge)
  editor.restoringFocus(() => 
    applyChanges(editor)(updateIndexesWithBaseChanges(changesToMerge, myChanges))
  )
}
