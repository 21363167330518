import React from 'react'
import { pathEq, propEq } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'
import { OrganizationMembershipRole, ProjectOwnerType } from '../utils/authentication'
import UserName from 'components/Commons/UserName'

export const isMembershipFor = (container, parentName) => pathEq([parentName, '_id'], container._id)
export const isMembershipOfRole = propEq('role')

// checks memberhips of User.xxxMembership
export const isMemberWithRole = (membershipPropName, contextContainerPropName, memberShipToContainerPropName, role) =>
  ({ currentUser: user, [contextContainerPropName]: container }) => {
    if (!container) { return false }
    const membership = (user[membershipPropName] || EMPTY_ARRAY)
      .find(isMembershipFor(container, memberShipToContainerPropName))
    return membership && isMembershipOfRole(role)(membership)
  }

export const isOrgProject = owner => owner && owner.__typename === ProjectOwnerType.Organization
export const projectOwnerName = ({ owner }, user) => (
  owner.__typename === ProjectOwnerType.Organization ? owner.name
    : (owner._id === user._id ? 'You' : <UserName user={owner} />)
)
// works with the full Organization and User objects
export const isOrgOwner = (organization, user) => 
  organization.members.list.find(m => 
    m.role === OrganizationMembershipRole.OWNER
    && m.user._id === user._id
  )

