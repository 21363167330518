exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameRow__1hWcv{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.nameRow__1hWcv .ant-avatar{margin-right:1rem}", ""]);

// exports
exports.locals = {
	"nameRow": "nameRow__1hWcv"
};