exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".FactsTable__2Hbkh tbody tr td{padding:2px !important}.FactsTable__2Hbkh tbody tr td:first-child{padding-left:1rem !important}.FactsTable__2Hbkh .ant-table-thead th:not(:last-child){border-right:1px solid #eaeaea}.FactsTable__2Hbkh .ant-table-thead th{width:25%}.FactsTable__2Hbkh .ant-table-body col{width:25%}.FactsTable__2Hbkh .ant-table-body tr:not(.ant-table-row-level-0):not(.ant-table-row-level-1) td{width:25%}.FactsTable__2Hbkh .ant-table-body tr:not(.ant-table-row-level-0):not(.ant-table-row-level-1) td .ant-table-row-indent{padding-left:.5rem}.FactsTable__2Hbkh .ant-table-footer{padding-top:0;padding-bottom:0}.FactsTable__2Hbkh .actionsColumn__3eNc4>div{display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}.FactsTable__2Hbkh .nameColumn__2Tzxj{-ms-flex-align:center;align-items:center}.FactsTable__2Hbkh .nameColumn__2Tzxj .nameContent__l2z78.laneLabel__3JHg- span{margin-left:1rem;color:#a9a9a9;letter-spacing:.1rem;font-size:.8rem}.FactsTable__2Hbkh .nameColumn__2Tzxj .withStaticError__1zZED{color:#e46262}.FactsTable__2Hbkh .nameColumn__2Tzxj .withStaticError__1zZED .anticon{color:#e46262;margin-right:.5rem}.FactValueError__2nuCm{display:-ms-flexbox;display:flex;background-color:#feebec;padding-left:.5rem}.FactValueError__2nuCm .anticon{margin-right:.5rem}.typeColumn__1I8sF{display:table-cell}", ""]);

// exports
exports.locals = {
	"FactsTable": "FactsTable__2Hbkh",
	"actionsColumn": "actionsColumn__3eNc4",
	"nameColumn": "nameColumn__2Tzxj",
	"nameContent": "nameContent__l2z78",
	"laneLabel": "laneLabel__3JHg-",
	"withStaticError": "withStaticError__1zZED",
	"FactValueError": "FactValueError__2nuCm",
	"typeColumn": "typeColumn__1I8sF"
};