import React from 'react'
import { Tooltip } from 'antd'

import styles from './Annotations.scss'

export const RemoveContentAnnotation = ({ children, ...others }) => {
  const { data: { value } } = others.annotation.toJS()
  return (<span>
    <Tooltip placement="top" title={value}>
      <span className={styles.contentRemoved} />
    </Tooltip>
    {children}
  </span>)
}
