import React from 'react'
import { connect } from 'react-redux'
import { Table, Icon } from 'antd'
import { reverse, reject, propEq, prop } from 'ramda'
import { compose, withPropsOnChange, withState } from 'recompose'
import classNames from 'classnames'

import { sorterByStringProperty } from 'utils/string'
import { BNEStateVariables } from 'model/constants'
import { objectEvents as eventsSelector } from 'selectors/state'
import { selectNode } from 'actions/nodes'

import filteringTable from 'hocs/filteringTable'
import Preferences from 'preferences/Preferences'

import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'
import withSinglePreference from '../../hocs/withSinglePreference'

import StateFieldValue from './StateFieldValue'

import { fullTable } from 'styles/table.scss'
import styles from './StateEvents.scss'

const { Column } = Table

const StateEvents = ({ events, onNodeClick, setSelectedEvent, selectedEvent, filteringProps }) => (
  <div className={classNames(fullTable, styles.eventsTable)}>
    <Table
      dataSource={reverse(events)}
      rowKey="timestamp"
      size="small"
      footer={() => <div>{events ? events.length : 0} events</div>}
      pagination={false}
      scroll={{ y: true }}
      locale={{
        emptyText: 'No Events'
      }}
      onRow={event => ({
        onClick: () => {
          setSelectedEvent(event)
        }
      })}
      rowClassName={event => (selectedEvent && event.timestamp === selectedEvent.timestamp ? styles.selectedRow : '')}
    >
      <Column
        title={<Icon type="clock-circle" />}
        dataIndex="timestamp"
        key="timestamp"
        defaultSortOrder="descend"
        sorter={sorterByStringProperty('timestamp')}
        className={styles.timestampColumn}
      />
      <Column
        title="Node"
        className={styles.nodeColumn}
        key="nodeId"
        render={obj => (
          <SimpleNode nodeId={obj.nodeId} onClick={() => onNodeClick(obj.nodeId)} />
        )}
      />
      <Column
        dataIndex="field"
        key="field"
        sorter={sorterByStringProperty('field')}
        className={styles.fieldColumn}
        {...filteringProps()}
      />
      <Column
        className={styles.valueColumn}
        title="Value"
        key="value"
        render={event => <StateFieldValue event={event} onClick={onNodeClick} />}
      />
    </Table>
  </div>
)

export default compose(
  withState('selectedEvent', 'setSelectedEvent'),
  connect(state => ({
    events: eventsSelector(state)
  }),
  ({ onNodeClick: selectNode })
  ),
  withSinglePreference('showExecutionCount', Preferences.Playback.showExecutionCountVariables),
  // order matters,
  filteringTable('events', prop('field'), 'Variable'),
  withPropsOnChange(
    ['events', 'showExecutionCount'],
    ({ events, showExecutionCount }) => ({
      events: showExecutionCount ? events : reject(propEq('field', BNEStateVariables.execution_count), events)
    })
  ),
)(StateEvents)