exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ConfirmationsModal__3oXMu{z-index:1200}", ""]);

// exports
exports.locals = {
	"ConfirmationsModal": "ConfirmationsModal__3oXMu"
};