import { createSelector } from 'selectors/reselect'
import { reject, path, pipe, uniq, prop, propOr, map, sortBy, identity, concat, assoc, omit, uniqBy } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { EMPTY_ARRAY } from '../utils/object'
import { languageResources } from './objects'
import { safeToLowerCase, safeToUpperCase } from 'utils/string'
import { markupModel } from './project'

const { types: { markup: { MarkupBasicTypes, isInvalidMarkup } } } = model

//
// dynamic data: actual usages
//

export const allMarkupCalls = createSelector(
  [languageResources],
  resources => resources.reduce((acc, res) => {
    const markups = path(['data', 'markups'], res)
    if (markups) {
      acc.push(...markups)
    }
    return acc
  }, [])
)

export const usedMarkups = createSelector(
  [allMarkupCalls],
  reject(isInvalidMarkup)
)

export const usedMarkupsNames = createSelector(
  [usedMarkups],
  pipe(map(prop('name')), uniq, sortBy(identity))
)

//
// static data: markups model & definitions
//

export const markupDefinitions = createSelector([markupModel], propOr(EMPTY_ARRAY, 'markups'))

export const markupTypes = createSelector([markupModel], prop('types'))

export const allMarkupTypes = createSelector([markupTypes], concat(MarkupBasicTypes))

//
// combined data: (static definitions + dynamic usages)
//

export const ProposalType = {
  definition: 'def',
  usage: 'usage'
}

const definitionsToProposals = map(assoc('type', ProposalType.definition))
const usagesToProposal = map(pipe(
  assoc('type', ProposalType.usage),
  omit(['offset', 'length'])
))

export const markupProposals = createSelector(
  [usedMarkups, markupDefinitions],
  (used, defs) => pipe(
    uniqBy(pipe(prop('name'), safeToLowerCase)),
    sortBy(prop('name'))
    // defs should go first so they win in uniq()
  )(definitionsToProposals(defs).concat(usagesToProposal(used)))
)

export const markupProposalsNames = createSelector(
  [markupProposals],
  map(pipe(prop('name'), safeToUpperCase))
)