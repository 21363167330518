import { model, Sys, lang } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'
import { indexBy, keys, path } from 'ramda'
import { EMPTY_OBJECT } from '../../utils/object'
import { makeMemoizeSelector } from '../memoize'
import { lanesFromProject, objectsBySys } from '../objects'
import { objectsIndex } from 'selectors/apollo'
import groupFactsByMapper from './facts/groupFactsByMapper'
import getFactLaneMapper from './facts/mappers/getFactLaneMapper'

const { types: { object: { Paths } } } = model
const { rule: { typing: { types, infer, TypingContext } } } = lang

export const facts = createSelector(
  [objectsBySys],
  index => Object.values(index[Sys.fact] || EMPTY_OBJECT)
)

export const factsByName = createSelector('factsByName',
  [facts],
  indexBy(path(Paths.node.name))
)

export const factNames = createSelector('factNames',
  [factsByName], keys
)

export const NO_LANE_NAME = 'No Lane'

export const factsTreeByLane = () => makeMemoizeSelector(
  { facts, objectsIndex, factsByName, indexBySys: objectsBySys, lanes: lanesFromProject },
  groupFactsByMapper(getFactLaneMapper, 'lane', NO_LANE_NAME)
)

// Selector
export const factsTypes = createSelector('factsTypes',
  [factsByName],
  index => {
    const typingContext = new TypingContext({
      withCheck: false,
      factTypes: {},
      variableTypes: {},
      propNodeTypes: {},
      itType: types.Any,
      factResolver: name => index[name]
    })

    for (const factName of Object.keys(index)) {
      const expr = path(Paths.fact.expr, index[factName])
      if (expr?.rule) {
        const type = infer(expr.rule, typingContext)
        typingContext.addFactType(factName, type)
      }
    }

    return typingContext.getIndexedFactTypes()
  }
)

export const currentTypingContextWithProps = (withCheck = false) => state => {
  const byName = factsByName(state)
  const factTypes = factsTypes(state)

  return new TypingContext({
    withCheck,
    factTypes,
    variableTypes: {},
    propNodeTypes: {},
    itType: types.Any,
    factResolver: name => byName[name]
  })
}

export const currentTypingContext = currentTypingContextWithProps(false)
export const currentTypingAndCheckContext = currentTypingContextWithProps(true)

export const makeInferExprTypeSelector = expr => createSelector('makeInferExprTypeSelector',
  [currentTypingContext],
  typingContext => (expr ? infer(expr, typingContext) : undefined)
)