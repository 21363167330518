import { lang, model } from 'beanie-engine-api-js'
import { isNil } from 'ramda'

const { types: { fact: { getExpressionAST } } } = model
const { rule: { utils: { getFactNamesVisitor, getNodeReferencesVisitor, visitExpr } } } = lang

/**
 * A mapper impl (@see #groupFactsByMapper) that is used to group by fact's lanes.
 * It computes the lane based on the rule content: specifically node references and calling other facts.
 * This is a work-in-progress for an important domain problem/decision.
 * This is just a start
 */
const getFactLaneMapper = (fact, cache) => {
  // TODO: we just get the lane of the first node_ref/fact we can resolve
  //  pending to define how to compute broaden scopes, like a fact using nodes from > 1 lane
  //  or mixing node_refs and calling many other facts with different scopes in turn, etc.

  const ast = getExpressionAST(fact)
  if (isNil(ast)) return undefined

  const { factNames: factDependencies = [], nodeRefs = [] } = visitExpr(ast, [getNodeReferencesVisitor, getFactNamesVisitor])

  // try to get it from node_refs
  for (const id of nodeRefs) {
    const lane = cache.getLaneOfNode(id)
    if (lane) { return lane }
  }

  // otherwise get it from any fact dependency
  for (const calledFactName of factDependencies) {
    // TODO: check circularity !
    const lane = cache.followFactWithName(calledFactName)
    if (lane) { return lane }
  }

  return undefined
}

export default getFactLaneMapper