exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ArrayEmbeddedContent__2_HYD{margin:0 !important}.ArrayEmbeddedContent__2_HYD .ant-table{-webkit-box-shadow:none !important;box-shadow:none !important;border:none}.ArrayEmbeddedContent__2_HYD .ant-table .ant-table-content{min-height:auto !important}", ""]);

// exports
exports.locals = {
	"ArrayEmbeddedContent": "ArrayEmbeddedContent__2_HYD"
};