import jwt from 'jsonwebtoken'
import { path } from 'ramda'

export const LOGIN_STORAGE_KEY = 'LOGIN'

// KEEP THIS SYNC WITH BACKEND !

export const Roles = {
  ADMIN: 'ADMIN',
  DEV: 'DEV'
}

export const TeamMembershipRole = {
  MEMBER: 'MEMBER',
  ADMIN: 'ADMIN'
}

export const OrganizationMembershipRole = {
  OWNER: 'OWNER',
  MEMBER: 'MEMBER'
}

export const ProjectGrantRole = {
  ADMIN: 'ADMIN',
  READ: 'READ',
  WRITE: 'WRITE',
  TEXT_REVIEW: 'TEXT_REVIEW'
}

export const ProjectGrantRoleIcons = {
  [ProjectGrantRole.READ]: 'eye',
  [ProjectGrantRole.WRITE]: 'edit',
  [ProjectGrantRole.TEXT_REVIEW]: 'check-circle',
  [ProjectGrantRole.ADMIN]: 'safety-certificate',
}

export const ProjectGrantToType = {
  Team: 'Team',
  User: 'User'
}

export const ProjectOwnerType = {
  User: 'User',
  Organization: 'Organization'
}

// utils

export const setLocalStorage = (token, user, newRefreshToken, newRefreshExpires) => {
  const decodedToken = jwt.decode(token, { json: true })

  let refreshExpires
  let refreshToken
  if (newRefreshToken) {
    refreshExpires = newRefreshExpires
    refreshToken = newRefreshToken
  } else {
    const data = getLoginDataFromLocalStorage()
    refreshExpires = data && data.refreshExpires
    refreshToken = data && data.refreshToken
  }

  localStorage.setItem(
    LOGIN_STORAGE_KEY,
    JSON.stringify({
      expires: path(['exp'], decodedToken),
      token,
      refreshToken,
      refreshExpires,
      user
    })
  );
}

export const clearLoginDataFromLocalStorage = () => {
  localStorage.removeItem(LOGIN_STORAGE_KEY)
}

export const getLoginDataFromLocalStorage = () => {
  const item = localStorage.getItem(LOGIN_STORAGE_KEY)
  return item === 'undefined' || item === 'null' ? undefined : JSON.parse(item)
}

export const PRIVILEGED_ROLES = [Roles.ADMIN, Roles.DEV]

export const meetRoleRequirements = (userRoles = [], requiredRoles = []) => {
  if (requiredRoles.length === 0) return true
  if (userRoles.length === 0) return false

  return userRoles.reduce((acum, curr) => acum || requiredRoles.includes(curr), false)
}

export const isPriviledgedUser = ({ currentUser }) => currentUser && meetRoleRequirements(currentUser.roles, PRIVILEGED_ROLES)