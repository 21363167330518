exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__3mgED{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex:1 1 0%;flex:1 1 0%}.avatar__1G_X8{display:-ms-flexbox;display:flex}.avatar__1G_X8>span{padding:.2rem;background-color:#efefef;border-radius:5px}.imageTitle__2-LBw{font-size:.8rem;margin-top:.5rem}", ""]);

// exports
exports.locals = {
	"content": "content__3mgED",
	"avatar": "avatar__1G_X8",
	"imageTitle": "imageTitle__2-LBw"
};