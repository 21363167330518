import { compose, withState } from 'recompose'

import { pick } from 'ramda'
import paginatedQuery from 'hocs/paginatedQuery'
import query from 'api/queries/organizationProjects.graphql'

import { orgWriteAccess } from 'security/organization'
import secure from 'hocs/secure'

import OrganizationProjects from './OrganizationProjects'


// this is a pretty basic impl working for the moment
const RATIO = 11 / 1600 // 11 items in 1600px height device
const getNumberOfItems = () => {
  const { screen: { height }, devicePixelRatio } = window
  const availableHeightInPx = height * devicePixelRatio
  return Math.floor(availableHeightInPx * RATIO)
}

export default compose(
  withState('archived', 'setArchived', false),
  paginatedQuery({
    query,
    name: 'projects',
    pageSize: getNumberOfItems(),
    variables: pick(['organizationName', 'archived']),
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  }),
  secure('hasOrgWriteAccess', orgWriteAccess),
)(OrganizationProjects)
