import React from 'react'
import { connect, Provider } from 'react-redux'
import classnames from 'classnames'
import countdown from 'countdown'
import { compose, lifecycle, pure, withHandlers, withState } from 'recompose'
import { Button, Input, notification } from 'antd'

import { loginUser as loginUserAction } from 'actions/login'
import { user as getUser, error, isLoggingIn as isLoggingInSelector, refreshToken as refreshTokenSelector } from 'selectors/auth'
import { withTargetValue } from 'utils/antd'

import styles from './SessionExpirationWarning.scss'
import commonStyles from 'styles/commons.scss'

const SESSION_EXTENSION_NOTIFICATION_KEY = 'TOKEN_WILL_EXPIRE_NOTIFICATION'

const { Password } = Input

const wrapProvider = Component => ({ store, ...rest }) => (
  <Provider store={store}>
    <Component {...rest} />
  </Provider>
)

const _WarningContent = ({ timeLeft, setPassword, password, extendLogin, loginError }) => (
  <div className={commonStyles.warningContent}>
    <p>{timeLeft} left.</p>
    <p>Re-enter your password to extend your session:</p>
    <Password value={password} onChange={withTargetValue(setPassword)} onPressEnter={extendLogin} className={classnames({ [styles.passwordError]: loginError !== undefined })} />
    <Button className={styles.extendButton} type="primary" onClick={extendLogin}>Extend</Button>
  </div>
)

const WarningContent = compose(
  pure,
  wrapProvider,
  connect(
    state => ({
      user: getUser(state),
      loginError: error(state),
      isLoggingIn: isLoggingInSelector(state),
      refreshToken: refreshTokenSelector(state)
    }),
    { loginUser: loginUserAction }
  ),
  withState('password', 'setPassword', null),
  withState('timeLeft', 'setTimeLeft', null),
  lifecycle({
    componentDidMount() {
      const { setTimeLeft, expires } = this.props
      this.timerId = setInterval(() => {
        setTimeLeft(countdown(expires * 1000).toString())
      }, 1000)
    },
    componentDidUpdate({ isLoggingIn: prevIsLoggingIn }) {
      const { isLoggingIn, loginError, refreshToken } = this.props
      const enteredPasswordCorrectly = prevIsLoggingIn === true && isLoggingIn === false && loginError === undefined
      const isAlreadyLoggedOut = refreshToken === undefined
      if (enteredPasswordCorrectly || isAlreadyLoggedOut) notification.close(SESSION_EXTENSION_NOTIFICATION_KEY)
    },
    componentWillUnmount() {
      clearInterval(this.timerId)
    },
  }),
  withHandlers({
    extendLogin: ({ user, loginUser, password }) => () => {
      loginUser(user.email, password)
    }
  })
)(_WarningContent)

export default ({ expires, store }) => () =>
  notification.warning({
    key: SESSION_EXTENSION_NOTIFICATION_KEY,
    message: 'Your session will expire soon!',
    description: <WarningContent expires={expires} store={store} />,
    duration: 0,
    className: commonStyles.notificationNotClosable
  })
