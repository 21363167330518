import moment from 'moment'
import { isNotEmpty } from 'ramda-adjunct'

import WaitingSubject from 'observables/WaitingSubject'
import { synchronizeFunction } from 'utils/async'
import { sequence } from 'utils/promises'

import { isLoaded, lastChangeSet } from 'selectors/project'
import { changeSetQueue } from 'selectors/sync'

import { syncEnqueue, syncDequeue } from 'actions/sync'
import applyChangeSetMutation from 'api/mutations/applyChangeSet.graphql'
import reapplyChangeSetMutation from 'api/mutations/reapplyChangeSet.graphql'
import revertChangeSetMutation from 'api/mutations/revertChangeSet.graphql'

/**
 * Represents the state on the server (backend).
 * Emits changeSets when we receive one from subscriptions (outside of this for now, done by ObjectChangeDetector)
 * When applying a changeSet from other store, it sends it to the backend with a mutation
 */
class ServerStore {

  constructor(store, apolloClient) {
    this.store = store
    this.apolloClient = apolloClient

    this.subject = new WaitingSubject()

    // We can only perform one server action at the time
    this.executeServerAction = synchronizeFunction(::this.executeServerAction)
  }

  getReduxStore() { return this.store }

  async apply(changeSet) {
    const { data: { applyChangeSet: responseChangeSet } } = await this.apolloClient.mutate({
      mutation: applyChangeSetMutation,
      variables: { input: changeSet },
    })
    return responseChangeSet
  }

  subscribe(...args) { this.subject.asObservable().subscribe(...args) }

  //
  // Own API for components/actions that implements the actual
  // connection to the server (subscriptions)
  //

  // pushing to server

  async executeServerAction(f) {
    const changeSet = await f(this.apolloClient)
    return this.receiveFromServer(changeSet)
  }

  async undo(changeSetId) {
    const { data: { revertChangeSet: changeSet } } = await this.apolloClient.mutate({
      mutation: revertChangeSetMutation,
      variables: { changeSetId }
    })
    return changeSet
  }

  async redo(changeSetId) {
    const { data: { reapplyChangeSet: changeSet } } = await this.apolloClient.mutate({
      mutation: reapplyChangeSetMutation,
      variables: { changeSetId }
    })
    return changeSet
  }

  // receiving from server

  async receiveFromServer(changeSet) {
    const projectIsLoaded = isLoaded(this.store.getState())

    if (projectIsLoaded) {
      await this.subject.next(changeSet)
    } else {
      // enqueue
      this.store.dispatch(syncEnqueue(changeSet))
    }
  }

  // TODO: test me
  async applyEnqueuedChangesetsFromServer() {
    const state = this.store.getState()
    const latestChangeSet = lastChangeSet(state)
    const queue = changeSetQueue(state)
    await sequence(
      queue
        .map(changeSet => async () => {
          if (moment(latestChangeSet.timestamp).isBefore(changeSet.timestamp)) {
            await this.subject.next(changeSet)
          }
          // dequeue
          this.store.dispatch(syncDequeue())
        })
    )

    // keep consuming the queue until its really empty
    if (isNotEmpty(changeSetQueue(this.store.getState()))) {
      await this.applyEnqueuedChangesetsFromServer()
    }
  }

}

export default ServerStore