exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".node__opDAR{opacity:1}", ""]);

// exports
exports.locals = {
	"node": "node__opDAR"
};