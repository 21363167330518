import React from 'react'
import { Tooltip } from 'antd'

import styles from './Annotations.scss'

export const ReplaceContentAnnotation = ({ children, ...others }) => {
  const { data: { removedValue, value } } = others.annotation.toJS()
  return (
    <Tooltip placement="topLeft" title={`${removedValue} => ${value}`}>
      <span className={styles.contentReplaced}>{children}</span>
    </Tooltip>)
}
