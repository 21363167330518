import Dexie from 'dexie'

/**
 * A services that initializes and returns a local database: an IndexedDB instance.
 * Having it as a service allows us to have an indirection and therefore
 * enable/disabled it or mock it for tests.
 */
const LocalDB = {
  name: 'localDB',

  start: () => {
    const db = new Dexie('BNE-Studio')
    db.version(1).stores({

      /** Cache of Revision BNEObjects */
      revisions: '&revisionId',

      /** Cache of engine LUA files */
      engineFile: '&url',

    })
    return db
  }

}

export default LocalDB

