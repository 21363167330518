const callbacks = []

// if we see a delay higher than 2s then we suppose we were asleep.
export /* to test */ const OFFSET_THRESHOLD = 2000
export /* to test */ const INTERVAL = 30000


let lastTime = Date.now()

export /* for test */ const intervalIncurredOnOffset = (currTime, prevTime) => currTime > (prevTime + INTERVAL + OFFSET_THRESHOLD)

setInterval(() => {
  const currentTime = Date.now()
  if (intervalIncurredOnOffset(currentTime, lastTime)) {
    callbacks.forEach(cb => cb())
  }
  lastTime = currentTime
}, INTERVAL)

export default cb => {
  callbacks.push(cb)
}