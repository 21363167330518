import React from 'react'

import Title from './Title'

import BneObjectPropertiesEditor from 'components/BneObjectPropertiesEditor/BneObjectPropertiesEditor'

import styles from './BneActorPropertiesEditor.scss'

const { Fragment } = React

const BneActorPropertiesEditor = ({ actor, ...props }) => (
  <Fragment>
    <Title><span>Properties</span></Title>
    <BneObjectPropertiesEditor
      className={styles.actorPropertiesEditor}
      object={actor}
      {...props}
    />
  </Fragment>
)

export default BneActorPropertiesEditor
