import React from 'react'
import { compose, withHandlers } from 'recompose'
import { withRef } from 'utils/recompose'

import { noop } from 'utils/functions'

import withArchiveUnarchive from 'hocs/project/withArchiveUnarchive'

import ModalOpener from 'components/Commons/ModalOpener'
import ArchiveProjectMessage from 'components/ProjectSettings/ProjectDangerZone/ArchiveProjectMessage'

const ArchiveUnarchiveModal = ({ project, hideModal, onArchiveUnarchive }) => (
  <ModalOpener
    title={project.archived ? 'Unarchive' : 'Archive'}
    onOk={onArchiveUnarchive}
    onCancel={hideModal}>
    <ArchiveProjectMessage project={project} />
  </ModalOpener>
)

export default compose(
  withRef('archiveFormRef'),
  withArchiveUnarchive,
  withHandlers({
    onArchiveUnarchive: ({ onArchiveUnarchive, onPerformed = noop, hideModal }) => async () => {
      await onArchiveUnarchive()
      hideModal()
      onPerformed()
    }
  }),
)(ArchiveUnarchiveModal)
