exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ArchivedFilter__2BzHr .ant-checkbox-wrapper{white-space:nowrap}", ""]);

// exports
exports.locals = {
	"ArchivedFilter": "ArchivedFilter__2BzHr"
};