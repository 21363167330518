import { NODE_TYPES } from '../../Constants'
import { one } from '../schema-utils'
import { dialogueLineNormalize } from '../normalizers/dialogueLine'

export default {
  nodes: [
    {
      match: [{ type: NODE_TYPES.ACTOR_PART }],
      ...one
    },
    {
      match: [{ type: NODE_TYPES.TEXT_PART }],
      ...one
    }
  ],
  normalize: dialogueLineNormalize
}