exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2PLFD{color:#add8e6;cursor:pointer}.iconAsLink__2PLFD:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2W79A{font-style:italic}.formFieldsVerticalGutter__3acS2{padding:.5rem}.formFieldsVerticalGutter__3acS2>*{margin:.5rem !important}.tableBackground__3bTTb .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3bTTb .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2bY2r .ant-notification-notice-close{display:none}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2PLFD",
	"labelFromRef": "labelFromRef__2W79A",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3acS2",
	"tableBackground": "tableBackground__3bTTb",
	"notificationNotClosable": "notificationNotClosable__2bY2r"
};