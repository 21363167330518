import React from 'react'
import { Button } from 'antd'

import CreateProject from 'components/Project/CreateProject'

import { ModalConsumer } from 'components/Commons/Modal'

const CreateOrganizationProjectButton = ({ organization, onCreated }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <Button type="primary" onClick={() => showModal(CreateProject, { owner: organization, onCreated, readOnlyOwner: true })}>Create Project</Button>
    )}
  </ModalConsumer>
)

export default CreateOrganizationProjectButton
