import { Creators } from 'actions/vm'

const { playbackStop } = Creators

/**
 * Called by BNE when the playback has reached the end of the game.
 * When there is no more content to be presented.
 *
 * @param {*} dispatch - this was bound before starting BNE to be able to dispatch more actions
 */
const onPresentationEnded = dispatch => () => { dispatch(playbackStop()) }

export default onPresentationEnded