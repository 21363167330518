import { compose, withHandlers } from 'recompose'
import withSecurityScope from './withSecurityScope'

const withSecureChecker = (propName = 'doCheck') => compose(
  withSecurityScope,
  withHandlers({
    [propName]: ({ scope, ...props }) => check => check(scope, props)
  })
)

export default withSecureChecker