import memoize from 'memoize-state'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { parentFor } from 'selectors/paths'
import { Sys } from 'beanie-engine-api-js'

export const makeTimerWithoutChoicesAncestorCheck = ({ id }) => {
  const mSelector = memoize((objectIndex, objectsIndexedBySys) => {
    let timerCounter = 0
    let current_id = parentFor(objectIndex[id], objectsIndexedBySys)
    let current = objectIndex[current_id]
  
    while (current && current.sys !== Sys.marker) {
      if (current.sys === Sys.timer) timerCounter += 1
  
      if (current.sys === Sys.choices) {
        if (timerCounter === 0) return OK_RESULT
        else timerCounter -= 1
      }
  
      current_id = parentFor(current, objectsIndexedBySys)
      current = objectIndex[current_id]
    }
  
    if (timerCounter > 0) {
      return ({
        status: CHECK_RESULT_STATUS.ERROR,
        severity: CHECK_SEVERITY_LEVEL.ERROR,
        id: 'With timer before',
        message: 'There is a timer before this one'
      }) 
    }
  
    return ({
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.ERROR,
      id: 'Without choices ancestor',
      message: 'There is no a choices ancestor for this timer'
    })
  })

  const selector = ({ objectIndex, objectsIndexedBySys }) => mSelector(objectIndex, objectsIndexedBySys)

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Timer without choices ancestor',
    makeSelector: makeTimerWithoutChoicesAncestorCheck
  }
]