import React from 'react'
import { connect } from 'react-redux'
import { Modal, Progress } from 'antd'

import { blockingTask } from 'selectors/ui'

export class BlockingTaskModal extends React.Component {
  state = {
    visible: !!this.props.task,
    // previousTask: null
  }

  shouldComponentUpdate = (nextProps, nextState) => (
    this.props.task !== nextProps.task
    || this.state.visible !== nextState.visible
  )

  UNSAFE_componentWillReceiveProps(newProps) {
    const { task } = this.props
    if (!newProps.task && !!task) {
      this.setState({ previousTask: task })
      setTimeout(() => this.setState({ visible: false }), 200)
    } else {
      this.setState({ visible: !!newProps.task })
    }
  }

  render() {
    const { previousTask } = this.state
    const { task } = this.props
    const t = task || previousTask || { description: 'Running' }
    return (
      <Modal
        title={t.description}
        visible={this.state.visible}
        footer={null}
        closable={false}
      >
        <Progress percent={100} status="active" showInfo={false} />
      </Modal>
    )
  }
}

export default connect(state => ({
  task: blockingTask(state),
}))(BlockingTaskModal)