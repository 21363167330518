import React from 'react'
import classNames from 'classnames'

import { ActorIcon } from 'components/Commons/ActorAvatar'
import LanguageResourceText from 'components/Commons/LanguageResourceText'

import styles from './ClipPlayback.scss'

const ClipPlaybackItem = ({ langResource, actor, defaultActor, previousActor, nextActor, isPlaying }) => (
  <li
    className={classNames({ 
      [styles.defaultActorLine]: defaultActor,
      [styles.sameActorAsPrevious]: previousActor && previousActor.id === actor.id,
      [styles.sameActorAsNext]: nextActor && nextActor.id === actor.id,
      [styles.isPlaying]: isPlaying,
    })}
  >
    <ActorIcon actor={actor} className={styles.lineActorAvatar} />
    <LanguageResourceText langResource={langResource} className={styles.lineText} />
  </li>
)

export default ClipPlaybackItem