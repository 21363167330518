import { collapseAllRoots, expandAllRoots, toggleCollapseLane } from 'actions/lanes'
import { collapseOtherLanes } from 'actions/view'
import { isCollapsed } from 'selectors/folders'
import { revisionId } from 'selectors/project'
import { collapsedElements } from 'selectors/view'
import Contexts from '../shortcuts/contexts'

export const COLLAPSE_ALL_LANE_ROOTS = ({
  context: Contexts.Tree,
  label: 'Collapse All Lane Roots',
  action: collapseAllRoots,
})

export const EXPAND_ALL_LANE_ROOTS = ({
  context: Contexts.Tree,
  label: 'Expand All Lane Roots',
  action: expandAllRoots,
})

export const TOGGLE_COLLAPSE_LANE = ({
  context: Contexts.Tree,
  label: (laneName, state) => (
    `${isCollapsed()(laneName, revisionId(state), collapsedElements(state)) ? 'Expand' : 'Collapse'} Lane`
  ),
  action: toggleCollapseLane
})

export const COLLAPSE_OTHER_LANES = ({
  context: Contexts.Tree,
  label: 'Collapse Other Lanes',
  action: collapseOtherLanes,
})