import React from 'react'

import { Icon } from 'antd'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { pipe, map } from 'ramda'

import { selectNode } from 'actions/nodes'
import makeForkingDecisionPathSelector from 'selectors/objects/paths/makeForkingDecisionPathSelector'
import { propFromProps } from 'selectors/props'
import { curriedIntersperse } from '../../../utils/object'
import { truncate } from '../../../utils/string'

import PathElement, { MAX_TEXT_LENGTH } from './PathElement'

import styles from './NodePath.scss'

const _NodePath = ({ id, decisionPath, className, selectNodeAction, labels = false, tooltip, containers }) => {
  let elements = []
  try {
    elements = pipe(
      map(pathElement => (
        <PathElement key={pathElement.node.id} element={pathElement} onNodeSelected={selectNodeAction} label={labels} containers={containers} tooltip={tooltip} />
      )),
      curriedIntersperse(i => <Icon key={i} type="right" className={styles.arrowSeparator} />)
    )(decisionPath.path)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('ERROR rendering path for id', id, 'with path', decisionPath.path, 'Error', error.message, error)
    return <div className={styles.ErrorComputingPath}><Icon type="warning" />Error computing path!</div>
  }
  return (
    <div className={classNames(styles.path, className)}>
      <div className={classNames(styles.lane, { [styles.orphaned]: decisionPath.lane === undefined })}>{decisionPath.lane ? truncate(decisionPath.lane.name, MAX_TEXT_LENGTH) : 'Orphaned'}</div>
      {decisionPath.path.length > 0 && <Icon type="right" className={styles.arrowSeparator} />}
      {elements}
    </div>
  )
}

export default compose(
  connect((initialState, { includeSelf = true }) => {
    const pathSelector = makeForkingDecisionPathSelector(propFromProps('id'), includeSelf)
    return (state, props) => ({
      decisionPath: pathSelector(state, props)
    })
  }, {
    selectNodeAction: selectNode
  })
)(_NodePath)

