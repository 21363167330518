import { equals } from 'ramda'
import { NormalizationError } from './constants'
import { NODE_TYPES } from '../../Constants'
import { findDescendentOfType } from '../../nodeUtils'
import { customTextPart } from '../../slateMocks/actorTextPart'
import { textNode } from '../../slateMocks/textNode'
import { dialogueLineTextPart } from '../../slateMocks/dialogueLine'

export const dialogueLineNormalize = (editor, { code, node }) => {
  if (code === NormalizationError.CHILD_MIN_INVALID) {
    const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(node)
    if (!textPart) {
      editor.insertChildAt(node, 1, customTextPart([textNode('')]))
    }
  } else if (code === NormalizationError.CHILD_TYPE_INVALID) {
    // if pasted text on an empty text_part, slate replaces it with a paragraph.
    // we convert that to a text_part
    if (equals(node.nodes.map(n => n.type).toArray(), ['actor_part', 'paragraph'])) {
      const paragraph = node.nodes.get(1)
      const newText = dialogueLineTextPart(paragraph.nodes.toJS())
      editor.replaceNodeWith(paragraph, newText)
    }
  }
}