import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import classNames from 'classnames'

import { token as tokenSelector } from 'selectors/auth'
import { updateUserData } from 'actions/login'
import { EMPTY_ARRAY } from 'utils/object'

import userChangeProfileImageMutation from 'api/mutations/userChangeProfileImage.graphql'

import AvatarUpload from 'components/ActorSection/AvatarUpload'
import Avatar from 'components/ActorSection/Avatar'
import OrganizationAvatar from 'components/Organizations/Commons/OrganizationAvatar'
import GoToOrganizationLink from 'components/Organizations/Commons/GoToOrganizationLink'
import UserName from 'components/Commons/UserName'

import styles from './UserPopover.scss'

import { userImageGetURL, userImagePostURL } from 'model/resources'

export class UserPopover extends React.Component {

  render() {
    const { user, token, editable = true, content, actions } = this.props
    const imageFile = user.profileImage ? userImageGetURL(user._id || user.id, user.profileImage, token) : undefined

    return (
      <div className={classNames(styles.card, { [styles.deletedUser]: user.deleted })}>
        <div className={styles.leftSide}>
          { editable ?
            <AvatarUpload
              title={UserName({ user })}
              imageFile={imageFile}
              uploadURL={userImagePostURL(user._id, token)}
              onChange={this.handleProfileImageChanged}
            />
            : <Avatar title={UserName({ user })} imageFile={imageFile} />
          }
        </div>
        <div className={styles.rightSide}>
          <div className={styles.content}>
            <div className={styles.title}>{user.firstName} {user.lastName}</div>
            <div className={styles.email}>{user.email}</div>
          </div>
          {content}
          <div className={styles.orgsBar}>
            {(user.organizationsMemberships || EMPTY_ARRAY).map(membership => (
              <GoToOrganizationLink key={membership.organization._id} organization={membership.organization}>
                <OrganizationAvatar organization={membership.organization} size="small" disabled />
              </GoToOrganizationLink>
            ))}
          </div>
          {actions && (
            <div className={styles.actions}>
              {actions}
            </div>
          )}
        </div>
      </div>
    )
  }

  handleProfileImageChanged = fileName => {
    const { changeProfileImage, user, updateUserDataAction } = this.props
    changeProfileImage({ variables: { input: {
      id: user._id,
      fileName
    } } }).then(({ data: { userChangeProfileImage } }) => {
      updateUserDataAction(userChangeProfileImage)
    })
  }
}

export default compose(
  connect(state => ({
    token: tokenSelector(state)
  }),
  ({
    updateUserDataAction: updateUserData
  })
  ),
  graphql(userChangeProfileImageMutation, { name: 'changeProfileImage' })
)(UserPopover)