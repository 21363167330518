import { model } from 'beanie-engine-api-js'
import { objectsIndex } from '../../../selectors/apollo'
import ParentChildrenConnector from './connectors/children'
import OneToManyConnector from './connectors/oneToMany'
import OneToOneConnector from './connectors/oneToOne'
import RootsConnector from './connectors/roots'

const { types: { object: { Paths }, relationship: { ROOT_PATH } } } = model

/**
 * New generic implementation of DND handlers that works in terms of relationships
 * and does all the work of rewiring stuff.
 *
 * TODO: API difference with previous DND
 *   - source.selection is expected to be just 1 Selection and not an array with 1 selection
 *   - Relationship should represent the target place where to place the object
 *      for example with an already computed index where to place it (if an array)
 *   - target must provide an "operation" that identifies what to do in the target relationship
 */
const drop = (dragSource, dropTarget) => (dispatch, getState, { synchronizer }) => {
  const api = synchronizer.getEngineStore().getEngine().getAPI()
  const resolve = id => objectsIndex(getState())[id]
  const context = { api, resolve }

  const { relationship: sourceRelationship } = dragSource
  const { relationship: targetRelationship, operation } = dropTarget

  // TODO: this impl only supports a single selection for the moment. Here we might remove this
  //  if we want to support dragging multiple selections like > 1 choice elements
  const selection = Array.isArray(dragSource.selection) ? dragSource.selection[0] : dragSource.selection

  if (areFromDifferentRelationships(sourceRelationship, targetRelationship)) {
    if (canRewire(context, selection, sourceRelationship, targetRelationship)) {
      disconnectFromSource(context, selection, sourceRelationship)
      connectToSource(context, selection, targetRelationship, operation)
    }
  } else {
    moveInRelationship(context, sourceRelationship, sourceRelationship.index, targetRelationship.index)
  }
}

export default drop

const canRewire = (context, selection, sourceRelation, targetRelation) => {
  return getConnectors(targetRelation.path).canConnect(context, selection, targetRelation)
}

const areFromDifferentRelationships = (relA, relB) => (relA.path !== relB.path || relA.from !== relB.from)

const disconnectFromSource = (context, selection, relationship) => {
  getConnectors(relationship.path, context).disconnect(context, selection, relationship)
}

const connectToSource = (context, selection, relationship, operation) => {
  getConnectors(relationship.path, context).connect(context, selection, relationship, operation)
}

const moveInRelationship = (context, relationship, fromIndex, toIndex) => {
  // nothing to do, noop
  if (fromIndex === toIndex || toIndex === undefined) { return }
  getConnectors(relationship.path, context).move(context, relationship, fromIndex, toIndex)
}

const getConnectors = aPath => {
  if (!aPath) {
    throw new Error('Cannot use drop action without a relationship in context')
  }
  const connector = Connectors[aPath.join('.')]
  if (!connector) { throw new Error(`No connector for relationship ${aPath}`) }
  return connector
}

//
// Connectors registry by relationship
//

const Connectors = {

  [Paths.node.child.join('.')]: ParentChildrenConnector,

  // TODO: get rid of this one by inferring it via schema (an array of refs)
  [Paths.container.container_content.join('.')]: OneToManyConnector,

  // TODO: get rid of this one by inferring it via schema (a simple ref)
  [Paths.choices2.idle.join('.')]: OneToOneConnector,

  // customs

  [ROOT_PATH]: RootsConnector,

  // TODO: add more here
}

