import React from 'react'
import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { allPass } from 'ramda'

import { isAfterChangeSetsDarkAge } from 'model/constants'

import revertChangeSetMutation from 'api/mutations/revertChangeSet.graphql'

import withConfirm from 'hocs/withConfirm'
import { isCurrentRevisionWritable, projectAdminAccess } from 'security/project'

import LinkButton from 'components/Commons/LinkButton'
import Secure from 'components/Commons/Secure'

const ChangeSetActions = ({ changeSet, revert }) => (
  <div>
    <Secure check={allPass([isCurrentRevisionWritable, projectAdminAccess])}>
      {!changeSet.reverts && isAfterChangeSetsDarkAge(changeSet) && (
        <LinkButton onClick={revert}>Revert</LinkButton>
      )}
    </Secure>
  </div>
)

export default compose(
  withConfirm,
  graphql(revertChangeSetMutation, {
    name: 'revert',
    options: props => ({ variables: { changeSetId: props.changeSet._id } })
  }),
  withHandlers({
    revert: ({ confirm, revert, refetch }) => () => {
      confirm(async () => {
        await revert();
        refetch()
      }, (
        <div>
          This will apply the inverse changes, if many changes took place after it <strong>it might corrupt the project</strong>. Are you sure ?
        </div>
      ))
    }
  })
)(ChangeSetActions)