import React from 'react'
import CreateLocalUserForm from './CreateLocalUserFormContainer'

export const TakeInvite = ({ token, onSuccess }) => (
  <div>
    <h1>Congratulations !</h1>
    <h2>You have been invited to access Beanie !</h2>
    <p>Take a moment to fill some information for the first time</p>
    <CreateLocalUserForm token={token} onSuccess={onSuccess} />
  </div>
)

export const InviteAccepted = () => (
  <div>
    <h1>Congratulations!</h1>
    <p>
      You have signed up for Beanie successfully. <br />
      Now <a href="/">sign-in</a> and enjoy it creating great games!</p>
  </div>
)

export const InviteValidating = () => (
  <div>
    <h1>Loading ...</h1>
    <p>Checking invititation, please wait a moment</p>
  </div>
)
export const Starting = InviteValidating
export const InviteInvalid = ({ invalidReason }) => (
  <div>
    <h1>Error </h1>
    <p>Invitation is invalid: {invalidReason}</p>
  </div>
)

// this is a program errors. Shouldn't happend
export const UnknownState = ({ state }) => <div>Error ! invalid state {state}</div>