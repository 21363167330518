import React from 'react'
import { getDisplayName } from 'recompose'
import { CheckerContext } from 'providers/RevisionStaticAnalyzer'

const withChecker = C => {
  const R = props => (
    <CheckerContext.Consumer>
      {checkerContext => <C {...props} checkerContext={checkerContext} />}
    </CheckerContext.Consumer>
  )
  R.displayName = `withChecker(${getDisplayName(C)})`

  return R
}

export default withChecker