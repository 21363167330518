import React from 'react'
import { always } from 'ramda'

import { isFunction } from 'utils/object'

// reusable super class for components that register
// to graphql subscriptions.

// You must annotate your subclass with "@withApollo" !!!

const emptyVariables = always({})
const call = f => (isFunction(f) ? f() : f)

class Subscribed extends React.Component {

  state = {
    subscribed: false
  }

  componentDidMount() { this.tryToSubscribe() }
  /* eslint react/no-did-update-set-state:0 */
  componentDidUpdate() {
    const { subscribed } = this.state
    if (subscribed && !this.subscriptionEnabled()) {
      this.tryToUnsubscribe()
      this.setState({ subscribed: false })
    } else {
      this.tryToSubscribe()
    }
  }

  tryToSubscribe() {
    const { subscribed } = this.state
    if (!subscribed && this.subscriptionEnabled()) {
      this.subscription = this.subscribe()
      this.setState({ subscribed: true })
    }
  }
  tryToUnsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  componentWillUnmount() { this.tryToUnsubscribe() }

  subscribe() {
    const variables = call(this.variables || emptyVariables)
    const { client } = this.props
    return client
      .subscribe({ query: this.query, variables })
      .subscribe({
        next: ::this.onSubscriptionEvent,
        error: ::this.onSubscriptionError
      })
  }

  // Override in subclasses
  onSubscriptionEvent() { }
  onSubscriptionError() { }
  subscriptionEnabled() { return true }

}

export default Subscribed