import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withStateHandlers } from 'recompose'
import { withRef } from 'utils/recompose'
import { promisify } from 'util'
import inviteAccept from 'api/mutations/inviteAccept.graphql'

import { parseGraphQLErrors } from 'utils/graphql'
import CreateLocalUserForm from './CreateLocalUserForm'

// TODO: we might want to generalize this which is basically a way
// to create better forms on top of antd
// handles
//  - ref to the form
//  - provide a onSubmit() fn that performs validation (here called onCreate())
//  - promises on the main handler() on submit with error handling
//  - handles and parses graphqlErrors
//  - loading for spinning

const FormWithRef = ({ formRef, ...others }) => (
  <CreateLocalUserForm ref={formRef()} {...others} />
)

export default compose(
  graphql(inviteAccept, { name: 'inviteAcceptMutation' }),
  withRef('formRef'),
  withStateHandlers(
    { loading: false, errors: [] },
    {
      updateState: prev => ({ loading, errors }) => ({ ...prev, loading, errors }),
    }
  ),
  withHandlers({
    onCreate: ({ token, inviteAcceptMutation, updateState, formRef, onSuccess = () => {} }) => async () => {
      const form = formRef().current

      let values;
      try {
        updateState({ loading: true, errors: [] })
        values = await promisify(::form.validateFields)()
      } catch (error) {
        // form errors: don't need to set ast errors, since antd already handle those
        updateState({ loading: false })
        return
      }

      const { confirmPassword, ...fields } = values
      try {
        await inviteAcceptMutation({ variables: { input: { token, ...fields } } })
        updateState({ loading: false, errors: [] })
        form.resetFields()
        onSuccess()
      } catch (error) {
        updateState({ loading: false, errors: parseGraphQLErrors(error) })
      }
    }
  })
)(FormWithRef)