import { useCallback } from 'react'

import useWindowEvent from 'hooks/useWindowEvent'
import winston from 'utils/logger'
import { getAsText } from 'utils/clipboard'

const FOCUS_EVENT = 'focus'
const COPY_EVENT = 'copy'

const doItAsync = async ({ onClipboardChanged, transform }) => {

  try {
    const text = await getAsText()

    // transform clipboard if we want to
    const transformedClipboard = await transform(text)

    // check if we are interested on this clipboard change
    if (transformedClipboard) {
      // emit result calling onClipboardChange
      onClipboardChanged(transformedClipboard)
    }
  } catch (e) {
    winston.error('Errored while reading or transforming the clipboard. Error was', e?.message)
    winston.silly(e?.stack)
  }
}

const SystemClipboardListener = ({ onClipboardChanged, transform = i => i, children }) => {
  const onWindowFocus = useCallback(() => {
    doItAsync({ onClipboardChanged, transform })
  }, [onClipboardChanged, transform])

  useWindowEvent(FOCUS_EVENT, onWindowFocus)
  useWindowEvent(COPY_EVENT, onWindowFocus)

  return children
}

export default SystemClipboardListener