import React from 'react'
import { connect } from 'react-redux'
import autocompletePluginCreator from './AutocompletePluginCreator'
import { NODE_TYPES } from '../Constants'
import { markupProposalsNames as markupNames } from 'selectors/markups'

export default () => autocompletePluginCreator({
  renderPortal: (Portal, props) => (
    <MarkupNames>
      {names => (
        <Portal {...props} suggestions={names} />
      )}
    </MarkupNames>
  ),
  shouldHandleNode: (editor, { type }) => type === NODE_TYPES.MARK_UP_NAME && !editor.selectionIsExpanded(),
  onEnter: (suggestion, editor) => {
    editor.replaceCurrentText(suggestion)
  }
})

const _MarkupNames = ({ names, children }) => children(names)
const MarkupNames = connect(state => ({ names: markupNames(state) }))(_MarkupNames)