
// This object provides the styling information for our custom color
// styles.
export default {
  choice: { color: 'rgba(0, 180, 0, 1.0)', fontWeight: 'bold' },

  // TO be deleted
  red: { color: 'rgba(255, 0, 0, 1.0)' },
  orange: { color: 'rgba(255, 127, 0, 1.0)' },
  yellow: { color: 'rgba(180, 180, 0, 1.0)' },
  indigo: { color: 'rgba(75, 0, 130, 1.0)' },
  violet: { color: 'rgba(127, 0, 255, 1.0)' }
}