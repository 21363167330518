exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".actorPropertiesEditor__7OmAb .ant-table-body{max-height:25vh !important}", ""]);

// exports
exports.locals = {
	"actorPropertiesEditor": "actorPropertiesEditor__7OmAb"
};