import React from 'react'
import { head, path, pipe, not, prop, identity } from 'ramda'
import { noop } from 'ramda-adjunct'
import { message } from 'antd'
import { compose, withProps, withHandlers, withState, branch } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { NativeTypes } from 'react-dnd-html5-backend'
import { DropTarget } from 'react-dnd'
import { target, targetConnector } from 'utils/dnd'
import { DnDTypes } from 'dnd/model-dnd'

import Preferences from 'preferences/Preferences'
import { preference } from 'selectors/view'
import { createObjectByIdSelector } from 'selectors/objects'

import LineWithStoryboard from './LineWithStoryboard'

import styles from '../../SlateTextEditor.scss'

/**
 * Implements dnd for audio files and images (storyboard).
 * Must be a class to be used with react-dnd + our `targetConnector`
 */
class Line extends React.Component {
  render() {
    const { connectDropTarget = identity } = this.props
    return connectDropTarget(
      <div className={classNames(styles.Line)}>
        <LineWithStoryboard {...this.props} />
      </div>
    )
  }

  onDrop = monitor => {
    const { lineId, editor: { props: { setLineAudio, moveLineStoryboard = noop } }, onStoryboardChanged } = this.props
    const item = monitor.getItem()

    if (item.files) {
      // file dropped
      const [file] = monitor.getItem().files
      switch (head(file.type.split('/'))) {
        case 'audio': setLineAudio(lineId, file); return
        case 'image': onStoryboardChanged(file); return
        default: return message.error('The type of the dropped file is not supported on a line.')
      }
    } else if (item.lineId !== lineId) {
      // storyboard dragged
      moveLineStoryboard(item.lineId, lineId, item.storyboard)
    }
  }

  onHover = monitor => {
    const { setStoryboardOver, isTransient } = this.props
    // react-dnd doesn't allow to access the files before onDrop, so we cannot do a preview :(
    const item = monitor.getItem()
    if (item.lineId && !isTransient) {
      setStoryboardOver({ storyboardOver: item.storyboard })
    }
  }
}

const canDrop = ({ lineId }) => !!lineId

const lineIdPropSelector = (s, p) => p.node.get('data').get('line_id')

export default compose(
  connect(() => {
    const objectSelector = createObjectByIdSelector(lineIdPropSelector)()
    const storyboardEnabledSelector = preference(Preferences.TextEditor.showStoryboards)
    return (state, props) => ({
      line: objectSelector(state, props),
      storyboardsEnabled: storyboardEnabledSelector(state),
    })
  }),
  withProps(({ node, line }) => ({
    lineId: node.get('data').get('line_id'),
    isTransient: !node.get('data').get('line_id'),
    storyboard: path(['data', 'editor', 'storyboard'])(line)
  })),

  withState('storyboardOver', 'setStoryboardOver'),
  withHandlers({
    onStoryboardChanged: ({ editor: { props: { setLineStoryboard = noop } }, lineId }) => async file => {
      await setLineStoryboard(lineId, file)
      window.URL.revokeObjectURL(file.preview)
    },
    setAudio: ({ lineId, editor: { props: { setLineAudio = noop } } }) => file => {
      setLineAudio(lineId, file)
    },
    deleteLine: ({ editor, node }) => () => {
      editor.removeNodeByKey(node.key)
      editor.focus()
    }
  }),
  // DropTarget must be the last one, since it access the component (needs to be a class)
  branch(
    pipe(prop('readOnly'), not),
    DropTarget([NativeTypes.FILE, DnDTypes.Storyboard], target(canDrop), targetConnector()),
  ),
)(Line)
