exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3N-6f{color:#add8e6;cursor:pointer}.iconAsLink__3N-6f:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3xuAE{font-style:italic}.formFieldsVerticalGutter__1c5SC{padding:.5rem}.formFieldsVerticalGutter__1c5SC>*{margin:.5rem !important}.tableBackground__3s1d1 .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3s1d1 .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1zXTi .ant-notification-notice-close{display:none}.textEditor__1Sb8o{width:100%;-ms-flex-item-align:start;align-self:flex-start;padding-left:1em;padding-right:1em;padding-top:.5em;padding-bottom:.5em}.textEditor__1Sb8o>div{font-family:\"Courier Prime\",Courier,monospace}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3N-6f",
	"labelFromRef": "labelFromRef__3xuAE",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1c5SC",
	"tableBackground": "tableBackground__3s1d1",
	"notificationNotClosable": "notificationNotClosable__1zXTi",
	"textEditor": "textEditor__1Sb8o"
};