import { Sys } from 'beanie-engine-api-js'
import { T } from 'ramda'

// reconcilers
import { editClip } from './clip'
import { editChoices } from './choices'

// checkers
import clipChecker from './checkers/clipChecker'

// m2m
import clipModelTransformations from './m2m/clipM2M'

const reconcilers = {
  [Sys.clip]: editClip,
  [Sys.choices]: editChoices
}

const checkers = {
  [Sys.clip]: clipChecker
}

const modelToModelTransformations = {
  [Sys.clip]: clipModelTransformations
}

export const commitChanges = (container, items) => (reconcilers[container.sys] || T)(container, items)

export const modelToModel = (object, model) => (dispatch, getState) => {
  const transformation = modelToModelTransformations[object.sys]
  if (!transformation) { return model }
  return transformation(getState())(model)
}

/*
 * kind of the main action to type-check (or any other check) on objects being
 * edited.
 */
export const performChecks = (object, model) => async (dispatch, getState) => {
  const checker = checkers[object.sys]
  if (!checker) { return model }
  return checker(getState())(model)
}