import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Layout from '../Layout/Layout'
import routes from './routes'
import PlatformGeneralView from './PlatformGeneralView'

const PlatformRouter = () => (
  <Layout showProjectInformation={false} showLeftPanel={false}>
    <Switch>
      <Route path={routes.patform} component={PlatformGeneralView} />
    </Switch>
  </Layout>
)

export default PlatformRouter