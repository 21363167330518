import { pathOr } from 'ramda'
import { NodePlaybackStatus } from 'beanie-engine-api-js'

import { playbackIndex } from 'selectors/playback'

/**
 * called by BNE when polling the playback status of a node. Callback of `BNEHostGetPresentStatus`
 *
 * @returns returns a valid status for a node, check NodePlaybackStatus constants for further detail.
 * Valid status can be "presenting*" when still presenting, anything else then is not presenting.
 */
// eslint-disable-next-line no-unused-vars
const getNodeExecutionStatus = (dispatch, getState) => (bneNode, playbackId, speculative) => {
  const state = getState()
  if (speculative === undefined) speculative = false
  return pathOr(NodePlaybackStatus.NOT_PRESENTING, [playbackId, 'status'])(playbackIndex(state))
}

export default getNodeExecutionStatus