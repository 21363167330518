import { model } from 'beanie-engine-api-js'

import React from 'react'
import { compose } from 'recompose'
import { Tooltip, Icon } from 'antd'
import { path, map, uniq, pipe, prop, toPairs, isNil } from 'ramda'
import { mapIndexed } from 'ramda-adjunct'
import { connect } from 'react-redux'
import { pathFromProps } from 'selectors/props'
import { makeStaticVariableMutationsSelector } from 'selectors/state'
import { BNEActionMode } from 'model/constants'
import switchComponent from 'hocs/switchComponent'
import { sortDescending } from 'utils/ramda'

import styles from './VariablePotentialValuesPreview.scss'

const { types: { object: { Paths } } } = model

const VariablePotentialValuesPreview = ({ mutations }) => (mutations && Object.keys(mutations).length > 0 ?
  <ol>
    {toPairs(mutations).map(([mode, actions], i) => (
      <MutationsForMode key={i} mode={mode} actions={actions} />
    ))}
  </ol> : null
)

const NoValue = () => <span className={styles.NoValue}>Nothing</span>

const valueMutation = pathToValue => pipe(
  prop('actions'),
  map(path(pathToValue)),
  uniq,
  sortDescending,
  mapIndexed((value, i) => (
    <li key={i}>{isNil(value) ? <NoValue /> : value.toString()}</li>
  ))
)

const AssignMutations = valueMutation(['data', 'state_value'])
const Action2Mutations = valueMutation([...Paths.action2.effect, 'expr', 'value'])
const DecrementMutations = () => <li className={styles.decrements}><Tooltip title="Decrements"><Icon type="arrow-down" />1</Tooltip></li>
const IncrementMutations = () => <li className={styles.increments}><Tooltip title="Increments"><Icon type="arrow-up" />1</Tooltip></li>
const CoinFlipMutations = () => <li className={styles.coinFlip}><Tooltip title="Coin Flip"><Icon type="pay-circle" /></Tooltip></li>

const MutationsForMode = switchComponent({
  path: ['mode'],
  cases: {
    [BNEActionMode.assign]: AssignMutations,
    [BNEActionMode.increment]: IncrementMutations,
    [BNEActionMode.decrement]: DecrementMutations,
    [BNEActionMode.coinFlip]: CoinFlipMutations,
  },
  // assuming mode will be always correct... the only case where thre is no mode or is incorrect we will think its action2
  otherwise: Action2Mutations
})

export default compose(
  connect(() => {
    const mutationsSelector = makeStaticVariableMutationsSelector(pathFromProps(['variable', 'name']))
    return (state, props) => ({
      mutations: mutationsSelector(state, props)
    })
  })
)(VariablePotentialValuesPreview)