// import { createSelector } from 'selectors/reselect'
import { createSelector } from 'reselect'
import { prop, path, propEq, propOr, pipe, find, ifElse, always, pathEq, filter, map, pluck, flatten, uniqBy } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { nodeId } from 'selectors/props'
import { RevisionStateType } from '../model/app/revision/RevisionState'
import { RevisionSessionState } from '../model/constants'
import { isBranch, isCheckpoint } from 'utils/project'

const fromGrantToUsers = grant => {
  if (grant.to.__typename === 'User') return [grant.to]
  if (grant.to.__typename === 'Team') return pluck('user', grant.to.members.list)
}

export const projectSelector = prop('project')

export const buildConfigSelector = path(['project', 'buildConfig'])

export const projectName = path(['project', 'project', 'name'])
export const projectId = path(['project', 'project', '_id'])

export const currentProject = path(['project', 'project'])
export const currentProjectUsers = createSelector([currentProject], pipe(
  prop('grants'),
  map(fromGrantToUsers),
  flatten,
  uniqBy(prop('_id')),
))
export const currentRevision = path(['project', 'revision'])

export const revisionId = createSelector([currentRevision], prop('_id'))
export const revisionVersion = pipe(currentRevision, prop('version'))

export const currentIsBranch = createSelector(
  [currentRevision], revision => (revision ? isBranch(revision) : false))

export const currentCheckpointName = createSelector(
  [currentRevision], revision => (revision && isCheckpoint(revision) ? revision.version : 'latest'))

export const currentBranch = createSelector(
  [currentRevision], revision => (revision ? (isBranch(revision) ? revision : revision.sourceBranch) : undefined))

export const currentBranchId = createSelector(
  [currentBranch],
  revision => (revision ? revision._id : undefined)
)

export const branchVersion = pipe(currentBranch, prop('version'))

export const revisionState = path(['project', 'state'])
export const loading = pipe(
  revisionState,
  ifElse(
    propEq('type', RevisionStateType.LOADING),
    prop('revisionId'),
    always(undefined)
  )
)
export const loadingError = state => (state.project.state.type === RevisionStateType.ERROR ? state.project.state.error : undefined)
export const isLoaded = pathEq(['project', 'state', 'type'], RevisionStateType.LOADED)
export const hasLoadingError = pathEq(['project', 'state', 'type'], RevisionStateType.ERROR)
export const loaded = state => (state.project.state.type === RevisionStateType.LOADED ? revisionId(state) : undefined)
export const loadedAt = path(['project', 'loadedAt'])
export const lastChangeSet = path(['project', 'lastChangeSet'])
export const markupModel = pipe(currentRevision, propOr({ markups: [], types: [] }, 'markupModel'))

export const projectTimestamp = createSelector(
  [loadedAt, lastChangeSet],
  (loadingTime, lastChange) => (lastChange ? lastChange.timestamp : loadingTime)
)

// revisionSession

export const revisionSessionConnectionState = path(['project', 'connectionState'])
export const sessionId = path(['project', 'session', '_id'])

export const sessionsIndex = path(['project', 'sessions'])
export const sessions = pipe(
  path(['project', 'sessions']),
  Object.values,
  filter(propEq('state', RevisionSessionState.CONNECTED))
)

export const cursors = path(['project', 'cursors'])

export const createSessionsOnNode = () => createSelector(
  [nodeId, cursors, sessionsIndex],
  (id, cursorsPerSession, _sessionsIndex) => {
    return Object.entries(cursorsPerSession)
      .filter(([, oid]) => oid === id)
      .map(([sid]) => _sessionsIndex[sid])
      // TODO: test !
      .filter(isNotNil) // weird case we have cursor info but session is not there anymore
  }
)

// preferences

export const projectPreference = ({ name, defaultValue }) => createSelector(
  [currentProject],
  pipe(
    propOr([], 'preferences'),
    find(propEq('name', name)),
    ifElse(isNotNil, prop('value'), always(defaultValue))
  )
)

export const makeProjectPreferenceSelector = propName =>
  (state, props) => projectPreference(props[propName])(state)
