import React, { useCallback } from 'react'
import useCopyToClipboard from '../../hooks/ui/useCopyToClipboard'
import { isDeveloperCheck } from '../../security/platform'
import IconButton from '../Commons/IconButton'
import Secure from '../Commons/Secure'
import styles from './FactDrawer.scss'

/**
 *
 */
const FactDrawerTitle = ({ title, ast }) => {
  const copyToClipboard = useCopyToClipboard()
  const copyAST = useCallback(() => {
    copyToClipboard(JSON.stringify(ast, null, 2), 'AST copied to the clipboard!')
  }, [ast, copyToClipboard])

  return (
    (
      <div className={styles.FactDrawerTitle}>
        {title}
        <div className={styles.titleActions}>
          <Secure check={isDeveloperCheck}>
            <IconButton type="file-excel" tooltip="Copy AST" onClick={copyAST} />
          </Secure>
        </div>
      </div>
    )
  )
}

export default FactDrawerTitle