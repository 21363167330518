import { NODE_TYPES } from '../Constants'
import { Key } from 'utils/keyboard'
import { customDialogueLine } from '../slateMocks/dialogueLine'
import { customActorPart } from '../slateMocks/actorPart'
import { textNode } from '../slateMocks/textNode'
import { customTextPart } from '../slateMocks/actorTextPart'
import { equals } from 'ramda'
import { isEmptyOrNull } from 'utils/string'

export default () => ({
  onKeyDown (event, editor, next) {
    const { keyCode } = event
    const currentLine = editor.currentLineBlock()
    const { type, key, text } = currentLine

    if (keyCode === Key.ENTER) {
      event.preventDefault()
      event.stopPropagation()

      if (editor.focusIsAtEndOfNode(editor.currentPhysicalLine())) {
        createNewBlockOrMove(editor)
      } else {
        editor.splitCurrentLine()
      }

      return false
    }

    if (equals(keyCode, Key.TAB) && equals(type, NODE_TYPES.DIRECTOR_LINE) && isEmptyOrNull(text)) {
      const index = editor.indexOnParent(currentLine)
      event.preventDefault()
      event.stopPropagation()
      editor.replaceBlockByKey(key, customDialogueLine(customActorPart([]), customTextPart([])))
      editor.moveToPath([index, 0])
      editor.moveToStartOfBlock()
      return false
    }

    return next()
  },
})

const createNewBlockOrMove = editor => {
  const { type } = editor.currentPhysicalLine()
  const { path } = editor.currentFocus()

  if (type === NODE_TYPES.ACTOR_PART) {
    editor.moveToNextNode(path)
  } else {
    editor.createNewDirectorLine(path, [textNode('')])
  }
}
