import { Types } from 'actions/vm'
import { always, mergeDeepLeft } from 'ramda'
import initState from './init'

const { VM_DISPOSED, VM_LOADED, VM_LOADING } = Types

// LifeCycle
const LifeCycle = {
  [VM_DISPOSED]: always(initState),
  [VM_LOADING]: ({ revision }) => mergeDeepLeft({ loaded: false, loading: true, revision }),
  [VM_LOADED]: ({ revision }) => mergeDeepLeft({
    loaded: true,
    loading: false,
    revision,
    playback: initState.playback
  })
}

export default LifeCycle