exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".list__1wXVu{width:100%}.list__1wXVu .ant-list-item{padding-left:1.5rem}.list__1wXVu .ant-list-item>a{-ms-flex:.1 1 0%;flex:.1 1 0%;color:gray}.list__1wXVu .undone__2pN1V{opacity:.8;background:#e8e8e8;border-bottom:1px solid #dedede}.list__1wXVu .isCurrent__2Sac4{border-top:2px solid #e0e0e0}.list__1wXVu .isCurrent__2Sac4 .ant-list-item-meta-content .ant-list-item-meta-title{font-weight:bold}", ""]);

// exports
exports.locals = {
	"list": "list__1wXVu",
	"undone": "undone__2pN1V",
	"isCurrent": "isCurrent__2Sac4"
};