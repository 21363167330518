
import { isNil, remove } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useStore } from 'react-redux'
import { useRecoilState } from 'recoil'

import { searchesState, currentSearchState } from 'state/search/searches'
import { currentWalkListSearch, currentResult } from 'selectors/walklist'

import doSearch from 'actions/nodes/search/search'
import { Creators as WalkListCreators } from 'actions/walklist'

import SearchViewPanel from './SearchViewPanel'

const ConnectedSearchViewPanel = () => {
  // searches
  const [currentSearch, setCurrentSearch] = useRecoilState(currentSearchState)
  const [searches, setSearches] = useRecoilState(searchesState)

  const store = useStore()

  const [results, setResults] = useState()

  useEffect(() => {
    const r = !isNil(currentSearch) ? doSearch()(::store.getState, currentSearch.search) : undefined
    setResults(r)
  }, [currentSearch])

  // walklist
  const walkingSearch = useSelector(currentWalkListSearch)
  const currentlyWalking = useSelector(currentResult)

  const onToggleWalking = useCallback(onOff => {
    store.dispatch(onOff ? WalkListCreators.walkList(currentSearch.search, results) : WalkListCreators.close())
  }, [currentSearch, results])

  const onDeleteSearch = search => {
    const i = searches.indexOf(search) // works by identity
    const newList = remove(i, 1, searches)
    setSearches(newList)
    if (currentSearch === search) {
      setCurrentSearch(null)
    }
    if (walkingSearch === search) {
      store.dispatch(WalkListCreators.close())
    }
  }

  return (
    <SearchViewPanel
      searches={searches}
      currentSearch={currentSearch}
      results={results}
      onSearchSelected={setCurrentSearch}
      onDeleteSearch={onDeleteSearch}

      walkingSearch={walkingSearch}
      onToggleWalking={onToggleWalking}
      currentlyWalking={currentlyWalking}
    />
  )
}

export default ConnectedSearchViewPanel