import React from 'react'
import { Table } from 'antd'
import { remove, propSatisfies, update, prop } from 'ramda'
import { withPropsOnChange, compose, withHandlers } from 'recompose'

import { schema, isNode, parseRef } from 'beanie-engine-api-js'


import IconButton from 'components/Commons/IconButton'
import ObjectRefValue from './Value/ObjectRefValue'

import styles from './ArrayEmbeddedContent.scss'

const { Column } = Table

const ArrayEmbeddedContent = ({ isRefs, record: { value }, onNavigateRef, onRemove, onEdit }) => (
  <Table
    dataSource={(isRefs ? value.map(parseRef) : value).map(text => ({ text }))}
    size="small"
    pagination={false}
    showHeader={false}
    rowKey={prop('text')}
    className={styles.ArrayEmbeddedContent}
  >
    <Column
      title="Index"
      key="index"
      render={(text, element, i) => i}
    />
    <Column
      title="Element"
      key="element"
      // TODO: support editing a value here (update array position)
      render={(_, { text }, i) => (isRefs ?
        <ObjectRefValue
          onNavigateRef={onNavigateRef}
          value={text}
          filter={propSatisfies(isNode, 'sys')}
          isEditable
          truncate={false}
          onValueChanged={newValue => onEdit(i, newValue)}
        />
        : JSON.stringify(text))
      }
    />
    <Column
      title="Actions"
      key="actions"
      render={(_, element, i) => <IconButton type="close" onClick={() => onRemove(i)} />}
    />
  </Table>
)

export default compose(
  withPropsOnChange(
    ['object', 'record'],
    ({ object, record }) => ({
      isRefs: schema.isArrayOfRef(object.sys, record.path)
    })
  ),
  withHandlers({
    onRemove: ({ record: { path, value }, onPropertyChanged }) => index => {
      onPropertyChanged(path, remove(index, 1, value))
    },
    onEdit: ({ record: { path, value }, onPropertyChanged }) => (index, itemNewValue) => {
      const newValue = update(index, itemNewValue, value)
      onPropertyChanged(path, newValue)
    }
  })
)(ArrayEmbeddedContent)