import React from 'react'

import { Button, Icon } from 'antd'
import { compose } from 'recompose'

import SearchBox from 'components/Commons/SearchBox'
import CreateOrgModal from 'components/Organizations/CreateOrgModal'
import withBooleanState from 'hocs/withBooleanState'
import { isSuperAdminCheck } from 'security/platform'
import Secure from 'components/Commons/Secure'

import styles from './OrganizationTabHeader.scss'


const TabHeader = ({ refreshOrganizations, isCreateVisible, stopShowingModal, handleCreateOrg, onSearch }) => (
  <div className={styles.pageHeader}>
    <div className={styles.searchBox}>
      <SearchBox onSearch={onSearch} />
    </div>
    <Secure check={isSuperAdminCheck}>
      {isCreateVisible && <CreateOrgModal
        hide={stopShowingModal}
        onCreated={refreshOrganizations}
      />}
      <CreateOrganizationButton handleCreateOrg={handleCreateOrg} />
    </Secure>
  </div>
)

const CreateOrganizationButton = ({ handleCreateOrg }) => (
  <Button type="primary" onClick={handleCreateOrg}><Icon type="plus" />Create</Button>
)

export default compose(
  withBooleanState('isCreateVisible', 'handleCreateOrg', 'stopShowingModal'),
)(TabHeader)