import { assocPath, dissoc, dissocPath, mergeDeepLeft } from 'ramda'

import { Types } from 'actions/presets'
import { createReducerFlipped } from 'reducers/utils'

export const presetsInitialState = { }

const assocPreset = ({ preset, savegame, projectId, userId }) =>
  mergeDeepLeft({
    [preset]: {
      [userId]: {
        [projectId]: savegame
      }
    }
  })

const _dissocPreset = ({ preset, projectId, userId }) =>
  dissocPath([preset, userId, projectId])

const setActivePreset = ({ preset }) =>
  assocPath(['active'], preset)

const unsetActivePreset = () => state =>
  dissocPath(['active'])(state)

const clearActive = () => dissoc('active')

export const presets = createReducerFlipped({
  // REVIEWME: active cannot be propagated between projects
  // the whole state here needs to be rethink'ed to store which one is active also by project
  '@@INIT': clearActive,
  [Types.ASSOC_PRESET]: assocPreset,
  [Types._DISSOC_PRESET]: _dissocPreset,
  [Types.SET_ACTIVE_PRESET]: setActivePreset,
  [Types.UNSET_ACTIVE_PRESET]: unsetActivePreset
}, presetsInitialState)
