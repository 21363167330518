

import { connect } from 'react-redux'
import { compose } from 'recompose'
import { makeIsEnabledNode } from 'selectors/playback'

const withNodeIsEnabled = () => compose(
  connect((_, initialProps) => {
    const nodeIsEnabled = makeIsEnabledNode(initialProps.node.id)
    return (state, props) => ({
      isEnabled: nodeIsEnabled(state, props)
    })
  }),
)

export default withNodeIsEnabled