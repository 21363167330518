import { Sys } from 'beanie-engine-api-js'
import { toBNEAction } from '../utils'

/**
 * node type (sys) => BNE lua function to set text
 */
const textFunctionMapping = {
  // careful this will trim the text ! to avoid trimming we should pass an option
  [Sys.choice]: 'set_text',
}
const defaultSetter = 'set_name'

// TODO: make it receive the id and not a JSON object as input !
export const _editText = (object, text) => api => {
  // hack because BNE doesn't allow to set_target() with the id.
  // only supports passing the name of the node :(
  if (object.sys === Sys.jump) {
    api.update(object.id, { target: text })
  } else {
    const functionName = textFunctionMapping[object.sys] || defaultSetter
    const luaObject = api.obj.get(object.id)
    luaObject.call(functionName, text)
  }
}

export default toBNEAction(_editText, 'Edit Text')