exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SearchResultItem__1Tvi6{display:-ms-flexbox;display:flex;width:100%}.SearchResultItem__1Tvi6 .content__75-pW{min-width:50%;max-width:50%}", ""]);

// exports
exports.locals = {
	"SearchResultItem": "SearchResultItem__1Tvi6",
	"content": "content__75-pW"
};