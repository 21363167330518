import React from 'react'
import classNames from 'classnames'
import withNodeDropBehavior from '../../dnd/withNodeDropBehavior'
import styles from '../../TreeView.scss'

/**
 *
 */
const IdleChainPlaceholder = ({ connectDropTarget, droppingRight }) => {
  return connectDropTarget(
    <div className={classNames(styles.IdleChainPlaceholder, { [styles.dndIsHover]: droppingRight })}>No Idle Content</div>
  )
}

export default withNodeDropBehavior(IdleChainPlaceholder)