import React from 'react'
import { isNil, equals } from 'ramda'
import classNames from 'classnames'
import { lang } from 'beanie-engine-api-js'

import { EMPTY_OBJECT } from 'utils/object'

import styles from './NodeRuleEditor.scss'

const { rule: { error: { isError } } } = lang

/**
 * A bullet graphical representation of a boolean expression evaluation.
 * Like a traffic light. Used for boolean expressions like enabled_rules
 */
const BulletResult = ({ result, isDirty, shouldEval = false, className }) => {
  return (
    <div
      className={classNames(
        styles.result, {
          [styles.noEvaluate]: !shouldEval || isDirty
        },
        shouldEval && !isDirty ? {
          [styles.passed]: equals(result, true),
          [styles.notPassed]: equals(result, false),
          [styles.error]: isNil(result) || isError(result)
        } : EMPTY_OBJECT,
        className
      )}
    />
  )
}

export default BulletResult