export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_TOKEN_REFRESHED = 'LOGIN_TOKEN_REFRESHED'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS'

export const CREDENTIAL_MODIFICATION_ACTIONS = [LOGIN_TOKEN_REFRESHED, LOGIN_SUCCESS, LOGOUT]

export const logout = (expired = false) => ({ type: LOGOUT, expired })

// don't move up before "LOGOUT", there is a cyclic-dep between utils and login.
// eslint-disable-next-line import/first
import { asyncMethod } from './utils'
import { noop } from 'utils/functions'

const login = asyncMethod('POST', {
  path: 'api/auth/authenticate',
  actions: {
    request: LOGIN_REQUEST,
    receive: LOGIN_SUCCESS,
    error: LOGIN_ERROR
  },
  requireAuthentication: false
})

export const loginTokenRefreshed = (token, user) => ({ type: LOGIN_TOKEN_REFRESHED, token, user })

export const loginUser = (email, password, then = noop) => login({ body: { email, password }, actionPayload: { [LOGIN_SUCCESS]: { then } } })

export const clearLoginErrors = () => ({ type: CLEAR_LOGIN_ERRORS })

export const updateUserData = data => ({ type: UPDATE_USER_DATA, data })