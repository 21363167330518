import React, { useMemo } from 'react'
import classNames from 'classnames'
import { model, parseRef } from 'beanie-engine-api-js'

import TruthTableColumnHeader from '../../header/TruthTableColumnHeader'
import TruthTableDrawRow from './TruthTableDrawRow'

import styles from '../../TruthTable.scss'

const { types: { truthTable: { headers, rows, otherwise } } } = model

/**
 * A TruthTable that is being dragged
 */
const DraggingTruthTable = ({ truthTable }) => {
  const _headers = useMemo(() => headers(truthTable), [truthTable])
  const _rows = useMemo(() => rows(truthTable), [truthTable])

  return (
    <table
      className={classNames(styles.truthTable, styles.dragging)}
      border="0"
      cellPadding="0"
      cellSpacing="0"
    >
      <thead>
        <tr>
          {_headers.map((header, i) => (
            <TruthTableColumnHeader
              key={`header_${i}`}
              index={i}
              truthTable={truthTable}
              header={header}
            />
          ))}
        </tr>
      </thead>

      <tbody>
        {_rows.map((rowId, i) => (
          <TruthTableDrawRow
            withoutChain
            key={`row_${rowId}`}
            index={i}
            truthTable={truthTable}
            id={parseRef(rowId)}
          />
        ))}

        {otherwise(truthTable) && (
          <tr>
            <td colSpan={_headers.length}>
              <div className={styles.otherwise}>
                otherwise
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default DraggingTruthTable