import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import withDebouncedProp from 'hocs/withDebouncedProp'

import { isSynchingInbound } from 'selectors/sync'

/* eslint-disable import/no-webpack-loader-syntax */
import SynchInboundIcon from '-!svg-react-loader!./synch-inbound.svg'

import SynchingIndicatorIcon from './SynchIndicatorIcon'

const InboundSynchIndicator = compose(
  connect(state => ({ isSynching: isSynchingInbound(state) })),
  withDebouncedProp('isSynching'),
  withProps(({ debounced }) => ({ iconType: SynchInboundIcon, iconStyle: 'inbound', isIconActive: debounced.value })),
)(SynchingIndicatorIcon)

export default InboundSynchIndicator