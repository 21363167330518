import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import withConfirm from 'hocs/withConfirm'

import { OrganizationMembershipRole } from 'utils/authentication'

import OrgAction from '../Commons/OrgAction'
import OrgActionLink from '../Commons/OrgActionLink'
import ChangeRoleModal from '../Commons/ChangeRoleModal'

import organizationRemoveMemberMutation from 'api/mutations/organizationRemoveMember.graphql'
import organizationSetMemberRoleMutation from 'api/mutations/organizationSetMemberRole.graphql'

import styles from './OrganizationMemberActions.scss'

// Compare with OrganizationTeamActions they are the exact oppossite
// way of doing it. This one uses HOCs, while the other uses renderProps

const OrganizationMemberActions = ({ organization, member, remove, changeRole }) => (
  <div className={styles.actionsContainer}>
    <OrgAction>
      <ChangeRoleModal container={organization} member={member} onChange={changeRole} roles={OrganizationMembershipRole} />
    </OrgAction>
    <OrgActionLink label="Remove" tooltip="Remove from organization" onClick={remove} />
  </div>
)

export default compose(
  withConfirm,
  graphql(organizationSetMemberRoleMutation, { name: 'setMemberRole' }),
  graphql(organizationRemoveMemberMutation, { name: 'removeMember' }),
  withHandlers({
    removeMember: ({ removeMember, organization, member, refreshItems }) => async () => {
      await removeMember({
        variables: { input: { organizationId: organization._id, userId: member.user.id } }
      })
      refreshItems()
    },
    changeRole: ({ setMemberRole, organization, member, refreshItems }) => async ({ Role: role }) => {
      await setMemberRole({ 
        variables: { input: { organizationId: organization._id, userId: member.user.id, role } }
      })
      refreshItems()
    }
  }),
  withHandlers({
    remove: ({ organization, member, confirm, removeMember }) => () => {
      confirm(removeMember, <RemoveConfirmMessage organization={organization} member={member} />)
    }
  })
)(OrganizationMemberActions)

const RemoveConfirmMessage = ({ organization, member }) => (
  <div>This will remove <strong>{member.user.firstName} {member.user.lastName}</strong> from <strong>{organization.name}</strong>
    <br />Are you sure ?
  </div>
)