import { HORIZONTAL_VALUES, newPosition, VERTICAL_VALUES } from '../../position'

/**
 * Splits the node into a 9 quadrants positions of same size
 *     33%             33%            33%
 * [ TOP-LEFT,     TOP-CENTER,    TOP-RIGHT    ]
 * [ CENTER-LEFT,  CENTER-CENTER, CENTER-RIGHT ]
 * [ BOTTOM-LEFT,  BOTTOM-CENTER, BOTTOM-RIGHT ]
 *
 */
const nodeComputePosition = (mouse, rect) => {
  const { top, height, left, width } = rect
  const { x, y } = mouse

  return newPosition(
    computeAxis(y - top, height, VERTICAL_VALUES),
    computeAxis(x - left, width, HORIZONTAL_VALUES)
  )
}

const computeAxis = (relativeX, width, [first, second, third]) => {
  const hSlotSize = width / 3
  if (relativeX <= hSlotSize) {
    return first
  } else if (relativeX <= (2 * hSlotSize)) {
    return second
  } else {
    return third
  }
}

export default nodeComputePosition
