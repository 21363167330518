import fetch from 'isomorphic-fetch'
import { andThen, otherwise, pipe } from 'ramda'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { asyncMethod } from './utils'
import tarball from '../utils/tarball'

import notifyExtensionMutation from 'api/mutations/notifyExtension.graphql'

const EXTENSION_INDEX_FILENAME = 'bne_ext_def.lua'
export const HEAD = 'head'
export const SOURCE = 'src'
const ALL = '*'

export const Operations = {
  Add: 'add',
  Delete: 'delete',
  Rename: 'rename'
}

export const addExtension = ({ fileName: name, content }) => (dispatch, getState, { getApolloClient: getClient }) => {
  const revisionId = revisionIdSelector(getState())

  return pipe(
    () => dispatch(asyncMethod(
      'POST', {
        path: 'api/engine'
      }
    )({
      body: { revisionId, extension: name, filename: `${name}.tar`, version: HEAD, vm: SOURCE }
    })),
    andThen(async ({ signedUrl }) => {
      const tar = new tarball.TarWriter()
      tar.addTextFile(EXTENSION_INDEX_FILENAME, content)

      const blob = await tar.writeBlob()

      await fetch(signedUrl, {
        method: 'PUT',
        body: blob
      })

      await getClient().mutate({
        mutation: notifyExtensionMutation,
        variables: { input: { operation: Operations.Add, revisionId, extension: name, filename: `${name}.tar`, version: HEAD, vm: SOURCE } },
      })

      return { status: 'ok' }
    }),
    otherwise(error => {
      return { status: 'failed', message: error.message }
    }),
  )()
}

export const publishExtension = ({ fileName: name }) => (dispatch, getState) => {
  const revisionId = revisionIdSelector(getState())

  return pipe(
    () => dispatch(asyncMethod(
      'POST', {
        path: 'api/engine/publish'
      }
    )({
      body: { revisionId, extension: name, version: HEAD }
    })),
    andThen(() => {
      return { status: 'ok' }
    }),
    otherwise(error => {
      return { status: 'failed', message: error.message }
    }),
  )()
}

export const getExtensionContent = ({ fileName: name }) => (dispatch, getState) => pipe(
  getState,
  revisionIdSelector,
  revisionId => dispatch(asyncMethod(
    'GET', {
      path: `api/engine?${new URLSearchParams({ revisionId, package: `extensions/${name}`, filename: `${name}.tar`, version: HEAD, vm: SOURCE })}`
    }
  )()),
  andThen(async ({ signedUrl }) => {
    const res = await fetch(signedUrl)

    const tar = new tarball.TarReader()
    const arrayBuffer = await res.arrayBuffer();
    tar.readArrayBuffer(arrayBuffer)

    return tar.getTextFile(EXTENSION_INDEX_FILENAME)
  })
)()

export const deleteExtension = extension => (dispatch, getState, { getApolloClient: getClient }) => {
  const revisionId = revisionIdSelector(getState())

  return pipe(
    () => dispatch(asyncMethod(
      'DELETE', {
        path: `api/engine?${new URLSearchParams({ revisionId, extension, filename: ALL, version: ALL, vm: ALL })}`
      }
    )()),
    andThen(async () => {
      await getClient().mutate({
        mutation: notifyExtensionMutation,
        variables: { input: { operation: Operations.Delete, revisionId, extension, filename: `${extension}.tar`, version: HEAD, vm: SOURCE } },
      })

      return { status: 'ok' }
    }),
    otherwise(error => {
      return { status: 'failed', message: error.message }
    }),
  )()
}

export const changeExtensionName = ({ fileName }, newName) => (dispatch, getState, { getApolloClient: getClient }) => {
  const revisionId = revisionIdSelector(getState())

  return pipe(
    () => dispatch(asyncMethod(
      'PUT', {
        path: 'api/engine'
      }
    )({
      body: { revisionId, extension: fileName, newName }
    })),
    andThen(async () => {
      await getClient().mutate({
        mutation: notifyExtensionMutation,
        variables: { input: { operation: Operations.Rename, revisionId, oldName: fileName, newName, version: HEAD, vm: SOURCE } },
      })

      return { status: 'ok' }
    }),
    otherwise(error => {
      return { status: 'failed', message: error.message }
    }),
  )()
}
