import React, { useEffect, useCallback } from 'react'
import useEnabledRule from 'hooks/useEnabledRule'

import NodeRuleEditor from './NodeRuleEditor'
import RuleContainer from './RuleContainer'
import classNames from 'classnames'
import { lang } from 'beanie-engine-api-js'

import styles from './NodeWithEnabledRule.scss'

const { rule: { typing: { types } } } = lang

const EnabledRule = ({ node }) => {
  const { id, data: { enabled_rule: { source } } } = node
  const {
    value,
    setValue,
    edit,
    onEnter,
    onCancel,
    onDeleteRule,
    isEditingEnabledRuleNode
  } = useEnabledRule(id)

  const onContextMenu = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
  }, [])

  useEffect(() => setValue(source), [source])

  return (
    <div
      onContextMenu={onContextMenu}
      className={classNames(styles.enabledRule, {
          [styles.editing]: isEditingEnabledRuleNode
        })}>
      {isEditingEnabledRuleNode ?
        <NodeRuleEditor
          expectedType={types.Bool}
          setValue={setValue}
          itValue={id}
          value={value}
          node={node}
          onCancel={onCancel}
          onApply={onEnter} /> :
        <RuleContainer
          node={node}
          source={source}
          edit={edit}
          onDeleteRule={onDeleteRule} />}
    </div>
  )
}

export default EnabledRule