import React from 'react'
import { Icon } from 'antd'
import LinkButton from './LinkButton'

export const Disposition = {
  Horizontal: 'horizontal',
  Vertical: 'vertical'
}

const icon = (disposition, collapsed) => {
  if (collapsed) {
    return disposition === Disposition.Vertical ? 'down' : 'left'
  } else {
    return disposition === Disposition.Vertical ? 'up' : 'right'
  }
}

export default class CollapseToggle extends React.Component {
  shouldComponentUpdate = (nextProps) => (
    this.props.disposition !== nextProps.disposition
    || this.props.collapsed !== nextProps.collapsed
    || this.props.className !== nextProps.className
  )
  render() {
    const { disposition = Disposition.Horizontal, collapsed, className } = this.props

    return (
      <LinkButton className={className} onClick={this.toggle}>
        <Icon type={icon(disposition, collapsed)} />
      </LinkButton>
    )
  }

  toggle = () => {
    const { onExpand, onCollapse } = this.props
    const collapsed = !this.props.collapsed
    if (collapsed && onExpand) {
      onCollapse()
      return
    }
    if (!collapsed && onCollapse) {
      onExpand()
    }
  }
}