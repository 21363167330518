import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose, withProps, onlyUpdateForKeys } from 'recompose'
import classNames from 'classnames'
import { Tooltip } from 'antd'
import { always, cond, head, isNil, objOf, path, pipe, splitAt, T, when } from 'ramda'

import { obj } from 'components/propTypes'
import { isEmptyOrNull } from 'utils/string'
import { EMPTY_LABEL } from 'utils/ui'

import makeObjectLabelSelector, { LabelType } from 'selectors/objects/makeObjectLabelSelector'

import withLinkToNode from 'hocs/withLinkToNodeRefract'

import NodePathPopover from '../TreeView/NodePathPopover/NodePathPopover'

import commonStyles from 'styles/commons.scss'
import styles from './NodeLabel.scss'

const LABEL_FOR_SYS = {
  truth_table: 'Truth table',
  truth_table_row: 'Truth table row',
  truth_table_otherwise_row: 'Otherwise',
}

export const LIMIT_TO_TRUNCATE = 15
const needsTooltip = (value, truncated, showTooltip) => () => value !== truncated && showTooltip
const tooltipWrap = value => c => (<Tooltip title={value} mouseEnterDelay={1.4}>{c}</Tooltip>)

const nodeLabel = (truncatedLabel, node) => {
  if (!isEmptyOrNull(truncatedLabel)) return truncatedLabel

  return LABEL_FOR_SYS[node?.sys] || node?.data?.name || EMPTY_LABEL
}

export const _NodeLabel = ({ node, label, truncatedLabel, isSelected, hoveringToNavigate = false, onMouseEnter, onMouseLeave, onClick, showTooltip }) => {
  const text = label && !label.error && label.value

  const wrapper = !label?.error && hoveringToNavigate ? children => <NodePathPopover id={label.to}>{children}</NodePathPopover> : when(needsTooltip(text, truncatedLabel, showTooltip), tooltipWrap(text))

  return wrapper(
    <div
      className={classNames(
        styles.label,
        {
          [styles.error]: !!label?.error,
          [styles.empty]: isNil(text),
          [commonStyles.labelFromRef]: label && label.type === LabelType.REFERENCE,
          [styles.selected]: isSelected,
          [styles.hoveringToNavigate]: !label?.error && hoveringToNavigate
        }
      )}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {nodeLabel(truncatedLabel, node)}
    </div>
  )
}

const labelValue = path(['label', 'value'])
const truncLabelProp = objOf('truncatedLabel')
const addTruncatedLabel = cond([
  [pipe(labelValue, isNil), always(truncLabelProp(undefined))],
  [T, pipe(labelValue, splitAt(LIMIT_TO_TRUNCATE), head, truncLabelProp)]
])

const NodeLabel = compose(
  connect((initialState, initialProps) => {
    const labelSelector = makeObjectLabelSelector(initialProps.node.id)
    return state => ({
      label: labelSelector(state)
    })
  }),
  withProps(addTruncatedLabel),
  withLinkToNode({
    onClickToNavigate: ({ label, onNodeSelectedAction }) => {
      if (!label?.error) onNodeSelectedAction(label.to)
    }
  }),
  onlyUpdateForKeys([
    'node',
    'isSelected',
    'label',
    'hoveringToNavigate',
    'showTooltip'
  ]),
)(_NodeLabel)

export default NodeLabel

NodeLabel.propTypes = {
  node: obj.isRequired,
  isSelected: PropTypes.bool,
}