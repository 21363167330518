import React from 'react'
import { Mutation } from 'react-apollo'
import { Spin } from 'antd'

import teamDeleteMutation from 'api/mutations/teamDelete.graphql'
import { teamWriteAccess } from 'security/team'

import withConfirm from 'hocs/withConfirm'

import Secure from 'components/Commons/Secure'
import LinkButton from 'components/Commons/LinkButton'

// Compare with OrganizationMembersActions they are the exact oppossite
// way of doing it. This one uses (apollo react components with) renderProps
// We might want to abstract and reuse some things here.

const OrganizationTeamActions = ({ organization, team, confirm, refreshItems }) => (
  <div>
    <Secure context={{ organization, team }} check={teamWriteAccess}>
      <Mutation mutation={teamDeleteMutation}>
        {(perform, { loading }) => (
          <Spin spinning={loading}>
            <LinkButton
              tooltip="Remove from organization"
              onClick={() => {
                const doIt = async () => {
                  await perform({ variables: { input: { teamId: team._id } } })
                  refreshItems()
                }
                confirm(doIt, <DeleteTeamConfirm team={team} />)
              }}>
              Remove
            </LinkButton>
          </Spin>
        )}
      </Mutation>
    </Secure>
  </div>
)

const DeleteTeamConfirm = ({ team }) => (
  <div>This will completely delete the team <strong>{team.name}</strong> from the system.
    <br />Are you sure ?
  </div>
)

export default withConfirm(OrganizationTeamActions)