import { equals, includes, path, pipe, prop, toLower } from 'ramda'
import { syntaxTree } from '@codemirror/language'
import { EditorState } from '@codemirror/state'
import { basicSetup } from '@codemirror/basic-setup'
import { CompletionContext } from '@codemirror/autocomplete'
import rules from './RuleLanguageExtension'
import { isUUID } from 'utils/string'
import { flatMap } from 'utils/ramda'

export const keywordList = [
  'is',
  'is not',
  'not',
  'it',
  'when',
  'otherwise',
  'then',
  'set',
  'to',
  'executed',
  'choose',
  'execution_count',
  'last_chosen',
  'and',
  'or',
  'max',
  'min'
]

export const IT = 'it'
export const NODE_REF_TYPE = 'NodeRef'
export const COMPARE_TYPE = 'EqOp'
export const OBJECT_ID_TYPE = 'ObjectId'
export const STRING_TYPE = 'String'
export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'

export const isOneOf = types => node => {
  if (!node) return false

  const { name, firstChild, lastChild } = node

  return includes(name, types) || !!(firstChild && equalNodes(firstChild, lastChild) && isOneOf(types)(firstChild))
}

export const isOfType = _type => node => {
  if (!node) return false

  const { name, firstChild, lastChild } = node

  return name === _type || !!(firstChild && equalNodes(firstChild, lastChild) && isOfType(_type)(firstChild))
}

export const isCommonVariable = isOfType('Variable')
export const isSpecialVariable = isOfType('SpecialVariable')
export const isVariable = isOneOf(['Variable', 'SpecialVariable'])
export const isFactApp = isOfType('FactApp')
export const isProperty = isOfType('Property')
export const isAssignmentNode = isOfType('Assignment')
export const isNodeRef = isOfType(NODE_REF_TYPE)
export const isEqNode = isOfType(COMPARE_TYPE)
export const isOfNode = isOfType('PropFromNode')
export const isNodeKeyword = isOfType('NodeKeyword')
export const isSetKeyword = isOfType('Set')
export const isToKeyword = isOfType('To')
export const isOfKeyword = isOfType('Of')
export const isIsKeyword = isOfType('Is')
export const isLastChosenKeyword = isOfType('LastChosen')
export const isExecutedKeyword = isOfType('Executed')
export const isChooseKeyword = isOfType('Choose')
export const isFactVariable = node => isVariable(node) && isFactApp(node.parent)

export const isCmpNode = isOneOf(['EqOp', 'GeOp', 'LeOp', 'LtOp', 'GtOp', 'NeOp'])

export const isError = path(['type', 'isError'])

export const editorStateFrom = ({ source, selection, factNames = [], variables = [], possibleStaticVariablesValues = {} }) => {
  const { cursor = 0, from: _from, to } = selection
  return EditorState.create({
    doc: source,
    selection: { head: _from || cursor, anchor: to || cursor },
    extensions: [
      basicSetup,
      rules({ factNames, variables, possibleStaticVariablesValues })
    ]
  })
}

export const equalNodes = (tree1, tree2) => tree1 === tree2 ||
  (tree1.toString() === tree2.toString() && tree1.from === tree2.from && tree1.to === tree2.to)

export const rootParent = node => {
  let currentParent = node.parent

  while (currentParent && equalNodes(currentParent.firstChild, currentParent.lastChild)) {
    currentParent = currentParent.parent
  }

  return currentParent
}

const firstSiblingTo = to => (initialNode, admitErrorNode = true) => {
  const getTo = ({ firstChild, lastChild }) => (to === LEFT ? firstChild : lastChild)
  const getInverse = ({ firstChild, lastChild }) => (to === LEFT ? lastChild : firstChild)

  if (!initialNode) return initialNode

  let current = initialNode
  let currentParent = current.parent

  // We go up until find a {TO} sibling
  while (currentParent && equalNodes(getTo(currentParent), current)) {
    current = currentParent
    currentParent = currentParent.parent
  }

  if (!currentParent) return

  // Select my {TO} sibling
  current = to === LEFT ? current.prevSibling : current.nextSibling

  // Go down as much as possible to the {INVERSE}
  while (getInverse(current)) {
    current = getInverse(current)
  }

  // Doesn't makes sense if the found sibling its itself
  if (equalNodes(initialNode, current)) return

  // If we want to return the first that is not an error, we have to call recursively
  if (!admitErrorNode && isError(current)) return firstSiblingTo(to)(current, admitErrorNode)

  return current
}

export const firstSibilingToLeft = firstSiblingTo(LEFT)
export const firstSibilingToRight = firstSiblingTo(RIGHT)

export const keywords = keywordList.join(' ')
export const tokenBefore = tree => tree.cursor.moveTo(tree.from, -1)

export const currentTextNode = (state, current) => state.sliceDoc(current.from, current.to)

export const editorText = ({ state }) => currentTextNode(state, state.tree)

export const nodeInPosition = (state, cursorPos) => {
  let current = syntaxTree(state).resolveInner(cursorPos, -1)

  if (state.sliceDoc(current.to - 1, current.to) === ' ') {
    const error = syntaxErrorNode(state)
    if (error) { current = error }
  }

  return current
}

export const sourceContext = (state, pos) => {
  const current = nodeInPosition(state, pos)
  const text = isError(current) ? currentTextNode(state, current) : ''

  return {
    parent: rootParent(current),
    leftSibling: firstSibilingToLeft(current),
    rightSibling: firstSibilingToRight(current),
    isError: current.type.isError,
    from: current.from,
    to: current.to,
    current,
    text
  }
}

export const getChildFromNode = (node, childType) => {
  const { name, firstChild, lastChild } = node
  if (name === childType) return node

  const found = getChildFromNode(firstChild, childType)
  if (found) return found

  if (equalNodes(firstChild, lastChild)) return null

  return getChildFromNode(lastChild, childType)
}

export const isIt = pipe(toLower, equals(IT))

export const nodeRefs = state => {
  const refs = []
  syntaxTree(state).iterate({
    enter: (type, from, to) => {
      const typeName = type.name
      if (typeName === NODE_REF_TYPE || typeName === OBJECT_ID_TYPE) {
        const idWithDelimiters = currentTextNode(state, { from, to })
        const id = isIt(idWithDelimiters) ? IT : idWithDelimiters.slice(1, -1)
        if (isUUID(id) || isIt(idWithDelimiters)) {
          refs.push({ id, from, to })
        }
      }
    }
  })

  return refs
}

// Basic linter using pure codemirror
// TODO: investigate how to define a custom linter using the iterate method and current parser state
export const syntaxErrorNode = state => {
  let node
  syntaxTree(state).iterate({
    enter: (type, from, to, get) => {
      if (type.isError && !node) {
        node = get()
      }
    },
  })
  return node
}

export const analyzeAST = (tree, checks = []) => {
  tree.iterate({
    enter: (type, from, to, get) => {
      checks.map(check => check({ type, from, to, node: get() }))
    },
  })

  return flatMap(prop('diagnostics'), checks)
}

export const makeEditorContext = ({ source, selection }) => {
  const { cursor = 0 } = selection
  const state = editorStateFrom({ source, selection })
  const completionContext = new CompletionContext(state, cursor)
  const ctx = sourceContext(state, completionContext.pos)
  return { state, completionContext, ctx, cursor }
}

export const doEditorTest = ({ source, selection }, desc, func) => {
  const context = makeEditorContext({ source, selection })
  it(desc, () => func(context))
}

doEditorTest.only = ({ source, selection }, desc, func) => {
  const context = makeEditorContext({ source, selection })
  // eslint-disable-next-line mocha/no-exclusive-tests
  it.only(desc, () => func(context))
}