exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__teSVa{color:#add8e6;cursor:pointer}.iconAsLink__teSVa:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3y2Sp{font-style:italic}.formFieldsVerticalGutter__3Z81-{padding:.5rem}.formFieldsVerticalGutter__3Z81->*{margin:.5rem !important}.tableBackground__2Qw7q .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2Qw7q .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2tTjF .ant-notification-notice-close{display:none}.historyTable__3AB8w{-ms-flex:0 0 30vw;flex:0 0 30vw;overflow:auto;-webkit-box-shadow:2px 2px 2px #d2d2d3;box-shadow:2px 2px 2px #d2d2d3;background-color:#fff}.historyTable__3AB8w .timestampColumn__3p0CU{width:25%}.historyTable__3AB8w .authorColumn__1vPrw{width:8%}.historyTable__3AB8w .descriptionColumn__kQC-5{width:62%}.historyTable__3AB8w .selectedRow__3v6jQ{font-weight:bold}.historyTable__3AB8w .isRevert__dxVBk{font-style:italic;background-color:#f2f2f2;color:#9f9e9e}.historyTable__3AB8w .isReapply__3fMpv{font-style:italic;background-color:#f2f2f2;color:#9f9e9e}.historyTable__3AB8w .ant-table-row{cursor:pointer}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__teSVa",
	"labelFromRef": "labelFromRef__3y2Sp",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3Z81-",
	"tableBackground": "tableBackground__2Qw7q",
	"notificationNotClosable": "notificationNotClosable__2tTjF",
	"historyTable": "historyTable__3AB8w",
	"timestampColumn": "timestampColumn__3p0CU",
	"authorColumn": "authorColumn__1vPrw",
	"descriptionColumn": "descriptionColumn__kQC-5",
	"selectedRow": "selectedRow__3v6jQ",
	"isRevert": "isRevert__dxVBk",
	"isReapply": "isReapply__3fMpv"
};