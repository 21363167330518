import React from 'react'
import { connect } from 'react-redux'

import { withHandlers, compose, withState, withPropsOnChange } from 'recompose'
import classNames from 'classnames'
import { urlForAudio } from 'model/resources'
import Waveform from './Waveform'

import { volume as volumeSelector } from 'selectors/view'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { token as tokenSelector } from 'selectors/auth'


import { Icon } from 'antd'

import LinkButton from 'components/Commons/LinkButton'

import styles from './SoundPlayer.scss'

const _SoundPlayer = ({ className, audioURL, options, volume, pos, playing, handlePosChange, handleTogglePlay, onFinish }) => (
  <div className={classNames(styles.player, className)}>
    <LinkButton onClick={() => handleTogglePlay()}>
      <Icon type={`${playing ? 'pause' : 'play'}-circle`} style={{ fontSize: '2em' }} />
    </LinkButton>
    <Waveform
      className={styles.wave}
      audioURL={audioURL}
      volume={volume}
      pos={pos}
      onPosChange={handlePosChange}
      playing={playing}
      options={options}
      onFinish={() => onFinish()}
    >
      <Duration />
    </Waveform>
  </div>
)

const playingInitial = false
const posInitial = 0

/**
 * Pure component not connected
 * You must provide "audioURL".
 * If you only have the name/path of the revision resource then use the default export which assembles the URL
 */
export const SoundPlayer = compose(
  withState('playing', 'setPlaying', playingInitial),
  withState('pos', 'setPos', posInitial),
  withHandlers({
    handleTogglePlay: ({ playing, setPlaying }) => () => { setPlaying(!playing) },
    onFinish: ({ setPlaying, setPos }) => () => {
      setPlaying(playingInitial)
      setPos(posInitial)
    },
    handlePosChange: ({ setPos }) => e => { setPos(e.originalArgs[0]) }
  })
)(_SoundPlayer)

//
// connected to play revision's audio resources (non-presentational)
//

const SoundPlayerForRevisionResource = compose(
  connect(state => ({
    volume: volumeSelector(state),
    revisionId: revisionIdSelector(state),
    token: tokenSelector(state)
  })),
  withPropsOnChange(['token', 'revisionId', 'audioFile'], ({ token, revisionId, audioFile }) => ({
    audioURL: urlForAudio(token, revisionId, audioFile)
  })),
)(SoundPlayer)

export default SoundPlayerForRevisionResource

const Duration = ({ wavesurfer }) => (
  <div>
    <span className={styles.durationNumber}>
      {wavesurfer && round(wavesurfer.getDuration())}
    </span>
    <span className={styles.durationUnit}>sec</span>
  </div>
)

const round = num => Math.round(num * 100) / 100