import { Sys } from 'beanie-engine-api-js'
import { isEmptyOrNull } from 'utils/string'
import { isNotSys } from '../../../model/project/searches/query/bneQueries'
import performSearch from './performSearch'
import { matchSearch, findSearch, compositeSearch } from 'model/project/searches/searches'
import { idQuery } from 'model/project/searches/query/queries'

/**
 * Given a text and optionally a Query to filter objects it searches for matches of the given text
 * in those objects matching the Query.
 * Returns { search, result } where result is a list of SearchResult and search is the AST definition of the Search
 * we internally did. With the Search you can do things like store it in the history, persist it, share it to other users,
 * etc.
 */
const searchMatchingText = (text, { filterOption: filterQuery, visitor } = {}) => dispatch =>
  dispatch(performSearch(createSearch(text, filterQuery), visitor))


const createSearch = (text, filterQuery) => {
  const s = (isEmptyOrNull(text) ?
    matchSearch(text, filterQuery)
    : compositeSearch(
      findSearch(idQuery(text)),
      matchSearch(text, filterQuery)
    )
  )
  return {
    title: `match "${text}"`,
    ...s
  }
}

// search match lines, not clips (otherwise we will match the clip label because it will have the first line label)
export const globalSearchQuery = isNotSys(Sys.clip)
export const createGlobalSearch = text => createSearch(text, globalSearchQuery)

export default searchMatchingText
