import Groups from '../Groups'
import Namespaces from 'shortcuts/Namespaces'
import { Creators as WalklistCreators } from 'actions/walklist'
import { Creators as UiCreators } from 'actions/ui'
import Contexts from 'shortcuts/contexts'

const base = {
  group: Groups.Editing,
  preventDefault: true,
  stopPropagation: true,
  context: Contexts.Tree,
}

export const SEARCH = {
  ...base,
  context: null,

  label: 'Search',
  icon: 'search',

  namespace: Namespaces.Project.Edit,
  shortcut: { osx: 'command+f', windows: 'ctrl+f', linux: 'ctrl+f' },

  action: UiCreators.openGlobalSearch,
}

export const NEXT = {
  ...base,
  label: 'Next',
  icon: 'down',

  shortcut: { osx: 'option+right', windows: 'alt+right', linux: 'alt+right' },

  action: WalklistCreators.next,
}

export const PREVIOUS = {
  ...base,
  label: 'Previous',
  icon: 'up',

  shortcut: { osx: 'option+left', windows: 'alt+left', linux: 'alt+left' },

  action: WalklistCreators.previous,
}