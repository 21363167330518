import React from 'react'
import { Icon } from 'antd'
import classNames from 'classnames'
import IconButton from '../../Commons/IconButton'

import styles from '../TreeView.scss'

/**
 * A box to render a reference to a node that doesn't exists !
 */
const UnresolvedNode = ({ onRemoveFromReferencer }) => {
  return (
    <div className={classNames(styles.UnresolvedNode, styles.bneContainerRow, styles.bneNode)}>
      <Icon type="warning" theme="filled" />
      Unresolved
      {onRemoveFromReferencer && <IconButton type="close" onClick={() => onRemoveFromReferencer()} />}
    </div>
  )
}

export default UnresolvedNode