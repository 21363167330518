import React, { useCallback } from 'react'
import classNames from 'classnames'
import useExtensionPoint from '../../hooks/useExtensionPoint'
import IconButtonExtension from './IconButtonExtension'

import styles from './ExtensionPoint.scss'
import { useDispatch } from 'react-redux'
import { toBNEAction } from 'engine/actions/utils'

const defaultRenderExtension = (extension, { moreProps }) => <IconButtonExtension key={extension.id} extension={extension} {...moreProps} />

/**
 * The main component to declare UI extension points in the app.
 * Any react component can be extended by rendering one of this components that specifies that's the "point".
 * Then the system will include there all dynamic extensions.
 * You must provide a @renderExtension() prop to render each extension in the way you prefer/need.
 * If not provided then it will use a default impl that renders @IconButtonExtension.
 * Example usage
 *
 *   <div>
 *     Hello this is my text that can be extended with more paragraphs
 *     <ExtensionPoint
 *        point="PARAGRAPHS"
 *        renderExtension={extension => (
 *          <p>{extension.text}</p>
 *        )}
 *     />
 *   </div>
 *
 * Any extra prop provided to <ExtensionPoint> will also be forwarded to the "renderExtension" method.
 */
const ExtensionPoint = ({ point, renderExtension = defaultRenderExtension, className, useFragmentContainer = false, ...others }) => {
  const extensions = useExtensionPoint(point)

  const content = extensions
    .filter(({ enabledOn }) => (enabledOn ? (enabledOn.invoke || enabledOn)(...(others.params || [])) : true))
    .map(ex => <ExtensionContent {...others} extension={ex} renderExtension={renderExtension} />)

  return useFragmentContainer ? (
    <React.Fragment>{content}</React.Fragment>
  ) : (
    <div className={classNames(styles.ExtensionPoint, className)}>
      {content}
    </div>
  )
}

const ExtensionContent = ({ renderExtension, extension, ...moreProps }) => {
  const dispatch = useDispatch()

  // extAsBneAction receives a fn as first arg, "rest" args are used to call the frist fn
  const extAsBneAction = useCallback((actionFunction, ...args) => () => {
    const bneAction = toBNEAction(
      () => () => {
        return actionFunction(...args)
      },
      `Extension ${extension.label}`
    )
    return dispatch(bneAction())
  }, [extension])

  return renderExtension(extension, { ...moreProps, extAsBneAction })
}

export default ExtensionPoint