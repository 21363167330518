import { append, insert, lensPath, over } from 'ramda'
import { ref } from 'beanie-engine-api-js'
import { EMPTY_ARRAY } from 'utils/object'
import { OneToManyOperations } from '../../Operations'

/**
 * Default impls when there is no custom "schema based" mutations this are used.
 * They are "data-drive" implementations which mutates the LUA objects through
 * `bne.obj.update(id, delta)` (more FP) instead of calling LUA methods (more OOP).
 * This is not an OOP vs FP holly war: basically any clever UI needs a declarative
 * separation of concern between objects invariants like preconditions or parameter's restrictions
 * from the imperative code that does the mutation. Meaning the following code is not UI friendly
 *
 * set_idle(node) {
 *   assert(node.get_sys() === "clip", "Idle nodes can only be markers")
 *   this.idle = node
 * }
 *
 * Since a good UX needs to avoid making errors, therefore it needs to run rules BEFORE actually
 * setting things. So checks/validations/invariants must be expressed independently from the actual code.
 * Therefore it is a cross-cutting concern.
 * The "data-driven" approach doesn't have this issue since there is no setters. But that's not enough.
 * It just works-around it for simple cases.
 * The best solution would be to follow a declarative OOP solution (since the engine is mostly OOP)
 * with strong conventions and separation of concerns, like "Naked Objects" did/does.
 */
const OneToManyDefaultMutations = {

  [OneToManyOperations.INSERT]: (api, object, path, index, id) => {
    updateArray(api, object, path, insert(index, ref(id)))
  },

  [OneToManyOperations.APPEND]: (api, object, path, id) => {
    updateArray(api, object, path, append(ref(id)))
  }

}

export default OneToManyDefaultMutations

/**
 * Util function to reuse between all operations since they update
 * the object's array with some transformation.
 *
 * (context, relationship, transformation: Array => Array)
 * */
export const updateArray = (api, object, path, fn) => {
  api.update(object.id, over(
    lensPath(path),
    maybeArray => fn(maybeArray || EMPTY_ARRAY),
    object,
  ).data)
}