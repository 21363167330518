import React from 'react'

import { isEmpty, isNil, tryCatch } from 'ramda'
import { Select, notification } from 'antd'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { changeDebugScenario, enableDisablePins } from 'actions/project/debuggingData'
import IconButton from '../Commons/IconButton'
import { ModalConsumer } from '../Commons/Modal'
import Button from 'components/Commons/Button'
import FileChooser from 'components/Commons/FileChooser'
import { exportPins, importPins } from 'utils/pins/importExport'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'
import { isJsonParseError } from 'utils/exceptions'
import { currentProject, branchVersion } from 'selectors/project'
import { currentDebugScenarios, currentDebugScenarioSelected } from 'selectors/debuggingData'
import DeleteScenarioModal from './DeleteScenarioModal'
import CreateScenarioModal from './CreateScenarioModal'
import DeleteDebuggingPinsModal from './DeleteDebuggingPinsModal'

import styles from './PinsPanel.scss'

const { Option } = Select

const notifyError = e => {
  const detail = isJsonParseError(e) ? 'The selected file is not in correct JSON format.' : ''
  notification.error({
    message: 'Error Importing Pin File',
    description: `Could not import pins from file. ${detail}`
  })
}

const PinsActions = ({ selectedPins, setSelectedPins }) => {
  const editable = useSecureCheck(isCurrentRevisionWritable)
  const scenarios = useSelector(currentDebugScenarios)
  const currentScenarioSelected = useSelector(currentDebugScenarioSelected)
  const project = useSelector(currentProject)
  const version = useSelector(branchVersion)
  const cantEditPins = !editable || isEmpty(selectedPins)

  const dispatch = useDispatch()

  return (
    <div className={styles.ActionsSections}>
      <div className={styles.ScenariosActions}>
        <span className={styles.Title} >Select scenario</span>
        <Select
          disabled={isEmpty(scenarios)}
          onSelect={id => dispatch(changeDebugScenario(id))}
          value={currentScenarioSelected?._id}
        >
          {scenarios.map(({ name, _id }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </div>
      <div className={styles.CurrentActions}>
        <span className={styles.Title} >Scenario actions</span>
        <div className={styles.Actions}>
          <ModalConsumer>
            {({ showModal }) =>
              (
                <IconButton
                  className={styles.Action}
                  type="plus"
                  tooltip="Add scenario"
                  onClick={() => showModal(CreateScenarioModal, {})}
                />
              )
            }
          </ModalConsumer>
          <ModalConsumer>
            {({ showModal }) =>
              (
                <IconButton
                  disabled={isNil(currentScenarioSelected)}
                  className={styles.Action}
                  type="minus"
                  tooltip="Delete scenario"
                  onClick={() => showModal(DeleteScenarioModal, { scenario: currentScenarioSelected })}
                />
              )
            }
          </ModalConsumer>
        </div>
      </div>
      <div className={styles.CurrentActions}>
        <span className={styles.Title} >Pins actions</span>
        <div className={styles.Actions}>
          <ModalConsumer>
            {({ showModal }) =>
              (
                <IconButton
                  disabled={cantEditPins}
                  className={styles.Action}
                  type="delete"
                  tooltip="Delete debugging pins"
                  onClick={() => showModal(DeleteDebuggingPinsModal, { pins: selectedPins, setSelectedPins })}
                />
              )
            }
          </ModalConsumer>
          <IconButton
            disabled={cantEditPins}
            className={styles.Action}
            type="eye"
            tooltip="Enable debugging pins"
            onClick={() => dispatch(enableDisablePins(selectedPins, true))}
          />
          <IconButton
            disabled={cantEditPins}
            className={styles.Action}
            type="eye-invisible"
            tooltip="Disable debugging pins"
            onClick={() => dispatch(enableDisablePins(selectedPins, false))}
          />
        </div>
        <div className={classNames(styles.Actions, styles.Actions2)}>
          <IconButton
            disabled={cantEditPins}
            key="download"
            type="download"
            tooltip="Export selected pins"
            onClick={() => exportPins(project, version, selectedPins)}
          />
          <ModalConsumer key="state-control-modal">
            { ({ showModal }) => (
              <FileChooser key="upload" onFileSelected={tryCatch(importPins(showModal), notifyError)}>
                {onClick => <Button disabled={!editable} key="icon" type="upload" description="Import pins" onClick={onClick} /> }
              </FileChooser>
            )}
          </ModalConsumer>
        </div>
      </div>
    </div>
  )
}

export default PinsActions