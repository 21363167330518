import { cursors } from '../../selectors/project'
import { focusViewInNode } from '../nodes'

import revisionSessionSendResponseMutation from 'api/mutations/revisionSessionSendResponse.graphql'

export const ON_SESSION_CONNECTING = 'ON_SESSION_CONNECTING'
export const onSessionConnecting = () => ({ type: ON_SESSION_CONNECTING })

export const ON_SESSION_CONNECTED = 'ON_SESSION_CONNECTED'
export const onSessionConnected = session => ({ type: ON_SESSION_CONNECTED, session })

export const ON_SESSION_DESTROYED = 'ON_SESSION_DESTROYED'
export const onSessionDestroyed = () => ({ type: ON_SESSION_DESTROYED })

export const ON_SESSION_RECONNECTING = 'ON_SESSION_RECONNECTING'
export const onSessionReconnecting = () => ({ type: ON_SESSION_RECONNECTING })

export const ON_SESSION_RECONNECTED = 'ON_SESSION_RECONNECTED'
export const onSessionReconnected = () => ({ type: ON_SESSION_RECONNECTED })

export const showSessionCursor = session => (dispatch, getState) => {
  const all = cursors(getState())
  const cursorOnId = all[session._id]
  if (cursorOnId) {
    dispatch(focusViewInNode(cursorOnId))
  }
}

/**
 * Sends back a response to a request that we received for a command.
 */
export const sendCommandResponse = (request, response) => async (dispatch, getState, { getApolloClient }) => {
  const client = getApolloClient()
  // const { data: { revisionSessionSendResponse: r } } = await client.mutate({
  await client.mutate({
    mutation: revisionSessionSendResponseMutation,
    variables: {
      input: {
        requestId: request._id,
        status: 'OK',
        value: response,
      }
    },
    fetchPolicy: 'no-cache'
  })
}
