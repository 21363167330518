import React from 'react'
import { Progress } from 'antd'
import classnames from 'classnames'
import { compose, lifecycle, withState } from 'recompose'

import styles from './TimerProgress.scss'

const TimerProgress = ({ className, paused, percentage, warnAt = 20, alertAt = 5 }) => (
  <Progress
    className={classnames(
      styles.progress,
      className,
      {
        [styles.paused]: paused,
        [styles.warningAboutToExpire]: percentage <= warnAt,
        [styles.alertingAboutToExpire]: percentage <= alertAt,
      }
    )}
    status={paused ? 'normal' : 'active'}
    percent={percentage}
    showInfo={false}
    strokeWidth={8}
  />
)

// actions

// TODO: migrate to hooks
export default compose(
  withState('deltaPercentagePerRefresh', null, ({ seconds, step = 150 }) => (step / (seconds * 1000)) * 100),
  withState('percentage', 'setPercentage', 100),
  lifecycle({
    percentage: 100,

    componentDidMount() {
      if (!this.props.paused) {
        this.resume()
      }
    },
    componentDidUpdate(prevProps) {
      if (!prevProps.paused && this.props.paused) {
        this.pause()
      } else if (prevProps.paused && !this.props.paused) {
        this.resume()
      }
    },
    componentWillUnmount() {
      this.pause()
    },
    pause() {
      clearInterval(this.timer)
      this.timer = undefined
    },
    resume() {
      const { step = 150 } = this.props
      if (this.timer === undefined) {
        this.timer = setInterval(::this.doStep, step)
      }
    },
    doStep() {
      // cut
      const { onExpired, percentage, setPercentage, deltaPercentagePerRefresh } = this.props
      if (percentage <= 0) {
        this.pause()
        if (onExpired) {
          onExpired()
        }
        return
      }
      // update
      setPercentage(percentage - deltaPercentagePerRefresh)
    }
  })

)(TimerProgress)