import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { copySomethingToClipboard } from '../../actions/clipboard'

/**
 * Returns an action to copy content into the clipboard
 */
const useCopyToClipboard = () => {
  const dispatch = useDispatch()

  return useCallback((text, message) => {
    dispatch(copySomethingToClipboard(text, message))
  }, [dispatch])
}

export default useCopyToClipboard