import { always, equals, head, isEmpty, reject, take } from 'ramda'

import { prependLaneToPath, createExpandPathTo } from 'selectors/paths'
import { lanes, selectedObject } from 'selectors/objects'
import { isSelectedCollapsed } from 'selectors/view'
import { projectId, revisionId as revisionIdSelector } from 'selectors/project'
import { PreferenceScope } from 'preferences/Preferences'

// collapsing

export const TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE'

export const toggleCollapse = (revisionId, element) => ({
  type: TOGGLE_COLLAPSE,
  revisionId,
  element
})

const toggleCollapseSelected = () => (dispatch, getState) => {
  const state = getState()
  const selectedNode = selectedObject(state)
  if (selectedNode) {
    const revisionId = revisionIdSelector(state)
    return dispatch(toggleCollapse(revisionId, selectedNode.id))
  }
}

const _toggleCollapseSelectedWhen = whenCollapsed => () => (dispatch, getState) => {
  const state = getState()
  const collapsed = isSelectedCollapsed(state)
  if (!!collapsed === whenCollapsed) {
    dispatch(toggleCollapseSelected())
  }
}
export const collapseSelected = _toggleCollapseSelectedWhen(false)
export const expandSelected = _toggleCollapseSelectedWhen(true)

const lanesToCollapse = (lane, lanesNames) => reject(equals(lane), lanesNames)
export const collapseOtherLanes = thisLane => (dispatch, getState) => {
  const state = getState()
  const lanesNames = lanes(state)
  const revisionId = revisionIdSelector(state)
  return dispatch(collapse(
    revisionId,
    ...lanesToCollapse(thisLane, lanesNames)
  ))
}

export const COLLAPSE = 'COLLAPSE'
export const collapse = (revisionId, ...elements) => ({
  type: COLLAPSE,
  revisionId,
  elements
})

export const EXPAND = 'EXPAND'
export const expand = (revisionId, ...elements) => ({
  type: EXPAND,
  revisionId,
  elements
})

export const ELEMENT_RENAMED = 'ELEMENT_RENAMED'
export const elementRenamed = (revisionId, previousName, newName) => ({
  type: ELEMENT_RENAMED,
  revisionId,
  previousName,
  newName
})

const expandPathWithLane = (expandPath, nodeId, state) => {
  if (!isEmpty(expandPath)) return prependLaneToPath(expandPath)(state)
  return take(1, prependLaneToPath([nodeId])(state))
}

// REFACTORME: this action evolved into a monster it has complex coupled logic
// First we shouldn't be prepending the lane to the path as a second step
// cause that sometimes is impossible because the original path gets filtered content
// We need a better path model that creates the full path with all stuff there
// and specially with the lane. Then each particular action or function could filter
// whatever things it need. But the base path logic show compute the full path
export const expandToViewNode = nodeId => (dispatch, getState) => {
  const state = getState()
  const lanesNames = lanes(state)
  const revisionId = revisionIdSelector(state)
  const expandPath = createExpandPathTo(nodeId)(state)
  const pathWithLane = expandPathWithLane(expandPath, nodeId, state)
  const pathHasLane = lanesNames.includes(head(pathWithLane))

  if (pathHasLane) return dispatch(expand(revisionId, ...pathWithLane))
}

//  split panes

export const RESET_EDIT_LAYOUT = 'RESET_EDIT_LAYOUT'
export const resetEditLayout = always(({ type: RESET_EDIT_LAYOUT }))

export const RESIZE_PANE = 'RESIZE_PANE'
export const resizePane = (splitPanelName, size) => ({
  type: RESIZE_PANE,
  splitPanelName,
  size
})

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB'
export const setCurrentTab = (splitPanelName, tab) => ({
  type: SET_CURRENT_TAB,
  splitPanelName,
  tab
})
export const MINIMIZE_SECONDARY_PANE = 'MINIMIZE_SECONDARY_PANE'
export const minimizeSecondaryPane = (splitPanelName, minimize) => ({
  type: MINIMIZE_SECONDARY_PANE,
  splitPanelName,
  minimize
})

// sound

export const SET_VOLUME = 'SET_VOLUME'

export const setVolume = value => ({
  type: SET_VOLUME,
  value
})


//
// Preferences: very first attempt to model view preferences
//

export const TOGGLE_PREFERENCE = 'TOGGLE_PREFERENCE'
export const CYCLE_PREFERENCE = 'CYCLE_PREFERENCE'
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE'
export const togglePreference = pref => (dispatch, getState) => dispatch(createPreferenceAction(TOGGLE_PREFERENCE, pref, getState))
export const cyclePreference = pref => ({ type: CYCLE_PREFERENCE, preference: pref })

export const updatePreference = (pref, value) => (dispatch, getState) => dispatch(createUpdatePreferenceAction(pref, value, getState))

export const createPreferenceAction = (type, pref, getState) => ({
  type,
  preference: pref,
  ...(pref?.scope === PreferenceScope.Project) && {
    project: projectId(getState())
  }
})
export const createUpdatePreferenceAction = (pref, value, getState) => ({
  ...createPreferenceAction(UPDATE_PREFERENCE, pref, getState),
  value,
})
