import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'

import QueryMutationLink from 'graphql/links/queries-and-mutations/QueryMutationLink'
import SubscriptionsLink from 'graphql/links/subscriptions/WebSocketLink'

// the root/main link
const createLink = context => split(isSubscription, SubscriptionsLink(context), QueryMutationLink(context))

const isSubscription = ({ query }) => {
  const { kind, operation } = getMainDefinition(query)
  return kind === 'OperationDefinition' && operation === 'subscription'
}

export default createLink