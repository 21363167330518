import React, { useMemo } from 'react'
import { model } from 'beanie-engine-api-js'
import CompositeNode from '../CompositeNode'

const { types: { choices: { getChoiceType } } } = model

const Choices = props => {
  const nodeType = useMemo(() => getChoiceType(props.node), [props.node])
  return (
    <CompositeNode nodeType={nodeType} {...props} />
  )
}

export default Choices