import { applySpec, objOf } from 'ramda'

import { pipeThunk } from 'utils/redux'
import { dropLeadingSlash as dls } from 'utils/string'
import { asyncMethod } from 'actions/utils'
import { token as tokenSelector } from 'selectors/auth'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { resourceUrl } from 'model/resources'

export const ResourceType = {
  audio: 'audio',
  storyboard: 'storyboard',
  image: 'image',
  extensions: 'engine/extensions'
}

const resourceReq = method => urlFactory => asyncMethod(method, {
  path: ({ revisionId, token }) => urlFactory(token, revisionId)
})

const postRequest = resourceReq('POST')
const putRequest = resourceReq('PUT')
export const uploadResource = method => type => pipeThunk(file => [
  applySpec({ revisionId: revisionIdSelector, token: tokenSelector }),
  urlParams => method(resourceUrl(type))({
    urlParams,
    body: { file: { name: 'file', file } }
  })
])

export const putResource = uploadResource(putRequest)
export const postResource = uploadResource(postRequest)

const justPathPart = url => {
  try {
    return dls(new URL(url).pathname)
  } catch (e) {
    return url
  }
}
export const uploadProjectImage = (path, file) =>
  postRequest(() => justPathPart(path))({ urlParams: {}, body: { file: { name: 'file', file } } })

const deleteRequest = resourceReq('DELETE')
export const deleteResource = type => pipeThunk(fileName => [
  applySpec({ revisionId: revisionIdSelector, token: tokenSelector }),
  objOf('urlParams'),
  deleteRequest(resourceUrl(type, fileName))
])
