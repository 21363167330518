import { model } from 'beanie-engine-api-js'

import { Key } from 'utils/keyboard'
import { isEmptyOrNull } from 'utils/string'
import { NODE_TYPES_ALLOWED_TO_MARKUP } from '../../markupUtils'
import { addParameter, openNote, closeNote, TAB_INTERACTION_BY_NODE_TYPE, isInMarkup, SHIFT_TAB_INTERACTION_BY_NODE_TYPE } from './mutations'
import { isAtEndOfMarkUpNameOrParam, isAtTheEndOfParameterKey } from './queries'

const { types: { markup: { DELIM_END, DELIM_START, MARKUP_FIELD_SEPARATOR } } } = model

export default () => ({

  // Interaction Input handlers
  onKeyDown (event, editor, next) {
    const currentNode = editor.currentNode()

    // SPACE
    if (event.keyCode === Key.SPACE && shouldAppendNewParameter(currentNode, editor)) {
      return addParameter(event, editor)
    }

    // Special move interactions
    if ((isAtEndOfMarkUpNameOrParam(currentNode, editor) && event.key === DELIM_END) ||
      (isAtTheEndOfParameterKey(currentNode, editor) && event.key === MARKUP_FIELD_SEPARATOR)) {
      event.preventDefault()
      event.stopPropagation()
      editor.moveCursorToRight()
      return false
    }

    if (event.keyCode === Key.TAB && isInMarkup(editor, currentNode)) {
      event.preventDefault()
      event.stopPropagation()
      let node = currentNode
      if (editor.selectionIsExpanded()) {
        editor.collapse()
        node = editor.currentNode()
      }

      const markup = editor.currentMarkUp()

      const func = !event.shiftKey ?
        (TAB_INTERACTION_BY_NODE_TYPE[node.type] || TAB_INTERACTION_BY_NODE_TYPE.DEFAULT) :
        (SHIFT_TAB_INTERACTION_BY_NODE_TYPE[node.type] || SHIFT_TAB_INTERACTION_BY_NODE_TYPE.DEFAULT)

      return func(editor, node, markup, next)
    }


    // Markup creation
    if (NODE_TYPES_ALLOWED_TO_MARKUP.includes(currentNode.type)) {
      const { value: { selection: { focus: { offset } } } } = editor
      const currentText = editor.currentTextNode()

      if (event.key === DELIM_END) {
        return closeNote(event, editor, currentText, offset)
      } else if (event.key === DELIM_START) {
        return openNote(event, editor, currentText, offset)
      }
    }
    return next()
  },
})

const shouldAppendNewParameter = (currentNode, editor) =>
  isAtEndOfMarkUpNameOrParam(currentNode, editor) && !isEmptyOrNull(currentNode.getText())
