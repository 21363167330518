import React from 'react'

import IconButton from 'components/Commons/IconButton'

const ViewControls = ({ styles, controls: Controls, disposition, minimize, toggleFullScreen, fullScreen }) => (
  <div className={styles.controls}>
    {Controls && <Controls fullScreen={fullScreen} />}
    {Controls && <span className={styles.controlsSeparator} />}

    {toggleFullScreen &&
    <IconButton tooltip="Maximize" type="desktop" className={styles.maximizeButton} onClick={toggleFullScreen} />
    }
    {minimize &&
    <IconButton key="minimize" tooltip="Minimize" type={`${disposition === 'horizontal' ? 'down' : 'right'}`} className={styles.minimizeButton} onClick={minimize} />
    }
  </div>
)


export default ViewControls