import React from 'react'
import * as jsondiffpatch from 'jsondiffpatch'

import FieldSkeleton from '../FieldSkeleton'
import { compose, withPropsOnChange } from 'recompose'

const lanesDiff = jsondiffpatch.create({
  objectHash: obj => obj.name
})

const _LanesFieldChange = ({ field, delta }) => {
  return (
    <FieldSkeleton field={field}>
      <pre>{JSON.stringify(delta, null, 2)}</pre>
    </FieldSkeleton>
  )
}

const LanesFieldChange = compose(
  withPropsOnChange(
    ['prev', 'next'],
    ({ prev, next }) => ({
      delta: lanesDiff.diff(prev, next)
    })
  )
)(_LanesFieldChange)

export default LanesFieldChange