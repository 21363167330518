
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"RevisionSessionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"RevisionSession"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"revision"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"state"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"user"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"ip"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"userAgent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"clientType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":163}};
    doc.loc.source = {"body":"\nfragment RevisionSessionFragment on RevisionSession {\n    _id\n    revision { _id }\n    state\n    user { _id }\n    ip\n    userAgent\n    clientType\n\n    createdAt\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
