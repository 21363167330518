import { isRef, parseRef } from 'beanie-engine-api-js'
import { isNil, path } from 'ramda'
import { objectsIndex } from 'selectors/apollo'

/**
 * Creates the context to be passed to the evaluation of a Query
 * This context provides utility methods to access the state to resolve objects
 * for example.
 */
const createQueryContext = getState => {
  const index = objectsIndex(getState())
  const resolve = ref => index[parseRef(ref)]
  return ({

    resolvePath: (aPath, object) => {
      const value = path(aPath, object)
      if (isNil(value)) return undefined

      // either it must be a ref string or an array of refs !
      return isRef(value) ? resolve(value) : value.map(resolve)
    }

  })
}

export default createQueryContext