// play, pause, resume, stop
import React from 'react'
import { compose } from 'recompose'

import PlayPauseResumeButton from './PlayPauseResumeButton'
import StopButton from './StopButton'
import VolumeControl from 'components/VolumeControl/VolumeControlContainer'
import StatePresetButtons from './PresetControls/StatePresetButtons'
import RewindButton from './RewindButton'
import StateToolBar from 'components/StateToolBar/StateToolBar'

import styles from './PlaybackControls.scss'

const PlaybackControls = () => (
  <div className={styles.PlaybackControls}>
    <RewindButton />
    <PlayPauseResumeButton />
    <StopButton />
    <VolumeControl asIcon className={styles.volumeControl} />
    <StatePresetButtons />
    <StateToolBar />
  </div>
)

export default compose()(PlaybackControls)