import React from 'react'
import { pipe, prop } from 'ramda'
import ObjectRefValue from 'components/PropertiesEditor/Value/ObjectRefValue'
import { isNode } from 'beanie-engine-api-js'

const isNodeLabelFilter = pipe(prop('sys'), isNode)

const jumpTargetFilter = jump => o => isNodeLabelFilter(o) && jump.id !== o.id

const JumpEditor = ({ text, onApply, onCancel, node }) => (
  <ObjectRefValue
    value={text}
    filter={jumpTargetFilter(node)}
    isEditable
    isEditing
    onValueChanged={onApply}
    onCancel={onCancel}
    onBlur={onCancel}
  />
)

export default JumpEditor