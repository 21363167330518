import React from 'react'
import { Modal, Form, Alert } from 'antd'
import { isFunction } from 'utils/object'
import { parseGraphQLErrors } from 'utils/graphql'

/**
 * A button to open a modal with a form.
 *
 * It is a pain in the neck to do this with antd over and over
 * so this is to reuse.
 * You use it by providing two children components (order matters)
 * 1) a componente that expects a property called onClick. It will be set in a way
 * so that the modal is open upon clicking
 * 2) a componente that renders the content of the modal. 2 props will be injected:
 *  `errors` (array of errors while processing the "ok")
 *  `getFieldDecorator`: to create fields in the form
 *
 * Also you can provide 3 properties to the main component to handle events
 * - onOk:
 * - onCancel:
 * - onClick: when the button is clicked you can intercept the event to decide whether to open
 * the modal or not. Signature (showModal) => {}  Where showModal is a function to open the modal.
 *
 */
const GenericForm = ({ title, visible, onCancel, onOk, processing, form, errors, children, wrapClassName, modalProps }) => {
  const { getFieldDecorator } = form

  const resetFieldsOnCancel = () => {
    form.resetFields()
    onCancel()
  }

  const content = React.cloneElement(React.Children.toArray(children)[0], {
    errors,
    form,
    getFieldDecorator
  })

  return visible ? (
    <Modal
      title={title}
      visible={visible}
      processing={processing}
      onOk={onOk}
      onCancel={resetFieldsOnCancel}
      wrapClassName={wrapClassName}
      {...modalProps}
    >
      {content}
      {errors.length > 0 && (
        <div>
          {errors.map(e => (
            <Alert key={e} message={e.toString()} type="error" />
          ))}
        </div>
      )}
    </Modal>
  ) : null
}

export default class ModalButton extends React.Component {
  state = {
    visible: false,
    processing: false,
    errors: []
  }
  mounted = true

  componentDidMount = () => {
    this.WrappedGenericForm = Form.create()(GenericForm)
  }

  componentWillUnmount = () => {
    this.mounted = false
  }

  onOk = () => {
    if (this.mounted) {
      this.setState({ processing: true, errors: [] }, this.process)
    }
  }

  process = () => {
    const { form } = this
    const { onOk } = this.props

    form.validateFields(async (err, values) => {
      if (err) { return }

      try {
        await onOk(values)

        form.resetFields()
        this.setState({ visible: false, processing: false })
      } catch (e) {
        this.setState({ errors: parseGraphQLErrors(e), processing: false })
      }
    })
  }

  onCancel = async () => {
    const { onCancel = () => {} } = this.props
    await onCancel()
    this.setState({
      visible: false,
      processing: false
    })
  }

  showModal = () => { this.setState({ visible: true }) }
  onClick = () => {
    const { onClick } = this.props
    return onClick ? onClick(this.showModal) : this.showModal()
  }

  render() {
    const { title, children, wrapClassName, modalProps } = this.props
    const { visible, processing, errors } = this.state
    const { WrappedGenericForm } = this

    const [first, second] = children

    const triggerElement = React.cloneElement(first, { onClick: this.onClick })

    return (
      <div>
        {triggerElement}
        {visible &&
          <WrappedGenericForm
            title={title}
            ref={form => { this.form = form }}
            visible={visible}
            processing={processing}
            onOk={this.onOk}
            onCancel={this.onCancel}
            errors={errors}
            wrapClassName={wrapClassName}
            modalProps={modalProps}
          >
            {isFunction(second) ? second() : second}
          </WrappedGenericForm>
        }
      </div>
    )
  }
}