import { pipeP } from 'ramda'
import _streamToBuffer from 'stream-to-buffer'
import { promisify } from 'util'
import { isBrowser } from 'actions/utils'

const streamToBuffer = promisify(_streamToBuffer)

const FILE_NAME_CONTENT_DISPOSITON_REGEX = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
export const extractFileNameFromContentDisposition = response => {
  const match = FILE_NAME_CONTENT_DISPOSITON_REGEX.exec(response.headers.get('content-disposition'))
  return match && match[1]
}

// TODO: this can be enhanced, DEMO-mode
export const extractFileNameFromPath = path => path.slice(path.lastIndexOf('/'))
  
// download file

/**
 * Process a response from an http request (isofetch for example) in order
 * to parse the file (only used to read the response int a File object in memory)
 * 
 * @param {*} url THe original URL, used to give a name to the file (this could be done in another way maybe)
 * @param {*} response the response object from 
 */
export const processFileResponse = url => pipeP(
  checkStatus(url),
  parseBlob,
  createFile(url)
)

const checkStatus = url => response => (response.ok && (response.status === 200 || response.status === 0) ?
  Promise.resolve(response)
  : Promise.reject(new Error(`Error loading: ${url}`))
)

const safeGetContentType = headers => headers.has('content-type') && headers.get('content-type')
const parseBlob = async response => ({ blob: await getBlob(response), type: safeGetContentType(response.headers) })
// nodejs (tests) doesn't have the same API as browser !
export const getBlob = response => (isBrowser() ? response.blob() : streamToBuffer(response.body))

const createFile = url => ({ blob, type }) => {
  const file = new File([blob], parseImageName(url), { type })
  file.preview = URL.createObjectURL(blob)
  file.dispose = () => {
    if (file.preview) {
      window.URL.revokeObjectURL(file.preview)
      file.preview = undefined
    }
  }
  return file
}

export const parseImageName = url => url.split('/').pop().split('#')[0].split('?')[0]