import { connect } from 'react-redux'
import makeResolveReference from 'selectors/objects/makeResolveReference'
import makeResolveChain from 'selectors/objects/makeResolveChain'

/**
 * Resolves an object relationship to another user leaving it available as the `to` property.
 * The source must be an object in the current props given by the name "from".
 */
const resolving = ({ from = 'node', path, chain, to }) => connect(() => {
  const selector = path ? makeResolveReference(from, path) : makeResolveChain(from, chain)
  return (state, props) => ({
    [to]: selector(state, props)
  })
})


export default resolving