// import { createSelector } from 'selectors/reselect'
import { createSelector } from 'reselect'
import { pathOr, and, not, concat } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { nodeId } from 'selectors/props'
import { EMPTY_ARRAY, isEmpty } from 'utils/object'

const syncOutboundOperations = pathOr(EMPTY_ARRAY, ['sync', 'outbound', 'operations'])
export const isSynchingOutbound = createSelector([syncOutboundOperations], isNotEmpty)
isSynchingOutbound.noDebug = true

const syncInboundOperations = pathOr(EMPTY_ARRAY, ['sync', 'inbound', 'operations'])
export const isSynchingInbound = createSelector([syncInboundOperations], isNotEmpty)
isSynchingInbound.noDebug = true

const syncChangeSetQueue = pathOr(EMPTY_ARRAY, ['sync', 'changeSetQueue'])
export const isEmptyChangeSetQueue = createSelector([syncChangeSetQueue], isEmpty)
export const inAndOutOperations = createSelector([syncInboundOperations, syncOutboundOperations], concat)

export const isSynching = createSelector(
  [isSynchingInbound, isSynchingOutbound],
  (synchingInbound, synchingOutbound) => synchingInbound || synchingOutbound
)
isSynching.noDebug = true

export const isSynchronized = createSelector([isSynching], not)

export const canSafelyApplyChangeSet = createSelector(
  [isSynchronized, isEmptyChangeSetQueue],
  and
)

// TODO: createIsNodeUnderSyncOperation
export const createIsNodeUnderSyncOutboundOperation = (propSelector = nodeId) => createSelector(
  [propSelector, inAndOutOperations],
  (id, operations) => !!id && operations.some(op => op.objects.includes(id))
)
createIsNodeUnderSyncOutboundOperation.noDebug = true

export const changeSetQueue = syncChangeSetQueue
