exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".GlobalSearchPopover__aEllj .walkLink__l4x2R{margin-right:1rem !important;font-size:.9rem}.GlobalSearchPopover__aEllj a.walkLink__l4x2R{color:#5068e8 !important}.GlobalSearchPopover__aEllj span.walkLink__l4x2R{color:#93928d !important}.GlobalSearchPopover__aEllj .searchBox__1sAal .ant-select-selection__rendered{background-color:#f3eeba}.GlobalSearchPopover__aEllj .searchBox__1sAal .ant-select-selection__rendered .ant-input{border:0}.GlobalSearchPopover__aEllj .searchBox__1sAal .ant-select-selection__rendered .ant-input:focus{border:0 !important;-webkit-box-shadow:none;box-shadow:none}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t>div{position:relative !important}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t>div .ant-select-dropdown{-webkit-box-shadow:none;box-shadow:none;position:relative !important;top:unset !important;left:unset !important;width:100% !important}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t .ant-select-dropdown-menu{background-color:#f3eeba}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t .ant-select-dropdown-menu .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled){background-color:#e8e08c}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t .ant-select-dropdown-menu .ant-select-dropdown-menu-item-active{background-color:#ece59c}.GlobalSearchPopover__aEllj .dropdownInlineContainer__2Pu9t .ant-select-dropdown-menu .ant-select-dropdown-menu-item:not(.ant-select-dropdown-menu-item-active){background-color:#f3eeba}", ""]);

// exports
exports.locals = {
	"GlobalSearchPopover": "GlobalSearchPopover__aEllj",
	"walkLink": "walkLink__l4x2R",
	"searchBox": "searchBox__1sAal",
	"dropdownInlineContainer": "dropdownInlineContainer__2Pu9t"
};