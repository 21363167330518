import revisionSessionSendRequest from './revisionSessionSendRequest'

/**
 * Higher-order function to create an action (redux/thunk) that is used to
 * send a request/command to another session.
 *
 * It accepts a `fn` with the form
 *
 * (targetSession: RevisionSession, getState: () => ReduxState) => async (...args) => RequestSpec
 *
 * Where RequestSpec is a partial RevisionSessionRequest object with the form
 * {
 *   command: String,
 *   params: JSON
 * }
 */
const requestSessionAction = fn => (targetSession, ...args) => async (dispatch, getState, context) => {
  const requestSpec = await fn(targetSession, getState)(...args)

  return revisionSessionSendRequest({
    to: targetSession._id,
    ...requestSpec
  })(dispatch, getState, context)
}

export default requestSessionAction