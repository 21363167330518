import React, { useMemo } from 'react'

import { isEmpty } from 'ramda'
import { Bool, Str, Num, Var, It, WildCard, Nothing } from './Value'
import { lang } from 'beanie-engine-api-js'
import { FactApp } from './Fact'
import { Assign } from './Effect'
import { ChooseNode, ExecutedNode, ExecutionCount, LastChosen, NodeRef } from './BneNode'
import { NotOp, AndOp, OrOp, GtOp, LtOp, GeOp, LeOp, EqOp, NeOp, PlusOp, MinusOp, MulOp, MaxOp, MinOp, DivOp, ModOp, OfOp } from './Operation'
import { CasesExpr } from './PatternMatching'

import styles from './Rule.scss'

const { rule: { parser, Type, error: { isError } } } = lang

/**
 *
 */
const Rule = ({ source = '', onHoveringToNavigate }) => {
  if (isEmpty(source)) return null

  const expr = useMemo(() => parser(source), [source])

  const Component = getComponentFor(expr)

  return (
    <span className={styles.ruleContainer}>
      {isError(expr) ?
        source :
        <Component
          node={expr}
          onHoveringToNavigate={onHoveringToNavigate}
          level={0}
        />
      }
    </span>
  )
}

export default Rule

const NODE_RENDER = {
  [Type.BOOLEAN]: Bool,
  [Type.STRING]: Str,
  [Type.NUMBER]: Num,
  [Type.VAR_REF]: Var,
  [Type.SPECIAL_VAR_REF]: Var,
  [Type.IT]: It,
  [Type.WILD_CARD]: WildCard,
  [Type.NOTHING]: Nothing,

  [Type.NODE_REF]: NodeRef,
  [Type.CHOOSE_NODE]: ChooseNode,
  [Type.EXECUTED_NODE]: ExecutedNode,
  [Type.EXECUTION_COUNT_NODE]: ExecutionCount,
  [Type.LAST_CHOSEN_NODE]: LastChosen,

  [Type.FACT_APP]: FactApp,

  [Type.CASES_EXPR]: CasesExpr,

  [Type.NOT_OP]: NotOp,
  [Type.AND_OP]: AndOp,
  [Type.OR_OP]: OrOp,

  [Type.GT_OP]: GtOp,
  [Type.LT_OP]: LtOp,
  [Type.GE_OP]: GeOp,
  [Type.LE_OP]: LeOp,
  [Type.EQ_OP]: EqOp,
  [Type.NE_OP]: NeOp,

  [Type.PLUS_OP]: PlusOp,
  [Type.MINUS_OP]: MinusOp,
  [Type.MUL_OP]: MulOp,
  [Type.MAX_OP]: MaxOp,
  [Type.MIN_OP]: MinOp,
  [Type.DIV_OP]: DivOp,
  [Type.MOD_OP]: ModOp,

  [Type.OF_OP]: OfOp,

  // Effects
  [Type.ASSIGNMENT]: Assign,
}

export const getComponentFor = ({ type }) => NODE_RENDER[type]
