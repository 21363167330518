exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SearchToolBar__UdAfQ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.SearchToolBar__UdAfQ .ant-select{width:22rem}.SearchToolBar__UdAfQ .ant-select .ant-select-selection{border-radius:4px}.SearchToolBar__UdAfQ .ant-select .ant-select-selection__rendered{margin-left:2rem}.SearchToolBar__UdAfQ .ant-select .ant-select-selection__clear{right:unset;left:.5rem}.SearchToolBar__UdAfQ .resultsCount__2ERSj{font-size:.8rem;min-width:8rem}.SearchToolBar__UdAfQ .walkingSwitch__n4mxz{min-width:8rem}.SearchToolBar__UdAfQ .walkingSwitch__n4mxz .ant-switch{margin-left:.6rem}.SearchToolBar__UdAfQ .walkingSwitch__n4mxz .ant-switch-checked{background-color:#c0d3ae}.SearchToolBar__UdAfQ .searchResultsControls__3mq5j{-ms-flex-positive:.7;flex-grow:.7;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:end;justify-content:flex-end;margin-right:.5rem}.SearchToolBar__UdAfQ .searchResultsControls__3mq5j .anticon{color:gray}", ""]);

// exports
exports.locals = {
	"SearchToolBar": "SearchToolBar__UdAfQ",
	"resultsCount": "resultsCount__2ERSj",
	"walkingSwitch": "walkingSwitch__n4mxz",
	"searchResultsControls": "searchResultsControls__3mq5j"
};