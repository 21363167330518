import React from 'react'
import { List } from 'antd'
import classNames from 'classnames'
import { equals } from 'ramda'

import SearchResultItem from './SearchResultItem/SearchResultItem'

import styles from './SearchResults.scss'

const SearchResults = ({ results, currentlyWalking }) => (
  <List
    className={styles.SearchResults}
    size="large"
    bordered
    dataSource={results}
    renderItem={result => (
      <List.Item
        className={classNames({ [styles.currentlyWalking]: equals(currentlyWalking, result) })}
      >
        <SearchResultItem item={result} />
      </List.Item>
    )}
  />
)

export default SearchResults