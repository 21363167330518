import { OBJECT_TYPES } from '../../Constants'
import { performanceNormalize } from '../normalizers/performanceNote'

export default {
  nodes: [
    {
      match: { object: OBJECT_TYPES.text, text: /^\[.*\]$/ }
    }
  ],
  normalize: performanceNormalize
}