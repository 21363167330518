import { equals } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { one, SPACE } from '../schema-utils'
import { NODE_TYPES, OBJECT_TYPES } from '../../Constants'
import { markupParameterNormalize } from '../normalizers/markUp'

const { types: { markup: { MARKUP_FIELD_SEPARATOR } } } = model

export default {
  nodes: [
    SPACE,
    {
      match: { type: NODE_TYPES.MARK_UP_PARAMETER_KEY },
      ...one
    },
    { match: { object: OBJECT_TYPES.text, text: equals(MARKUP_FIELD_SEPARATOR) } },
    {
      match: { type: NODE_TYPES.MARK_UP_PARAMETER_VALUE },
      ...one
    },
    SPACE,
  ],
  normalize: markupParameterNormalize
}