import React, { useContext } from 'react'

import { pipe } from 'ramda'
import { connect, useStore } from 'react-redux'

import { findLaneUnderMouse, findElementUnderMouse, isUnderDOMElementWithOwnContextMenu } from 'dom/dom'

import { objectsIndex } from 'selectors/apollo'

import { clearSelectedNodes } from 'actions/nodes'

import SecureContext from 'components/Commons/SecureContext'

import { ContextMenu as BlueprintContextMenu } from '@blueprintjs/core'
import { FactDrawerContext } from '../FactDrawer/FactDrawerContext'
import LaneContextMenu from './LaneContextMenu/LaneContextMenu'
import TreeViewContextMenu from './TreeViewContextMenu/TreeViewContextMenu'
import SelectionContextMenu from './SelectionContextMenu/SelectionContextMenu'

import './ContextMenu.css'
import './ContextMenu.scss'

/**
 *
 */
class ContextMenuWithStore extends React.Component {

  shouldComponentUpdate = nextProps => (
    this.props.children !== nextProps.children
  )

  render = () => (
    <div ref={div => { this.domElement = div }} onContextMenu={this.onContextMenu}>
      {this.props.children}
    </div>
  )

  onContextMenu = event => {
    // check to bypass this contextmenu if right-click was over an object
    // that has its own contextmenu (currently just lane titles)
    if (isUnderDOMElementWithOwnContextMenu(event)) {
      return
    }

    const { store } = this.props
    const index = objectsIndex(store.getState())

    event.preventDefault()
    const content = pipe(
      findElementUnderMouse(index),
      this.renderMenuRecreatingContexts(event)
    )(event)
    BlueprintContextMenu.show(content, { left: event.clientX, top: event.clientY }, this.onContextMenuClose)
  }

  onContextMenuClose = () => {
    const { onClose } = this.props
    return onClose && onClose()
  }

  /**
   * Blueprint context menus seems to be mounted outside of our react tree.
   * So we need to manually create again providers for every context we plan on using.
   * This sucks, but well.
   * We are manually consuming context in `ContextMenu` and passing those values as props here
   * where we place them again on the new tree.
   */
  renderMenuRecreatingContexts = event => element => (
    <SecureContext.Provider value={this.props.context}>
      <FactDrawerContext.Provider value={this.props.factContext}>
        {this.renderContextMenu(event)(element)}
      </FactDrawerContext.Provider>
    </SecureContext.Provider>
  )

  renderContextMenu = event => element => {
    const { clearSelectedNodesAction } = this.props
    if (element) {
      return this.renderMenuForSelection(element)
    } else {
      clearSelectedNodesAction()
      return this.renderMenuNoSelection(event)
    }
  }

  renderMenuForSelection = o => (
    <SelectionContextMenu o={o} store={this.props.store} />
  )

  renderMenuNoSelection = event => {
    const { store } = this.props
    const laneName = findLaneUnderMouse(event)
    return (
      laneName ?
        <LaneContextMenu lane={laneName} store={store} />
        : <TreeViewContextMenu store={store} />
    )
  }

}

const ContextMenu = props => {
  // retrieve values of contexts and pass them as props.
  // they will be used to recreate contexts in #renderMenuRecreatingContexts
  const store = useStore()
  const secureContext = useContext(SecureContext)
  const factContext = useContext(FactDrawerContext)

  return (
    <ContextMenuWithStore
      store={store}
      context={secureContext}
      factContext={factContext}

      {...props}
    />
  )
}

export default connect(null, {
  clearSelectedNodesAction: clearSelectedNodes
})(ContextMenu)
