import React from 'react'
import { compose } from 'recompose'
import classNames from 'classnames'

import { DEFAULT_LOCALE } from 'beanie-engine-api-js'

import { ResultType } from 'model/project/searches/searches'
import resolving from 'hocs/node/resolving'

import LineActor from 'components/PlaybackView/CurrentPlayback/nodes/clip/LineActor'
import MatchResultText from '../../../SearchObject/MatchResultText'

import styles from './LineSearchResultItem.scss'

const LineSearchResultItem = ({ item, actor, text, className }) => (
  <div className={classNames(styles.LineSearchResultItem, className)}>
    <LineActor actor={actor} className={styles.avatar} />
    <div className={styles.dialoguePart}>
      {item.type === ResultType.match ?
        <MatchResultText result={item} text={text} />
        : <div>{text}</div>
      }
    </div>
  </div>
)

export default compose(
  resolving({
    from: 'item.object',
    path: 'data.actor',
    to: 'actor'
  }),
  resolving({
    from: 'item.object',
    chain: [
      'data.selected_take',
      `data.locales.${DEFAULT_LOCALE}`,
      'data.text',
    ],
    to: 'text'
  })
)(LineSearchResultItem)