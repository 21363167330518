import { propEq, propOr } from 'ramda'
import { createSelector } from 'selectors/reselect'
import { markupDefinitions } from 'selectors/markups'
import { objectsIndex } from 'selectors/apollo'
import { propFrom } from 'utils/object'
import { isVisible } from '../../utils/markupUtils'

// TODO: test me

const markupNameSelector = (state, { editor, node }) => editor.markupNameText(node)

export const markupColor = createSelector(
  [markupDefinitions, markupNameSelector],
  (defs, name) => {
    if (!name) return undefined
    const def = defs.find(propEq('name', name))
    return def && def.color
  }
)

export const makeErrorsSelector = () => (state, { editor, node }) => {
  const index = objectsIndex(state)
  const markup = editor.model(node, propFrom(index))
  return propOr([], 'errors', markup)
}

export const isMarkupVisible = (state, { editor: { props: { visibleMarkups } }, node }) => isVisible(visibleMarkups, node)
