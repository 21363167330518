import { model } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'
import { apply, head, propEq, propOr } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { isEmptyObject } from 'utils/object'

import { objectsIndex } from 'selectors/apollo'
import { objectsBySys, projectObject, lanesFromProject } from 'selectors/objects'
import { DEFAULT_LANE } from 'model/constants'
import { currentChain } from 'selectors/paths'

const { types: { projectObject: { findLaneForMarker } } } = model

export const firstLane = createSelector('firstLane', [lanesFromProject], head)

export const currentLane = createSelector('currentLane',
  [currentChain, objectsIndex, objectsBySys, projectObject],
  (chain, index, bySys, project) =>
    (!isEmptyObject(index) && chain.length > 0 ? findLaneForMarker(chain[0], project) : undefined)
)

export const currentLaneName = createSelector('currentLaneName', [currentLane], lane => (lane ? lane.name : DEFAULT_LANE))

const indexOfCurrentLane = createSelector('indexOfCurrentLane',
  [currentLane, lanesFromProject],
  (lane, lanes) => (lane ? lanes.findIndex(propEq('name', lane.name)) : -1)
)
export const hasPreviousLane = createSelector('hasPreviousLane', [indexOfCurrentLane], i => i > 0)
export const hasNextLane = createSelector('hasNextLane', [indexOfCurrentLane, lanesFromProject], (i, lanes) => i >= 0 && i < lanes.length - 1)

export const selectedLaneRootIds = createSelector('selectedLaneRootIds', currentLane, propOr([], 'roots'))

// REVIEWME: this might break if "name" has some weird symbols
const LANE_COPY_EXTRACTOR_REGEX = name => new RegExp(`${name} copy ?(\\d*)?`)

export const laneCopyName = lane => state => {
  const re = LANE_COPY_EXTRACTOR_REGEX(lane)
  const matchingLanes = lanesFromProject(state).map(({ name }) => {
    const match = re.exec(name)
    return match && (parseInt(match[1] || '1'))
  }).filter(isNotNil)

  return `${lane} copy${matchingLanes.length > 0 ? (` ${apply(Math.max, matchingLanes) + 1}`) : ''}`
}