import React from 'react'
import { Slider } from 'antd'
import { formatWith2Decimals } from 'utils/numbers'

import styles from '../ActorDetail.scss'

const SpeechSlider = ({ name, value, ...rest }) => (
  <div className={styles.item}>
    <div>{name}</div>
    <Slider value={value} {...rest} tipFormatter={formatWith2Decimals} />
  </div>
)

export default SpeechSlider