
import { selectNode, clearSelectedNodes } from '../nodes'
import { simpleSelection } from 'selectors/selectors'
import makeComputeClosestNodeToSelectWhenDeleting from 'selectors/selection/nodes/closestNodeToSelectWhenDeleting'

const selectClosestNode = selectedNodes => (dispatch, getState) => {
  const toSelectId = makeComputeClosestNodeToSelectWhenDeleting(selectedNodes)(getState())
  return dispatch(
    toSelectId ? selectNode(simpleSelection(toSelectId), false) : clearSelectedNodes()
  )
}

export default selectClosestNode

