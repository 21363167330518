import { Tasks, updateTaskProgress } from 'actions/tasks'
import { isDebugOn } from 'selectors/ui'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../utils/object'

/**
 * Loads the Project Revision into the beanie engine.
 * Currently it just loads all the object by delegating into BNEEngine.loadProject()
 */
export const loadProjectIntoBeanie = async (revision, dispatch, getState, synchronizer) => {
  const engine = synchronizer.getEngine()

  // load debugging settings
  await engine.getAPI().debugging.set_settings(revision.debugSettings || EMPTY_OBJECT)
  await engine.getAPI().debugging.set_pins(revision.currentDebugScenarioPins || EMPTY_ARRAY)

  // load objects
  await engine.loadProject(
    Object.values(revision.objects),
    progress => dispatch(updateTaskProgress(Tasks.LOAD_PROJECT.type, progress)),
    isDebugOn(getState())
  )
}
export default loadProjectIntoBeanie