import { compose, lifecycle, withState } from 'recompose'
import moment from 'moment'

const resetState = self => {
  clearTimeout(self.timer)
  self.timer = undefined
  self.props.setDebounced({})
}
const TIMEOUT = 1500 // ms
const passedTheTime = (self, timeout = TIMEOUT) => self.props.debounced.ts && moment(self.props.debounced.ts).add(timeout, 'ms').isBefore(new Date())

// exported to test
export const debouncedPropComponentDidUpdate = (self, propName, timeout) => prevProps => {
  const { setDebounced, [propName]: propValue } = self.props
  if (propValue !== prevProps[propName]) {
    if (propValue) {
      clearTimeout(self.timer)
      self.timer = setTimeout(() => {
        resetState(self)
      }, TIMEOUT)
      // we save the date becuase moment.add operation has side effects so we want to keep our souls impoluted from mutation
      setDebounced({ value: propValue, ts: new Date() })
    } else if (passedTheTime(self, timeout)) {
      // is not synching anymore and this event arrived out of the bouncing window.
      resetState(self)
    }
  }

}

/**
 * TODO: currently this only works for boolean props (or needs a review to check if it works for any other type)
 *
 * This hocs guarantees that the new value of propName (propValue) will be TRUE for at least TIMEOUT ms.
 *
 * If propValue switches back to FALSE before TIMEOUT the deobunced copy will remain until TIMEOUT has passed.
 * If TIMEOUT has passed and propValue hasn't switched back yet, then the debounced copy will switch back whenever propValue does.
 *
 * The hoc was useful to help a component (which show/hides depending on a prop boolean value) to be shown for a minimum
 * amount of time, even when the prop change is really fast.
 *
 * TODO: this component needs more work to become really reusable. Right now is reusable only for Boolean values.
 *
 * @param {String} propName - the name of the prop from where to take the debounced value.
 */
const withDebouncedProp = propName => compose(
  withState('debounced', 'setDebounced', {}),
  lifecycle({
    componentDidUpdate(...args) {
      debouncedPropComponentDidUpdate(this, propName)(...args)
    }
  })
)
export default withDebouncedProp
