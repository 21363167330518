exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__1IMuo:not(.debug__32o3V) .player__3gan8:not(.active__3iA0X){display:none}.container__1IMuo.debug__32o3V{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:space-evenly;justify-content:space-evenly;height:90vh;padding:1rem}.container__1IMuo.debug__32o3V .player__3gan8{padding:1rem;background-color:#3a373e;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.container__1IMuo.debug__32o3V .player__3gan8>div:first-child{margin-bottom:.5rem}.container__1IMuo.debug__32o3V .player__3gan8.active__3iA0X>div:nth-child(2){font-weight:bold;color:violet}", ""]);

// exports
exports.locals = {
	"container": "container__1IMuo",
	"debug": "debug__32o3V",
	"player": "player__3gan8",
	"active": "active__3iA0X"
};