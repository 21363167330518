exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3iToH{color:#add8e6;cursor:pointer}.iconAsLink__3iToH:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2lSmI{font-style:italic}.formFieldsVerticalGutter__1Oc-_{padding:.5rem}.formFieldsVerticalGutter__1Oc-_>*{margin:.5rem !important}.tableBackground__2Vkxj .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2Vkxj .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__WKoJJ .ant-notification-notice-close{display:none}.item__1V_zQ{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;width:12.5rem}.item__1V_zQ .iconPlaceHolder__i7Ygg{width:1.6em;font-size:.8em;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.item__1V_zQ .text__XyjCJ{-ms-flex-positive:1;flex-grow:1}.item__1V_zQ .description__1j3ip{color:#d3d3d3;-ms-flex-item-align:center;align-self:center;font-size:.8em;padding-right:.4rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3iToH",
	"labelFromRef": "labelFromRef__2lSmI",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1Oc-_",
	"tableBackground": "tableBackground__2Vkxj",
	"notificationNotClosable": "notificationNotClosable__WKoJJ",
	"item": "item__1V_zQ",
	"iconPlaceHolder": "iconPlaceHolder__i7Ygg",
	"text": "text__XyjCJ",
	"description": "description__1j3ip"
};