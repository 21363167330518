import { propEq, mergeDeepLeft, always, forEach, addIndex } from 'ramda'
import { cloneNodes } from '../../slateMocks/clone'
import { Text } from 'slate'
import { List } from 'immutable'
import { findDescendentOfType } from '../../nodeUtils'
import { noop } from 'utils/functions'
import { NODE_TYPES, OBJECT_TYPES } from '../../Constants'
import { isVisible as isVisibleMarkup } from '../../markupUtils'
import { isVisible as isVisibleNote } from 'components/SlateTextEditor/Components/Note/selectors'

//
// higher-order functions to create queries/mutations
//

const parentPath = (path = List([])) => (path && !path.isEmpty() ? path.slice(0, -1) : path)

const _recurseFrom = direction => (editor, node, fn, continueWithParent = noop) => {
  let i = 0;
  const childrenSize = node.nodes ? node.nodes.size : 0
  const nodes = node.nodes && direction === 'RIGHT' ? node.nodes.reverse() : node.nodes

  const next = () => {
    if (i === childrenSize) {
      fn(node, continueWithParent)
    } else {
      _recurseFrom(direction)(editor, nodes.get(i++), fn, next)
    }
  }
  // do it
  next()
}

const getEdgeMostTextChild = recursingFunction => (editor, node) => {
  let text;
  editor[recursingFunction](node, (n, next) => {
    if (Text.isText(n) && !editor.isImmutableText(n)) { // isImmutableText couples this with selectionPlugin
      text = n
    } else {
      next()
    }
  })
  return text
}

export const IS_VISIBLE_BY_TYPE = {
  [NODE_TYPES.MARK_UP]: ({ props: { visibleMarkups } }, markup) => isVisibleMarkup(visibleMarkups, markup),
  [NODE_TYPES.PERFORMANCE_NOTE]: ({ props: { visibleNotes } }, note) => isVisibleNote(visibleNotes, note),
  [NODE_TYPES.PRODUCTION_NOTE]: ({ props: { visibleNotes } }, note) => isVisibleNote(visibleNotes, note),
}

//
// plugin
//

export default () => ({

  queries: {
    
    // basic nodes

    pathByKey: (editor, key) => { try { return editor.value.document.assertPath(key) } catch (e) { return undefined } },
    
    nodeByKey: (editor, key) => editor.blockByPath(editor.pathByKey(key)),

    nodeByType: (editor, type) => findDescendentOfType(type)(editor.value.document),
    
    currentNode: editor => editor.blockByPath(parentPath(editor.currentPath())),
    
    currentTextNode: editor => editor.blockByPath(editor.currentPath()),
    
    currentPath: editor => editor.value.selection.focus.path,

    // deprecated (use nodeByPath)
    blockByPath: (editor, path = List([])) => editor.value.document.getNode(path),

    nodeByPath: (editor, path = List([])) => editor.value.document.getNode(path),
    
    existsNode: (editor, key) => !!editor.pathByKey(key),

    // parents / ancestors

    parentPath: (editor, path = editor.currentPath()) => parentPath(path),

    parent(editor, node) {
      if (!node) return undefined
      const path = editor.pathByKey(node.key)
      const _parentPath = parentPath(path)
      return path === _parentPath ? undefined : editor.blockByPath(_parentPath)
    },

    parentOfType(editor, type, node) {
      const p = editor.parent(node)
      if (!p) return null
      return p.type === type ? p : editor.parentOfType(type, p)
    },

    parentOfAnyType(editor, types, node) {
      if (!node) return null
      return types.includes(node.type) ? node : 
        editor.parentOfAnyType(types, editor.parent(node))
    },

    ancestors(editor, node) {
      const all = []
      let n = node
      /* eslint no-cond-assign: 0 */
      while (n = editor.parent(n)) {
        all.unshift(n)
      }
      return all
    },
    nodesPath: (editor, node) => [...editor.ancestors(node), node],
    findAncestor(editor, node, predicate) { return node ? (predicate(node) ? node : editor.findAncestor(editor.parent(node), predicate)) : undefined },
    findAncestorOfType(editor, node, type) { return editor.findAncestor(node, propEq('type', type)) },

    // siblings

    leftNodesOf: (editor, parent, splited) => {
      const splitedIndex = parent.nodes.indexOf(splited)
      return cloneNodes(parent.nodes.slice(0, splitedIndex))
    },

    rightNodesOf: (editor, parent, splited) => {
      const splitedIndex = parent.nodes.indexOf(splited)
      return cloneNodes(parent.nodes.slice(splitedIndex + 1))
    },

    getTextOnFocus: (editor, delta = 0) => {
      const textNode = editor.currentTextNode()
      const newFocus = editor.currentFocus().offset + delta
      return newFocus <= textNode.text.length && newFocus >= 0 ? textNode : editor.getNextOrPreviousTextNode(textNode, delta)
    },

    getNextOrPreviousTextNode: (editor, node, delta) => {
      const parent = editor.parent(node)
      if (!parent) { return undefined }
      const i = editor.indexOnParent(node)

      const goingRight = delta > 0
      const siblings = goingRight ? parent.nodes.slice(i + 1) : parent.nodes.slice(0, i).reverse()

      const foundOnSiblings = siblings.reduce((found, sibling) => {
        if (found) return found
        if (Text.isText(sibling)) {
          // text
          return !editor.isImmutableText(sibling) ? sibling : null // couples with selectionPlugin
        } else {
          // node
          return goingRight ? editor.getRightMostTextChild(sibling) : editor.getLeftMostTextChild(sibling) 
        }
      }, null)

      return foundOnSiblings || editor.getNextOrPreviousTextNode(parent, delta)
    },

    // text recursion (filtering immutables)

    getLeftMostTextChild: getEdgeMostTextChild('recurseFromLeft'),
    getRightMostTextChild: getEdgeMostTextChild('recurseFromRight'),

    // general recursion

    recurseFromLeft: _recurseFrom('LEFT'),
    recurseFromRight: _recurseFrom('RIGHT'),

    indexOnParent: (editor, node) => {
      const parent = editor.parent(node)
      return parent.nodes.findIndex(propEq('key', node.key))
    },

    isVisible: (editor, node) => (IS_VISIBLE_BY_TYPE[node.type] || always(true))(editor, node)

  },

  commands: {
    // nodes
    
    removeNode: (editor, node) => {
      if (node) {
        REMOVE_NODE_BY_OBJECT_TYPE[node.object](editor, node)
      }
    },

    insertNode: (editor, node) => INSERT_NODE_BY_OBJECT_TYPE[node.object](editor, node),
    insertNodes: (editor, nodes) => forEach(editor.insertNode)(nodes),
    // childs

    createBlock: (editor, path, index = 0, block) => editor.insertNodeByPath(path, index, block),
    insertChildAt: (editor, parent, index, child) => editor.createBlock(editor.pathByKey(parent.key), index, child),
    insertChildsAt: (editor, parent, index, childs) => addIndex(forEach)((child, i) => editor.insertChildAt(parent, index + i, child))(childs),

    // replace

    replaceBlock(editor, path, block) { 
      editor.snapshotSelection()
      const currentNode = editor.nodeByPath(path)
      const withData = mergeDeepLeft(block, { data: currentNode.get('data').toJS() })
      editor.replaceNodeByPath(path, withData)
    },
    replaceBlockByKey(editor, key, block) {
      editor.snapshotSelection()
      editor.replaceNodeByKey(key, block)
    },
    // TESTME
    replaceNodeWith(editor, currentNode, newNode) {
      editor.snapshotSelection()
      // REVIEWME: keep / copy metadata ?
      editor.replaceNodeByKey(currentNode.key, newNode)
    }
  }

})

const INSERT_NODE_BY_OBJECT_TYPE = {
  [OBJECT_TYPES.text]: (editor, { text }) => editor.insertText(text),
  [OBJECT_TYPES.inline]: (editor, node) => {
    editor.insertInline(node)
    // THIS IS TO NOT ALLOW THAT THE NEXT TEXT WILL BE PART OF THIS INLINE
    editor.moveCursorToRight()
  },
  [OBJECT_TYPES.block]: (editor, node) => editor.insertBlock(node),
}

const REMOVE_NODE_BY_OBJECT_TYPE = {
  [OBJECT_TYPES.text]: (editor, { key, text }) => editor.removeTextByKey(key, 0, text.length),
  [OBJECT_TYPES.inline]: (editor, { key }) => editor.removeNodeByKey(key),
  [OBJECT_TYPES.block]: (editor, { key }) => editor.removeNodeByKey(key),
}