import { model, DEFAULT_LOCALE, Sys } from 'beanie-engine-api-js'
import { pathOr, propEq } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

const { types: { object: { Paths } } } = model

/**
 * Finds a line that has at least one markup usage for a specific markup name
 */
const lineHasMarkupResolver = ({ name: markupName }) => (line, clip, { resolvePath }) => {
  if (line.sys !== Sys.line) return false

  const take = resolvePath(Paths.line.selected_take, line)
  if (!take) { return false }
  const langRes = resolvePath([...Paths.take.locales, DEFAULT_LOCALE], take)
  if (!langRes) { return false }

  return pathOr(EMPTY_ARRAY, Paths.language_resource.markups, langRes)
    .some(propEq('name', markupName))
}

export default lineHasMarkupResolver