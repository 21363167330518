import { ref } from 'beanie-engine-api-js'

import { lines } from 'selectors/objects'

export const _moveLines = (sourceActorId, targetActorId, allLines) => api => {
  const sourceRef = ref(sourceActorId)
  const targetRef = ref(targetActorId)
  // TODO: we should use a new api.updateMany(ids, { actor: targetRef }) to avoid "RPC" overhead
  allLines.forEach(line => {
    if (line.data.actor === sourceRef) {
      api.update(line.id, { actor: targetRef })
    }
  })
}

const moveLines = (sourceActorId, targetActorId) => (dispatch, getState, { synchronizer }) =>
  synchronizer.doSynchingBNE('Moving lines from actor',
    api => {
      const allLines = lines(getState())
      return _moveLines(sourceActorId, targetActorId, allLines)(api)
    }, sourceActorId, targetActorId)

export default moveLines