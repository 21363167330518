import React from 'react'

import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { noop } from 'utils/functions'
import { EMPTY_OBJECT } from 'utils/object'

import CreateModal from 'components/Commons/CreateModal'
import deleteProjectMutation from 'api/mutations/deleteProject.graphql'


const DeleteProjectModal = ({ onDelete, hide }) => 
  (<CreateModal title="Are you sure that you want to delete this project ?" hide={hide} submit={onDelete} canSubmit>
    {() => (<p>If you delete this project, you will lose all the content</p>)}
  </CreateModal>)

export default compose(
  graphql(deleteProjectMutation, { name: 'deleteProject' }),
  withHandlers({
    onDelete: ({ deleteProject, project, onDeleted = noop, onDeleteUpdate }) => async () => {
      await deleteProject({
        variables: { 
          id: project._id },
        ...(onDeleteUpdate ? { update: onDeleteUpdate } : EMPTY_OBJECT)
      })
      onDeleted()
    }
  })
)(DeleteProjectModal)
