exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__11v7b{display:-ms-flexbox;display:flex;position:absolute;background-color:#f9f9f9;-ms-flex-direction:column;flex-direction:column;padding-top:1.5rem;padding-left:4rem;padding-right:4rem;height:100%;width:100%}", ""]);

// exports
exports.locals = {
	"content": "content__11v7b"
};