exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__jfWir{color:#add8e6;cursor:pointer}.iconAsLink__jfWir:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__GCCi6{font-style:italic}.formFieldsVerticalGutter__39JlH{padding:.5rem}.formFieldsVerticalGutter__39JlH>*{margin:.5rem !important}.tableBackground__3CMW4 .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3CMW4 .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__lFaVa .ant-notification-notice-close{display:none}.BranchSelector__38ai1{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.version__Yxim7{min-width:4rem;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-size:.8rem;border-right:1px solid #3e3e3e;padding-left:.5rem;padding-right:.5rem;cursor:pointer}.version__Yxim7 .anticon-branches{margin-right:.5rem;color:gray;font-size:1rem}.version__Yxim7>.anticon-down{-ms-flex-item-align:center;align-self:center;padding-left:1rem;color:gray;font-size:.8rem}.branchDropdownOverlay__1JcQW{overflow:auto;max-height:90vh;min-width:13rem}.branchDropdownOverlay__1JcQW .title__36A5p{text-align:center;margin-top:.8rem;margin-bottom:.2rem;color:gray}.branchDropdownOverlay__1JcQW .ant-menu-item-selected{background-color:transparent !important;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}.branchDropdownOverlay__1JcQW .ant-menu-item-selected .anticon{-ms-flex-item-align:center;align-self:center}.branchItem__1C67d{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__jfWir",
	"labelFromRef": "labelFromRef__GCCi6",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__39JlH",
	"tableBackground": "tableBackground__3CMW4",
	"notificationNotClosable": "notificationNotClosable__lFaVa",
	"BranchSelector": "BranchSelector__38ai1",
	"version": "version__Yxim7",
	"branchDropdownOverlay": "branchDropdownOverlay__1JcQW",
	"title": "title__36A5p",
	"branchItem": "branchItem__1C67d"
};