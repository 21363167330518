import React from 'react'
import NodeId from '../../Commons/NodeId'

/**
 *
 */
const DeletedChange = ({ change }) => (
  <NodeId id={change.deleted.id} />
)

export default DeletedChange