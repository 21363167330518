import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { head } from 'ramda'
import { model } from 'beanie-engine-api-js'

import CollapseChainButton from '../../../TreeChain/CollapseChainButton'
import TreeChain from '../../../TreeChain/TreeChain'
import withOnCollapseOnExpand from 'hocs/withOnCollapseOnExpand'
import makeChildrenChainSelector from 'selectors/objects/makeChildrenChainSelector'
import { propFromProps } from 'selectors/props'
import ExpandChainButton from '../../../TreeChain/ExpandChainButton'

import styles from '../TruthTable.scss'

const { types: { relationship: { Relationship } } } = model

/**
 *
 */
export const ChainRowChild = ({ node, children, onCollapse, onExpand }) => {
  const child = useMemo(() => head(children.chain), [children.chain])
  const relationship = useMemo(() => Relationship.child(node.id), [node.id])
  return (
    <td data-selection="clear">
      {child ?
        <div className={styles.chain}>
          <CollapseChainButton id={node.id} onCollapse={onCollapse} />
          <TreeChain id={child.id} relationship={relationship} />
        </div>
        : <div />
      }
      {children.remaining > 0 &&
        <ExpandChainButton count={children.remaining} onExpand={onExpand} />
      }
    </td>
  )
}

export default compose(
  connect(() => {
    const childSelector = makeChildrenChainSelector(propFromProps('id'))
    return (state, props) => ({
      children: childSelector(state, props)
    })
  }),
  withOnCollapseOnExpand(),
)(ChainRowChild)
