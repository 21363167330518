import { useCallback, useContext } from 'react'
import { Sys } from 'beanie-engine-api-js'

import { FactDrawerContext } from '../../components/FactDrawer/FactDrawerContext'
import useTransaction from './useTransaction'

/**
 * Kind of ad-hoc but a hook that allow you to start creating a fact.
 * You can pre-populate the fact instance to implement shortcuts for the user.
 * This will open the FactDrawer to edit the fact the user is creating
 */
const useFactCreator = factObjectCreator => {
  const { open } = useContext(FactDrawerContext)

  const onCreateFact = useTransaction(api => ({ name, expression }) => {
    api.obj.new(Sys.fact, undefined, { name, expr: expression })
  })

  return useCallback((...args) => {
    open({
      title: 'Create Fact',
      okButtonLabel: 'Create',
      onAccept: onCreateFact,
      fact: factObjectCreator(...args),
    })
  }, [factObjectCreator])
}

export default useFactCreator