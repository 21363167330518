import { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lang } from 'beanie-engine-api-js'
import { message } from 'antd'
import { path, pathOr, always, isNil } from 'ramda'
import { makeIsEditingSelector } from 'selectors/ui'
import { editProperty, deleteProperty } from 'engine/actions/objects'
import { Creators } from 'actions/ui'
import { objectById } from 'selectors/objects'

const { rule: { parser, utils: { isExpression } } } = lang

const { editStart, editEnd } = Creators

const useEffectNodeProperty = (id, propertyPath) => {
  const dispatch = useDispatch()
  const sourcePath = [...propertyPath, 'source']
  const node = useSelector(objectById(id))

  const [value, setValue] = useState(pathOr('', sourcePath, node))
  const valueRef = useRef(value)

  useEffect(() => setValue(pathOr('', sourcePath, node)), [node])
  useEffect(() => { valueRef.current = value }, [value])

  const isEditingSelector = useMemo(() => makeIsEditingSelector(always(id), always(propertyPath)), [id])
  const isEditingEffectPropertyNode = useSelector(isEditingSelector)

  const deletePropertyAction = useCallback(_node => dispatch(deleteProperty(_node, propertyPath)), [])
  const editPropertyAction = useCallback((_node, obj) => dispatch(editProperty(_node, propertyPath, obj)), [])
  const editEndAction = useCallback(() => dispatch(editEnd()), [])
  const editStartAction = useCallback((_id, _path) => dispatch(editStart(_id, _path)), [])

  const edit = useCallback(() => {
    if (!isEditingEffectPropertyNode) {
      editStartAction(id, propertyPath)
    }
  }, [isEditingEffectPropertyNode, id])

  const onEnter = useCallback(async () => {
    const source = valueRef.current
    const effect = parser(source)
    if (isNil(effect)) {
      message.error('Error trying to set an empty expression')
      return
    }

    if (isExpression(effect)) {
      message.error('Error trying to set an expression instead of an effect')
      return
    }
    await editPropertyAction(node, { source, effect })
    editEndAction()
  }, [node, valueRef])

  const onCancel = useCallback(async () => {
    setValue(path(sourcePath, node))
    editEndAction()
  }, [node])

  const onDeleteRule = useCallback(() => {
    deletePropertyAction(node)
  }, [node])

  return {
    // State
    value,
    setValue,
    isEditingEffectPropertyNode,

    // Callbacks
    edit,
    onEnter,
    onCancel,
    onDeleteRule,

    // Actions
    deletePropertyAction,
    editPropertyAction,
    editEndAction,
    editStartAction,
  }
}

export default useEffectNodeProperty