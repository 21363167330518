import { createActions } from 'reduxsauce'
import { partial } from 'ramda'
import { updateQueryValue, pushTransform, pullTransform, replace } from 'graphql/updates'

import { blocking } from 'actions/ui'

import savegamesSummaryQuery from 'api/queries/savegamesSummary.graphql'
import createSaveGameMutation from 'api/mutations/createSaveGameMutation.graphql'
import updateSaveGameMutation from 'api/mutations/updateSaveGameMutation.graphql'
import deleteSaveGameMutation from 'api/mutations/deleteSaveGameMutation.graphql'

import { _current } from 'selectors/state'


export const STATE_EVENTS = 'STATE_EVENTS'
export const stateEvents = (events, reason) => ({
  type: STATE_EVENTS,
  reason,
  events,
})

export const { Types, Creators } = createActions({
  // state
  clearState: null,
  // savegames
  setCurrentSavegame: ['savegame'],
  savegameSaved: null,
  setState: ['state'],
  setStateDirty: ['stateDirty'],
  // initial state is the state from which a playthrough/playbackhistory starts (could be from the scratch  {}, or from a savegame)
  setInitialState: ['newInitial'],
  setEvents: ['events']
})

// savegames state (presets)

const updateSaveGamesCache = (gameId, mutationName, transform) => updateQueryValue(
  { query: savegamesSummaryQuery, variables: { gameId } },
  transform(['savegames'], mutationName)
)


// TODO error handling !
export const createState = (gameId, slot, data) => async (dispatch, getState, { getApolloClient }) => {
  const response = await getApolloClient().mutate({
    mutation: createSaveGameMutation,
    variables: {
      input: {
        gameId,
        slot,
        data: data || _current(getState())
      }
    },
    // TODO: omit the 'data' here from the newly created savegame!
    // TODO: replace pushTransform for some new updateSortedArrayTransform, right now adding breaks sorting
    update: updateSaveGamesCache(gameId, 'createSaveGame', pushTransform)
  })
  const { data: { createSaveGame } } = response

  dispatch(Creators.setCurrentSavegame(createSaveGame))
  return createSaveGame
}

export const saveState = savegame => async (dispatch, getState, { getApolloClient }) => {
  const state = getState()
  const { gameId, slot } = savegame
  await getApolloClient().mutate({
    mutation: updateSaveGameMutation,
    variables: { input: {
      gameId,
      slot,
      data: _current(state)
    } },
    update: updateSaveGamesCache(gameId, 'updateSaveGame', replace(partial(savegameEquals, [savegame])))
  })
  await dispatch(Creators.savegameSaved())
}
const savegameEquals = (s1, s2) => s1.gameId === s2.gameId && s1.slot === s2.slot

const _deleteState = ({ gameId, slot }) => async (dispatch, getState, { getApolloClient }) => {
  await getApolloClient().mutate({
    mutation: deleteSaveGameMutation,
    variables: { input: { gameId, slot } },
    update: updateSaveGamesCache(gameId, 'deleteSaveGame', pullTransform(savegameEquals))
  })
  dispatch(Creators.setCurrentSavegame(undefined))
}

export const deleteState = blocking(_deleteState, 'Deleting...')
