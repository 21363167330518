import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'
import { push } from 'connected-react-router'
import { path, mergeDeepRight } from 'ramda'
import { compose, withStateHandlers, withHandlers, withState, lifecycle } from 'recompose'
import { Alert, Form, Input, Icon, Button } from 'antd'

import { sleep } from 'utils/promises'
import { loginUser as loginUserAction } from 'actions/login'
import { thenURLFromLocation } from 'selectors/auth'

import LinkButton from 'components/Commons/LinkButton'
import ChangePasswordModal from 'components/Users/ChangePasswordModal'
import { clearLoginErrors, LOGIN_ERROR } from '../../actions/login'

import styles from './Login.scss'

const FormItem = Form.Item

const maybeError = error => (
  error ? <Alert message={error} type="error" banner /> : ''
)

const LoginForm = ({ toForgotPassword, handlePasswordOnChange, handleEmailOnChange, onPasswordResetFinish, handleLogin, isChangePasswordVisible, loginState, form: { getFieldDecorator }, loginLoading }) => (
  <div className={styles.body}>
    <div className={styles.formTitle}>
      <h1>Welcome&nbsp;!</h1>
      <p>Please login to continue</p>
    </div>
    <ChangePasswordModal
      visible={isChangePasswordVisible}
      onFinish={onPasswordResetFinish}
      mandatory
    />
    <div className={styles.errorContainer}>
      {maybeError(loginState.error)}
      {maybeError(loginState.expired && 'Session expired!')}
    </div>
    <Form onSubmit={handleLogin}>
      <FormItem>
        <div className={styles.fieldWithLabel}>
          <Icon type="user" />
          {
            getFieldDecorator('name', {
              rules: [
                { type: 'email', message: 'Not a valid E-mail.' }, { required: true, message: 'E-mail is required.' },
              ],
            })(
              <Input
                type="text"
                placeholder="user@brunerhouse.com"
                onChange={handleEmailOnChange}
                ref={e => { if (e) { e.focus() } }}
              />
            )
          }
        </div>
      </FormItem>
      <FormItem className={styles.passwordFormItem}>
        <div className={styles.fieldWithLabel}>
          <div className={styles.password}>
            <div>
              <Icon type="lock" />
              {
                getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Password is required.' }],
                })(
                  <Input
                    type="password"
                    placeholder="password"
                    onChange={handlePasswordOnChange}
                  />
                )
              }
            </div>
            <div className={styles.forgotPassword}>
              <LinkButton type="primary" onClick={toForgotPassword}>
                Forgot your password?
              </LinkButton>
            </div>
          </div>
        </div>
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" loading={loginLoading} >
          Log in
        </Button>
      </FormItem>
    </Form>
  </div>
)

const handleBool = name => value => prev => () => mergeDeepRight(prev, { [name]: value })
const handleIsPasswordVisible = handleBool('isChangePasswordVisible')
export default compose(
  withRouter,
  withApollo,
  connect(
    ({ login }, ownProps) => ({
      loginState: login,
      then: thenURLFromLocation(null, ownProps)
    }),
    {
      loginUser: loginUserAction,
      clearErrors: clearLoginErrors,
      pushToHistory: push
    }
  ),
  withState('loginLoading', 'setLoginLoading', false),
  Form.create(),
  withStateHandlers(
    { isChangePasswordVisible: false, email: undefined, password: undefined },
    {
      changePasswordHide: handleIsPasswordVisible(false),
      changePasswordShow: handleIsPasswordVisible(true),
      handleEmailOnChange: prev => ({ target: { value: email } }) => mergeDeepRight(prev, { email }),
      handlePasswordOnChange: prev => ({ target: { value: password } }) => mergeDeepRight(prev, { password }),
    }
  ),
  withHandlers({
    handleLogin: ({ loginUser, form, email, password, then, setLoginLoading }) => loginEvent => {
      loginEvent.preventDefault()
      form.validateFields(err => {
        if (err) return
        setLoginLoading(true)
        loginUser(email, password, then)
          .then(async ({ type }) => {
            await sleep(500)
            if (type === LOGIN_ERROR) {
              setLoginLoading(false)
            }
            // if ok then we already navigated away (unmounted) so don't update
          })
          .catch(async () => {
            await sleep(500)
            setLoginLoading(false)
          })
      })
    },
    onPasswordResetFinish: ({ pushToHistory, then = '/', changePasswordHide }) => () => {
      changePasswordHide()
      pushToHistory(then)
    },
    handleEmailOnChange: ({ loginState, handleEmailOnChange, clearErrors }) => (...args) => {
      if (loginState.error) clearErrors()
      handleEmailOnChange(...args)
    },
    handlePasswordOnChange: ({ loginState, handlePasswordOnChange, clearErrors }) => (...args) => {
      if (loginState.error) clearErrors()
      handlePasswordOnChange(...args)
    },
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.client.resetStore()
    },
    async componentDidUpdate(prevProps) {
      const { changePasswordShow, loginState, pushToHistory, client } = this.props
      const justLoggedIn = loginState.token !== prevProps.loginState.token && !!loginState.token
      if (justLoggedIn) {
        await client.resetStore()
        if (path(['user', 'forceResetPassword'], loginState)) {
          changePasswordShow()
        } else {
          pushToHistory(prevProps.then || '/')
        }
      }
    }
  })
)(LoginForm)
