import { objectsIndex } from 'selectors/apollo'
import { model } from 'beanie-engine-api-js'
import { makeMemoizeSelector } from '../memoize'
import { collapsedElements } from '../view'
import { revisionId } from '../project'
import { isCollapsed } from '../folders'

const { types: { node: { getChildId } } } = model

const EMPTY_RESULT = { chain: [], remaining: 0 }

const collectChain = (node, index, result) => {
  const ref = getChildId(node)
  if (!ref) return
  const resolved = index[ref]

  if (resolved) {
    result.visit(resolved.id, node)
    collectChain(resolved, index, result)
  } else {
    // invalid reference ! cut collecting here
    result.visit(ref, node)
  }
}

class CollectChainCollapsingResult {
  constructor(id, collapsedIndex, revision) {
    this.revision = revision
    this.collapsedIndex = collapsedIndex
    this.isCollapsedFn = isCollapsed(false)

    this.chain = []
    this.remaining = 0
    this.collapsed = this.isCollapsed(id) // initial node
  }
  visit(id, parent) {
    // depending on the "mode" push the id or a count
    if (this.collapsed) {
      this.remaining += 1
    } else {
      this.chain.push({ id, parent: parent.id })
    }

    if (!this.collapsed && this.isCollapsed(id)) {
      this.collapsed = true
    }
  }
  isCollapsed(id) {
    return this.isCollapsedFn(id, this.revision, this.collapsedIndex)
  }
  toResult() {
    return { chain: this.chain, remaining: this.remaining }
  }
}

/**
 * Computes a node's chain of nodes
 */
export const makeChildrenChainSelector = idFromPropSelector => makeMemoizeSelector({
  index: objectsIndex,
  id: idFromPropSelector,
  collapsedIndex: collapsedElements,
  revision: revisionId,
}, ({ id, index, collapsedIndex, revision }) => {
  const root = index[id]
  if (!root) { return EMPTY_RESULT }

  const result = new CollectChainCollapsingResult(id, collapsedIndex, revision)
  collectChain(root, index, result)
  return result.toResult()
})

export default makeChildrenChainSelector