import { when, head, pipe, prop, applyTo, fromPairs } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import PSGamepad from './gamepad/impls/ps'
import SwitchGamepad from './gamepad/impls/switch'
import DefaultGamepad from './gamepad/impls/default'
import XBoxGamepad from './gamepad/impls/xbox'

// order matters: it will try to match from top to bottom
const GamepadList = [
  PSGamepad,
  SwitchGamepad,
  XBoxGamepad,
  // ADD more here. Objects created by gamepad/api.js#createGamepadDefinition()
  
  // default
  DefaultGamepad
]

export const Gamepad = fromPairs(GamepadList.map(impl => [impl.type, impl]))

export const inferGamepadType = gamepad => {
  const found = GamepadList.find(pipe(prop('matches'), applyTo(gamepad)))
  return found && found.type
}

export const getDefaultGamepad = () => when(isNotNil, head)(navigator.getGamepads && navigator.getGamepads())

const gamepadDefForType = type => Gamepad[type]

export const buttonToChoiceIndex = (gamepadType, apiButtonName) => {
  const def = gamepadDefForType(gamepadType)
  const button = def.apiToButtonMapping[apiButtonName]
  return button && def.buttonToChoiceMapping[button.name]
}
export const choiceIndexToButton = (gamepadType, choiceIndex) => {
  const def = gamepadDefForType(gamepadType)
  const buttonName = def.choiceIndexToButtonMapping[choiceIndex]
  return def.buttons[buttonName]
}
