import { Key } from 'utils/keyboard'
import platform from 'utils/platform'

const cursorHandler = {
  moveToStartOfLine: editor => editor.moveCursorToStartOfLine(),
  moveToEndOfLine: editor => editor.moveCursorToEndOfLine(),

  moveOneWordToLeft: editor => editor.moveCursorOneWordToLeft(),
  moveOneWordToRight: editor => editor.moveCursorOneWordToRight(),
  
  moveOneToLeft: editor => {
    if (editor.selectionIsExpanded()) {
      editor.collapseToStart()
    } else {
      editor.moveCursorToLeft()
    }
  },
  moveOneToRight: editor => {
    if (editor.selectionIsExpanded()) {
      editor.collapseToEnd()
    } else {
      editor.moveCursorToRight()
    }
  }
}

const selectionHandler = {
  moveToStartOfLine: editor => editor.moveFocusToStartOfLine(),
  moveToEndOfLine: editor => editor.moveFocusToEndOfLine(),

  moveOneWordToLeft: editor => editor.moveFocusOneWordToLeft(),
  moveOneWordToRight: editor => editor.moveFocusOneWordToRight(),

  moveOneToLeft: editor => editor.moveFocusToLeft(),
  moveOneToRight: editor => editor.moveFocusToRight(),
}
export const handler = event => (event.shiftKey ? selectionHandler : cursorHandler)


const isCrossTheLineInMac = event => platform.isMac && event.metaKey
export const isCrossTheWord = event => (platform.isMac && event.altKey) || (!platform.isMac && event.ctrlKey)

export default () => ({

  onChange (editor, next) {
    if (editor && editor.currentPath()) {
      if (!editor.selectionIsExpanded() && editor.isImmutableText(editor.currentTextNode())) {
        editor.moveToEndOfPreviousText()
      }
    }
    next()
  },

  onKeyDown (event, editor, next) {

    const handle = fn => {
      event.preventDefault()
      event.stopPropagation()
      fn()
      return false
    }

    if (event.keyCode === Key.END || (event.keyCode === Key.RIGHT && isCrossTheLineInMac(event))) {
      return handle(() => handler(event).moveToEndOfLine(editor))
    }

    if (event.keyCode === Key.HOME || (event.keyCode === Key.LEFT && isCrossTheLineInMac(event))) {
      return handle(() => handler(event).moveToStartOfLine(editor, event))
    }

    if (event.keyCode === Key.RIGHT) {
      return handle(() => {
        const _handler = handler(event)
        const moveFunc = isCrossTheWord(event) ? _handler.moveOneWordToRight : _handler.moveOneToRight
        moveFunc(editor)
      })
    }

    if (event.keyCode === Key.LEFT) {
      return handle(() => {
        const _handler = handler(event)
        const moveFunc = isCrossTheWord(event) ? _handler.moveOneWordToLeft : _handler.moveOneToLeft
        moveFunc(editor)
      })
    }

    return next()
  }
})
