import { NODE_TYPES, LineNodeTypes, PhysicalLineNodeTypes } from '../Constants'
import { findDescendentOfType, findDescendentsOfType } from '../nodeUtils'
import { isEmpty, pipe, equals } from 'ramda'
import { haveConflicts, conflicts } from '../changes/lineConflics'
import { changes, lineIdFromNode } from '../changes/lineChange'

export default () => ({
  queries: {

    // history, changes and merge/rebase

    changes(editor, baseValue, valueToMerge) { return changes(baseValue, valueToMerge) },

    hasChanges(editor, initialValue) {
      const myChanges = editor.changes(initialValue, editor.value)
      return !isEmpty(myChanges)
    },

    areMergeable(editor, initialValue, baseValue, valueToMerge) { return areMergeable(editor, initialValue, baseValue, valueToMerge) },

    haveConflicts(editor, baseChanges, changesToMerge) { return haveConflicts(baseChanges, changesToMerge) },

    conflicts(editor, changes1, changes2) { return conflicts(changes1, changes2) },

    // clip dialogues

    lineById(editor, lineId) { return lineById(editor, lineId) },
    currentNodeId(editor) { return currentNodeId(editor) },
    getLines(editor) { return editor.value.document.nodes },
    getFirstLine(editor) { return editor.getLines().first() },
    getLastLine(editor) { return editor.getLines().last() },

    lineBlockPath(editor) { return lineBlockPath(editor) },

    getDialogueTextByIndex(editor, index) { return getDialogueTextByIndex(editor, index) },
    getDialogueActorByIndex(editor, index) { return getDialogueActorByIndex(editor, index) },

    currentActorNode(editor, path) { return currentActorNode(editor, path) },
    currentTextPart(editor, path) { return currentNodeByType(editor, NODE_TYPES.TEXT_PART, path) },
    currentActorPart(editor, path) { return currentNodeByType(editor, NODE_TYPES.ACTOR_PART, path) },
    currentDirectorLine(editor, path) { return currentNodeByType(editor, NODE_TYPES.DIRECTOR_LINE, path) },
    currentLineBlock(editor, path) { return currentLineBlock(editor, path) },
    lineByIndex(editor, index) { return editor.getLines().get(index) },
    currentPhysicalLine(editor, path) { return currentPhysicalLine(editor, path) },

    // markups

    currentMarkUpParameter(editor, path) { return currentNodeByType(editor, NODE_TYPES.MARK_UP_PARAMETER, path) },
    currentMarkUp(editor, path) { return currentNodeByType(editor, NODE_TYPES.MARK_UP, path) },
    markupNameText(editor, node) {
      const markupNode = editor.findAncestorOfType(node, NODE_TYPES.MARK_UP)
      const name = findDescendentOfType(NODE_TYPES.MARK_UP_NAME)(markupNode)
      return name && name.text
    },
    currentMarkUpParameterNames(editor, path) {
      const markupNode = editor.currentMarkUp(path)
      return findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_KEY)(markupNode)
        .map(n => n.getText())
    }
  },
})

const currentPhysicalLine = (editor, path = editor.currentPath()) => {
  const current = editor.blockByPath(path)
  return PhysicalLineNodeTypes.includes(current.type) ? current : currentPhysicalLine(editor, editor.parentPath(path))
}

const currentLineBlock = (editor, path = editor.currentPath()) => {
  const current = editor.blockByPath(path)
  return LineNodeTypes.includes(current.type) ? current : currentLineBlock(editor, editor.parentPath(path))
}

const currentNodeByType = (editor, type, path = editor.currentPath()) => {
  if (!path || path.isEmpty()) { return }
  const current = editor.blockByPath(path)
  return current.type === type ? current : currentNodeByType(editor, type, editor.parentPath(path))
}

const areMergeable = (editor, initialValue, baseValue, valueToMerge) => {
  const { haveConflicts: haveConflictsFunc } = editor
  const myChanges = editor.changes(initialValue, baseValue)
  const changesToMerge = editor.changes(initialValue, valueToMerge)

  return !haveConflictsFunc(myChanges, changesToMerge)
}

const currentActorNode = (editor, path = editor.currentPath()) => editor.blockByPath(path.slice(0, 2))

const lineBlockPath = editor => editor.currentPath().take(1)
const getDialogueActorByIndex = (editor, index) => editor.blockByPath([index, 0]).text
const getDialogueTextByIndex = (editor, index) => editor.blockByPath([index, 1]).text
const currentNodeId = editor => editor.currentNode().data.get('line_id')
const lineById = (editor, lineId) => {
  const lines = editor.getLines()
  return lines.find(pipe(lineIdFromNode, equals(lineId)))
}
