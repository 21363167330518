import { Pos } from 'codemirror'

export const hint = list => editor => {
  const cursor = editor.getCursor();
  const currentLine = editor.getLine(cursor.line);
  let start = cursor.ch
  let end = start
  while (end < currentLine.length && /[\w$]+/.test(currentLine.charAt(end))) ++end;
  while (start && /[\w$]+/.test(currentLine.charAt(start - 1))) --start;

  const curWord = start !== end && currentLine.slice(start, end)
  const regex = new RegExp(`^${curWord}`, 'i');
  return {
    list: (!curWord ? list : list.filter(item => item.text.match(regex))).sort(),
    from: Pos(cursor.line, start),
    to: Pos(cursor.line, end)
  }
}
