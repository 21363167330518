import React from 'react'
import { Icon } from 'antd'
import { withHandlers } from 'recompose'

import LinkButton from 'components/Commons/LinkButton'
import AddPropertyPopover from 'components/Commons/AddPropertyPopover'

const ComplexObjectValue = ({ addField, isEditable }) => (
  <div>
    {
      isEditable && (
        <AddPropertyPopover onChanged={addField}>
          <LinkButton tooltip="Add field">
            <Icon type="plus" />
          </LinkButton>
        </AddPropertyPopover>
      )
    }
  </div>
)

export default withHandlers({
  addField: ({ onPropertyChanged, path }) => (name, value) => {
    onPropertyChanged(`${path}.${name}`, value)
  }
})(ComplexObjectValue)