import { batch } from 'react-redux'
import { Keys } from 'shortcuts/keymap'
import { T, values, pipe } from 'ramda'
import { noop, isNotNil } from 'ramda-adjunct'
import * as commands from 'commands/all'
import { Creators } from 'actions/ui'
import { isEditing } from 'selectors/ui'
import { dispatchCommand } from './commands'

// TODO: we need to have tests for this for Fowler's sake !

const { editEnd } = Creators

// Configuration

const Modes = {
  Edit: {
    check: pipe(isEditing, isNotNil),
    handler: action => action === Keys.TreeView.CANCEL_EDIT && editEnd(),
  },
  Default: {
    handler: (action, event, scope) => {
      const command = findCommand(action, scope, document.activeElement)
      if (command.preventDefault) {
        event.preventDefault()
      }
      if (command.stopPropagation) {
        event.stopPropagation()
      }
      return dispatchCommand(command, event)
    }
  },
  NoopMode: {
    check: T,
    handler: noop
  }
}

// logic

const handleShortcut = (scope, action, event) => (dispatch, getState) => batch(() => {
  const reduxAction = handleKeyEvent(action, event, getState(), scope)
  return reduxAction && dispatch(reduxAction)
})


const handleKeyEvent = (action, event, state, scope) => getMode(state).handler(action, event, scope)
const getMode = state => values(Modes).find(m => !m.check || m.check(state)) || Modes.NoopMode

const findCommand = (action, scope, activeElement) => {
  const c = commands[action]
  return (c && isEnabledInScopeAndContext(c, scope, activeElement)) ? c : NoopCommand
}

// there is currently a mix o different attributes a Command has which is kind of redundant.
// "visible" and "enabled" are just used from the context menu because those are commands
// that require parameters. We should think on how to unify that with this enabled that is based
// on scope check
// Update: I've added a "disabled" for undo/redo, used only here in shortcuts
const isEnabledInScopeAndContext = (c, scope, activeElement) => appliesToContext(c, activeElement) && isEnabledInScope(c, scope) && !c.disabled
// the current "enabled" was more like ad-hoc for commands that receive parameters.
// we also would like to access the state (like with a selector)
const appliesToContext = command =>
  !command.context || elementIsOnContext(document.activeElement, command.context)
const elementIsOnContext = (element, predicate) => predicate(element) || (element.parentElement && elementIsOnContext(element.parentElement, predicate))

export const isEnabledInScope = (c, scope) => (c.check || T)(scope)


const NoopCommand = { key: 'Noop', action: noop }

export default handleShortcut
