import React from 'react'
import { pipe, prop } from 'ramda'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Menu, Icon } from 'antd'
import { push } from 'connected-react-router'

import withSecureChecker from 'hocs/withSecureChecker'

import styles from './EntityMenu.scss'

const EntityMenu = ({ handleClick, currentSection, sections, doCheck }) => (
  <div className={styles.menu}>
    <Menu mode="horizontal" onClick={handleClick} selectedKeys={[currentSection]}>
      {sections
        .filter(s => !s.visibleCheck || doCheck(s.visibleCheck))
        .map(s => (
          <Menu.Item key={s.name}>
            <Icon type={s.icon} />{s.label}
          </Menu.Item>
        ))
      }
    </Menu>
  </div>
)

export default compose(
  connect(null, {
    handleClick: pipe(prop('key'), push)
  }),
  withSecureChecker()
)(EntityMenu)