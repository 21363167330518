import React from 'react'
import { Dropdown, Icon } from 'antd'
import classNames from 'classnames'

/* eslint import/no-webpack-loader-syntax: 0 */
import DropdownIcon from '-!svg-react-loader!icons/dropdownIcon.svg'

import styles from './DropdownMenu.scss'

const DropdownMenu = ({ setDropdownVisibility, children, iconClassName }) => (
  <Dropdown
    onVisibleChange={setDropdownVisibility}
    overlay={children}
    overlayClassName={styles.DropdownMenu}
    trigger={['click']}
    >
    <div className={classNames(styles.dropdownIcon, iconClassName)}>
      <Icon component={DropdownIcon} />
    </div>
  </Dropdown>
)

export default DropdownMenu
