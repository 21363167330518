exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ant-select-selection{border-radius:0 !important}.nameSection__1oviW{padding-bottom:1rem}.packageTab__21hf1{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:1rem}.packageSelect__30fks{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;gap:1rem;padding:12px 0}.packageSelect__30fks:first-child{padding-top:0}.packageSelect__30fks>div{width:50%}.alignSelfEnd__1ba65{-ms-flex-item-align:end;align-self:end;width:unset !important}.packageActions__260Vz{display:-ms-flexbox;display:flex;gap:12px}.packageActionsIcon__2qCkl{color:gray}.packageActionsIcon__2qCkl:hover{color:#7b61ca}.twoColumns__J4rd1{display:grid;grid-template-columns:1fr 1fr;gap:2rem}.addEditModal__wMB8k .ant-modal{width:50vw !important}.addEditModal__wMB8k .ant-form-item{margin:0}", ""]);

// exports
exports.locals = {
	"nameSection": "nameSection__1oviW",
	"packageTab": "packageTab__21hf1",
	"packageSelect": "packageSelect__30fks",
	"alignSelfEnd": "alignSelfEnd__1ba65",
	"packageActions": "packageActions__260Vz",
	"packageActionsIcon": "packageActionsIcon__2qCkl",
	"twoColumns": "twoColumns__J4rd1",
	"addEditModal": "addEditModal__wMB8k"
};