import { anyPass, prop, pipe, T, isNil } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import {
  isCmpNode,
  sourceContext,
  currentTextNode,
  isCommonVariable,
  isSpecialVariable,
  isOfKeyword,
  isProperty,
  isNodeKeyword,
  isSetKeyword,
  isToKeyword,
  isIsKeyword,
  isError,
  isOneOf,
  isOfNode,
  isEqNode,
  firstSibilingToLeft,
  isLastChosenKeyword,
  isNodeRef,
  isFactVariable,
  isExecutedKeyword,
} from './TreeUtils'

export const hasNotSpace = str => str.indexOf(' ') < 0

export const isErrorProperty = current => isError(current) && isProperty(current.parent)

export const isVariableTemplate = ({ state, pos }) => {
  const { current } = sourceContext(state, pos)
  return isOneOf(['Variable', 'SpecialVariable', 'FactApp'])(current)
}

const leftSiblingIs = isNode => pipe(prop('leftSibling'), sibling => isNotNil(sibling) && isNode(sibling))

export const isVariableToSetTemplate = leftSiblingIs(isSetKeyword)
export const isVariableValueToAssignTemplate = leftSiblingIs(isToKeyword)

export const isVariableSibling = (sibling, { state }) => {
  
  if (!sibling) return false
  const nextLeftSibling = firstSibilingToLeft(sibling, true)

  if (isSpecialVariable(nextLeftSibling)) return true

  return isCommonVariable(nextLeftSibling) && hasNotSpace(currentTextNode(state, nextLeftSibling))
}

export const isFactAppSibling = sibling =>
  (!sibling ? false : isFactVariable(sibling) || isFactVariable(firstSibilingToLeft(sibling, true)))

export const isLastChosenSibling = sibling => {
  const nextLeftSibling = firstSibilingToLeft(sibling, true)
  return isIsKeyword(sibling) && // is
    isNodeRef(nextLeftSibling) && // "id"
    isLastChosenKeyword(firstSibilingToLeft(nextLeftSibling, true)) // last_chosen
}

export const isNodeKeywordOrEmpty = (sibling, { state }) =>
  !sibling || (isNodeKeyword(sibling) && hasNotSpace(currentTextNode(state, sibling)))

export const isTemplateWithParentAndSibling = (parentMatch, siblingMatch) => (editorContext, { parent, leftSibling }) =>
  !!parent && parentMatch(parent) && siblingMatch(leftSibling, editorContext)

export const isVariableValueTemplate = isTemplateWithParentAndSibling(isCmpNode, isVariableSibling)

export const isFactValueTemplate = isTemplateWithParentAndSibling(isCmpNode, isFactAppSibling)

export const isNodeKeywordTemplate = isTemplateWithParentAndSibling(anyPass([isOfNode, isNodeKeyword]), anyPass([isNil, isProperty, isExecutedKeyword]))
export const isPropertyOfTemplate = isTemplateWithParentAndSibling(T, isOfKeyword)
export const isLastChoosenIsTemplate = isTemplateWithParentAndSibling(isEqNode, isLastChosenSibling)

export const isNodeReferenceTemplate = anyPass([isNodeKeywordTemplate, isPropertyOfTemplate, isLastChoosenIsTemplate])