exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".panel__HS1VW{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;border-radius:3px;border-collapse:collapse;-webkit-box-shadow:0 0 4px 0 rgba(0,0,0,.3);box-shadow:0 0 4px 0 rgba(0,0,0,.3);background-color:#f9f9f9;width:60%;margin:0 auto;margin-top:2em}.panel__HS1VW .ant-table-pagination{padding-right:1em}.panel__HS1VW .ant-table-thead>tr>th,.panel__HS1VW .ant-table-tbody>tr>td{padding:.4em 16px}.panel__HS1VW .ant-table-footer{padding-top:.5em;padding-bottom:.5em;font-size:.8em}.pageHeader__25ITF{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:1rem;padding-top:1rem;padding-left:2rem;padding-right:2rem;background-color:#f9f9f9}.pageHeader__25ITF .searchBox__3-OdU{-ms-flex-positive:1;flex-grow:1;-ms-flex-item-align:center;align-self:center;padding-right:2em}.pageHeader__25ITF .searchBox__3-OdU input{height:2.5em}.loading__3eNuG{display:-ms-flexbox;display:flex;-ms-flex-positive:100;flex-grow:100;-ms-flex-align:center;align-items:center;height:300px;-ms-flex-pack:center;justify-content:center}.loading__3eNuG .anticon{padding-right:1em}", ""]);

// exports
exports.locals = {
	"panel": "panel__HS1VW",
	"pageHeader": "pageHeader__25ITF",
	"searchBox": "searchBox__3-OdU",
	"loading": "loading__3eNuG"
};