exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".breadcrumb__2FZ6T .ant-breadcrumb{font-size:1.2rem}.breadcrumb__2FZ6T .ant-breadcrumb-link .anticon{padding-right:.5rem}", ""]);

// exports
exports.locals = {
	"breadcrumb": "breadcrumb__2FZ6T"
};