exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".DefaultSearchResultItem__1ZiJF{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.DefaultSearchResultItem__1ZiJF>.anticon:first-child{margin-right:1rem}", ""]);

// exports
exports.locals = {
	"DefaultSearchResultItem": "DefaultSearchResultItem__1ZiJF"
};