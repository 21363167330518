import React from 'react'
import { Observable } from 'rxjs/Rx'
import PropTypes from 'prop-types'
import { Denormalizer as MaterializeDenormalizer /* ProxyBasedDenormalizer */ } from 'beanie-engine-api-js'

const defaultDenormalizer = MaterializeDenormalizer
// const defaultDenormalizer = ProxyBasedDenormalizer

/* eslint react/no-unused-prop-types: 0 */
export default class DenormalizerProvider extends React.Component {
  /* eslint react/forbid-prop-types:0, react/require-default-props: 0 */
  static propTypes = {
    synchronizer: PropTypes.object.isRequired,
    datasource: PropTypes.object.isRequired,
    project: PropTypes.string,
    denormalizeFn: PropTypes.func,
  }
  static childContextTypes = {
    denormalizerObservable: PropTypes.object.isRequired
  }

  UNSAFE_componentWillMount() {
    this.denormalizerClass = this.props.denormalizerClass || defaultDenormalizer

    this.observers = []
    this.denormalizer = this.createDenormalizer(this.props)
    this.denormalizerObservable = Observable.create(observer => {
      observer.next(this.denormalizer)
      this.observers.push(observer)
      return () => {
        this.observers.splice(this.observers.indexOf(observer), 1)
      }
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.project !== newProps.project) {
      this.denormalizer.dispose()
      this.denormalizer = this.createDenormalizer(newProps)
      this.observers.forEach(observer => observer.next(this.denormalizer))
    }
  }

  componentWilUnmount() {
    this.denormalizer.dispose()
  }

  getChildContext() {
    return {
      denormalizerObservable: this.denormalizerObservable
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}
      </div>
    )
  }

  createDenormalizer = ({ synchronizer, datasource, denormalizeFn }) => new this.denormalizerClass(synchronizer, datasource, denormalizeFn)

}
