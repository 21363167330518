import ApolloClient from 'apollo-client'
import cache from './Cache'
import createLink from 'graphql/links/Link'

export default function createApolloClient({ getState, store, link, synchronizer } = {}) {
  const getClient = () => client
  const client = new ApolloClient({
    link: link || createLink({ getState, store, synchronizer, getClient }),
    cache
  })
  return client
}

