exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".LoadingMore__3pWId{position:absolute;bottom:6rem;left:calc(50% - 8rem);background:#000;border-radius:5px;color:#fff;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;padding:1rem;opacity:.5}", ""]);

// exports
exports.locals = {
	"LoadingMore": "LoadingMore__3pWId"
};