import React from 'react'
import { Form, Input } from 'antd'
import { model } from 'beanie-engine-api-js'

import CustomTypeValues from './CustomTypeValues'

import styles from './CreateCustomTypeForm.scss'
import { arrayLengthMin } from 'utils/antd'

const { types: { markup: { CUSTOM_TYPE_NAME_PATTERN } } } = model

const { Item: FormItem } = Form

const required = { required: true, message: 'required.' }

const CreateMarkupForm = ({ customType, getFieldDecorator }) => (
  <Form layout="horizontal">
    <div className={styles.nameSection}>
      <FormItem label="Name">
        { getFieldDecorator('name', {
          ...customType && { initialValue: customType.name },
          rules: [
            required,
            { pattern: CUSTOM_TYPE_NAME_PATTERN, message: 'No whitespaces or special characters besides _' }
          ] })(
            <Input />
        )}
      </FormItem>
      <FormItem label="Description">
        { getFieldDecorator('description', {
          ...customType && { initialValue: customType.description },
        })(
          <Input />
        )}
      </FormItem>
      <FormItem label="Values">
        { getFieldDecorator('values', {
          initialValue: customType ? customType.values : [],
          rules: [
            { validator: arrayLengthMin(2, 'values') }
          ]
        })(
          <CustomTypeValues />
        )}
      </FormItem>
    </div>
  </Form>
)

export default CreateMarkupForm