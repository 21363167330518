exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".projectImage__1OFDl{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex:1 1 0%;flex:1 1 0%}.projectImage__1OFDl>div{padding:.2rem;background-color:#efefef;border-radius:5px}.projectImage__1OFDl>div>img{max-width:24rem;max-height:16rem}.imageTitle__3cxec{font-size:.8rem;margin-top:.5rem}", ""]);

// exports
exports.locals = {
	"projectImage": "projectImage__1OFDl",
	"imageTitle": "imageTitle__3cxec"
};