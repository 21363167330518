import { useSelector } from 'react-redux'

import { urlForImage } from 'model/resources'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { token as tokenSelector } from 'selectors/auth'

/**
 * Returns the full URL to a revision resource given a file path.
 * ie
 * cost url = useImageUrl('audio/pepito.wav')
 *   // gives `/p/$revisionId/resources/audio/pepito.wav?token=...`
 * @param filePath
 */
const useImageURL = filePath => {
  const [revisionId, token] = [useSelector(revisionIdSelector), useSelector(tokenSelector)]
  return urlForImage(token, revisionId, filePath)
}

export default useImageURL