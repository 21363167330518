exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".debugIcon__LdmeU{position:absolute;background:#6eda8a;width:.5rem;height:.5rem;border-radius:50%;border:.1rem solid #3b7930;left:-0.35rem;top:calc(50% - .25rem)}", ""]);

// exports
exports.locals = {
	"debugIcon": "debugIcon__LdmeU"
};