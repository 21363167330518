import React from 'react'
import classNames from 'classnames'

import { NodeExecutionStateStatus, defaultNodeState } from 'selectors/execution'
import { propFromProps } from 'selectors/props'

import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'

import styles from './TreeNodeExecutionDecoration.scss'

export const TreeNodeExecutionDecoration = ({ children, trackExecutionEnabled, executionState, executionPath }) => {
  if (!trackExecutionEnabled || executionState === defaultNodeState) return children
  return (
    <div className={styles.executionDecorations}>
      <Overlays executionState={executionState} executionPath={executionPath} />
      {children}
    </div>
  )
}

const Overlays = ({ executionState: { execution_count, status }, executionPath }) => (
  <React.Fragment>
    <div
      key="path"
      className={classNames(styles.executionPath, {
      [styles.executed]: execution_count > 0,
      [styles.executing]: status === NodeExecutionStateStatus.executing,
      [styles.paused]: status === NodeExecutionStateStatus.paused,
      [styles.hasNext]: executionPath && executionPath.pathAfter,
      [styles.hasPrevious]: executionPath && executionPath.pathBefore,
    })} />
    {status === NodeExecutionStateStatus.executing &&
      <div key="executingBox" className={styles.executingBox} />
    }
    {status === NodeExecutionStateStatus.paused &&
      <div key="pausedBox" className={styles.pausedBox} />
    }
  </React.Fragment>
)

export default withNodeExecutionTracking(propFromProps('id'))(TreeNodeExecutionDecoration)