import React from 'react'
import ReactDOM from 'react-dom'

// import before redux!
// import 'why-did-you-update-redux'

import { Provider } from 'react-redux'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import storeCreator from 'storeCreator'

import { ApolloProvider } from 'react-apollo'
import StateSynchronizerProvider from 'providers/StateSynchronizerProvider'
import StateSynchronizer from 'sync/StateSynchronizer'
import errorReporter from 'sync/errorReporter'
import { BNEEngine, BNEStore } from 'beanie-engine-api-js'
import EngineProvider from './providers/EngineProvider'
import { notInProduction } from './services/services-enabling'
import EngineStore from './sync/reactive-stores/EngineStore'
import LocalStore from './sync/reactive-stores/LocalStore'
import App from 'containers/App/App'
import { ConfigProvider } from 'antd'
import enUS from 'antd/es/locale/en_US'
import { createBrowserHistory as createHistory } from 'history'

// services
import hotjar from 'services/hotjar'
import whyUpdate from 'services/whyupdate'
import tokenRefresher from 'services/tokenRefresher'
import sentry from 'services/sentry'
import logrocket from 'services/logrocket'
import debug from 'services/debug'

import 'styles/globals.scss'
import 'styles/fonts.scss'

const services = [
  hotjar,
  whyUpdate,
  tokenRefresher,
  sentry,
  logrocket,
  debug
]

const engineStore = new EngineStore(new BNEStore(new BNEEngine()), undefined, undefined)
const synchronizer = new StateSynchronizer(
  // frontend is still "single-engine"
  engineStore,
  errorReporter(() => store.getState())
)

const history = createHistory()
const store = storeCreator({ history, synchronizer })

if (notInProduction() && window.WeakRef) {
  window.bne = {
    // eslint-disable-next-line no-undef
    engine: new window.WeakRef(engineStore.getEngine()),
    store,
  }
}

// because of interdep we need to set the store later
// Not good. but cutting refactor here
synchronizer.setLocalStore(new LocalStore(store))
synchronizer.getEngineStore().store = store

const Root = () => (
  <div>
    <Provider store={store}>
      <RecoilRoot>
        <RecoilNexus />
        <ApolloProvider store={store} client={store.apolloClient}>
          <StateSynchronizerProvider synchronizer={synchronizer} store={store}>
            <EngineProvider engine={engineStore.getEngine()}>
              <ConfigProvider locale={enUS}>
                <App history={history} />
              </ConfigProvider>
            </EngineProvider>
          </StateSynchronizerProvider>
        </ApolloProvider>
      </RecoilRoot>
    </Provider>
  </div>
)

// init services
services
  .filter(s => !s.enabledOn || s.enabledOn())
  .forEach(s => s({ reduxStore: store }))

ReactDOM.render(<Root />, document.getElementById('app'))
