import { stopAll as stopAllAudio } from 'actions/audio'

import { Creators } from 'actions/vm'
import Groups from './Groups'

const { playPauseResumeToggle, stopPlayback, rewindPlayback, selectChoiceWithShortcut } = Creators

export const PLAY = {
  group: Groups.Playback,
  shortcut: 'f5',
  action: playPauseResumeToggle
}

export const STOP = {
  group: Groups.Playback,
  shortcut: 'f6',
  action: stopPlayback
}

export const REWIND = {
  group: Groups.Playback,
  shortcut: 'f7',
  action: rewindPlayback
}

export const SKIP_LINE_PLAYBACK = {
  group: Groups.Playback,
  shortcut: 'space',
  preventDefault: true,
  action: () => stopAllAudio(false)
}

const SELECT_CHOICE = number => ({
  group: Groups.Playback,
  shortcut: `alt+${number}`,
  action: selectChoiceWithShortcut(number),
})

export const SELECT_CHOICE_1 = SELECT_CHOICE(1)
export const SELECT_CHOICE_2 = SELECT_CHOICE(2)
export const SELECT_CHOICE_3 = SELECT_CHOICE(3)
export const SELECT_CHOICE_4 = SELECT_CHOICE(4)
export const SELECT_CHOICE_5 = SELECT_CHOICE(5)
export const SELECT_CHOICE_6 = SELECT_CHOICE(6)
export const SELECT_CHOICE_7 = SELECT_CHOICE(7)
export const SELECT_CHOICE_8 = SELECT_CHOICE(8)
export const SELECT_CHOICE_9 = SELECT_CHOICE(9)