import React from 'react'
import { model, Sys } from 'beanie-engine-api-js'

import { selectedObject } from 'selectors/objects'
import { currentNode as walkedNode } from 'selectors/walklist'
import useLastBetweenSelectors from '../../hooks/selectors/useLastBetweenSelectors'

import SlateEditorConnected from './ConnectedObjectTextEditor'
import { TextEditorForSelectedNode } from '../TextEditor/TextEditor'
import TextEditorForLuaNode from '../TextEditorForLuaNode/TextEditorForLuaNode'

const { types: { object: { isSomeChoices, isChoice, isLua, isSys } } } = model

const isEditable = isSys(Sys.clip)

const SlateEditorForSelectedNode = props => {
  const object = useLastBetweenSelectors(walkedNode, selectedObject)

  if (isChoice(object) || isSomeChoices(object)) {
    return <TextEditorForSelectedNode {...props} object={object} />
  }

  if (isLua(object)) {
    return <TextEditorForLuaNode {...props} object={object} />
  }

  if (!isEditable(object)) return null

  return (
    <SlateEditorConnected {...props} object={object} />
  )
}

export default SlateEditorForSelectedNode