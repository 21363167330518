import { isEmpty } from 'ramda'
import { message } from 'antd'

import { addedNodesFromChangeSet } from 'model/ChangeSet'
import { undoConflictingChangeSets } from 'model/changeSet/conflictDetection'

import { lastUndoableOwnChange, lastRedoFromChangeSet, futureCleanHistoryFrom } from 'selectors/undo'
import { canSafelyApplyChangeSet } from 'selectors/sync'
import { updateSelection } from '../selection/updateSelection'
import { CONCURRENT_ERROR } from 'utils/async'

import {
  UNDO_SUCCESS_MESSAGE,
  UNDO_OVERLAPS_MESSAGE,
  CONFLICT_ERROR,
  NOTHING_TO_UNDO_ERROR,
  NOT_SYNCHRONIZED_ERROR,
  BUSINESS_EXPECTED_ERRORS
} from './constants'

const undoRestoreSelection = async (changeSet, dispatch, getState) => {
  const nodes = addedNodesFromChangeSet(changeSet)
  return updateSelection(nodes, dispatch, getState)
}


const undoAction = async (dispatch, getState, { synchronizer }) => {
  if (canSafelyApplyChangeSet(getState())) {
    const lastChange = lastUndoableOwnChange(getState())

    if (lastChange) {
      const lastRedoCS = lastRedoFromChangeSet(getState(), lastChange)
      const nextChangeSets = futureCleanHistoryFrom(getState(), (lastRedoCS || lastChange).timestamp)
      const undoConflicts = undoConflictingChangeSets(nextChangeSets, lastChange)
      if (isEmpty(undoConflicts)) {
        const undoChangeSet = await synchronizer.getServerStore().undo(lastChange._id)
        await undoRestoreSelection(lastChange, dispatch, getState)
        return undoChangeSet
      } else {
        throw CONFLICT_ERROR
        // RESOLVE UNDO CONFLICTS
      }
    } else {
      throw NOTHING_TO_UNDO_ERROR
    }
  } else {
    throw NOT_SYNCHRONIZED_ERROR
  }
}

export const undoOrRedo = (action, successMessage, overlapsError, operationName) => () => async (dispatch, _, { synchronizer }) => {
  try {
    await synchronizer.getServerStore().executeServerAction(() => dispatch(action))
    message.success(successMessage, 1)
  } catch (err) {
    if (err === CONCURRENT_ERROR) {
      message.error(overlapsError, 3)
      return
    }
    if (BUSINESS_EXPECTED_ERRORS.includes(err)) {
      message.error(err.message, 3)
    } else {
      // eslint-disable-next-line no-console
      console.error(`Error performing ${operationName}: ${err.message}`)
      throw err
    }
  }
}

export const undo = undoOrRedo(undoAction, UNDO_SUCCESS_MESSAGE, UNDO_OVERLAPS_MESSAGE, 'undo')

