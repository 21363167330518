import { useMemo, useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { lang, model } from 'beanie-engine-api-js'
import { message } from 'antd'
import { path, pathOr, always, isNil } from 'ramda'
import { makeIsEditingSelector } from 'selectors/ui'
import { editRule, deleteRule } from 'engine/actions/objects'
import { Creators } from 'actions/ui'
import { objectById } from 'selectors/objects'

const { types: { object: { Paths } } } = model
const { rule: { parser, utils: { isEffect } } } = lang

const { editStart, editEnd } = Creators

// TODO: move this to Paths.object.enabled_rule.source
const sourcePath = ['data', 'enabled_rule', 'source']

const useEnabledRule = id => {
  const dispatch = useDispatch()
  const node = useSelector(objectById(id))

  const [value, setValue] = useState(pathOr('', sourcePath, node))
  const valueRef = useRef(value)

  useEffect(() => setValue(pathOr('', sourcePath, node)), [node])
  useEffect(() => { valueRef.current = value }, [value])

  const isEditingSelector = useMemo(() => makeIsEditingSelector(always(id), always(Paths.object.enabled_rule)), [id])
  const isEditingEnabledRuleNode = useSelector(isEditingSelector)

  const deleteRuleAction = useCallback(_node => dispatch(deleteRule(_node)), [])
  const editRuleAction = useCallback((_node, ruleObject) => dispatch(editRule(_node, ruleObject)), [])
  const editEndAction = useCallback(() => dispatch(editEnd()), [])
  const editStartAction = useCallback((_id, _path) => dispatch(editStart(_id, _path)), [])

  const edit = useCallback(() => {
    if (!isEditingEnabledRuleNode) {
      editStartAction(id, Paths.object.enabled_rule)
    }
  }, [isEditingEnabledRuleNode, id])

  const onEnter = useCallback(async () => {
    const source = valueRef.current
    const rule = parser(source)
    if (isNil(rule)) {
      message.error('Error trying to set an empty expression')
      return
    }

    if (isEffect(rule)) {
      message.error('Error trying to set an effect instead of an expression')
      return
    }
    await editRuleAction(node, { source, rule })
    editEndAction()
  }, [node, valueRef])

  const onCancel = useCallback(async () => {
    setValue(path(sourcePath, node))
    editEndAction()
  }, [node])

  const onDeleteRule = useCallback(() => {
    deleteRuleAction(node)
  }, [node])

  return {
    // State
    value,
    setValue,
    isEditingEnabledRuleNode,

    // Callbacks
    edit,
    onEnter,
    onCancel,
    onDeleteRule,

    // Actions
    deleteRuleAction,
    editRuleAction,
    editEndAction,
    editStartAction,
  }
}

export default useEnabledRule