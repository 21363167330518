import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'antd'

import { isCompleted } from 'actions/tasks'
import { isAnyTaskRunning, currentJobName, loadingTasks } from 'selectors/tasks'

import TaskStatus from './TaskStatus'
import LoadingCheck from './LoadingCheck'

import styles from './LoadingModal.scss'

/**
 *
 */
const LoadingRevisionModal = ({ handleOk, handleCancel }) => {
  const loading = useSelector(isAnyTaskRunning)
  const title = useSelector(currentJobName)

  const [visible, setVisible] = useState(loading, false)

  useEffect(() => {
    if (loading) {
      setVisible(loading)
    } else if (visible) {
      setTimeout(() => setVisible(false), 1300)
    }
  }, [loading, visible])

  return visible ? (
    <Modal
      title={title}
      visible={visible}
      footer={null}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
    >
      <LoadingTasks />
    </Modal>
  ) : null
}

const LoadingTasks = () => {
  const tasks = useSelector(loadingTasks)
  return (
    <React.Fragment>
      <ol className={styles.taskList}>
        {tasks.map((task, i) => (
          <li key={i}>
            <TaskStatus task={task} />
          </li>
        ))}
      </ol>
      <LoadingCheck loading={!tasks.every(isCompleted)} />
    </React.Fragment>
  )
}

export default LoadingRevisionModal