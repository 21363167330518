exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".NodeId__2wFKW .anticon{margin-left:1rem}", ""]);

// exports
exports.locals = {
	"NodeId": "NodeId__2wFKW"
};