import React, { Fragment, useCallback } from 'react'
import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Dropdown, Icon, Alert, Spin } from 'antd'
import { prop } from 'ramda'

import { currentBranch as currentBranchSelector } from 'selectors/project'
import withBooleanState from 'hocs/withBooleanState'

import projectBranchesQuery from 'api/queries/projectBranches.graphql'
import openRevisionAction from 'actions/project/openRevision'
import BranchesMenu from './BranchesMenu'

import styles from './BranchSelector.scss'

/**
 * Dropdown selector for the current version of the project
 */
const BranchSelector = ({ projectBranches, enableMenu, closeMenu, isVisible, currentBranch, showMakeCheckpointModal, branches, onBranchSelected, onDeletePressed }) => {

  const onVisibleChange = useCallback(visible => {
    (visible ? enableMenu : closeMenu)()
  }, [enableMenu, closeMenu])

  return (
    <div className={styles.BranchSelector}>
      <Spin spinning={projectBranches && projectBranches.loading}>
        <Dropdown
          onClick={enableMenu}
          visible={isVisible}
          onVisibleChange={onVisibleChange}
          overlay={
            <BranchesMenu
              isVisible={isVisible}
              current={currentBranch}
              showMakeCheckpointModal={showMakeCheckpointModal}
              branches={branches}
              onBranchSelected={onBranchSelected}
              onDeletePressed={onDeletePressed}
            />
          }
          trigger={['click']}
        >
          <div className={styles.version}>
            {currentBranch && (
              <Fragment>
                <Icon type="branches" />
                <div>{currentBranch.version}</div>
                <Icon type="down" />
              </Fragment>
            )}
          </div>
        </Dropdown>
        {projectBranches && projectBranches.error && <Alert type="error" message={`Error loading the project versions: ${projectBranches.error}`} banner /> }
      </Spin>
    </div>
  )
}

export default compose(
  graphql(projectBranchesQuery, {
    name: 'projectBranches',
    options: ({ project }) => ({ variables: { projectId: prop('_id')(project) } }),
    skip: ({ project }) => !project
  }),

  connect(state => ({
    currentBranch: currentBranchSelector(state),
  }), {
    openRevision: openRevisionAction
  }),
  withBooleanState('isVisible', 'enableMenu', 'closeMenu'),
  withProps(({ project, projectBranches }) => {
    if (!project || !projectBranches || projectBranches.loading || !projectBranches.projectWithId) return { versions: [], currentBranch: undefined }
    const { branches } = projectBranches.projectWithId
    return {
      branches
    }
  }),
  withHandlers({
    onBranchSelected: ({ projectBranches, openRevision, currentBranch, closeMenu }) => async branch => {
      if (currentBranch._id !== branch._id && projectBranches.projectWithId) {
        closeMenu()
        try {
          await openRevision(branch._id)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error loading revision', error)
        }
      }
    },
    onDeletePressed: ({ closeMenu }) => () => {
      closeMenu()
    }
  })
)(BranchSelector)
