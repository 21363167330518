import { last, propEq, replace, pipe, trim } from 'ramda'

export const escapeTags = pipe(replace(/\([^)]*\)/g, ''), trim)

// TODO: this should be refactored to work in terms of "words", instead of 
// checking to user first, lastname, name, etc..
// - make a word list by flat mapping adll words in each field (supports firstName having multiple names like "Jan Baptista")
// - first escape any tag on each part
// - then try to use initials of each word
export const acronymFor = (user, others = []) => {
  const taken = a => others.some(propEq('acronym', a.toUpperCase()))
  const options = (...names) => names.reduce((acc, name) => {
    return acc || (taken(name) ? undefined : name)
  }, undefined) || last(names)

  const tryAbbreviate = (...tokens) => {
    const [t1, t2] = tokens.map(escapeTags)
    return options(
      t1[0],
      `${t1[0]}${t2 ? t2[0] : ''}`,
      t1.slice(0, 2), 
      t1,
      user.email
    ).toUpperCase()
  }

  if (user.firstName && user.lastName) return tryAbbreviate(user.firstName, user.lastName)
  if (user.firstName) return tryAbbreviate(user.firstName)
  if (user.lastName) return tryAbbreviate(user.lastName)
  if (user.name) return tryAbbreviate(user.name)
  return user.email ? tryAbbreviate(user.email) : undefined
}