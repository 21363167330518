import { clips } from 'selectors/objects'

export const _deleteActorWithLines = (actorIdToDelete, allClips) => api => {
  allClips.forEach(clip => removeActorFromClip(actorIdToDelete, clip.id)(api))
  api.obj.delete(actorIdToDelete)
}
const deleteActorWithLines = actorIdToDelete => (dispatch, getState, { synchronizer }) =>
  synchronizer.doSynchingBNE('Deleting actor and its lines',
    api => _deleteActorWithLines(actorIdToDelete, clips(getState()))(api), actorIdToDelete)

const removeActorFromClip = (actorId, clipId) => api => {
  api.obj.get(clipId).remove_lines_from_actor(actorId)
}

export default deleteActorWithLines