/* eslint-disable import/no-absolute-path */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useState } from 'react'
import { Skeleton, Card, Tag } from 'antd'

import { projectOwnerName, isOrgProject } from 'security/commons'

import OrganizationAvatar from '../../Organizations/Commons/OrganizationAvatar'
import UserAvatar from '../../RevisionSessions/UserAvatar'
import RelativeTime from '../../Commons/RelativeTime'
import ProjectOptions from './ProjectOptions'
import ProjectRevisionSelector from './ProjectRevisionSelector/ProjectRevisionSelector'
import { urlForImage } from 'model/resources'

const defaultCoverUrl = '/images/default-project-cover.png'

import styles from './ProjectCard.scss'

/**
 * A project card displayed in the home page
 */
const ProjectCard = ({ refresh, project, currentUser, selectProject, selectSettings, token }) => {
  const { owner, lastUpdatedAt, coverImage, _id } = project
  const imageFile = coverImage ? urlForImage(token, _id, coverImage) : defaultCoverUrl

  const [revisionId, setRevisionId] = useState(project.masterBranch._id)

  const selectProjectCallback = useCallback(() => {
    selectProject(revisionId)
  }, [revisionId])

  return (
    <Card
      className={styles.projectCard}
      title={
        <div className={styles.title}>
          <OwnerAvatar owner={owner} />
          <ProjectName selectProject={selectProjectCallback} currentUser={currentUser} project={project} />
          {project.archived && <Tag>archived</Tag>}
          <ProjectOptions refresh={refresh} selectSettings={selectSettings} project={project} />
        </div>
      }

      actions={[
        <ProjectRevisionSelector
          project={project}
          revisionId={revisionId}
          setRevisionId={setRevisionId}
        />,
        lastUpdatedAt ? <div className={styles.updatedAt}><span>Updated&nbsp;</span><RelativeTime date={lastUpdatedAt} /></div> : <div />
      ]}
      >
      <div className={styles.image} onClick={selectProjectCallback}>
        <img src={imageFile} alt={project.name} />
      </div>
    </Card>
  )
}

export default ProjectCard

const ProjectName = ({ project, currentUser, selectProject }) => (
  <div className={styles.projectNameContainer}>
    <span className={styles.projectName} onClick={selectProject}>{project.name}</span>
    <span className={styles.ownerName}>By <strong>{projectOwnerName(project, currentUser)}</strong></span>
  </div>
)

const OwnerAvatar = ({ owner }) => (
  <div className={styles.avatar}> {isOrgProject(owner) ?
    <OrganizationAvatar shape="circle" withoutTitle organization={owner} size="default" disabled /> :
    <UserAvatar user={owner} contextMenuEnabled={false} showPopOver={false} />}
  </div>
)


export const SkeletonCard = () => <Skeleton className={styles.skeleton} loading avatar active shape="square" />