exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".projectCardsNoContent__2CyMg{left:calc(50% - 8rem);position:relative}", ""]);

// exports
exports.locals = {
	"projectCardsNoContent": "projectCardsNoContent__2CyMg"
};