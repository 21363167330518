import React from 'react'
import classNames from 'classnames'
import { isEmptyOrNull } from 'utils/string'

import ActorAvatar from 'components/Commons/ActorAvatar'

import { getActorIcon, getActorName } from 'selectors/clip'

import styles from './CurrentClipPlayback.scss'

const LineActor = ({ actor, className }) => {
  const icon = getActorIcon(actor)

  return (
    <div className={classNames(styles.actorBox, className)}>
      {!isEmptyOrNull(icon) ?
        <div className={styles.actorAvatar}>
          <ActorAvatar fileName={getActorIcon(actor)} className={styles.avatar} />
          <span className={styles.actorAvatarLabel}>{getActorName(actor)}</span>
        </div>
        : <ActorAvatar className={styles.avatarNoIcon}>{getActorName(actor)}</ActorAvatar>
      }
    </div>
  )
}

export default LineActor