exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".control__2LnJg{z-index:1;background-color:#b1afaf;color:#fff;font-size:.8rem;position:absolute;bottom:-1rem;right:0;padding-left:.5rem;padding-right:.5rem}", ""]);

// exports
exports.locals = {
	"control": "control__2LnJg"
};