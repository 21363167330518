import React from 'react'
import SearchBox from 'components/Commons/SearchBox'

import styles from './SectionHeader.scss'

const SectionHeader = ({ onSearch, actions, children }) => (
  <div className={styles.SectionHeader}>
    <div className={styles.filters}>
      <SearchBox onSearch={onSearch} />
      {children && 
        <div className={styles.extraFilters}>
          {children}
        </div>
      }
    </div>
    <div className={styles.actions}>{actions}</div>
  </div>
)

export default SectionHeader