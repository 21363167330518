import { propEq } from 'ramda'

export default () => ({

  queries: {

    hasMarkInPos: (editor, markType, offset) => editor.value.document
      .getMarksAtPosition(editor.currentPath(), offset)
      .some(propEq('type', markType)),

    hasEndMark: (editor, markType) => { 
      const { value: { selection: { end: { offset } } } } = editor
      return editor.hasMarkInPos(markType, offset)
    },

    hasStartMark: (editor, markType) => { 
      const { value: { selection: { start: { offset } } } } = editor
      return editor.hasMarkInPos(markType, offset)
    }

  },

  commands: {

    selectWholeMarkRange: (editor, markType) => {
      const { offset } = editor.currentFocus()
      editor.expandSelectionLeftWhile(() => editor.hasStartMark(markType))
      editor.moveEndTo(offset)
      editor.expandSelectionRightWhile(() => editor.hasEndMark(markType))
    },

    removeCurrentMark: (editor, markType, offset = editor.currentFocus().offset) => {
      editor.selectWholeMarkRange(markType)
      const { value: { selection: { start, end } } } = editor
      const length = end.offset - start.offset
      editor.removeMarkByPath(editor.currentPath(), start.offset, length, markType)
      editor.moveTo(offset)
    }

  }

})