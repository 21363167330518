exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".list__1DCmf .ant-list-item{padding-top:.2rem;padding-bottom:.2rem}.list__1DCmf .ant-list-item-meta{-ms-flex-align:center !important;align-items:center !important}.title__cpOxk{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.title__cpOxk>div:first-child{margin-right:1rem;-ms-flex-positive:1;flex-grow:1}", ""]);

// exports
exports.locals = {
	"list": "list__1DCmf",
	"title": "title__cpOxk"
};