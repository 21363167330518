import React from 'react'
import { Avatar, Tooltip } from 'antd'
import toMaterialStyle from 'material-color-hash'
import { origin } from '../../../model/constants/SessionClientType'

const RevisionSessionCursor = ({ session }) => {
  const style = toMaterialStyle(session._id)
  style.border = '1px solid white'

  return (
    <Tooltip title={displayNameForUser(session)}>
      <Avatar style={style} />
    </Tooltip>
  )
}

export default RevisionSessionCursor

const displayNameForUser = session => {
  const { user } = session
  return (
    `${user.firstName && user.lastName ? `${user.firstName} ${user.lastName}`
      : user.name} from ${origin(session)}`
  )
}