exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".enabledRule__1sNh5{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:start;width:100%;min-height:1.5rem;min-width:3rem;max-width:10rem;font-weight:bolder;border:1px solid transparent;border-radius:1rem;padding-right:.5rem;padding-left:.5rem;padding-bottom:.1rem;padding-top:.1rem}.enabledRule__1sNh5:hover{border:1px solid #d3d3d375}.enabledRule__1sNh5:hover .deleteButton__3tJry{visibility:visible}.enabledRule__1sNh5 .anticon{padding-right:.2rem}.editing__2gQMm{border:none !important}.ruleContent__2kOQw{width:100%;max-width:8rem;max-height:1.2rem;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}.questionIcon__1RF9Y{padding-right:.2rem}.ruleContainer__1lWCE{width:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;cursor:pointer}.ruleContainer__1lWCE .deleteButton__3tJry{color:#d6d4d4;visibility:hidden;padding-left:.5rem;padding-right:.2rem}.ruleContainer__1lWCE .deleteButton__3tJry:hover{color:#636262}.ruleError__GJuZW{color:#db6666}", ""]);

// exports
exports.locals = {
	"enabledRule": "enabledRule__1sNh5",
	"deleteButton": "deleteButton__3tJry",
	"editing": "editing__2gQMm",
	"ruleContent": "ruleContent__2kOQw",
	"questionIcon": "questionIcon__1RF9Y",
	"ruleContainer": "ruleContainer__1lWCE",
	"ruleError": "ruleError__GJuZW"
};