import React from 'react'

import styles from './RootMarkerPlayback.scss'
import playbackHistoryStyles from '../PlaybackHistory.scss'

export default class RootMarkerPlayback extends React.Component {
  render() {
    const { playingObject } = this.props
    return (
      <div className={styles.separator}>
        <span className={playbackHistoryStyles.rootMarkerTitle}>{ playingObject.data.name }</span>
      </div>
    )
  }

  componentDidMount = () => {
    const { node, onContentAdded, onFinished } = this.props
    onContentAdded()
    onFinished(node)
  }
}