import React from 'react'
import { compose } from 'recompose'
import { pipe, map } from 'ramda'

import { allMarkupTypes as markupTypesSelector } from 'selectors/markups'

import AutocompleteEditComponent from 'components/EditableTable/AutocompleteEditComponent'
import connectState from 'hocs/connectState'

const toOption = type => ({
  key: type.name,
  value: type.name,
  label: type.name
})

const Composed = compose(
  connectState({
    options: pipe(markupTypesSelector, map(toOption))
  })
)(AutocompleteEditComponent)

// Just to be called to the focus() instance method :(
export default class ComponentWithFocus extends React.Component {
  refToComponent = React.createRef()
  render() {
    return (
      <Composed refToComponent={this.refToComponent} {...this.props} />
    )
  }
  focus() {
    this.refToComponent.current.focus()
  }
}