exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editingContainer__6Ezt5{background:#fff;position:absolute;z-index:10;border:1px solid #d0d0d0;-webkit-box-shadow:2px 2px 2px #d2d2d3;box-shadow:2px 2px 2px #d2d2d3;border-radius:.5rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:start;min-width:8rem}.errorMessage__2NSn4{display:-ms-flexbox;display:flex;padding:.5rem;background:#feebec;max-width:inherit}.nodeEditor__2BuFl{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:row;flex-direction:row;font-size:smaller;-ms-flex-align:center;align-items:center;width:auto;min-height:2rem;max-height:3rem}.nodeEditor__2BuFl .editor__2lGjq{height:auto;width:auto;border:none;min-width:12rem}.nodeEditor__2BuFl .isValid__3ez8V{font-size:.8rem;padding-right:.5rem}.withError__uB7R9{border-bottom:1px solid #d0d0d0}.resultAndEditor__3hAc8{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;height:auto}.result__a7fkS{margin-right:.2rem;margin-left:.5rem;height:.5rem;width:.5rem;background-color:transparent;border-radius:50%;border:1px solid #d6d6d6;display:inline-block}.noEvaluate__IyHls{background:transparent;border:1px solid #d6d6d6}.error__zi2HP{background:#bd7373;border:none}.passed__AGNp9{background:#9eb696;border:none}.notPassed__rAP03{background:#c4c4c4;border:none}", ""]);

// exports
exports.locals = {
	"editingContainer": "editingContainer__6Ezt5",
	"errorMessage": "errorMessage__2NSn4",
	"nodeEditor": "nodeEditor__2BuFl",
	"editor": "editor__2lGjq",
	"isValid": "isValid__3ez8V",
	"withError": "withError__uB7R9",
	"resultAndEditor": "resultAndEditor__3hAc8",
	"result": "result__a7fkS",
	"noEvaluate": "noEvaluate__IyHls",
	"error": "error__zi2HP",
	"passed": "passed__AGNp9",
	"notPassed": "notPassed__rAP03"
};