import { any, pathSatisfies, prop, pathOr, pick } from 'ramda'
import { message } from 'antd'
import { model } from 'beanie-engine-api-js'

import winston from 'utils/logger'
import { getFromJson } from 'utils/clipboard'

import { selectedObject, selectedObjects } from 'selectors/objects'
import { objectsIndex } from 'selectors/apollo'
import { revisionId as revisionIdSelector } from 'selectors/project'
import pasteNodesTransform from 'model/operations/paste/pasteNodes'
import { ClipboardContentType } from 'model/app/clipboard/clipboard'
import { _pasteNodesAfter } from './node/pasteNodesAfter'
import { _setProperties } from './objects'

import { Creators as UiCreators } from 'actions/ui'

const { types: { node: { isStrictNode } } } = model

// exported just for test
// Note: this function does not seem to be tested
const doPasteNodes = (clipboardContent, afterId) => (api, _, getState) => {
  const index = objectsIndex(getState())
  const revisionId = revisionIdSelector(getState())
  const { copies, headId, tailId } = pasteNodesTransform(clipboardContent, index, revisionId)
  return _pasteNodesAfter(
    Object.values(copies),
    headId,
    tailId,
    afterId
  )(api)
}


const doPasteNodeProperties = (clipboard, nodesIds, paths) => api => {
  let toCopy = clipboard.content.properties
  if (paths) {
    const keys = paths.map(path => path.split('.')[clipboard.content.path.length])
    toCopy = pick(keys, toCopy)
  }

  nodesIds.forEach(
    nodeId => _setProperties(nodeId, toCopy, clipboard.content.path)(api)
  )
}

const isNotPastingContained = ({ content, headId }) => pathSatisfies(isStrictNode, [headId, 'sys'], content)

// I 'm not using toBNEAction helper just because I need getState to connect with selectors.
const pasteNodes = (afterNode, clipboard) => async (dispatch, getState, { synchronizer }) => {
  if (!afterNode) return

  if (isNotPastingContained(clipboard)) {
    message.error('You can\'t paste clipboard\'s content after that node.')
    return
  }

  try {
    const pasted = await synchronizer.doSynchingBNE(
      'Paste',
      doPasteNodes(clipboard, afterNode.id),
      afterNode
    )
    if (pasted) {
      message.success('Pasted objects from clipboard.')
    }
  } catch (e) {
    message.error('Error pasting, nothing changed.')
    winston.error(`Error pasting. Exception message is: ${e.message}`)
    winston.error('Error pasting: Most probably your clipboard included a line from an actor that does not exist where you pasted.')
    winston.error(`Error pasting: stack trace -> ${e.stack}`)
  }
}

const thereIsConflict = (objects, nodePropertiesClipboard) => {
  const { content: { properties, path } } = nodePropertiesClipboard
  const propertiesKeys = Object.keys(properties)

  return any(object => {
    const objectProps = pathOr({}, path, object)

    return any(
      key => { return !!objectProps[key] },
      propertiesKeys
    )
  }, objects)
}

export const pasteNodeProperties = (targets, _clipboard, paths) => async (dispatch, getState, { synchronizer }) => {
  try {
    const clipboard = _clipboard || await getFromJson()

    // keys are selected props to paste (& override if collision) on targets
    if (!paths && thereIsConflict(targets, clipboard)) {
      // we have to preserve clipboard as it is, in case the user modifies the clipboard when the modal is open
      dispatch(UiCreators.setPropertiesConflictDialog(targets, clipboard))
      return
    }

    await synchronizer.doSynchingBNE(
      'Paste node properties',
      doPasteNodeProperties(clipboard, targets.map(prop('id')), paths),
      targets
    )
    message.success('Pasted properties from clipboard.')
  } catch (e) {
    message.error('Error pasting, nothing changed.')
    winston.error(`Error pasting. Exception message is: ${e.message}`)
    winston.error('Error pasting: Most probably your clipboard included a line from an actor that does not exist where you pasted.')
    winston.error(`Error pasting: stack trace -> ${e.stack}`)
  }
}

export const handleTreeNodePaste = () => async (dispatch, getState, args) => {
  const clipboard = await getFromJson()

  if (!clipboard?.contentType) {
    message.error('Clipboard does not contain BNE data, can\'t paste here.')
    return
  }

  if (clipboard?.contentType === ClipboardContentType.Nodes) {
    return pasteNodes(selectedObject(getState()), clipboard)(dispatch, getState, args)
  }

  if (clipboard?.contentType === ClipboardContentType.NodeProperties) {
    return pasteNodeProperties(selectedObjects(getState()), clipboard)(dispatch, getState, args)
  }
}
