import React, { Fragment } from 'react'
import { graphql } from 'react-apollo'
import { Checkbox, Modal, Form, Input } from 'antd'
import editUser from 'api/mutations/userEdit.graphql'
import { Errors } from 'components/Commons/Error'
import { parseGraphQLErrors } from 'utils/graphql'
import { withTargetChecked } from 'utils/antd'

import { Roles } from 'utils/authentication'

import IconButton from 'components/IconButton/IconButton'

import styles from './CreateUser.scss'

const CheckboxGroup = Checkbox.Group;

class EditUserForm extends React.Component {

  state = {
    confirmDirty: false,
  }

  handleConfirmBlur = (e) => {
    const { value } = e.target
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  checkPassword = (rule, value, callback) => {
    const { form } = this.props
    callback(value && value !== form.getFieldValue('password') ? 'Passwords are inconsistent!' : undefined)
  }

  checkConfirm = (rule, value, callback) => {
    const { form } = this.props
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirmPassword'], { force: true })
    }
    callback()
  }

  render() {
    const { user, onAccept, onCancel, form, visible, loading, serverErrors, editPassword, setEditPassword, roles } = this.props
    const FormItem = Form.Item
    const { getFieldDecorator } = form
    const required = { required: true, message: 'required.' }
    return (<Modal confirmLoading={loading} onCancel={onCancel} onOk={onAccept} title="Edit User" visible={visible} okText="Save">
      <Form layout="vertical">
        <div className={styles.nameSection}>
          <FormItem label="First Name">
            { getFieldDecorator('firstName', {
              initialValue: user.firstName
            })(
              <Input />
            )}
          </FormItem>
          <FormItem label="Last Name">
            { getFieldDecorator('lastName', {
              initialValue: user.lastName
            })(
              <Input />
            )}
          </FormItem>
        </div>
        <FormItem label="E-mail">
          {
            getFieldDecorator('email', {
              rules: [{ type: 'email', message: 'Not a valid E-mail.' }, { required: true, message: 'E-mail is required.' }],
              initialValue: user.email
            })(
              <Input />
            )
          }
        </FormItem>
        <FormItem>
          <div className={styles.rolesSection}>
            <div>Roles </div>
            <div>
              {getFieldDecorator('roles', { initialValue: roles })(
                <CheckboxGroup options={Object.values(Roles)} />
              )}
            </div>
          </div>
        </FormItem>
        <FormItem>
          <Checkbox onChange={withTargetChecked(setEditPassword)}>Change password</Checkbox>
        </FormItem>
        { editPassword &&
          <Fragment>
            <FormItem label="Password">
              {
                getFieldDecorator('password', {
                  rules: [
                    required,
                    { validator: this.checkConfirm }
                  ],
                })(
                  <Input type="password" />
                )
              }
            </FormItem>
            <FormItem label="Confirm Password">
              {
                getFieldDecorator('confirmPassword', {
                  rules: [
                    required,
                    { validator: this.checkPassword }
                  ],
                })(
                  <Input type="password" onBlur={this.handleConfirmBlur} />
                )
              }
            </FormItem>
          </Fragment>
        }

        {serverErrors && serverErrors.length > 0 &&
          <Errors items={serverErrors} />
        }

      </Form>
    </Modal>
    )
  }
}

const WrappedEditUserForm = Form.create()(EditUserForm)


@graphql(editUser)
export default class EditUser extends React.Component {
  state = {
    loading: false,
    visible: false,
    editPassword: false
  }

  saveFormRef = form => {
    this.form = form
  }

  setVisible = visible => { this.setState({ visible }) }
  openModal = () => { this.setVisible(true) } 
  closeModal = () => { this.setVisible(false) } 

  setEditPassword = editPassword => { this.setState({ editPassword }) }

  render() {
    const { user } = this.props
    const { visible, loading, errors, editPassword } = this.state
    return (
      <div>
        <IconButton icon="edit" tooltip="Edit" tooltipPlacement="bottom" onClick={this.openModal} />
        {visible && 
          <WrappedEditUserForm
            ref={this.saveFormRef}
            user={user}
            visible={visible}
            onCancel={this.closeModal}
            onAccept={this.perform}
            loading={loading}
            serverErrors={errors}
            editPassword={editPassword}
            setEditPassword={this.setEditPassword}
            roles={user.roles}
          />
        }
      </div>
    )
  }

  perform = () => {
    const { user } = this.props
    const { form } = this
    form.validateFields((err, values) => {
      if (err) return
      const { editPassword } = this.state
      const { mutate, onAccept } = this.props
      this.setState({ loading: true })
      const { firstName, lastName, email, password, roles } = values

      const input = {
        _id: user._id || user.id, // TODO: remove when user.id does not exists!
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
        ...(email && { email }),
        ...(editPassword && { password }),
        roles
      }
      mutate({ variables: { input } })
        .then(() => {
          this.setState({ loading: false, errors: [] })
          form.resetFields()
          this.setState({ visible: false })
          this.closeModal()
          if (onAccept) { onAccept() }
        })
        .catch(error => {
          this.setState({ loading: false, errors: parseGraphQLErrors(error) })
        })
    })
  }

}
