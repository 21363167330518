import React from 'react'
import { connect } from 'react-redux'

import { makePreferenceSelector } from 'selectors/view'
import { togglePreference, cyclePreference } from 'actions/view'

import { isFunction } from 'utils/object'

import Button from 'components/Commons/Button'

import styles from './TogglePreferenceButton.scss'

const TogglePreferenceButton = ({ type, description, toggle, currentValue }) => (
  <Button
    type={isFunction(type) ? type(currentValue) : type}
    description={isFunction(description) ? description(currentValue) : description}
    className={currentValue ? styles.enabled : styles.disabled}
    onClick={toggle}
  />
)

const toggleOrCycle = props => (props.cycle ? cyclePreference : togglePreference)(props.preference)

export default connect(() => {
  const selector = makePreferenceSelector('preference')
  return (state, props) => ({
    currentValue: selector(state, props)
  })
}, (dispatch, props) => ({
  toggle: () => dispatch(toggleOrCycle(props))
}))(TogglePreferenceButton)