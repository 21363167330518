import Stats from 'utils/timing/Stats'
import { repeat } from 'ramda'

const statsStack = []
let accum = 0

const profileSelectorsMiddleware = () => next => action => {
  // new state for this action
  window.selectorsStats = new Stats()
  statsStack.push(window.selectorsStats)

  // call it
  const result = next(action)

  // compute time
  const stats = statsStack.pop()
  const elapsedTime = stats.totalAccumulatedTime() + accum
  // eslint-disable-next-line no-console
  console.log(repeat('\t', statsStack.length).join(''), 'Action [', actionName(action), '] took selectors T = ', elapsedTime.toFixed(3), 'ms')

  accum = statsStack.length === 0 ? 0 : accum + elapsedTime

  return result
}

const actionName = action => action.type || action.name || action

export default profileSelectorsMiddleware