import moment from 'moment'
import { append } from 'ramda'
import { getRecoil, setRecoil } from 'recoil-nexus'
import { searchesState } from '../../../../state/search/searches'

/**
 * Saves the given search into the Search History
 */
const save = (search, timestamp) => () => {
  setRecoil(searchesState, append({ search, timestamp: timestamp || moment() }, getRecoil(searchesState)))
}

export default save