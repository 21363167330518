import React from 'react'
import { Table } from 'antd'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { model } from 'beanie-engine-api-js'

import { intersperse } from 'utils/object'
import { currentIsBranch } from 'selectors/project'
import IconButton from 'components/Commons/IconButton'
/* eslint import/no-webpack-loader-syntax: 0 */
import WalkIcon from '-!svg-react-loader!components/WalkList/WalkListPopover/icons/walk.svg'

import EditMarkup from './EditMarkup'

import styles from './MarkupsTable.scss'

const { types: { markup: { markupColorToCSS } } } = model

const { Column } = Table

/**
 *
 */
const MarkupsTable = ({ markups, deleteMarkup, markupDefEdited, onWalkMarkupUsages, isBranch }) => (
  <Table
    rowKey={record => record.name}
    dataSource={markups}
    pagination={false}
  >
    <Column
      sorter
      title="Name"
      key="name"
      className={styles.nameColumn}
      width="50%"
      render={({ name, parameters, color }) => (
        <div>
          <span className={styles.name} {...color && { style: { color: markupColorToCSS(color) } }}>{name}</span>
          {intersperse(parameters.map(({ name: n, type }) => `${n}: ${type}`), i => <span key={i} className={styles.separator} />)}
        </div>
      )}
    />
    <Column
      title="Description"
      dataIndex="description"
      key="description"
      width="30%"
    />
    <Column
      title=""
      key="actions"
      render={markup => (
        isBranch && <div className={styles.actions}>
          <EditMarkup markup={markup} markupDefEdited={markupDefEdited} />
          <IconButton tooltip="Walk Usages" component={WalkIcon} onClick={() => onWalkMarkupUsages(markup)} />
          <IconButton tooltip="Remove" type="delete" onClick={() => deleteMarkup(markup)} />
        </div>
      )}
    />
  </Table>
)

export default compose(connect(state => ({ isBranch: currentIsBranch(state) }), {}))(MarkupsTable)
