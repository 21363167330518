/*
 Names here are just to better describe our commands/shortcuts
 Actual dom section binding has to do with the part of the App where <Shortcuts> components are rendered
 */

const Namespaces = {
  Project: {
    TreeView: 'TreeView',
    Edit: 'ProjectEdit',
    Global: 'ProjectGlobal',
  }
}

export default Namespaces