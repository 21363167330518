import { isNotNil } from 'ramda-adjunct'
import { createSelector, createArraySelector, createDeepEqualSelector } from 'selectors/reselect'
import { untitledLaneIndexes, NEW_LANE } from 'model/constants'
import {
  length,
  pathEq,
  pluck,
  map,
  prop,
  find,
  head,
  chain,
  reject,
  isNil,
  pathOr,
  pipe,
  values,
  pickBy,
  mapObjIndexed,
} from 'ramda'
import { parseRef, Sys, DEFAULT_ACTOR, isNode, model } from 'beanie-engine-api-js'

import { emptyObjectToArray, flatten, EMPTY_ARRAY, propFrom, EMPTY_OBJECT } from 'utils/object'

// other selectors
import { idFromProps, playbackNodeIdFromProp } from 'selectors/props'
import { objects, objectsIndex } from 'selectors/apollo'
import { selectedNode, selectedNodes } from './nodeSelection'

const { types: { object: { isDisabled } } } = model

export const selectedObject = createSelector('selectedObject', [selectedNode, objectsIndex], prop)

export const resolveId = id => objectById(parseRef(id))

// TODO: AVOID CREATING A NEW ARRAY EVERY TIME ! mix of createArraySelector + deepEqual
export const selectedObjects = createDeepEqualSelector('selectedObjects',
  [selectedNodes, objectsIndex],
  (nodeIds, index) => {
    if (!nodeIds) { return EMPTY_ARRAY }
    return nodeIds
      .map(propFrom(index))
      // we shouldn't need this if we keep the selection sync with remote deletes
      .filter(isNotNil)
  })

// TODO:  all the followings are deprecated ! use makeObjectByIdSelector.js
export const createObjectByIdSelector = idFromPropSelector => () => createSelector('createObjectByIdSelector',
  [idFromPropSelector, objectsIndex],
  prop
)
export const makeObjectFromIdSelector = createObjectByIdSelector(idFromProps)
export const createObjectByPlaybackIdSelector = createObjectByIdSelector(playbackNodeIdFromProp);
[createObjectByIdSelector, makeObjectFromIdSelector, createObjectByPlaybackIdSelector].forEach(_ => { _.noDebug = true })

// /resolve object

/* deprecated ! this cannot be reused between different components */
export const objectById = nodeId => createSelector('objectById', objectsIndex, prop(nodeId))
objectById.noDebug = true


export const objectsBySys = createSelector('objectsBySys',
  objects,
  // DONT USE RAMDA HERE: it degrates performance a lot
  all => all.reduce((acc, obj) => {
    if (!acc[obj.sys]) { acc[obj.sys] = {} }
    acc[obj.sys][obj.id] = obj
    return acc
  }, {})
)

export const allOf = sys => createSelector(`allOf-${sys}`, [objectsBySys], pipe(prop(sys), values))
const oneOf = sys => createSelector(`oneOf(${sys})`, [allOf(sys)], head)

export const conditionals = allOf(Sys.conditional)
export const clips = allOf(Sys.clip)
export const lines = allOf(Sys.line)
export const takes = allOf(Sys.take)
export const facts = allOf(Sys.fact)
export const actors = allOf(Sys.actor)
export const actions = allOf(Sys.action)
export const actions2 = allOf(Sys.action2)
export const truthTables = allOf(Sys.truth_table)
export const truthTableRows = allOf(Sys.truth_table_row)
export const projectObject = oneOf(Sys.project)

export const languageResources = allOf(Sys.language_resource)

export const lanesFromProject = createSelector('lanesFromProject', projectObject,
  pathOr(EMPTY_ARRAY, ['data', 'editor', 'lanes']))

export const rootObjectsIds = createSelector('rootObjectsIds', [lanesFromProject], pipe(pluck('roots'), flatten))

export const rootObjects = createSelector('rootObjects',
  [rootObjectsIds, objectsIndex],
  (ids, idx) => pipe(map(propFrom(idx)), reject(isNil))(ids)
)

export const collapsableRoots = createSelector('collapsableRoots',
  [rootObjects],
  chain(o => (o.sys === Sys.folder ? (o.data.container_contents || EMPTY_ARRAY).map(parseRef) : o.id))
)

const resolveFromIndex = idx => id => {
  const object = idx[id]
  if (!object) {
    /* eslint no-console: 0 */
    console.warn(`Found reference to non-existent objects ${id}`)
  }
  return object
}

// lanes

export const lanes = createArraySelector('lanes', [lanesFromProject], prop('name'))

export const rootObjectIdsByLane = createSelector('rootObjectIdsByLane',
  [lanesFromProject],
  allLanes => allLanes
    .reduce((ret, { name, roots }) => ({
      ...ret,
      [name]: emptyObjectToArray(roots)
    }), EMPTY_OBJECT)
)

// TEST_ME
export const makeRootIdsForLane = laneNamePropSelector => createSelector('makeRootIdsForLane',
  [laneNamePropSelector, rootObjectIdsByLane],
  (name, roots) => roots[name] || EMPTY_ARRAY
)

export const rootObjectsByLane = createSelector('rootObjectsByLane',
  [rootObjectIdsByLane, objectsIndex],
  (lanesIndex, idx) => mapObjIndexed(
    map(resolveFromIndex(idx)),
    lanesIndex
  )
)

const _newLaneName = laneNames => {
  const newIndex = untitledLaneIndexes(laneNames) + 1
  return newIndex > 0 ? `${NEW_LANE}(${newIndex})` : NEW_LANE
}
export const newLaneName = createSelector('newLaneName', [lanes], _newLaneName)

export const defaultActor = createSelector('defaultActor', [actors], find(pathEq(['data', 'actorName'], DEFAULT_ACTOR)))

export const makeRootsForLane = laneNameSelector => createSelector(
  [rootObjectsByLane, laneNameSelector],
  (laneIndex, laneName) => laneIndex[laneName] || EMPTY_ARRAY
)
export const makeRootNrForLane = laneNameSelector => createSelector(
  [makeRootsForLane(laneNameSelector)],
  length,
)

//

export const nodesIndex = createSelector(
  [objectsIndex],
  pickBy(pipe(prop('sys'), isNode))
)

// Selector
export const isDisabledFromId = id => createSelector(
  [objectsIndex],
  idx => isDisabled(idx[id])
)
