import { Sys } from 'beanie-engine-api-js'
import choicesHandler from './handlers/choices'
import clipHandler from './handlers/clip'

const handlersBySys = {
  [Sys.clip]: clipHandler,
  [Sys.choices]: choicesHandler,
  [Sys.choices2]: choicesHandler,
}

export default handlersBySys