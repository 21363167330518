import BlockType from 'components/TextEditor/BlockType'
import { createChoiceBlock } from 'components/TextEditor/choicesEditorModel'
import { updateContent, appendBlock, setCursorAt, composeMutator } from 'components/TextEditor/draftjs-mutations'
import { getBlockForSelectionAnchor } from 'components/TextEditor/draftjs-api'
import { CommandResult, Command } from 'components/TextEditor/draftjs-constants'

export const COMMAND = Command.enter

export default () => ({
  handleKeyCommand: (command, editorState, { setEditorState }) => {
    if (command === COMMAND && precondition(editorState)) {
      setEditorState(handleEnter(editorState))
      return CommandResult.handled
    }
  }
})

const precondition = state => getBlockForSelectionAnchor(state).getType() === BlockType.choicesTitle

const splitBlockInContentState = (contentState, selectionState) => {
  if (!selectionState.isCollapsed()) { return contentState }

  const blockToSplit = contentState.getBlockForKey(selectionState.getAnchorKey())
  const offset = selectionState.getAnchorOffset()
  
  if (isAtTheEndOfBlock(blockToSplit, offset) && !hasBlocksOfType(BlockType.choice, contentState)) {
    return appendChoiceBlock(contentState, selectionState)
  } else {
    return contentState
  }
}

const appendChoiceBlock = (content, selectionState) => {
  const block = createChoiceBlock()
  return composeMutator(
    appendBlock(block),
    setCursorAt(block.getKey(), 0)
  )(content, selectionState)
}

const isAtTheEndOfBlock = (block, offset) => block.getText().slice(offset).trim() === '' 
const hasBlocksOfType = (type, content) => content.getBlocksAsArray().some(b => b.getType() === type)

const handleEnter = updateContent('split-block', splitBlockInContentState)
