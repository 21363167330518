import React from 'react'
import { cond, all, T, always } from 'ramda'
import { Icon } from 'antd'

import { model } from 'beanie-engine-api-js'
import { ERROR_COLOR_STRING, WARNING_COLOR_STRING } from './MarkupColor'

const { types: { markup: { isErrorSeverity, isWarningSeverity } } } = model

export const ERROR_ICON = () => <Icon type="close-circle" theme="filled" style={{ color: ERROR_COLOR_STRING }} />
export const WARNING_ICON = () => <Icon type="exclamation-circle" theme="filled" style={{ color: WARNING_COLOR_STRING }} />

export const iconByErrors = cond([
  [all(isWarningSeverity), always(WARNING_ICON)],
  [all(isErrorSeverity), always(ERROR_ICON)],
  // mixed
  [T, always(ERROR_ICON)]
])

export default errors => iconByErrors(errors)()