import React from 'react'
import { Popover } from 'antd'
import SoundPlayer from 'components/SoundPlayer/SoundPlayer'

import { ZIndex } from 'utils/antd'

export default class PopoverSoundPlayer extends React.Component {
  state = {
    visible: false,
  }
  hide = () => {
    this.setState({
      visible: false,
    })
  }
  handleVisibleChange = (visible) => {
    this.setState({ visible })
  }
  render() {
    const { audioFile, trigger = 'click' } = this.props
    return (
      <Popover
        overlayStyle={{ zIndex: ZIndex.DropDown + 1 }}
        content={
          <div style={{ width: '200px' }}>
            <SoundPlayer
              audioFile={audioFile} 
              options={{ 
                waveColor: '#a9d4f3',
                progressColor: '#108ee9',
                cursorColor: 'rgba(51, 51, 51, 0.26)',
                barWidth: 2,
                scrollParent: false,
                hideScrollbar: true,
                height: 50
              }}
            />
          </div>
        }
        trigger={trigger}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        {this.props.children}
      </Popover>
    )
  }
}