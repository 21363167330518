exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".GraphiQL__1LtnC{height:80vh}", ""]);

// exports
exports.locals = {
	"GraphiQL": "GraphiQL__1LtnC"
};