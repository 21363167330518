
/**
 * "type" values for MatchSource objects.
 */
const MatchSourceType = {

  /**
   * A MatchSource that works over the object's "label".
   * We compute labels with a complex logic addressing referencing nodes, etc.
   * @see makeObjectLabelSelector
   */
  label: 'label',

  /**
   * A generic strategy to get data (text/s) derived from the object but not directly in the object.
   * For example notes of a line, which are actually stored in language_resources.
   * @see derivedSource
   */
  derived: 'derived'
}

export default MatchSourceType