
/**
 * Removes an element from a node chain.
 * Which means removing a parent-child relationship
 */
export const removeChainElement = api => (id, chain, chainIndex) => {
  const parent = api.obj.get(chainIndex === 0 ? id : chain[chainIndex - 1])

  const child = parent.get_child_node()
  if (child) {
    child.set_parent(null)
  }
}
