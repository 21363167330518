import { forEach, equals, pipe, propEq, filter, reduce, prop, min } from 'ramda'
import { CHANGE_TYPE, EDIT_TYPE, lineIdFromNode, isRemoveEdition, isReplaceEdition } from './lineChange'
import { textsToDialogueLine, textToDirectorLine } from '../../textToFragment/textToFragment'
import { EMPTY_ARRAY } from 'utils/object'
import { NODE_TYPES } from '../Constants'

export const applyAddChange = (editor, { lineIndex, data: { node } }) => {
  const lastIndex = editor.getLines().size
  editor.createBlock(EMPTY_ARRAY, min(lastIndex, lineIndex), node)
}

export const applyDeleteChange = (editor, { lineId }) => {
  const lines = editor.getLines()
  const node = lines.find(pipe(lineIdFromNode, equals(lineId)))
  editor.removeNode(node)
}

export const applyEditChange = (editor, change) => {
  const { lineId, data: { typeLineSwitched, editions } } = change
  const node = editor.lineById(lineId)
  if (node) {
    if (typeLineSwitched) { switchTypeLine(editor, node) }

    applyEditions(editor, lineId, editions)
  }
}

export const applyInsertText = (text, { value, start }, offset = 0) =>
  text.substr(0, start + offset) + value + text.substr(start + offset)

export const applyRemoveText = (text, { start, length }, offset = 0) =>
  text.substr(0, start + offset) + text.substr(start + offset + length)

export const applyReplaceText = (text, { start, removedValue, value }, offset = 0) => {
  const textRemoved = applyRemoveText(text, { start, length: removedValue.length }, offset)
  return applyInsertText(textRemoved, { value, start }, offset)
}

const APPLY_EDITION = {
  [EDIT_TYPE.INSERT_TEXT]: applyInsertText,
  [EDIT_TYPE.REMOVE_TEXT]: applyRemoveText,
  [EDIT_TYPE.REPLACE_TEXT]: applyReplaceText,
}

const APPLY_CHANGE = {
  [CHANGE_TYPE.ADD]: applyAddChange,
  [CHANGE_TYPE.DELETE]: applyDeleteChange,
  [CHANGE_TYPE.EDIT]: applyEditChange,
}

export const moveToNewIndex = (editor, node, index) => {
  editor.removeNodeByKey(node.key)
  editor.createBlock(EMPTY_ARRAY, index, node)
}

const switchedNode = ({ type, text, nodes }) => {
  if (type === NODE_TYPES.DIRECTOR_LINE) {
    return textsToDialogueLine('', text)
  } else {
    const [, textPart] = nodes
    return textToDirectorLine(textPart.text)
  }
}

const switchTypeLine = (editor, line) => {
  editor.replaceNodeByKey(line.key, { ...switchedNode(line), data: line.data })
}

const applyEdition = (edition, currentText, edit, offset) => APPLY_EDITION[edition.editType](currentText, edit, offset)

const computeNewOffset = (offset, edit) => {

  if (isRemoveEdition(edit)) return offset - edit.length

  if (isReplaceEdition(edit)) return offset - (edit.removedValue.length - edit.value.length)

  // insert
  else return offset
}

export const applyEditionsToText = text => pipe(
  reduce(({ text: currentText, offset }, edit) => ({
    text: applyEdition(edit, currentText, edit, offset),
    offset: computeNewOffset(offset, edit)
  }), { text, offset: 0 }),
  prop('text'),
)

const applyEditionsToDialogueLine = (editor, { nodes: [actorPart, textPart] }, editions) => textsToDialogueLine(
  applyEditionsToText(actorPart.text)(filter(propEq('physicalLineTypeEdited', NODE_TYPES.ACTOR_PART), editions)),
  applyEditionsToText(textPart.text)(filter(propEq('physicalLineTypeEdited', NODE_TYPES.TEXT_PART), editions))
)

const applyEditionsToDirectorLine = (editor, { text }, editions) => textToDirectorLine(applyEditionsToText(text)(editions))

export const applyEditions = (editor, lineId, editions) => {
  const line = editor.lineById(lineId)
  const { type, data, key } = line
  const newLine = ((type === NODE_TYPES.DIRECTOR_LINE) ? applyEditionsToDirectorLine : applyEditionsToDialogueLine)(editor, line, editions)
  editor.replaceNodeByKey(key, { ...newLine, data })
}

export const applyChanges = editor => forEach(change => APPLY_CHANGE[change.type](editor, change))