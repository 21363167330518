exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2yaVa{color:#add8e6;cursor:pointer}.iconAsLink__2yaVa:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__23qPG{font-style:italic}.formFieldsVerticalGutter__1Dpjq{padding:.5rem}.formFieldsVerticalGutter__1Dpjq>*{margin:.5rem !important}.tableBackground__16kBW .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__16kBW .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__3cqv1 .ant-notification-notice-close{display:none}.progress__1yFgv{width:100%;transform:translateZ(0);-webkit-transform:translateZ(0);padding-bottom:1em}.progress__1yFgv .ant-progress-bg{-webkit-transition:all .4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;-o-transition:all .4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;transition:all .4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;-ms-flex-item-align:start;align-self:flex-start;-webkit-transform:translateZ(0);transform:translateZ(0)}.progress__1yFgv .ant-progress-inner{background-color:#cdd6d9}.progress__1yFgv .ant-progress-bg{background-color:#b8a7ea}.progress__1yFgv.paused__8zv7S .ant-progress-bg{background-color:#979797}.progress__1yFgv:not(.paused__8zv7S).warningAboutToExpire__3WNif .ant-progress-bg{background-color:orange}.progress__1yFgv:not(.paused__8zv7S).alertingAboutToExpire__a41yT .ant-progress-bg{background-color:#e16e88}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2yaVa",
	"labelFromRef": "labelFromRef__23qPG",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1Dpjq",
	"tableBackground": "tableBackground__16kBW",
	"notificationNotClosable": "notificationNotClosable__3cqv1",
	"progress": "progress__1yFgv",
	"paused": "paused__8zv7S",
	"warningAboutToExpire": "warningAboutToExpire__3WNif",
	"alertingAboutToExpire": "alertingAboutToExpire__a41yT"
};