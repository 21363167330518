import React, { useEffect, createContext } from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withProps, withState } from 'recompose'
import withSynchronizer from 'hocs/withSynchronizer'
import withStore from 'hocs/withStore'
import { loaded } from 'selectors/project'
import Checker from './Checker/Checker'
import { StaticAnalysisEnablePreference } from 'model/project/preferences'
import { endCheckAnalysisAction, publishAnalysisProgress, startCheckAnalysisAction } from 'actions/staticAnalysis'
import withProjectPreference from 'hocs/withProjectPreference'
import IssuesObserver from './Checker/IssuesObserver'
import Preferences from 'preferences/Preferences'
import { preference } from 'selectors/view'
import withPreference from 'hocs/withPreference'

export const CheckerContext = createContext({
  checker: null,
  runChecks: () => {}
})

const RevisionStaticAnalyzer = ({ synchronizer, checker, className, children, checkerContext }) => {
  useEffect(() => {
    const observer = {
      appliedChangeSet(changeSet) {
        checker.receiveNewChangeSet(changeSet)
      }
    }

    synchronizer.addObserver(observer)

    return () => {
      synchronizer.removeObserver(observer)
    }
  }, [])
  
  return (
    <CheckerContext.Provider value={checkerContext}>
      <div className={className}>{children}</div>
    </CheckerContext.Provider>
  )
}

export default compose(
  withPreference({ enableChecksPref: Preferences.StaticAnalysis.enabledChecks }),
  withState('issuesObserver', 'setIssuesObserver', new IssuesObserver()),
  withProjectPreference({ analysisEnabled: StaticAnalysisEnablePreference }),
  withStore,
  connect(() => {
    const currentModeSelector = preference(Preferences.StaticAnalysis.analysisMode)
    return state => ({
      isLoaded: loaded(state),
      currentMode: currentModeSelector(state)
    }) 
  }, {
    startCheckAnalysis: startCheckAnalysisAction,
    endCheckAnalysis: endCheckAnalysisAction,
    updateProgress: publishAnalysisProgress
  }),
  withState('checker', 'setChecker', ({ store, issuesObserver, currentMode, enableChecksPref, ...props }) => {
    const checker = new Checker(store, currentMode, enableChecksPref)
    checker.addObserver({
      startAnalysis(event) {
        issuesObserver.handleStartAnalysis(props, event)
      },

      endAnalysis(event) {
        issuesObserver.handleEndAnalysis(props, event)
      },

      newIssueEvent(event) {
        issuesObserver.handleIssueEvent(props, event)
      },

      cleanIssues() {
        issuesObserver.handleCleanIssues(props)
      }
    })

    return checker
  }),
  withProps(({ checker }) => ({
    checkerContext: {
      checker,
      runChecks: () => checker.refreshChecks()
    }
  })),
  lifecycle({
    componentDidUpdate({
      isLoaded: isLoadedBefore,
      analysisEnabled: analysisEnabledBefore,
      enableChecksPref: enableChecksPrefBefore
    }) {
      const {
        checker,
        isLoaded: isLoadedAfter,
        analysisEnabled: analysisEnabledAfter,
        currentMode,
        enableChecksPref } = this.props

      if (
        (analysisEnabledAfter && !isLoadedBefore && isLoadedAfter) ||
          (isLoadedAfter && !analysisEnabledBefore && analysisEnabledAfter)) {
        checker.on()
      }

      if (analysisEnabledBefore && !analysisEnabledAfter) {
        checker.off()
      }

      if (checker.mode.modeLabel !== currentMode) {
        checker.setMode(currentMode)
      }

      if (enableChecksPref !== enableChecksPrefBefore) {
        checker.onChangeChecksPreferences(enableChecksPref)
      }
    }
  }),
)(withSynchronizer(RevisionStaticAnalyzer))
