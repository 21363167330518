exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3Usit{color:#add8e6;cursor:pointer}.iconAsLink__3Usit:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3hDUC{font-style:italic}.formFieldsVerticalGutter__3zCgB{padding:.5rem}.formFieldsVerticalGutter__3zCgB>*{margin:.5rem !important}.tableBackground__1s8kK .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1s8kK .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1kVGP .ant-notification-notice-close{display:none}.actorsSection__3xFIe{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:1em;-ms-flex-pack:justify;justify-content:space-between}.actorsSectionCol__2CqwX{overflow:auto;-webkit-box-shadow:2px 2px 2px #d2d2d3;box-shadow:2px 2px 2px #d2d2d3;background-color:#fff;min-height:90vh;margin:0 5px 0 5px}.actorsTable__1XyjI{-ms-flex:1 1 0%;flex:1 1 0%}.actorsTable__1XyjI .column__1d-Ks{width:20%}.actorsTable__1XyjI .avatarColumn__3C0kF{width:10%}.actorsTable__1XyjI .avatarColumn__3C0kF .avatarAndStar__14T0E{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.actorsTable__1XyjI .selectedRow__2U6Bs{font-weight:800}.actorDetail__21NC5{-ms-flex:.5 1 0%;flex:.5 1 0%;padding:0 1rem}.actorActions__V75rT .anticon{font-size:1.2rem;padding:.2rem}.Actions__lgzCD{display:-ms-flexbox;display:flex}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3Usit",
	"labelFromRef": "labelFromRef__3hDUC",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3zCgB",
	"tableBackground": "tableBackground__1s8kK",
	"notificationNotClosable": "notificationNotClosable__1kVGP",
	"actorsSection": "actorsSection__3xFIe",
	"actorsSectionCol": "actorsSectionCol__2CqwX",
	"actorsTable": "actorsTable__1XyjI",
	"column": "column__1d-Ks",
	"avatarColumn": "avatarColumn__3C0kF",
	"avatarAndStar": "avatarAndStar__14T0E",
	"selectedRow": "selectedRow__2U6Bs",
	"actorDetail": "actorDetail__21NC5",
	"actorActions": "actorActions__V75rT",
	"Actions": "Actions__lgzCD"
};