import React, { useEffect, useRef, useState } from 'react'
import { isFunction } from 'utils/functions'

/**
 * Wraps a component so that it won't render right away when mounting it.
 * Instead it will defer that until the next animation frame (2 actually)
 * This is useful when rendering a very big component tree which would make
 * React freeze for a bit until showing it all completely.
 * This is like a manual mechanism to do an "incremental rendering" I wish
 * there was support in React out of the box :(
 *
 * @param shouldDefer tells if it must defer the component or not based on props
 * @param Loading the component to render when loading like a placeholder
 */
const withDeferRender = ({ shouldDefer, Loading }) => Presentational => props => {

  const callback = useRef()
  const [shouldRender, setShouldRender] = useState(!shouldDefer(props))

  useEffect(() => {
    // mounting
    if (!shouldRender) {
      callback.current = window.requestAnimationFrame(() => {
        callback.current = window.requestAnimationFrame(() => {
          setShouldRender(true)
        })
      })
    }
    return () => {
      // unmounting
      if (callback.current) {
        window.cancelAnimationFrame(callback.current)
      }
    }
  }, [])

  return !shouldRender ? (isFunction(Loading) ? <Loading /> : Loading) : <Presentational {...props} />
}

export default withDeferRender