import React from 'react'
import { Input } from 'antd'
import { Key } from 'utils/keyboard'

// I had to use a state here, because somehow antd
// was not managing well using the Input directly with the sourrounding form
// The example from their docs didn't work as it came
// The rendered value was always the original value
// maybe it is because they have a higher version than we are using right now (3.13 vs 3.9)

export default class EditComponent extends React.Component {
  state = { value: this.props.value }
  render() {
    const { value } = this.state
    const { save } = this.props
    return (
      <Input
        value={value}
        onChange={this.onChange}
        ref={node => { this.input = node }}
        onPressEnter={save}
        onKeyDown={this.onKeyDown}
      />
    )
  }
  onKeyDown = e => {
    const { cancel } = this.props
    if (e.which === Key.ESC) {
      cancel()
      e.preventDefault()
      e.stopPropagation()
    }
  }
  onChange = e => {
    const { onChange } = this.props
    this.setState({ value: e.target.value })
    onChange(e.target.value)
  }
  focus() {
    if (this.input) this.input.focus()
  }
}