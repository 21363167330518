import React from 'react'

import OrganizationTabHeader from './OrganizationTabHeader'
import Organizations from './Organizations'
import organizationsQuery from 'api/queries/organizations.graphql'

import { graphql } from 'react-apollo'
import { compose } from 'recompose'

import styles from './HomeOrganizationsContent.scss'

const HomeOrganizationsContent = ({ organizations }) => (
  <div className={styles.content}>
    <OrganizationTabHeader
      refreshOrganizations={() => organizations.refetch({ query: '' })}
      onSearch={query => {
        organizations.refetch({ query: query || '' })
      }} />
    <Organizations refresh={organizations.refetch} organizations={organizations} />
  </div>
)

export default compose(
  graphql(organizationsQuery, { name: 'organizations', options: { variables: { query: '' }, fetchPolicy: 'network-only' } })
)(HomeOrganizationsContent)
