import uuid from 'uuid/v4'

import { nf } from 'utils/ui'
import { syncStartedFor, syncFinishedFor, SyncDirection } from 'actions/sync'
import { noop } from 'utils/functions'

// TODO: think about unsubscribing from stores ?

/**
 * The core component of the frontend state synchronization logic.
 * It works with observable "Stores" that trigger events every time a transaction
 * gets committed into it and generates a ChangeSet. Then the StateSynchronizer
 * replicates that ChangeSet into the other stores
 */
export default class StateSynchronizer {

  constructor(engineStore, errorReporter = noop) {
    this.engineStore = engineStore

    // currently being set hackerly from the provider !
    // this.store = store

    this.observers = []
    this.errorReporter = errorReporter

    // engine => redux + server
    engineStore.subscribe(changeSet => this._receiveFromBNE(changeSet))
  }

  // *******************************
  // ** Setup (stores setting)
  // ** kind of ad-hoc for now
  // *******************************

  setLocalStore(localStore) {
    this.store = localStore.getReduxStore()
    this.localStore = localStore
    // we don't have events from the locale store !
    localStore.subscribe(() => {
      throw new Error('Local/Redux store produced a ChangeSet but StateSynchronizer doesn\'t support handling that yet!')
    })
  }
  setServerStore(serverStore) {
    this.serverStore = serverStore
    serverStore.subscribe(changeSet => this._receiveFromServer(changeSet))
  }
  getLocalStore() { return this.localStore }
  getServerStore() { return this.serverStore }
  getEngineStore() { return this.engineStore }

  // *******************************
  // ** Internal methods
  // *******************************

  /* SERVER => BNE + LOCAL */
  async _receiveFromServer(changeSet) {
    // TODO: we could compute which objects get affected by the changeset
    // and store that in the sync state to show feedback
    await this.doSynching(async () => {
      await this._notifyingErrors({ operation: 'receiveFromServer', changeSet }, async () => {
        // => BNE
        await this.engineStore.apply(changeSet)
        // => Redux
        await this.localStore.apply(changeSet)
        this.notifyChangeSet(changeSet.project, changeSet)
      })
    }, SyncDirection.INBOUND)
  }

  /* ** SERVER + LOCAL <== BNE */
  async _receiveFromBNE(changeSet) {
    // => SERVER
    const appliedChangeSet = await this.serverStore.apply(changeSet)
    // => LOCAL (redux): it is important to apply the server-response and not the original
    //   since the reducer uses __typename to identify changes
    await this.localStore.apply(appliedChangeSet)
    this.notifyChangeSet(changeSet.project, appliedChangeSet)
  }

  async _notifyingErrors(context, fn) {
    try {
      return await fn()
    } catch (error) {
      this.errorReporter(context, error)
      throw error
    }
  }

  // *******************************
  // ** Listeners
  // *******************************

  addObserver(obs) { this.observers.push(obs) }
  removeObserver(obs) { this.observers.splice(this.observers.indexOf(obs), 1) }
  notifyChangeSet(revision, changeSet) { this.observers.forEach(obs => { obs.appliedChangeSet(changeSet) }) }

  // *******************************
  // ** To be refactor
  // *******************************

  async doSynching(fn, direction, ...objects) {
    const uid = uuid()
    try {
      this.store.dispatch(syncStartedFor(uid, direction, ...objects))
      return await fn()
    } finally {
      this.store.dispatch(syncFinishedFor(uid, direction))
    }
  }

  // this must be part of the EngineStore, but doSynch'ing is not correctly
  // balanced between incoming and outgoing transactions.
  // we need to define the SS API for stores to make transactions
  async doSynchingBNE(description, fn, ...objects) {
    return this.doSynching(async () => {
      await nf()
      return this.engineStore.mutate(description, fn)
    }, SyncDirection.OUTBOUND, ...objects)
  }

  //
  // deprecated
  //

  // DELETEME: backward compat refactor
  getEngine() { return this.engineStore.getEngine() }

}
