import { compose } from 'recompose'

import { pick } from 'ramda'
import paginatedQuery from 'hocs/paginatedQuery'
import query from 'api/queries/organizationTeams.graphql'

import OrganizationTeams from './OrganizationTeams'

export default compose(
  paginatedQuery({
    query,
    name: 'teams',
    variables: pick(['organizationName']),
  })
)(OrganizationTeams)