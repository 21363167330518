import React from 'react'
import { applySpec } from 'ramda'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Icon, Tooltip } from 'antd'
import { compose, withProps } from 'recompose'

import { keyMap, shortcutOf } from 'shortcuts/keymap'
import { isRewindEnabled as isRewindEnabledSelector } from 'selectors/playback'
import { activePreset as activePresetSelector } from 'selectors/presets'
import { Creators } from 'actions/vm'

import styles from './PlaybackControls.scss'

const { rewindPlayback: rewindPlaybackAction } = Creators

const RewindButton = ({ rewindPlayback, className, iconType, isRewindEnabled }) => (
  <Tooltip title={`Rewind (${shortcutOf(keyMap.TreeView.REWIND)})`}>
    <Icon
      className={classnames(className, { [styles.disabled]: !isRewindEnabled })}
      type={iconType}
      onClick={rewindPlayback}
    />
  </Tooltip>
)

export default compose(
  connect(
    applySpec({
      activePreset: activePresetSelector,
      isRewindEnabled: isRewindEnabledSelector
    }),
    { rewindPlayback: rewindPlaybackAction }
  ),
  withProps(({ activePreset }) => ({
    iconType: (activePreset ? 'backward' : 'fast-backward')
  }))
)(RewindButton)
