import React from 'react'
import EditableValue from './EditableValue'
import { isTrue } from 'ramda-adjunct'
import { Switch } from 'antd'

export default class BoolValue extends EditableValue {

  onChange = value => {
    if (this.mounted) {
      this.setState({ value })
    }
  }

  renderForEditing() {
    const { value } = this.state
    return (<Switch checked={isTrue(value)} onChange={this.onChange} />)
  }

  renderForDisplaying() {
    const { value } = this.state
    return <Switch checked={isTrue(value)} disabled />
  }
}