import { schema } from 'beanie-engine-api-js'

/**
 * Tells whether the given field in the object should be considered as dirty in the context.
 * Uses the engine's schema definitions for this
 *
 * @param object
 * @param field
 * @param ctxt an object that abstracts the impl from redux. Should provide functions to access
 * the project like selectors.
 */
const dirtiedByNextValue = (object, field, ctxt) => (
  schema.metadataOf(object.sys, field)?.dirtiedBy?.(object, ctxt)
)

export default dirtiedByNextValue