exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2RjwY{color:#add8e6;cursor:pointer}.iconAsLink__2RjwY:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__1jQQd{font-style:italic}.formFieldsVerticalGutter__Y7BHg{padding:.5rem}.formFieldsVerticalGutter__Y7BHg>*{margin:.5rem !important}.tableBackground__SKLI6 .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__SKLI6 .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2c4uU .ant-notification-notice-close{display:none}.outside__2YdaB{-ms-flex-positive:1;flex-grow:1;height:100%}.outside__2YdaB .inside__2Q_ip{-ms-flex-positive:1;flex-grow:1;font-family:\"Courier Prime\",Courier,monospace}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2RjwY",
	"labelFromRef": "labelFromRef__1jQQd",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__Y7BHg",
	"tableBackground": "tableBackground__SKLI6",
	"notificationNotClosable": "notificationNotClosable__2c4uU",
	"outside": "outside__2YdaB",
	"inside": "inside__2Q_ip"
};