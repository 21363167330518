exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".selected__L4XSl{background-color:#eee}", ""]);

// exports
exports.locals = {
	"selected": "selected__L4XSl"
};