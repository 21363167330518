import { equals, pipe, not, isEmpty, slice, head, tail, map, filter } from 'ramda'
import { isCMDorCTRL, Key } from 'utils/keyboard'
import { PathUtils } from 'slate'
import { isNotNil } from 'ramda-adjunct'
import { GHOST_TEXT_BY_INLINE_CONTAINER, FIXED_TEXT_BY_CONTAINER } from '../../schema/schema'
import { isNoteNode } from '../../../textToModel/notes/NoteSerializer'

import { lineIdFromNode } from '../../changes/lineChange'

// higher order functions to reuse code

const BLANK = ' '

const isInAdelimiterNote = editor => {
  const { offset } = editor.currentFocus()
  const currentNode = editor.currentNode()
  return isNoteNode(currentNode) && (offset === currentNode.text.length - 1 || offset === 1)
}

const moveBackwardWhilePrevChar = condition => editor => {
  while (condition(editor.charBeforeFocus()) && !editor.focusIsAtStartOfNode(editor.currentTextNode())) {
    editor.moveBackward()
    if (isInAdelimiterNote(editor)) { break }
  }
}

const moveForwardWhileChar = condition => editor => {
  while (condition(editor.charAtFocus()) && !editor.focusIsAtEndOfNode(editor.currentTextNode())) {
    editor.moveForward()
    if (isInAdelimiterNote(editor)) { break }
  }
}

const goRight = {
  isAtEdge: (editor, text) => editor.focusIsAtEndOfNode(text),
  moveToOther: editor => editor.moveCursorToNextText(),
  moveWithin: editor => editor.moveForward()
}
const goLeft = {
  isAtEdge: (editor, text) => editor.focusIsAtStartOfNode(text),
  moveToOther: editor => editor.moveCursorToPreviousText(),
  moveWithin: editor => editor.moveBackward()
}

const moveCursorTo = go => editor => {
  editor.collapse()
  if (go.isAtEdge(editor, editor.currentTextNode())) {
    go.moveToOther(editor)

    if (isNoteNode(editor.currentNode())) { 
      moveCursorTo(go)(editor)
    }

  } else {
    go.moveWithin(editor)
  }
}

const doWhileIsGhostText = fn => editor => {
  fn(editor)
  while (editor.isImmutableText(editor.currentTextNode())) {
    fn(editor)
  }
}

// plugin

export default () => ({

  // Interaction Input handlers
  onKeyDown (event, editor, next) {
    
    // Select current line
    if (isCMDorCTRL(event) && event.keyCode === Key.E) {
      editor.selectCurrentLine()
      return false
    }
    
    return next()
  },

  //
  // Queries
  //
  
  queries: {

    isImmutableText: (editor, node) => editor.isGhostText(node) || editor.isFixedText(node),

    isGhostText: (editor, textNode) => textNode.text === '' && isGhostByContainer(editor, textNode),

    isFixedText: (editor, textNode) => {
      const { type } = editor.parent(textNode)
      const predicate = FIXED_TEXT_BY_CONTAINER[type]
      return !!(predicate && predicate(textNode))
    },

    isInNode: (editor, { path }, { key }) => {
      const nodePath = editor.pathByKey(key).toJS()
      const pointPath = path.toJS()
      const minLength = Math.min(nodePath.length, pointPath.length)
      return nodePath.slice(0, minLength).toString() === pointPath.slice(0, minLength).toString()
    },

    currentSelection: editor => editor.value.selection,

    isFocused: editor => editor.currentSelection().isFocused,

    currentFocus: editor => editor.currentSelection().focus,

    restoringFocus: (editor, fn) => {
      const focus = editor.currentFocus().toJS()
      const currentLine = editor.currentLineBlock()
      const r = fn()
      editor.restoreFocus(focus, lineIdFromNode(currentLine))
      return r
    },

    currentStart: editor => editor.currentSelection().start,

    currentEnd: editor => editor.currentSelection().end,

    currentAnchor: editor => editor.currentSelection().anchor,

    selectionIsExpanded: editor => editor.currentSelection().isExpanded,

    selectionIsExpandedToAllContent: editor => editor.selectionIsExpanded() && editor.selectionExpandToNode(editor.value.document),

    selectionExpandToNode: (editor, node) => editor.startIsAtStartOfNode(node) && editor.endIsAtEndOfNode(node),

    isBeforeOf: (_, p1, p2) => { return p1 && p2 && PathUtils.isBefore(p1, p2) },

    isAfterOf: (_, p1, p2) => { return p1 && p2 && PathUtils.isAfter(p1, p2) },

    pointIsBeforeToNode: (editor, point, node) => {
      const nodePath = editor.pathByKey(node?.key)
      return nodePath && point ?
        point.isAtStartOfNode(node) || editor.isBeforeOf(point.path, nodePath) :
        false
    },

    pointIsAfterToNode: (editor, point, node) => {
      const nodePath = editor.pathByKey(node?.key)
      return nodePath && point ?
        point.isAtEndOfNode(node) || editor.isAfterOf(point.path, nodePath) :
        false
    },

    nodeIsFullySelected: (editor, node) => {
      return editor.pointIsBeforeToNode(editor.currentStart(), node) && editor.pointIsAfterToNode(editor.currentEnd(), node)
    },

    linesFullySelected: (editor) => {
      return editor.getLines().filter(line => editor.nodeIsFullySelected(line))
    },

    fullySelectedLineIds: editor => {
      const lines = editor.linesFullySelected()
      const ids = pipe(map(lineIdFromNode), filter(isNotNil))(lines)
      
      return ids.toJS()
    },
    
    // focus

    focusIsAtStartOfNode: (editor, node) => editor.currentFocus().isAtStartOfNode(node),
    focusIsAtEndOfNode: (editor, node) => editor.currentFocus().isAtEndOfNode(node),

    charRelativeToFocus: (editor, delta) => editor.currentTextNode().text[editor.currentFocus().offset + delta],
    charAtFocus: editor => editor.charRelativeToFocus(0),
    charBeforeFocus: editor => editor.charRelativeToFocus(-1),

    // anchor
    anchorIsAtStartOfNode: (editor, node) => editor.currentAnchor().isAtStartOfNode(node),
    anchorIsAtEndOfNode: (editor, node) => editor.currentAnchor().isAtEndOfNode(node),

    // start
    startIsAtStartOfNode: (editor, node) => editor.currentStart().isAtStartOfNode(node),
    startIsAtEndOfNode: (editor, node) => editor.currentStart().isAtEndOfNode(node),

    // end
    endIsAtStartOfNode: (editor, node) => editor.currentEnd().isAtStartOfNode(node),
    endIsAtEndOfNode: (editor, node) => editor.currentEnd().isAtEndOfNode(node),
  },

  //
  // Commands
  //

  commands: {

    restoreFocusByPath: (editor, previousJSFocus) => {
      // TODO: if the line its the same but change his format (dialogue <-> director) we should define a form of move "fine" the focus
      const { path, offset } = previousJSFocus

      if (!path || isEmpty(path)) return

      const node = editor.nodeByPath(path)
      if (node) {
        if (offset > node.text.length) {
          editor.moveFocusToEndOfNode(node)
        } else {
          editor.setFocus(previousJSFocus)
        }
        editor.collapse()
        editor.focus()
      } else {
        const parentPath = !!path && !isEmpty(path) ? slice(0, -1)(path) : path
        editor.restoreFocusByPath({ ...previousJSFocus, path: parentPath })
      }
    },

    restoreFocus: (editor, previousJSFocus, lineId) => {
      const line = editor.lineById(lineId)
      const path = line ? 
        [head(editor.pathByKey(line.key).toJS()), ...tail(previousJSFocus.path)] :
        editor.pathByKey(previousJSFocus.key)

      editor.restoreFocusByPath({ ...previousJSFocus, path })
    },

    collapse: editor => {
      const focus = editor.currentFocus()
      editor.setAnchor(focus.toJS())
    },

    collapseToStart: editor => {
      const selection = editor.currentSelection()
      editor.select(selection.setEnd(selection.start))
    },
    collapseToEnd: editor => {
      const selection = editor.currentSelection()
      editor.select(selection.setStart(selection.end))
    },

    moveToPath: (editor, path) => { editor.moveToEndOfNode(editor.blockByPath(path)) },

    // cursor interactions
    moveCursorToRight: moveCursorTo(goRight),
    moveCursorToLeft: moveCursorTo(goLeft),

    moveCursorToNextText: doWhileIsGhostText(editor => editor.moveToStartOfNextText()),
    moveCursorToPreviousText: doWhileIsGhostText(editor => editor.moveToEndOfPreviousText()),

    moveCursorOneWordToLeft(editor) { moveCursorOneWordToLeft(editor) },
    moveCursorOneWordToRight(editor) { moveCursorOneWordToRight(editor) },
    
    moveCursorToEndOfPreviousWord: moveBackwardWhilePrevChar(equals(BLANK)),
    moveCursorToStartOfCurrentWord: moveBackwardWhilePrevChar(pipe(equals(BLANK), not)),

    moveCursorToEndOfCurrentWord: moveForwardWhileChar(pipe(equals(BLANK), not)),
    moveCursorToStartOfNextWord: moveForwardWhileChar(equals(BLANK)),

    moveToParentBlock(editor) { editor.moveToEndOfNode(editor.currentNode()) },
    moveToNextNode(editor, path) { moveToNextNode(editor, path) },

    // focus interactions
    moveFocusToRight(editor) { moveFocusToRight(editor) },
    moveFocusToLeft(editor) { moveFocusToLeft(editor) },

    moveFocusToPreviousText: doWhileIsGhostText(editor => editor.moveFocusToEndOfPreviousText()),
    moveFocusToNextText: doWhileIsGhostText(editor => editor.moveFocusToStartOfNextText()),

    moveFocusOneWordToRight(editor) { moveFocusOneWordToRight(editor) },
    moveFocusOneWordToLeft(editor) { moveFocusOneWordToLeft(editor) },

    // 

    selectInRanges(editor, start, end) { selectInRanges(editor, start, end) },
    selectCompleteNode(editor, node) { selectCompleteNode(editor, node) },
    selectCurrentLine(editor) { editor.selectCompleteNode(editor.currentLineBlock()) },
    replaceCurrentText(editor, textValue) { replaceCurrentText(editor, textValue) },
    expandSelectionLeftWhile(editor, func) { expandSelectionLeftWhile(editor, func) },
    expandSelectionRightWhile(editor, func) { expandSelectionRightWhile(editor, func) },

  }

})

const isGhostByContainer = (editor, textNode) => {
  const { type } = editor.parent(textNode)
  const fn = GHOST_TEXT_BY_INLINE_CONTAINER[type]
  return fn && fn(editor, textNode)
}

const moveCursorOneWordToLeft = editor => {
  editor.collapse()
  if (editor.focusIsAtStartOfNode(editor.currentTextNode())) {
    editor.moveCursorToPreviousText()
  }

  const { text } = editor.currentTextNode()
  const { offset } = editor.value.selection.focus

  if (text[offset] === BLANK || text[offset - 1] === BLANK) {
    editor.moveCursorToEndOfPreviousWord()
  }

  editor.moveCursorToStartOfCurrentWord()
}

const moveToNextNode = (editor, path) => {
  const parentPath = editor.parentPath(path)
  const nextIndex = parentPath.last() + 1
  editor.moveToPath([...editor.parentPath(parentPath), nextIndex])
}

const moveCursorOneWordToRight = editor => {
  editor.collapse()
  if (editor.focusIsAtEndOfNode(editor.currentTextNode())) {
    editor.moveCursorToNextText()
  }

  const { text } = editor.currentTextNode()
  const { offset } = editor.value.selection.focus

  if (text[offset] === BLANK) {
    editor.moveCursorToStartOfNextWord()
  }

  editor.moveCursorToEndOfCurrentWord()
}

// focus

const moveFocusToRight = editor => {
  if (editor.focusIsAtEndOfNode(editor.currentTextNode())) {
    editor.moveFocusToNextText()
  }
  editor.moveFocusForward()
}

const moveFocusToLeft = editor => {
  if (editor.focusIsAtStartOfNode(editor.currentTextNode())) {
    editor.moveFocusToPreviousText()
  }
  editor.moveFocusBackward()
}

const moveFocusOneWordToRight = editor => {
  const { anchor } = editor.value.selection
  editor.moveCursorOneWordToRight()
  editor.setAnchor(anchor.toJS())
}

const moveFocusOneWordToLeft = editor => {
  const { anchor } = editor.value.selection
  editor.moveCursorOneWordToLeft()
  editor.setAnchor(anchor.toJS())
}

//

const expandSelectionLeftWhile = (editor, func) => {
  while (func() && editor.value.selection.start.offset > 0) {
    editor.moveStartTo(editor.value.selection.start.offset - 1)
  }
}

const expandSelectionRightWhile = (editor, func) => {
  const { text } = editor.currentTextNode()
  while (func() && editor.value.selection.end.offset < text.length) {
    editor.moveEndTo(editor.value.selection.end.offset + 1)
  }
}

const selectCompleteNode = (editor, node) => {
  editor.moveAnchorToEndOfNode(node)
  editor.moveFocusToStartOfNode(node)
}

const selectInRanges = (editor, start, end) => {
  editor.moveAnchorTo(start)
  editor.moveFocusTo(end)
}

const replaceCurrentText = (editor, textValue) => {
  const currentText = editor.currentTextNode().text
  editor.selectInRanges(0, currentText.length)
  editor.insertText(textValue)
}