import React from 'react'
import { prop, path, head, last } from 'ramda'
import { connect } from 'react-redux'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { makeSubchainSelector } from 'selectors/paths'

import { Direction } from '../CursorInfo'
import TextViewItem from '../TextViewItem'

const ChainTextView = ({ chain, moveToNextEditor, moveToPreviousEditor, ownCursorInfo }) => chain.map((childId, i) => (
  <TextViewItem
    key={childId}
    id={childId}

    cursorInfo={ownCursorInfo}
    moveToNextEditor={lineOffset => { moveToNextEditor(i, lineOffset) }}
    moveToPreviousEditor={lineOffset => { moveToPreviousEditor(i, lineOffset) }}
  />
))

export default compose(
  connect(() => {
    const chainSelector = makeSubchainSelector()
    return (state, props) => ({
      chain: chainSelector(state, props)
    })
  }),
  withState('ownCursorInfo', 'setOwnCursorInfo', prop('cursorInfo')),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { object, chain, setOwnCursorInfo, cursorInfo } = this.props

      // got focus from the outside
      const cursorAt = path(['cursorInfo', 'cursorAt'])
      if (cursorAt(prevProps) !== object.id && cursorAt(this.props) === object.id) {
        if (chain.length > 0) {
          setOwnCursorInfo({
            ...cursorInfo,
            cursorAt: (cursorInfo.direction === 'down' ? head : last)(chain)
          })
        }
      } else if (cursorAt(prevProps) !== cursorAt(this.props)) {
        // update if it comes from outside
        setOwnCursorInfo(this.props.cursorInfo)
      }
    }
  }),
  withHandlers({
    moveToNextEditor: ({ setOwnCursorInfo, chain, moveToNextEditor }) => (i, lineOffset) => {
      const newIndex = i + 1
      if (newIndex >= chain.length) {
        return moveToNextEditor(lineOffset)
      } else {
        setOwnCursorInfo({ cursorAt: chain[newIndex], lineOffset, direction: Direction.DOWN })
      }
    },
    moveToPreviousEditor: ({ setOwnCursorInfo, chain, moveToPreviousEditor }) => (i, lineOffset) => {
      const newIndex = i - 1
      if (newIndex < 0) {
        return moveToPreviousEditor(lineOffset)
      } else {
        setOwnCursorInfo({ cursorAt: chain[newIndex], lineOffset, direction: Direction.UP })
      }
    }
  })
)(ChainTextView)