import { pipe, propEq, curry, __, when, curryN, always, map, reverse, path, pathEq, anyPass } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { batch } from 'react-redux'
import debounce from 'lodash.debounce'

import { parseRef, ref, Sys, isNode, model } from 'beanie-engine-api-js'

import { isComplexObject, last, propFrom } from 'utils/object'

import { selectedObject, selectedObjects } from 'selectors/objects'
import { nodeSelection } from 'selectors/selections'
import { objectsIndex, objects } from 'selectors/apollo'

import { selectedNode, selectedNodes, simpleSelection, SIMPLE_SELECTION, rangeSelection, isMultipleSelection, selectionIds } from 'selectors/nodeSelection'

import { nodeTraverseCollectingIds, traverseToEnd, traverseToBeginning } from 'selections/graph'

import { selectAsMultiple, selectMultiple, addToSelection, removeFromSelection, clear } from 'actions/selection'
import { expandToViewNode } from 'actions/view'
import { scrollNodeIntoView } from 'actions/scrolling'

import { getNextNodeMovement } from 'utils/keyboard'
import Preferences from 'preferences/Preferences'
import { push } from 'connected-react-router'

import { focusForNode } from 'dom/dom'
import { pipeThunk } from 'utils/redux'

const { types: { object: { Paths } } } = model

const nodeId = node => (!node ? undefined : isComplexObject(node) ? node.id || node.nodeId : node)

//
// MOST BASIC OPERATIONS
//

export const SELECTION_ID = 'node'

/** most basic setter */
export const setNodeSelection = selectAsMultiple(SELECTION_ID)
export const setNodeSelections = selectMultiple(SELECTION_ID)

/** shortcut to #setNodeSelection to create a range */
export const setNodeSelectionWithRange = (startId, endId) => setNodeSelection(rangeSelection(startId, endId))

/** shortcut to #setNodeSelection to set a single id */
export const setNodeSelectionWithId = pipe(simpleSelection, setNodeSelection)

export const addRangeToNodeSelection = (startId, endId) => addToSelection(SELECTION_ID)(rangeSelection(startId, endId))

const removeSelectionElement = removeFromSelection(SELECTION_ID)

export const clearSelectedNodes = () => (d, getState) => {
  if (selectedNodes(getState()).length > 0) {
    batch(() => {
      d(clear(SELECTION_ID))
      d(push('.'))
    })
  }
}

//

const pushUrl = (id, dispatch) => {
  dispatch(push(`./${id || '.'}`))
}
const debouncedPushURL = debounce(pushUrl, 500, { trailing: true })

export const changeUrlToNodeId = (id, debouce = true) => dispatch => {
  (debouce ? debouncedPushURL : pushUrl)(id, dispatch)
}

//
// More complex actions
//

export const selectNode = (node, debounceUrlUpdate) => dispatch => {
  const id = nodeId(node)
  focusForNode(id)
  batch(() => {
    dispatch(focusViewInNode(id))

    dispatch(setNodeSelection(simpleSelection(id)))

    // we could have some sort of "debounce" here. Not exactly
    // The idea is that when you are moving too fast with the keyboard we don't want
    // to change the URL for each intermediate node that you passed on
    // because the URL triggers re-rendering some core containers.
    dispatch(changeUrlToNodeId(id, debounceUrlUpdate))
  })
}

export const focusViewInNode = id => dispatch => batch(() => {
  dispatch(expandToViewNode(id))
  dispatch(scrollNodeIntoView(id))
})

export const selectIfNotInSelection = node => (dispatch, getState) => {
  if (!selectedNodes(getState()).includes(nodeId(node))) {
    dispatch(selectNode(node))
  }
}

export const selectJumpTarget = pipeThunk(() => [
  selectedObject,
  path(Paths.jump.target),
  when(isNotNil, pipe(parseRef, selectNode))
])

export const selectNodes = pipeThunk(nodes => [
  always(nodes.map(nodeId)),
  map(simpleSelection),
  // reverse to let the focus on the first selection of the group
  reverse,
  setNodeSelections,
])

const withSelectedNodeAction = fn => pipeThunk(() => [
  selectedNode,
  when(isNotNil, fn)
])

export const focusOnSelectedNode = withSelectedNodeAction(id => scrollNodeIntoView(id, { forceInViews: [Preferences.GraphView.trackSelection] }))
export const forceFocusOnSelectedNode = withSelectedNodeAction(focusForNode)


export const onNodeSelected = (node, add) => {
  const id = !node ? undefined : isComplexObject(node) ? node.id : node
  return (add ? addToNodeSelection : selectNode)(id)
}

const addToNodeSelection = pipeThunk((nextId, keyboard) => [
  selectedObject,
  curry(addOrRemoveSelection)(__, nextId, keyboard)
])

const _dispatchWithNextNode = actionCall => pipeThunk(code => [
  selectedNode,
  curryN(2, getNextNodeMovement)(code),
  when(isNotNil, actionCall)
])
export const handleAddToSelectionKeys = _dispatchWithNextNode(nextId => addToNodeSelection(nextId, true))
export const handleMove = _dispatchWithNextNode(onNodeSelected)

export const addOrRemoveSelection = (node, nextId, keyboard) => (dispatch, getState) => {
  const nodes = selectedObjects(getState())
  const action = (nextId && nodes.some(propEq('id', nextId)) ?
    removeNodeFromSelection(keyboard ? node.id : nextId)
    : addNodeToSelection(nextId)
  )
  return dispatch(action)
}

export const addNodeToSelection = pipe(simpleSelection, addToSelection(SELECTION_ID))
export const removeNodeFromSelection = pipe(simpleSelection, removeFromSelection(SELECTION_ID))

const selectUpTo = getIds => (dispatch, getState) => {
  const state = getState()
  if (selectedNodes(state).length > 0) {
    const index = objectsIndex(state)
    const startObject = selectedObject(state)
    const ids = getIds(index)(startObject)
    const { id: startId } = startObject
    const endId = last(ids)
    if (ids.length > 0 && startId !== endId) {
      clearRepeatedElementsInSelection(dispatch, state)(startId, endId)
      dispatch(addRangeToNodeSelection(startId, endId))
    }
  }
}

export const selectToBeginning = () => selectUpTo(traverseToBeginning)
export const selectToEnd = () => selectUpTo(traverseToEnd)

const isConnectedTo = (id, index) => pipe(
  propFrom(index),
  anyPass([
    pathEq(Paths.node.parent, ref(id)),
    pathEq(Paths.node.child, ref(id))
  ])
)

const isEdge = _path => (ids, index) => id => {
  const value = path(_path, index[id])
  return !value || !ids.includes(parseRef(value))
}
export const isRangeStart = isEdge(Paths.node.parent)
export const isRangeEnd = isEdge(Paths.node.child)

export const packSelectionAsRange = () => (dispatch, getState) => {
  const state = getState()
  const index = objectsIndex(state)

  if (isMultipleSelection(state)) {
    // TODO: take to a selector
    const selections = nodeSelection(state)

    const checkIsContiguous = id => !!selectionIds(selections).find(isConnectedTo(id, index))

    const isContiguousSelection = selections.every(selection =>
      selectionIds([selection]).some(checkIsContiguous)
    )

    if (isContiguousSelection) {
      // pack
      const ids = selectedNodes(state)

      const packedSelection = rangeSelection(
        ids.find(isRangeStart(ids, index)),
        ids.find(isRangeEnd(ids, index)),
      )
      // change selection to the newly created range
      dispatch(setNodeSelection(packedSelection))
    }
  }
}

export const addRangeToSelection = rangeEndNodeId => (dispatch, getState) => {
  const state = getState()
  if (selectedNodes(state).length > 0) {
    const index = objectsIndex(state)

    const startObject = selectedObject(state)
    const endObject = index[rangeEndNodeId]

    const ids = nodeTraverseCollectingIds(index)(startObject, endObject)

    if (ids.length > 0) {
      clearRepeatedElementsInSelection(dispatch, state)(startObject.id, endObject.id)
      dispatch(addRangeToNodeSelection(startObject.id, endObject.id))
    }
  }
}

const clearRepeatedElementsInSelection = (dispatch, state) => (...ids) => {
  const selectedElements = state.selection.node
  const repeatedSelection = selectedElements.find(e =>
    e.type === SIMPLE_SELECTION && (ids.includes(e.id))
  )

  if (repeatedSelection) dispatch(removeSelectionElement(repeatedSelection))
}

// ~UTILS: Traversing graph nodes from apollo


// SELECT VISIBLE NODE
// TODO: this could be generic by using the schema information

/*
 * Given a selection it selects the appropiated "visible" object.
 * For example if the given object is a language_resource it will select
 * the clip that contains a line which contains a take that has that language resource.
 */
export const selectVisibleObject = pipeThunk(id => [
  objects,
  findVisibleContainerOf(id),
  node => selectNode(node, false)
])

const findVisibleContainerOf = id => allObjects => {
  const object = allObjects.find(propEq('id', id))
  return isNode(object.sys) ? object : findVisibleContainer(object, allObjects)
}

export const findVisibleContainer = (object, allObjects) => {
  let lookOnSyses;
  switch (object.sys) {
    case Sys.language_resource: lookOnSyses = [Sys.take]; break;
    case Sys.take: lookOnSyses = [Sys.line]; break;
    case Sys.line: lookOnSyses = [Sys.clip, Sys.choice]; break;
    default: return undefined;
  }
  const referencing = findObjectReferencing(lookOnSyses, object.id, allObjects)

  if (!referencing) return undefined

  return isNode(referencing.sys) ? referencing : findVisibleContainer(referencing, allObjects)
}

const findObjectReferencing = (fromSyses, id, allObjects) => {
  const reference = ref(id)
  return Object.values(allObjects).find(
    o => fromSyses.includes(o.sys) && hasReferenceTo(o, reference)
  )
}

const hasReferenceTo = (o, reference) => {
  const arrayDataHasRef = name => o.data[name].includes(reference)
  switch (o.sys) {
    case Sys.take: return Object.values(o.data.locales).includes(reference)
    case Sys.line: return arrayDataHasRef('takes')
    case Sys.clip: return arrayDataHasRef('lines')
    case Sys.choice: return o.data.line === reference
    default: return false
  }
}
