import { pipe, map, curry, __, propEq } from 'ramda'
import { promiseTimeout } from 'utils/promises'
import { loadEngineWhenLoadingProject } from 'model/features'
import winston from 'utils/logger'

const DEFAULT_TASK_TIMEOUT = 120 /* seconds */ * 1000
const NO_TIMEOUT = 'NO_TIMEOUT'

export const Tasks = {
  CREATE_SESSION: { type: 'CREATE_SESSION', description: 'Establishing Session' },
  FETCH: { type: 'FETCH', description: 'Fetching', runAlways: true },
  DATA: { type: 'DATA', description: 'Project Data', timeout: NO_TIMEOUT },
  LOAD_VM: { type: 'LOAD_VM', description: 'Initializing BNE VM', timeout: NO_TIMEOUT },
  LOAD_PROJECT: { type: 'LOAD_PROJECT', description: 'Loading Project', timeout: NO_TIMEOUT },
  RESTORE_STATE: { type: 'RESTORE_STATE', description: 'Restoring State' },
  CLEAR_VM: { type: 'CLEAR_VM', description: 'Clearing BNE VM' },
  LOAD_REVISION: { type: 'LOAD_REVISION', description: 'Loading Revision' },
  EXEC_EXT_ON_LOAD: { type: 'EXEC_EXT_ON_LOAD', description: 'Executing extensions on load' }
}

export const TaskState = {
  waiting: 'waiting',
  running: 'running',
  completed: 'completed',
  error: 'error'
}

export const Jobs = {
  LOAD_PROJECT: {
    name: 'Load Project',
    tasks: loadEngineWhenLoadingProject ?
      [Tasks.CREATE_SESSION, Tasks.FETCH, Tasks.DATA, Tasks.LOAD_VM, Tasks.LOAD_PROJECT, Tasks.RESTORE_STATE, Tasks.EXEC_EXT_ON_LOAD]
      : [Tasks.CREATE_SESSION, Tasks.FETCH, Tasks.DATA, Tasks.RESTORE_STATE],
  },
  LOAD_PROJECT_BONES: {
    name: 'Load Project Bones',
    tasks: [Tasks.CREATE_SESSION, Tasks.FETCH, Tasks.RESTORE_STATE]
  },
  LOAD_REVISION: {
    name: 'Load Revision',
    tasks: [Tasks.CLEAR_VM, Tasks.LOAD_REVISION]
  },
  RECREATE_REVISION_SESSION: {
    name: 'Reconnecting',
    tasks: [Tasks.CREATE_SESSION],
  }
}

export const isTaskInState = propEq('state')
export const isCompleted = isTaskInState(TaskState.completed)
export const isLoading = isTaskInState(TaskState.loading)

export const CREATE_JOB = 'CREATE_JOB'
export const createJob = job => ({ type: CREATE_JOB, job })
export const task = (t, state = TaskState.waiting) => ({ type: t.type, state })

export const createJobAction = pipe(({ tasks, ...others }) => ({
  ...others,
  tasks: map(curry(task)(__, TaskState.waiting))(tasks)
}), createJob)

export const UPDATE_TASK_STATE = 'UPDATE_TASK'
export const updateTaskState = (taskType, newState, payload) => ({
  type: UPDATE_TASK_STATE,
  taskType,
  newState,
  ...(payload && { payload })
})

export const UPDATE_TASK_PROGRESS = 'UPDATE_TASK_PROGRESS'
export const updateTaskProgress = (taskType, progress) => ({
  type: UPDATE_TASK_PROGRESS,
  taskType,
  progress,
})

export const runTask = (dispatch, getState) => async (t, fn) => {
  try {
    dispatch(updateTaskState(t.type, TaskState.running))
    const r = t.timeout === NO_TIMEOUT ? await fn(dispatch, getState) : await promiseTimeout(t.timeout || DEFAULT_TASK_TIMEOUT, fn(dispatch, getState))
    dispatch(updateTaskState(t.type, TaskState.completed))
    return r
  } catch (error) {
    winston.error(error)
    dispatch(updateTaskState(t.type, TaskState.error, { error: error.message }))
    throw new Error(`Error while running task ${t.type}: ${error}`)
  }
}
