exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".choiceBlock__34FxC{padding-bottom:1rem;padding-left:1rem}.isDisabled__h0dos{color:#d3d3d3}", ""]);

// exports
exports.locals = {
	"choiceBlock": "choiceBlock__34FxC",
	"isDisabled": "isDisabled__h0dos"
};