import React from 'react'
import { connect } from 'react-redux'
import { sortBy, path, pipe, map, reject, propEq, toUpper } from 'ramda'
import { Select } from 'antd'

import { actors as actorsSelector } from 'selectors/objects'

const { Option } = Select


const ActorSelector = ({ setActorId, skipActor, actors }) => (
  <Select
    showSearch
    style={{ width: '200px' }}
    placeholder="Select an actor"
    onChange={actorId => setActorId(actorId)}
    optionFilterProp="children"
    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
  >
    {
      pipe(
        reject(propEq('id', skipActor.id)),
        sortBy(pipe(path(['data', 'actorName']), toUpper)),
        map(anActor => (
          <Option key={anActor.id} value={anActor.id}>{anActor.data.actorName}</Option>
        ))
      )(actors)
    }
  </Select>
)

export default connect(state => ({
  actors: actorsSelector(state)
})
)(ActorSelector)