import { truncateFraction } from 'utils/math'
import calculateTimeToPlay from './calculateTimeToPlay'
import splitText from './splitText'

/**
 * Does the text splitting needed to display captions in screen.
 */
const splitLanguageResource = (maxLength = 60) => ({ data: { text, time } }) => {
  const totalTime = time && time > 0 ? time : calculateTimeToPlay(text)
  return splitText(maxLength, text)
    .map(chunk => ({
      text: chunk,
      time: truncateFraction(3)((chunk.length * totalTime) / text.length)
    }))
}

export default splitLanguageResource