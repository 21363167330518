import { createSelector } from 'selectors/reselect'
import { pathOr } from 'ramda'
import { EMPTY_OBJECT, EMPTY_ARRAY } from 'utils/object'

export const isUnderSync = state => state.sync.operations && state.sync.operations.length > 0

export const objectsIndex = pathOr(EMPTY_OBJECT, ['project', 'revision', 'objects'])

/* @Deprecated: think about using objectsIndex which is indexed ! */
export const objects = createSelector('objects',
  [objectsIndex],
  index => (index ? Object.values(index) : EMPTY_ARRAY)
)