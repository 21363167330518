import { times } from 'ramda'
import { addElementWithClip } from '../../templates'

/**
 * Creates a choices2 with an idle clip and the specified nr of choices
 */
const createTimedChoiceTemplate = nrOfChoice => api => {
  const choices = api.choices2.new()

  const addChoice = addElementWithClip(api, () => choices.add_choice())
  times(addChoice, nrOfChoice)

  const idleClip = api.clip.new()
  choices.set_idle(idleClip)

  return choices
}

export default createTimedChoiceTemplate