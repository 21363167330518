import { model } from 'beanie-engine-api-js'
import { OBJECT_TYPES } from '../../Constants'
import { markupNameNormalize } from '../normalizers/markUp'

const { types: { markup: { MARKUP_NAME_INPUT_PATTERN } } } = model

export default {
  nodes: [
    {
      match: { object: OBJECT_TYPES.text, text: MARKUP_NAME_INPUT_PATTERN }
    }
  ],
  normalize: markupNameNormalize
}