import React, { useCallback, useEffect, useState } from 'react'
import { propOr, countBy } from 'ramda'
import classNames from 'classnames'
import { Table, Icon } from 'antd'
import { ChangeType, changeType } from 'beanie-engine-api-js'
import { compose, onlyUpdateForKeys } from 'recompose'
import { EMPTY_ARRAY } from 'utils/object'

import ShortId from 'components/Commons/ShortId'
import { filterChanges } from '../../model/ChangeSet'
import ChangeSetInfo from './ChangeSetInfo'

import Change from './Change'

import styles from './ChangeSetPanel.scss'
import ChangesFilter from './ChangesFilter/ChangesFilter'

const { Column } = Table

const iconsByChangeType = {
  [ChangeType.UPDATED]: 'edit',
  [ChangeType.ADDED]: 'plus-circle-o',
  [ChangeType.DELETED]: 'minus-circle-o'
}

const changesFromChangeSet = propOr(EMPTY_ARRAY, 'changes')

const ChangeSetPanel = ({ changeSet }) => {
  const [showEmpties, setShowEmpties] = useState(true)
  const [showTimestamps, setShowTimestamps] = useState(true)
  const [searchText, setSearchText] = useState('')

  const [visibleChanges, setVisibleChanges] = useState(changesFromChangeSet(changeSet))

  const onSearch = useCallback(() => {
    setVisibleChanges(filterChanges(searchText, changesFromChangeSet(changeSet)))
  }, [changeSet, searchText])

  useEffect(() => {
    onSearch()
  }, [changeSet])

  return (
    <div className={styles.changeSetPanel}>
      <ChangeSetInfo changeSet={changeSet} />
      <ChangesFilter {...{ showEmpties, setShowEmpties, showTimestamps, setShowTimestamps, searchText, setSearchText, onSearch }} />
      <Table
        dataSource={visibleChanges}
        size="small"
        footer={renderFooter(visibleChanges)}
        className={styles.changeSetTable}
        rowKey={(change, index) => index}
      >
        <Column
          title="Node"
          key="node"
          className={styles.nodeColumn}
          render={change => (
            <ShortId id={change.id || (change.added && change.added.id) || (change.deleted.id)} />
          )}
        />
        <Column
          title="Type"
          key="type"
          className={styles.typeColumn}
          render={change => {
            const type = changeType(change)
            return <div className={classNames(styles.changeIcon, styles[type])}><Icon type={iconsByChangeType[type]} /></div>
          }}
        />
        <Column
          title="Detail"
          key="detail"
          className={styles.detailColumn}
          render={change => <Change change={change} showEmpties={showEmpties} showTimestamps={showTimestamps} />}
        />
      </Table>
    </div>
  )
}

const renderFooter = changes => () => {
  const aggregates = countBy(changeType)(changes)
  return (
    <div>{changes.length} changes ({aggregates[ChangeType.ADDED] || 0} added, {aggregates[ChangeType.DELETED] || 0} deleted, {aggregates[ChangeType.UPDATED] || 0} updated)</div>
  )
}

export default compose(
  onlyUpdateForKeys(['changeSet']),
)(ChangeSetPanel)