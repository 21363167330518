import React from 'react'
import { tryCatch } from 'ramda'
import { notification } from 'antd'

import { importState } from 'utils/state/importExport'
import { isJsonParseError } from 'utils/exceptions'

import Button from 'components/Commons/Button'
import FileChooser from 'components/Commons/FileChooser'
import { ModalConsumer } from 'components/Commons/Modal'

const notifyError = e => {
  const detail = isJsonParseError(e) ? 'The selected file is not in correct JSON format.' : ''
  notification.error({
    message: 'Error Importing State File',
    description: `Could not import state from file. ${detail}`
  })
}

const StateFileChooserButton = ({ modal, description }) => (
  <ModalConsumer key="state-control-modal">
    { ({ showModal }) => (
      <FileChooser key="upload" onFileSelected={tryCatch(importState(showModal, modal), notifyError)}>
        { onClick => <Button key="icon" type="upload" description={description} onClick={onClick} /> }
      </FileChooser>
    )}
  </ModalConsumer>
)

export default StateFileChooserButton
