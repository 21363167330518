exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__s9MmX{color:#add8e6;cursor:pointer}.iconAsLink__s9MmX:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__gqaHW{font-style:italic}.formFieldsVerticalGutter__1b65P{padding:.5rem}.formFieldsVerticalGutter__1b65P>*{margin:.5rem !important}.tableBackground__31cCU .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__31cCU .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__3t9p5 .ant-notification-notice-close{display:none}.searchBox__1HLvD{padding-left:1em;padding-right:1em;width:200px}.searchBox__1HLvD .ant-input{border:none;background:#fff;border-radius:15px}.searchBox__1HLvD .ant-input:hover,.searchBox__1HLvD .ant-input:focus{border:1px solid #7b61ca !important}.options__2iprt{-webkit-box-shadow:2px 4px 8px rgba(0,0,0,.5);box-shadow:2px 4px 8px rgba(0,0,0,.5)}.option__3HLfb{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.option__3HLfb .anticon{padding-right:.5em}.option__3HLfb>:nth-child(2){-ms-flex-positive:1;flex-grow:1}.option__3HLfb .SearchObjectContextInfo__4AyVe{color:#969696;font-style:italic;font-size:.8rem}.dropdownInlineContainer__X-okG>div{position:relative !important}.dropdownInlineContainer__X-okG>div .ant-select-dropdown{-webkit-box-shadow:none;box-shadow:none;position:relative !important;top:unset !important;left:unset !important;width:100% !important}.MatchResultText__27Bi6 .matchToken__vK_9d{font-weight:bold}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__s9MmX",
	"labelFromRef": "labelFromRef__gqaHW",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1b65P",
	"tableBackground": "tableBackground__31cCU",
	"notificationNotClosable": "notificationNotClosable__3t9p5",
	"searchBox": "searchBox__1HLvD",
	"options": "options__2iprt",
	"option": "option__3HLfb",
	"SearchObjectContextInfo": "SearchObjectContextInfo__4AyVe",
	"dropdownInlineContainer": "dropdownInlineContainer__X-okG",
	"MatchResultText": "MatchResultText__27Bi6",
	"matchToken": "matchToken__vK_9d"
};