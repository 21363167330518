import { always } from 'ramda'
import { NODE_TYPES } from 'components/SlateTextEditor/utils/Constants'

export const NOTES = {
  Performance: {
    name: 'Performance',
    type: NODE_TYPES.PERFORMANCE_NOTE
  },
  Production: {
    name: 'Production',
    type: NODE_TYPES.PRODUCTION_NOTE
  }
} 

export const noteProposals = always([NOTES.Performance, NOTES.Production])