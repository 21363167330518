import React from 'react'
import { connect } from 'react-redux'
import { focusOnSelectedNode } from 'actions/nodes'

import IconButton from 'components/Commons/IconButton'

import styles from './FocusCursor.scss'

const FocusCursor = ({ focusOnSelected }) => (
  <div className={styles.focus}>
    <IconButton tooltip="Focus in last selected node" type="scan" onClick={focusOnSelected} />
  </div>
)

export default connect(undefined, {
  focusOnSelected: focusOnSelectedNode
})(FocusCursor)
