import React from 'react'
import classNames from 'classnames'
import LinkButton from 'components/Commons/LinkButton'
import { withHandlers } from 'recompose'
import { isFunction } from 'utils/object'

const isActive = (activeChild, child) => activeChild && (child.props.id === activeChild.props.id)

const ViewTitles = ({ children, styles, ...others }) => (
  <div className={styles.tabs}>
    {React.Children.map(children, child => child && (
      <ViewTitleLink child={child} styles={styles} {...others} />
    ))}
  </div>
)

const _ViewTitleLink = ({ child, styles, itemStyle, activeChild, onClick }) => {
  let title
  if (child.props.pane.title && isFunction(child.props.pane.title)) {
    const Title = child.props.pane.title
    title = <Title />
  }
  return (
    <LinkButton 
      key={child.props.id}
      className={classNames(styles.viewTitle, itemStyle, { 
        [styles.current]: isActive(activeChild, child) }
      )}
      disabled={isActive(activeChild, child)}
      onClick={onClick}>
      {title || child.props.pane.title || child.props.pane.id}
    </LinkButton>
  )
}

const ViewTitleLink = withHandlers({
  onClick: ({ selectView, child, maximizeOnClick = false }) => () => selectView(child.props.id, maximizeOnClick)
})(_ViewTitleLink)

export default ViewTitles