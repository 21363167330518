import React from 'react'
import IconButton from 'components/Commons/IconButton'

const Controls = ({ className, onClose, controls }) => (
  <div className={className}>
    {controls}
    <IconButton type="close" onClick={onClose} />
  </div>
)

export default Controls