import { Sys } from 'beanie-engine-api-js'
import { assoc, propEq, reduce, find } from 'ramda'
import folderChecks from './folder'
import truthTableChecks from './truth_table'
import timerChecks from './timer'
import conditionalChecks from './conditional'
import conditionChecks from './condition'
import jumpChecks from './jump'
import nodeChecks from './node'
import rowChecks from './row'
import factChecks from './fact'

export const CHECK_RESULT_STATUS = {
  OK: 'OK',
  ERROR: 'ERROR'
}

export const CHECK_SEVERITY_LEVEL = {
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR'
}

export const error = (id, message) => ({
  id,
  message,
  status: CHECK_RESULT_STATUS.ERROR,
  severity: CHECK_SEVERITY_LEVEL.ERROR,
})
export const warning = (id, message) => ({
  id,
  message,
  status: CHECK_RESULT_STATUS.ERROR,
  severity: CHECK_SEVERITY_LEVEL.WARNING,
})

export const OK_RESULT = { status: CHECK_RESULT_STATUS.OK }

export const COMMON_NODE_CHECKS = nodeChecks
export const CHECKS_BY_SYS = {
  [Sys.jump]: jumpChecks,
  [Sys.conditional]: conditionalChecks,
  [Sys.condition]: conditionChecks,
  [Sys.timer]: timerChecks,
  [Sys.truth_table]: truthTableChecks,
  [Sys.truth_table_row]: rowChecks,
  // [Sys.action]: [], // increment/decrement a non variable number
  [Sys.folder]: folderChecks,
  [Sys.fact]: factChecks
}

export const ALL_PREFERENCE_CHECKS = [
  {
    type: 'node',
    checks: nodeChecks
  },
  {
    type: Sys.jump,
    checks: jumpChecks
  },
  {
    type: Sys.conditional,
    checks: conditionalChecks
  },
  {
    type: Sys.condition,
    checks: conditionChecks
  },
  {
    type: Sys.timer,
    checks: timerChecks
  },
  {
    type: Sys.truth_table,
    checks: truthTableChecks
  },
  {
    type: Sys.truth_table_row,
    checks: rowChecks
  },
  {
    type: Sys.folder,
    checks: folderChecks
  },
  {
    type: Sys.fact,
    checks: factChecks
  }
]

export const allChecksOfType = type => (type === 'node' ? COMMON_NODE_CHECKS : CHECKS_BY_SYS[type])
export const checkByTypeAndName = (type, name) => find(propEq('name', name), allChecksOfType(type))

export const INITIAL_CHECKS_CONFIG = () => reduce((acc, elem) => assoc(elem.type, {
  enabled: true, // true | false | 'INTERM'
  checks: reduce((acc2, { name }) => assoc(name, true, acc2), {}, elem.checks)
}, acc), {}, ALL_PREFERENCE_CHECKS)

// {
//   node: {
//     enabled: true/false
//     checks: {
//       name1: true/false,
//        ...
//       nameN: true/false,
//      }
//   }
// }
