import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withState, withProps } from 'recompose'
import { head, identity, isEmpty } from 'ramda'
import { Input, Checkbox, Alert } from 'antd'

import { updateQueryValue, pushTransform } from 'graphql/updates'
import { proposeNextCheckpoint } from 'utils/projectVersion'
import { input } from 'utils/graphql'
import { withTargetValue } from 'utils/antd'
import { EMPTY_STRING } from 'utils/string'

import projectBranchesQuery from 'api/queries/projectBranches.graphql'
import projectCreateBranchMutation from 'api/mutations/projectCreateBranch.graphql'

import openRevisionAction from '../../actions/project/openRevision'

import { revisionVersion, revisionId, projectId as projectIdSelector, currentBranchId as currentBranchIdSelector } from 'selectors/project'

import HelpIcon from 'components/Commons/HelpIcon'
import CreateModal from 'components/Commons/CreateModal'

import styles from './MakeCheckpoint.scss'

const MakeBranchModal = ({ checkpointName, checkpointNameChanged, isBranch, hide, currentVersion, handleMakeBranch, branchName, branchNameChanged, navigateToBranch, setNavigateToBranch }) => (
  <CreateModal
    title="Make a new Version"
    submit={handleMakeBranch}
    canSubmit={!isEmpty(branchName)}
    hide={hide}
  >
    {onCreate => (
      <div className={styles.modalContent}>
        <div className={styles.modalRow}>
          <span className={styles.rowTitle}>Name</span>
          <Input className={styles.input} placeholder="Version name" value={branchName} onChange={branchNameChanged} onPressEnter={onCreate} />
        </div>
        <div className={styles.centeredTitle}>From</div>
        <div className={styles.modalRow}>
          <span className={styles.rowTitle}>Version</span>
          <span className={styles.version}>{currentVersion}</span>
        </div>
        <div className={styles.modalRow}>
          {isBranch ? 
            newCheckpointInput(checkpointName, checkpointNameChanged, onCreate) :
            showCurrentCheckpointLabel(checkpointName)}
        </div>
        {isBranch && <div className={styles.modalRow}>
          <span className={styles.rowTitle}><Alert message="We will create a checkpoint as starting point" type="info" /></span>
        </div>}
        <div className={styles.modalRow}>
          <div className={styles.openRightAway}><Checkbox checked={navigateToBranch} onChange={withTargetValue(setNavigateToBranch)} /> Open right away</div>
        </div>
      </div>
    )}
  </CreateModal>
)

const newCheckpointInput = (checkpointName, checkpointNameChanged, onCreate) => (<Fragment>
  <span className={styles.rowTitle}>New Checkpoint</span>
  <Input className={styles.input} placeholder="Checkpoint name" value={checkpointName} onChange={checkpointNameChanged} onPressEnter={onCreate} />
  <span className={styles.helpIcon}><HelpIcon message="Making a version requires starting it from a checkpoint" /></span>
</Fragment>)

const showCurrentCheckpointLabel = (checkpointName) => (<Fragment>
  <span className={styles.rowTitle}>Checkpoint</span>
  <span className={styles.version}>{checkpointName}</span>
</Fragment>)

const handleMakeBranch = ({ createBranch, branchName }) => () => createBranch(branchName)

export default compose(
  connect(state => ({
    currentCheckpointName: revisionVersion(state),
    currentBranchId: currentBranchIdSelector(state),
    sourceCheckpoint: revisionId(state),
    projectId: projectIdSelector(state)
  }), {
    openRevision: openRevisionAction
  }),
  withProps(({ checkpointsQuery }) => ({
    previousCheckpoint: checkpointsQuery.loading || checkpointsQuery.error ? undefined : head(checkpointsQuery.branchCheckpoints)
  })),
  withState('branchName', 'setBranchName', EMPTY_STRING),
  withState('checkpointName', 'setCheckpointName', ({ isBranch, currentCheckpointName, previousCheckpoint }) =>
    (isBranch ? proposeNextCheckpoint(previousCheckpoint) : currentCheckpointName)),
  withState('navigateToBranch', 'setNavigateToBranch', true),
  graphql(projectCreateBranchMutation, { name: 'projectCreateBranch' }),
  withHandlers({
    branchNameChanged: ({ setBranchName }) => withTargetValue(setBranchName),
    checkpointNameChanged: ({ setCheckpointName }) => withTargetValue(setCheckpointName),
    createBranch: ({ checkpointsQuery, currentBranchId, isBranch, checkpointName, projectCreateBranch, sourceCheckpoint, projectId, navigateToBranch, openRevision }) => async branchName => {
      const createBranchInput = {
        branchName,
        ...(isBranch ?
          { checkpointName, projectId, sourceBranch: currentBranchId } :
          { sourceCheckpoint })
      }

      const { data: { projectCreateBranch: { _id: createdRevisionId } } } = await projectCreateBranch(
        input(
          createBranchInput,
          identity,
          {
            update: updateQueryValue(
              {
                query: projectBranchesQuery,
                variables: { projectId }
              },
              pushTransform(['projectWithId', 'branches'], 'projectCreateBranch')
            )
          }
        )
      )

      if (isBranch && checkpointsQuery) {
        checkpointsQuery.refetch({ branchId: currentBranchId })
      }

      if (navigateToBranch) {
        try {
          await openRevision(createdRevisionId)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error loading revision', error)
          throw error
        }
      }
    }
  }),
  withHandlers({ handleMakeBranch })
)(MakeBranchModal)
