import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import Recorder from 'react-mp3-recorder'
import classNames from 'classnames'
import { message, Tooltip } from 'antd'
import { setRecordedAudioForLine as setRecordedAudioForLineAction } from 'engine/actions/lines'
import styles from './RecordLineButton.scss'

const _RecordAudio = ({ className, finishedRecording, handleRecordingError }) => {
  const [recording, setRecording] = useState()

  return (
    <div
      className={classNames(styles.RecordLineButton, className)}
      role="button"
      tabIndex="0"
      onMouseDown={() => setRecording(true)}
      onMouseUp={() => setRecording(false)}
    >
      <Tooltip title="Hold the button to record the line's audio">
        <Recorder
          className={classNames(styles.micButton, { [styles.recording]: recording })}
          onRecordingComplete={finishedRecording}
          onRecordingError={handleRecordingError}
        />
      </Tooltip>
    </div>
  )
}

export default compose(
  connect(null, { setRecordedAudioForLine: setRecordedAudioForLineAction }),
  withHandlers({
    finishedRecording: ({ lineId, setRecordedAudioForLine }) => async blob => {
      await setRecordedAudioForLine(lineId, blob)
    },
    handleRecordingError: () => err => {
      message.error(<div>Oops ! there was a problem recording the audio!</div>)
      // eslint-disable-next-line no-console
      console.error('Error during recording', err)
    }
  })
)(_RecordAudio)
