import { useCallback, useState } from 'react'
import useIsMountedRef from './useIsMountedRef'

/**
 * Similar to useState() but whose setter function won't do anything
 * if the component was already unmounted. Avoiding the annoying react error
 * about calling setState once unmounted due to async callbacks
 */
const useStateWithMountCheck = (...args) => {
  const isMountedRef = useIsMountedRef()
  const [state, originalSetState] = useState(...args)

  const setState = useCallback((..._args) => {
    if (isMountedRef.current) {
      originalSetState(..._args)
    }
  }, [isMountedRef, originalSetState])

  return [state, setState]
}

export default useStateWithMountCheck