import React from 'react'
import { Table } from 'antd'

import UserAvatar from 'components/RevisionSessions/UserAvatar'
import OrganizationMemberActions from './OrganizationMemberActions'

import styles from './OrganizationMembersTable.scss'
import SectionElementsTable from '../Section/SectionElementsTable/SectionElementsTable'

const { Column } = Table

const OrganizationMembersTable = ({ data, pageSize, onPageChanged, refreshItems, handleTableChange }) => (
  <div className={styles.membersTable}>
    <SectionElementsTable
      data={data}
      dataPath={['organization', 'members']}
      pageSize={pageSize}
      onPageChanged={onPageChanged}
      handleTableChange={handleTableChange}
      refreshItems={refreshItems}
      actions={member => <OrganizationMemberActions organization={data.organization} member={member} refreshItems={refreshItems} />}
      rowKeyPath="user.email"
    >
      <Column
        title="User"
        key="user.firstName"
        sorter
        render={member => (
          <div className={styles.userColumn}>
            <UserAvatar user={member.user} contextMenuEnabled={false} />
            <span>{member.user.firstName} {member.user.lastName}</span>
          </div>
        )}
      />
      <Column
        title="Role"
        sorter
        key="role"
        dataIndex="role"
        render={role => `Org ${role}`}
      />
    </SectionElementsTable>
  </div>
)

export default OrganizationMembersTable