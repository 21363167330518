import { useEffect } from 'react'

export default (event, callback) => {
  useEffect(
    () => {
      const onLoad = () => {
        // lead call onLoad maybe this should be optional but it's needed for onclipboardchange
        // so that you have the latest clipboard when you start and load the page (focus is not called until you are out and in)
        callback()
        window.addEventListener(event, callback)
      }
      window.addEventListener('load', onLoad)
      return () => {
        window.removeEventListener('load', onLoad)
        window.removeEventListener(event, callback)
      }
    },
    [event, callback]
  )
}
