import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers, withState } from 'recompose'
import pluralize from 'pluralize'

import { Modal, Icon } from 'antd'
import deleteActorWithLines from 'engine/actions/actor/deleteActorWithLines'

const DeleteActorModal = ({ onFinish, visible, actor, lines, confirmLoading, handleDelete }) => {
  if (!visible) return null

  const numberOfLines = lines[actor.id]
  return (
    <Modal
      title={`Delete Actor: ${actor.data.actorName}`}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleDelete}
      onCancel={onFinish}
      okType="danger"
      okText="Confirm"
      destroyOnClose
    >
      { numberOfLines > 0 && 
        <h4>
          <Icon type="warning" /> {actor.data.actorName} has <strong>{numberOfLines} {pluralize('line', numberOfLines)}</strong> that will be deleted !
        </h4>
      }
      <h4> { `Confirm you want to delete ${actor.data.actorName}` } </h4>
    </Modal>
  )
}

export default compose(
  withState('confirmLoading', 'setConfirmLoading'),
  connect(null, {
    deleteActorWithLinesAction: deleteActorWithLines
  }),
  withHandlers({
    handleDelete: ({ setConfirmLoading, onFinish, deleteActorWithLinesAction, actor }) => async () => {
      setConfirmLoading(true)
      await deleteActorWithLinesAction(actor.id)
      onFinish()
      setConfirmLoading(false)
    }
  }),
)(DeleteActorModal)