exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2agxV{color:#add8e6;cursor:pointer}.iconAsLink__2agxV:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3FUwN{font-style:italic}.formFieldsVerticalGutter__2LWX5{padding:.5rem}.formFieldsVerticalGutter__2LWX5>*{margin:.5rem !important}.tableBackground__1qMIc .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1qMIc .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__3SUc0 .ant-notification-notice-close{display:none}.container__1r0LX{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-webkit-box-shadow:2px 2px 2px #d2d2d3;box-shadow:2px 2px 2px #d2d2d3;background:#fff}.container__1r0LX .ant-input{border-radius:20px}.container__1r0LX .filters__vcPPy{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:distribute;justify-content:space-around;background:#f0f2f5;padding-left:.5em;padding-right:.5em;padding-bottom:.5em;padding-top:.5em}.container__1r0LX .filters__vcPPy>div:nth-child(1){width:200px}.container__1r0LX .filters__vcPPy>input{width:30%}.container__1r0LX .filters__vcPPy>div:nth-child(3),.container__1r0LX .filters__vcPPy>div:nth-child(4){width:7em}.container__1r0LX table{padding:0px !important}.container__1r0LX table th{background:#f7f7f7 !important;font-weight:500}.container__1r0LX table tr{line-height:initial !important}.container__1r0LX table .ant-table-row{cursor:pointer}.container__1r0LX table .selectedRow__Fq0Tt{font-weight:bolder}.container__1r0LX table .idColumn__3qOYD{width:15%}.container__1r0LX table .sysColumn__32u5O{width:20%}.container__1r0LX table .textColumn__1mySK{width:65%}.container__1r0LX table .actionsColumn__1HZBj{font-weight:normal}.ObjectActions__3dUXD{display:-ms-flexbox;display:flex}.ExtensionPoint__1F9xx{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2agxV",
	"labelFromRef": "labelFromRef__3FUwN",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__2LWX5",
	"tableBackground": "tableBackground__1qMIc",
	"notificationNotClosable": "notificationNotClosable__3SUc0",
	"container": "container__1r0LX",
	"filters": "filters__vcPPy",
	"selectedRow": "selectedRow__Fq0Tt",
	"idColumn": "idColumn__3qOYD",
	"sysColumn": "sysColumn__32u5O",
	"textColumn": "textColumn__1mySK",
	"actionsColumn": "actionsColumn__1HZBj",
	"ObjectActions": "ObjectActions__3dUXD",
	"ExtensionPoint": "ExtensionPoint__1F9xx"
};