import { model, Sys } from 'beanie-engine-api-js'
import { OneToManyOperations } from '../../Operations'

const { types: { object: { Paths } } } = model

// TODO: ideally we will have this as a definition in the schema that specifies which LUA method to call
//   to manipulate the relationship (like: add, remove elements)
const OneToManyCustomMutations = {

  [Sys.truth_table]: {

    [Paths.truth_table.rows.join('.')]: {

      [OneToManyOperations.INSERT]: (api, object, path, index, id) => {
        // need to shift LUA arrays :(
        api.obj.get(object.id).add_row(id, index + 1)
      },

      [OneToManyOperations.APPEND]: (api, object, path, id) => {
        api.obj.get(object.id).add_row(id)
      }

    }

  }

}

export default OneToManyCustomMutations