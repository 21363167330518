import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose'
import { Input } from 'antd'

import CreateModal from 'components/Commons/CreateModal'
import { withTargetValue } from 'utils/antd'
import { updateQueryValue, pushTransform } from 'graphql/updates'

import teamCreateMutation from 'api/mutations/teamCreate.graphql'
import organizationTeamsQuery from 'api/queries/organizationTeams.graphql'

import styles from './CreateTeamModal.scss'

const CreateTeamModal = ({ name, setName, description, setDescription, hide, canSubmit, submit, onCreated }) => (
  <CreateModal title="Create Team" hide={hide} submit={submit} canSubmit={canSubmit} onCreated={onCreated}>
    {({ onCreate }) => (
      <div className={styles.fields}>
        <Input placeholder="Name" value={name} onChange={withTargetValue(setName)} onPressEnter={onCreate} />
        <Input placeholder="Description" value={description} onChange={withTargetValue(setDescription)} />
      </div>
    )}
  </CreateModal>
)

export default compose(
  withState('name', 'setName'),
  withState('description', 'setDescription'),
  withPropsOnChange(['name'], ({ name }) => ({
    canSubmit: !!name
  })),
  graphql(teamCreateMutation, { name: 'createMutation' }),
  withHandlers({
    submit: ({ createMutation, organization, name, description }) => () =>
      createMutation({
        variables: { input: { organizationId: organization._id, name, description } },
        update: updateQueryValue({
          query: organizationTeamsQuery,
          variables: { organizationName: organization.name }
        },
        pushTransform(['organization', 'teams', 'list'], 'teamCreate', true)
        )
      })
  })
)(CreateTeamModal)

