import { filter, head, isEmpty, isNil, length, path, reduce, toString } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import memoize from 'memoize-state'

import { model, lang } from 'beanie-engine-api-js'

import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { EMPTY_ARRAY } from 'utils/object'
import { factsByName, factsTypes } from 'selectors/objects/facts'

const { types: { object: { isDisabled } } } = model
const {
  rule: {
    typing: { inferAndUnifyWith, types, TypingContext },
    error: { createErrorMessage, isError, isSyntaxError },
    utils: { getFactNamesVisitor, getNodeReferencesVisitor, visitExpr },
  }
} = lang

const errorFromRuleError = (header, err, id) => ({
  status: CHECK_RESULT_STATUS.ERROR,
  message: `${header} ${createErrorMessage(err)}`,
  severity: CHECK_SEVERITY_LEVEL.WARNING,
  ...id ? { id } : {},
})

const checkFactErrors = ({ factIndex, errorId, header }, { withNonExistentFact = true }, factNames) => {
  const errors = []

  const nonExistentFactNames = filter(factName =>
    withNonExistentFact && isNil(factIndex[factName]), factNames
  )

  if (isNotEmpty(nonExistentFactNames)) {
    errors.push({
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.WARNING,
      message: length(nonExistentFactNames) === 1 ?
        `${header} The fact ${toString(head(nonExistentFactNames))} doesn't exist` :
        `${header} The facts ${toString(nonExistentFactNames)} don't exist`,
      ...errorId ? { id: errorId } : {}
    })
  }

  return errors
}

const checkNodeRefErrors = ({ objectIndex, errorId, header }, { withNonExistentRef = true, withDisabledRef = true }, nodeRefs) => {
  const errors = []

  const { nonExistentNodeIds, nonDisabledNodeIds } = reduce((acc, nodeRef) => {
    const obj = objectIndex[nodeRef]

    if (withNonExistentRef && isNil(obj)) {
      acc.nonExistentNodeIds.push(nodeRef)
    }

    if (withDisabledRef && !isNil(obj) && isDisabled(obj)) {
      acc.nonDisabledNodeIds.push(nodeRef)
    }

    return acc
  }, { nonExistentNodeIds: [], nonDisabledNodeIds: [] }, nodeRefs)

  // Check non existent node references
  if (isNotEmpty(nonExistentNodeIds)) {
    errors.push({
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.WARNING,
      message: length(nonExistentNodeIds) === 1 ?
        `${header} The node ${toString(head(nonExistentNodeIds))} doesn't exist` :
        `${header} The nodes ${toString(nonExistentNodeIds)} don't exist`,
      ...errorId ? { id: errorId } : {}
    })
  }

  // Check disabled node references
  if (isNotEmpty(nonDisabledNodeIds)) {
    errors.push({
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.WARNING,
      message: length(nonDisabledNodeIds) === 1 ?
        `${header} The node ${toString(head(nonDisabledNodeIds))} is disabled` :
        `${header} The nodes ${toString(nonDisabledNodeIds)} are disabled`,
      ...errorId ? { id: errorId } : {}
    })
  }

  return errors
}

const anyCheckIsEnabled = ({ withNonExistentRef = true, withDisabledRef = true, withNonExistentFact = true }) =>
  withNonExistentRef || withDisabledRef || withNonExistentFact

const visitorsByChecks = checks => {
  const { withNonExistentRef = true, withDisabledRef = true, withNonExistentFact = true } = checks
  return [
    ...(withNonExistentRef || withDisabledRef) ? [getNodeReferencesVisitor] : EMPTY_ARRAY,

    ...withNonExistentFact ? [getFactNamesVisitor] : EMPTY_ARRAY
  ]
}

export const checkRule = ({
  objectIndex,
  factIndex,
  factsTypesIndex,
  rule: { source, program },
  ruleType,
  headerMessage,
  errorId,
  checks = {}
}) => {
  const header = `${headerMessage} (${isEmpty(source) ? ' -Empty- ' : source}) error:`
  const {
    isUndefined = true,
  } = checks

  if (isNil(program)) {
    return isUndefined ? {
      status: CHECK_RESULT_STATUS.ERROR,
      message: `${header} Rule is undefined`
    } : OK_RESULT
  }

  // Check parse error
  if (isSyntaxError(program)) return errorFromRuleError(header, program, errorId)

  // Check type error
  const typeOrError = inferAndUnifyWith(program, ruleType, new TypingContext({
    withCheck: true,
    factTypes: factsTypesIndex,
    variableTypes: {},
    propNodeTypes: {},
    itType: types.Any,
    factResolver: name => factIndex[name]
  }))

  if (isError(typeOrError)) return errorFromRuleError(header, typeOrError, errorId)

  if (anyCheckIsEnabled(checks)) {
    const { factNames = [], nodeRefs = [] } = visitExpr(program, visitorsByChecks(checks))

    // Check node refs errors
    const nodeRefErrors = checkNodeRefErrors({ objectIndex, errorId, header }, checks, nodeRefs)
    if (isNotEmpty(nodeRefErrors)) return head(nodeRefErrors)

    // Check fact errors
    const factErrors = checkFactErrors({ factIndex, errorId, header }, checks, factNames)
    if (isNotEmpty(factErrors)) return head(factErrors)
  }

  return OK_RESULT
}

export const makeExpressionCheckSelector = (exprPath, header, type, programPath = ['rule']) => ({ id }) => {
  const mSelector = memoize((objectIndex, factIndex, factsTypesIndex) => {
    const expr = path(exprPath, objectIndex[id])

    if (!expr) return OK_RESULT

    return checkRule({
      objectIndex,
      factIndex,
      factsTypesIndex,
      rule: { source: expr.source, program: path(programPath, expr) },
      ruleType: type,
      headerMessage: header,
      errorId: `${header} error`
    })
  })

  const selector = ({ objectIndex, state }) => mSelector(objectIndex, factsByName(state), factsTypes(state))

  selector.memoizingSelector = mSelector
  return selector
}