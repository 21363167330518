import React from 'react'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import { propEq, pathOr } from 'ramda'
import { Icon } from 'antd'
import users from 'api/queries/usersAllActive.graphql'
import onlineUsers from 'api/queries/onlineUsers.graphql'
import paginatedQuery from 'hocs/paginatedQuery'

import { user as userSelector } from 'selectors/auth'
import connectState from 'hocs/connectState'

import UsersPageHeader from './UsersPageHeader'
import UsersTable from './UsersTable'

import styles from 'styles/panel.scss'

const PAGE_SIZE = 10

const UsersTab = ({ usersData, user, onlineUserIds, onSearch, refreshItems, onPageChanged, handleTableChange }) => {
  const isOnline = aUser => 
    onlineUserIds.onlineUsers && onlineUserIds.onlineUsers.some(propEq('id', aUser.id))
  const nrOnline = pathOr(0, ['onlineUsers', 'length'], onlineUserIds)
  return (
    <div>
      <UsersPageHeader onSearch={onSearch} />
      {usersData.loading && (
        <Loading />
      )}
      {usersData.usersAllActive &&
        <UsersTable 
          data={usersData}
          users={usersData.usersAllActive}
          user={user}
          pageSize={PAGE_SIZE}
          onPageChanged={onPageChanged}
          handleTableChange={handleTableChange}
          onUserUpdated={refreshItems}
          isOnline={isOnline}
          footer={`${usersData.usersAllActive.paging.nrOfItems} users (${nrOnline} ${nrOnline === 1 ? 'is' : 'are'} online)`}
        />
      }
    </div>
  )
}

const Loading = () => (
  <div className={styles.loading}>
    <Icon type="loading" /> Loading...
  </div>
)

export default compose(
  connectState({ user: userSelector }),
  paginatedQuery({
    query: users,
    name: 'usersData',
    pageSize: PAGE_SIZE,
  }),
  graphql(onlineUsers, { name: 'onlineUserIds' })
)(UsersTab)
