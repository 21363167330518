import React, { useMemo } from 'react'
import { compose, onlyUpdateForKeys } from 'recompose'
import { isNotNil } from 'ramda-adjunct'
import classNames from 'classnames'
import { model } from 'beanie-engine-api-js'
import withNodeDropBehavior from '../../../dnd/withNodeDropBehavior'

import TruthTableColumnHeader from './TruthTableColumnHeader'

import styles from '../TruthTable.scss'

const { types: { truthTable: { headers } } } = model

/**
 *
 */
const TruthTableHeaderRow = ({
  truthTable,
  otherwiseId,
  countOfRows,
  countOfColumns,

  executed,
  trackExecutionEnabled,

  staticIssues,
  isHovered,
  isEnabled,
  isSelected,

  connectDropTarget,
  droppingLeft,
  droppingRight,
  updateIsDraggingColumn,
  isDraggingArray,
}) => {
  const _headers = useMemo(() => headers(truthTable), [truthTable])

  return connectDropTarget(
    <tr data-selection="clear">
      {_headers.map((header, i) => (
        <TruthTableColumnHeader
          key={`${i}`}
          truthTable={truthTable}

          index={i}
          isFirst={i === 0}
          isLast={i === countOfColumns - 1}
          countOfRows={countOfRows}
          droppingLeft={droppingLeft}
          droppingRight={droppingRight}
          isDraggingColumn={isDraggingArray[i]}
          truthTableExecuted={executed}
          trackExecutionEnabled={trackExecutionEnabled}
          hasOtherwiseRow={isNotNil(otherwiseId)}
          updateIsDraggingColumn={updateIsDraggingColumn}
          truthTableIsHovered={isHovered}
          staticIssues={staticIssues}
          header={header}
          truthTableEnabled={isEnabled}
          truthTableSelected={isSelected}
        />
      ))}
      <th data-selection="clear" className={classNames(styles.filler)} />
    </tr>
  )
}

export default compose(
  withNodeDropBehavior,
  onlyUpdateForKeys([
    'truthTable',
    'otherwiseId',

    'countOfRows',
    'countOfColumns',
    'isDraggingArray',

    'trackExecutionEnabled',
    'connectDropTarget',

    'staticIssues',

    'isHovered',
    'isEnabled',
    'isSelected',
    'executed',

    'updateIsDraggingColumn',
    'droppingLeft',
    'droppingRight',
  ]),
)(TruthTableHeaderRow)
