import React from 'react'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { Sys, parseRef } from 'beanie-engine-api-js'
import { Icon } from 'model/label'

import LinkButton from 'components/Commons/LinkButton'

import { truncate } from 'utils/string'

import { createLabelSelector } from 'selectors/labels'
import { onNodeSelected } from 'actions/nodes'
import { propFromProps } from 'selectors/props'

const JumpTextView = ({ label, object: { data: { target } }, selectNodeAction }) => (
  <h1>
    <Icon sys={Sys.jump} />
    <LinkButton onClick={() => selectNodeAction(parseRef(target))} disabled={!target}>
      {label ? truncate(label.name, 40) : '<No target>'}
    </LinkButton>
  </h1>
)
export default compose(
  withProps(({ object: { data: { target } } }) => ({
    targetId: target && parseRef(target)
  })),
  connect(() => {
    const labelSelector = createLabelSelector(propFromProps('targetId'))
    return (state, props) => ({
      label: labelSelector(state, props)
    })
  },
  {
    selectNodeAction: onNodeSelected
  })
)(JumpTextView)