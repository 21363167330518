import { model } from 'beanie-engine-api-js'
import { laneOf, rootOf } from '../../paths'

const { types: { object: { getName } } } = model

/**
 * An object that tracks the logic that traverse all facts
 * to index them by different criteria (specified by a mapper)
 *
 * @see groupFactsByMapper()
 */
export default class FactsComputingCache {

  constructor(mapper, defaultValue, factsIndexByName, state, index, indexBySys, lanes) {
    /**
     * A cache: { [factName]: computedValue}
     * Everytime we compute the value of a fact we store it here.
     * This is useful since the mapper might be triggering computing dependent facts while computing for a particular fact
     * This way the first time we see a fact we compute its value and then we just return that value
     */
    this.factToValueCache = {}

    /** The value computing function (Fact) => V */
    this.mapper = mapper
    /** A default value in case the mapper returns undefined */
    this.defaultValue = defaultValue

    // context objects (to resolve objects, facts, etc)
    // we propagate the state to the mapper in case it also needs to access it
    this.state = state
    this.index = index
    this.indexBySys = indexBySys
    this.lanes = lanes
    this.factsIndexByName = factsIndexByName
  }

  followFactWithName(factName) {
    const f = this.factsIndexByName[factName]
    return f ? this.computeFact(f) : undefined
  }

  computeFact(fact) {
    const name = getName(fact)
    const computedValue = this.factToValueCache[name] || this.mapper(fact, this) || this.defaultValue

    // record 'factName -> computedValue' in the cache
    if (!this.factToValueCache[name]) {
      this.factToValueCache[name] = computedValue
    }
    return computedValue
  }

  getState() { return this.state }

  getLaneOfNode(id) {
    const root = rootOf(this.index, this.indexBySys, id)
    return laneOf(this.lanes)(root)?.name
  }

}