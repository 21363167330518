import { path } from 'ramda'
import { isRef } from 'beanie-engine-api-js'
import { resolver, makeSelector } from './rereselect'

// TODO: we should provide a way to customize what to do if it cannot resolve something
// so the UI could show some feedback
const makeResolveChain = (from, chain) => makeSelector((query, getProps) => {
  const resolve = resolver(query)

  const obj = path(from.split('.'), getProps())

  // TODO: handle broken chain / nulls / undefined
  return chain.reduce(reduceChain(resolve), obj)
})

const reduceChain = resolve => (o, aPath) => {
  const value = path(aPath.split('.'), o)
  return isRef(value) ? resolve(value) : value
}

export default makeResolveChain