/** @jsx h */
import plugins from '../plugins/Plugins'
import { Editor, Value } from 'slate'
import schema from '../schema/schema'
import { EMPTY_ARRAY } from 'utils/object'
import h from 'slate-hyperscript'
import { NODE_TYPES } from '../Constants'

const bypassChecks = true

export const createEditorWithValue = (value, { withSchema } = { withSchema: false }) => new Editor({
  onChange: jest.fn(),
  plugins: [...(withSchema ? [{ schema }] : EMPTY_ARRAY), ...plugins({}, bypassChecks)],
  readOnly: false,
  value: Value.fromJSON(value),
})

export const emptyEditorDocument = () => (<document>
  <block type={NODE_TYPES.DIRECTOR_LINE}>
    <cursor /><text />
  </block>
</document>)