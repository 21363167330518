import React from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'
import { model } from 'beanie-engine-api-js'

import { makeLineTypeOfAudioSelector } from 'selectors/lines'
import { propFromProps } from 'selectors/props'

import IconButton from 'components/Commons/IconButton'

import styles from '../../../SlateTextEditor.scss'
import DropDownMenuItem from '../DropDownMenuItem'
import LineAudioMenuContent from './LineAudioMenuContent'

const { types: { line: { AudioType } } } = model

const AudioTypeIcon = {
  // TODO: better icons
  [AudioType.NO_AUDIO]: 'stop',
  [AudioType.TTS]: 'android',
  [AudioType.VOICE]: 'user',
}

const LineAudioMenuItem = props => {
  const { isAudioDirty, audioType } = props

  return (
    <DropDownMenuItem
      content={onItemSelected => (
        <LineAudioMenuContent
          {...props}
          onItemSelected={onItemSelected}
        />
      )}
    >
      <div className={styles.LineAudioMenuItem}>
        <AudioDirtyDecoration dirty={isAudioDirty} />
        <IconButton type={AudioTypeIcon[audioType]} />
      </div>
    </DropDownMenuItem>
  )
}

const AudioDirtyDecoration = ({ dirty }) => (dirty ?
  (
    <Icon type="warning" theme="filled" className={styles.AudioDirtyDecoration} />
  )
  : null
)

export default connect(
  () => {
    const audioTypeSelector = makeLineTypeOfAudioSelector(propFromProps('lineId'))
    return (state, props) => ({
      audioType: audioTypeSelector(state, props)
    })
  }
)(LineAudioMenuItem)