exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__KpQsJ{overflow-y:auto;height:100%;background-color:#a5a5a5}.container__KpQsJ .outside__2gLV5{background:#a5a5a5;padding-left:2rem;padding-right:2rem;padding-top:1rem;padding-bottom:1rem}.container__KpQsJ .inside__S5o1t{background:#fff;-webkit-box-shadow:2px 4px 8px rgba(0,0,0,.5);box-shadow:2px 4px 8px rgba(0,0,0,.5);margin-bottom:2rem}", ""]);

// exports
exports.locals = {
	"container": "container__KpQsJ",
	"outside": "outside__2gLV5",
	"inside": "inside__S5o1t"
};