export default class Check {
  constructor(name, objId, selector) {
    this.name = name
    this.objId = objId
    this.memoizedSelector = selector
    this.affectedPaths = []
    this.lastHasMiss = true
    this.result = undefined
  }

  misses() {
    return this.memoizedSelector.memoizingSelector.cacheStatistics?.cacheMiss || 0
  }

  run(context) {
    const missesBefore = this.misses()
    this.result = this.memoizedSelector(context)
    this.affectedPaths = this.memoizedSelector.memoizingSelector.getAffectedPaths()

    this.lastHasMiss = this.misses() > missesBefore
    return this.result
  }

  asData() {
    return ({
      name: this.name,
      objId: this.objId,
      result: this.result
    })
  }
}