import { connect } from 'react-redux'
import { compose } from 'recompose'
import { makeNodeIssuesSelector } from 'selectors/staticIssues'

const withStaticAnalysis = () => compose(
  connect((_, { node }) => {
    const staticIssuesSelector = makeNodeIssuesSelector(node.id)
    return (state, props) => ({
      staticIssues: staticIssuesSelector(state, props),
    })
  }),
)

export default withStaticAnalysis
