// took from LUA code
const wpm = 175
const avg_word_length = 6
const delay = 0.5

const calculateTimeToPlay = text => {
  const words = text.length / avg_word_length
  const time = (words / wpm) * 60
  return time + delay
}

export default calculateTimeToPlay