import { remove } from 'ramda'
import { selectionToLeftAndRight } from 'selectors/nodeSelection'
import { moveArrayIndexTo } from 'utils/object'
import { OneToManyOperations } from '../Operations'
import accepts from './oneToMany/accepts'
import { updateArray } from './oneToMany/OneToManyDefaultMutations'
import { appendOperation, insertOperation } from './oneToMany/operationMutations'

/**
 * Generic one-to-many without an inverse relation.
 * Works for an array
 */
const OneToManyConnector = {

  connect: ({ api, resolve }, selection, relationship, operation) => {
    const { from, path } = relationship
    const object = resolve(from)
    const { left } = selectionToLeftAndRight(selection)

    switch (operation) {
      case OneToManyOperations.INSERT:
        insertOperation(api, object, path, relationship.index, left)
        break;
      case OneToManyOperations.APPEND:
        appendOperation(api, object, path, left)
        break;
      default: throw new Error(`Unknown operation on one-to-many: ${operation}`)
    }
  },

  canConnect: ({ resolve }, selection, relationship) => {
    const { left } = selectionToLeftAndRight(selection)
    const { from, path } = relationship
    return accepts(resolve(from), path, resolve(left))
  },

  disconnect: ({ api, resolve }, selection, { from, path, index }) => {
    updateArray(api, resolve(from), path, remove(index, 1))
  },

  move: ({ api, resolve }, { from, path }, fromIndex, toIndex) => {
    updateArray(api, resolve(from), path, moveArrayIndexTo(fromIndex, toIndex))
  },

}

export default OneToManyConnector