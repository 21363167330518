import { path, prop } from 'ramda'

// general 
export const fromProps = fn => (_, props) => fn(props)

export const propFromProps = name => fromProps(prop(name))
export const pathFromProps = aPath => fromProps(path(aPath))

// redundante (to be deleted)
export const idFromProps = propFromProps('id')
export const nodeId = (_, props) => (props.node || props.object || props.o || {}).id
export const nodePath = (_, props) => props.nodePropPath

export const playbackNodeIdFromProp = (_, props) => props.nodeId || props.node.nodeId
