import helpers from 'react-shortcuts/lib/helpers'
import { pipe, mapObjIndexed, groupBy, pathOr, map, dissoc, replace, when, keys, assocPath } from 'ramda'
import { flattenProp, isNotNil } from 'ramda-adjunct'
import { mapObject, properties } from 'utils/object'
import * as commands from 'commands/all'
import Groups from '../commands/Groups'
import Namespaces from 'shortcuts/Namespaces'

const DEFAULT_NAMESPACE = Namespaces.Project.TreeView

export const keyMap = keys(commands).reduce((acc, action) => {
  const command = commands[action]

  const { shortcut } = commands[action]
  if (shortcut) {
    return assocPath([command.namespace || DEFAULT_NAMESPACE, action], shortcut, acc)
  }

  return acc
}, {})
export default keyMap

// Exports an objects with the same structure as the keymap
// to be used from the components as `Keys.TreeView.MOVE_LEFT` instead of using the string
export const Keys = mapObject(keyMap, ({ value }) => mapObject(value, keyValue => keyValue.name))

const reduceShortcuts = [replace(/command/g, 'cmd'), replace(/option/g, 'opt')]

const shrinkShortcut = when(isNotNil, pipe(...reduceShortcuts))

export const shortcutOf = value => (
  Array.isArray(value) ? value.map(shortcutOf).join('/')
    : shrinkShortcut((typeof value === 'string' || !value ? value : value[helpers.getPlatformName()]))
)
export const shortCutForActionOnSys = (action, sys) => shortcutOf(keyMap.TreeView[`${action}_${sys.toUpperCase()}`])

// grouping data

export const groupCommands = pipe(
  properties,
  groupBy(pathOr(Groups.Others, ['value', 'group'])),
  mapObjIndexed(map(pipe(flattenProp('value'), dissoc('value'))))
)