import React from 'react'

import styles from './ErrorPanel.scss'

const LoadProjectErrorModal = ({ error }) => {
  if (error === undefined) return null
  return (
    <div className={styles.errorPanel}>
      <p>There was a problem loading the project</p>
      {error &&
        <details>
          {error.message.toString()}
          <br />
          {error.stack && error.stack.split('\n').map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </details>
      }
    </div>
  )
}

export default LoadProjectErrorModal