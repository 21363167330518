import { toRegularText } from './utils'
import { NormalizationError } from './constants'

export const actorPartNormalize = (editor, { code, child, node }) => {

  if (code === NormalizationError.CHILD_OBJECT_INVALID) {
    const { key } = child
    const focus = editor.currentFocus().toJS()
    toRegularText(editor, key, key)
    if (editor.focusIsAtEndOfNode(node)) {
      editor.setFocus({ ...focus, offset: focus.offset - 1 })
    }
  }

}