import React from 'react'
import { connect } from 'react-redux'
import { noop } from 'ramda-adjunct'
import { Input, Icon, Spin } from 'antd'
import { debounceEventHandler } from 'utils/async'
import { isEmptyOrNull } from 'utils/string'
import { isUri } from 'valid-url'

import { fetchImageFromURL } from 'actions/storyboard'

import styles from './FetchImageURLInput.scss'

export class FetchFileFromURLInput extends React.Component {

  state = {
    fetching: false,
    error: undefined,
    file: undefined
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.file && prevState.file !== this.state.file) {
      prevState.file.dispose()
    }
  }

  render() {
    const { inputPlaceHolder } = this.props
    const { error, fetching } = this.state
    return (
      <div className={styles.container}>
        <Input
          className={styles.urlInput}
          placeholder={inputPlaceHolder}
          onChange={this.onChange}
          prefix={<span>&nbsp;</span>}
          {...error && { prefix: <Icon className={styles.errorPreffix} type="exclamation-circle" /> }}
          {...fetching && { prefix: <Spin indicator={<Icon type="loading" spin />} /> }}
        />
        {error &&
          <div className={styles.error}>{error}</div>
        }
      </div>
    )
  }
  onChange = debounceEventHandler(({ target: { value } }) => {
    if (isEmptyOrNull(value)) {
      this.setState({ error: undefined, fetching: false })
    } else if (!isUri(value)) {
      this.onError('Invalid URL')
    } else {
      this.doFetch(value)
    }
  }, 1000)

  doFetch = async url => {
    const { fetchImage, onFetched = noop } = this.props
    this.setState({ error: null, fetching: true })
    try {
      const file = await fetchImage(url)
      this.setState({ error: null, fetching: false, file })
      onFetched(file)
    } catch (error) {
      this.onError(error.message)
    }
  }
  onError = error => {
    this.setState({ error, fetching: false })
  }
}

export default connect(null, ({
  fetchImage: fetchImageFromURL
}))(FetchFileFromURLInput)
