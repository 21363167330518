import CompositeLink from 'graphql/links/utils/CompositeLink'
import AuthLink from './auth/AuthLink'
import RevisionSessionExpiredCheckLink from './revisionSession/RevisionSessionExpiredCheckLink'
import HttpLink from './HttpLink'
import NetworkCheckLink from './NetworkCheckLink'

export default CompositeLink(
  // check backend reachable
  NetworkCheckLink,
  // check we still have an app session (token)
  AuthLink,
  // check we still have a revision session (working on a project revision)
  RevisionSessionExpiredCheckLink,
  // setup backend API URL
  HttpLink
)