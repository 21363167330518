import React from 'react'
import { connect } from 'react-redux'

import makeObjectEditLabelSelector from 'selectors/objects/makeObjectEditLabelSelector'

import EditNode from './EditNode/EditNode'

const TreeNodeEdit = ({ node, textValue, onEditCompleted, onCancel }) => (
  <EditNode node={node} text={textValue} onApply={onEditCompleted} onCancel={onCancel} />
)

export default connect((initialState, initialProps) => {
  const valueSelector = makeObjectEditLabelSelector(initialProps.node.id)
  return state => ({
    textValue: valueSelector(state) || '' // ?
  })
})(TreeNodeEdit)