import React, { useCallback, useMemo } from 'react'
import { useSelector, useStore } from 'react-redux'
import classNames from 'classnames'

import { searchAction } from 'actions/nodes/search/search'
import ExprEditor from './ExprEditor/ExprEditor'
import { isBNENode } from 'model/project/searches/query/bneQueries'
import { matchSearch } from 'model/project/searches/searches'

import Preferences from 'preferences/Preferences'

import { allVariables, staticVariablesPossibleValues } from 'selectors/state'
import { resolver, labelFor } from 'selectors/labels'
import { objectsIndex } from 'selectors/apollo'
import { preference } from 'selectors/view'

import styles from './BeanieLanguageEditor.scss'

const BeanieLanguageEditor = ({ className, placeholder, autoFocus = false, value, setValue, onCancel, expectedType, onApply, withSyntheticMatch, ...others }) => {
  const store = useStore()
  const variables = useSelector(allVariables)
  const possibleStaticVariablesValues = useSelector(staticVariablesPossibleValues)
  const pref = useMemo(() => preference(Preferences.ExprEditor.linterEnabled), [])
  const linterEnabled = useSelector(pref)
  const findMatchRefs = useCallback(async text => {
    const index = objectsIndex(store.getState())
    const result = await store.dispatch(searchAction(matchSearch(text, isBNENode)))
    return result.map(({ object }) => ({ label: labelFor(object, resolver(index)), sys: object.sys, id: object.id }))
  }, [store])

  return (
    <ExprEditor
      className={classNames(styles.editor, className)}
      placeholder={placeholder}
      expectedType={expectedType}
      variables={variables}
      store={store}
      possibleStaticVariablesValues={possibleStaticVariablesValues}
      findMatchRefs={findMatchRefs}
      value={value}
      onCancel={onCancel}
      onApply={onApply}
      setValue={setValue}
      autoFocus={autoFocus}
      linterEnabled={linterEnabled}
      withSyntheticMatch={withSyntheticMatch}
      {...others}
  />)
}

export default BeanieLanguageEditor