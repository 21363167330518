exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editor__LhPeD{width:12.5rem;border:1px solid #add8e6;font-weight:normal;font-size:1.4em;background:#fff}.editor__LhPeD .CodeMirror{height:auto}.editor__LhPeD .CodeMirror pre{font-family:\"Catamaran\" !important}.editor__LhPeD .cm-editor.cm-focused{outline:none}.editor__LhPeD div > .cm-editor .cm-tooltip-hover{background:#fff !important;font-family:\"Catamaran\" !important}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul{background:#fff !important;font-family:\"Catamaran\" !important}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul>li{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;overflow:hidden;padding:.2rem}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul>li .cm-completionDetail{-ms-flex-item-align:end;align-self:flex-end;padding-right:1rem;font-size:.875rem;color:#d3d3d3}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul>li .cm-completionLabel{-ms-flex-pack:start;justify-content:start;-ms-flex:1 1 0%;flex:1 1 0%}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul>li .cm-completionIcon{margin-right:1rem;-ms-flex-item-align:center;align-self:center;margin-left:.5rem;width:10px;height:10px}.editor__LhPeD div > .cm-editor > .cm-tooltip-autocomplete > ul>li .cm-completionIcon::after{content:none}.nodeReference__3uvvw{font-size:.6rem;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content}", ""]);

// exports
exports.locals = {
	"editor": "editor__LhPeD",
	"nodeReference": "nodeReference__3uvvw"
};