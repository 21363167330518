import React from 'react'
import { Table, Tag } from 'antd'

import { Link } from 'react-router-dom'

import routes from '../../routes'

import SectionHeader from '../../SectionHeader/SectionHeader'
import SectionElementsTable from '../../Section/SectionElementsTable/SectionElementsTable'
import RelativeTime from 'components/Commons/RelativeTime'
import ArchivedFilter from 'components/Commons/Filters/ArchivedFilter'

import styles from '../../Section/Section.scss'
import ownStyles from './TeamProjects.scss'

const { Column } = Table

const TeamProjects = ({ onSearch, projects, pageSize, onPageChanged, handleTableChange, archived, setArchived }) => (
  <div className={styles.container}>
    <SectionHeader
      onSearch={onSearch}
    >
      <ArchivedFilter archived={archived} setArchived={setArchived} />
    </SectionHeader>
    <SectionElementsTable
      data={projects}
      pageSize={pageSize}
      onPageChanged={onPageChanged}
      handleTableChange={handleTableChange}
      rowKeyPath="_id"
      dataPath={['team', 'projects']}
    >
      <Column
        title="Name"
        key="name"
        render={project => (
          <div className={ownStyles.nameRow}>
            <Link to={routes.oldProjectRouteForId(project.masterBranch._id)}>{project.name}</Link>
            {project.archived && <Tag>archived</Tag>}
          </div>
        )}
        sorter
      />
      <Column
        title="Created At"
        key="createdAt"
        sorter
        dataIndex="createdAt"
        render={date => <RelativeTime date={date} />}
      />
      <Column
        title="Updated"
        key="lastUpdatedAt"
        render={p => p.lastUpdatedAt && <RelativeTime date={p.lastUpdatedAt} />}
      />
    </SectionElementsTable>
  </div>
)

export default TeamProjects