import revisionSessionGet from 'api/queries/revisionSessionGet.graphql'
import { GQL_OPERATION_METADATA_DONT_FAIL_ON_REVISION_SESSION_ERROR, hasInvalidRevisionSessionError } from '../../../graphql/links/queries-and-mutations/revisionSession/RevisionSessionExpiredCheckLink'
import resyncRevision from '../resyncRevision'
import { onSessionDestroyed } from '../session'

/**
 * When the networks got up again it:
 * - validates the revision session
 * - if it is still valid then re-sync (changeSets and sessions)
 * - if it is not valid anymore then trigger destroyed (which will trigger recreating one)
 */
const sessionOnNetworkReconnected = () => async (dispatch, getState, { getApolloClient: getClient }) => {
  //  check if our session is still valid
  if (await isSessionStillValid(getClient())) {
    // if ok, then re-sync project
    await dispatch(resyncRevision())
  } else {
    dispatch(onSessionDestroyed())
  }
}

const isSessionStillValid = async client => {
  try {
    // if didn't fail the we are good (?)
    await client.query({
      query: revisionSessionGet,
      // we don't want the Link to check and fail. We will do that here
      context: {
        metadata: {
          [GQL_OPERATION_METADATA_DONT_FAIL_ON_REVISION_SESSION_ERROR]: true
        }
      }
    })
    return true
  } catch (error) {
    // if we got the session Invalid error
    if (error.graphQLErrors && hasInvalidRevisionSessionError(error.graphQLErrors)) {
      return false
    }
    // otherwise another error, we don't want to eat that
    throw error
  }
}

export default sessionOnNetworkReconnected