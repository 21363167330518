exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".LineSearchResultItem__2Virc{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.LineSearchResultItem__2Virc .avatar__3m1NE{margin-right:2rem}.LineSearchResultItem__2Virc .dialoguePart__182zD{margin-right:2rem}", ""]);

// exports
exports.locals = {
	"LineSearchResultItem": "LineSearchResultItem__2Virc",
	"avatar": "avatar__3m1NE",
	"dialoguePart": "dialoguePart__182zD"
};