import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { message, notification } from 'antd'

import { parseGraphQLErrors } from 'utils/graphql'
import syncLineTts from 'actions/project/tasks/tts/syncLineTts'
import IconButton from 'components/Commons/IconButton'

/**
 *
 */
const SyncTtsButton = ({ lineId }) => {
  const dispatch = useDispatch()
  const onClick = useCallback(async () => {
    try {
      await dispatch(syncLineTts(lineId))
      notification.success({ message: 'TTS synchronization', description: 'Finished correctly' })
    } catch (error) {
      message.error(`Could not generate TTS audio: ${parseGraphQLErrors(error)}`)
    }
  }, [dispatch])

  return (
    <IconButton
      type="sync"
      onClick={onClick}
    />
  )
}
export default SyncTtsButton
