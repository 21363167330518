exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameColumn__193cK{font-weight:bolder}.valuesColumn__3ZrY8 ol{list-style:none;margin:0}.actionsColumn__2qzyR{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:space-evenly;justify-content:space-evenly}", ""]);

// exports
exports.locals = {
	"nameColumn": "nameColumn__193cK",
	"valuesColumn": "valuesColumn__3ZrY8",
	"actionsColumn": "actionsColumn__2qzyR"
};