exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".uploadAudioPreview__38XuQ{color:gray;margin-top:1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.uploadAudioPreview__38XuQ .title__2BNiK{font-size:1rem}.uploadAudioPreview__38XuQ .player__N6_Hm{width:300px}.uploadAudioPreview__38XuQ .fileName__1qxvI{font-size:.8rem;margin-top:.2rem}.header__1NF4V{width:100%}.header__1NF4V .idInfo__12YT7{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-bottom:1rem;-ms-flex-align:center;align-items:center;color:#d3d3d3}.header__1NF4V .idInfo__12YT7 .ant-row{width:80%}.header__1NF4V .line__2Kx3Z{font-family:\"Courier Prime\",Courier,monospace;padding-bottom:1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.header__1NF4V .line__2Kx3Z .actorPart__1HH88{font-weight:bold}", ""]);

// exports
exports.locals = {
	"uploadAudioPreview": "uploadAudioPreview__38XuQ",
	"title": "title__2BNiK",
	"player": "player__N6_Hm",
	"fileName": "fileName__1qxvI",
	"header": "header__1NF4V",
	"idInfo": "idInfo__12YT7",
	"line": "line__2Kx3Z",
	"actorPart": "actorPart__1HH88"
};