import React from 'react'
import { onlyUpdateForKeys } from 'recompose'
import useTransaction from '../../../hooks/beanie/useTransaction'

import TreeChain from '../TreeChain/TreeChain'

/**
 * An item in a CompositeNode (ie choice in choices, sequence in sequencer, etc)
 */
const ComponentNode = ({ componentId, isForcedToShowDisabled, containerId, ...forwardProps }) => {

  const removeFromContainer = useTransaction(api => () => {
    api.obj.get(containerId).container_remove_node(componentId)
  }, [containerId, componentId], 'Remove from container')

  return (
    <TreeChain
      id={componentId}
      isForcedToShowDisabled={isForcedToShowDisabled}
      onRemoveFromReferencer={removeFromContainer}

      {...forwardProps}
    />
  )
}

export default onlyUpdateForKeys(['componentId', 'containerId', 'relationship', 'isForcedToShowDisabled'])(ComponentNode)