import BlockType from './BlockType'

import styles from './blockStyleFn.scss'

const blockStyleFn = block => {
  switch (block.getType()) {
    case BlockType.choicesTitle: return styles.choicesTitle
    default: return undefined
  }
}

export default blockStyleFn