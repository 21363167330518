import React from 'react'
import { Button } from 'antd'

import CreateTeamModal from './CreateTeamModal'

import { ModalConsumer } from 'components/Commons/Modal'

const CreateTeamButton = ({ organization, onCreated }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <Button type="primary" onClick={() => showModal(CreateTeamModal, { organization, onCreated })}>Create Team</Button>
    )}
  </ModalConsumer>
)

export default CreateTeamButton

