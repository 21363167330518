import React from 'react'
import { connect } from 'react-redux'
import { propEq } from 'ramda'
import { compose } from 'recompose'
import { push } from 'connected-react-router'

const findComp = (sections, sectionName) => {
  const section = sections.find(propEq('name', sectionName))
  return section && section.comp
}

const SectionSwitcher = ({ layout: Layout, onSectionChanged, routeParam, match: { params: { [routeParam]: subsection } }, sections, ...others }) => {
  const C = findComp(Object.values(sections), subsection)
  return (
    <Layout currentSection={subsection} sections={Object.values(sections)} onSectionChanged={onSectionChanged}>
      <C {...others} />
    </Layout>
  )
}

export default compose(
  connect(null, {
    onSectionChanged: sectionName => push(sectionName)
  })
)(SectionSwitcher)