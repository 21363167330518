import React from 'react'

import { pathOr, prop } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { LUA_UNSET_VALUE } from 'beanie-engine-api-js'
import { Icon, Table, Tooltip } from 'antd'

import { propertiesOf, EMPTY_OBJECT } from 'utils/object'


import styles from './PropertiesEditor.scss'
import PropertyLabel from './PropertyLabel'
import { getRowClassName, hasEmbeddedContent, isChild, renderEmbeddedContent } from './helpers'


const { Column } = Table


const defaultNameCollisionTooltip = (targetProperties, name) => `Will override ${JSON.stringify(targetProperties[name])}`

const getIcon = ({ name, targetProperties, nameCollisionTooltip }) => {
  const nameCollision = !!targetProperties[name]

  if (nameCollision) {
    return (
      <Tooltip
        className={styles.collisionWarningIcon}
        title={nameCollisionTooltip(targetProperties, name)}
      >
        <Icon type="warning" />
      </Tooltip>
    )
  }

  return null
}

const PropertiesSelector = ({
  editable,
  targetObject,
  object,
  onNavigateRef,
  onPropertyChanged,
  lens,
  hiddenFields = [],
  nonEditableFields = [],
  selected,
  setSelected,
  nameCollisionTooltip = defaultNameCollisionTooltip }) => {
  return (
    <div className={styles.propertiesEditor}>
      <Table
        rowSelection={{
          selectedRowKeys: selected,
          onChange: selectedRowKeys => {
            setSelected(selectedRowKeys)
          },
          getCheckboxProps: record => ({
            disabled: isChild(lens)(record)
          }),
        }}
        dataSource={propertiesOf(object || EMPTY_OBJECT, hiddenFields, undefined, lens, isNotNilOrEmpty(lens))}
        size="small"
        pagination={false}
        bordered={false}
        defaultExpandAllRows={false}
        rowClassName={getRowClassName(lens)}
        expandedRowRender={renderEmbeddedContent(object, onNavigateRef, onPropertyChanged)}
        scroll={{ y: '50%' }}
        rowKey={prop('path')} >
        <Column
          title="Name"
          dataIndex="name"
          width="35%"
          render={(text, record) => (
            <PropertyLabel
              text={text}
              record={record}
              isEditable={editable && !nonEditableFields.includes(record.name)}
              onRemove={() => onPropertyChanged(record.path, LUA_UNSET_VALUE)}
              object={object} />
          )} />
        <Column
          title="Value"
          dataIndex="value"
          width="65%"
          render={(text, record) => (
            <span>
              {getIcon({
                name: record.name,
                targetProperties: pathOr({}, lens, targetObject),
                nameCollisionTooltip
              })}
              {!hasEmbeddedContent(record) && text}
            </span>
          )}
          />
      </Table>

    </div>
  )
}

export default PropertiesSelector