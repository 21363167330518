import React from 'react'
import { List } from 'antd'

import { ProjectGrantRole as ProjectRoles } from 'utils/authentication'

import Popconfirm from 'components/Commons/Popconfirm'
import LinkButton from 'components/Commons/LinkButton'
import ChangeRoleModal from 'components/Organizations/Commons/ChangeRoleModal'
import ProjectGrantRole from 'components/Organizations/OrganizationProjects/ProjectGrantRole'

import styles from './GrantsGroup.scss'

const { Item } = List

const toId = grant => grant.to._id || grant.to.id

const GrantsGroup = ({ grants, revokeGrant, changeGrantRole, itemRenderer, project }) => (
  <List
    itemLayout="horizontal"
    dataSource={grants}
    renderItem={grant => (
      <Item><Grant project={project} grant={grant} revokeGrant={() => revokeGrant(toId(grant))} changeGrantRole={changeGrantRole(toId(grant))} itemRenderer={itemRenderer} /></Item>
    )}
  />
)

const Grant = ({ grant, revokeGrant, itemRenderer: ItemRenderer, project, changeGrantRole }) => {
  return (
    <div className={styles.grantItem}>
      <div className={styles.name}>
        <ItemRenderer grant={grant} />
      </div>
      <div className={styles.role}>
        <ProjectGrantRole role={grant.role} /> {grant.role}
      </div>
      <div className={styles.actions}>
        <div>
          <Popconfirm title="Are you sure ?" onConfirm={revokeGrant} okText="Revoke" cancelText="Cancel">
            <LinkButton>Revoke</LinkButton>
          </Popconfirm>
        </div>
        <div>
          <ChangeRoleModal container={project} member={grant} onChange={changeGrantRole} roles={ProjectRoles} />
        </div>
      </div>
    </div>
  )
}

export default GrantsGroup