import { apiFetch, fetchOptions } from './utils'
import { token as tokenSelector } from 'selectors/auth'
import { postResource } from 'actions/resources'
import { ResourceType } from 'model/resources'
import { processFileResponse } from 'utils/http'

export const uploadStoryboard = postResource(ResourceType.storyboard)

export const fetchImageFromURL = url => (dispatch, getState) => {
  const state = getState()
  const token = tokenSelector(state)

  // TODO: fail and not even try if there is no token ?

  return apiFetch(`api/cors/?url=${encodeURIComponent(url)}`, fetchOptions('GET', {}, token))
    .then(processFileResponse(url))
}

