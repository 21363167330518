exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__1Gxbe{color:#add8e6;cursor:pointer}.iconAsLink__1Gxbe:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__9vfsq{font-style:italic}.formFieldsVerticalGutter__3dz3C{padding:.5rem}.formFieldsVerticalGutter__3dz3C>*{margin:.5rem !important}.tableBackground__1Plbb .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1Plbb .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1kxm2 .ant-notification-notice-close{display:none}.actorAvatar__6i3vy{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.actorAvatar__6i3vy .actorAvatarLabel__2hOLJ{font-size:.6em}.actorAvatar__6i3vy:not(.active__2dsUQ){opacity:.4}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__1Gxbe",
	"labelFromRef": "labelFromRef__9vfsq",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__3dz3C",
	"tableBackground": "tableBackground__1Plbb",
	"notificationNotClosable": "notificationNotClosable__1kxm2",
	"actorAvatar": "actorAvatar__6i3vy",
	"actorAvatarLabel": "actorAvatarLabel__2hOLJ",
	"active": "active__2dsUQ"
};