import { isNil, length, max, min, pathOr, pipe, indexOf } from 'ramda'
import { model, Sys, ref } from 'beanie-engine-api-js'

import { EMPTY_ARRAY } from 'utils/object'
import { toBNEAction } from './utils'

import { Creators } from 'actions/ui'

const { types: { object: { Paths } } } = model

const { editStart } = Creators

//
// Truth table headers
//

const countOfColumns = pipe(pathOr(EMPTY_ARRAY, Paths.truth_table.headers), length)

export const updateTruthTableHeader = toBNEAction(
  (truthTable, index, newHeader) => api => {
    api.obj.get(truthTable.id).update_header(index + 1, newHeader)
  }, 'Update Truth Table header'
)

export const addAndEditTruthTableColumn = toBNEAction(
  truthTable => (api, dispatch) => {
    const index = truthTable.data.headers.length
    api.obj.get(truthTable.id).add_header(index + 1)
    dispatch(editStart(truthTable.id, ['data', 'headers', index]))
  }, 'Add Truth Table column'
)

export const insertColumnAtPosition = toBNEAction(
  (tt, toIndex) => api => {
    const indexToInsert = min(max(0, toIndex), countOfColumns(tt)) + 1
    api.obj.get(tt.id).add_header(indexToInsert)
  }, 'Add Truth Table header at position'
)

const _moveColumnToPosition = (tt, fromIndex, toIndex) => api => {
  const indexToInsert = min(max(0, toIndex) + 1, countOfColumns(tt))

  api.obj.get(tt.id).move_header_to(fromIndex + 1, indexToInsert)
}
export const moveColumnToPosition = toBNEAction(
  _moveColumnToPosition, 'Move Truth Table header'
)

export const _insertCustomColumn = (tt, toIndex, column) => api => {
  const indexToInsert = min(max(0, toIndex), countOfColumns(tt)) + 1
  api.obj.get(tt.id).add_custom_column(indexToInsert, column)
}
export const insertCustomColumn = toBNEAction(
  _insertCustomColumn, 'Add Custom Truth Table column at position'
)

export const _deleteTruthTableColumn = (truthTable, index) => api => {
  const tt = api.obj.get(truthTable.id)
  if (length(truthTable.data.headers) === 1) { api.obj.delete(tt) } else { tt.remove_header(index + 1) }
}
export const deleteTruthTableColumn = toBNEAction(
  _deleteTruthTableColumn, 'Delete Truth Table column'
)

/**
 * Moves a TruthTable column from one to another truth table position.
 * Actually source & target TT's can be the same
 */
const _moveColumn = (source, sourceIndex, column, target, targetIndex) => api => {
  if (target.id === source.id) {
    if (targetIndex === sourceIndex) return
    const indexToInsert = targetIndex + (sourceIndex > targetIndex ? 0 : -1)
    _moveColumnToPosition(target, sourceIndex, indexToInsert)(api)
  } else {
    // migrate column from one table to another
    _deleteTruthTableColumn(source, sourceIndex)(api)
    _insertCustomColumn(target, targetIndex, column)(api)
  }
}

export const moveColumn = toBNEAction(_moveColumn, 'Move TruthTable column')

// Truth table rows

export const addTruthTableRow = toBNEAction(
  truthTable => api => {
    const index = truthTable.data.container_contents.length
    return api.obj.get(truthTable.id).add_row(undefined, index + 1)
  }, 'Add Truth Table row'
)

export const addTruthTableRowAfter = toBNEAction(
  (truthTable, afterRow) => api => {
    const index = indexOf(ref(afterRow.id), truthTable.data.container_contents)
    return api.obj.get(truthTable.id).add_row(undefined, index + 2)
  }, 'Add Truth Table row after'
)

export const insertRowAtPosition = toBNEAction(
  (tt, index, row) => api => {
    const countOfRows = pathOr(EMPTY_ARRAY, Paths.container.container_content, tt).length
    const indexToInsert = min(max(0, index), countOfRows) + 1
    api.obj.get(tt.id).add_row(row.id, indexToInsert)
  }, 'Add Truth Table row at position'
)

export const removeTruthTableRow = toBNEAction(
  (truthTable, index) => api => {
    api.obj.get(truthTable.id).remove_row_by_index(index + 1)
  }, 'Remove Truth Table row'
)

export const moveRowToPosition = toBNEAction(
  (truthTable, row, index) => api => {
    const tt = api.obj.get(truthTable.id)
    const bneRow = api.obj.get(row.id)
    const countOfRows = pathOr(EMPTY_ARRAY, Paths.container.container_content, truthTable).length
    const indexToInsert = min(max(0, index) + 1, countOfRows)

    tt.remove_row(bneRow)
    tt.add_row(bneRow.get_id(), indexToInsert)
  }, 'Move Truth Table row'
)

export const updateTruthTableCell = toBNEAction(
  (truthTable, index, newRule) => api => {
    api.obj.get(truthTable.id).update_cell(index + 1, newRule)
  }, 'Update Truth Table cell'
)

export const removeOtherwiseRow = toBNEAction(
  truthTable => api => {
    const tt = api.obj.get(truthTable.id)
    const otherwise = tt.otherwise()
    api.obj.delete(otherwise.get_id())
    tt.set_otherwise(undefined)
  }, 'Remove Truth Table otherwise row'
)

export const addOtherwiseRow = toBNEAction(
  truthTable => api => {
    const tt = api.obj.get(truthTable.id)
    const otherwise = tt.otherwise()
    if (isNil(otherwise)) {
      const otherwiseNode = api.obj.new(Sys.truth_table_otherwise_row)
      tt.set_otherwise(otherwiseNode)
    }
  }, 'Add Truth Table otherwise row'
)
