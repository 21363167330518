import React from 'react'
import { connect } from 'react-redux'
import { pipe, map, sort, path, equals, reject } from 'ramda'
import autocompletePluginCreator from './AutocompletePluginCreator'
import { NODE_TYPES } from '../Constants'
import { actors as actorsSelector } from 'selectors/objects'
import { createSelector } from '../../../../selectors/reselect'
import { DEFAULT_ACTOR } from 'beanie-engine-api-js'

// TODO: We are moving the cursor always because currently otherwise it will still be opened.
// Once we move to a not-so-automatic solution we won't need to move it
const updateCursor = editor => {
  const index = editor.lineBlockPath().first()
  editor.moveCursorToTextPartOfDialogue(index)
}

export default () => autocompletePluginCreator({
  renderPortal: (Portal, props) => (
    <Actors>
      {actors => (
        <Portal {...props} suggestions={actors} />
      )}
    </Actors>
  ),
  shouldHandleNode: (editor, currentNode) => currentNode.type === NODE_TYPES.ACTOR_PART && !editor.selectionIsExpanded(),
  onEnter: (suggestion, editor) => {
    editor.replaceCurrentText(suggestion)
    updateCursor(editor)
  }
})

const makeActorSelector = () => createSelector(
  [actorsSelector],
  pipe(
    map(path(['data', 'actorName'])),
    reject(equals(DEFAULT_ACTOR)),
    sort((a, b) => a.localeCompare(b))
  )
)

const Actors = connect(() => {
  const selector = makeActorSelector()
  return state => ({
    actors: selector(state)
  })
})(({ actors, children }) => children(actors))