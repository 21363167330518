exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__Qclpm{color:#add8e6;cursor:pointer}.iconAsLink__Qclpm:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__1KOgy{font-style:italic}.formFieldsVerticalGutter__ya1y7{padding:.5rem}.formFieldsVerticalGutter__ya1y7>*{margin:.5rem !important}.tableBackground__2m0Kp .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2m0Kp .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2KIrR .ant-notification-notice-close{display:none}.option__9llwC{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.id__2-WT5{color:rgba(68,68,68,.3);padding-left:.5em;padding-right:.5em}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__Qclpm",
	"labelFromRef": "labelFromRef__1KOgy",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__ya1y7",
	"tableBackground": "tableBackground__2m0Kp",
	"notificationNotClosable": "notificationNotClosable__2KIrR",
	"option": "option__9llwC",
	"id": "id__2-WT5"
};