import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { compose, onlyUpdateForKeys } from 'recompose'

import { EMPTY_ARRAY } from 'utils/object'
import { model, parseRef } from 'beanie-engine-api-js'

import withNodeSelection from 'hocs/node/withNodeSelection'
import useBooleanState from '../../../hooks/react/useBooleanState'

import ComponentNode from './ComponentNode'
import TreeChainNode from '../TreeChain/TreeChainNode'
import CompositeFillerLine from './CompositeFillerLine'
import { getDebuggableContainerContentId, isDebuggable } from 'selectors/debuggingData'

import treeStyles from '../TreeView.scss'
import styles from './CompositeNode.scss'

const { types: { object: { isDisabled }, relationship: { Relationship } } } = model

/**
 * Tree Component for all "node_container" nodes from beanie.
 * For example for sequencers, choices, etc.
 */
const CompositeNode = ({ node, nodeType, filler: _filler, contentBeforeContents, className, relationship, isForcedToShowDisabled }) => {
  const debuggableSelector = useMemo(() => isDebuggable(node.id), [node.id])
  const debuggable = useSelector(debuggableSelector)

  const debuggableChildIdSelector = useMemo(() => getDebuggableContainerContentId(node.id), [node.id])
  const debuggableChildId = useSelector(debuggableChildIdSelector)

  const filler = _filler !== undefined ? _filler : true

  const [expanded, , , toggleCollapse] = useBooleanState(true)

  return (
    <div
      className={classNames(
        styles.compositeNode,
        treeStyles.bneContainerColumn,
        styles[node.sys],
        { [treeStyles.disabledCompositeScope]: isForcedToShowDisabled || (isDisabled(node) && !debuggable) },
        className
      )}>
      <div className={styles.ownNode}>
        <TreeChainNode
          node={node}
          nodeType={nodeType}
          relationship={relationship}
          onToggleCollapse={toggleCollapse}
          expanded={expanded}
        />
        {filler && expanded &&
        <CompositeFillerLine id={node.id} />
        }
      </div>
      <div className={classNames({ [styles.collapsed]: !expanded })}>
        {contentBeforeContents}
      </div>
      <div className={classNames(styles.components, styles[node.sys], { [styles.collapsed]: !expanded })} >
        <div className={styles.bindLine} />
        {(node.data.container_contents || EMPTY_ARRAY).map((componentId, i) => (
          <ComponentNodeWithIndex
            key={i}
            i={i}
            componentId={componentId}
            containerId={node.id}
            isForcedToShowDisabled={isForcedToShowDisabled}
            debuggableChildId={debuggableChildId}
          />
        ))}
      </div>
    </div>
  )
}

/**
 * Wraps <ComponentNode> just to be able to memo things based on its index (i) position
 */
const ComponentNodeWithIndex = ({ componentId, i, containerId, isForcedToShowDisabled, debuggableChildId }) => {
  const relationship = useMemo(() => Relationship.containerContent(containerId, i), [containerId, i])
  return (
    <ComponentNode
      containerId={containerId}
      componentId={parseRef(componentId)}
      relationship={relationship}

      isForcedToShowDisabled={isForcedToShowDisabled || (debuggableChildId && debuggableChildId !== parseRef(componentId))}
    />
  )
}

export default compose(
  onlyUpdateForKeys(['node', 'nodeType', 'relationship', 'isForcedToShowDisabled']),
  withNodeSelection(),
  onlyUpdateForKeys(['node', 'nodeType', 'relationship', 'isSelected', 'isForcedToShowDisabled']),
)(CompositeNode)
