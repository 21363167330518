import React from 'react'
import classNames from 'classnames'

// nested values

import styles from './PropertiesEditor.scss'
import ArrayEmbeddedContent from './ArrayEmbeddedContent'

export const isChild = lens => record => {
  const baseLevel = lens.length

  const recordLevel = record.path.split('.').length - baseLevel

  return recordLevel > 1
}

export const hasEmbeddedContent = record => (Array.isArray(record.value) || !!record.children)
export const getRowClassName = lens => record => classNames([{
  [styles.hasEmbedContent]: hasEmbeddedContent(record),
  [styles.isArray]: Array.isArray(record.value),
  [styles.isChild]: isChild(lens)(record)
}])

export const renderEmbeddedContent = (object, onNavigateRef, onPropertyChanged) => record => (
  Array.isArray(record.value) ? (
    <ArrayEmbeddedContent
      object={object}
      record={record}
      onNavigateRef={onNavigateRef}
      onPropertyChanged={onPropertyChanged} />
  ) : null
)