exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".spinner__2EDMp{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1}.spinner__2EDMp>.ant-spin-container{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1}.objectTextEditor__fi3Js{width:100%;-ms-flex-item-align:start;align-self:flex-start;position:relative;padding-top:0 !important;padding-bottom:1rem}.objectTextEditor__fi3Js .ant-spin-container{-ms-flex-direction:column;flex-direction:column}.objectTextEditor__fi3Js .ant-spin-container .contentUpdatedMessage__2SUkh{-ms-flex-pack:end;justify-content:flex-end;display:-ms-flexbox;display:flex;position:-webkit-sticky;position:sticky;top:0px;z-index:1;padding-left:.5rem;padding-right:.5rem;padding-top:0 !important}.objectTextEditor__fi3Js .ant-spin-container .message__1qb_G{-webkit-box-shadow:0px 2px 6px #eee;box-shadow:0px 2px 6px #eee;position:-webkit-sticky;position:sticky;width:100%;top:0px;z-index:1}.objectTextEditor__fi3Js .ant-spin-container>.editor__M1z__{padding-right:1rem}", ""]);

// exports
exports.locals = {
	"spinner": "spinner__2EDMp",
	"objectTextEditor": "objectTextEditor__fi3Js",
	"contentUpdatedMessage": "contentUpdatedMessage__2SUkh",
	"message": "message__1qb_G",
	"editor": "editor__M1z__"
};