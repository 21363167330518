import memoize from 'memoize-state'
import { model, parseRef } from 'beanie-engine-api-js'
import { isEmpty, isNil } from 'ramda'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'

const { types: { object: { isDisabled } } } = model

export const makeJumpToNonExistentNodeCheck = ({ id }) => {

  const mSelector = memoize((objectIndex) => {
    const { data: { target } } = objectIndex[id]

    if (isNil(target) || isEmpty(target)) {
      return ({
        status: CHECK_RESULT_STATUS.ERROR,
        id: 'Without target to jump',
        message: 'There is no target to jump',
        severity: CHECK_SEVERITY_LEVEL.WARNING,
      })
    }

    const targetNodeId = objectIndex[parseRef(target)]?.id

    return isNil(targetNodeId) ?
      {
        status: CHECK_RESULT_STATUS.ERROR,
        message: `The target object with id ${parseRef(target)} doesn't exist`,
        severity: CHECK_SEVERITY_LEVEL.ERROR,
        id: 'With non existent target node'
      } :
      OK_RESULT
  })

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export const makeJumpToDisabledNodeCheck = ({ id }) => {
  const mSelector = memoize((objectIndex) => {
    const { data: { target } } = objectIndex[id]
    if (isNil(target) || isEmpty(target)) { return OK_RESULT }

    const targetId = parseRef(target)

    return isDisabled(objectIndex[targetId]) ?
      {
        status: CHECK_RESULT_STATUS.ERROR,
        message: `The target object with id ${targetId} is disabled`,
        severity: CHECK_SEVERITY_LEVEL.ERROR,
        id: 'With disabled target node'
      } :
      OK_RESULT
  })

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Jump to non existent node',
    makeSelector: makeJumpToNonExistentNodeCheck
  },
  {
    name: 'Jump to disabled node',
    makeSelector: makeJumpToDisabledNodeCheck
  }
]