import React from 'react'
import { connect } from 'react-redux'
import { path, isNil, propSatisfies } from 'ramda'
import { compose, onlyUpdateForKeys, withHandlers, branch } from 'recompose'
import classNames from 'classnames'
import { Sys, isContainer, model } from 'beanie-engine-api-js'

import { makeObjectFromIdSelector } from 'selectors/objects'
import collapsable from 'hocs/collapsable'
import Choices from '../Node/Choices/Choices'
import Choices2 from '../Node/Choices2/Choices2'
import UnresolvedNode from '../Node/UnresolvedNode'

import TreeChainNode from './TreeChainNode'
import TreeChainElementLogic from './TreeChainElementLogic'
import NodeWithEnabledRule from './NodeWithEnabledRule/NodeWithEnabledRule'

import FolderNode from '../Node/FolderNode'
import TruthTable from '../Node/TruthTable/TruthTable'
import TruthTableRow from '../Node/TruthTable/row/TruthTableRow'
import CompositeNode from '../Node/CompositeNode'
import TreeElementErrorHandler from '../TreeElementErrorHandler'

// TODO: migrate to have its own file here
import styles from '../TreeView.scss'

const { types: { object: { hasLogic, hasRule } } } = model

export const TreeChainElement = ({ id, node, relationship, isForcedToShowDisabled, onRemoveFromReferencer, isRoot }) => {

  const ComponentType = nodeComponentFor(node)
  if (!ComponentType) {
    return (
      <UnresolvedNode id={id} onRemoveFromReferencer={onRemoveFromReferencer} />
    )
  }

  const content = (
    <ComponentType
      key={node.id}
      node={node}

      isRoot={isRoot}
      isForcedToShowDisabled={isForcedToShowDisabled}

      relationship={relationship}
      onRemoveFromReferencer={onRemoveFromReferencer}
    />
  )

  if (hasRule(node)) return (<NodeWithEnabledRule node={node} component={content} />)

  // TODO: we should avoid using bneContainerRow here, also in TreeNode
  return (
    <div className={classNames(styles.bneContainerRow, styles.nodeContainer)}>
      {hasLogic(node) ?
        <TreeChainElementLogic node={node}>
          {content}
        </TreeChainElementLogic>
        : content
      }
    </div>
  )
}

export const nodeComponentFor = node => {
  if (!node) return undefined
  switch (node.sys) {
    case Sys.logic: return CompositeNode
    case Sys.folder: return FolderNode
    case Sys.truth_table: return TruthTable
    case Sys.truth_table_row: return TruthTableRow
    case Sys.choices2: return Choices2
    case Sys.choices: return Choices
    default: {
      return isContainer(node) ? CompositeNode : TreeChainNode
    }
  }
}

const ComposedTreeChainElement = compose(
  // we accept: node | id
  // when having id we will resolve it from the store
  branch(
    propSatisfies(isNil, 'node'),
    connect(() => {
      const nodeSelector = makeObjectFromIdSelector()
      return (state, props) => ({
        node: nodeSelector(state, props)
      })
    }),
  ),
  collapsable({ id: path(['node', 'id']) }),
  withHandlers({
    toggleCollapse: ({ toggleCollapse, revisionId, node }) => () => {
      toggleCollapse(revisionId, node.id)
    }
  }),
  TreeElementErrorHandler,
  onlyUpdateForKeys(['node', 'collapsed', 'relationship', 'isForcedToShowDisabled']),
)(TreeChainElement)

export default ComposedTreeChainElement