import CodeMirror from 'codemirror'
import { createMode } from './modes-utils'
import { schema, Sys } from 'beanie-engine-api-js'

export const MODE = 'bne/condition'

export const getOperands = () => schema.enumValuesOf(Sys.condition, 'data.mode')

export const modeFactory = createMode(getOperands())
CodeMirror.defineMode(MODE, modeFactory)
