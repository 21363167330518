exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".RightPanel__1mZr5 .propertiesEditorInEdit__uMt10{box-shadow:none !important;-webkit-box-shadow:none !important;-ms-flex-positive:1;flex-grow:1}.RightPanel__1mZr5 .propertiesEditorInEdit__uMt10 .ant-table-small{border:0;border-bottom:1px solid #e9e9e9}.RightPanel__1mZr5 .propertiesEditorInEdit__uMt10 .ant-table-content{max-height:inherit}.RightPanel__1mZr5 .propertiesEditorInEdit__uMt10>div{-ms-flex-positive:1;flex-grow:1}.RightPanel__1mZr5 .singleNodeTextEditor__2pcnR>div{padding-top:2em;padding-bottom:2em}.RightPanel__1mZr5 .objectHistory__34fJu>div{width:100%;padding-right:1rem}", ""]);

// exports
exports.locals = {
	"RightPanel": "RightPanel__1mZr5",
	"propertiesEditorInEdit": "propertiesEditorInEdit__uMt10",
	"singleNodeTextEditor": "singleNodeTextEditor__2pcnR",
	"objectHistory": "objectHistory__34fJu"
};