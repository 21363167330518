import React from 'react'
/* eslint no-unused-vars: 0 */
import { T, F } from 'ramda'

const whyupdate = () => {
  /* eslint global-require: 0 */
  const { whyDidYouUpdate } = require('why-did-you-update')
  whyDidYouUpdate(React, {
    // include: [],
    exclude: [
      // antd
      /^AnimateChild$/,
      /^Spin$/,
      /^DialogWrap$/,
      /^IconReact$/,
      /^Route$/,
      /^Divider$/,
      /^Select$/,
      /^Input$/,
      /^InputElement$/,
      /^Tooltip$/,
      /^AutoComplete$/,
      /^LazyRenderBox$/,
      /^Modal$/,
      /MenuItem/,
      /^TableCell$/,
      /^LocaleReceiver$/,
      /^Menu$/,
      /^Wave$/,
      /^TransButton$/,
      /InternalMenu/,
      // graphql
      /^Query$/,
      /^Mutation$/,
      /^withApollo/,
      // blueprint
      /^Blueprint2.Menu$/,
      // router
      /Switch/,
      // commons,
      /FileChooser/,
      /^SplitPane$/,
      /^CSSTransition$/,
      /^Basic$/,
      /^Adapter$/,
      /SubPopupMenu/,
    ]
  })
}
whyupdate.enabledOn = F
// whyupdate.enabledOn = T

export default whyupdate