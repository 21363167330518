import React from 'react'
import { Table } from 'antd'

import { Link } from 'react-router-dom'

import routes from '../routes'

import { orgWriteAccess } from 'security/organization'

import Secure from 'components/Commons/Secure'
import SectionHeader from '../SectionHeader/SectionHeader'
import SectionElementsTable from '../Section/SectionElementsTable/SectionElementsTable'
import TeamAvatar from '../Teams/Commons/TeamAvatar'
import CreateTeamButton from './CreateTeamButton'
import OrganizationTeamActions from './OrganizationTeamActions'

import styles from '../Section/Section.scss'
import ownStyles from './OrganizationTeams.scss'

const { Column } = Table

const OrganizationTeams = ({ organization, teams, refreshItems, pageSize, onPageChanged, handleTableChange, onSearch }) => (
  <div className={styles.container}>
    <SectionHeader
      onSearch={onSearch}
      actions={
        <Secure context={{ organization }} check={orgWriteAccess}>
          <CreateTeamButton organization={organization} onCreated={refreshItems} />
        </Secure>
      }
    />
    <SectionElementsTable
      data={teams}
      pageSize={pageSize}
      onPageChanged={onPageChanged}
      handleTableChange={handleTableChange}
      rowKeyPath="_id"
      dataPath={['organization', 'teams']}
      actions={team => <OrganizationTeamActions organization={organization} team={team} refreshItems={refreshItems} />}
    >
      <Column
        title="Name"
        key="name"
        sorter
        render={team => (
          <div className={ownStyles.nameRow}>
            <TeamAvatar size={32} team={team} disabled />
            <Link to={routes.teamForTeam(team)}>{team.name}</Link>
          </div>
        )}
      />
      <Column
        title="Description"
        key="description"
        dataIndex="description"
      />
    </SectionElementsTable>
  </div>
)

export default OrganizationTeams