import React from 'react'
import { Spin, Form } from 'antd'
import { T, pathOr } from 'ramda'

import { ProjectGrantRole } from 'utils/authentication'

import ProjectGrantRoleComponent from 'components/Organizations/OrganizationProjects/ProjectGrantRole'
import Select from 'components/Commons/Forms/components/Select'
import UserSelectOption from 'components/Commons/UserSelectOption'

import styles from './GrantTeamForm.scss'

const GrantUserForm = ({ usersData, filter, getFieldDecorator }) => (
  <Spin spinning={usersData && !!usersData.loading}>
    <Form layout="horizontal">
      <Select
        getFieldDecorator={getFieldDecorator}
        placeholder="Choose one..."
        fieldName="User"
        options={
          pathOr([], ['organization', 'members', 'list'], usersData)
            .filter(filter || T)
            .map(member => ({
              value: member.user.id,
              label: <UserSelectOption user={member.user} />
            }))
        }
      />
      <Select
        getFieldDecorator={getFieldDecorator}
        initialValue={ProjectGrantRole.READ}
        placeholder="Role"
        fieldName="Role"
        labelRenderer={role => <span className={styles.roleLabel}><ProjectGrantRoleComponent role={role} />{role}</span>}
        options={Object.values(ProjectGrantRole)
          .map(role => ({
            value: role,
            label: role
          }))
        }
      />
    </Form>
  </Spin>
)

export default GrantUserForm