import { and, or } from 'ramda'

import { getExtensionContent } from 'actions/extensions'
import { buildConfigSelector } from 'selectors/project'

export const isBeingUsed = (getState, extension, version, vm) => {
  const buildConfig = buildConfigSelector(getState())
  const projectExtensions = buildConfig?.exts || []

  return projectExtensions.some(ext => and(
    ext.name === extension,
    or(ext.v === version, buildConfig.v === version),
    or(ext.bytecode === vm, buildConfig.bytecode === vm)
  ))
}

export const install = dispatch => async (api, extension) => {
  const content = await dispatch(getExtensionContent({ fileName: extension }))
  // TODO: what if the extension modifies and object ? I don't think we want that (transactions I mean)
  api.extension.install(content, extension)
}

const onEngineExtensionAdded = ({ extension, version, vm }) => async (dispatch, getState, { synchronizer }) => {
  if (!isBeingUsed(getState, extension, version, vm)) return

  const api = synchronizer.getEngineStore().getEngine().getAPI()
  await install(dispatch)(api, extension)
}

export default onEngineExtensionAdded
