import { append, reduce } from 'ramda'
import { model } from 'beanie-engine-api-js'
import UnrealCommandDefinition from 'model/sessions/clients/unreal/UnrealCommandDefinition'
import { EMPTY_ARRAY } from 'utils/object'
import requestSessionAction from '../requestSessionAction'

const { types: { object: { isClip } } } = model

const generateSequence = requestSessionAction(
  () => nodes => ({
    command: UnrealCommandDefinition.GenerateSequence.name,
    params: reduce((acc, obj) => (isClip(obj) ? append(obj.id, acc) : acc), EMPTY_ARRAY, nodes)
  })
)

export default generateSequence