import React from 'react'
import { pipe, identity } from 'ramda'

import { message } from 'antd'
import { Portal } from 'react-portal'
import { isEmpty, isEmptyObject, EMPTY_ARRAY } from 'utils/object.js'
import { Key, matchesStrictly } from 'utils/keyboard'
import position from './CaretPosition'
import { autoCompleteViewId } from 'dom/dom'
import SuggestionItem from './SuggestionItem'
import { nextIndex } from 'utils/list'
import sentry from 'services/sentry'

import { sortAndFilterByMatch } from 'utils/string'

import styles from './SuggestionPortal.scss'

const isSelectKey = keyCode => keyCode === Key.ENTER || keyCode === Key.TAB
const BLOCK = 'block'
const NONE = 'none'

class SuggestionPortal extends React.Component {
  contentRef = React.createRef()
  
  componentDidMount = () => { this.adjustPosition() }
  componentDidUpdate = () => { this.adjustPosition() }

  constructor(props) {
    super()
    const { callback } = props
    callback.onKeyDown = this.onKeyDown
    callback.onChange = this.onChange
    callback.isOpen = this.isOpen
    callback.closePortal = this.closePortal

    this.state = { selectedIndex: 0 }
  }

  nextIndex = (editor, delta) => nextIndex(this.getFilteredSuggestions(editor), this.state.selectedIndex, delta)

  updateSelection = newIndex => {
    this.setState({ selectedIndex: newIndex })
  }

  getSelectedSuggestion = editor => {
    const { selectedIndex } = this.state

    const filteredSuggestions = this.getFilteredSuggestions(editor)
    return filteredSuggestions.length ? filteredSuggestions[selectedIndex] : undefined
  }

  onEnter = (onEnter, next, editor) => {
    const suggestion = this.getSelectedSuggestion(editor)
    if (suggestion) {
      onEnter(suggestion, editor)
    } else {
      next()
    }
  }

  moveSelected = (editor, event, delta) => {
    event.preventDefault()
    this.updateSelection(this.nextIndex(editor, delta))
    return false
  }

  openPortalOnKeyDownHandler = (event, editor, next) => {
    const { keyCode, shiftKey } = event
    const { callback: { onEnter } } = this.props

    const suggestions = this.getFilteredSuggestions(editor)
    if (!isEmpty(suggestions)) {
      if (suggestions.length > 1 && matchesStrictly(event, Key.DOWN)) {
        return this.moveSelected(editor, event, +1)
      } else if (suggestions.length > 1 && matchesStrictly(event, Key.UP)) {
        return this.moveSelected(editor, event, -1)
      } else if (shiftKey) {
        return next()
      } else if (keyCode === Key.ESC) {
        this.closePortal()
        return false
      } else if (isSelectKey(keyCode) && !shiftKey) {
        event.preventDefault()
        this.closePortal()
        return this.onEnter(onEnter, next, editor)
      }
    }

    return next()
  }

  closedPortalOnKeyDownHandler = (event, editor, next) => {
    const { keyCode, ctrlKey } = event
    if (ctrlKey && keyCode === Key.SPACE) {
      this.openPortal()
      return false
    }

    return next()
  }

  onKeyDown = (event, editor, next) => ((this.isOpen()) ?
    this.openPortalOnKeyDownHandler(event, editor, next) :
    this.closedPortalOnKeyDownHandler(event, editor, next))

  onChange = (editor, next) => {
    this.updateSelection(0)
    return next()
  }

  filteredSuggestions = () => {
    const { editorRef: { current: editor } } = this.props
    return this.matchTrigger() ? this.getFilteredSuggestions(editor) : EMPTY_ARRAY
  }

  getFilteredSuggestions = editor => {
    const { suggestions, filterSuggestions } = this.props
    const { text } = editor.currentTextNode()

    try {
      return text ?
        pipe(sortAndFilterByMatch(text.toLowerCase().trim()), filterSuggestions || identity)(suggestions) :
        EMPTY_ARRAY
    } catch (err) {
      message.error('Oops! there was a problem generating autocomplete suggestions.')
      sentry.handleError(err)
      return EMPTY_ARRAY
    }

  }

  matchTrigger = () => {
    const { editorRef: { current: editor }, shouldHandleNode } = this.props
    return !isEmptyObject(editor) && 
      !isEmptyObject(editor.currentPath()) && 
      shouldHandleNode(editor, editor.currentNode()) && 
      !isEmpty(this.getFilteredSuggestions(editor)) &&
      editor.isFocused()
  }

  isOpen = () => this.contentRef.current && this.contentRef.current.style.display === BLOCK

  adjustPosition = () => {
    const menu = this.contentRef.current

    if (this.matchTrigger()) {
      const pos = position()
      const { scrollX, scrollY } = window
      this.openPortal()
      menu.style.opacity = 1
      menu.style.top = `${pos.top + scrollY}px` 
      menu.style.left = `${pos.left + scrollX}px`
    } else {
      this.closePortal()
    }
  }

  setDisplayPortal = display => {
    const menu = this.contentRef.current
    menu.style.display = display
  }

  openPortal = () => { this.setDisplayPortal(BLOCK) } 
  closePortal = () => { this.setDisplayPortal(NONE) }

  render = () => {
    const { selectedIndex } = this.state
    const { objectId } = this.props
    return (
      <Portal isOpened>
        <div ref={this.contentRef} className={styles.suggestionPortal} id={autoCompleteViewId(objectId)} tabIndex={-1}>
          <ul>
            {this.filteredSuggestions().map((suggestion, index) =>
              (<SuggestionItem
                key={`${suggestion}_${index}`}
                index={index}
                suggestion={suggestion}
                selectedIndex={selectedIndex}
                setSelectedIndex={this.updateSelection}
                callback={this.props.callback}
              />)
            )}
          </ul>
        </div>
      </Portal>
    )
  }
}

export default SuggestionPortal