import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'
import { makeAudioForLineSelector } from 'selectors/lines'
import { propFromProps } from 'selectors/props'
import { createIsAudioDirtySelector } from 'selectors/objectsMeta'

import styles from '../../SlateTextEditor.scss'
import LineAudioMenuItem from './LineMenu/LineAudioMenuItem'
import LineMenu from './LineMenu/LineMenu'
import LineSubtitlesButton from './LineMenu/LineSubtitlesButton'
import { isDialogueLine } from '../../model/Line'

const LineLeftBar = ({ mouseOver, ...props }) => {
  return (
    <div className={classNames(styles.LineLeftBar, { [styles.visible]: mouseOver })} contentEditable={false}>
      {mouseOver &&
        <LeftBarContent {...props} />
      }
    </div>
  )
}

export default LineLeftBar

const _LeftBarContent = ({ line, lineId, readOnly, onStoryboardChanged, deleteLine, isAudioDirty, node, audio, onAudioChanged }) => (
  <React.Fragment>
    <LineMenu
      line={line}
      lineId={lineId}
      readOnly={readOnly}
      onStoryboardChanged={onStoryboardChanged}
      deleteLine={deleteLine}
      isAudioDirty={isAudioDirty}
    />
    <LineAudioMenuItem
      lineId={lineId}
      node={node}
      isAudioDirty={isAudioDirty}
      audio={audio}
      readOnly={readOnly}
      onAudioChanged={onAudioChanged}
    />
    {
      isDialogueLine(node) &&
      <LineSubtitlesButton
        line={line}
        lineId={lineId}
      />
    }
  </React.Fragment>
)

const LeftBarContent = compose(
  connect(() => {
    const idFromProp = propFromProps('lineId')
    const audioForLineSelector = makeAudioForLineSelector(idFromProp)
    const isAudioDirtySelector = createIsAudioDirtySelector(idFromProp)
    return (state, props) => ({
      audio: audioForLineSelector(state, props),
      isAudioDirty: isAudioDirtySelector(state, props),
    })
  })
)(_LeftBarContent)