import React from 'react'
import { compose, onlyUpdateForKeys, withPropsOnChange, withState } from 'recompose'
import classNames from 'classnames'
import { connect, useStore } from 'react-redux'
import { identity, pipe, prop } from 'ramda'
import { DragSource, DropTarget } from 'react-dnd'
import { ContextMenu as BlueprintContextMenu } from '@blueprintjs/core'

import { withData, DataFields } from 'dom/dom'
import { sourceConnector, targetConnector } from 'utils/dnd'
import { DnDTypes } from 'dnd/model-dnd'
import whenRevisionModifiable from '../../../hocs/project/revision/whenRevisionModifiable'
import computeHoverPosition from '../../../utils/dnd/computeHoverPosition'
import { isHoveringBottom, isHoveringTop } from '../../../utils/dnd/position'

import { propFromProps } from 'selectors/props'
import { makeRootNrForLane } from 'selectors/objects'

import CollapseToggle from 'components/Commons/CollapseToggle'
import EditTextPopover from 'components/Commons/EditTextPopover'
import SecureScope from 'components/Commons/SecureScope'

import { reorderLane } from 'engine/actions/lanes'
import withSecurityScope from '../../../hocs/withSecurityScope'
import LaneContextMenu from '../../ContextMenu/LaneContextMenu/LaneContextMenu'

import styles from './TreeLane.scss'

const onlyIfCollapsed = prop('collapsed')

/**
 *
 */
class _TreeLaneTitle extends React.Component {

  render() {
    const { name, collapsed, numberOfRoots, hasWriteAccess, handleRename, toggleCollapse, connectDragSource = identity, connectDropTarget = identity, isHoveringTop: hoverAbove, isHoveringBottom: hoverBelow } = this.props
    const wrapper = onlyIfCollapsed(this.props) ? pipe(connectDropTarget, connectDragSource) : identity
    return wrapper(
      <div
        className={classNames(
          styles.title,
          {
            [styles.draggingOverLaneTop]: hoverAbove,
            [styles.draggingOverLaneBottom]: hoverBelow,
          }
        )}
        onDoubleClick={toggleCollapse}
        {...withData(DataFields.ownContextMenu, 'true')}
        onContextMenu={this.onContextMenu}
      >
        <div className={styles.titleInner}>
          <CollapseToggle
            className={styles.toggleCollapse}
            disposition="vertical"
            collapsed={collapsed}
            onCollapse={toggleCollapse}
            onExpand={toggleCollapse}
          />
          <EditTextPopover overlayClassName={styles.editLaneTitlePopover} value={name} onChanged={handleRename} disabled={!hasWriteAccess}>
            {name} {collapsed && <small>({numberOfRoots} chains)</small>}
          </EditTextPopover>
        </div>
      </div>
    )
  }

  onContextMenu = event => {
    event.preventDefault()
    BlueprintContextMenu.show(this.renderContextMenu(), { left: event.clientX, top: event.clientY }, this.onContextMenuClose)
  }

  renderContextMenu() {
    const { name, store, scope } = this.props
    return (
      <SecureScope scope={scope}>
        <LaneContextMenu store={store} lane={name} />
      </SecureScope>
    )
  }

}

const ConnectedTreeLaneTitle = compose(
  connect(() => {
    const rootNrForLane = makeRootNrForLane(propFromProps('name'))
    return (state, props) => ({
      numberOfRoots: rootNrForLane(state, props)
    })
  }, {
    reorderLaneAction: reorderLane,
  }),
  withSecurityScope,
  whenRevisionModifiable(
    compose(
      DragSource(
        DnDTypes.Lane,
        {
          beginDrag: ({ name }) => ({ id: name }),
          canDrag: onlyIfCollapsed,
        },
        sourceConnector
      ),
      withState('hoverPosition', 'setHoverPosition'),
      withPropsOnChange(['hoverPosition', 'isOver'], ({ hoverPosition, isOver }) => ({
        isHoveringTop: isOver && isHoveringTop(hoverPosition),
        isHoveringBottom: isOver && isHoveringBottom(hoverPosition),
      })),
      DropTarget(
        DnDTypes.Lane,
        {
          canDrop: onlyIfCollapsed,
          hover: ({ name, hoverPosition, setHoverPosition }, monitor, component) => {
            computeHoverPosition(name, hoverPosition, setHoverPosition, monitor, component)
          },
          drop: ({ name, reorderLaneAction, isHoveringBottom: below }, monitor) => {
            const dragged = monitor.getItem()
            if (dragged.id !== name) {
              reorderLaneAction(dragged.id, name, below)
            }
          }
        },
        targetConnector()
      ),
    )
  ),
  onlyUpdateForKeys([
    'name',
    'collapsed',
    'numberOfRoots',
    'isOver',
    'isDragging',
    'isHoveringTop',
    'isHoveringBottom',
  ])
)(_TreeLaneTitle)

const TreeLaneTitle = props => {
  const store = useStore()
  return <ConnectedTreeLaneTitle store={store} {...props} />
}

export default TreeLaneTitle
