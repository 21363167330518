import React, { useMemo } from 'react'
import { Button } from 'antd'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { model } from 'beanie-engine-api-js'

import useObject from '../../../../../hooks/beanie/useObject'

import { makeChoiceLangResourceSelector } from '../../../../../selectors/choices'
import GamepadChoiceButton from './gamepads/GamepadChoiceButton'

import styles from './CurrentChoicesPlayback.scss'

const { types: { choice: { isAutoChoice } } } = model

const textOrIndex = (langRes, idx) => ((langRes && langRes.data) ? langRes.data.text : `[${idx + 1}]`)

/**
 * A choice being shown as part of showing options to the user to select.
 */
const ChoicePlaybackItem = ({
  choiceId,
  isEnabled,
  firstPinnedChoiceId,
  isPaused,
  onSelected,
  index,
  gamepadType,
}) => {
  const enabledAndFirstPinned = isEnabled && firstPinnedChoiceId
  const choiceIsTheFirstPinned = enabledAndFirstPinned && choiceId === firstPinnedChoiceId
  const choiceIsDisabledByOtherPin = enabledAndFirstPinned && choiceId !== firstPinnedChoiceId

  const choice = useObject(choiceId)

  const langResSelector = useMemo(() => makeChoiceLangResourceSelector(choiceId), [choiceId])
  const langRes = useSelector(langResSelector)

  return (
    <li
      key={choiceId}
      className={classNames(styles.choiceItem, {
        [styles.autoChoice]: !choiceIsTheFirstPinned && isAutoChoice(choice)
      })}>
      <Button
        className={classNames({
          [styles.pinnedChoice]: choiceIsTheFirstPinned,
        })}
        disabled={isPaused || choiceIsDisabledByOtherPin}
        onClick={() => onSelected(index)}>
        {gamepadType && <GamepadChoiceButton type={gamepadType} choiceIndex={index} />}
        {textOrIndex(langRes, index)}
      </Button>
      {choiceIsTheFirstPinned && <div className={styles.pinnedChoiceLegend}>pinned</div>}
      {isAutoChoice(choice) && <div className={styles.autoChoiceLegend}>auto</div>}
    </li>
  )
}

export default ChoicePlaybackItem