import React, { createContext } from 'react'

/* eslint react/no-unused-state: 0 */

const ModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {}
})

export const ModalConsumer = ModalContext.Consumer

export class ModalProvider extends React.Component {
  showModal = (component, props = {}) => {
    this.setState({ component, props })
  }
  hideModal = () => {
    this.setState({ component: null, props: {} })
  }

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal
  }

  render() {
    const { children } = this.props
    const { component: Component, props } = this.state
    return (
      <ModalContext.Provider value={this.state}>
        {children}
        {Component ? <Component {...props} hide={this.hideModal} /> : null}
      </ModalContext.Provider>
    )
  }
}
