import React from 'react'
import { propEq } from 'ramda'

import { NotificationType } from 'model/constants'
import Notification from 'components/NotificationsIndicator/Notification'

const pdfJobErrorNotifier = {
  accepts: propEq('type', NotificationType.PDF_ERROR),
  render: notification => {
    const { payload: { jobId } } = notification
    return (<Notification
      key={notification._id}
      notification={notification}
      title="Requested PDF Failed"
      message={`There was an error generating the requested PDF (${jobId})`}
    />)
  }
}

export default pdfJobErrorNotifier
