import React, { useCallback, useState } from 'react'

import { propEq } from 'ramda'
import { isBranch } from 'utils/project'
import { Spin, Icon, Menu, Dropdown } from 'antd'
import styles from '../ProjectCard.scss'
import withVersions from './withVersions'


const BranchesMenu = ({ loading, versions, branch, doSelect }) => {
  const onSelect = useCallback(({ key }) => {
    doSelect(versions.find(propEq('_id', key)))
  }, [versions])

  const branches = versions.filter(isBranch)
  const master = branches.find(propEq('version', 'master')) || {}
  const others = branches.filter(({ version: versionName }) => versionName !== 'master')

  return (
    <Spin spinning={loading}>
      <Menu
        selectedKeys={[branch._id]}
        mode="vertical"
        className={styles.projectRevisionsMenu}
        onSelect={onSelect}
      >
        <Menu.Item key={master._id}>{master.version}</Menu.Item>
        {others.length > 0 && <Menu.Divider />}
        {
          others.map(({ _id, version: versionName }) => (
            <Menu.Item key={_id}>{versionName}</Menu.Item>
          ))
        }
      </Menu>
    </Spin>
  )
}

const BranchesMenuWithVersions = withVersions(BranchesMenu)

const BranchSelector = ({ project, setRevisionId, branch, setBranch, setCheckpoint }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={setVisible}
      overlay={
        <BranchesMenuWithVersions
          project={project}
          branch={branch}
          doSelect={
            selectedBranch => {
              setCheckpoint({ _id: selectedBranch._id, version: 'latest', modifiable: false, sourceBranch: selectedBranch })
              setRevisionId(selectedBranch._id)
              setBranch(selectedBranch)

              setVisible(false)
            }
          }
        />
      }
    >
      <span className={styles.revisionSelector}><Icon type="branches" /> {branch.version}</span>
    </Dropdown>
  )
}

export default BranchSelector