import React, { useContext, useMemo } from 'react'

import IconButton from '../Commons/IconButton'
import { FactDrawerContext } from '../FactDrawer/FactDrawerContext'

/**
 *
 */
const AddFactButton = ({ onAddFact }) => {
  const { isOpen, open } = useContext(FactDrawerContext)

  const drawerProps = useMemo(() => ({
    title: 'Add Fact',
    okButtonLabel: 'Add',
    onAccept: onAddFact
  }))

  return (
    <IconButton
      type="plus"
      tooltip="Add a new Fact"
      onClick={() => { open(drawerProps) }}
      disabled={isOpen}
    />
  )
}

export default AddFactButton