import { connect } from 'react-redux'
import { last } from 'ramda'
import collapseNode from 'actions/nodes/collapsing/collapseNode'
import expandNode from 'actions/nodes/collapsing/expandNode'
import { compose, withHandlers } from 'recompose'

// TODO: TEST ME
const withOnCollapseOnExpand = () => compose(
  connect(null, {
    collapse: collapseNode,
    expand: expandNode,
  }),
  withHandlers({
    onExpand: ({ id, children, expand }) => event => {
      event.preventDefault()
      event.stopPropagation()
      expand(children.chain.length === 0 ? id : last(children.chain)?.id)
    },
    onCollapse: ({ collapse }) => (id, event) => {
      event.preventDefault()
      event.stopPropagation()
      collapse(id)
    }
  }),
)

export default withOnCollapseOnExpand