import { equalsIgnoreCase } from 'utils/string'

const fullName = ({ firstName, lastName }) => (
  equalsIgnoreCase(firstName.trim(), lastName.trim()) ?
    firstName : `${firstName} ${lastName}`
)

const UserName = ({ user }) => (
  user.firstName && user.lastName ?
    fullName(user) : (user.name || user.email)
)

export default UserName