import { MAX_CHANGESET_SIZE, splitChangeSet, applyChangeSetToStore, BNEStore, BNEEngine } from 'beanie-engine-api-js'

import { sequence } from 'utils/promises'
import WaitingSubject from 'observables/WaitingSubject'

import { objectsIndex } from 'selectors/apollo'
import { revisionId } from 'selectors/project'
import { txOptions } from 'selectors/transactions'
import { propFrom } from 'utils/object'

/**
 * A store implementation over beanie LUA/JS engine API.
 * Makes it polymorphic with ReduxStore and ServerStore to be used
 * by StateSynchronizer.
 * It uses beanie journaling to create ChangeSets for transactions
 */
class EngineStore {

  constructor(bneStore, store) {
    this.bneStore = bneStore
    this.store = store

    this.subject = new WaitingSubject()

    this.transactionCounter = 0
  }

  // I don't like this but because of the inter-dependencies to setup things we
  // need to be able to set it later.
  setStore(store) { this.store = store }

  //

  async apply(changeSet) {
    await applyChangeSetToStore(this.bneStore, changeSet)
  }

  //
  // public API for code using the engine
  //

  getEngine() { return this.bneStore.getEngine() }
  getBNEStore() { return this.bneStore }

  mutate(description, fn) {
    // this now supports nested transactions like for potential problems in idle-dnd
    // but the rollback strategy in this case is not so easy to handle
    // I think that ideally we shouldn't support nesting like this.
    // the UI must be in control of transactions and make sure it just opens a single transaction
    return this.store.dispatch(async (dispatch, getState, context) => {
      // console.log(`[TX-"${description}"] RUNNING`)
      const state = getState()
      const revision = revisionId(state)

      this.transactionCounter++

      // we need to start a transaction
      if (this.transactionCounter === 1) {
        // console.log(`[TX-"${description}"] STARTING NEW TRANSACTION`)
        this.startTransaction(revision)
      }

      let endedTransaction = false
      try {
        const returnValue = await fn(this.getAPI(), dispatch, getState, context)

        this.transactionCounter--

        // we need to commit
        if (this.transactionCounter === 0) {
          // console.log(`[TX-"${description}"] COMMITTING`)
          const changes = this.getEngine().endTransaction(revision, this.getTxOptions())
          endedTransaction = true
          await this._doReceiveFromBNEIfNeeded({
            project: revision,
            description,
            changes
          })
        }

        return returnValue
      } catch (error) {
        // REVIEWME: is it ok to restart the whole thing ? I guess so
        // any previous work will be rollback-ed and this still will blow up
        this.transactionCounter = 0

        if (endedTransaction) {
          this.getEngine().rollbackLastTransaction()
        } else {
          this.getEngine().rollbackTransaction()
        }

        // eslint-disable-next-line no-console
        console.error(`[TX: ${description}] ERROR: ${error?.message}`, error)
        throw error
      }
    })
  }

  // Transactions API (fine-grained API)

  startTransaction(revision) {
    this.getEngine().startTransaction(revision)
  }

  // this gets called by LUA when auto-starting a new transaction.
  // it will be better to push all this down to the engine journaling and BNEEngine
  // to remove this complexity from here.
  async finishTransaction(revision, description) {
    const changeSet = {
      // TODO: rename to revision in the whole architecture
      project: revision,
      description,
      changes: this.getEngine().endTransaction(revision, this.getTxOptions())
    }
    // TODO: to be consistent with "mutate()" we should be calling _doReceiveFromBNEIfNeeded()
    await this._commit(changeSet)
  }

  //
  // observers
  //
  subscribe(...args) { this.subject.asObservable().subscribe(...args) }

  //
  // Internal API
  //

  _doReceiveFromBNEIfNeeded = async changeSet => {
    if (changeSet && changeSet.changes.length > 0) {
      const sets = changeSet.changes.length > MAX_CHANGESET_SIZE ? splitChangeSet(changeSet) : [changeSet]
      await sequence(sets.map(cset => () => this._commit(cset)))
    }
  }

  // publish to observers (StateSynchronizer, which then pushes to other stores)
  async _commit(changeSet) {
    await this.subject.next(changeSet)
  }

  getResolver = () => propFrom(objectsIndex(this.store.getState()))
  getAPI = () => this.bneStore.getEngine().getAPI()

  getTxOptions = () => txOptions(this.store.getState())

  // just for testing
  toArray() {
    return this.getBNEStore().toArray()
  }

}

export const createEngineStore = () => new EngineStore(new BNEStore(new BNEEngine()))

export default EngineStore