import React, { useCallback, useState } from 'react'

import { message } from 'antd'
import { pathOr } from 'ramda'
import { useDispatch } from 'react-redux'
import { lang, model } from 'beanie-engine-api-js'
import BeanieLanguageEditor from 'components/Language/Rules/BeanieLanguageEditor'
import { updateActionEffect } from 'engine/actions/action2'
import { stopPropagation } from 'dom/events'

const { types: { object: { Paths } } } = model

const { rule: { utils: { isExpression }, typing: { types }, parser: parse } } = lang

import styles from './Action2Editor.scss'

const Action2Editor = ({ node, onCancel }) => {
  const dispatch = useDispatch()

  const [source, setSource] = useState(pathOr('', Paths.action2.source, node), [node])

  const onApply = useCallback(async (cm, code) => {
    const effect = parse(code)
    if (isExpression(effect)) {
      message.error('Error trying to set an expression instead of an effect')
      return
    }

    await dispatch(updateActionEffect(node.id, code, effect))
    onCancel()
  }, [])

  return (
    <div
      className={styles.editorContainer}
      onClick={stopPropagation}>
      <BeanieLanguageEditor
        className={styles.editor}
        expectedType={types.Void}
        itValue={node?.id}
        autoFocus

        value={source}
        setValue={setSource}

        onApply={onApply}
        onBlur={onCancel}
        onCancel={onCancel}
      />
    </div>
  )
}

export default Action2Editor