import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import moment from 'moment'
import { Alert, Icon, notification, Layout } from 'antd'
import { isChrome } from 'react-device-detect'
import { Helmet } from 'react-helmet'

import { appVersion as appVersionSelector } from 'selectors/appMeta'
import frontendVersionQuery from 'api/queries/frontendVersionQuery.graphql'
import LoginForm from './LoginForm'
import ForgotPasswordForm from 'components/ForgotPassword/ForgotPasswordForm'
// import { hardRefresh } from 'utils/browser'

import styles from './Login.scss'

const { Header, Content, Footer } = Layout

const Login = ({ toLogin, showLogin, showResetInfo, toggleShowLogin }) => (
  <Layout className={styles.loginLayout}>
    <Helmet title="Beanie Studio - Shape your story™" />
    <Header>
      <div className={styles.title}>
        <img className={styles.logo} src="/images/logo-full-white-tm.png" alt="Beanie Studio - Shape your story™" />
      </div>
    </Header>
    <Content>
      {showResetInfo &&
        <Alert className={styles.forgotPasswordInfo} type="info" message="Check your inbox for an email containing the link you should follow to reset your password." />
      }
      {!isChrome &&
        <Alert type="warning" message="⚠️ You should use the Google Chrome browser to avoid some known unexpected errors with other browsers." />
      }
      {
        showLogin ?
          <LoginForm toForgotPassword={toggleShowLogin} />
          :
          <ForgotPasswordForm toLogin={toLogin} />
      }
    </Content>
    <Footer>
      <div className={styles.footer} >
        <p>&copy; <b>{moment().format('YYYY')} Bruner House</b> &#8226; Terms &#8226; Privacy</p>
      </div>
    </Footer>
  </Layout>
)

const forcingUpdate = 'version-checker-forcing-update'

const forceAppUpdateIfNewVersionDetected = ({ frontendVersion, appVersion }) => {
  if (appVersion && frontendVersion && !frontendVersion.loading && !frontendVersion.error && frontendVersion.frontendVersion?.latestVersion !== appVersion) {
    notification.open({
      key: forcingUpdate,
      message: 'Updating beanie',
      description: `New version was detected. Reload to update to newest version ${frontendVersion.frontendVersion?.latestVersion}.`,
      icon: <Icon type="warning" style={{ color: 'orange' }} />,
      closeIcon: null,
      duration: 0,
    })
    // give time for the user to see and read the message.
    // setTimeout(hardRefresh, 1000)
  }
}

export default compose(
  connect(state => ({ appVersion: appVersionSelector(state) })),
  graphql(frontendVersionQuery, { name: 'frontendVersion' }),
  withStateHandlers(
    { showLogin: true, showResetInfo: false },
    {
      toggleShowLogin: prev => () => ({ ...prev, showLogin: !prev.showLogin }),
      toLogin: prev => ({ sentResetLink: showResetInfo = false } = {}) => ({ ...prev, showLogin: !prev.showLogin, showResetInfo }),
    }
  ),
  lifecycle({
    componentDidMount() {
      forceAppUpdateIfNewVersionDetected(this.props)
    },
    componentDidUpdate() {
      forceAppUpdateIfNewVersionDetected(this.props)
    }
  })
)(Login)
