import Contexts from 'shortcuts/contexts'

import { pasteAsConditionalFromChoicesAction } from 'engine/actions/conditionalFromChoices'
import { pasteAsTruthTableFromChoicesAction } from 'engine/actions/truthTableFromChoices'
import { isCurrentRevisionWritable } from 'security/project'
import { canPasteChoicesAs } from 'selectors/clipboard'
import Groups from './Groups'

const commonProps = {
  icon: 'anticon-export',
  group: Groups.Editing,
  context: Contexts.Tree,
  enabledSelector: canPasteChoicesAs,
  check: isCurrentRevisionWritable
}

export const PASTE_AS_CONDITIONAL = {
  ...commonProps,
  label: 'Paste as Conditional',
  shortcut: { osx: 'command+option+v', windows: 'ctrl+alt+v', linux: 'ctrl+alt+v' },
  action: pasteAsConditionalFromChoicesAction,
}

export const PASTE_AS_TRUTH_TABLE = {
  ...commonProps,
  label: 'Paste as Truth Table',
  action: pasteAsTruthTableFromChoicesAction,
}