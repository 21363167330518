exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2SWUn{color:#add8e6;cursor:pointer}.iconAsLink__2SWUn:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__1XB8X{font-style:italic}.formFieldsVerticalGutter__1_bso{padding:.5rem}.formFieldsVerticalGutter__1_bso>*{margin:.5rem !important}.tableBackground__1NPRw .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1NPRw .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__q2vPQ .ant-notification-notice-close{display:none}.playbackCanvas__3Zx_t{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;height:100%;-ms-flex:1 1 auto;flex:1 1 auto;-ms-flex-pack:justify;justify-content:space-between}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2SWUn",
	"labelFromRef": "labelFromRef__1XB8X",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1_bso",
	"tableBackground": "tableBackground__1NPRw",
	"notificationNotClosable": "notificationNotClosable__q2vPQ",
	"playbackCanvas": "playbackCanvas__3Zx_t"
};