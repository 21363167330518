import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Icon, Menu } from 'antd'

import { revisionId as revisionIdSelector, loadedAt } from 'selectors/project'
import routes from 'components/Organizations/routes'
import LoadingProject from './LoadingProject'
import styles from './ProjectSettings.scss'

const { Item } = Menu

const ProjectSettings = ({ currentSection, sections, children, onSectionChanged, revisionId, isProjectLoaded }) => (
  <div className={styles.projectSettings}>
    <h1><Icon type="setting" />Project Management</h1>

    <div className={styles.content}>
      <Menu mode="inline" defaultSelectedKeys={[currentSection]} onClick={({ key }) => onSectionChanged(key)}>
        {sections.map(s => (
          <Item key={s.name}><Link to={routes.projectSettingsForSection(revisionId, s.name)}>{s.label}</Link></Item>
        ))}
      </Menu>

      <div className={styles.sections}>
        {isProjectLoaded && children}
        {!isProjectLoaded && <LoadingProject />}
      </div>
    </div>
  </div>
)

export default connect(
  state => ({
    revisionId: revisionIdSelector(state),
    isProjectLoaded: !!loadedAt(state)
  })
)(ProjectSettings)
