import React from 'react'
import classNames from 'classnames'
import { Tooltip, Icon } from 'antd'

import styles from './TreeView.scss'

// receives `error` prop, not currently using it
const TreeErrorElement = ({ node, id }) => (
  <div className={classNames(styles.bneContainerRow, styles.nodeContainer)}>
    <div
      className={classNames(
        styles.bneContainerRow,
        styles.bneNode,
        styles.error
      )}
      tabIndex="-1"
      role="button"
    >
      <Tooltip title={node ? node.id : id}>
        <Icon type="warning" /> ERROR
      </Tooltip>
    </div>
  </div>
)

export default TreeErrorElement