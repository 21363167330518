import React from 'react'
import LinkButton from 'components/Commons/LinkButton'
import { Icon } from 'antd'

const Button = ({ type, onClick, description, disabled, style, className, spin }) => (
  <LinkButton tooltip={description} onClick={onClick} disabled={disabled} className={className}>
    <Icon type={type} style={style} spin={spin} />
  </LinkButton>
)

export default Button