import React from 'react'
import { Alert } from 'antd'

import States from './ForgotPasswordState'
import InvitePage from 'components/InvitesAccess/InvitePage'
import ChangePasswordForm from 'components/Users/ChangePasswordForm.jsx'

import styles from './ForgotPasswordAccess.scss'

const ForgotPasswordAccess = props => {
  const Content = resolvePageByState(props.state)
  return (
    <InvitePage><Content {...props} /></InvitePage>
  )
}
export default ForgotPasswordAccess

const resolvePageByState = state => componentByState[state] || UnknownState

const ValidToken = ({ waitingReset, onNewPassword, error }) => (
  <div className={styles.forgotPasswordPanel}>
    <h1>Set your new password</h1>
    {error &&
      <Alert type="error" message={error} />
    }
    <ChangePasswordForm mandatory onPasswordChanged={onNewPassword} spinButton={waitingReset} />
  </div>
)

const Resetted = () => (
  <div className={styles.forgotPasswordPanel}>
    <h1>Great!</h1>
    <p>
      You have resetted your Beanie password successfully.
    </p>
    <p>
      Now try to <a href="/">sign-in</a> with the new password and keep up creating awesome stories!
    </p>
  </div>
)

const ValidatingToken = () => (
  <div>
    <h1>Loading ...</h1>
    <p>Checking token, please wait a moment</p>
  </div>
)

const InvalidToken = ({ invalidReason }) => (
  <div>
    <h1>Error </h1>
    <p>Token is invalid: {invalidReason}</p>
  </div>
)

const UnknownState = ({ state }) => <div>Error ! invalid state {state}</div>

const componentByState = {
  [States.Starting]: ValidatingToken,
  [States.Validating]: ValidatingToken,
  [States.ValidToken]: ValidToken,
  [States.InvalidToken]: InvalidToken,
  [States.Resetted]: Resetted,
}