import { EventKey, Key } from 'utils/keyboard'
import { splitAt, propEq, last } from 'ramda'
import { NODE_TYPES } from '../../Constants'
import { serializeListValue, serializeListElement, listElementSeparator } from '../../../modelToText/MarkupSerializer'
import { findDescendentsOfType } from '../../nodeUtils'
import { textNode as textToTextNode } from '../../slateMocks/textNode'
import { isRichText } from './MarkupRichTextValuePlugin'


export const isTheLastListItem = (editor, item) => {
  const listNode = editor.parent(item)
  return last(findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)(listNode)) === item
} 

const itemByIndex = (root, index) => {
  const elements = findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)(root)
  return elements[index]
}

const moveCursorToListElement = (editor, root, index) => {
  editor.moveToStartOfNode(itemByIndex(root, index))
}

const listElementIndexOf = (root, { key }) => {
  const elements = findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)(root)
  return elements.findIndex(propEq('key', key))
}

const switchToSimpleValue = (editor, listNode, stringValues, listElement) => {
  const { offset } = editor.currentFocus()
  editor.replaceNodeWith(listNode, textToTextNode(stringValues[0]))
  moveCursorToOffsetAfterToRemove(editor, offset, itemByIndex(listNode, listElementIndexOf(listNode, listElement) - 1))
}

const moveCursorToOffsetAfterToRemove = (editor, offset, previousItem) => {
  editor.moveTo(previousItem && (offset === 0) ? previousItem.text.length : offset)
}

const moveAsSimpleCommaRemove = (editor, listNodeOutdated, currentElements, elemIndex) => {
  const previousItemOutdated = itemByIndex(listNodeOutdated, elemIndex - 1)
  editor.moveToStartOfNode(currentElements[elemIndex - 1])
  editor.moveTo(previousItemOutdated.text.length)
}

const updateAsSelectionRemove = (editor, listNodeOutdated, currentElements, elemIndex, selection) => {
  const { focus: { offset } } = selection
  const previousItemOutdated = itemByIndex(listNodeOutdated, elemIndex - 1)
  editor.moveToStartOfNode(currentElements[elemIndex])
  moveCursorToOffsetAfterToRemove(editor, offset, previousItemOutdated)
}

const updateListValue = (editor, listNode, stringValues, listElement) => {
  const { selection } = editor.value
  const { focus: { offset } } = selection
  const { key } = editor.parent(listNode)
  const elemIndex = listElementIndexOf(listNode, listElement)

  const moveCursorFunc = offset === 0 && editor.focusIsAtStartOfNode(listElement) ? 
    moveAsSimpleCommaRemove : updateAsSelectionRemove

  editor.replaceNodeWith(listNode, serializeListValue(stringValues))

  const root = editor.nodeByPath(editor.pathByKey(key))
  const elements = findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)(root)

  moveCursorFunc(editor, listNode, elements, elemIndex, selection)
}

const updateListNodeAfterRemove = (editor, listNode, listElement) => {
  if (!listNode) return
  const stringValues = listNode.text.split(EventKey.COMMA)
  const elements = findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)(listNode)
  if (elements.length > stringValues.length) {
    const func = (stringValues.length === 1 ? switchToSimpleValue : updateListValue)
    func(editor, listNode, stringValues, listElement)
  }
}

export default () => ({

  commands: {

    switchToListValue: (editor, valueNode) => {
      const textNode = valueNode.nodes.get(0)
      const stringValues = splitAt(editor.currentFocus().offset, textNode.text)
      editor.replaceNodeWith(textNode, serializeListValue(stringValues))
      const updatedValue = editor.nodeByPath(editor.pathByKey(valueNode.key))
      moveCursorToListElement(editor, updatedValue, 1)
    },

    insertNewListElement: (editor, listElement) => {
      const listNode = editor.parent(listElement)
      const listElementIndex = listElementIndexOf(listNode, listElement)
      const i = listNode.nodes.findIndex(propEq('key', listElement.key))
      const { offset } = editor.currentFocus()

      const [firstNode, secondNode] = splitAt(offset, listElement.text).map(serializeListElement)

      editor.replaceNodeWith(listElement, firstNode)
      editor.insertChildAt(listNode, i + 1, secondNode)
      editor.insertChildAt(listNode, i + 2, listElementSeparator())

      moveCursorToListElement(editor, editor.nodeByPath(editor.pathByKey(listNode.key)), listElementIndex + 1)
    }
  },

  onKeyDown (event, editor, next) {
    const currentNode = editor.currentNode()
    
    if (event.key === EventKey.COMMA) {
      // comma pressed
      const { text } = editor.currentTextNode()
      if (isRichText(text) && !editor.focusIsAtStartOfNode(currentNode) && !editor.focusIsAtEndOfNode(currentNode)) return next()

      if (currentNode.type === NODE_TYPES.MARK_UP_PARAMETER_VALUE) {
        event.preventDefault()
        event.stopPropagation()
        editor.switchToListValue(currentNode)
        return false
      } else if (currentNode.type === NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT) {
        event.preventDefault()
        event.stopPropagation()
        editor.insertNewListElement(currentNode)
        return false
      }
    }

    if (event.keyCode === Key.BACKSPACE && currentNode.type === NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT) {
      event.preventDefault()
      event.stopPropagation()
      const listNode = editor.parent(currentNode)
      next()
      updateListNodeAfterRemove(editor, editor.nodeByPath(editor.pathByKey(listNode.key)), currentNode)
      return false
    }
    return next()
  }

})