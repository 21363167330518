import React from 'react'
import useObjectSelection from 'hooks/beanie/useObjectSelection'
import BneObjectPropertiesEditor from './BneObjectPropertiesEditor'

/**
 * A PropertiesEditor wrapper that edits the currently selected node.
 */
const BneNodePropertiesEditor = props => {
  const [object, selectObject] = useObjectSelection()

  return (
    <BneObjectPropertiesEditor
      {...props}
      object={object}
      onNavigateRef={selectObject}
    />
  )
}

export default BneNodePropertiesEditor
