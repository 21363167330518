import React from 'react'
import { AutoComplete } from 'antd'
import { compose, withState, withHandlers } from 'recompose'
import { nthArg, pipe, prop } from 'ramda'
import { Key } from 'utils/keyboard'
import { isEmptyOrNull } from 'utils/string'

const { Option } = AutoComplete

const AutocompleteEditComponent = ({ textInput, filteredOptions, updateSearchText, value, onChange, save, onKeyUp, refToComponent }) => (
  <div onKeyUp={onKeyUp}>
    <AutoComplete
      autoFocus
      ref={refToComponent}
      defaultValue={value}
      value={textInput}
      onChange={pipe(nthArg(0), updateSearchText)}
      onSelect={e => { onChange(e); save() }}
      size="small"
      placeholder="Type name"
    >
      {filteredOptions.map(o => (
        <Option key={o.key} value={o.value}>
          {o.label}
        </Option>
      ))}
    </AutoComplete>
  </div>
)

export default compose(
  withState('textInput', 'setTextInput', prop('value')),
  withState('filteredOptions', 'setFilteredOptions', prop('options')),
  withHandlers({
    updateSearchText: ({ options, setTextInput, setFilteredOptions }) => text => {
      setTextInput(text)
      setFilteredOptions(isEmptyOrNull(text) ? options : options.filter(o => o.label.toUpperCase().indexOf(text.toUpperCase()) >= 0))
    },
    onKeyUp: ({ cancel }) => e => {
      if (e.keyCode === Key.ESC) {
        e.preventDefault()
        e.stopPropagation()
        cancel()
      }
    }
  })
)(AutocompleteEditComponent)
