import { T } from 'ramda'
import { parseRef } from 'beanie-engine-api-js'

import { objectsIndex } from 'selectors/apollo'
import createQueryContext from '../../query/context/createQueryContext'
import queryToPredicate from '../../query/queryToPredicate'
import makeSearcher from './makeSearcher'
import searchInSource from './sources/searchInSource'
import { getMatchSourceFor } from './MatchSourcesBySys'

/**
 * Creates and returns a Collector function that will be evaluated over an object (and its node maybe).
 * You can use that function later to pass every object you want (depending on how you visit the project)
 * or which part/segment of objects you want to evaluate.
 * And this function takes care of
 * - matching the text
 * - making SearchResult objects
 * - pushing them by using `pushResult`
 *
 * This function knows what to evaluate within each object. Mainly:
 * - its label
 * - some particular fields depending on the type of object. For example within langRes notes
 */
const matchCollectorFactory = ({ text, query }, getState, pushResult) => {
  const predicate = query ? queryToPredicate(query) : T

  const objectsById = objectsIndex(getState())
  const resolve = ref => objectsById[parseRef(ref)]

  const context = createQueryContext(getState)

  // we create a search fn partially that will be later applied (below) with each object
  // and later with a text to search (extracted from MatchSources)
  const partialSearch = makeSearcher(text, pushResult)

  return (object, node) => {
    if (predicate(object, node, context)) {

      getMatchSourceFor(object)
        .forEach(searchInSource(resolve, object, partialSearch(object, node)))
    }
  }
}

export default matchCollectorFactory