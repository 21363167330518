import React from 'react'
import { Icon, Tooltip } from 'antd'
import classNames from 'classnames'
import { CHECK_SEVERITY_LEVEL } from 'providers/Checker/CheckSelectors/CheckSelectors'
import { EMPTY_OBJECT } from 'utils/object'

import styles from '../TreeView/TreeChain/TreeChain.scss'
import severityStyles from './ProblemSeverity.scss'

const customStyleByType = {
  [CHECK_SEVERITY_LEVEL.ERROR]: styles.issueError,
  [CHECK_SEVERITY_LEVEL.WARNING]: styles.issueWarning,
  [CHECK_SEVERITY_LEVEL.INFO]: styles.issueInfo,
}

const ProblemSeverity = ({ severity, className = EMPTY_OBJECT }) => (
  <div className={classNames(severityStyles.container, className)}>
    <Tooltip title={severity}>
      <Icon type="exclamation-circle" className={classNames(styles.issueIcon, customStyleByType[severity])} theme="filled" />
    </Tooltip>
  </div>
)

export default ProblemSeverity