exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ReadOnlyRevisionMessage__G2QRU{margin-left:1rem;color:#baad48}.ReadOnlyRevisionMessage__G2QRU .anticon{margin-right:.4rem}", ""]);

// exports
exports.locals = {
	"ReadOnlyRevisionMessage": "ReadOnlyRevisionMessage__G2QRU"
};