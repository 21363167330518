import React from 'react'

import { always } from 'ramda'
import UpdatableImage from '../../../../Commons/UpdatableImage'
import styles from './ProjectCoverImage.scss'
import { urlForImage } from 'model/resources'

const defaultCoverUrl = '/images/default-project-cover.png'

export default ({ projectData, handleProjectCoverImageChanged, token }) => {
  const { projectWithId: { coverImage, _id, id } } = projectData
  const imageFile = coverImage ? urlForImage(token, _id || id, coverImage) : defaultCoverUrl
  return (
    <div className={styles.projectImage} >
      <UpdatableImage
        readOnly={false}
        image={imageFile}
        onChange={handleProjectCoverImageChanged}
        srcImage={imageFile}
        imagePath={path => path}
        imageAlt={always('Cover')}
        />
      <h4 className={styles.imageTitle}>Cover</h4>
    </div>
  )
}
