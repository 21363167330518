exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".dangerSection__1_LvE .ant-card-bordered{border-radius:1rem}.dangerSection__1_LvE .ant-card-bordered .ant-card-head{background-color:rgba(255,0,0,.23);min-height:auto;border-top-left-radius:1rem;border-top-right-radius:1rem;color:#966868}.dangerSection__1_LvE .ant-card-bordered .ant-card-head .ant-card-head-title{padding-top:8px;padding-bottom:8px}.dangerSection__1_LvE .ant-card-bordered .ant-card-head .ant-card-head-title .anticon{margin-right:.5rem}.dangerSection__1_LvE p{padding-top:.2em}.dangerSection__1_LvE .dangerItem__1YKBV:not(:last-child){border-bottom:1px solid #efefef;padding-bottom:1rem;margin-bottom:1rem}.dangerSection__1_LvE .dangerItem__1YKBV .ant-btn{min-width:7rem}", ""]);

// exports
exports.locals = {
	"dangerSection": "dangerSection__1_LvE",
	"dangerItem": "dangerItem__1YKBV"
};