import React, { useCallback } from 'react'
import { branch, compose } from 'recompose'
import { identity, pipe, prop, not } from 'ramda'
import classNames from 'classnames'
import { Icon } from 'antd'
import ProjectImage from 'components/Commons/ProjectImage'
import { ModalConsumer } from 'components/Commons/Modal'

import { DragSource } from 'react-dnd'
import { source, sourceConnector } from 'utils/dnd'
import { DnDTypes } from 'dnd/model-dnd'
import useProjectImage from '../../hooks/beanie/useProjectImage'

import styles from './UpdatableImage.scss'

import SetStoryboardModal from 'components/SetStoryboardModal/SetStoryboardModal'

// keep this, if you fix the div that has onClick with tabOrder & role, then DND doesn't work anymore
/* eslint jsx-a11y/no-static-element-interactions: 0 */

const _UpdatableImage = ({ imagePath = identity, imageAlt = identity, srcImage, readOnly, onRemove, image, onChange = () => {}, connectDragSource, isDragging, urlResolver }) => {
  const dndWrapper = _ => (image ? connectDragSource(_) : _)

  const maybeModal = readOnly ?
    content => content()
    : content => (
      <ModalConsumer>
        {({ showModal }) => dndWrapper(content(() => {
            showModal(SetStoryboardModal, { onFileProvided: onChange })
          }))}
      </ModalConsumer>
    )

  const url = useProjectImage(imagePath(image), srcImage, urlResolver)

  const openImage = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(url, '_blank').focus()
  }, [url])

  return maybeModal(onClick => (
    <div
      className={classNames(styles.content, { [styles.readOnly]: readOnly })}
      contentEditable={false}
      onClick={onClick}
      >
      {image && !isDragging ?
        <ProjectImage srcImage={url} alt={imageAlt(image)} />
          : <ImagePlaceholder />
        }

      {!isDragging && !readOnly &&
        <div className={styles.overlay} contentEditable={false}>
          <div className={styles.changeCaption}>
            <Icon type="picture" />
            <span contentEditable={false}>{image ? 'Change' : 'Set'}</span>
          </div>

          {image && onRemove &&
            <div className={styles.upperLeftCorner} role="button" tabIndex={0} onClick={onRemove}>
              <Icon type="close" />
            </div>
          }
          {image && (
            <div className={styles.upperRightCorner} role="button" onClick={openImage}>
              <Icon type="fullscreen" />
            </div>
          )}
        </div>
      }
    </div>
  ))
}

const ImagePlaceholder = () => <div className={styles.noImage} contentEditable={false} />

export default compose(
  branch(
    pipe(prop('readOnly'), not),
    DragSource(DnDTypes.Storyboard, source(), sourceConnector),
  )
)(_UpdatableImage)