import { currentDebugScenarioSelected, currentDebugSettings, isPinned } from 'selectors/debuggingData'
import { revisionId } from 'selectors/project'
import { model, isContained, Sys, parseRef } from 'beanie-engine-api-js'

import { equals, filter, isEmpty, isNil, length, reduce, append } from 'ramda'
import addDebuggingPinsMutation from 'api/mutations/addDebuggingPins.graphql'
import deleteDebuggingPinsMutation from 'api/mutations/deleteDebuggingPins.graphql'
import enableDisableDebuggingPinsMutation from 'api/mutations/enableDisableDebuggingPins.graphql'
import setUserDebugSettingsMutation from 'api/mutations/setUserDebugSettings.graphql'
import createDebugScenarioMutation from 'api/mutations/createDebugScenario.graphql'
import changeDebugScenarioMutation from 'api/mutations/changeDebugScenario.graphql'
import deleteDebugScenarioMutation from 'api/mutations/deleteDebugScenario.graphql'
import { syntheticMatch } from 'engine/actions/truthTableFromChoices'
import { selectedNodes } from 'selectors/nodeSelection'
import { objectsBySys } from 'selectors/objects'
import { objectsIndex } from 'selectors/apollo'
import { parentFor } from 'selectors/paths'

export const SET_DEBUGGING_PINS = 'SET_DEBUGGING_PINS'
export const UPDATE_DEBUGGING_PINS = 'UPDATE_DEBUGGING_PINS'
export const ADD_DEBUGGING_PINS = 'ADD_DEBUGGING_PINS'
export const DELETE_DEBUGGING_PINS = 'DELETE_DEBUGGING_PINS'
export const SET_DEBUG_SETTINGS = 'SET_DEBUG_SETTINGS'
export const SET_SCENARIOS = 'SET_SCENARIOS'

export const setDebuggingPins = pins => ({ type: SET_DEBUGGING_PINS, pins })
export const addDebuggingPins = pins => ({ type: ADD_DEBUGGING_PINS, pins })
export const deleteDebuggingPins = nodeIds => ({ type: DELETE_DEBUGGING_PINS, nodeIds })
export const setDebugSettings = settings => ({ type: SET_DEBUG_SETTINGS, settings })
export const updateDebuggingPins = pins => ({ type: UPDATE_DEBUGGING_PINS, pins })
export const setScenarios = scenarios => ({ type: SET_SCENARIOS, scenarios })

export /* for test */ const DEFAULT_DEBUG_SCENARIO_NAME = 'Default'

const { types: { sequencer: { Mode: SequencerMode }, task_container: { Mode: TaskContainerMode } } } = model

// Command actions

// common code between put/remove pins actions
const mutatePinForIds = (mutation, doAfterAction) => ids => async (dispatch, getState, { getApolloClient }) => {

  const state = getState()
  const pins = ids || selectedNodes(state)
  const revision = revisionId(state)
  const scenario = currentDebugScenarioSelected(state) || await dispatch(createDefaultDebugScenarioIfNoneExist())

  if (isNil(scenario) || isEmpty(pins)) {
    // We don't do anything if there is no scenario selected
    return
  }

  await getApolloClient().mutate({
    mutation,
    variables: {
      input: {
        revision,
        scenarioId: scenario._id,
        pins
      }
    }
  })

  return doAfterAction ? dispatch(doAfterAction(pins, state)) : null
}

const isToggablePinContainer = container => {
  if (container.sys === Sys.task_container) return container.data.mode === TaskContainerMode.RANDOM
  if (container.sys === Sys.sequencer) return container.data.mode === SequencerMode.SHUFFLE

  return true
}

const siblingsToUnpin = (obj, container, state) => {
  if (!container || !isToggablePinContainer(container)) return []

  return reduce((acc, refId) => {
    const id = parseRef(refId)
    return (
      id !== obj.id && isPinned(id)(state) ? append(id, acc) : acc
    )
  }, [], container.data.container_contents)
}

const afterToPutPins = (ids, state) => {
  const index = objectsIndex(state)
  const sysIndex = objectsBySys(state)

  const pins = reduce((_pins, id) => {
    const obj = index[id]

    if (!isContained(obj)) return _pins

    const containerId = parentFor(obj, sysIndex)
    const container = containerId && index[containerId]
    return _pins.concat(siblingsToUnpin(obj, container, state))
  }, [], ids)

  return removePinToNodes(pins)
}

export const putPinToNodes = mutatePinForIds(addDebuggingPinsMutation, afterToPutPins)
export const removePinToNodes = mutatePinForIds(deleteDebuggingPinsMutation)

export const togglePinUnpinSelectedNode = ({ id }) => (dispatch, getState) =>
  dispatch((isPinned(id)(getState()) ? removePinToNodes : putPinToNodes)([id]))

export const pinReferencesFromChoice = choice => async (dispatch, getState) => {
  const state = getState()
  const conditionsIds = conditionsBy({ nameToMatch: choice.id, modeToMatch: '=' }, state)
  const rowIds = rowsByChoice(choice, state)
  return dispatch(putPinToNodes([...conditionsIds, ...rowIds]))
}

const withSimilarConditions = action => ({ data }) => async (dispatch, getState) => {
  const conditionsIds = conditionsBy({ nameToMatch: data.name, modeToMatch: data.mode }, getState())
  return dispatch(action(conditionsIds))
}
export const pinSimilarConditions = withSimilarConditions(putPinToNodes)
export const unpinSimilarConditions = withSimilarConditions(removePinToNodes)

export const conditionsBy = ({ nameToMatch, modeToMatch }, state) => {
  const conditions = objectsBySys(state)[Sys.condition] || {}

  return filter(id => {
    const { data } = conditions[id]
    return nameToMatch === data.name && modeToMatch === data.mode
  }, Object.keys(conditions))
}

export const rowsByChoice = ({ id: choiceId }, state) => {
  const rows = objectsBySys(state)[Sys.truth_table_row] || {}
  const syntheticRule = syntheticMatch(choiceId)

  return filter(id => {
    const { data: { cells } } = rows[id]
    return length(cells) === 1 && equals(cells[0], syntheticRule)
  }, Object.keys(rows))
}

//

export const enableDisablePins = (pins, enable) => (_, getState, { getApolloClient }) => {
  const scenario = currentDebugScenarioSelected(getState())

  if (isNil(scenario)) {
    // We don't do anything if there is no scenario selected
    return
  }

  return getApolloClient().mutate({
    mutation: enableDisableDebuggingPinsMutation,
    variables: {
      input: {
        revision: revisionId(getState()),
        scenarioId: scenario._id,
        enabled: enable,
        pins
      }
    }
  })
}

export const updateDebugMode = isEnabled => (dispatch, getState) =>
  dispatch(updateDebuggingSettings({
    ...currentDebugSettings(getState()),
    enabled: isEnabled
  }))

export const updateDebuggingSettings = ({ enabled, scenarioSelected }) => (_, getState, { getApolloClient }) =>
  getApolloClient().mutate({
    mutation: setUserDebugSettingsMutation,
    variables: {
      input: {
        revision: revisionId(getState()),
        scenarioSelected: scenarioSelected?._id,
        enabled,
      }
    }
  })

export const changeDebugScenario = _id => (_, getState, { getApolloClient }) =>
  getApolloClient().mutate({
    mutation: changeDebugScenarioMutation,
    variables: {
      input: {
        revision: revisionId(getState()),
        _id,
      }
    }
  })

export const deleteDebugScenario = _id => (_, getState, { getApolloClient }) =>
  getApolloClient().mutate({
    mutation: deleteDebugScenarioMutation,
    variables: {
      input: {
        revision: revisionId(getState()),
        _id,
      }
    }
  })

export const createDebugScenario = name => (_, getState, { getApolloClient }) =>
  getApolloClient().mutate({
    mutation: createDebugScenarioMutation,
    variables: {
      input: {
        revision: revisionId(getState()),
        name,
      }
    }
  })

export /* for test */ const createDefaultDebugScenarioIfNoneExist = () => async (dispatch, getState) => {
  if (currentDebugScenarioSelected(getState())) return null

  const response = await dispatch(createDebugScenario(DEFAULT_DEBUG_SCENARIO_NAME))
  return response?.data?.createDebugScenario
}