import { createActions } from 'reduxsauce'
import { batch } from 'react-redux'
import { createWalkList } from 'model/project/walklist'
import { length, currentIndex, currentNode } from 'selectors/walklist'

import { scrollNodeIntoView } from 'actions/scrolling'
import { expandToViewNode } from 'actions/view'
import Preferences from 'preferences/Preferences'
import { findByNodeIds } from '../model/project/searches/searches'
import performSearch from './nodes/search/performSearch'

export const { Types, Creators } = createActions({

  // more high-level events

  /** Given a search & its results it starts walking it */
  walkList: (search, results) => (dispatch, getState) => {
    dispatch(Creators.startWalking(createWalkList(search, results)))
    if (results.length > 0) {
      dispatch(Creators.focusOnCurrent(dispatch, getState))
    }
  },

  /** Given a list of ids and a title it creates a new Search with that title and starts walking its results */
  walkNodes: (title, ids) => dispatch => {
    const { search, result } = dispatch(performSearch(findByNodeIds(title, ids)))
    dispatch(Creators.walkList(search, result))
  },

  // walk-list events

  startWalking: ['walkList'],

  close: [],
  open: () => dispatch => {
    dispatch(Creators.setActive(true))
  },

  next: () => (dispatch, getState) => {
    const state = getState()
    const size = length(state)
    const current = currentIndex(state)

    if (current < (size - 1)) {
      batch(() => {
        dispatch(Creators.setCurrentIndex(current + 1))
        dispatch(Creators.focusOnCurrent(dispatch, getState))
      })
    }
  },
  previous: () => (dispatch, getState) => {
    const state = getState()
    const current = currentIndex(state)

    if (current > 0) {
      batch(() => {
        dispatch(Creators.setCurrentIndex(current - 1))
        dispatch(Creators.focusOnCurrent(dispatch, getState))
      })
    }
  },

  focusOnCurrent: () => (dispatch, getState) => {
    // this should probably be in the component that matched and not here
    const node = currentNode(getState())
    if (node) {
      batch(() => {
        dispatch(expandToViewNode(node.id))
        dispatch(scrollNodeIntoView(node.id, { forceInViews: [Preferences.GraphView.trackSelection] }))
      })
    }
  },

  // internals
  setActive: ['active'],
  setCurrentIndex: ['current'],

})

export default Creators