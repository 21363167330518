import React from 'react'
import NodePath from 'components/Commons/NodePath/NodePath'

import SearchResultItemContent from './SearchResultItemContent'

import styles from './SearchResultItem.scss'

const SearchResultItem = ({ item }) => (
  <div className={styles.SearchResultItem}>
    <SearchResultItemContent item={item} className={styles.content} />
    <NodePath className={styles.searchResultPath} id={item.object.id} labels containers tooltip={false} includeSelf={false} />
  </div>
)

export default SearchResultItem