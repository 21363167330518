import React, { useState, useCallback } from 'react'

import { isEmpty, pathOr } from 'ramda'
import { Icon, Tooltip } from 'antd'
import classNames from 'classnames'
import useEffectNodeProperty from 'hooks/useEffectNodeProperty'
import { ignoreEvent } from 'dom/events'
import { lang } from 'beanie-engine-api-js'
import IconButton from '../IconButton/IconButton'
import Rule from '../Language/Rules/Rule/Rule'
import BeanieLanguageEditor from '../Language/Rules/BeanieLanguageEditor'
import DeleteButton from '../Commons/DeleteButton'

import editorStyles from '../TreeView/TreeChain/NodeWithEnabledRule/NodeRuleEditor.scss'
import styles from './PropertiesEditor.scss'

const { rule: { typing: { types } } } = lang

export const EffectPropEditor = ({ containerClassName, setValue, value, onCancel, onApply, onBlur, itValue }) => (
  <div
    onClick={ignoreEvent}
    onDoubleClick={ignoreEvent}
    className={classNames(styles.editingContainer, containerClassName)}
    >
    <div className={editorStyles.nodeEditor} >
      <BeanieLanguageEditor
        className={editorStyles.editor}
        expectedType={types.Void}
        itValue={itValue}
        autoFocus

        value={value}
        setValue={setValue}

        onApply={onApply}
        onBlur={onBlur}
        onCancel={onCancel}
      />
    </div >
  </div>
)

const EffectProp = ({ propertyPath, node, editable }) => {
  const [editingMode, setEditingMode] = useState(false)
  const { setValue, value, onEnter, onDeleteRule } = useEffectNodeProperty(node?.id, propertyPath)

  const onCancel = useCallback(() => {
    setEditingMode(false)
  }, [editingMode, setEditingMode])

  const onEnterEdition = useCallback(async () => {
    await onEnter()
    setEditingMode(false)
  }, [editingMode, setEditingMode, onEnter])

  const onEdit = useCallback(() => {
    if (editable) {
      setEditingMode(true)
    }
  }, [editable, editingMode, setEditingMode])

  const onDelete = useCallback(() => {
    if (editable) {
      onDeleteRule()
    }
  }, [editable, editingMode, setEditingMode])

  const source = pathOr('', [...propertyPath, 'source'], node)

  return (<div className={styles.exprField}>
    {editingMode ?
      <EffectPropEditor
        containerClassName={styles.ruleEditor}
        setValue={setValue}
        propertyPath={propertyPath}
        value={value}
        node={node}
        itValue={node?.id}
        onCancel={onCancel}
        onApply={onEnterEdition}
        onBlur={onEnterEdition}
      /> :
      <div className={classNames(styles.rule)} onDoubleClick={onEdit}>
        {!isEmpty(source) ? <Rule source={source} /> : source}
        {editable ?
          <div className={styles.icons}>
            <IconButton className={styles.icon} tooltip="Edit" icon="edit" onClick={onEdit} />
            {!isEmpty(source) && <DeleteButton className={styles.deleteIcon} onDelete={onDelete} tooltip="Delete" />}
          </div> :
          <Tooltip title="Non modifiable">
            <Icon type="lock" />
          </Tooltip>
          }
      </div>
      }
  </div>
  )
}

const EffectProperty = ({ propertyPath, object, editable }) => (
  <div className={styles.effectProperty}>
    {object && propertyPath ?
      <EffectProp propertyPath={propertyPath} editable={editable} node={object} /> :
      <div className={styles.noExprPlaceholder}>No effect</div>
    }
  </div>
)


export default EffectProperty