import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { noop } from 'ramda-adjunct'

/**
 * Subscribes to a GraphQL subscription.
 * It will call `onEvent` for each received event and `onError` in case of error.
 * You can control enabling/disabling it through the "active" param.
 *
 * @param query the graphql query
 * @param active controls activating/deactivating the subscription reactively
 * @param variables an object with variables to use with the query
 * @param onEvent callback to be called for each received event
 * @param onError callback to be called if an error is detected
 * @param onSubscribed callback whenever we subscribe
 */
const useSubscription = ({ query, variables, active, onEvent, onError, onSubscribed = noop }) => {
  const subscription = useRef()

  // TODO: useApolloClient once we upgrade apollo ! :S
  const dispatch = useDispatch()
  const subscribe = useCallback(() => {
    dispatch((_, getState, { getApolloClient }) => {
      return getApolloClient()
        .subscribe({
          query,
          variables,
        })
        .subscribe({
          next: onEvent,
          error: onError
        })
    })
  }, [dispatch, onEvent, onError])

  useEffect(() => {
    if (!subscription.current && active) {
      // must subscribe
      // console.log('[useSubscription] subscribing to', query.definitions[0].name.value, { active, subscribe })
      subscription.current = subscribe()
      onSubscribed()
    }
  }, [active, subscribe])

  // unmount
  useEffect(() => {
    return () => {
      if (subscription.current) {
        subscription.current.unsubscribe()
        subscription.current = null
      }
    }
  })
}

export default useSubscription
