import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Table, Checkbox } from 'antd'
import { prop, reduce, pipe, mapObjIndexed, assoc, keys } from 'ramda'
import { compose, withHandlers, withState } from 'recompose'
import { ALL_PREFERENCE_CHECKS } from 'providers/Checker/CheckSelectors/CheckSelectors'
import CreateModal from '../Commons/CreateModal'
import Preferences from 'preferences/Preferences'
import IconButton from '../Commons/IconButton'
import { ModalConsumer } from '../Commons/Modal'
import withPreference from 'hocs/withPreference'
import { updatePreference } from 'actions/view'

import styles from './ProjectProblems.scss'

const { Column } = Table

const expandedRowRender = (enableChecks, onChangeCheckOfType) =>
  ({ type, checks }) => (
    <Table
      rowKey={prop('name')}
      dataSource={checks}
      pagination={false} >
      <Column
        title="Check"
        key="name"
        dataIndex="name" />
      <Column
        title="Enabled"
        key="check-enabled"
        render={({ name }) => (<Checkbox
          className={styles.checkBox}
          onChange={e => onChangeCheckOfType(e, type, name)}
          checked={enableChecks[type].checks[name]} />)}
      />
    </Table>
  )

const _ConfigModal = ({
  hide,
  reset,
  enableChecks,
  onCreate,
  isInterminate,
  onChangeCheckOfType,
  onChangeAllOfType
}) => (<CreateModal
  titleIcon="setting"
  title="Analysis checks"
  hide={pipe(reset, hide)}
  canSubmit
  submit={onCreate}
  >
  {() => (<div className={classNames(styles.problemsConfig)}>
    <Table
      rowKey={prop('type')}
      className={styles.problemsConfigTable}
      dataSource={ALL_PREFERENCE_CHECKS}
      expandedRowRender={expandedRowRender(enableChecks, onChangeCheckOfType)}
      size="small"
      pagination={false}
      >
      <Column
        title="Type"
        key="type"
        dataIndex="type" />
      <Column
        title="Enabled"
        key="type-enabled"
        render={({ type }) => (<Checkbox
          className={styles.checkBox}
          indeterminate={isInterminate(type)}
          onChange={e => onChangeAllOfType(e, type)}
          checked={isInterminate(type) ? true : enableChecks[type].enabled}
        />)}
        />
    </Table >
  </div>)}
</CreateModal>)

const INTER = 'INTER'

const ConfigModal = compose(
  withPreference({ enableChecksPref: Preferences.StaticAnalysis.enabledChecks }),
  withState('enableChecks', 'setEnableChecks', prop('enableChecksPref')),
  connect(null, dispatch => ({
    updatePreferenceValue: value =>
      dispatch(updatePreference(Preferences.StaticAnalysis.enabledChecks, value))
  })),
  withHandlers({
    isInterminate: ({ enableChecks }) => type => enableChecks[type].enabled === INTER,
    onChangeCheckOfType: ({ enableChecks, setEnableChecks }) => ({ target }, type, name) => {

      const { allFalse, allTrue } = reduce(({ allFalse: currFalse, allTrue: currTrue }, key) => {
        const current = key === name ? target.checked : enableChecks[type].checks[key]
        return ({
          allFalse: currFalse && current === false,
          allTrue: currTrue && current === true,
        }) 
      }, { allFalse: true, allTrue: true }, keys(enableChecks[type].checks))

      const typeEnabled = allFalse ? false : allTrue ? true : INTER
      const newValue = {
        enabled: typeEnabled,
        checks: assoc(name, target.checked, enableChecks[type].checks)
      }

      setEnableChecks(assoc(type, newValue, enableChecks))
    },
    onChangeAllOfType: ({ enableChecks, setEnableChecks }) => ({ target }, type) => {
      const newValue = {
        enabled: target.checked,
        checks: mapObjIndexed(() => target.checked, enableChecks[type].checks)
      }

      setEnableChecks(assoc(type, newValue, enableChecks))
    },
    onCreate: ({ updatePreferenceValue, enableChecks }) => () => { updatePreferenceValue(enableChecks) },
    reset: ({ setEnableChecks, enableChecksPref }) => () => { setEnableChecks(enableChecksPref) }
  })
)(_ConfigModal)

const ProblemsConfig = ({ doDelete }) => (
  <ModalConsumer>
    {({ showModal }) => (<div>
      <IconButton
        type="setting"
        tooltip="Customize Analysis checks"
        onClick={() => showModal(ConfigModal, { doDelete })} />
    </div>)}
  </ModalConsumer>
)

export default ProblemsConfig
