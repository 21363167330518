exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".AceEditor__2QZNV{border:1px solid #cecece;border-radius:5px}", ""]);

// exports
exports.locals = {
	"AceEditor": "AceEditor__2QZNV"
};