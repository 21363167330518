import React from 'react'
import useLastBetweenSelectors from 'hooks/selectors/useLastBetweenSelectors'
import { selectedObject } from 'selectors/objects'
import { currentNode as walkedNode } from 'selectors/walklist'

import ObjectHistory from './ObjectHistory'

const SelectedObjectHistory = props => {
  const object = useLastBetweenSelectors(walkedNode, selectedObject)
  return <ObjectHistory {...props} object={object} />
}

export default SelectedObjectHistory