import React from 'react'

import classNames from 'classnames'
import { EMPTY_STRING } from 'utils/string'

import styles from '../../SlateTextEditor.scss'

const TextPart = ({ attributes, children, node }) => 
  (<div {...attributes} className={classNames(styles.TextPart, { [styles.empty]: node.getText() === EMPTY_STRING })}>{children}</div>)

export default TextPart