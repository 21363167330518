exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".RevisionSessionsList__3qIDZ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-positive:.8;flex-grow:.8;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;text-align:center}.RevisionSessionsList__3qIDZ .ant-avatar{margin-right:.5em;cursor:default}.RevisionSessionTitle__2lc-f .RevisionSessionOrigin__2mLX6{padding-left:.2rem;color:#d3d3d3}.RevisionSessionAvatar__IgP0J{position:relative;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.RevisionSessionAvatar__IgP0J>.anticon{position:absolute;bottom:0px;right:6px}.SessionDetailsPopover__3F0FO{font-size:.7rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.SessionDetailsPopover__3F0FO>div{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.SessionDetailsPopover__3F0FO .fieldName__3TEGY{width:6rem;color:#a2a2a2}", ""]);

// exports
exports.locals = {
	"RevisionSessionsList": "RevisionSessionsList__3qIDZ",
	"RevisionSessionTitle": "RevisionSessionTitle__2lc-f",
	"RevisionSessionOrigin": "RevisionSessionOrigin__2mLX6",
	"RevisionSessionAvatar": "RevisionSessionAvatar__IgP0J",
	"SessionDetailsPopover": "SessionDetailsPopover__3F0FO",
	"fieldName": "fieldName__3TEGY"
};