import React, { useCallback } from 'react'
import { Icon, message } from 'antd'
import { T } from 'ramda'
import classNames from 'classnames'
import LinkButton from 'components/Commons/LinkButton'
import useStateWithMountCheck from '../../hooks/useStateWithMountCheck'

const IconButton = ({ tooltip, tooltipPlacement, theme, type, disabled, className, onClick, iconStyle, onError, ...others }) => {
  const [spinning, setSpinning] = useStateWithMountCheck(false)

  const onClickMemo = useCallback(async (...args) => {
    setSpinning(true)
    try {
      return onClick ? await onClick(...args) : undefined
    } catch (error) {
      if (onError) {
        onError(error)
      } else {
        message.error(`Oops! there was an error: ${error.message}`)
      }
    } finally {
      setSpinning(false)
    }
  }, [onClick, setSpinning, spinning])

  return (
    <LinkButton
      className={classNames(className, { buttonDisabled: disabled })}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      disabled={disabled}
      onClick={disabled ? T : onClickMemo}
    >
      <Icon
        spin={spinning}
        theme={theme}
        type={type}
        {...iconStyle && { style: iconStyle }}
        {...others}
      />
    </LinkButton>
  )
}

export default IconButton