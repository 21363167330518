exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".card__3WkzF{display:-ms-flexbox;display:flex}.card__3WkzF .leftSide__2sHfb .ant-avatar{height:120px;width:120px;line-height:120px;font-size:40px}.card__3WkzF .rightSide__b2tHw{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding-left:1em}.card__3WkzF .rightSide__b2tHw .content__3VM_z{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1}.card__3WkzF .rightSide__b2tHw .content__3VM_z .title__3pkBc{font-size:1.2em}.card__3WkzF .rightSide__b2tHw .content__3VM_z .title__3pkBc span{color:#a9a9a9;font-size:.9em;padding-left:.2em}.card__3WkzF .rightSide__b2tHw .content__3VM_z .email__DW_KX{font-size:.8em;color:#6262a9}.card__3WkzF .rightSide__b2tHw .orgsBar__10tT6{padding-bottom:.4rem}.card__3WkzF .rightSide__b2tHw .orgsBar__10tT6>a:not(:last-child){padding-right:.3em}.card__3WkzF .rightSide__b2tHw .actions__2YWsA{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;color:#6495ed;border-top:1px solid #ebeaea;padding-top:.2em}.deletedUser__3F1cu{opacity:.5;text-decoration:line-through}", ""]);

// exports
exports.locals = {
	"card": "card__3WkzF",
	"leftSide": "leftSide__2sHfb",
	"rightSide": "rightSide__b2tHw",
	"content": "content__3VM_z",
	"title": "title__3pkBc",
	"email": "email__DW_KX",
	"orgsBar": "orgsBar__10tT6",
	"actions": "actions__2YWsA",
	"deletedUser": "deletedUser__3F1cu"
};