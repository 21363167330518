import React from 'react'
import styles from './Preferences.scss'

const PreferencesGroup = ({ title, children }) => (
  <div className={styles.PreferencesGroup}>
    <h3 className={styles.editor}>{title}</h3>
    <div className={styles.preferences}>
      {children}
    </div>
  </div>
)

export default PreferencesGroup