import { path, pathEq } from 'ramda'
import { LoadingState } from '../model/constants'

// this file should be "app", because it is not the videoplayer state part but the whole "app" state

// loading state
export const loadingState = path(['player', 'loadingState'])
export const isLoading = pathEq(['player', 'loadingState'], LoadingState.LOADING)
export const isLoaded = pathEq(['player', 'loadingState'], LoadingState.LOADED)
export const isReady = isLoaded

export const source = path(['player', 'source'])