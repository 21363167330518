import { pipe, prop, test } from 'ramda'
import { GamepadButton, createGamepadDefinition } from '../api'

const PSButton = {
  cross: { name: 'cross', icon: '/images/gamepads/button-ps-cross.jpeg' },
  circle: { name: 'circle', icon: '/images/gamepads/button-ps-circle.jpeg' },
  square: { name: 'square', icon: '/images/gamepads/button-ps-square.jpeg' },
  triangle: { name: 'triangle', icon: '/images/gamepads/button-ps-triangle.jpeg' },
}

//
// gamepad def
// 

const PSGamepad = createGamepadDefinition({
  type: 'PS',
  matches: pipe(prop('id'), test(/PS/)),
  buttons: PSButton,
  apiToButtonMapping: {
    [GamepadButton.A]: PSButton.cross,
    [GamepadButton.B]: PSButton.circle,
    [GamepadButton.X]: PSButton.square,
    [GamepadButton.Y]: PSButton.triangle,
  },
  buttonToChoiceMapping: {
    [PSButton.square.name]: 0,
    [PSButton.triangle.name]: 1,
    [PSButton.circle.name]: 2,
    [PSButton.cross.name]: 3,
  },
})

export default PSGamepad