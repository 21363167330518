import React from 'react'
import { compose, withHandlers, withProps } from 'recompose'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'

import projectWithIdQuery from 'api/queries/projectWithId.graphql'
import { projectId } from 'selectors/project'

import routes from 'components/Organizations/routes'

import SectionRouter from 'components/Commons/Sections/SectionRouter'
import Sections from './Sections'
import ProjectSettings from './ProjectSettings'

const ProjectSettingsRouter = ({ project, projectData, refreshData }) => (
  <SectionRouter
    basePath={routes.projectSettings}
    layout={ProjectSettings}
    sections={Sections}
    defaultSection={Sections.Options}

    project={project}
    projectData={projectData}
    refreshData={refreshData}
  />
)

export default compose(
  withRouter,
  connect(state => ({
    project: projectId(state)
  })),
  graphql(projectWithIdQuery, {
    name: 'projectData',
    options: ({ project }) => ({ variables: { projectId: project } }),
    skip: ({ project }) => !project
  }),
  withHandlers({
    refreshData: ({ project, projectData }) => () => projectData.refetch({ name: project })
  }),
  withProps(({ projectData }) => ({
    project: projectData?.projectWithId
  }))
)(ProjectSettingsRouter)