import React from 'react'

import LinkButton from 'components/Commons/LinkButton'
import TeamAction from './TeamAction'

const TeamActionLink = ({ team, label, tooltip, onClick }) => (
  <TeamAction team={team}>
    <LinkButton tooltip={tooltip} onClick={onClick}>
      {label}
    </LinkButton>
  </TeamAction>
)

export default TeamActionLink
