import { ifElse, intersperse, map, when, test } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { markUpWith, newMarkUpWithCustomNodes, markUpName } from '../utils/slateMocks/markUp'
import { isEmpty, isEmptyObject, isArray } from 'utils/object'
import { NODE_TYPES } from '../utils/Constants'

import { inlineNode } from '../utils/slateMocks/inlineNode'
import { textNode } from '../utils/slateMocks/textNode'
import { isRichText } from '../utils/plugins/Markup/MarkupRichTextValuePlugin'

const { types: { markup: { MARKUP_FIELD_SEPARATOR, MARKUP_PARAM_VALUE_LIST_SEPARATOR } } } = model

// args

export const markUpArgument = (name, value) => inlineNode([
  inlineNode([textNode(name)], NODE_TYPES.MARK_UP_PARAMETER_KEY),
  textNode(MARKUP_FIELD_SEPARATOR),
  inlineNode([serializeValue(value)], NODE_TYPES.MARK_UP_PARAMETER_VALUE)
], NODE_TYPES.MARK_UP_PARAMETER)

// lists
export const listElementSeparator = () => textNode(MARKUP_PARAM_VALUE_LIST_SEPARATOR)
const serializeRichText = value => `"${value}"`
const hasSpecialChars = text => test(/\W/g)(text) && !isRichText(text)
const serializeTextValue = textValue => textNode(when(hasSpecialChars, serializeRichText)(textValue.toString()))
export const serializeListElement = value => inlineNode([serializeValue(value)], NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT)
export const serializeListValue = list => inlineNode(
  intersperse(listElementSeparator(), list.map(serializeListElement)),
  NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST
)
const serializeValue = ifElse(isArray, serializeListValue, serializeTextValue)

const markUpArg = ({ name, value }) => markUpArgument(name, value)

const markUpWithParams = (name, args) => newMarkUpWithCustomNodes(markUpName(name), map(markUpArg, args))

const simpleMarkup = name => markUpWith(markUpName(name))

const markToNode = ({ name, args }) => (isEmpty(args) || isEmptyObject(args) ? simpleMarkup(name) : markUpWithParams(name, args))

export default markToNode