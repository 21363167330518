import dialogueLine from './dialogueLine'
import directorLine from './directorLine'
import actorPart from './actorPart'
import TextPart from './textPart'
import { NODE_TYPES } from '../../Constants'

export default {
  [NODE_TYPES.DIALOGUE_LINE]: dialogueLine,
  [NODE_TYPES.DIRECTOR_LINE]: directorLine,
  [NODE_TYPES.ACTOR_PART]: actorPart,
  [NODE_TYPES.TEXT_PART]: TextPart,
}