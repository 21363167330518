import { useCallback, useMemo } from 'react'
import debounce from 'lodash.debounce'

/**
 * Similar to useCallback but already debounce the function with the given debounceTime (3rd param)
 */
const useDebouncedCallback = (fn, dependencies, debounceTime) => {
  const cb = useCallback(fn, dependencies)
  return useMemo(() => debounce(cb, debounceTime), [cb])
}

export default useDebouncedCallback