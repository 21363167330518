exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".contentInserted__2GshT{padding:.2rem;background-color:#d2f5d2}.contentRemoved__nQhUf{display:inline;-ms-flex-item-align:center;align-self:center;border:1px solid #f3c6bb;padding-top:.1rem;padding-bottom:.1rem}.contentReplaced__15yV1{padding:.2rem;background-color:#f6f7d5}.inlineContainer__sAbCM{display:inline-block}.deleteLineContainer__2lrtI{display:inline-block;-ms-flex-direction:column;flex-direction:column}.lineRemoved__8YrzQ{display:inline-block;border:1px solid #f3c6bb;margin-top:.5rem;height:.1rem;width:100%}", ""]);

// exports
exports.locals = {
	"contentInserted": "contentInserted__2GshT",
	"contentRemoved": "contentRemoved__nQhUf",
	"contentReplaced": "contentReplaced__15yV1",
	"inlineContainer": "inlineContainer__sAbCM",
	"deleteLineContainer": "deleteLineContainer__2lrtI",
	"lineRemoved": "lineRemoved__8YrzQ"
};