import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { equals } from 'ramda'
import IconButton from 'components/Commons/IconButton'

import { ANALYSIS_MODES } from 'providers/Checker/CheckerMode'
import { analysisIsLoading, staticAnalysisIsEnabled } from 'selectors/staticIssues'
import Preferences from 'preferences/Preferences'
import withPreference from 'hocs/withPreference'
import withChecker from 'hocs/withChecker'
import SelectPreference from '../Commons/Preferences/SelectPreference'
import ProblemsConfig from './ProblemsConfig'
import ExtensionPoint from '../ExtensionPoints/ExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import styles from './ProblemsControls.scss'

const ProblemsControls = ({ currentMode, isLoading, analyzerIsEnabled, checkerContext }) => (
  <div className={styles.problemControls}>
    <ProblemsConfig />
    <div className={styles.preference}>
      Analysis mode
      <SelectPreference
        className={styles.manualAutoStaticAnalysis}
        disabled={!analyzerIsEnabled}
        preference={Preferences.StaticAnalysis.analysisMode}
        options={[
          {
            value: ANALYSIS_MODES.AUTO,
            name: 'auto'
          },
          {
            value: ANALYSIS_MODES.MANUAL,
            name: 'manual'
          }
        ]}
      />
    </div>
    {analyzerIsEnabled && <AnalyzerModeIcon isLoading={isLoading} currentMode={currentMode} checkerContext={checkerContext} />}
    <ExtensionPoint point={EXTENSION_POINTS.PROBLEMS_PANEL_CONTROL} />
  </div>
)

const AnalyzerModeIcon = ({ isLoading, currentMode, checkerContext }) => (equals(currentMode, ANALYSIS_MODES.MANUAL) ?
  <IconButton type="reload" disabled={isLoading} onClick={() => checkerContext.runChecks()} tooltip="Analyze nodes" /> : null
)

export default compose(
  withChecker,
  withPreference({ currentMode: Preferences.StaticAnalysis.analysisMode }),
  connect(state => ({
    analyzerIsEnabled: staticAnalysisIsEnabled(state),
    isLoading: analysisIsLoading(state)
  }), null),
)(ProblemsControls)