import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withStateHandlers, withHandlers } from 'recompose'
import { any, hasPath, pathOr, isEmpty } from 'ramda'

import { Modal, Tooltip } from 'antd'

import { setProperties } from 'engine/actions/objects'
import PropertiesSelector from './PropertiesSelector'
import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'

import styles from './CopyPropertiesModal.scss'

/*
  Only considers first level collisions
 */
const hasKeyCollision = (sourcePaths, obj) => any(
  sourcePath => hasPath(sourcePath.split('.'), obj), sourcePaths
)

const CopyPropertiesModal = ({ children, lens, handleCopy, onFinish, visible, targetObject, sourceObject, confirmLoading, selectedProperties, setSelectedProperties }) => {
  if (!visible) return null

  return (
    <Modal
      title={<Tooltip title={targetObject.id}>
        <span>Copy properties from <SimpleNode nodeId={targetObject.id} className={styles.inlineNode} /></span>
      </Tooltip>}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleCopy}
      onCancel={onFinish}
      okType={hasKeyCollision(selectedProperties, targetObject) ? 'danger' : undefined}
      okButtonProps={isEmpty(selectedProperties)}
      okText={hasKeyCollision(selectedProperties, targetObject) ? 'Confirm (will override values)' : 'Confirm'}
      destroyOnClose
    >
      <>
        {children}
        {
          sourceObject && (
            <div className={styles.propertiesSelectorContainer}>
              <h4>Select properties to copy from <SimpleNode nodeId={sourceObject.id} className={styles.inlineNode} /></h4>
              <PropertiesSelector
                selected={selectedProperties}
                setSelected={setSelectedProperties}
                editable={false}
                object={sourceObject}
                targetObject={targetObject}
                lens={lens}
              />
            </div>
          )
        }
      </>
    </Modal>
  )
}

export default compose(
  connect(null, {
    setPropertiesAction: setProperties
  }),
  withStateHandlers(
    { confirmLoading: false, selectedProperties: [] },
    {
      setLoading: prev => confirmLoading => ({ ...prev, confirmLoading }),
      setSelectedProperties: prev => selectedProperties => ({ ...prev, selectedProperties }),
    }
  ),
  withHandlers({
    handleCopy: ({ setLoading, onFinish, setPropertiesAction, targetObject, lens, sourceObject }) => async () => {
      setLoading(true)
      const sourceProperties = pathOr({}, lens, sourceObject)
      await setPropertiesAction(targetObject.id, sourceProperties, lens)
      await onFinish()
      setLoading(false)
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { sourceObject, setSelectedProperties } = this.props
      const { sourceObject: previousSourceObject } = prevProps

      if (sourceObject?.id !== previousSourceObject?.id) {
        setSelectedProperties([])
      }
    }
  }),
)(CopyPropertiesModal)