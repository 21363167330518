import React from 'react'

import classNames from 'classnames'
import UserProperties from './UserProperties'

import styles from './PropertiesEditor.scss'

const PropertiesEditor = ({
  editable,
  object,
  className,
  onNavigateRef,
  onPropertyChanged,
  lens,
  hiddenFields = [],
  nonEditableFields = [],
  customValueProvider,
  onCopyProperties
}) => (
  <div className={classNames(styles.propertiesEditor, className)}>
    <UserProperties
      object={object}
      editable={editable}
      onNavigateRef={onNavigateRef}
      onPropertyChanged={onPropertyChanged}
      lens={lens}
      hiddenFields={hiddenFields}
      nonEditableFields={nonEditableFields}
      customValueProvider={customValueProvider}
      onCopyProperties={onCopyProperties}
    />
  </div>
)

export default PropertiesEditor
