import React from 'react'
import { SketchPicker } from 'react-color'
import { prop } from 'ramda'

import styles from './ColorPicker.scss'
import { compose, withState, withHandlers } from 'recompose'
import { noop } from 'utils/functions'

const colorStyle = color => {
  if (color) {
    const { r, g, b, a } = color
    return { background: `rgba(${r}, ${g}, ${b}, ${a})` }
  } else {
    return {}
  }
}

const ColorPicker = ({ displayColorPicker, color, handleClick, handleClose, handleChange }) => {
  return (
    <div>
      <div className={styles.swatch} onClick={handleClick}>
        <div className={styles.color} style={colorStyle(color)} />
      </div>
      {displayColorPicker ? <div className={styles.popover} >
        <div className={styles.cover} onClick={handleClose} />
        <SketchPicker color={color || undefined} onChange={handleChange} />
      </div> : null }
    </div>
  )
}

export default compose(
  withState('displayColorPicker', 'setDisplayColorPicker', false),
  withState('color', 'setColor', prop('value')),
  withHandlers({
    handleClick: ({ displayColorPicker, setDisplayColorPicker }) => () => {
      setDisplayColorPicker(!displayColorPicker)
    },
  
    handleClose: ({ setDisplayColorPicker }) => () => {
      setDisplayColorPicker(false)
    },
  
    handleChange: ({ setColor, onChange = noop }) => color => {
      setColor(color.rgb)
      onChange(color.rgb)
    }
  })
)(ColorPicker)