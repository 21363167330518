/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef } from 'react'
import styles from './SuggestionItem.scss'
import HighlightText from 'components/Commons/HighlightText'

const SuggestionItem = ({ suggestion, callback: { editor, closePortal, onEnter }, index, selectedIndex, setSelectedIndex }) => {

  const elem = useRef()
  const isSelected = index === selectedIndex
  const getText = useCallback(() => (editor && editor.currentPath() ? editor.currentTextNode().text : '').trim(), [editor])
  const onMouseEnter = useCallback(() => setSelectedIndex(index), [index, setSelectedIndex])
  const onMouseDown = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    if (editor) {
      closePortal()
      onEnter(suggestion, editor)
      editor.focus()
    }
  }, [editor, closePortal, onEnter])

  useEffect(() => {
    if (isSelected && elem?.current) {
      elem.current?.scrollIntoView?.()
    }
  }, [isSelected, elem])

  return (
    <li
      ref={elem}
      className={isSelected ? styles.selected : undefined}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}>
      <HighlightText text={suggestion} search={getText()} />
    </li>
  )
}

export default SuggestionItem