import { toBNEAction } from '../utils'
import { _moveRangeAfter } from './moveRangeAfter'

/**
 *
 */
export const _pasteNodesAfter = (nodesToCopy, fromId, toId, afterId) => api => {
  api.createObjects(...nodesToCopy)
  _moveRangeAfter(fromId, toId, afterId, true)(api)
}

export default toBNEAction(_pasteNodesAfter, 'Paste nodes')
