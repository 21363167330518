import { model, Sys } from 'beanie-engine-api-js'
import { pathOr, path } from 'ramda'
import React from 'react'
import { Tooltip } from 'antd'

const { types: { object: { Paths }, sequencer: { Mode: SequencerMode }, jump: { Mode: JumpMode }, task_container: { Mode: TaskContainerMode }, choice: { Mode: ChoiceMode } } } = model

const JumpContentByMode = {
  [JumpMode.JUMP]: 'J',
  [JumpMode.JUMP_AND_RETURN]: 'R',
}

const SequencerContentByMode = {
  [SequencerMode.RETIRE]: 'RT',
  [SequencerMode.SHUFFLE]: 'S',
  [SequencerMode.PARK]: 'P',
  [SequencerMode.REPEAT]: 'RP',
}

const TaskContainerContentByMode = {
  [TaskContainerMode.CONCURRENT]: 'C',
  [TaskContainerMode.SEQUENTIAL]: 'S',
  [TaskContainerMode.RACE]: 'RACE',
  [TaskContainerMode.RANDOM]: 'RND',
}

const ChoiceContentByMode = {
  [ChoiceMode.ONE_SHOT]: '1'
}

import styles from './TreeNodeDecorations.scss'

const TreeNodeDecorations = ({ node }) => (
  <div className={styles.decorations}>
    {node.sys === Sys.jump &&
      <ModeDecoration node={node} path={Paths.jump.mode} mapping={JumpContentByMode} unknown={JumpMode.JUMP} />
    }
    {node.sys === Sys.sequencer &&
      <ModeDecoration node={node} path={Paths.sequencer.mode} mapping={SequencerContentByMode} unknown={SequencerMode.RETIRE} />
    }
    {node.sys === Sys.task_container &&
      <ModeDecoration node={node} path={Paths.task_container.mode} mapping={TaskContainerContentByMode} unknown={TaskContainerMode.CONCURRENT} />
    }
    {node.sys === Sys.choice && !!node?.data.mode && node?.data.mode !== ChoiceMode.DEFAULT &&
      <ModeDecoration node={node} path={Paths.task_container.mode} mapping={ChoiceContentByMode} unknown={ChoiceMode.DEFAULT} />
    }
  </div>
)

const ModeDecoration = ({ node, path: aPath, mapping, unknown }) => (
  <Decoration
    tooltip={path(aPath, node)}
  >
    <span className={styles.character}>{mapping[pathOr(unknown, aPath, node)]}</span>
  </Decoration>
)

const Decoration = ({ children, tooltip }) => (
  <div className={styles.Decoration}>
    { tooltip ?
      <Tooltip title={tooltip}>
        {children}
      </Tooltip>
      : children
    }
  </div>
)

export default TreeNodeDecorations