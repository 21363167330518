import {
  assoc,
} from 'ramda'

import { createReducer } from './utils'
import { USER_TASKS_SET, USER_TASK_CREATED } from '../actions/userTasks'


const initialState = {
  tasks: []
}

const _userTasks = (state = initialState, action) => {
  switch (action.type) {
    case USER_TASKS_SET:
      return assoc('tasks', action.tasks)

    case USER_TASK_CREATED:
      return state

    default: return state
  }
}

export const userTasks = createReducer(_userTasks)
