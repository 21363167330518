import React from 'react'

import styles from './OrganizationError.scss'

const OrganizationError = ({ error }) => (
  <div className={styles.container}>
    <h1>Oops! Something went wrong</h1>
    <p>Seems like you are trying to access an organization that doesn&apos;t exists or you are not allowed to access</p>
    {JSON.stringify(error)}
  </div>
)

export default OrganizationError