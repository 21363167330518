import { T } from 'ramda'
import { CommandResult } from '../../draftjs-constants'
import { isFunction } from 'utils/object'

// contains higher order functions to create some plugins
// that follow certain patterns easiry

/**
 * A plugin that reacts to a key by performing a mutation on the editorState
 */
export const keyboardPlugin = ({ name, key, handler, applies = T }) => () => ({
  keyBindingFn: e => (key(e) ? name : undefined),
  handleKeyCommand: (command, editorState, { setEditorState, ...others }) => {
    if (command === name && applies(editorState)) {
      setEditorState(execHandler(handler, editorState, others))
      return CommandResult.handled
    } else {
      return CommandResult.notHandled
    }
  }
})

const execHandler = (handler, editorState, others) => {
  const r = handler(editorState, others)
  return isFunction(r) ? r(editorState, others) : r
}