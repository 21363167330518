import React from 'react'
import { connect } from 'react-redux'
import { head, pathOr, equals, prop, path } from 'ramda'
import { compose, withState, withHandlers, withProps, lifecycle } from 'recompose'
import { EMPTY_ARRAY } from 'utils/object'
import { Sys } from 'beanie-engine-api-js'
import classNames from 'classnames'

import { pathToSelected } from 'selectors/paths'

import TextEditor from 'components/TextEditor/TextEditor'
import ComponentTextView from './ComponentTextView'
import NavigableContainerTitle from './NavigableContainerTitle'
import ContainerTitle from './ContainerTitle'

import { denormalizing } from 'hocs/denormalizing'

import { Direction } from '../CursorInfo'

import styles from './ContainerTextView.scss'

const ContainerTextView = ({ object, components, selectedComponent, moveComponent, moveCursorToComponent, ownCursorInfo, moveCursorToOwnComponent, moveToPreviousEditor, moveToNextEditor }) => ([
  <ContainerView
    key="components"
    object={object}

    cursorInfo={ownCursorInfo}
    moveToNextEditor={moveCursorToComponent}
    moveToPreviousEditor={moveToPreviousEditor}
  />,
  selectedComponent && (
    <div key="component" className={classNames(styles.componentsPanel, styles[selectedComponent.sys])}>
      <div className={styles.foldingLine} />
      <ComponentTextView
        component={selectedComponent}
        components={components}
        moveComponent={moveComponent}

        cursorInfo={ownCursorInfo}
        moveToPreviousEditor={moveCursorToOwnComponent}
        moveToNextEditor={moveToNextEditor}
      />
    </div>
  )
])

const ContainerView = ({ object, ...others }) => {
  switch (object.sys) {
    case Sys.choices: return <TextEditor object={object} {...others} />
    case Sys.choices2: return <TextEditor object={object} {...others} />
    case Sys.folder: return <NavigableContainerTitle object={object} {...others} />
    default: return <ContainerTitle object={object} />
  }
}

const componentFromSelectionPath = ({ components, selectedChain }) => components.find(c => selectedChain.includes(c.id))
const initiallySelectedComponent = props => componentFromSelectionPath(props) || head(props.components)

export default compose(
  denormalizing({
    prop: 'object',
    recurse: true
  }),
  connect(state => ({
    selectedChain: pathToSelected(state)
  })),
  withProps(({ object }) => ({
    components: pathOr(EMPTY_ARRAY, ['data', 'container_contents'])(object)
  })),
  withState('selectedComponent', 'setSelectedComponent', initiallySelectedComponent),
  withState('ownCursorInfo', 'setOwnCursorInfo', prop('cursorInfo')),
  lifecycle({
    componentDidUpdate(prevProps) {
      // selection changed, make sure we change the selected component
      // to show it (if its under a sub-branch)
      const { selectedChain, object, selectedComponent, setSelectedComponent, setOwnCursorInfo, cursorInfo } = this.props
      if (prevProps.selectedChain !== selectedChain && selectedChain.includes(object.id)) {
        const newOne = componentFromSelectionPath(this.props)
        if (!!newOne && newOne !== selectedComponent) {
          setSelectedComponent(newOne)
        }
      }

      // gets cursor
      const cursorAt = path(['cursorInfo', 'cursorAt'])
      if (cursorAt(prevProps) !== object.id && cursorAt(this.props) === object.id) {
        setOwnCursorInfo(cursorInfo.direction === Direction.DOWN ?
          cursorInfo
          : { ...cursorInfo, cursorAt: selectedComponent.id }
        )
      } else if (cursorAt(prevProps) !== cursorAt(this.props)) {
        // update if it comes from outside
        setOwnCursorInfo(this.props.cursorInfo)
      }
    }
  }),
  withHandlers({
    moveComponent: ({ components, setSelectedComponent, selectedComponent }) => delta => {
      const currentIndex = components.findIndex(equals(selectedComponent))
      setSelectedComponent(components[currentIndex + delta])
    },
    moveCursorToOwnComponent: ({ setOwnCursorInfo, object }) => lineOffset => {
      setOwnCursorInfo({ cursorAt: object.id, lineOffset, direction: Direction.UP })
    },
    moveCursorToComponent: ({ setOwnCursorInfo, selectedComponent }) => lineOffset => {
      setOwnCursorInfo({ cursorAt: selectedComponent.id, lineOffset, direction: Direction.DOWN })
    },
  })
)(ContainerTextView)

