exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__2fpC0{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.content__2fpC0>div:not(:last-child){margin-bottom:1rem}", ""]);

// exports
exports.locals = {
	"content": "content__2fpC0"
};