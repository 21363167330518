import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setBneState } from 'engine/actions/state'

import CreateModal from 'components/Commons/CreateModal'

import StateFileChooserButton from 'components/State/StateFileChooserButton'

/**
 * A button that opens a file chooser to pick the json file that will
 * be used to set the BNE state.
 *
 * This will be just setting BNE state, nothing to do with SAVEGAMES.
 *
 * This will be more like a debugging/admin tool, raw setting of the vm state,
 * that's why it's at the console panel and not at the STATE panel
 */
const SetBneStateButton = () => {

  return (
    <React.Fragment>
      <StateFileChooserButton modal={SetBneStateModal} description="Set BNE State" />
    </React.Fragment>
  )
}


const SetBneStateModal = ({ savegameData, fileName, hide, onCreated }) => {
  const dispatch = useDispatch()
  const submit = useCallback(() =>
    dispatch(setBneState(savegameData))
  , [setBneState, dispatch, savegameData])

  return (
    <CreateModal title="Set BNE State" hide={hide} submit={submit} canSubmit onCreated={onCreated}>
      {() => (
        <div>
          <p>You will be setting the state with the contents of file {fileName}</p>
        </div>
      )}
    </CreateModal>
  )
}

export default SetBneStateButton