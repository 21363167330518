import { teamWriteAccess } from 'security/team'
import { teams } from 'model/resources'

import teamSetAvatarMutation from 'api/mutations/teamSetAvatar.graphql'

import avatarContainer from 'components/Organizations/Commons/EntityAvatarContainer'

export default avatarContainer(
  'team', teamSetAvatarMutation, 'teamId', teamWriteAccess, teams, 'team'
)
