import { notification } from 'antd'
import { user } from 'selectors/auth'
import { isSessionFromUs } from 'model/constants/SessionClientType'
import { origin } from '../../../model/constants/SessionClientType'
import { sessionId } from '../../../selectors/project'

export const ON_REVISION_SESSION_DESTROYED_EVENT = 'ON_REVISION_SESSION_DESTROYED_EVENT'

const onRevisionSessionDestroyedEvent = event => (dispatch, getState) => {
  const state = getState()
  const ourUser = user(state)
  const ourSessionId = sessionId(state)

  // our own session ! ignore !
  if (event.session._id === ourSessionId) {
    return
  }

  // This is hacker UI code, but the easiest way. We will revisit the UX for the whole "session binding"
  // for sure so ...
  if (isSessionFromUs(ourUser, event.session)) {
    notification.info({
      message: 'Disconnected !',
      description: `You disconnected from ${origin(event.session) || event.session.clientType} !`
    })
  }

  return dispatch(sessionDestroyedAction(event))
}

export const sessionDestroyedAction = event => ({
  type: ON_REVISION_SESSION_DESTROYED_EVENT,
  event
})

export default onRevisionSessionDestroyedEvent