import { NormalizationError } from './constants'
import { convertInlineNodeInText } from './utils'

export const markupNormalize = (editor, { code, node }) => {
  if (code === NormalizationError.CHILD_TEXT_INVALID) {
    if (!editor.isVisible(node)) {
      editor.removeNodeByKey(node.key)
    } else {
      convertInlineNodeInText(editor, node)
    }
  }
}

export const markupNameNormalize = (editor) => { convertCurrentMarkupInText(editor) }

export const markupParameterNormalize = (editor, { code, node }) => {
  if (code === NormalizationError.CHILD_MIN_INVALID) {
    editor.removeNodeByKey(node.key)
    editor.moveCursorToLeft()
  } else {
    convertCurrentMarkupInText(editor)
  }
}

export const markupParameterKeyNormalize = (editor) => { convertCurrentMarkupInText(editor) }

export const markupParameterValueNormalize = (editor) => { convertCurrentMarkupInText(editor) }

export const markUpParametersNormalize = (editor) => { convertCurrentMarkupInText(editor) }

export const markUpWithParamsNormalize = (editor) => { convertCurrentMarkupInText(editor) }

const convertCurrentMarkupInText = editor => { convertInlineNodeInText(editor, editor.currentMarkUp()) }
