exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3KzY2{color:#add8e6;cursor:pointer}.iconAsLink__3KzY2:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2AKrF{font-style:italic}.formFieldsVerticalGutter__1fkw4{padding:.5rem}.formFieldsVerticalGutter__1fkw4>*{margin:.5rem !important}.tableBackground__6u2ld .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__6u2ld .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__14eBL .ant-notification-notice-close{display:none}.RevisionSelector__KBNM-{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.version__1rVKA{min-width:4rem;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;font-size:.8rem;border-right:1px solid #3e3e3e;padding-left:.5rem;padding-right:.5rem;cursor:pointer}.version__1rVKA>.anticon-down{-ms-flex-item-align:center;align-self:center;padding-left:1rem;color:gray;font-size:.8rem}.revisionDropdownOverlay__3vKZW{overflow:auto;max-height:90vh;min-width:13rem}.revisionDropdownOverlay__3vKZW .title__1_daK{text-align:center;margin-top:.8rem;margin-bottom:.2rem;color:gray}.checkpointItem__2k7GY{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between}.subMenu__yT3MB .ant-menu-item-selected{background-color:transparent !important;color:#fff !important;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}.subMenu__yT3MB .ant-menu-item-selected .anticon{-ms-flex-item-align:center;align-self:center;margin-left:1rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3KzY2",
	"labelFromRef": "labelFromRef__2AKrF",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1fkw4",
	"tableBackground": "tableBackground__6u2ld",
	"notificationNotClosable": "notificationNotClosable__14eBL",
	"RevisionSelector": "RevisionSelector__KBNM-",
	"version": "version__1rVKA",
	"revisionDropdownOverlay": "revisionDropdownOverlay__3vKZW",
	"title": "title__1_daK",
	"checkpointItem": "checkpointItem__2k7GY",
	"subMenu": "subMenu__yT3MB"
};