import { token } from 'selectors/auth'
import { Creators as UiCreators } from 'actions/ui'
import { urlForPdf, urlForAudio } from 'model/resources'
import { dropLeadingSlash as dls } from 'utils/string'

export const downloadPdf = ({ jobId, revisionId }) => (dispatch, getState) => {
  const state = getState()
  const authToken = token(state)

  return dispatch(UiCreators.downloadFile({
    path: dls(new URL(urlForPdf(authToken, revisionId, `${jobId}.pdf`)).pathname),
    query: {},
    description: 'Downloading revision PDF. Please wait, this might take a while...'
  }))
}

export const downloadAudio = ({ revisionId, audio }) => (dispatch, getState) => {
  const state = getState()
  const authToken = token(state)
  
  return dispatch(UiCreators.downloadFile({
    path: dls(new URL(urlForAudio(authToken, revisionId, audio)).pathname),
    query: {},
    description: 'Downloading audio file. Please wait, this might take a while...'
  }))
}
