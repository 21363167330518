exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__tM7sT{color:#add8e6;cursor:pointer}.iconAsLink__tM7sT:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3lZXO{font-style:italic}.formFieldsVerticalGutter__1-FBw{padding:.5rem}.formFieldsVerticalGutter__1-FBw>*{margin:.5rem !important}.tableBackground__2wbiS .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2wbiS .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__mm1EN .ant-notification-notice-close{display:none}.ExtensionPoint__3g6Il{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.container__2pv84{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;max-width:45vw;-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.container__2pv84 .currentProject__1pYEn{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding-left:1rem;padding-right:1rem;border-right:1px solid #3e3e3e;border-left:1px solid #3e3e3e;font-size:1.2rem;min-width:14rem;cursor:pointer}.container__2pv84 .currentProject__1pYEn>.anticon-down{-ms-flex-item-align:center;align-self:center;padding-left:1rem;color:gray;font-size:.8rem}.menu__k1CFQ .ant-dropdown-menu-item{padding-left:1rem}.menu__k1CFQ .ant-dropdown-menu-submenu{padding-left:1rem}.menu__k1CFQ .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title{padding-left:0 !important}.menu__k1CFQ .ant-menu-item-active{color:#f0f0f0 !important}.menu__k1CFQ .ant-menu-item,.menu__k1CFQ .ant-menu-submenu{margin-left:1.5rem}.subMenu__2h3IQ .ant-dropdown-menu-sub{min-width:7rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__tM7sT",
	"labelFromRef": "labelFromRef__3lZXO",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1-FBw",
	"tableBackground": "tableBackground__2wbiS",
	"notificationNotClosable": "notificationNotClosable__mm1EN",
	"ExtensionPoint": "ExtensionPoint__3g6Il",
	"container": "container__2pv84",
	"currentProject": "currentProject__1pYEn",
	"menu": "menu__k1CFQ",
	"subMenu": "subMenu__2h3IQ"
};