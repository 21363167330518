import React from 'react'
import { formatTimestamp, formatRelative } from 'utils/dates'
import { Tooltip } from 'antd'

/**
 * Shows a date value with a "relative" human friendly label like "2 seconds ago"
 */
const RelativeTime = ({ className, date, showTooltip = true }) => {
  if (!date) return null
  const content = <span className={className}>{formatRelative(date)}</span>

  if (showTooltip) {
    return (
      <Tooltip title={formatTimestamp(date)}>{content}</Tooltip>
    )
  }
  return content
}

export default RelativeTime