import { prop, path, pipe, propEq, values, toPairs, sortBy, map, last, find } from 'ramda'
import { Sys } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'
import { DEFAULT_LANE } from 'model/constants'

import { studioVideoUrl, VideoSources } from '../model/constants'
import { source } from './player'

const project = path(['player', 'project'])
const projectName = path(['player', 'project', 'name'])

export const projectTitle = state => {
  const p = project(state)
  return p && (p.title || p.name)
}
const videoSources = pipe(project, path(['video', 'sources']))
export const videoUrl = createSelector(
  [source, videoSources, projectName],
  (useSource, sources, name) => {
    if (!name) { return undefined }

    if (!!useSource && !!sources && useSource !== VideoSources.studio) {
      const foundSource = sources.find(propEq('name', useSource))
      return foundSource.url || studioVideoUrl(name)
    }

    return studioVideoUrl(name)
  }
)

export const objects = pipe(project, prop('objects'))
export const objectsList = createSelector(
  [objects],
  values
)

// beanie objects

export const projectBNEObject = createSelector(
  [objectsList],
  find(propEq('sys', Sys.project))
)

export const lanes = createSelector(
  [projectBNEObject],
  path(['data', 'editor', 'lanes'])
)

//
// Computes in a hacky way which node is the starting one
// We should have this as a user decision being set and modeled in beanie objects
// meanwhile I'm trying to do some hacky magic here
export const startingNodeId = createSelector(
  [lanes, objectsList],
  (allLanes, all) => {
    if (allLanes.length > 0) {
      // default or first lane
      const withRoots = allLanes.filter(lane => lane.roots && lane.roots.length > 0)
      const lane = withRoots.find(propEq('name', DEFAULT_LANE)) || withRoots[0]
      return lane.roots[0]
    } else {
      // if no lanes then just pick the first "root marker" (not actually just a marker without parent)
      return all.find(o => o.sys === Sys.marker && !o.data.parent).id
    }
  }
)

// video selectors

export const timeline = createSelector(
  [project],
  path(['video', 'timeline'])
)

export const createTimelineItemForClip = (state, { id }) => timeline(state)[id]

export const sortedTimeline = createSelector(
  [timeline],
  pipe(
    toPairs,
    sortBy(([, time]) => time.start),
    map(([id, time]) => ({ id, time }))
  )
)

export const videoLength = createSelector(
  [sortedTimeline],
  tline => (tline && tline.length > 0 ? last(tline).time.end : undefined)
)