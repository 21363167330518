import nextFrame from 'next-frame'
import { message } from 'antd'

export const nf = process.env.NODE_ENV === 'test' ? () => {} : nextFrame

export const EMPTY_LABEL = '- empty -'

export const withFeedback = async (okText, errorText, promise) => {
  try {
    const result = await promise
    message.success(okText)
    return result
  } catch (e) {
    message.error(errorText)
    throw e
  }
}