import React from 'react'
import { Icon } from 'antd'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'
import withNodeDragBehavior from '../../../../dnd/withNodeDragBehavior'

import styles from '../../TruthTable.scss'
import useCustomDragPreview from '../../useCustomDragPreview'

/**
 * Custom icon on the top-left corner that acts as a "handle" to be able
 * to drag the whole table as a node.
 */
const TruthTableDragHandle = ({ connectDragSource, connectDragPreview, currentOffset }) => {
  const isModifiable = useSecureCheck(isCurrentRevisionWritable)

  useCustomDragPreview(connectDragPreview, currentOffset)

  return isModifiable ? connectDragSource(
    <div className={styles.truthTableSelector}>
      <Icon type="drag" className={styles.truthTableSelectorIcon} />
    </div>
  ) : null
}

export default withNodeDragBehavior(TruthTableDragHandle)
