import { difference, map, append, flatten, length } from 'ramda'
import { Operation } from 'slate'
import { EMPTY_ARRAY } from 'utils/object'
import { List } from 'immutable'


// Plugin

export default () => ({

  //
  // Queries
  //

  queries: {
    getUndos: editor => {
      return editor.value.data.get('undos') || List(EMPTY_ARRAY)
    }
  },


  //
  // Commands
  //

  commands: {
    historyTransaction: (editor, func) => {
      const initialUndos = editor.getUndos().toJS()
      func(editor)
      const nextUndos = editor.getUndos().toJS()
      const diff = difference(nextUndos, initialUndos)
      if (length(diff) > 1) {
        // Merge the diff in just one undo change
        const newUndos = map(subList => List(map(Operation.fromJSON, subList)), append(flatten(diff), initialUndos))
        const newData = editor.value.data.set('undos', List(newUndos))
        editor.setData(newData)
      }
    },
  },
})
