exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".toolBarGroup__Fa8rE{display:-ms-flexbox;display:flex}.item__2Dy1e{display:-ms-flexbox;display:flex;cursor:pointer;color:#a5a1a1}.item__2Dy1e.disabled__ZZI6n{opacity:.4}.item__2Dy1e:not(.disabled__ZZI6n):hover{color:#000}.item__2Dy1e .anticon{width:32px;font-size:1.2em;-ms-flex-item-align:center;align-self:center;padding:2px;padding-right:.5em;-webkit-transition:all .5s;-o-transition:all .5s;transition:all .5s}.toolBarSplitter__2kIIK{border-right:1px solid rgba(128,128,128,.35);width:2px;margin-right:1em !important;margin-left:1em !important}", ""]);

// exports
exports.locals = {
	"toolBarGroup": "toolBarGroup__Fa8rE",
	"item": "item__2Dy1e",
	"disabled": "disabled__ZZI6n",
	"toolBarSplitter": "toolBarSplitter__2kIIK"
};