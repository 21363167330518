exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SectionHeader__9gIeJ{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding-bottom:1rem;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center}.SectionHeader__9gIeJ .filters__1bjNG{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:1rem}.SectionHeader__9gIeJ .filters__1bjNG>div:first-child{width:40rem}.SectionHeader__9gIeJ .filters__1bjNG>div{padding-right:1rem}", ""]);

// exports
exports.locals = {
	"SectionHeader": "SectionHeader__9gIeJ",
	"filters": "filters__1bjNG"
};