exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".option__CIJ42 .ant-avatar{margin-right:.5rem}.option__CIJ42 .description__3g0y2{opacity:.4}", ""]);

// exports
exports.locals = {
	"option": "option__CIJ42",
	"description": "description__3g0y2"
};