exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editor__UutNJ{width:12.5rem;border:1px solid #add8e6;font-weight:normal;font-size:1.4em;background:#fff}.editor__UutNJ .CodeMirror{height:auto}.editor__UutNJ .CodeMirror pre{font-family:\"Catamaran\" !important}.completionItem__AVDzd{font-family:\"Catamaran\" !important;font-size:.875rem}.bulletVariable__1e5rN{color:#adff2f}.CodeMirror-hints{z-index:1001 !important;min-width:12.5rem;border:none !important}.CodeMirror-hints .CodeMirror-hint-active__2HMWs{background:#e6f7ff;color:#000}", ""]);

// exports
exports.locals = {
	"editor": "editor__UutNJ",
	"completionItem": "completionItem__AVDzd",
	"bulletVariable": "bulletVariable__1e5rN",
	"CodeMirror-hint-active": "CodeMirror-hint-active__2HMWs"
};