import React from 'react'
import { Table, Tag } from 'antd'

import { Link } from 'react-router-dom'

import { isEmpty } from 'utils/object'

import routes from '../routes'

import RelativeTime from 'components/Commons/RelativeTime'
import ArchivedFilter from 'components/Commons/Filters/ArchivedFilter'
import SectionHeader from '../SectionHeader/SectionHeader'
import SectionElementsTable from '../Section/SectionElementsTable/SectionElementsTable'

import CreateOrganizationProjectButton from './CreateOrganizationProjectButton'
import OrganizationProjectActions from './OrganizationProjectActions'

import GrantAvatarList from './GrantAvatarList'

import styles from '../Section/Section.scss'
import ownStyles from './OrganizationProjects.scss'

const { Column } = Table

const OrganizationProjects = ({ organization, hasOrgWriteAccess, projects, pageSize, refetchQuery, handleTableChange, onPageChanged, onSearch, archived, setArchived }) => (
  <div className={styles.container}>
    <SectionHeader
      onSearch={onSearch}
      actions={
        hasOrgWriteAccess ? 
          <CreateOrganizationProjectButton organization={organization} onCreated={refetchQuery} />
        : null
      }
    >
      <ArchivedFilter archived={archived} setArchived={setArchived} />
    </SectionHeader>
    <SectionElementsTable
      data={projects}
      pageSize={pageSize}
      onPageChanged={onPageChanged}
      handleTableChange={handleTableChange}
      rowKeyPath="_id"
      dataPath={['organization', 'projects']}
      actions={project => <OrganizationProjectActions organization={organization} project={project} refreshItems={refetchQuery} />}
    >
      <Column
        title="Name"
        key="name"
        render={project => (
          <div className={ownStyles.nameRow}>
            <Link to={routes.oldProjectRouteForId(project.masterBranch._id)}>{project.name}</Link>
            {project.archived && <Tag>archived</Tag>}
          </div>
        )}
        sorter
      />
      <Column
        title="Description"
        key="description"
        dataIndex="description"
      />
      {hasOrgWriteAccess &&
        <Column
          title="Grants"
          key="grants"
          render={({ grants }) => (!isEmpty(grants) ? (<GrantAvatarList grants={grants} />) : null)}
        />
      }
      <Column
        title="Created"
        key="createdAt"
        sorter
        render={p => (<RelativeTime date={p.createdAt} />)}
      />
      <Column
        title="Updated"
        key="lastUpdatedAt"
        render={p => (<RelativeTime date={p.lastUpdatedAt} />)}
      />
    </SectionElementsTable>
  </div>
)

export default OrganizationProjects