import React from 'react'
import { Checkbox } from 'antd'

export default class CheckboxEditComponent extends React.Component {
  render() {
    const { value } = this.props
    return (
      <Checkbox
        checked={value}
        onChange={this.onChange}
        ref={node => { this.control = node }}
      />
    )
  }
  onChange = ({ target: { checked } }) => {
    const { save, onChange } = this.props
    onChange(checked)
    save()
  }
  focus() {
    if (this.control) this.control.focus()
  }
}