import { NODE_TYPES, OBJECT_TYPES } from '../../Constants'
import { actorPartNormalize } from '../normalizers/actorPart'

export default {
  parent: { type: NODE_TYPES.DIALOGUE_LINE },
  nodes: [
    {
      match: { object: OBJECT_TYPES.text },
    },
  ],
  normalize: actorPartNormalize
}