import { Sys } from 'beanie-engine-api-js'
import { propEq, head, length, any } from 'ramda'
import openSequence from 'actions/sessions/unreal/openSequence'
import generateSequence from 'actions/sessions/unreal/generateSequence'
import playNode from 'actions/sessions/unreal/playNode'
import Contexts from 'shortcuts/contexts'


export const OPEN_SEQUENCE = ({
  label: 'Open Sequence',
  action: (session, nodes) => openSequence(session, head(nodes)),
  visible: (session, nodes) => {
    const node = head(nodes)

    return length(nodes) === 1 && propEq('sys', Sys.clip, node)
  },
  context: Contexts.Tree
})

export const GENERATE_SEQUENCE = ({
  label: 'Generate Sequence',
  action: generateSequence,
  visible: (session, nodes) => any(propEq('sys', Sys.clip), nodes),
  context: Contexts.Tree
})

export const PLAY_NODE = ({
  label: 'Play Node',
  action: (session, nodes) => playNode(session, head(nodes)),
  visible: (session, nodes) => length(nodes) === 1,
  context: Contexts.Tree
})