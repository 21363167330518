import React, { useMemo } from 'react'
import classNames from 'classnames'
import { getLangResText } from 'selectors/clip'

/**
 * Note: previously this had logic to parse inline elements like notes
 * and markups that were part of the text. Now that we have that stored
 * outside as metadata we don't need much.
 */
const LanguageResourceText = ({ className, langResource }) => {
  const text = useMemo(() => getLangResText(langResource), [langResource])
  return (
    <div className={classNames(className)}>
      <span>{text}</span>
    </div>
  )
}

export default LanguageResourceText