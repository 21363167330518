exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".roleLabel__1eO7J .anticon{margin-right:.5rem;margin-left:.5rem}", ""]);

// exports
exports.locals = {
	"roleLabel": "roleLabel__1eO7J"
};