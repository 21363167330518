
// forgot pass workflow
const States = {
  Starting: 'starting',
  Validating: 'validating',
  ValidToken: 'valid',
  InvalidToken: 'invalid',

  Resetted: 'resetted'
}

// graphql types
export const ForgotPasswordTokenStatus = { Valid: 'Valid', Invalid: 'Invalid' }
export const ForgotPasswordInvalidTokenReasons = { InvalidToken: 'InvalidToken', ExpiredToken: 'ExpiredToken' }

export default States