import { createActions } from 'reduxsauce'

import { isConnecting, isConnected, isReconnecting } from 'selectors/network'

export const { Types, Creators } = createActions({

  connectingStarted: null,

  connectionSucceeded: null,

  reconnectionStarted: null,

  reconnectionSucceeded: () => async (dispatch, getState) => {
    const state = getState()
    if (isConnected(state) || isConnecting(state)) {
      return
    }

    // in some cases we get reconnected without first telling us that we were reconnecting !
    // we need to keep a standard flow consistent flow ! so we simulate that missing event here
    if (!isReconnecting(state)) {
      await dispatch(Creators.reconnectionStarted())
    }

    // inform we just reconnected
    return dispatch(Creators.connectionReconnected())
  },

  // low-level: use reconnectionSucceeded instead
  connectionReconnected: null,

  connectionError: error => (dispatch, getState) => {
    if (isConnected(getState())) {
      dispatch(Creators._connectionError(error))
    }
  },
  _connectionError: ['error']
})
