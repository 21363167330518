import React from 'react'
import { keys } from 'ramda'
import { Select } from 'antd'
import { withHandlers } from 'recompose'
import classNames from 'classnames'

import styles from './FilterStatusDropdown.scss'

const { Option } = Select

const ALL_VALUE = 'ALL'

const FilterStatusDropdown = ({ className, setFilterStatus, refreshItems, defaultValue, options, allowAll = false }) => (
  <Select
    className={classNames(className, styles.dropdown)}
    defaultValue={allowAll && defaultValue === null ? ALL_VALUE : defaultValue}
    onChange={value => setFilterStatus(value, () => refreshItems())}
  >
    {allowAll && 
    <Option key="all" value={ALL_VALUE} className={styles.allOption}>All</Option>
    }
    {keys(options).map(status => (<Option key={status} value={status}>{options[status]}</Option>))}
  </Select>
)

export default withHandlers({
  setFilterStatus: ({ setFilterStatus }) => value => {
    setFilterStatus(value === ALL_VALUE ? null : value)
  }
})(FilterStatusDropdown)
