import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'

//
// A cache modelled with react "contexts" to detect images (avatars) loading errors
// and avoid requesting them further times.
// This has 2 parts:
//   - Container: components using "useAvatarCacheContext", like CurrentNodePlayback.
//        these ones basically defines the cache lifecycle by a boolean prop.
//        Passing true creates and uses a cache while true
//        Passing false destroys the cache.
//   - Image/Avatar: components that are rendered within the Container and that render images
//        they use "useAvatarCacheForFileName" to use the cache.
//
// The whole thing works like this:
//   - A container starts using a cache
//   - an image is mounted and it detects an error for that specific fileName. The cache remembers that one
//   - on the next attempt to show that same fileName then the cache will tell "dont even try!"
//
// The whole purpose for the moment here is when playing (playback) to avoid making GET requests over and over
// for image files that don't exist ! 'Cause that affects performance (I/O) !
//
// NOTE: this is not a cache of images ! Although that could also be an improvement (?)!

export const AvatarCacheContext = React.createContext()

/**
 *
 */
export const AvatarCache = () => {
  const errored = {}
  return {
    errored(fileName) {
      errored[fileName] = true
    },
    isErrored(fileName) {
      return errored[fileName]
    }
  }
}

/**
 * Controls having a cache in context given a reactive boolean prop.
 * This is used by the container context that wants to set a Cache context
 * then used by "ActorAvatar" components within it. Like "the memory"
 */
export const useAvatarCacheContext = onOff => {
  const avatarCache = useRef()
  useEffect(() => {
    avatarCache.current = onOff ? AvatarCache() : undefined
  }, [onOff])
  return avatarCache
}

/**
 * This one is then used by each component that wants to show an image.
 * Given the original fileName it returns
 *    [maybeFileName, onError]
 *
 * If "maybeFileName" is undefined then it means that the image was previously found an error
 * So you shouldn't try to render and <img> or dont use an image in an avatar.
 * `onError` should be set into the <img> tag or passed to antd Avatar
 */
export const useAvatarCacheForFileName = fileName => {
  const cache = useContext(AvatarCacheContext)

  const onError = useCallback(() => {
    cache?.current?.errored(fileName)
    return true // see antd
  }, [cache?.current])

  const fileNameAvoidingErrored = useMemo(() => {
    if (!fileName) return fileName
    const c = cache?.current
    return c && c.isErrored(fileName) ? undefined : fileName
  }, [fileName, cache?.current])

  return [fileNameAvoidingErrored, onError]
}