exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".actions__3WEUo{display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}", ""]);

// exports
exports.locals = {
	"actions": "actions__3WEUo"
};