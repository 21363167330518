
export const DnDTypes = {
  // tree view
  Node: 'node',
  Lane: 'lane',

  // text editor
  Storyboard: 'storyboard',

  // truth tables special
  TruthTableColumn: 'TruthTableColumn',
}
