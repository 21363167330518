import React from 'react'
import { Button } from 'antd'

import { ModalConsumer } from 'components/Commons/Modal'
import InviteToOrganizationModal from 'components/Organizations/OrganizationMembers/InviteToOrganizationModal'

const InviteToOrganizationButton = ({ organization, onInvited, refreshMembers }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <Button type="primary" onClick={() => showModal(InviteToOrganizationModal, { organization, onInvited, refreshMembers })}>Create Invite</Button>
    )}
  </ModalConsumer>
)

export default InviteToOrganizationButton
