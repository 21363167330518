import { Types } from 'actions/network'
import { NetworkStatus } from 'model/network'

const initialState = {
  status: NetworkStatus.connected
}

export const network = (state = initialState, action) => {
  switch (action.type) {
    case Types.CONNECTING_STARTED: return {
      status: NetworkStatus.connecting
    }
    case Types.CONNECTION_SUCCEEDED: return {
      status: NetworkStatus.connected
    }
    case Types.RECONNECTION_STARTED: return {
      status: NetworkStatus.reconnecting
    }
    case Types.CONNECTION_RECONNECTED: return {
      status: NetworkStatus.connected
    }
    case Types._CONNECTION_ERROR: return {
      status: NetworkStatus.disconnected,
      lastError: action.error
    }
    default: return state
  }
}