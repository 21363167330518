import { always, prop, propOr } from 'ramda'
import { preference } from 'selectors/view'
import Preferences from 'preferences/Preferences'
import { Sys } from 'beanie-engine-api-js'
import { nodeIdFor, textViewItemIdFor, scrollIntoView, ScrollAlign } from 'dom/dom'
import { EMPTY_ARRAY } from 'utils/object'
import { reducePromises } from 'utils/promises'
import { objectById } from 'selectors/objects'

const ALIGMENT_BY_SYS = {
  [Sys.truth_table]: ScrollAlign.start,
  [Sys.truth_table_row]: ScrollAlign.start,
  default: ScrollAlign.center
}

import viewPanelStyles from 'components/ViewPanel/ViewPanel.scss'

const scrollings = [
  {
    pref: Preferences.GraphView.trackSelection,
    tracker: nodeIdFor,
    alignment: obj => propOr(ALIGMENT_BY_SYS.default, prop('sys', obj))(ALIGMENT_BY_SYS)
  },
  {
    pref: Preferences.TextEditor.trackSelection,
    tracker: textViewItemIdFor,
    alignment: always(ScrollAlign.start),
    boundarySelector: `.${viewPanelStyles.container}`
  }
]

export const scrollNodeIntoView = (id, { forceInViews = EMPTY_ARRAY, force = false } = { forceInViews: EMPTY_ARRAY, force: false }) => (dispatch, getState) => {
  const state = getState()
  const node = objectById(id)(state)

  return reducePromises(
    ({ pref }) => forceInViews.includes(pref) || !!preference(pref)(state) || force,
    ({ tracker, alignment, boundarySelector }) => scrollIntoView(tracker(id), alignment(node), boundarySelector)
  )(scrollings)
}