import { assoc, dissoc, has, map, pipe, path as ramdaPath, reduce, trim } from 'ramda'
import * as jsondiffpatch from 'jsondiffpatch'
import { Data } from 'slate'
import { EMPTY_STRING } from 'utils/string'
import { merge } from '../changes/mergeChanges'
import { rebase } from '../changes/rebaseChanges'
import { EMPTY_ARRAY, EMPTY_OBJECT, isEmpty } from 'utils/object'
import { customDirectorLine, customDirectorLineWithCustomData } from '../slateMocks/directorLine'
import { lineIdFromNode } from '../changes/lineChange'
import { NODE_TYPES } from '../Constants'
import { customTextPart } from '../slateMocks/actorTextPart'
import { customDialogueLine } from '../slateMocks/dialogueLine'
import { cloneNodes } from '../slateMocks/clone'
import { customActorPart } from '../slateMocks/actorPart'
import { textNode } from '../slateMocks/textNode'
import { inlineNode } from '../slateMocks/inlineNode'
import { NoteDelimiters, NoteTypes } from 'model/constants'
import { emptyEditorDocument } from '../slateMocks/editor'
import { isDialogueLine } from '../../model/Line'
import { findDescendentOfType } from '../nodeUtils'
import { noop } from 'utils/functions'

export default () => ({

  commands: {

    clearContent: editor => editor.replaceContent(emptyEditorDocument()),

    replaceContent: (editor, { nodes: newLines }) => {
      const previousLines = editor.getLines()
      newLines.forEach((line, i) => {
        const currentLine = editor.nodeByPath([i])
        if (currentLine) {
          editor.replaceBlockByKey(currentLine.key, line)
        } else {
          editor.createBlock(EMPTY_ARRAY, i, line)
        }
      })

      if (previousLines.size > newLines.size) {
        previousLines.slice(newLines.size)
          .forEach(excessLine => editor.removeNodeByKey(excessLine.key))
      }
    },

    updateContent: (editor, content) => {
      const { document: { nodes } } = content
      editor.historyTransaction(e => {
        const jsonDiff = jsondiffpatch.create({})
        const currentJSLines = e.value.document.nodes.toJS()
        const diff = jsonDiff.diff(currentJSLines, nodes.toJS())

        // Just replace the content when the value its different
        if (diff) {
          const focus = e.currentFocus()
          e.replaceContent(content.document)
          e.restoreFocusByPath(focus.toJS())
        }
      })
    },

    removeBneReferenceFromLine: (editor, line) => {
      const { data } = line.toJS()

      const newData = pipe(
        dissoc('line_id'),
        dissoc('res_id'),
        dissoc('fqn'))(data)

      editor.replaceBlockByKey(line.key, line.set('data', Data.create(newData)))
    },

    removeDuplicatedReferences: editor => {
      reduce((visitedIds, line) => {
        const currentId = lineIdFromNode(line)

        if (!currentId) return visitedIds

        if (has(currentId, visitedIds)) {
          editor.removeBneReferenceFromLine(line)
        }

        return assoc(currentId, true, visitedIds)

      }, EMPTY_OBJECT, editor.getLines())
    },

    mergeTwoLines: (editor, startLine, endLine) => {
      MERGE_BY_TYPES[startLine.type][endLine.type](editor, startLine, endLine)
    },

    deletePartialDialogueLineContent: (editor, line) => {
      const actorName = findDescendentOfType(NODE_TYPES.ACTOR_PART)(line)
      const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(line)

      const actorNameIsFullySelected = editor.nodeIsFullySelected(actorName)
      const actorTextIsFullySelected = editor.nodeIsFullySelected(textPart)

      if (editor.startIsAtStartOfNode(line)) {
        if (actorNameIsFullySelected) {
          editor.replaceBlockByKey(actorName.key, customActorPart([textNode('')]))
          editor.moveStartToStartOfNode(textPart)
        }

        // end is at the end of line
      } else if (actorTextIsFullySelected) {
        editor.replaceBlockByKey(textPart.key, customTextPart([textNode('')]))
        editor.moveEndToEndOfNode(actorName)
      }

      editor.delete()
    },

    deleteSelection: e => {
      e.historyTransaction(editor => {
        const start = editor.currentStart()
        const end = editor.currentEnd()

        const fullySelectedLines = editor.linesFullySelected()
        const startLine = editor.lineByIndex(start.path.first())
        const endLine = editor.lineByIndex(end.path.first())
        const startLineIsFullySelected = editor.nodeIsFullySelected(startLine)
        const endLineIsFullySelected = editor.nodeIsFullySelected(endLine)

        if (startLine === endLine && !startLineIsFullySelected) {
          editor.delete()
          return
        }

        // delete all the fully selectedLines
        fullySelectedLines.forEach(editor.removeNode)

        if (!endLineIsFullySelected) {
          editor.moveStartToStartOfNode(endLine)

          if (isDialogueLine(endLine)) {
            editor.deletePartialDialogueLineContent(endLine)
          } else { editor.delete() }
        }

        if (!startLineIsFullySelected) {
          editor.setStart(start)
          editor.moveEndToEndOfNode(startLine)

          if (isDialogueLine(startLine)) {
            editor.deletePartialDialogueLineContent(startLine)
          } else { editor.delete() }
        }

        if (!(startLineIsFullySelected || endLineIsFullySelected)) {
          // merge the partial lines
          editor.mergeTwoLines(
            editor.nodeByKey(startLine.key),
            editor.nodeByKey(endLine.key)
          )

          editor.setFocus(start)
          editor.collapse()
        }
      })
    },

    merge: (editor, initialValue, valueToMerge) => { merge(editor, initialValue, valueToMerge) },

    rebase: (editor, initialValue, valueToMerge) => { rebase(editor, initialValue, valueToMerge) },

    moveCursorToEndOfLine: editor => {
      editor.collapse()
      editor.moveToEndOfNode(editor.currentPhysicalLine())
    },
    moveCursorToStartOfLine: editor => {
      editor.collapse()
      editor.moveToStartOfNode(editor.currentPhysicalLine())
    },

    moveCursorToEditorLine: (editor, index) => {
      editor.moveToPath([index])
    },
    moveCursorToTextPartOfDialogue: (editor, index) => {
      editor.moveToPath([index, 1])
    },
    moveCursorToActorPartOfDialogue: (editor, index) => {
      editor.moveToPath([index, 0])
    },

    cleanEmptyLines: (editor) => {
      editor.getLines().forEach(({ key }) => editor.checkAndCleanNodeByKey(key))
    },

    checkAndCleanNodeByKey: (editor, key) => {
      const node = editor.nodeByKey(key)
      if (node && trim(node.text) === EMPTY_STRING) {
        editor.removeNode(node)
      }
    },

    createNewDirectorLine: (editor, path, nodes) => { createNewDirectorLine(editor, path, nodes) },

    splitCurrentLine: editor => {
      const lineBlock = editor.currentLineBlock()
      const { isCollapsed } = editor.currentSelection()
      if (lineBlock.type === NODE_TYPES.DIALOGUE_LINE && isCollapsed && editor.focusIsAtStartOfNode(lineBlock)) {
        const [lineNumber] = editor.pathByKey(lineBlock.key).toJS()
        createNewDirectorLine(editor, [lineNumber - 1], [])
      } else {
        split(editor)
      }
    },

    // focus

    moveFocusToEndOfLine: editor => { editor.moveFocusToEndOfNode(editor.currentPhysicalLine()) },
    moveFocusToStartOfLine: editor => { editor.moveFocusToStartOfNode(editor.currentPhysicalLine()) },

  },

})

// Split utils

const createNewDirectorLine = (editor, path, nodes = []) => {
  const index = (path.first ? path.first() : path[0]) + 1
  editor.createBlock([], index, customDirectorLine(nodes))
  editor.moveToPath([index, 0])
}

const splitNote = (noteType, openDelimiter, closeDelimiter) => (editor, { left, right, parent, parentPath }) => {
  const newParentPath = editor.parentPath(parentPath)
  const newParent = editor.blockByPath(newParentPath)
  const params = (left.text === '') ?
    {
      left: inlineNode([left], noteType, parent.data),
      right: inlineNode([right], noteType)
    }
    : {
      left: inlineNode([textNode(`${left.text}${closeDelimiter}`)], noteType, parent.data),
      right: inlineNode([textNode(`${openDelimiter}${right.text}`)], noteType)
    };

  getSplitStrategy(newParent)(editor,
    { ...params,
      splited: parent,
      parent: newParent,
      parentPath: newParentPath })
}

const splitTextPart = (editor, { left, right, splited, parent, parentPath }) => {
  const leftNodes = [...editor.leftNodesOf(parent, splited), left]
  const rightNodes = [right, ...map(dissoc('data'), editor.rightNodesOf(parent, splited))]
  const index = parentPath.first() + 1

  editor.replaceBlockByKey(parent.key, customTextPart(leftNodes, parent.data))
  editor.createBlock([], index, customDialogueLine(
    customActorPart(cloneNodes(editor.currentActorPart().nodes)),
    customTextPart(rightNodes)
  ))
  editor.moveToPath([index, 1])
  editor.moveToStartOfBlock()
}

const splitActorPart = (editor, { left, right, splited, parent, parentPath }) => {
  const leftNodes = [...editor.leftNodesOf(parent, splited), left]
  const rightNodes = [right, ...editor.rightNodesOf(parent, splited)]
  const dialogueTextPath = [parentPath.first(), 1]
  const currentDialogueText = editor.blockByPath(dialogueTextPath)
  editor.replaceBlockByKey(parent.key, customActorPart(leftNodes))
  editor.replaceBlockByKey(currentDialogueText.key,
    customTextPart([...rightNodes, textNode('\n'), ...cloneNodes(currentDialogueText.nodes)]))
  editor.moveToPath(dialogueTextPath)
  editor.moveToStartOfBlock()
}

const splitDirectorLine = (editor, { left, right, splited, parent, parentPath }) => {
  const leftNodes = [...editor.leftNodesOf(parent, splited), left]
  const rightNodes = [right, ...map(dissoc('data'), editor.rightNodesOf(parent, splited))]

  editor.replaceBlockByKey(parent.key, customDirectorLineWithCustomData(leftNodes, parent.data))
  createNewDirectorLine(editor, parentPath, rightNodes)
}

const noSplit = () => {}

const splitParent = {
  [NODE_TYPES.DIRECTOR_LINE]: splitDirectorLine,
  [NODE_TYPES.ACTOR_PART]: splitActorPart,
  [NODE_TYPES.TEXT_PART]: splitTextPart,
  [NODE_TYPES.PERFORMANCE_NOTE]: splitNote(NODE_TYPES.PERFORMANCE_NOTE, NoteDelimiters[NoteTypes.Performance].left, NoteDelimiters[NoteTypes.Performance].right),
  [NODE_TYPES.PRODUCTION_NOTE]: splitNote(NODE_TYPES.PRODUCTION_NOTE, NoteDelimiters[NoteTypes.Production].left, NoteDelimiters[NoteTypes.Production].right),
  DEFAULT: noSplit,
}

const getSplitStrategy = ({ type }) => splitParent[type] || splitParent.DEFAULT

const split = editor => {
  const splitResult = splitCurrentText(editor)
  getSplitStrategy(splitResult.parent)(editor, splitResult)
  editor.moveTo(0)
}

const splitCurrentText = editor => {
  const { value: { selection: { start } } } = editor
  const currentTextNode = editor.currentTextNode()
  const [leftText, rightText] = currentTextNode.splitText(start.offset)
  const parentPath = editor.parentPath(editor.currentPath())
  return {
    left: leftText,
    right: rightText,
    splited: currentTextNode,
    parentPath,
    parent: editor.blockByPath(parentPath)
  }
}

const mergeNodesWithData = editor => ({ key, nodes, data, builder, after = noop }) => {
  editor.replaceBlockByKey(key, builder(cloneNodes(nodes), data))
  after()
}

const MERGE_BY_TYPES = {
  [NODE_TYPES.DIRECTOR_LINE]: {
    [NODE_TYPES.DIRECTOR_LINE]: (editor, dir1, dir2) => {
      mergeNodesWithData(editor)({
        key: dir1.key,
        nodes: [...dir1.nodes, ...dir2.nodes],
        data: dir1.data,
        builder: customDirectorLineWithCustomData,
        after: () => editor.removeNode(dir2)
      })
    },
    [NODE_TYPES.DIALOGUE_LINE]: (editor, dir, diag) => {
      const actorName = findDescendentOfType(NODE_TYPES.ACTOR_PART)(diag)
      if (isEmpty(actorName.text)) {
        const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(diag)

        mergeNodesWithData(editor)({
          key: dir.key,
          nodes: [...dir.nodes, ...textPart.nodes],
          data: dir.data,
          builder: customDirectorLineWithCustomData,
          after: () => editor.removeNode(diag)
        })
      }
    },
  },
  [NODE_TYPES.DIALOGUE_LINE]: {
    [NODE_TYPES.DIRECTOR_LINE]: (editor, diag, dir) => {
      const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(diag)

      mergeNodesWithData(editor)({
        key: textPart.key,
        nodes: [...textPart.nodes, ...dir.nodes],
        data: textPart.data,
        builder: customTextPart,
        after: () => editor.removeNode(dir)
      })
    },
    [NODE_TYPES.DIALOGUE_LINE]: (editor, diag1, diag2) => {
      const textPart1 = findDescendentOfType(NODE_TYPES.TEXT_PART)(diag1)
      const actorName2 = findDescendentOfType(NODE_TYPES.ACTOR_PART)(diag2)
      const textPart2 = findDescendentOfType(NODE_TYPES.TEXT_PART)(diag2)

      if (isEmpty(actorName2.text)) {

        mergeNodesWithData(editor)({
          key: textPart1.key,
          nodes: [...textPart1.nodes, ...textPart2.nodes],
          data: textPart1.data,
          builder: customTextPart,
          after: () => editor.removeNode(diag2)
        })
      }
    },
  }
}

export const completeDialogueLine = line => {
  const actorName = findDescendentOfType(NODE_TYPES.ACTOR_PART)(line) || customActorPart([textNode('')])
  const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(line) || customTextPart([textNode('')])
  return customDialogueLine(actorName, textPart, ramdaPath(['data', 'line_id'], line))
}