import React, { useEffect } from 'react'
import CurrentClipPlaybackContainerCreator, { getActorAndResources } from '../nodes/clip/CurrentClipPlaybackContainerCreator'
import PlayCurrentLine from '../nodes/clip/PlayCurrentLine'

/**
 * Playing a clip when the panel is minimized
 */
const MinimizedClipPlayback = ({ node, playbackCurrentObject, onFinishedLine }) => {
  // TODO: we should migrate getActorAndResources to be a real selector and avoid denormalizing
  const { actor, langRes, isDisabled } = getActorAndResources(node, playbackCurrentObject)
  if (!actor || !langRes) { return null }

  useEffect(() => {
    if (langRes && isDisabled) {
      onFinishedLine(node)
    }
  }, [langRes?.id, isDisabled])

  return isDisabled ? null : (
    <PlayCurrentLine node={node} actor={actor} langRes={langRes} onPlayed={onFinishedLine} />
  )
}

export default CurrentClipPlaybackContainerCreator(MinimizedClipPlayback)