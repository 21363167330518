exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2f82U{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:27em}.container__2f82U .errorPreffix__12v1Q{color:#e02525ed}.container__2f82U .error__E6mp7{word-break:break-all;color:#e02525ed;font-size:.8em;padding-left:.5em}", ""]);

// exports
exports.locals = {
	"container": "container__2f82U",
	"errorPreffix": "errorPreffix__12v1Q",
	"error": "error__E6mp7"
};