exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2zH6y{color:#add8e6;cursor:pointer}.iconAsLink__2zH6y:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2yRYB{font-style:italic}.formFieldsVerticalGutter__bJOEF{padding:.5rem}.formFieldsVerticalGutter__bJOEF>*{margin:.5rem !important}.tableBackground__3djTA .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3djTA .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__N3QdA .ant-notification-notice-close{display:none}.dark__oKSzr{background-color:#273749;color:#d2d2d2;border-color:silver}.dark__oKSzr input{background-color:#273749;color:#d2d2d2;border-color:silver}.dark__oKSzr .anticon-close-circle>svg{fill:silver}.dark__oKSzr .noElements__2XaSw{color:rgba(174,174,174,.65)}.dark__oKSzr .noElements__2XaSw:hover{color:rgba(174,174,174,.65)}.assocElement__3salV{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:justify;justify-content:space-between;cursor:default;-ms-flex-align:center;align-items:center}.assocElement__3salV.noAssocElement__gicc8{opacity:.6}.assocElement__3salV.selectedActions__2MaOD{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;height:1.5rem;padding-top:.3rem;padding-bottom:.3rem;padding-right:1.5rem;padding-left:1.5rem;font-size:1.2rem}.assocElement__3salV.selectedActions__2MaOD.showSelectedAndActions__1ZFn3{font-size:.9rem;padding-top:1rem}.emptyMargins__3BywY{margin-right:1rem;margin-left:1rem}.emptyMargins__3BywY .ant-empty-description{color:rgba(174,174,174,.65)}.dropdownInput__1vxXn .ant-input{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.down__2Adxz{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.spinner__1tQDY{text-align:center}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2zH6y",
	"labelFromRef": "labelFromRef__2yRYB",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__bJOEF",
	"tableBackground": "tableBackground__3djTA",
	"notificationNotClosable": "notificationNotClosable__N3QdA",
	"dark": "dark__oKSzr",
	"noElements": "noElements__2XaSw",
	"assocElement": "assocElement__3salV",
	"noAssocElement": "noAssocElement__gicc8",
	"selectedActions": "selectedActions__2MaOD",
	"showSelectedAndActions": "showSelectedAndActions__1ZFn3",
	"emptyMargins": "emptyMargins__3BywY",
	"dropdownInput": "dropdownInput__1vxXn",
	"down": "down__2Adxz",
	"spinner": "spinner__1tQDY"
};