import React from 'react'
import { Button, Icon } from 'antd'
import { compose, withStateHandlers } from 'recompose'

import SearchBox from 'components/Commons/SearchBox'
import CreateProject from 'components/Project/CreateProject'
import ArchivedFilter from 'components/Commons/Filters/ArchivedFilter'

import styles from './ProjectTabHeader.scss'

const ProjectTabHeader = ({ refresh, isCreateVisible, stopShowingModal, handleCreateProject, onSearch, archived, setArchived }) => (
  <div className={styles.pageHeader}>
    <div className={styles.filters}>
      <SearchBox onSearch={onSearch} />
      <ArchivedFilter archived={archived} setArchived={setArchived} />
    </div>
    {isCreateVisible && <CreateProject
      hide={stopShowingModal}
      onCreated={refresh}
    />}
    <CreateProjectButton handleCreateProject={handleCreateProject} />
  </div>
)

const CreateProjectButton = ({ handleCreateProject }) => (
  <Button type="primary" onClick={handleCreateProject}><Icon type="plus" /> Create</Button>
)

export default compose(
  withStateHandlers(
    { isCreateVisible: false },
    {
      handleCreateProject: prev => () => ({ ...prev, isCreateVisible: true }),
      stopShowingModal: prev => () => ({ ...prev, isCreateVisible: false }),
    }
  )
)(ProjectTabHeader)