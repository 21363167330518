exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2iyUV{position:fixed;width:100%;height:100%;z-index:200;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.container__2iyUV .overlay__1oPqI{background-color:#363636;opacity:.6;-ms-flex-positive:1;flex-grow:1}.container__2iyUV .networkStatus__2MW0c{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;text-align:center;-webkit-transition:1s linear height;-o-transition:1s linear height;transition:1s linear height;padding-left:1em;padding-right:1em;height:1.8em}.container__2iyUV .networkStatus__2MW0c.disconnected__2bPLJ{background-color:#c7a6a6;color:#644}.container__2iyUV .networkStatus__2MW0c.disconnected__2bPLJ .anticon-loading{margin-right:.5rem;color:#a45050}.container__2iyUV .networkStatus__2MW0c.disconnected__2bPLJ .anticon:not(.anticon-loading){color:darkred}.container__2iyUV .networkStatus__2MW0c.reconnecting__1j6BC{background-color:#ebeb9a;color:#9b7925}.container__2iyUV .networkStatus__2MW0c .anticon{padding-left:1em}", ""]);

// exports
exports.locals = {
	"container": "container__2iyUV",
	"overlay": "overlay__1oPqI",
	"networkStatus": "networkStatus__2MW0c",
	"disconnected": "disconnected__2bPLJ",
	"reconnecting": "reconnecting__1j6BC"
};