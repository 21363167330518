import { selectedObject } from 'selectors/objects'
import { currentNode as walkedNode } from 'selectors/walklist'
import useLastBetweenSelectors from 'hooks/selectors/useLastBetweenSelectors'

import useObjectUpdater from './useObjectUpdater'
import useSelectNode from './useSelectNode'

/**
 * Gives access to:
 * - the currently selected object and a function
 * - an action to change the selected object
 * - an action to update an object's path with a value. Useful for editing
 *
 * @returns {[currentSelectedObject, selectObject, onPropertyChanged]}
 */
const useObjectSelection = () => {
  const object = useLastBetweenSelectors(walkedNode, selectedObject)

  return [
    object,
    // to change selected
    useSelectNode(),
    // to edit the object (maybe kind of coupled with current usages but well...)
    useObjectUpdater(object)
  ]
}

export default useObjectSelection