import { path, always, pathOr, and, isNil, values } from 'ramda'
import { createSelector } from 'selectors/reselect'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'
import { parseRef } from 'beanie-engine-api-js'
import { objectsIndex } from './apollo'
import { makeMemoizeSelector } from './memoize'

export const currentDebuggingPins = pathOr(EMPTY_OBJECT, ['project', 'revision', 'currentDebugScenarioPins'])
export const currentDebugScenarios = pathOr(EMPTY_ARRAY, ['project', 'revision', 'debugScenarios'])

export const DEBUG_SETTINGS_PATH = ['project', 'revision', 'debugSettings']

export const currentDebugSettings = path(DEBUG_SETTINGS_PATH)
export const isEnabled = pathOr(true, [...DEBUG_SETTINGS_PATH, 'enabled'])
export const currentDebugScenarioSelected = path([...DEBUG_SETTINGS_PATH, 'scenarioSelected'])

export const currentDebuggingPinsAsArray = createSelector(
  [currentDebuggingPins], values
)

//
// Is Pinned
//
// - idIsPinned: use this one for one-time computing (from actions, etc). Lightweight
// - isPinned: ONLY use this one directly from useSelector/connect or as a dependency of another selector
//     but NEVER manually apply it with a state like `isPinned(id)(state)` because it will leak !

/**
 * A regular function to know if an id is pinned. This does not memoize.
 * Util for places where we don't want to create a new selector ! like an on-demand action
 */
export const idIsPinned = (id, state) => currentDebuggingPins(state)[id] !== undefined
const isIsPinnedEnabled = (id, state) => currentDebuggingPins(state)[id]?.enabled || false

const isPinnedEnabled = id => state => isIsPinnedEnabled(id, state)

export const isPinnedFromProps = idFromProps => makeMemoizeSelector(
  { id: idFromProps }, ({ state, id }) => idIsPinned(id, state)
)
export const isPinned = id => isPinnedFromProps(always(id))

//
// is debuggable: basically if it is pinned BUT debug mode is true
// REMEMBER there are two flavors here:
//   - isDebuggable: is a memoizing selector. ONLY USE IT from components where it make sense to memoiza !
//      DON'T use it from custom logic that needs to ask for the value and then discard the selector ! that leaks !
//   - idIsDebuggable: if you have the state in your hand and just want to manually evaluate the value then use this one!
//     it is THE way, lightweight. It won't create a selector just to throw it away.

export const isDebuggable = id => createSelector([isEnabled, isPinnedEnabled(id)], and)
const isIdDebuggable = (id, state) => isEnabled(state) && isIsPinnedEnabled(id, state)

export const getDebuggableContainerContentId = id => makeMemoizeSelector(
  { indexedObjects: objectsIndex }, ({ state, indexedObjects }) => {
    const { data: { container_contents = EMPTY_ARRAY } } = indexedObjects[id]
    for (const ref of container_contents) {
      const childId = parseRef(ref)
      if (isIdDebuggable(childId, state)) {
        return childId
      }
    }
    return undefined
  }
)

export const getDebuggableRowFromTruthTableId = id => makeMemoizeSelector({
  debuggableRow: getDebuggableContainerContentId(id),
  indexedObjects: objectsIndex
},
({ state, debuggableRow, indexedObjects }) => {
  const { data: { otherwise: otherwiseRef } } = indexedObjects[id]
  const otherwise = parseRef(otherwiseRef)

  if (otherwise && isNil(debuggableRow) && isIdDebuggable(otherwise, state)) {
    return otherwise
  }

  return debuggableRow
})