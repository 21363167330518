import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withState } from 'recompose'
import { Input, Checkbox } from 'antd'

import { withTargetChecked, withTargetValue } from 'utils/antd'
import copyProjectMutation from 'api/mutations/copyProject.graphql'
import { projectId } from 'selectors/project'

import openRevisionAction from 'actions/project/openRevision'

import CreateModal from 'components/Commons/CreateModal'

// TODO: fix reusing from the other modal
import styles from './CreateProject.scss'

const CopyProjectModal = ({ handleCopy, hide, name, setName, switchToNewOnCopy, setSwitchToNewOnCopy }) => (
  <CreateModal
    title="Copy Current Project as a new Project"
    titleIcon="warning"
    hide={hide}
    submit={handleCopy}
    canSubmit={!!name}
  >
    <div className={styles.modalContent}>
      <Input placeholder="Name" value={name} onChange={withTargetValue(setName)} onPressEnter={handleCopy} />
      <Checkbox checked={switchToNewOnCopy} onChange={withTargetChecked(setSwitchToNewOnCopy)}>Switch to the new project when finished copying</Checkbox>
    </div>
  </CreateModal>
)

export default compose(
  graphql(copyProjectMutation, { name: 'copyProject' }),
  connect(
    state => ({ project: projectId(state) }),
    {
      openRevision: openRevisionAction
    }
  ),
  withState('name', 'setName'),
  withState('switchToNewOnCopy', 'setSwitchToNewOnCopy', true),
  withHandlers({
    copy: ({ copyProject, project }) => name => copyProject({
      variables: {
        input: {
          projectId: project,
          name
        }
      }
    })
  }),
  withHandlers({
    // need another withHandlers to receive "copy" defined above
    handleCopy: ({ copy, switchToNewOnCopy, name, openRevision }) => async () => {
      const { data: { copyProject: project } } = await copy(name)
      if (switchToNewOnCopy) {
        try {
          await openRevision(project.masterBranch._id)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error loading revision', error)
          throw error
        }
      }
    }
  })
)(CopyProjectModal)
