exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".inlineNode__dou2s{display:-ms-inline-flexbox;display:inline-flex;font-size:.6rem}.propertiesSelectorContainer__1qW4C{margin-top:.6rem}", ""]);

// exports
exports.locals = {
	"inlineNode": "inlineNode__dou2s",
	"propertiesSelectorContainer": "propertiesSelectorContainer__1qW4C"
};