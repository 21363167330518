import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'antd'

import { withTargetValue } from 'utils/antd'
import { isUUID } from 'utils/string'
import { objectsIndex } from 'selectors/apollo'


const ObjectSelector = ({ setObject }) => {
  const [objectId, setObjectId] = useState()

  const objects = useSelector(objectsIndex)

  const handleChange = id => {
    setObject(isUUID(id) ? objects[id] : null)
    setObjectId(id)
  }

  return <Input placeholder="Insert object id" value={objectId} onChange={withTargetValue(handleChange)} />
}

export default ObjectSelector