import React from 'react'
import { Icon, Tooltip } from 'antd'
import withRevision from 'hocs/withRevision'

import styles from './ReadOnlyRevisionMessage.scss'

const ReadOnlyRevisionMessage = ({ revision }) => (revision && !revision.modifiable ?
  <div className={styles.ReadOnlyRevisionMessage}>
    <Tooltip title="Checkpoint versions cannot be modified"><Icon type="warning" theme="filled" /></Tooltip>READ ONLY
  </div> : null
)

export default withRevision(ReadOnlyRevisionMessage)