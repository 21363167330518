import { head, last, splitAt, test, trim } from 'ramda'
import { isEmptyOrNull } from 'utils/string'

export const lastIndexOfMatch = (regex, text) => {
  const re = new RegExp(regex, 'g')
  let lastMatch = re.exec(text)
  if (!lastMatch) return -1
  /* eslint no-constant-condition: 0 */
  while (true) {
    const currentMatch = re.exec(text)
    if (!currentMatch) return lastMatch.index
    lastMatch = currentMatch
  }
}

const isBreakableCharacter = test(/[\s|\\.]/)
const canBreak = (theLast, first) => isBreakableCharacter(theLast) || isBreakableCharacter(first)
export const doSplitTextPart = (maxLength, text) => {
  const [current, next] = splitAt(maxLength, text)
  // TODO: didn't split (one piece)
  if (!next) return [current]
  if (canBreak(last(current), head(next))) {
    return [current.trim(), next.trim()]
  }

  const lastIndex = lastIndexOfMatch(/[\s|,|\\.|-]/, current)
  if (lastIndex === -1) {
    // no match: we should hyphen the word maybe
    return [text.join ? text.join('') : text]
  }
  return splitAt(lastIndex, text).map(trim)
}

const splitText = (maxLength, text) => {
  let t = text
  const parts = []
  while (t && t.length > maxLength) {
    const [first, next] = doSplitTextPart(maxLength, t)
    parts.push(first)
    t = next
  }
  // remaining text
  if (!isEmptyOrNull(t)) {
    parts.push(t)
  }
  return parts
}

export default splitText