import { always, T } from 'ramda'

export const _ = 'ANY'

/**
 * An case to evaluate the context on in order to infer which operation
 * will be done.
 * For example:
 *   If
 *      over a root-node (R)
 *      & at the top-right
 *      & the content is a marker|folder (M)
 *   Then
 *      insert M into "lane.roots" at i(R)
 *
 * Understands #matches(context) => Operation
 */
export default class OperationInferenceRule {

  constructor([target, vertical, horizontal, selections, inferrer]) {
    this.predicates = [
      target,
      positionPredicate(vertical, horizontal),
      selections === _ ? T : selections,
    ]
    this.inferrer = inferrer
  }

  matches(context) {
    return this.predicates.every(p => p(context)) ?
      this.inferrer(context)
      : undefined
  }
}

const positionPredicate = (vertical, horizontal) => {
  // optimization: always match if we don't care horiz/vert position
  if (horizontal === _ && vertical === _) {
    return always(true)
  }

  return ({ hoverPosition }) => (
    hoverPosition &&
    (horizontal === _ || horizontal === hoverPosition.horizontal)
    && (vertical === _ || vertical === hoverPosition.vertical)
  )
}