import { equals, values } from 'ramda'

import { ClipboardContentType, nodesClipboardHeader, laneClipboardHeader, nodePropertiesClipboardHeader } from 'model/app/clipboard/clipboard'
import { EMPTY_OBJECT } from 'utils/object'
import winston from 'utils/logger'

// Object => Promise
export const copyAsJson = async content => {
  try {
    await navigator.clipboard.writeText(JSON.stringify(content))
  } catch (e) {
    winston.error('Cannot write to the clipboard. Check permissions.')
  }
}

export const getAsText = async () => {
  try {
    return await navigator.clipboard.readText()
  } catch (e) {
    winston.error('Cannot read from the clipboard')
  }
}

// Unit => Promise(Object)
export const getFromJson = async () => {
  const jsonContent = await getAsText()

  try {
    return JSON.parse(jsonContent)
  } catch (e) {
    // this can be normal flow of pressing paste with other than nodes in the clipboard
    // this function is for usage with nodes on clipboard, if there ain't nodes on clipboard
    // then it's like nothing is on clipboard.
    return undefined
  }
}

export const nodeFromClipboard = async filter => {
  const clipboard = await getFromJson()
  if (clipboard?.contentType !== ClipboardContentType.Nodes) return undefined
  const objects = values(clipboard.content)
  return objects?.find?.(filter)
}

const TransformClipboard = {
  [ClipboardContentType.Lane]: laneClipboardHeader,
  [ClipboardContentType.Nodes]: nodesClipboardHeader,
  [ClipboardContentType.NodeProperties]: nodePropertiesClipboardHeader
}

/**
 * Receives clipboard's text and redux clipboard content, interprets the text and compares it with the redux clipboard.
 * Will return the header object to be dispatched to the set clipboard meta action. If transformation to header clipboard
 * is not possible, then it will return EMPTY_OBJECT so its dispatch is equivalent to reset the redux clipboard meta.
 * Remember the objective of all this is to have meta and system clipboard in-sync, if system clipboard is not tree-graph
 * then we should not have a header/meta for it.
 *
 * @param {*} reduxClipboard - Used to compare and return false if transformation result is deep equals this arg.
 * @param {*} clipboardText - The text taken from the clipboard to be interpreted and transformed to header form when possible.
 *
 * @returns EMPTY_OBJECT  - if clipboard content is not interpretable as a tree-graph clipboard or if interpreted content is same as reduxClipboard
 *          Object - representing the redux storable tree-graph clipboard header
 */
export const fromTextToHeader = (reduxClipboard, clipboardText) => {
  if (!clipboardText) return EMPTY_OBJECT

  try {
    const parsedHeader = parseHeader(clipboardText)
    const { contentType, manifest } = parsedHeader

    // parsed json needs to have our meta
    if (!contentType || !manifest) return EMPTY_OBJECT

    const transformed = TransformClipboard[contentType](parsedHeader)

    // avoid submitting same redux clipboard twice
    return (equals(reduxClipboard, transformed)) ? EMPTY_OBJECT : transformed

  } catch (e) {
    // TODO: dont report as error? just swallow?
    throw new Error('Cant put that into the redux clipboard.', e.msg)
  }
}

const parseHeader = text => {
  try {
    // TODO: change clipboard serialized form so that we don't need to parse json to get the header
    // e.g.: 'fixed header has N chars then semi-colon then json body;{ "a": "b" }'
    return JSON.parse(text)
  } catch (e) {
    // text on clipboard is not json/parseable
    return EMPTY_OBJECT
  }
}
