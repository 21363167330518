import React from 'react'
import IconButton from 'components/Commons/IconButton'
import useTransaction from '../../hooks/beanie/useTransaction'

import styles from './ExtensionPoint.scss'

/**
 * Renders an ExtensionInstance as a button with an icon.
 * When pressed it will execute the "action" function of the extension in a beanie transaction.
 */
const IconButtonExtension = ({ extension: { label, icon, action }, params = [] }) => {
  const perform = useTransaction(() => () => {
    // TODO: the "invoke" here is coupled with limitations of the engine JS bridge
    (action?.invoke || action)(...params)
  }, [action, ...params], `Extension: ${label}`)

  return (
    <div className={styles.Extension}>
      <IconButton type={icon} onClick={perform} tooltip={label} />
    </div>
  )
}


export default IconButtonExtension