/* eslint-disable no-template-curly-in-string */
import { snippetCompletion as snip } from '@codemirror/autocomplete'
import { applyLabel, Types } from './Completion'

// Common templates autocompletion
export const snippets = withSpace => [
  snip(applyLabel('when ${pattern} then expr1 \n\totherwise expr2', withSpace), {
    label: 'when pattern then expr1 \n\totherwise expr2',
    detail: 'keyword template',
    type: Types.Keyword
  }),
  snip(applyLabel('variable of ', withSpace), {
    label: 'of',
    detail: 'keyword template',
    type: Types.Operator
  }),
  snip(applyLabel('${variable} is value', withSpace), {
    label: 'variable is value',
    detail: 'keyword template',
    type: Types.Operator
  }),
  snip(applyLabel('is ${value}', withSpace), {
    label: 'is value',
    detail: 'keyword template',
    type: Types.Operator
  }),
  snip(applyLabel('set ${variable} to value', withSpace), {
    label: 'set variable to value',
    detail: 'keyword template',
    type: Types.Operator
  }),
]
