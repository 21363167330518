import { compose } from 'recompose'

import Preferences from 'preferences/Preferences'
import withPreference from 'hocs/withPreference'
import connectState from 'hocs/connectState'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'

import { visibleNoteSelector } from '../selectors'
import { renderNote } from '../Note'

import styles from '../../../SlateTextEditor.scss'

const PerformanceNote = ({ editor, attributes, children, node, visible }) => {
  const contentEditable = useSecureCheck(isCurrentRevisionWritable)

  return renderNote({ editor, node, visible, attributes, children, styleComp: styles.PerformanceNote, contentEditable })
}

export default compose(
  withPreference({ visibleNotes: Preferences.TextEditor.visibleNotes }),
  connectState({
    visible: visibleNoteSelector
  })
)(PerformanceNote)
