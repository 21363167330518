import React from 'react'
import { connect } from 'react-redux'
import { userId } from '../../selectors/auth'
import UserName from '../Commons/UserName'
import AvatarList from '../AvatarList/AvatarList'
import RevisionSessionAvatar from './RevisionSessionAvatar'
import RevisionSessionOrigin from './RevisionSessionOrigin'

import styles from './RevisionSessions.scss'

const RevisionSessionsList = ({ ownUserId, sessions, ...others }) => (
  <AvatarList
    className={styles.RevisionSessionsList}
    renderAvatar={session => (
      <RevisionSessionAvatar
        key={session._id}
        session={session}
        {...others}
      />
    )}
    renderTitle={renderSessionTitle(ownUserId)}
    list={sessions}
  />
)

const renderSessionTitle = ownUserId => session => (
  <div className={styles.RevisionSessionTitle}>
    <div>
      {session.user._id === ownUserId ?
        <React.Fragment>You <RevisionSessionOrigin className={styles.RevisionSessionOrigin} session={session} /></React.Fragment>
        : <UserName user={session.user} />
      }
    </div>
  </div>
)

export default connect(state => ({
  ownUserId: userId(state)
}))(RevisionSessionsList)
