import { or, equals } from 'ramda'

/* eslint prefer-template: 0 */
export const ownURLForPath = path => {
  const { hostname, port, protocol } = window.location
  return `${protocol}//${hostname}${!port || isStandardPort(port) ? '' : (':' + port)}${path}`
}

const isStandardPort = or(equals(80), equals(443))

export const isDev = () => window.location.hostname === 'dev.brunerhouse.com'

export const clientWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
export const clientHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

export const saveAsFile = (fileName, content) => {
  // note: don't inline this variable !!
  const url = window.URL.createObjectURL(content)
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', fileName)
  tempLink.click()

  // cleanup
  setTimeout(() => {
    if (tempLink.parentNode) {
      tempLink.parentNode.removeChild(tempLink)
      window.URL.revokeObjectURL(url)
    }
  }, 10000)
}

export const hardRefresh = () => window.location.reload(true)