import { uniqBy, prop, match, propEq } from 'ramda'
import winston from 'utils/logger'
import { removeFirstAppearance, clear } from 'utils/list'
import { transformText } from './text-transform'
import noopSay from './tts-noop'

// TODO: review this ! it seems to have several issues
//  - clips: why do we push ids there ? we just push and no one seems to read. This will leak !
//  - why use arrays ? we should try to use objects that are indexed if we need to check them
const state = {
  startedClips: [],
  endedClips: [],
  clips: [],

  voices: [],
}

export const beginClip = id => {
  state.startedClips.push(id)
  state.endedClips
    .filter(match(new RegExp(`^${id}`)))
    .forEach(removeFirstAppearance(state.endedClips))
}
export const endClip = ::state.endedClips.push

export const pauseAllTts = () => {
  if (synth) { synth.pause() }
}
export const resumeAllTts = () => {
  if (synth) { synth.resume() }
}
export const stopAllTts = (clearingClips = true) => {
  if (synth) { synth.cancel() }
  if (clearingClips) { clearClips() }
}

const clearClips = () => { clearEndedClips(); clearStartedClips() }
const clearEndedClips = () => { clear(state.endedClips); clear(state.clips) }
const clearStartedClips = () => { clear(state.startedClips) }

// objects

const synth = window.speechSynthesis
if (synth) { // for test env
  synth.onvoiceschanged = () => {
    state.voices = uniqBy(prop('voiceURI'), synth.getVoices())
  }
}
let ttsQueue = Promise.resolve();

export const getVoices = () => state.voices
const LOCALE_REGEX = /([^-]*)-?([^-]*)?/
export const parseLocale = voice => {
  const [, lang, country] = voice.lang.match(LOCALE_REGEX)
  return { lang, country }
}

const speak = (utterance, clipId) => {
  let timer
  const waitUtterance = () => {
    if (!synth.speaking) {
      synth.cancel()
      state.clips.push(clipId)
      utterance.onend()
      clearTimeout(timer)
      return
    }
    timer = setTimeout(waitUtterance, 200)
  }

  const speakTimer = setTimeout(() => {
    if (!synth.speaking) {
      clearTimeout(speakTimer)
      synth.speak(utterance)
      waitUtterance()
    }
  }, 100)
}


const say = (text, { volume, speechVoice, speechPitch = 1, speechRate = 1 }, id) => {
  ttsQueue = ttsQueue.then(doSay(text, volume, speechVoice, speechPitch, speechRate, id))
  return ttsQueue
}

const doSay = (text, volume, speechVoice, speechPitch, speechRate, id) => () => new Promise((resolve, reject) => {
  const synthText = transformText(text)
  const utterance = new SpeechSynthesisUtterance(synthText)
  if (speechVoice) {
    utterance.voice = state.voices.find(propEq('name', speechVoice))
  }

  utterance.pitch = speechPitch
  utterance.rate = speechRate
  utterance.volume = volume ? volume / 100 : 100

  utterance.onend = resolve

  utterance.onerror = e => {
    winston.error(`Utterance |${text}| fired error event: |${e.error}|`)
    reject()
  }

  if (!state.endedClips.includes(id)) {
    speak(utterance, id)
  } else {
    synth.cancel()
    resolve()
  }
})

export const textToSpeech = (text, prefs = {}, clipId) => {
  const fn = synth ? say : noopSay
  return fn(text, prefs, clipId)
}

