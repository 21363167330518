import React from 'react'
import { map } from 'ramda'
import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { Badge, Card, Spin } from 'antd'
import classnames from 'classnames'

import IconButton from '../Commons/IconButton'
import { input } from 'utils/graphql'
import { capitalise } from 'utils/string'

import queuesCountQuery from 'api/queries/queuesCount.graphql'
import queuesEmptyMutation from 'api/mutations/queuesEmpty.graphql'

import styles from './QueueManagement.scss'
import panelStyle from 'styles/panel.scss'

const QUEUE_STATUSES = ['waiting', 'active', 'delayed', 'paused', 'completed', 'failed']
const OVERFLOW_AT = 99999

const QueueManagementComponent = ({ queuesCount: { queuesCount = [], loading: countLoading, refetch: countRefetch }, clearQueues }) => (
  <Spin spinning={!!countLoading}>
    <div className={classnames(styles.QueueManagement, panelStyle.panel)}>
      <div className={styles.header}>
        <h1>Message/Job Queue Management</h1>
        <div className={styles.headerButtons}>
          <IconButton type="reload" tooltip="Refresh Queues" onClick={() => countRefetch()} disabled={countLoading} />
          <IconButton type="delete" tooltip="Empty All Queues" onClick={() => clearQueues()} disabled={countLoading} />
        </div>
      </div>
      <div className={styles.queues}>
        {
          map(
            ({ name, ...counts }) => (
              <Card
                key={name}
                title={name}
                extra={<IconButton type="delete" tooltip="Empty Queue" onClick={() => clearQueues(name)} disabled={countLoading} />}
                className={styles.cardShadow}

                headStyle={{ fontWeight: 'bold' }}
                bodyStyle={{ padding: '2rem' }}
              >
                {
                  QUEUE_STATUSES
                    .map(countName => (
                      <div key={countName} className={styles.count}>
                        <span>{capitalise(countName)}</span>
                        <Badge count={counts[countName]} overflowCount={OVERFLOW_AT} className={styles.badge} showZero />
                      </div>
                    ))
                }
              </Card>
            ),
            queuesCount
          )
        }
      </div>

    </div>
  </Spin>
)

const QueueManagement = compose(
  graphql(queuesCountQuery, {
    name: 'queuesCount',
    options: { fetchPolicy: 'cache-and-network' }
  }),
  graphql(queuesEmptyMutation, {
    name: 'queuesEmpty',
    options: { refetchQueries: [{ query: queuesCountQuery }] }
  }),
  withHandlers({
    clearQueues: ({ queuesEmpty }) => async queueName => {
      await queuesEmpty(input({ ...queueName && { queueName } }))
    }
  })
)(QueueManagementComponent)

export default QueueManagement
