exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".choices__4lAo0{background:beige;border-radius:20px;padding-left:3rem;padding-top:1rem}", ""]);

// exports
exports.locals = {
	"choices": "choices__4lAo0"
};