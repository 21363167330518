import { compose, withStateHandlers, withHandlers, withProps } from 'recompose'
import { path, equals, pipe, prop, propEq, find, nth, toPairs, indexBy, map, concat } from 'ramda'
import { graphql } from 'react-apollo'

import { reQuery, gqlProps } from 'api/queries/utils'
import { SortOrder } from '../model/constants'
import { isEmpty, isEmptyObject } from '../utils/object'

const defaultVariables = () => ({})
const defaultOptions = () => ({})

/**
 input: { [field1]: [value1], [field2]: [value2] }
 output: [{ field: field1, values: [value1] }, { field: field2, values: [value2] }]
 */
const transformFilters = filters =>
  toPairs(filters) // [ [ field1, [value1] ], [ field2, [value2] ] ]
    .map(([field, values]) => ({ field, values }))

const mergeFixedFields = (prev, next) => {
  if (equals(prev, next)) return next

  const prevFilterByFields = prev?.fields || []
  const nextFilterByFields = next?.fields || []

  const fixedFilters = prevFilterByFields.filter(propEq('fixed', true))
  const fields = indexBy(prop('field'), fixedFilters)

  const result = {}
  result.fields = pipe(
    map(aFilter => {
      if (fields[aFilter.field]) {
        const tmp = fields[aFilter.field]
        // fixed fields can't be filtered through UI
        delete fields[aFilter.field]
        return tmp
      }
      return aFilter
    }),
    concat(fixedFilters)
  )(nextFilterByFields)

  return result
}

/**
 *  paginatedQuery({
 *   query: GqlQuery,
 *   name: String,
 *   pageSize: 10,
 *   variables: () => { [key]: value },
 *   options : () => { [key]: value }
 * })
 */
const paginatedQuery = ({ query: gqlQuery, name, defaultSort, pageSize: size = 9, variables = defaultVariables, options = defaultOptions, ...apolloProps }) => compose(
  graphql(gqlQuery, {
    name,
    options: props => ({
      variables: { page: 1, size, sortBy: defaultSort, ...variables(props) },
      ...options(props)
    }),
    ...gqlProps({
      changePage: (query, page, sortBy, filterBy, props) => reQuery({ page, query, size, sortBy, filterBy, ...variables(props) }),
      search: (query, sortBy, filterBy, props) => reQuery({ query, size, sortBy, filterBy, ...variables(props) }),
      sort: (query, sortBy, filterBy, props) => reQuery({ query, size, sortBy, filterBy, ...variables(props) }),
      filter: (query, sortBy, filterBy, props) => reQuery({ query, size, sortBy, ...variables(props), filterBy })
    }, name),
    ...apolloProps
  }),
  withStateHandlers({
    query: undefined,
    page: 1,
    sortBy: undefined,
    filterBy: undefined
  }, {
    setPage: prev => page => ({ ...prev, page }),
    setQuery: prev => query => ({ ...prev, query }),
    setSortBy: prev => sortBy => ({ ...prev, sortBy }),
    setFilterBy: prev => filterBy => ({ ...prev, filterBy }),
  }),
  withProps({ pageSize: size, name }),
  withHandlers({
    onPageChanged: ({ changePage, setPage, query, sortBy, filterBy, ...others }) => page => {
      setPage(page)
      changePage(query, page, sortBy, filterBy, others)
    },
    onSearch: ({ search, sortBy, filterBy, setQuery, ...others }) => (query, moreProps = {}) => {
      setQuery(query)
      search(query, sortBy, filterBy, { ...others, ...moreProps })
    },
    refetchQuery: ({ [name]: queryRef }) => queryRef.refetch,
    refreshItems: ({ changePage, query, page, sortBy, filterBy, ...others }) => () => (
      changePage(query, page, sortBy, filterBy, others)
    ),
    handleTableChange: ({ sortBy, setSortBy, sort, filterBy, setFilterBy, filter, query, ...others }) => (pagination, filters, sorter) => {

      const newFilterBy = filters && !isEmpty(filters)
        ? {
          fields: transformFilters(filters)
        }
        : undefined

      const fixedNewFilterBy = mergeFixedFields(variables(others).filterBy, newFilterBy)
      setFilterBy(fixedNewFilterBy)
      filter(query, sortBy, fixedNewFilterBy)

      const newSortBy = sorter && !isEmptyObject(sorter) ? {
        field: sorter.columnKey,
        order: sorter.order === AntdSortOrderDescend ? SortOrder.DESCENDENT : SortOrder.ASCENDENT
      } : undefined

      if (!equals(sortBy, newSortBy)) {
        setSortBy(newSortBy)
        sort(query, newSortBy, filterBy, others)
      }
    },
  })
)

export default paginatedQuery

//

const AntdSortOrderDescend = 'descend'

export const queryName = pipe(
  prop('definitions'),
  find(propEq('operation', 'query')),
  path(['selectionSet', 'selections']),
  nth(0),
  path(['name', 'value'])
)
