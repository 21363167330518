import { reduce, pipe, prop, trim, propEq } from 'ramda'
import { Text } from 'slate'
import { EMPTY_ARRAY } from 'utils/object'
import { isEmptyOrNull } from 'utils/string'
import { OBJECT_TYPES } from './Constants'

export const isBlockOfType = type => propEq('type', type)

export const isText = propEq('object', OBJECT_TYPES.text)

export const nodeIsEmptyText = pipe(prop('text'), trim, isEmptyOrNull)

//
// generic slate node traversing utilities
//

export const findDescendentOfType = type => node => {
  if (node.type === type) return node;

  return reduce((found, child) => {
    if (found) return found
    return findDescendentOfType(type)(child)
  }, null, node.nodes || EMPTY_ARRAY)
}

const _findDescendentsOfType = type => (node, result) => {
  if (node.type === type) {
    result.push(node)
    return result
  }

  (node.nodes || EMPTY_ARRAY).forEach(child => {
    _findDescendentsOfType(type)(child, result)
  })
  return result
}

export const findDescendentsOfType = type => node => _findDescendentsOfType(type)(node, [])

export const getTextNodes = node => node.nodes.filter(n => Text.isText(n))