import React from 'react'
import { graphql } from 'react-apollo'
import { compose, shallowEqual, withPropsOnChange } from 'recompose'
import { Icon, Button } from 'antd'

import revisionBuildConfigs from 'api/queries/revisionBuildConfigs.graphql'
import runtime from 'api/queries/runtime.graphql'

import styles from 'styles/panel.scss'
import BuildConfigsTable from './BuildConfigsTable'
import AddBuildConfig from './EditBuildConfig'
import withRevisionId from 'hocs/withRevisionId'

const BuildConfigsTab = ({ revisionBuildConfigsData, runtimeData, isLoading }) => {
  if (isLoading) return <Loading />

  return (
    <div>
      <div className={styles.pageHeader}>
        <AddBuildConfig
          Button={({ onClick }) => <Button type="primary" icon="plus" onClick={onClick}>Create</Button>}
          callback={revisionBuildConfigsData.refetch}
          runtimeData={runtimeData.getRuntime}
        />
      </div>
      <BuildConfigsTable
        list={revisionBuildConfigsData.revisionWithId?.buildConfigurations || []}
        onBuildConfigUpdated={revisionBuildConfigsData.refetch}
        runtimeData={runtimeData.getRuntime}
      />
    </div>
  )
}

const Loading = () => (
  <div className={styles.loading}>
    <Icon type="loading" /> Loading...
  </div>
)

export default compose(
  withRevisionId,
  graphql(revisionBuildConfigs, {
    name: 'revisionBuildConfigsData',
    options: ({ revisionId }) => ({ variables: { revisionId } })
  }),
  graphql(runtime, {
    name: 'runtimeData',
    options: ({ revisionId }) => ({
      variables: {
        revisionId,
      },
      fetchPolicy: 'no-cache'
    }),
  }),
  withPropsOnChange(
    (prev, next) => !shallowEqual(prev.runtimeData?.loading, next.runtimeData?.loading) || !shallowEqual(prev.revisionBuildConfigsData?.loading, next.revisionBuildConfigsData?.loading),
    ({ runtimeData, revisionBuildConfigsData }) => ({
      isLoading: runtimeData.loading || revisionBuildConfigsData.loading
    })
  ),
)(BuildConfigsTab)
