import React from 'react'
import RowCell from '../../row/RowCell'
import TruthTableColumnHeader from '../../header/TruthTableColumnHeader'
import { EMPTY_ARRAY } from 'utils/object'

/**
 *
 */
const DraggingColumn = ({ item: { columnIndex, truthTable, column: { header, cells } }, rows = EMPTY_ARRAY }) => (
  <tbody>
    <tr>
      <TruthTableColumnHeader
        index={columnIndex}
        truthTable={truthTable}
        header={header}
      />
    </tr>
    {cells.map((cell, i) => (
      <tr key={`${i}-${columnIndex}`}>
        <RowCell
          rowIndex={i}
          columnIndex={columnIndex}
          truthTable={truthTable}
          node={rows[i]}
          cell={cell}
        />
      </tr>
    ))}
  </tbody>
)

export default DraggingColumn