import React, { useCallback, useState } from 'react'
import { Button, Input } from 'antd'
import { useDispatch } from 'react-redux'
import { sendCommandResponse } from '../../actions/project/session'

/**
 * Hacky temporary UI for unknown commands received from other clients.
 * Just for debugging purposes
 */
const SessionRequestModal = ({ request }) => {

  const [response, setResponse] = useState()

  const dispatch = useDispatch()

  const sendResponse = useCallback(() => {
    dispatch(sendCommandResponse(request, response))
  }, [request, response])

  return (
    <div>
      <div>
        <div>
          {request.from.user.firstName} {request.from.user.lastName}
        </div>
        <div>
          Says: <pre>{request.command}</pre>
          With:  <pre>{JSON.stringify(request.params)}</pre>
        </div>
        <div>
          Response: <Input onChange={e => setResponse(e.target.value)} value={response} />
          <Button type="primary" onClick={sendResponse}>Reply</Button>
        </div>
      </div>
    </div>
  )
}

export default SessionRequestModal