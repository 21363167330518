import React from 'react'
import { Icon } from 'antd'

import switchComponent from 'hocs/switchComponent'
import FieldSkeleton from './FieldSkeleton'
import LanesFieldChange from './LanesFieldChange/LanesFieldChange'

import Json from 'components/Commons/Json'

import styles from './FieldChange.scss'

const DefaultFieldChange = ({ field, prev, next }) => (
  <FieldSkeleton field={field}>
    <Json value={prev} />
    <Icon type="arrow-right" className={styles.separator} />
    <Json value={next} />
  </FieldSkeleton>
)

const FieldChange = switchComponent({
  path: ['field'],
  cases: {
    'data.editor.lanes': LanesFieldChange,
  },
  otherwise: DefaultFieldChange
})

export default FieldChange