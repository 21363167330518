import React from 'react'
import classNames from 'classnames'

import SegmentVideoPlayer from '../SegmentVideoPlayer/SegmentVideoPlayer'

import { DEBUG } from '../../config'

import styles from './BufferingVideoPlayer.scss'


const BufferingClipPlayer = ({ url, clip: { id, active, timelineItem }, onCompleted, onPaused, onPlaying, onReady }) => (
  <div className={classNames(
    styles.player, {
      [styles.active]: active,
    })}
  >
    <SegmentVideoPlayer
      url={url}
      id={id}

      segment={timelineItem}
      playing={active}

      onCompleted={() => onCompleted(id)}
      onPaused={() => onPaused(id)}
      onPlaying={() => onPlaying(id)}
      onReady={() => onReady(id)}

      // debug
      width={DEBUG ? (active ? 320 : 160) : undefined}
      height={DEBUG ? (active ? 240 : 100) : undefined}
    />
    {DEBUG && <div>{id}</div>}
  </div>
)

export default BufferingClipPlayer