import * as jsondiffpatch from 'jsondiffpatch'
import { properties } from 'utils/object'

const differ = jsondiffpatch.create({})

export default function arrayDiff(prev, next) {
  const diff = differ.diff(prev, next)

  const mapped = next.map((e, i) => {
    const diffForE = diff ? diff[i] : []
    
    if (isSame(diffForE)) {
      return { state: 'same', value: e }
    } else if (isAdded(diffForE)) {
      return { state: 'added', value: e }
    } else {
      return { state: 'unknown', value: e }
    }
  })

  properties(diff || {}).filter(({ name }) => name !== '_t' && name[0] === '_')
    .forEach(({ name }) => {
      const indexOnPrev = parseInt(name.split('_')[1])
      const deletedElement = prev[indexOnPrev]
      const addedSoFar = mapped.slice(0, indexOnPrev).filter(e => e.state === 'added').length
      mapped.splice(addedSoFar + indexOnPrev, 0, { state: 'deleted', value: deletedElement })
    })

  return mapped
}

const isSame = diff => !diff || diff.length === 0
const isAdded = diff => diff.length === 1