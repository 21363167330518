import { call } from './commons'
import { toBNEAction } from './utils'

export const setMode = call('set_mode')

export const setTaskAsync = toBNEAction(
  (object, nextValue) => api => {
    const luaObj = api.obj.get(object.id)
    luaObj.set_async(nextValue)
  }
)