import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { prop } from 'ramda'
import { isEmptyOrNull } from 'utils/string'

const withFilteredElements = ({ propName, targetPropName = 'elements', filter }) => compose(
  withState(targetPropName, 'setElements', prop(propName)),
  withState('searchText', 'setSearchText', ''),
  withHandlers({
    onSearch: ({ [propName]: sourceElements, setElements, setSearchText }) => text => {
      setSearchText(text)
      setElements(
        isEmptyOrNull(text) ? 
          sourceElements
          : sourceElements.filter(e => filter(e, text))
      )
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { [propName]: sourceElements, onSearch, searchText } = this.props 
      if (prevProps[propName] !== sourceElements) {
        onSearch(searchText)
      }
    }
  })
)

export default withFilteredElements