import { connect } from 'react-redux'
import { compose } from 'recompose'
import { T } from 'ramda'

import { isVisible } from 'actions/commands'
import { EMPTY_ARRAY } from 'utils/object'
import { isFunction } from 'utils/functions'

import withSecurityScope from 'hocs/withSecurityScope'

export default compose(
  withSecurityScope,
  connect(
    (state, { scope, command, params, singleSelectionOnly, multipleSelectionOnly }) => {
      const labelParams = [
        ...params || EMPTY_ARRAY,
        state
      ]

      return ({
        isVisible: isVisible(command, params, state, scope, singleSelectionOnly, multipleSelectionOnly),
        isEnabled: (command.enabledSelector || T)(state),
        label: isFunction(command.label) ? command.label(...labelParams) : command.label
      })
    }
  )
)