import React, { useCallback } from 'react'
import { prepend, isNil } from 'ramda'
import { Icon } from 'antd'
import { useSetRecoilState } from 'recoil/dist'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { batch, connect } from 'react-redux'
import debounce from 'lodash.debounce'

import { noop } from 'utils/functions'

import { Creators as WalkListCreators } from 'actions/walklist'
import { Creators as UICreators } from 'actions/ui'
import { selectVisibleObject } from 'actions/nodes'
import { createGlobalSearch } from 'actions/nodes/search/searchMatchingText'

import { isGlobalSearchActive } from 'selectors/ui'
import { searchesState } from 'state/search/searches'

import LinkButton from 'components/Commons/LinkButton'
import TextSearch from 'components/TreeViewMenu/TextSearch/TextSearch'
import { searchHistoryItem } from 'model/project/searches/searches'

import SearchWalkPopover from '../SearchWalkPopover/SearchWalkPopover'

import styles from './GlobalSearchPopover.scss'

const { walkList } = WalkListCreators
const { closeGlobalSearch } = UICreators

const DROPDOWN_ID = 'globalSearchDropDown'

/**
 *
 * @param active
 * @param onClose
 * @param onObjectSelected
 * @param onWalk
 * @param matchesSize
 * @param onFound
 */
const _GlobalSearchPopover = ({ active, onClose, onObjectSelected, onWalk, matchesSize, onFound }) => (
  <SearchWalkPopover
    className={styles.GlobalSearchPopover}
    onClose={onClose}
    active={active}
    controls={
      <LinkButton
        className={styles.walkLink}
        onClick={matchesSize === 0 ? undefined : onWalk}
        disabled={matchesSize === 0}
      >walk {matchesSize ? `(${matchesSize})` : ''}
      </LinkButton>
    }
    icon={<Icon type="search" />}
    content={
      <TextSearch
        autoCompleteClassName={styles.searchBox}
        onValueChanged={onObjectSelected}
        autoFocus
        getPopupContainer={() => document.getElementById(DROPDOWN_ID)}
        onFound={onFound}
        debounceTime={1000}
      />
    }
    dropDownContent={
      <div id={DROPDOWN_ID} className={styles.dropdownInlineContainer} />
    }
  />
)

export const GlobalSearchPopover = compose(
  withState('matchesSize', 'setMatchesSize', 0),
  withHandlers({
    onFound: ({ setMatchesSize, onFound = noop }) => (matches, text) => {
      setMatchesSize(matches.length)
      onFound(matches, text)
    },
    onClose: ({ setMatchesSize, onClose = noop }) => () => {
      setMatchesSize(0)
      onClose()
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { active, matchesSize, setMatchesSize, onDeactivated = noop } = this.props
      // deactivated
      if (prevProps.active && !active) {
        if (matchesSize > 0) setMatchesSize(0)
        onDeactivated()
      }
    }
  })
)(_GlobalSearchPopover)

const SearchSavingGlobalSearchPopover = props => {
  const setSearches = useSetRecoilState(searchesState)

  const saveSearch = useCallback(debounce(text => {
    setSearches(prepend(
      searchHistoryItem(createGlobalSearch(text)),
    ))
  }, 2000), [])

  const onFound = useCallback((matches, text) => {
    props.onFound(matches, text)
    if (matches.length > 0) {
      saveSearch(text)
    }
  }, [props.onFound])
  return (
    <GlobalSearchPopover
      {...props}
      onFound={onFound}
    />
  )
}

export default compose(
  connect(state => ({
    active: isGlobalSearchActive(state)
  }), {
    walkListAction: walkList,
    deactivateSearch: closeGlobalSearch,
    selectVisibleObjectAction: selectVisibleObject,
  }),

  withState('searchText', 'setSearchText'),
  withState('matches', 'setMatches'),

  withHandlers({
    onObjectSelected: ({ selectVisibleObjectAction, deactivateSearch }) => id => {
      if (isNil(id)) { return }
      batch(() => {
        selectVisibleObjectAction(id)
        deactivateSearch()
      })
    },
    onFound: ({ setMatches, setSearchText }) => (matches, text) => {
      batch(() => {
        setMatches(matches)
        setSearchText(text)
      })
    },
    onWalk: ({ walkListAction, deactivateSearch, searchText, matches }) => () => {
      batch(() => {
        walkListAction(createGlobalSearch(searchText), matches)
        deactivateSearch()
      })
    },
    onClose: ({ active, deactivateSearch }) => () => {
      if (active) { deactivateSearch() }
    },
    onDeactivated: ({ setSearchText, setMatches }) => () => {
      batch(() => {
        setSearchText('')
        setMatches(undefined)
      })
    }
  })
)(SearchSavingGlobalSearchPopover)