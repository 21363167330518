exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__bKHDG{width:200px}.container__bKHDG .ant-slider-mark-text{font-size:.6em}.container__bKHDG .ant-slider-rail{height:8px}.container__bKHDG .ant-slider-track{height:8px}.container__bKHDG .ant-slider-handle{margin-top:-3px}.container__bKHDG .ant-slider-dot{margin-top:2px}.popoverOverlay__1wcO6 .ant-popover-arrow{background-color:#273749 !important;border-color:#273749 !important}.popoverOverlay__1wcO6 .ant-popover-inner-content{background-color:#273749;padding-left:.2rem;padding-right:.2rem;border-radius:5px;padding-top:1px !important;padding-bottom:1px !important}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider{margin-bottom:.4rem;margin-top:.2rem}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-rail{border-radius:4px;background-color:#375a81}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-rail .ant-slider-track{background-color:#ad91ff}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-track{background-color:#ad91ff}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-step{display:none}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-handle{background-color:#ad91ff;border-color:#c1b3ea}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-handle:focus{-webkit-box-shadow:0 0 0 5px rgba(198,24,255,.2);box-shadow:0 0 0 5px rgba(198,24,255,.2)}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-mark{font-family:sans-serif}.popoverOverlay__1wcO6 .ant-popover-inner-content .ant-slider .ant-slider-mark .ant-slider-mark-text{color:#d2d2d2}", ""]);

// exports
exports.locals = {
	"container": "container__bKHDG",
	"popoverOverlay": "popoverOverlay__1wcO6"
};