import React, { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { pipe, prop } from 'ramda'
import { Table } from 'antd'
import { currentDebuggingPinsAsArray } from 'selectors/debuggingData'
import classNames from 'classnames'
import { selectNode } from 'actions/nodes'
import { SimpleNodeContainer } from '../Commons/SimpleNode'
import NodePath from '../Commons/NodePath/NodePath'
import { EMPTY_ARRAY } from 'utils/object'
import PinsActions from './PinsActions'
import EnableDisableIcon from './EnableDisableIcon'

import styles from './PinsPanel.scss'
import { fullTable } from 'styles/table.scss'

const { Column } = Table

const rowSelection = (selectedPins, setSelectedPins) => ({
  onChange: (_, selectedRows) => {
    setSelectedPins(selectedRows.map(prop('nodeId')))
  },
  selectedRowKeys: selectedPins,
  getCheckboxProps: record => ({
    id: record.id,
  }),
})

const PinsPanel = () => {
  const pins = useSelector(currentDebuggingPinsAsArray)
  const dispatch = useDispatch()
  const selectNodeAction = pipe(selectNode, dispatch)
  const [selectedPins, setSelectedPins] = useState(EMPTY_ARRAY)

  return (
    <div className={styles.DebuggingTableContainer}>
      <div className={classNames(fullTable, styles.PinsTable)}>
        <Table
          rowSelection={rowSelection(selectedPins, setSelectedPins)}
          dataSource={pins}
          rowClassName={({ enabled }) => (!enabled ? styles.DisabledRow : null)}
          size="small"
          pagination={false}
          scroll={{ y: true }}
          rowKey={prop('nodeId')}
          locale={{ emptyText: 'No Pins' }}>
          <Column
            title="Node"
            className={styles.NodeColumn}
            defaultSortOrder="ascend"
            key="node"
            render={({ nodeId }) => <SimpleNodeContainer nodeId={nodeId} onClick={() => selectNodeAction(nodeId)} />}
          />
          <Column
            title="Path"
            className={styles.NodeColumn}
            key="path"
            render={({ nodeId }) => <NodePath id={nodeId} labels containers tooltip={false} includeSelf />}
          />
          <Column
            title="Enabled"
            className={styles.NodeColumn}
            key="enabled"
            render={({ enabled }) => <EnableDisableIcon isEnabled={enabled} />}
          />
        </Table>
      </div>
      <PinsActions
        selectedPins={selectedPins}
        setSelectedPins={setSelectedPins}
      />
    </div>
  )
}

export default PinsPanel