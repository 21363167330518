
import React, { useCallback, useMemo, useState } from 'react'
import debounce from 'lodash.debounce'
import classNames from 'classnames'

import { Tooltip } from 'antd'

import Rule from 'components/Language/Rules/Rule/Rule'
import styles from './NodeWithEnabledRule.scss'

const LabelContent = ({ source, maxSize, customTooltip, className, shouldShowToolTip = false }) => {
  // handling tooltip (not showing if hovering to navigate an inner content
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [hovering, setHovering] = useState(false)

  const showShouldTooltip = useMemo(() => tooltipVisible && !hovering, [hovering, tooltipVisible])

  const showTooltip = useMemo(() => debounce(() => setTooltipVisible(true), 500), [setTooltipVisible])
  const onMouseLeave = useCallback(() => {
    showTooltip.cancel()
    setTooltipVisible(false)
  }, [setTooltipVisible])

  const ruleComponent = (
    <Rule source={source} onHoveringToNavigate={setHovering} />
  )

  return (
    <div className={classNames(styles.ruleContent, className)} onMouseEnter={showTooltip} onMouseLeave={onMouseLeave}>
      {shouldShowToolTip || source.length > maxSize ?
        <Tooltip
          title={customTooltip || ruleComponent}
          visible={showShouldTooltip}
        >
          {ruleComponent}
        </Tooltip>
        : ruleComponent
      }
    </div>
  )
}

export default LabelContent