import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Spin, message } from 'antd'
import { isNil, findIndex } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import AceEditor from 'components/Commons/Editors/AceEditor/AceEditor'
import { EMPTY_STRING } from 'utils/string'
import { isBuiltin } from './ManageEngineExtensions'
import { noop } from 'utils/functions'

import styles from './ManageEngineExtensions.scss'
import CreateExtension from './CreateExtension'

const ExtensionAceEditorWithCallbacks = ({ loading, selected, setSelected, hasChanges, setHasChanges, custom, updateFileContent, readOnly, createCustomExtension, publishCustomExtension }) => {
  const editorRef = useRef()
  const [source, setSource] = useState(selected?.content || EMPTY_STRING)
  const [saving, setSaving] = useState(false)

  useEffect(() => { setSource(selected?.content || EMPTY_STRING) }, [selected])

  useEffect(() => { setHasChanges(selected && selected?.content !== source) }, [source, selected])

  const onSaveContent = useCallback(async () => {
    const editor = editorRef.current
    if (!selected) return

    const index = findIndex(elem => elem?.fileName === selected.fileName, custom)
    if (index === -1) return

    const errors = editor.getSession().getAnnotations()

    if (isNotEmpty(errors)) {
      message.error('There are some problems to fix before to save the extension content', 3)
      return
    }

    if (!hasChanges) {
      message.info('There is nothing to save', 3)
      return
    }

    setSaving(true)
    await updateFileContent(selected, source)
    message.success('Content saved !')
    setSaving(false)
  }, [
    source,
    setSource,
    selected,
    setSelected,
    hasChanges,
    setSaving
  ])

  const onPublish = useCallback(async () => {
    if (!selected) return

    const index = findIndex(elem => elem?.fileName === selected.fileName, custom)
    if (index === -1) return

    setSaving(true)
    await publishCustomExtension(selected)
    message.success('Publish job started!')
    setSaving(false)
  }, [
    selected,
    setSaving
  ])

  return (
    <div className={styles.ExtensionEditor}>
      <Spin className={styles.Spinner} spinning={loading || saving}>
        {selected?.fileName && (
          <h2>Editing <strong>{selected?.fileName}</strong></h2>
        )}
        <AceEditor
          className={styles.luaEditor}
          mode="lua"
          id={`engine_extension_editor_${selected?.fileName}`}
          value={source}
          onChange={setSource}
          enableLiveAutocompletion
          enableBasicAutocompletion
          enableSnippets
          onLoad={editor => {
            editor.setShowPrintMargin(false)
            editorRef.current = editor
          }}
          readOnly={readOnly || isNil(selected) || isBuiltin(selected)}
          commands={[
            {
              bindKey: { win: 'ctrl-s', mac: 'command-s' },
              name: 'onSave',
              exec: noop,
            }
          ]}
          height="auto"
          width="100%"
          autoComplete
        />
        <div className={styles.SaveContentButton}>
          <div className={styles.addExtensionContainer}>
            <span className={styles.CreateExtensionTitle}>Create a new lua extension</span>
            <CreateExtension loading={loading} readOnly={readOnly} createCustomExtension={createCustomExtension} />
          </div>

          <Button
            disabled={!hasChanges}
            onClick={onSaveContent}
            loading={saving}
            type="primary"
          >
            Save
          </Button>
          <Button
            disabled={hasChanges}
            onClick={onPublish}
            loading={saving}
            type="primary"
          >
            Publish
          </Button>
        </div>
      </Spin>
    </div>
  )
}

export default ExtensionAceEditorWithCallbacks
