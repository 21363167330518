import UnrealCommandDefinition from 'model/sessions/clients/unreal/UnrealCommandDefinition'
import requestSessionAction from '../requestSessionAction'

const openSequence = requestSessionAction(
  () => clip => ({
    command: UnrealCommandDefinition.OpenSequence.name,
    params: clip.id
  })
)

export default openSequence