import React from 'react'
import { compose, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { pipe, find, propEq, when, prop } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { videoUrl } from '../../selectors/project'
import { segments as segmentsSelector } from './selectors'

import { Creators as EngineActions } from '../../actions/engine'

import VideoPlayerPlaceholder from '../VideoPlayerPlaceholder/VideoPlayerPlaceholder'
import BufferingClipPlayer from './BufferingClipPlayer'

import { DEBUG } from '../../config'

import styles from './BufferingVideoPlayer.scss'


// { url, segments: [{ id, timelineItem, active: Boolean }] }
const PureBufferingVideoPlayer = ({ url, segments, onCompleted, onPaused, onPlaying, onReady }) => (
  segments.length === 0 ? 
    <VideoPlayerPlaceholder />
    : (
      <div className={classNames(styles.container, { [styles.debug]: DEBUG })}>
        {segments.map(clip => (
          <BufferingClipPlayer
            key={clip.id}

            url={url}
            clip={clip}
            onCompleted={onCompleted}
            onPaused={onPaused}
            onPlaying={onPlaying}
            onReady={onReady}
          />))}
      </div>
    )
)

export const _BufferingVideoPlayer = compose(
  withProps(({ segments }) => ({
    currentClipId: pipe(find(propEq('active', true)), when(isNotNil, prop('id')))(segments)
  })),
  withHandlers({
    onCompleted: ({ currentClipId, onClipEnded }) => id => {
      if (id === currentClipId) {
        onClipEnded(id)
      }
    },
    onPaused: ({ currentClipId, onPaused }) => id => {
      if (id === currentClipId) {
        onPaused()
      }
    },
    onPlaying: ({ currentClipId, onResuming }) => id => {
      if (id === currentClipId) {
        onResuming()
      }
    },
    // nothing (?)
    onReady: () => () => {},
  })
)(PureBufferingVideoPlayer)

// store connection separated to easily test the pure part
export default connect(state => ({
  url: videoUrl(state),
  segments: segmentsSelector(state),
}), {
  onClipEnded: EngineActions.onClipEnded,
  onPaused: EngineActions.onPause,
  onResuming: EngineActions.onResume,
})(_BufferingVideoPlayer)