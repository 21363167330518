
// workflow of the invitation
const States = {
  Starting: 'starting', // first load of the screen -> Validating
  Validating: 'validating', // goes to the server to check the token
  
  InvalidToken: 'invalid', // token validation failed (end) !
  Taking: 'taking', // token valid, taking the invitation

  Accepted: 'accepted' // invitation accepted (end)
}

// graphql types

export const InviteStatus = { Valid: 'Valid', Invalid: 'Invalid' }
export const InviteInvalidReasons = { InvalidToken: 'InvalidToken', AlreadyTaken: 'AlreadyTaken' }

export default States