import React from 'react'
import { Tooltip, Icon } from 'antd'

import { getProviderFor } from 'model/label'
import { EMPTY_OBJECT } from 'utils/object'

const NodeIcon = ({ node, withStyle = true }) => {
  const provider = getProviderFor(node.sys)
  return (
    <Tooltip title={node.sys}>
      <Icon
        type={provider.icon}
        {...(withStyle ? { style: { color: provider.color } } : EMPTY_OBJECT)}
      />
    </Tooltip>
  )
}

export default NodeIcon