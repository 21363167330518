import React from 'react'
import { trim, all, map, test, pipe, split } from 'ramda'
import { compose, withHandlers, withStateHandlers, withPropsOnChange } from 'recompose'
import { withApollo } from 'react-apollo'
import { Input, Checkbox } from 'antd'

import { OrganizationMembershipRole } from 'utils/authentication'
import CreateModal from 'components/Commons/CreateModal'
import organizationAddMembersMutation from 'api/mutations/organizationAddMembers.graphql'
import { withTargetValue, withTargetChecked } from 'utils/antd'
import HelpIcon from 'components/Commons/HelpIcon'

import styles from './InviteToOrganizationModal.scss'

const InviteToOrganizationModal = ({ resetEmails, hide, setEmails, emails, setOwners, owners, addMembers, onInvited, canSubmit }) => (
  <CreateModal title="Add members" hide={pipe(resetEmails, hide)} submit={addMembers} canSubmit={canSubmit} onCreated={onInvited}>
    {({ onCreate }) => (
      <div className={styles.form}>
        <div className={styles.emailInput}>
          <Input placeholder="Email list" value={emails} onChange={withTargetValue(setEmails)} onPressEnter={onCreate} />
          <HelpIcon className={styles.suggestionIcon} message="A comma separated list of emails" />
        </div>
        <div className={styles.checkBox}>
          <Checkbox value={owners} onChange={withTargetChecked(setOwners)} >As owners</Checkbox>
          <HelpIcon className={styles.suggestionIcon} message="Check to set all added members as organization owners" />
        </div>
      </div>
    )}
  </CreateModal>
)

export default compose(
  withApollo,
  withStateHandlers(
    { emails: '', owners: false },
    {
      resetEmails: prev => () => ({ ...prev, emails: '' }),
      setEmails: prev => emails => ({ ...prev, emails }),
      setOwners: prev => owners => ({ ...prev, owners })
    }
  ),
  withPropsOnChange(['emails'], ({ emails }) => ({
    canSubmit: validateEmailList(emails)
  })),
  withHandlers({
    addMembers: ({ organization, client, emails, owners, refreshMembers }) => async () => {
      await client.mutate({
        mutation: organizationAddMembersMutation,
        variables: { input: { organizationId: organization._id, emails: toEmailsArray(emails), ...owners && { role: OrganizationMembershipRole.OWNER } } }
      })
      // call members' refreshItems
      await refreshMembers()
    }
  })
)(InviteToOrganizationModal)

// eslint-disable-next-line no-useless-escape
const VALID_EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const toEmailsArray = pipe(split(','), map(trim))

const validateEmailList = emails => {
  if (!emails || emails === '') return false
  return all(test(VALID_EMAIL_REGEX), toEmailsArray(emails))
}