import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { model } from 'beanie-engine-api-js'

import useHover from 'hooks/useHover'
import { nodeIdFor } from 'dom/dom'

import { makeObjectFromIdSelector } from 'selectors/objects'
import { pathFromProps } from 'selectors/props'

import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'

import useIsPinned from 'hooks/beanie/useIsPinned'

import ChainRowChild from '../row/ChainRowChild'
import OtherwiseContent from './OtherwiseContent'

const { types: { relationship: { Relationship } } } = model

/**
 *
 */
const TruthTableOtherwiseRow = ({ id, node, truthTable, truthTableExecuted, truthTableEnabled, truthTableSelected, countOfColumns, trackExecutionEnabled, executionState, executionPath }) => {
  const [hoverRef, isHovered] = useHover()
  const relationship = useMemo(() => Relationship.otherwiseOf(truthTable.id), [truthTable.id])

  const [visibleDropdown, setDropdownVisibility] = useState(false)

  const pinned = useIsPinned(id)

  return node ?
    <tr id={nodeIdFor(id)} ref={hoverRef}>
      <OtherwiseContent
        node={node}
        relationship={relationship}

        truthTable={truthTable}
        truthTableExecuted={truthTableExecuted}
        truthTableSelected={truthTableSelected}
        truthTableEnabled={truthTableEnabled}
        countOfColumns={countOfColumns}

        isHovered={isHovered}
        setDropdownVisibility={setDropdownVisibility}
        visibleDropdown={visibleDropdown}

        pinned={pinned}

        trackExecutionEnabled={trackExecutionEnabled}
        executionState={executionState}
        executionPath={executionPath}
      />

      <ChainRowChild id={id} node={node} />
    </tr> :
    <tr />
}

export default compose(
  connect(() => {
    const nodeSelector = makeObjectFromIdSelector()
    return (state, props) => ({
      node: nodeSelector(state, props),
    })
  }),
  withNodeExecutionTracking(pathFromProps(['node', 'id'])),
)(TruthTableOtherwiseRow)
