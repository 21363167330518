import { isRef } from 'beanie-engine-api-js'
import { path } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'
import { derivedMatchSourceData } from './MatchSource'

/**
 * Interprets the Derived MatchSource to search over it for a specific object.
 * It traverse the list of paths until reaching the end objects and call search with them.
 * While traversing it supports:
 * - paths to arrays: they get unboxed processing each element
 * - ref values (even in arrays): it resolves those refs to objects.
 *
 * @param resolve function to resolve an object reference
 * @param searcher a function (text, ifMatchPayload) => {} to call with a text to search within
 * @param object the object that we must search over, for example a bne node or a line
 * @param source the MatchSource spec we must search over within this object. For example a spec to search in its label,
 * or to search in "notes", etc.
 */
const searchInDerivedSource = (resolve, object, searcher, source) => {
  const { name, paths } = source

  const processValue = (value, pathToValue, nextPaths) => {
    // handle resolving a ref
    const resolvedValue = isRef(value) ? resolve(value) : value

    if (nextPaths.length === 0) {
      // reached the end, execute the search here
      searcher(resolvedValue, derivedMatchSourceData(name, pathToValue))
    } else {
      // not yet, recurse then
      applyPaths(nextPaths, resolvedValue, pathToValue)
    }
  }

  /**
   * Recursive function to traverse each path updating the "currentValue" and
   * collecting the the real path in `iterationPath`.
   * At the end it calls the `searcher`
   */
  const applyPaths = ([aPath, ...nextPaths], currentValue, iterationPath) => {
    // get the value
    const newValue = path(aPath, currentValue)
    // handle array
    if (Array.isArray(newValue)) {
      newValue.forEach((v, i) => processValue(v, [...iterationPath, aPath, i], nextPaths))
    } else {
      processValue(newValue, [...iterationPath, aPath], nextPaths)
    }
  }

  // kick off
  applyPaths(paths, object, EMPTY_ARRAY)

}

export default searchInDerivedSource