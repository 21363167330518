import { NodePlaybackStatus } from 'beanie-engine-api-js'
import { Creators } from 'actions/vm'

import { endClip } from 'tts/tts'
import handlersBySys from './handlersBySys'

const { updatePlaybackStatus } = Creators

/**
 * Called by BNE to end/stop a node's playback. Callback of `BNEHostEndPresentNode`
 * @returns the return value is not used.
 */
// eslint-disable-next-line no-unused-vars
const onEndPresentNode = (dispatch, getState) => (bneNode, playbackId, speculative) => {
  if (speculative === undefined) speculative = false

  const sys = bneNode.get_sys()

  // either the name is wrong or we should move this to the clip specific handler
  endClip(playbackId)

  dispatch(updatePlaybackStatus({
    nodeId: bneNode.get_id(),
    playbackId,
    status: NodePlaybackStatus.TERMINATED
  }))

  handlersBySys[sys]?.onEndPresentNode?.(bneNode, playbackId, dispatch, getState)
}

export default onEndPresentNode