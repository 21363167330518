exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".actions__16vTu{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"actions": "actions__16vTu"
};