import React from 'react'
import { Modal as AntdModal, Button, Icon } from 'antd'
import { path, T } from 'ramda'
import classNames from 'classnames'

import styles from './ModalOpener.scss'

/**
 * Wraps antd modal to make ok/cancelButtonProps work (was not working, a bug in antd)
 */
const Modal = ({ title, titleIcon, modalProps = {}, children, onOk, onCancel = T, okText, cancelText, okType, okButtonProps, cancelButtonProps, className }) => (
  <div>
    <AntdModal
      title={titleIcon ? <div><Icon type={titleIcon} className={styles.titleIcon} />{title}</div> : title}
      visible
      onCancel={onCancel}
      {
        ...(!onOk ?
          { footer: null }
          : {
            onOk,
            footer: [<Footer key="footer" onOk={onOk} okText={okText} okButtonProps={okButtonProps} onCancel={onCancel} cancelText={cancelText} cancelButtonProps={cancelButtonProps} okType={okType} modalProps={modalProps} />]
          }
        )
      }
      {...modalProps}
      className={classNames(styles.ModalOpener, className)}
    >
      {children}
    </AntdModal>
  </div>
)

// TODO: is this bug also present in the updated version of ant that we re using now?
// I had to override the footer because there is a bug in ant Modal which makes
// it not take into account the "okButtonProps/cancelButtonProps"
const Footer = ({ onOk, okText = 'Accept', okButtonProps, cancelText = 'Cancel', cancelButtonProps, onCancel, okType = 'primary', modalProps }) => (
  <div key="footer">
    <Button
      onClick={onCancel}
      {...cancelButtonProps}
    >
      {cancelText}
    </Button>
    <Button
      type={okType}
      loading={path(['confirmLoading'], modalProps)}
      onClick={onOk}
      {...okButtonProps}
    >
      {okText}
    </Button>
  </div>
)

export default Modal