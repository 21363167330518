import React from 'react'
import IconButton from 'components/IconButton/IconButton'
import Popover from 'components/Commons/Popover'
import { Button, Slider, Icon } from 'antd'

import styles from './VolumeControl.scss'

export default class VolumeControl extends React.Component {
  shouldComponentUpdate = nextProps => this.props.value !== nextProps.value
  render() {
    const { onChange, value, asButton = false, asIcon = false, className } = this.props
    return (
      <VolumePopover overlayClassName={styles.popoverOverlay} onChange={onChange} value={value} >
        { asButton ? <Button icon="sound" className={className} />
          :
          asIcon ? <Icon type="sound" className={className} />
          : <IconButton icon="sound" className={className} tooltip="Volume" />
        }
      </VolumePopover>
    )
  }
}

class VolumePopover extends Popover {
  shouldComponentUpdate = (nextProps, nextState) => (
    this.props.value !== nextProps.value
    || this.state.visible !== nextState.visible
  )
  renderPopContent() {
    const { onChange, value } = this.props
    return (
      <div id="volumePopover" className={styles.container}>
        <Slider defaultValue={value} max={100} min={0} onAfterChange={onChange} />
      </div>
    )
  }
}