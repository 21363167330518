exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".hiddenIcon__2V87e{position:absolute;top:-1rem;left:-0.25rem}.noteIndicator__3AXXb{color:#c3d1dd;font-size:.5rem}.NoteContainer__2pQAD{position:relative}", ""]);

// exports
exports.locals = {
	"performanceNoteColor": "#d3d3d3",
	"productionNoteColor": "#9bc598",
	"hiddenIcon": "hiddenIcon__2V87e",
	"noteIndicator": "noteIndicator__3AXXb",
	"NoteContainer": "NoteContainer__2pQAD"
};