exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".fields__18SjN{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.fields__18SjN>*:not(:last-child){margin-bottom:1rem}", ""]);

// exports
exports.locals = {
	"fields": "fields__18SjN"
};