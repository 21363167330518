import React from 'react'

import styles from './ProjectAvatar.scss'

import AvatarUpload from 'components/ActorSection/AvatarUpload'
import { urlForImage } from 'model/resources'

export default ({ projectData, handleProjectAvatarImageChanged, token }) => {
  const { projectWithId: { avatarImage, name, _id, id } } = projectData
  const imageFile = avatarImage ? urlForImage(token, _id || id, avatarImage) : undefined
  return (
    <div className={styles.content}> 
      <div className={styles.avatar}>
        <AvatarUpload
          size={150}
          title={name}
          shape="square"
          imageFile={imageFile}
          uploadURL={urlForImage(token, _id)}
          onChange={handleProjectAvatarImageChanged}
        />
      </div>
      <h4 className={styles.imageTitle}>Avatar</h4>
    </div>)
}