import React from 'react'

import { toString } from 'ramda'
import { isBoolean } from 'ramda-adjunct'
import { isUUID } from 'utils/string'

import { NodeRef } from './BneNode'

import styles from './Rule.scss'

const valueToString = value => (isBoolean(value) ? toString(value) : value)

const Value = (className = styles.simpleValue) => ({ node: { value }, synthetic }) =>
  (!synthetic ? <span className={className}>{valueToString(value)}&nbsp;</span> : <span />)

const Constant = (className, constant) => ({ synthetic }) =>
  (!synthetic ? <span className={className}>{constant}&nbsp;</span> : <span />)

const StrValue = props => {
  const { node: { value } } = props
  const Comp = isUUID(value) ? NodeRef : Value(styles.string)
  return <Comp {...props} />
}

export const Bool = Value(styles.bool)
export const Str = StrValue
export const Num = Value(styles.number)
export const Var = Value(styles.variable)
export const It = Constant(styles.it, 'it')
export const Nothing = Constant(styles.it, 'nothing')
export const WildCard = Constant(styles.wildCard, '?')