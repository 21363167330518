import React from 'react'
import { Menu } from 'antd'
import {
  ADD_TEMPLATE_TIMED_CHOICE_1,
  ADD_TEMPLATE_TIMED_CHOICE_2,
  ADD_TEMPLATE_TIMED_CHOICE_3,
  ADD_TEMPLATE_TIMED_CHOICE_4,
  ADD_TEMPLATE_CONDITIONAL,
  ADD_TASK_CONTAINER,
  ADD_ACTION, ADD_ACTION2, ADD_CHOICES, ADD_CHOICES2,
  ADD_CLIP, ADD_CONDITIONAL,
  ADD_TRUTH_TABLE,
  ADD_FOLDER,
  ADD_JUMP,
  ADD_LUA,
  ADD_MARKER,
  ADD_REFERENCE,
  ADD_SEQUENCER,
  ADD_STOP,
  ADD_TIMER } from 'commands/all'
import { anyChoicesFilter } from '../../../../ContextMenu/SelectionContextMenu/InsertMenuItem'
import MenuItem from './MenuItem'
import NodeAutoCompleteSelectionAntd from './NodeAutoCompleteSelectionAntd'

import useAction from 'hooks/redux/useAction'
import { conditionalFromChoicesAction } from 'engine/actions/conditionalFromChoices'
import { truthTableFromChoicesAction } from 'engine/actions/truthTableFromChoices'


const { SubMenu } = Menu

import styles from './Menu.scss'

/**
 *
 */
// REVIEWME: I don't like spreading props but we are "forced" by antd when making our own components within ProjectLeftMenu
//  see here https://stackoverflow.com/questions/55164824/antd-submenu-typeerror-cannot-read-property-isrootmenu-of-undefined
const InsertChildSubMenu = ({ node, onCloseMenu, ...antdProps }) => {
  const createConditionalFromChoices = useAction(conditionalFromChoicesAction)
  const createTruthTableFromChoices = useAction(truthTableFromChoicesAction)

  return (
    <SubMenu
      className={styles.subMenu}
      title="Insert Child..."
      key="insertChild"
      {...antdProps}
    >
      <SubMenu
        className={styles.subMenu}
        title="Template..."
        key="insertTemplate"
      >

        {
          [
            ADD_TEMPLATE_TIMED_CHOICE_1,
            ADD_TEMPLATE_TIMED_CHOICE_2,
            ADD_TEMPLATE_TIMED_CHOICE_3,
            ADD_TEMPLATE_TIMED_CHOICE_4,
            ADD_TEMPLATE_CONDITIONAL,
          ].map(renderNodeCommandItem(node, onCloseMenu))
        }

        <NodeAutoCompleteSelectionAntd
          key="conditionalFor"
          title="Conditional For..."
          elementLabel="choices"
          filter={anyChoicesFilter}
          onEnter={createConditionalFromChoices}
          onCloseMenu={onCloseMenu}
          node={node}
        />
        <NodeAutoCompleteSelectionAntd
          key="truthTableFor"
          title="Truth table For..."
          elementLabel="choices"
          filter={anyChoicesFilter}
          onEnter={createTruthTableFromChoices}
          onCloseMenu={onCloseMenu}
          node={node}
        />
      </SubMenu>

      {[
        ADD_ACTION,
        ADD_ACTION2,
        ADD_CHOICES,
        ADD_CHOICES2,
        ADD_CLIP,
        ADD_CONDITIONAL,
        ADD_FOLDER,
        ADD_JUMP,
        ADD_LUA,
        ADD_MARKER,
        ADD_REFERENCE,
        ADD_SEQUENCER,
        ADD_STOP,
        ADD_TASK_CONTAINER,
        ADD_TIMER,
        ADD_TRUTH_TABLE,
      ].map(renderNodeCommandItem(node, onCloseMenu))}
    </SubMenu>
  )
}

const renderNodeCommandItem = (node, onCloseMenu) => (command, i) => (
  <MenuItem
    key={`${i}`}
    command={command}
    params={[node]}
    onCloseMenu={onCloseMenu}
  />
)

export default InsertChildSubMenu
