import React from 'react'

import IconButton from 'components/IconButton/IconButton'
import TableRowActions from 'components/Commons/TableRowActions'
import { UserTaskStatus } from 'model/constants'
import Popconfirm from '../Commons/Popconfirm'

const transitions = {
  [UserTaskStatus.Created]: [UserTaskStatus.InProgress, UserTaskStatus.Failed],
  [UserTaskStatus.InProgress]: [UserTaskStatus.Finished, UserTaskStatus.Failed, UserTaskStatus.TimedOut, UserTaskStatus.Cancelling, UserTaskStatus.RollingBack],
  [UserTaskStatus.Finished]: [UserTaskStatus.RollingBack],
  [UserTaskStatus.Failed]: [UserTaskStatus.RollingBack],
  [UserTaskStatus.TimedOut]: [UserTaskStatus.RollingBack],
  [UserTaskStatus.Cancelling]: [UserTaskStatus.Cancelled, UserTaskStatus.Failed],
  [UserTaskStatus.Cancelled]: [],
  [UserTaskStatus.RollingBack]: [UserTaskStatus.RolledBack, UserTaskStatus.Failed],
  [UserTaskStatus.RolledBack]: [],
}
const canChange = (task, newStatus) => transitions[task.status] && transitions[task.status].includes(newStatus)

const UserTasksRowActions = ({ userTask, deleteUserTask, cancelUserTask, rollbackUserTask, userTaskDownloadOutput }) => {
  return (
    <TableRowActions>
      <IconButton icon="delete" tooltip="Delete" tooltipPlacement="bottom" onClick={() => deleteUserTask(userTask._id)} />
      {
        canChange(userTask, UserTaskStatus.Cancelling) && (
          <Popconfirm title="Are you sure to cancel this task？" okText="Yes" cancelText="No" onConfirm={() => cancelUserTask(userTask._id)}>
            <IconButton icon="close-circle" tooltip="Cancel" tooltipPlacement="bottom" />
          </Popconfirm>
        )
      }
      {
        canChange(userTask, UserTaskStatus.RollingBack) && (
          <Popconfirm title="Are you sure to rollback this task changes？" okText="Yes" cancelText="No" onConfirm={() => rollbackUserTask(userTask._id)}>
            <IconButton icon="rollback" tooltip="Rollback" tooltipPlacement="bottom" />
          </Popconfirm>
        )
      }
      {
        userTask.hasOutput && (
          <IconButton icon="download" tooltip="Download" tooltipPlacement="bottom" onClick={() => userTaskDownloadOutput(userTask)} />
        )
      }
    </TableRowActions>
  )
}

export default UserTasksRowActions
