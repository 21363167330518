exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".projectsContainer__3sZE5{padding-top:1rem;padding-bottom:2rem;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center;-ms-flex-wrap:wrap;flex-wrap:wrap;overflow:auto}.projectsContainer__3sZE5:not(.empty__3Cb8E){-ms-flex-pack:start;justify-content:flex-start}.projectsContainer__3sZE5 .infinite-scroll-component{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:left;justify-content:left;-ms-flex-wrap:wrap;flex-wrap:wrap}", ""]);

// exports
exports.locals = {
	"projectsContainer": "projectsContainer__3sZE5",
	"empty": "empty__3Cb8E"
};