import React, { useState } from 'react'
import { Dropdown } from 'antd'
import ProjectDropdownMenu from './ProjectDropdownMenu'

const ProjectDropdown = ({ children, ...props }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Dropdown
      overlay={<ProjectDropdownMenu {...props} setVisible={setVisible} />}
      onVisibleChange={setVisible}
      visible={visible}
    >
      {children}
    </Dropdown>
  )
}

export default ProjectDropdown