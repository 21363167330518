import { propEq } from 'ramda'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import RevisionConnectionState from 'model/app/revision/RevisionConnectionState'

import connectRevision from '../../actions/project/connectRevision'
import recreateRevisionSession from '../../actions/project/recreateRevisionSession'
import sessionOnNetworkReconnected from '../../actions/project/session/sessionOnNetworkReconnected'

import { RevisionStateType } from '../../model/app/revision/RevisionState'
import { revisionState, revisionSessionConnectionState as revisionSessionConnectionStateSelector } from '../../selectors/project'
import { isReconnecting as isReconnectingSelector, isConnected as isConnectedSelector } from 'selectors/network'
import { noop } from '../../utils/functions'
import { Jobs } from 'actions/tasks'
import { allItemsWithAlternateRoutes } from 'config/menu'

const RevisionSession = ({ children }) => children || null

const requiredJobFor = location => {
  const currentItem = allItemsWithAlternateRoutes.find(item => (location?.pathname?.includes(`/${item.goTo}`)))
  return currentItem?.requiredJob || Jobs.LOAD_PROJECT
}

/**
 * Application component that acts as a context to work under a project revision.
 * It is the "controller" to keep the current revision session and its subscription.
 * Its state is in store.project[session, connectionState]
 *
 * It collaborates with *many* architectural pieces that detects connection issues and trigger
 * appropriated actions that updates teh store.project.connectionState.
 *
 * Controller responsibilities:
 *  - detects we started to load a revision and triggers "connectSession" action to really do it :P
 *  - detects we unloaded the revision (unmount) and unsubscribes
 *  - detects revision was destroyed server-side and creates a new one (recreateSession action)
 */
export default compose(
  connect(state => ({
    revisionState: revisionState(state),
    revisionSessionConnectionState: revisionSessionConnectionStateSelector(state),
    isReconnecting: isReconnectingSelector(state),
    isConnected: isConnectedSelector(state),
  }), {
    connectSession: connectRevision,
    recreateSession: recreateRevisionSession,
    onNetworkReconnected: sessionOnNetworkReconnected
  }),
  lifecycle({
    async componentDidUpdate(prevProps) {
      const { revisionState: newRevState, revisionSessionConnectionState: newConnectionState, isConnected, onConnectionFailed = noop, onConnectionSucceeded = noop, location } = this.props
      const { revisionState: prevRevState, revisionSessionConnectionState: prevConnectionState, isReconnecting: wasReconnecting } = prevProps

      // actions
      const { connectSession, recreateSession, onNetworkReconnected, moreOptions = {} } = this.props

      if (prevRevState !== newRevState) {
        // should we connect ?
        if (!isLoading(prevRevState) && isLoading(newRevState)) {
          try {
            const requiredJob = requiredJobFor(location)
            this.subscription = await connectSession(::this.onSubscriptionError, moreOptions, requiredJob, this.subscription)
            onConnectionSucceeded()
          } catch (err) {
            onConnectionFailed(err)
          }
        // should we disconnect ?
        } else if (isLoaded(prevRevState) && !isLoaded(newRevState) && this.subscription) {
          this.subscription.unsubscribe()
          this.subscription = null
        }
      }

      // did we got kicked out from revisionSession ? -> create new one, get up-to-date
      if (prevConnectionState === RevisionConnectionState.CONNECTED && newConnectionState === RevisionConnectionState.DISCONNECTED) {
        this.closeSubscriptionIfAny()
        this.subscription = await recreateSession(::this.onSubscriptionError)
      }

      // did network (ws) just reconnected ? -> get up to date
      if (isLoaded(newRevState) && wasReconnecting && isConnected) {
        onNetworkReconnected()
      }

    },
    // we must disconnect
    componentWillUnmount() {
      const { revisionState: sessionState } = this.props

      if (isLoaded(sessionState)) {
        this.closeSubscriptionIfAny()
      }
    },

    closeSubscriptionIfAny() {
      if (this.subscription) {
        this.subscription.unsubscribe()
        this.subscription = null
      }
    },

    async onSubscriptionError(err) {
      const { onCommunicationError = noop } = this.props
      onCommunicationError(err)
    }
  })
)(RevisionSession)

const isLoading = propEq('type', RevisionStateType.LOADING)
const isLoaded = propEq('type', RevisionStateType.LOADED)
