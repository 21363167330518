import React, { createContext, useMemo } from 'react'
import { useStore } from 'react-redux'

export const ExtensionsContext = createContext({
  registerExtension: () => {},
  unregisterExtension: () => {},
  getExtensionPointObserver: () => {},
})

const RevisionExtensions = ({ className, children }) => {
  const store = useStore()
  const container = store.extensionsContainer

  const extensionsContext = useMemo(() => ({
    registerExtension: ::container.registerExtension,
    unregisterExtension: ::container.unregisterExtension,
    getExtensionPointObserver: ::container.getExtensionPointObserver,
  }), [container])

  return (
    <ExtensionsContext.Provider value={extensionsContext}>
      <div className={className}>{children}</div>
    </ExtensionsContext.Provider>
  )
}

export default RevisionExtensions
