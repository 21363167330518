import { forEach } from 'ramda'
import { model, Sys } from 'beanie-engine-api-js'
import { canPasteChoicesAs } from '../../selectors/clipboard'
import createFromNodeInto from './createFromNodeInto'
import { toBNEAction } from './utils'
import { selectedObject } from 'selectors/objects'
import { nodeFromClipboard } from 'utils/clipboard'

const { types: { object: { isSomeChoices } } } = model

export const createConditionalFromChoices = choicesId => api => {
  const choicesLua = api.obj.get(choicesId)
  const conditional = api.createObject({ sys: Sys.conditional })

  conditional.set_name(choicesId)

  forEach(choiceRef => {
    const choice = api.obj.get(choiceRef)
    conditional.add_condition().set_name(choice.get_id())
  })(choicesLua.container_get_nodes().toJSONObject())

  return conditional
}

export const conditionalFromChoicesAction = toBNEAction(
  createFromNodeInto(createConditionalFromChoices),
  'Conditional from Choices'
)

// REVIEWME: this one is still coupled with inserting into a children
export const pasteAsConditionalFromChoices = afterNode => async (dispatch, getState, { synchronizer }) => {
  if (!canPasteChoicesAs(getState())) {
    return
  }
  // canPasteChoicesAs already did all the checks. This is safe
  const choices = await nodeFromClipboard(isSomeChoices)
  if (!choices) return
  synchronizer.doSynchingBNE(
    'Paste as conditional from choices',
    createFromNodeInto(createConditionalFromChoices)(choices.id, afterNode.id),
    afterNode
  )
}

export const pasteAsConditionalFromChoicesAction = () => (dispatch, getState, args) =>
  pasteAsConditionalFromChoices(selectedObject(getState()))(dispatch, getState, args)