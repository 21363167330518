import { head } from 'ramda'
import { packSelectionAsRange, selectNode } from 'actions/nodes'
import { nodeSelection } from 'selectors/selections'
import { leftEdge, selectedNodes } from 'selectors/nodeSelection'
import { objectsIndex } from 'selectors/apollo'
import { fixSelectionsWithEdges } from 'model/selection'
import { DnDTypes } from 'dnd/model-dnd'

/*
 * This gets called after canDrag() so selection is already packed
 * And as it is draggable, it means that there is a single selection (@see canDrag). At least for the moment
 * Until we support dragging for example multiple sibling choices up/down
 *
 * A couple of behaviors
 * If the node is not part of the selection, then it clears it setting a new simple selection for that node.
 * Since all the DND is based on the selection in redux.
 *
 * If it was part of the selection then it triggers packing it (in the future we would like the selection framework
 * to be packing automatically on every update, instead of doing it later here in actions :S).
 * It also "fixes" the selection ranges (@see fixSelectionsWithEdges). We would also like to handle that already in the selection
 * but we'd need to change the core model.
 *
 * Finally it always return a structure with this form
 *
 * {
 *   selection: [Simple|Range],
 *   left: Node
 * }

 */
const beginDrag = (node, relationship) => (dispatch, getState) => {
  const selectedIds = selectedNodes(getState())

  // if was in selection then drag the selection if not, then reset selection to this node
  if (selectedIds.includes(node.id)) {
    packSelectionAsRange()(dispatch, getState)
    return createDragSourceForSelection(node, relationship, getState)
  } else {
    dispatch(selectNode(node.id))
    return createDragSource(node, relationship, {
      itemType: DnDTypes.Node,
      selection: nodeSelection(getState()),
      // we might not need this ? if we have the selection
      left: node
    })
  }
}

const createDragSource = (node, relationship, result) => ({
  ...result, relationship
})

export const createDragSourceForSelection = (node, relationship, getState) => {
  const index = objectsIndex(getState())
  const selection = fixSelectionsWithEdges(nodeSelection(getState()), index)

  return createDragSource(node, relationship, {
    itemType: DnDTypes.Node,
    selection,
    // we might not need this ? if we have the selection
    left: index[leftEdge(head(selection))]
  })
}

export default beginDrag