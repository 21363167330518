import React from 'react'
import { Form, Input } from 'antd'
import { model } from 'beanie-engine-api-js'

import MarkupParametersTable from './MarkupParametersTable'

import ColorPicker from 'components/Commons/ColorPicker'

import styles from './CreateMarkupForm.scss'

const { types: { markup: { MARKUP_NAME_PATTERN } } } = model

const { Item: FormItem } = Form

const required = { required: true, message: 'required.' }

const CreateMarkupForm = ({ markup, getFieldDecorator }) => (
  <Form layout="horizontal">
    <div className={styles.nameSection}>
      <FormItem label="Name">
        { getFieldDecorator('name', {
          ...markup && { initialValue: markup.name },
          rules: [
            required,
            { pattern: MARKUP_NAME_PATTERN, message: 'No whitespaces or special characters besides _' }
          ]
        })(
          <Input />
        )}
      </FormItem>
      <FormItem label="Color">
        { getFieldDecorator('color', {
          ...markup && markup.color && { initialValue: markup.color },
        })(
          <ColorPicker />
        )}
      </FormItem>
      <FormItem label="Description">
        { getFieldDecorator('description', {
          ...markup && { initialValue: markup.description },
        })(
          <Input />
        )}
      </FormItem>
    </div>
    <FormItem label="Parameters">
      { getFieldDecorator('parameters', {
        ...markup && { initialValue: markup.parameters },
      })(
        <MarkupParametersTable />
      )}
    </FormItem>
  </Form>
)

export default CreateMarkupForm