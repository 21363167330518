import React from 'react'
import { connect } from 'react-redux'
import { prop } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { markupName, paramKey } from '../markupUtils'
import { isValid } from '../editorUtils'
import autocompletePluginCreator from './AutocompletePluginCreator'
import { markupDefinitions as definitions, markupTypes as types } from 'selectors/markups'
import { NODE_TYPES } from '../Constants'

const { types: { markup: { suggestParamValue } } } = model

const ALLOWED_NODES = [NODE_TYPES.MARK_UP_PARAMETER_VALUE, NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]

const shouldHandleNode = (editor, { type }) => ALLOWED_NODES.includes(type)

export default () => autocompletePluginCreator({
  renderPortal: (Portal, props) => {
    const { editorRef: { current } } = props
    return (
      <MarkupValues>
        {(markupDefinitions, markupTypes) => (
          <Portal
            {...props}
            suggestions={isValid(current) && shouldHandleNode(current, current.currentNode()) ?
              computeSuggestions(current, markupDefinitions, markupTypes) :
              []} />
        )}
      </MarkupValues>
    )
  },
  shouldHandleNode,
  onEnter: (suggestion, editor) => {
    editor.replaceCurrentText(suggestion)
  }
})

const _MarkupValues = ({ markupDefinitions, markupTypes, children }) => children(markupDefinitions, markupTypes)
const MarkupValues = connect(state => ({ markupDefinitions: definitions(state), markupTypes: types(state) }))(_MarkupValues)

const computeSuggestions = (editor, markupDefinitions, markupTypes) => {
  const markUpName = markupName(editor.currentMarkUp())
  const paramName = paramKey(editor.currentMarkUpParameter()).text
  const suggestedValues = suggestParamValue({ markupDefinitions, markupTypes })(markUpName, paramName)
  return suggestedValues.map(prop('value'))
}
