import React from 'react'

import { Icon, Menu } from 'antd'
import sections from './Sections'
import { map, keys } from 'ramda'

import styles from './ManagePlatform.scss'
import { compose, withState } from 'recompose'
import withUser from 'hocs/withUser'

const { Item } = Menu

const PlatformGeneralView = ({ sectionSelected, setSection }) => {
  const Comp = sections[sectionSelected].comp
  return (
    <div className={styles.generalView}>
      <h1><Icon type="setting" />Platform</h1>

      <div className={styles.content}>
        <Menu mode="inline" defaultSelectedKeys={[sectionSelected]} onClick={({ key }) => setSection(key)}>
          {map(key => (<Item key={sections[key].name} >
            <Icon type={sections[key].icon} />
            {sections[key].label}
          </Item>), keys(sections))}
        </Menu>
        <div className={styles.sections}><Comp className={styles.section} /></div>
      </div>
    </div>
  ) 
}

export default compose(
  withUser,
  withState('sectionSelected', 'setSection', sections.users.name)
)(PlatformGeneralView)