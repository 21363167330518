import { Creators } from 'actions/vm'

import { playbackQueue } from 'selectors/playback'
import { events } from 'selectors/state'
import { isPausePoint } from '../../../selectors/playback/debug'
import handlersBySys from './handlersBySys'

const { recordNodeState } = Creators

const onBeginExecuteNode = (dispatch, getState) => (bneNode, playbackId, speculative) => {
  if (speculative === undefined) speculative = false
  const sys = bneNode.get_sys()
  const state = getState()
  const stateEventsLength = events(state).length
  const playbackQueueLength = playbackQueue(state).length


  dispatch(recordNodeState(playbackId, 'atBeginNode.stateEventsLength', stateEventsLength))
  dispatch(recordNodeState(playbackId, 'atBeginNode.playbackQueueLength', playbackQueueLength))

  handlersBySys[sys]?.onBeginExecuteNode?.(bneNode, playbackId, dispatch, getState)

  const id = bneNode.get_id()
  // console.log('onBeginExecuteNode', id, sys)
  // pause-points
  if (isPausePoint(id, state)) {
    // console.log('pausing!')
    dispatch(Creators.pausePlayback())
  }
}

export default onBeginExecuteNode