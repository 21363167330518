import moment from 'moment'
import { toBNEAction } from './utils'
import { saveAsFile } from 'utils/browser'


// export & import

export const exportTo = (fileType, bneMethodName, then = saveAsFile) => project => api => {
  const string = api.obj[bneMethodName]()
  then(
    `${project.name}-export-${moment().format('YYYYMMDD_HHmmSS')}.${fileType}`,
    new Blob([string], { type: `application/${fileType}` })
  )
}

export const exportProjectToJSON = toBNEAction(exportTo('json', 'all_to_json'), 'Export Project to JSON')
