import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

import { selectNode } from 'actions/nodes'
import { executionsTree } from 'selectors/state/executions'

import ShortId from 'components/Commons/ShortId'
import { SimpleNodeContainer as SimpleNode } from 'components/Commons/SimpleNode'

import styles from './ChainExecutionsView.scss'

const { Column } = Table

const ChainExecutionsTable = ({ setSelectedChainExecution }) => {
  const roots = useSelector(executionsTree)
  const dispatch = useDispatch()
  return (
    <Table
      className={styles.ChainExecutionsTable}
      dataSource={roots}
      rowKey="id"
      pagination={false}
      scroll={{ y: true }}
      onRow={chainExecution => ({
        onClick: () => { setSelectedChainExecution(chainExecution) }
      })}
      locale={{ emptyText: 'No Chain Executions' }}
      defaultExpandAllRows
    >
      <Column
        key="expanded"
        className={styles.expandColumn}
      />
      <Column
        title="ID"
        dataIndex="id"
        key="id"
        className={styles.jobIdColumn}
        render={id => <ShortId id={id} />}
      />
      <Column
        title="Entry"
        dataIndex="entry_point"
        key="entry_point"
        className={styles.entryPointColumn}
        render={nodeId => nodeId && (
          <SimpleNode nodeId={nodeId} onClick={() => dispatch(selectNode(nodeId))} />
        )}
      />
      <Column
        title="Status"
        dataIndex="status"
        key="status"
        className={styles.statusColumn}
      />
      <Column
        title="Role"
        key="role"
        dataIndex="role"
        className={styles.roleColumn}
      />
      <Column
        title="Nodes Executions"
        className={styles.nodesColumn}
        key="node_executions"
        dataIndex="node_executions"
        render={nodeExecutions => (
          <div className={styles.ExecutingNodes}>
            {nodeExecutions.map(nodeExec => (
              nodeExec.node ? (
                <SimpleNode
                  key={nodeExec.id}
                  nodeId={nodeExec.node}
                  onClick={() => dispatch(selectNode(nodeExec.node))}
                />
              ) : null
            ))}
          </div>
        )}
      />
    </Table>
  )
}

export default ChainExecutionsTable