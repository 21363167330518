exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".matchingToken__2jEmv{font-weight:bold}", ""]);

// exports
exports.locals = {
	"matchingToken": "matchingToken__2jEmv"
};