import React from 'react'
import { compose, pure } from 'recompose'
import { connect } from 'react-redux'

import { hasPreviousLane as hasPreviousLaneSelector, hasNextLane as hasNextLaneSelector } from 'selectors/lanes'
import { selectNextLane, selectPreviousLane } from 'actions/lanes'

import NavigableTextView from './NavigableTextView'

const LaneTextView = ({ object: lane, hasPreviousLane, hasNextLane, moveRight, moveLeft }) => (
  <NavigableTextView
    hasPrevious={hasPreviousLane}
    hasNext={hasNextLane}
    moveLeft={moveLeft}
    moveRight={moveRight}
  >
    <h1>{lane.name}</h1>
  </NavigableTextView>
)

export default compose(
  connect(state => ({
    hasPreviousLane: hasPreviousLaneSelector(state),
    hasNextLane: hasNextLaneSelector(state),
  }), {
    moveRight: selectNextLane,
    moveLeft: selectPreviousLane,
  }),
  pure
)(LaneTextView)