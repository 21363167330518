import QueryType from './QueryType'

/**
 * Translates a Query object into its "text representation" (pretty print?)
 * We still don't have nor defined a proper grammar and syntax for this queries/searches
 * so this will probably change and a lot.
 * But meanwhile we use it for UI purpose, to show labels, for a search for example.
 *
 * Query -> String
 */
export const queryToText = query => {
  const mapper = mappers[query.type]
  if (!mapper) throw new Error(`Unknown query type [${query.type}] (query ${JSON.stringify(query)})`)
  return mapper(query)
}

export default queryToText

//
//
//

const compositeToText = operator => ({ queries }) =>
  queries.reduce((acc, q, i) => (i === 0 ? queryToText(q) : `${acc} ${operator} ${queryToText(q)}`), '')

const andToText = compositeToText('and')
const orToText = compositeToText('or')

const notToText = ({ query }) => `not ${queryToText(query)}`

const propEqToText = ({ name, value }) => `${name} is ${value}`
const pathEqToText = ({ path, value }) => `${path.join('.')} is ${value}`

const idToText = ({ text }) => `id startsWith ${text}`

// registry

const mappers = {
  // basic types
  [QueryType.AND]: andToText,
  [QueryType.OR]: orToText,
  [QueryType.NOT]: notToText,

  //
  [QueryType.PROP_EQ]: propEqToText,
  [QueryType.PATH_EQ]: pathEqToText,
  [QueryType.ID]: idToText,
}

