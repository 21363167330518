import { isContained, model } from 'beanie-engine-api-js'
import { allPass, anyPass, applySpec, complement, path, pipe, prop, propEq } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { ClipboardContentType } from 'model/app/clipboard/clipboard'
import { revisionId } from './project'

const { types: { object: { isSomeChoices }, node: { isStrictNode } } } = model

export const clipboard = path(['clipboard'])

const clipboardMetaComplies = clipboardMetaCondition => pipe(
  clipboard,
  allPass([
    isNotNil,
    clipboardMetaCondition
  ])
)

export const contentTypeIs = propEq('contentType')
export const contentTypeIsLane = clipboardMetaComplies(contentTypeIs(ClipboardContentType.Lane))
export const contentTypeIsNodes = clipboardMetaComplies(contentTypeIs(ClipboardContentType.Nodes))
const headNodeIsStrictNode = pipe(path(['headNode']), isStrictNode)

export const isNodePropertiesMetadata = contentTypeIs(ClipboardContentType.NodeProperties)

export const canPasteOnLane = clipboardMetaComplies(anyPass([
  contentTypeIs(ClipboardContentType.Lane),
  allPass([
    contentTypeIs(ClipboardContentType.Nodes),
    headNodeIsStrictNode
  ])
]))

export const canPasteOnTreeView = clipboardMetaComplies(
  anyPass([
    isNodePropertiesMetadata,
    allPass([
      propEq('contentType', ClipboardContentType.Nodes),
      pipe(prop('headNode'), complement(isContained))
    ])
  ])
)

const hasASingleChoiceFromSameProject = pipe(
  applySpec({ clipboard, revisionId }),
  ({ clipboard: { headNode: choices, headId, tailId }, revisionId: revId }) =>
    // TODO: probably we should allow to copy even if it is from another revision worst scenario is a node pasted with refs to unknown
    headId === tailId && isSomeChoices(choices) && propEq('project', revId, choices)
)

export const canPasteChoicesAs = allPass([
  canPasteOnTreeView,
  hasASingleChoiceFromSameProject
])
