import React from 'react'
import { compose, withProps } from 'recompose'
import { propEq } from 'ramda'
import { graphql } from 'react-apollo'

import organizationQuery from 'api/queries/organization.graphql'
import SecureScope from 'components/Commons/SecureScope'
import EntityLayout from 'components/Organizations/Commons/EntityLayout/EntityLayout'
import TeamSummary from 'components/Organizations/Teams/TeamSummary/TeamSummary'

import OrganizationPage from './OrganizationPage/OrganizationPage'
import OrganizationError from './OrganizationError'

const findComp = (sections, sectionName) => {
  const section = sections.find(propEq('name', sectionName))
  return section && section.comp
}

const SectionSwitcher = ({ organization, error, team, sections, match: { params: { organizationName, teamName, sectionName } } }) => {
  if (error) {
    return <OrganizationError />
  }
  const C = findComp(sections, sectionName) || (teamName ? TeamSummary : OrganizationPage)
  // TODO: remove duplicity in organization and organizatoinName, just pass one (same for team)
  return (
    <SecureScope scope={{ organization, team }}>
      <EntityLayout organization={organization} team={team} organizationName={organizationName} teamName={teamName} sectionName={sectionName} sections={sections}>
        <C
          organization={organization}
          organizationName={organizationName}
          team={team}
          teamName={teamName}
        />
      </EntityLayout>
    </SecureScope>
  )
}

export default compose(
  graphql(organizationQuery, {
    name: 'organization',
    options: ({ match: { params: { organizationName } } }) => ({
      variables: {
        organizationName,
      },
    }),
  }),
  withProps(({ organization }) => ({
    loading: organization.loading,
    organization: organization.organization,
    error: organization.error
  }))
)(SectionSwitcher)
