import composePlugins from '../../composePlugins'
import markupQueriesPlugin from './MarkupQueriesPlugin'
import markupListValuePlugin from './markupListValuePlugin'
import markupRichTextValuePlugin from './MarkupRichTextValuePlugin'

// TODO: move all these plugins that are outside to be here in this folder
// import markUpCreatorPlugin from '../MarkUpCreatorPlugin/plugin'
// import markUpNameSuggestionPlugin from '../MarkUpNameSuggestionPlugin'
// import markUpKeySuggestionPlugin from '../MarkUpKeySuggestionPlugin'
// import markUpValueSuggestionPlugin from '../MarkUpValueSuggestionPlugin'
// import hiddenMarkupsPlugin from '../HiddenMarkupsPlugin'

export default () => composePlugins(
  markupQueriesPlugin(),

  markupRichTextValuePlugin(),
  markupListValuePlugin(),

  // markUpNameSuggestionPlugin,
  // markUpKeySuggestionPlugin,
  // markUpValueSuggestionPlugin,
  // markUpCreatorPlugin,

  // hiddenMarkupsPlugin,
  // markUpCreatorPlugin,
)