import { HORIZONTAL_VALUES, newPosition, VERTICAL_VALUES } from '../../position'

/**
 * A computer that gives [TOP, CENTER, BOTTOM] and [LEFT, CENTER, RIGHT]
 * but that is strict to detect CENTER.
 * A coordinate to be considered as "center" must be at the exact pixel.
 * Applies to horizontal as well as vertical "center".
 * This basically generates 4 quadrants + 3 points for center (top, center, and bottom)
 *
 * This was our original impl
 */
const strictComputePosition = (mouse, rect) => newPosition(
  vertPos(mouse.y, rect),
  horizPos(mouse.x, rect)
)

export default strictComputePosition

// utils

const halfPos = (root, length) => root + (length / 2)
const position = (value, half, [onLesser, onEqual, onGreater]) => {
  if (value === half) return onEqual
  return value < half ? onLesser : onGreater
}

const vertPos = (y, { top, height }) => position(y, halfPos(top, height), VERTICAL_VALUES)
const horizPos = (x, { left, width }) => position(x, halfPos(left, width), HORIZONTAL_VALUES)