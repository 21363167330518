import React from 'react'
import { Input } from 'antd'

const SearchBox = ({ onSearch, ...others }) => (
  <div>
    <Input.Search
      placeholder="Search"
      onSearch={onSearch}
      {...others}
    />
  </div>
)

export default SearchBox