import {
  pipe,
  pickBy,
  toPairs,
  map,
  indexBy,
  prop,
  filter,
  flatten,
  reject,
  isNil,
  propOr,
  identity,
  groupBy
} from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'
import { model } from 'beanie-engine-api-js'
import { EMPTY_ARRAY, propFrom, emptyObjectToArray } from 'utils/object'
import { createSelector } from 'selectors/reselect'
import { _current } from 'selectors/state'
import { makeMemoizeSelector } from '../memoize'

const { types: { state: { isChainExecution, isNodeExecution, executions: { isExecuting } } } } = model

const EXECUTION_MANAGER = 'execution_manager'

//
// executions
//

export const chainExecutions = createSelector('chainExecutions',
  [_current],
  pipe(
    pickBy(isChainExecution),
    toPairs,
    map(([id, chainExecution]) => ({ id, ...chainExecution })),
  )
)

export const executionManager = createSelector('executionManager',
  [_current],
  prop(EXECUTION_MANAGER)
)

export const executionsTree = createSelector('executionsTree',
  [executionManager, _current],
  (manager, current) => {
    const resolve = propFrom(current)

    const processPseudoArray = (idPropName, mapper = identity) => pipe(
      emptyObjectToArray,
      map(pipe(
        id => ({
          ...resolve(id),
          [idPropName]: id
        }),
        mapper
      ))
    )

    const hydrateChainExecution = ({ children = EMPTY_ARRAY, node_executions = EMPTY_ARRAY, ...rest }) => {
      return {
        ...rest,
        children: processPseudoArray('id', hydrateChainExecution)(children),
        node_executions: processPseudoArray('id')(node_executions),
      }
    }

    return pipe(
      propOr(EMPTY_ARRAY, 'chain_executions'),
      processPseudoArray('id', hydrateChainExecution)
    )(manager)
  }
)

export const makeChainExecutionSelector = idFromProp => createSelector('chainExecutionSelector',
  [idFromProp, _current],
  prop
)

export const nodeExecutions = createSelector('nodeExecutions',
  [_current],
  pipe(
    pickBy(isNodeExecution),
    toPairs,
    map(([id, nodeExecution]) => ({ id, ...nodeExecution })),
  )
)

export const nodeExecutionsIndex = createSelector(
  [nodeExecutions],
  indexBy(prop('id'))
)

const executingChainExecutions = createSelector('executingJobs',
  [chainExecutions],
  filter(isExecuting)
)

export const isExecutingChainExecutions = createSelector('isExecutingChainExecutions',
  [executingChainExecutions],
  isNotEmpty
)

export const executingNodeIds = createSelector('executingNodeIds',
  [executingChainExecutions, nodeExecutionsIndex],
  (runningJobs, allNodeExecutions) => pipe(
    map(prop('node_executions')),
    flatten,
    map(propFrom(allNodeExecutions)),
    map(prop('node')),
    reject(isNil)
  )(runningJobs)
)

//
// issues
//

/** A list of engine catch ExecutionIssues */
export const issues = createSelector('issues',
  [executionManager],
  propOr(EMPTY_ARRAY, 'issues')
)

// this might not scale in terms of performance for large projects and large number of issues
// but there's just not an easy way to do it not even with memoize(), reselect, re-reselect, etc.
export const issuesByNode = createSelector('issuesByNode',
  [issues],
  groupBy(prop('node'))
)

// this won't memoize quite well because the previous "groupBy" makes new arrays on every computation
// at least in term of identity
export const makeIssuesForNodeSelector = nodeId => makeMemoizeSelector(
  { index: issuesByNode },
  ({ index }) => propOr(EMPTY_ARRAY, nodeId, index)
)
