exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".path__20SQs{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.path__20SQs .lane__1VevQ{font-size:.8rem;white-space:nowrap}.path__20SQs .lane__1VevQ.orphaned__3bRq0{font-style:italic;color:gray}.arrowSeparator__3RoFh{color:#d3d3d3;font-size:.8rem;margin-right:.5rem;margin-left:.5rem}.ErrorComputingPath__rS8hr .anticon{margin-right:.5rem}", ""]);

// exports
exports.locals = {
	"path": "path__20SQs",
	"lane": "lane__1VevQ",
	"orphaned": "orphaned__3bRq0",
	"arrowSeparator": "arrowSeparator__3RoFh",
	"ErrorComputingPath": "ErrorComputingPath__rS8hr"
};