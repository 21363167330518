import React, { useCallback } from 'react'
import { pathOr } from 'ramda'
import { useDispatch } from 'react-redux'
import { model } from 'beanie-engine-api-js'

import styles from '../../../SlateTextEditor.scss'
import { setDisabledSubtitles as setDisabledSubtitlesAction } from 'engine/actions/lines'
import IconButton from '../../../../Commons/IconButton'

// eslint-disable-next-line import/no-webpack-loader-syntax
import SubtitlesDisabledIcon from '-!svg-react-loader!../../../../Commons/SubtitlesIcon/subtitles-disabled.svg'
// eslint-disable-next-line import/no-webpack-loader-syntax
import SubtitlesEnabledIcon from '-!svg-react-loader!../../../../Commons/SubtitlesIcon/subtitles-enabled.svg'


const { types: { object: { Paths } } } = model


const LineSubtitlesButton = props => {
  const { line, lineId } = props

  const dispatch = useDispatch()

  const disabledSubtitles = pathOr(false, Paths.line.disabled_subtitles, line)

  const handleClick = useCallback(() => {
    dispatch(setDisabledSubtitlesAction(lineId, !disabledSubtitles))
  }, lineId, disabledSubtitles)

  return (
    <div className={styles.LineAudioMenuItem}>
      <IconButton
        component={disabledSubtitles ? SubtitlesDisabledIcon : SubtitlesEnabledIcon}
        onClick={handleClick}
      />
    </div>
  )
}

export default LineSubtitlesButton