import { createSelector } from 'reselect'
import moment from 'moment'
import { pathOr, pipe, reduce, prop, filter, indexBy, values, omit, concat, map, pathEq, append, find, isEmpty, propEq, assoc, reduceRight, prepend, findIndex, update, reject, findLast, path, cond } from 'ramda'
import { user as userSelector } from 'selectors/auth'
import { sessionId as sessionIdSelector } from 'selectors/project'
import { isFromUs, isRevert as isUndo, isReapply as isRedo, isBasicChange } from 'model/ChangeSet'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'

const EMPTY_CLEANED_STACK = {
  indexedChanges: EMPTY_OBJECT,
  undoStack: EMPTY_ARRAY,
  nextUndoableCS: undefined,
  nextRedoableCS: undefined
}

export const history = pathOr(EMPTY_ARRAY, ['project', 'history'])

const undoStackByChanges = indexedChanges => reduceRight(cond([
  // basic cs case
  [isBasicChange, (cs, stack) => prepend(cs, reject(isUndo, stack))],
  // undo cs case
  [isUndo, (cs, stack) => {
    const i = findIndex(propEq('_id', cs.reverts._id))(stack)
    return update(i, cs, stack)
  }],
  // redo cs case
  [isRedo, (cs, stack) => {
    const basicCSId = cs.reapplies._id
    const i = findIndex(pathEq(['reverts', '_id'], basicCSId))(stack)
    return update(i, indexedChanges[basicCSId], stack)
  }],
]), EMPTY_ARRAY)

export const nextUndoableBy = pipe(find(isBasicChange), prop('_id'))
export const nextRedoableBy = pipe(findLast(isUndo), path(['reverts', '_id']))

export const cleanedStack = shouldCheckChangeSet => stack => {
  const ownStack = filter(shouldCheckChangeSet)(stack)

  if (isEmpty(ownStack)) return EMPTY_CLEANED_STACK

  const indexedChanges = pipe(filter(isBasicChange),
    reduce((acc, cs) => assoc(cs._id, cs, acc), EMPTY_OBJECT))(ownStack)

  const undoStack = undoStackByChanges(indexedChanges)(ownStack)

  return {
    indexedChanges,
    undoStack,
    nextUndoableCS: prop(nextUndoableBy(undoStack), indexedChanges),
    nextRedoableCS: prop(nextRedoableBy(undoStack), indexedChanges)
  }
}

export const undoStack = createSelector(
  [history, userSelector, sessionIdSelector],
  (stack, user, sessionId) => cleanedStack(isFromUs(user, sessionId))(stack)
)

// We are not filtering by after or equal because is not possible that two changeSets conflict if have same sessionId
const changeSetIsNewer = timestamp => changeSet => moment.utc(changeSet.timestamp).isAfter(moment.utc(timestamp))

// TODO: unify this functions
export const lastRedoFromChangeSet = (state, changeSet) => find(pathEq(['reapplies', '_id'], changeSet._id))(history(state))
export const lastUndoFromChangeSet = (state, changeSet) => find(pathEq(['reverts', '_id'], changeSet._id))(history(state))

export const cleanHistory = historyStack => {
  const changeSetsIndex = indexBy(prop('_id'), historyStack)

  const reducer = (index, changeSet) => {
    const { reverts, reapplies, _id } = changeSet
    if (index[_id]) {
      // UNDO CASE
      if (reverts) return simplifyFromUndo(index, changeSet)
      // REDO CASE
      if (reapplies) return simplifyFromRedo(index, changeSet)
    }
    return index
  }

  return pipe(
    reduce(reducer, changeSetsIndex),
    values
  )(historyStack)
}

// Invariant: We assume that the changeSets are sorted by timestamp
// Filter the ChangeSets that happened after of timestamp
export const futureCleanHistoryFrom = (state, timestamp) =>
  cleanHistory(filter(changeSetIsNewer(timestamp), history(state)))

export const lastUndoableOwnChange = createSelector(
  [undoStack], prop('nextUndoableCS')
)

export const lastRedoableOwnChange = createSelector(
  [undoStack], prop('nextRedoableCS')
)

// Util functions

const changeSetsRelatedWith = (id, expect_id, obj) => values(map(c => (c._id !== expect_id &&
    (pathEq(['reverts', '_id'], id, c) || pathEq(['reapplies', '_id'], id, c)) ? c._id : undefined), obj))


// TODO: TEST ME
const simplifyFromUndo = (obj, { _id, reverts: { _id: revertedId } }) => {
  const idsToRemove = concat(
    changeSetsRelatedWith(revertedId, _id, obj),
    obj[revertedId] ? [_id, revertedId] : []
  )

  return omit(idsToRemove, obj)
}

// TODO: TEST ME
const simplifyFromRedo = (obj, { _id, reapplies: { _id: reappliedId } }) => {
  const idsToRemove = append(reappliedId, changeSetsRelatedWith(reappliedId, _id, obj))

  return omit(idsToRemove, obj)
}