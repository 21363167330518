import { SELECT_ACTION, SELECT_MULTIPLE_ACTION, CLEAR_ACTION, ADD_TO_SELECTION_ACTION, REMOVE_FROM_SELECTION_ACTION, selectAsMultiple, addToSelection } from '../actions/selection'
import { LOGOUT } from 'actions/login'
// import winston from 'winston'
import { without, uniq } from 'ramda'

//
// Kind of a selection service for global selections that needs to be shared
// between different components
//

const defaultMutator = (state, name, value) => ({
  ...state,
  [name]: value
})
const mutators = {}
export const registerMutator = (name, mutator) => {
  mutators[name] = mutator
}

export const addDependencies = (name, ...dependencies) => {
  registerMutator(name, (state, _, value) => {
    const returnObject = {
      ...state,
      [name]: value
    }

    dependencies.forEach(dependencyName => {
      delete returnObject[dependencyName]
    })

    return returnObject
  })
}

const mutatorFor = name => mutators[name] || defaultMutator

const adding = (mutator, state, name, value) => {
  const arrayValue = (Array.isArray(state[name]) && state[name]) || []

  const newValue = uniq(arrayValue.concat(value))
  return mutator(state, name, newValue)
}

const removing = (mutator, state, name, value) => {
  const arrayValue = (Array.isArray(state[name]) && state[name]) || []
  const forceArray = []
  const newValue = without(forceArray.concat(value), arrayValue)
  return mutator(state, name, newValue)
}

const initialState = { }

export function selection(state = initialState, action) {
  switch (action.type) {
    case SELECT_ACTION: {
      // avoid dup selections
      if (state[action.name] === action.value) {
        return state
      }
      return mutatorFor(action.name)(state, action.name, action.value)
    }
    case SELECT_MULTIPLE_ACTION: return action.values
      .map((value, i) => (i === 0 ? selectAsMultiple : addToSelection)(action.name)(value))
      .reduce(selection, state)
    case CLEAR_ACTION: return mutatorFor(action.name)(state, action.name, undefined)
    case ADD_TO_SELECTION_ACTION: return adding(mutatorFor(action.name), state, action.name, action.value)
    case REMOVE_FROM_SELECTION_ACTION: return removing(mutatorFor(action.name), state, action.name, action.value)
    case LOGOUT: return initialState

    default: return state
  }
}

export const on = name => ({
  set(derivedName, fn) {
    registerMutator(name, (state, _, value) => ({
      ...state,
      [name]: value,
      ...(value && {
        [derivedName]: fn(value)
      })
    }))
  },
  doAfter(fn) {
    registerMutator(name, (state, _, value) => {
      const r = defaultMutator(state, _, value)
      fn(value, state[name])
      return r
    })
  }
})
