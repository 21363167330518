import { updateQueryValue, pushTransform } from 'graphql/updates'
import branchCheckpointsQuery from 'api/queries/branchCheckpoints.graphql'

/**
 * When a checkpoint is created it updates the apollo cache
 * used by the checkpoints selector
 */
const projectCheckpointCreated = checkpoint => (dispatch, getState, { getApolloClient }) => {
  updateQueryValue(
    {
      query: branchCheckpointsQuery,
      variables: { branchId: checkpoint.sourceBranch._id }
    },
    pushTransform(['branchCheckpoints'], 'checkpoint', true)
  )(getApolloClient(), { data: { checkpoint } })
}

export default projectCheckpointCreated