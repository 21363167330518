import React from 'react'

import classNames from 'classnames'
import { EMPTY_STRING } from 'utils/string'

import Line from '../Line/Line'

import styles from '../../SlateTextEditor.scss'

const DirectorLine = ({ children, ...others }) => (
  <Line className={classNames(styles.DirectorLine, { [styles.empty]: others.node.getText() === EMPTY_STRING })} {...others}>{children}</Line>
)

export default DirectorLine