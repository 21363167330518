import React from 'react'

import styles from './ChoicesWrapper.scss'

const ChoicesWrapper = ({ children }) => (
  <ol className={styles.choices}>
    {children}
  </ol>
)

export default ChoicesWrapper