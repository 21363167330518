import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { Button, Icon, Table, Tooltip } from 'antd'
import { always, pick } from 'ramda'
import { Link } from 'react-router-dom'

import paginatedQuery from 'hocs/paginatedQuery'
import ttsJobsForProjectQuery from 'api/queries/ttsJobsForProject.graphql'
import SectionElementsTable from 'components/Organizations/Section/SectionElementsTable/SectionElementsTable'
import { revisionId as revisionIdSelector } from 'selectors/project'
import routes from 'components/Organizations/routes'
import { TtsJobStatus } from 'model/constants'
import DeleteTTSJobIcon from './DeleteTTSJobIcon'
import FilterStatusDropdown from './FilterStatusDropdown'
import { formatTimestamp, humanizedDiff } from 'utils/dates'

import styles from './TtsJobs.scss'

const { Column } = Table

const QUERY_PATH = ['revisionWithId', 'ttsJobs']

const TtsJobs = ({ ttsJobsActive, filterStatus, setFilterStatus, pageSize, onPageChanged, refreshItems, handleTableChange }) => (
  <Fragment>
    <h3>Jobs</h3>
    <div className={styles.filterToolbar}>
      <FilterStatusDropdown
        setFilterStatus={setFilterStatus}
        refreshItems={refreshItems}
        defaultValue={filterStatus}
        options={TtsJobStatus}
        allowAll
      />
      <Button type="primary" icon="search" onClick={() => refreshItems()}>Search</Button>
    </div>
    <SectionElementsTable
      data={ttsJobsActive}
      dataPath={QUERY_PATH}
      pageSize={pageSize}
      scroll={{ x: '30vw', y: '55vh' }}
      onPageChanged={onPageChanged}
      refreshItems={refreshItems}
      handleTableChange={handleTableChange}
      actions={ttsJob => ([
        <Tooltip key="details" title="Job Details">
          <Link to={routes.ttsJobFor(ttsJob)}><Icon type="search" /></Link>
        </Tooltip>,
        <DeleteTTSJobIcon key="delete" job={ttsJob} onDeleted={refreshItems} />
      ])}
    >
      <Column
        title="Job id"
        key="_id"
        dataIndex="_id"
        width="15vw"
      />
      <Column
        title="Status"
        key="status"
        sorter
        dataIndex="status"
        width="15vw"
      />
      <Column
        title="Last updated"
        sorter
        key="updatedAt"
        width="15vw"
        render={({ updatedAt }) => (<span>{formatTimestamp(updatedAt)}</span>)}
      />
      <Column
        title="Elapsed"
        width="15vw"
        render={({ createdAt, updatedAt }) => (
          <span>{humanizedDiff(updatedAt, createdAt)}</span>
        )}
    />
    </SectionElementsTable>
  </Fragment>
)

export default compose(
  connect(state => ({ revisionId: revisionIdSelector(state) })),
  withState('filterStatus', 'setFilterStatus', null),
  paginatedQuery({
    query: ttsJobsForProjectQuery,
    name: 'ttsJobsActive',
    variables: pick(['revisionId', 'filterStatus']),
    options: always({ fetchPolicy: 'cache-and-network' })
  }),
)(TtsJobs)