import { flatten, equals, reject, isNil } from 'ramda'
import { isArray } from 'utils/object'

/**
 * An object that holds a value that could be like a tree
 * of alternative or concurrent "paths" of execution.
 * The value represents all available paths.
 * Then you can update with more information on what happened
 * to a particular point in the path.
 * iex:
 *   1) You can only go to point A, That's
 *       Cache(Value(A))
 *   2) You realize that fron A you'll be able going in parallel to B and C
 *       Cache(And(B, C))
 *   3) Then from B you can go to D, so you do `cache.update('B', Value(D))` and you get
 *       Cache(And(D, C))
 *   4) Then you discover that from C you cannot go further: cache.update('C', null)
 *       Cache(Value(D))
 *   5) Now, from D you can either go to E or F: cache.update('D', Or(E, F))
 *       Cache(Or(E, F))
 *   6) Then you form F you can go to G, as both paths were mutably exclusive that means
 *     you have chosen F, so we can discard all other paths besides F, and Or gets unwrapped. cache.update('F', G)
 *       Cache(Value(G))
 *   7) Finally if you cannot go further from G, cache.update('G', null)
 *       null
 * 
 * This is an immutable object. All inner values are also immutable.
 * And they keep a strict implementation to allow reusing identity whenever possible.
 * This is important to make it performant, so that we can just compare by identity instead of a deep compare
 */
class Cache {
  constructor(element) {
    this.element = element || new Value()
  }

  update(id, value) {
    const updated = this.element.replace(id, value)

    // nullating ended up with an empty cache, return null
    if (value === null && updated === null) { return null }

    // if it doesn't change, then we didn't hit anything
    // dispose the old content and start from scratch
    return new Cache(this.element !== updated ? updated : value)
  }

  flatten() {
    return this.element.flatten()
  }

  toString() { return `Cache(${this.element})` }

}

//
// type of nodes in the cache
//

export class CacheElement {

  // handy to compose
  _compose(type, element) {
    return new type([this].concat(isArray(element) ? element : [element]))
  }
  or(element) { return this._compose(Or, element) }
  and(element) { return this._compose(And, element) }

  // main behavior
  // eslint-disable-next-line no-unused-vars
  replace(id, element) { throw new Error('Subclass responsibility') }

}

//
// Value: a tree leaf. Just has a string id. Knows how to replace itself

export class Value extends CacheElement {
  constructor(id) {
    super()
    this.type = 'Value'
    this.id = id
  }
  replace(id, element) {
    return this.id === id ? (this.equals(element) ? this : element) : this
  }

  flatten() { return [this.id] }

  equals(other) {
    return other instanceof Value && other.id === this.id
  }

  toString() { return this.id }
}

// Abstract base class between Or & And
export class CompositeElement extends CacheElement {
  constructor(elements) {
    super()
    this.elements = elements
  }
  flatten() {
    return flatten(this.elements.map(e => e.flatten()))
  }
  /* eslint prefer-template: 0 */
  toString() { return `[${this.elements.map(_ => _.toString()).join(' ' + this.symbol + ' ')}]` }
}

//
// Or: Represent alternative flows. When an element changes, then it unwrapps itself.
//     if an element gets nullated, then it will be fully nullated

const NULLATE = 'nullate'
const UNCHANGED = 'unchanged'
export class Or extends CompositeElement {
  constructor(elements) {
    super(elements)
    this.type = 'Or'
    this.symbol = '|'
  }

  replace(id, element) {
    const changed = this.elements.reduce((acc, e) => {
      const replaced = e.replace(id, element)
      return replaced === null ? NULLATE : (replaced !== e ? replaced : acc)
    }, UNCHANGED)
    return changed === NULLATE ? null : (changed === UNCHANGED ? this : changed)
  }

}

//
// And: Represents parallel flows. 
//  if it gets reduced to 1 element then it just becomes that element (no And anymore)

export class And extends CompositeElement {

  constructor(elements) {
    super(elements)
    this.type = 'And'
    this.symbol = '&'
  }
  
  replace(id, element) {
    const newElements = this.elements.map(e => e.replace(id, element))
    return this.thisOrNewElements(newElements)
  }

  // utils
  thisOrNewElements(elements) {
    return equals(elements, this.elements) ? this : createAnd(elements)
  }
}

export default Cache

// utils

export const compact = reject(isNil)

export const and = (one, other) => createAnd([one, other])

export const createAnd = elements => {
  // remove any null (element replaced by null)
  const compacted = compact(elements)
  
  if (compacted.length === 0) { return null }
  if (compacted.length === 1) { return compacted[0] }
  
  return new And(compacted)
}

export const createOr = elements => {
  const compacted = compact(elements)

  if (compacted.length === 0) { return null }
  if (compacted.length === 1) { return compacted[0] }
  
  return new Or(compacted)
}