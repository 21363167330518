import { Entity } from 'draft-js'
import { EMPTY_ARRAY } from 'utils/object'
import { anyPass, map, pipe, join } from 'ramda'

// general API helpers

export const getCurrentContent = state => state.getCurrentContent()


// doesn't contemplate non collapsed selections
export const getEntityAtSelectionAnchor = state => getBlockForSelectionAnchor(state).getEntityAt(state.getSelection().getStartOffset())

export const isRemovingPartiallyFromLeft = (selection, range) => range.start >= selection.getStartOffset() && range.end > selection.getEndOffset()
export const isRemovingPartiallyFromRight = (selection, range) => range.start < selection.getStartOffset() && range.end <= selection.getEndOffset()
export const isRemovingEntityPartially = selection => range => anyPass([isRemovingPartiallyFromLeft, isRemovingPartiallyFromRight])(selection, range)

export const isSelectionOnASingleBlock = state => state.getSelection().getAnchorKey() === state.getSelection().getFocusKey()
export const isCursorWithoutSelection = selection => selection.getAnchorKey() === selection.getFocusKey() && selection.getAnchorOffset() === selection.getFocusOffset()

export const getBlockForSelectionAnchor = state => state.getCurrentContent().getBlockForKey(state.getSelection().getAnchorKey())
export const getBlockForSelectionFocus = state => state.getCurrentContent().getBlockForKey(state.getSelection().getFocusKey())

export const getBlockContentAfterSelection = state => getBlockForSelectionAnchor(state).getText().slice(state.getSelection().getStartOffset())
export const getBlockContentBeforeSelection = state => getBlockForSelectionAnchor(state).getText().slice(0, state.getSelection().getStartOffset())

export const getBlockContentAfterDeleting = (block, selection) => (isCursorWithoutSelection(selection) ? getBlockContentDeleting : getBlockContentWithoutSelection)(block, selection)

export const getBlockContentDeleting = (block, selection) => {
  const text = block.getText()
  const offset = selection.getAnchorOffset()
  return `${text.slice(0, offset - 1)}${text.slice(offset)}`
}
export const getBlockContentWithoutSelection = (block, selection) => {
  const [from, to] = selection.isBackward ?
    [selection.getFocusOffset(), selection.getAnchorOffset()]
    : [selection.getAnchorOffset(), selection.getFocusOffset()]

  const textBefore = block.getText().slice(0, from)
  const textAfter = block.getText().slice(to)

  return `${textBefore}${textAfter}`
}

// fragments

export const getEntitiesFromFragment = fragment => (fragment || EMPTY_ARRAY).reduce((entities, block) => {
  block.getCharacterList().forEach(character => {
    const key = character.getEntity()
    if (key !== null) {
      entities[key] = Entity.get(key)
    }
  })
  return entities
}, {})

export const fragmentToText = pipe(map(block => block.getText()), join(''))

// blockMap

export const splitBlockMapAt = (blockMap, blockToSplit) => [
  blockMap.toSeq().takeUntil(v => v === blockToSplit), // before
  blockMap.toSeq().skipUntil(v => v === blockToSplit).rest() // after
]