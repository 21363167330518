import React from 'react'

import { Icon } from 'antd'
import { compose, withProps } from 'recompose'
import { connect } from 'react-redux'
import { Sys } from 'beanie-engine-api-js'
import { equals } from 'ramda'
import { getLuaCode } from 'components/TextEditorForLuaNode/TextEditorForLuaNode'

import { selectedLaneRootIds } from 'selectors/lanes'

import NavigableTextView from './NavigableTextView'

import styles from '../TextViewItem.scss'

const LuaTextView = ({ hasPrevious, hasNext, moveSelectedRoot, object }) => (
  <NavigableTextView
    className={styles.lua}
    hasNext={hasNext}
    hasPrevious={hasPrevious}
    moveLeft={() => moveSelectedRoot(-1)}
    moveRight={() => moveSelectedRoot(+1)} >
    <h1><Icon sys={Sys.lua} />{getLuaCode(object)}</h1>
  </NavigableTextView>
)

export default compose(
  connect((state, props) => ({
    roots: selectedLaneRootIds(state, props)
  })),
  withProps(({ roots, object }) => ({
    hasPrevious: object && !object.parent && roots.findIndex(equals(object.id)) > 0,
    hasNext: object && !object.parent && roots.findIndex(equals(object.id)) < roots.length - 1
  }))
)(LuaTextView)
