import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { prop } from 'ramda'

import classNames from 'classnames'
import { Table } from 'antd'
import { EMPTY_ARRAY } from 'utils/object'

import filteringTable from 'hocs/filteringTable'
import { sorterByStringProperty } from 'utils/string'

import StateVariableName from './StateVariableName'

import VariablePotentialValuesPreview from './VariablePotentialValuesPreview/VariablePotentialValuesPreview'
import VariableAssignmentsCount from './VariableAssignmentsCount'

import { fullTable } from 'styles/table.scss'
import styles from './State.scss'
import VariableValue from './VariableValue'

const { Column } = Table

/**
 * A table of variables and values.
 * Reused to show project (global) variables as well as node variables
 */
const State = ({ className, variables, onChangeVariable, dirties = {}, filteringProps, assignmentsIndex }) => (
  <div className={classNames(fullTable, styles.State, className)}>
    <Table
      dataSource={variables}
      rowKey="name"
      size="small"
      pagination={false}
      scroll={{ y: true }}
    >
      <Column
        dataIndex="name"
        key="name"
        defaultSortOrder="ascend"
        sorter={sorterByStringProperty('name')}
        className={styles.nameColumn}
        {...filteringProps()}
        render={name => <StateVariableName name={name} dirties={dirties} />}
      />
      <Column
        title="Value"
        key="value"
        className={styles.valueColumn}
        render={variable => (
          <VariableValue variable={variable} onChange={onChangeVariable} />
        )}
      />
      {assignmentsIndex &&
        <Column
          title="Assignments"
          key="assignments"
          className={styles.assignmentsColumn}
          render={variable => (
            <VariableAssignmentsCount variable={variable} assignments={assignmentsIndex[variable.name] || EMPTY_ARRAY} />
          )}
        />
      }
      {assignmentsIndex &&
        <Column
          title="Potential Values"
          key="potentialValues"
          className={styles.potentialValuesColumn}
          render={variable => (
            <VariablePotentialValuesPreview variable={variable} />
          )}
        />
      }
    </Table>
  </div>
)

export default State

export const connectWith = (variablesFn, setVariableFn, dirtiesSelector, assignments) => compose(
  connect(state => ({
    variables: variablesFn(state),
    ...(assignments && { assignmentsIndex: assignments(state) }),
    dirties: dirtiesSelector(state)
  }),
  {
    onChangeVariable: setVariableFn
  }),
  filteringTable('variables', prop('name')),
)(State)

