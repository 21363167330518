import { ifElse, path, pathEq, pipe, prop, applySpec, map, pathOr, assoc } from 'ramda'
import { DEFAULT_ACTOR, DEFAULT_LOCALE } from 'beanie-engine-api-js'
import { NODE_TYPES, OBJECT_TYPES } from '../utils/Constants'
import { isEmpty, EMPTY_ARRAY } from 'utils/object'
import { combineMetadata, metadataToNodes, SyntheticMetadataType } from './lineMetadata'
import { mapArrayFQN, fqnRef } from '../binding/fqn'

const getResourceFromLine = path(['data', 'selected_take', 'data', 'locales', DEFAULT_LOCALE])
const addMarkType = assoc('type', SyntheticMetadataType.mark)
const mergeInlines = (notes, markups) => (notes ? (markups ? notes.concat(markups) : notes) : EMPTY_ARRAY)
const nodes = (text, inlines) => (isEmpty(inlines) ? [textLineToText(text)] : textLineWithInlines(inlines, text))

const textLineWithInlines = (inlines, text) => metadataToNodes(combineMetadata(inlines, text))

const textLineToText = text => ({
  object: OBJECT_TYPES.text,
  marks: EMPTY_ARRAY,
  text
})

const textLineToTextBlock = (text, notes, markups) => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.TEXT_PART,
  data: {},
  nodes: nodes(text, mergeInlines(notes, markups))
})

const actorToTextBlock = actor => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.ACTOR_PART,
  data: {},
  nodes: [
    {
      object: OBJECT_TYPES.text,
      text: actor.data.actorName,
      marks: EMPTY_ARRAY
    }
  ]
})

const directorLine = ({ line_id, disabled, res_id, text, notes, markups }) => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.DIRECTOR_LINE,
  data: { line_id, disabled, fqn: fqnRef(res_id) },
  nodes: nodes(text, mergeInlines(notes, markups))
})

const dialogueLine = ({ line_id, disabled, res_id, text, notes, markups, actor }) => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.DIALOGUE_LINE,
  data: { line_id, disabled, fqn: fqnRef(res_id) },
  nodes: [
    actorToTextBlock(actor),
    textLineToTextBlock(text, notes, markups)
  ]
})

const actorOfLine = path(['data', 'actor'])

const dataObj = {
  line_id: prop('id'),
  disabled: prop('disabled'),
  res_id: pipe(getResourceFromLine, prop('id')),
  text: pipe(getResourceFromLine, path(['data', 'text'])),
  notes: pipe(getResourceFromLine, pathOr(EMPTY_ARRAY, ['data', 'notes']), mapArrayFQN(['data', 'notes'])),
  markups: pipe(getResourceFromLine, pipe(pathOr(EMPTY_ARRAY, ['data', 'markups']), map(addMarkType), mapArrayFQN(['data', 'markups'])))
}

export const directorLineToBlock = pipe(applySpec(dataObj), directorLine)
export const dialogueLineToBlock = pipe(applySpec({ ...dataObj, actor: actorOfLine }), dialogueLine)

const isDirLine = pathEq(['data', 'actor', 'data', 'actorName'], DEFAULT_ACTOR)
export const lineToText = ifElse(isDirLine, directorLineToBlock, dialogueLineToBlock)

