import React from 'react'

import connectState from '../../../hocs/connectState'

import { projectTitle as projectTitleSelector } from '../selectors/project'

import styles from './Header.scss'

const Header = ({ projectTitle }) => (
  <div className={styles.header}>
    <img className={styles.logo} alt="logo" src="/images/logo-horiz-white.png" />
    <div className={styles.title}><h1>{projectTitle || 'Loading...'}</h1></div>
  </div>
)

export default connectState({
  projectTitle: projectTitleSelector
})(Header)