import React from 'react'
import { Icon } from 'antd'
import { compose, withState, withHandlers } from 'recompose'
import PopoverSoundPlayer from 'components/SoundPlayer/PopoverSoundPlayer'
import StringValue from './StringValue'

const AudioValue = ({ localValue, value, onChanged, ...props }) => {

  // TODO: we should NOT enable the user to modify the audio path from here, we should provide new features
  // like media or resources management screens where the user is able to check what resources are in use and
  // interact with them. But that should be a different view, also I think it could be better that the user won't
  // modify resources manually but just upload new resources so that the platform makes what make sense with them.
  return (value && value.length > 0 ?
    (
      <span>
        <StringValue
          value={localValue || value}
          onValueChanged={onChanged}
          {...props}
        />
        &nbsp;
        <PopoverSoundPlayer audioFile={localValue || value}>
          <a role="link" tabIndex={0}><Icon type="sound" /></a>
        </PopoverSoundPlayer>
      </span>
    )
    : <span />
  )
}

// REVIEWME: I 've tried with hooks but storybook fails when I use them
// I suppose we should upgrade storybook or add a hooks addon for storybook
// but that's a whole different chapter of this storybook.
export default compose(
  withState('localValue', 'setLocalValue'),
  withHandlers({
    onChanged: ({ onValueChanged, setLocalValue }) => e => {
      const { value: newValue } = e.target
      onValueChanged(e)
      setLocalValue(newValue)
    }
  })
)(AudioValue)
