import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { always, cond, equals } from 'ramda'
import { Icon, Tooltip } from 'antd'
import { model } from 'beanie-engine-api-js'
import classNames from 'classnames'

import { isInitial } from 'selectors/playback'
import filteringTable from '../../hocs/filteringTable'

import DeleteButton from '../Commons/DeleteButton'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import { fullTable } from 'styles/table.scss'
import ExtensionPoint from '../ExtensionPoints/ExtensionPoint'
import FactValue from '../FactDrawer/FactValue'
import FactType from '../FactDrawer/FactType'
import EditFactButton from './EditFactButton'
import HierarchicalStateFacts from './HierarchicalStateFacts'
import ListStateFacts from './ListStateFacts'

import styles from './StateFacts.scss'

const { types: { object: { getName }, fact: { getError, getExpressionAST, getExpressionSource } } } = model

export const FactsViewType = {
  LIST: 'list',
  HIERARCHICAL: 'hierarchical',
}

/**
 * Table showing all the facts and their current values (when playing)
 * Kind of a "watch expressions" panel in IDE debuggers
 */
const StateFacts = props => {
  const { facts, viewType, onEditFact, onDeleteFact } = props
  const isPlaying = !useSelector(isInitial)

  //
  // reused code between view impls as rendering functions
  //

  const renderFactName = fact => {
    const error = getError(fact)
    return (
      <div className={classNames(styles.nameContent, { [styles.withStaticError]: !!error })}>
        {error && (
          <Tooltip mouseEnterDelay={0.5} title={error}>
            <Icon type="warning" />
          </Tooltip>
        )}
        <Tooltip mouseEnterDelay={1.2} title={getExpressionSource(fact)}>
          {getName(fact)}
        </Tooltip>
      </div>
    )
  }

  const renderFactValue = fact => (
    isPlaying ? <FactValue expression={getExpressionAST(fact)} /> : null
  )

  const renderFactType = fact => (
    <FactType expression={getExpressionAST(fact)} />
  )

  const renderFactActions = fact => (
    <div>
      <EditFactButton fact={fact} onEditFact={updated => onEditFact(fact, updated)} />

      <ExtensionPoint
        point={EXTENSION_POINTS.FACT_ACTIONS}
        useFragmentContainer
        params={[fact.id]}
      />

      <DeleteButton onDelete={() => onDeleteFact(fact)} tooltip="Delete fact" />
    </div>
  )

  const renderFooter = () => <div>{facts ? facts.length : 0} facts</div>

  //
  // resolve which component to use according to the selected view
  //

  const Component = useMemo(() => cond([
    [equals(FactsViewType.HIERARCHICAL), always(HierarchicalStateFacts)],
    [equals(FactsViewType.LIST), always(ListStateFacts)]
  ])(viewType), [viewType])

  return (
    <div className={classNames(fullTable, styles.FactsTable)}>
      <Component
        {...props}

        renderFactName={renderFactName}
        renderFactType={renderFactType}
        renderFactValue={renderFactValue}
        renderFactActions={renderFactActions}
        renderFooter={renderFooter}
      />
    </div>
  )
}

export default filteringTable('facts', getName)(StateFacts)