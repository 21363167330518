import React from 'react'
import { Alert } from 'antd'
import { applySpec, pathOr, pipe, propEq, find, prop, when, path } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withProps } from 'recompose'
import { connect } from 'react-redux'

import { VideoSources } from 'model/constants'

import withProject from 'hocs/withProject'
import { revisionId as revisionIdSelector } from 'selectors/project'

import projectVideoQuery from 'api/queries/projectVideoQuery.graphql'
import projectVideoUpdateSourceMutation from 'api/mutations/projectVideoUpdateSource.graphql'

import StringValue from 'components/PropertiesEditor/Value/StringValue'

import styles from './Sources.scss'

const Sources = ({ youtubeUrl, handleYouTubeChanged, error }) => (
  <React.Fragment>
    <h3>Sources</h3>
    <div className={styles.source}>
      <div>YouTube:</div>
      {error && <Alert type="error" message={error} />}
      <div>
        <StringValue
          isEditable
          showAcceptCancelIcons={false}
          value={youtubeUrl}
          onValueChanged={handleYouTubeChanged}
        />
      </div>
    </div>
  </React.Fragment>
)

export default compose(
  withProject,
  connect(applySpec({ revisionId: revisionIdSelector })),
  graphql(projectVideoQuery, {
    name: 'videoQuery',
    options: ({ revisionId }) => ({
      variables: { revisionId }
    })
  }),
  graphql(projectVideoUpdateSourceMutation, { name: 'updateVideoMutation' }),
  withProps(applySpec({
    youtubeUrl: pipe(
      pathOr([], ['videoQuery', 'revisionWithId', 'video', 'sources']),
      find(propEq('name', VideoSources.YouTube)),
      when(isNotNil, prop('url'))
    ),
    error: path(['video', 'error', 'message'])
  })),
  withHandlers({
    handleYouTubeChanged: ({ revisionId, updateVideoMutation }) => async value => {
      await updateVideoMutation({
        variables: {
          input: {
            revisionId,
            source: {
              name: VideoSources.YouTube,
              url: value
            }
          }
        }, 
      })
    },
  })
)(Sources)