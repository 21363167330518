import { useMemo } from 'react'
import { useSelector } from 'react-redux'

/**
 * Use a selector factory/maker that is usually dependent on props.
 * This correctly handles memoization of the args/props to create a new selector every time.
 * This is a shortcut hook to avoid boilerplate that arises while migrating "connect" functions
 * for memoizing selectors that depend on props.
 *
 * ie:
 *
 *   connect(() => {
 *     const mySelector = selectorMaker(props => props.something)
 *     return (state, props) => ({
 *       value: mySelector(state, props)
 *     })
 *   }
 *
 * Now becomes something like
 *
 *   const value = useSelectorMaker(selectorMaker, [props.something])
 *
 * While without this hook it would be
 *
 *   const selector = useMemo(() => selectorMaker(props.something), [props.something])
 *   const value = useSelector(selector)
 *
 * This is the recommended way to work with react-redux hooks since useSelector doesn't support props
 *
 * @param selectorMaker a function that can receive args and returns a new selector
 * @param args an array of arguments to be used to call selectorMaker
 */
const useSelectorMaker = (selectorMaker, args) => {
  const selector = useMemo(() => selectorMaker(...args), args)
  return useSelector(selector)
}

export default useSelectorMaker