import React from 'react'

const Counter = ({ className, current, total }) => (
  <div className={className}>
    <span>{current + 1}</span>
    <span>&#47;</span>
    <span>{total}</span>
  </div>
)

export default Counter