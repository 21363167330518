import { prop, path } from 'ramda'
import { mapIndexed } from 'ramda-adjunct'

//
// an object model for FQNs.
// An FQN is like a ramda path or lens that allows to uniquely identify an object
// We store FQNs in slate nodes to bind them to the model objects.
//
// Then with the #resolve() function you can get the real object for that FQN
// providing a resolver function (for example resolving refs from redux store)
//

export const FQNType = {
  ref: 'ref',
  prop: 'prop',
  path: 'path',
  index: 'index'
}

const fqnElement = (type, propName) => value => ({ type, [propName || type]: value })
export const fqnRef = fqnElement(FQNType.ref, 'id')
export const fqnProp = fqnElement(FQNType.prop, 'name')
export const fqnPath = fqnElement(FQNType.path, 'path')
export const fqnIndex = fqnElement(FQNType.index, 'i')

export const mapArrayFQN = _path => mapIndexed((o, i) => ({ ...o, fqn: [fqnPath(_path), fqnIndex(i)] }))

export const resolve = resolver => fqn => doResolve(resolver, fqn)

const doResolve = (resolver, fqn) => fqn.reduce((obj, element) => {
  switch (element.type) {
    case FQNType.ref: return resolver(element.id)
    case FQNType.prop: return prop(element.name, obj)
    case FQNType.path: return path(element.path, obj)
    case FQNType.index: return obj[element.i]

    default: throw new Error(`Unknown fqn element type '${element}' resolving FQN ${fqn}`)
  }
}, null)
