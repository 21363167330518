import React from 'react'

import { T, head } from 'ramda'
import { Table, message } from 'antd'
import renderExtension from './Extension'
import { NativeTypes } from 'react-dnd-html5-backend'
import classNames from 'classnames'
import withCustomDrop from 'components/TreeView/withCustomDrop'
import DeleteButton from 'components/Commons/DeleteButton'
import CreateExtension from './CreateExtension'
import { LUA_FILE_EXTENSION } from './ManageEngineExtensionsView'
import parser from 'luaparse'

import styles from './ManageEngineExtensions.scss'

const ManageExtensionTables = ({
  selected,
  custom,
  setSelected,
  hasChanges,
  loadExtensionContent,
  setVisibleList,
  readOnly,
  isOver,
  loading,
  updateFileName,
  createCustomExtension,
  deleteCustomExtension,
  connectDropTarget
}) => connectDropTarget(
  <div className={styles.manageEngineExtensionsModaFiles}>
    <div className={styles.tables}>
      <Table
        loading={loading}
        dataSource={custom}
        className={classNames(styles.table, {
          [styles.dropOver]: isOver
        })}
        size="small"
        rowKey="fileName"
        pagination={({ pageSize: 5 })}
        rowClassName={elem => classNames(styles.row, {
          [styles.rowSelected]: selected && selected.fileName === elem.fileName
        })}
        columns={[
          {
          title: <div className={styles.columnName}>Project extensions</div>,
          key: 'fileName',
          render: renderExtension({
            setSelected,
            loadExtensionContent,
            setVisibleList,
            editable: !readOnly,
            onValueChanged: updateFileName,
            hasChanges
          })
        },
        {
          render: elem => (
            <DeleteButton disabled={readOnly} onDelete={() => deleteCustomExtension(elem)} tooltip="Remove extension" tooltipPlacement="rightTop" />
          )
        }
      ]}
      />
    </div>
    <div className={styles.addExtensionContainer}>
      <span className={styles.CreateExtensionTitle}>Create a new lua extension</span>
      <CreateExtension loading={loading} readOnly={readOnly} createCustomExtension={createCustomExtension} />
    </div>
  </div>
)

export default withCustomDrop({
  dropTypes: [NativeTypes.FILE],
  target: {
    drop: ({ createCustomExtension }, monitor) => {
      const { files } = monitor.getItem()
      const file = head(files)
      const type = head(file?.type.split('/'))
      if (file && type !== 'audio' && type !== 'image') {
        const [name, fileExtension] = file.name.split('.')

        if (fileExtension !== LUA_FILE_EXTENSION) {
          message.error('Unsupported file, only .lua', 3)
          return
        }

        file.text().then(content => {
          try {
            parser.parse(content)
          } catch (e) {
            message.error('There are some problems on the content to fix before to create the extension', 3)
            return
          }

          createCustomExtension(name, content)
        })
      }
    },
    canDrop: T
  }
})(ManageExtensionTables)
