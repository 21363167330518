exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".organizationsContainer__jW49v{padding-top:1rem;padding-bottom:2rem;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:center;justify-content:center;overflow:auto}.organizationsContainer__jW49v:not(.empty__1xPBh){-ms-flex-pack:start;justify-content:flex-start}", ""]);

// exports
exports.locals = {
	"organizationsContainer": "organizationsContainer__jW49v",
	"empty": "empty__1xPBh"
};