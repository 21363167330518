import React from 'react'
import { properties } from 'utils/object'
import { model } from 'beanie-engine-api-js'
import { isNilOrEmpty } from '../../../utils/ramda'

import styles from '../Change.scss'

const { types: { metadata: { isTimestampProp } } } = model

const filterProps = ['__typename', 'project', 'id', 'sys']

/**
 *
 */
const AddedChange = ({ change, ...props }) => (
  <div>{renderProperties(change.added, props)}</div>
)

const renderProperties = ({ data, ...rest }, { showEmpties, showTimestamps }) => (
  <div>
    <div className={styles.sys}>{rest.sys}</div>
    <table className={styles.properties}>
      <tbody>
        {properties(
          { ...rest, ...data }
        ).filter(({ name }) => !filterProps.includes(name))
          .filter(({ value }) => showEmpties || !isNilOrEmpty(value))
          .filter(({ name }) => showTimestamps || !isTimestampProp(name))
          .map(({ name, value }) => (
            <tr key={name}>
              <td>{name}</td>
              <td>{JSON.stringify(value)}</td>
            </tr>
          ))
        }
      </tbody>
    </table>
  </div>
)

export default AddedChange