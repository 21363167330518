exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".usage__yFqqo{font-style:italic;color:rgba(168,160,160,.5)}", ""]);

// exports
exports.locals = {
	"usage": "usage__yFqqo"
};