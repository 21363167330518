import OptionsSection from './Sections/OptionsSection'
import AccessSection from './Sections/AccessSection'
import TtsSection from './Sections/TtsSection/TtsSection'
import VideoSection from './Sections/VideoSection/VideoSection'
import RuntimeSection from './Sections/Runtime/RuntimeSection'

export default {
  Options: { name: 'options', label: 'Options', comp: OptionsSection },
  Access: { name: 'access', label: 'Access', comp: AccessSection },
  Tts: { name: 'tts', label: 'Text-to-Speech', comp: TtsSection },
  Video: { name: 'video', label: 'Video', comp: VideoSection },
  Runtime: { name: 'runtime', label: 'Runtime', comp: RuntimeSection },
}
