import { objectsIndex } from 'selectors/apollo'
import { propFrom } from 'utils/object'
import connectState from './connectState'

const modelSelector = (state, { editor, node }) => {
  const index = objectsIndex(state)
  return editor.model(node, propFrom(index))
}

const withModel = (name = 'model') => connectState({
  [name]: modelSelector
})

export default withModel