import React from 'react'
import ReactDOM from 'react-dom'
import { isNil } from 'ramda'
import { Icon, Tooltip, Spin, message } from 'antd'
import classNames from 'classnames'

import { noop } from 'utils/functions'
import { Key } from 'utils/keyboard'

import IconButton from 'components/IconButton/IconButton'

export const IconDisposition = {
  Before: 'before',
  After: 'after'
}

import styles from '../PropertiesEditor.scss'

export default class EditableValue extends React.Component {

  initialState() { return {} }

  saveOnEnter = true

  state = {
    isEditing: !!this.props.isEditing,
    applyingChange: false,
    value: this.props.value,
    changed: false,
    ...this.initialState()
  }

  componentDidMount() {
    this.mounted = true
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, isEditing } = this.state
    // value updated from outside, give feedback
    if (value !== nextProps.value) {
      this.setState({ value: nextProps.value, changed: this.mounted, isEditing: false })
      // this.changedTimer = setTimeout(() => {
      if (this.mounted) {
        this.setState({ changed: false })
      }
      // }, 1000)
    }
    if (isEditing !== nextProps.isEditing) {
      this.setState({ isEditing: nextProps.isEditing })
    }
  }

  /* eslint react/no-find-dom-node: 0 */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditing && !prevState.isEditing) {
      this.focus()
      this.select()
    }
  }

  /* eslint react/no-find-dom-node: 0 */
  getControl() {
    return ReactDOM.findDOMNode(this).querySelector('input')
  }

  componentWillUnmount() {
    this.mounted = false
    if (this.changedTimer) {
      clearTimeout(this.changedTimer)
    }
  }

  handleEdit = () => {
    this.setState({ isEditing: true })
  }

  handleKeyDown = e => {
    const { saveOnEnter } = this.props
    if (!this.state.isEditing) {
      return
    }
    switch (e.keyCode) {
      case Key.ESC: this.cancelEdit(); break;
      case Key.ENTER: if (this.saveOnEnter || saveOnEnter) { this.handleSave(); break }
      default:
    }
  }
  cancelEdit = () => {
    const { onCancel, value } = this.props
    this.setState({ isEditing: false, value, changed: false })
    if (onCancel) { this.props.onCancel() }
  }

  handleSave = () => {
    if (this.mounted) {
      this.handleSaveWithValue()
    }
  }

  handleSaveWithValue = value => {
    const { onValueChanged, value: oldValue, validateFn } = this.props

    const newValue = value || this.state.value

    if (onValueChanged && newValue !== oldValue) {
      if (validateFn !== undefined) {
        const validation = validateFn(newValue, oldValue)
        if (!validation.isOk) {
          message.error(validation.message || 'Validation error.')
          this.cancelEdit()
          return
        }
      }
      this.setState({ isEditing: false, applyingChange: true })
      Promise.resolve(onValueChanged(newValue, oldValue))
        .then(() => {
          if (this.mounted) {
            this.setState({ applyingChange: false, changed: true })
          }
        })
        .catch(err => {
          /* eslint no-console: 0 */
          // TODO: handle error (rollback ? show feedback)
          message.error(`Error editing value: ${err}`)
          if (this.mounted) {
            this.setState({ applyingChange: false })
          }
        })
    } else {
      this.cancelEdit()
    }
  }

  getIcon(isEditable) {
    if (this.props.getIcon) return this.props.getIcon()

    if (isEditable) {
      return <IconButton tooltip={this.props.tooltipText || 'Edit'} icon="edit" onClick={this.handleEdit} />
    } else {
      return (
        <Tooltip title="Non modifiable">
          <Icon type="lock" />
        </Tooltip>
      )
    }
  }

  renderForEditing() {
    return <div>To be Overrided by subclass</div>
  }

  renderForDisplaying() {
    const { value } = this.state
    const { placeholder = '' } = this.props
    const valueToShow = (isNil(value) ? placeholder : value).toString()
    return (
      <span onDoubleClick={this.isEditEnabled() ? this.handleEdit : noop} onClick={this.props.onDisplayClick}>
        {this.transformValueBeforeDisplaying(valueToShow)}
      </span>
    )
  }

  /**
   * Receives the value before displaying and modifies it add some ad-hoc rendering/formatting.
   *
   * To be redifined in concrete classes - this default impl returns identity.
   * @param {*} value
   *
   * @returns the transformed value
   */
  transformValueBeforeDisplaying(value) {
    return `${value}`
  }

  isEditEnabled() {
    const { isEditable } = this.props
    return isEditable && !this.state.applyingChange
  }

  updateHighlightFadeIfNecessary = () => {
    const { changed } = this.state
    if (changed && this.changedDiv) {
      const timer = setTimeout(() => {
        if (this.changedDiv) this.changedDiv.classList.remove(styles.changed)
        clearTimeout(timer)
      }, 600)
    }
  }

  /* eslint jsx-a11y/no-static-element-interactions:0 */
  render() {
    const { isEditing, changed, applyingChange } = this.state
    const { isEditable, showAcceptCancelIcons = true, iconDisposition = IconDisposition.Before } = this.props
    this.updateHighlightFadeIfNecessary()
    return (
      <Spin spinning={applyingChange}>
        {(isEditable && isEditing && !applyingChange)
          ? (
            <div className={styles.editing} onKeyDown={this.handleKeyDown}>
              { showAcceptCancelIcons && [
                <IconButton key="save" tooltip="Save (enter)" icon="check" onClick={this.handleSave} />,
                <IconButton key="cancel" tooltip="Cancel (esc)" icon="close" onClick={this.cancelEdit} />
              ]}
              <div className={styles.EditableValueControl}>
                {this.renderForEditing()}
              </div>
            </div>
          )
          : (
            <div
              ref={e => { this.changedDiv = e }}
              className={classNames(styles.editableValue, styles.showing, {
                [styles.changed]: changed,
                [styles.dispositionBefore]: iconDisposition === IconDisposition.Before,
                [styles.dispositionAfter]: iconDisposition === IconDisposition.After,
              })}
              >
              {iconDisposition === IconDisposition.Before && this.getIcon(isEditable) }

              {this.renderForDisplaying()}

              {iconDisposition === IconDisposition.After && this.getIcon(isEditable) }

            </div>
          )
        }
      </Spin>
    )
  }

  focus() { this.withControl(c => c.focus()) }
  select() { this.withControl(c => c.select()) }

  withControl(fn) {
    if (this.state.isEditing) {
      const c = this.getControl()
      if (c) fn(c)
    }
  }

}

