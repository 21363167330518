import { model, ref } from 'beanie-engine-api-js'
import { isNotNil } from 'ramda-adjunct'
import { Creators, NodeState } from 'actions/vm'
import { EMPTY_ARRAY } from 'utils/object'
import { emptyObjectToArray } from '../../../../utils/object'

const { types: { choices: { StateFields } } } = model

const { recordNodeState } = Creators

/**
 * Playback event handler for choices
 */
const choicesHandler = {

  /**
   * We get the enabled choices from LUA and store them locally in our history.
   * Actually in the frontend "state" for this playbackId (executing_node).
   * Since this information might change dynamically while running we need to get a snapshot of it.
   */
  onBeginPresentNode: (bneNode, playbackId, dispatch) => {
    const enabledValue = bneNode.get_enabled_choices()
    dispatch(recordNodeState(playbackId, NodeState.choices.enabledChoices, enabledValue ? enabledValue.toJSONObject() : EMPTY_ARRAY))
  },

  /**
   * Records the selected choice from beanie state.
   * We do this to keep our history right in case choices expired and the engine
   * auto-decided the choice.
   */
  onEndExecuteNode: (bneNode, playbackId, dispatch) => {
    // get node state "last_selected"
    const lastSelectedNodeId = bneNode.get_state(StateFields.LAST_SELECTED_CHOICE_NODE_ID)

    if (isNotNil(lastSelectedNodeId)) {
      // compute its index :S
      const lastSelectedIndex = emptyObjectToArray(
        bneNode.get_enabled_choices().toJSONObject()
      ).indexOf(ref(lastSelectedNodeId))

      // this fixes a problem in the engine state. Where `last_selected...` gets some presentation status value
      // instead of the index. Ie. "choices_time_out" or "presenting-interruptible" etc.
      if (lastSelectedIndex >= 0) {
        // store it in NodePlayback (history)
        dispatch(recordNodeState(playbackId, NodeState.choices.selectedChoice, lastSelectedIndex))
      }
    }
  }

}

export default choicesHandler