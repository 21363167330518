import { equals } from 'ramda'
import React, { useCallback } from 'react'
import { Select, Icon } from 'antd'

/* eslint import/no-webpack-loader-syntax: 0 */
import WalkIcon from '-!svg-react-loader!components/WalkList/WalkListPopover/icons/walk.svg'
import { noop } from 'utils/functions'
import IconButton from 'components/Commons/IconButton'
import RelativeTime from 'components/Commons/RelativeTime'
import { title } from '../../../model/project/searches/searches'

import styles from './SearchSelector.scss'

const { Option } = Select

const SearchSelector = ({ onSearchSelected, searches, currentSearch, walkingSearch, onDeleteSearch = noop }) => {
  const onChange = useCallback((timestamp, component) => {
    // !component === preseed clear
    onSearchSelected(component ? component.props.search : undefined)
  }, [])

  const _onDeleteSearch = (e, search) => {
    e.preventDefault()
    e.stopPropagation()
    onDeleteSearch(search)
  }

  return (
    <Select
      className={styles.Select}
      allowClear
      placeholder="Last Searches"
      onChange={onChange}
      defaultActiveFirstOption={false}
      value={currentSearch?.timestamp.format()}
      suffixIcon={
        <span className={styles.inputRightPart}>
          {searches.length}
          <Icon type="down" className="suffix" />
        </span>
      }
    >
      {searches.map(search => (
        <Option
          key={search.timestamp?.format()}
          value={search.timestamp?.format()}
          className={styles.option}
          search={search}
        >
          <div className={styles.leftDecorations}>
            {equals(search.search, walkingSearch) && <Icon component={WalkIcon} />}
          </div>

          <div className={styles.title}>{title(search.search)}</div>

          <div className={styles.rightDecorations}>
            <span><RelativeTime date={search.timestamp} /></span>
            <IconButton type="close" onClick={e => _onDeleteSearch(e, search)} />
          </div>
        </Option>
      ))}
    </Select>
  )
}


export default SearchSelector