import { NODE_TYPES, OBJECT_TYPES } from '../../Constants'
import { directorLineNormalize } from '../normalizers/directorLine'

export default {
  nodes: [
    {
      match: [{ object: OBJECT_TYPES.text }, 
        { type: NODE_TYPES.PRODUCTION_NOTE },
        { type: NODE_TYPES.MARK_UP }
      ]
    }
  ],
  normalize: directorLineNormalize
}