import React from 'react'
import { prop } from 'ramda'
import { Table, Spin, Icon, Tooltip } from 'antd'
import { EMPTY_ARRAY } from 'utils/object'

import InvitesRowActions from './InvitesRowActions'
import RelativeTime from 'components/Commons/RelativeTime'
import Date from 'components/Commons/Date'
// TODO: rename it is now generic
import UsersPageHeader from '../UsersPageHeader'
import UserAvatar from 'components/RevisionSessions/UserAvatar'

import CreateInvite from './CreateInvite'

import styles from './InvitesPanel.scss'
import OrganizationAvatar from '../../Organizations/Commons/OrganizationAvatar';

const { Column } = Table

const InvitesPanel = ({ invitesData, onPageChanged, onSearch, refreshItems, deleteInvite, resendInvite, pageSize, handleTableChange }) => (
  <div>
    <UsersPageHeader onSearch={onSearch}>
      <CreateInvite onCreated={refreshItems} />
    </UsersPageHeader>
    <Spin spinning={invitesData.loading}>
      <Table
        rowKey={prop('created_at')}
        dataSource={invitesData.loading ? EMPTY_ARRAY : invitesData.invites.list}
        className={styles.invitesTable}
        pagination={({
          pageSize,
          onChange: onPageChanged,
          total: invitesData.loading ? 0 : invitesData.invites.paging.nrOfItems
        })}
        onChange={handleTableChange}
      >
        <Column
          title="Email"
          key="email"
          dataIndex="email"
          sorter
          className={styles.emailColumn}
        />
        <Column
          title="Created At"
          sorter
          key="created_at"
          field="created_at"
          className={styles.createdAtColumn}
          render={invite => (<RelativeTime date={invite.created_at} />)}
        />
        <Column
          title="Invited by"
          key="invitedBy"
          dataIndex="invitedBy"
          className={styles.invitedByColumn}
          render={inviter => (
            // this has issues because the acronym doesn't get calculated based on the list of users
            <UserAvatar user={inviter} contextMenuEnabled={false} />
          )}
        />
        <Column
          title="To Org"
          key="organization"
          dataIndex="organization"
          sorter
          className={styles.toOrganizationColumn}
          render={org => (
            org && <OrganizationAvatar organization={org} size="small" disabled />
          )}
        />
        <Column
          title="Accepted?"
          key="accepted_at"
          dataIndex="accepted_at"
          sorter
          className={styles.acceptedColumn}
          render={accepted_at => (accepted_at ? <Tooltip title={<Date d={accepted_at} />}><Icon type="check" /></Tooltip> : null)}
        />
        <Column
          title="Actions"
          key="actions"
          className={styles.actionsColumn}
          render={invite => <InvitesRowActions invite={invite} resendInvite={resendInvite} deleteInvite={deleteInvite} />}
        />
      </Table>
    </Spin>
  </div>
)

export default InvitesPanel