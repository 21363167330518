import { filter, groupBy, isEmpty, pathEq, pipe, prop } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

import { ResultType } from '../../../../../model/project/searches/searches'
import { lineIdFromNode } from '../../../utils/changes/lineChange'
import createWholeLineHighlightDecoration from './createWholeLineHighlightDecoration'
import matchResultDecorationsFor from './matchResultDecorationsFor'

/**
 * Given Slate Editor, a Slate Node (for the line) and beanie Search result
 * it creates slate decorations over the line to show the matching search results (highlight)
 */
const searchMatchesDecorations = (node, editor, searchMatch) => {
  const lineId = lineIdFromNode(node)

  const resultsForThisLine = pipe(
    filter(pathEq(['object', 'id'], lineId)),
    groupBy(prop('type')),
  )(searchMatch.results)

  let allDecorations = []

  // matches results
  const matchResults = resultsForThisLine[ResultType.match] || EMPTY_ARRAY
  if (!isEmpty(matchResults)) {
    const decorations = matchResultDecorationsFor(editor, matchResults, node, lineId)
    allDecorations = isEmpty(decorations) ? allDecorations : allDecorations.concat(decorations)
  }

  // whole object results
  const wholeLineResults = resultsForThisLine[ResultType.object] || EMPTY_ARRAY
  if (!isEmpty(wholeLineResults)) {
    // we just consider one as enough (how to show multiples ?)
    allDecorations = allDecorations.concat(createWholeLineHighlightDecoration(node, editor, lineId))
  }
  return allDecorations
}

export default searchMatchesDecorations