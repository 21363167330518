import React from 'react'
import { Skeleton, Card } from 'antd'

import OrganizationAvatar from 'components/Organizations/Commons/OrganizationAvatar'
import OrganizationOptions from './OrganizationOptions'
import Counter from './Counter'

import styles from './OrganizationCard.scss'

const OrganizationCard = ({ refresh, organization, selectOrganization, selectSettings }) => {
  const { counters } = organization
  return (
    <Card
      className={styles.organizationCard} 
      title={
        <div className={styles.title}> 
          <OrganizationName selectOrganization={selectOrganization} organization={organization} />
          <OrganizationOptions refresh={refresh} selectSettings={selectSettings} organization={organization} />
        </div>
      }
      actions={[
        <div className={styles.counters}>
          <Counter icon="book" value={(counters && counters.projects) || '-'} tooltip="Projects" />
          <Counter icon="team" value={(counters && counters.teams) || '-'} tooltip="Teams" />
          <Counter icon="user" value={(counters && counters.members) || '-'} tooltip="Members" />
        </div>
      ]}
      >
      <div className={styles.image} onClick={() => selectOrganization(organization)}>
        <OrganizationAvatar withoutTitle organization={organization} />
      </div>
    </Card>
  ) 
}

const OrganizationName = ({ organization, selectOrganization }) => (
  <div className={styles.organizationNameContainer}>
    <span
      className={styles.organizationName}
      onClick={() => selectOrganization(organization)}>{organization.name}
    </span>
  </div>
)

export default OrganizationCard


export const SkeletonCard = () => <Skeleton className={styles.skeleton} loading avatar active shape="square" />