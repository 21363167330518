import React from 'react'
import { useDispatch } from 'react-redux'
import { copySomethingToClipboard } from 'actions/clipboard'

import IconButton from 'components/Commons/IconButton'

const CopyResourceIdButton = ({ resourceId }) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      type="copy"
      onClick={() => dispatch(copySomethingToClipboard(resourceId))}
    />
  )
}

export default CopyResourceIdButton