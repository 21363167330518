import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

export default new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'Change',
          possibleTypes: [
            { name: 'Updated' }, { name: 'Added' }, { name: 'Deleted' }
          ]
        }
      ]
    }
  }
})