import ApolloClient from 'apollo-client'
import cache from 'providers/Cache'
import createHttpLink from 'graphql/links/queries-and-mutations/HttpLink'

export default function createApolloClient() {
  const client = new ApolloClient({
    link: createHttpLink(),
    cache
  })
  return client
}

