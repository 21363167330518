exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".focus__25Ckj{z-index:1;position:absolute;bottom:.6rem;font-size:2em;color:gray;background:-webkit-radial-gradient(white, transparent);background:-o-radial-gradient(white, transparent);background:radial-gradient(white, transparent);opacity:.5;left:15px}.focus__25Ckj>a{color:gray}.focus__25Ckj:hover{opacity:1}", ""]);

// exports
exports.locals = {
	"focus": "focus__25Ckj"
};