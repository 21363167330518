import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { denormalizing } from 'hocs/denormalizing'
import classNames from 'classnames'
import { path } from 'ramda'

import { onlyUpdateForPaths } from 'utils/shouldUpdate'
import { actorAndResources as actorAndResourcesSelector, getCurrentLineIndex } from 'selectors/clip'
import { defaultActor as defaultActorSelector } from 'selectors/objects'
import { isPlaying as isPlayingSelector, createIsPlayingNodeSelector } from 'selectors/playback'
import { propFromProps } from 'selectors/props'

import styles from './ClipPlayback.scss'
import { DEFAULT_LOCALE } from 'beanie-engine-api-js'
import ClipPlaybackItem from './ClipPlaybackItem'

const _ClipPlayback = ({ playingObject, defaultActor, isPlayingNode, node }) => {
  const lineIndex = getCurrentLineIndex(playingObject, path(['state', 'lineId'], node))
  const lines = actorAndResourcesSelector(playingObject, DEFAULT_LOCALE, 0, lineIndex + 1)

  const isDefaultActor = actor => defaultActor && actor.id === defaultActor.id

  return (
    <ol className={classNames(styles.dialogue, { [styles.active]: isPlayingNode })} >
      { // eslint-disable-next-line no-unused-vars
      lines.map(([actor, langResource, _, isDisabledLine], i) =>
        (!isDisabledLine ? (
          <ClipPlaybackItem
            key={langResource.id}
            actor={actor}
            langResource={langResource}
            defaultActor={isDefaultActor(actor)}
            isPlaying={isPlayingNode && i === lineIndex}
            previousActor={i > 0 ? lines[i - 1][0] : undefined}
            nextActor={i + 1 < lines.length ? lines[i + 1][0] : undefined}
          />
        ) : null)
      )}
    </ol>
  )
}

export const ClipPlayback = compose(
  lifecycle({
    componentDidMount() { this.componentDidUpdate({}, {}) },
    componentDidUpdate() { this.props.onContentAdded() }
  }),
  onlyUpdateForPaths(['node', 'node.state.lineId', 'playingObject', 'isPlayingNode'])
)(_ClipPlayback)

// connected
export default compose(
  connect(() => {
    const isPlayingNodeSelector = createIsPlayingNodeSelector(propFromProps('node'))
    return (state, props) => ({
      isPlaying: isPlayingSelector(state),
      isPlayingNode: isPlayingNodeSelector(state, props),
      defaultActor: defaultActorSelector(state),
    })
  }),
  denormalizing({
    prop: 'playingObject',
    recurse: true
  })
)(ClipPlayback)