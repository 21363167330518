import { reduce } from 'ramda'
import { resolveP } from 'ramda-adjunct'
import { timeout } from 'promise-timeout'

export const createPromise = cb => makeQuerablePromise(new Promise(cb))

export const createResolveblePromise = () => {
  let resolve;
  let reject;
  return {
    promise: createPromise((_resolve, _reject) => { resolve = _resolve; reject = _reject }),
    resolve,
    reject,
  }
}

export const makeQuerablePromise = (promise = Promise.resolve()) => {
  // already queryable
  if (promise.isResolved) return promise

  let isResolved = false
  let isRejected = false

  const result = promise.then(
    v => { isResolved = true; return v },
    e => { isRejected = true; throw e }
  );

  result.isFulfilled = () => isResolved || isRejected
  result.isResolved = () => isResolved
  result.isRejected = () => isRejected
  return result
}

// backward compat. removed custom code, using a library now (promise-timeout)
export const promiseTimeout = (ms, promise) => timeout(promise, ms)

export const sequence = list => list.reduce((soFar, func) =>
  soFar.then(result => {
    const pOrValue = func()
    const next = e => { if (e) result.push(e); return result }
    return pOrValue && pOrValue.then ? pOrValue.then(next) : next(pOrValue)
  }
  ), Promise.resolve([])
);

export const delay = (ms, fn) => new Promise((resolve, reject) => {
  const id = setTimeout(() => {
    clearTimeout(id)
    fn(resolve, reject)
  }, ms)
})

export const sleep = ms => delay(ms, resolve => resolve())

export const delayedExpect = (ms, fn) => delay(ms, (resolve, reject) => {
  try {
    fn()
    resolve()
  } catch (err) {
    reject(err)
  }
})

export const toPromise = o => (o.then ? o : resolve(o))

export const promiseObject = async (o, valueFn, filterKey = () => true) => {
  const result = {}

  await Promise.all(
    Object.keys(o)
      .filter(filterKey)
      .map(key => toPromise(valueFn(o[key])).then(value => { result[key] = value }))
  )

  return result
}

export const resolve = resolveP

/**
 * Given an array of objects performs a reduction to a single promise that
 * sequentially process each object that passes the "cond"ition.
 *
 * Sample
 *
 *   await reducePromises(isGreaterThan18, sendABeer, users)
 *
 * @param {*} cond (E => boolean) to filter elements that needs to be processed
 * @param {*} promisify (E => Promise(E)) processes the element creating a promise
 */
export const reducePromises = (cond, promisify, initial) => reduce(
  (p, e) => (cond(e) ? p.then(() => promisify(e)) : p),
  resolve(initial)
)
