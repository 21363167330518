import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Shortcuts } from 'react-shortcuts'

import handleShortcut from 'actions/keyboard'
import useSecurityScope from 'hooks/security/useSecurityScope'

import styles from '../components/EditProjectSection/EditProjectSection.scss'

export default ({ namespace, ...options }) => C => {
  const WithShortcuts = props => {
    const scope = useSecurityScope()
    const dispatch = useDispatch()

    const onHotKey = useCallback((action, event) => {
      dispatch(handleShortcut(scope, action, event))
    }, [dispatch, scope])

    return (
      <Shortcuts
        className={styles.editProjSectionRoute}
        name={namespace}
        handler={onHotKey}
        preventDefault={false}
        stopPropagation={false}
        global
        {...options}
      >
        <C {...props} />
      </Shortcuts>)
  }
  return WithShortcuts
}
