import memoize from 'memoize-state'
import { mapObjIndexed, includes, reject } from 'ramda'

// TODO: Warning! this is a proof of concept, this will be refined on https://github.com/BrunerHouse/beanie-studio-fronatend/pull/1491
export const makeMemoizeSelector = (selectorsObject = {}, functionToMemoize) => {
  const mSelector = memoize(functionToMemoize)
  const selector = (state, props) => mSelector({
    state,
    props,
    ...mapObjIndexed(sel => sel(state, props), selectorsObject)
  })

  selector.memoizingSelector = mSelector
  return selector
}

export const cleanPaths = paths => reject(path => includes('@@functional/placeholder', path), paths)
