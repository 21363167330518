import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import engine from './engine'
import apolloFactory from './apolloFactory'
import * as reducers from './reducers'

const createReducer = () => combineReducers(reducers)

const createEnhancer = (client) => compose(
  applyMiddleware(
    thunk.withExtraArgument({ client, engine }),
    // graphqlMiddleware
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({
    name: 'BNE-VIDEO-PLAYER',
  }) : f => f
)

const storeCreator = () => {
  const apolloClient = apolloFactory()
  
  const store = createStore(createReducer(), createEnhancer(apolloClient))
  store.apolloClient = apolloClient // hack
  return store
}

export default storeCreator