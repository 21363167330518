import { path } from 'ramda'
import { Sys, isContainer, parseRef, model } from 'beanie-engine-api-js'

import { lanesFromProject } from 'selectors/objects'
import { objectsIndex } from 'selectors/apollo'

const { types: { object: { Paths }, node: { getChildId }, container: { getContainerContentsIds }, clip: { getLinesIds } } } = model

/**
 * A visitor that iterates the graph lane by lane, and chain by chain.
 * Another type of visitor could visit the list of nodes in random order for example.
 */
const graphOrderedVisitor = (getState, collect) => {
  const state = getState()
  const index = objectsIndex(state)

  const resolve = id => index[id]
  const getLanes = () => lanesFromProject(state)

  getLanes().forEach(lane => {
    lane.roots.forEach(rootId => {
      visit(rootId, resolve, collect)
    })
  })
}

/**
 * Does the actual visiting of nodes traversing its content
 * This logic is decoupled from the actual query or rule to evaluate, that's
 * `collect` is responsible for that..
 * This just knows how to traverse objects.`
 */
export const visit = (id, resolve, collect, container) => {
  const obj = resolve(id)
  if (!obj) {
    // eslint-disable-next-line no-console
    console.error('Reference to non-existent object', id)
    return
  }
  collect(obj, container)

  // aggregated elements first (lines for a clip)
  if (obj.sys === Sys.clip) {
    getLinesIds(obj).forEach(lineId => visit(lineId, resolve, collect, obj))
  }

  // chain  deep first
  const childId = getChildId(obj)
  if (childId) {
    visit(childId, resolve, collect)
  }

  // TODO: this should be done generic using the schema !
  // choices2
  doVisitOne(Paths.choices2.idle, obj, resolve, collect)
  // truth table's otherwise
  doVisitOne(Paths.truth_table.otherwise, obj, resolve, collect)

  // sub-chains
  if (isContainer(obj)) {
    getContainerContentsIds(obj).forEach(e => {
      visit(e, resolve, collect, obj)
    })
  }
}

const doVisitOne = (aPath, obj, resolve, collect) => {
  const ref = path(aPath, obj)
  if (ref) {
    visit(parseRef(ref), resolve, collect)
  }
}

export default graphOrderedVisitor
