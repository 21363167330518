import React from 'react'

import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import { logout as logoutAction } from 'actions/login'
import { token as tokenSelector, user as currentUserSelector } from 'selectors/auth'

import { Icon, Dropdown } from 'antd'
import { userImageGetURL } from 'model/resources'
import Avatar from '../ActorSection/Avatar'
import CurrentUserDropDownMenu from './CurrentUserDropDownMenu'
import styles from './CurrentUserInfo.scss'


const menu = (user, currentUser, push, logout, goToPlatformView) => {
  return (
    <CurrentUserDropDownMenu
      user={user}
      isUs={currentUser._id === user._id}
      push={push}
      logout={logout}
      goToPlatformView={goToPlatformView}
    />
  )
}

const CurrentUserInfo = ({ push, user, logout, token, currentUser, goToPlatformView }) => {
  const imageFile = user.profileImage ? userImageGetURL(user._id || user.id, user.profileImage, token) : undefined
  return (
    <Dropdown className={styles.userMenuDropdown} overlay={menu(user, currentUser, push, logout, goToPlatformView)} trigger={['click']}>
      <div className={styles.dropdownContent}>
        <Avatar style={styles.avatar} imageFile={imageFile} />
        <Icon type="caret-down" />
      </div>
    </Dropdown>
  )
}

export default compose(
  connect(state => ({
    token: tokenSelector(state),
    currentUser: currentUserSelector(state)
  }), { logout: logoutAction }),
  withHandlers({
    goToPlatformView: ({ push }) => () => { push('/platform') }
  })
)(CurrentUserInfo)
