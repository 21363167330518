import React from 'react'
import { message } from 'antd'
import SessionRequestModal from '../../../components/SessionRequestModal/SessionRequestModal'
import { getCommandHandler } from './StudioFrontendCommands'

// handlers y command

const defaultHandler = request => {
  // This is temporary
  message.info(<SessionRequestModal request={request} />, 0)
}

/**
 * Session request received. We try to resolve an implementation
 * and execute it.
 * Uses definitions from StudioFrontEndCommands
 */
const onReceiveRequestEvent = request => (dispatch, getState, context) => {
  const handler = getCommandHandler(request)
  return (handler || defaultHandler)(request, dispatch, getState, context)
}

export default onReceiveRequestEvent