import { equals, prepend } from 'ramda'
import React, { useCallback } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil/dist'
import { compose } from 'recompose'
import { connect, useStore } from 'react-redux'
import { Icon } from 'antd'

import { setCurrentTab } from 'actions/view'
import { Creators } from 'actions/walklist'
import { Creators as UICreators } from 'actions/ui'

import { title, currentIndex, length, active as activeSelector } from 'selectors/walklist'

/* eslint import/no-webpack-loader-syntax: 0 */
import WalkIcon from '-!svg-react-loader!./icons/walk.svg'

import IconButton from 'components/Commons/IconButton'
import { minimizeSecondaryPane } from '../../../actions/view'
import { searchHistoryItem } from '../../../model/project/searches/searches'
import Panes, { Disposition } from '../../../preferences/Panes'
import { currentWalkListSearch } from '../../../selectors/walklist'
import { currentSearchState, searchesState } from '../../../state/search/searches'
import { EMPTY_ARRAY } from 'utils/object'
import { batched } from 'utils/redux'

import SearchTitle from './SearchTitle'
import Counter from './Counter'

import SearchWalkPopover from './SearchWalkPopover/SearchWalkPopover'

import styles from './WalkListPopover.scss'

const { close: closeAction, next: nextAction, previous: previousAction } = Creators
const { openGlobalSearch } = UICreators

export const WalkListPopover = ({ onNext, onPrevious, onClose, searchTitle, active, current, total, onViewSearch, onTitleDoubleClick }) => (
  <SearchWalkPopover
    icon={<Icon component={WalkIcon} />}
    active={active}
    onClose={onClose}
    className={styles.WalkListPopover}
    content={
      <React.Fragment>
        <SearchTitle title={searchTitle} className={styles.title} onClose={onClose} onTitleDoubleClick={onTitleDoubleClick} />
    
        <Counter className={styles.counter} current={current} total={total} />

        <IconButton
          type="unordered-list"
          tooltip="View results in search panel"
          className={styles.openSearchTable}
          onClick={onViewSearch}
        />
        
        <div className={styles.divider} />
      </React.Fragment>
    }
    controls={
      <React.Fragment>
        <IconButton type="down" onClick={onNext} className={styles.button} disabled={current === total - 1} />
        <IconButton type="up" onClick={onPrevious} className={styles.button} disabled={current === 0} />
      </React.Fragment>
    }
  />
)

const _WalkListPopover = props => {
  const [searches, setSearches] = useRecoilState(searchesState)
  const setCurrentSearch = useSetRecoilState(currentSearchState)
  const store = useStore()

  const onViewSearch = () => {
    const walkingSearch = currentWalkListSearch(store.getState())
    let found = searches.find(s => equals(s.search, walkingSearch))
    // create it if it is not there already
    if (!found) {
      // TODO: the title !
      found = searchHistoryItem(walkingSearch)
      setSearches(prepend(found, searches))
    }
    setCurrentSearch(found)

    store.dispatch(setCurrentTab(Disposition.horizontal, Panes.horizontal.Search.id))
    store.dispatch(minimizeSecondaryPane(Disposition.horizontal, false))
  }

  const onTitleDoubleClick = useCallback(batched(() => {
    store.dispatch(closeAction())
    store.dispatch(openGlobalSearch())
  }), EMPTY_ARRAY)

  return (
    <WalkListPopover
      {...props}
      onViewSearch={onViewSearch}
      onTitleDoubleClick={onTitleDoubleClick}
    />
  )
}

export default compose(
  connect(state => ({
    active: activeSelector(state),
    searchTitle: title(state),
    current: currentIndex(state),
    total: length(state)
  }), {
    onClose: closeAction,
    onNext: batched(nextAction),
    onPrevious: batched(previousAction),
  }),
)(_WalkListPopover)