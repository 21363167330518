import React from 'react'
import ReactDOM from 'react-dom'
import { DragLayer } from 'react-dnd'
import { compose, onlyUpdateForKeys } from 'recompose'
import { Sys } from 'beanie-engine-api-js'

import { DnDTypes } from 'dnd/model-dnd'

import { DRAG_PARENT_ID } from '../../../../TreeView'
import DraggingRow from './DraggingRow'

import styles from '../../TruthTable.scss'

/**
 * TODO: unify all this 3 layers that are almost the same
 */
const TruthTableRowDrawLayer = ({ truthTable, row, rowIndex }) => {
  if (!row) return null

  return ReactDOM.createPortal(
    <div style={{ position: 'relative' }}>
      <table className={styles.truthTableDrawLayer}>
        <DraggingRow truthTable={truthTable} row={row} rowIndex={rowIndex} />
      </table>
    </div>,
    document.getElementById(DRAG_PARENT_ID)
  )
}

export default compose(
  DragLayer(monitor => {
    const item = monitor.getItem()
    if (!monitor.isDragging() || !item) return {}
    const { itemType, left, relationship } = item
    const row = itemType === DnDTypes.Node && left && left.sys === Sys.truth_table_row ? left : null
    return ({
      row,
      rowIndex: row && relationship.index,
    })
  }),
  onlyUpdateForKeys(['row', 'rowIndex', 'truthTable']),
)(TruthTableRowDrawLayer)