import {
  allPass,
  assocPath,
  cond,
  flip,
  gte,
  identity, keys,
  length,
  path,
  pathOr,
  pipe,
  propEq,
  T,
  when
} from 'ramda'
import { isNotNil, pathNotEq } from 'ramda-adjunct'
import { PROJECT_FETCHED } from '../../actions/project'
import { STORE_REDUX_DEV_TOOLS } from '../../model/features'
import { EMPTY_ARRAY } from '../../utils/object'

export const MAX_NR_OBJECTS_TO_SHOW = 200
const STORE_BNEOBJECTS_PATH = ['project', 'revision', 'objects']
const FETCH_PROJECT_BNEOBJECTS_PATH = ['revision', 'objects']
export const OMITTED_MESSAGE = '<< OMITTED FOR PERF REASONS (disable this in reduxDevTools.js) >>'

const reduxDevToolsEnhancer = (trimBneObjects = true) => (
  window && STORE_REDUX_DEV_TOOLS && window.__REDUX_DEVTOOLS_EXTENSION__ ?
    window.__REDUX_DEVTOOLS_EXTENSION__(
      trimBneObjects ? { actionSanitizer, stateSanitizer } : {}
    )
    : identity
)

//
// reducing big actions (from the plugin UI of course, not the real objects)
//

// utils

const trimBigArray = (aPath, limit) => object => {
  const array = pathOr(EMPTY_ARRAY, aPath, object)
  return array.length > limit ?
    assocPath(aPath, OMITTED_MESSAGE, object)
    : object
}
const isType = propEq('type')

const actionSanitizer = cond([
  [isType(PROJECT_FETCHED), trimBigArray(FETCH_PROJECT_BNEOBJECTS_PATH, MAX_NR_OBJECTS_TO_SHOW)],
  [T, identity]
])

//
// reducing big state
//

// cannot use ramda, it blows up if a field is explicitly = undefined
const _hasPath = p => pathNotEq(p, undefined)

const mustTrimObjects = allPass([
  isNotNil,
  _hasPath(STORE_BNEOBJECTS_PATH),
  // objects are indexed in the store (no array)
  pipe(path(STORE_BNEOBJECTS_PATH), keys, length, flip(gte)(MAX_NR_OBJECTS_TO_SHOW))
])

const trimBNEObjects = when(mustTrimObjects, assocPath(STORE_BNEOBJECTS_PATH, OMITTED_MESSAGE))

export /* to test */ const stateSanitizer = trimBNEObjects

//
export default reduxDevToolsEnhancer
