import React from 'react'
import { Menu, Icon, message } from 'antd'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { push } from 'connected-react-router'
import classNames from 'classnames'

import sentry from 'services/sentry'
import { truncate } from 'utils/string'
import { Themes } from 'utils/antd'
import { input, parseGraphQLErrors } from 'utils/graphql'
import { isUndoRedoEnabled } from 'model/features'

import { downloadVideo as downloadVideoAction, downloadSubtitle as downloadSubtitleAction } from 'actions/project'
import { exportProjectToJSON } from 'engine/actions/project'
import { purgeInaccessible as purgeInaccessibleAction } from 'engine/actions/objects'

import { undo as undoAction } from 'actions/edit/undo'
import { redo as redoAction } from 'actions/edit/redo'
import { resetEditLayout as resetEditLayoutAction } from 'actions/view'
import { Creators as UiCreators } from 'actions/ui'
import resyncSessionsAction from 'actions/project/resyncSessions'

import { revisionId as revisionIdSelector, currentIsBranch as currentIsBranchSelector } from 'selectors/project'
import { lastUndoableOwnChange, lastRedoableOwnChange } from 'selectors/undo'
import { projectAdminAccess, isCurrentRevisionWritable } from 'security/project'

import withUser from 'hocs/withUser'
import secure from 'hocs/secure'

import videoJobCreateMutation from 'api/mutations/videoJobCreate.graphql'
import videoJobCreateForProjectMutation from 'api/mutations/videoJobCreateForProject.graphql'
import ttsJobCreateMutation from 'api/mutations/ttsJobCreate.graphql'
import pdfJobStartMutation from 'api/mutations/pdfJobStart.graphql'
import createUserTaskMutation from 'api/mutations/userTaskCreate.graphql'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import globalStyles from 'styles/globals.scss'
import styles from './CurrentProjectTitle.scss'

const { SubMenu } = Menu

const ProjectDropdownMenu = ({
  currentIsBranch,
  adminAccess,
  writeAccess,
  handleCopyProject,
  watchYoutubeVideo,
  watchStudioVideo,
  purgeInaccessible,
  exportToJSON,
  synchAudio,
  search,
  exportToPDF,
  cleanAndVerify,
  generateProjects,
  downloadVideo,
  downloadSubtitle,
  undo,
  undoableChangeSet,
  redo,
  redoableChangeSet,
  generateClips,
  resetEditLayout,
  onClick,
  resyncSessions,
}) => (
  <Menu
    selectable={false}
    className={classNames(styles.menu, globalStyles.topBarMenu)}
    theme={Themes.dark}
    onClick={onClick}
  >
    {adminAccess &&
      <Menu.Item key="copy" onClick={handleCopyProject}>
        <Icon type="copy" />Copy Project
      </Menu.Item>
    }

    <Menu.Divider />

    <Menu.Item key="resync" onClick={resyncSessions}>
      <Icon type="sync" />Sync Sessions
    </Menu.Item>

    <Menu.Divider />

    <SubMenu
      key="view"
      popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
      title="View">
      <Menu.Item key="resetEditLayout" disabled={false} onClick={resetEditLayout}>
        <Icon type="layout" />
        <span>Reset Layout</span>
      </Menu.Item>
    </SubMenu>
    {isUndoRedoEnabled && (
      <SubMenu
        key="edit"
        popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
        title="Edit">
        <Menu.Item key="undo" disabled={!undoableChangeSet} onClick={undo}>
          <Icon type="undo" />Undo {undoableChangeSet && (
            <span>&quot;{truncate(undoableChangeSet.description, 15)}&quot;</span>
          )}
        </Menu.Item>
        <Menu.Item key="redo" disabled={!redoableChangeSet} onClick={redo}>
          <Icon type="redo" />Redo {redoableChangeSet && (
            <span>&quot;{truncate(redoableChangeSet.description, 15)}&quot;</span>
          )}
        </Menu.Item>
      </SubMenu>
    )}
    <Menu.Item key="search" onClick={search}>
      <Icon type="search" />Search...
    </Menu.Item>
    <ExtensionPoint
      className={styles.ExtensionPoint}
      point={EXTENSION_POINTS.PROJECT_MENU}
      renderExtension={({ id, label, action: extensionAction, icon }, { moreProps, extAsBneAction }) => (
        <Menu.Item
          {...moreProps}
          key={id}
          disabled={false}
          onClick={extAsBneAction(extensionAction)}>
          <Icon type={icon} />{label}
        </Menu.Item>
      )}
    />
    {writeAccess &&
      <Menu.Divider />
    }
    {writeAccess &&
      <Menu.Item key="clean" onClick={cleanAndVerify} disabled={!currentIsBranch}>
        <Icon type="medicine-box" />
        {'Clean & Verify'}
      </Menu.Item>
    }
    {writeAccess && (
      <SubMenu
        key="tts"
        popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
        title="TTS">
        <Menu.Item onClick={synchAudio}>
          <Icon type="solution" />Synchronize
        </Menu.Item>
      </SubMenu>
    )}
    {writeAccess &&
    <SubMenu
      key="video"
      popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
      title="Video">
      <SubMenu
        popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
        title="Generate">
        <Menu.Item onClick={generateProjects}>
          <Icon type="video-camera-o" />
          Project Video
        </Menu.Item>
        <Menu.Item onClick={generateClips}>
          <Icon type="video-camera-o" />
          Clip Movies
        </Menu.Item>
      </SubMenu>
      <SubMenu
        popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
        title="Watch">
        <Menu.Item onClick={watchYoutubeVideo}>
          <Icon type="youtube" />Youtube</Menu.Item>
        <Menu.Item onClick={watchStudioVideo}>
          <Icon type="youtube" />Embeded</Menu.Item>
      </SubMenu>
      <SubMenu
        popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
        title="Download">
        <Menu.Item onClick={downloadVideo}>
          <Icon type="download" />Movie
        </Menu.Item>
        <Menu.Item onClick={downloadSubtitle}>
          <Icon type="download" />Subtitle
        </Menu.Item>
      </SubMenu>
    </SubMenu>}
    {writeAccess &&
      <Menu.Divider />
    }
    <SubMenu
      popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
      title="Export">
      <Menu.Item onClick={exportToPDF}>
        <Icon type="file-pdf" />PDF
      </Menu.Item>
      <Menu.Item onClick={exportToJSON}>
        <Icon type="download" />JSON
      </Menu.Item>
    </SubMenu>
  </Menu>
)

// Actions
const generateClips = ({ createVideoJobForClips, revisionId }) => async () => {
  await createVideoJobForClips(input({ autoStart: true, revisionId }))
  message.success('Generating videos. This might take a while')
}

const generateProjects = ({ createVideoJobForProject, revisionId }) => async () => {
  try {
    await createVideoJobForProject(input({ revisionId }))
    message.success('Generating video. This might take a while')
  } catch (err) {
    message.error('Oops! there was a problem generating the video. Try again and contact the admin if the problem persist')
    // eslint-disable-next-line no-console
    console.error(err)
    sentry.handleError(err)
  }
}

const synchAudio = ({ revisionId, ttsJobCreate }) => async () => {
  /* eslint no-unused-vars: 0 */
  try {
    const { data: { ttsJobCreate: job } } = await ttsJobCreate(input({ revisionId, autoStart: true }))
    message.success('Generating audio files. This may take a while')
  } catch (err) {
    message.error('Oops! there was a problem generating TTS audio. Try again and contact the admin if the problem persist')
    // eslint-disable-next-line no-console
    console.error(err)
    sentry.handleError(err)
  }
}

const exportToPDF = ({ revisionId, pdfJobStart }) => async () => {
  try {
    const { data: { pdfJobStart: { jobId } } } = await pdfJobStart(input({ revisionId }))
    message.success('Generating pdf file. This might take a while.', 6)
  } catch (e) {
    message.error(`Oops! there was a problem generating the pdf.\n(${parseGraphQLErrors(e)})`)
  }
}

const cleanAndVerify = ({ revisionId, createUserTask }) => async () => {
  try {
    const mutationInput = {
      revision: revisionId,
      taskType: 'Lua',
      taskArgs: {
        luaCode: 'bne.obj.purge_inaccessible()'
      }
    }
    const { data: { createUserTask: { name } } } = await createUserTask(input(mutationInput))
    message.success(`Started Clean & Verify task: ${name}`)
  } catch (e) {
    message.error(`Oops! there was a problem creating the Clean & Verify task.\n(${parseGraphQLErrors(e)})`)
  }
}

const watchYoutubeVideo = ({ goToVideo, revisionId }) => () => goToVideo(`/video/${revisionId}?source=youtube`)
const watchStudioVideo = ({ goToVideo, revisionId }) => () => goToVideo(`/video/${revisionId}?source=studio`)

const exportToJSON = ({ project, exportToJSONAction }) => () => exportToJSONAction(project)

export default compose(
  withUser,
  secure('adminAccess', projectAdminAccess, ['project']),
  secure('writeAccess', isCurrentRevisionWritable, ['project']),
  graphql(videoJobCreateMutation, { name: 'createVideoJobForClips' }),
  graphql(videoJobCreateForProjectMutation, { name: 'createVideoJobForProject' }),
  graphql(ttsJobCreateMutation, { name: 'ttsJobCreate' }),
  graphql(pdfJobStartMutation, { name: 'pdfJobStart' }),
  graphql(createUserTaskMutation, { name: 'createUserTask' }),
  connect(state => ({
    revisionId: revisionIdSelector(state),
    currentIsBranch: currentIsBranchSelector(state),
    ...isUndoRedoEnabled && {
      undoableChangeSet: lastUndoableOwnChange(state),
      redoableChangeSet: lastRedoableOwnChange(state),
    }
  }), ({
    purgeInaccessible: purgeInaccessibleAction,
    goToVideo: url => dispatch => { dispatch(push(url)) },
    downloadVideo: downloadVideoAction,
    downloadSubtitle: downloadSubtitleAction,
    exportToJSONAction: exportProjectToJSON,
    resetEditLayout: resetEditLayoutAction,
    ...isUndoRedoEnabled && {
      undo: undoAction,
      redo: redoAction,
    },
    search: UiCreators.openGlobalSearch,
    resyncSessions: resyncSessionsAction,
  })
  ),
  withHandlers({
    generateClips,
    generateProjects,
    watchYoutubeVideo,
    watchStudioVideo,
    exportToJSON,
    exportToPDF,
    synchAudio,
    cleanAndVerify,

    onClick: ({ setVisible }) => () => {
      setVisible(false)
    }
  }),
)(ProjectDropdownMenu)
