import React from 'react'
import { Menu } from '@blueprintjs/core'
import MenuGroupCommand from '../MenuGroupCommand'
import MenuItemCommand from '../MenuItemCommand'
import { COPY_NODES } from 'commands/copyPaste'

import SessionsSelectionMenuGroup from './SelectionOtherSessionsMenuGroup'

import { DELETE, ENABLE, DISABLE, PUT_DEBUGGING_PIN, REMOVE_DEBUGGING_PIN } from 'commands/edition'
import { connect } from 'react-redux'
import { selectedObjects } from 'selectors/objects'
import ContextMenuExtensionPoint from '../ContextMenuExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

const MultipleSelectionContextMenu = () => (
  <Menu>
    <MenuGroupCommand>
      <MenuItemCommand command={PUT_DEBUGGING_PIN} multipleSelectionOnly />
      <MenuItemCommand command={REMOVE_DEBUGGING_PIN} multipleSelectionOnly />
      <MenuItemCommand command={ENABLE} multipleSelectionOnly />
      <MenuItemCommand command={DISABLE} multipleSelectionOnly />
      <ContextMenuExtensionPoint point={EXTENSION_POINTS.MULTIPLE_SELECTION_CONTEXT_MENU} />
    </MenuGroupCommand>

    <MenuGroupCommand>
      <MenuItemCommand command={COPY_NODES} />
    </MenuGroupCommand>
    <MenuGroupCommand>
      <MenuItemCommand command={DELETE} />
    </MenuGroupCommand>

    <SessionsSelectionMenuGroup />
  </Menu>
)

export default connect(() => state => ({
  selected: selectedObjects(state)
}))(MultipleSelectionContextMenu)
