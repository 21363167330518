import { InMemoryCache } from 'apollo-cache-inmemory'
import fragmentMatcher from './FragmentMatcher'

const dataIdFromObject = obj => {
  switch (obj.__typename) {
    // We probably don't need this anymore. Maybe we should get the _id
    // as any other mongo object, since we need a unique identifier
    // to avoid confusing the cache between projects retrieval
    case 'BNEObject': return `${obj.project}-${obj.id}`;
    default: return obj._id
  }
}

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject
}).restore(window.__APOLLO_STATE__)

export default cache