import React from 'react'

import Secure from 'components/Commons/Secure'
import { orgWriteAccess } from 'security/organization'

const OrgAction = ({ children }) => (
  <Secure check={orgWriteAccess}>
    {children}
  </Secure>
)

export default OrgAction