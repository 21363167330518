import React from 'react'

import { getComponentFor } from './Rule'

import styles from './Rule.scss'

export const CasesExpr = ({ node: { branches, otherwise }, synthetic, level, ...others }) => (
  <span className={styles.casesContainer}>
    {branches.map(branch => <When node={branch} {...others} level={level + 1} />)}
    {otherwise && <Otherwise node={otherwise} {...others} level={level + 1} />}
  </span>
)

const When = ({ node: { pattern, body }, synthetic, level, ...others }) => {
  const Pattern = getComponentFor(pattern)
  const Body = getComponentFor(body)

  return (
    <span className={styles.whenBranch}>
      <span className={styles.keyword}>when</span>&nbsp;
      <Pattern node={pattern} {...others} level={level + 1} />
      <span className={styles.keyword}>then</span>&nbsp;
      <Body node={body} {...others} level={level + 1} />
    &nbsp;
    </span>
  )
}

const Otherwise = ({ node: { body }, synthetic, level, ...others }) => {
  const Body = getComponentFor(body)

  return (
    <span className={styles.otherwiseBranch}>
      <span className={styles.keyword}>otherwise</span>&nbsp;
      <Body node={body} {...others} level={level + 1} />
    </span>
  )
}

export default CasesExpr