exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".Select__RmikM .ant-select-selection-selected-value .leftDecorations__1a-3W,.Select__RmikM .ant-select-selection-selected-value .rightDecorations__3njFE{display:none}.Select__RmikM .inputRightPart__33Eyn{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.Select__RmikM .inputRightPart__33Eyn .anticon{margin-left:.5rem}.option__qH8Cv{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.option__qH8Cv .leftDecorations__1a-3W{width:1rem;margin-right:.8rem;font-size:1.1rem}.option__qH8Cv .title__1skK5{-ms-flex-positive:1;flex-grow:1;overflow:hidden;padding-right:.4rem;-o-text-overflow:ellipsis;text-overflow:ellipsis}.option__qH8Cv:hover .rightDecorations__3njFE .anticon{opacity:1}.option__qH8Cv .rightDecorations__3njFE>span:first-child{color:#acacac;font-size:.7rem}.option__qH8Cv .rightDecorations__3njFE .anticon{opacity:0;color:gray;margin-left:.6rem}", ""]);

// exports
exports.locals = {
	"Select": "Select__RmikM",
	"leftDecorations": "leftDecorations__1a-3W",
	"rightDecorations": "rightDecorations__3njFE",
	"inputRightPart": "inputRightPart__33Eyn",
	"option": "option__qH8Cv",
	"title": "title__1skK5"
};