import React, { Fragment } from 'react'
import { Menu, Dropdown, Icon } from 'antd'
import { compose } from 'recompose'
import { projectAdminAccess } from 'security/project'

import withBooleanState from 'hocs/withBooleanState'
import withUser from 'hocs/withUser'
import secure from 'hocs/secure'

import { ModalConsumer } from 'components/Commons/Modal'

import DeleteProjectModal from '../../ProjectSettings/ProjectDangerZone/DeleteProjectModal'
import TransferProjectModal from '../../ProjectSettings/ProjectDangerZone/TransferProjectModal'
import ArchiveUnarchiveModal from './ArchiveUnarchiveModal'

import styles from './ProjectCard.scss'

const menu = ({ project, selectSettings, startShowingTransferModal, hasAccess, refresh, showArchiveUnarchiveModal }) => (
  (<Menu className={styles.projectMenu}>
    {hasAccess && 
      <Menu.Item key="archive" onClick={showArchiveUnarchiveModal}>
        <Icon type={project.archived ? 'folder-open' : 'folder'} />
        <span>{project.archived ? 'Unarchive' : 'Archive'}</span>
      </Menu.Item>
    }
    {hasAccess && <Menu.Item key="transfer" onClick={startShowingTransferModal}>
      <Icon type="shrink" />
      <span>Transfer</span>
    </Menu.Item>}
    {hasAccess && <Menu.Item key="delete">
      <ModalConsumer>
        {({ showModal }) =>
          (<span onClick={() => showModal(DeleteProjectModal, { onDeleted: refresh, project })}>
            <Icon className={styles.itemIcon} type="delete" theme="filled" />
            <span>Delete</span>
          </span>)}
      </ModalConsumer>
    </Menu.Item>}
    {hasAccess && <Menu.Divider />}
    <Menu.Item key="settings" onClick={() => selectSettings(project)}>
      <Icon type="setting" theme="filled" />
      <span>Settings</span>
    </Menu.Item>
  </Menu>)
)

const ProjectOptions = ({ refresh, hasAccess, startShowingDeleteModal, transferModalVisible, selectSettings, startShowingTransferModal, showArchiveUnarchiveModal, stopShowingTransferModal, project, archiveVisible, hideArchiveUnarchiveModal }) => (
  <Fragment>
    <Dropdown className={styles.projectDropdown} overlay={menu({ refresh, hasAccess, project, selectSettings, startShowingTransferModal, startShowingDeleteModal, showArchiveUnarchiveModal })} trigger={['click']}>
      <Icon className={styles.menuIcon} type="menu" />
    </Dropdown>
    {transferModalVisible && 
      <TransferProjectModal
        onTransferred={refresh}
        project={project}
        hideModal={stopShowingTransferModal}
      />
    }
    {archiveVisible &&
      <ArchiveUnarchiveModal
        onPerformed={refresh}
        project={project}
        hideModal={hideArchiveUnarchiveModal}
      />
    }
  </Fragment>
)

export default compose(
  withUser,
  secure('hasAccess', projectAdminAccess, ['project']),
  withBooleanState('transferModalVisible', 'startShowingTransferModal', 'stopShowingTransferModal'),
  withBooleanState('archiveVisible', 'showArchiveUnarchiveModal', 'hideArchiveUnarchiveModal'),
)(ProjectOptions)