import { SIMPLE_SELECTION, RANGE_SELECTION, unknownSelectionTypeError, RangeSelectionDirection } from 'selectors/nodeSelection'

import { parseRef } from 'beanie-engine-api-js'

//
// We are doing this like a patch.
// Ideally the store should be always packed + with correct edges model
// what we do here, as an intermediate refactor is to convert range selections
// into a "new range" with the form { left: ID, right: ID, direction: LEFT | RIGHT }
// where direction is the selection direction from the user
// but the range is always keeping the order of the graph ! left is the leftmost node, and right the rightmost
//

export const fixSelectionsWithEdges = (selections, index) => selections.map(fixSelectionWithEdges(index))

const fixSelectionWithEdges = index => selection => {
  switch (selection.type) {
    case SIMPLE_SELECTION: return selection
    case RANGE_SELECTION: return fixRageSelectionWithEdges(selection, index)
    default: throw unknownSelectionTypeError(selection)
  }
}

export const newRange = (left, right, direction = RangeSelectionDirection.RIGHT) => ({ type: RANGE_SELECTION, left, right, direction })

const isNodeUpstream = (targetNodeId, startingNodeId, index) => {
  const getParentFor = id => parseRef(index[id]?.data?.parent)

  let currentNode = getParentFor(startingNodeId)

  while (getParentFor(currentNode)) {
    currentNode = getParentFor(currentNode)
  }

  return !!currentNode
}

const fixRageSelectionWithEdges = (range, index) => (
  isNodeUpstream(range.from, range.to, index) ?
    newRange(range.from, range.to, RangeSelectionDirection.RIGHT)
    : newRange(range.to, range.from, RangeSelectionDirection.LEFT)
)
