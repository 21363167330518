import React from 'react'
import { Alert } from 'antd'
import { compose, withHandlers } from 'recompose'
import { push as pushAction } from 'connected-react-router'
import { connect } from 'react-redux'
import { times, isEmpty } from 'ramda'
import classNames from 'classnames'

import { user, token as tokenSelector } from 'selectors/auth'
import openRevisionAction from 'actions/project/openRevision'

import routes from 'components/Organizations/routes'

import ProjectCards, { HOME_PAGE_PROJECTS_CONTAINER_ID } from './ProjectCards'
import { SkeletonCard } from './ProjectCard'

import styles from './Projects.scss'

const Projects = ({ refresh, projects, fetchMore, hasMore, currentUser, selectProject, selectSettings, token }) => (
  <div id={HOME_PAGE_PROJECTS_CONTAINER_ID} className={classNames(styles.projectsContainer, { [styles.empty]: !projects.loading && isEmpty(projects.myGroupedProjects) })} >
    {projects.error && (
      <Alert
        message="Error"
        description="Oops, there was an error fetching the data !"
        type="error"
        showIcon
      >{JSON.stringify(projects.error, null, 2)}</Alert>
    )}
    {projects.loading && <SkeletonCards />}
    {!projects.error && !projects.loading &&
      <ProjectCards
        token={token}
        projects={projects}
        refresh={refresh}
        fetchMore={fetchMore}
        hasMore={hasMore}
        currentUser={currentUser}
        selectProject={selectProject}
        selectSettings={selectSettings}
      />
    }
  </div>
)

const SkeletonCards = () => times(i => (<SkeletonCard key={`skeletonCard_${i}`} />), 6)

export default compose(
  connect(state => ({
    currentUser: user(state),
    token: tokenSelector(state)
  }),
  {
    openRevision: openRevisionAction,
    push: pushAction,
  }),
  withHandlers({
    selectProject: ({ openRevision }) => revisionId => {
      openRevision(revisionId, { url: '/' })
    },
    selectSettings: ({ push }) => project => {
      push(routes.projectSettingsForSection(project.masterBranch._id, 'options'))
    },
  })
)(Projects)
