import loadRevision from '../project/loadRevision'
import { selectedRevisionId } from 'selectors/selections'
import { loadRevisionStart } from '../project'

/**
 * Loads the selected revisionId. Starts all the magic to:
 * - fetch the revision
 * - setup the local redux state
 * - setup the engine
 * - fetch any other resources like savegames
 * - create a revision session to be connected for online editing
 */
const loadSelectedRevision = () => (dispatch, getState, context) =>
  loadRevision(selectedRevisionId(getState()))(dispatch, getState, context)

export const reLoadSelectedRevision = () => async (dispatch, getState) => {
  dispatch(loadRevisionStart(selectedRevisionId(getState())))
}

export default loadSelectedRevision
