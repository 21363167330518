exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".slider__2chvO{width:100%}", ""]);

// exports
exports.locals = {
	"slider": "slider__2chvO"
};