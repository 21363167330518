import React from 'react'

import { F } from 'ramda'
import resolving from 'hocs/node/resolving'

import styles from '../TreeView.scss'

/* eslint global-require: 0 */

const TreeChainElementLogic = ({ logicNode, children }) => {
  const TElement = require('./TreeChainElement').default
  return (
    <div className={styles.logicNode}>
      {children}
      <TElement
        node={logicNode}
        dndCanDrop={F}
        dndCanDrag={F}
      />
    </div>
  )
}

export default resolving({
  from: 'node',
  path: 'data.logic_node',
  to: 'logicNode'
})(TreeChainElementLogic)