import React, { useMemo } from 'react'
import { update, times, F, propOr } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { compose, onlyUpdateForKeys, withState, withHandlers, defaultProps } from 'recompose'
import { model, parseRef } from 'beanie-engine-api-js'

import TruthTableOtherwiseRow from './otherwise/TruthTableOtherwiseRow'
import withNodeSelection from 'hocs/node/withNodeSelection'
import TruthTableRow from './row/TruthTableRow'
import withNodeIsEnabled from 'hocs/withNodeIsEnabled'
import { nodeIdFor, withData, DataFields } from 'dom/dom'
import withStaticAnalysis from 'hocs/withStaticAnalysis'
import TruthTableRowDrawLayer from './dnd/row/TruthTableRowDrawLayer'
import TruthTableColumnDrawLayer from './dnd/column/TruthTableColumnDrawLayer'
import TruthTableDrawLayer from './dnd/table/TruthTableDrawLayer'
import TruthTableHeaderRow from './header/TruthTableHeaderRow'
import useHover from 'hooks/useHover'
import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'
import TruthTableDragHandle from './dnd/table/TruthTableDragHandle'
import { getDebuggableRowFromTruthTableId, isDebuggable } from 'selectors/debuggingData'
import { pathFromProps } from 'selectors/props'

import styles from './TruthTable.scss'
import domStyles from 'dom/dom.scss'

const { types: { object: { isDisabled }, truthTable: { headers, otherwise, rows }, relationship: { Relationship } } } = model

/**
 *
 */
const TruthTable = props => {
  const {
    node,
    relationship,

    isSelected,
    onClicked,
    isDraggingArray,
    isEnabled,
    executionState,
    staticIssues,
    isForcedToShowDisabled
  } = props

  const execution_count = propOr(0, 'execution_count', executionState)
  const executed = execution_count > 0
  const countOfColumns = useMemo(() => headers(node).length, [node])

  const _rows = useMemo(() => rows(node), [node])
  const countOfRows = useMemo(() => _rows.length, [_rows])
  const otherwiseId = useMemo(() => parseRef(otherwise(node)), [node])
  const [hoverRef, isHovered] = useHover()

  const debuggableRowSelector = useMemo(() => getDebuggableRowFromTruthTableId(node.id), [node.id])
  const debuggableSelector = useMemo(() => isDebuggable(node.id), [node.id])

  const debuggable = useSelector(debuggableSelector)
  const debuggableRow = useSelector(debuggableRowSelector)
  return (
    <div
      className={classNames(
        styles.truthTableContainer,
        domStyles.node,
      )}
      id={nodeIdFor(node.id)}
      {...withData(DataFields.nodeId, node.id)}
      ref={hoverRef}
      onClick={onClicked}
      tabIndex="-1"
      role="button"
    >
      {isHovered && isSelected && <TruthTableDragHandle node={node} relationship={relationship} />}

      <table
        className={classNames(styles.truthTable, {
          [styles.disabledTruthTableScope]: isForcedToShowDisabled || (isDisabled(node) && !debuggable)
        })}
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        <TruthTableDrawLayer />
        <TruthTableRowDrawLayer truthTable={node} />
        <TruthTableColumnDrawLayer truthTableId={node.id} />

        {/* TODO: avoid passing all props ! */}
        <thead>
          <TruthTableHeaderRow
            executed={executed}
            countOfRows={countOfRows}
            countOfColumns={countOfColumns}
            otherwiseId={otherwiseId}
            staticIssues={staticIssues}
            isHovered={isHovered}
            truthTable={node}
            {...props}
          />
        </thead>

        <tbody>
          {_rows.map((rowId, i) => (
            <TruthTableRow
              id={parseRef(rowId)}
              key={`row_${i}_${rowId}`}
              index={i}
              relationship={Relationship.containerContent(node.id, i)}

              truthTable={node}
              countOfRows={countOfRows}
              truthTableExecuted={executed}
              truthTableIsHovered={isHovered}
              hasOtherwiseRow={isNotNil(otherwiseId)}
              isDraggingArray={isDraggingArray}

              truthTableEnabled={isEnabled}
              truthTableSelected={isSelected}

              debuggableRow={debuggableRow}
            />
          ))}

          <TruthTableOtherwiseRow
            id={otherwiseId}
            truthTable={node}

            truthTableExecuted={executed}
            truthTableEnabled={isEnabled}
            truthTableSelected={isSelected}
            countOfColumns={countOfColumns}
            debuggableRow={debuggableRow}
          />
        </tbody>
      </table>
    </div>
  )
}

export default compose(
  withState('expanded', 'setExpanded', true),
  withState('isDraggingArray', 'setIsDraggingArray', ({ node }) => {
    return times(F, headers(node).length)
  }),
  withHandlers({
    toggleCollapse: ({ setExpanded, expanded }) => () => {
      setExpanded(!expanded)
    },
    updateIsDraggingColumn: ({ isDraggingArray, setIsDraggingArray }) => (index, value) => {
      setIsDraggingArray(update(index, value, isDraggingArray))
    }
  }),
  defaultProps({
    filler: true
  }),
  withNodeExecutionTracking(pathFromProps(['node', 'id'])),
  withNodeIsEnabled(),
  withNodeSelection(),
  withStaticAnalysis(),
  onlyUpdateForKeys([
    'node',
    'isSelected',
    'isHovered',
    'executionState',
    'staticIssues',
    'executionPath',
    'trackExecutionEnabled',
    'expanded',
    'isEnabled',
    'isDraggingArray',
    'isForcedToShowDisabled'
  ]),
)(TruthTable)
