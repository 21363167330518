import React from 'react'
import PropTypes from 'prop-types'
import { getDisplayName } from 'hocs/utils'

const withSynchronizer = WrappedComponent => {
  const c = class WithSynchronizer extends React.Component {
    static contextTypes = {
      synchronizer: PropTypes.object.isRequired,
    }
    render() {
      const { synchronizer } = this.context
      return (
        <WrappedComponent {...this.props} synchronizer={synchronizer} />
      )
    }
  }
  c.displayName = `withSynchronizer(${getDisplayName(WrappedComponent)})`
  return c
}

export default withSynchronizer