import React from 'react'
import { connect } from 'react-redux'
import { Icon, message, Tooltip } from 'antd'
import classNames from 'classnames'
import { compose, withHandlers, withPropsOnChange, withState } from 'recompose'

import { playLineAudio as playLineAudioAction } from 'actions/audio'
import { stopSound } from 'model/sound'

import { makeAudioForLineSelector } from 'selectors/lines'
import { propFromProps } from '../../../../selectors/props'

import styles from './PlayLineAudio.scss'

// eslint-disable-next-line import/no-webpack-loader-syntax
import StopIcon from '-!svg-react-loader!../../../PlaybackView/PlaybackControls/icons/stop.svg'

const _PlayLineAudio = ({ play, stop, isPlaying, hideControl }) => (
  <div contentEditable={false} className={classNames(styles.PlayLineAudio, { [styles.hidden]: hideControl })}>
    {(isPlaying ?
      <Icon component={StopIcon} onClick={stop} />
        : <Tooltip title="Play audio" mouseEnterDelay={1}><Icon type="caret-right" onClick={play} /></Tooltip>
    )}
  </div>
)

const PlayLineAudio = compose(
  connect(
    () => {
      const audioForLineSelector = makeAudioForLineSelector(propFromProps('lineId'))

      return (state, props) => ({
        audio: audioForLineSelector(state, props)
      })
    },
    {
      playLineAudio: playLineAudioAction
    }
  ),
  withState('isPlaying', 'setPlaying', false),
  withPropsOnChange(
    ['mouseOver', 'audio', 'isPlaying'],
    ({ mouseOver, audio, isPlaying }) => ({
      // hide when it has no audio or is not mouse over, but always show if it's playing
      hideControl: !((audio && mouseOver) || isPlaying)
    })
  ),
  withHandlers({
    stop: ({ lineId, setPlaying }) => () => {
      stopSound(lineId)
      setPlaying(false)
    },
    play: ({ lineId, audio, setPlaying, playLineAudio }) => async () => {
      setPlaying(true)
      try {
        await playLineAudio(lineId, audio)
      } catch (error) {
        message.error('There was a problem trying to play the line\'s audio !')
      } finally {
        setPlaying(false)
      }
    }
  })
)(_PlayLineAudio)
export default PlayLineAudio
