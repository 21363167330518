import React from 'react'
import { Dropdown } from 'antd'
import classNames from 'classnames'
import { compose, withState, withHandlers } from 'recompose'
import { Key } from 'utils/keyboard'

import onKeyPress from 'hocs/onKeyPress'

import styles from '../../SlateTextEditor.scss'

const DropDownMenuItem = ({ className, overlayClassName, visible, onVisibleChange, onItemSelected, children, content }) => (
  <div contentEditable={false} className={classNames(styles.DropDownMenuItem, className)}>
    <Dropdown
      trigger={['click']}
      overlay={content(onItemSelected)}
      overlayClassName={classNames(styles.DropDownMenuItemOverlay, overlayClassName)}
      visible={visible}
      onVisibleChange={onVisibleChange}
    >{children}</Dropdown>
  </div>
)

export default compose(
  withState('visible', 'setVisible', false),
  withHandlers({
    onVisibleChange: ({ setVisible }) => nowVisible => {
      setVisible(nowVisible)
    },
    onItemSelected: ({ setVisible, }) => () => {
      setVisible(false)
    }
  }),
  onKeyPress({
    [Key.ESC]: ({ visible, setVisible }) => event => {
      if (visible) {
        setVisible(false)
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }),
)(DropDownMenuItem)