import React from 'react'
import { Tooltip, Spin } from 'antd'
import { compose, withState, withHandlers, pure } from 'recompose'
import { noop } from 'utils/functions'

const LinkButton = ({ children, tooltip, tooltipPlacement = 'top', disabled, processing, setProcessing, ...props }) => {
  const render = () => {
    if (disabled) return <span {...props}>{children}</span>
    if (processing) return <Spin spinning><span {...props}>{children}</span></Spin>
    return <a {...props} role="button" tabIndex={-1}>{children}</a>
  }

  return tooltip ? (
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      {render()}
    </Tooltip>
  ) : render()
}

export default compose(
  pure,
  withState('processing', 'setProcessing'),
  withHandlers({
    onClick: ({ onClick = noop, setProcessing }) => async (...args) => {
      try {
        setProcessing(true)
        await onClick(...args)
      } finally {
        setProcessing(false)
      }
    }
  })
)(LinkButton)