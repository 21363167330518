import { Key, matchesStrictly } from 'utils/keyboard'
import { NODE_TYPES } from '../../Constants'
import { any, always, equals, curry } from 'ramda'
import { findDescendentOfType } from '../../nodeUtils'

const ARROWS = [Key.DOWN, Key.UP, Key.RIGHT, Key.LEFT]

const isAtLastPartByLine = {
  [NODE_TYPES.DIALOGUE_LINE]: ({ type }) => equals(type, NODE_TYPES.TEXT_PART),
  [NODE_TYPES.DIRECTOR_LINE]: always(true)
}

const isAtFirstPartByLine = {
  [NODE_TYPES.DIALOGUE_LINE]: ({ type }) => equals(type, NODE_TYPES.ACTOR_PART),
  [NODE_TYPES.DIRECTOR_LINE]: always(true)
}

export default () => ({

  queries: {

    indexOfCurrentLine: editor => editor.getLines().indexOf(editor.currentLineBlock()),

    isAtFirstPartOfLine: editor => {
      const { type: typeOfLine } = editor.currentLineBlock()
      return isAtFirstPartByLine[typeOfLine](editor.currentNode())
    },

    isAtLastPartOfLine: editor => {
      const { type: typeOfLine } = editor.currentLineBlock()
      return isAtLastPartByLine[typeOfLine](editor.currentNode())
    },

    isAtFirstLine: editor => equals(editor.indexOfCurrentLine(), 0),
    isAtLastLine: editor => equals(editor.indexOfCurrentLine(), editor.getLines().size - 1)
  },

  commands: {

    setCursorOnLineFromTop: (editor, line) => {
      const node = equals(line.type, NODE_TYPES.DIALOGUE_LINE) ?
        findDescendentOfType(NODE_TYPES.ACTOR_PART)(line) : line
      editor.moveToStartOfNode(node)
    },

    setCursorOnLineFromBottom: (editor, line) => {
      const node = (equals(line.type, NODE_TYPES.DIALOGUE_LINE)) ?
        findDescendentOfType(NODE_TYPES.TEXT_PART)(line) : line
      editor.moveToStartOfNode(node)
    },

    traverseFromTop: (editor, offset) => {
      editor.focus()
      if (offset === 0) { editor.moveToStartOfDocument() } else {
        editor.setCursorOnLineFromTop(editor.getFirstLine())
        editor.moveTo(offset)
      }
    },

    traverseFromBottom: (editor, offset) => {
      const { value: { document } } = editor
      editor.focus()
      if (offset >= document.text.length) { editor.moveToEndOfDocument() } else {
        editor.setCursorOnLineFromBottom(editor.getLastLine())
        editor.moveTo(offset)
      }
    }
  },

  onClick(event, editor, next) {
    const { props: { cursorInfo, moveToSpecificEditor, object } } = editor
    if (moveToSpecificEditor && object.id !== cursorInfo.cursorAt) {
      moveToSpecificEditor(object.id)
    }
    return next()
  },

  onKeyDown (event, editor, next) {
    if (!editor.selectionIsExpanded() && any(curry(matchesStrictly)(event), ARROWS)) {

      const { props: { moveToNextEditor, moveToPreviousEditor } } = editor
      const { offset } = editor.currentFocus()
      const currentLine = editor.currentLineBlock()

      if (editor.isAtFirstLine() && moveToPreviousEditor) {
        if (equals(event.keyCode, Key.UP) && editor.isAtFirstPartOfLine()) {
          event.preventDefault()
          event.stopPropagation()
          moveToPreviousEditor(offset)
          return false
        }

        if (equals(event.keyCode, Key.LEFT) && editor.focusIsAtStartOfNode(currentLine)) {
          event.preventDefault()
          event.stopPropagation()
          moveToPreviousEditor(1000)
          return false
        }
      }

      if (editor.isAtLastLine() && moveToNextEditor) {
        if (equals(event.keyCode, Key.DOWN) && editor.isAtLastPartOfLine() && moveToNextEditor) {
          event.preventDefault()
          event.stopPropagation()
          moveToNextEditor(offset)
          return false
        }

        if (equals(event.keyCode, Key.RIGHT) && editor.focusIsAtEndOfNode(currentLine)) {
          event.preventDefault()
          event.stopPropagation()
          moveToNextEditor(0)
          return false
        }
      }
    }
    return next()
  },

})
