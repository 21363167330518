
/*
 * @deprecated: we will replace this with denormalize
 */
export const createSelector = (array, ...args) => {
  const middlewares = []
  const execMiddlewares = e => middlewares.reduce((acc, mid) => mid(acc), e)

  const exec = () => args.reduce((acc, fn) => execMiddlewares(acc.map(fn)), execMiddlewares(array))
  exec.use = middleware => {
    middlewares.push(middleware)
    return exec
  }
  return exec
}