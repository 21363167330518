import React, { createContext } from 'react'

export const EngineContext = createContext()

/**
 * Sets the engine available as a context
 */
const EngineProvider = ({ engine, children }) => {
  return (
    <EngineContext.Provider value={engine}>
      {children}
    </EngineContext.Provider>
  )
}

export default EngineProvider