import { Modal } from 'antd'
import React from 'react'
import Preferences from '../../preferences/Preferences'
import TogglePreferenceButton from '../Commons/Preferences/TogglePreferenceButton'
import StateEvents from './StateEvents'

import styles from './StateEventsModal.scss'

/**
 *
 */
const StateEventsModal = ({ hide }) => {
  return (
    <Modal
      title="State Events Log"
      visible
      footer={null}
      destroyOnClose
      onCancel={() => { hide() }}
    >
      <div className={styles.StateEventsModal}>

        <StateEvents />

        <div className={styles.toolbar}>
          <TogglePreferenceButton
            key="showExecutionCountVariables"
            type="number"
            description="Show/Hide Execution Counts"
            preference={Preferences.Playback.showExecutionCountVariables}
          />
        </div>

      </div>
    </Modal>
  )
}

export default StateEventsModal