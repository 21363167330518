
export const capitaliseFirstLetter = (string) => string
  .split('-')
  .map(part => part.charAt(0).toUpperCase() + part.slice(1))
  .join('')

export const positiveIntegerProptype = (props, propName, componentName) => {
  const n = props[propName]
  if (n !== undefined && (typeof n !== 'number' || n !== parseInt(n, 10) || n < 0)) {
    return new Error(`Invalid ${propName} supplied to ${componentName}, expected a positive integer`)
  }
  return null
}

export const audioPropType = (props, propName, componentName) => {
  const prop = props[propName];
  if (prop && typeof prop !== 'string' && !(prop instanceof window.Blob) && !(prop instanceof window.File)) {
    return new Error(`Invalid ${propName} supplied to ${componentName}
      expected either string or file/blob`);
  }
  return null;
}

export const resizeThrottler = fn => () => {
  let resizeTimeout

  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null
      fn()
    }, 66)
  }
}

// wavesurfer

export const EVENTS = [
  'audioprocess',
  'error',
  'finish',
  'loading',
  'mouseup',
  'pause',
  'play',
  'ready',
  'scroll',
  'seek',
  'zoom'
]

export const hookEventListeners = (listener, _wavesurfer) => {
  EVENTS.forEach(e => {
    const propCallback = listener[`on${capitaliseFirstLetter(e)}`];
    const wavesurfer = _wavesurfer
    if (propCallback) {
      _wavesurfer.on(e, (...originalArgs) => {
        propCallback({
          wavesurfer,
          originalArgs
        })
      })
    }
  })
}

export const loadAudio = (_wavesurfer, audioFileOrElt, audioPeaks) => {
  if (audioFileOrElt instanceof window.HTMLElement) {
    // media element
    _wavesurfer.loadMediaElement(audioFileOrElt, audioPeaks);
  } else if (typeof audioFileOrElt === 'string') {
    // bog-standard string is handled by load method and ajax call
    _wavesurfer.load(audioFileOrElt, audioPeaks);
  } else if (audioFileOrElt instanceof window.Blob || audioFileOrElt instanceof window.File) {
    // blob or file is loaded with loadBlob method
    _wavesurfer.loadBlob(audioFileOrElt, audioPeaks)
  } else {
    throw new Error('Wavesurfer._loadAudio expects prop audioFile to be either HTMLElement, string or file/blob');
  }
}

export const loadMediaElt = (_wavesurfer, selectorOrElt, audioPeaks) => {
  if (selectorOrElt instanceof window.HTMLElement) {
    loadAudio(_wavesurfer, selectorOrElt, audioPeaks);
  } else {
    if (!window.document.querySelector(selectorOrElt)) {
      throw new Error('Media Element not found!')
    }
    loadAudio(_wavesurfer, window.document.querySelector(selectorOrElt), audioPeaks)
  }
}
