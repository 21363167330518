import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { Table, Badge } from 'antd'
import UserAvatar from 'components/RevisionSessions/UserAvatar'
import RelativeTime from 'components/Commons/RelativeTime'
import UserName from 'components/Commons/UserName'
import UsersRowActions from './UsersPanel/UsersRowActions'

import userDeleteMutation from 'api/mutations/userDelete.graphql'
import userEditMutation from 'api/mutations/userEdit.graphql'

const { Column } = Table

const Footer = content => () => <div>{content}</div>

const UsersTable = ({ deleteUser, resetPassword, user: currentUser, users: { list, paging }, pageSize, onPageChanged, handleTableChange, onUserUpdated, isOnline, footer }) => (
  <Table
    rowKey={record => record.email}
    dataSource={list}
    pagination={({ pageSize, onChange: onPageChanged, total: paging.nrOfItems })}
    onChange={handleTableChange}
    footer={Footer(footer)}
  >
    <Column
      key="avatar"
      render={(_, user) => (
        isOnline(user) ?
          <Badge status="success"><UserAvatar user={user} contextMenuEnabled={false} /></Badge>
          : <UserAvatar user={user} contextMenuEnabled={false} />
      )}
      width="2em"
    />
    <Column
      title="Name"
      key="name"
      render={(_, user) => UserName({ user })}
      width="20%"
    />
    <Column
      title="Email"
      sorter
      dataIndex="email"
      key="email"
      width="30%"
    />
    <Column
      title="Role"
      dataIndex="roles"
      key="role"
      render={roles => roles.reduce((acum, cur) => (acum === '' ? cur : `${acum}, ${cur}`), '')}
    />
    <Column
      title="Last Login"
      sorter
      dataIndex="lastLoginAt"
      key="lastLoginAt"
      render={_ => <RelativeTime date={_} />}
    />
    <Column
      title="Actions"
      key="actions"
      render={(_, record) => (
        <UsersRowActions
          currentUser={currentUser}
          user={record}
          onUserUpdated={onUserUpdated}
          deleteUser={deleteUser}
          resetPassword={resetPassword}
        />
      )}
    />
  </Table>
)

export default compose(
  graphql(userDeleteMutation, { name: 'mutateUserDelete' }),
  graphql(userEditMutation, { name: 'mutateUserEdit' }),
  withHandlers({
    deleteUser: ({ onUserUpdated, mutateUserDelete }) => async userId => {
      // TODO: handle loading wheel until promise resolved fine / error
      await mutateUserDelete({ variables: { input: { userId } } })
      await onUserUpdated()
    },
  
    resetPassword: ({ onUserUpdated, mutateUserEdit }) => async _id => {
      await mutateUserEdit({ variables: { input: { _id, forceResetPassword: true } } })
      await onUserUpdated()
    },
  })
)(UsersTable)
