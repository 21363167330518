import React from 'react'
import { Icon } from 'antd'

import styles from './PinsPanel.scss'

const EnableDisableIcon = ({ isEnabled }) => (
  <Icon
    theme=""
    className={styles.VisibilityIcon}
    type={isEnabled ? 'eye' : 'eye-invisible'}
  />
)

export default EnableDisableIcon
