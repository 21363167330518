exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".errors__MGvIn{padding-top:.5rem;padding-bottom:.5rem;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.errors__MGvIn .error__1_vO5{color:red}", ""]);

// exports
exports.locals = {
	"errors": "errors__MGvIn",
	"error": "error__1_vO5"
};