import React from 'react'
import { Avatar } from 'antd'
import { branch, compose, renderComponent } from 'recompose'

import styles from './OrganizationSelectOption.scss'

import { organizations } from 'model/resources'
import withToken from 'hocs/withToken'

const OrganizationSelectOption = ({ organization, nameEnabled = true, descriptionEnabled = true }) => (
  <div className={styles.option}>
    <OrganizationSimpleAvatar organization={organization} />
    {nameEnabled && organization.name} {descriptionEnabled && <span className={styles.description}>{organization.description}</span>}
  </div>
)

const hasOrgAvatar = ({ organization, token }) => !!getOrgAvatarImg(organization, token)
const imageAvatar = ({ organization, token }) => <Avatar src={getOrgAvatarImg(organization, token)} />
const noImageAvatar = () => <Avatar icon="bank" />

const _OrganizationSimpleAvatar = compose(
  branch(
    hasOrgAvatar,
    renderComponent(imageAvatar),
    renderComponent(noImageAvatar)
  )
)(() => null)

const getOrgAvatarImg = (organization, token) =>
  organization.avatar && organizations.get(organization._id, organization.avatar, token)

const OrganizationSimpleAvatar = withToken(_OrganizationSimpleAvatar)

export default OrganizationSelectOption