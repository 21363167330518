import { createActions } from 'reduxsauce'

import { activePreset, makeSavegameForPreset } from 'selectors/presets'
import { isExecutingChainExecutions } from 'selectors/state/executions'
import { loadSavegameFromPreset } from 'engine/actions/state'
import { Creators as vmCreators } from 'actions/vm'

export const { Types, Creators } = createActions({

  assocPreset: ['preset', 'projectId', 'userId', 'savegame'],

  dissocPreset: (preset, projectId, userId) => (dispatch, getState) => {
    const active = activePreset(getState())
    if (preset === active) dispatch(Creators.unsetActivePreset())
    return dispatch(Creators._dissocPreset(preset, projectId, userId))
  },

  _dissocPreset: ['preset', 'projectId', 'userId'],
  setActivePreset: ['preset'],
  unsetActivePreset: null,

  activatePreset: preset => async (dispatch, getState) => {
    dispatch(vmCreators.stopPlayback()) // clears playback state
    dispatch(Creators.setActivePreset(preset))
    await dispatch(loadSavegameFromPreset(preset))
    if (isExecutingChainExecutions(getState())) dispatch(vmCreators.pausePlayback())
  },

  togglePreset: preset => async (dispatch, getState) => {
    const savegame = makeSavegameForPreset()(getState(), { preset })
    if (!savegame) return
    const active = activePreset(getState())
    if (active !== preset) {
      await dispatch(Creators.activatePreset(preset))
    } else {
      dispatch(Creators.unsetActivePreset())
    }
  }
})
