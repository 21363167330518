import { model } from 'beanie-engine-api-js'
import { toggleAutoChoice } from '../engine/actions/choice'
import { pinReferencesFromChoice } from 'actions/project/debuggingData'
import { isCurrentRevisionWritable } from '../security/project'
import { currentDebugScenarioSelected } from '../selectors/debuggingData'

const { types: { object: { isChoice }, choice: { isAutoChoice } } } = model

export const TOGGLE_AUTO_CHOICE = ({
  label: choice => (
    isAutoChoice(choice) ? 'Unset Auto-choice' : 'Set Auto-choice'
  ),
  action: toggleAutoChoice,
  visible: isChoice,
  enabled: (o, state) => currentDebugScenarioSelected(state) && isChoice(o),

  check: isCurrentRevisionWritable
})

export const PIN_CONDITIONS_FROM_CHOICE = ({
  label: () => 'Pin all related conditions/rows',
  icon: () => 'pin',
  action: pinReferencesFromChoice,
  visible: isChoice,
  enabled: (o, state) => currentDebugScenarioSelected(state) && isChoice(o),

})