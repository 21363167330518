import React from 'react'
import { Dropdown, Icon, Menu } from 'antd'
import { connect } from 'react-redux'
import { isCurrentStateEmpty } from '../../../../selectors/state'
import UnrealIcon from './UnrealIcon'
import UnrealSession from './UnrealSession'

import setEngineState from 'actions/sessions/unreal/setEngineState'

const renderMenu = ({ session, isPlaybackStateEmpty, onSendStateToUnreal }) => (
  <Menu>
    <Menu.Item onClick={() => onSendStateToUnreal(session)} disabled={isPlaybackStateEmpty}>
      <Icon type="export" />Send Playback State
    </Menu.Item>
  </Menu>
)

/**
 * An Unreal icon/dropdown menu that appears in the Playback controls bar when the user
 * has an active unreal session.
 * Provides actions to send commands to unreal.
 */
const UnrealPlaybackMenu = ({ onSendStateToUnreal, isPlaybackStateEmpty }) => (
  <UnrealSession>
    {session => (
      <Dropdown overlay={renderMenu({ session, onSendStateToUnreal, isPlaybackStateEmpty })}>
        <UnrealIcon />
      </Dropdown>
    )}
  </UnrealSession>
)

export default connect(
  state => ({
    isPlaybackStateEmpty: isCurrentStateEmpty(state)
  }),
  {
    onSendStateToUnreal: setEngineState
  }
)(UnrealPlaybackMenu)