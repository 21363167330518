import React from 'react'
import { compose, lifecycle } from 'recompose'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { Icon, notification } from 'antd'

import { appVersion } from 'selectors/appMeta'
import Subscribed from 'hocs/Subscribed'
// import { hardRefresh } from 'utils/browser'

import versionCheckerSubscriptionQuery from 'api/subscriptions/versionChecker.graphql'
// import styles from 'styles/commons.scss'


// const _UpdateButton = ({ loading, update }) => <Button type="danger" loading={loading} onClick={update}>Update</Button>
// const UpdateButton = compose(
//   withState('loading', 'setLoading', false),
//   withHandlers({
//     update: ({ setLoading }) => () => {
//       setLoading(true)
//       hardRefresh()
//     }
//   })
// )(_UpdateButton)

// const updateAvailable = 'version-checker-update-available'
const updatedOk = 'version-checker-updated-ok'

@withApollo
export default class VersionChecker extends Subscribed {

  query = versionCheckerSubscriptionQuery

  onSubscriptionEvent({ data: { frontendVersionChecker: { latestVersion } } }) {
    // eslint-disable-next-line no-console
    console.log('received subscription event for frontendVersionChecker with latestVersion:', latestVersion)
    // this.setState({ show: true, latestVersion })
    // notification.open({
    //   key: updateAvailable,
    //   message: 'New version available',
    //   description: `Click update to confirm you want to upgrade to version ${latestVersion}. Application will reload after confirm.`,
    //   icon: <Icon type="warning" style={{ color: 'orange' }} />,
    //   closeIcon: null,
    //   btn: <UpdateButton />,
    //   duration: 0,
    //   className: styles.notificationNotClosable
    // })
  }

  onSubscriptionError(error) {
    notification.open({
      message: 'Communication Error',
      description: `${JSON.stringify(error)}`,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
    })
  }

  render() {
    return <LatestVersionInstalled />
  }
}

const LatestVersionInstalled = compose(
  connect(state => ({
    version: appVersion(state)
  })),
  lifecycle({
    componentDidUpdate() {
      const { version } = this.props
      notification.open({
        key: updatedOk,
        message: 'Hooray!',
        description: `You are using version ${version}. The latest Beanie version available.`,
        icon: <Icon type="check-circle" style={{ color: 'green' }} />,
        duration: 0
      })
    }
  })
)(() => null)