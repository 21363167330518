import { path } from 'ramda'
import { model, parseRef } from 'beanie-engine-api-js'
import { visit } from 'actions/nodes/search/visitors/graphOrderedVisitor'
import { objectsIndex } from 'selectors/apollo'
import { propFromProps } from 'selectors/props'
import { createSelector } from 'selectors/reselect'
import { propFrom } from 'utils/object'

const { types: { object: { Paths } } } = model

/**
 * create a memoizing selector for an object id which analyses the downstream
 * for "outbound" paths.
 * That is jump to nodes that are out side of this "cluster" of nodes.
 * Useful to compute for root chains. In that case it will be the "outer connections" to
 * nodes on other chains.
 *
 * i.e.:
 *    ROOT1 -> ... -> CHOICES -> ...
 *                       CH1 -> JUMP(ROOT2)
 *                       CH2 -> JUMP(ROOT3)
 *    ROOT2 -> ...
 *    ROOT3 -> ...
 *
 * The outbounds of ROOT1 are: [ROOT2, ROOT3].
 * With this you don't need to visually inspect the chain's complete content to understand where does it goes to
 */
const createChainExitsSelector = () => createSelector(
  'createOutboundFlowSelector',
  [propFromProps('nodeId'), objectsIndex],
  (id, index) => {
    const outbounds = {}
    const idsSeen = {}
    const collect = obj => {
      // remember everything we traverse
      idsSeen[obj.id] = true

      // if there was a jump to this already, remove it
      if (outbounds[obj.id]) {
        delete outbounds[obj.id]
      }

      // process jumps
      if (obj.sys === 'jump') {
        const target = path(Paths.jump.target, obj)
        if (target) {
          const targetId = parseRef(target)

          // if target exists and was not seen
          if (index[targetId] && !idsSeen[targetId]) {
            outbounds[targetId] = true
          }
        }
      }
    }
    visit(id, propFrom(index), collect)
    return Object.keys(outbounds)
  }
)

export default createChainExitsSelector