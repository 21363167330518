import React from 'react'
import { connect } from 'react-redux'
import { propEq } from 'ramda'

import { actors as actorsSelector } from 'selectors/objects'
import { linesByActor as linesByActorSelector } from 'selectors/lines'

import ActorTable from './ActorTable'
import ActorDetail from './ActorDetail'
import DeleteActorModal from './DeleteActorModal'
import MoveLinesModal from './MoveLinesModal'
import CopyActorPropertiesModal from './CopyActorPropertiesModal'

import styles from './ActorSection.scss'

@connect(state => ({
  linesByActor: linesByActorSelector(state),
  actors: actorsSelector(state)
}))
export default class ActorSection extends React.Component {
  state = {}

  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { actors } = nextProps
    const { actor } = prevState
    if (actors && actor) {
      const newActor = actors.find(propEq('id', actor.id))
      if (newActor !== actor) {
        return { actor: newActor }
      }
    }
    return null
  }

  validateNewName = name => {
    const { actors } = this.props
    return !actors.some(actor => {
      const existingName = actor.data.actorName
      return existingName && existingName.toUpperCase() === name.toUpperCase()
    })
  }

  handleActorChanged = actor => {
    this.setState({ actor })
  }

  handleDeleteActor = actorToBeDeleted => { this.setState({ actorToBeDeleted }) }
  clearDeleteActor = () => this.setState({ actorToBeDeleted: undefined })

  handleMoveLines = actorToMoveLinesFrom => { this.setState({ actorToMoveLinesFrom }) }
  clearMoveLines = () => this.setState({ actorToMoveLinesFrom: undefined })

  handleCopyProperties = actorToCopyPropertiesTo => { this.setState({ actorToCopyPropertiesTo }) }
  clearCopyProperties = () => this.setState({ actorToCopyPropertiesTo: undefined })

  render = () => {
    const { actor, actorToBeDeleted, actorToMoveLinesFrom, actorToCopyPropertiesTo } = this.state
    return (
      <div className={styles.actorsSection}>
        <ActorTable
          actors={this.props.actors}
          onActorChanged={this.handleActorChanged}
          onDeleteActor={this.handleDeleteActor}
          onMoveLines={this.handleMoveLines}
        />
        <ActorDetail actor={actor} validateNewName={this.validateNewName} onCopyProperties={this.handleCopyProperties} />

        {
          actorToBeDeleted &&
            <DeleteActorModal visible lines={this.props.linesByActor} actor={actorToBeDeleted} onFinish={this.clearDeleteActor} />
        }
        {
          actorToMoveLinesFrom &&
            <MoveLinesModal visible lines={this.props.linesByActor} actor={actorToMoveLinesFrom} onFinish={this.clearMoveLines} />
        }

        {
          actorToCopyPropertiesTo &&
            <CopyActorPropertiesModal actorToCopyPropertiesTo={actorToCopyPropertiesTo} clearCopyProperties={this.clearCopyProperties} />
        }
      </div>
    )
  }
}