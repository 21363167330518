import React from 'react'
import classNames from 'classnames'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Menu, Icon } from 'antd'
import { graphql } from 'react-apollo'

import { Themes } from 'utils/antd'
import { EMPTY_ARRAY } from 'utils/object'

import { projectWriteAccess } from 'security/project'
import { currentBranchId as currentBranchIdSelector, revisionId, currentIsBranch, branchVersion } from 'selectors/project'

import secure from 'hocs/secure'
import withProject from 'hocs/withProject'
import branchCheckpointsQuery from 'api/queries/branchCheckpoints.graphql'

import MakeCheckpoint from 'components/Project/MakeCheckpoint'
import openRevisionAction from '../../actions/project/openRevision'
import ShortTimestamp from '../Commons/Time/ShortTimestamp'
import MakeBranch from '../Project/MakeBranch'
import { ModalConsumer } from '../Commons/Modal'

import globalStyles from 'styles/globals.scss'
import DeleteRevisionButton from './DeleteRevisionButton'
import styles from './RevisionSelector.scss'

const { SubMenu } = Menu

const RevisionsMenu = ({ hasAccess, selectCheckpoint, isVisible, showMakeCheckpointModal, showMakeBranchModal, onDeletePressed, checkpoints = [], isBranch, currentBranchId, currentRevisionId }) => (
  <ModalConsumer>
    {({ showModal }) => (
      <Menu
        className={classNames(globalStyles.topBarMenu, styles.revisionDropdownOverlay)}
        theme={Themes.dark}
        visible={isVisible}
        selectedKeys={currentRevisionId ? [currentRevisionId] : null}
      >
        <div className={styles.title}>revision</div>
        <SubMenu
          popupClassName={classNames(styles.subMenu, globalStyles.topBarSubMenu)}
          title="Switch to checkpoint"
        >
          <Menu.Item key={currentBranchId} onClick={() => selectCheckpoint({ _id: currentBranchId })}>
            latest
            {currentRevisionId === currentBranchId && (
              <Icon type="check" />
            )}
          </Menu.Item>
          <Menu.Divider />
          {checkpoints.length === 0 && (
            <Menu.Item key="no-checkpoints" disabled>No Checkpoints</Menu.Item>
          )}
          {checkpoints.map(checkpoint => (
            <Menu.Item key={checkpoint._id} onClick={() => selectCheckpoint(checkpoint)} className={styles.checkpointItem}>
              {checkpoint.version} <ShortTimestamp date={checkpoint.createdAt} />
              {currentRevisionId === checkpoint._id && (
                <Icon type="check" />
              )}
              {currentRevisionId !== checkpoint._id && (
                <DeleteRevisionButton id={checkpoint._id} onPressed={onDeletePressed} />
              )}
            </Menu.Item>
          ))}
        </SubMenu>
        <Menu.Divider />
        <Menu.Item
          disabled={!isBranch || !hasAccess}
          onClick={showMakeCheckpointModal(showModal)}>
            Make checkpoint...
        </Menu.Item>
        <Menu.Item
          disabled={!hasAccess}
          onClick={showMakeBranchModal(showModal)} >
          Make version...
        </Menu.Item>
      </Menu>
    )}
  </ModalConsumer>
)

export default compose(
  withProject,
  secure('hasAccess', projectWriteAccess, ['project']),
  connect(state => ({
    currentBranchId: currentBranchIdSelector(state),
    currentRevisionId: revisionId(state),
    currentVersion: branchVersion(state),
    isBranch: currentIsBranch(state),
  }),
  {
    openRevision: openRevisionAction
  }),
  graphql(branchCheckpointsQuery, {
    name: 'checkpointsQuery',
    options: ({ currentBranchId }) => ({
      variables: { branchId: currentBranchId },
    }),
    skip: ({ currentBranchId }) => !currentBranchId
  }),
  withPropsOnChange(
    ['checkpointsQuery'],
    ({ checkpointsQuery }) => ({
      checkpoints: !checkpointsQuery || checkpointsQuery.loading ? EMPTY_ARRAY : (!checkpointsQuery.error ? checkpointsQuery.branchCheckpoints : EMPTY_ARRAY)
    })
  ),
  withHandlers({
    showMakeCheckpointModal: ({ checkpointsQuery, closeMenu, currentVersion }) => showModal => () => {
      showModal(MakeCheckpoint, { currentVersion, checkpointsQuery })
      closeMenu()
    },
    showMakeBranchModal: ({ checkpointsQuery, closeMenu, currentVersion, isBranch }) => showModal => () => {
      showModal(MakeBranch, { currentVersion, isBranch, checkpointsQuery })
      closeMenu()
    },
    selectCheckpoint: ({ openRevision, closeMenu }) => async checkpoint => {
      try {
        await openRevision(checkpoint._id)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error loading revision', error)
      }
      closeMenu()
    },
    onDeletePressed: ({ closeMenu }) => () => {
      closeMenu()
    }
  })
)(RevisionsMenu)