import { Types } from 'actions/ui'
import uuid from 'uuid/v4'
import { assocPath, dissocPath, pickAll, set, lensProp, assoc } from 'ramda'
import { getDefaultGamepad, inferGamepadType } from 'utils/gamepad'
import { isRevisionChangedAction, isRevisionClearedAction } from '../actions/project'
import GamePadNotification from '../components/GamePad/GamePadNotification'
import { CLEAR_ACTION, SELECT_ACTION } from 'actions/selection'

const {

  EDIT_START,
  _EDIT_END,

  CONFIRM_ACTION,

  ACTION_CONFIRMED,

  START_BLOCKING_TASK,
  END_BLOCKING_TASK,

  DOWNLOAD_START,
  INNER_DOWNLOAD_COMPLETED,

  SET_DEBUG,

  GAMEPAD_CONNECTED,
  GAMEPAD_DISCONNECTED,

  SET_GLOBAL_SEARCH_VISIBLE,

  SET_PROPERTIES_CONFLICT_DIALOG,
  CLEAR_PROPERTIES_CONFLICT_DIALOG
} = Types

const gamepad = getDefaultGamepad()

const initialState = {
  editingNode: undefined,
  confirmDialogs: {},
  downloads: {},
  debug: false, // turn-on manually here
  ...gamepad && {
    gamepad: {
      type: inferGamepadType(gamepad)
    }
  },

  globalSearchVisible: false,

  propertiesConflictDialog: null //  when paste objects properties has name collisions
}

const projectChangedOrCleared = (state, action) =>
  (isRevisionChangedAction(action) || isRevisionClearedAction(action) ? initialState : state)

export function ui(state = initialState, action) {
  switch (action.type) {
    case SELECT_ACTION:
    case CLEAR_ACTION: return projectChangedOrCleared(state, action)

    case EDIT_START: return assoc('editingNode', pickAll(['id', 'path'], action), state)
    case _EDIT_END: return assoc('editingNode', undefined, state)

    case CONFIRM_ACTION: {
      const newDialog = { [uuid()]: { message: action.message, actionFn: action.actionFn } }
      return { ...state, confirmDialogs: { ...state.confirmDialogs, ...newDialog } }
    }
    case ACTION_CONFIRMED: {
      const confirmDialogs = { ...state.confirmDialogs }
      delete confirmDialogs[action.id]
      return { ...state, confirmDialogs }
    }

    case START_BLOCKING_TASK: return set(lensProp('blockingTask'), action.task)(state)
    case END_BLOCKING_TASK: return dissocPath(['blockingTask'])(state)

    case SET_DEBUG: return set(lensProp('debug'), action.onOff)(state)

    // TODO: test
    case DOWNLOAD_START: return assocPath(['downloads', action.id], {
      ...pickAll(['path', 'query', 'description', 'id'])(action)
    })(state)
    case INNER_DOWNLOAD_COMPLETED: return dissocPath(['downloads', action.id])(state)

    // GAMEPADS
    case GAMEPAD_CONNECTED: {
      const type = inferGamepadType(action.gamepad)
      if (type) {
        GamePadNotification({
          title: 'Gamepad Connected!',
          message: `Recognized as ${type}`,
        })
        return assocPath(['gamepad'], { type })(state)
      }
    }
    case GAMEPAD_DISCONNECTED: {
      GamePadNotification({
        title: 'Gamepad Disconnected!',
        message: 'Connect again if you want to use it',
      })
      return dissocPath(['gamepad'])(state)
    }

    // global SET_GLOBAL_SEARCH_VISIBLE
    case SET_GLOBAL_SEARCH_VISIBLE: {
      return assoc('globalSearchVisible', action.visible)(state)
    }

    case SET_PROPERTIES_CONFLICT_DIALOG: {
      return assoc('propertiesConflictDialog', { targets: action.targets, clipboard: action.clipboard })(state)
    }

    case CLEAR_PROPERTIES_CONFLICT_DIALOG: {
      return assoc(['propertiesConflictDialog'], null)(state)
    }

    default: return state
  }
}