import React from 'react'
import { connect } from 'react-redux'
import { push as pushAction } from 'connected-react-router'
import { Button, Alert } from 'antd'
import DangerZone, { Item } from 'components/Commons/DangerZone/DangerZone'
import { ModalConsumer } from 'components/Commons/Modal'
import DeleteOrganizationModal from './DeleteOrganizationModal'

import styles from './OrganizationSettings.scss'

const OrganizationSettings = ({ organizationName, organization, push }) => (
  <div className={styles.OrganizationSettings}>
    <DangerZone>
      <Item
        title="Delete Organization"
        paragraph={`
          Deleting an organization requires that you previously delete all of its projects.
          Once deleted there is no turning back, and you will need to create a new one.
          It will also remove any user memberships and teams.
        `}
      >
        <ModalConsumer>
          {({ showModal }) => (
            <Button
              type="danger"
              onClick={() => showModal(DeleteOrganizationModal, {
                onDeleted: () => push('/'),
                organizationName,
                organization
              })}
              disabled={organization && organization.counters.projects > 0}
            >
              Delete
            </Button>
          )}
        </ModalConsumer>
        {organization && organization.counters.projects > 0 &&
          <Alert message={`You cannot delete this organization because it has ${organization.counters.projects} projects ! Delete them first`} type="warning" />
        }
      </Item>
    </DangerZone>
  </div>
)

export default connect(
  null,
  {
    push: pushAction
  }
)(OrganizationSettings)