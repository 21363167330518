import React from 'react'
import { connect } from 'react-redux'
import { compose, withStateHandlers, withHandlers, lifecycle } from 'recompose'
import { Dropdown, Menu, Icon, Checkbox, Tooltip } from 'antd'
import { identity, propEq } from 'ramda'
import { ALL } from 'preferences/Preferences'
import { makePreferenceSelector } from 'selectors/view'
import { updatePreference as updatePreferenceAction } from 'actions/view'

import { toggleElement } from 'utils/list'

const DropDownButton = ({ icon, description, all, ...rest }) => (all.length === 0 ? <Icon type={icon} /> : (
  <Dropdown overlay={OptionsMenu({ all, ...rest })} trigger={['click']}>
    <Tooltip title={description}>
      <Icon type={icon} />
    </Tooltip>
  </Dropdown>
))
const OptionsMenu = ({ all, optionKey = o => o, renderOption = o => o, onOptionChanged, indeterminate, checkAll, onCheckAllChanged, isChecked }) => (
  <Menu>
    <Menu.Item key={ALL}>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChanged}
        checked={checkAll}
      >
      All</Checkbox>
    </Menu.Item>
    <Menu.Divider key="all-divider" />
    {all.map(option => (
      <Menu.Item key={optionKey(option)}>
        <Checkbox checked={isChecked(option)} onChange={() => onOptionChanged(option)}>{renderOption(option)}</Checkbox>
      </Menu.Item>
    ))}
  </Menu>
)

export default compose(
  connect(() => {
    const selector = makePreferenceSelector('preference')
    return (state, props) => ({
      currentValue: selector(state, props),
      all: props.allSelector(state)
    })
  }, (dispatch, { preference }) => ({
    updatePreference: value => dispatch(updatePreferenceAction(preference, value))
  })),
  withStateHandlers(
    ({ all, currentValue }) => computeInternalState(all, currentValue),
    {
      recomputeCurrentState: (_, { all, currentValue }) => () => computeInternalState(all, currentValue),

      onOptionChanged: ({ checkedList: prevCheckedList, checkAll, optionKey = identity }, { all, updatePreference }) => option => {
        const allKeys = all.map(optionKey)
        const selectedKey = optionKey(option)
        
        const checkedList = toggleElement(selectedKey)(checkAll ? allKeys : prevCheckedList)
        const willAllCheck = checkedList.length === allKeys.length
        updatePreference(willAllCheck ? ALL : checkedList)
        return {
          checkedList,
          checkAll: willAllCheck,
          indeterminate: !!checkedList.length && (checkedList.length < allKeys.length),
        }
      },

      onCheckAllChanged: ({ checkAll: prevCheckAll, optionKey = identity }, { all, updatePreference }) => () => {
        const checkAll = !prevCheckAll
        const allKeys = all.map(optionKey)
        updatePreference(checkAll ? ALL : [])
        return {
          checkedList: checkAll ? allKeys : [],
          indeterminate: false,
          checkAll
        }
      }
    }
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.currentValue !== this.props.currentValue) {
        this.props.recomputeCurrentState()
      }
    }
  }),
  withHandlers({
    isChecked: ({ checkAll, checkedList }) => option => checkAll || checkedList.some(propEq('name', option.name))
  })
)(DropDownButton)


const computeInternalState = (all, currentValue) => ({
  checkedList: currentValue === ALL ? all : currentValue,
  indeterminate: false,
  checkAll: currentValue === ALL
})
