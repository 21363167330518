import React from 'react'

// TODO: this is from figma to deleted evicted results we need a lot of work  there.
// import IconButton from 'components/Commons/IconButton'

const SearchResultControls = ({ className }) => (
  <div className={className}>
    {/* <IconButton type="close" /> */}
  </div>
)

export default SearchResultControls