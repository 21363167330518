import React from 'react'
import { applySpec } from 'ramda'
import { connect } from 'react-redux'
import { Icon, Tooltip } from 'antd'
import classnames from 'classnames'

import { keyMap, shortcutOf } from 'shortcuts/keymap'
import { PlaybackStatus } from 'model/constants'
import { playbackStatus as playbackStatusSelector, isPlayPauseResumeEnabled } from 'selectors/playback'
import { Creators } from 'actions/vm'

import styles from './PlaybackControls.scss'

/* eslint import/no-webpack-loader-syntax: 0 */
import PlayIcon from '-!svg-react-loader!./icons/play.svg'
import PauseIcon from '-!svg-react-loader!./icons/pause.svg'
import ResumeIcon from '-!svg-react-loader!./icons/resume.svg'

const { playPauseResumeToggle: playPauseResumeToggleAction } = Creators

const DELETE = 'delete'

const icon = status => {
  switch (status) {
    case PlaybackStatus.PLAYING: return PauseIcon
    case PlaybackStatus.STOPPED:
    case PlaybackStatus.INITIAL: return PlayIcon
    case PlaybackStatus.PAUSED: return ResumeIcon
    default: return DELETE
  }
}

const text = status => {
  switch (status) {
    case PlaybackStatus.PLAYING: return 'Pause'
    case PlaybackStatus.STOPPED:
    case PlaybackStatus.INITIAL: return 'Play'
    case PlaybackStatus.PAUSED: return 'Resume'
    default: return DELETE
  }
}

const PlayPauseResumeButton = ({ isEnabled, playbackStatus, playPauseResumeToggle, className }) => (
  <Tooltip title={`${text(playbackStatus)} (${shortcutOf(keyMap.TreeView.PLAY)})`}>
    <Icon
      component={icon(playbackStatus)}
      onClick={playPauseResumeToggle}
      className={classnames(className, { [styles.disabled]: !isEnabled })}
    />
  </Tooltip>
)

export default connect(
  applySpec({
    playbackStatus: playbackStatusSelector,
    isEnabled: isPlayPauseResumeEnabled
  }),
  { playPauseResumeToggle: playPauseResumeToggleAction }
)(PlayPauseResumeButton)
