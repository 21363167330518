import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { isDebuggable } from 'selectors/debuggingData'

import styles from './DebugIcon.scss'

/**
 * The pure presentational component
 */
export const DebugIcon = ({ className, debuggable }) => {
  return debuggable ? (
    <div className={classNames({ [styles.debugIcon]: debuggable }, className)} />
  ) : null
}

/**
 * The connected one that computes "debuggable" with the store
 */
const ConnectedDebugIcon = ({ nodeId }) => {
  const selector = useMemo(() => isDebuggable(nodeId), [nodeId])
  const debuggable = useSelector(selector)

  return debuggable ? <DebugIcon debuggable={debuggable} /> : null
}

ConnectedDebugIcon.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  nodeId: PropTypes.string.isRequired
}

export default ConnectedDebugIcon