import React from 'react'
import { Icon, notification } from 'antd'
/* eslint import/no-webpack-loader-syntax: 0 */
import GamepadIcon from '-!svg-react-loader!./gamepad.svg'

const open = ({ title, message }) => {
  notification.open({
    icon: <Icon component={GamepadIcon} />,
    message: title,
    description: message,
  })
}

export default open