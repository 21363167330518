import React, { useCallback } from 'react'
import classNames from 'classnames'
import { batch } from 'react-redux'
import { Key } from 'utils/keyboard'

import Controls from './Controls'

import styles from './SearchWalkPopover.scss'

/**
 * Common component between searching and walking. It is the floating search panel
 * which is a generalization.
 */
const SearchWalkPopover = ({ className, active, icon, content, controls, onClose, dropDownContent }) => {
  const onKeyDown = useCallback(e => {
    if (e.which === Key.ESC) {
      batch(() => {
        onClose()
      })
      e.preventDefault()
      e.stopPropagation()
    }
  }, [onClose])
  return (
    <div className={classNames(styles.SearchWalkPopover, { [styles.hidden]: !active }, className)} onKeyDown={onKeyDown}>
      {active && (
        <React.Fragment>
          <div className={classNames(styles.primaryContent, { [styles.showingDropDown]: !!dropDownContent })}>
            <div className={styles.content}>
              {icon}
              {content}
            </div>
            <Controls className={styles.controls} controls={controls} onClose={onClose} />
          </div>
          {dropDownContent && (
            <div className={styles.secondaryContent}>
              {dropDownContent}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default SearchWalkPopover