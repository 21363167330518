import { Tooltip } from 'antd'
import React, { useContext, useMemo } from 'react'
import IconButton from '../Commons/IconButton'
import { FactDrawerContext } from '../FactDrawer/FactDrawerContext'

/**
 *
 */
const EditFactButton = ({ fact, onEditFact }) => {
  const { open, isOpen } = useContext(FactDrawerContext)

  const drawerProps = useMemo(() => ({
    fact,
    title: 'Edit Fact',
    okButtonLabel: 'Save',
    onAccept: onEditFact
  }))

  return (
    <Tooltip mouseEnterDelay={0.5} title="Edit Fact">
      <IconButton
        type="edit"
        onClick={() => { open(drawerProps) }}
        disabled={isOpen}
      />
    </Tooltip>
  )
}

export default EditFactButton