import { clearSelectedNodes } from 'actions/nodes'
import { navigateToProject } from 'actions/routing'
import { Creators as VMCreators } from 'actions/vm'

const { resetPlayback } = VMCreators

/**
 * This must be THE way to load a revision.
 * It changes the route, which triggers updating the selected revision, which in
 * turn triggers loading the revision
 */
const openRevision = (revisionId, match) => dispatch => {
  dispatch(clearSelectedNodes())
  dispatch(resetPlayback())
  // previous two steps are required to avoid text editor breaking when openingRevision with clip selected or some playback state on
  dispatch(navigateToProject(revisionId, match))
}

export default openRevision