import React from 'react'

import SecureContext from 'components/Commons/SecureContext'
import { compose, shallowEqual, withPropsOnChange } from 'recompose'
import omitProps from 'hocs/omitProps'

const SecureScope = ({ children, scope }) => (
  <SecureContext.Consumer>
    {parentScope => (
      <MergedScopeMemoizing parentScope={parentScope} childScope={scope}>
        {children}
      </MergedScopeMemoizing>
    )}
  </SecureContext.Consumer>
)

const _InnerScope = ({ children, scope }) => (
  <SecureContext.Provider value={scope}>
    {children}
  </SecureContext.Provider>
)

const MergedScopeMemoizing = compose(
  withPropsOnChange(
    (prev, next) => !shallowEqual(prev.parentScope, next.parentScope) || !shallowEqual(prev.childScope, next.childScope),
    ({ parentScope, childScope }) => ({
      scope: { ...parentScope, ...childScope }
    })
  ),
  omitProps(['parentScope', 'childScope'])
)(_InnerScope)

export default SecureScope