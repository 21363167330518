import { propEq } from 'ramda'
import { markUpParams, markupNameNode } from '../../markupUtils'
import { newMarkUpWithCustomNodes, emptyArgument } from '../../slateMocks/markUp'
import { cloneNodes, cloneNode } from '../../slateMocks/clone'
import { NODE_TYPES } from '../../Constants'
import { isTheLastListItem } from '../Markup/markupListValuePlugin'
import { findDescendentsOfType } from '../../nodeUtils'

export const isLastValue = (editor, markUp, currentValueKey) => {
  const lastParameter = editor.getInlinesByTypeRecursively(markUpParams(markUp), NODE_TYPES.MARK_UP_PARAMETER).last()
  const { key } = editor.getInlinesByTypeRecursively(lastParameter, NODE_TYPES.MARK_UP_PARAMETER_VALUE).first()
  return currentValueKey === key
}

export const parameterByIndex = (markup, index) => 
  (findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER)(markup))[index]

export const parameterIndexByNode = (editor, markup, node) => {
  const param = editor.findAncestorOfType(node, NODE_TYPES.MARK_UP_PARAMETER)
  if (param) {
    const elements = findDescendentsOfType(NODE_TYPES.MARK_UP_PARAMETER)(markup)
    return elements.findIndex(propEq('key', param.key))
  }
  return -1
}

export const firstMarkUpKey = (editor, path) => {
  const markup = editor.blockByPath(path)
  const firstParameter = editor.getInlinesByTypeRecursively(markUpParams(markup), NODE_TYPES.MARK_UP_PARAMETER).first()
  return editor.getInlinesByTypeRecursively(firstParameter, NODE_TYPES.MARK_UP_PARAMETER_KEY).first()
}

export const lastParameter = (editor, path) => {
  const newMarkUp = editor.blockByPath(path)
  return editor.getInlinesByTypeRecursively(markUpParams(newMarkUp), NODE_TYPES.MARK_UP_PARAMETER).last()
}

export const lastMarkUpKey = (editor, path) => editor.getInlinesByTypeRecursively(lastParameter(editor, path), NODE_TYPES.MARK_UP_PARAMETER_KEY).first()
export const lastMarkUpValue = (editor, path) => editor.getInlinesByTypeRecursively(lastParameter(editor, path), NODE_TYPES.MARK_UP_PARAMETER_VALUE).first()

export const markUpWithNewParam = editor => markUp => {
  const nameNode = cloneNode(markupNameNode(markUp))
  const params = editor.getInlinesByTypeRecursively(markUpParams(markUp), NODE_TYPES.MARK_UP_PARAMETER)
  const paramNodes = [...cloneNodes(params), emptyArgument()]
  return newMarkUpWithCustomNodes(nameNode, paramNodes)
}

export const hasParams = markUp =>
  markUp.nodes.filter(propEq('type', NODE_TYPES.MARK_UP_WITH_PARAMS)).size > 0

export const isAtEndOfMarkUpNameOrParam = (node, editor) => (
  node.type === NODE_TYPES.MARK_UP_NAME || 
  node.type === NODE_TYPES.MARK_UP_PARAMETER_VALUE ||
  (node.type === NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT && isTheLastListItem(editor, node))) && 
  editor.focusIsAtEndOfNode(node)

export const isAtTheEndOfParameterKey = (node, editor) => 
  node.type === NODE_TYPES.MARK_UP_PARAMETER_KEY && editor.focusIsAtEndOfNode(node)