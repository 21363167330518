import { push } from 'connected-react-router'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { searchAction } from '../../../actions/nodes/search/search'

import { Creators as WalkListCreators } from 'actions/walklist'

/**
 *
 */
const useWalkAction = makeSearch => {
  const dispatch = useDispatch()

  return useCallback((...args) => {
    dispatch(push('../edit/'))

    const aSearch = makeSearch(...args)

    const result = dispatch(searchAction(aSearch))
    dispatch(WalkListCreators.walkList(aSearch, result))
  }, [dispatch])
}

export default useWalkAction