import React from 'react'

import { Input } from 'antd'
import CreateModal from 'components/Commons/CreateModal'
import { compose, withStateHandlers, withHandlers } from 'recompose'
import { organizationUpdate } from 'api/mutations/organizationUpdate.graphql'
import { withTargetValue } from 'utils/antd'
import { orgWriteAccess } from 'security/organization'
import { graphql } from 'react-apollo'
import secure from 'hocs/secure'

const RenameOrgModal = ({ name, setName, onUpdated, hide, updateName }) => (
  <CreateModal title="Change the organization name" hide={hide} canSubmit submit={updateName} onCreated={onUpdated}>
    {onCreate => (<Input placeholder="Organization name" value={name} onChange={withTargetValue(setName)} onPressEnter={onCreate} />)}
  </CreateModal>
)

export default compose(
  secure('hasWriteAccess', orgWriteAccess),
  graphql(organizationUpdate, { name: 'changeNameMutation' }),
  withStateHandlers(
    ({ organization }) => ({ name: organization.name }),
    { setName: prev => name => ({ ...prev, name }) }
  ),
  withHandlers({
    updateName: ({ organization, name, changeNameMutation }) => async () => {
      await changeNameMutation({
        variables: {
          input: {
            organizationId: organization._id,
            name
          }
        }
      })
    }
  })
)(RenameOrgModal)