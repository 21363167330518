import platform from 'utils/platform'
import { nodeIdFor, TREE_VIEW_DOM_ID } from 'dom/dom'
import { propFrom } from 'utils/object'
import domStyles from 'dom/dom.scss'

// I decided to put here the configuration of keyboard navigation in the app.
// I thought it would be better to see in a glance all together than to have to
// navigate all the code

export const TabIndexes = {
  Layout: {
    projectSelector: 0,
    logout: 1,
    searchElement: 2
  }
}

export const KeyLocation = {
  LEFT: 1,
  RIGHT: 2
}

export const Key = {
  TAB: 9,
  ESC: 27,
  ENTER: 13,
  SPACE: 32,
  BACKSPACE: 8,
  // modifiers
  SHIFT: 16,
  ALT: 18,
  CTRL: 17,
  CMD: 91,
  HOME: 36,
  END: 35,
  // ARROWS
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,

  SEMI_COLON: 186,

  OPEN_BRACKET: 219,
  CLOSE_BRACKET: 221,

  // chars
  D: 68,
  E: 69,
  S: 83,
  Z: 90
}

export const EventKey = {
  COMMA: ',',
  DOUBLE_QUOTES: '"'
}

const keyCodesHumanReadable = {
  0: 'That key has no keycode',
  3: 'break',
  8: 'backspace / del',
  9: 'tab',
  12: 'clear',
  13: 'enter',
  16: 'shift',
  17: 'ctrl',
  18: platform.isMac ? 'option' : 'alt',
  19: 'pause/break',
  20: 'caps lock',
  21: 'hangul',
  25: 'hanja',
  27: 'escape',
  28: 'conversion',
  29: 'non-conversion',
  32: 'spacebar',
  33: 'page up',
  34: 'page down',
  35: 'end',
  36: 'home',
  37: '←',
  38: '↑',
  39: '→',
  40: '↓',
  41: 'select',
  42: 'print',
  43: 'execute',
  44: 'Print Screen',
  45: 'insert',
  46: 'delete',
  47: 'help',
  48: '0',
  49: '1',
  50: '2',
  51: '3',
  52: '4',
  53: '5',
  54: '6',
  55: '7',
  56: '8',
  57: '9',
  58: ':',
  59: 'semicolon (firefox), equals',
  60: '<',
  61: 'equals (firefox)',
  63: 'ß',
  64: '@ (firefox)',
  65: 'a',
  66: 'b',
  67: 'c',
  68: 'd',
  69: 'e',
  70: 'f',
  71: 'g',
  72: 'h',
  73: 'i',
  74: 'j',
  75: 'k',
  76: 'l',
  77: 'm',
  78: 'n',
  79: 'o',
  80: 'p',
  81: 'q',
  82: 'r',
  83: 's',
  84: 't',
  85: 'u',
  86: 'v',
  87: 'w',
  88: 'x',
  89: 'y',
  90: 'z',
  91: platform.isMac ? '⌘' : '⊞',
  92: 'right window key',
  93: platform.isMac ? '⌘' : '≣',
  95: 'sleep',
  96: 'numpad 0',
  97: 'numpad 1',
  98: 'numpad 2',
  99: 'numpad 3',
  100: 'numpad 4',
  101: 'numpad 5',
  102: 'numpad 6',
  103: 'numpad 7',
  104: 'numpad 8',
  105: 'numpad 9',
  106: 'multiply',
  107: 'add',
  108: 'numpad period (firefox)',
  109: 'subtract',
  110: 'decimal point',
  111: 'divide',
  112: 'f1',
  113: 'f2',
  114: 'f3',
  115: 'f4',
  116: 'f5',
  117: 'f6',
  118: 'f7',
  119: 'f8',
  120: 'f9',
  121: 'f10',
  122: 'f11',
  123: 'f12',
  124: 'f13',
  125: 'f14',
  126: 'f15',
  127: 'f16',
  128: 'f17',
  129: 'f18',
  130: 'f19',
  131: 'f20',
  132: 'f21',
  133: 'f22',
  134: 'f23',
  135: 'f24',
  144: 'num lock',
  145: 'scroll lock',
  160: '^',
  161: '!',
  162: '؛ (arabic semicolon)',
  163: '#',
  164: '$',
  165: 'ù',
  166: 'page backward',
  167: 'page forward',
  168: 'refresh',
  169: 'closing paren (AZERTY)',
  170: '*',
  171: '~ + * key',
  172: 'home key',
  173: 'minus (firefox), mute/unmute',
  174: 'decrease volume level',
  175: 'increase volume level',
  176: 'next',
  177: 'previous',
  178: 'stop',
  179: 'play/pause',
  180: 'e-mail',
  181: 'mute/unmute (firefox)',
  182: 'decrease volume level (firefox)',
  183: 'increase volume level (firefox)',
  186: 'semi-colon / ñ',
  187: 'equal sign',
  188: 'comma',
  189: 'dash',
  190: 'period',
  191: 'forward slash / ç',
  192: 'grave accent / ñ / æ / ö',
  193: '?, / or °',
  194: 'numpad period (chrome)',
  219: 'open bracket',
  220: 'back slash',
  221: 'close bracket / å',
  222: 'single quote / ø / ä',
  223: '`',
  224: 'left or right ⌘ key (firefox)',
  225: 'altgr',
  226: '< /git >, left back slash',
  230: 'GNOME Compose Key',
  231: 'ç',
  233: 'XF86Forward',
  234: 'XF86Back',
  235: 'non-conversion',
  240: 'alphanumeric',
  242: 'hiragana/katakana',
  243: 'half-width/full-width',
  244: 'kanji',
  251: 'unlock trackpad (Chrome/Edge)',
  255: 'toggle touchpad',
};

export const getHumanReadableCode = propFrom(keyCodesHumanReadable)

export const UP_CODE = 'ArrowUp'
export const DOWN_CODE = 'ArrowDown'
export const LEFT_CODE = 'ArrowLeft'
export const RIGHT_CODE = 'ArrowRight'

const isHorizontal = dir => dir === RIGHT_CODE || dir === LEFT_CODE
const isVertical = dir => dir === UP_CODE || dir === DOWN_CODE
const rectToPoint = ({ x, y }) => ({ x, y })

export const isCMDorCTRL = event => (platform.isMac && event.metaKey) || (!platform.isMac && event.ctrlKey)

export const isAltKeyPressed = event => event.altKey
export const isAltKeyOnly = event => event.altKey && !isCMDorCTRL(event) && !event.shiftKey

export const isSaveShortCut = event => isCMDorCTRL(event) && event.keyCode === Key.S

export const getNextNodeMovement = (code, currentId) => {
  if (!currentId) { return undefined }
  
  const nodes = document.querySelector(`#${TREE_VIEW_DOM_ID}`).querySelectorAll(`.${domStyles.node}`)
  const currentNode = document.querySelector(`#${nodeIdFor(currentId)}`)
  
  const nextOne = selectNextElement(code, nodes, currentNode)

  return nextOne && nextOne.dataset.nodeId
}

export const matchesStrictly = (event, key) => {
  const { keyCode, shiftKey, altKey, ctrlKey } = event
  return keyCode === key && !altKey && !shiftKey && !ctrlKey
}


export const isUndo = event => (platform.isMac ? event.metaKey : event.ctrlKey) && (event.keyCode === Key.Z)

const distance = (pointA, pointB) => [pointA.x - pointB.x, pointA.y - pointB.y]
const getPoint = node => rectToPoint(node.getBoundingClientRect())

/* eslint no-continue: 0 */
const selectNextElement = (dir, nodes, currentNode) => {
  const currentPoint = getPoint(currentNode)

  let bestNode = null
  let bestX = Number.MAX_SAFE_INTEGER
  let bestY = Number.MAX_SAFE_INTEGER

  for (const node of nodes) {
    if (node === currentNode) { continue }
    const [distX, distY] = distance(getPoint(node), currentPoint)

    if (Math.abs(distY) > 100) { continue }

    // moving left/right
    if (isHorizontal(dir)) {
      if (Math.abs(distY) > Math.abs(bestY) || // anything closer in y is already better
          distX === 0 || // but anything we are directly in line with isn't moving left or right!
          (dir === RIGHT_CODE && distX < 0) ||
          (dir === LEFT_CODE && distX > 0)) {
        continue
      }

      // Closer in y is always a winner 
      if (Math.abs(distY) < Math.abs(bestY)) {
        bestX = distX
        bestY = distY
        bestNode = node
      } else if ((dir === RIGHT_CODE && (distX < bestX)) || (dir === LEFT_CODE && (distX > bestX))) {
        bestX = distX
        bestNode = node
      }
    } else if (isVertical(dir)) {
      if (Math.abs(distX) > Math.abs(bestX) || // anything closer in x is already better
          distY === 0 || // but anything we are directly in line with isn't moving up or down!
          (dir === UP_CODE && (distY > 0)) ||
          (dir === DOWN_CODE && (distY < 0))) {
        continue
      }    

      // Closer in x is always a winner
      if (Math.abs(distX) < Math.abs(bestX)) {
        bestY = distY
        bestX = distX
        bestNode = node
      } else if ((dir === UP_CODE && (distY > bestY)) || (dir === DOWN_CODE && (distY < bestY))) {
        bestY = distY
        bestNode = node
      }
    }
  }

  return bestNode
}

const isMoveTo = dirKeyCode => event => !event.metaKey && !event.shiftKey && event.keyCode === dirKeyCode

export const isMoveToRight = isMoveTo(Key.RIGHT)
export const isMoveToLeft = isMoveTo(Key.LEFT)

const isMoveOrExpandTo = dirKeyCode => event => !event.metaKey && event.keyCode === dirKeyCode

export const isMoveOrExpandToRight = isMoveOrExpandTo(Key.RIGHT)
export const isMoveOrExpandToLeft = isMoveOrExpandTo(Key.LEFT)