import { groupBy, prop } from 'ramda'
import React, { useCallback, useMemo } from 'react'
import { Icon, Menu } from 'antd'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { Themes } from 'utils/antd'

import { isSuperAdmin } from 'security/platform'

import { ModalConsumer } from 'components/Commons/Modal'
import UserName from 'components/Commons/UserName'
import ShortcutsModal from 'components/ShortcutsModal/ShortcutsModal'
import bindUnrealSession from '../../actions/project/session/bindUnrealSession'
import destroyRevisionSession from '../../actions/project/session/destroyRevisionSession'
import SessionClientType, { origin, sessionTypeToLabel } from '../../model/constants/SessionClientType'
import { otherSessionsFromUs, ourUnrealSession } from '../../selectors/sessions/sessions'

import BroadcastModal from '../BroadcastModal/BroadcastModal'

import globalStyles from 'styles/globals.scss'
import IconButton from '../Commons/IconButton'
import RelativeTime from '../Commons/RelativeTime'
import styles from './CurrentUserInfo.scss'

const { SubMenu } = Menu

/**
 * User dropdown menu (top-right)
 */
const CurrentUserDropDownMenu = ({ user, isUs, push, goToPlatformView, logout }) => {
  const gotoHelp = useCallback(() => { window.open('https://docs.brunerhouse.com/') }, [])

  const devicesSection = devices()

  return (
    <ModalConsumer>
      {({ showModal }) => (
        <Menu className={classNames(styles.userMenu, globalStyles.topBarMenu)} theme={Themes.dark}>
          <Menu.Item key="user-name" disabled className={styles.UserNameTitle}>
            <UserName user={user} />
          </Menu.Item>

          {devicesSection}

          <Menu.Divider />

          <Menu.Item key="home" onClick={() => push('/')}>
            <Icon type="home" />
            Go to homepage
          </Menu.Item>

          <Menu.Divider />

          <Menu.Item key="keyboardShortcuts" disabled={false} onClick={ShortcutsModal}>
            <Icon type="tag-o" />
            <span>Keyboard Shortcuts</span>
          </Menu.Item>
          <Menu.Item onClick={gotoHelp}><Icon type="question" />Help / Documentation</Menu.Item>
          <Menu.Divider />

          {isUs && isSuperAdmin(user) &&
            [
              <Menu.Item key="manage-platform" onClick={goToPlatformView}>
                <Icon type="project" />
                Manage Platform
              </Menu.Item>,
              <Menu.Item key="broadcast" onClick={() => showModal(BroadcastModal)}>
                <Icon type="message" />
                Broadcast
              </Menu.Item>,
              <Menu.Divider key="div-admin" />
            ]
          }

          <Menu.Item key="logout" onClick={logout}>
            <Icon type="logout" />
            Log out
          </Menu.Item>
        </Menu>
      )}
    </ModalConsumer>
  )
}

const devices = () => {
  const otherSessions = useSelector(otherSessionsFromUs)
  const sessionsByClientType = useMemo(() => groupBy(prop('clientType'), otherSessions), [otherSessions])
  const dispatch = useDispatch()
  const deleteSession = useCallback(session => {
    dispatch(destroyRevisionSession(session))
  }, [dispatch])

  const unrealBoundSession = useSelector(ourUnrealSession)
  const bindSession = useCallback((event, session) => {
    if (!unrealBoundSession || session._id !== unrealBoundSession._id) {
      dispatch(bindUnrealSession(session))
    }
  }, [dispatch, unrealBoundSession])

  return (
    <SubMenu
      title="Devices"
      popupClassName={classNames(globalStyles.subMenu, globalStyles.topBarSubMenu)}
    >
      {Object.entries(sessionsByClientType).map(([clientType, sessions]) => (
        <Menu.ItemGroup key={clientType} title={sessionTypeToLabel(clientType)}>
          {sessions.map(session => (
            <Menu.Item key={session._id} className={classNames(styles.DeviceItem, { [styles.bound]: unrealBoundSession && unrealBoundSession._id === session._id })}>
              <div className={styles.DeviceTitle}>
                <div>
                  {
                    session.clientType === SessionClientType.Unreal.code &&
                    <IconButton type="check" className={styles.bindButton} onClick={e => bindSession(e, session)} />
                  }
                </div>
                <div>
                  <div>
                    {origin(session)}
                    <span className={styles.SessionId}>{session._id.slice(-5)}</span>
                  </div>
                  <div className={styles.DeviceBottomLine}>
                    <RelativeTime date={session.createdAt} />
                  </div>
                </div>
                <div>
                  <IconButton type="delete" onClick={() => deleteSession(session)} />
                </div>
              </div>
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      ))}
      {otherSessions.length === 0 && (
        <Menu.Item key="no-devices" disabled>
          No Connected Devices
        </Menu.Item>
      )}
    </SubMenu>
  )
}

export default CurrentUserDropDownMenu