import { T } from 'ramda'
import { isMultipleSelection } from 'selectors/nodeSelection'
import { EMPTY_ARRAY, toArray } from 'utils/object'
import { isEnabledInScope } from 'actions/keyboard'

const getParamsFromStateAndEvent = (params, state, event) => {
  const selectors = params ? toArray(params) : EMPTY_ARRAY
  return selectors.map(fn => fn(state, event))
}

// This has a similar code in MenuItemCommand to enable/disabled the item
// that's ok, just warning here
export const isEnabled = (command, params, state) =>
  (command.enabledSelector || T)(state)
  && (command.enabled || T)(...params, state)

export const dispatchCommand = (command, event, ...args) => (dispatch, getState) => {
  const params = args && args.length > 0 ? args : getParamsFromStateAndEvent(command.params, getState(), event)
  if (!isEnabled(command, params, getState())) return

  const action = command.action(...params)
  return action && dispatch(action)
}

// TODO: test me
const BIND_OPTS = { ignoreArgs: false }
export const bindCommand = (command, { ignoreArgs } = BIND_OPTS) => (...args) => dispatchCommand(command, null, ...ignoreArgs ? EMPTY_ARRAY : args)


//
// Reusable fns from components
//

const isVisibleByCommand = (command, params, state) => !command.visible || command.visible(...params, state)
const isVisibleBySelection = (state, singleSelectionOnly, multipleSelectionOnly) => {
  const isMultiple = isMultipleSelection(state)
  return (!singleSelectionOnly && !multipleSelectionOnly)
    || (singleSelectionOnly && !isMultiple)
    || (multipleSelectionOnly && isMultiple)
}

export const isVisible = (command, params, state, scope, singleSelectionOnly, multipleSelectionOnly) => (
  isVisibleBySelection(state, singleSelectionOnly, multipleSelectionOnly)
  && isVisibleByCommand(command, params, state)
  && isEnabledInScope(command, scope)
)