import React, { useEffect, useMemo, useRef } from 'react'
import Prompt from './Prompt'

/**
 * The current line
 */
const ConsolePrompt = ({ label, argument, point, value }) => {
  const cursorRef = useRef()

  const actualLabel = useMemo(() => (
    argument ? textAfter('\n', label, '') : label), [label, argument]
  )

  // make it blink periodically
  const updateSemaphore = useRef(0)
  useEffect(() => {
    if (!cursorRef.current) return;
    if (updateSemaphore === 0) {
      cursorRef.current.className = 'react-console-cursor'
    }
    updateSemaphore.current++
    window.setTimeout(() => {
      updateSemaphore.current--
      if (updateSemaphore.current === 0 && cursorRef.current) {
        cursorRef.current.className = 'react-console-cursor react-console-cursor-idle'
      }
    }, 1000)
  }, [label, argument, point, value])

  return (
    <div className="react-console-prompt-box">
      <span className="react-console-prompt-label">{actualLabel}</span>
      <span className="react-console-prompt-argument">{argument}</span>
      <span className="react-console-prompt">
        <Prompt
          cursorRef={cursorRef}
          point={point}
          value={value}
        />
      </span>
    </div>
  )
}
ConsolePrompt.defaultProps = {
  point: -1,
  value: '',
  label: '> ',
  argument: null,
}

export default ConsolePrompt

const textAfter = (separator, text, defaultV) => {
  const idx = text.lastIndexOf(separator)
  return idx >= 0 ? text.substring(0, idx + 1) : defaultV
}