import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { notification } from 'antd'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'

import { parseGraphQLErrors } from 'utils/graphql'
import { deleteNodes } from 'engine/actions/actions'
import { getTimeAsString } from 'utils/dates'
import { ModalConsumer } from '../Commons/Modal'
import CreateModal from '../Commons/CreateModal'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'
import ExtensionPoint from '../ExtensionPoints/ExtensionPoint'

import IconButton from 'components/Commons/IconButton'

import styles from './ObjectsList.scss'

const DeleteObjectModal = ({ doDelete, hide, object: { sys, id } }) => (
  <CreateModal
    title={`Are you sure that you want to delete this ${sys} object ?`}
    titleIcon="warning"
    hide={hide}
    submit={doDelete}
    canSubmit>
    {() => (
      <p>{`You are going to delete the ${sys} object with id ${id}`}</p>
    )}
  </CreateModal>
)

const ObjectsListRowActions = ({ doDelete, hasWriteAccess, object }) => (
  <ModalConsumer>
    {({ showModal }) => (<div className={styles.ObjectActions}>
      {hasWriteAccess && <IconButton
        type="delete"
        tooltip="Delete"
        onClick={() => showModal(DeleteObjectModal, { doDelete, object })} />}
      <ExtensionPoint
        className={styles.ExtensionPoint}
        point={EXTENSION_POINTS.OBJECT_ACTIONS}
        renderExtension={({ id, label, action: extensionAction, icon }, { moreProps, extAsBneAction }) => (
          <IconButton key={id} tooltip={label} type={icon} onClick={extAsBneAction(extensionAction, object)} {...moreProps} />
        )}
      />
    </div>)}
  </ModalConsumer>
)

export default compose(
  secure('hasWriteAccess', isCurrentRevisionWritable),
  connect(null, {
    deleteNodesAction: deleteNodes
  }),
  withHandlers({
    doDelete: ({ object, deleteNodesAction }) => async () => {
      try {
        await deleteNodesAction([object])
      } catch (err) {
        notification.error({
          key: getTimeAsString(),
          message: 'Error',
          description: `Could not delete object: ${parseGraphQLErrors(err)}`
        })
      }
    }
  })
)(ObjectsListRowActions)