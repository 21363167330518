exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".QueueManagement__2J3Or{width:100%}.QueueManagement__2J3Or .header__2XigJ{padding:1rem}.QueueManagement__2J3Or .header__2XigJ .headerButtons__1pPv0{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;font-size:1.3rem;width:100%;-ms-flex-pack:center;justify-content:center}.QueueManagement__2J3Or .header__2XigJ .headerButtons__1pPv0>*{margin:0 3rem}.QueueManagement__2J3Or .queues__1W2w_{padding:2rem;display:grid;-webkit-column-gap:2.5rem;-moz-column-gap:2.5rem;column-gap:2.5rem;row-gap:2.5rem;grid-template-columns:1fr 1fr 1fr}.QueueManagement__2J3Or .queues__1W2w_ .cardShadow__2TP9L{-webkit-box-shadow:10px 10px 12px -9px rgba(0,0,0,.61);box-shadow:10px 10px 12px -9px rgba(0,0,0,.61)}.QueueManagement__2J3Or .queues__1W2w_ .count__261Uw{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding:.5rem 3rem}.QueueManagement__2J3Or .queues__1W2w_ .count__261Uw .badge__co-dN sup.ant-badge-count{background-color:silver;color:#fefefe;font-weight:bold}", ""]);

// exports
exports.locals = {
	"QueueManagement": "QueueManagement__2J3Or",
	"header": "header__2XigJ",
	"headerButtons": "headerButtons__1pPv0",
	"queues": "queues__1W2w_",
	"cardShadow": "cardShadow__2TP9L",
	"count": "count__261Uw",
	"badge": "badge__co-dN"
};