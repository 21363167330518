import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { notification } from 'antd'
import { Sys } from 'beanie-engine-api-js'

import { parseGraphQLErrors } from 'utils/graphql'
import videoJobCreateMutation from 'api/mutations/videoJobCreate.graphql'

import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'

import { selectedNodeObject } from 'selectors/selectors'

import Button from 'components/Commons/Button'

import styles from './TextEditorControlButton.scss'

const isDisabled = selectedNode => !(selectedNode && selectedNode.sys === Sys.clip)

const SyncVideoButton = ({ hasWriteAccess, selectedNode, handleSyncVideo, spinning }) => (
  <Button
    type="video-camera"
    description="Create video for the clip"
    onClick={handleSyncVideo}
    spin={spinning}
    disabled={!hasWriteAccess || isDisabled(selectedNode)}
    className={!hasWriteAccess || isDisabled(selectedNode) ? styles.disabled : styles.enabled}
  />
)

export default compose(
  connect(state => ({
    selectedNode: selectedNodeObject(state),
  })),
  graphql(videoJobCreateMutation, { name: 'videoJobCreate' }),
  withState('spinning', 'setSpinning', false),
  withHandlers({
    handleSyncVideo: ({ selectedNode, videoJobCreate, setSpinning, selectedNode: { project, id: clipId } }) => async () => {
      try {
        if (isDisabled(selectedNode)) return
        setSpinning(true)
        await videoJobCreate({ variables: { input: { revisionId: project, clipId, autoStart: true } } })
        notification.info({ message: 'Video synchronization', description: 'Enqueued correctly' })
      } catch (e) {
        notification.error({
          message: 'Video synchronization',
          description: `Could not queue video job: ${parseGraphQLErrors(e)}`
        })
      } finally {
        setSpinning(false)
      }
    },
  }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
)(SyncVideoButton)