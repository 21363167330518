import React from 'react'
import { Icon } from 'antd'
import { connect } from 'react-redux'

import { Creators as EngineCreators } from '../../actions/engine'

import { isReady as isReadySelector } from '../../selectors/player'

import styles from './VideoPlayerPlaceholder.scss'

const VideoPlayerPlaceholder = ({ onPlay, isReady }) => (
  <div className={styles.placeholder}>
    {isReady &&
      <Icon type="play-circle" theme="filled" onClick={onPlay} />
    }
  </div>
)

export default connect(state => ({
  isReady: isReadySelector(state)
}), ({
  onPlay: EngineCreators.onStartGame
})
)(VideoPlayerPlaceholder)