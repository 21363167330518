import { DO_WITH_STATE_TYPE } from './testActions'

/**
 * Wraps the original store reducer to handle the "DO_WITH_STATE" action.
 * For that one it just uses the given "fn" from the action to mutate the state bypassing all reducers.
 * For any other it just goes with the original reducer.
 * Util to arbitrary modify any part of the state.
 */
const testReducerWrapper = reducer => (state, action) => (
  action?.type === DO_WITH_STATE_TYPE ? action.fn(state) : reducer(state, action)
)

export default testReducerWrapper