import React from 'react'
import { pathOr } from 'ramda'

import { EMPTY_ARRAY } from 'utils/object'
import { model } from 'beanie-engine-api-js'

// REVIEWME: if we use { _RowCell } like a "lite" version of the component
// then dragging preview gets offset and doesn't look good
// This whole dragging preview customization is completely fragile. We should
// come up with a better impl
import RowCell from '../../row/RowCell'

const { types: { object: { Paths } } } = model

/**
 *
 */
const DraggingRow = ({ truthTable, rowIndex, row }) => {
  const cells = pathOr(EMPTY_ARRAY, Paths.truth_table_row.cells, row)
  return (
    <tbody>
      <tr>
        {cells.map((cell, i) => (
          <RowCell
            key={`${rowIndex}-${i}`}
            rowIndex={rowIndex}
            columnIndex={i}
            truthTable={truthTable}
            node={row}
            cell={cell}
          />
        ))}
      </tr>
    </tbody>
  )
}

export default DraggingRow