import now from 'performance-now'
import { nthArg } from 'ramda'
import Stats from './timing/Stats'
// import winston from 'winston'

window.selectorsStats = new Stats()

export const recordElapsedTime = (fn, name = fn.name) => (...args) => {
  const start = now()
  const result = fn(...args)
  window.selectorsStats.record(name, now() - start, args)
  return result
}
/** @deprecated use #recordElapsedTime() */
export const logElapsedTime = recordElapsedTime

export const logPromiseElapsedTime = (fn, name = fn.name) => async (...args) => {
  const start = now()
  const result = await fn(...args)
  const elapsed = (now() - start)
  /* eslint no-console: 0 */
  console.log(`${name}() took: ${elapsed.toFixed(3)}`)
  return result
}

const _measureDevelopment = (name, fn) => logElapsedTime(fn, name)
const _measureProduction = nthArg(1)
export const measure = process.env.NODE_ENV === 'production' ? _measureProduction : _measureDevelopment
