import createQueryContext from '../../query/context/createQueryContext'
import { createObjectResult } from '../../searches'
import queryToPredicate from '../../query/queryToPredicate'

/**
 * Returns a Collector function which evaluated with an object it will generate and push
 * an SearchObjectResult through @pushResult
 *
 * This collector knows how to interpret FindSearch's by compiling the SearchQuery into a JS
 * predicate through #queryToPredicate.
 */
const findCollectorFactory = ({ query }, getState, pushResult) => {
  const predicate = queryToPredicate(query)
  const context = createQueryContext(getState)

  return (object, node) => {
    if (predicate(object, node, context)) {
      pushResult(createObjectResult(object, node))
    }
  }
}

export default findCollectorFactory