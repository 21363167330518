/* eslint no-console: 0 */


const middleware = () => next => action => {

  if (action.type) {
    console.log('[redux-action] ', action.type)
  } else {
    console.log('[redux-action] FN', action.name || action.toString())
  }

  return next(action)
}

export default middleware