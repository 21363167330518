import React, { useMemo } from 'react'
import classNames from 'classnames'

// order could matter (https://github.com/securingsincity/react-ace/issues/725#issuecomment-543109155)
import Ace from 'react-ace'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/theme-eclipse'

// lua support
import 'ace-builds/src-noconflict/mode-lua'
// eslint-disable-next-line import/no-webpack-loader-syntax
import luaWorker from 'ace-builds/src-noconflict/worker-lua'
// eslint-disable-next-line no-undef
ace.config.setModuleUrl('ace/mode/lua_worker', luaWorker)

// NOTE: we must add more crazy imports like this to have support
// for other modes and languages
// the worker is the "checker" hard time making it work but this is the way

import styles from './AceEditor.scss'

/**
 * Uses Cloud9 Ace's editor (https://ace.c9.io/)
 * But encapsulates its usage from the whole app so we can upgrade, tune etc.. in a single place
 */
const AceEditor = ({
  id,
  placeholder,
  value,
  onChange,
  className,
  height,
  width,
  autoComplete,
  mode,
  readOnly,
  onBlur,
  onLoad,
  focus,
  commands,
  enableLiveAutocompletion,
  enableBasicAutocompletion,
  enableSnippets
}) => {
  const opts = useMemo(() => ({
    editorProps: { $blockScrolling: true },
    ...autoComplete && {
      setOptions: {
        enableBasicAutocompletion: true,
        useWorker: true,
        // enableLiveAutocompletion: true,
        // enableSnippets: true
      }
    }
  }), [])

  return (
    <Ace
      className={classNames(styles.AceEditor, className)}
      // defaults we want to be consistent in the whole system
      theme="eclipse"
      editorProps={opts.editorProps}
      setOptions={opts.setOptions}
      tabSize={2}

      // customizations / props
      mode={mode}
      name={id}
      
      readOnly={readOnly}
      
      height={height}
      width={width}
      
      defaultValue={placeholder}
      value={value}
      onChange={onChange}
      onLoad={onLoad}

      focus={focus}
      onBlur={onBlur}
      commands={commands}

      enableLiveAutocompletion={enableLiveAutocompletion}
      enableBasicAutocompletion={enableBasicAutocompletion}
      enableSnippets={enableSnippets}
    />
  )
}

export default AceEditor