import React from 'react'
import { compose, withHandlers, onlyUpdateForKeys } from 'recompose'
import { connect } from 'react-redux'
import SplitPane from 'react-split-pane'
import { path } from 'ramda'

import withShortcuts from 'hocs/withShortcuts'
import withErrorHandler from 'hocs/withErrorHandler'

import { EDIT_PROJECT_SECTION_DOM_ID } from 'dom/dom'
import CurrentNodePlayback from 'components/PlaybackView/CurrentPlayback/CurrentNodePlayback'
import MainEditorPanel from 'components/MainEditorPanel/MainEditorPanel'
import BottomPanel from 'components/BottomPanel/BottomPanel'

import WalkListPopover from 'components/WalkList/WalkListPopover/WalkListPopover'
import GlobalSearchPopover from 'components/WalkList/WalkListPopover/GlobalSearchPopover/GlobalSearchPopover'
import FactDrawerProvider from '../FactDrawer/FactDrawerContext'

import RightPanel from './RightPanel'
import ErrorPanel from './ErrorPanel'

import { panes as panesSelector } from 'selectors/view'

import { resizePane } from 'actions/view'

import './SplitPanel.css'
import styles from './EditProjectSection.scss'
import Namespaces from 'shortcuts/Namespaces'

const horizontalMinimized = path(['horizontal', 'minimized'])
const horizontalSize = path(['horizontal', 'size'])
const verticalMinimized = path(['vertical', 'minimized'])
const verticalSize = path(['vertical', 'size'])

/**
 *
 */
const EditProjectSection = ({ panes, onResizeHorizontalPane, onResizeVerticalPane }) => {
  return (
    <div id={EDIT_PROJECT_SECTION_DOM_ID} className={styles.panel}>
      <div className={styles.content}>
        <FactDrawerProvider>
          <GlobalSearchPopover />
          <WalkListPopover />
          <SplitPane
            split="horizontal"
            style={{ position: 'relative' }}

            onDragFinished={onResizeHorizontalPane}

            allowResize={!horizontalMinimized(panes)}
            size={horizontalMinimized(panes) ? 'calc(100% - 50px)' : horizontalSize(panes)}
            pane2Style={{ ...panes.horizontal.minimized && { maxHeight: 'fit-content' } }}
          >
            <SplitPane
              split="vertical"
              size={verticalMinimized(panes) ? 'calc(100% - 50px)' : verticalSize(panes)}
              allowResize={!verticalMinimized(panes)}
              onDragFinished={onResizeVerticalPane}
            >
              <MainEditorPanel />
              <RightPanel panes={panes} />
            </SplitPane>

            <BottomPanel />
          </SplitPane>
          <CurrentNodePlayback />
        </FactDrawerProvider>
      </div>
    </div>
  )
}

const onResize = pane => ({ resizePaneAction }) => size => resizePaneAction(pane, size)

export default compose(
  // we don't need anything from the router (match | location)
  onlyUpdateForKeys([]),
  withShortcuts({ namespace: Namespaces.Project.TreeView }),
  withShortcuts({ namespace: Namespaces.Project.Edit, targetNodeSelector: 'body' }),
  connect(
    state => ({
      panes: panesSelector(state)
    }),
    ({
      resizePaneAction: resizePane,
    })
  ),
  withHandlers({
    onResizeVerticalPane: onResize('vertical'),
    onResizeHorizontalPane: onResize('horizontal')
  }),
  withErrorHandler(ErrorPanel, true),
  onlyUpdateForKeys(['panes'])
)(EditProjectSection)
