import React from 'react'
import { graphql } from 'react-apollo'
import { Modal, Form, Input, Checkbox, Button } from 'antd'
import inviteCreate from 'api/mutations/inviteCreate.graphql'
import { Errors } from 'components/Commons/Error'
import { parseGraphQLErrors } from 'utils/graphql'

import { Roles } from 'utils/authentication'

import userInvites from 'api/queries/userInvites.graphql'
import { EMPTY_ARRAY } from 'utils/object'

class CreateInviteForm extends React.Component {
  state = {
    confirmDirty: false,
  }

  handleConfirmBlur = e => {
    const { value } = e.target
    this.setState({ confirmDirty: this.state.confirmDirty || !!value })
  }

  render() {
    const { onCreate, onCancel, form, visible, loading, errors, admin, toggle } = this.props
    const FormItem = Form.Item
    const { getFieldDecorator } = form

    const required = { required: true, message: 'required.' }
    return (<Modal confirmLoading={loading} onCancel={onCancel} onOk={onCreate} title="Invite User" visible={visible} okText="Invite">
      <Form layout="vertical">
        <FormItem label="E-mail">
          {getFieldDecorator('email', {
            rules: [{ type: 'email', message: 'not a valid email.' }, required],
          })(
            <Input />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('admin')(
            <Checkbox checked={admin} onChange={toggle}>Give administrator privileges</Checkbox>
          )}
        </FormItem>
        {errors && errors.length > 0 &&
          <Errors items={errors} />
        }
      </Form>
    </Modal>
    )
  }
}

const WrappedForm = Form.create()(CreateInviteForm)

@graphql(inviteCreate, { refetchQueries: [userInvites] })
export default class CreateInvite extends React.Component {
  state = {
    loading: false,
    visible: false,
    giveAdminPrivileges: false
  }
  form = React.createRef()

  toggleAdminPrivileges = () => {
    this.setState({ giveAdminPrivileges: !this.state.giveAdminPrivileges })
  }

  setVisible = visible => { this.setState({ visible }) }
  openModal = () => { this.form.current.resetFields(); this.setVisible(true) } 
  closeModal = () => { this.setVisible(false) } 

  render() {
    const { visible, giveAdminPrivileges, loading, errors } = this.state
    return (
      <div>
        <Button type="primary" icon="mail" onClick={this.openModal}>Invite</Button>
        <WrappedForm
          ref={this.form}
          visible={visible}
          onCancel={this.closeModal}
          onCreate={this.perform}
          toggle={this.toggleAdminPrivileges}
          admin={giveAdminPrivileges}
          loading={loading}
          errors={errors}
        />
      </div>
    )
  }

  perform = () => {
    const { form } = this
    form.current.validateFields((err, values) => {
      if (err) return
      const { mutate, onCreated } = this.props
      this.setState({ loading: true })
      const { email, admin } = values

      mutate({ variables: { input: { email, roles: admin ? [Roles.ADMIN] : EMPTY_ARRAY } } })
        .then(() => {
          this.setState({ loading: false, errors: [] })
          form.current.resetFields()
          this.setState({ visible: false })
          this.closeModal()
          onCreated()
        })
        .catch(error => {
          this.setState({ loading: false, errors: parseGraphQLErrors(error) })
        })
    })
  }

}
