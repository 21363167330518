
export const SyncDirection = { // (sounds like a boys-band mashup)
  OUTBOUND: 'outbound', // our change being synchronized to the server
  INBOUND: 'inbound' // anyone else's change being synchronized to our browser
}

export const SYNC_STARTED = 'SYNC_STARTED'
export const syncStartedFor = (operationId, direction, ...objectsOrIds) => ({
  type: SYNC_STARTED,
  operationId,
  direction,
  objects: objectsOrIds.map(o => o.id || o)
})
export const SYNC_FINISHED = 'SYNC_FINISHED'
export const syncFinishedFor = (operationId, direction) => ({
  type: SYNC_FINISHED,
  direction,
  operationId,
})
export const SYNC_ENQUEUE = 'SYNC_ENQUEUE'
export const syncEnqueue = changeSet => ({
  type: SYNC_ENQUEUE,
  changeSet
})
export const SYNC_DEQUEUE = 'SYNC_DEQUEUE'
export const syncDequeue = () => ({ type: SYNC_DEQUEUE })
