import React from 'react'
import { connect } from 'react-redux'
import { compose, withStateHandlers, lifecycle, withHandlers, withState } from 'recompose'
import { assocPath, pipe, when } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { getRelativeElement } from 'utils/list'
import classNames from 'classnames'

/* eslint import/no-named-as-default: 0 */
import TextViewItem, { _TextViewItem as SimpleTextViewItem } from './TextViewItem'

import { currentChain as currentChainSelector, collectChain } from 'selectors/paths'
import { currentLane as currentLaneSelector, selectedLaneRootIds } from 'selectors/lanes'
import { objectsIndex } from 'selectors/apollo'

import { selectNode } from 'actions/nodes'
import { Direction } from './CursorInfo'

import styles from './TextView.scss'

const _TextView = ({ chain, cursorInfo, lane, changeSelectedRoot, outsideClassName, insideClassName, moveToNextEditor, moveToSpecificEditor, moveToPreviousEditor }) => (
  <div className={classNames(styles.outside, outsideClassName)}>
    <div className={classNames(styles.inside, insideClassName)}>
      {lane && <SimpleTextViewItem object={lane} />}
      {chain.map((id, i) => (
        <TextViewItem
          key={id}
          id={id}
          moveSelectedRoot={changeSelectedRoot}
          
          cursorInfo={cursorInfo}
          moveToSpecificEditor={moveToSpecificEditor}
          moveToNextEditor={lineOffset => { moveToNextEditor(i, lineOffset) }}
          moveToPreviousEditor={lineOffset => { moveToPreviousEditor(i, lineOffset) }}
        />
      ))}
    </div>
  </div>
)

const moveToEditor = delta => ({ chain, setCursorInfo }) => (currentIndex, lineOffset) => {
  const newIndex = currentIndex + delta
  if (newIndex >= 0 && newIndex < chain.length) {
    setCursorInfo({
      cursorAt: chain[newIndex],
      direction: delta > 0 ? Direction.DOWN : Direction.UP,
      lineOffset
    })
  }
}

const moveToSpecificEditor = ({ chain, setCursorInfo }) => newId => {
  if (chain.cursorAt !== newId) {
    setCursorInfo({
      cursorAt: newId,
      direction: Direction.UP,
      // TODO
      lineOffset: 0,
    })
  }
}

const TextView = compose(
  withStateHandlers(({ items }) => ({ chain: items }), {
    changeSelectedRoot: ({ chain }, { changeRootFromChain }) => delta => ({ chain: changeRootFromChain(chain, delta) }),
    updateChain: () => chain => ({ chain })
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { items, updateChain } = this.props
      if (prevProps.items !== items) {
        updateChain(items)
      }
    }
  }),

  // cursor
  withState('cursorInfo', 'setCursorInfo', ({ chain }) => ({ cursorAt: chain.length > 0 ? chain[0] : undefined, direction: 'down' })),
  withHandlers({
    moveToSpecificEditor,
    moveToNextEditor: moveToEditor(+1),
    moveToPreviousEditor: moveToEditor(-1),
  })
)(_TextView)

export default compose(
  connect(state => ({
    items: currentChainSelector(state),
    lane: pipe(currentLaneSelector, when(isNotNil, assocPath(['sys'], 'lane')))(state)
  }), ({
    changeRootFromChain: ([currentRoot], delta) => (dispatch, getState) => {
      const state = getState()
      const rootIds = selectedLaneRootIds(state)
      const newRootId = getRelativeElement(rootIds, currentRoot, delta)
      dispatch(selectNode(newRootId))
      return collectChain(objectsIndex(state))(newRootId)
    }
  })
  )
)(TextView)