import { NODE_TYPES } from '../../Constants'
import { one, SPACE } from './../schema-utils'
import { markUpWithParamsNormalize } from '../normalizers/markUp'

export default {
  nodes: [
    SPACE,
    {
      match: { type: NODE_TYPES.MARK_UP_NAME },
      ...one
    },
    SPACE,
    {
      match: { type: NODE_TYPES.MARK_UP_PARAMETERS },
      ...one
    },
    SPACE,
  ],
  normalize: markUpWithParamsNormalize
}