exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "h2 .anticon{margin-right:1rem;cursor:pointer}.errorIcon__1Smgc{color:#f67a7a;margin-left:.4rem}.filterToolbar__3QSCX{padding-bottom:1rem}.filterToolbar__3QSCX>*:not(:last-child){margin-right:1rem}", ""]);

// exports
exports.locals = {
	"errorIcon": "errorIcon__1Smgc",
	"filterToolbar": "filterToolbar__3QSCX"
};