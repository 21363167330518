import { message } from 'antd'
import { ref, model } from 'beanie-engine-api-js'
import { pathOr, values } from 'ramda'

import { canPasteOnLane } from 'selectors/clipboard'
import pasteNodes from 'model/operations/paste/pasteNodes'
import { projectObject } from 'selectors/objects'
import { laneCopyName } from 'selectors/lanes'
import { objectsIndex } from 'selectors/apollo'
import { revisionId, selectedRevisionId } from 'selectors/selectors'
import { getFromJson } from 'utils/clipboard'
import { EMPTY_ARRAY } from 'utils/object'
import { ClipboardContentType } from 'model/app/clipboard/clipboard'
import { acquireNodeId } from '../../model/operations/BNECopyPasteHelper'
import { appendRootToLane } from './lanes'

const { types: { node: { canBeRoot, isStrictNode } }, factory: { objects: { build, withChild, marker } } } = model

import winston from 'utils/logger'

const pasteOnLane = lane => async (dispatch, getState) => {
  const state = getState()
  const project = projectObject(state)

  if (!canPasteOnLane(state)) return

  const clipboardContent = await getFromJson()

  switch (clipboardContent.contentType) {
    case ClipboardContentType.Lane:
      return dispatch(_pasteLane(project, clipboardContent))
    case ClipboardContentType.Nodes:
      return dispatch(_pasteNodesIntoLane(project, clipboardContent, lane))
    default: throw new Error(`Unknown clipboard content ${clipboardContent.contentType}`)
  }
}

export default pasteOnLane

const _pasteLane = (project, clipboardContent) => async (dispatch, getState, { synchronizer }) => {
  const state = getState()
  const index = objectsIndex(state)
  const revId = revisionId(state)
  const { lane, chainClipboards } = clipboardContent

  const pastingByChain = await Promise.all(chainClipboards.map(
    clipboardContentForChain => pasteNodes(clipboardContentForChain, index, revId)
  ))

  await synchronizer.doSynchingBNE(
    'Paste Lane',
    api => {
      const newRootIds = []

      pastingByChain.forEach(({ copies, headId }) => {
        newRootIds.push(headId)
        api.createObjects(...values(copies))
      })

      api.update(project.id, {
        editor: {
          lanes: [
            ...pathOr(EMPTY_ARRAY, ['data', 'editor', 'lanes'], project),
            {
              name: laneCopyName(lane)(state),
              roots: newRootIds
            },
          ]
        }
      })
    }
  )
}

const _pasteNodesIntoLane = (project, clipboardContent, lane) => async (dispatch, getState, { synchronizer }) => {
  const { content, headId } = clipboardContent
  if (!isStrictNode(content[headId])) {
    return
  }

  const index = objectsIndex(getState())
  const revId = selectedRevisionId(getState())
  const { copies, headId: newHeadId } = pasteNodes(clipboardContent, index, revId)
  const newHead = copies[newHeadId]

  try {
    await synchronizer.doSynchingBNE(
      'Paste as Chain',
      api => {
        // hook root to lane
        appendRootToLane(lane, getOrCreateRoot(newHead, api).id)(api, dispatch, getState)
        // paste them all
        values(copies).forEach(aCopy => {
          api.createObject(aCopy)
        })
      }
    )
  } catch (e) {
    message.error('Error pasting, nothing changed.')
    winston.error(`Error pasting. Exception message is: ${e.message}`)
    winston.error('Error pasting: Most probably your clipboard included a line from an actor that does not exist where you pasted.')
    winston.error(`Error pasting: stack trace -> ${e.stack}`)
  }

}

const makeNewRoot = (head, api) => {
  const root = build(acquireNodeId(), [marker, withChild(head.id)])
  api.createObject(root)
  head.data.parent = ref(root.id)
  return root
}
const getOrCreateRoot = (head, api) => (canBeRoot(head) ? head : makeNewRoot(head, api))