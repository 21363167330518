exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".unauthorized__214Ha{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-positive:1;flex-grow:1;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-item-align:center;align-self:center;height:100%}.unauthorized__214Ha h2{-ms-flex-preferred-size:unset;flex-basis:unset}", ""]);

// exports
exports.locals = {
	"unauthorized": "unauthorized__214Ha"
};