import React from 'react'
import { Icon } from 'antd'
import { isSuperAdminCheck } from '../../security/platform'
import Secure from '../Commons/Secure'

import styles from './ObjectTextEditorWithError.scss'

const ObjectTextEditorWithError = ({ error }) => (
  <div className={styles.objectTextEditorWithError}>
    <Icon className={styles.icon} type="exclamation-circle" theme="filled" />
    <p>Node content can not be displayed.</p>
    <p>Part of this node&apos;s saved content looks corrupted.</p>
    <p>Corrupted data may be recovered with help from an administrator.</p>

    <Secure check={isSuperAdminCheck}>
      <details>
        {error.toString()}
      </details>
    </Secure>
  </div>
)

export default ObjectTextEditorWithError
