import { flatten } from 'ramda'
import { resolve } from '../../../binding/fqn'

export default () => ({

  queries: {

    fqn: (editor, node) => flatten(editor.nodesPath(node).reduce((acc, n) => {
      if (n.data.has('fqn')) { acc.push(n.data.get('fqn')) }
      return acc
    }, [])),
  
    model: (editor, node, resolver) => {
      const fqn = editor.fqn(node)
      return resolve(resolver)(fqn.map(maybeUnwrapImmutable))
    }
    
  }
})

const maybeUnwrapImmutable = e => (e.toJS ? e.toJS() : e)