import { Sys, DEFAULT_LOCALE, model } from 'beanie-engine-api-js'
import { EMPTY_ARRAY } from 'utils/object'
import { derivedMatchSourceData, derivedSource, LABEL_MATCH_SOURCE } from './sources/MatchSource'
import MatchSourceType from './sources/MatchSourceType'

const { types: { object: { Paths } } } = model

/**
 * For the sake of clarity organizing constants and paths.
 * here we define for each type of object Paths to derived data.
 * This is used below in the `sourcesBySys` definition
 */
const DerivedPaths = {

  [Sys.line]: {
    notes: [
      Paths.line.selected_take,
      [...Paths.take.locales, DEFAULT_LOCALE], // REVIEWME: multiple-locales support
      Paths.language_resource.notes,
      ['text'],
    ]
  }

}

//
// notes
//

export const isNoteMatchResultSource = source => source.type === MatchSourceType.derived && source.name === 'notes'
// REVIEWME: this seems fragile but then any impl will be fragile. Another option would be to get the 3rd element
// or the one after "Paths.language_resource.notes"
export const getNoteIndex = ({ source: { path } }) => path.find(e => typeof e === 'number')

/**
 * Util factory for tests that need to simulate a SearchMatchResult.
 * Otherwise this "data" gets automatically created by the matchCollector by the source
 * and the line it looked into.
 */
export const createNoteSourceData = noteIndex => derivedMatchSourceData('notes', [
  Paths.line.selected_take,
  [...Paths.take.locales, DEFAULT_LOCALE],
  [...Paths.language_resource.notes],
  noteIndex,
  ['text']
])

/**
 * A declaration of where to lookup up when trying to match text
 * per type of beanie object.
 * This decouples the definition (WHAT_ from the code that knows HOW to do the lookup/matching.
 * Allowing to easily define new cases like "now for actions we want to look into `data.blah`
 *
 * This is subject to further changes. Maybe needing inheritance or a more flexible matching
 * not just the sys.
 */
const MatchSourcesBySys = {

  [Sys.line]: [

    derivedSource('notes', DerivedPaths[Sys.line].notes)

  ]
}

export default MatchSourcesBySys

/**
 * Given an object it returns a list of MatchSource objects as specs
 * of where to search.
 */
export const getMatchSourceFor = object => [
  // we always look into labels
  LABEL_MATCH_SOURCE,
  // then extra sources depending on sys
  ...(MatchSourcesBySys[object.sys] || EMPTY_ARRAY)
]