import { batch } from 'react-redux'

/**
 * Creates an object with methods that will be called by the beanie engine JS API.
 * It dynamically loads all files based on the method names.
 * If the engine JS adds a new method then you must write the string here
 * and have a file with that same name `.js` with a function with this form
 *
 *    (dispatch, getState) => (params) => { ... }
 */
const createPresenter = (dispatch, getState, engine) => [

  'onBeginExecuteNode',
  'onEndExecuteNode',
  'onBeginPresentNode',
  'onEndPresentNode',
  'getNodeExecutionStatus',
  'onPresentationEnded',
  'onPresentationError',
  'onStateEvents',

].reduce((presenter, eventName) => {
  // eslint-disable-next-line global-require,import/no-dynamic-require
  presenter[eventName] = wrapHandler(require(`./${eventName}`).default(dispatch, getState, engine))
  return presenter
}, {})

/**
 * We wrap every handler completely to run into a single redux batch transaction.
 * This is due to performance reasons. If handlers dispatch several actions then we don't
 * want the components to update on each one, but on the callback as a whole.
 */
const wrapHandler = handler => (...args) => batch(() => handler(...args))

export default createPresenter