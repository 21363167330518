exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".LoadProjectErrorModal__1RNim{width:50vw}.RevisionStaticAnalyzer__2ymDz{height:100%}.RevisionExtensions__TWhn1{height:100%}", ""]);

// exports
exports.locals = {
	"LoadProjectErrorModal": "LoadProjectErrorModal__1RNim",
	"RevisionStaticAnalyzer": "RevisionStaticAnalyzer__2ymDz",
	"RevisionExtensions": "RevisionExtensions__TWhn1"
};