import { findByNodeIds } from '../../../model/project/searches/searches'
import { selectedNodes } from '../../../selectors/nodeSelection'
import { selectNode } from '../../nodes'
import save from '../../nodes/search/history/save'
import performSearch from '../../nodes/search/performSearch'
import { createGlobalSearch } from '../../nodes/search/searchMatchingText'
import { sendCommandResponse } from '../session'
import { Creators as WalkListCreators } from 'actions/walklist'

/**
 * A registry / definition of all commands that a Studio Frontend client supports.
 * The key must match the "command name" requested by the other session.
 * The handler will get executed when we receive such an event.
 *
 * Handler signature is
 *
 *    (request: Request, dispatch, getState, context) => Promise[Void]|Void
 *
 * This is currently a pretty low-level API and base implementation.
 * For example if a command needs to send a response then you need to code
 * that from the handler (we have an action for that @sendCommandResponse).
 *
 * Eventually could create a proxy infrastructure for every piece of the architecture.
 * To be able to call a command like if it was an RPC
 *
 * The caller will do:
 *
 *     frontend.focus_node()
 *
 * Which will create the request/command
 * And the frontend here instead of coding commands could be an object with methods
 * like
 *
 *    frontend {
 *      focus_node(id) {
 *        ...
 *      }
 *
 *      // and even avoid sending the response by using the return value (might be a promise)
 *
 *      get_selected_nodes() {
 *        return selectNodes(getState())
 *      }
 *
 *    }
 *
 * But this is more work for the future.
 *
 */
const StudioFrontendCommands = {

  /**
   * Changes the currently selected node to the given.
   * Params: String id
   */
  'studio-front/focus_node': (request, dispatch) => dispatch(selectNode(request.params)),

  /**
   * Clients ask for the currently selected node
   * Params: None
   */
  'studio-front/get_selected_nodes': (request, dispatch, getState) => {
    const nodeIds = selectedNodes(getState())
    dispatch(sendCommandResponse(request, nodeIds))
  },

  //
  // SEARCH & WALK API
  //

  /**
   * The MOST basic and generic method for walk-lists.
   * Given a Search definition (a JSON data object that represents a search with find/match/composite + query objects (like an AST)).
   * it creates a search, performs it, saves it to the history and starts walking it.
   * Params:
   *   { search: Search }
   */
  'studio-front/search/walk': (request, dispatch) => {
    const { search } = request.params
    // executed it
    const { result } = dispatch(performSearch(search))
    // save it
    dispatch(save(search))
    // walk it
    dispatch(WalkListCreators.walkList(search, result))
  },

  /**
   * Triggers walking the given list of node ids and saving this search to the history.
   * Params:
   *  { title: String, ids: [Id] }
   */
  'studio-front/search/walk_nodes': (request, dispatch) => {
    const { title, ids } = request.params
    dispatch(WalkListCreators.walkNodes(title, ids))
  },


  /**
   * Creates and saves he given Search specified as the search AST.
   * Params:
   *   { search: Search }
   */
  'studio-front/search/create': (request, dispatch) => {
    const { search } = request.params
    // save it
    dispatch(save(search))
  },

  /**
   * Creates a new "match text search" and save it into the history.
   * It doesn't actually perform the search. That will be done once the user selects it from
   * the search panel
   * Params:
   *    { text: String }
   */
  'studio-front/search/create_by_text': (request, dispatch) => {
    // TODO: optionally accept a "title"
    const { text } = request.params
    // create & save a global match search
    dispatch(save(createGlobalSearch(text)))
  },

  /**
   * Creates a new search by extension (a list of ids) with a title and saves it.
   * Doesn't start walking !
   * Params:
   *     { title: String, ids: [ID] }
   */
  'studio-front/search/create_by_node_ids': (request, dispatch) => {
    const { title, ids } = request.params
    dispatch(save(findByNodeIds(title, ids)))
  },

  // ADD more here

}

// resolution
export const getCommandHandler = ({ command }) => StudioFrontendCommands[command]

export default StudioFrontendCommands