import React from 'react'

import classNames from 'classnames'

import IconButton from 'components/Commons/IconButton'

import styles from '../TextViewItem.scss'

const NavigableTextView = ({ className, hasPrevious, hasNext, moveRight, moveLeft, children }) => (
  <div className={classNames(styles.navigableItem, className)}>
    <IconButton type="left" disabled={!hasPrevious} onClick={moveLeft} />
    {children}
    <IconButton type="right" disabled={!hasNext} onClick={moveRight} />
  </div>
)

export default NavigableTextView