import React from 'react'
import { Spin } from 'antd'
import { Mutation } from 'react-apollo'

import IconButton from 'components/Commons/IconButton'
import ttsJobDelete from 'api/mutations/ttsJobDelete.graphql'

const DeleteTTSJobIcon = ({ job, onDeleted }) => (
  <Mutation mutation={ttsJobDelete}>
    {(mutate, { loading }) => (
      <Spin spinning={loading}>
        <IconButton
          type="delete"
          tooltip="Delete Job & items"
          onClick={async () => {
            await mutate({ variables: { id: job._id } })
            onDeleted(job)
          }}
        />
      </Spin>
    )}
  </Mutation>
)

export default DeleteTTSJobIcon