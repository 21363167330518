import React from 'react'
import { Popover } from 'antd'

import NodePath from 'components/Commons/NodePath/NodePath'

const NodePathPopover = ({ id, children }) => (
  <Popover
    content={<NodePath id={id} labels containers tooltip={false} />}
    visible
  >
    {children}
  </Popover>
)

export default NodePathPopover