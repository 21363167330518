import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { toBNEAction } from '../../engine/actions/utils'

/**
 * A hook to create an function that runs a beanie transaction.
 * This is the most idiomatic way to modify beanie objects from a React Component.
 * Example usage:
 *
 *   ({ object })  = {
 *     const onSetName = useTransaction(api => text => {
 *
 *      api.obj.get( object.id ).set_name( text )
 *
 *     }, [object], 'Changing Name')
 *
 *   return
 *     <input onChange={text => onSetName(text) } />
 *
 * Signature
 *
 */
const useTransaction = (actionFunction, deps, transactionTitle, opts) => {
  const dispatch = useDispatch()

  return useCallback((...args) => {
    const bneAction = toBNEAction(() => (api, _dispatch, getState) =>
      actionFunction(api, _dispatch, getState)(...args), transactionTitle, opts)
    return dispatch(bneAction())
  }, deps)
}

export default useTransaction