import React, { useMemo } from 'react'
import { Table } from 'antd'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

import { factsTreeByLane } from '../../selectors/objects/facts'

import styles from './StateFacts.scss'

const { Column } = Table

/**
 * Shows the project facts in a hierarchy based on lanes.
 * @see factsTreeByLane()
 */
const HierarchicalStateFacts = ({ renderFactName, renderFactType, renderFactValue, renderFactActions, renderFooter }) => {
  const selector = useMemo(() => factsTreeByLane(), [])
  const factsTree = useSelector(selector)

  return (
    <Table
      dataSource={factsTree}
      rowKey={laneOrFact => laneOrFact.id || laneOrFact.lane}
      size="small"
      footer={renderFooter}
      pagination={false}
      scroll={{ y: true }}
      locale={{
        emptyText: 'No Facts'
      }}

      childrenColumnName="facts"
    >
      <Column
        className={styles.nameColumn}
        title="Name"
        key="name"
        render={laneOrFact => (laneOrFact.sys ? renderFactName(laneOrFact) : (
          <div className={classNames(styles.nameContent, styles.laneLabel)}>
            {laneOrFact.lane}
            <span>({laneOrFact.facts.length})</span>
          </div>
        ))}
      />

      <Column
        className={styles.typeColumn}
        title="Type"
        key="Type"
        render={laneOrFact => (laneOrFact.sys ? renderFactType(laneOrFact) : null)}
      />

      <Column
        className={styles.valueColumn}
        title="Value"
        key="value"
        render={laneOrFact => (laneOrFact.sys ? renderFactValue(laneOrFact) : null)}
      />

      <Column
        className={styles.actionsColumn}
        title="Actions"
        key="actions"
        render={laneOrFact => laneOrFact.sys && renderFactActions(laneOrFact)}
      />
    </Table>
  )
}

export default HierarchicalStateFacts