import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose'
import { Input } from 'antd'

import CreateModal from 'components/Commons/CreateModal'
import { withTargetValue } from 'utils/antd'

import notificationBroadcastMutation from 'api/mutations/notificationBroadcast.graphql'

const { TextArea } = Input

const BroadcastModal = ({ title, setTitle, description, setDescription, hide, canSubmit, submit, onCreated }) => (
  <CreateModal title="Create Broadcast Message" hide={hide} submit={submit} canSubmit={canSubmit} onCreated={onCreated}>
    {({ onCreate }) => (
      <div>
        <Input placeholder="Title" value={title} onChange={withTargetValue(setTitle)} onPressEnter={onCreate} />
        <TextArea rows={3} placeholder="Description" value={description} onChange={withTargetValue(setDescription)} onPressEnter={onCreate} />
      </div>
    )}
  </CreateModal>
)

export default compose(
  withState('title', 'setTitle'),
  withState('description', 'setDescription'),
  withPropsOnChange(['title', 'description'], ({ title, description }) => ({
    canSubmit: !!title && !!description
  })),
  graphql(notificationBroadcastMutation, { name: 'broadcastMessage' }),
  withHandlers({
    submit: ({ broadcastMessage, title, description }) => () =>
      broadcastMessage({ variables: { input: { title, description } } })
  })
)(BroadcastModal)

