import { soundManager } from 'soundmanager2'
import { over, lensPath, path, modulo, indexOf, set, reduce, __, assocPath, mergeDeepLeft } from 'ramda'
import { renameKeys } from 'ramda-adjunct'

import { dotSplit } from 'utils/string'
import { trueFunction, falseFunction } from 'utils/functions'
import { lensPathOr } from 'utils/ramda'
import { elementFullName } from 'model/view'
import Preferences, { PreferenceScope } from 'preferences/Preferences'

import initialState, { initialPanesSizes } from './init'

import { ELEMENT_RENAMED, TOGGLE_COLLAPSE, COLLAPSE, EXPAND, SET_VOLUME, RESIZE_PANE, SET_CURRENT_TAB, MINIMIZE_SECONDARY_PANE, TOGGLE_PREFERENCE, CYCLE_PREFERENCE, UPDATE_PREFERENCE, RESET_EDIT_LAYOUT } from 'actions/view'

// state of visuals/ui/view/components that you want to
// remember between different sessions of the user.
// this gets automatically persisted and restored from the localStorage
// (see storeCreator.js)

const prefLens = ({ preference: { name, scope }, project }) => lensPath(
  ['preferences'].concat(scope === PreferenceScope.Project ? [project, name] : [name])
)
const nextPref = action => currentValue => {
  const values = path(dotSplit(action.preference.name).concat('values'), Preferences)
  return values[modulo(indexOf(currentValue, values) + 1, values.length)]
}

export function view(state = initialState, action) {
  switch (action.type) {
    // collapsing
    case TOGGLE_COLLAPSE: return setValues(action.revisionId, [action.element], toggle)(state)
    case COLLAPSE: return setValues(action.revisionId, action.elements, trueFunction)(state)
    case EXPAND: return setValues(action.revisionId, action.elements, falseFunction)(state)
    case ELEMENT_RENAMED: return renameValue(action.revisionId, action.previousName, action.newName)(state)
    // volume
    case SET_VOLUME: {
      const { value: volume } = action
      soundManager.defaultOptions = { ...soundManager.defaultOptions, volume }
      soundManager.setVolume(volume)
      return assocPath(['sound', 'volume'], volume)(state)
    }
    // resizing split pane
    case RESIZE_PANE: return updatePane(action, { size: action.size })(state)
    case SET_CURRENT_TAB: return updatePane(action, { currentTab: action.tab })(state)
    case MINIMIZE_SECONDARY_PANE: return updatePane(action, { minimized: action.minimize })(state)
    case RESET_EDIT_LAYOUT: return mergeDeepLeft(initialPanesSizes, state)

    // preferences
    case TOGGLE_PREFERENCE: return over(prefLens(action), negateOrNegateInitial(action.preference))(state)
    case CYCLE_PREFERENCE: return over(prefLens(action), nextPref(action))(state)
    case UPDATE_PREFERENCE: return set(prefLens(action), action.value)(state)

    default: return state
  }
}

const setValues = (revisionId, elements, mutator) => reduce((acc, element) => {
  const key = elementFullName(revisionId, element)
  return mutate(mutator)(key, acc)
}, __, elements)

const mutate = mutator => (key, state) => over(lensPath(['graph', 'collapsed', key]), value => mutator(key, value))(state)

const renameValue = (revisionId, previousName, nextName) => {
  const from = elementFullName(revisionId, previousName)
  const to = elementFullName(revisionId, nextName)
  return over(
    lensPath(['graph', 'collapsed']),
    renameKeys({ [from]: to })
  )
}

const negateOrNegateInitial = ({ initial }) => value => (value === undefined ? !initial : !value)

const toggle = (key, current) => !current

const updatePane = (action, values) => over(lensPathOr({}, ['panes', action.splitPanelName]), mergeDeepLeft(values))