import React from 'react'
import { connect } from 'react-redux'
import { graphql, withApollo } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { map, propEq, values } from 'ramda'
import { Table } from 'antd'

import RelativeTime from '../Commons/RelativeTime'
import UserTasksRowActions from './UserTasksRowActions'
import { UserTaskStatus, UserTaskTypes } from 'model/constants'
import { Creators as UiCreators } from 'actions/ui'
import { currentProjectUsers } from 'selectors/project'

import userTaskDeleteMutation from 'api/mutations/userTaskDelete.graphql'
import userTaskCancelMutation from 'api/mutations/userTaskCancel.graphql'
import userTaskRollbackMutation from 'api/mutations/userTaskRollback.graphql'
import userTaskOutputQuery from 'api/queries/userTaskOutput.graphql'
import withUser from 'hocs/withUser'
import SimpleAvatar from '../Commons/SimpleAvatar/SimpleAvatar'

const { Column } = Table

const asFilter = s => ({ text: s, value: s })

const userFilters = map(({ _id, firstName, lastName, name }) => ({ text: name || `${firstName} ${lastName}`, value: _id }))
const typeFilters = values(UserTaskTypes).map(asFilter)
const statusFilters = values(UserTaskStatus).map(asFilter)

const idLabel = ({ id }) => id.split('-')[1]

const UsersTasksTable = ({ userTasks: { list, paging }, pageSize, onPageChanged, handleTableChange, deleteUserTask, cancelUserTask, rollbackUserTask, userTaskDownloadOutput, projectUsers, user }) => (
  <Table
    rowKey={record => record.id}
    dataSource={list}
    pagination={({ pageSize, onChange: onPageChanged, total: paging.nrOfItems })}
    onChange={handleTableChange}
  >
    <Column
      title="Task ID"
      render={idLabel}
      key="id"
    />
    <Column
      title="User"
      sorter
      filters={userFilters(projectUsers)}
      defaultFilteredValue={[user._id]}
      dataIndex="user"
      key="user"
      render={userId => userId && <SimpleAvatar user={projectUsers.find(propEq('_id', userId))} />}
    />
    <Column
      title="Created at"
      sorter
      dataIndex="createdAt"
      key="createdAt"
      render={_ => <RelativeTime date={_} />}
    />
    <Column
      title="Type"
      sorter
      filters={typeFilters}
      dataIndex="type"
      key="type"
    />
    <Column
      title="Name"
      sorter
      dataIndex="name"
      key="name"
    />
    <Column
      title="Description"
      dataIndex="description"
      key="description"
    />
    <Column
      filters={statusFilters}
      title="Status"
      dataIndex="status"
      key="status"
    />
    <Column
      title="Actions"
      key="actions"
      render={(_, record) => (
        <UserTasksRowActions
          userTask={record}
          deleteUserTask={deleteUserTask}
          cancelUserTask={cancelUserTask}
          rollbackUserTask={rollbackUserTask}
          userTaskDownloadOutput={userTaskDownloadOutput}
        />
      )}
    />
  </Table>
)

export default compose(
  graphql(userTaskDeleteMutation, { name: 'mutateUserTaskDelete' }),
  graphql(userTaskCancelMutation, { name: 'mutateUserTaskCancel' }),
  graphql(userTaskRollbackMutation, { name: 'mutateUserTaskRollback' }),
  withApollo,
  connect(undefined, dispatch => ({
    downloadFromUrl: (id, externalUrl) => dispatch(UiCreators.downloadFile({
      fileName: `user-task-${idLabel({ id })}`,
      externalUrl,
      query: {},
      description: 'Downloading task output. Please wait, this might take a while...'
    }))
  })),
  connect(state => ({
    projectUsers: currentProjectUsers(state)
  })),
  withUser,
  withHandlers({
    deleteUserTask: ({ mutateUserTaskDelete }) => async userTaskId => {
      await mutateUserTaskDelete({ variables: { input: { userTaskId } } })
    },
    cancelUserTask: ({ mutateUserTaskCancel }) => async userTaskId => {
      await mutateUserTaskCancel({ variables: { input: { userTaskId } } })
    },
    rollbackUserTask: ({ mutateUserTaskRollback }) => async userTaskId => {
      await mutateUserTaskRollback({ variables: { input: { userTaskId } } })
    },
    userTaskDownloadOutput: ({ client, downloadFromUrl }) => async (userTask) => {
      const { data: { userTaskOutput: { signedUrl } } } = await client.query({
        query: userTaskOutputQuery,
        variables: { _id: userTask._id },
      })

      downloadFromUrl(userTask.id, signedUrl)
    }
  })
)(UsersTasksTable)
