exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2BTAU{border-radius:15px;font-size:1rem;z-index:10}", ""]);

// exports
exports.locals = {
	"container": "container__2BTAU"
};