import React from 'react'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'
import { any, equals, objOf, path, pipe, split } from 'ramda'
import { compose, withProps } from 'recompose'

import { isDev } from 'utils/browser'
import ProjectLeftMenu from 'components/ProjectLeftMenu/ProjectLeftMenu'
import BeanieHeader from 'components/Header/Header'
import Heartbeat from 'components/Heartbeat/Heartbeat'
import BlockingTaskModal from 'components/BlockingTaskModal/BlockingTaskModal'
import { Layout } from 'antd'
import NetworkStatus from 'components/NetworkStatus/NetworkStatus'

import styles from './Layout.scss'

// given props withRoute returns true only if the location pathname has any / separated part that matches "matching"
// TODO: this could be extracted to some util
const matchPathnamePart = matching => pipe(
  path(['location', 'pathname']),
  split('/'),
  any(equals(matching)),
)

const { Header, Content } = Layout

export const BeanieLayout = ({ showProjectInformation = true, computedMatch, match, contentClassName, children, realMatch = computedMatch || match, showLeftPanel = true, isInEditSection }) => (
  <Layout>
    <NetworkStatus />
    <Header className={classnames({ [styles.header]: true, [styles.devColor]: isDev() })}>
      <Heartbeat />
      <BeanieHeader projectInformation={showProjectInformation} isInEditSection={isInEditSection} />
      <BlockingTaskModal />
    </Header>
    <Content className={styles.content}>
      {showLeftPanel &&
        <div className={styles.leftPanel}>
          <ProjectLeftMenu match={realMatch} />
        </div>
      }
      <div className={classnames(styles.rightPanel, contentClassName)}>
        {children}
      </div>
    </Content>
  </Layout>
)

export default compose(
  withRouter,
  withProps(pipe(matchPathnamePart('edit'), objOf('isInEditSection'))),
)(BeanieLayout)
