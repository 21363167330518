import React from 'react'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { Icon, Popover } from 'antd'
import ReactPlayer from 'react-player'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { token as tokenSelector } from 'selectors/auth'
import { urlForVideo } from 'model/resources'

const VideoValue = ({ value, token, revisionId, visible, setVisible }) => {
  return (value && value.length > 0 ?
    (
      <span>
        {`${value}`}
        &nbsp;
        <Popover
          content={
            <div style={{ width: '200px' }}>
              <ReactPlayer
                url={urlForVideo(token, revisionId, value)}
                width="100%"
                height="90%"
                controls
              />
            </div>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}>

          <a role="link" tabIndex={0}><Icon type="video-camera" /></a>
        </Popover>
      </span>
    )
    : <span />
  )
}

export default compose(
  connect(state => ({
    revisionId: revisionIdSelector(state),
    token: tokenSelector(state)
  })),
  withState('visible', 'setVisible', false)
)(VideoValue)
