import moment from 'moment'
import {
  identity,
  path,
  prepend,
  head,
  indexBy,
  prop,
  dissocPath,
  assoc,
  mergeDeepLeft,
  over,
  lensProp,
  lensPath,
  map,
  assocPath,
  pipe,
  when,
  always,
  propEq,
  append,
  reject,
  pathOr,
  findIndex,
  update,
  lens,
  slice,
  filter,
  omit,
  defaultTo,
  not,
  hasPath,
  find,
  mergeLeft,
  ifElse,
  isNil,
  descend, sort, dissoc
} from 'ramda'
import { propNotEq } from 'ramda-adjunct'
import { lengthIsGreaterThan } from 'utils/list'
import { isRevisionChangedAction, isRevisionClearedAction, RESET_PROJECT, LOAD_REVISION_START, LOAD_REVISION_END, LOAD_REVISION_ERROR, PROJECT_FETCHED, CHANGE_SET, MARKUP_DEF_CREATED, MARKUP_DEF_EDITED, MARKUP_DEF_DELETED, MARKUP_CUSTOM_TYPE_CREATED, MARKUP_CUSTOM_TYPE_DELETED, MARKUP_CUSTOM_TYPE_EDITED, UPDATE_PROJECT_PREFERENCES, PREJECT_PREFERENCE_SET, UPDATE_PROJECT_DATA, ON_REVISION_SESSIONS_SYNCHED, BUILD_CONFIG_SET, BUILD_CONFIG_DELETE_EXT, BUILD_CONFIG_RENAME_EXT } from 'actions/project'
import { ON_SESSION_CONNECTED, ON_SESSION_DESTROYED, ON_SESSION_CONNECTING, ON_SESSION_RECONNECTING, ON_SESSION_RECONNECTED } from 'actions/project/session'

import { CLEAR_UNDO_HISTORY } from 'actions/edit'
import { SELECT_ACTION, CLEAR_ACTION } from 'actions/selection'
import { CREATE_JOB, UPDATE_TASK_STATE, UPDATE_TASK_PROGRESS, TaskState } from 'actions/tasks'
import { ANALYSIS_PROGRESS, END_ANALYSIS, START_ANALYSIS } from 'actions/staticAnalysis'
import { acronymFor } from 'utils/names'

import { ON_REVISION_SESSION_CREATED_EVENT } from '../actions/project/events/onRevisionSessionCreatedEvent'
import { ON_REVISION_SESSION_DESTROYED_EVENT } from '../actions/project/events/onRevisionSessionDestroyedEvent'
import { SET_DEBUGGING_PINS, UPDATE_DEBUGGING_PINS, ADD_DEBUGGING_PINS, DELETE_DEBUGGING_PINS, SET_DEBUG_SETTINGS, SET_SCENARIOS } from 'actions/project/debuggingData'
import { ON_UPDATE_USER_CURSOR_EVENT } from '../actions/project/events/onUpdateUserCursorEvent'
import { BIND_UNREAL_SESSION } from '../actions/project/session/bindUnrealSession'
import RevisionState from '../model/app/revision/RevisionState'
import { StaticAnalysisEnablePreference } from 'model/project/preferences'
import RevisionConnectionState from '../model/app/revision/RevisionConnectionState'
import { applyChangesToObjects } from '../model/ChangeSet'
import { EVENT_TYPE } from 'providers/Checker/Checker'
import SessionClientType from '../model/constants/SessionClientType'

import { createReducer } from './utils'
import { LOGOUT } from 'actions/login'
import { EMPTY_ARRAY, isEmptyObject } from 'utils/object'
import { UNDO_HISTORY_LIMIT } from 'model/features'
import { compose } from 'recompose'

const MARKUPS_PATH = ['revision', 'markupModel', 'markups']
const MARKUP_TYPES_PATH = ['revision', 'markupModel', 'types']

export const ANALYSIS_STATE = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
}

//
// NOTE: if state shape changes (specially where objects are stored) update reduxDevTools
//

const initialState = {
  state: RevisionState.INIT,
  connectionState: undefined,
  session: undefined,
  sessions: {}, // { [sessionId]: Session }
  cursors: {}, // { [sessionId]: nodeId }

  project: undefined,
  buildConfig: undefined,
  staticAnalysis: { // { issues: { obj: { issueHas: issue } }, state: LOADING/LOADED, progress: Int }
    state: ANALYSIS_STATE.LOADED,
    issues: {},
    progress: 100,
  },

  job: {},
  doneTasks: [],
  history: [], // [ChangeSet]
}

const debuggingPinsPath = ['revision', 'currentDebugScenarioPins']
const indexPins = indexBy(prop('nodeId'))

const defaultDebugSettings = revision => ({
  revision: {
    _id: revision._id
  },
  scenarioSelected: null,
  enabled: true
})

const _project = (state = initialState, action) => {
  switch (action.type) {
    // RESET if project changes!!! hack but well :(
    case LOGOUT: return initialState
    case RESET_PROJECT: return initialState

    // TEST_ME !!
    case SELECT_ACTION:
      //  if is the current or is loading don't do anything
      return isRevisionChangedAction(action) && ![path(['revision', '_id'], state), path(['state', 'revisionId'], state)].includes(action.value) ? initialState : state
    case CLEAR_ACTION: return isRevisionClearedAction(action) ? initialState : state;

      // LOAD

    case LOAD_REVISION_START: return pipe(
      // if we change the shape here of loading|loaded then withRevisionSubscription will break. Quiet fragile !
      assoc('state', RevisionState.LOADING(action.revision)),
      assoc('loadedAt', null),
      assoc('startedLoadingAt', new Date().toISOString())
    )

    // project objects
    case PROJECT_FETCHED: return pipe(
      assoc('project', action.revision.project),
      assoc('buildConfig', action.revision.buildConfig),
      assoc('revision', pipe(
        omit(['project', 'sessions', 'changeSets']),
        over(lensProp('debugSettings'), ifElse(isNil, () => defaultDebugSettings(action.revision), identity)),
        over(lensProp('currentDebugScenarioPins'), pipe(defaultTo(EMPTY_ARRAY), indexPins))
      )(action.revision)),
      // handle sessions & unreal binding
      s => {
        return pipe(
          // REVIEWME: maybe this must be an update/merge if we already got some session events while loading
          assoc('sessions', processSessions(s.session)(action.revision.sessions || EMPTY_ARRAY)),
          bindAnyUnrealSession
        )(s)
      },

      assoc('lastChangeSet', head(pathOr(EMPTY_ARRAY, ['changeSets', 'list'], action.revision))),
    )

    case LOAD_REVISION_END: return pipe(
      assoc('state', RevisionState.LOADED),
      assoc('loadedAt', new Date().toISOString())
    )

    case LOAD_REVISION_ERROR:
      return assoc('state', RevisionState.ERROR({ message: action.error, stack: action.stack }))

    case CREATE_JOB: return assoc('job', pipe(prop('job'), over(lensProp('tasks'), map(assoc('createdAt', moment()))))(action))

    case UPDATE_TASK_STATE: return pipe(
      over(lensPath(['job', 'tasks']),
        map(when(propEq('type', action.taskType), pipe(
          assoc('state', action.newState),
          when(always(action.newState === TaskState.running), t => ({ ...t, startedAt: moment() })),
          when(always(action.newState === TaskState.completed), t => ({ ...t, elapsedTime: moment.duration(moment().diff(t.startedAt)).asMilliseconds() })),
          when(always(action.payload), assoc('payload', action.payload))
        )))
      ),
      over(lensPath(['doneTasks']),
        when(always(action.newState === TaskState.completed), append(action.taskType))
      )
    )

    case UPDATE_TASK_PROGRESS: return over(lensPath(['job', 'tasks']),
      map(when(propEq('type', action.taskType), assoc('progress', action.progress)))
    )

      //
      // events
      //

    case ON_SESSION_CONNECTED: return pipe(
      assoc('session', action.session),
      assoc('connectionState', RevisionConnectionState.CONNECTED),
    )
    case ON_SESSION_DESTROYED: return assoc('connectionState', RevisionConnectionState.DISCONNECTED)
    case ON_SESSION_CONNECTING: return assoc('connectionState', RevisionConnectionState.CONNECTING)
    case ON_SESSION_RECONNECTING: return assoc('connectionState', RevisionConnectionState.RECONNECTING)
    case ON_SESSION_RECONNECTED: return assoc('connectionState', RevisionConnectionState.CONNECTED)

    case SET_DEBUG_SETTINGS:
      return over(lensPath(['revision', 'debugSettings']), always(action.settings))

    case SET_DEBUGGING_PINS:
      return over(lensPath(debuggingPinsPath), always(indexPins(action.pins)))

    case UPDATE_DEBUGGING_PINS:
      return over(lensPath(debuggingPinsPath), mergeLeft(indexPins(action.pins)))

    case ADD_DEBUGGING_PINS:
      return over(lensPath(debuggingPinsPath), mergeLeft(indexPins(action.pins)))

    case DELETE_DEBUGGING_PINS:
      return over(lensPath(debuggingPinsPath), omit(action.nodeIds))

    case SET_SCENARIOS:
      return over(lensPath(['revision', 'debugScenarios']), always(action.scenarios))

    // history & changes
    case CHANGE_SET: return processChangeSet(action.changeSet)
    case CLEAR_UNDO_HISTORY: return assoc('history', EMPTY_ARRAY);

      // ******************************************************
      // ** ProjectRevisionEvents (RevisionSession)
      // ******************************************************

      // sessions management & binding
    case ON_REVISION_SESSIONS_SYNCHED:
      return pipe(
        assoc('sessions', processSessions(state.session)(action.sessions || EMPTY_ARRAY)),
        // if we were bound to an unreal session that got killed then auto-bind to another one
        s => (isNil(s.boundUnrealSession) || isNil(s.sessions[s.boundUnrealSession]) ?
          bindAnyUnrealSession(s) : s
        )
      )
    case BIND_UNREAL_SESSION:
      return assoc('boundUnrealSession', action.sessionId, state)

    case ON_REVISION_SESSION_CREATED_EVENT: return pipe(
      appendSession(action.event.session),
      // try to bind if it was unreal
      s => (isNil(s.boundUnrealSession) ? bindAnyUnrealSession(s) : s)
    )
    case ON_REVISION_SESSION_DESTROYED_EVENT: {
      const id = action.event.session._id
      return pipe(
        dissocPath(['sessions', id]),
        dissocPath(['cursors', id]),
        // update unreal session if it was bound
        s => (s.boundUnrealSession === id ? pipe(dissoc('boundUnrealSession'), bindAnyUnrealSession)(s) : s)
      )
    }

    // cursors

    case ON_UPDATE_USER_CURSOR_EVENT: return pipe(
      action.event.currentObjectId ? assocPath(['cursors', action.event.session._id], action.event.currentObjectId) : dissocPath(['cursors', action.event.session._id]),
      // if we didn't know this session lets get updated !
      when(compose(not, hasPath(['sessions', action.event.session._id])), appendSession(action.event.session))
    )

      // ******************************************************
      // ** Static revision Analysis
      // ******************************************************

    case START_ANALYSIS: return assocPath(['staticAnalysis', 'state'], ANALYSIS_STATE.LOADING)
    case END_ANALYSIS: return pipe(
      assocPath(['staticAnalysis', 'state'], ANALYSIS_STATE.LOADED),
      over(lensPath(['staticAnalysis', 'issues']), issues => {
        const issuesToModify = action.replace ? {} : { ...issues }

        action.issueEvents.forEach(({ type, issue }) => {
          const { objId, issueHash } = issue

          if (!issuesToModify[objId]) { issuesToModify[objId] = {} }

          if (type === EVENT_TYPE.NEW_ISSUE) {
            issuesToModify[objId][issueHash] = issue
          } else {
            delete issuesToModify[objId][issueHash]

            if (isEmptyObject(issuesToModify[objId])) {
              delete issuesToModify[objId]
            }
          }
        })

        return issuesToModify
      }),
      assocPath(['staticAnalysis', 'progress'], 100)
    )

    case ANALYSIS_PROGRESS: return assocPath(['staticAnalysis', 'progress'], action.progress)

      // ******************************************************
      // ** Markups
      // ******************************************************

    case MARKUP_DEF_CREATED: return create(MARKUPS_PATH, action.markupDef)
    case MARKUP_DEF_EDITED: return edit(MARKUPS_PATH, action.markupDef)
    case MARKUP_DEF_DELETED: return remove(MARKUPS_PATH, action.markupDef)
    case MARKUP_CUSTOM_TYPE_CREATED: return create(MARKUP_TYPES_PATH, action.customType)
    case MARKUP_CUSTOM_TYPE_EDITED: return edit(MARKUP_TYPES_PATH, action.customType)
    case MARKUP_CUSTOM_TYPE_DELETED: return remove(MARKUP_TYPES_PATH, action.customType)

    // ProjectData
    case UPDATE_PROJECT_DATA: return pipe(over(lensPath(['project']), mergeDeepLeft(action.data)))

    // ProjectPreferences
    case UPDATE_PROJECT_PREFERENCES: return pipe(
      when(
        () => {
          const preference = find(propEq('name', StaticAnalysisEnablePreference.name), action.preferences)
          return preference && propEq('value', false, preference)
        },
        assocPath(['staticAnalysis', 'issues'], {})
      ),
      assocPath(['project', 'preferences'], action.preferences)
    )

    case PREJECT_PREFERENCE_SET: {
      return over(
        lens(pathOr([], ['project', 'preferences']), assocPath(['project', 'preferences'])),
        prefs => {
          const i = findIndex(propEq('name', action.preference.name), prefs)
          return i >= 0 ? update(i, action.preference, prefs) : append(action.preference, prefs)
        }
      )
    }

    case BUILD_CONFIG_SET: {
      return assoc('buildConfig', action.buildConfig)
    }

    case BUILD_CONFIG_RENAME_EXT: {
      return over(
        lens(pathOr([], ['buildConfig', 'exts']), assocPath(['buildConfig', 'exts'])),
        map(when(propEq('name', action.extension), assoc('name', action.newName)))
      )
    }

    case BUILD_CONFIG_DELETE_EXT: {
      return over(
        lens(pathOr([], ['buildConfig', 'exts']), assocPath(['buildConfig', 'exts'])),
        reject(propEq('name', action.extension))
      )
    }

    default: return state
  }
}

export const project = createReducer(_project)

const transformUser = all => u => {
  const found = all.find(propEq('_id', u._id))
  return ({
    ...u,
    acronym: found?.acronym || acronymFor(u, all)
  })
}

const processSessions = ourSession => pipe(
  _sessions => {
    // this is mutable crap, but I'm doing a really big refactor (client sessions API)
    const sessions = _sessions || EMPTY_ARRAY
    const allUsers = [];
    sessions.forEach(session => {
      const user = transformUser(allUsers)(session.user)
      session.user = user
      allUsers.push(user)
    })
    return sessions
  },
  // now filter our own session
  ourSession ? filter(propNotEq('_id', ourSession._id)) : identity,
  indexBy(prop('_id')),
)

const processChangeSet = changeSet => pipe(
  over(lensPath(['revision', 'objects']), applyChangesToObjects(changeSet.changes)),
  assoc('lastChangeSet', changeSet),
  over(lensProp('history'), pipe(
    prepend(changeSet),
    when(lengthIsGreaterThan(UNDO_HISTORY_LIMIT), slice(0, UNDO_HISTORY_LIMIT))
  )),
)

const appendSession = session => s => {
  const allUsers = Object.values(s.sessions).map(prop('user'))
  const sessionWithUser = over(lensProp('user'), transformUser(allUsers), session)
  return assocPath(
    ['sessions', session._id],
    sessionWithUser
  )(s)
}

/**
 * If there are unreal sessions then it picks the last created and store its id as the bound session
 * to interact with unreal. This can be later manually changed by the user through UI
 */
const bindAnyUnrealSession = state => {
  const latestUnrealSession = pipe(
    filter(propEq('clientType', SessionClientType.Unreal.code)),
    sort(descend(prop('createdAt'))),
    head
  )(Object.values(state.sessions))

  return latestUnrealSession ? assoc('boundUnrealSession', latestUnrealSession._id, state) : dissoc('boundUnrealSession', state)
}

// CRUD utils for list of objects (markup defs & types)

const create = (_path, obj) => over(lensPath(_path), append(obj))
const edit = (_path, obj) => over(lensPath(_path), map(when(propEq('_id', obj._id), always(obj))))
const remove = (_path, obj) => over(lensPath(_path), reject(propEq('_id', obj._id)))
