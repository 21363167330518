import { createReducer } from 'reduxsauce'
import { pipe, over, dissocPath, lensProp, indexBy, prop, assocPath, lensPath, path, assoc } from 'ramda'
import { NodePlaybackStatus } from 'beanie-engine-api-js'

import { LoadingState, ChoicesResultType } from '../model/constants'
import Cache from '../model/Cache'
// actions
import { Types as PlayerActions } from '../actions/player'
import { Types as EngineActions } from '../actions/engine'

const {
  SET_SOURCE,
  //
  LOADING_PROJECT,
  LOADING_PROJECT_ERROR,
  LOADING_PROJECT_SUCCESS,
  //
  LOADING_BEANIE_SUCCESS,
} = PlayerActions

const {
  PLAY_CLIP, END_CLIP,
  PLAY_CHOICES, END_CHOICES,

  ON_CHOICE_SELECTED,
  ON_CHOICE_EXPIRED,
  ON_CLIP_ENDED,

  ON_PRESENTATION_ENDED
} = EngineActions

const INITIAL_STATE = {
  // project: {
  //   objects: [],
  //   video: {
  //     url, timeline
  //   }
  // },
  // engine: {
  //   current: {
  //     clip: ID,
  //     choices: ID,
  //     choicesResult: { type: ChoicesResultType, value: Int }
  //   }
  //   nextClipsCache: Cache(),
  //   lastClip: ID,
  // },
}


// *******************
// ** handlers
// *******************

// project loading

const setSource = (state, { source }) => assoc('source', source)(state)

const setLoadingState = loadingState => (state = INITIAL_STATE) => ({ ...state, loadingState })
const loadingProject = setLoadingState(LoadingState.LOADING)
const loadingProjectSuccess = (state = INITIAL_STATE, { project }) => ({
  ...state,
  project: over(lensProp('objects'), indexBy(prop('id')))(project)
})
const loadingProjectError = setLoadingState(LoadingState.ERROR) // SET THE ERROR IN THE STATE

// beanie loading

const loadingBeanieSuccess = assoc('loadingState', LoadingState.LOADED)

// engine playback state

const playClip = (state, { clipId, nextClipIds = null }) => pipe(
  assocPath(['engine', 'current', 'clip'], clipId),
  over(lensPath(['engine', 'nextClipsCache']), cache => (cache ? cache.update(clipId, nextClipIds) : new Cache(nextClipIds)))
)(state)

// TODO: test this
const endClip = (state, { clipId }) => {
  const current = path(['engine', 'current', 'clip'], state)
  if (current === clipId) {
    // we were still playing ! engine is forcing us to stop !
    return onClipEnded(state)
  } else {
    // we were already not playing it, it is just the engine calling us
    // because of its normal burocratic flow
    return state
  }
}

const playChoices = (state, { choicesId, enabledChoices }) => pipe(
  assocPath(['engine', 'current', 'choices'], choicesId),
  assocPath(['engine', 'current', 'enabledChoices'], enabledChoices),
)(state)
const endChoices = state => pipe(
  dissocPath(['engine', 'current', 'choices']),
  dissocPath(['engine', 'current', 'choicesResult']),
  dissocPath(['engine', 'current', 'enabledChoices'])
)(state)

const onChoiceSelected = (state, { choiceIndex }) => assocPath(['engine', 'current', 'choicesResult'], {
  type: ChoicesResultType.SELECTED,
  value: NodePlaybackStatus.CHOICE_SELECTED(choiceIndex)
})(state)
const onChoiceExpired = state => assocPath(['engine', 'current', 'choicesResult'], {
  type: ChoicesResultType.EXPIRED,
})(state)

const onClipEnded = state => pipe(
  // record as lastClip
  assocPath(['engine', 'lastClip'], path(['engine', 'current', 'clip'])(state)),
  // remove currentClip
  dissocPath(['engine', 'current', 'clip']),
)(state)

const onPresentationEnded = state => pipe(
  dissocPath(['engine', 'current']),
  dissocPath(['engine', 'lastClip']),
  dissocPath(['engine', 'nextClipsCache']),
)(state)

//
// compose the reducer
//

export const player = createReducer(INITIAL_STATE, {
  [SET_SOURCE]: setSource,
  // loading project
  [LOADING_PROJECT]: loadingProject,
  [LOADING_PROJECT_SUCCESS]: loadingProjectSuccess,
  [LOADING_PROJECT_ERROR]: loadingProjectError,
  // loading beanie
  [LOADING_BEANIE_SUCCESS]: loadingBeanieSuccess,

  // engine
  [PLAY_CLIP]: playClip,
  [END_CLIP]: endClip,
  [PLAY_CHOICES]: playChoices,
  [END_CHOICES]: endChoices,
  [ON_CHOICE_SELECTED]: onChoiceSelected,
  [ON_CHOICE_EXPIRED]: onChoiceExpired,
  [ON_CLIP_ENDED]: onClipEnded,
  [ON_PRESENTATION_ENDED]: onPresentationEnded

})
