import { extractAllMatches, isEmptyOrNull } from 'utils/string'
import { EMPTY_ARRAY } from '../../../../../utils/object'

/**
 * Given a text to search and another text to search within it computes
 * a list of matches having
 *   {
 *     offset: Int,
 *     length: Int
*    }
 */
const computeMatches = (text, inText) => {
  if (isEmptyOrNull(text)) return EMPTY_ARRAY
  return extractAllMatches(new RegExp(text, 'ig'))(inText)
    .map(m => {
      const [matchedText] = m
      return { offset: m.index, length: matchedText.length }
    })
}

export default computeMatches