import getRevisionBNEObjectsFromCache from './getRevisionBNEObjectsFromCache'

import Preferences from 'preferences/Preferences'
import { preference } from 'selectors/view'

export const fetchRevisionHead = (revisionId, services) => async () => {
  const revision = await services.studioAPI.getRevisionData(revisionId)
  if (!revision) { throw new Error('Not found') }
  return revision
}

/**
 * Either fetches the objects from frontend cache and update them
 * or it fetches from the backend. Here is where we have a cache to avoid loading
 * fully every time which is heavy for big projects
 */
export const getBNEObjects = (revisionId, services) => async (dispatch, getState) => {
  const doCacheObjs = preference(Preferences.ProjectLoading.projectDataCache)(getState())
  let retVal = doCacheObjs && await getRevisionBNEObjectsFromCache(revisionId, services)
  if (!retVal) {
    retVal = await services.studioAPI.getRevisionBNEObjects(revisionId)
    if (doCacheObjs) {
      await services.revisionsCache.put(revisionId, retVal)
    }
  }
  return retVal
}
