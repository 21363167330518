import React, { useCallback } from 'react'
import classNames from 'classnames'
import { onlyUpdateForKeys, compose } from 'recompose'

import IconButton from 'components/Commons/IconButton'
// import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'
// import { propFromProps } from 'selectors/props'
// import { defaultNodeState } from '../../../selectors/execution'

import styles from './TreeChain.scss'

const CollapseChainButton = ({ id, onCollapse }) => {
  // REVIEWME: This is great to make the green execution neat, but impacts performance on an already overloaded
  // graph that is up to the limit with big projects like endeavour. So, disabling it for now

  // const hasExecFeedback = useMemo(() => trackExecutionEnabled
  //   && executionState !== defaultNodeState && executionState.execution_count > 0
  //   && executionPath.pathAfter,
  // [trackExecutionEnabled, executionState, executionPath]
  // )

  const onClick = useCallback(e => {
    onCollapse(id, e)
  }, [id, onCollapse])

  const hasExecFeedback = false
  return (
    <div className={classNames(styles.CollapseChainButton, { [styles.hasExecFeedback]: hasExecFeedback })}>
      {hasExecFeedback &&
        <div className={styles.ExecutionPath} />
      }
      <IconButton type="minus-square" className={styles.collapseButton} onClick={onClick} />
    </div>
  )
}

export default compose(
  onlyUpdateForKeys(['id']),
  // withNodeExecutionTracking(propFromProps('id'))
)(CollapseChainButton)