import React from 'react'
import { compose, withState, withPropsOnChange } from 'recompose'
import { Dropdown, Icon, Menu, Input, Spin } from 'antd'
import { identity, pipe, test } from 'ramda'
import escapeStringRegEx from 'escape-string-regexp'
import classNames from 'classnames'
import { isEmptyOrNull } from 'utils/string'
import { withTargetValue, Themes } from 'utils/antd'

import globalStyles from 'styles/globals.scss'
import styles from './FilteredDropdown.scss'

const { Divider, Item } = Menu

// custom kind of propOr with default being a fn call
const valueFromProp = (k, defaultFn = identity) => o => {
  if (!o) return
  if (k === undefined) return defaultFn(o)
  return o[k]
}

/**
 * props:
 * @param trigger - same as antd's Dropdown trigger. Defaults to ['click']
 * @param loading - wether elements are loading.  defaults to false
 * @param error - wether elements failed to load. defaults to undefined (no-error).
 * @param elements - list of elements to show with filtering.
 * @param selected - the selected element from the elements.
 * @param elProp - the element prop to be shown as value, if not provided will use the element itself.
 * @param elLabelProp - the element prop to be shown as label, if not provided will use elProp.
 * @param select - the associate action to execute when selecting an element from the filtered list
 * @param onClear - the action to execute when clicking on the clear action of the selectedElement.
 * @param l10n - localization object, has the props: { entityName: 'value used to fill in default messages when filter and empty not provided', filter: 'message shown as filter placeholder' , empty: 'message shown when no elements', error: 'message shown when error getting elements' }
 * @param allowClear - If true then the name of the selected option will be shown on the top ProjectLeftMenu.Item of the Dropdown ProjectLeftMenu with a clear icon to clear the selected item.
 * @param selectedActions - An array of React.Component, you can pass IconButton for e.g. They will all be shown side by side. Will show actions if array is not null and has length > 0
 */
const _FilteredDropdown = ({ children, trigger = ['click'], loading = false, error, elements, selected, onSelected, onClear, elProp, elLabelProp, renderElement, l10n = {}, onClick, filterValue, setFilterValue, allowClear = false, selectedActions, placeholder = 'None', icon, theme = 'light' }) => {
  const elementValue = valueFromProp(elProp)
  const elementLabel = valueFromProp(elLabelProp, elementValue)
  const localizedFilter = l10n.filter ? l10n.filter : `Filter ${l10n.entityName || ''}...`
  const localizedEmpty = l10n.empty ? l10n.empty : `No ${l10n.entityName || 'items'}.`
  const localizedError = l10n.error ? l10n.error : `Error loading ${l10n.entityName || 'items'}.`
  const isDarkTheme = theme === Themes.dark

  const showSelected = !!allowClear
  const showActions = selectedActions?.length
  const showTopHeader = showSelected || showActions

  return (
    <Dropdown
      onClick={onClick}
      trigger={trigger}
      icon={<Icon type="down" />}
      overlay={
        <Menu theme={theme} className={classNames(styles.FilteredDropdown_menu, globalStyles.topBarMenu)}>
          {
            loading &&
              <Item key="spinner">
                <div className={styles.spinner}>
                  <Spin />
                </div>
              </Item>
          }
          { error && <Item key="error">{localizedError}</Item> }

          { showTopHeader &&
            <Item key="associatedElement" data-class="data-fixedMenuItem1">
              { showSelected && (
                <div className={classNames(styles.assocElement, { [styles.noAssocElement]: !selected })}>
                  { <span>{selected ? elementLabel(selected) : placeholder}</span> }
                  { selected && <Icon type="close" onClick={() => (selected ? onClear() : undefined)} /> }
                </div>
              )}

              { showActions && (
                <div className={classNames(styles.assocElement, { [styles.selectedActions]: showActions, [styles.showSelectedAndActions]: showSelected })}>
                  { showActions && selectedActions }
                </div>
              )}
            </Item>
          }
          { showTopHeader && <Divider /> }

          <Item key="filter" disabled className={styles.filterItem} data-class={`data-fixedMenuItem${showTopHeader ? '2' : '1'}`}>
            <Input
              placeholder={localizedFilter}
              size="small"
              value={filterValue}
              onChange={withTargetValue(setFilterValue)}
              className={classNames({ [styles.dark]: isDarkTheme })}
              allowClear
              data-test="filterInput"
            />
          </Item>
          <Divider />
          { (!!elements && elements.length > 0) ?
              elements
                .map(e => (
                  <Item key={elementValue(e)} onClick={() => { onSelected(e); setFilterValue('') }}>
                    {(renderElement || elementLabel)(e)}
                  </Item>
                )
              )
              : (
                <Item key="noElements" disabled className={styles.noElements}>
                  {isEmptyOrNull(filterValue) ? localizedEmpty : 'No matches'}
                </Item>
              )
          }
        </Menu>
      }
      >
      { React.Children.count(children) === 0 ?
        <div className={styles.dropdownInput}><Input readOnly suffix={icon || <Icon className={styles.down} type="down" />} value={selected === undefined ? placeholder : elementLabel(selected)} /></div>
        :
        children
      }
    </Dropdown>
  )
}

const FilteredDropdown = compose(
  withState('filterValue', 'setFilterValue', ''),
  withPropsOnChange(['elements', 'selected', 'elProp', 'elLabelProp', 'filterValue'], ({ filterValue, elements, selected, elProp, elLabelProp }) => {
    const elementValue = valueFromProp(elProp)
    const elementLabel = valueFromProp(elLabelProp, elementValue)
    return {
      elements: elements ? elements
        .filter(e => elementValue(e) !== elementValue(selected))
        .filter(pipe(elementLabel, test(new RegExp(escapeStringRegEx(filterValue), 'i'))))
        : undefined,

      allElements: elements,
    }
  })
)(_FilteredDropdown)
export default FilteredDropdown
