import React from 'react'
import { path } from 'ramda'
import { compose } from 'recompose'
import { Icon } from 'antd'
import { useSelector } from 'react-redux'

import winston from 'utils/logger'
import { ProjectRevisionEventTypes, SortOrder } from 'model/constants'
import paginatedQuery from 'hocs/paginatedQuery'
import withSubscription from 'hocs/withSubscription'
import withSession from 'hocs/withSession'
import withRevisionId from 'hocs/withRevisionId'
import withUser from 'hocs/withUser'

import userTasksQuery from 'api/queries/userTasks.graphql'
import projectRevisionEventsSubscriptionQuery from 'api/subscriptions/projectRevisionEvents.graphql'

import UserTasksTable from './UserTasksTable'

import { revisionId as revisionIdSelector } from 'selectors/project'

const PAGE_SIZE = 10

const _ConnectedUserTasksPanel = ({ userTasksData, onPageChanged, handleTableChange }) => {
  return (
    <div style={{ width: '100%' }}>
      {userTasksData.loading && (
        <Loading />
      )}
      {userTasksData.userTasks &&
        <UserTasksTable
          data={userTasksData}
          userTasks={userTasksData.userTasks}
          pageSize={PAGE_SIZE}
          onPageChanged={onPageChanged}
          handleTableChange={handleTableChange}
        />
      }
    </div>
  )
}

// TODO extract this compo
const Loading = () => (
  <div
    // className={styles.loading}
    >
    <Icon type="loading" /> Loading...
  </div>
)

const queryName = 'userTasksData'
const topics = [ProjectRevisionEventTypes.UserTaskAddedEvent, ProjectRevisionEventTypes.UserTaskUpdateEvent, ProjectRevisionEventTypes.UserTaskDeletedEvent]
const hasToRefetch = event => topics.includes(path(['data', 'projectRevisionEvents', '__typename'], event))
const ConectedUserTasksPanel = compose(
  withSession,
  withRevisionId,
  withUser,
  paginatedQuery({
    query: userTasksQuery,
    name: queryName,
    pageSize: PAGE_SIZE,
    defaultSort: {
      field: 'createdAt',
      order: SortOrder.DESCENDENT
    },
    variables: ({ revisionId, user }) => ({
      filterBy: {
        fields: [
          { field: 'revision', fixed: true, values: [revisionId] },
          ...(user && [{ field: 'user', values: [user._id] }]) || []
        ]
      }
    })
  }),
  withSubscription({
    subscriptionEnabled: ({ session }) => session?._id,
    variables: ({ session }) => ({ sessionId: session?._id }),
    query: projectRevisionEventsSubscriptionQuery,
    onSubscriptionEvent: props => event => {
      if (hasToRefetch(event)) props[queryName].refetch()
    },
    onSubscriptionError: () => e => {
      winston.error(`ERROR: You will have a degraded beanie notifications service. Could not subscribe to ${queryName} (cause: ${e.message}).`)
    }
  })
)(_ConnectedUserTasksPanel)

const UserTasksPanel = props => {
  const revisionId = useSelector(revisionIdSelector)
  return revisionId ? <ConectedUserTasksPanel {...props} /> : <div />
}

export default UserTasksPanel
