import { TeamMembershipRole } from 'utils/authentication'

import { isSuperAdminCheck } from './platform'
import { isMemberWithRole } from './commons'
import { isOrgOwner } from './organization'

// (user, { team }): Boolean
export const hasTeamAdminRole = isMemberWithRole('teamsMemberships', 'team', 'team', TeamMembershipRole.ADMIN)

export const teamWriteAccess = scope => (
  isSuperAdminCheck(scope)
  || isOrgOwner({ ...scope, organization: scope.team.organization })
  || hasTeamAdminRole(scope)
)