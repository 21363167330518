exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2mzBs{color:#add8e6;cursor:pointer}.iconAsLink__2mzBs:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3TOkh{font-style:italic}.formFieldsVerticalGutter__1kqRl{padding:.5rem}.formFieldsVerticalGutter__1kqRl>*{margin:.5rem !important}.tableBackground__i9ShY .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__i9ShY .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__7nyjJ .ant-notification-notice-close{display:none}.PlaybackControls__1w13f{display:-ms-flexbox;display:flex;-ms-flex-positive:.8;flex-grow:.8;-ms-flex-align:center;align-items:center;line-height:2.5rem;padding:0 .25rem}.PlaybackControls__1w13f>i{cursor:pointer;font-size:1.8rem;vertical-align:middle}.PlaybackControls__1w13f>i.disabled__1YZ4t{opacity:.5;cursor:not-allowed !important}.PlaybackControls__1w13f>*{margin:0 .25rem}.PlaybackControls__1w13f .volumeControl__10cqK{font-size:1rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2mzBs",
	"labelFromRef": "labelFromRef__3TOkh",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1kqRl",
	"tableBackground": "tableBackground__i9ShY",
	"notificationNotClosable": "notificationNotClosable__7nyjJ",
	"PlaybackControls": "PlaybackControls__1w13f",
	"disabled": "disabled__1YZ4t",
	"volumeControl": "volumeControl__10cqK"
};