exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".separator__2wHB1{position:relative;height:2px;background:#e1e4e8;margin-right:15%;margin-left:15%;margin-top:9px;-ms-flex-item-align:center;align-self:center;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center}.separator__2wHB1 span{left:50%;background:#fff;font-size:1.2em;color:#afafaf;text-transform:uppercase;text-align:center;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;padding-left:.5em;padding-right:.5em}.separator__2wHB1 span.onFuture__1zyHr{background-color:#e8e8e8}", ""]);

// exports
exports.locals = {
	"separator": "separator__2wHB1",
	"onFuture": "onFuture__1zyHr"
};