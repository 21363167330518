import { partialRight } from 'ramda'
import { makeSelector as originalMakeSelector } from '@taskworld.com/rereselect'
import { objectsIndex } from 'selectors/apollo'
import { parseRef } from 'beanie-engine-api-js'


// base selectors
export const objectById = id => state => objectsIndex(state)[id]
export const resolver = query => ref => query(objectById(parseRef(ref)))

/**
 * Replacement of rereselect makeSelector(query => ...)
 * To support having access to props
 *    makeSelector((query, getProps) => ...)
 * See note below
 */
export const makeSelector = fn => {
  // TODO: this is not recomputing if the "interesting props" have changed.
  // it is just like a hacky way to access props for the moment.
  // But it won't react to changes to the props only (it will pick new props when recomputing
  // because of state changes but not if state remains the same, and only props changes)
  // if you need to react
  let localProps;
  const getProps = () => localProps
  const boundFn = partialRight(fn, [getProps])

  const selector = originalMakeSelector(boundFn)
  return (state, props) => {
    localProps = props
    return selector(state)
  }
}