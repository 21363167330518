import React from 'react'
import { Alert } from 'antd'

import styles from './Preferences.scss'

const Preference = ({ title, children, message }) => (
  <div className={styles.preference}>
    <div className={styles.preferenceTitle}>{title}</div>
    <div className={styles.preferenceControl}>
      {children}
    </div>
    {message && (
      <div className={styles.alert}><Alert banner type="warning" message={message} /></div>
    )}
  </div>
)

export default Preference