import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button } from 'antd'

import closeProjectAction from 'actions/project/closeProject'

import { ModalConsumer } from 'components/Commons/Modal'
import DangerZone, { Item } from 'components/Commons/DangerZone/DangerZone'

import DeleteProjectModal from './DeleteProjectModal'
import TransferProjectItem from './TransferProjectItem'
import ArchiveProjectItem from './ArchiveProjectItem'

const ProjectDangerZone = ({ project, closeProject }) => (
  <DangerZone>
    <ArchiveProjectItem project={project} />
    <TransferProjectItem project={project} closeProject={closeProject} />
    <Item
      title="Delete Project"
      paragraph={`
        Delete this project completely. This will remove all the content for you and any other user
        It will also delete all the history.
        Careful ! after removing the content cannot be recovered !
      `}
    >
      <ModalConsumer>
        {({ showModal }) => (
          <Button type="danger" onClick={() => showModal(DeleteProjectModal, { onDeleted: closeProject, project })}>Delete</Button>)}
      </ModalConsumer>
    </Item>
  </DangerZone>
)

export default compose(connect(null, { closeProject: closeProjectAction }))(ProjectDangerZone)