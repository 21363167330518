import { mergeDeepLeft } from 'ramda'
import { PLAYBACK, TEXT } from 'preferences/PaneIds'

export const initialPanesSizes = {
  panes: {
    horizontal: { size: 400 },
    vertical: { size: 700 }
  }
}

export default Object.freeze(mergeDeepLeft(initialPanesSizes, {
  graph: {
    collapsed: {}
  },
  sound: {
    volume: 100
  },
  panes: {
    horizontal: { currentTab: PLAYBACK },
    vertical: { currentTab: TEXT },
  },
  preferences: {}
}))