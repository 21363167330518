import React from 'react'
import PropTypes from 'prop-types'
import { model } from 'beanie-engine-api-js'
import { getDisplayName } from './utils'
import { onlyOneIsUndefined } from 'utils/object'

const { types: { object: { Paths } } } = model

class DenormalizingComponent extends React.Component {

  state = {
    object: undefined
  }
  UNSAFE_componentWillMount() {
    this.context.denormalizerObservable.subscribe(newDenormalizer => {
      this.unsubscribe()
      this.denormalizer = newDenormalizer
      // console.log(`${getDisplayName(this.getWrappedComponent())}.UNSAFE_componentWillMount() calling denormalizeAndHookObserver`, this.getObjectId())
      this.denormalizeAndHookObserver(this.props)
    })
  }

  denormalizeAndHookObserver(props) {
    const propName = this.getProp()

    const { [propName]: node } = props

    if (!node) {
      // console.log(`${getDisplayName(this.getWrappedComponent())} has no object to denormalize on prop ${propName}. Props:`, this.props)
      return
    }

    const observable = this.denormalizer.denormalize(node, {
      includes: this.getIncludes(),
      excludes: this.getExcludes(),
      follow: this.getRecurse()
    })

    this.subscription = observable.subscribe(o => {
      this.setState({ object: o })
    })
  }

  filter = o => this.getFilter()(o)

  UNSAFE_componentWillReceiveProps(newProps) {
    const propName = this.getProp()
    const oldObject = this.props[propName]
    const newObject = newProps[propName]
    if (onlyOneIsUndefined(oldObject, newObject) || (newObject && oldObject.id !== newObject.id)) {
      this.unsubscribe()
      this.denormalizeAndHookObserver(newProps)
    }
  }

  unsubscribe = () => {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  componentWillUnmount = () => { this.unsubscribe() }

  getObjectId() {
    const { [this.getProp()]: node } = this.props
    return node ? node.id : 'undefined'
  }

  render() {
    const { [this.getProp()]: node, ...props } = this.props
    const Wrapped = this.getWrappedComponent()

    // console.log(`${getDisplayName(this.getWrappedComponent())}.render()`, this.getObjectId())

    return (
      // TODO: make it a prop if your component wants to still render while
      // the object is still not hydrated, or if you don't want it to be rendered
      // until not fully hydrated
      this.state.object ?
        <Wrapped
          {...props}
          {...{ [this.getProp()]: this.state.object }}
        /> : this.renderNoObject()
    )
  }

  renderNoObject = () => (<div>...</div>)

}

export const denormalizing = ({ prop, includes, excludes = [Paths.node.parent.join('.'), Paths.node.child.join('.')], recurse = false }) => Component => {
  class Denormalizing extends DenormalizingComponent {
    static contextTypes = {
      denormalizerObservable: PropTypes.object.isRequired,
    }

    getWrappedComponent() { return Component }
    getProp() { return prop }
    getIncludes() { return includes }
    getExcludes() { return excludes }
    getRecurse() { return recurse }
  }
  Denormalizing.displayName = `Denormalizing(${getDisplayName(Component)})`

  return Denormalizing
}