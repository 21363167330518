import { isPausePoint } from '../../../selectors/playback/debug'
import Groups from '../../Groups'
import { Creators } from 'actions/vm/playback/debug'

const { togglePausePoint } = Creators

/**
 * Toggles setting a node as a pause-point
 */
export const PAUSE_POINT_TOGGLE = {
  label: (o, state) => (isPausePoint(o.id, state) ? 'Unset Pause-Point' : 'Set Pause-Point'),
  icon: 'pause-circle',
  group: Groups.Editing,
  action: togglePausePoint,
}