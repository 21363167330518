import { NODE_TYPES } from '../utils/Constants'
import { clipToModel } from './ClipSerializer'
import { always } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

export const textToModel = value => parseText(value)

export const emptyBeanieObj = EMPTY_ARRAY

const emptySerializer = always(emptyBeanieObj)

const serializers = {
  [NODE_TYPES.CLIP]: clipToModel,
}

export const parseText = ({ document: { data, nodes } }) => (nodes && data.get('type') ? (serializers[data.get('type')] || emptySerializer)(nodes) : emptyBeanieObj)
