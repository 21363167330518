import moment from 'moment'
import { STATE_EVENT_TYPE } from 'beanie-engine-api-js'
import { produce } from 'immer'
import { EMPTY_OBJECT } from '../../../utils/object'

/**
 *
 */
const onStateEventsMutable = ({ events }) => currentState => produce(currentState, (draft => {
  if (events.length === 0) return

  const { state } = draft.playback

  // prepend events: would it be more efficient to avoid calling revers and
  // instead iterate in reverse order and unshift every element ?
  state.events = events.concat(state.events)

  // process each event (in reverse order)
  for (let i = events.length - 1; i >= 0; i--) {
    const { eventType, nodeId, field, value } = events[i]
    switch (eventType) {
      // clear all state
      case STATE_EVENT_TYPE.CLEAR_ALL: {
        state.current = EMPTY_OBJECT
        // REVIEWME: is this semantically correct ?
        state.currentDirty = EMPTY_OBJECT
        break;
      }
      // clear just one object state
      case STATE_EVENT_TYPE.CLEAR: {
        delete state.current[nodeId]
        // REVIEWME: not sure this is semantically correct but it is backward compat
        delete state.currentDirty[nodeId]
        break;
      }
      // handle update, by default is handled as update
      case STATE_EVENT_TYPE.UPDATE:
      default: {
        const lowerField = field.toLowerCase()
        if (!state.current[nodeId]) { state.current[nodeId] = {} }
        state.current[nodeId][lowerField] = value

        if (!state.currentDirty[nodeId]) { state.currentDirty[nodeId] = {} }
        state.currentDirty[nodeId][lowerField] = moment()
      }
    }
  }
}))

export default onStateEventsMutable
