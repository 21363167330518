import React from 'react'

import styles from './ProjectInformation.scss'
import StringValue from 'components/PropertiesEditor/Value/StringValue'

import { Alert } from 'antd'
import { IconDisposition } from 'components/PropertiesEditor/Value/EditableValue'

const EditableProjectData = ({ handleProjectNameChanged, handleProjectDescriptionChanged, projectData, error }) => {
  const { projectWithId: { name, description } } = projectData
  return (
    <div className={styles.editingPart}>
      {error && <Alert type="error" message={error} />}
      <div className={styles.nameBox}>
        <div className={styles.propTitle}>Name</div>
        <div className={styles.propSubtitle}>
          <StringValue
            iconDisposition={IconDisposition.After}
            showAcceptCancelIcons={false}
            isEditable
            value={name}
            onValueChanged={handleProjectNameChanged}
        />
        </div>
      </div>
      <div>
        <div className={styles.propTitle}>Description</div>
        <div className={styles.propSubtitle}>
          <StringValue
            iconDisposition={IconDisposition.After}
            isEditable
            showAcceptCancelIcons={false}
            value={description}
            onValueChanged={handleProjectDescriptionChanged}
        />
        </div>
      </div>
    </div>
  )
}

export default EditableProjectData