import {
  curry,
  equals,
  head,
  toUpper,
  pipe,
  path,
  when,
  split,
  tail,
  test,
  last,
  dropLast,
  match,
  map,
  prop,
  toLower,
  filter,
  uniqBy,
  isNil
} from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import uuidv4 from 'uuid/v4'
import matchSorter, { rankings } from 'match-sorter'

import { toArray } from 'utils/object'
import escapeStringRegEx from 'escape-string-regexp'

export const ISO_DATE_REGEX = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/
export const dotSplit = pipe(when(test(/\./), split('.')), toArray)
export const dotSplitTail = str => {
  const idx = str.lastIndexOf('.')
  return str.lastIndexOf('.') > -1 ? str.substring(idx + 1) : str
}

export const NEW_LINE = '\n'

export const containsIgnoreCase = (string, token) => !!string && string.toLowerCase().indexOf(token.toLowerCase()) >= 0

export const splitMatching = (text, searchText) => {
  return text.split(new RegExp(`(${escapeStringRegEx(searchText)})`, 'ig'))
    .filter(part => part !== '')
    .map(part => ({
      token: part,
      isMatch: equalsIgnoreCase(part, searchText)
    }))
}

export const isNumberString = string => !isNaN(string) && !isNaN(parseFloat(string))

export const equalsIgnoreCase = (a, b) => a.toUpperCase() === b.toUpperCase()

export const safeToLowerCase = when(isNotNil, toLower)

export const safeToUpperCase = when(isNotNil, toUpper)

const _propEqIgnoreCase = (name, value) => pipe(prop(name), safeToLowerCase, equals(safeToLowerCase(value)))

export const propEqIgnoreCase = curry(_propEqIgnoreCase)

export const truncate = (s = '', n) => ((s.length > n) ? `${s.substr(0, n - 1)}...` : s);

export const firstUpperCase = s => s && (s[0].toUpperCase() + s.slice(1).toLowerCase())
export const capitalise = s => s.charAt(0).toUpperCase() + s.slice(1)

export const isEmptyOrNull = string => !string || string.trim() === ''
export const EMPTY_STRING = ''

export const leftPad = (str, n, char) => Array(Math.max(str.length - n, 0)).fill(char).concat([str]).join('')


// sorting

export const defaultSorter = (a, b) => (isNil(a) && isNil(b) ? 0 : (isNil(a) || a < b ? -1 : (isNil(b) || a > b ? 1 : 0)))

export const sorterBy = transformer => (a, b, direction) => defaultSorter(transformer(a), transformer(b), direction)
export const sorterByStringProperty = property => sorterBy(pipe(path(property.split('.')), safeToUpperCase))

// matching / regex

export const matchOrDefault = pattern => string => {
  const r = match(pattern)(string)
  return r.length > 0 ? r : [string]
}

/* eslint no-cond-assign:0, no-shadow: 0 */
export const extractAllMatches = regex => text => {
  let aMatch;
  const matches = []
  while (aMatch = regex.exec(text)) {
    matches.push(aMatch)
  }
  return matches
}

const matchToObject = e => {
  const [fullText, text] = e
  const start = e.index + fullText.indexOf(text)
  return { start, end: start + text.length, text }
}
export const extractAllMatchesAsObjects = regex => pipe(
  extractAllMatches(regex),
  map(matchToObject)
)

/**
 * Given a text and a spec of certain section of it it splits the string into
 * and array of 3 strings: [contentBefore, matchingContent, contentAfter]
 */
export const splitMatch = (text, offset, length) => {
  const before = text.slice(0, offset)
  const match = text.slice(offset, offset + length)
  const after = text.slice(offset + length)
  return [before, match, after]
}

//
// TOKENIZING utils
//

/*
 * Parses a string line by line. Returns a list of tokens.
 * Token: { text: String, start: Number, end: Number }
 * Trims each line content
 */
export const tokenize = text => {
  const regex = /([^\n]*)/gm
  const tokens = []
  let m;
  /* eslint no-cond-assign: 0 */
  while (m = regex.exec(text)) {
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    if (m[0] !== '') {
      tokens.push({ start: m.index, end: m.index + m[0].length, text: m[0].trim() })
    }
  }
  return tokens
}

export const dropLeadingSlash = when(pipe(head, equals('/')), tail)
export const dropTrailingSlash = when(pipe(last, equals('/')), dropLast(1))

export const isUUIDv4 = s => /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i.test(s)
export const isUUID = isUUIDv4
export const uuid = uuidv4

const sortByMatch = (word, options = { threshold: rankings.STARTS_WITH }) => list => matchSorter(list, word, options)
export const filterByMatch = word => filter(suggestion => isNotNil(suggestion) && toLower(suggestion).indexOf(word) !== -1)
export const sortAndFilterByMatch = word => {
  const lowerWord = toLower(word || '')
  return pipe(
    filterByMatch(lowerWord),
    uniqBy(toLower),
    sortByMatch(lowerWord, { threshold: rankings.MATCHES })
  )
}