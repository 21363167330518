import React from 'react'
import { compose, withHandlers, withState } from 'recompose'
import { DEFAULT_ACTOR } from 'beanie-engine-api-js'
import { Spin, Tooltip, Icon } from 'antd'
import ExtensionPoint from '../ExtensionPoints/ExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import styles from './ActorSection.scss'

const ActorActions = ({ actor, lineCount, onDelete, onMoveLines, loading, onClick }) => (
  <Spin spinning={loading}>
    <div className={styles.Actions}>
      {actor.data.actorName !== DEFAULT_ACTOR &&
        <Tooltip title="Delete actor">
          <Icon type="delete" className={styles.iconAsLink} onClick={onClick(onDelete)} />
        </Tooltip>
      }
      {
        lineCount(actor) > 0 &&
        <Tooltip title="Move lines">
          <Icon type="solution" className={styles.iconAsLink} onClick={onClick(onMoveLines)} />
        </Tooltip>
      }
      <ExtensionPoint
        point={EXTENSION_POINTS.ACTOR_ACTIONS}
        renderExtension={({ id, label, action: extensionAction, icon }, { moreProps, extAsBneAction }) => (
          <Tooltip key={id} title={label}>
            <Icon
              type={icon}
              className={styles.iconAsLink}
              onClick={extAsBneAction(extensionAction, actor)}
              {...moreProps}
            />
          </Tooltip>
        )}
      />
    </div>
  </Spin>
)

export default compose(
  withState('loading', 'setLoading', false),
  withHandlers({
    onClick: ({ actor, setLoading }) => action => async () => {
      setLoading(true)
      await new Promise(resolve => {
        // I needed to delay this because on heavy projects this was preventing the spin from showing
        setTimeout(async () => {
          await action(actor)
          setLoading(false)
          resolve()
        }, 100)
      })
    }
  })
)(ActorActions)
