import React from 'react'
import ReactDOM from 'react-dom'
import { compose, onlyUpdateForKeys } from 'recompose'
import { DnDTypes } from 'dnd/model-dnd'
import { DragLayer } from 'react-dnd'
import { Sys } from 'beanie-engine-api-js'

import { DRAG_PARENT_ID } from '../../../../TreeView'
import DraggingTruthTable from './DraggingTruthTable'

import styles from '../../TruthTable.scss'

/**
 * TODO: unify all this 3 layers that are almost the same
 */
const TruthTableDrawLayer = ({ truthTable }) => {
  if (!truthTable) return null

  return ReactDOM.createPortal(
    <div style={{ position: 'relative' }}>
      <div className={styles.truthTableDrawLayer}>
        <DraggingTruthTable truthTable={truthTable} />
      </div>
    </div>,
    document.getElementById(DRAG_PARENT_ID)
  )
}

export default compose(
  DragLayer(monitor => {
    const item = monitor.getItem()
    if (!monitor.isDragging() || !item) return {}
    const { itemType, left } = item
    const truthTable = itemType === DnDTypes.Node && left && left.sys === Sys.truth_table ? left : null
    return ({
      truthTable,
    })
  }),
  onlyUpdateForKeys(['truthTable']),
)(TruthTableDrawLayer)
