import React from 'react'
import { Spin, Table } from 'antd'

import OrganizationSelectOption from 'components/Organizations/Commons/OrganizationSelectOption'
import GoToOrganizationLink from 'components/Organizations/Commons/GoToOrganizationLink'
// TODO: rename and relocate UsersPageHeader as it can be reused for others
import UsersPageHeader from 'components/Users/UsersPageHeader'
// TODO: rename and relocate SectionElementsTable as it can be reused for others
import GenericTable from 'components/Organizations/Section/SectionElementsTable/SectionElementsTable.jsx'

import panelStyles from 'styles/panel.scss'
import classNames from 'classnames'
import commonStyles from 'styles/commons.scss'
import styles from './OrganizationListPage.scss'

const { Column } = Table
const OrganizationListPage = ({ className = {}, organizations, pageSize, onPageChanged, refreshItems, handleTableChange, onSearch }) => (
  <Spin spinning={organizations.loading}>
    <div className={classNames(panelStyles.panel, className)}>

      <UsersPageHeader onSearch={onSearch} />

      <GenericTable
        className={commonStyles.tableBackground}
        data={organizations}
        dataPath={['organizations']}
        pageSize={pageSize}
        onPageChanged={onPageChanged}
        handleTableChange={handleTableChange}
        refreshItems={refreshItems}
        scroll={{ x: '30vw', y: '60vh' }}
      >
        <Column
          title="Organization"
          key="name"
          sorter
          width="15vw"
          render={org => (
            <div className={styles.organizationNameColumn}>
              <GoToOrganizationLink key={org._id} organization={org}>
                <OrganizationSelectOption organization={org} descriptionEnabled={false} />
              </GoToOrganizationLink>
            </div>
          )}
        />
        <Column
          title="Description"
          key="description"
          width="15vw"
          render={org => (
            org.description ?
              <span>{org.description}</span>
              : <span className={styles.noDescription}>No description</span>
          )}
        />
      </GenericTable>
    </div>
  </Spin>
)

export default OrganizationListPage