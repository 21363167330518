import { onError } from 'apollo-link-error'
import { onSessionDestroyed } from 'actions/project/session'
import { REVISION_SESSION_ON_MUTATION_ERROR_PATTERN } from 'api/Errors'
import { pathEq } from 'ramda'

import { isLoaded, sessionId } from 'selectors/project'

export const GQL_OPERATION_METADATA_DONT_FAIL_ON_REVISION_SESSION_ERROR = 'dontFailOnRevisionSessionError'

/**
 * Check a query/mutation error to detect that we got kicked out from a revisionsession (destroyed)
 */
const createLink = ({ store }) => onError(e => {
  const { graphQLErrors, operation } = e
  if (graphQLErrors && hasInvalidRevisionSessionError(graphQLErrors) && weAreInARevisionSession(store) && !dontFail(operation.getContext())) {
    store.dispatch(onSessionDestroyed())
  }
})

export const isInvalidRevisionSessionError = e => e.message.startsWith(REVISION_SESSION_ON_MUTATION_ERROR_PATTERN)
export const hasInvalidRevisionSessionError = errors => errors.some(isInvalidRevisionSessionError)

const weAreInARevisionSession = store => {
  const state = store.getState()
  return isLoaded(state) && !!sessionId(state)
}

const dontFail = pathEq(['metadata', GQL_OPERATION_METADATA_DONT_FAIL_ON_REVISION_SESSION_ERROR], true)

export default createLink

