exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".assignmentItem__1mnUT{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.assignmentItem__1mnUT .path__17AU3{-ms-flex-align:center;align-items:center;opacity:.8;margin-right:1rem}.assignmentItem__1mnUT .separator__1A4VF{margin-left:1rem;margin-right:2rem}.assignmentItem__1mnUT .node__1xkw-{cursor:pointer}.popover__2mCEa .ant-popover-inner-content{padding-top:2rem;padding-bottom:2rem}", ""]);

// exports
exports.locals = {
	"assignmentItem": "assignmentItem__1mnUT",
	"path": "path__17AU3",
	"separator": "separator__1A4VF",
	"node": "node__1xkw-",
	"popover": "popover__2mCEa"
};