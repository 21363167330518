import React from 'react'
import GraphiQLBase from 'graphiql'
import fetch from 'isomorphic-fetch'
import classNames from 'classnames'
import { withPropsOnChange, compose } from 'recompose'

import connectState from 'hocs/connectState'
import { token as tokenSelector } from 'selectors/auth'
import { sessionId as sessionIdSelector } from 'selectors/project'

import styles from './GraphiQL.scss'

import 'graphiql/graphiql.css'

const GraphiQL = ({ fetcher, className = {} }) => (
  <div className={classNames(styles.GraphiQL, className)}>
    <GraphiQLBase fetcher={fetcher} />
  </div>
)

export default compose(
  connectState({
    token: tokenSelector,
    sessionId: sessionIdSelector,
  }),
  withPropsOnChange(['token', 'sessionId'], ({ token, sessionId }) => ({
    fetcher: graphQLParams => fetch(`${window.location.origin}/api/graphql`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        bneSessionId: sessionId,
      },
      body: JSON.stringify(graphQLParams)
    }).then(response => response.json())
  }))
)(GraphiQL)