import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import classNames from 'classnames'
import { makeChainExecutionSelector } from 'selectors/state/executions'
import { pathFromProps } from 'selectors/props'
import ChainExecutionsTable from './ChainExecutionsTable'
import NodeExecutionsTable from './NodeExecutionsTable'

import styles from './ChainExecutionsView.scss'
import { fullTable } from 'styles/table.scss'

const ChainExecutionsView = ({ setSelectedChainExecution, selectedChainExecution, currentChainExecution }) => {
  useEffect(() => {
    if (!currentChainExecution) {
      setSelectedChainExecution(undefined)
    }
  }, [currentChainExecution])

  return (
    <div className={styles.viewPanel}>
      <div className={classNames(fullTable, styles.ChainExecutionsTable)}>
        <ChainExecutionsTable setSelectedChainExecution={setSelectedChainExecution} />
      </div>
      <NodeExecutionsTable chainExecution={selectedChainExecution} />
    </div>
  )
}

export default compose(
  withState('selectedChainExecution', 'setSelectedChainExecution'),
  connect(() => {
    const selector = makeChainExecutionSelector(pathFromProps(['selectedChainExecution', 'id']))
    return (state, props) => ({
      currentChainExecution: selector(state, props)
    })
  })
)(ChainExecutionsView)