import React from 'react'
import { Tooltip } from 'antd'
import { truncate } from 'utils/string'

const ShortId = ({ id }) => (
  <Tooltip title={id}>
    {truncate(id, 7)}
  </Tooltip>
)

export default ShortId