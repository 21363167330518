import React from 'react'
import ReactDOM from 'react-dom'
import { last } from 'ramda'
import { connect } from 'react-redux'
import { Modal } from 'antd'

import { properties } from 'utils/object'

import { Creators } from 'actions/ui'

import styles from './Confirmations.scss'

const { actionConfirmed } = Creators

class MainEditorConfirmations extends React.Component {

  modalRefs = {}

  componentDidMount() {
    this.focus()
  }

  componentDidUpdate(prevProps) {
    const { confirmDialogs } = this.props
    const { confirmDialogs: oldDialogs } = prevProps

    if (confirmDialogs !== oldDialogs) {
      this.focus()
    }
  }

  /* eslint react/no-find-dom-node: 0 */
  focus() {
    // I need to schedule this for once the <Modal> renders something
    // seems like it won't show instatenously when getting mounted
    // and there is no prop callback to be notified and trigger this.
    // so the last resource is to wait for a little bit
    // otherwise `domNode` will always be null :(
    setTimeout(() => {
      const aRef = last(Object.values(this.modalRefs).filter(ref => !!ref))
      if (aRef) {
        const domNode = ReactDOM.findDOMNode(aRef)
        if (!domNode) { return }
        const button = domNode.querySelector('.ant-modal-footer .ant-btn-primary')
        if (button) {
          button.focus()
        }
      }
    }, 100)
  }


  onConfirm = (id, actionFn) => () => {
    const { confirmed } = this.props
    actionFn()
    confirmed(id)
  }

  onCancel = id => () => {
    const { confirmed } = this.props
    confirmed(id)
  }

  render () {
    const { confirmDialogs } = this.props
    return (
      <div>
        {properties(confirmDialogs)
          .map(prop => (
            <Modal
              ref={ref => {
                /* eslint brace-style: 0 */
                if (ref === null) { delete this.modalRefs[prop.name] }
                else { this.modalRefs[prop.name] = ref }
              }}
              key={prop.name}
              title="Please confirm"
              visible
              autoFocusButton="ok"
              onOk={this.onConfirm(prop.name, prop.value.actionFn)}
              onCancel={this.onCancel(prop.name)}
              wrapClassName={styles.ConfirmationsModal}
            >
              {prop.value.message}
            </Modal>
          )
        )}
      </div>
    )
  }
}

export default connect(
  state => ({ confirmDialogs: state.ui.confirmDialogs }),
  { confirmed: actionConfirmed }
)(MainEditorConfirmations)