import { isCMDorCTRL, Key } from 'utils/keyboard'

export default () => ({

  onKeyDown (event, editor, next) {

    if (isCMDorCTRL(event) && event.keyCode === Key.D) {
      event.preventDefault()
      event.stopPropagation()

      if (event.shiftKey) {
        editor.enableFullySelectedLines()
        return false
      }

      editor.disableFullySelectedLines()
      return false
    }
    
    return next()
  },

  commands: {

    enableFullySelectedLines: editor => {
      editor.props.enableLines?.(editor.fullySelectedLineIds())
      editor.collapse()
    },

    disableFullySelectedLines: editor => {
      editor.props.disableLines?.(editor.fullySelectedLineIds())
      editor.collapse()
    }
  }
})