
import projectRevisionDeleteMutation from '../../api/mutations/projectRevisionDelete.graphql'

const projectRevisionDelete = id => async (dispatch, getState, { getApolloClient }) => {
  await getApolloClient().mutate({
    mutation: projectRevisionDeleteMutation,
    variables: {
      input: { id }
    }
  })
}

export default projectRevisionDelete