import { cond, equals, ifElse, pipe, toLower, trim, T, F, isNil } from 'ramda'

export const isBoolean = v => ['true', 'false'].includes(v.toLowerCase())
const isInteger = v => /^-?[0-9]+$/.test(v)
const isFloat = v => /^-?[0-9]+(\.[0-9]+)?$/.test(v)
const isNothing = v => v.toLowerCase() === 'nothing'

/**
 * Given a string value tries to infer if it represented another basic type
 * and coerce/convert it. For example a string with value "true" is transformed into the true basic type value.
 */
const inferValue = v => {
  if (isNil(v)) return v
  const trimmed = trim(v)
  if (isNothing(trimmed)) return null
  // try rules
  const inferred = _inferValue(trimmed)
  return isNil(inferred) ? v : inferred
}

const _inferValue = cond([
  [isBoolean, pipe(toLower, ifElse(equals('true'), T, F))],
  [isFloat, parseFloat],
  [isInteger, parseInt],
])

export default inferValue