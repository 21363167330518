import React, { useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import withSynchronizer from 'hocs/withSynchronizer'
import { createObjectByPlaybackIdSelector } from 'selectors/objects'
import { gamepadType as gamepadTypeSelector } from 'selectors/ui'
import { isPlaying } from 'selectors/playback'
import { isEnabled as isEnabledSelector, getDebuggableContainerContentId } from 'selectors/debuggingData'

import { Creators } from 'actions/vm'
import { model } from 'beanie-engine-api-js'
import { withEnabledChoicesIds } from '../../../PlaybackHistory/nodes/ChoicesPlayback'

import ChoicePlaybackItem from './ChoicePlaybackItem'
import TimerProgress from 'components/PlaybackView/TimerProgress'
import getNextTimerOf from '../../../../../engine/utils/getNextTimerOf'

import styles from './CurrentChoicesPlayback.scss'

const { types: { object: { getName }, choices: { getChoiceType } } } = model

const { selectChoice: choiceSelectedAction, choicesExpired: choicesExpiredAction } = Creators

/**
 *
 */
export const CurrentChoicesPlaybackComp = ({ playbackCurrentObject, node, choicesIds, isEnabled, initialTime, onSelected, onExpired, paused, gamepadType }) => {
  const selector = useMemo(() => getDebuggableContainerContentId(node.nodeId), [node.nodeId])
  const firstPinnedChoiceId = useSelector(selector)

  const choicesType = useMemo(() => getChoiceType(playbackCurrentObject), [playbackCurrentObject])

  return (
    <div className={styles.choices}>
      {choicesType && (
        <div className={styles.ChoicesType}>{choicesType}</div>
      )}

      {choicesIds.length === 0 &&
        <h3>{getName(playbackCurrentObject)}</h3>
      }

      {initialTime > 0 && <TimerProgress className={styles.progressBar} seconds={initialTime} onExpired={onExpired} paused={paused} />}

      <div className={styles.choiceList}>
        {choicesIds.length === 0 ?
          <div>No available options !</div>
          // eslint-disable-next-line no-unused-vars
          : choicesIds.map((id, index) => (
            <ChoicePlaybackItem
              key={id}
              choiceId={id}
              isEnabled={isEnabled}
              isPaused={paused}
              onSelected={onSelected}
              index={index}
              gamepadType={gamepadType}
              firstPinnedChoiceId={firstPinnedChoiceId}
            />
          ))
        }
      </div>
    </div>
  )
}

/**
 * Base container to share code between choices1 and 2.
 * The only difference is the way they compute the "timer" / timeout value.
 * Once we remove choice1 from the engine we can get rid of the choices1 part
 * and unify it all into the Choices2 component
 */
export const BaseCurrentChoicesPlaybackContainer = compose(
  withSynchronizer,
  connect(() => {
    const objectSelector = createObjectByPlaybackIdSelector()
    return (state, props) => {
      const playbackCurrentObject = objectSelector(state, props)
      return {
        playbackCurrentObject,
        gamepadType: gamepadTypeSelector(state),
        paused: !isPlaying(state),
        isEnabled: isEnabledSelector(state)
      }
    }
  }, {
    choicesExpired: choicesExpiredAction,
    choiceSelected: choiceSelectedAction
  }),
  withEnabledChoicesIds(),
  withHandlers({
    onExpired: ({ node: { playbackId }, choicesExpired }) => () => choicesExpired(playbackId),
    onSelected: ({ node: { playbackId }, choiceSelected }) => index => choiceSelected(playbackId, index)
  })
)

/**
 * choices (1) had its timer as a children.
 * So we need this custom logic here to get the timer calling the engine
 * (we could be getting if from the redux store :P
 */
export default compose(
  BaseCurrentChoicesPlaybackContainer,
  withSynchronizer,
  withPropsOnChange(
    ['playbackCurrentObject'],
    ({ playbackCurrentObject, synchronizer }) => ({
      initialTime: getNextTimerOf(playbackCurrentObject.id, synchronizer.getEngine()),
    })
  )
)(CurrentChoicesPlaybackComp)
