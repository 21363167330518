exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".color__4e-_B{width:36px;height:14px;border-radius:2px}.swatch__1g5KR{padding:5px;background:#fff;border-radius:1px;-webkit-box-shadow:0 0 0 1px rgba(0,0,0,.1);box-shadow:0 0 0 1px rgba(0,0,0,.1);display:inline-block;cursor:pointer}.popover__s7qbD{position:absolute;z-index:2}.cover__2_4VH{position:fixed;top:0px;right:0px;bottom:0px;left:0px}", ""]);

// exports
exports.locals = {
	"color": "color__4e-_B",
	"swatch": "swatch__1g5KR",
	"popover": "popover__s7qbD",
	"cover": "cover__2_4VH"
};