import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withStateHandlers, withPropsOnChange } from 'recompose'
import { prop, has, includes, map, pipe } from 'ramda'
import { Select, Input, Checkbox } from 'antd'
import { push as pushAction } from 'connected-react-router'

import openRevisionAction from 'actions/project/openRevision'
import addNewProjectMutation from 'api/mutations/addNewProject.graphql'
import withUser from 'hocs/withUser'
import { isEmpty } from 'utils/object'
import { withTargetValue } from 'utils/antd'
import { noop } from 'utils/functions'
import { userOrganizationsAsOwner } from 'selectors/auth'

import ModalOpener from 'components/Commons/ModalOpener'
import { Errors } from 'components/Commons/Error'

import styles from './CreateProject.scss'
import UserSelectOption from 'components/Commons/UserSelectOption'
import OrganizationSelectOption from 'components/Organizations/Commons/OrganizationSelectOption'

const { Option, OptGroup } = Select

// TODO: maybe we should only let Org owner create projects under the org (?)
//  this could be done by filtering (maybe a selector) memberships by role

const CreateProjectModal = ({ navigateToProject, owner, readOnlyOwner, user, ownedOrgs, confirmLoading, errors, name, setName, setOwner, setNavigateToProject, onCreateNewProject, onCancel }) => (
  <ModalOpener
    title="Create Project"
    onOk={onCreateNewProject}
    onCancel={onCancel}
    okButtonProps={{ disabled: !name }}
    modalProps={{ destroyOnClose: true, confirmLoading }}
  >
    <div className={styles.modalContent}>
      <Select placeholder="Owner" defaultValue={owner} dropdownClassName={styles.options} onSelect={setOwner} disabled={readOnlyOwner}>
        <Option selected value={user._id}>
          <UserSelectOption user={user} />
        </Option>
        <OptGroup label="Organizations">
          {ownedOrgs.map(m => (
            <Option key={m.organization._id} value={m.organization._id}>
              <OrganizationSelectOption organization={m.organization} />
            </Option>
          ))}
          {isEmpty(user.organizationsMemberships) && (
            <Option key={42} value={42} disabled>You don&apos;t belong to any organization</Option>
          )}
        </OptGroup>
      </Select>
      <Input placeholder="Name" value={name} onChange={withTargetValue(setName)} onPressEnter={onCreateNewProject} />
      <div className={styles.openRightAway}><Checkbox checked={navigateToProject} onChange={withTargetValue(setNavigateToProject)} /> Open right away</div>
      {errors && errors.length > 0 &&
        <Errors items={errors} />
      }
    </div>
  </ModalOpener>
)

const onCreateNewProject = ({ navigateToProject, addProject, hide, name, owner, setErrors, setConfirmLoading, match, openRevision, onCreated = noop }) => async () => {
  try {
    setErrors([])
    setConfirmLoading(true)
    const { data: { createProject: project } } = await addProject(owner, name)
    await onCreated()
    if (navigateToProject) {
      const latest = project.masterBranch._id
      await openRevision(latest, match)
    }
    setConfirmLoading(false)
    hide()
  } catch (e) {
    if (!e.graphQLErrors) {
      setErrors([e.message])
      throw e
    }
    setErrors(e.graphQLErrors.map(pipe(prop('message'), parseError(name))))
  }
}

export default compose(
  graphql(addNewProjectMutation, { name: 'addNewProject' }),
  withRouter,
  withUser,
  connect(state => ({
    ownedOrgs: userOrganizationsAsOwner(state)
  }), {
    push: pushAction,
    openRevision: openRevisionAction
  }),
  withPropsOnChange(['owner', 'user', 'ownedOrgs'], ({ owner, ownedOrgs, user }) => ({
    ownedOrgs: owner && has('_id', owner) && !includes(owner._id, [...map(prop('_id'), ownedOrgs), user._id]) ?
      [...ownedOrgs, { organization: owner }] : ownedOrgs
  })),
  withStateHandlers(
    ({ owner, user }) => ({
      name: undefined,
      owner: owner ? owner._id : user._id,
      errors: [],
      confirmLoading: false,
      navigateToProject: true,
    }),
    {
      setName: prev => newName => ({ ...prev, name: newName }),
      setOwner: prev => owner => ({ ...prev, owner }),
      setNavigateToProject: prev => () => ({ ...prev, navigateToProject: !prev.navigateToProject }),
      setErrors: prev => errors => ({ ...prev, errors, confirmLoading: false }),
      setConfirmLoading: prev => confirmLoading => ({ ...prev, confirmLoading })
    }
  ),
  withHandlers({
    addProject: ({ user, addNewProject }) => (ownerId, name) => addNewProject({
      variables: {
        input: {
          ...ownerId !== user._id && { organization: ownerId },
          name,
        }
      }
    })
  }),
  withHandlers({
    onCreateNewProject,
    onCancel: ({ hide, setConfirmLoading }) => () => {
      setConfirmLoading(false)
      hide()
    }
  }),
)(CreateProjectModal)

const parseError = name => s => {
  if (s.match('Duplicated')) return `Owner already has a project with name ${name}`
  return s
}
