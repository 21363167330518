import { isContained } from 'beanie-engine-api-js'
import { selectionToLeftAndRight } from '../../../../selectors/nodeSelection'

/**
 * Knows how to connect & disconnect and move elements
 * in the bi-directional relationship `parent <->> child`
 */
const ParentChildrenConnector = {

  connect: ({ api }, selection, relationship) => {
    const { left, right } = selectionToLeftAndRight(selection)
    const parent = api.obj.get(relationship.from)

    // connect right to the current children
    const rightObj = api.obj.get(right)
    const child = parent.get_child_node()
    child?.set_parent(rightObj)

    // now connect the target to the left part
    api.obj.get(left).set_parent(parent)
  },

  canConnect: ({ api, resolve }, selection, relationship) => {
    const { left } = selectionToLeftAndRight(selection)
    const parent = api.obj.get(relationship.from)

    if (parent.get_id() === left) {
      // cannot set an object to be its own children
      return false
    }
    const leftNode = resolve(left)

    if (isContained(leftNode)) {
      // cannot set a container item as a root element of the chain
      return false
    }

    return true
  },

  disconnect: ({ api }, selection) => {
    const { left, right } = selectionToLeftAndRight(selection)
    const leftObj = api.obj.get(left)
    const parent = leftObj.get_parent()

    // disconnect left
    leftObj.set_parent(null)

    // rewire right's children with our parent
    if (parent) { // TODO: test this if
      const child = api.obj.get(right).get_child_node()
      child?.set_parent(parent)
    }
  },

}

export default ParentChildrenConnector