import { useSelector } from 'react-redux'
import { ourUnrealSession } from 'selectors/sessions/sessions'

/**
 * A wrapper component that gives you access to the unreal session in the form of an arg to the render function (body)
 *
 * Example
 *
 *    <UnrealSession>
 *      {session => (
 *        <div> Your Unreal Session id is {session._id}</div>
 *      )}
 *    </UnrealSession>
 *
 * @constructor
 */
const UnrealSession = ({ children }) => {
  const session = useSelector(ourUnrealSession)

  return session ? children(session) : null
}

export default UnrealSession