import React from 'react'
import EnabledRule from './EnabledRule'

import styles from './NodeWithEnabledRule.scss'

const NodeWithEnabledRule = ({ node, component }) => (
  <div className={styles.enabledRuleContainers}>
    {component}
    <EnabledRule node={node} />
  </div>
)

export default NodeWithEnabledRule
