import ReactDOM from 'react-dom'
import { arePositionsDifferent } from './position'
import strictComputePosition from './position/compute/strictComputePosition'

/**
 * Given
 *  - a DOM element id
 *  - current position and a setter
 *  - monitor (dnd)
 *  - component to get its physical dimensions
 *  - (optional) a strategy to compute the Position based on the mouse coordinates and the element bounding box
 *
 * Returns a `Position` object with { vertical: TOP|CENTER|BOTTOM, horizontal: LEFT|CENTER|RIGHT }
 */
const computeHoverPosition = (elementId, current, setPosition, monitor, component, computer = strictComputePosition) => {
  if (monitor.getItem().id === elementId) {
    return
  }
  const mouse = monitor.getClientOffset()
  /* eslint react/no-find-dom-node: 0 */
  const rect = ReactDOM.findDOMNode(component).getBoundingClientRect()
  const newHoverPosition = computer(mouse, rect)

  if (arePositionsDifferent(current, newHoverPosition)) {
    setPosition(newHoverPosition)
  }
}

export default computeHoverPosition