import { compose, withStateHandlers, lifecycle, withState, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'

import { extractTokenFromForgotPasswordUrl } from 'actions/routing'
import States, { ForgotPasswordTokenStatus } from './ForgotPasswordState'
import ForgotPasswordAccess from './ForgotPasswordAccess'
import { parseGraphQLErrors } from 'utils/graphql'

import userForgotPasswordValidateQuery from 'api/queries/userForgotPasswordValidate.graphql'
import forgotPasswordResetMutation from 'api/mutations/userForgotPasswordReset.graphql'

export default compose(
  withState('token', 'setToken', () => extractTokenFromForgotPasswordUrl()),
  graphql(forgotPasswordResetMutation, { name: 'forgotPasswordReset' }),
  graphql(userForgotPasswordValidateQuery, {
    name: 'validateQuery',
    options: ({ token }) => ({
      variables: { token },
    }),
  }),
  withStateHandlers(
    { state: States.Starting, error: false, waitingReset: false },
    {
      setWaitingReset: prev => waitingReset => ({ ...prev, waitingReset }),
      setError: prev => error => ({ ...prev, error }),
      onSuccess: prev => () => ({ ...prev, state: States.Resetted }),
      validatingToken: prev => () => ({ ...prev, state: States.Validating }),
      tokenValidated: prev => ({ status, reason }) => ({
        ...prev,
        state: status === ForgotPasswordTokenStatus.Valid ? States.ValidToken : States.InvalidToken,
        invalidReason: reason
      })
    }
  ),
  withHandlers({
    onNewPassword: ({ setWaitingReset, setError, onSuccess, forgotPasswordReset, token: forgotPasswordToken }) => async password => {
      try {
        setWaitingReset(true)
        await forgotPasswordReset({ variables: { input: { forgotPasswordToken, password } } })
        onSuccess()
      } catch (e) {
        setError(parseGraphQLErrors(e))
      } finally {
        setWaitingReset(false)
      }
    }
  }),
  lifecycle({
    async componentDidUpdate() {
      const { state, validateQuery: { error, userForgotPasswordValidate }, tokenValidated } = this.props
      if (state !== States.Resetted && !error && userForgotPasswordValidate) {
        tokenValidated(userForgotPasswordValidate)
      }
    }
  })
)(ForgotPasswordAccess)