import makeCollect from '../makeCollector'

/**
 * Search resolver for a "composite" type of search.
 * It just interprets each search like a union collecting each result.
 */
const compositeCollectorFactory = ({ searches }, getState, pushResult) => {
  const collectors = searches.map(search => makeCollect(search, getState, pushResult))
  return (object, node) => {
    collectors.forEach(collect => {
      collect(object, node)
    })
  }
}

export default compositeCollectorFactory