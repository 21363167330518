
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"profileImage"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"email"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"roles"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"deleted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastLoginAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"forceResetPassword"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":298}};
    doc.loc.source = {"body":"fragment UserFragment on User {\n  id # deprecated !\n  name # deprecated ! (or we should generate one!)\n\n  _id\n  profileImage\n  firstName\n  lastName\n  email\n\n  created_at\n\n  roles\n  deleted\n\n  # secured data ! TODO: move to special query for superadmin sections\n  lastLoginAt\n  forceResetPassword\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
