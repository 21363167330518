import React from 'react'
import { batch, connect } from 'react-redux'
import { path } from 'ramda'
import { Spin } from 'antd'
import { compose, lifecycle, withHandlers, withProps, withState } from 'recompose'
import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import { updateBNEObject } from 'engine/actions/actions'
import { createIsNodeUnderSyncOutboundOperation } from 'selectors/sync'
import { model } from 'beanie-engine-api-js'

import AceEditor from '../Commons/Editors/AceEditor/AceEditor'

import { Creators } from 'actions/ui'

const { types: { object: { Paths } } } = model

const { editStart, editEnd } = Creators

import styles from './TextEditorForLuaNode.scss'

const TextEditorForLuaNode = ({ object, setValue, isUnderSyncOperation, value, commands, readOnly, onSave }) => {
  return (
    <div className={styles.editor}>
      <Spin spinning={isUnderSyncOperation} wrapperClassName={styles.spinner}>
        <AceEditor
          className={styles.LuaEditor}
          mode="lua"
          id={`lua_editor_${object.id}`}
          value={value}
          onChange={v => setValue(v)}
          readOnly={readOnly}
          onBlur={onSave}
          commands={commands}
          height="100%"
          width="100%"
          autoComplete
        />
      </Spin>
    </div>
  )
}

const luaCodePath = Paths.lua.code

export const getLuaCode = obj => path(luaCodePath, obj) || ''

export default compose(
  withState('value', 'setValue', ({ object }) => getLuaCode(object)),
  secure('hasWriteAccess', isCurrentRevisionWritable),
  connect(
    () => {
      const isNodeUnderSyncOperation = createIsNodeUnderSyncOutboundOperation()
      return (state, props) => ({
        isUnderSyncOperation: isNodeUnderSyncOperation(state, props),
      })
    }, {
      updateObjectAction: updateBNEObject,
      editEndAction: editEnd,
      editStartAction: editStart,
    }
  ),
  withHandlers({
    onSave: ({ value, editEndAction, editStartAction, updateObjectAction, object }) => async () => {
      if (value !== getLuaCode(object)) {
        await batch(async () => {
          await editStartAction(object.id, luaCodePath)
          await updateObjectAction(object.id, { lua_code: value })
          editEndAction()
        })
      }
    }
  }),
  withProps(({ hasWriteAccess, onSave }) => ({
    readOnly: !hasWriteAccess,
    commands: [
      {
        bindKey: { win: 'ctrl-s', mac: 'command-s' },
        name: 'onSave',
        exec: onSave,
      }
    ]
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { object } = prevProps
      if (object.id !== this.props.object.id) {
        this.props.setValue(getLuaCode(this.props.object))
      }
    }
  })
)(TextEditorForLuaNode)
