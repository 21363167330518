exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".statePresetButtons__1bcXt{display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly;margin-left:1.2rem;border-style:solid;border-width:1px;border-color:#fff;border-radius:8px;line-height:1.9rem;font-size:1rem}.statePresetButtons__1bcXt:not(.anyAssociated__clMUs){border-color:#65737e}.statePresetButtons__1bcXt>div{-ms-flex:1 1 0%;flex:1 1 0%;text-align:center;min-width:2rem}.statePresetButtons__1bcXt>div:first-child{border-top-left-radius:6px;border-bottom-left-radius:6px}.statePresetButtons__1bcXt>div:last-child{border-top-right-radius:6px;border-bottom-right-radius:6px}", ""]);

// exports
exports.locals = {
	"statePresetButtons": "statePresetButtons__1bcXt",
	"anyAssociated": "anyAssociated__clMUs"
};