import { allPass, pathEq, propEq, prop, pipe, cond, propSatisfies, includes, pathSatisfies, T, F, reduce, equals, map, concat, forEach } from 'ramda'
import { collectProp } from 'utils/ramda'
import { changeType, isAdded, isDeleted, isUpdate, added as _added, deleted as _deleted, updated as _updated, applyChanges as _applyChanges } from 'beanie-engine-api-js'
import { nonePass } from 'ramda-adjunct'

import { EMPTY_ARRAY } from 'utils/object'
import { isEmptyOrNull } from '../utils/string'
import { noop } from 'utils/functions'

// TODO: all things here that are not related to GrqphQL or the frontend app (like state, selectors, etc)
//  must be moved to the engine repo

const applyChanges = changes => object => _applyChanges(object, changes)

//
// GraphQL types
//

// keep in sync with backend
export const ChangeSetGQLTypeName = {
  Added: 'Added',
  Updated: 'Updated',
  Deleted: 'Deleted',
}

// factories for Change's including __typename (graphql, server-side full model)
const withTypeName = (__typename, fn) => (...args) => ({ ...fn(...args), __typename })
export const updated = withTypeName(ChangeSetGQLTypeName.Updated, _updated)
export const added = withTypeName(ChangeSetGQLTypeName.Added, _added)
export const deleted = withTypeName(ChangeSetGQLTypeName.Deleted, _deleted)

const getChangeTypeName = change => {
  if (isUpdate(change)) return ChangeSetGQLTypeName.Updated
  if (isAdded(change)) return ChangeSetGQLTypeName.Added
  if (isDeleted(change)) return ChangeSetGQLTypeName.Deleted
  throw new Error(`Unknown change type: ${JSON.stringify(change)}`)
}
export const assignTypeNameToChange = change => ({
  ...change,
  __typename: getChangeTypeName(change)
})

//
//
//

export const ChangeType = {
  Added: 'added',
  Deleted: 'deleted',
  Updated: 'updated'
}

export const isFromUs = (user, sessionId) => allPass([
  pathEq(['author', 'user', '_id'], user._id),
  pathEq(['author', 'sessionId'], sessionId)
])

export const isRevert = c => !!c.reverts
export const isReapply = c => !!c.reapplies
export const isBasicChange = nonePass([isRevert, isReapply])

export const addedNodesFromChangeSet = pipe(prop('changes'), collectProp('added'))
export const removedNodesFromChangeSet = pipe(prop('changes'), collectProp('deleted'))

const isChangeWithTypeForObject = (predicate, objectProp) => (changeSet, object) => (changeSet.changes || EMPTY_ARRAY)
  .some(allPass([
    predicate,
    pathEq([objectProp, 'id'], object.id)
  ]))
export const isAddedForObject = isChangeWithTypeForObject(isAdded, 'added')
export const isDeletedForObject = isChangeWithTypeForObject(isDeleted, 'deleted')

export const getUpdateForObject = (id, changeSet) => changeSet.changes
  .find(allPass([
    isUpdate,
    propEq('id', id)
  ]))


export const filterChanges = (text, changes) => (isEmptyOrNull(text) ?
  changes
  : changes.filter(cond([
    [isUpdate, propSatisfies(includes(text.trim()), 'id')],
    [isAdded, pathSatisfies(includes(text.trim()), ['added', 'id'])],
    [isDeleted, pathSatisfies(includes(text.trim()), ['deleted', 'id'])],
    [T, F],
  ]))
)

// Keep in sync with GraphQL schema
export const ChangeSetDescKind = { TtsChangeSet: 'TtsChangeSet' }


/**
 * Applies a list of Changes (from a ChangeSet) into an array/index of objects
 * It doesn't change the given objects but return a new index (no effect version)
 *
 * This was optimized and therefore the code suffered a bit.
 * Mapping the changes to ramda operations in a pipe was just 1 line
 * But when having a project with ~90k objects seems like doing a copy on each step of the pipe
 * was killing performance.
 * So this is a more "imperative/mutable" version reducing the amount of copies/steps.
 */
export const applyChangesToObjects = changes => objects => {
  // we do a single copy of the object's index and then mutate that
  const copy = { ...objects }
  changes.forEach(c => {
    switch (c.__typename) {
      case ChangeSetGQLTypeName.Added: copy[c.added.id] = c.added; break
      case ChangeSetGQLTypeName.Deleted: delete copy[c.deleted.id]; break
      case ChangeSetGQLTypeName.Updated: copy[c.id] = applyChanges(c.changes)(copy[c.id]); break
      default: {
        // eslint-disable-next-line no-console
        throw new Error(`Unknown change type ${c.__typename} from change ${JSON.stringify(c, null, 2)}`)
        // console.error(`Unknown change type ${c.__typename} from change ${JSON.stringify(c, null, 2)}`)
      }
    }
  })
  return copy
}

export const mapChanges = ({ onAdded = noop, onDeleted = noop, onUpdated = noop }, changes) =>
  forEach(change => {
    const type = changeType(change)
    if (equals(type, 'ADDED')) {
      onAdded(change.added)
    } else if (equals(type, 'DELETED')) {
      onDeleted(change.deleted)
    } else {
      onUpdated(change)
    }
  }, changes)

export const splitChanges = changes => {
  const split = { added: [], deleted: [], updates: [] }

  mapChanges({
    onAdded: add => split.added.push(add),
    onDeleted: del => split.deleted.push(del),
    onUpdated: change => split.updates.push(change)
  }, changes)

  return split
}

export const affectedPathFromChangeSet = ({ changes }) => {
  const { added: splitedAdded, deleted: splitedDeleted, updates: splitedUpdates } = splitChanges(changes)
  const updatedPaths = reduce((acc, { id, changes: updateChanges }) =>
    concat(acc, map(pipe(prop('field'), concat(`${id}.`)), updateChanges)), [], splitedUpdates)

  return [...map(prop('id'), splitedAdded), ...map(prop('id'), splitedDeleted), ...updatedPaths]
}