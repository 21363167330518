import React from 'react'
import NotificationsBroadcastSubscription from '../NotificationsBroadcastSubscription/NotificationsBroadcastSubscription'
import Confirmations from '../Header/Confirmations'
import Notifications from '../Header/Notifications'

/**
 * A component that contains all the site-wide global components that control
 * logic like listening to server messages, showing cross-site confirmation dialogs, and stuff like that.
 */
const ApplicationComponents = ({ user }) => {

  return (
    <React.Fragment>
      <Notifications />

      <Confirmations />

      {user &&
        <NotificationsBroadcastSubscription />
      }
    </React.Fragment>
  )
}

export default ApplicationComponents