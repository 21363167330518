import React from 'react'

import { compose, onlyUpdateForKeys, withState, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { equals, prop } from 'ramda'
import classNames from 'classnames'
import { refToCallback } from 'use-callback-ref'

import { DnDTypes } from 'dnd/model-dnd'
import { anyIsError, anyIsWarning } from 'providers/Checker/Checker'
import DebugIcon from 'components/Commons/DebugIcon'

import { makeColumnSelector, makeColumnValueSelector } from 'selectors/truthTables'
import { isInitial } from 'selectors/playback'

import useHover from 'hooks/useHover'
import { moveColumn } from 'engine/actions/truthTable'
import secure from 'hocs/secure'
import { isCurrentRevisionWritable } from 'security/project'
import { model, lang } from 'beanie-engine-api-js'
import { updateTruthTableHeader } from '../../../../../engine/actions/truthTable'

import TruthTableCell from '../cell/TruthTableCell'
import ColumnDropdown from './column/ColumnDropdown'
import ColumnValueFeedback from './column/ColumnValueFeedback'
import DropFeedback, { DropZone } from '../DropFeedback'
import withCustomDrag from '../../../withCustomDrag'
import withCustomDrop from '../../../withCustomDrop'
import StaticProblemsFeedback from '../../../TreeChain/StaticProblemsFeedback'

import treeChainStyles from '../../../TreeView.scss'
import styles from '../HeaderTruthTable.scss'
import ttStyles from '../TruthTable.scss'

const { types: { object: { Paths } } } = model
const { rule: { typing: { types } } } = lang

/**
 *
 */
const TruthTableColumnHeader = ({
  header,
  affectedPath,
  isFirst,
  isLast,

  truthTable,
  truthTableSelected,
  truthTableEnabled,
  truthTableExecuted,
  hasOtherwiseRow,

  trackExecutionEnabled,

  visibleDropdown,
  setDropdownVisibility,

  // dd target for nodes
  droppingLeft,
  droppingRight,

  // dd columns
  connectDragSource,
  connectDropTarget,
  connectDragPreview,

  shouldEval,
  countOfRows,
  truthTableIsHovered,
  isDragging,
  isOver,
  result,
  isDraggingColumn,
  staticIssues,
  writeAccess,
  ...props
}) => {
  const [hoverRef, isHovered] = useHover()
  const anyErrorIssue = isLast && anyIsError(staticIssues)
  const anyWarningIssue = isLast && anyIsWarning(staticIssues)

  return connectDropTarget(connectDragSource(
    <th
      ref={refToCallback(hoverRef)}
      className={classNames(styles.columnHeader,
        truthTableExecuted && trackExecutionEnabled ? {
        [ttStyles.truthTableExecutedTopBorder]: true,
        [ttStyles.truthTableExecutedBottomBorder]: equals(countOfRows, 0) && !hasOtherwiseRow,
        [ttStyles.truthTableExecutedLeftBorder]: isFirst,
        [ttStyles.truthTableExecutedRightBorder]: isLast,
        } : null)}>
      {isFirst && <DebugIcon nodeId={truthTable.id} />}
      <ColumnValueFeedback
        truthTableIsHovered={truthTableIsHovered}
        shouldEval={shouldEval}
        result={result}
      />

      <TruthTableCell
        node={truthTable}
        expectedType={types.Any}
        affectedPath={affectedPath}

        rule={header}
        truthTable={truthTable}

        shouldEval={shouldEval}

        cellStyle={classNames(styles.header, {
          [treeChainStyles.disabledNode]: !truthTableEnabled,
          [styles.overToDrop]: isOver,
          [styles.dragging]: isDraggingColumn,
          ...(truthTableSelected ? {
            [styles.headerTopSelected]: true,
            [styles.headerLeftSelectedBorder]: isFirst,
            [styles.headerRightSelectedBorder]: isLast,
            [styles.headerBottomSelectedBorder]: equals(countOfRows, 0) && !hasOtherwiseRow
            } : null),
          })
        }
        {...(isLast && staticIssues) ? {
          issuesComponent: (
            <StaticProblemsFeedback
              className={ttStyles.cellProblemsFeedback}
              issues={staticIssues}
              isError={anyErrorIssue}
              isWarning={anyWarningIssue}
              node={truthTable}
            />
          )
        } : null}
        result={result}
        resultErrorStyle={styles.resultErrorHeader}
        {...(writeAccess && (isHovered || visibleDropdown) ? {
          iconComponent: (
            <ColumnDropdown
              truthTable={truthTable}
              columnIndex={props.index}
              setDropdownVisibility={setDropdownVisibility}
            />
          )
        } : null)}
        {...props}
      />

      {isLast && droppingRight &&
        <DropFeedback zone={DropZone.Right} />
      }
      {isFirst && droppingLeft &&
        <DropFeedback zone={DropZone.Left} />
      }

    </th>
  ))
}

/**
 * HOCS that implements DND support
 */
const withDND = [
  secure('writeAccess', isCurrentRevisionWritable),
  withCustomDrop({
    dropTypes: [DnDTypes.TruthTableColumn],
    target: {
      drop: ({ moveTruthTableColumnAction, index, truthTable: targetTruthTable }, monitor) => {
        const { column, columnIndex, truthTable: sourceTruthTable } = monitor.getItem()
        moveTruthTableColumnAction(sourceTruthTable, columnIndex, column, targetTruthTable, index)
      }
    }
  }),
  withCustomDrag({
    dragType: DnDTypes.TruthTableColumn,
    source: {
      beginDrag: ({ index, truthTable, column }) => ({ columnIndex: index, truthTable, column }),
      canDrag: prop('writeAccess')
    }
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { isDragging: isDraggingBefore } = prevProps
      const { isDragging: isDraggingAfter, updateIsDraggingColumn, index } = this.props
      if (!isDraggingBefore && isDraggingAfter) {
        updateIsDraggingColumn(index, true)
      }

      if (isDraggingBefore && !isDraggingAfter) {
        updateIsDraggingColumn(index, false)
      }
    }
  }),
]


export default compose(
  withState('visibleDropdown', 'setDropdownVisibility', false),
  connect((_, { truthTable, index }) => {
    const columnSelector = makeColumnSelector(truthTable.id, index)
    const columnHeaderValueSelector = makeColumnValueSelector(truthTable.id, index)
    return state => {
      const shouldEval = !isInitial(state)
      return ({
        column: columnSelector(state),
        result: shouldEval ? columnHeaderValueSelector(state) : null,
        shouldEval,
      })
    }
  }, {
    editAction: updateTruthTableHeader,
    moveTruthTableColumnAction: moveColumn,
  }),
  withPropsOnChange(['index'], ({ index }) => ({ affectedPath: [...Paths.truth_table.headers, index] })),
  ...withDND,
  onlyUpdateForKeys([
    'header',
    'truthTable',
    'index',
    'isFirst',
    'isLast',

    'result',
    'shouldEval',

    'droppingRight',
    'droppingLeft',
    'connectDragSource',
    'connectDropTarget',
    'connectDragPreview',
    'isDragging',
    'isOver',

    'isDraggingColumn',
    'updateIsDraggingColumn',
    'writeAccess',

    'countOfRows',
    'visibleDropdown',
    'hasOtherwiseRow',
    'truthTableSelected',
    'trackExecutionEnabled',
    'truthTableExecuted',
    'truthTableEnabled',
    'truthTableIsHovered',
    'staticIssues'
  ]),
)(TruthTableColumnHeader)
