import React from 'react'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { model } from 'beanie-engine-api-js'
import {
  deleteTruthTableColumn,
  insertColumnAtPosition,
  moveColumnToPosition
} from 'engine/actions/truthTable'

import DropdownMenu from '../../DropdownMenu'
import TruthTableColumnMenu from '../../Menu/TruthTableColumnMenu'

import styles from '../../DropdownMenu.scss'

const { types: { truthTable: { headers } } } = model

/**
 *
 */
// TODO: avoid passing all props. Makes it harder to maintain / change
const ColumnDropdown = ({ setDropdownVisibility, ...props }) => (
  <DropdownMenu
    iconClassName={styles.columnIcon}
    setDropdownVisibility={setDropdownVisibility}
  >
    <TruthTableColumnMenu
      setDropdownVisibility={setDropdownVisibility}
      {...props}
    />
  </DropdownMenu>
)

export default compose(
  connect(null, {
    insertColumnAtPositionAction: insertColumnAtPosition,
    moveColumnToPositionAction: moveColumnToPosition,
    deleteColumnAction: deleteTruthTableColumn,
  }),
  // TODO: refactor all this handlers to compose functions instead of using 2 withHandlers
  withHandlers({
    applyAndCloseDropdown: ({ setDropdownVisibility }) => func => {
      func()
      setDropdownVisibility(false)
    }
  }),
  withHandlers({
    insertColumnLeft: ({ insertColumnAtPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => insertColumnAtPositionAction(truthTable, columnIndex)),
    insertColumnRight: ({ insertColumnAtPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => insertColumnAtPositionAction(truthTable, columnIndex + 1)),
    moveColumnLeft: ({ moveColumnToPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => moveColumnToPositionAction(truthTable, columnIndex, columnIndex - 1)),
    moveColumnRight: ({ moveColumnToPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => moveColumnToPositionAction(truthTable, columnIndex, columnIndex + 1)),
    moveColumnToLeftMost: ({ moveColumnToPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => moveColumnToPositionAction(truthTable, columnIndex, 0)),
    moveColumnToRightMost: ({ moveColumnToPositionAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => {
        moveColumnToPositionAction(truthTable, columnIndex, headers(truthTable).length)
      }),
    deleteColumn: ({ deleteColumnAction, applyAndCloseDropdown, columnIndex, truthTable }) => () =>
      applyAndCloseDropdown(() => deleteColumnAction(truthTable, columnIndex)),
  }),
)(ColumnDropdown)
