import React from 'react'
import { compose, withState, withHandlers } from 'recompose'

const withMouseOver = ({ propName } = { propName: 'mouseOver' }) => C => {

  const Wrapped = ({ onMouseEnter, onMouseLeave, ...others }) => (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <C {...others} />
    </div>
  )

  return compose(
    withState(propName, `set${propName}`, false),
    withHandlers({
      onMouseEnter: ({ [`set${propName}`]: set }) => () => {
        set(true)
      },
      onMouseLeave: ({ [`set${propName}`]: set }) => () => {
        set(false)
      }
    })
  )(Wrapped)
}

export default withMouseOver