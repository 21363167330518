import { propEq } from 'ramda'

import { TREE_VIEW_DOM_ID } from 'dom/dom'

const Contexts = {

  Tree: propEq('id', TREE_VIEW_DOM_ID)

}

export default Contexts