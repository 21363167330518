import { undo } from 'actions/edit/undo'
import { redo } from 'actions/edit/redo'
import makeDebounce from 'redux-debounce-thunk'
import Groups from '../Groups'
import { isUndoRedoEnabled } from 'model/features'

export const UNDO = ({
  group: Groups.Editing,
  label: 'Undo',
  icon: 'undo',
  preventDefault: true,
  stopPropagation: true,
  action: makeDebounce(undo, 50),
  shortcut: { osx: 'command+z', windows: 'ctrl+z', linux: 'ctrl+z' },

  disabled: !isUndoRedoEnabled,
  // TODO: define enabled condition
})

export const REDO = ({
  group: Groups.Editing,
  label: 'Redo',
  icon: 'redo',
  preventDefault: true,
  stopPropagation: true,
  action: makeDebounce(redo, 50),
  shortcut: { osx: 'command+shift+z', windows: 'ctrl+shift+z', linux: 'ctrl+shift+z' },
  // TODO: define enabled condition

  // hacked field until we unify the `enabled(...params)`
  disabled: !isUndoRedoEnabled,
})