import { batch } from 'react-redux'

import { loadRevisionStart } from '../project'
import closeRevision from './closeRevision'
import { loading, loaded } from 'selectors/project'

/**
 * Triggers the first step to start loading a revision.
 * That is
 *  - closing any current revision
 *  - transitions the state to "loading"
 *
 * Then `RevisionSession` component will detect that and do the actual
 * handshake, fetching and all the steps by calling connectRevision
 */
const loadRevision = revisionId => async (dispatch, getState) => {
  const state = getState()

  // already loading or loaded
  if (revisionId === loading(state) || revisionId === loaded(state)) return

  // we don't want renders/updates in intermediate "invalid" states !
  batch(() => {
    dispatch(closeRevision())
    dispatch(loadRevisionStart(revisionId))
  })

}

export default loadRevision