import React, { useState } from 'react'
import { connect } from 'react-redux'
import { always, assocPath } from 'ramda'
import { Modal } from 'antd'

import { pasteNodeProperties as pasteNodePropertiesAction } from 'engine/actions/clipboard'
import { Creators as UiCreators } from 'actions/ui'
import { propertiesConflictDialog } from 'selectors/ui'

import PropertiesSelector from './PropertiesSelector'

import { mergeAt } from 'utils/object'

const clipboardAsObject = ({ content: { path, properties } }) => assocPath(path, properties)({})


const PropertiesConflictDialog = ({ object, lens = [], propertiesConflictModal, clearPropertiesConflictDialog, pasteNodeProperties }) => {
  const [selected, setSelected] = useState([])

  if (!propertiesConflictModal) return null

  const { clipboard, targets } = propertiesConflictModal

  return (
    <Modal
      visible
      title="Some values could be overridden"
      onOk={() => {
        pasteNodeProperties([object], clipboard, selected)
        clearPropertiesConflictDialog()
      }}
      onCancel={clearPropertiesConflictDialog}
    >
      <h4>Confirm properties to paste into your targets</h4>
      <PropertiesSelector
        selected={selected}
        setSelected={setSelected}
        editable={false}
        object={clipboardAsObject(clipboard)}
        targetObject={mergeAt(targets, clipboard.content.path)}
        lens={lens}
        nameCollisionTooltip={always('Will override value on targets')}
      />
    </Modal>
  )
}

export default connect(state => ({
  propertiesConflictModal: propertiesConflictDialog(state)
}), ({
  pasteNodeProperties: pasteNodePropertiesAction,
  clearPropertiesConflictDialog: UiCreators.clearPropertiesConflictDialog
}))(PropertiesConflictDialog)