import React from 'react'
import { Icon } from 'antd'

import styles from './LoadingProject.scss'

const LoadingProject = () => (
  <div className={styles.LoadingProject}>
    <Icon type="loading" /> Loading ...
  </div>
)

export default LoadingProject