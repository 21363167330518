import moment from 'moment'

const RevisionCache = {
  // this is still not used ! we manually instantiate services for the moment
  name: 'revisionsCache',
  dependsOn: ['localDB'],

  start: db => {

    return {
      get(revisionId) {
        return db.revisions.get({ revisionId })
      },

      /**
       * timestamp here is optional just for testing
       */
      async put(revisionId, objects, timestamp) {
        const item = {
          revisionId,
          timestamp: timestamp || moment.utc().toISOString(),
          objects,
        }
        await db.revisions.put(item)
        return item
      },

      remove(revisionId) {
        return db.revisions.clear(revisionId)
      }
    }
  }

}

export default RevisionCache