import { Modifier, EditorState } from 'draft-js'
import { pipe, reduce, flatten, dropRepeats, prop, map, identity } from 'ramda'
import BlockType from 'components/TextEditor/BlockType'
import { createSelectionAt, createSelectionOnAllBlockContent, createSelectionOnBlock } from 'components/TextEditor/draftjs-utils'
import { isCursorWithoutSelection } from 'components/TextEditor/draftjs-api'
import { ChangeType } from './draftjs-constants';

// Content Mutators (contentState, selection) => contentState

export const composeMutator = (...mutators) => (c, selection) => reduce((content, mutator) => mutator(content, selection), c, mutators)

export const setBlockType = type => (c, selection) => Modifier.setBlockType(c, selection, type)
export const setLineType = selectionState => c => Modifier.setBlockType(c, selectionState, BlockType.line)

export const getAllInlineStylesForBlock = pipe(
  block => block.getCharacterList().toJS(),
  map(prop('style')),
  flatten,
  dropRepeats
)

export const clearAllInlineStylesFromBlock = (block, styles) => c => {
  const selection = createSelectionOnAllBlockContent(block)
  return clearInlineStyles(styles || getAllInlineStylesForBlock(block))(c, selection)
}

export const clearInlineStyles = (styles, selectionMapping = identity) => (c, selection) => reduce((content, style) =>
  Modifier.removeInlineStyle(content, selectionMapping(selection), style), c
)(styles)

export const applyInlineStyle = (selection, style) => content => Modifier.applyInlineStyle(content, selection, style)

export const applyInlineStyleFromAnchor = (style, length) => (c, selection) => applyInlineStyle(
  createSelectionAt(selection.getAnchorKey(), selection.getStartOffset(), selection.getStartOffset() + length),
  style
)(c, selection)

export const setCursorAt = (blockKey, offset) => (c, s) => c.merge({
  selectionBefore: s,
  selectionAfter: s.merge({
    anchorKey: blockKey,
    anchorOffset: offset,
    focusKey: blockKey,
    focusOffset: offset,
    isBackward: false,
    hasFocus: true
  }),
})

// general mutations
export const commit = (state, changeType) => content => EditorState.push(state, content, changeType)
export const insertText = (text, selectionState, { styles, entity } = {}) => (content, selection) => Modifier.insertText(content, selectionState || selection, text, styles, entity)
export const replaceText = (text, selectionState, { styles, entity } = {}) => (content, selection) => Modifier.replaceText(content, selectionState || selection, text, styles, entity)

export const deleteSelectionFromContent = (c, selection) => Modifier.replaceText(c, selectionToDelete(selection), '')
const selectionToDelete = selection => (isCursorWithoutSelection(selection) ?
  selection.merge({ focusOffset: selection.getAnchorOffset() - 1, isBackward: true })
  : selection
)

export const appendBlock = block => c => c.merge({
  blockMap: c.getBlockMap().toSeq().concat([[block.getKey(), block]]).toOrderedMap()
})

/**
 * Replaces the current selection by transforming the text with a transformer (String => String)
 */
export const replaceSelectionWith = transformer => (c, selection) =>
  Modifier.replaceText(c, selection, transformer(getSelectionContent(c, selection)))

// TODO: and getType() === BlockType.dirComment?
export const isEmptyBlock = (c, selection) => c
  .getBlockForKey(selection.getAnchorKey())
  .getText()
  .trim() === ''
export const isWholeBlockSelected = (c, selection) => {
  if (selection.isCollapsed() || selection.getStartKey() !== selection.getEndKey()) return false

  const length = c
    .getBlockForKey(selection.getStartKey())
    .getLength()

  return selection.getStartOffset() === 0 && selection.getEndOffset() === length
}

// doesn't support cross-block selection
export const getSelectionContent = (c, selection) => c
  .getBlockForKey(selection.getAnchorKey())
  .getText()
  .substring(
    ...(selection.getIsBackward() ?
      [selection.getFocusOffset(), selection.getAnchorOffset()]
      : [selection.getAnchorOffset(), selection.getFocusOffset()]
    )
  )

export const createEntityOnSelection = (type, mutability, selectionMapping) => (c, s) => {
  const contentWithEntity = c.createEntity(type, mutability)
  return Modifier.applyEntity(
    contentWithEntity,
    selectionMapping(s),
    contentWithEntity.getLastCreatedEntityKey()
  )
}

export const removeEntityOnSelection = selectionMapping => (c, s) =>
  Modifier.applyEntity(c, selectionMapping(s), null)

// *********
// ** State mutators editorState => editorState
// *********

export const updateContent = (changeType, contentTransformer) => state => commit(state, changeType)(
  contentTransformer(state.getCurrentContent(), state.getSelection())
)

export const forceSelection = updater => state => EditorState.forceSelection(state, updater(state.getSelection()))

export const deleteSelection = state => commit(state, ChangeType.removeRange)(
  Modifier.replaceText(state.getCurrentContent(), state.getSelection(), '')
)

export const replaceSelectionWithFragment = fragment => state => Modifier.replaceWithFragment(state.getCurrentContent(), state.getSelection(), fragment)
export const LINE_REGEX = /^([A-Z]+:[\S\s\n]+.*)$/gu
export const replaceSelectionWithFragmentKeepingType = fragment => state => {
  let newContent = replaceSelectionWithFragment(fragment)(state)
  const isWhollySelected = isWholeBlockSelected(state.getCurrentContent(), state.getSelection())
  const isEmpty = isEmptyBlock(state.getCurrentContent(), state.getSelection())
  const type = fragment.first().getType()
  const text = fragment.first().getText()
  if ((isEmpty || isWhollySelected) && fragment.size === 1 && type === BlockType.line && text.match(LINE_REGEX)) {
    // replaceWithFragment looses type when pasting a single line, so we are forcing retain here
    newContent = setLineType(newContent.getSelectionAfter())(newContent)
  }
  // if the modified fragment is type === line then it should be parseable as a line
  const newSelection = newContent.getSelectionAfter()
  const currentBlock = newContent.getBlockForKey(newSelection.getStartKey())
  if (newSelection.isCollapsed() && BlockType.line === currentBlock.getType() && !currentBlock.getText().match(LINE_REGEX)) {
    // then we have a block with type LINE which is not parseable as an actor line, we 'll make it a dir comment
    newContent = setBlockType(BlockType.directorComment)(newContent, newSelection)
  }
  return newContent
}

export const setCursorToStart = (editorState, lineOffset = 0) => {
  const block = editorState.getCurrentContent().getFirstBlock()
  const cursorAt = Math.min(block.getText().length, lineOffset)
  return EditorState.forceSelection(editorState, createSelectionOnBlock(
    block.getKey(),
    {
      anchorOffset: cursorAt,
      focusOffset: cursorAt,
      hasFocus: true
    }
  ))
}

export const setCursorToEnd = (editorState, lineOffset) => {
  const block = editorState.getCurrentContent().getLastBlock()
  const cursorAt = lineOffset ? Math.min(block.getText().length, lineOffset) : block.getText().length
  return EditorState.forceSelection(editorState, createSelectionOnBlock(
    block.getKey(),
    {
      anchorOffset: cursorAt,
      focusOffset: cursorAt,
      hasFocus: true
    }
  ))
}

export const insertTextIntoState = (text, options) => updateContent(
  ChangeType.insertCharacters,
  insertText(text, undefined, options)
)

export const replaceTextIntoState = (text, options, selection) => updateContent(
  ChangeType.insertCharacters,
  replaceText(text, selection, options)
)
