exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".avatarContainer__mjzfA{cursor:pointer;position:relative;width:100%;height:100%}.avatarContainer__mjzfA .ant-avatar{height:120px;width:120px;line-height:120px;font-size:40px}.avatarContainer__mjzfA:hover img{opacity:.3}.avatarContainer__mjzfA:hover .changeAvatarOverlay__2-ktW{opacity:1}.avatarContainer__mjzfA img{opacity:1;display:block;-webkit-transition:.5s ease;-o-transition:.5s ease;transition:.5s ease;-webkit-backface-visibility:hidden;backface-visibility:hidden}.avatarContainer__mjzfA .changeAvatarOverlay__2-ktW{-webkit-transition:.5s ease;-o-transition:.5s ease;transition:.5s ease;opacity:0;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);text-align:center}", ""]);

// exports
exports.locals = {
	"avatarContainer": "avatarContainer__mjzfA",
	"changeAvatarOverlay": "changeAvatarOverlay__2-ktW"
};