import { pathOr } from 'ramda'
import { model } from 'beanie-engine-api-js'
import { toBNEAction } from './utils'

const { types: { object: { Paths } } } = model

export const toggleAutoChoice = toBNEAction(
  choice => api => {
    const current = pathOr(false, Paths.choice.auto_choice, choice)
    api.obj.get(choice.id).set_auto_choice(!current)
  },
  'Toggle auto-choice'
)