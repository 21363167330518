import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

/**
 * Given a selector it computes the current but also the previous value.
 *
 * @returns [currentValue, previousValue]
 */
const useSelectorWithPreviousValue = selector => {
  const currentValue = useSelector(selector)
  const prevRef = useRef()

  useEffect(() => {
    if (prevRef.current !== currentValue) {
      prevRef.current = currentValue
    }
  })

  return [currentValue, prevRef.current]
}

export default useSelectorWithPreviousValue