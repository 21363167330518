exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editor__2sGdu{width:auto}.bulletKeyword__32f1z{background-color:#e4bee9}.bulletStaticVariable__2WkpL{background-color:#8cb3da}.bulletDynamicVariable__2-DL5{background-color:#d6a68f}.bulletOperator__2VYsf{background-color:#9acfa8}.bulletFact__37JSw{background-color:#9acccf}", ""]);

// exports
exports.locals = {
	"editor": "editor__2sGdu",
	"bulletKeyword": "bulletKeyword__32f1z",
	"bulletStaticVariable": "bulletStaticVariable__2WkpL",
	"bulletDynamicVariable": "bulletDynamicVariable__2-DL5",
	"bulletOperator": "bulletOperator__2VYsf",
	"bulletFact": "bulletFact__37JSw"
};