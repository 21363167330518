import { noop } from 'ramda-adjunct'
import Groups from './Groups'
import Namespaces from 'shortcuts/Namespaces'


export const PRESSED_F5 = {
  group: Groups.Others, // TODO don't display this shortcut on Help
  action: noop,
  shortcut: 'f5',

  namespace: Namespaces.Project.Global,

  preventDefault: true,
  stopPropagation: true,
}
