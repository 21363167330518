import { connect } from 'react-redux'
import { makeSelectorForPreference } from '../selectors/view'

/**
 * Like withPreference but for a single preference.
 * It correctly memoizes/updates not like withPreference which is not doing it well
 */
const withSinglePreference = (propName, preference) =>
  connect(() => {
    const selector = makeSelectorForPreference(preference)
    return state => ({
      [propName]: selector(state)
    })
  })

export default withSinglePreference