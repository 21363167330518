exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ShortTimestamp__3Wx8H{color:gray;font-size:.7rem;margin-left:.5rem}", ""]);

// exports
exports.locals = {
	"ShortTimestamp": "ShortTimestamp__3Wx8H"
};