exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ChangeSetChangesCells__1eBIH{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding-left:1rem;padding-right:1rem;padding-top:.5rem;padding-bottom:.5rem;-ms-flex-align:center;align-items:center}.ChangeSetChangesCells__1eBIH .ChangeCell__11Uat{width:.5rem;height:.5rem;min-width:.5rem;background-color:gray}.ChangeSetChangesCells__1eBIH .ChangeCell__11Uat.ADDED__1Xli2{background-color:#82cd82}.ChangeSetChangesCells__1eBIH .ChangeCell__11Uat.DELETED__3fyXZ{background-color:#d84646}.ChangeSetChangesCells__1eBIH .ChangeCell__11Uat.UPDATED__9mwv4{background-color:#f0b446}.ChangeSetChangesCells__1eBIH .ChangeCell__11Uat:not(:last-child){margin-right:.2rem}.ChangeSetChangesCells__1eBIH>span{font-size:.6rem;padding-left:.5rem}", ""]);

// exports
exports.locals = {
	"ChangeSetChangesCells": "ChangeSetChangesCells__1eBIH",
	"ChangeCell": "ChangeCell__11Uat",
	"ADDED": "ADDED__1Xli2",
	"DELETED": "DELETED__3fyXZ",
	"UPDATED": "UPDATED__9mwv4"
};