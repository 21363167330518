import { cond, defaultTo, keys, nthArg, pipe, propEq, T } from 'ramda'

import { isFunction } from 'utils/object'

export const createReducer = fn => (state, action) => {
  const r = fn(state, action)
  return isFunction(r) ? r(state) : r
}

// did not work with ramda's own uncurryN + flip (unsetActivePreset did not work)
const flipArgs = (fn, initialState) => (state = initialState, action) => fn(action)(state)
const getState = nthArg(0)
const getAction = nthArg(1)
const isAction = type => pipe(getAction, propEq('type', type))
export const reducerCases = (mapping, initialState) => keys(mapping).map(type => [isAction(type), flipArgs(mapping[type], initialState)])
export const createReducerFlipped = (mapping, initialState = {}) => cond([
  ...reducerCases(mapping, initialState),
  [T, pipe(getState, defaultTo(initialState))]
])
