exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".teamHeader__18oUr{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;padding-left:2rem;padding-right:2rem;background-color:#fff;border-bottom:1px solid #e1e4e8}", ""]);

// exports
exports.locals = {
	"teamHeader": "teamHeader__18oUr"
};