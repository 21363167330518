import React from 'react'
import ResultExpr from '../../../TreeChain/NodeWithEnabledRule/BulletResult'
import styles from '../TruthTable.scss'

const RowBulletResult = ({ result }) => (
  <div className={styles.bulletResult}>
    <ResultExpr
      className={styles.bullet}
      result={result}
      isDirty={false}
      shouldEval
    />
  </div>
)

export default RowBulletResult