exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".decorations__2689Z{-ms-flex-direction:row;flex-direction:row;display:-ms-flexbox;display:flex;position:absolute;z-index:10;bottom:17px;left:-6px;text-align:center}.decorations__2689Z .anticon{padding-left:.1em}.decorations__2689Z .Decoration__3lrqR{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:left;justify-content:left;width:auto;height:1.2em;font-size:.8em;padding-left:.3em;padding-right:.3em;background:#fff;border-radius:10px 10px 10px 10px;border:1px solid rgba(128,128,128,.58);color:rgba(128,128,128,.77)}.decorations__2689Z .Decoration__3lrqR .character__2AYpl{font-size:.5rem}", ""]);

// exports
exports.locals = {
	"decorations": "decorations__2689Z",
	"Decoration": "Decoration__3lrqR",
	"character": "character__2AYpl"
};