import { ifElse, pipe, when } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { subnodesToText } from '../textToModel/utils'
import { NODE_TYPES } from '../utils/Constants'
import { isBlockOfType } from '../utils/nodeUtils'
import { getTextPart } from './DialogueLine'

/**
 * This file contains the API for Line blocks. Like an ADT
 * As we don't work with classes and OOP we have a separation between behavior and state.
 * But that doesn't mean some of those behaviors represent core object reponsabilities.
 * Like inherent behavior for all Lines with dispatch functions acting as method lookup
 * in a OOP hierarchy.
 * Conceptually we also still have subtypes: Line = DialogueLine | DirectorLine
 *
 * This API can be thought as this in OOP
 *
 * type Line {
 *
 *   isDialogueLine(): Boolean
 *   isDirectorLine(): Boolean
 *
 *   getLineText(): String
 *
 * }
 *
 */

// isXXX type
export const isDialogueLine = isBlockOfType(NODE_TYPES.DIALOGUE_LINE)
export const isDirectorLine = isBlockOfType(NODE_TYPES.DIRECTOR_LINE)

//  getLineText

const getLineTextForDir = subnodesToText
const getLineTextForDialogue = pipe(getTextPart, when(isNotNil, subnodesToText))
export const getLineText = ifElse(isDirectorLine, getLineTextForDir, getLineTextForDialogue)
