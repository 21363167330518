import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { assoc, equals, isNil, keys } from 'ramda'
import CodeMirror from 'rodemirror'
import { lang } from 'beanie-engine-api-js'
import { noop } from 'utils/functions'

import { indentMore, indentLess, deleteCharBackward } from '@codemirror/commands'
import { completionStatus } from '@codemirror/autocomplete'
import { historyKeymap } from '@codemirror/history'
import { basicSetup } from '@codemirror/basic-setup'
import { keymap } from '@codemirror/view'

import { factsByName, factsTypes } from 'selectors/objects/facts'

import rules from './Extensions/RuleLanguageExtension'
import nodeDecorations from './Extensions/Decorations'
import NodeComponentRef from './NodeComponentRef'
import { AutocompleteIcon } from './AutocompleteComponent'
import { linter } from './Extensions/Linter'
import { editorText } from './Extensions/TreeUtils'
import theme from './Extensions/Theme'
import classNames from 'classnames'

import styles from './ExprEditor.scss'

const { rule: { typing: { types } } } = lang

const style = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto'
}

const customKeymap = ({ onApply, onCancel }) => [
  { key: 'Enter', run: cm => onApply(cm, editorText(cm)) },
  { key: 'Escape', run: onCancel },
  { key: 'Tab', run: indentMore, shift: indentLess },
  { key: 'Backspace', run: deleteCharBackward, shift: deleteCharBackward },
  ...historyKeymap
]

const isActive = equals('active')

/**
 *
 */
const ExprEditor = ({
  value: defaultValue,
  expectedType = types.Any,
  withSyntheticMatch = false,
  onBlur,
  setValue,
  onCancel = noop,
  onApply = noop,
  variables,
  linterEnabled = false,
  possibleStaticVariablesValues,
  findMatchRefs,
  autoFocus,
  className,
  itValue = undefined,
  store
}) => {
  const editorView = useRef()
  const [writeValue] = useState(defaultValue)
  const [autocompleteState, setAutocompleteState] = useState(null)
  const [nodeIdRefs, setNodeIdRefs] = useState({})
  const [autocompletions, setAutocompletions] = useState({})
  const factsIndex = useSelector(factsByName)
  const factTypesIndex = useSelector(factsTypes) 

  const registerWidget = useCallback((key, value) => {
    setNodeIdRefs(prev => assoc(key, value, prev))
  }, [nodeIdRefs, setNodeIdRefs])

  const registerAutocompletion = useCallback((key, value) => {
    setAutocompletions(prev => assoc(key, value, prev))
  }, [autocompletions, setAutocompletions])

  const decoPlugin = useMemo(() => nodeDecorations(registerWidget), [registerWidget])
  const factNames = useMemo(() => keys(factsIndex), [factsIndex])

  const extensions = useMemo(() => [
    keymap.of(customKeymap({ onApply, onCancel })),
    basicSetup,
    rules({
      store,
      factNames,
      variables,
      registerAutocompletion,
      possibleStaticVariablesValues,
      findMatchRefsPromise: findMatchRefs,
      factTypesIndex
    }),
    theme,
    linter({ expectedType, syntheticMatch: withSyntheticMatch, linterEnabled, factTypesIndex, store }),
    decoPlugin
  ], [
    onCancel,
    onApply,
    factNames,
    linterEnabled,
    variables,
    withSyntheticMatch,
    possibleStaticVariablesValues,
    factTypesIndex,
    store,
  ])

  useEffect(() => { if (autoFocus && editorView.current && !editorView.current.hasFocus) { editorView.current.focus() } }, [editorView.current])

  return (
    <div
      className={classNames(styles.editor, className)}
      onBlur={onBlur}
    >
      <CodeMirror
        value={writeValue}
        style={style}
        onUpdate={view => {
          if (editorView.current) {
            const state = completionStatus(editorView?.current?.state)

            // This unregister all the autocompletions registered
            if (!isNil(autocompleteState) && isNil(state)) { setAutocompletions({}) }

            if (autocompleteState !== state) { setAutocompleteState(state) }
          }

          if (view.docChanged) {
            const val = view.state.doc.toString()
            setValue(val)
          }
        }}
        onEditorViewChange={view => {
          if (!editorView.current && view) { editorView.current = view }
        }}
        extensions={extensions}
      />
      {Object.entries(nodeIdRefs).map(([key, { nodeId, container }]) => (
        <NodeComponentRef key={key} nodeId={nodeId} container={container} itValue={itValue} />)
      )}
      {/* We need to know if the autocomplete is active to avoid flickering */}
      {isActive(autocompleteState) && Object.entries(autocompletions).map(([key, { completion, containerId }]) => (
        <AutocompleteIcon key={key} completion={completion} containerId={containerId} />)
      )}
    </div>
  )
}

export default ExprEditor