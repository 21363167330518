import React from 'react'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers, withStateHandlers } from 'recompose'
import { F, pipe } from 'ramda'
import { Input } from 'antd'
import { push as pushAction } from 'connected-react-router'

import organizationCreate from '../../api/mutations/organizationCreate.graphql'
import routes from 'components/Organizations/routes'
import ModalOpener from 'components/Commons/ModalOpener'
import { Errors } from 'components/Commons/Error'
import { withTargetValue } from 'utils/antd'

import styles from '../../styles/commons.scss'
import { parseGraphQLErrors } from 'utils/graphql'

const { TextArea } = Input

const CreateOrgModal = ({ errors, name, setName, description, setDescription, onCreateNewOrg, onCancel, confirmLoading }) => (
  <ModalOpener
    title="Create Organization"
    onOk={onCreateNewOrg}
    onCancel={onCancel}
    okButtonProps={{ disabled: !name }}
    modalProps={{ destroyOnClose: true, confirmLoading }}
  >
    <div className={styles.formFieldsVerticalGutter}>
      <Input placeholder="Name" value={name} onChange={withTargetValue(setName)} onPressEnter={onCreateNewOrg} />
      <TextArea rows={3} placeholder="Description" value={description} onChange={withTargetValue(setDescription)} />
      {errors && errors.length > 0 &&
        <Errors items={errors} />
      }
    </div>
  </ModalOpener>
)

const onCreateNewOrg = ({ createOrg, hide, name, onCreated, description, setConfirmLoading, setErrors, push }) => async () => {
  try {
    setConfirmLoading(true)
    await createOrg({ variables: { input: { name, description } } })
    push(routes.organizationFor(name))
    setConfirmLoading(false)
    onCreated()
    hide()
  } catch (e) {
    if (!e.graphQLErrors) {
      setErrors([e.message])
      throw e
    }
    setErrors(parseGraphQLErrors(e))
  }
}

export default compose(
  connect(undefined, { push: pushAction }),
  graphql(organizationCreate, { name: 'createOrg' }),
  withStateHandlers(
    { name: undefined, description: undefined, errors: [], confirmLoading: false },
    {
      setDescription: prev => description => ({ ...prev, description }),
      setName: prev => name => ({ ...prev, name }),
      setErrors: prev => errors => ({ ...prev, errors, confirmLoading: false }),
      setConfirmLoading: prev => confirmLoading => ({ ...prev, confirmLoading })
    }
  ),
  withHandlers({
    onCreateNewOrg,
    onCancel: ({ hide, setConfirmLoading }) => pipe(F, setConfirmLoading, hide)
  })
)(CreateOrgModal)
