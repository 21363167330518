import { createSelector } from 'selectors/reselect'
import { pipe, mapObjIndexed, values, prop, pathOr, path } from 'ramda'

import winston from 'utils/logger'

import { Sys, DEFAULT_LOCALE, parseRef } from 'beanie-engine-api-js'


import { propFrom, isEmpty } from 'utils/object'

import { objectsIndex } from 'selectors/apollo'
import { playbackNodeIdFromProp, propFromProps } from 'selectors/props'

import commonStyles from 'styles/commons.scss'

//
// generic labels to be used from components (new approach)
//

export const resolver = index => pipe(parseRef, propFrom(index))

// NOTE: this calculates all labels everytime an object changes
// there is no easy way to fix this.
// using "createObjectSelector" won't work because each object gets copied
// comparing each object by deepEqual to recompute only some keys (objects) still 
// won't work, because some object's labels, depend on other objects
// so there's like a dependency graph. Iex. if you change a lang_res maybe that should trigger
// recomputing a clip that had a line that had a take that had that lang_res
// the whole labeling implementation should change to something more reactive.
export const objectLabelIndex = createSelector('objectLabelIndex',
  objectsIndex,
  mapObjIndexed((o, id, index) => ({
    id: o.id,
    sys: o.sys,
    name: labelFor(o, resolver(index))
  }))
)


// try to avoid this. Use objectLabelIndex instead
export const objectLabelList = createSelector('objectLabelList', objectLabelIndex, values)

export const labelFor = (o, _resolver) => {
  const resolveLabel = ref => {
    if (ref) {
      const referenced = _resolver(ref, _resolver)
      if (!referenced) {
        // inconsistent model !
        winston.warn('Reference to non-existent object', ref)
        return undefined
      }
      return labelFor(referenced, _resolver)
    } else {
      return undefined
    }
  }
  switch (o.sys) {
    // derived
    case Sys.jump: return resolveLabel(o.data.target)
    case Sys.choice: return resolveLabel(o.data.line)
    // clip
    case Sys.take: return resolveLabel(o.data.locales[DEFAULT_LOCALE])
    case Sys.line: return resolveLabel(o.data.selected_take)
    case Sys.clip: return !isEmpty(o.data.lines) ? resolveLabel(o.data.lines[0]) : undefined
    // terminals
    case Sys.project: return 'Project'
    case Sys.actor: return o.data.actorName
    case Sys.language_resource: return o.data.text
    default: return o.data?.name
    // TODO: condition
  }
}

export const createLabelSelector = (idSelector = playbackNodeIdFromProp) => 
  createSelector([idSelector, objectLabelIndex], prop)
createLabelSelector.noDebug = true


//
// Tree Node labels: old label just used from the tree
// REVIEWME: see if we should unify and use the selector that is above
//

const labelWithStyle = (value, labelStyle) => ({ value, labelStyle })

const labelFromRef = (resolve, reference, fromRef = false) => {
  const referenced = resolve(reference)
  const refLabel = getNodeLabel(resolve, referenced)
  return labelWithStyle(refLabel.value || parseRef(reference), fromRef ? commonStyles.labelFromRef : undefined)
}

export /* to test */ const getNodeLabel = (resolve, object) => {
  const defaultLabel = () => labelWithStyle(pathOr('', ['data', 'name'], object))

  if (!object) {
    // hack for unresolved objects
    return { value: '', labelStyle: undefined }
  }

  switch (object.sys) {

    case Sys.line: return labelFromRef(resolve, path(['data', 'selected_take'], object))
    case Sys.take: return labelFromRef(resolve, path(['data', 'locales', DEFAULT_LOCALE], object))

    case Sys.language_resource: {
      const text = path(['data', 'text'], object)
      return text ? labelWithStyle(text) : defaultLabel()
    }

    case Sys.choice: if (object.data.line) return labelFromRef(resolve, object.data.line)

    case Sys.jump: if (object.data.target) return labelFromRef(resolve, object.data.target, true)

    case Sys.clip: {
      if (object.data.name) return defaultLabel()
      if (object.data.lines && object.data.lines.length > 0) return labelFromRef(resolve, object.data.lines[0])
    }

    // referencing by conventions (we will get rid of that eventually)

    case Sys.conditional: {
      const referenced = resolve(object?.data?.name)
      if (referenced) {
        const referencedLabel = getNodeLabel(resolve, referenced)
        return labelWithStyle(referencedLabel.value || referenced.id, commonStyles.labelFromRef)
      }
    }

    case Sys.condition: if (object.data.state_node) return labelFromRef(resolve, object.data.state_node)
    default: return defaultLabel()
  }
}


const nodeFromProps = propFromProps('node')
export const createTreeLabelSelector = () => createSelector(
  [nodeFromProps, objectsIndex],
  (node, index) => getNodeLabel(resolver(index), node)
)