import memoize from 'memoize-state'
import { filter, isEmpty, path, propEq, reduce } from 'ramda'
import { mapIndexed } from 'utils/ramda'
import { lang, model } from 'beanie-engine-api-js'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { factsByName, factsTypes } from 'selectors/objects/facts'
import { checkRule } from './rule'

const { types: { object: { Paths } } } = model
const { rule: { typing: { types } } } = lang

export const makeTruthTableRowRuleCellsCheck = ({ id }) => {
  const mSelector = memoize((objectIndex, factIndex, factsTypesIndex) => {
    const cells = path(Paths.truth_table_row.cells, objectIndex[id])

    const cellsCheckResult = mapIndexed(cell =>
      checkRule({
        objectIndex,
        factIndex,
        factsTypesIndex,
        rule: { source: cell.source, program: cell.rule },
        ruleType: types.Bool,
        headerMessage: 'Row cell',
        checks: { isUndefined: false }
      }), cells)

    const errors = filter(propEq('status', CHECK_RESULT_STATUS.ERROR), cellsCheckResult)

    return isEmpty(errors) ? OK_RESULT : {
      status: CHECK_RESULT_STATUS.ERROR,
      severity: CHECK_SEVERITY_LEVEL.ERROR,
      id: 'With cells errors',
      message: reduce((acc, { message }) => `${acc}${message}\n`, '', errors)
    }
  })

  const selector = ({ objectIndex, state }) => mSelector(objectIndex, factsByName(state), factsTypes(state))

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Truth table row cells consistent',
    makeSelector: makeTruthTableRowRuleCellsCheck
  }
]