import { NODE_TYPES, OBJECT_TYPES } from '../../Constants'

export default {
  parent: [{ type: NODE_TYPES.DIALOGUE_LINE }],
  nodes: [
    {
      match: [{ object: OBJECT_TYPES.text }, 
        { type: NODE_TYPES.PERFORMANCE_NOTE },
        { type: NODE_TYPES.PRODUCTION_NOTE },
        { type: NODE_TYPES.MARK_UP }
      ],
    },
  ],
}