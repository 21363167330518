import { reverse, prop, head, dropLast, pipe, indexBy, last, equals, not, filter, map, when } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { isContained, isContainer, parseRef } from 'beanie-engine-api-js'
import { objectsIndex } from 'selectors/selectors'
import { createPathTo } from 'selectors/paths'

import { previousAndNextElements } from 'utils/list'

// TODO: doesn't support
// - moving to the previous/next root node if you are deleting a root node or the whole chain
// I think that the ideal implementation should work with the whole "selection model" (simple, multiple, range, etc)
// plus have access to the store to get paths and lanes, etc
const makeComputeClosestNodeToSelectWhenDeleting = _selectedNodes => state => {
  const selectedNodesArg = reverse(_selectedNodes)
  const selectedIdsIndex = indexBy(prop('id'), selectedNodesArg)
  const isSelected = id => !!selectedIdsIndex[id]
  const lastSelected = head(selectedNodesArg)

  return lookupParent(selectedNodesArg, isSelected)
    || (lastSelected && lookupClosestToLastSelected(lastSelected, isSelected, state))
}

export default makeComputeClosestNodeToSelectWhenDeleting

// has parent not part of the selection
const lookupParent = (selectedNodesArg, isSelected) => {
  const nodeWithParent = selectedNodesArg.find(node => node.data.parent && !isSelected(parseRef(node.data.parent)))
  return nodeWithParent && parseRef(nodeWithParent.data.parent)
}

// new impl supports container contents. Maybe it will replpace the `lookupParent` completely
const lookupClosestToLastSelected = (object, isSelected, state) => {
  const index = objectsIndex(state)
  const reversePath = pipe(
    createPathTo(object.id),
    dropLast(1),
    reverse
  )(state)
  
  const foundId = reversePath.find(id => !isSelected(id))
  if (foundId === undefined) { return undefined }
  const foundObj = index[foundId]

  return isContainer(foundObj) && isContained(object) ?
    computeClosestContainerComponentToSelect(object, foundObj, isSelected)
    : foundId
}

const computeClosestContainerComponentToSelect = (component, container, isSelected) => {
  const sibling = nextOrPreviousSibling(container.data.container_contents, component, isSelected)
  return sibling || container.id
}

const nextOrPreviousSibling = (elements, component, isSelected) => {
  const i = elements.findIndex(pipe(parseRef, equals(component.id)))

  if (i < 0) { return undefined }

  return pipe(
    previousAndNextElements(i),
    map(filter(pipe(parseRef, isSelected, not))),
    ([previousComponents, nextComponents]) => head(nextComponents) || last(previousComponents),
    when(isNotNil, parseRef)
  )(elements)
}