exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2B2zp{color:#add8e6;cursor:pointer}.iconAsLink__2B2zp:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__11dS2{font-style:italic}.formFieldsVerticalGutter__1lpk3{padding:.5rem}.formFieldsVerticalGutter__1lpk3>*{margin:.5rem !important}.tableBackground__3yBOD .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3yBOD .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2ytv- .ant-notification-notice-close{display:none}.choices__33s1F{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:1rem}.choices__33s1F .choiceType__XE8S6{color:#afafaf;font-size:.7rem;font-style:italic;font-weight:normal;letter-spacing:.1rem}.choices__33s1F .choicesName__10RdQ{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;font-size:1.2em;padding-bottom:1em}.choices__33s1F .choicesName__10RdQ .expired__c7yEC{color:#b8860b;margin-left:1rem;font-size:.8rem}.choices__33s1F .choicesList__3cKNh{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:center;justify-content:center}.choices__33s1F .choicesList__3cKNh .choice__1VTCc:not(:last-child){margin-right:.8em}.choices__33s1F .choicesList__3cKNh .choice__1VTCc{list-style-type:none}.choiceContainer__1JzUJ{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.pinnedLabel__3WHj5{color:#519e65;font-size:x-small}.pinnedChoice__gXOEa{background-color:#6eda8a !important}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2B2zp",
	"labelFromRef": "labelFromRef__11dS2",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1lpk3",
	"tableBackground": "tableBackground__3yBOD",
	"notificationNotClosable": "notificationNotClosable__2ytv-",
	"choices": "choices__33s1F",
	"choiceType": "choiceType__XE8S6",
	"choicesName": "choicesName__10RdQ",
	"expired": "expired__c7yEC",
	"choicesList": "choicesList__3cKNh",
	"choice": "choice__1VTCc",
	"choiceContainer": "choiceContainer__1JzUJ",
	"pinnedLabel": "pinnedLabel__3WHj5",
	"pinnedChoice": "pinnedChoice__gXOEa"
};