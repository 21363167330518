import { Key } from 'utils/keyboard'

export default () => ({
  onKeyDown (event, editor, next) {
    if ((event.ctrlKey || event.shiftKey) && event.keyCode === Key.ENTER) {
      event.preventDefault()
      event.stopPropagation()
      editor.insertText('\n')
      return false
    }

    return next()
  },
})
