import { Icon } from 'antd'
import React from 'react'
import { connect } from 'react-redux'
import { isContainer, Sys } from 'beanie-engine-api-js'
import classNames from 'classnames'

import { textViewItemIdFor } from 'dom/dom'

import { makeObjectFromIdSelector } from 'selectors/objects'
import { makeAmISelectedSelector } from 'selectors/nodeSelection'
import { propFromProps } from 'selectors/props'

import TreeNodeCursors from 'components/TreeView/TreeNodeCursors/TreeNodeCursors'

import TextEditorView from './View/TextEditorView'
import ContainerTextView from './View/ContainerTextView'
import JumpTextView from './View/JumpTextView'
import MarkerTextView from './View/MarkerTextView'
import TimerTextView from './View/TimerTextView'
import LaneTextView from './View/LaneTextView'
import ActionTextView from './View/ActionTextView'
import StopTextView from './View/StopTextView'
import LuaTextView from './View/LuaTextView'

import styles from './TextViewItem.scss'

const UnknownSysTextView = ({ object }) => (
  <h1 className={styles.UnknownSysTextView}>
    <Icon type="warning" />
    Cannot display object of type {object.sys}
  </h1>
)

const resolveComponentClass = object => {
  switch (object.sys) {
    case Sys.marker: return MarkerTextView
    case Sys.lua: return LuaTextView
    case Sys.timer: return TimerTextView
    case Sys.jump: return JumpTextView
    case 'lane': return LaneTextView
    case Sys.action: return ActionTextView
    case Sys.clip: return TextEditorView
    case Sys.stop: return StopTextView
    default: {
      return isContainer(object) ? ContainerTextView : UnknownSysTextView
    }
  }
}

const View = (object, others) => {
  const Component = resolveComponentClass(object)
  return <Component object={object} {...others} />
}

export const _TextViewItem = ({ object, isSelected, ...others }) => (object ? (
  <div
    {...object.id && { id: textViewItemIdFor(object.id) }}
    className={classNames(styles.item, styles[object.sys], { [styles.selected]: isSelected })}
  >
    <TreeNodeCursors node={object} />
    {View(object, others)}
  </div>
) : null)

export default connect(() => {
  const objectFromId = makeObjectFromIdSelector()
  const amISelected = makeAmISelectedSelector(propFromProps('id'))
  return (state, props) => ({
    object: objectFromId(state, props),
    isSelected: amISelected(state, props),
  })
})(_TextViewItem)