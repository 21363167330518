exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2c9PO{display:-ms-flexbox;display:flex;width:100%;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;-ms-flex-direction:column;flex-direction:column}.container__2c9PO .node__rstNt .anticon{padding-right:.5em}.container__2c9PO .node__rstNt .name__3BjhM{padding-right:1em;font-weight:bold}.container__2c9PO .node__rstNt a{padding-left:1em}.container__2c9PO .blockedWarning__2xxcN{background-color:#f8f8a2;padding-left:.5em;padding-right:.5em;margin-top:.5em}", ""]);

// exports
exports.locals = {
	"container": "container__2c9PO",
	"node": "node__rstNt",
	"name": "name__3BjhM",
	"blockedWarning": "blockedWarning__2xxcN"
};