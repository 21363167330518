import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isEmpty } from 'ramda'

import ProjectCard from './ProjectCard'

import NoContent from 'components/Commons/NoContent'
import LoadingMore from './LoadingMore'

import styles from './ProjectCards.scss'

export const HOME_PAGE_PROJECTS_CONTAINER_ID = 'homePageProjects'

/**
 *
 */
const ProjectCards = ({ projects, fetchMore, hasMore, refresh, token, currentUser, selectSettings, selectProject }) => {
  const projectsList = projects.myGroupedProjects.list
  if (isEmpty(projectsList)) return <NoContent message="No projects available" className={styles.projectCardsNoContent} />

  return (
    <InfiniteScroll
      scrollableTarget={HOME_PAGE_PROJECTS_CONTAINER_ID}
      dataLength={projects.myGroupedProjects.list.length}
      next={fetchMore}
      hasMore={hasMore}
      loader={<LoadingMore />}
    >
      {projectsList.map(project => (
        <ProjectCard
          key={project._id}
          refresh={refresh}
          token={token}
          selectSettings={selectSettings}
          selectProject={selectProject}
          currentUser={currentUser}
          project={project}
        />
      ))}
    </InfiniteScroll>
  )
}

export default ProjectCards