import React from 'react'
import { Icon, List } from 'antd'
import { isNotNil } from 'ramda-adjunct'

import GrantAvatar from './GrantAvatar'
import ProjectGrantRole from './ProjectGrantRole'
import UserName from '../../Commons/UserName'

import styles from './GrantAvatarListPopoverContent.scss'

export const keyForGrant = grant => `${grant.to ? grant.to._id : 'unknown'}-${grant.role}`

export const GrantAvatarListPopoverContent = ({ grants }) => (
  <List
    itemLayout="horizontal"
    dataSource={grants}
    rowKey={keyForGrant}
    className={styles.list}
    renderItem={grant => (
      <List.Item>
        <List.Item.Meta
          avatar={isNotNil(grant.to) ? <GrantAvatar grant={grant} /> : null}
          title={(
            <div className={styles.title}>
              {grant.to ? (
                <React.Fragment>
                  <div>{grant.to.__typename === 'User' ? <UserName user={grant.to} /> : grant.to.name}</div>
                  <div><ProjectGrantRole role={grant.role} /></div>
                </React.Fragment>
              ) : <div><Icon type="warning" />UNKNOWN USER</div>}

            </div>
          )}
        />
      </List.Item>
    )}
  />
)

export default GrantAvatarListPopoverContent