exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".SearchResults__36Joj{height:100%;overflow:scroll}.SearchResults__36Joj .currentlyWalking__LN8Na{background-color:#ece59c}", ""]);

// exports
exports.locals = {
	"SearchResults": "SearchResults__36Joj",
	"currentlyWalking": "currentlyWalking__LN8Na"
};