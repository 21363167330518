import { assocPath, drop } from 'ramda'
import { DEFAULT_LOCALE, model, parseRef } from 'beanie-engine-api-js'

import { setContext } from 'sync/metadataChanges-utils'
import { toBNEAction } from 'engine/actions/utils'
import dirtiedByNextValue from '../../model/changeSet/dirtiedByNextValue'
import { objectsIndex } from '../../selectors/apollo'

const { types: { object: { Paths }, metadata: { DirtyMetadata } } } = model

//
// generic actions for any path
//

const withoutData = drop(1)
const setDirtyFieldValue = (api, id, path, value) => api.update(id, assocPath(
  withoutData(DirtyMetadata.propNameToMetaByPath(path)),
  value,
  {}
))

// exported for test
export const _forceNotDirty = ({ id }, path, context) => api => {
  const { index } = context
  setDirtyFieldValue(api, id, path, dirtiedByNextValue(index[id], path, context))
}

export const forceNotDirty = toBNEAction(
  _forceNotDirty,
  'Resolve to not dirty',
  { before: (dispatch, getState) => setContext(getState()) }
)

const langResForLine = (lineId, state) => {
  const index = objectsIndex(state)
  const line = index[lineId]
  const take = index[parseRef(line.data.selected_take)]
  return index[parseRef(take.data.locales[DEFAULT_LOCALE])]
}

export const _forceDirty = (id, path) => api => setDirtyFieldValue(api, id, path, null)
export const forceDirty = toBNEAction(_forceDirty, 'Force as dirty')

//
// line audio
//

const AUDIO_PATH_STRING = Paths.language_resource.audio.join('.')

export const forceLineAudioNotDirty = lineId => (dispatch, getState) => {
  const langRes = langResForLine(lineId, getState())
  return dispatch(forceNotDirty({ id: langRes.id }, AUDIO_PATH_STRING))
}

export const forceLineAudioAsDirty = lineId => (dispatch, getState) => {
  const langRes = langResForLine(lineId, getState())
  return dispatch(forceDirty(langRes.id, AUDIO_PATH_STRING))
}

