import React from 'react'
import { useDispatch } from 'react-redux'

import { forceLineAudioNotDirty } from 'engine/actions/objectsMeta'

import IconButton from 'components/Commons/IconButton'

const MarkAsNotDirtyAudioButton = ({ lineId, isAudioDirty }) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      type="issues-close"
      disabled={!isAudioDirty}
      onClick={() => dispatch(forceLineAudioNotDirty(lineId))}
    />
  )
}

export default MarkAsNotDirtyAudioButton