import { isNil, path } from 'ramda'
import React, { useMemo, useState } from 'react'
import { Collapse, Icon } from 'antd'
import classNames from 'classnames'
import { model } from 'beanie-engine-api-js'

import { getMetadataForSys } from '../../model/metadata/UIMetadata'
import BasicProperties from './BasicProperties'
import ChainExits from './ChainExits/ChainExits'
import UserProperties from './UserProperties'
import EnabledRuleProperty from './EnabledRuleProperty'
import EffectProperty from './EffectProperty'

import CopyPropertiesModal from './CopyPropertiesModal'

import styles from './PropertiesEditor.scss'

import ObjectSelector from './ObjectSelector'

const { types: { object: { Paths } } } = model

const { Panel } = Collapse

const SectionKey = {
  basic: 'basic',
  enabledRule: 'enabledRule',
  on_enter: 'on_enter',
  on_leave: 'on_leave',
  userProps: 'userProps',
  chainExits: 'chainExits',
}
const allSectionKeys = Object.values(SectionKey)

const UserPropertiesPanelContent = ({
  object,
  editable,
  onNavigateRef,
  onPropertyChanged,
  hiddenFields,
  nonEditableFields,
  customValueProvider
}) => {
  const [sourceObject, setSourceObject] = useState()
  const [copyModalOpen, setCopyModalOpen] = useState()

  return (
    <>
      <UserProperties
        object={object}
        editable={editable}
        className={styles.customUserPropertiesTable}
        onNavigateRef={onNavigateRef}
        onPropertyChanged={onPropertyChanged}
        lens={Paths.object.user}
        hiddenFields={hiddenFields}
        nonEditableFields={nonEditableFields}
        customValueProvider={customValueProvider}
        onCopyProperties={() => setCopyModalOpen(true)}
      />
      {
        copyModalOpen &&
        <CopyPropertiesModal
          visible
          lens={Paths.object.user}
          sourceObject={sourceObject}
          targetObject={object}
          onFinish={() => setCopyModalOpen(false)}
          getObjectName={obj => obj?.id}
        >
          <h4>Object id</h4>
          <ObjectSelector setObject={setSourceObject} />
        </CopyPropertiesModal>
      }
    </>
  )
}

/**
 *
 */
const UserPropertiesEditor = ({
  editable,
  object,
  className,
  onNavigateRef,
  onPropertyChanged,
  hiddenFields = [],
  nonEditableFields = [],
  customValueProvider
}) => {

  const metadata = useMemo(() => getMetadataForSys(object?.sys), [object?.sys])

  return (
    <div className={classNames(styles.propertiesEditor, className)}>
      <Collapse bordered={false} defaultActiveKey={allSectionKeys}>
        <Panel
          header={metadata && (
            <div className={styles.sys}>
              <Icon type={metadata.icon} />
              <div className={styles.label}>{metadata.label}</div>
            </div>
          )}
          key={SectionKey.basic}>
          <BasicProperties
            object={object}
            editable={editable}
            customValueProvider={customValueProvider}
            nonEditableFields={nonEditableFields}
            onPropertyChanged={onPropertyChanged}
          />
        </Panel>
        <Panel header="Enabled when" key={SectionKey.enabledRule}>
          <EnabledRuleProperty object={object} editable={editable} />
        </Panel>
        <Panel header="On enter" key={SectionKey.on_enter}>
          <EffectProperty propertyPath={Paths.node.on_enter} object={object} editable={editable} />
        </Panel>
        <Panel header="On leave" key={SectionKey.on_leave}>
          <EffectProperty propertyPath={Paths.node.on_leave} object={object} editable={editable} />
        </Panel>
        <Panel header="User Properties" key={SectionKey.userProps}>
          <UserPropertiesPanelContent
            object={object}
            editable={editable}
            onNavigateRef={onNavigateRef}
            onPropertyChanged={onPropertyChanged}
            hiddenFields={hiddenFields}
            nonEditableFields={nonEditableFields}
            customValueProvider={customValueProvider}
          />
        </Panel>
        {object && isNil(path(Paths.node.parent, object)) && (
          <Panel header="Chain Exits" key={SectionKey.chainExits}>
            <ChainExits nodeId={object.id} />
          </Panel>
        )}
      </Collapse>
    </div>
  )
}


export default UserPropertiesEditor