import { always, pathOr, toLower } from 'ramda'
import { _current } from 'selectors/state'
import { parentFor } from 'selectors/paths'
import { objectsIndex } from 'selectors/apollo'
import { factsByName } from 'selectors/objects/facts'
import { model } from 'beanie-engine-api-js'
import { createSelector } from 'selectors/reselect'
import { projectObject, objectsBySys } from 'selectors/objects'

const { types: { object: { Paths } } } = model

// Common selector to create the expression context 
const createContext = (state, itValue) => createSelector('createContext',
  [_current, projectObject, objectsIndex, objectsBySys, factsByName],
  (current, projectObj, index, indexBySys, factIndex) => ({
    resolveIt: always(itValue),
    resolveVariable: name => pathOr(null, [projectObj.id, toLower(name)], current),
    resolveNode: nodeId => index[nodeId],
    resolveNodeVariable: (nodeId, varName) => pathOr(null, [nodeId, varName], current),
    resolveFactRule: factName => pathOr(null, Paths.fact.rule, factIndex[factName]),
    resolveContainerOf: node => parentFor(node, indexBySys)
  })
)(state)

// Selector to create the context source for an expression
export const createContextSource = itSelector => createSelector('createContextSource',
  [_current, projectObject, objectsIndex, objectsBySys, factsByName, itSelector],
  (current, projectObj, index, indexBySys, factIndex, itValue) => ({ current, projectObj, index, indexBySys, factIndex, itValue })
)

// Function to create the context by a context source
export const makeContextFromSource = ctx => ({
  resolveIt: always(ctx.itValue),
  resolveVariable: name => pathOr(null, [ctx.projectObj.id, toLower(name)], ctx.current),
  resolveNode: nodeId => ctx.index[nodeId],
  resolveNodeVariable: (nodeId, varName) => pathOr(null, [nodeId, varName], ctx.current),
  resolveFactRule: factName => pathOr(null, Paths.fact.rule, ctx.factIndex[factName]),
  resolveContainerOf: node => parentFor(node, ctx.indexBySys)
})

export default createContext
