import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { noop } from 'ramda-adjunct'

//
// Injects a boolean property "hasFocus" and tracks documment focus change to recalculated it
//
// It requires a function to be passed that will tell if the component is supposed to be focused
// or not. That should probable query document.activeElement
//

const withHasFocus = ({ name = 'hasFocus', queryDOMFunction, onFocus = noop, onBlur = noop }) => compose(
  withState(name, 'setHasFocus', props => queryDOMFunction(props)),
  withHandlers({
    hasFocusF: props => () => queryDOMFunction(props)
  }),
  lifecycle({
    componentDidMount() {
      const { setHasFocus } = this.props
      this.onFocus = () => {
        if (!this.unmounting) {
          const r = queryDOMFunction(this.props)
          if (r !== this.props[name]) {
            setHasFocus(r)
          }
        }
      }
      document.addEventListener('focusin', ::this.onFocus)
    },
    componentDidUpdate(prevProps) {
      const { hasFocus } = this.props

      if (prevProps.hasFocus && !hasFocus) {
        onBlur(this.props)
      }

      if (!prevProps.hasFocus && hasFocus) {
        this.onFocus()
        onFocus(this.props)
      }
    },
    componentWillUnmount() {
      this.unmounting = true
      document.removeEventListener('focusin', ::this.onFocus)
    }
  }),
)

export default withHasFocus