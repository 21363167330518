exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".enabled__18Kma,.disabled__4cnhO{-webkit-transition:1s linear opacity;-o-transition:1s linear opacity;transition:1s linear opacity;color:#000 !important}.enabled__18Kma{opacity:1}.disabled__4cnhO{opacity:.3}", ""]);

// exports
exports.locals = {
	"enabled": "enabled__18Kma",
	"disabled": "disabled__4cnhO"
};