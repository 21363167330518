exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__X6mFk{color:#add8e6;cursor:pointer}.iconAsLink__X6mFk:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2L_Ic{font-style:italic}.formFieldsVerticalGutter__Am3bG{padding:.5rem}.formFieldsVerticalGutter__Am3bG>*{margin:.5rem !important}.tableBackground__1aEoD .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__1aEoD .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__uSbT- .ant-notification-notice-close{display:none}.problemControls__7tTO8{display:-ms-flexbox;display:flex;-ms-flex-item-align:center;align-self:center;-ms-flex-align:center;align-items:center;padding:0 .25rem}.problemControls__7tTO8>i{cursor:pointer;font-size:1.8rem;vertical-align:middle}.problemControls__7tTO8>i.disabled__2UZRx{opacity:.5;cursor:not-allowed !important}.problemControls__7tTO8>*{margin:0 .25rem}.problemControls__7tTO8 .volumeControl__3neIB{font-size:1rem}.preference__1hR0p{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-align:center;align-items:center;padding-bottom:1rem;padding-bottom:0}.preferenceTitle__1Tx9f{padding-right:1rem}.manualAutoStaticAnalysis__2_zrg{display:-ms-flexbox;display:flex;padding-left:.5rem;padding-right:.2rem}.manualAutoStaticAnalysis__2_zrg .ant-select-selection{display:-ms-flexbox;display:flex;padding-right:1rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__X6mFk",
	"labelFromRef": "labelFromRef__2L_Ic",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__Am3bG",
	"tableBackground": "tableBackground__1aEoD",
	"notificationNotClosable": "notificationNotClosable__uSbT-",
	"problemControls": "problemControls__7tTO8",
	"disabled": "disabled__2UZRx",
	"volumeControl": "volumeControl__3neIB",
	"preference": "preference__1hR0p",
	"preferenceTitle": "preferenceTitle__1Tx9f",
	"manualAutoStaticAnalysis": "manualAutoStaticAnalysis__2_zrg"
};