import React from 'react'
import { connect } from 'react-redux'
import { pipe } from 'ramda'
import { Avatar, Popover } from 'antd'
import { ContextMenuTarget } from '@blueprintjs/core'
import { selectNode as selectSingleNode } from 'actions/nodes'

import { token as tokenSelector } from 'selectors/auth'
import { userImageGetURL } from 'model/resources'
import { acronymFor } from 'utils/names'
import { isSuperAdmin } from 'security/platform'

import UserPopover from 'components/UserPopover/UserPopover'
import { EMPTY_OBJECT } from 'utils/object'
import { userAvatarBaseStyle } from 'components/Commons/SimpleAvatar/SimpleAvatar'

import UserAvatarBadge from './UserAvatarBadge'

@connect(
  () => {
    return state => ({
      token: tokenSelector(state),
    })
  },
  { selectSingleNodeAction: selectSingleNode }
)
@ContextMenuTarget
export default class UserAvatar extends React.Component {

  render() {
    const { size = 'default', user, token, showPopOver = true, style, popoverContent, popoverActions } = this.props

    const mergedStyle = { ...userAvatarBaseStyle, ...(style || EMPTY_OBJECT) }

    return wrapping(user, token, showPopOver, popoverContent, popoverActions)(
      <Avatar size={size} style={mergedStyle} src={user.profileImage && userImageGetURL(user._id || user.id, user.profileImage, token)}>
        {user.acronym || acronymFor(user)}
      </Avatar>
    )

  }
}

const wrapIf = (condition, wrap) => wrapped => (condition ? wrap(wrapped) : wrapped)

const wrapping = (user, token, showPopOver, popoverContent, popoverActions) => pipe(
  wrapIf(showPopOver,
    c => (
      <Popover
        placement="bottom"
        content={
          <UserPopover
            user={user}
            token={token}
            editable={false}
            content={popoverContent}
            actions={popoverActions}
          />
        }>
        {c}
      </Popover>
    )
  ),
  wrapIf(isSuperAdmin(user) || !!user.deleted,
    c => {
      if (user.deleted) {
        return <UserAvatarBadge type="close-square">{c}</UserAvatarBadge>
      } else if (isSuperAdmin(user)) {
        return <UserAvatarBadge type="safety-certificate">{c}</UserAvatarBadge>
      }
      return c
    }
  )
)
