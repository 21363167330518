import React, { useMemo, useCallback } from 'react'
import classNames from 'classnames'
import { compose } from 'recompose'
import { Icon } from 'antd'
import { useSelector } from 'react-redux'

import { makeIsEnabledNode } from 'selectors/playback'
import { isCurrentRevisionWritable } from 'security/project'
import { isPinned } from 'selectors/debuggingData'
import secure from 'hocs/secure'
import withUser from 'hocs/withUser'

import IconButton from 'components/Commons/IconButton'
import { lang } from 'beanie-engine-api-js'
import LabelContent from './LabelContent'

import treeStyles from '../../TreeView.scss'
import styles from './NodeWithEnabledRule.scss'

const { rule: { error: { isError } } } = lang

const MAX_SOURCE_SIZE = 20

const RuleContainer = ({ source, node, onDeleteRule, edit, writeAccess }) => {
  const pinnedSelector = useMemo(() => isPinned(node.id), [node.id])
  const nodeIsEnabledSelector = useMemo(() => makeIsEnabledNode(node.id), [node.id])

  const pinned = useSelector(pinnedSelector)
  const isEnabled = useSelector(nodeIsEnabledSelector)

  const onDoubleClick = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      if (writeAccess) edit()
    },
    [edit, writeAccess])

  return (
    <div
      onDoubleClick={onDoubleClick}
      className={classNames(styles.ruleContainer, {
      [treeStyles.disabledNode]: !isEnabled || pinned,
      [styles.ruleError]: isError(isEnabled)
    })}>
      {isError(isEnabled) ?
        <Icon type="close-circle" /> :
        <Icon type="question-circle" className={styles.questionIcon} />}
      <LabelContent source={source} maxSize={MAX_SOURCE_SIZE} />
      <IconButton className={styles.deleteButton} type="close" onClick={onDeleteRule} tooltip="Delete rule" />
    </div>
  )
}

export default compose(
  withUser,
  secure('writeAccess', isCurrentRevisionWritable, ['project']),
)(RuleContainer)
