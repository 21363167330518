import React from 'react'
import { prop, pathEq } from 'ramda'
import { compose, withHandlers } from 'recompose'
import { withApollo } from 'react-apollo' 
import { Button } from 'antd'
import UserSelectOption from 'components/Commons/UserSelectOption'
import ModalButton from 'components/Commons/ModalButton'
import AutocompleteSelectorForm from 'components/Commons/Forms/AutocompleteSelectorForm'

import organizationMembersQuery from 'api/queries/organizationMembers.graphql'
import teamAddMembersMutation from 'api/mutations/teamAddMembers.graphql'

const InviteToTeamButton = ({ searchMember, addMembers, onInvited }) => (
  <ModalButton
    title="Add to Team"
    onOk={async ({ Users }) => {
      await addMembers(Users.map(prop('key')))
      onInvited()
    }}
  >
    <Button type="primary">Add a Member</Button>
    <AutocompleteSelectorForm placeholder="Search users from the organization to add" fieldName="Users" search={searchMember} />
  </ModalButton>
)

// we might want to create a <Select.Option> with rich visual content like the avatar, etc
const userToOption = user => ({
  text: <UserSelectOption user={user} />,
  value: user.id
})

const notInTeam = team => user => !team.members.list.some(pathEq(['user', '_id'], user._id))

export default compose(
  withApollo,
  withHandlers({
    searchMember: ({ team, client }) => async searchText => {
      const response = await client.query({
        query: organizationMembersQuery,
        variables: {
          organizationId: team.organization._id,
          searchText
        },
        fetchPolicy: 'no-cache'
      })
      return response.data.organizationMembers
        .filter(notInTeam(team))
        .map(userToOption)
    },
    addMembers: ({ team, client }) => async userIds => client.mutate({
      mutation: teamAddMembersMutation,
      variables: { input: { teamId: team._id, userIds } }
    })
  })
)(InviteToTeamButton)