import { path, uniq } from 'ramda'
import { createSelector } from 'selectors/reselect'
import { model } from 'beanie-engine-api-js'

import { objects, timeline } from './project'

const { types: { node: { getChildId } } } = model

/* eslint no-multi-spaces:0 */
export const engine =                 path(['player', 'engine'])

export const currentClipId =          path(['player', 'engine', 'current', 'clip'])
export const lastClipId =             path(['player', 'engine', 'lastClip'])
export const currentChoicesId =       path(['player', 'engine', 'current', 'choices'])
export const currentChoicesResult =   path(['player', 'engine', 'current', 'choicesResult'])
export const enabledChoicesIds =      path(['player', 'engine', 'current', 'enabledChoices'])
export const nextClipsCache =         path(['player', 'engine', 'nextClipsCache'])

export const currentlyVisibleClipId = createSelector(
  [currentClipId, lastClipId],
  (current, last) => current || last
)

export const nextClipsIds = createSelector(
  [nextClipsCache],
  cache => uniq(cache ? cache.flatten() : [])
)

export const currentChoices = createSelector(
  [currentChoicesId, objects],
  (id, index) => (id ? index[id] : undefined)
)

// REVIEWME: we don't pay attention to the timer object, and instead use the
// iddle clipp
export const currentChoicesExpirationTime = createSelector(
  [currentChoices, timeline],
  (choices, tline) => {
    if (!choices) return undefined
    // REVIEWME: this assumes the project is well formed, as well as the choices
    // having a choices -> idleClip -> timer
    // if there are more idle components then we should be computing that !
    const idleClipId = getChildId(choices)
    return tline[idleClipId].length
  }
)