import React from 'react'

import NodeIcon from 'components/Commons/nodes/NodeIcon'

import SearchObjectContextInfo from './SearchObjectContextInfo'
import SearchResultItemCaption from './SearchResultItemCaption'

/**
 *
 */
const SearchObjectItem = ({ item, withStyle = false, showContextInfo = true }) => {
  return (
    <React.Fragment>
      <NodeIcon node={item.object} withStyle={withStyle} />
      <SearchResultItemCaption item={item} />
      {showContextInfo && (
        <SearchObjectContextInfo object={item.object} node={item.node} />
      )}
    </React.Fragment>
  )
}

export default SearchObjectItem