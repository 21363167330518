exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".sys__21y4E{font-weight:bold}.fieldChanged__2l_38{display:-ms-flexbox;display:flex}.properties__3DBDa tr>td:first-child{min-width:3rem}.updateChanges__33MIG tr>td:first-child{word-break:keep-all;padding-right:.5rem}.updateChanges__33MIG .textDiff__1fZcw ins{color:#6f976f}.updateChanges__33MIG .textDiff__1fZcw del{color:#cc7c7c}.updateChanges__33MIG .textDiff__1fZcw span{opacity:.6}.arrayChange__2se2x{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-wrap:wrap;flex-wrap:wrap;overflow:auto}.arrayChange__2se2x .leftBracket__1x9M7,.arrayChange__2se2x .rightBracket__QVOyv{border-top:1px solid rgba(0,0,0,.5);border-bottom:1px solid rgba(0,0,0,.5);width:4px}.arrayChange__2se2x .leftBracket__1x9M7{border-left:1px solid rgba(0,0,0,.5)}.arrayChange__2se2x .rightBracket__QVOyv{border-right:1px solid rgba(0,0,0,.5)}.arrayChange__2se2x .element__3zVld{border-radius:5px;margin-left:2px;margin-right:2px}.arrayChange__2se2x .element__3zVld:not(:last-child)::after{margin-right:1em}.arrayChange__2se2x .element__3zVld.added__27pej{background-color:#6f976f;color:#fff}.arrayChange__2se2x .element__3zVld.deleted__2T4kP{background-color:#cc7c7c;color:#fff;text-decoration:line-through}.arrayChange__2se2x .element__3zVld.same__2mywV{opacity:.6}.arrayChange__2se2x .element__3zVld.unknown__YZOag{background-color:wheat}.filteredLabel__gPAB4{color:gray}.value_undefined__1LePQ{color:#afaeae}", ""]);

// exports
exports.locals = {
	"sys": "sys__21y4E",
	"fieldChanged": "fieldChanged__2l_38",
	"properties": "properties__3DBDa",
	"updateChanges": "updateChanges__33MIG",
	"textDiff": "textDiff__1fZcw",
	"arrayChange": "arrayChange__2se2x",
	"leftBracket": "leftBracket__1x9M7",
	"rightBracket": "rightBracket__QVOyv",
	"element": "element__3zVld",
	"added": "added__27pej",
	"deleted": "deleted__2T4kP",
	"same": "same__2mywV",
	"unknown": "unknown__YZOag",
	"filteredLabel": "filteredLabel__gPAB4",
	"value_undefined": "value_undefined__1LePQ"
};