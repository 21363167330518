exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".walklistElementMatchesBadge__3c896{border-radius:15px;padding-left:.3rem;padding-right:.3rem;position:absolute;right:5px;font-size:.6rem}.walklistElementMatchesBadge__3c896.active__1q0Kg{color:#fff;background-color:#d9a214}.walklistElementMatchesBadge__3c896.active__1q0Kg>span:nth-child(2){padding-left:.2rem;padding-right:.2rem;color:#e8dfc8}.walklistElementMatchesBadge__3c896:not(.active__1q0Kg){background-color:#dade00;color:#000}", ""]);

// exports
exports.locals = {
	"walklistElementMatchesBadge": "walklistElementMatchesBadge__3c896",
	"active": "active__1q0Kg"
};