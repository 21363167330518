import { findIndex } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

export const splitElement = (auxElements, matchFunction, splitter) => {
  const elements = [...auxElements]
  const index = findIndex(matchFunction, elements)
  if (index < 0) { return { left: elements, right: EMPTY_ARRAY } }
  
  const [leftPart, rightPart] = splitter(elements[index])

  return {
    left: [...elements.slice(0, index), leftPart],
    right: [rightPart, ...elements.slice(index + 1)]
  }
}
