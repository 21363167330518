import React from 'react'
import { batch, connect } from 'react-redux'
import { path } from 'ramda'
import { hasPath } from 'ramda-adjunct'
import { compose, withHandlers } from 'recompose'
import { average } from 'utils/math'
import { Table, Tooltip, Icon } from 'antd'

import { Sys } from 'beanie-engine-api-js'

import Secure from 'components/Commons/Secure'
import { isCurrentRevisionWritable } from 'security/project'

import { linesByActor as linesByActorSelector, clipsByActor as clipsByActorSelector } from 'selectors/lines'
import { sorterByStringProperty, sorterBy, firstUpperCase } from 'utils/string'
import filteringTable from 'hocs/filteringTable'
import { isSys, lineHasActor } from '../../model/project/searches/query/bneQueries'
import { andQuery } from '../../model/project/searches/query/queries'
import { findSearch } from '../../model/project/searches/searches'

import { push } from 'connected-react-router'
import { Creators as WalkListCreators } from 'actions/walklist'
import { searchAction } from '../../actions/nodes/search/search'

import Avatar from './Avatar'
import ActorActions from './ActorActions'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { token as tokenSelector } from 'selectors/auth'
import { urlForImage } from 'model/resources'
import classnames from 'classnames'
import styles from './ActorSection.scss'

const { Column } = Table

const CounterType = {
  lines: 'lines',
  clips: 'clips'
}

class ActorTable extends React.Component {
  state = {
    selectedActor: undefined
  }

  renderActions = actor => (
    <Secure check={isCurrentRevisionWritable}>
      <ActorActions
        actor={actor}
        onDelete={this.props.onDeleteActor}
        onMoveLines={this.props.onMoveLines}
        lineCount={this.getLinesCount}
      />
    </Secure>
  )

  countByActor = selector => actor => this.props[selector][actor.id] || 0
  getLinesCount = this.countByActor('linesByActor')
  getClipsCount = this.countByActor('clipsByActor')

  isStarActor = actor => this.getLinesCount(actor) > this.avgLinesByActor

  getNameColumnTitle = () => {
    const { searchText } = this.props
    const suffix = searchText ? ` (${searchText})` : ''
    return `Name${suffix}`
  }

  render() {
    const { selectedActor } = this.state
    const { onActorChanged, linesByActor, actors, filteringProps, token, revisionId, onNavigateSearch } = this.props

    this.loading = true
    setTimeout(() => { this.loading = false }, 300)
    this.avgLinesByActor = average(Object.values(linesByActor))
    return (
      <div className={classnames(styles.actorsSectionCol, styles.actorsTable)}>
        <Table
          dataSource={actors}
          size="small"
          rowKey="id"
          onRow={actor => ({
            onClick: () => {
              this.setState({ selectedActor: actor })
              onActorChanged(actor)
            }
          })}
          rowClassName={record => (selectedActor && record.id === selectedActor.id ? styles.selectedRow : '')}
        >
          <Column
            dataIndex="data.actorIcon"
            key="avatar"
            className={styles.avatarColumn}
            render={(value, actor) => (
              <div className={styles.avatarAndStar}>
                <Avatar
                  {...actor?.data?.actorIcon && { imageFile: urlForImage(token, revisionId, actor.data.actorIcon) }}
                />
                <Tooltip title={this.isStarActor(actor) ? 'More lines than the average' : 'Fewer lines than the average'}>
                  <Icon type={this.isStarActor(actor) ? 'star' : 'star-o'} />
                </Tooltip>
              </div>
            )}
          />
          <Column
            title={this.getNameColumnTitle()}
            dataIndex="data.actorName"
            key="name"
            className={styles.column}
            defaultSortOrder="ascend"
            sorter={sorterByStringProperty('data.actorName')}
            {...filteringProps()}
          />

          {([CounterType.lines, CounterType.clips].map(name => (
            <Column
              title={<Tooltip title={`Number of ${firstUpperCase(name)}`}>{firstUpperCase(name)}</Tooltip>}
              key={`nr${firstUpperCase(name)}`}
              className={styles.column}
              sorter={sorterBy(this[`get${firstUpperCase(name)}Count`])}
              render={(value, actor) => {
                const v = this[`get${firstUpperCase(name)}Count`](actor)
                // TODO: limiting here, until we implement a query for "clips for actor"
                return name === CounterType.lines ? (
                  <a onClick={() => { onNavigateSearch(actor, name) }}>{v}</a>
                ) : v
              }}
            />
          )))}

          <Column
            title="Voice ?"
            key="hasTTSVoice"
            className={styles.hasTTSVoiceColumn}
            render={actor => hasPath(['data', 'editor', 'speechVoice'], actor) && <Icon type="check" />}
          />

          <Column
            key="actions"
            className={classnames(styles.avatarColumn, styles.actorActions)}
            render={this.renderActions}
          />
        </Table>
      </div>
    )
  }
}

export default compose(
  connect(state => ({
    linesByActor: linesByActorSelector(state),
    clipsByActor: clipsByActorSelector(state),
    revisionId: revisionIdSelector(state),
    token: tokenSelector(state)
  }), {
    doSearch: searchAction,
    walkListAction: WalkListCreators.walkList,
    pushAction: push,
  }),
  filteringTable('actors', path('data.actorName'.split('.'))),
  withHandlers({
    onNavigateSearch: ({ pushAction, doSearch, walkListAction }) => (actor, type) => {
      batch(() => {
        pushAction('../edit/') // hmmm

        const aSearch = findSearch(andQuery(
          isSys(type === CounterType.lines ? Sys.line : Sys.clip),
          lineHasActor(actor)
        ), `${type} of ${actor.data.actorName}`)

        walkListAction(aSearch, doSearch(aSearch))
      })
    }
  })
)(ActorTable)