import { compose, lifecycle, branch, renderComponent, withState } from 'recompose'

import sentry from 'services/sentry'

const withErrorHandler = (ErrorComponent, report = false) => compose(
  withState('error', 'setError'),
  withState('errorInfo', 'setErrorInfo'),
  lifecycle({
    componentDidCatch(error, errorInfo) {
      if (report) sentry.handleError(error, errorInfo)
      this.props.setError(error)
      this.props.setErrorInfo(errorInfo)
    },
    componentDidUpdate(prevProps) {
      // TODO: generalize the use of object here into a broader concept of parameterized updateProps
      // then we would use those prop names to check for the change of [TBD] ALL or ANY of them
      const { object } = prevProps
      const { object: current, setError, setErrorInfo } = this.props
      if (object !== current) {
        setError(undefined)
        setErrorInfo(undefined)
      }
    }
  }),
  branch(props => props.error, renderComponent(ErrorComponent)),
)

export default withErrorHandler