import { T, cond, reverse, mergeDeepRight, pipe } from 'ramda'
import { isAdded, isDeleted, isUpdate } from 'beanie-engine-api-js'

// THIS CODE IS COPIED FROM THE BACKEND

export const createRevertChangeSetFromSameAuthor = changeSet => ({
  project: changeSet.project,
  author: changeSet.author,

  reverts: changeSet._id,

  description: `Undo ${changeSet.description}`,
  timestamp: new Date(),

  changes: reverse(changeSet.changes.map(toInverseChange))
})

const reverseUpdate = c => ({
  id: c.id,
  changes: c.changes.map(fieldChange => ({
    field: fieldChange.field,
    prev: fieldChange.next,
    next: fieldChange.prev,
  })),
})

const reverseAdded = ({ added }) => ({ deleted: added })
const reverseDeleted = ({ deleted }) => ({ added: deleted })

const addMeta = v => mergeDeepRight({ kind: v, __typename: v })
const reverser = (fn, type) => pipe(fn, addMeta(type))

const toInverseChange = cond([
  [isUpdate, reverser(reverseUpdate, 'Updated')],
  [isAdded, reverser(reverseAdded, 'Deleted')],
  [isDeleted, reverser(reverseDeleted, 'Added')],
  [T, c => { throw new Error(`Unknown change ${JSON.stringify(c)}`) }]
])
