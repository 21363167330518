import { createStore, combineReducers } from 'redux'

import { isFunction } from 'utils/object'

import * as selectionService from 'reducers/selection'
import setupSelections from 'selections/setup'
import apolloClientFactory from 'providers/Apollo'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'
import * as ReselectTools from 'reselect-tools'

import StudioAPI from './api/StudioAPI'
import { token } from './selectors/auth'
import createEnhancer from './store/createEnhancer'
import { isTest } from './services/services-enabling'
import testReducerWrapper from './store/testReducerWrapper'

import ServerStore from './sync/reactive-stores/ServerStore'

import * as reducers from './reducers'
import * as selectors from 'selectors/selectors'

import { editorModule } from './api/Editor'
import ExtensionsContainer from './providers/Extensions/ExtensionsContainer'

setupSelections(selectionService)

export default function storeCreator({ history, apolloFactory = apolloClientFactory, synchronizer, baseState, storeEnhancer, getStudioAPI: _getStudioAPI } = {}) {
  let editorAPI
  let studioAPI
  const getState = () => store.getState()
  const getApolloClient = () => apolloClient
  const getStudioAPI = _getStudioAPI || (() => {
    if (studioAPI === undefined) {
      studioAPI = new StudioAPI(getApolloClient, () => token(store.getState()))
    }
    return studioAPI
  })
  const getEditorModule = () => editorAPI

  const historyObj = history || createHistory()

  const store = createStore(
    createReducer(historyObj),
    baseState,
    createEnhancer(historyObj, getApolloClient, synchronizer, storeEnhancer, getEditorModule, getStudioAPI)
  )

  const apolloClient = apolloFactory({ getState, store, synchronizer })
  store.apolloClient = apolloClient // hack
  store.extensionsContainer = new ExtensionsContainer()
  editorAPI = editorModule(store.dispatch, getState, synchronizer, getApolloClient, store.extensionsContainer)

  if (synchronizer) { // some tests don't have a sync
    synchronizer.setServerStore(new ServerStore(store, apolloClient))
  }

  if (process.env.NODE_ENV !== 'production') {
    setupSelectorsDebugging(store)
  }

  return store
}

const createReducer = history => {
  const r = combineReducers({
    ...reducers,
    router: connectRouter(history),
  })
  return isTest ? testReducerWrapper(r) : r
}

export const setupSelectorsDebugging = store => {
  // reselect selectors debugging
  ReselectTools.getStateWith(::store.getState)
  const distilledSelectors = Object.entries(selectors).reduce((acc, [k, v]) => {
    if (isStateSelector(v)) acc[k] = v
    return acc
  }, {})
  ReselectTools.registerSelectors(distilledSelectors)
}
// we only process function with the form (state) =>
const isStateSelector = v => v && !v.noDebug && isFunction(v) && v.length === 1
