import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { clearUndoHistory as clearHistoryAction } from 'actions/edit'

import IconButton from 'components/Commons/IconButton'

const UndoHistoryControls = ({ clearHistory }) => [
  <IconButton key="clear" type="close" tooltip="Clear History" onClick={clearHistory} />
]

export default compose(
  connect(null, {
    clearHistory: clearHistoryAction,
  })
)(UndoHistoryControls)