import { OBJECT_TYPES } from '../Constants'
import { pipe, map, reject } from 'ramda'
import { textNode } from './textNode'
import { inlineNode } from './inlineNode'
import { leafNode } from './leafNode'
import { nodeIsEmptyText } from '../nodeUtils'

export const cloneNode = node => CLONE_NODE_BY_TYPE_WITH[node.object](node, cloneNodes)
export const cloneNodes = nodes => [...nodes.map(cloneNode)] // spread to convert Immutable.Lits to regular array

export const cloneNodeWithoutGhostChilds = node => CLONE_NODE_BY_TYPE_WITH[node.object](node, cloneNodesWithoutGhostChilds)
export const cloneNodesWithoutGhostChilds = pipe(reject(nodeIsEmptyText), map(cloneNodeWithoutGhostChilds))

const CLONE_NODE_BY_TYPE_WITH = {
  [OBJECT_TYPES.text]: node => textNode(node.text),
  [OBJECT_TYPES.inline]: (node, func) => inlineNode(func(node.nodes), node.type, node.data.toJS()),
  [OBJECT_TYPES.leaf]: node => leafNode(node.text)
}
