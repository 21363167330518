import React from 'react'
import classNames from 'classnames'
import withState from 'recompose/withState'
import { Table, Spin, Icon } from 'antd'
import { compose, withPropsOnChange } from 'recompose'
import withHandlers from 'recompose/withHandlers'
import { connect } from 'react-redux'

import { isFalse } from 'ramda-adjunct'
import { equals, filter as filterFunction, length, prop, startsWith } from 'ramda'
import { EMPTY_ARRAY } from 'utils/object'

import { analysisIsLoading, projectIssuesSelector, objectsWithIssuesSelector } from 'selectors/staticIssues'
import { model } from 'beanie-engine-api-js'
import ProblemSeverity from './ProblemSeverity'
import ProblemDescription from './ProblemDescription'
import { SimpleNodeContainer } from 'components/Commons/SimpleNode'
import { CHECK_SEVERITY_LEVEL } from 'providers/Checker/CheckSelectors/CheckSelectors'
import { selectNode } from 'actions/nodes'
import SearchBox from '../Commons/SearchBox'
import IconButton from '../Commons/IconButton'

import styles from './ProjectProblems.scss'
import { fullTable } from 'styles/table.scss'

const { types: { object: { isDisabled } } } = model

const { Column } = Table

const isErrorSeverity = equals(CHECK_SEVERITY_LEVEL.ERROR)
const isWarningSeverity = equals(CHECK_SEVERITY_LEVEL.WARNING)
const isInfoSeverity = equals(CHECK_SEVERITY_LEVEL.INFO)

const sortSeverity = ({ severity: severity1 }, { severity: severity2 }) => {
  if (isErrorSeverity(severity1) || (isWarningSeverity(severity1) && !isErrorSeverity(severity2))) return -1
  if (isErrorSeverity(severity2) || (isInfoSeverity(severity1) && isWarningSeverity(severity2))) return 1
  return 0
}

const sortDescription = ({ message: message1 }, { message: message2 }) => length(message1) < length(message2)
const onFilterBySeverity = (severityFilter, { severity }) => equals(severityFilter, severity)

const FilterDropdownByNode = ({ confirm, onSearch }) => (
  <div className={styles.searchBox}>
    <SearchBox
      placeholder="Search by node id"
      onSearch={id => onSearch(confirm, id)}
    />
  </div>
)

const ProjectProblems = ({ filteredIssues, isLoading, selectNodeAction, handleSearchByNodeId, visibleConfig, setVisibleConfig }) => (
  <div className={classNames(fullTable, styles.problemsTable)}>
    <Spin tip="Analysing ..." spinning={isLoading}>
      <Table
        dataSource={isLoading ? EMPTY_ARRAY : filteredIssues}
        rowKey={prop('issueHash')}
        size="small"
        pagination={false}
        scroll={{ y: true }}
        locale={{ emptyText: 'No Problems' }}
        >
        <Column
          title="Severity"
          className={styles.severityColumn}
          key="severity"
          sorter={sortSeverity}
          onFilter={onFilterBySeverity}
          defaultSortOrder="ascend"
          filters={[
            { text: 'Error', value: CHECK_SEVERITY_LEVEL.ERROR },
            { text: 'Warning', value: CHECK_SEVERITY_LEVEL.WARNING },
            { text: 'Info', value: CHECK_SEVERITY_LEVEL.INFO }
          ]}
          dataIndex="severity"
          render={severity => <ProblemSeverity severity={severity} />}
          />
        <Column
          title={(<div className={styles.objectsTitleContainer}>
            <IconButton
              className={styles.filterDisableNodes}
              type={visibleConfig ? 'eye' : 'eye-invisible'}
              theme="filled"
              onClick={() => setVisibleConfig(!visibleConfig)}
              tooltip={visibleConfig ? 'Filter disabled nodes' : 'Show disabled nodes'}
            />
            <span className={styles.objectsTitle}>Object</span>
          </div>)}
          className={styles.objectColumn}
          key="object"
          dataIndex="objId"
          filterDropdown={props => (<FilterDropdownByNode confirm={props.confirm} onSearch={handleSearchByNodeId} />)}
          filterIcon={<Icon type="search" />}
          render={id => <SimpleNodeContainer nodeId={id} onClick={() => selectNodeAction(id)} />}
        />
        <Column
          title="Description"
          key="description"
          sorter={sortDescription}
          className={styles.descriptionColumn}
          render={issue => <ProblemDescription issue={issue} />}
          />
      </Table>
    </Spin>
  </div>
)

const filterIssues = (issues, filter, visibleConfig, isDisabledNode) => filterFunction(({ objId, severity }) => {
  if (filter.severity && severity !== filter.severity) { return false }
  if (filter.objId && !startsWith(filter.objId, objId)) { return false }
  if (isFalse(visibleConfig) && isDisabledNode(objId)) { return false }

  return true
}, issues)

export default compose(
  withState('visibleConfig', 'setVisibleConfig', true),
  withState('filter', 'setFilter', {}),
  connect(() => (state, props) => ({
    issues: projectIssuesSelector(state, props),
    objectsWithIssues: objectsWithIssuesSelector(state),
    isLoading: analysisIsLoading(state),
  }), dispatch => ({
    selectNodeAction: id => dispatch(selectNode(id))
  })),
  withHandlers({
    isDisabledNode: ({ objectsWithIssues }) => id => isDisabled(objectsWithIssues[id])
  }),
  withPropsOnChange(['issues', 'filter', 'visibleConfig'], ({ issues, filter, visibleConfig, isDisabledNode }) => ({
    filteredIssues: filterIssues(issues, filter, visibleConfig, isDisabledNode)
  })),
  withHandlers({
    handleSearchByNodeId: ({ filter, setFilter }) => (confirm, id) => {
      confirm()
      setFilter({ ...filter, objId: id })
    }
  })
)(ProjectProblems)
