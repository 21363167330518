import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import IconButton from 'components/Commons/IconButton'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { downloadAudio as downloadAudioAction } from 'actions/download'

const DownloadLineAudioButton = ({ download }) => (
  <IconButton type="download" onClick={download} />
)

export default compose(
  connect(state => ({
    revisionId: revisionIdSelector(state)
  }), { 
    downloadAudio: downloadAudioAction 
  }),
  withHandlers({
    download: ({ downloadAudio, revisionId, audio }) => () => {
      downloadAudio({ revisionId, audio })
    }
  })
)(DownloadLineAudioButton)
