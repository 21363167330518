import { makeMemoizeSelector } from './memoize'
import { fromProps } from './props'
import { collapsedElements } from './view'
import { elementFullName } from 'model/view'
import { revisionId } from './project'

// pure logic, util non-memoizing
export const isCollapsed = (defaultToCollapsed = false) => (name, revId, collapsed) => {
  const c = collapsed[elementFullName(revId, name)]
  return defaultToCollapsed && c === undefined ? true : c
}

export const makeIsCollapsedSelector = (fn, defaultToCollapsed = false) => makeMemoizeSelector(
  {
    name: fromProps(fn),
    revId: revisionId,
    collapsed: collapsedElements
  },
  ({ name, revId, collapsed }) => isCollapsed(defaultToCollapsed)(name, revId, collapsed)
)
makeIsCollapsedSelector.noDebug = true