import React from 'react'
import ReactDOM from 'react-dom'
import { compose, onlyUpdateForKeys } from 'recompose'
import { DnDTypes } from 'dnd/model-dnd'
import { DragLayer } from 'react-dnd'

import { DRAG_PARENT_ID } from '../../../../TreeView'
import DraggingColumn from './DraggingColumn'

import styles from '../../TruthTable.scss'

/**
 * TODO: unify all this 3 layers that are almost the same
 */
const ColumnLayer = ({ isDragging, itemType, ...others }) => {
  if (!isDragging) return null

  if (itemType !== DnDTypes.TruthTableColumn) return null

  return ReactDOM.createPortal(
    <div style={{ position: 'relative' }}>
      <table className={styles.truthTableDrawLayer}>
        <DraggingColumn item={others.item} rows={others.rows} />
      </table>
    </div>,
    document.getElementById(DRAG_PARENT_ID)
  )
}

export default compose(
  DragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  })),
  onlyUpdateForKeys(['item', 'rows']),
)(ColumnLayer)
