import { batch } from 'react-redux'

import { clearSelectedNodes } from '../nodes'
import { resetProject } from '../project'
import disposeVM from './disposeVM'
import { Creators as VMCreators } from 'actions/vm'
import { isLoaded } from 'selectors/project'

const { resetPlayback } = VMCreators

const closeRevision = () => (dispatch, getState) => {
  // we assume we must only close a revision if there was something already loaded
  // otherwise we mess up selection when restoring from URL which triggers a selection before
  // actually loading the project then this here clears the selection
  if (!isLoaded(getState())) {
    return
  }
  batch(() => {
    dispatch(clearSelectedNodes())
    dispatch(resetPlayback())
    dispatch(resetProject())
    dispatch(disposeVM())
  })
}

export default closeRevision