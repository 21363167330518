import React from 'react'
import { connect } from 'react-redux'

import { EditorBlock } from 'draft-js'
import { createObjectByIdSelector } from 'selectors/objects'
import classNames from 'classnames'
import { model } from 'beanie-engine-api-js'

import styles from './ChoiceBlock.scss'

const { types: { object: { isDisabled } } } = model

class ChoiceBlock extends EditorBlock {
  render() {
    return (
      <div className={classNames(styles.choiceBlock, {
        [styles.isDisabled]: isDisabled(this.props.object)
      })}>
        {super.render()}
      </div>
    )
  }
}

export default connect(() => {
  const objectFromId = createObjectByIdSelector((_, props) => props.block.getData().toJS().choice)()
  return (state, props) => ({
    object: objectFromId(state, props)
  })
})(ChoiceBlock)
