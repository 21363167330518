import { Key } from 'utils/keyboard'
import { keyboardPlugin } from './pluginFactories'

export const SAVE_COMMAND = 'save-command'

export default keyboardPlugin({
  name: SAVE_COMMAND,
  key: e => e.metaKey && e.which === Key.S,
  handler: (editorState, { getProps }) => {
    if (getProps().onSave) {
      getProps().onSave()
    }
    return editorState
  }
})
