import React from 'react'
import { model } from 'beanie-engine-api-js'

import connectState from 'hocs/connectState'
import { markupColor } from './selectors'

import styles from './MarkUp.scss'

const { types: { markup: { markupColorToCSS } } } = model

const MarkUpName = ({ attributes, children, color }) =>
  (<span
    {...attributes}
    className={styles.markUpName}
    {...color && { style: { color: markupColorToCSS(color) } }}
  >{children}</span>)

export default connectState({
  color: markupColor
})(MarkUpName)