import { graphql } from 'react-apollo'
import { compose, withProps } from 'recompose'

import organizationQuery from 'api/queries/organization.graphql'

import OrganizationRouter from './OrganizationRouter'

export default compose(
  graphql(organizationQuery, {
    name: 'organization',
    options: ({ computedMatch: { params: { organizationName } } }) => ({
      variables: {
        organizationName
      },
    }),
  }),
  withProps(({ organization }) => ({
    loading: organization.loading,
    organization: organization.organization,
    error: organization.error
  }))
)(OrganizationRouter)