import { isNil } from 'ramda'
import React, { useMemo, useState } from 'react'
import FactDrawer from './FactDrawer'

export const FactDrawerContext = React.createContext()

/**
 * A provider to control the state of FactDrawer.
 * Allows to model the idea that we can only have 1 drawer open at a time
 */
const FactDrawerProvider = ({ children }) => {
  const [factProps, setFactProps] = useState()

  const context = useMemo(() => ({
    open: setFactProps,
    isOpen: !isNil(factProps),
  }), [factProps, setFactProps])

  return (
    <FactDrawerContext.Provider value={context}>
      {children}

      {factProps && (
        <FactDrawer
          {...factProps}
          onAccept={async fact => {
            await factProps.onAccept?.(fact)
            setFactProps(null)
          }}
          onClose={() => setFactProps(null)}
        />
      )}
    </FactDrawerContext.Provider>
  )
}

export default FactDrawerProvider