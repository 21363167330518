import { isNil, reject } from 'ramda'
import QueryType from './QueryType'

// here we have Query factory functions for the different types of Query's

const compositeQuery = type => (...elements) => {
  const queries = reject(isNil, elements)
  return queries.length === 1 ? queries[0] : ({
    type,
    queries,
  })
}

export const andQuery = compositeQuery(QueryType.AND)
export const orQuery = compositeQuery(QueryType.OR)

export const propEqQuery = (name, value) => ({
  type: QueryType.PROP_EQ,
  name,
  value
})

export const pathEqQuery = (path, value) => ({
  type: QueryType.PATH_EQ,
  path,
  value
})

export const notQuery = query => ({
  type: QueryType.NOT,
  query
})

export const idQuery = text => ({
  type: QueryType.ID,
  text: text.toLowerCase().trim()
})
