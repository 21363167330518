import React from 'react'
import styles from 'styles/panel.scss'

import SearchBox from 'components/Commons/SearchBox'

const MarkupsTabHeader = ({ onSearch, children }) => (
  <div className={styles.pageHeader}>
    <div className={styles.searchBox}>
      <SearchBox onSearch={onSearch} />
    </div>
    {children}
  </div>
)
export default MarkupsTabHeader