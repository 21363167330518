
export const UNDO_SUCCESS_MESSAGE = 'Undo applied successfully!'
export const REDO_SUCCESS_MESSAGE = 'Redo applied successfully!'
export const REDO_CONLFLICTS_MESSAGE = 'Redo the last change will conflict with another change'
export const UNDO_CONLFLICTS_MESSAGE = 'Undo the last change will conflict with another change'
export const NOT_SYNCHRONIZED_MESSAGE = 'Hey, slow down! Still applying another action. Wait until this sign is dismissed'
export const UNDO_OVERLAPS_MESSAGE = 'Wait to finish the previous undo!'
export const REDO_OVERLAPS_MESSAGE = 'Wait to finish the previous redo!'
export const NOTHING_TO_UNDO_MESSAGE = 'You don\'t have anything to undo'
export const NOTHING_TO_REDO_MESSAGE = 'You don\'t have anything to redo'

export const CONFLICT_ERROR = new Error(UNDO_CONLFLICTS_MESSAGE)
export const NOT_SYNCHRONIZED_ERROR = new Error(NOT_SYNCHRONIZED_MESSAGE)
export const NOTHING_TO_UNDO_ERROR = new Error(NOTHING_TO_UNDO_MESSAGE)
export const NOTHING_TO_REDO_ERROR = new Error(NOTHING_TO_REDO_MESSAGE)

/**
 * well-known possible errors due to the user doing something in an incorrect state.
 * The UI can treat them in a special way, and move on.
 * Other type of errors are consider "program errors" for which there's no way to recover.
 * And we need to just explode
 */
export const BUSINESS_EXPECTED_ERRORS = [CONFLICT_ERROR, NOT_SYNCHRONIZED_ERROR, NOTHING_TO_UNDO_ERROR, NOTHING_TO_REDO_ERROR]