import memoize from 'memoize-state'
import { Sys, parseRef, model } from 'beanie-engine-api-js'
import { isNil, map } from 'ramda'
import { isUUID } from 'utils/string'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { makeNameWithDisabledReferenceCheck } from './node'

const { types: { container: { getContainerContentsIds } } } = model

const isConditionalFromChoices = (objectIndex, conditional) => {
  const { data: { name } } = conditional
  if (!isUUID(name)) return false

  const choices = objectIndex[name]

  if (isNil(choices) || choices.sys !== Sys.choices) return false

  const conditions = map(id => objectIndex[id], getContainerContentsIds(conditional))
  const choiceIds = getContainerContentsIds(choices)

  conditions.forEach(({ data: { name: conditionName } }, i) => {
    if (conditionName !== choiceIds[i]) return false
  })

  return true
}

export const makeConditionalToNonExistentChoicesCheck = ({ id }) => {
  const mSelector = memoize((objectIndex) => {
    const { data: { name } } = objectIndex[id]

    if (!isUUID(name)) return OK_RESULT

    const nameNodeId = objectIndex[parseRef(name)]?.id

    return isNil(nameNodeId) ?
      {
        status: CHECK_RESULT_STATUS.ERROR,
        message: `The choices object with id ${parseRef(name)} doesn't exist`,
        severity: CHECK_SEVERITY_LEVEL.ERROR,
        id: 'With non existent name node'
      } :
      OK_RESULT
  })

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export const makeConditionalToTruthTableCheck = ({ id }) => {
  const mSelector = memoize((objectIndex) => (
    isConditionalFromChoices(objectIndex, objectIndex[id]) ? {
      status: CHECK_RESULT_STATUS.ERROR,
      message: 'This conditional could be transformed into a truth table',
      id: 'With conditional as truth table',
      severity: CHECK_SEVERITY_LEVEL.INFO,
    } : OK_RESULT))

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export default [
  {
    name: 'Conditional point to non existent choices',
    makeSelector: makeConditionalToNonExistentChoicesCheck
  },
  {
    name: 'Conditional to truth table',
    makeSelector: makeConditionalToTruthTableCheck
  },
  {
    name: 'Conditional from disabled node',
    makeSelector: makeNameWithDisabledReferenceCheck
  }
]