exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".descriptionColumn__ivp9D{width:60%}", ""]);

// exports
exports.locals = {
	"descriptionColumn": "descriptionColumn__ivp9D"
};