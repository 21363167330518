import React from 'react'
import { connect } from 'react-redux'
import { TextEditorAutoMergePreference, StaticAnalysisEnablePreference } from 'model/project/preferences'
import SelectProjectPreference from 'components/Commons/Preferences/Project/SelectProjectPreference'
import SelectPreference from '../../../../Commons/Preferences/SelectPreference'
import PreferencesDeclarations from 'preferences/Preferences'
import Preference from './Preference'

import styles from './Preferences.scss'
import PreferencesGroup from './PreferencesGroup'

const Preferences = () => (
  <div className={styles.Preferences}>
    <h2 className={styles.subTitle}>Preferences</h2>

    <div>
      {/* general */}
      <PreferencesGroup title="General">
        <Preference title="Problems Detection" message="This will affect other online users editing the project">
          <SelectProjectPreference preference={StaticAnalysisEnablePreference} />
        </Preference>
        <Preference title="Project Data Cache">
          <SelectPreference
            preference={PreferencesDeclarations.ProjectLoading.projectDataCache}
            options={[
              { name: 'Enabled', value: true },
              { name: 'Disabled', value: false },
            ]}
          />
        </Preference>
        <Preference title="Gamepad Support">
          <SelectPreference
            preference={PreferencesDeclarations.GraphView.trackSelection}
            options={[
              { name: 'Enabled', value: true },
              { name: 'Disabled', value: false },
            ]}
          />
        </Preference>
      </PreferencesGroup>

      {/* text editor */}
      <PreferencesGroup title="Text Editor">
        <Preference title="Auto-merge">
          <SelectProjectPreference preference={TextEditorAutoMergePreference} />
        </Preference>
      </PreferencesGroup>

      {/* expression editor */}
      <PreferencesGroup title="Expression Editor">
        <Preference title="Linter">
          <SelectPreference
            preference={PreferencesDeclarations.ExprEditor.linterEnabled}
            options={[
              { name: 'Enabled', value: true },
              { name: 'Disabled', value: false },
            ]}
          />
        </Preference>
      </PreferencesGroup>
    </div>

  </div>
)

export default connect(null, null)(Preferences)