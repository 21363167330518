import React from 'react'
import classNames from 'classnames'
import { pathEq, reject } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

import styles from './WalkListNodeBadge.scss'

const WalkListNodeBadge = ({ nodeId, walkListMatch }) => (shouldShowBadge(nodeId, walkListMatch) ?
  <div className={classNames(styles.walklistElementMatchesBadge, { [styles.active]: walkListMatch.current || walkListMatch.currentIndex >= 0 })}>
    {walkListMatch.currentIndex >= 0 && walkListMatch.results.length > 1 ?
      (
        <React.Fragment>
          <span>{walkListMatch.currentIndex + 1}</span>
          <span>/</span>
          <span>{walkListMatch.results.length}</span>
        </React.Fragment>
      ) : walkListMatch.results.length
    } 
  </div>
  : null  
)

const otherObjectsResults = (id, results) => reject(pathEq(['object', 'id'], id), results)
const shouldShowBadge = (id, match) => match && isNotEmpty(otherObjectsResults(id, match.results))

export default WalkListNodeBadge