import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, withProps, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { path, append, reject, equals } from 'ramda'

import editorsQuery from 'api/queries/editors.graphql'
import changeSetsQuery from 'api/queries/changeSets.graphql'
import { revisionId } from 'selectors/project'
import paginatedQuery from 'hocs/paginatedQuery'

import HistoryPanel from 'components/HistoryPanel/HistoryPanel'
import HistoryFilterPanel from 'components/HistoryFilterPanel/HistoryFilterPanel'
import ChangeSetPanel from 'components/ChangeSetPanel/ChangeSetPanel'

import { EMPTY_ARRAY } from 'utils/object'
import { ChangeSetDescKind } from 'model/ChangeSet'

import styles from './HistorySection.scss'

const editorsPath = ['revisionWithId', 'editors']
export const pageSize = 10

const HistorySection = ({ onSelectEditor, onDeselectEditor, changeSetData, changeSet, setChangeSet, editors, searchByDescription, onBlurDescription, onPageChanged, handleTableChange, refetchQuery, onExcludeByDescKind, excludeByDescKind }) => (
  <div className={styles.section}>
    <HistoryFilterPanel
      editors={editors}
      onSearchByDescription={searchByDescription}
      onSelectEditor={onSelectEditor}
      onBlurDescription={onBlurDescription}
      onDeselectEditor={onDeselectEditor}
      onExcludeByDescKind={onExcludeByDescKind}
      excludeByDescKind={excludeByDescKind}
    />
    <HistoryPanel
      changeSetData={changeSetData}
      onChangeSetSelected={setChangeSet}
      changeSet={changeSet}
      handleTableChange={handleTableChange}
      onPageChanged={onPageChanged}
      refetchQuery={refetchQuery}
    />
    <ChangeSetPanel changeSet={changeSet} />
  </div>
)

export default compose(
  withState('changeSet', 'setChangeSet'),
  withState('description', 'setDescription', ''),
  withState('editorIds', 'setEditorIds', []),
  withState('excludeByDescKind', 'setExcludeByDescKind', [ChangeSetDescKind.TtsChangeSet]),
  connect(state => ({
    revision: revisionId(state)
  })),
  graphql(editorsQuery, {
    name: 'revisionEditors',
    options: ({ revision }) => ({ variables: { revisionId: revision } }),
    skip: ({ revision }) => !revision,
    fetchPolicy: 'network-only' }),
  withProps(({ revisionEditors }) => ({
    editors: revisionEditors && !revisionEditors.error && !revisionEditors.loading ?
      path(editorsPath, revisionEditors) : EMPTY_ARRAY
  })),
  paginatedQuery({
    query: changeSetsQuery,
    name: 'changeSetData',
    pageSize,
    variables: ({ revision, editorIds, description, excludeByDescKind }) => ({ revisionId: revision, editorIds, query: description, excludeByDescKind }),
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    skip: ({ revision }) => !revision
  }),
  withHandlers({
    onSearchWithFilters: ({ changeSetData, description, editorIds, excludeByDescKind }) => () => changeSetData.refetch({ description, editorIds, excludeByDescKind })
  }),
  withHandlers({
    searchByDescription: ({ setDescription, onSearchWithFilters }) => newDescription => {
      setDescription(newDescription)
      onSearchWithFilters()
    },
    onBlurDescription: ({ setDescription, onSearchWithFilters, description }) => onBlurDescription => {
      if (description !== onBlurDescription) {
        setDescription(onBlurDescription)
        onSearchWithFilters()
      }
    },
    onSelectEditor: ({ setEditorIds, editorIds, onSearchWithFilters }) => editorId => {
      const newEditorIds = append(editorId, editorIds)
      setEditorIds(newEditorIds)
      onSearchWithFilters()
    },
    onDeselectEditor: ({ setEditorIds, editorIds, onSearchWithFilters }) => async editorId => {
      const newEditorIds = reject(equals(editorId), editorIds)
      setEditorIds(newEditorIds)
      onSearchWithFilters()
    },
    onExcludeByDescKind: ({ setExcludeByDescKind, onSearchWithFilters }) => checked => {
      setExcludeByDescKind(checked)
      onSearchWithFilters()
    }
  })
)(HistorySection)