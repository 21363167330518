exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".columnHeader__1H060{position:relative}.header__19PFf{background-color:#d5be9b;border:1px solid gray;width:100%;height:100%;min-height:1.8rem}.resultErrorHeader__sMLXf{background:#bd7373 !important;display:-ms-flexbox;display:flex;position:relative;color:#fff}.overToDrop__11mkb{border-left:3px solid #a48759}.dragging__2VFqU{opacity:50%}.headerTopSelected__2Hfjg{-webkit-box-shadow:2px 0px 2px #d2d2d3;box-shadow:2px 0px 2px #d2d2d3;border-top:1px solid #7b81a3}.headerLeftSelectedBorder__1xSAG{border-left:1px solid #7b81a3}.headerRightSelectedBorder__XpQYv{border-right:1px solid #7b81a3}.headerBottomSelectedBorder__14JCk{-webkit-box-shadow:2px 2px 2px #d2d2d3;box-shadow:2px 2px 2px #d2d2d3;border-bottom:1px solid #7b81a3}.cellValueContainer__3qkWD{display:-ms-flexbox;display:flex;position:relative;-ms-flex-pack:center;justify-content:center}.cellValueContainer__3qkWD .cellValue__3ycq7{display:-ms-flexbox;display:flex;position:absolute;-ms-flex-pack:center;justify-content:center;min-width:auto;max-width:10rem;max-height:none;width:auto;top:-0.7rem;color:#eae7e2;border:solid 1px #848482;background:#b8a387;padding-left:.1rem;padding-right:.1rem;border-radius:7%}", ""]);

// exports
exports.locals = {
	"columnHeader": "columnHeader__1H060",
	"header": "header__19PFf",
	"resultErrorHeader": "resultErrorHeader__sMLXf",
	"overToDrop": "overToDrop__11mkb",
	"dragging": "dragging__2VFqU",
	"headerTopSelected": "headerTopSelected__2Hfjg",
	"headerLeftSelectedBorder": "headerLeftSelectedBorder__1xSAG",
	"headerRightSelectedBorder": "headerRightSelectedBorder__XpQYv",
	"headerBottomSelectedBorder": "headerBottomSelectedBorder__14JCk",
	"cellValueContainer": "cellValueContainer__3qkWD",
	"cellValue": "cellValue__3ycq7"
};