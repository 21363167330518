exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".formItemContent__rs4yD{width:100%}", ""]);

// exports
exports.locals = {
	"formItemContent": "formItemContent__rs4yD"
};