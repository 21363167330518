import { atom } from 'recoil'
import { EMPTY_ARRAY } from 'utils/object'

// TODO: rename to searchHistory

export const searchesState = atom({
  key: 'searches',
  default: EMPTY_ARRAY,
})

export const currentSearchState = atom({
  key: 'currentSearch',
})