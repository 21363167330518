import { when, pipe, propEq, anyPass } from 'ramda'

import { isNotNil } from 'ramda-adjunct'
import promisedScrollIntoView from 'smooth-scroll-into-view-if-needed'
import { paramCase as kebabCase } from 'change-case'

import { propFrom } from 'utils/object'

// contains DOM imperative operations or logic
// We shouldn't be doing much of this since React handles the DOM
// but sometimes we need to refer to a node for example to scroll to that.

//
// Data fields
//

const dataField = name => ({ name, propName: kebabCase(name) })

export const DataFields = {
  laneName: dataField('laneName'),
  nodeId: dataField('nodeId'),
  ownContextMenu: dataField('ownContextMenu')
}

export const withData = (field, value) => ({
  [`data-${field.propName}`]: value
})

//
//
//

export const nodeIdFor = id => `node-${id}`
export const textViewItemIdFor = id => `textViewItem-${id}`
export const autoCompleteViewId = id => `${AUTOCOMPLETE_PORTAL_VIEW_DOM_ID}_${id}`

const queryDOMForNodeId = id => document.querySelector(`#${nodeIdFor(id)}`)

// this is to at least avoid the string dup in other places. Not ideal
export const TREE_VIEW_DOM_ID = 'treeView'
export const EDIT_PROJECT_SECTION_DOM_ID = 'editProjectSection'

export const EDITOR_VIEW_DOM_ID = 'editorView'
export const editorIdFor = id => `${EDITOR_VIEW_DOM_ID}_${id}`
export const AUTOCOMPLETE_PORTAL_VIEW_DOM_ID = 'autoCompleteView'

const focus = n => n.focus()
const focusIfExists = when(isNotNil, focus)
export const focusForNode = pipe(queryDOMForNodeId, focusIfExists)

export const isFocusWithinTreeView = () => currentOrAncestorSatisfies(propEq('id', TREE_VIEW_DOM_ID))

export const isFocusWithinAutocomplete = ({ object }) => currentOrAncestorSatisfies(propEq('id', autoCompleteViewId(object.id)))

export const isFocusWithinTextEditor = ({ object, editorId }) =>
  currentOrAncestorSatisfies(anyPass([
    propEq('id', editorId),
    propEq('id', autoCompleteViewId(object.id)),
    propEq('id', textViewItemIdFor(object.id)),
  ]))

export const currentOrAncestorSatisfies = (cond, elem = document.activeElement) => cond(elem) || !!findAncestor(cond, elem)

export const ScrollAlign = {
  center: 'center',
  start: 'start',
  end: 'end',
  nearest: 'nearest'
}

export const scrollIntoView = async (id, alignment, boundarySelector) => {
  const dom = document.querySelector(`#${id}`)
  if (dom) {
    await promisedScrollIntoView(dom, {
      scrollMode: 'if-needed',
      block: alignment,
      inline: alignment,
      behavior: 'smooth',
      ...!!boundarySelector && { boundary: document.querySelector(boundarySelector) }
    })
    focusForNode(id)
  }
}

export const findAncestor = (cond, from) => {
  let element = from
  while (element) {
    if (cond(element)) {
      return element
    }
    element = element.parentElement
  }
  return undefined
}

export const removeNodeAfter = (time, node) => setTimeout(() => {
  if (node.parentNode) {
    node.parentNode.removeChild(node)
  }
}, 10000)

const findDataTraversingAncestors = _dataField => ({ clientX: x, clientY: y }) => {
  const dom = findAncestor(e => e.dataset[_dataField.name], document.elementFromPoint(x, y))
  return dom && dom.dataset[_dataField.name]
}

export const findLaneUnderMouse = findDataTraversingAncestors(DataFields.laneName)

export const findElementUnderMouse = index => pipe(
  findDataTraversingAncestors(DataFields.nodeId),
  when(isNotNil, propFrom(index))
)

export const isUnderDOMElementWithOwnContextMenu = pipe(findDataTraversingAncestors(DataFields.ownContextMenu), isNotNil)
