import React from 'react'
import { DECORATION_TYPES } from '../utils/Constants'
import SearchHighlightDecoration from '../Components/SearchHighlightDecoration/SearchHighlightDecoration'

const renderDecoration = () => (props, editor, next) => {
  const { children, decoration, attributes } = props

  switch (decoration.type) {
    case DECORATION_TYPES.highlight: return (
      <SearchHighlightDecoration
        decoration={decoration}
        attributes={attributes}
        clipId={editor.props.object.id}
      >
        {children}
      </SearchHighlightDecoration>
    )
    default: return next()
  }
}

export default renderDecoration