exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".control__2N8Fb{background-color:red;color:#fff;font-size:.8rem;position:absolute;top:-1rem;right:0;padding-left:.5rem;padding-right:.5rem}.control__2N8Fb .anticon{padding-right:.5rem}", ""]);

// exports
exports.locals = {
	"control": "control__2N8Fb"
};