import { model, ref } from 'beanie-engine-api-js'
import { notQuery, pathEqQuery, propEqQuery } from './queries'
import QueryType from './QueryType'

const { types: { object: { Paths } } } = model

export const isBNENode = ({ type: QueryType.IS_BNE_NODE })
export const isSys = sys => propEqQuery('sys', sys)
export const isNotSys = sys => notQuery(isSys(sys))

export const lineHasActor = actor => pathEqQuery(Paths.line.actor, ref(actor.id))

export const lineHasMarkup = name => ({
  type: QueryType.LINE_HAS_MARKUP,
  name
})