import { merge } from 'rxjs'
import { mapTo, distinctUntilChanged, startWith } from 'rxjs/operators'

const eventToStateObservable = (enterName, leaveName, initialValue = false) => component => {
  const [onEnter$, onEnter] = component.useEvent(enterName)
  const [onLeave$, onLeave] = component.useEvent(leaveName)
  
  const observable$ = merge(
    onEnter$.pipe(mapTo(true)),
    onLeave$.pipe(mapTo(false))
  ).pipe(
    startWith(initialValue),
    distinctUntilChanged(),
  )

  return [observable$, onEnter, onLeave]
}

export default eventToStateObservable