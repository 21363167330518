exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2Guyz{color:#add8e6;cursor:pointer}.iconAsLink__2Guyz:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__2rRVr{font-style:italic}.formFieldsVerticalGutter__2lrfF{padding:.5rem}.formFieldsVerticalGutter__2lrfF>*{margin:.5rem !important}.tableBackground__3I9hP .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__3I9hP .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1c8Uo .ant-notification-notice-close{display:none}ol.dialogue__Pzjjd{-ms-flex:1 1 0%;flex:1 1 0%;overflow:auto;padding-left:0px}ol.dialogue__Pzjjd li{color:#3f3f3f;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start;padding:1rem;padding-left:0px}ol.dialogue__Pzjjd li:not(:last-child){padding-bottom:0em}ol.dialogue__Pzjjd li.defaultActorLine__1UDRt{border:0px;background:none;-ms-flex-pack:center;justify-content:center;-webkit-box-shadow:none;box-shadow:none;color:#9d9b9b}ol.dialogue__Pzjjd li.defaultActorLine__1UDRt .ant-avatar{display:none}ol.dialogue__Pzjjd li.sameActorAsPrevious__1pXYw{padding-top:0px}ol.dialogue__Pzjjd li.sameActorAsPrevious__1pXYw:not(.defaultActorLine__1UDRt) .lineActorAvatar__1vz9c{visibility:hidden;height:1px}ol.dialogue__Pzjjd li.sameActorAsNext__2eDsK{padding-bottom:2px}ol.dialogue__Pzjjd li.isPlaying__12oin{color:#6a6ab8}ol.dialogue__Pzjjd li .actor__9eyT9{font-weight:bolder;font-size:1.1em;min-width:3.5em}ol.dialogue__Pzjjd li .lineText__3RHd8{padding-left:1em}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2Guyz",
	"labelFromRef": "labelFromRef__2rRVr",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__2lrfF",
	"tableBackground": "tableBackground__3I9hP",
	"notificationNotClosable": "notificationNotClosable__1c8Uo",
	"dialogue": "dialogue__Pzjjd",
	"defaultActorLine": "defaultActorLine__1UDRt",
	"sameActorAsPrevious": "sameActorAsPrevious__1pXYw",
	"lineActorAvatar": "lineActorAvatar__1vz9c",
	"sameActorAsNext": "sameActorAsNext__2eDsK",
	"isPlaying": "isPlaying__12oin",
	"actor": "actor__9eyT9",
	"lineText": "lineText__3RHd8"
};