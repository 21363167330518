exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".TeamMembersHeader__3Mvg7{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:1rem}.TeamMembersHeader__3Mvg7>div:first-child{width:30rem}", ""]);

// exports
exports.locals = {
	"TeamMembersHeader": "TeamMembersHeader__3Mvg7"
};