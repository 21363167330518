exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2kL4N{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-positive:1;flex-grow:1;padding-top:3rem}", ""]);

// exports
exports.locals = {
	"container": "container__2kL4N"
};