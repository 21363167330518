import React from 'react'

import ModalOpener from 'components/Commons/ModalOpener'
import FetchImageInput from 'components/SetStoryboardModal/FetchFileFromURLInput'

import DropZone from 'components/SetStoryboardModal/DropZone'
import styles from 'components/SetStoryboardModal/SetStoryboardModal.scss'

export default class FileUploaderModal extends React.Component {

  state = {
    file: undefined
  }

  render() {
    const { file } = this.state
    const { title, header, inputPlaceHolder, acceptedTypes, urlSource = true, icon, preview } = this.props
    return (
      <ModalOpener
        title={title}
        onOk={this.onOk}
        onCancel={this.onCancel}
        okButtonProps={{ disabled: !file }}
        modalProps={{ destroyOnClose: true }}
      >
        <div className={styles.content} contentEditable={false}>
          {header}
          <DropZone acceptedTypes={acceptedTypes} onDrop={this.setImage} file={file} icon={icon} />
          {urlSource && (
            <React.Fragment>
              <span><Line /> or <Line /></span>
              <FetchImageInput onFetched={this.setImage} inputPlaceHolder={inputPlaceHolder} />
            </React.Fragment>
          )}
          {preview && preview(file)}
        </div>
      </ModalOpener>
    )
  }

  onOk = async () => {
    const { file } = this.state
    const { hide, onFileProvided = () => {}, waitForAcceptToClose = false } = this.props
    if (waitForAcceptToClose) {
      await onFileProvided(file)
    } else {
      onFileProvided(file)
    }
    hide()
  }
  onCancel = () => {
    const { hide } = this.props
    hide()
  }

  setImage = file => { this.setState({ file }) }
}

const Line = ({ size = 50 }) => (<svg width={size} height="5">
  <line x1="0" y1="0" x2={size} y2="0" />
</svg>)
