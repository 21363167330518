import { Sys } from 'beanie-engine-api-js'
import { EMPTY_ARRAY } from 'utils/object'
import { always } from 'ramda'

import choicesParser from './parsers/choices'

const parsers = {
  [Sys.choices]: choicesParser,
  [Sys.choices2]: choicesParser,
}

const textToModel = (container, editorRawState) => (parsers[container.sys] || always(EMPTY_ARRAY))(editorRawState)

export default textToModel

