exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".projectSettings__1LKCx{margin:1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.projectSettings__1LKCx .content__2HSgq{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;background-color:#fff;min-height:85vh;border-radius:5px;padding:1rem}.projectSettings__1LKCx .content__2HSgq .ant-menu{width:15rem;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border:1px solid #d3d3d3}.projectSettings__1LKCx .content__2HSgq .ant-menu .ant-menu-item:not(:last-child){border-bottom:1px solid #d3d3d3}.projectSettings__1LKCx h1{padding-bottom:1em;padding-left:.5em}.projectSettings__1LKCx h1 .anticon{padding-right:.5em}.projectSettings__1LKCx .sections__2cFFa{width:100%;padding-left:2rem;padding-right:1rem}.projectSettings__1LKCx .sections__2cFFa>div:not(:last-child):not(:first-child){padding-bottom:1.5rem}h2{-ms-flex-preferred-size:3rem;flex-basis:3rem}", ""]);

// exports
exports.locals = {
	"projectSettings": "projectSettings__1LKCx",
	"content": "content__2HSgq",
	"sections": "sections__2cFFa"
};