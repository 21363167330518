import React from 'react'
import { model } from 'beanie-engine-api-js'

import { insertRowAtPosition, removeOtherwiseRow as removeOtherwise } from 'engine/actions/truthTable'
import TruthTableRowMenu from '../Menu/TruthTableRowMenu'
import DropdownMenu from '../DropdownMenu'
import { togglePinUnpinSelectedNode } from 'actions/project/debuggingData'
import useDropdownAction from '../row/useDropdownAction'

import styles from '../DropdownMenu.scss'

const { types: { truthTable: { rows } } } = model

/**
 *
 */
const OtherwiseRowDropdown = ({ node, pinned, truthTable, setDropdownVisibility }) => {

  const insertRowAbove = useDropdownAction(setDropdownVisibility, () =>
    insertRowAtPosition(truthTable, rows(truthTable).length), [truthTable]
  )

  const deleteRow = useDropdownAction(setDropdownVisibility, () =>
    removeOtherwise(truthTable), [truthTable]
  )

  const toggleDebugging = useDropdownAction(setDropdownVisibility, () =>
    togglePinUnpinSelectedNode(node), [node]
  )

  return (
    <DropdownMenu
      iconClassName={styles.rowIcon}
      setDropdownVisibility={setDropdownVisibility}
    >
      <TruthTableRowMenu
        node={node}
        truthTable={truthTable}
        pinned={pinned}

        insertRowAbove={insertRowAbove}
        deleteRow={deleteRow}
        toggleDebugging={toggleDebugging}

        setDropdownVisibility={setDropdownVisibility}
      />
    </DropdownMenu>
  )
}

export default OtherwiseRowDropdown