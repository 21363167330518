import React from 'react'

import styles from './FieldChange.scss'

const FieldSkeleton = ({ field, children }) => (
  <div className={styles.DefaultFieldChange}>
    <span>{field}</span>
    <div className={styles.delta}>
      {children}
    </div>
  </div>
)

export default FieldSkeleton