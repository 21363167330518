import React from 'react'
import SearchBox from 'components/Commons/SearchBox'
import OrganizationMembersTable from './OrganizationMembersTable'

import styles from './OrganizationMembersPane.scss'

const OrganizationMembersPane = ({ organizationMembersList, pageSize, onPageChanged, handleTableChange, onSearch, refreshItems }) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <SearchBox onSearch={onSearch} />
    </div>
    {organizationMembersList &&
      <OrganizationMembersTable data={organizationMembersList} pageSize={pageSize} handleTableChange={handleTableChange} onPageChanged={onPageChanged} refreshItems={refreshItems} />
    }
  </div>
)

export default OrganizationMembersPane