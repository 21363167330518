import React from 'react'
import { connect } from 'react-redux'
import { navigateToOrganization } from 'actions/routing'

import LinkButton from 'components/Commons/LinkButton'

const GoToOrganizationLink = ({ goToOrg, children }) => (
  <LinkButton onClick={goToOrg}>
    {children}
  </LinkButton>
)

export default connect(null, (dispatch, { organization }) => ({
  goToOrg: () => dispatch(navigateToOrganization(organization.name))
}))(GoToOrganizationLink)