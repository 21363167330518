import React from 'react'
import winston from 'utils/logger'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withApollo } from 'react-apollo'
import Subscribed from 'hocs/Subscribed'
import { Icon, notification } from 'antd'

import { ProjectEventTypes } from 'model/constants'

import projectEventsSubscriptionQuery from 'api/subscriptions/projectEvents.graphql'
import { projectPrefenceSet } from 'actions/project'
import projectBranchCreated from 'actions/project/events/project/projectBranchCreated'
import projectCheckpointCreated from 'actions/project/events/project/projectCheckpointCreated'
import projectRevisionDeleted from 'actions/project/events/project/projectRevisionDeleted'

import { user as userSelector } from 'selectors/auth'


class ProjectEvents extends Subscribed {

  query = projectEventsSubscriptionQuery
  variables = { project: this.props.project }
  subscriptionEnabled = () => !!this.props.project

  onSubscriptionEvent({ data: { projectEvents: event } }) {
    const { user, project, projectPrefenceSetAction, projectBranchCreatedAction, projectRevisionDeletedAction, projectCheckpointCreatedAction } = this.props

    if (project !== event.project) {
      // ignore events from other projects
      return
    }
    const isFromUs = event.user && user._id === event.user

    switch (event.__typename) {
      case ProjectEventTypes.ProjectPreferenceSet: {
        if (isFromUs) { return }
        projectPrefenceSetAction(event.preference)
        return
      }
      case ProjectEventTypes.ProjectBranchCreated: {
        if (isFromUs) { return }
        projectBranchCreatedAction(event.branch)
        return
      }
      case ProjectEventTypes.ProjectCheckpointCreated: {
        if (isFromUs) { return }
        projectCheckpointCreatedAction(event.checkpoint)
        return
      }
      case ProjectEventTypes.ProjectRevisionDeleted: {
        projectRevisionDeletedAction(event.revision)
        return
      }
      default: {
        winston.debug('Unknown project event', event)
      }
    }
  }

  onSubscriptionError(error) {
    notification.open({
      message: 'Communication Error',
      description: `${JSON.stringify(error)}`,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
    })
  }

  render() {
    return null
  }
}

export default compose(
  connect(state => ({
    user: userSelector(state),
  }),
  ({
    projectPrefenceSetAction: projectPrefenceSet,
    projectBranchCreatedAction: projectBranchCreated,
    projectCheckpointCreatedAction: projectCheckpointCreated,
    projectRevisionDeletedAction: projectRevisionDeleted,
  })
  ),
  withApollo
)(ProjectEvents)
