import { Sys, NodePlaybackStatus } from 'beanie-engine-api-js'

import { ChoicesResultType } from '../model/constants'

import { currentClipId, currentChoicesId, currentChoicesResult } from '../selectors/engine'

// other actions
import { Creators as EngineActions } from './engine'

// TODO: make a test for each callback

// eslint-disable-next-line no-unused-vars
const onBeginPresentNode = dispatch => (bneNode, threadId, speculative) => {
  // console.log('[engine] onBeginPresentNode', bneNode.get_id(), '=', bneNode.get_sys())
  const id = bneNode.get_id()
  const sys = bneNode.get_sys()

  if (sys === Sys.clip) {
    dispatch(EngineActions.onPlayClip(id))
  } else if (sys === Sys.choices) {
    const enabledValue = bneNode.get_enabled_choices()
    const enabledChoices = enabledValue ? enabledValue.toJSONObject() : []
    dispatch(EngineActions.playChoices(id, enabledChoices))
  }
}

const isCurrentChoices = (id, sys, state) => sys === Sys.choices && id === currentChoicesId(state)

// eslint-disable-next-line no-unused-vars
const onEndPresentNode = (dispatch, getState) => (bneNode, threadId, speculative) => {
  const id = bneNode.get_id()
  const sys = bneNode.get_sys()
  const state = getState()
  // console.log('[engine] onEndPresentNode', id, '=', sys)

  if (sys === Sys.clip) {
    dispatch(EngineActions.endClip(id))
  }
  if (isCurrentChoices(id, sys, state)) {
    dispatch(EngineActions.endChoices(id))
  }
}

// eslint-disable-next-line no-unused-vars
const getPresentStatus = (dispatch, getState) => (bneNode, threadId, speculative) => {
  const id = bneNode.get_id()
  const sys = bneNode.get_sys()
  const state = getState()

  if (sys === Sys.clip) {
    return currentClipId(state) === id ? NodePlaybackStatus.PRESENTING_INTERRUPTIBLE : NodePlaybackStatus.NOT_PRESENTING
  }

  if (isCurrentChoices(id, sys, state)) {
    const result = currentChoicesResult(state)
    return result ? choiceResultToPresentationStatus(result) : NodePlaybackStatus.PRESENTING_NOT_INTERRUPTIBLE
  }
  return NodePlaybackStatus.NOT_PRESENTING
}

const choiceResultToPresentationStatus = result => {
  switch (result.type) {
    case ChoicesResultType.EXPIRED: return NodePlaybackStatus.CHOICE_TIMED_OUT
    case ChoicesResultType.SELECTED: return result.value
    default: throw new Error(`Unknown ChoicesResultType ${result}`)
  }
}


const videoHostPresenter = (dispatch, getState) => ({

  onBeginPresentNode: onBeginPresentNode(dispatch, getState),
  onEndPresentNode: onEndPresentNode(dispatch, getState),
  getNodeExecutionStatus: getPresentStatus(dispatch, getState),
  onPresentationEnded: () => {
    dispatch(EngineActions.onPresentationEnded())
  },

  // TODO missing error handler "onPresentationError" !

})
export default videoHostPresenter