import React from 'react'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import { prop, path, pathOr } from 'ramda'
import { Icon, Spin } from 'antd'
import { ChooseValue as ChooseComponent } from 'components/PropertiesEditor/Value/ChooseValue'

import ttsVoicesQuery from 'api/queries/ttsVoices.graphql'

import styles from './VoiceValue.scss'

export const groupByLocale = pathOr('No locale', ['locale', 'language'])
const genderIcon = gender => (gender === 'Male' ? 'man' : 'woman')

export const renderOption = voice => (
  <div className={styles.option}>
    <Icon type={genderIcon(voice.gender)} />
    <span>{voice.name}</span>
    {path(['locale', 'country'], voice) &&
      <span className={styles.locale}>({voice.locale.country})</span>
    }
  </div>
)

const _VoiceValue = ({ availableVoices, ...rest }) => (
  <Spin spinning={availableVoices.loading}>
    <ChooseComponent
      {...rest}
      options={availableVoices.ttsVoices}
      keyFn={prop('_id')}
      labelFn={renderOption}
      valueFn={prop('_id')}
      groupBy={groupByLocale}
    />
  </Spin>
)

export default compose(
  graphql(ttsVoicesQuery, { name: 'availableVoices' })
)(_VoiceValue)
