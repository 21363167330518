import React from 'react'
import AceEditor from '../AceEditor/AceEditor'

/**
 * A component to edit Lua
 * We should reuse this in all places we want to edit lua code
 */
const LuaEditor = ({ id, placeholder, content, setContent, className, height, width }) => {

  return (
    <AceEditor
      id={id}
      className={className}
      mode="lua"
      autoComplete
      height={height}
      width={width}

      placeholder={placeholder}
      value={content}
      onChange={setContent}

      focus
    />
  )
}

export default LuaEditor