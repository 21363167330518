import React from 'react'

import { Avatar } from 'antd'
import StringValue from 'components/PropertiesEditor/Value/StringValue'
import ActorAvatar from 'components/Commons/ActorAvatar'

import styles from './ImageValue.scss'

// TODO: reach to the actor object name to use it as avatar
//  in case no image was set.

// inherits from stringValue as a temporary way to edit the value
export default class ImageValue extends StringValue {

  renderForDisplaying() {
    const { value } = this.props
    const hasValue = value && value.length > 0
    return (
      <div>
        {hasValue &&
          <div className={styles.imageValue}>
            <ActorAvatar fileName={value} />
            <div className={styles.value}>{`${value}`}</div>
          </div>
        }
        {!hasValue &&
          <Avatar>?</Avatar>
        }
      </div>
    )
  }

}