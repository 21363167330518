import { compose, withStateHandlers, lifecycle, withState } from 'recompose'
import { graphql } from 'react-apollo'
import { extractTokenFromInviteUrl } from 'actions/routing'
import States, { InviteStatus } from './InvitesState'
import InvitesAccess from './InvitesAccess'

import inviteValidate from 'api/mutations/inviteValidate.graphql'

export default compose(
  withState('token', 'setToken', () => extractTokenFromInviteUrl()),
  withStateHandlers(
    { state: States.Starting },
    {
      onSuccess: prev => () => ({ ...prev, state: States.Accepted }),
      validatingToken: prev => () => ({ ...prev, state: States.Validating }),
      tokenValidated: prev => ({ status, reason }) => ({
        ...prev,
        state: status === InviteStatus.Valid ? States.Taking : States.InvalidToken,
        invalidReason: reason
      })
    }
  ),
  graphql(inviteValidate, { name: 'inviteValidateMutation' }),
  lifecycle({
    async componentDidMount() {
      const { inviteValidateMutation, token, tokenValidated } = this.props

      const response = await inviteValidateMutation({ variables: { input: { token } } })
      tokenValidated(response.data.inviteValidate)
    }
  })
)(InvitesAccess)