import React from 'react'
import Gamepad from 'react-gamepad'
import { compose, withHandlers, lifecycle, withState, branch, renderNothing } from 'recompose'
import { head, propEq } from 'ramda'
import { connect } from 'react-redux'
import { Creators } from 'actions/vm'
import { Creators as UICreators } from 'actions/ui'
import { makeCurrentlyPlayingChoicesSelector, isPlaying as isPlayingSelector } from 'selectors/playback'
import { gamepadType as gamepadTypeSelector } from 'selectors/ui'

import { buttonToChoiceIndex } from 'utils/gamepad'
import { noop } from 'utils/functions'
import withPreference from '../../../hocs/withPreference'
import Preferences from '../../../preferences/Preferences'

const { selectChoice: choiceSelectedAction } = Creators
const { gamepadConnected: gamepadConnectedAction, gamepadDisconnected: gamepadDisconnectedAction } = UICreators

// presentational, pure
export const GamePadController = ({ onConnect, onDisconnect, onButtonChange }) => (
  <Gamepad
    onConnect={onConnect}
    onDisconnect={onDisconnect}
    onButtonChange={onButtonChange}
  >
    <span />
  </Gamepad>
)

// with state and adapting from API buttons to choices
export const ChoicesGamePadController = compose(
  withState('gamepadId', 'setGamepadId'),
  withHandlers({
    onConnect: ({ setGamepadId, gamepadConnected = noop }) => gamepadIndex => {
      const gamepad = navigator.getGamepads()[gamepadIndex]
      setGamepadId(gamepad.id)
      gamepadConnected(gamepad)
    },
    onDisconnect: ({ setGamepadId, gamepadDisconnected = noop }) => gamepadIndex => {
      const gamepad = navigator.getGamepads()[gamepadIndex]
      setGamepadId(undefined)
      gamepadDisconnected(gamepad)
    },
    onButtonChange: ({ isPlaying = true, buttonPressed = noop, choicePlaybackNode, choiceSelected = noop, gamepadType }) => buttonName => {
      if (!choicePlaybackNode || !isPlaying) return
      buttonPressed(buttonName)
      const selectedChoiceIndex = buttonToChoiceIndex(gamepadType, buttonName)
      if (selectedChoiceIndex) {
        return choiceSelected(choicePlaybackNode.playbackId, selectedChoiceIndex)
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      const { setGamepadId } = this.props
      const firstGamepad = head(navigator.getGamepads())
      if (firstGamepad) {
        setGamepadId(firstGamepad)
      }
      // Multi-gamepad support
      // show user a non-invasive prompt to see which one will be picked
      // and which others available to allow to quickly switch between them.
    }
  })
)(GamePadController)

// connected
export default compose(
  withPreference({
    enabled: Preferences.Playback.gamePadEnabled,
  }),
  branch(
    propEq('enabled', true),
    // enabled
    connect(() => {
      const choicesSelector = makeCurrentlyPlayingChoicesSelector()
      return state => ({
        choicePlaybackNode: head(choicesSelector(state)),
        gamepadType: gamepadTypeSelector(state),
        isPlaying: isPlayingSelector(state)
      })
    }, {
      choiceSelected: choiceSelectedAction,
      gamepadConnected: gamepadConnectedAction,
      gamepadDisconnected: gamepadDisconnectedAction,
    }),
    // disabled
    renderNothing
  )
)(ChoicesGamePadController)
