import { any, filter, propEq, prop, isEmpty, map, reject, complement, pipe } from 'ramda'
import { CHANGE_TYPE } from './lineChange'
import { EMPTY_ARRAY } from 'utils/object'

const isDeleteEdit = (type1, type2) =>
  (type1 === CHANGE_TYPE.DELETE && type2 === CHANGE_TYPE.EDIT) || (type1 === CHANGE_TYPE.EDIT && type2 === CHANGE_TYPE.DELETE)

const isEditEdit = (type1, type2) => type1 === type2 && type1 === CHANGE_TYPE.EDIT

export const haveConflicts = (baseChanges, changesToMerge) => {
  // TODO: Just like a first version, we going to say that there conflicts when at most exist a conflict,
  // The idea is that the second version the implementation support only merge the no conflict changes

  return any(changeToMerge => {
    const baseChangesWithSameId = filter(propEq('lineId', prop('lineId', changeToMerge)))(baseChanges)
    return any(haveConflict(changeToMerge))(baseChangesWithSameId)
  })(changesToMerge)
}

const haveConflict = changeToMerge => baseChange => {
  // Just could be three type of conflict for the SAME line,
  // 1 - DELETE vs DELETE => Are equivalent, there no really conflicts here
  // 2 - DELETE vs EDIT   => Always there conflict here unless the edit change its just a moveIndex change
  // 3 - EDIT   vs EDIT   => Only if both changes touch the same part of the text

  if (isDeleteEdit(baseChange.type, changeToMerge.type)) return checkDeleteEdit(baseChange, changeToMerge)

  if (isEditEdit(baseChange.type, changeToMerge.type)) return checkEditEdit(baseChange, changeToMerge)

  return false
}

const checkDeleteEdit = (change1, change2) => {
  const { data: { editions } } = change1.type === CHANGE_TYPE.EDIT ? change1 : change2
  return !isEmpty(editions)
}

const checkEditEdit = ({ data: { editions: baseEditions } }, { data: { editions: toMergeEditions } }) => any(editionToMerge => {
  const editiosFromTheSamePhisicalLine = filter(propEq('physicalLineTypeEdited', prop('physicalLineTypeEdited', editionToMerge)))(baseEditions)
  return any(haveEditionConflict(editionToMerge))(editiosFromTheSamePhisicalLine)
})(toMergeEditions)

const haveEditionConflict = editionToMerge => baseEdition => {
  const { start, length } = baseEdition
  const { start: start1, length: length1 } = editionToMerge
  return rangesIntersect({ start, end: start + length }, { start: start1, end: start1 + length1 })
}

const rangesDisjoint = (range1, range2) => range1.end <= range2.start || range1.start >= range2.end

export const rangesIntersect = complement(rangesDisjoint)

// this function  return an array of array of object with this structure:
// {
//  baseChange,
//  conflictiveChange
// }
const conflict = (baseChange, conflictiveChange) => ({ baseChange, conflictiveChange })

const computeConflict = changes => change => pipe(
  filter(propEq('lineId', change.lineId)),
  map(otherChange => (haveConflict(change)(otherChange) ? conflict(change, otherChange) : EMPTY_ARRAY))
)(changes)

export const conflicts = (changes1, changes2) => pipe(
  map(computeConflict(changes2)),
  reject(isEmpty)
)(changes1)
