import { equals } from 'ramda'
import React, { useMemo } from 'react'

import { EMPTY_ARRAY } from 'utils/object'

import SearchToolBar from './SearchToolBar/SearchToolBar'
import SearchResults from './SearchResults/SearchResults'

import styles from './SearchViewPanel.scss'

const SearchViewPanel = ({ onSearchSelected, onDeleteSearch, currentSearch, searches = EMPTY_ARRAY, results, currentlyWalking, walkingSearch, onToggleWalking }) => {

  const walking = useMemo(() => !!currentSearch && equals(walkingSearch, currentSearch.search),
    [currentSearch, walkingSearch]
  )

  return (
    <div className={styles.SearchViewPanel}>
      <SearchToolBar

        onSearchSelected={onSearchSelected}
        currentSearch={currentSearch}
        searches={searches}
        results={results}
        onDeleteSearch={onDeleteSearch}

        walking={walking}
        walkingSearch={walkingSearch}
        onToggleWalking={onToggleWalking}
      />
      <SearchResults
        results={results}
        currentlyWalking={currentlyWalking}
      />
    </div>
  )
}

export default SearchViewPanel