import React from 'react'

import LinkButton from 'components/Commons/LinkButton'
import OrgAction from './OrgAction'

const OrgActionLink = ({ label, tooltip, onClick }) => (
  <OrgAction>
    <LinkButton tooltip={tooltip} onClick={onClick}>
      {label}
    </LinkButton>
  </OrgAction>
)

export default OrgActionLink