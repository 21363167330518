import React from 'react'
import { Icon, Tooltip } from 'antd'
import useBooleanState from '../../../hooks/react/useBooleanState'
import { ERROR_ICON, WARNING_ICON } from '../../SlateTextEditor/Components/MarkUp/IssueIcon'
import IconButton from 'components/Commons/IconButton'

import styles from '../TreeView.scss'

import { model } from 'beanie-engine-api-js'

const { types: { NodeExecutionIssue: { NodeExecutionIssueType } } } = model

/**
 *
 */
const NodeIssuesIcon = ({ issues }) => {
  const tooltipContent = issues.length > 1 ?
    <MultipleIssuesTooltipContent issues={issues} />
    : <Issue issue={issues[0]} />

  return (
    <Tooltip title={tooltipContent}>
      <Icon type="exclamation-circle" theme="filled" />
    </Tooltip>
  )
}

const MultipleIssuesTooltipContent = ({ issues }) => {
  return (
    <ol className={styles.MultipleIssuesTooltipContent}>
      {issues.map((issue, i) => (
        <li key={i}>
          <Issue issue={issue} />
        </li>
      ))}
    </ol>
  )
}

const iconForIssueType = issue => {
  switch (issue.type) {
    case NodeExecutionIssueType.ERROR: return ERROR_ICON()
    case NodeExecutionIssueType.WARN: return WARNING_ICON()
    default: {
      // eslint-disable-next-line no-console
      console.error('Found unknown engine issue type ', issue)
      return <Icon type="info-circle" theme="filled" style={{ color: 'blue' }} />
    }
  }
}

const Issue = ({ issue }) => {
  const [expanded, , , toggleCollapse] = useBooleanState(false)
  return (
    <div className={styles.Issue}>
      {iconForIssueType(issue)}
      {issue.message}
      {issue.data?.message &&
        (<>
          <IconButton className={styles.expandIssue} type={expanded ? 'left' : 'right'} onClick={toggleCollapse} />
          {expanded &&
            <div className={styles.extendedMessage}>{issue.data.message}</div>
          }
        </>)
      }
    </div>
  )
}

export default NodeIssuesIcon