import React, { useState, useEffect } from 'react'

import { prop } from 'ramda'
import { isNotNilOrEmpty } from 'ramda-adjunct'
import { LUA_UNSET_VALUE } from 'beanie-engine-api-js'

import { Table } from 'antd'

import { propertiesOf, EMPTY_OBJECT } from 'utils/object'

import ComplexObjectValue from 'components/PropertiesEditor/Value/ComplexObjectValue'
import PropertyValue from 'components/PropertiesEditor/Value/PropertyValue'

import PropertyLabel from './PropertyLabel'

import styles from './PropertiesEditor.scss'
import IconButton from 'components/IconButton/IconButton'
import { getRowClassName, renderEmbeddedContent } from './helpers'

import PropertiesConflictDialog from './PropertiesConflictDialog'
import CopyPasteButtons from './CopyPasteButton'


const { Column } = Table

const UserProperties = ({ editable, object, className, onNavigateRef, onPropertyChanged, lens = [], hiddenFields = [], nonEditableFields = [], customValueProvider, onCopyProperties }) => {
  const [selected, setSelected] = useState([])

  useEffect(() => setSelected([]), [object?.id])

  const dataSource = propertiesOf(object || EMPTY_OBJECT, hiddenFields, undefined, lens, isNotNilOrEmpty(lens))

  return (
    <div className={className}>
      <PropertiesConflictDialog object={object} lens={lens} />

      <CopyPasteButtons selected={selected} dataSource={dataSource} lens={lens} object={object} />

      <Table
        rowSelection={{
          selectedRowKeys: selected,
          onChange: selectedRowKeys => {
            setSelected(selectedRowKeys)
          },
        }}
        dataSource={dataSource}
        size="small"
        pagination={false}
        bordered={false}
        defaultExpandAllRows
        rowClassName={getRowClassName(lens)}
        expandedRowRender={renderEmbeddedContent(object, onNavigateRef, onPropertyChanged)}
        scroll={{ y: '50%' }}
        rowKey={prop('path')} >
        <Column
          title="Name"
          dataIndex="name"
          width="35%"
          render={(text, record) => (
            <PropertyLabel
              text={text}
              record={record}
              isEditable={editable && !nonEditableFields.includes(record.name)}
              onRemove={() => onPropertyChanged(record.path, LUA_UNSET_VALUE)}
              object={object} />
          )} />
        <Column
          title="Value"
          dataIndex="value"
          width="65%"
          render={(text, record) => (
            record.type !== 'complex' ? // where does 'complex' come from?
              <PropertyValue
                key={record.key}
                name={record.name}
                path={record.path}
                value={text}
                isEditable={editable}
                nonEditableFields={nonEditableFields}
                onNavigateRef={onNavigateRef}
                onPropertyChanged={onPropertyChanged}
                object={object}
                customValueProvider={customValueProvider} />
              : <span />
          )} />
      </Table>

      { editable && object && isNotNilOrEmpty(lens) && (
        <div className={styles.addRootPropertyContainer}>
          <div className={styles.addRootProperty}>
            <ComplexObjectValue
              object={object}
              path={lens?.join?.('.')}
              isEditable={editable}
              onPropertyChanged={onPropertyChanged} />
            <span className={styles.addRootPropertyTitle}>Add a new property</span>
          </div>
          {
            onCopyProperties &&
            <div className={styles.addRootProperty}>
              <IconButton key="copy-props" icon="plus" onClick={() => onCopyProperties(object)} />
              <span className={styles.addRootPropertyTitle}>Copy properties from another <i>{object.sys}</i></span>
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default UserProperties