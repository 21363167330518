
export const DO_WITH_STATE_TYPE = 'DO_WITH_STATE'

/**
 * Perform a mutation over the state.
 * What to do is in the form of a function. This is not recommended in the real app
 * 'cause functions are not serializable/data. But this is just for testing, where it allows us
 * to arbitrary mutate the state to mock something
 */
export const doWithState = fn => ({
  type: 'DO_WITH_STATE',
  fn,
})