import React, { useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { pathOr, propSatisfies, isNil, length } from 'ramda'
import { onlyUpdateForKeys, compose, branch, withState } from 'recompose'
import classNames from 'classnames'

import { nodeIdFor } from 'dom/dom'
import { EMPTY_ARRAY } from 'utils/object'
import { makeObjectFromIdSelector } from 'selectors/objects'
import useHover from 'hooks/useHover'
import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'
import withStaticAnalysis from 'hocs/withStaticAnalysis'
import { isDebuggable } from 'selectors/debuggingData'
import { pathFromProps } from 'selectors/props'

import withNodeSelection from 'hocs/node/withNodeSelection'
import { model } from 'beanie-engine-api-js'

import RowCell from './RowCell'
import ChainRowChild from './ChainRowChild'

import styles from '../TruthTable.scss'

const { types: { object: { Paths } } } = model

/**
 *
 */
// TODO: avoid forwarding all props ! makes it harder to maintain and spot chances to improve the component
const TruthTableRow = ({
  id,
  node, // the row

  index,
  relationship,
  isSelected,
  countOfRows,
  onClicked,

  truthTable,
  truthTableSelected,
  currentItemType,
  truthTableEnabled,
  truthTableIsHovered,
  truthTableExecuted,
  hasOtherwiseRow,

  onContextMenu,
  executionState,
  executionPath,
  trackExecutionEnabled,

  isDraggingRow,
  setIsDraggingRow,
  isDraggingArray,
  isOverRow,
  setIsOverRow,

  staticIssues,

  truthTableDebuggable,
  debuggableRow,

  ...props
}) => {
  const execution_count = executionState?.execution_count || 0

  const rowWasExecuted = execution_count > 0
  const cells = pathOr(EMPTY_ARRAY, Paths.truth_table_row.cells, node)
  const debuggableSelector = useMemo(() => isDebuggable(node.id), [node.id])

  const debuggable = useSelector(debuggableSelector)
  const [hoverRef, isHovered] = useHover()

  return (
    <tr
      className={classNames(styles.row)}
      id={nodeIdFor(node.id)}
      ref={hoverRef}
      onClick={onClicked}
      tabIndex="0"
    >
      {cells.map((cell, i) => (
        <RowCell
          key={`${index}-${i}`}

          cell={cell}
          node={node}
          truthTable={truthTable}

          rowIndex={index}
          columnIndex={i}

          isFirst={i === 0}
          isLast={i === length(cells) - 1}
          isLastRow={countOfRows - 1 === index}

          relationship={relationship}

          isDraggingRow={isDraggingRow}
          setIsDraggingRow={setIsDraggingRow}
          isOverRow={isOverRow}
          setIsOverRow={setIsOverRow}

          truthTableIsHovered={truthTableIsHovered}
          isDraggingColumn={isDraggingArray[i]}
          currentItemType={currentItemType}

          hasOtherwiseRow={hasOtherwiseRow}

          staticIssues={staticIssues}

          rowIsHovered={isHovered}
          rowWasExecuted={rowWasExecuted}
          rowWasNotExecuted={truthTableExecuted && !rowWasExecuted}
          rowSelected={isSelected}

          truthTableExecuted={truthTableExecuted}
          trackExecutionEnabled={trackExecutionEnabled}

          truthTableEnabled={truthTableEnabled}
          truthTableSelected={truthTableSelected}

          rowIsDebuggable={debuggable}
          debuggableRow={debuggableRow}
          truthTableDebuggable={truthTableDebuggable}

          {...props}
        />
      ))}
      <ChainRowChild id={id} node={node} />
    </tr>
  )
}

export default compose(
  withState('isOverRow', 'setIsOverRow', false),
  withState('isDraggingRow', 'setIsDraggingRow', false),
  branch(
    propSatisfies(isNil, 'node'),
    connect(() => {
      const nodeSelector = makeObjectFromIdSelector()
      return (state, props) => ({
        node: nodeSelector(state, props),
      })
    }),
  ),
  withNodeExecutionTracking(pathFromProps(['node', 'id'])),
  withStaticAnalysis(),
  withNodeSelection(),
  onlyUpdateForKeys([
    'node',
    'index',
    'countOfRows',
    'isSelected',
    'isDraggingRow',
    'currentItemType',
    'isOverRow',
    'setIsOverRow',
    'isCursorAtIt',
    'executionState',
    'staticIssues',
    'executionPath',
    'trackExecutionEnabled',
    'hasOtherwiseRow',
    'truthTableExecuted',
    'isDraggingArray',
    'onClicked',
    'truthTableIsHovered',
    'truthTableSelected',
    'truthTableDebuggable',
    'truthTableEnabled',
    'debuggableRow'
  ]),
)(TruthTableRow)
