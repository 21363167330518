import { identity, pipe, when, omit } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { currentRevision } from 'selectors/project'
import { createDeepEqualSelector } from 'selectors/reselect'

// we don't want to have the full revision on the scope
// otherwise a change to a single object re-renders all components having <Secure> :S
const currentRevisionWithoutObjects = pipe(currentRevision, when(isNotNil, omit(['objects'])))

// as omit always returns a new one, we need to check it for equals
// this is heavier than the default by identity equal, but it is better
// to compute it here and have the overhead in a single place instead of
// making all appps components recompute / re-render ! :)
const revisionForScope = createDeepEqualSelector(
  [currentRevisionWithoutObjects],
  identity
)

export default revisionForScope