import React from 'react'
import { Table, Icon } from 'antd'
import { remove, update, prop } from 'ramda'
import { compose, withHandlers, withProps } from 'recompose'
import { EMPTY_ARRAY } from 'utils/object'

import IconButton from 'components/Commons/IconButton'
import LinkButton from 'components/Commons/LinkButton'

import { TableEditableCell, EditableFormRow } from 'components/EditableTable/TableEditableCell'

import styles from './CustomTypeValues.scss'

const { Column } = Table

const CustomTypeValues = ({ values, addValue, removeValue, editValue }) => (
  <div>
    <Table
      rowKey={prop('value')}
      dataSource={values}
      pagination={false}
      components={{
        body: {
          row: EditableFormRow,
          cell: TableEditableCell,
        }
      }}
    >
      <Column
        title="Name"
        key="name"
        className={styles.nameColumn}
        dataIndex="value"
        width="20%"
        onCell={record => ({
          record,
          editable: true,
          dataIndex: 'value',
          title: 'Name',
          handleSave: editValue,
        })}
      />
      <Column
        title="Description"
        dataIndex="description"
        key="description"
        className={styles.descriptionColumn}
        width="30%"
        onCell={record => ({
          record,
          editable: true,
          dataIndex: 'description',
          title: 'Description',
          handleSave: editValue,
        })}
      />
      <Column
        title=""
        key="actions"
        className={styles.actionsColumn}
        render={(param, _, i) => (
          <div>
            <IconButton tooltip="Remove" type="delete" onClick={() => removeValue(i)} />
          </div>
        )}
      />
    </Table>
    <LinkButton onClick={addValue}><Icon type="plus" />Add</LinkButton>
  </div>
)

export default compose(
  withHandlers({
    addValue: ({ value = EMPTY_ARRAY, onChange }) => () => {
      onChange(value.concat({
        value: `Value_${value.length + 1}`,
      }))
    },
    removeValue: ({ value, onChange }) => i => {
      onChange(remove(i, 1, value))
    },
    editValue: ({ value, onChange }) => ({ index, ...param }) => {
      onChange(update(index, param, value))
    }
  }),
  withProps(({ value = EMPTY_ARRAY }) => ({
    values: value.map((param, index) => ({ ...param, index }))
  })),
)(CustomTypeValues)