
import React, { useCallback, useState } from 'react'
import { message } from 'antd'
import { Sys, lang } from 'beanie-engine-api-js'
import ProjectState from 'components/State/ProjectState'
import { useSelector } from 'react-redux'
import useTransaction from '../../hooks/beanie/useTransaction'
import { facts as factsSelector } from '../../selectors/objects/facts'
import IconButton from '../Commons/IconButton'
import { ModalConsumer } from '../Commons/Modal'
import StateEventsModal from '../StateEvents/StateEventsModal'
import AddFactButton from '../StateFacts/AddFactButton'
import { renameFactReferences } from 'model/languague/rules/ruleActions'
import { isNil } from 'ramda'

import StateFacts, { FactsViewType } from '../StateFacts/StateFacts'

import styles from './ProjectStatePanel.scss'

const { rule: { utils: { isEffect } } } = lang

/**
 * Panel with (playback) state related UI
 */
const ProjectStatePanel = () => {
  const facts = useSelector(factsSelector)

  const onAddFact = useTransaction(api => ({ name, expression }) => {
    api.obj.new(Sys.fact, undefined, { name, expr: expression })
  })

  const [factsViewType, setFactsViewType] = useState(FactsViewType.LIST)
  const toggleFactsView = useCallback(() => {
    setFactsViewType(current => {
      const values = Object.values(FactsViewType)
      const nextIndex = values.indexOf(current) + 1
      return values[nextIndex === values.length ? 0 : nextIndex]
    })
  }, [setFactsViewType])

  const onEditFact = useTransaction((api, _, getState) => (fact, { name, expression }) => {
    const factLua = api.obj.get(fact.id)
    const prevName = factLua.get_name()
    
    if (isNil(expression.rule)) {
      message.error('Error trying to set an empty expression')
      return
    }

    if (isEffect(expression.rule)) {
      message.error('Error trying to set an effect instead of an expression')
      return
    }

    if (prevName !== name) {
      factLua.set_name(name)
      renameFactReferences(api, getState, prevName, name)
    }
    factLua.set_expr(expression)
  })

  const onDeleteFact = useTransaction(api => fact => {
    api.obj.delete(fact.id)
  })

  return (
    <div className={styles.content}>
      <div className={styles.innerContent}>

        <StatePart
          title="Variables"
          toolbar={(
            <ModalConsumer>
              {({ showModal }) =>
                (
                  <IconButton
                    type="bars"
                    tooltip="See State Events Log"
                    onClick={() => showModal(StateEventsModal, {})}
                  />
                )
              }
            </ModalConsumer>
          )}
        >
          <ProjectState />
        </StatePart>

        <StatePart
          title="Facts"
          toolbar={(
            <div className={styles.Toolbar}>
              <AddFactButton onAddFact={onAddFact} />
              <IconButton title={`Switch to ${factsViewType === FactsViewType.LIST ? 'Tree' : 'List'} View`} type={factsViewType === FactsViewType.LIST ? 'bars' : 'fork'} onClick={toggleFactsView} />
            </div>
          )}
        >
          <StateFacts
            facts={facts}
            onEditFact={onEditFact}
            onDeleteFact={onDeleteFact}
            viewType={factsViewType}
          />
        </StatePart>

      </div>
    </div>
  )
}

const StatePart = ({ title, children, toolbar }) => (
  <div className={styles.statePart}>
    <div className={styles.title}>
      <span>{title}</span>
      {toolbar && <div className={styles.toolbar}>{toolbar}</div>}
    </div>
    {children}
  </div>
)

export default ProjectStatePanel