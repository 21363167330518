import React from 'react'
import { pure } from 'recompose'
import { Tooltip, Avatar as AntAvatar } from 'antd'

import noImage from 'images/no-image.png'

const Avatar = ({ title, imageFile, size = 'large', shape, noImageIcon = 'user' }) => {
  const content = 
    imageFile ?
      <AntAvatar src={imageFile} alt="avatar" size={size} shape={shape} /> :
      (<AntAvatar icon={noImageIcon} src={!noImageIcon && noImage} size={size} shape={shape}>
        {title ? title.substring(0, 1) : ''}
      </AntAvatar>)
  return (title ? <Tooltip title={title}>{content}</Tooltip> : content)
}

export default pure(Avatar)