import { propEq, always } from 'ramda'
import { isNotEmpty } from 'ramda-adjunct'

import { TREE_VIEW_DOM_ID, isFocusWithinTreeView } from 'dom/dom'

import { currentLane } from 'selectors/lanes'
import { lanesFromProject, makeRootIdsForLane } from 'selectors/objects'
import { isEditing } from 'selectors/ui'
import { revisionId } from 'selectors/project'

import { selectNode, clearSelectedNodes } from 'actions/nodes'
import { collapse, expand } from 'actions/view'
import { toggleCollapse } from './view'


const moveLane = delta => () => (dispatch, getState) => {
  const state = getState()
  const lane = currentLane(state)
  const lanes = lanesFromProject(state)

  const index = lanes.findIndex(propEq('name', lane.name))
  const newLane = lanes[index + delta]
  // if it is empty ?
  if (isNotEmpty(newLane.roots)) {
    dispatch(selectNode(newLane.roots[0], false))
  }
}

export const selectNextLane = moveLane(+1)
export const selectPreviousLane = moveLane(-1)

export const clickOnEmptySpace = () => (dispatch, getState) => {
  const focusWasInTree = isFocusWithinTreeView()

  if (!focusWasInTree) {
    document.querySelector(`#${TREE_VIEW_DOM_ID}`).focus()
  } else {
    if (isEditing(getState())) {
      // eslint-disable-next-line no-unused-expressions
      document.activeElement?.blur?.()
    }
    dispatch(clearSelectedNodes())
  }
}

const collapseExpandAllLaneRoots = fn => laneName => (dispatch, getState) => {
  const state = getState()
  const rootIds = makeRootIdsForLane(always(laneName))(state)

  if (rootIds.length > 0) {
    dispatch(fn(revisionId(state), ...rootIds))
  }
}
export const collapseAllRoots = collapseExpandAllLaneRoots(collapse)
export const expandAllRoots = collapseExpandAllLaneRoots(expand)

export const toggleCollapseLane = laneName => (dispatch, getState) =>
  dispatch(toggleCollapse(revisionId(getState()), laneName))

