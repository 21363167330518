import React, { useState } from 'react'

import styles from '../ProjectCard.scss'
import BranchSelector from './BranchSelector'
import CheckpointSelector from './CheckpointSelector'


const ProjectRevisionSelector = props => {
  const [branch, setBranch] = useState({ _id: props.revisionId, version: 'master', modifiable: true })
  const [checkpoint, setCheckpoint] = useState({ _id: props.revisionId, version: 'latest', modifiable: false, sourceBranch: branch })

  const commonProps = { branch, setBranch, checkpoint, setCheckpoint }

  return (
    <div className={styles.projectRevisions}>
      <BranchSelector
        {...props}
        {...commonProps}
      />
      <CheckpointSelector
        {...props}
        {...commonProps}
      />
    </div>
  )
}

export default ProjectRevisionSelector