import { Sys } from 'beanie-engine-api-js'
import { createEditorStateFromRawContent } from './draftjs-utils'
import choicesSerializer from './serializers/choicesSerializer'
import BlockType from './BlockType'

export const modelToText = (node, context) => {
  const content = createContent(node, context)
  // directorComment here is hardcoded, coupled with clip editor
  return createEditorStateFromRawContent(content.blocks.length === 0 ? emptyContent(BlockType.directorComment)() : content)
}

const serializers = {
  [Sys.choices]: choicesSerializer,
  [Sys.choices2]: choicesSerializer,
  // add more here to edit different types of nodes by sys
}
const getSerializer = node => (node && serializers[node.sys] ? serializers[node.sys] : emptyContent())

const createContent = (node, context) => getSerializer(node)(node, context)

const emptyContent = type => () => ({
  blocks: [emptyBlock(type)],
  entityMap: {}
})
const emptyBlock = (type = 'unstyled') => ({
  text: '',
  type,
  data: {}
})

export default modelToText