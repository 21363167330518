import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { objectById } from '../../selectors/objects'

/**
 * A Hook to resolve a beanie object having its id.
 * It decouples each component from how we do it, like using selectors, which kind of selectors,
 * if we memoize or not, etc.
 */
const useObject = id => {
  const objectSelector = useMemo(() => objectById(id), [id])
  return useSelector(objectSelector)
}

export default useObject