import React from 'react'
import { Switch } from 'antd'
import { connect } from 'react-redux'

import { makePreferenceSelector } from 'selectors/view'
import { togglePreference } from 'actions/view'

const TogglePreferenceSwitch = ({ currentValue, toggle, switchProps }) => (
  <Switch
    checked={currentValue}
    onChange={toggle}
    {...switchProps}
  />
)

export default connect(() => {
  const selector = makePreferenceSelector('preference')
  return (state, props) => ({
    currentValue: selector(state, props)
  })
},
(dispatch, { preference }) => ({
  toggle: () => dispatch(togglePreference(preference))
})
)(TogglePreferenceSwitch)