import React from 'react'
import { Tooltip, Icon } from 'antd'
import { model } from 'beanie-engine-api-js'

import connectState from 'hocs/connectState'
import { formatMarkup } from '../../utils/markupUtils'
import { markupColor } from './selectors'

import styles from './MarkUp.scss'

const { types: { markup: { markupColorToCSS } } } = model

const HiddenMarkup = ({ markup, node, color }) => (
  <Tooltip title={formatMarkup(node)}><span
    className={styles.hiddenIcon}
    contentEditable={false} >
    <Icon
      type="caret-down"
      className={styles.markUpIndicator}
      theme="filled"
      {...color && { style: { color: markupColorToCSS(color) } }}
    />
    {markup}
  </span>
  </Tooltip>
)

export default connectState({
  color: markupColor
})(HiddenMarkup)