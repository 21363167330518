import { filter, omit, propEq, splitAt, takeLast } from 'ramda'
import { createActions } from 'reduxsauce'
import { model, NodePlaybackStatus, Sys } from 'beanie-engine-api-js'
import { stopAll as stopAllAudio, pauseAll as pauseAllAudio, resumeAll as resumeAllAudio } from 'actions/audio'

import { PlaybackStatus } from 'model/constants'
import { objectsIndex } from 'selectors/apollo'
import { selectedObject } from 'selectors/objects'
import { activePreset } from 'selectors/presets'
import { events } from 'selectors/state'
import { Creators as presetsCreators } from 'actions/presets'
import { Creators as stateCreators } from 'actions/state'
import { loadSavegameFromPreset, clearState } from 'engine/actions/state'
import { currentlyPlayingNode, isPlaying, isStopped, isRestoreInspecting, playingNodes, playbackStatus, isPlayPauseResumeEnabled, isRewindEnabled, playbackIndex, playbackQueue } from 'selectors/playback'
import { isLastLine, getNextLineId } from 'selectors/clip'

const { types: { line: { getStoryboard } } } = model

export const NodeState = {
  choices: {
    enabledChoices: 'enabledChoices',
    hasExpired: 'hasExpired',
    selectedChoice: 'selectedChoice'
  },
  clip: {
    lineId: 'lineId'
  }
}

const stopSettingStatus = statusToSetFn => () => (dispatch, getState, { synchronizer }) => {
  if (isStopped(getState())) return // can't stop while stopped.
  const api = synchronizer.getEngine().getAPI()
  if (api) {
    api.getExecution().stop()
  }
  dispatch(stopAllAudio())
  dispatch(statusToSetFn())
}

export const { Types, Creators } = createActions({

  // lifecycle
  vmDisposed: null,
  vmLoading: ['revision'],
  vmLoaded: ['revision'],

  // playback

  // start & stop
  startPlaybackOnSelection: () => (dispatch, getState, args) =>
    Creators.startPlayback(selectedObject(getState()))(dispatch, getState, args),

  startNodePlayback: ['nodeId', 'sys', 'playbackId'],

  startPlayback: object => (dispatch, getState, { synchronizer }) => {
    if (!object) { return }
    synchronizer.getEngine().executeNodeWithId(object.id)
    dispatch(Creators.playbackStart())
  },
  playbackInitial: null,
  playbackStart: null,
  pausePlayback: () => (dispatch, getState, { synchronizer }) => {
    synchronizer.getEngine().getAPI().getExecution().pause()
    dispatch(Creators.playbackPause())
    dispatch(pauseAllAudio())
  },
  playbackPause: null,
  resumePlayback: () => (dispatch, getState, { synchronizer }) => {
    dispatch(Creators.restorePlaybackHistoryAndEventsIfNeeded())
    synchronizer.getEngine().getAPI().getExecution().resume()
    dispatch(Creators.playbackResume())
    dispatch(resumeAllAudio())
  },
  playbackResume: null,
  stopPlayback: stopSettingStatus(() => Creators.playbackStop()),
  /**
   * Used when unloading a revision.
   * We used to use stopPlayback but that wouldn't be correct because it will transition to the
   * "stop" status which is not the same as "initial" status.
   * For example facts are evaluated both in case it is playing but also while stopped.
   * So stopped still means there is state. While "initial" means no state at all.
   */
  resetPlayback: stopSettingStatus(() => Creators.playbackInitial()),
  playbackStop: null,
  playPauseResumeToggle: () => async (dispatch, getState) => {
    const status = playbackStatus(getState())
    const preset = activePreset(getState())

    if (!isPlayPauseResumeEnabled(getState())) return

    // get status
    switch (status) {
      case PlaybackStatus.PLAYING: return dispatch(Creators.pausePlayback())
      case PlaybackStatus.INITIAL:
      case PlaybackStatus.STOPPED:
        if (preset) await dispatch(loadSavegameFromPreset(preset))
        return dispatch(Creators.startPlaybackOnSelection())
      case PlaybackStatus.PAUSED: return dispatch(Creators.resumePlayback())
      default: break
    }
  },
  playbackRewind: null,
  rewindPlayback: () => async (dispatch, getState) => {
    const state = getState()
    const preset = activePreset(state)

    if (!isRewindEnabled(state)) return
    // stop playback
    if (!preset) {
      dispatch(Creators.stopPlayback())
      dispatch(stateCreators.clearState())
      dispatch(clearState())
      dispatch(Creators.playbackInitial())
      dispatch(Creators.playbackRewind())
    } else {
      // if preset => (re)load savegame
      await dispatch(presetsCreators.activatePreset(preset))
    }
  },

  // low-level actions (updating, avoid using directly from UI)
  updatePlaybackStatus: ['newPlaybackStatus'],
  reenterPlaybackNode: ['playbackId', 'status'],
  // propName can be dot separated path if you want
  recordNodeState: ['playbackId', 'propName', 'value'],

  // Higher-level updates (use these ones from UI)

  finishPresentingNode: playbackId => dispatch => {
    dispatch(Creators.updatePlaybackStatus({
      playbackId,
      status: NodePlaybackStatus.TERMINATED,
    }))
  },
  notPresentingNode: playbackId => dispatch => {
    dispatch(Creators.updatePlaybackStatus({
      playbackId,
      status: NodePlaybackStatus.NOT_PRESENTING,
    }))
  },

  selectChoice: (playbackId, choiceIndex) => dispatch => {
    dispatch(Creators.recordNodeState(playbackId, NodeState.choices.selectedChoice, choiceIndex))
    dispatch(Creators.updatePlaybackStatus({ status: NodePlaybackStatus.CHOICE_SELECTED(choiceIndex), playbackId }))
  },

  choicesExpired: playbackId => dispatch => {
    dispatch(Creators.recordNodeState(playbackId, NodeState.choices.hasExpired, true))
    dispatch(Creators.updatePlaybackStatus({ status: NodePlaybackStatus.CHOICE_TIMED_OUT, playbackId }))
  },

  selectChoiceWithShortcut: number => () => (dispatch, getState) => {
    if (!isPlaying(getState())) return false
    const choiceIndex = number - 1
    filter(propEq('sys', Sys.choices), playingNodes(getState()))
      .forEach(({ playbackId, state: { enabledChoices } }) => {
        if (choiceIndex < enabledChoices.length) {
          Creators.selectChoice(playbackId, number - 1)(dispatch)
        } // TODO: trigger a sound notification when selection out of choices (error sound)
      })
  },

  setCurrentStoryboard: ['playbackId', 'lineId', 'storyboard'],
  updateStoryboard: (playbackId, lineId) => (dispatch, getState) => {
    const aLine = objectsIndex(getState())[lineId]
    const storyboard = getStoryboard(aLine)
    if (storyboard) dispatch(Creators.setCurrentStoryboard(playbackId, lineId, storyboard))
  },
  // this is the playbackId the state for which the state is represented. Usually the last item in the piano roll,
  // if restored to inspect state in the past... then points to that point in the past
  setCurrentPlaybackId: ['playbackId'],
  setPlaybackIndex: ['playbackIndex'],
  setPlaybackQueue: ['playbackQueue'],

  // finish

  finishClipLine: (playbackId, bneClip, lineId) => dispatch => {
    if (!isLastLine(bneClip, lineId)) {
      const nextLineId = getNextLineId(bneClip, lineId)

      dispatch(Creators.recordNodeState(playbackId, NodeState.clip.lineId, nextLineId))
      dispatch(Creators.updateStoryboard(playbackId, nextLineId))
    } else {
      dispatch(Creators.finishClip(playbackId))
    }
  },
  finishClip: playbackId => Creators.updatePlaybackStatus({
    playbackId,
    status: NodePlaybackStatus.NOT_PRESENTING
  }),
  setRestoreInspecting: ['isInspecting'],
  restorePlaybackHistoryAndEventsIfNeeded: () => (dispatch, getState) => {
    const state = getState()
    const inspecting = isRestoreInspecting(state)

    // nothing to be done if we 're not inspecting
    if (!inspecting) return

    const playbackNode = currentlyPlayingNode(state)

    // get events len
    const atBeginNodeEventsLength = playbackNode?.state?.atBeginNode?.stateEventsLength
    const currentEvents = events(state)

    // if our position is the same as the current, nothing to do here
    if (atBeginNodeEventsLength === currentEvents.length) return

    // split the events and update events, queue and index
    const newEvents = takeLast(atBeginNodeEventsLength, currentEvents)
    dispatch(stateCreators.setEvents(newEvents))

    const queueLen = playbackNode.state.atBeginNode.playbackQueueLength
    const queue = playbackQueue(state)
    const [newQueue, removed] = splitAt(queueLen, queue)
    dispatch(Creators.setPlaybackQueue(newQueue))
    const playbackIdx = playbackIndex(state)
    dispatch(Creators.setPlaybackIndex(omit(removed, playbackIdx)))

  },

  // transactions
  setTxOptions: ['options']
})
