exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".page__2Y_IR{-ms-flex-positive:1;flex-grow:1;background-color:#001529;height:100%;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.page__2Y_IR .logo__2eyMs{padding-top:1rem;padding-bottom:2rem;width:20vw}.page__2Y_IR .panel__1VLZX{width:40vw;-ms-flex-item-align:center;align-self:center;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background-color:#f0f2f5;padding:1rem;border-radius:10px}.page__2Y_IR .panel__1VLZX button{width:100%}", ""]);

// exports
exports.locals = {
	"page": "page__2Y_IR",
	"logo": "logo__2eyMs",
	"panel": "panel__1VLZX"
};