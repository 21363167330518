import createMappingFor from 'utils/createMappingFor'
import { dispatchFunction } from '../../../utils/functions'
import { SearchType } from './searches'
import matchCollectorFactory from './collectors/match/matchCollectorFactory'
import findCollectorFactory from './collectors/find/findCollectorFactory'
import compositeCollectorFactory from './collectors/compositeCollectorFactory'

const mapping = createMappingFor(SearchType, {
  [SearchType.match]: matchCollectorFactory,
  [SearchType.find]: findCollectorFactory,
  [SearchType.composite]: compositeCollectorFactory,
}, search => search.type)

/**
 *
 * (Search, getState, pushResult) -> Collect
 *
 * Collect: (Object, Node) => void  // pushes things into pushResult
 */
const makeCollect = dispatchFunction(search => mapping.get(search))

export default makeCollect