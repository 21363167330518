import { always } from 'ramda'
import RevisionCache from '../../providers/RevisionCache/RevisionCache'
import LocalDB from './LocalDB'

/**
 * Knows how to instantiate the services registry.
 * This is currently done manually. Eventually we should get rid of this
 * and have a DI (dependency injection/container) engine that loads all services by introspecting
 * and calculating dependencies to do it in order and to inject them.
 * We can build it or use a lib.
 * For the moment this is all manual.
 *
 * It returns an object whose keys are the name of the services and instances are the services objects
 */
const createServices = (getStudioAPI, getEditorModule, synchronizer) => {
  const localDB = LocalDB.start()

  const services = {
    // order matters ! this should be computed based on the new services API & dependency injection
    // doing manually for the moment
    localDB: always(localDB),

    revisionsCache: always(RevisionCache.start(localDB)),

    studioAPI: getStudioAPI,
    editor: getEditorModule,
    synchronizer: always(synchronizer),


    // apolloClient should not be used and instead be used encapsulated within "studioAPI"
  }

  /**
   * We use a proxy so that we can write code like this:
   *
   *    services.studioAPI.something()
   *
   * Although accessing the studioAPI needs to execute a function. So under the hood this does
   *
   *    services.getStudioAPI().something()
   */
  return new Proxy({}, {

    get(target, name) {
      return services[name]?.()
    }

  })
}

export default createServices