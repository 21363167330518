import moment from 'moment'
import { applyChangesToObjects } from '../../../../model/ChangeSet'

/**
 * Given a cache Item it tries to updates the bneObjects with any changeSet
 * that might happened between the storage time and NOW.
 * It reaches for the backend to fetch all ChangeSets and apply them to the cached objects
 * Then updates the cache and returns the objects
 */
const getRevisionBNEObjectsFromCache = async (revisionId, services) => {
  const cacheItem = await services.revisionsCache.get(revisionId)
  if (!cacheItem) { return undefined }

  const { timestamp } = cacheItem
  const cachedTimeInDays = moment.utc().diff(moment.utc(timestamp), 'days')
  if (cachedTimeInDays >= 1) return undefined

  // get it in-sync with server changes
  const updatedItem = await syncCachedObjects(cacheItem, services)

  return updatedItem.objects
}

// exported just to test
export const syncCachedObjects = async (cacheItem, services) => {
  const { revisionId, timestamp, objects: cachedObjects } = cacheItem

  let objects = cachedObjects

  await services.studioAPI.mapRevisionChangeSets(revisionId, timestamp, changeSets => {
    objects = changeSets.reduce((acc, changeSet) => applyChangesToObjects(changeSet.changes)(acc), objects)
  })

  // store them
  return services.revisionsCache.put(revisionId, objects)
}

export default getRevisionBNEObjectsFromCache