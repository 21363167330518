import moment from 'moment'
import 'moment-duration-format'

const padZero = v => (v >= 10 ? `${v}` : `0${v}`)
export const formatDuration = d => `${padZero(d.hours())}:${padZero(d.minutes())}:${padZero(d.seconds())}`
export const utcIsoString = date => moment(date).utc().format()

const DATE_PATTERN = 'YYYY-DD-MM [at] HH:mm:SS'
export const formatTimestamp = d => moment(d).format(DATE_PATTERN)
export const humanizedDiff = (newer, older) => moment.duration(moment(newer).diff(older)).humanize()
export const formatRelative = d => moment(d).fromNow()

const DATE_SHORT_PATTERN = 'MMMM Do'
const DATE_SHORT_WITH_YEAR_PATTERN = 'MMMM Do YYYY'
const thisYear = moment().year()
export const formatShortTimestamp = d => {
  const m = moment(d)
  return m.format(m.year() !== thisYear ? DATE_SHORT_WITH_YEAR_PATTERN : DATE_SHORT_PATTERN)
}

export const formatSeconds = seconds => moment.duration(seconds, 'seconds').format('HH:mm:ss.SS', { trim: false })

export const getTimeAsString = (date = new Date()) => date.getTime().toString()