
import { always, cond, T } from 'ramda'

import { copyNodesToClipboard, copyIdToClipboard, copyLaneToClipboard } from 'actions/clipboard'
import { handleTreeNodePaste } from 'engine/actions/clipboard'
import Contexts from 'shortcuts/contexts'

import { isCurrentRevisionWritable } from 'security/project'
import pastOnLane from 'engine/actions/pasteOnLane'
import { canPasteOnLane, canPasteOnTreeView, contentTypeIsNodes, contentTypeIsLane } from 'selectors/clipboard'
import Groups from './Groups'

export const COPY_ID = {
  label: 'Copy Id',
  icon: 'anticon-copy',
  group: Groups.Editing,
  shortcut: { osx: 'command+shift+c', windows: 'ctrl+shift+c', linux: 'ctrl+shift+c' },
  preventDefault: true,
  action: copyIdToClipboard,
  context: Contexts.Tree,
}

export const COPY_NODES = {
  label: 'Copy',
  icon: 'anticon-copy',
  group: Groups.Editing,
  shortcut: { osx: 'command+c', windows: 'ctrl+c', linux: 'ctrl+c' },
  action: copyNodesToClipboard,
  context: Contexts.Tree,
}
export const PASTE_NODES = {
  label: 'Paste',
  icon: 'anticon-export',
  group: Groups.Editing,
  shortcut: { osx: 'command+v', windows: 'ctrl+v', linux: 'ctrl+v' },
  action: handleTreeNodePaste,
  context: Contexts.Tree,

  enabledSelector: canPasteOnTreeView,
  check: isCurrentRevisionWritable
}

export const COPY_LANE = {
  label: 'Copy Lane',
  icon: 'anticon-copy',
  group: Groups.Editing,
  preventDefault: true,
  context: Contexts.Tree,
  action: copyLaneToClipboard,
}
export const PASTE_ON_LANE = {
  label: (laneName, state) => cond([
    [contentTypeIsNodes, always('Paste as new chain')],
    [contentTypeIsLane, always('Paste Lane')],
    [T, always('Paste')]
  ])(state),
  icon: 'anticon-export',
  group: Groups.Editing,
  preventDefault: true,
  context: Contexts.Tree,
  action: pastOnLane,

  enabledSelector: canPasteOnLane,

  check: isCurrentRevisionWritable
}
