import { Document } from 'slate'
import { clipToPlainText } from '../../fragmentToText/LineToPlainText'
import { replace, pipe, assoc, when, over, lensProp, map, defaultTo, trim, pathEq, T, equals, any, anyPass, always, path, isNil, identity, allPass } from 'ramda'
import { clipToHtml } from '../../fragmentToText/LineToHtmlText'

import { getEventTransfer, setEventTransfer } from 'slate-react'
import { isDialogueLine } from '../../model/Line'
import { textToFragment } from '../../textToFragment/textToFragment'
import { NEW_LINE, isEmptyOrNull } from 'utils/string'
import { cloneNodesWithoutGhostChilds } from '../slateMocks/clone'
import Base64 from 'slate-base64-serializer'

import { EMPTY_OBJECT, EMPTY_ARRAY } from 'utils/object'
import { NODE_TYPES } from '../Constants'
import { lineIdFromNode } from '../changes/lineChange'
import { objectsIndex } from 'selectors/apollo'
import { completeDialogueLine } from './ClipCommands'
import { noop } from 'utils/functions'

export const MIME_TYPES = {
  textPlain: 'text/plain',
  textHtml: 'text/html',
  slateFragment: 'application/x-slate-fragment',
}

const TRANSFER_TYPES = {
  text: 'text',
  html: 'html',
  fragment: 'fragment'
}

const nodeHasOnlyAChild = pathEq(['nodes', 'size'], 1)

const cleanAndParseFragment = (editor, criteria) => fragment =>
  Base64.serializeNode(removeDataFromFragment(editor, criteria, fragment))


const isPartialCoveredBySelection = (line, selectedLineIds) => {
  const lineId = lineIdFromNode(line)
  return !any(equals(lineId), selectedLineIds)
}

const setEventFragment = {
  copy: {
    mutateFragment: editor => cleanAndParseFragment(editor, T),
    afterSet: noop,
  },
  cut: {
    mutateFragment: (editor, selectedLineIds) =>
      cleanAndParseFragment(editor, line => isPartialCoveredBySelection(line, selectedLineIds)),
    afterSet: editor => editor.deleteSelection()
  }
}

const removeDataFromFragment = (editor, shouldCleanLine, fragment) => {
  const mutateFunction = copiedLine => {
    const realLine = editor.lineById(lineIdFromNode(copiedLine))
    return realLine && shouldCleanLine(realLine) ?
      removeDataRecursively(copiedLine.toJS()) :
      copiedLine
  }

  return transformFragment(fragment, mutateFunction)
}

const exportToClipBoard = ({ mutateFragment, afterSet }) => (event, editor) => {
  const selectedLineIds = map(lineIdFromNode, editor.linesFullySelected()).toJS()
  const { fragment } = editor.value
  const { nodes: lines } = fragment

  event.preventDefault()
  event.stopPropagation()

  const editedFragment = mutateFragment(editor, selectedLineIds)(fragment)

  setEventTransfer(event, TRANSFER_TYPES.fragment, editedFragment)
  event.clipboardData.setData(MIME_TYPES.textHtml, clipToHtml(lines))
  event.clipboardData.setData(MIME_TYPES.textPlain, clipToPlainText(lines))

  afterSet(editor)
  event.selectedLineIds = selectedLineIds
  event.shouldRemoveAllData = false
  return false
}

export const onPasteAction = (event, editor) => (_, getState) => {
  const state = getState()
  const index = objectsIndex(state)

  const { type, fragment, text } = getEventTransfer(event)

  let lineToCheck = editor.currentLineBlock()

  const isFragmentType = type === TRANSFER_TYPES.fragment

  if (!isFragmentType && (!text || pipe(trim, isEmptyOrNull)(text))) return

  if (isFragmentType && !fragment) return

  const document = isFragmentType ?
    transformToPasteFragment(index, event, fragment) :
    textToFragment(replace(/\r/g, NEW_LINE, text))

  const allDocumentIsSelected = editor.selectionIsExpandedToAllContent()

  if (allDocumentIsSelected) {
    editor.clearContent()
    lineToCheck = editor.nodeByPath([0])
  }
  
  // CHECK IF YOU ARE PASTING PLAIN TEXT (just text and not whole lines)
  const pasteFunc = isPlainText(document, event) ? pasteAsPlainText : pasteComplexContent

  // PASTE ACTION
  pasteFunc(editor, document)

  editor.checkAndCleanNodeByKey(lineToCheck.key)

  event.shouldRemoveAllData = true
  return false
}

const isPlainText = (document, event) => {
  const line = document.nodes.first()
  return nodeHasOnlyAChild(document) && isPartialLine(line, event)
}

const isPartialLine = (line, { selectedLineIds = [] }) =>
  (isDialogueLine(line) && nodeHasOnlyAChild(line)) ||
  (!isDialogueLine(line) && isPartialCoveredBySelection(line, selectedLineIds))

const pasteAsPlainText = (editor, document) => {
  const partialLine = document.nodes.first()
  const { nodes } = (isDialogueLine(partialLine) ? partialLine.nodes.first() : partialLine)
  editor.insertNodes(cloneNodesWithoutGhostChilds(nodes))
}

const pasteAtTheMiddleOfDialogueLine = (editor, target, fragment) => {
  const { type } = editor.currentPhysicalLine()

  const nextIndex = editor.lineBlockPath().first() + 1

  if (type === NODE_TYPES.TEXT_PART) {
    editor.splitCurrentLine()
  }

  editor.insertFragmentByPath([], nextIndex, fragment)
  const line = editor.lineByIndex(nextIndex)
  editor.moveToEndOfNode(line)
}

const pasteComplexContent = (editor, document) => {
  const target = editor.currentLineBlock()
  const completePartialDialogueLine = when(
    allPass([isDialogueLine, nodeHasOnlyAChild]),
    pipe(line => line.toJS(), completeDialogueLine)
  )

  const fragment = transformFragment(document, completePartialDialogueLine)

  editor.delete() // Remove nodes selected

  if (editor.startIsAtStartOfNode(target) || editor.endIsAtEndOfNode(target)) {
    const indexToInsert = editor.lineBlockPath().first() + (editor.startIsAtStartOfNode(target) ? 0 : 1)
    editor.insertFragmentByPath([], indexToInsert, fragment)
  } else if (isDialogueLine(target)) {
    pasteAtTheMiddleOfDialogueLine(editor, target, fragment)
  } else {
    editor.insertFragment(fragment)
  }

  editor.removeDuplicatedReferences()
}

export default () => ({
  onCopy(event, editor) {
    return editor.historyTransaction(e =>
      exportToClipBoard(setEventFragment.copy)(event, e)
    )
  },
  onCut(event, editor) {
    return editor.historyTransaction(e =>
      exportToClipBoard(setEventFragment.cut)(event, e)
    )
  },
  onPaste(event, editor) {
    return editor.historyTransaction(e => {
      e.props.onPasteAction(event, editor)
    })
  }
})

const transformFragment = (fragment, mutateLine) => Document.create({
  ...fragment.toJS(),
  nodes: fragment.nodes.map(mutateLine)
})

const transformToPasteFragment = (index, event, fragment) => {
  const mutateFunction = line => {
    const jsonLine = line.toJS()
    const shouldRemoveData = anyPass([
      always(event.shouldRemoveAllData),
      shouldCleanDataFromLine(index)
    ])(jsonLine)

    return (shouldRemoveData ? removeDataRecursively : identity)(jsonLine)
  }

  return transformFragment(fragment, mutateFunction)
}

const removeDataRecursively = pipe(
  assoc('data', EMPTY_OBJECT),
  over(lensProp('nodes'), pipe(defaultTo(EMPTY_ARRAY), map(e => removeDataRecursively(e))))
)

const shouldCleanDataFromLine = index => object => {
  const id = path(['data', 'line_id'], object)
  if (!id) return false

  return isNil(index[id])
}
