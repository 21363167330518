import React from 'react'
import { Alert } from 'antd'
import OrganizationCard, { SkeletonCard } from './OrganizationCard'

import { compose, withHandlers } from 'recompose'
import { push as pushAction } from 'connected-react-router'
import { connect } from 'react-redux'
import { times, isEmpty } from 'ramda'
import { navigateToOrganization } from 'actions/routing'
import { user, token as tokenSelector } from 'selectors/auth'
import NoContent from 'components/Commons/NoContent'
import classNames from 'classnames'

import styles from './Organizations.scss'
import { EMPTY_ARRAY } from 'utils/object'

const Organizations = ({ refresh, organizations, currentUser, selectOrganization, selectSettings, token }) => (
  <div className={classNames(styles.organizationsContainer, { [styles.empty]: !organizations.loading && isEmpty(organizations.organizations.list) })} >
    {organizations.error && (
      <Alert
        message="Error"
        description="Oops, there was an error fetching the data !"
        type="error"
        showIcon
      >{JSON.stringify(organizations.error, null, 2)}</Alert>
    )}
    {organizations.loading && <SkeletonCards />}
    {!organizations.error && !organizations.loading && 
      <OrganizationCards
        organizations={organizations.organizations.list}
        refresh={refresh}
        currentUser={currentUser}
        selectOrganization={selectOrganization}
        selectSettings={selectSettings}
        token={token}
      />
    }
  </div>
)

const OrganizationCards = ({ organizations = EMPTY_ARRAY, refresh, token, selectSettings, selectOrganization, currentUser }) =>
  (isEmpty(organizations) ? <NoContent message="No organizations available" /> :
    organizations.map(org =>
      <OrganizationCard refresh={refresh} token={token} key={`Card_Org_${org._id}`} selectSettings={selectSettings} selectOrganization={selectOrganization} currentUser={currentUser} organization={org} />)
  )

const SkeletonCards = () => times(i => (<SkeletonCard key={`skeletonCard_${i}`} />), 6)

export default compose(
  connect(state => ({
    currentUser: user(state),
    token: tokenSelector(state)
  }),
  {
    push: pushAction,
    navigateToOrg: navigateToOrganization,
  }),
  withHandlers({
    selectOrganization: ({ navigateToOrg }) => organization => {
      navigateToOrg(organization.name)
    }
  })
)(Organizations)
