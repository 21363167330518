import React from 'react'
import { pipe, splitEvery, map, flatten } from 'ramda' 
import { Row, Col, Card } from 'antd'
import { properties, propertyValues } from 'utils/object'

import styles from './ShortcutsModal.scss'

const ShortCuts = ({ groupedCommands }) => (
  <div className={styles.shortcuts}>
    {
      pipe(
        properties,
        splitEvery(3),
        map(three => (
          <Row>
            {three.map(({ name, value }) => (
              <Col key={name} span={8}>
                <ShortCutGroup name={name} commands={value} />
              </Col>
            ))}
          </Row>
        )),
        flatten
      )(groupedCommands)
    }
  </div>
)

export default ShortCuts

const ShortCutGroup = ({ name, commands }) => (
  <Card size="small" key={name} title={name} style={{ width: 300 }} className={styles.groupCard}>
    {
      commands
        .filter(command => !!command.shortcut)
        .map(p => (
          <Row key={p.name}>
            <Col span={12}>{nameToString(p.name)}:</Col>
            <Col span={12}><span className={styles.shortcut}>{valueToString(p.shortcut)}</span></Col>
          </Row>
        ))
    }
  </Card>
)

const nameToString = name => name.replace(/_/g, ' ')
const valueToString = value => (
  // multiple values per platform
  value.osx ? propertyValues(value).join(' / ')
    // two options
    : Array.isArray(value) ? value.join(' | ') : value
)