import React from 'react'
import { compose, withPropsOnChange, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Tooltip } from 'antd'
import toMaterialStyle from 'material-color-hash'

import { isUnrealSession, isSessionFromUs } from 'model/constants/SessionClientType'
import { isSuperAdminCheck } from 'security/platform'
import { user as userSelector } from 'selectors/auth'
import secure from 'hocs/secure'
import { origin } from '../../model/constants/SessionClientType'
import UnrealIcon from '../Commons/Clients/unreal/UnrealIcon'

import UserAvatar from './UserAvatar'
import LinkButton from '../Commons/LinkButton'

import styles from './RevisionSessions.scss'

const RevisionSessionAvatar = ({ session, canManageSession, destroySession, showSessionCursor }) => {
  const style = toMaterialStyle(session._id)

  return (
    <div className={styles.RevisionSessionAvatar}>
      <UserAvatar
        key={session._id}
        user={session.user}
        style={{
          ...style,
          ...session.user.profileImage && { border: `3px solid ${style.backgroundColor}` }
        }}
        popoverContent={canManageSession && (
          <div className={styles.SessionDetailsPopover}>
            <div>
              <div className={styles.fieldName}>IP</div>
              <div className={styles.fieldValue}>{session.ip}</div>
            </div>
            <div>
              <div className={styles.fieldName}>Device</div>
              <div className={styles.fieldValue}><Tooltip title={session.userAgent}>{origin(session)}</Tooltip></div>
            </div>
            <div>
              <div className={styles.fieldName}>Client</div>
              <div className={styles.fieldValue}>{session.clientType}</div>
            </div>
          </div>
        )}
        popoverActions={canManageSession && (
          <React.Fragment>
            <LinkButton onClick={showSessionCursor}>Show Cursor</LinkButton>
            <LinkButton onClick={destroySession}>Disconnect</LinkButton>
          </React.Fragment>
        )}
      />
      {isUnrealSession(session) && <UnrealIcon />}
    </div>
  )
}

export default compose(
  connect(state => ({
    user: userSelector(state),
  })),
  secure('isSuperAdmin', isSuperAdminCheck),
  withPropsOnChange(
    ['session', 'user', 'isSuperAdmin'],
    ({ session, user, isSuperAdmin }) => ({
      canManageSession: isSuperAdmin || isSessionFromUs(user, session)
    })
  ),
  withHandlers({
    destroySession: ({ session, destroySession }) => () => {
      destroySession(session)
    },
    showSessionCursor: ({ session, showSessionCursor }) => () => {
      showSessionCursor(session)
    }
  })
)(RevisionSessionAvatar)