import { prop } from 'ramda'
import { branch, compose } from 'recompose'
import { isCurrentRevisionWritable } from '../../../security/project'
import secure from '../../secure'

/**
 * ifElse-like HOC to branch composition in case the current revision is modifiable
 * or not.
 */
const whenRevisionModifiable = (onModifiable, onNonModifiable) => compose(
  secure('hasWriteAccess', isCurrentRevisionWritable),
  branch(
    prop('hasWriteAccess'),
    onModifiable,
    onNonModifiable,
  )
)

export default whenRevisionModifiable