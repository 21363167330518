import { pipe, prop, test } from 'ramda'
import { GamepadButton, createGamepadDefinition } from '../api'

const icon = name => `/images/gamepads/button-xbox-${name}.jpeg`
const XBoxButton = {
  A: { name: 'A', icon: icon('a') },
  B: { name: 'B', icon: icon('b') },
  X: { name: 'X', icon: icon('x') },
  Y: { name: 'Y', icon: icon('y') },
}

//
// gamepad def
// 

const XBoxGamepad = createGamepadDefinition({
  type: 'XBOX',
  matches: pipe(prop('id'), test(/^Xbox/i)),
  buttons: XBoxButton,
  apiToButtonMapping: {
    [GamepadButton.A]: XBoxButton.A,
    [GamepadButton.B]: XBoxButton.B,
    [GamepadButton.X]: XBoxButton.X,
    [GamepadButton.Y]: XBoxButton.Y,
  },
  buttonToChoiceMapping: {
    [XBoxButton.A.name]: 0,
    [XBoxButton.B.name]: 1,
    [XBoxButton.X.name]: 2,
    [XBoxButton.Y.name]: 3,
  },
})

export default XBoxGamepad