exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__3_QQy{color:#add8e6;cursor:pointer}.iconAsLink__3_QQy:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__UAM6K{font-style:italic}.formFieldsVerticalGutter__381Jy{padding:.5rem}.formFieldsVerticalGutter__381Jy>*{margin:.5rem !important}.tableBackground__2p3jx .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2p3jx .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__3zowW .ant-notification-notice-close{display:none}.principal__t3PkL{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;margin-top:1vh}.principal__t3PkL>div{margin-left:1.8vw}.principal__t3PkL .actorName__1Mua-{font-size:1.3rem}.item__24ZZa{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:distribute;justify-content:space-around}.item__24ZZa .ant-select,.item__24ZZa .ant-slider{width:15rem}.playTagline__ZNkVj{color:#add8e6;cursor:pointer}.playTagline__ZNkVj:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.titleSection__2T6BX{padding-bottom:.5em}.titleSection__2T6BX .title__2tbXo{margin-top:12px}.titleSection__2T6BX .title__2tbXo span:first-of-type{margin-right:1rem}.titleSection__2T6BX .title__2tbXo span.buttonDisabled{opacity:.5;cursor:default}.titleSection__2T6BX .title__2tbXo span.buttonDisabled>.anticon{cursor:default}.titleSection__2T6BX .underline__v4ocN{display:block;height:1px;width:100%;position:relative;top:-0.6em;background:#e8e8e8}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__3_QQy",
	"labelFromRef": "labelFromRef__UAM6K",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__381Jy",
	"tableBackground": "tableBackground__2p3jx",
	"notificationNotClosable": "notificationNotClosable__3zowW",
	"principal": "principal__t3PkL",
	"actorName": "actorName__1Mua-",
	"item": "item__24ZZa",
	"playTagline": "playTagline__ZNkVj",
	"titleSection": "titleSection__2T6BX",
	"title": "title__2tbXo",
	"underline": "underline__v4ocN"
};