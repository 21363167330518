import React from 'react'
import pluralize from 'pluralize'

import SearchSelector from './SearchSelector'
import WalkingSwitch from './WalkingSwitch'
import SearchResultControls from './SearchResultControls'

import styles from './SearchToolBar.scss'

const SearchToolBar = ({ onSearchSelected, searches, currentSearch, results, walking, onToggleWalking, walkingSearch, onDeleteSearch }) => (
  <div className={styles.SearchToolBar}>
    <SearchSelector
      searches={searches}
      onSearchSelected={onSearchSelected}
      currentSearch={currentSearch}
      walkingSearch={walkingSearch}
      onDeleteSearch={onDeleteSearch}
    />
    <ResultCount results={results} className={styles.resultsCount} />
    <WalkingSwitch className={styles.walkingSwitch} walking={walking} onToggleWalking={onToggleWalking} />
    <SearchResultControls className={styles.searchResultsControls} />
  </div>
)

const ResultCount = ({ results, className }) => (
  <div className={className}>
    {results ? `${results.length} ${pluralize('result', results.length)}` : 'No Search'}
  </div>
)

export default SearchToolBar