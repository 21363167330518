exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".markUp__2-kEE{color:#6babc5;font-style:normal}.markUpName__7Px6L{color:#866fdb;text-transform:uppercase;font-weight:bold}.hiddenIcon__ZgRk6{position:absolute;top:-1rem;left:-0.25rem}.markUpIndicator__3jxxI{color:#6fa1ca;font-size:.5rem}.markUpContainer__1SLrA{position:relative}.markUpParameterName__1Jkqy{color:#6babc5;font-size:.8rem;margin-left:.5rem;font-family:\"Inconsolata\",monospace}.markUpParameterName__1Jkqy.error__2CWxi{color:#d54242 !important;text-decoration:underline dashed #d54242}.markUpParameterValue__MOjK-{color:#9caed1;font-size:.8rem;margin-left:.2rem;font-family:\"Inconsolata\",monospace}.markupParameterValueListElement__39KNo{margin-left:.2rem}.hasError__1LPbR>span{text-decoration:underline;-webkit-text-decoration-style:dashed;text-decoration-style:dashed}.hasError__1LPbR>span .anticon{padding-right:.3rem}", ""]);

// exports
exports.locals = {
	"markUp": "markUp__2-kEE",
	"markUpName": "markUpName__7Px6L",
	"hiddenIcon": "hiddenIcon__ZgRk6",
	"markUpIndicator": "markUpIndicator__3jxxI",
	"markUpContainer": "markUpContainer__1SLrA",
	"markUpParameterName": "markUpParameterName__1Jkqy",
	"error": "error__2CWxi",
	"markUpParameterValue": "markUpParameterValue__MOjK-",
	"markupParameterValueListElement": "markupParameterValueListElement__39KNo",
	"hasError": "hasError__1LPbR"
};