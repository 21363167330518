import React from 'react'

import IconButton from 'components/Commons/IconButton'

import styles from './NodeRuleEditor.scss'

/**
 *
 */
const ExpressionValidFeedback = ({ isValid: isStaticallyValid, extraErrorMessage }) => (
  <div className={styles.isValid} >
    {isStaticallyValid ?
      <IconButton type="check-circle" theme="twoTone" twoToneColor="#9eb696" disabled tooltip="The rule is valid!" /> :
      <IconButton type="close-circle" theme="twoTone" twoToneColor="#bd7373" disabled tooltip={`The rule is invalid!${extraErrorMessage ? ` ${extraErrorMessage}` : ''}`} />}
  </div>
)

export default ExpressionValidFeedback