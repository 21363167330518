
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MarkupDefinitionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MarkupDefinition"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"color"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"r"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"g"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"b"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"a"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"parameters"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"type"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"typeParams"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"required"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isVarArg"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":201}};
    doc.loc.source = {"body":"\nfragment MarkupDefinitionFragment on MarkupDefinition {\n  _id\n  name\n  description\n  color { r g b a }\n  parameters {\n    name\n    type\n    typeParams\n    required\n\n    # deprecated\n    isVarArg\n  }\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
