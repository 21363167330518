exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__33L9A{color:#add8e6;cursor:pointer}.iconAsLink__33L9A:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__3QyDA{font-style:italic}.formFieldsVerticalGutter__OMG7s{padding:.5rem}.formFieldsVerticalGutter__OMG7s>*{margin:.5rem !important}.tableBackground__6Hoif .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__6Hoif .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1ewEW .ant-notification-notice-close{display:none}.section__yyeA3{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:1em;-ms-flex-pack:distribute;justify-content:space-around}.section__yyeA3>div{height:90vh}.section__yyeA3>div:nth-child(1){-ms-flex:0 1 20vw;flex:0 1 20vw}.section__yyeA3>div:nth-child(2){-ms-flex:0 1 30vw;flex:0 1 30vw}.section__yyeA3>div:nth-child(3){-ms-flex:1 1 50vw;flex:1 1 50vw;margin-left:1rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__33L9A",
	"labelFromRef": "labelFromRef__3QyDA",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__OMG7s",
	"tableBackground": "tableBackground__6Hoif",
	"notificationNotClosable": "notificationNotClosable__1ewEW",
	"section": "section__yyeA3"
};