
/**
 * Receives a response from a request we made to another client.
 * We still never use this from the frontend
 */
const onReceiveResponseEvent = response => () => {
  // TODO: we still don't use this feature ! eventually we will
  /* eslint no-console: 0 */
  console.log('>>> GOT RESPONSE', response)
}

export default onReceiveResponseEvent