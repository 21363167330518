import React, { useCallback } from 'react'
import { DragDropContext } from 'react-dnd'
import HTMLBackend from 'react-dnd-html5-backend'

import { moveArrayIndexTo } from 'utils/object'
import ListItem from './SortableListItem'

const SortableList = ({ values, setValues, renderItem }) => {

  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      setValues(
        moveArrayIndexTo(
          dragIndex,
          hoverIndex,
          values
        )
      )
    },
    [values],
  )

  return (
    <div>
      {
        values.map((value, index) => (
          <ListItem
            key={value.id || value}
            renderItem={renderItem}
            value={value}
            index={index}
            moveItem={moveItem}
          />
        ))
      }
    </div>
  )

}

export default DragDropContext(HTMLBackend)(SortableList)
