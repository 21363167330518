import React from 'react'
import { Popconfirm } from 'antd'
import IconButton from './IconButton'

const DeleteButton = ({ onDelete, className, tooltip, disabled = false, tooltipPlacement }) => (
  <Popconfirm title="Are you sure ?" okText="Yes" cancelText="No" onConfirm={onDelete}>
    <IconButton
      className={className}
      type="delete"
      disabled={disabled}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
    />
  </Popconfirm>
)

export default DeleteButton