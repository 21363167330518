import React, { useMemo } from 'react'
import { Tooltip } from 'antd'
import { shallowEqual, useSelector } from 'react-redux'
import classNames from 'classnames'

import makeObjectLabelSelector from 'selectors/objects/makeObjectLabelSelector'
import { truncate } from '../../utils/string'

import styles from './NodeText.scss'

const NodeText = ({ node, truncateTextAt }) => {

  const selector = useMemo(() => makeObjectLabelSelector(node.id), [node.id])
  const label = useSelector(selector, shallowEqual)

  const text = useMemo(() => {
    if (!label) return '<empty>'
    if (truncateTextAt && label.value.length > truncateTextAt) {
      return <Tooltip title={label.value}>{truncate(label.value, truncateTextAt)}</Tooltip>
    }
    return label.value
  }, [label, truncateTextAt])

  return (
    <span className={classNames(styles[node.sys])}>
      {text}
    </span>
  )
}

export default NodeText