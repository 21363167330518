import React from 'react'
import { compose } from 'recompose'
import { Badge, Icon, Popover } from 'antd'
import classnames from 'classnames'
import winston from 'winston'

import withFetchMoreForPaging from 'hocs/withFetchMoreForPaging'
import subscribedToQuery from 'hocs/subscribedToQuery'
import withSubscription from 'hocs/withSubscription'

import { NOTIFICATIONS_QUERY_PAGE_SIZE, updateOnNewNotification as updateQuery } from 'components/NotificationsIndicator/updateNotificationsCache'
import NotificationsList from './NotificationsList'
import NotificationsListHeader from './NotificationsListHeader'

import notificationRefresh from 'api/subscriptions/notificationRefresh.graphql'
import subscription from 'api/subscriptions/notification.graphql'
import query from 'api/queries/notifications.graphql'

import styles from './NotificationsIndicator.scss'

const bellIcon = ({ error, loading }) => (
  <Icon type="bell" theme="filled" className={classnames(styles.bell, { [styles.bellError]: error, [styles.bellLoading]: loading })} />
)

const _NotificationsIndicator = ({ notifications, fetchMore }) => {
  const { loading, error } = notifications
  return (
    <div className={styles.NotificationIndicator}>
      { (loading || error) ?
        bellIcon(notifications)
        :
        (<Badge count={notifications?.notifications?.totalUnreadCount}>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={<NotificationsList notifications={notifications} fetchMore={fetchMore} />}
            title={<NotificationsListHeader unreadCount={notifications?.notifications?.totalUnreadCount} />}
            overlayClassName={styles.notificationsPopover}
            >
            { bellIcon(notifications) }
          </Popover>
        </Badge>)
      }
    </div>
  )
}

const queryName = 'notifications'
const variables = { page: 1, pageSize: NOTIFICATIONS_QUERY_PAGE_SIZE }
const NotificationsIndicator = compose(
  withFetchMoreForPaging({
    query,
    name: queryName,
    pageSize: NOTIFICATIONS_QUERY_PAGE_SIZE
  }),
  subscribedToQuery({
    queryProp: queryName,
    subscription,
    variables,
    updateQuery
  }),
  withSubscription({
    query: notificationRefresh,
    onSubscriptionEvent: ({ notifications }) => () => notifications.refetch(variables),
    onSubscriptionError: () => e => {
      winston.error(`ERROR: You will have a degraded beanie notifications service. Could not subscribe to notificationsRefresh (cause: ${e.message}).`)
    }
  })
)(_NotificationsIndicator)

export default NotificationsIndicator