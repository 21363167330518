import ttsJobCreateSingleFileMutation from 'api/mutations/ttsJobCreateSingleFile.graphql'
import { revisionId } from 'selectors/project'

/**
 *
 */
const syncLineTts = lineId => async (dispatch, getState, { getApolloClient }) => {
  await getApolloClient().mutate({
    mutation: ttsJobCreateSingleFileMutation,
    variables: {
      input: {
        lineId,
        revisionId: revisionId(getState())
      }
    }
  })
}

export default syncLineTts