import { propEq } from 'ramda'

import { isPriviledgedUser } from 'utils/authentication'
import { projectAdminAccess } from 'security/project'

import ObjectsSection from 'components/ObjectsSection/ObjectsSection'
import EditProjectSection from 'components/EditProjectSection/EditProjectSection'
import TextProjectSection from 'components/TextProjectSection/TextProjectSection'
import ActorSection from 'components/ActorSection/ActorSection'
import ProjectSettings from 'components/ProjectSettings/ProjectSettingsRouter'
import HistorySection from 'components/HistorySection/HistorySection'
import MarkupsSection from 'components/MarkupsSection/MarkupsSection'
import { createMenuItemsWithAlternateRoutes } from './menu-utils'
import { Jobs } from 'actions/tasks'
//

const menu = [
  {
    key: 'edit',
    icon: 'layout',
    label: 'Edit',
    goTo: 'edit',
    otherRoutes: [
      'edit/:objectId'
    ],
    component: EditProjectSection
  },
  {
    key: 'text',
    icon: 'file-text',
    label: 'Text',
    goTo: 'text',
    otherRoutes: [
      'text/:objectId'
    ],
    component: TextProjectSection
  },
  {
    key: 'actors',
    icon: 'team',
    label: 'Actors',
    goTo: 'actors',
    component: ActorSection
  },
  {
    key: 'markups',
    icon: 'tags',
    label: 'Markups',
    goTo: 'markups',
    component: MarkupsSection
  },
  {
    key: 'history',
    icon: 'clock-circle-o',
    label: 'History',
    goTo: 'history',
    // TODO: fix HistorySection performance problems and set back to always visible
    requires: isPriviledgedUser,
    component: HistorySection
  },
  {
    key: 'objects',
    icon: 'share-alt',
    label: 'Objects',
    goTo: 'objects',
    otherRoutes: [
      'objects/:objectId'
    ],
    requires: isPriviledgedUser,
    component: ObjectsSection
  },
  {
    key: 'settings',
    icon: 'setting',
    label: 'Settings',
    goTo: 'settings',
    requires: projectAdminAccess,
    component: ProjectSettings,
    requiredJob: Jobs.LOAD_PROJECT_BONES
  },
]

export default menu
export const allItems = menu

export const allItemsWithAlternateRoutes = createMenuItemsWithAlternateRoutes(allItems)

export const isCurrentlyAt = currentSection => propEq('goTo', currentSection)
