exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__5zLqM{width:200px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.content__5zLqM>div{padding-right:.5em}", ""]);

// exports
exports.locals = {
	"content": "content__5zLqM"
};