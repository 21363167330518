import { max, map, transduce } from 'ramda'
import moment from 'moment'
import { Sys } from 'beanie-engine-api-js'
import { arrayToObject } from 'utils/object'

// lanes

export const DEFAULT_LANE = 'Default'
export const NEW_LANE = 'Untitled'
const NEW_LANE_EXTRACTOR_REGEX = /Untitled(\((\d*)\))?/

export const extractUntitledId = name => {
  const match = NEW_LANE_EXTRACTOR_REGEX.exec(name)
  return match ? parseInt(match[2] || 0) : -1
}

export const untitledLaneIndexes = transduce(map(extractUntitledId), max, -1)

// nodes

const VisibleRootSys = arrayToObject([Sys.marker, Sys.folder])
export const isVisibleRootObject = o => !!VisibleRootSys[o.sys]

//
// API Types & Values
//

// TtsJob - keep in sync with backend
export const TtsJobStatus = {
  Creating: 'Creating',
  Created: 'Created',
  Started: 'Started',
  ErroredCreating: 'ErroredCreating',
  Finished: 'Finished',
  Cancelled: 'Cancelled'
}

// TtsJobItem - keep in sync with backend
export const TtsJobItemStatus = {
  Created: 'Created',
  Processing: 'Processing',
  Finished: 'Finished',
  Errored: 'Errored',
  Paused: 'Paused',
  Resumed: 'Resumed',
  Cancelled: 'Cancelled'
}

export const VideoJobStatus = {
  Creating: 'Creating',
  Created: 'Created',
  Started: 'Started',
  ErroredCreating: 'ErroredCreating',
  Errored: 'Errored',
  Finished: 'Finished',
  Cancelled: 'Cancelled'
}

export const VideoSources = {
  YouTube: 'youtube'
}

export const NotificationType = { // keep in sync with backend
  PDF_READY: 'pdf-ready',
  PDF_ERROR: 'pdf-error',
  TTS_FINISHED: 'tts-finished',
  COPY_PROJECT: 'copy-project'
}

// sync with backend
export const RevisionSessionState = {
  INIT: 'INIT',
  CONNECTED: 'CONNECTED',
  DESTROYED: 'DESTROYED'
}

export const ProjectEventTypes = {
  ProjectPreferenceSet: 'ProjectPreferenceSet',
  ProjectBranchCreated: 'ProjectBranchCreated',
  ProjectCheckpointCreated: 'ProjectCheckpointCreated',
  ProjectRevisionDeleted: 'ProjectRevisionDeleted',
}
export const ProjectRevisionEventTypes = {
  // lifecycle
  RevisionSessionCreatedEvent: 'RevisionSessionCreatedEvent',
  RevisionSessionDestroyedEvent: 'RevisionSessionDestroyedEvent',

  // requests
  RevisionSessionReceiveRequestEvent: 'RevisionSessionReceiveRequestEvent',
  RevisionSessionReceiveResponseEvent: 'RevisionSessionReceiveResponseEvent',

  // state changes
  RevisionSessionChangeSetEvent: 'RevisionSessionChangeSetEvent',
  RevisionSessionUpdateUserCursorEvent: 'RevisionSessionUpdateUserCursorEvent',

  // engine extensions
  EngineExtensionAdded: 'EngineExtensionAdded',
  EngineExtensionDeleted: 'EngineExtensionDeleted',
  EngineExtensionRenamed: 'EngineExtensionRenamed',

  // debugging data
  DebuggingPinsAddedEvent: 'DebuggingPinsAddedEvent',
  DebuggingPinsDeletedEvent: 'DebuggingPinsDeletedEvent',
  DebuggingPinsUpdatedEvent: 'DebuggingPinsUpdatedEvent',
  DebuggingPinsReplaceEvent: 'DebuggingPinsReplaceEvent',
  SetUserDebugSettingsEvent: 'SetUserDebugSettingsEvent',
  UserDebugScenariosUpdateEvent: 'UserDebugScenariosUpdateEvent',

  // user tasks
  UserTaskAddedEvent: 'UserTaskAddedEvent',
  UserTaskDeletedEvent: 'UserTaskDeletedEvent',
  UserTaskUpdateEvent: 'UserTaskUpdateEvent',
}

export const SortOrder = {
  ASCENDENT: 'ASCENDENT',
  DESCENDENT: 'DESCENDENT'
}

// NOTES

export const NoteTypes = {
  Performance: 'perf',
  Production: 'prod'
}

export const NoteDelimiters = {
  [NoteTypes.Performance]: { left: '[', right: ']' },
  [NoteTypes.Production]: { left: '<', right: '>' }
}

export const noteText = (type, text) => `${NoteDelimiters[type].left}${text}${NoteDelimiters[type].right}`

export const PlaybackStatus = {
  INITIAL: 'INITIAL', // playback state as if app have just started
  PLAYING: 'PLAYING', // or started
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED'
}

// TODO: move to engine
export const BNEStateVariables = {
  execution_count: 'execution_count'
}

// TODO: move to engine
export const BNEActionMode = {
  // MUST MATCH BNE LUA !!
  increment: 'increment',
  decrement: 'decrement',
  coinFlip: 'coin flip',
  assign: '=',
  toggle: 'toggle',
}

/**
 * Before this date ChangeSets had a lot of issues and we couldn't fix them all with migrations
 * So we put a limit to some features related to versioning. They can only be applied to changeSets that
 * were created after this specific date.
 * Updated to the date we migrated children -> child
 */
const CHANGESET_END_OF_DARK_AGE = moment.utc('2021-11-30T00:00:00.000Z')
export const isAfterChangeSetsDarkAge = changeSet => moment.utc(changeSet.timestamp).isAfter(CHANGESET_END_OF_DARK_AGE)

export const UserTaskTypes = {
  CircleCI: 'CircleCI',
  Lua: 'Lua'
}
export const UserTaskStatus = {
  Created: 'Created',
  InProgress: 'InProgress',
  Finished: 'Finished',
  Failed: 'Failed',
  Cancelling: 'Cancelling',
  Cancelled: 'Cancelled',
  RollingBack: 'RollingBack',
  RolledBack: 'RolledBack',
  TimedOut: 'TimedOut'
}
