import React from 'react'
import { Icon } from 'antd'
import DropDownMenuItem from '../DropDownMenuItem'

import LineMenuContent from './LineMenuContent'

/* eslint import/no-webpack-loader-syntax: 0 */
import HamburgerIcon from '-!svg-react-loader!./hamburger-icon.svg'

import styles from '../../../SlateTextEditor.scss'

const LineMenu = ({ lineId, audio, deleteLine: onDelete, readOnly, onStoryboardChanged, isAudioDirty }) => (
  <DropDownMenuItem
    className={styles.LineMenu}
    overlayClassName={styles.LineMenuOverlay}
    content={onItemSelected => (
      <LineMenuContent
        lineId={lineId}
        readOnly={readOnly}
        onItemSelected={onItemSelected}
        onStoryboardChanged={onStoryboardChanged}
        onDelete={onDelete}
        audio={audio}
        isAudioDirty={isAudioDirty}
      />
    )}
  >
    <Icon component={HamburgerIcon} />
  </DropDownMenuItem>
)

export default LineMenu