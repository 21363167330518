import { anyPass } from 'ramda'
import { notInProduction } from './services-enabling'

const underCypress = () => !!window.Cypress

const service = ({ reduxStore }) => {
  window.store = reduxStore
}
service.enabledOn = anyPass([notInProduction, underCypress])

export default service