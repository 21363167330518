import React from 'react'
import { pipe, propOr, propEq } from 'ramda'
import classNames from 'classnames'
import { compose, withProps } from 'recompose'
import { model } from 'beanie-engine-api-js'

import withModel from 'hocs/withModel'
import connectState from 'hocs/connectState'

import { markupColor } from './selectors'

import styles from './MarkUp.scss'

const { types: { markup: { markupColorToColorObject } } } = model

const lightColor = pipe(
  markupColorToColorObject,
  c => c.lighten(0.4)
    .desaturate(0.5)
    .fade(0.2),
  c => c.hsl().string()
)

const MarkUpParameterName = ({ attributes, children, color, errors }) => (<span
  {...attributes}
  className={classNames(styles.markUpParameterName, { [styles.error]: errors && errors.length > 0 })}
  {...color && { style: { color: lightColor(color) } }}
  >{children}</span>)

export default compose(
  withModel('markup'),
  connectState({
    color: markupColor
  }),
  withProps(({ node, markup }) => ({
    errors: propOr([], 'errors', markup).filter(propEq('arg', node.text))
  }))
)(MarkUpParameterName)