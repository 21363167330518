import React from 'react'

import { isComplexObject } from 'utils/object'
import ArrayValue from 'components/PropertiesEditor/Value/ArrayValue'
import StringValue from 'components/PropertiesEditor/Value/StringValue'
import ComplexObjectValue from 'components/PropertiesEditor/Value/ComplexObjectValue'

const PropertyValue = ({
  customValueProvider = () => {},
  showAcceptCancelIcons,
  nonEditableFields = [],
  onPropertyChanged,
  getIcon,
  iconDisposition,
  onNavigateRef,
  isEditable = true,
  object,
  name,
  value,
  path
}) => {

  const editable = isEditable && !nonEditableFields.includes(name)
  const onValueChanged = newValue => onPropertyChanged(path, newValue)

  let C = customValueProvider({ name, value, object, path })
  if (!C) {
    if (Array.isArray(value)) {
      C = ArrayValue
    } else if (isComplexObject(value)) {
      C = ComplexObjectValue
    } else {
      C = StringValue
    }
  }

  return (
    <div>
      <C
        object={object}
        path={path}
        value={value}
        onNavigateRef={onNavigateRef}
        isEditable={editable}
        onValueChanged={onValueChanged}
        onPropertyChanged={onPropertyChanged}
        customValueProvider={customValueProvider}
        iconDisposition={iconDisposition}
        getIcon={getIcon}
        showAcceptCancelIcons={showAcceptCancelIcons}
      />
    </div>
  )
}

export default PropertyValue