import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { values } from 'ramda'

import { dropTrailingSlash as dts } from 'utils/string'
import Sections from './OrganizationSections'
import Layout from 'components/Layout/Layout'
import TeamRouter from './Teams/TeamRouterContainer'
import routes from './routes'
import SectionSwitcher from './SectionSwitcher'

const OrganizationRouter = ({ computedMatch }) => (
  <Layout showProjectInformation={false} showLeftPanel={false}>
    <Switch>
      <Route path={routes.team} component={TeamRouter} />
      <Route path="/orgs/:organizationName/:sectionName" render={props => (<SectionSwitcher {...props} sections={values(Sections)} />)} />
      <Redirect to={`${dts(computedMatch.url)}/${Sections.Members.name}`} />
    </Switch>
  </Layout>
)

export default OrganizationRouter