import React from 'react'

import { Card, Icon } from 'antd'

import styles from './DangerZone.scss'

const DangerZone = ({ name = 'Danger Zone', children }) => {
  return (
    <div className={styles.dangerSection}>
      <Card title={<div><Icon type="exclamation-circle-o" />{name}</div>}>
        {children}
      </Card>
    </div>
  )
}

export const Item = ({ title, paragraph, children }) => (
  <div className={styles.dangerItem}>
    <h3>{title}</h3>
    <p>
      {paragraph}
    </p>
    {children}
  </div>
)

export default DangerZone