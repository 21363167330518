import React from 'react'
import { useDispatch } from 'react-redux'
import CreateModal from '../Commons/CreateModal'
import { removePinToNodes } from 'actions/project/debuggingData'
import { EMPTY_ARRAY } from 'utils/object'
import { length } from 'ramda'

const DeleteDebuggingPinsModal = ({ hide, pins = EMPTY_ARRAY, setSelectedPins }) => {
  const dispatch = useDispatch()

  const submit = () => {
    dispatch(removePinToNodes(pins))
    setSelectedPins(EMPTY_ARRAY)
  }

  return (
    <CreateModal
      title="Are you sure that you want to delete these pins ?"
      hide={hide}
      submit={submit}
      canSubmit
    >
      {() =>
        (
          <p>{`You are going to delete ${length(pins)} pins`}</p>
        )}
    </CreateModal>
  )
}

export default DeleteDebuggingPinsModal