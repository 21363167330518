import { NODE_TYPES } from '../Constants'
import { Key } from 'utils/keyboard'
import { customDirectorLine } from '../slateMocks/directorLine'
import { textNode } from '../slateMocks/textNode'
import { customDialogueLine } from '../slateMocks/dialogueLine'
import { customActorPart } from '../slateMocks/actorPart'
import { customTextPart } from '../slateMocks/actorTextPart'
import { cloneNodes } from '../slateMocks/clone'
import { isEmpty } from 'ramda'
import { findDescendentOfType } from '../nodeUtils'

export default () => ({
  onKeyDown (event, editor, next) {
    if (event.keyCode === Key.BACKSPACE) {
      const { isCollapsed, isExpanded } = editor.currentSelection()
      const lineBlockPath = editor.lineBlockPath()
      const currentLine = editor.blockByPath(lineBlockPath)
      const previousLineIndex = lineBlockPath.first() - 1
      const previousLine = editor.lineByIndex(previousLineIndex)
      const { key, text, type } = previousLine

      if (editor.selectionIsExpandedToAllContent()) {
        event.preventDefault()
        event.stopPropagation()

        editor.clearContent()

        return false
      }

      if (currentLine.type === NODE_TYPES.DIALOGUE_LINE) {
        const actortNode = editor.currentActorNode()
        if (editor.focusIsAtStartOfNode(actortNode) && isCollapsed) {
          event.preventDefault()
          event.stopPropagation()

          if (actortNode.type === NODE_TYPES.ACTOR_PART) {
            backSpaceInActorPart(editor, lineBlockPath, actortNode)
          } else {
            backSpaceInTextPart(editor, lineBlockPath)
          }
          return false
        }

        if (editor.endIsAtStartOfNode(actortNode) && isExpanded) {
          editor.setEnd({ ...editor.currentEnd(), path: editor.pathByKey(key), offset: text.length })

          if (type === NODE_TYPES.DIRECTOR_LINE && editor.selectionExpandToNode(previousLine)) {
            event.preventDefault()
            event.stopPropagation()

            editor.removeNode(previousLine)
            return false
          }
        }
      }

      if (currentLine.type === NODE_TYPES.DIRECTOR_LINE) {

        if (editor.focusIsAtStartOfNode(currentLine) &&
          previousLine && type === NODE_TYPES.DIALOGUE_LINE &&
          isCollapsed) {
          const textPart = findDescendentOfType(NODE_TYPES.TEXT_PART)(previousLine)

          if (isEmpty(textPart.text)) {
            event.preventDefault()
            event.stopPropagation()

            editor.replaceBlockByKey(textPart.key, customTextPart(cloneNodes(currentLine.nodes)))
            editor.removeNode(currentLine)
            editor.moveFocusToStartOfLine()
            editor.collapse()

            return false
          }
        }

        const nextLine = editor.lineByIndex(editor.lineBlockPath().first() + 1)

        if (nextLine && editor.focusIsAtStartOfNode(currentLine) &&
        isEmpty(currentLine.text) && editor.getLines().size > 1 &&
        (isCollapsed || editor.anchorIsAtStartOfNode(nextLine))) {
          event.preventDefault()
          event.stopPropagation()

          editor.removeNode(currentLine)
          return false
        }
      }
    }
    return next()
  },
})

const backSpaceInActorPart = (editor, lineBlockPath, actorNameNode) => {
  const actorTextNode = editor.blockByPath([...lineBlockPath, 1])
  const newBlock = customDirectorLine(nodesForDirectorLine(actorNameNode, actorTextNode))
  editor.replaceBlock(lineBlockPath, newBlock)
  const newDirLine = editor.nodeByPath(lineBlockPath)
  editor.moveToStartOfNode(newDirLine)
}

const backSpaceInTextPart = (editor, lineBlockPath) => {
  const actorName = editor.blockByPath([...lineBlockPath, 0])
  const { newActorNodes, restOfLineNodes } = splitFirstActorTextLine(editor, editor.blockByPath([...lineBlockPath, 1]))
  editor.replaceBlock(lineBlockPath, customDialogueLine(
    customActorPart([...cloneNodes(actorName.nodes), ...newActorNodes]),
    customTextPart(cloneNodes(restOfLineNodes)),
  ))

  editor.moveToPath([...lineBlockPath, 0])
  editor.moveTo(actorName.text.length)
}

const nodesForDirectorLine = (actorNameNode, actorTextNode) => 
  (actorTextNode.text 
    ? (actorNameNode.text ? [...actorNameNode.nodes, textNode('\n'), ...actorTextNode.nodes]
      : actorTextNode.nodes) : actorNameNode.nodes)

const splitFirstActorTextLine = (editor, textPartBlock) => {
  const firstWithSoftBreak = textPartBlock.getTexts().find(text => text.text.includes('\n'))
  return ((!firstWithSoftBreak) ? {
    newActorNodes: textPartBlock.nodes,
    restOfLineNodes: [textNode('')]
  } : splitSoftBreak(editor, textPartBlock, firstWithSoftBreak))
}

const splitSoftBreak = (editor, textPart, nodeToSplit) => {
  const [leftText, rightText] = nodeToSplit.splitText(nodeToSplit.text.indexOf('\n'))

  return {
    newActorNodes: [...editor.leftNodesOf(textPart, nodeToSplit), leftText],
    restOfLineNodes: [textNode(rightText.text.slice(1)), ...editor.rightNodesOf(textPart, nodeToSplit)]
  }
}