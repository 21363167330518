import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { createSessionsOnNode } from 'selectors/project'

import RevisionSessionCursor from './RevisionSessionCursor'

import styles from './TreeNodeCursors.scss'

const TreeNodeCursors = ({ sessionsOnNode }) => (
  <ul className={styles.cursors}>
    {sessionsOnNode
      .map(session =>
        <li key={session._id}><RevisionSessionCursor session={session} /></li>
      )
    }
  </ul>
)

export default compose(
  connect(() => {
    const sessionsCursors = createSessionsOnNode()
    return (state, props) => ({
      sessionsOnNode: sessionsCursors(state, props),
    })
  }),
)(TreeNodeCursors)