import initialState from 'reducers/view/init'
import { createSelector } from 'selectors/reselect'
import { path, pathOr, pipe, prop } from 'ramda'
import { elementFullName } from 'model/view'
import { makeMemoizeSelector } from './memoize'
import { selectedObject } from './objects'
import { isFunction } from 'util'
import { PreferenceScope } from '../preferences/Preferences'
import { revisionId } from './project'
import { EMPTY_OBJECT } from 'utils/object'
import { selectedProjectId } from './selections'

export const volume = pipe(pathOr(initialState.sound, ['view', 'sound']), prop('volume'))

export const panes = pathOr(initialState.panes, ['view', 'panes'])

export const PATH_COLLAPSED = ['view', 'graph', 'collapsed']
export const collapsedElements = pathOr(EMPTY_OBJECT, PATH_COLLAPSED)

export const isSelectedCollapsed = createSelector('isSelectedCollapsed',
  [selectedObject, revisionId, collapsedElements],
  (selected, revId, collapsed) =>
    selected && collapsed[elementFullName(revId, selected.id)]
)

// preferences
export const preference = ({ name, initial, scope }) => state => {
  const value = path(['view', 'preferences']
    .concat(scope === PreferenceScope.Project ? [selectedProjectId(state), name] : [name])
  )(state)
  return value === undefined ? (isFunction(initial) ? initial(state) : initial) : value
}
export const makePreferenceSelector = propName => (state, props) => preference(props[propName])(state)

export const makeSelectorForPreference = pref => makeMemoizeSelector({},
  ({ state }) => preference(pref)(state)
)
