import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, onlyUpdateForKeys } from 'recompose'
import { prop, propEq, pathOr } from 'ramda'
import { Table, Tooltip, Spin } from 'antd'
import classNames from 'classnames'

import { revisionId } from 'selectors/project'

import RelativeTime from 'components/Commons/RelativeTime'
import SimpleAvatar from 'components/Commons/SimpleAvatar/SimpleAvatar'
import ErrorPanel from 'components/EditProjectSection/ErrorPanel'
import { pageSize } from '../HistorySection/HistorySection'

import ChangeSetActions from './ChangeSetActions'

import styles from './HistoryPanel.scss'
import { isRevert, isReapply } from 'model/ChangeSet'
import ChangeSetChangesCells from '../ChangeSetPanel/ChangeSetChangesCells'

const { Column } = Table

const changeSetsPath = ['revisionWithId', 'changeSets']
const currentPageList = pathOr([], changeSetsPath.concat('list'))

export const _HistoryPanel = ({ changeSetData = { loading: true }, refetchQuery, onChangeSetSelected, changeSet: selectedChangeSet, onPageChanged, handleTableChange }) => (
  // TODO: move the fetching up to the whole section and then in case of error
  // show a full box spanning all the width (also the details)
  changeSetData.error ? <ErrorPanel error={changeSetData.error} />
    : (
      <div className={styles.historyTable}>
        <Spin tip="Fetching history ..." spinning={changeSetData.loading}>
          <Table
            dataSource={currentPageList(changeSetData)}
            size="small"
            rowKey={prop('timestamp')}
            onRow={changeSet => ({
              onClick: () => {
                onChangeSetSelected(changeSet)
              }
            })}
            rowClassName={record => classNames({
              [styles.selectedRow]: selectedChangeSet && record.timestamp === selectedChangeSet.timestamp,
              [styles.isRevert]: isRevert(record),
              [styles.isReapply]: isReapply(record)
            })}
            pagination={({
              pageSize,
              onChange: onPageChanged,
              total: changeSetData.loading ? 0 : pathOr(0, changeSetsPath.concat(['paging', 'nrOfItems']), changeSetData)
            })}
            onChange={handleTableChange}
            footer={() => (changeSetData.loading ? null : (
              <div><strong>{currentPageList(changeSetData)?.length}</strong> out of <strong>{pathOr(0, changeSetsPath.concat(['paging', 'nrOfItems']), changeSetData)}</strong> changeSets</div>
            ))}
          >
            <Column
              title="Timestamp"
              dataIndex="timestamp"
              key="timestamp"
              sorter
              className={styles.timestampColumn}
              render={(value) => (
                <Tooltip title={value}><RelativeTime date={value} /></Tooltip>
            )}
            />
            <Column
              dataIndex="author"
              key="author"
              sorter
              className={styles.authorColumn}
              render={author => (<SimpleAvatar user={author.user} />)}
            />
            <Column
              title="Description"
              key="description"
              dataIndex="description"
              sorter
              className={styles.descriptionColumn}
            />
            <Column
              key="cells"
              render={changeSet => (<ChangeSetChangesCells changeSet={changeSet} />)}
            />
            <Column
              title="Actions"
              key="actions"
              className={styles.actionsColumn}
              render={changeSet => (
                <ChangeSetActions changeSet={changeSet} refetch={refetchQuery} />
              )}
            />
          </Table>
        </Spin>
      </div>
    )
)

export default compose(
  connect(state => ({
    revision: revisionId(state)
  })),
  onlyUpdateForKeys(['changeSetData', 'changeSet']),
  lifecycle({
    componentDidUpdate() {
      const { revision, onChangeSetSelected, changeSet, changeSetData } = this.props
      if (revision && currentPageList(changeSetData).filter(propEq('timestamp', changeSet?.timestamp)).length === 0) onChangeSetSelected()
    }
  })
)(_HistoryPanel)