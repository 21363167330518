import React from 'react'
import { connect } from 'react-redux'
import { Table, Select, Input, Button } from 'antd'
import { always, isNil, map, mapObjIndexed, mergeDeepLeft, path, pipe, T, values } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { Sys } from 'beanie-engine-api-js'
import { EMPTY_STRING } from 'utils/string'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'

import { selectedObject as selectedObjectSelector } from 'selectors/objects'
import { objectLabelIndex } from 'selectors/labels'
import { objects as objectsSelector } from 'selectors/apollo'

import ShortId from 'components/Commons/ShortId'
import NodeText from '../Commons/NodeText'
import ObjectsListRowActions from './ObjectsListRowActions'

import styles from './ObjectsList.scss'
import { compose, lifecycle, withHandlers, withState } from 'recompose'

const { Option } = Select
const { Column } = Table

const showOptionsByKey = pipe(mapObjIndexed((_, key) => <Option key={key}>{key}</Option>), values)

const whenParent = conditionChecker => pipe(path(['data', parent]), conditionChecker)

const NodeType = {
  Root: whenParent(isNil),
  'Non-Root': whenParent(isNotNil),
  All: T
}

const RenderFilters = ({
  filter: { nodeType },
  onSysFilterChanged,
  onTextChanged,
  onSearch,
  onNodeTypeFilterChanged }) => (
    <div className={styles.filters}>
      <Select
        mode="multiple"
        placeholder="by Sys"
        onChange={onSysFilterChanged} >
        {pipe(map(sys => <Option key={sys}>{sys}</Option>), values)(Sys)}
      </Select>
      <Input
        placeholder="Text or Id"
        onPressEnter={onSearch}
        onChange={onTextChanged} />
      <Select
        placeholder="Node Type"
        onChange={onNodeTypeFilterChanged}
        value={nodeType} >
        {showOptionsByKey(NodeType)}
      </Select>
      <Button type="primary" onClick={onSearch}>Search</Button>
    </div>
)

const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    className: styles.idColumn,
    render: (_, record) => (<div><ShortId id={record.id} /></div>)
  },
  {
    title: 'Sys',
    dataIndex: 'sys',
    key: 'sys',
    className: styles.sysColumn
  },
  {
    title: 'Text',
    key: 'label',
    className: styles.textColumn,
    render: (record => <NodeText node={{ id: record.id }} />)
  },
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    className: styles.actionsColumn,
    render: (_, object) => (<ObjectsListRowActions object={object} />)
  },
]

const _ObjectsList = props => {
  const { objectsWithLabels: objects, selectedObject, onSelect } = props
  return (
    <div className={styles.container}>
      <RenderFilters {...props} />
      <Table
        dataSource={objects}
        rowKey="id"
        size="small"
        footer={() => <div>{objects ? objects.length : 0} objects</div>}
        scroll={{ y: '60vh' }}
        onRow={object => ({ onClick: () => onSelect(object.id) })}
        rowClassName={object => (selectedObject && selectedObject.id === object.id ? styles.selectedRow : '')} >
        {map(({ title, dataIndex, key, className, render }) => (<Column
          title={title}
          dataIndex={dataIndex}
          key={key}
          className={className}
          {...render ? { render } : EMPTY_OBJECT} />), columns)}
      </Table>
    </div>
  )
}

const ObjectsList = compose(
  withState('filter', 'setFilter', { nodeType: 'All' }),
  withHandlers({
    labelFor: ({ labels }) => object => path([object.id, 'name'], labels),
  }),
  withHandlers({
    shouldShow: ({ filter }) => object => {
      const sysCondition = (!filter.sys || filter.sys.length === 0 || filter.sys.includes(object.sys))
      const textCondition = (!filter.text ||
        ((object.label || EMPTY_STRING).indexOf(filter.text) >= 0)
        || ((object.id).indexOf(filter.text) >= 0)
      )
      const nodeTypeCondition = (!filter.nodeType || NodeType[filter.nodeType](object))
  
      return sysCondition && textCondition && nodeTypeCondition
    },
    objectsWithLabel: ({ objects, labelFor }) => () =>
      (objects || EMPTY_ARRAY).map(o => ({ ...o, label: labelFor(o) })),
    changeFilter: ({ filter, setFilter }) => f =>
      setFilter(mergeDeepLeft(f, filter)),
  }),
  withState('objectsWithLabels', 'setObjectsWithLabels', ({ objectsWithLabel }) => objectsWithLabel()),
  withHandlers({
    onSearch: ({ setObjectsWithLabels, objectsWithLabel, shouldShow }) =>
      always(setObjectsWithLabels(objectsWithLabel().filter(shouldShow))),
    onSysFilterChanged: ({ changeFilter }) => includedSys =>
      changeFilter({ sys: includedSys }),
    onTextChanged: ({ changeFilter }) => event =>
      changeFilter({ text: event.target.value }),
    onNodeTypeFilterChanged: ({ changeFilter }) => value =>
      changeFilter({ nodeType: value })
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { objects, onSearch } = this.props
      if (prevProps.objects !== objects) { onSearch() }
    }
  })
)(_ObjectsList)

export default compose(
  connect(state => ({
    labels: objectLabelIndex(state),
    selectedObject: selectedObjectSelector(state),
    objects: objectsSelector(state),
  }))
)(ObjectsList)