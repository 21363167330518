import createDescribe from '__tests__/utils/customDescribe'
import { createMockingContext } from '__tests__/sync/StateSynchronizer/createMockingContext'
import { BNEEngine, BNEHostInterface as i, BNEStore } from 'beanie-engine-api-js'
import { comparator } from 'ramda'
import { noop } from 'ramda-adjunct'
import createApolloClientWithMock from '../api/createApolloClientWithMock'
import fetchResource from './testFetchResource'
import readFile from './testReadFile'
import { createResolveblePromise } from 'utils/promises'

const describeBNEAction = createDescribe(body => {
  let engine
  let promiseTick
  const engineInitOpts = {
    journaling: true,
    serverApi: 'filesys',
    fetchResource,
    readFile,
    /* eslint no-unused-vars: 0 */
    globals: {
      BNEHostAquireNodeID: i.BNEHostAquireNodeID.autoNumeric(),
      ENV: 'test'
    },
    presenter: {
      onPollingTick: () => {
        promiseTick.resolve()
        promiseTick = createResolveblePromise()
      }
    }
  }

  beforeEach(async () => {
    promiseTick = createResolveblePromise()
    engine = new BNEEngine()
    engineInitOpts.globals.BNEHostAquireNodeID = i.BNEHostAquireNodeID.autoNumeric()
    await engine.init(engineInitOpts)
  }, 20000) // we need a long timeout to load the engine

  const untilNextTick = () => promiseTick.promise

  afterEach(() => {
    // promiseTick.resolve('AFTER_EACH')
    engine.dispose()
  })

  const getEngine = () => engine
  const getAPI = () => getEngine().getAPI()

  // util / generic doTest
  const doTest = ({ setup = noop, perform, expected, expectedToMatch, alsoExpecting = noop, expectWithResult = noop }) => {
    const api = getAPI()
    setup(api)
    const r = perform(api)

    const sort = array => array.sort(comparator((a, b) => a.id < b.id))
    const all = api.getAllObjectsAsJSON()
    sort(all)

    const e = expect(all)

    const check = (expectation, method) => {
      const toCheck = typeof expectation === 'function' ? expectation(r) : expectation
      sort(toCheck)
      // toMatchObject/toEqual
      e[method](toCheck)
    }

    if (expectedToMatch) {
      check(expectedToMatch, 'toMatchObject')
    }
    if (expected) {
      check(expected, 'toEqual')
    }
    expectWithResult(r)
    alsoExpecting(api)
  }

  // interpret body
  body({
    engineInitOpts,
    untilNextTick,
    getEngine,
    getAPI,

    doTest,

    mockTimestamp: time => {
      const api = getAPI()
      if (api) {
        api.evaluateScript(`
          bne.time.now = function() return "${time}" end
        `)
      }
    },

    // RENAME: to createContextWithObjects
    createContext: objects => createMockingContext({
      initialObjects: objects,
      bneStore: new BNEStore(getEngine()),
    }),
    // also loads them into beanie, you don't need to load them manually
    createContextNew: objects => createMockingContext({
      loadIntoBeanie: true, //
      initialObjects: objects,
      bneStore: new BNEStore(getEngine()),
    }),
    // give your own full state
    createContextWithState: baseState => createMockingContext({
      baseState,
      bneStore: new BNEStore(getEngine()),
    }),

    /**
     * This should become the preferred method since allows new params later
     */
    createIntegrationContext: ({ objects = [], backendMock, baseState, bneStore, loadIntoBeanie }) => {
      const r = createMockingContext({
        baseState,
        loadIntoBeanie: loadIntoBeanie !== undefined ? loadIntoBeanie : true,
        bneStore: bneStore || new BNEStore(getEngine()),
        ...objects && { initialObjects: objects },
        apolloClientFactory: createApolloClientWithMock(backendMock),
        storeEnhancer: recordActionsStoreEnhancer
      })
      return {
        ...r,
        backendMock
      }
    },

  })
})

export default describeBNEAction

/**
 * Enhances the store to record every action that was dispatched (only simple objects, not fns).
 * Also monkey patches the store adding:
 *  - getActions(): returns the list of actions recorded so far
 *  - clearActions(): clears the history
 */
export const recordActionsStoreEnhancer = createStore => (reducer, preloadedState) => {
  const store = createStore(reducer, preloadedState)

  let actions = []

  return {
    ...store,
    dispatch: action => {
      if (action.type) {
        actions.push(action)
      }
      return store.dispatch(action)
    },

    // new methods
    getActions: () => actions,
    clearActions: () => { actions = [] }
  }
}
