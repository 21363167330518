import { createActions } from 'reduxsauce'
import { message } from 'antd'
import uuid from 'uuid/v4'
import { apiFetch, fetchOptions, UNAUTHORIZED } from 'actions/utils'
import { isEditable } from 'beanie-engine-api-js'

import { token as tokenSelector } from 'selectors/auth'
import { isEditing } from 'selectors/ui'
import { selectedObject } from 'selectors/objects'

import FileSaver from 'file-saver'
import { polling } from 'utils/async'
import { extractFileNameFromContentDisposition, extractFileNameFromPath, getBlob } from 'utils/http'
import { editorIdFor } from '../dom/dom'
import { CHAIN, TEXT } from '../preferences/PaneIds'
import { minimizeSecondaryPane, setCurrentTab } from './view'
import { panes as panesSelector } from 'selectors/view'
import { externalFetch } from './utils'

const FileSaver_DONE = 2

const NO_ARGS = null

export const { Types, Creators } = createActions({
  editStart: ['id', 'path'],
  editSelectedStart: () => (dispatch, getState) => {
    const selectedNode = selectedObject(getState())
    if (selectedNode && isEditable(selectedNode)) {
      return dispatch(Creators.editStart(selectedNode.id, ['data', 'name']))
    }
  },
  _editEnd: NO_ARGS,
  editEnd: () => (dispatch, getState) => {
    if (isEditing(getState())) {
      return dispatch(Creators._editEnd())
    }
  },
  confirmAction: ['actionFn', 'message'],
  actionConfirmed: ['id'],
  startBlockingTask: ['task'],
  endBlockingTask: NO_ARGS,

  setDebug: ['onOff'],

  notifyError: text => () => {
    message.error(text, 2)
  },

  // downloads
  downloadFile: ({ path, query, description, fileName, externalUrl }) => async (dispatch, getState) => {
    const id = uuid()
    const hide = message.loading(description, 0)

    const _path = path || externalUrl

    dispatch(Creators.downloadStart(id, _path, query, description))

    // do fetch
    const response = externalUrl ? await externalFetch(externalUrl) : await apiFetch(_path, fetchOptions('GET', undefined, tokenSelector(getState())))
    dispatch(Creators.innerDownloadCompleted(id))

    // handle response
    if (!response.ok) {
      if (response.status === 401) {
        dispatch({ type: UNAUTHORIZED })
      } else {
        hide()
        /* eslint no-console: 0 */
        message.error(`Oops there was an error trying to download the file! (response code ${response.status})`)
        throw new Error(`There was an error trying to download the file. Response code: ${response.status}`)
      }
    } else {
      const _fileName = fileName || extractFileNameFromContentDisposition(response) || extractFileNameFromPath(_path)

      try {
        await dispatch(Creators.saveFile(_fileName, await getBlob(response)))
        hide()
        message.success(`File ${_fileName} downloaded successfully !`)
      } catch (err) {
        hide()
        message.error(err.message)
      }
    }

  },
  downloadStart: ['id', 'path', 'query', 'description'],
  innerDownloadCompleted: ['id'],

  saveFile: (fileName, blob) => async () => {
    const save = FileSaver.saveAs(blob, fileName)

    return new Promise(
      (resolve, reject) => {
        polling({
          every: 1000,
          until: () => save.readyState === FileSaver_DONE,
          onSuccess: () => {
            resolve()
          },
          onTimedOut: () => {
            reject(new Error('There was an error or timeout waiting to save the file'))
          }
        })
      }
    )
  },

  // gamepad
  gamepadConnected: ['gamepad'],
  gamepadDisconnected: ['gamepad'],

  // global search
  openGlobalSearch: () => dispatch => {
    dispatch(Creators.setGlobalSearchVisible(true))
  },
  closeGlobalSearch: () => dispatch => {
    dispatch(Creators.setGlobalSearchVisible(false))
  },
  setGlobalSearchVisible: ['visible'],

  setPropertiesConflictDialog: ['targets', 'clipboard'],

  clearPropertiesConflictDialog: NO_ARGS,

  /**
   * Opens the Text Editor component to edit the current clip/node
   * If it is minimized then it opens it.
   * If it has a different tab than Text or TextChain then it changes it to Text.
   */
  focusOnTextEditor: node => (dispatch, getState) => {
    // if right panel is minimized/closed then open it
    dispatch(minimizeSecondaryPane('vertical', false))
    // make it visible if not
    const currentId = panesSelector(getState()).vertical.currentTab
    if (currentId !== TEXT && currentId !== CHAIN) {
      // would like to use `Disposition.vertical` but introduces a circular dep
      dispatch(setCurrentTab('vertical', TEXT))
    }
    // focus
    const dom = document.querySelector(`#${editorIdFor(node.get_id())} > div`)
    if (dom) dom.focus()
  }

})

export const blocking = (fn, description) => (...args) => async dispatch => {
  const task = { description }
  dispatch(Creators.startBlockingTask(task))

  const r = await dispatch(fn(...args))

  dispatch(Creators.endBlockingTask(task))
  return r
}

