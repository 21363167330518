import { NODE_TYPES } from '../../Constants'
import { EventKey } from 'utils/keyboard'
import { isEmpty } from 'utils/object'

const PAIR_OF_DOUBLE_QUOTES = `${EventKey.DOUBLE_QUOTES}${EventKey.DOUBLE_QUOTES}`

const NODE_TYPES_ALLOWED = [NODE_TYPES.MARK_UP_PARAMETER_VALUE, NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT]

export const isRichText = text => text.match(/^".*"$/)

export default () => ({
  onKeyDown (event, editor, next) {
    const { text, type } = editor.currentNode()
    if (event.key === EventKey.DOUBLE_QUOTES) {
      if (NODE_TYPES_ALLOWED.includes(type) && isEmpty(text)) {
        event.preventDefault()
        event.stopPropagation()
        editor.insertText(PAIR_OF_DOUBLE_QUOTES)
        editor.moveToStartOfText()
        editor.moveTo(1)
        return false
      }
    }
    return next()
  }
})