// import TeamSummary from './TeamSummary/TeamSummary'
import TeamMembers from './TeamMembers/TeamMembersContainer'
import TeamProjects from './TeamProjects/TeamProjectsContainer'
// import TeamSubTeams from './TeamSubTeams/TeamSubTeams'
// import TeamSettings from './TeamSettings/TeamSettings'

const s = (name, icon, label, comp) => ({ name, icon, label, comp })

const Sections = {
  // Summary: s('summary', 'profile', 'Summary', TeamSummary),
  Members: s('members', 'user', 'Members', TeamMembers),
  // SubTeams: s('teams', 'team', 'Sub-Teams', TeamSubTeams),
  Projects: s('projects', 'book', 'Projects', TeamProjects),
  // Settings: s('settings', 'setting', 'Settings', TeamSettings)
}

export default Sections