import { OBJECT_TYPES, NODE_TYPES } from '../Constants'
import { textNode } from './textNode'

export const inlineNode = (nodes, type, data = {}) => ({
  data,
  nodes,
  object: OBJECT_TYPES.inline,
  type,
})

export const performanceNote = perfNoteText => inlineNode([textNode(perfNoteText)], NODE_TYPES.PERFORMANCE_NOTE)
export const productionNote = prodNoteText => inlineNode([textNode(prodNoteText)], NODE_TYPES.PRODUCTION_NOTE)