import { useContext, useEffect, useState } from 'react'
import { ExtensionsContext } from 'providers/RevisionExtensions'

/**
 * Allows components to consume "Extension"s for a specific "Extension-Point".
 * Returns a reactive array of Extension instances registered for the given point.
 */
const useExtensionPoint = point => {
  const [extensions, setExtensions] = useState([])

  const extensionsContext = useContext(ExtensionsContext)

  useEffect(() => {
    const observer = {
      update: exs => { setExtensions(exs) }
    }

    const observablePoint = extensionsContext.getExtensionPointObserver(point)
    observablePoint.addObserver(observer)
    setExtensions(observablePoint.getExtensions())

    return () => {
      observablePoint.removeObserver(observer)
    }
  }, [])

  return extensions
}

export default useExtensionPoint