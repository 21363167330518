import React, { useCallback, useState } from 'react'

import { propEq } from 'ramda'
import { isCheckpoint } from 'utils/project'
import { Spin, Icon, Menu, Dropdown } from 'antd'
import styles from '../ProjectCard.scss'
import withVersions from './withVersions'


const CheckpointsMenu = ({ loading, versions, checkpoint, doSelect, branch: selectedBranch }) => {
  const onSelect = useCallback(({ key }) => {
    doSelect(versions.find(propEq('_id', key)))
  }, [versions])

  const branchCheckpoints = versions
    .filter(isCheckpoint)
    .filter(({ sourceBranch }) => sourceBranch._id === selectedBranch._id)

  return (
    <Spin spinning={loading}>
      <Menu
        selectedKeys={[checkpoint._id]}
        mode="vertical"
        className={styles.projectRevisionsMenu}
        onSelect={onSelect}
      >
        <Menu.Item key={selectedBranch._id}>latest</Menu.Item>
        {branchCheckpoints.length > 0 && <Menu.Divider />}
        {
          branchCheckpoints.map(({ _id, version: versionName }) => (
            <Menu.Item key={_id}>{versionName}</Menu.Item>
          ))
        }
      </Menu>
    </Spin>
  )
}

const CheckpointsMenuWithVersions = withVersions(CheckpointsMenu)

const CheckpointSelector = ({ project, setRevisionId, branch, checkpoint, setCheckpoint }) => {
  const [visible, setVisible] = useState(false)

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={setVisible}
      overlay={
        <CheckpointsMenuWithVersions
          project={project}
          branch={branch}
          checkpoint={checkpoint}
          doSelect={
            selectedCheckpoint => {
              setRevisionId(selectedCheckpoint._id)
              setCheckpoint(selectedCheckpoint)

              setVisible(false)
            }
          }
        />
      }
    >
      <span className={styles.revisionSelector}><Icon type="tag" /> {checkpoint.version}</span>
    </Dropdown>
  )
}

export default CheckpointSelector