import React, { useMemo } from 'react'
import { isNil } from 'ramda'
import { lang } from 'beanie-engine-api-js'

import useEvaluationContext from '../../hooks/beanie/language/useEvaluationContext'

import styles from '../StateFacts/StateFacts.scss'
import ExpressionValidFeedback from '../TreeView/TreeChain/NodeWithEnabledRule/ExpressionValidFeedback'

const { rule: { error: { isError, createErrorMessage }, interpreter: { evaluate } } } = lang

/**
 * A component that knows how to evaluate a fact expression into a value
 */
const FactValue = ({ expression }) => {
  const context = useEvaluationContext(true)

  const value = useMemo(() => {
    if (isNil(expression)) { return '' }
    return evaluate(context, expression)
  }, [context, expression])

  return isError(value) ?
    <div className={styles.FactValueError}><ExpressionValidFeedback isValid={false} />{createErrorMessage(value)}</div>
    : <span>{value?.toString()}</span>
}

export default FactValue