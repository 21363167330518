import revisionSessionSendRequestMutation from 'api/mutations/revisionSessionSendRequest.gql'
import { TARGET_SESSION_NOT_CONNECTED } from '../../api/Errors'
import resyncSessions from '../project/resyncSessions'

/**
 * Sends a request to execute a command in a target session
 * This allows communication between different user client apps like studio-front and Unreal
 */
const revisionSessionSendRequest = request => async (dispatch, getState, { getApolloClient }) => {
  try {
    const { data } = await getApolloClient().mutate({
      mutation: revisionSessionSendRequestMutation,
      variables: {
        input: request
      },
      fetchPolicy: 'no-cache'
    })
    if (data.error) {
      throw new Error(`There was a problem with the operation: ${data.error}`)
    }

    // TODO we still don't handle waiting for a response (async)

    return data.revisionSessionSendRequest
  } catch (error) {
    // if we tried to send a message to a session that server says it is disconnected
    // then we were out of sync ! force re-sync sessions !
    if (error.message.match(TARGET_SESSION_NOT_CONNECTED)) {
      dispatch(resyncSessions())
    }
    throw error
  }
}

export default revisionSessionSendRequest