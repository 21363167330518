import React from 'react'
import { MenuItem, MenuDivider } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { Sys } from 'beanie-engine-api-js'
import { always } from 'ramda'
import { isSys } from '../../../model/project/searches/query/bneQueries'
import { orQuery } from '../../../model/project/searches/query/queries'

import MenuItemCommand from '../MenuItemCommand'
import NodeAutoCompleteSelectionBlueprint from '../NodeAutoCompleteSelection/NodeAutoCompleteSelectionBlueprint'
import { conditionalFromChoicesAction } from 'engine/actions/conditionalFromChoices'
import { truthTableFromChoicesAction } from 'engine/actions/truthTableFromChoices'
import { PASTE_AS_CONDITIONAL, PASTE_AS_TRUTH_TABLE } from 'commands/pasteAs'

import {
  ADD_MARKER,
  ADD_CLIP,
  ADD_CHOICES,
  ADD_CHOICES2,
  ADD_JUMP,
  ADD_SEQUENCER,
  ADD_STOP,
  ADD_LUA,
  ADD_CONDITIONAL,
  ADD_TIMER,
  ADD_ACTION,
  ADD_ACTION2,
  ADD_TEMPLATE_TIMED_CHOICE_1,
  ADD_TEMPLATE_TIMED_CHOICE_2,
  ADD_TEMPLATE_TIMED_CHOICE_3,
  ADD_TEMPLATE_TIMED_CHOICE_4,
  ADD_TEMPLATE_CONDITIONAL,
  ADD_TRUTH_TABLE,
  ADD_FOLDER,
  ADD_TASK_CONTAINER } from 'commands/edition'

import { EMPTY_OBJECT } from 'utils/object'

const InsertMenuItem = ({ title = 'Insert...', connectionFn, createConditionalFromChoices, createTruthTableFromChoices, object }) => (
  <MenuItem text={title} icon="small-plus">

    <MenuItem text="Template...">
      <MenuItemCommand command={ADD_TEMPLATE_TIMED_CHOICE_1} params={[object, connectionFn]} />
      <MenuItemCommand command={ADD_TEMPLATE_TIMED_CHOICE_2} params={[object, connectionFn]} />
      <MenuItemCommand command={ADD_TEMPLATE_TIMED_CHOICE_3} params={[object, connectionFn]} />
      <MenuItemCommand command={ADD_TEMPLATE_TIMED_CHOICE_4} params={[object, connectionFn]} />
      <MenuItemCommand command={ADD_TEMPLATE_CONDITIONAL} params={[object, connectionFn]} />
      <MenuItem text="Conditional For...">
        <NodeAutoCompleteSelectionBlueprint
          elementLabel="choices"
          filter={anyChoicesFilter}
          fromClipboardShortCut={PASTE_AS_CONDITIONAL.shortcut}
          onSelect={createConditionalFromChoices}
          connectionFn={connectionFn}
          currentNodeWithFocus={object} />
      </MenuItem>
      <MenuItem text="Truth table For...">
        <NodeAutoCompleteSelectionBlueprint
          elementLabel="choices"
          filter={anyChoicesFilter}
          fromClipboardShortCut={PASTE_AS_TRUTH_TABLE.shortcut}
          onSelect={createTruthTableFromChoices}
          connectionFn={connectionFn}
          currentNodeWithFocus={object} />
      </MenuItem>
    </MenuItem>

    <MenuDivider key="divider" />

    <MenuItemCommand key={ADD_ACTION.label} command={ADD_ACTION} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_ACTION2.label} command={ADD_ACTION2} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_CHOICES.label} command={ADD_CHOICES} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_CHOICES2.label} command={ADD_CHOICES2} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_CLIP.label} command={ADD_CLIP} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_CONDITIONAL.label} command={ADD_CONDITIONAL} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_FOLDER.label} command={ADD_FOLDER} params={[object]} />
    <MenuItemCommand key={ADD_JUMP.label} command={ADD_JUMP} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_LUA.label} command={ADD_LUA} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_MARKER.label} command={ADD_MARKER} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_SEQUENCER.label} command={ADD_SEQUENCER} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_STOP.label} command={ADD_STOP} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_TASK_CONTAINER.label} command={ADD_TASK_CONTAINER} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_TIMER.label} command={ADD_TIMER} params={[object, connectionFn]} />
    <MenuItemCommand key={ADD_TRUTH_TABLE.label} command={ADD_TRUTH_TABLE} params={[object, connectionFn]} />

  </MenuItem>
)

export default connect(
  always(EMPTY_OBJECT),
  ({
    createConditionalFromChoices: conditionalFromChoicesAction,
    createTruthTableFromChoices: truthTableFromChoicesAction
  })
)(InsertMenuItem)

// utils

export const anyChoicesFilter = orQuery(isSys(Sys.choices), isSys(Sys.choices2))