import React from 'react'
import classNames from 'classnames'
import { model } from 'beanie-engine-api-js'

import withMouseOver from 'hocs/withMouseOver'

import StoryboardPreview from './StoryboardPreview'
import LineLeftBar from './LineLeftBar'
import PlayLineAudio from './PlayLineAudio'
import EnableDisableLineIcon from './EnableDisableLineIcon'

import styles from './../../SlateTextEditor.scss'

const LineWithStoryboard = ({ line, node, editor, deleteLine, lineId, mouseOver, attributes, children, className, setAudio, onStoryboardChanged, storyboard, storyboardOver, storyboardsEnabled, editor: { props: { removeLineStoryboard, readOnlyEditor } }, readOnly, isOverCurrent }) => {
  const isFullySelected = node && editor.selectionIsExpanded() && editor?.nodeIsFullySelected(node)
  const disabled = model.types.object.isDisabled(line)
  return (
    <div
      className={classNames(
        styles.LineWithStoryboard,
        {
          [styles.dropOver]: isOverCurrent,
          [styles.disabledLine]: disabled,
          [styles.fullySelected]: isFullySelected
        }
      )}
      {...attributes}
    >
      <LineLeftBar
        line={line}
        lineId={lineId}
        node={node}
        readOnly={readOnlyEditor || readOnly}
        mouseOver={mouseOver}
        onStoryboardChanged={onStoryboardChanged}
        deleteLine={deleteLine}
        onAudioChanged={setAudio}
      />
      <PlayLineAudio lineId={lineId} mouseOver={mouseOver} />
      <div className={classNames(className, {
        [styles.disabledContentLine]: disabled,
      }, styles.children)}>{children}</div>
      <EnableDisableLineIcon isDisabled={disabled} editor={editor} mouseOver={mouseOver} lineId={lineId} />
      {storyboardsEnabled && storyboard && (
        <StoryboardPreview
          lineId={lineId}
          readOnly={readOnlyEditor || readOnly}
          storyboard={isOverCurrent && storyboardOver ? storyboardOver : storyboard}
          onChange={onStoryboardChanged}
          removeLineStoryboard={removeLineStoryboard}
        />
      )}
    </div>
  )
}

export default withMouseOver()(LineWithStoryboard)
