import { withEffects, toProps } from 'refract-rxjs'
import { combineLatest } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { branch, withHandlers, compose } from 'recompose'
import { pipe, prop, and } from 'ramda'

import { onReadyToNavigate as onKeyboardReady$ } from 'observables/ui/keyboard'
import onMouseOver from 'apertures/onMouseOver'
import { model } from 'beanie-engine-api-js'

const { types: { object: { isNavigable } } } = model

// Note: maybe we should stop listening to the keyboard observer if
// the user is editing a node (that's in redux state)

export const aperture = component => {
  const [onMouseReady$, onMouseEnter, onMouseLeave] = onMouseOver(component)

  return combineLatest(onMouseReady$, onKeyboardReady$, and)
    .pipe(
      distinctUntilChanged(),
      map(hoveringToNavigate => toProps({
        hoveringToNavigate,
        onMouseEnter,
        onMouseLeave,
      }))
    )
}

export const withLinkToNodeRefract = withEffects(aperture)


/**
 * Injects:
 *  - onClick: to be used when navigation
 *  - onMouseEnter/Leave to hook into your html so this calculates if hoveringToNavigate
 *  - hoveringToNavigate: a boolean stating if the user is currently hovering + ALT/OPT pressed
 */
export const withLink = ({ onClickToNavigate }) => compose(
  withLinkToNodeRefract,

  // TODO: this could be migrated to the aperture (useEvent('onClick'))
  withHandlers({
    onClick: props => event => {
      const { hoveringToNavigate } = props
      if (hoveringToNavigate) {
        event.preventDefault()
        event.stopPropagation()
        onClickToNavigate(props)
      }
    }
  }),
)

const withLinkToNode = props => branch(
  pipe(prop('node'), isNavigable), withLink(props)
)

export default withLinkToNode