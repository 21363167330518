
/**
 * Operation types for oneToMany relationships
 * @type {{INSERT: string, REPLACE: string, APPEND: string}}
 */
export const OneToManyOperations = {

  /**
   * Inserts an element into the given index/position.
   */
  INSERT: 'INSERT',

  /**
   * Replaces the element specified by index/position with another one.
   */
  REPLACE: 'REPLACE',

  /**
   * Adds an element to the end of the array.
   */
  APPEND: 'APPEND',

}