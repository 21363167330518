import React from 'react'
import classNames from 'classnames'
import DebugIcon from '../../../../Commons/DebugIcon'

import withNodeDropBehavior from '../../../dnd/withNodeDropBehavior'

import { TreeNodeExecutionDecoration } from '../../../TreeNodeExecutionDecoration/TreeNodeExecutionDecoration'
import DropFeedback from '../DropFeedback'
import OtherwiseRowDropdown from './OtherwiseRowDropdown'
import useSecureCheck from 'hooks/security/useSecureCheck'
import { isCurrentRevisionWritable } from 'security/project'

import styles from '../TruthTable.scss'
import treeViewStyles from '../../../TreeView.scss'

/**
 *
 */
const OtherwiseContent = ({
  node,

  truthTable,
  truthTableExecuted,
  truthTableSelected,
  truthTableEnabled,
  countOfColumns,

  visibleDropdown,
  setDropdownVisibility,

  // dnd
  connectDropTarget,
  droppingRight,
  droppingAbove,

  isHovered,

  executionState,
  executionPath,
  trackExecutionEnabled,

  pinned,
  debuggableRow,
}) => {
  const execution_count = executionState?.execution_count || 0
  const otherwiseRowWasExecuted = execution_count > 0

  const showDisabled = (truthTableEnabled === false) || (debuggableRow && debuggableRow !== node.id)
  const hasWriteAccess = useSecureCheck(isCurrentRevisionWritable)


  return connectDropTarget(
    <td
      colSpan={countOfColumns}
      className={classNames(
        styles.otherwise,
        truthTableExecuted && trackExecutionEnabled ? {
          [styles.truthTableExecutedBottomBorder]: true,
          [styles.truthTableExecutedLeftBorder]: true,
          [styles.truthTableExecutedRightBorder]: true,
        } : null,
        {
          [styles.selected]: truthTableSelected,
          [treeViewStyles.disabledNode]: showDisabled,
          [styles.rowUnselectedCellStyle]: truthTableExecuted && !otherwiseRowWasExecuted,
        }
      )}
    >
      <DebugIcon nodeId={node.id} />

      <TreeNodeExecutionDecoration
        trackExecutionEnabled={trackExecutionEnabled}
        executionState={executionState}
        executionPath={executionPath}
      >
        <div
          className={classNames({
            ...!truthTableEnabled ? {
              // REVIEWME: this style is wrong !! is not in the SCSS
              [treeViewStyles.truthTableDisabled]: true,
              [treeViewStyles.disabledNode]: true
            } : null,
            [styles.overToDrop]: droppingAbove,
          })}
        >
          {hasWriteAccess && (isHovered || visibleDropdown) &&
            <OtherwiseRowDropdown
              node={node}
              pinned={pinned}
              truthTable={truthTable}
              setDropdownVisibility={setDropdownVisibility}
            />
          }
          <span>otherwise</span>
        </div>
      </TreeNodeExecutionDecoration>
      {droppingRight &&
        <DropFeedback className={styles.otherwiseIsOver} />
      }
    </td>
  )
}

export default withNodeDropBehavior(OtherwiseContent)