import React from 'react'

import BufferingVideoPlayer from '../BufferingVideoPlayer/BufferingVideoPlayer'

import VideoPlayerControlsOverlay from '../VideoPlayerControlsOverlay/VideoPlayerControlsOverlay'

import styles from './VideoPlayer.scss'

const VideoPlayer = () => (
  <div className={styles.playerCanvas}>
    <BufferingVideoPlayer />
    <VideoPlayerControlsOverlay />
  </div>
)

export default VideoPlayer