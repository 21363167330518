import React from 'react'
import { Button } from 'antd'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import ModalButton from 'components/Commons/ModalButton'
import CreateCustomTypeForm from './CreateCustomTypeForm'

import markupCustomTypeCreateMutation from 'api/mutations/markupCustomTypeCreate.graphql'

import { revisionId, currentIsBranch } from 'selectors/project'
import { markupCustomTypeCreated as markupCustomTypeCreatedAction } from 'actions/project'

const CreateMarkupCustomType = ({ onCreate, isBranch }) => (
  <ModalButton title="Create Custom Type" onOk={onCreate}>
    <Button type="primary" disabled={!isBranch} icon="plus">Add</Button>
    <CreateCustomTypeForm />
  </ModalButton>
)

export default compose(
  connect(state => ({
    revision: revisionId(state),
    isBranch: currentIsBranch(state)
  }), {
    markupCustomTypeCreated: markupCustomTypeCreatedAction
  }),
  graphql(markupCustomTypeCreateMutation, { name: 'markupCustomTypeCreate' }),
  withHandlers({
    onCreate: ({ revision, markupCustomTypeCreate, markupCustomTypeCreated }) => async ({ name, description, values }) => {
      const { data: { markupCustomTypeCreate: created } } = await markupCustomTypeCreate({ variables: { input: {
        // TODO: rename to revision
        projectId: revision,
        name,
        description,
        values
      } } })
      markupCustomTypeCreated(created)
    }
  })
)(CreateMarkupCustomType)