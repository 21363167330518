import { createSelector } from '../reselect'
import { pathFromProps } from '../props'
import { objectsIndex } from '../apollo'
import { parseRef } from 'beanie-engine-api-js'

const makeResolveReference = (from, path) => createSelector(
  [pathFromProps(from.split('.').concat(path.split('.'))), objectsIndex],
  (ref, index) => {
    const id = parseRef(ref)
    return id && index[id]
  }
)

export default makeResolveReference