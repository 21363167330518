import { splitAt, map, pipe, prop, assocPath } from 'ramda'
import { textNode } from '../utils/slateMocks/textNode'
import { inlineNode } from '../utils/slateMocks/inlineNode'
import { NODE_TYPES } from '../utils/Constants'
import { EMPTY_ARRAY } from 'utils/object'
import { NoteTypes, noteText } from 'model/constants'

import markupSerializer from './MarkupSerializer'

const textToNote = (inlineType, noteType) => ({ text }) => inlineNode([textNode(noteText(noteType, text))], inlineType)

export const SyntheticMetadataType = {
  mark: 'mark', // for markups
  text: 'text' // for text parts of the split text
}

const METADATA_TO_NODE = {
  // beanie model types
  [NoteTypes.Production]: textToNote(NODE_TYPES.PRODUCTION_NOTE, NoteTypes.Production),
  [NoteTypes.Performance]: textToNote(NODE_TYPES.PERFORMANCE_NOTE, NoteTypes.Performance),
  // synthetic types (created to process them reusing this logic)
  [SyntheticMetadataType.mark]: markupSerializer,
  [SyntheticMetadataType.text]: pipe(prop('text'), textNode),
}

export const NodeTypeToNoteType = {
  [NODE_TYPES.PRODUCTION_NOTE]: NoteTypes.Production,
  [NODE_TYPES.PERFORMANCE_NOTE]: NoteTypes.Performance
}

export const metadataToNode = obj => {
  const node = METADATA_TO_NODE[obj.type](obj)
  if (obj.fqn) { return assocPath(['data', 'fqn'], obj.fqn, node) }
  return node
}
export const metadataToNodes = map(metadataToNode)

//
// combine metadata: inlines ++ markups ++ text
//

const sortMetadataElementsByOffset = elems => elems.sort((elem1, elem2) => elem1.offset - elem2.offset)
export const combineMetadata = (metadataElements, text) => {
  const { result: objects, offset: lastOffset, text: afterText } = sortMetadataElementsByOffset(metadataElements).reduce(
    ({ result, text: currentText, offset }, currentInline) => {
      const [textBefore, textAfter] = splitAt(currentInline.offset - offset, currentText)
      return {
        result: [...result, textMetadata(textBefore), currentInline],
        text: textAfter,
        offset: currentInline.offset
      }
    }, { result: EMPTY_ARRAY, text, offset: 0 })
  return afterText === '' ? objects : [...objects, textMetadata(text.slice(lastOffset))]
}

const textMetadata = text => ({ type: SyntheticMetadataType.text, text })