import { pipe, prop, test } from 'ramda'
import { GamepadButton, createGamepadDefinition } from '../api'

const SwitchButton = {
  A: { name: 'A', icon: '/images/gamepads/button-switch-a.jpeg' },
  B: { name: 'B', icon: '/images/gamepads/button-switch-b.jpeg' },
  X: { name: 'X', icon: '/images/gamepads/button-switch-x.jpeg' },
  Y: { name: 'Y', icon: '/images/gamepads/button-switch-y.jpeg' },
}

//
// gamepad def
// 

const SwitchGamepad = createGamepadDefinition({
  type: 'SWITCH',
  matches: pipe(prop('id'), test(/^Pro Controller/)),
  buttons: SwitchButton,
  apiToButtonMapping: {
    [GamepadButton.A]: SwitchButton.B,
    [GamepadButton.B]: SwitchButton.A,
    [GamepadButton.X]: SwitchButton.Y,
    [GamepadButton.Y]: SwitchButton.X,
  },
  buttonToChoiceMapping: {
    [SwitchButton.Y.name]: 0,
    [SwitchButton.X.name]: 1,
    [SwitchButton.A.name]: 2,
    [SwitchButton.B.name]: 3,
  },
})

export default SwitchGamepad