exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__15kS2{color:#add8e6;cursor:pointer}.iconAsLink__15kS2:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__hQE1k{font-style:italic}.formFieldsVerticalGutter__tpiFz{padding:.5rem}.formFieldsVerticalGutter__tpiFz>*{margin:.5rem !important}.tableBackground__BNUQu .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__BNUQu .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__2qnIa .ant-notification-notice-close{display:none}.menuContainer__3Nln-{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;background-color:rgba(0,0,0,.2);padding-top:1em}.menuContainer__3Nln- button{background-color:transparent !important;border:0px !important;margin-bottom:0px !important}.menuContainer__3Nln- .ant-menu-item{padding:0 12px !important}.menuContainer__3Nln- .ant-menu-inline-collapsed{width:40px}.menuContainer__3Nln- .ant-menu-item-selected{background-color:#503898 !important}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__15kS2",
	"labelFromRef": "labelFromRef__hQE1k",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__tpiFz",
	"tableBackground": "tableBackground__BNUQu",
	"notificationNotClosable": "notificationNotClosable__2qnIa",
	"menuContainer": "menuContainer__3Nln-"
};