import React from 'react'

import { ANNOTATION_TYPES } from './Constants'
import { AddContentAnnotation } from '../Components/Annotations/AddContentAnnotation'
import { DeleteLineAnnotation } from '../Components/Annotations/DeleteLineAnnotation'
import { RemoveContentAnnotation } from '../Components/Annotations/RemoveContentAnnotation'
import { ReplaceContentAnnotation } from '../Components/Annotations/ReplaceContentAnnotation'
import { componentsByBlockType, componentsByInlineType } from './Handlers'

export const readOnlyHandlers = {
  renderBlock: () => (props, editor, next) => {
    const { type } = props.node
    const Component = componentsByBlockType[type]
    return Component ? <Component {...props} /> : next()
  },

  renderAnnotation: () => (props, editor, next) => {
    const { type } = props.annotation
    const Component = componentByAnnotationType[type]
    return Component ? <Component {...props} /> : next()
  },

  renderInline: () => (props, editor, next) => {
    const { type } = props.node
    const Component = componentsByInlineType[type]
    return Component ? <Component {...props} /> : next()
  },
}

const componentByAnnotationType = {
  [ANNOTATION_TYPES.ADD_LINE_ANNOTATION]: AddContentAnnotation,
  [ANNOTATION_TYPES.DELETE_LINE_ANNOTATION]: DeleteLineAnnotation,
  [ANNOTATION_TYPES.INSERT_TEXT_ANNOTATION]: AddContentAnnotation,
  [ANNOTATION_TYPES.REMOVE_TEXT_ANNOTATION]: RemoveContentAnnotation,
  [ANNOTATION_TYPES.REPLACE_TEXT_ANNOTATION]: ReplaceContentAnnotation,
}
