import { ascend, descend, path, prop, sortWith } from 'ramda'
import React, { useMemo } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'

import { sessions as sessionsSelector } from 'selectors/project'
import destroyRevisionSession from 'actions/project/session/destroyRevisionSession'
import { showSessionCursor as showSessionCursorAction } from '../../actions/project/session'

import RevisionSessionsList from './RevisionSessionsList'

/**
 *
 */
const RevisionSessions = ({ sessions, destroySession, showSessionCursor }) => {
  const sortedSessions = useMemo(() => sortWith([
    ascend(path(['user', 'firstName'])),
    descend(prop('createdAt')),
  ], sessions), [sessions])
  return (
    <RevisionSessionsList
      sessions={sortedSessions}
      destroySession={destroySession}
      showSessionCursor={showSessionCursor}
    />
  )
}

export default compose(
  connect(state => ({
    sessions: sessionsSelector(state),
  }), {
    destroySession: destroyRevisionSession,
    showSessionCursor: showSessionCursorAction,
  }),
)(RevisionSessions)