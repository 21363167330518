import { defaultTo, filter, path, pipe, prop, propEq, map, match, nth } from 'ramda'
import { OrganizationMembershipRole } from 'utils/authentication'
import { EMPTY_ARRAY } from 'utils/object'
import { createSelector } from './reselect'

const login = prop('login')
export const user = createSelector([login], prop('user'))
export const userId = pipe(user, prop('_id'))
export const token = path(['login', 'token'])
export const refreshToken = path(['login', 'refreshToken'])
export const refreshExpires = path(['login', 'refreshExpires'])
export const error = path(['login', 'error'])
export const isLoggingIn = path(['login', 'logging'])

export const userOrganizationsMemberships = pipe(user, prop('organizationsMemberships'))
const userOrganizationsAs = role => pipe(userOrganizationsMemberships, defaultTo(EMPTY_ARRAY), filter(propEq('role', role)))
export const userOrganizationsAsMember = userOrganizationsAs(OrganizationMembershipRole.MEMBER)
export const userOrganizationsAsOwner = userOrganizationsAs(OrganizationMembershipRole.OWNER)
export const userOrganizations = pipe(userOrganizationsMemberships, map(prop('organization')))

const THEN_QUERY_REGEX = /then=(.*)/
export const thenURLFromLocation = (state, props) => {
  const { location } = props
  return location && location.search && nth(1, match(THEN_QUERY_REGEX, location.search))
}