import React from 'react'

import { globalSearchQuery } from 'actions/nodes/search/searchMatchingText'

import SearchObject from 'components/SearchObject/SearchObject'

const TextSearch = props => (
  <SearchObject
    filterOption={globalSearchQuery}
    {...props}
  />
)

export default TextSearch
