exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".menu__QQxno .ant-menu-item .anticon{opacity:.5}.menu__QQxno .ant-menu-item-active{text-shadow:0 0 .65px #333,0 0 .65px #333;font-weight:normal !important}", ""]);

// exports
exports.locals = {
	"menu": "menu__QQxno"
};