import React from 'react'
import schema from 'components/SlateTextEditor/utils/schema/schema'

import { Editor } from 'slate-react'
import { compose, withHandlers, withState } from 'recompose'

import { diffEditorPlugins } from './utils/plugins/Plugins'
import { readOnlyHandlers } from './utils/readOnlyHandlers'

const _ReadOnlyEditor = ({ renderBlock, renderInline, renderAnnotation, plugins, value }) => (
  <Editor
    schema={schema}
    value={value}
    renderBlock={renderBlock}
    renderInline={renderInline}
    renderAnnotation={renderAnnotation}
    plugins={plugins}
    readOnlyEditor
  />)

export default compose(
  withState('plugins', 'setPlugins', () => diffEditorPlugins()),
  withHandlers(readOnlyHandlers))(_ReadOnlyEditor)