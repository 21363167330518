
/**
 * New function here. We make the listener kind of an object (or a function with state).
 * It remembers the last subset it persisted. So on new events it shallow compares
 * and it will only persist if at least one key has changed
 */
const createStoreListener = (store, slicer, key, serialize) => {
  let lastSubset;
  const hasChanged = set => {
    if (lastSubset === undefined) return true

    for (const setKey in set) {
      if (set[setKey] !== lastSubset[setKey]) {
        return true
      }
    }
    return false
  }

  return () => {
    const state = store.getState()
    const subset = slicer(state)

    if (!hasChanged(subset)) return

    try {
      localStorage.setItem(key, serialize(subset))
      lastSubset = subset
    } catch (e) {
      /* eslint no-console: 0 */
      console.warn('Unable to persist state to localStorage:', e)
    }
  }
}

export default createStoreListener