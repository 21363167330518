import { DECORATION_TYPES } from '../../../utils/Constants'

/**
 * Factory to create the Search Highlight Decoration object
 */
const createLineHighlightDecoration = (lineId, line_result_index, path, key, startOffset, endOffset) => ({
  type: DECORATION_TYPES.highlight,
  anchor: { key, path, offset: startOffset, },
  focus: { key, path, offset: endOffset },
  data: {
    line_id: lineId,
    line_result_index,
  }
})

export default createLineHighlightDecoration