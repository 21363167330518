import React from 'react'
import { Icon, Menu } from 'antd'
import classNames from 'classnames'
import { compose as composeF, findIndex, prop, propEq, remove, sortBy, toLower } from 'ramda'
import { Themes } from 'utils/antd'
import DeleteRevisionButton from '../RevisionSelector/DeleteRevisionButton'

import styles from './BranchSelector.scss'
import globalStyles from 'styles/globals.scss'

const sortByVersion = sortBy(composeF(toLower, prop('version')))

/**
 *
 */
const BranchesMenu = ({ isVisible, current, branches, onBranchSelected, onDeletePressed }) => {
  if (!branches) return null
  const masterIndex = findIndex(propEq('master', true), branches)
  const master = branches[masterIndex]
  const others = sortByVersion(remove(masterIndex, 1, branches))

  return (
    <Menu
      className={classNames(styles.branchDropdownOverlay, globalStyles.topBarMenu)}
      theme={Themes.dark}
      selectedKeys={current ? [current._id] : null}
      visible={isVisible}
    >
      <div className={styles.title}>version</div>
      <Menu.Item
        key={master._id}
        onClick={() => onBranchSelected(master)}>
        {master.version}
        {current && current._id === master._id && (
          <Icon type="check" />
        )}
      </Menu.Item>
      <Menu.Divider key="masterDivider" />
      {others.map(branch => (
        <Menu.Item
          key={branch._id}
          className={styles.branchItem}
          onClick={() => onBranchSelected(branch)}
        >
          {branch.version}
          {current && current._id === branch._id && (
            <Icon type="check" />
          )}
          {current && current._id !== branch._id && (
            <DeleteRevisionButton id={branch._id} onPressed={onDeletePressed} />
          )}
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default BranchesMenu