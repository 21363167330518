import SessionExpirationWarning from 'components/SessionExpiration/SessionExpirationWarning'

export /* to test */ const MAX_INT_32 = 2147483647 // tad more than 24 days
export /* to test */ const WARNING_ANTICIPATION_MS = 3 * 60 * 1000 // three minutes
const toMs = secs => secs * 1000

// start warning about long session expiration way before so that users are able to see the warning
export /* to test */ const timeToPopupWarning = expires => toMs(expires) - new Date().getTime() - WARNING_ANTICIPATION_MS
export /* to test */ const isTimeToPopupInRange = ttpu => {
  return ttpu !== undefined && MAX_INT_32 - Math.abs(ttpu) >= 0
}

let timer

export const teardown = () => {
  clearTimeout(timer)
  timer = null
}

const setup = ({ reduxStore: store, expires }) => {
  teardown()
  if (expires && Number.isInteger(expires)) {
    const ttpu = timeToPopupWarning(expires)
    if (isTimeToPopupInRange(ttpu)) {
      timer = setTimeout(SessionExpirationWarning({ expires, store }), ttpu)
      return timer
    }
  }
}

export default setup