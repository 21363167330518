import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { selectNode } from 'actions/nodes'

/**
 * Returns a function to change the currently selected node
 */
const useSelectNode = () => {
  const dispatch = useDispatch()

  return useCallback((...args) =>
    dispatch(selectNode(...args)),
  []
  )
}

export default useSelectNode