import { orgWriteAccess } from 'security/organization'

import OrganizationTeams from './OrganizationTeams/OrganizationTeamsContainer'
import OrganizationMembers from './OrganizationMembers/OrganizationMembersContainer'
import OrganizationProjects from './OrganizationProjects/OrganizationProjectsContainer'
import OrganizationSettings from './OrganizationSettings/OrganizationSettings'
// import OrganizationPage from './OrganizationPage/OrganizationPage'

const s = (name, icon, label, comp, visibleCheck) => ({ name, icon, label, comp, visibleCheck })

const Sections = {
  // Summary: s('summary', 'profile', 'Summary', OrganizationPage),
  Members: s('members', 'user', 'Members', OrganizationMembers),
  Teams: s('teams', 'team', 'Teams', OrganizationTeams),
  Projects: s('projects', 'book', 'Projects', OrganizationProjects),
  Settings: s('settings', 'setting', 'Settings', OrganizationSettings, orgWriteAccess)
}

export default Sections