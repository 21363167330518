import { model } from 'beanie-engine-api-js'
import blocks from './blocks/blocks'
import { T, propEq } from 'ramda'
import inlines from './inlines/inlines'
import { NODE_TYPES } from '../Constants'
import { clipNormalize } from './normalizers/clip'

const { types: { markup: { MARKUP_FIELD_SEPARATOR, MARKUP_PARAM_VALUE_LIST_SEPARATOR, DELIM_END, DELIM_START } } } = model

const schema = {
  document: {
    nodes: [
      {
        match: [{ type: NODE_TYPES.DIRECTOR_LINE }, { type: NODE_TYPES.DIALOGUE_LINE }],
        min: 1,
      }
    ],
    normalize: clipNormalize
  },
  blocks,
  inlines,
}

export default schema

const isNotTheUniqueChild = (editor, textNode) => editor.parent(textNode).nodes.size > 1

// So, this declares for a container node (inline | block) a function
// that indicates whether a given child text is a ghost text.
// Ghost text are synthetically added by slate to avoid some html issues, for example between inlines.
export const GHOST_TEXT_BY_INLINE_CONTAINER = {
  [NODE_TYPES.MARK_UP_WITH_PARAMS]: T,
  [NODE_TYPES.MARK_UP_PARAMETERS]: T,
  [NODE_TYPES.MARK_UP_PARAMETER]: T,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE]: isNotTheUniqueChild,
  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST]: T,
}

export const FIXED_TEXT_BY_CONTAINER = {

  [NODE_TYPES.MARK_UP_PARAMETER]: propEq('text', MARKUP_FIELD_SEPARATOR),

  [NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST]: propEq('text', MARKUP_PARAM_VALUE_LIST_SEPARATOR),

  [NODE_TYPES.MARK_UP]: text => text.text === DELIM_END || text.text === DELIM_START,

}