/* eslint-disable no-unused-vars */
import {
  assocPath,
  compose,
  lens,
  pathOr,
  pipe,
  prop,
  isEmpty,
  includes,
  indexBy,
  lte,
  lt,
  path,
  gt,
  reduce,
  has,
  append,
  forEach,
  addIndex,
  map,
  flip,
  comparator,
  sort,
  repeat, join,
  split,
  symmetricDifference,
  over, uncurryN, curryN, chain, anyPass, isNil
} from 'ramda'
import { concatRight } from 'ramda-adjunct'
import { EMPTY_ARRAY } from './object'

/**
 * TODO: remove and use ramda-adjunct
 * @deprecated Don't use this unless composing functions. Makes no sense to use this level of
 * function composition when JS Array's already have a "map" method doing exactly this.
 * If you really need it then use ramda-adjunct's mapIndexed instead of this one
 */
export const mapIndexed = addIndex(map)

export const lensPathOr = (defaultValue, aPath) => lens(pathOr(defaultValue, aPath), assocPath(aPath))

const compareProp = comparatorFn => propToCompare => value => pipe(prop(propToCompare), currentValue => comparatorFn(currentValue, value))
export const propLteTo = compareProp(lte)
export const propLtTo = compareProp(lt)
export const propGtTo = compareProp(gt)

export const collectProp = _prop => reduce((acc, c) => (has(_prop, c) ? append(prop(_prop, c), acc) : acc), EMPTY_ARRAY)

export const isPropOf = flip(has)

export const included = flip(includes)

export const sortDescending = sort(comparator(lt))

export const concatRightTo = curryN(3, (aLens, obj, list) => over(aLens, concatRight(list), obj))

export const flatMap = chain

export const repeatString = pipe(repeat, join(''))

export const pathStr = uncurryN(2, pipe(split('.'), path))

export const indexById = indexBy(prop('id'))

export const arraySameElements = compose(isEmpty, symmetricDifference)

export const isNilOrEmpty = anyPass([isNil, isEmpty])
