import ShortcutsPlugin from '../misc/shortcuts'
import ChoicesOnEnterPlugin from '../choices/onEnter'

/**
 * This all is super deprecated.
 * Whenever we will want to have more features to edit choices from the text editor
 * we should address migrating it to Slate and delete all the rest of draft-js
 *
 */
export default [
  ShortcutsPlugin,
  ChoicesOnEnterPlugin,
]