import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { Icon } from 'antd'

import { status as statusSelector } from 'selectors/network'
import { logout as logoutAction } from 'actions/login'
import { manualReconnect } from 'graphql/links/subscriptions/WebSocketLink'
import { Status } from 'model/network'

import IconButton from 'components/Commons/IconButton'

import styles from './NetworkStatus.scss'

const SHOW_RECONNECT_BUTTON = false

export const NetworkStatus = ({ status, reconnect, logout }) => {
  switch (status) {
    case Status.disconnected: return (
      <ConnectionOverlay className={styles.disconnected}>
        <Icon type="loading" /> Oops, we lost the connection to the server! Retrying ...
        {SHOW_RECONNECT_BUTTON &&
          <IconButton tooltip="Reconnect" type="sync" onClick={reconnect} />
        }
        <IconButton tooltip="Logout" type="logout" onClick={logout} />
      </ConnectionOverlay>
    )
    case Status.reconnecting: return (
      <ConnectionOverlay className={styles.reconnecting}>
        Reconnecting
        <Icon type="loading" />
      </ConnectionOverlay>
    )
    default: return null
  }
}

export default connect(state => ({
  status: statusSelector(state)
}), {
  reconnect: manualReconnect,
  logout: logoutAction,
})(NetworkStatus)


const ConnectionOverlay = ({ children, className }) => (
  <div className={styles.container}>
    <div className={classNames(styles.networkStatus, className)}>
      {children}
    </div>
    <div className={styles.overlay} />
  </div>
)