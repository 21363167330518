exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".grantItem__9uE2Z{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:100%}.grantItem__9uE2Z>div{-ms-flex:1 1 0%;flex:1 1 0%}.grantItem__9uE2Z .name__wZDe5{-ms-flex:2 1 0%;flex:2 1 0%}.grantItem__9uE2Z .role__1pm03 .anticon{margin-right:.2rem}", ""]);

// exports
exports.locals = {
	"grantItem": "grantItem__9uE2Z",
	"name": "name__wZDe5",
	"role": "role__1pm03"
};