import React, { useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'

import SystemClipboardListener from './SystemClipboardListener'

import { clipboard } from 'selectors/clipboard'
import { setClipboardMeta } from 'actions/clipboard'
import { fromTextToHeader } from 'utils/clipboard'

const ConnectedSystemClipboard = ({ children }) => {
  const dispatch = useDispatch()
  const store = useStore()

  const onClipboardChanged = useCallback(clipboardMeta => {
    dispatch(setClipboardMeta(clipboardMeta))
  }, [dispatch])

  const transform = useCallback(parsedHeader =>
    fromTextToHeader(clipboard(store.getState()), parsedHeader)
  , [store])

  return (
    <SystemClipboardListener
      onClipboardChanged={onClipboardChanged}
      transform={transform}
    >
      {children}
    </SystemClipboardListener>
  )
}

export default ConnectedSystemClipboard
