import React from 'react'

const TeamErrorLoading = ({ error }) => (
  <div>
    <h1>Oops! Something went wrong</h1>
    <p>Seems like you are trying to access a team that doesn&apos;t exists or you are not allowed to access</p>
    {JSON.stringify(error)}
  </div>
)

export default TeamErrorLoading