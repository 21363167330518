import React from 'react'
import { connect } from 'react-redux'
import { model } from 'beanie-engine-api-js'

import { intersperse } from 'utils/object'

import { propFromProps } from 'selectors/props'
import { makeRootIdsForLane } from 'selectors/objects'

import TreeChain from '../TreeChain/TreeChain'
// import TreeChain from './GraphTreeChain'

import styles from './TreeLane.scss'

const { types: { relationship: { Relationship } } } = model

const LaneRoots = ({ name, rootIds }) => intersperse(
  rootIds
    .map((id, i) => (
      <TreeChain
        key={id}
        id={id}
        relationship={Relationship.rootOfLane(name, i)}
        isRoot
      />
    )),
  i => <div key={`${i}-sep`} className={styles.sceneSeparator} />
)

export default connect(() => {
  const rootsForLane = makeRootIdsForLane(propFromProps('name'))
  return (state, props) => ({
    rootIds: rootsForLane(state, props)
  })
})(LaneRoots)