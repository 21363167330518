import React from 'react'
import { Select } from 'antd'
import { connect } from 'react-redux'

import { makePreferenceSelector } from 'selectors/view'
import { updatePreference } from 'actions/view'

const { Option } = Select

const SelectPreference = ({ className, currentValue, options, onChange, disabled }) => (
  <Select
    className={className}
    disabled={disabled}
    value={`${currentValue}`}
    onChange={onChange}
  >
    {options.map((o, i) => (
      <Option key={i} value={`${o.value}`}>{o.name}</Option>
    ))}
  </Select>
)

export default connect(() => {
  const selector = makePreferenceSelector('preference')
  return (state, props) => ({
    currentValue: selector(state, props)
  })
}, (dispatch, { preference, options }) => ({
  onChange: (selectedValue, option) => {
    const index = parseInt(option.key)
    const { value } = options[index]
    dispatch(updatePreference(preference, value))
  }
}))(SelectPreference)