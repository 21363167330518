import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, withHandlers } from 'recompose'
import { propEq } from 'ramda'
import classnames from 'classnames'

import { NotificationType } from 'model/constants'
import { downloadPdf as downloadPdfAction } from 'actions/download'
import Notification from 'components/NotificationsIndicator/Notification'
import IconButton from 'components/Commons/IconButton'

import styles from '../Notification.scss'

const _DownloadPdfButton = ({ loading, download }) =>
  <IconButton type="download" tooltip="Click to download" onClick={download} className={classnames({ [styles.loading]: loading })} />

const DownloadPdfButton = compose(
  connect(null, { downloadPdf: downloadPdfAction }),
  withState('loading', 'setLoading', false),
  withHandlers({
    download: ({ downloadPdf, setLoading, notification: { payload: { jobId, revisionId } } }) => () => {
      setLoading(true)
      downloadPdf({ jobId, revisionId })
      setLoading(false)
    }
  })
)(_DownloadPdfButton)

const pdfJobReadyNotifier = {
  accepts: propEq('type', NotificationType.PDF_READY),
  render: notification => {
    return (<Notification
      key={notification._id}
      notification={notification}
      title="Your PDF is ready to download"
      actions={[<DownloadPdfButton key="download" notification={notification} />]}
    />)
  }
}
export default pdfJobReadyNotifier
