import React from 'react'
import { lang } from 'beanie-engine-api-js'

const { rule: { utils: { valueToString }, error: { createErrorMessage } } } = lang

import styles from './FactDrawer.scss'

/**
 * Feedback component for beanie language expressions
 */
const ExpressionResult = ({ shouldEval, runtimeError, staticError, result }) => {
  return (
    <div className={styles.ExpressionResult}>
      <div className={styles.resultTitle}>Result</div>
      {!shouldEval && (
        <div className={styles.notPlaying}>You must be <strong>playing</strong> to see the value</div>)
      }
      {shouldEval && staticError && (
        <div className={styles.runtimeError}>
          Invalid Expression
        </div>
      )}
      {shouldEval && !staticError && runtimeError && (
        <div className={styles.runtimeError}>
          {createErrorMessage(runtimeError)}
        </div>
      )}
      {shouldEval && !staticError && !runtimeError && (
        <pre>
          {valueToString(result)}
        </pre>
      )}
    </div>
  )
}

export default ExpressionResult