import React from 'react'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import classNames from 'classnames'
import { compose, withHandlers, withState, withPropsOnChange } from 'recompose'
import { objOf, pipe, prop, head, isEmpty } from 'ramda'
import { Input, Checkbox } from 'antd'

import AbsoluteTime from '../Commons/AbsoluteTime'

import openRevisionAction from '../../actions/project/openRevision'

import branchCheckpointsQuery from 'api/queries/branchCheckpoints.graphql'
import projectCreateCheckpointMutation from 'api/mutations/projectCreateCheckpoint.graphql'
import { updateQueryValue, pushTransform } from 'graphql/updates'

import { projectSelector, currentBranchId as currentBranchIdSelector } from 'selectors/project'
import { proposeNextCheckpoint } from 'utils/projectVersion'

import { withTargetValue } from 'utils/antd'

import CreateModal from 'components/Commons/CreateModal'

import styles from './MakeCheckpoint.scss'

const MakeCheckpointModal = ({ hide, previousCheckpoint, currentVersion, handleMakeCheckpoint, checkpointName, checkpointNameChanged, navigateToCheckpoint, setNavigateToCheckpoint, copyAudioResources, setCopyAudioResources }) => (
  <CreateModal
    title="Make a new Checkpoint"
    submit={handleMakeCheckpoint}
    canSubmit={!isEmpty(checkpointName)}
    hide={hide}
  >
    {onCreate => (
      <div className={styles.modalContent}>
        <div className={classNames(styles.modalRow, styles.name)}>
          <span className={styles.rowTitle}>Name</span>
          <Input className={styles.input} placeholder="Checkpoint name" value={checkpointName} onChange={checkpointNameChanged} onPressEnter={onCreate} />
        </div>
        <div className={styles.modalRow}>
          <span className={styles.rowTitle}>From version</span>
          <span className={styles.version}>{currentVersion}</span>
        </div>
        <div className={styles.modalRow}>
          {previousCheckpoint && <span>
            <span className={styles.rowTitle}>Previous Checkpoint is </span>
            <span className={styles.bold}>{previousCheckpoint.version} </span>
            <span>from </span>
            <span className={styles.bold}><AbsoluteTime date={previousCheckpoint.createdAt} /></span>
          </span>}
        </div>
        <div className={styles.modalRow}>
          <div className={styles.openRightAway}><Checkbox checked={navigateToCheckpoint} onChange={withTargetValue(setNavigateToCheckpoint)} /> Open right away</div>
        </div>
        <div className={styles.modalRow}>
          <div className={styles.openRightAway}><Checkbox checked={copyAudioResources} onChange={withTargetValue(setCopyAudioResources)} /> Copy audio and lipsync?</div>
        </div>
      </div>
    )}
  </CreateModal>
)

const handleMakeCheckpoint = ({ createCheckpoint, checkpointName }) => () => createCheckpoint(checkpointName)

export default compose(
  connect(state => ({
    currentBranchId: currentBranchIdSelector(state)
  }), {
    openRevision: openRevisionAction,
  }),
  withPropsOnChange(
    ['checkpointsQuery'],
    ({ checkpointsQuery }) => ({
      previousCheckpoint: checkpointsQuery.loading || checkpointsQuery.error ? undefined : head(checkpointsQuery.branchCheckpoints)
    })
  ),
  graphql(projectCreateCheckpointMutation, { name: 'projectCreateCheckpoint' }),
  connect(
    pipe(projectSelector, prop('project'), objOf('project')),
  ),
  withState('checkpointName', 'setCheckpointName', ({ previousCheckpoint }) => proposeNextCheckpoint(previousCheckpoint)),
  withState('navigateToCheckpoint', 'setNavigateToCheckpoint', true),
  withState('copyAudioResources', 'setCopyAudioResources', true),
  withHandlers({
    checkpointNameChanged: ({ setCheckpointName }) => ({ target: { value } }) => {
      setCheckpointName(value)
    },
    createCheckpoint: ({ currentBranchId, projectCreateCheckpoint, project: { _id: projectId }, navigateToCheckpoint, copyAudioResources, openRevision }) => async checkpointName => {
      const { data: { projectCreateCheckpoint: { _id: createdRevisionId } } } = await projectCreateCheckpoint({
        variables: {
          input: {
            projectId,
            checkpointName,
            sourceBranch: currentBranchId,
            copyAudioResources: !!copyAudioResources
          }
        },
        update: updateQueryValue({
          query: branchCheckpointsQuery,
          variables: { branchId: currentBranchId }
        },
        pushTransform(['branchCheckpoints'], 'projectCreateCheckpoint', true)
        )
      })

      if (navigateToCheckpoint) {
        try {
          await openRevision(createdRevisionId)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error loading revision', error)
          throw error
        }
      }
    }
  }),
  withHandlers({
    handleMakeCheckpoint
  })
)(MakeCheckpointModal)
