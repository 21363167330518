import { mapObjIndexed, pipe } from 'ramda'
import { objectsIndex } from '../../../selectors/apollo'
import { selectedNodeIdsIndex, selectionToLeftAndRight } from '../../../selectors/nodeSelection'
import { propFrom } from '../../../utils/object'
import OperationsRule from './OperationsRule'

/**
 * Given a context:
 *   - selections: what is trying being moved
 *   - a target: where it is hovering to be potentially dropped, anc contains
 *      - node
 *      - relationship: where this node is hooked at in thee graph
 *      = hoverPosition: where physically is being over like TOP-RIGHT, CENTER-LEFT, etc.
 *
 * This function determines which operation (if any) will be done if it is indeed dropped / applied.
 *
 * Internally it works like a rule engine so that we can easily and declarative specify
 * conditions and what operation to to in such a cases
 */
const computeTargetOperation = context => (dispatch, getState) => {
  const state = getState()
  const resolve = propFrom(objectsIndex(state))

  // generic constraint
  const selectedIds = selectedNodeIdsIndex(state)
  if (selectedIds[context.node.id]) { return undefined }

  // TODO: maybe avoid mutating the context ?
  context.resolve = resolve
  context.selections = context.selections.map(
    pipe(selectionToLeftAndRight, mapObjIndexed(resolve))
  )

  for (const rule of OperationsRule) {
    const result = rule.matches(context)
    if (result) {
      return result
    }
  }
  // no possible operation
  return undefined
}

export default computeTargetOperation
