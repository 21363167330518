import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, withProps, withPropsOnChange } from 'recompose'
import { Spin } from 'antd'
import { F, path } from 'ramda'
import { registerCopySource } from 'draftjs-conductor'

import sentry from 'services/sentry'

import Notifications from 'utils/Notifications'
import { getTimeAsString } from 'utils/dates'

import { isCurrentRevisionWritable } from 'security/project'
import secure from 'hocs/secure'

import Editor from 'draft-js-plugins-editor'
import { convertToRaw, EditorState } from 'draft-js'
import { model as bneModel } from 'beanie-engine-api-js'
import { CommandResult } from './draftjs-constants'

import { createIsNodeUnderSyncOutboundOperation } from 'selectors/sync'
import { selectedObject } from 'selectors/objects'
import { makeChoicesFromChoice } from 'selectors/paths'

import { commitChanges, performChecks, modelToModel } from 'engine/actions/texteditor'

import { modelToText } from './model-to-text'
import textToModel from './text-to-model'

import { blockRenderer, blockRenderMap } from './blockRenderer'
import blockStyleFn from './blockStyleFn'
import { denormalizing } from 'hocs/denormalizing'

import Plugins from './Plugins/Plugins'

import { syncSelectionWithNewContent } from './sync'

import { preference } from 'selectors/view'
import Preferences from 'preferences/Preferences'

import { getBlockForSelectionAnchor } from './draftjs-api'
import { setCursorToStart, setCursorToEnd } from './draftjs-mutations'

import colorStyleMap from './InlineStylesMap'

import styles from './TextEditor.scss'
import filterOnPaste from 'components/TextEditor/draftjs-paste-filters'

const { types: { object: { isChoice } } } = bneModel

export const isEditable = F

const returnModel = (object, model) => model
const defaultModelToModelAction = returnModel
const defaultPerformChecksActions = returnModel
const defaultCommitChangesAction = returnModel

export class TextEditor extends React.Component {
  editor = React.createRef()

  state = {
    readOnly: true,
    settingFocus: false
  }

  savePluginsDecorator() {
    if (!this.pluginDecorator) this.pluginDecorator = this.props.editorState.getDecorator()
  }

  /* eslint react/no-did-mount-set-state: 0, react/no-unused-state: 0 */
  componentDidMount() {
    const { object, provideController } = this.props
    this.copySource = registerCopySource(this.editor.current.editor)
    if (provideController) { provideController(this) }
    if (object) { this.setContent() }
    this.savePluginsDecorator()
  }

  /* eslint react/no-did-update-set-state: 0, react/no-unused-state: 0 */
  componentDidUpdate(prevProps, prevState) {
    const { object, visibleMarkups, editorState, cursorInfo } = this.props

    if (object !== prevProps.object) {
      const flushCursor = object && prevProps.object && object.id !== prevProps.object.id
      this.setContent(flushCursor)
      this.savePluginsDecorator()
      return;
    }
    if (visibleMarkups !== prevProps.visibleMarkups) {
      this.setContent()
      this.savePluginsDecorator()
      return;
    }

    // cursor / focus
    const cursorAt = path(['cursorInfo', 'cursorAt'])
    if (cursorAt(prevProps) !== object.id && cursorAt(this.props) === object.id) {
      // got focus from the outside. Set it and wait all the loop
      // once draft finished doing their s**t we will set the cursor (here below)
      this.setState({ settingFocus: true, cursorInfo })
      this.editor.current.focus()
    }
    if (prevState.settingFocus && !this.state.settingFocus) {
      // focus was set, now recover the cursor that we wanted to set
      const mutator = (cursorInfo.direction === 'down' ? setCursorToStart : setCursorToEnd)
      const newState = mutator(editorState, cursorInfo.lineOffset)
      this.doSetEditorState(newState, () => {
        this.editor.current.restoreEditorDOM()
      })
      this.savePluginsDecorator()
      return
    }

    this.savePluginsDecorator()
  }

  componentWillUnmount() {
    this.editor = null
    if (this.copySource) {
      this.copySource.unregister()
    }
  }

  // common between didMount/didUpdate
  setContent(flashCursor = false) {
    const { object, applyingChanges, editorState } = this.props

    const newStateRaw = modelToText(object, this.props)
    let newState = EditorState.createWithContent(newStateRaw.getCurrentContent(), this.pluginDecorator)

    if (!flashCursor && applyingChanges) {
      // keep the selection
      const oldContent = editorState.getCurrentContent()
      const selection = editorState.getSelection()
      const updatedSelection = syncSelectionWithNewContent(selection, oldContent, newState.getCurrentContent())
      newState = EditorState.forceSelection(newState, updatedSelection)
    }
    this.doSetEditorState(newState)
  }

  onChange = nextEditorState => {
    const { settingFocus } = this.state
    const { editorState } = this.props
    const filteredEditorState = filterOnPaste(editorState, nextEditorState)
    const callback = settingFocus ? () => { this.setState({ settingFocus: false }) } : undefined
    this.doSetEditorState(filteredEditorState, callback)
  }

  doSetEditorState = (editorState, callback) => {
    const { editorState: currentEditorState, setEditorState } = this.props

    const currentContent = currentEditorState.getCurrentContent()
    const newContent = editorState.getCurrentContent()

    const dirty = !!editorState.getLastChangeType() && currentContent !== newContent

    setEditorState(editorState, dirty, callback)
    this.pluginDecorator = editorState.getDecorator()
  }

  // events

  onSave = () => {
    const { dirty, setDirty } = this.props
    if (dirty) {
      this.commitChanges()
      setDirty(false)
    }
  }

  onFocus() {
    if (this.editor && this.editor.current) {
      this.editor.current.focus()
    }
  }

  commitChanges() {
    const { object, editorState, setApplyingChanges, commitChangesAction = defaultCommitChangesAction, performChecksAction = defaultPerformChecksActions, modelToModelAction = defaultModelToModelAction } = this.props

    setApplyingChanges(true, async () => {
      try {
        const model = textToModel(object, convertToRaw(editorState.getCurrentContent()))
        const transformedModel = modelToModelAction(object, model)
        const checkedModel = await performChecksAction(object, transformedModel)
        await commitChangesAction(object, checkedModel)
        this.onCommitFinished()
      } catch (error) {
        this.onCommitError(error)
      }
    })
  }
  onCommitFinished = () => { this.props.setApplyingChanges(false) }
  onCommitError = error => {
    /* eslint no-console: 0 */
    console.error('>>>> COMMIT ERROR !', error)
    Notifications.notify({
      type: 'error',
      message: 'Oops',
      description: (
        <div>
          There was an error saving and we had to rollback your changes :( <br />
          {error.message}
        </div>
      ),
      key: getTimeAsString()
    })
    this.setContent(true)
    this.onCommitFinished()
  }

  componentDidCatch(error, errorInfo) {
    this.onCommitError(error)
    sentry.handleError(error, errorInfo)
  }

  shouldSpin() {
    const { isUnderSyncOperation, applyingChanges, isReadOnly } = this.props
    return applyingChanges || (!isReadOnly && isUnderSyncOperation)
  }

  blockRendererdFn = block => blockRenderer(block, this.props.readOnly)

  onArrow = direction => (event, { getEditorState }) => {
    const { moveToNextEditor, moveToPreviousEditor } = this.props
    const editorState = getEditorState()
    const selection = editorState.getSelection()

    const handler = direction === 'down' ? moveToNextEditor : moveToPreviousEditor

    if (handler && selection.isCollapsed()) {
      const block = getBlockForSelectionAnchor(editorState)
      const borderBlock = editorState.getCurrentContent()[direction === 'down' ? 'getLastBlock' : 'getFirstBlock']()
      if (block === borderBlock) {
        const furtherText = direction === 'down' ?
          block.getText().slice(selection.getAnchorOffset())
          : block.getText().slice(0, selection.getAnchorOffset())

        const isAtTheBorderLine = furtherText.indexOf('\n') < 0
        if (isAtTheBorderLine) {
          handler(selection.getAnchorOffset())
          return CommandResult.handled
        }
      }
    }
    return CommandResult.notHandled
  }

  onDownArrow = this.onArrow('down')
  onUpArrow = this.onArrow('up')

  render() {
    const { editorState, isUnderSyncOperation, object, isReadOnly } = this.props
    const readOnly = isReadOnly || isUnderSyncOperation

    return (
      <div className={styles.textEditor}>
        <Spin spinning={this.shouldSpin()} tip="Saving...">
          <Plugins object={object}>
            {plugins => (
              <Editor
                key="editor"

                customStyleMap={colorStyleMap}
                editorState={editorState}
                onChange={this.onChange}

                spellCheck
                ref={this.editor}
                readOnly={readOnly}

                blockRendererFn={this.blockRendererdFn}
                blockRenderMap={blockRenderMap}
                blockStyleFn={blockStyleFn}

                onUpArrow={this.onUpArrow}
                onDownArrow={this.onDownArrow}

                plugins={plugins}

                {...!readOnly && {
                  handleDrop: this.onDrop,
                  onSave: this.onSave,
                  onBlur: this.onSave
                }}
              />
            )}
          </Plugins>
        </Spin>
      </div>
    )
  }

  // avoid screwing up lines by not copying metadata (not doing anything)
  onDrop = () => CommandResult.handled

}

// migrating component inner code
const commonHOCs = [
  withState('applyingChanges', 'setApplyingChanges', false),
  withProps(({ object, hasWriteAccess, applyingChanges }) => ({
    isReadOnly: (object && !isEditable(object.sys)) || applyingChanges || !hasWriteAccess
  })),
  withState('editorState', 'setEditorState', () => EditorState.createEmpty()),
  withState('dirty', 'setDirty', false),
  withHandlers({
    setEditorState: ({ setEditorState, setDirty, dirty: wasDirty }) => (editorState, dirty = true, callback) => {
      setEditorState(editorState, () => {
        if (!wasDirty && dirty) setDirty(dirty)
        if (callback) callback()
      })
    }
  })
]

export const BaseTextEditor = compose(...commonHOCs)(TextEditor)

const Connected = compose(
  connect(() => {
    const choicesFromChoice = makeChoicesFromChoice((_, { selectedObj, object }) => (object || selectedObj).id)
    const isNodeUnderSyncOperation = createIsNodeUnderSyncOutboundOperation()
    const visibleMarkupsSelector = preference(Preferences.TextEditor.visibleMarkups)
    return (state, props) => ({
      isUnderSyncOperation: isNodeUnderSyncOperation(state, props),
      visibleMarkups: visibleMarkupsSelector(state),
      choicesFromChoice: choicesFromChoice(state, props)
    })
  }, {
    commitChangesAction: commitChanges,
    modelToModelAction: modelToModel,
    performChecksAction: performChecks
  }),
  withPropsOnChange(['object', 'choicesFromChoice', 'selectedObj'],
    ({ object, choicesFromChoice, selectedObj }) => {
      const obj = object || selectedObj
      return ({
        object: isChoice(obj) && choicesFromChoice ? choicesFromChoice : obj
      })
    }),
  denormalizing({
    prop: 'object',
    recurse: true,
  }),
  secure('hasWriteAccess', isCurrentRevisionWritable),
  ...commonHOCs
)(TextEditor)
export default Connected

// current node text editor (initial functionality)
export const TextEditorForSelectedNode = connect(state => ({
  selectedObj: selectedObject(state),
}))(Connected)
