import { drop } from 'ramda'
import { Text, Inline } from 'slate'

import MatchSourceType from '../../../../../model/project/searches/collectors/match/sources/MatchSourceType'
import {
  getNoteIndex,
  isNoteMatchResultSource
} from '../../../../../model/project/searches/collectors/match/MatchSourcesBySys'
import { isNoteInline } from '../../../utils/Constants'
import { getTextNodes } from '../../../utils/nodeUtils'
import createDecorationsForTextMatches from './createDecorationsForTextMatches'
import createLineHighlightDecoration from './createLineHighlightDecoration'

/**
 * Given a list of `SearchMatchResult` for a line and its Slate node it creates decorations for all of the results.
 * This involves showing matches within the text but also within the inlines like notes.
 */
const createSearchHighlightDecorationsForLineText = nodeGetter => (editor, lineResults, node, lineId) => {
  const nodePath = editor.pathByKey(node)
  const { nodes } = nodeGetter(node)

  // precomputed to avoid filtering for every "result"
  const context = {
    textNodes: nodes.filter(n => Text.isText(n)),
    inlineNodes: nodes.filter(n => Inline.isInline(n))
  }

  const decorations = []

  // for each match elements we try to create a decoration
  lineResults.forEach((lineResult, i) => {

    const createDecoration = (textNode, start, end) => {
      const { key } = textNode
      const relativePath = drop(nodePath.size, editor.pathByKey(key))

      decorations.push(createLineHighlightDecoration(
        lineId,
        i,
        relativePath.toJS(),
        key,
        start,
        end,
      ))
    }

    // process it
    processMatchResultElement(lineResult, createDecoration, nodePath, editor, context)
  })

  return decorations
}

export default createSearchHighlightDecorationsForLineText

const processMatchResultElement = (result, appendDecoration, nodePath, editor, context) => {
  switch (result.source.type) {
    case MatchSourceType.label: createDecorationsForTextMatches(result, appendDecoration, context.textNodes); break;
    case MatchSourceType.derived: {
      // we only support notes for the moment
      if (isNoteMatchResultSource(result.source)) {
        processNoteMatchResultElement(result, appendDecoration, context)
        break;
      }
      // NOTE: here we could implement support for other sources
    }
    default: {
      // eslint-disable-next-line no-console
      console.log('Unknown search result type for a clip line match', result)
    }
  }
}

const processNoteMatchResultElement = (result, appendDecoration, { inlineNodes }) => {
  const noteIndex = getNoteIndex(result)

  // TODO: we are assuming the Slate Inlines length and order is the same as the notes in the line/lang_res
  //   this is a weak impl. We must instead have a strong binding using "data" within the Slate Inline, for example
  //   with its index in the model. Then find here the one by index. FQN's were a first attempt to start modelling a binding
  //   between Slate Nodes and the domain model.
  const matchedInline = inlineNodes.filter(isNoteInline).get(noteIndex)

  // +1 is to adjust decoration offset because of the starting delimiter which is present in the editor data model (slate)
  // but not in the domain model (search result + bne objects)
  createDecorationsForTextMatches(result, appendDecoration, getTextNodes(matchedInline), +1)
}
