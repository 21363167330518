import React from 'react'
import { Provider } from 'react-redux'

import { ADD_LANE } from 'commands/edition'
import useSecureCheck from '../../../hooks/security/useSecureCheck'

import MenuCommandGroup from '../MenuGroupCommand'
import MenuItemCommand from '../MenuItemCommand'

import RevisionExtensions from 'providers/RevisionExtensions'
import { isCurrentRevisionWritable } from 'security/project'

import { Menu } from '@blueprintjs/core'

/**
 *
 */
const TreeViewContextMenu = ({ store }) => {
  const hasWriteAccess = useSecureCheck(isCurrentRevisionWritable)
  return (
    hasWriteAccess ? <Provider store={store} >
      <RevisionExtensions>
        <Menu>
          <MenuCommandGroup>
            <MenuItemCommand key={ADD_LANE.label} command={ADD_LANE} />
          </MenuCommandGroup>
        </Menu>
      </RevisionExtensions>
    </Provider> : null
  )
}

export default TreeViewContextMenu