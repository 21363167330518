import { objectsIndex } from 'selectors/apollo'

export default class ReduxDataSource {
  constructor(getState) {
    this.index = objectsIndex
    this.getState = getState
  }

  find = id => this.index(this.getState())[id]

}