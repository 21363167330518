import React from 'react'

import styles from './cursorDebug.scss'
import { noop } from 'utils/functions'

export default () => {
  let updateSelection = noop
  const updateIt = editorState => {
    updateSelection({
      selection: {
        start: editorState.getSelection().getStartOffset(),
        end: editorState.getSelection().getEndOffset(),
        startKey: editorState.getSelection().getStartKey(),
        endKey: editorState.getSelection().getEndKey()
      }
    })
  }

  const instance = ({
    onChange: editorState => {
      updateIt(editorState)
      return editorState
    },
    registerComponent: c => { updateSelection = ::c.setState },
    unregisterComponent: () => { updateSelection = undefined },
    renderExtraComponent: key => (<CursorDebugFeedback key={key} source={instance} />)
  })

  return instance
}

class CursorDebugFeedback extends React.Component {
  state = {}
  componentDidMount() { this.props.source.registerComponent(this) }
  componenteWillUnmount() { this.props.source.unregisterComponent() }
  componentDidUpdate(prevProps, prevState) {
    // received an event, queue to remove it after 1 second
    if (prevState.event !== this.state.event && this.state.event) {
      setTimeout(() => { this.setState({ event: undefined }) }, 1000)
    }
  }
  render() {
    const { selection } = this.state
    return selection ? (
      <div className={styles.control}>
        [{selection.startKey}:{selection.start}]&rarr;[{selection.startKey}:{selection.end}]
      </div>
    ) : null
  }
}