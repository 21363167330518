import memoize from 'memoize-state'
import { model, parseRef, lang } from 'beanie-engine-api-js'
import { CHECK_RESULT_STATUS, OK_RESULT, CHECK_SEVERITY_LEVEL } from './CheckSelectors'
import { isUUID } from 'utils/string'
import { makeExpressionCheckSelector } from './rule'

const { types: { object: { Paths, isDisabled } } } = model
const { rule: { typing: { types } } } = lang

export const makeNameWithDisabledReferenceCheck = ({ id }) => {
  const mSelector = memoize((objectIndex) => {
    const { data: { name } } = objectIndex[id]

    if (!isUUID(name)) return OK_RESULT

    const nameRefId = parseRef(name)
    return isDisabled(objectIndex[nameRefId]) ?
      {
        status: CHECK_RESULT_STATUS.ERROR,
        message: `The node object with id ${nameRefId} is disabled`,
        severity: CHECK_SEVERITY_LEVEL.ERROR,
        id: 'With disabled name node'
      } :
      OK_RESULT
  })

  const selector = ({ objectIndex }) => mSelector(objectIndex)

  selector.memoizingSelector = mSelector
  return selector
}

export const makeEnabledRuleErrorCheck =
  makeExpressionCheckSelector(Paths.object.enabled_rule, 'Enabled rule', types.Bool)

export const makeOnEnterErrorCheck =
  makeExpressionCheckSelector(Paths.node.on_enter, 'On-enter trigger', types.Void, ['effect'])

export const makeOnLeaveErrorCheck =
  makeExpressionCheckSelector(Paths.node.on_leave, 'On-leave trigger', types.Void, ['effect'])

export default [
  {
    name: 'Enabled rule error',
    makeSelector: makeEnabledRuleErrorCheck
  },
  {
    name: 'On enter trigger',
    makeSelector: makeOnEnterErrorCheck
  },
  {
    name: 'On leave trigger',
    makeSelector: makeOnLeaveErrorCheck
  }
]