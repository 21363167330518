import React from 'react'
import { Icon } from 'antd'
import { Sys } from 'beanie-engine-api-js'
import { getProviderFor } from 'model/label'
import LinkButton from 'components/Commons/LinkButton'

import styles from './ReferencePlayback.scss'

export default class ReferencePlayback extends React.Component {
  state = { opened: true }
  render() {
    const { opened } = this.state
    const { playingObject } = this.props

    return (
      <div className={styles.container}>
        <div className={styles.node}>
          <Icon type={getProviderFor(Sys.reference).icon} />
          <span className={styles.name}>
            {playingObject.data.name}
          </span>
          opened
          <LinkButton onClick={this.onClick}>
            {playingObject.data.url}
          </LinkButton>
        </div>
        {!opened &&
          <div className={styles.blockedWarning}>
            ( <Icon type="warning" /> Your browser blocked the popup ! )
          </div>
        }
      </div>
    )
  }

  componentDidMount = () => {
    const { onContentAdded } = this.props
    onContentAdded()
    this.onClick()
  }

  onClick = () => {
    const { playingObject } = this.props 
    if (!playingObject.data.url) {
      return;
    }
    const target = playingObject.data.target || '_blank'
    const win = window.open(playingObject.data.url, target)
    this.setState({ opened: !!win })
  }

}