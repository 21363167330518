import { any, match, pipe, prop, split } from 'ramda'

const FORM_NAME = 'FormException'

export const isFormException = e => e.name === FORM_NAME
export class FormException extends Error {
  constructor(message) {
    super(message)
    this.name = FORM_NAME
  }
}

export const isJsonParseError = pipe(prop('stack'), split('\n'), any(match(/JSON.parse/)))