import { ANALYSIS_MODES } from 'providers/Checker/CheckerMode'
import { INITIAL_CHECKS_CONFIG } from 'providers/Checker/CheckSelectors/CheckSelectors'

export const PREF_SHOW_STORYBOARD_AND_HISTORY = 'storyboardAndHistory'
export const PREF_SHOW_STORYBOARD = 'storyboard'
export const PREF_SHOW_HISTORY = 'history'

export const ALL = 'ALL'

export const PreferenceScope = {
  Project: 'project',
}

const Preferences = {
  TextEditor: {
    trackSelection: {
      name: 'TextEditor.trackSelection',
      initial: true
    },
    showStoryboards: {
      name: 'TextEditor.showStoryboards',
      initial: false
    },
    visibleMarkups: {
      name: 'TextEditor.visibleMarkups',
      scope: PreferenceScope.Project,
      initial: ALL,
    },
    visibleNotes: {
      name: 'TextEditor.visibleNotes',
      scope: PreferenceScope.Project,
      initial: ALL,
    }
  },
  ExprEditor: {
    linterEnabled: {
      name: 'ExprEditor.linterEnabled',
      initial: false,
    }
  },
  GraphView: {
    trackSelection: {
      name: 'GraphView.trackSelection',
      initial: true
    },
    trackExecution: {
      name: 'GraphView.trackExecution',
      initial: false
    }
  },
  Playback: {
    currentPlaybackVisible: {
      name: 'Playback.currentPlaybackVisible',
      initial: true
    },
    showStoryboardAndHistory: {
      name: 'Playback.showStoryboardAndHistory',
      initial: PREF_SHOW_STORYBOARD_AND_HISTORY,
      values: [PREF_SHOW_STORYBOARD_AND_HISTORY, PREF_SHOW_STORYBOARD, PREF_SHOW_HISTORY]
    },
    lockScroll: {
      name: 'Playback.lockScroll',
      initial: false,
    },
    playDirectorComments: {
      name: 'Playback.playDirectorComments',
      scope: PreferenceScope.Project,
      initial: true
    },
    errorOnMissingAudio: {
      name: 'Playback.errorOnMissingAudio',
      scope: PreferenceScope.Project,
      initial: false
    },
    playDirtyAudio: {
      name: 'Playback.playDirtyAudio',
      scope: PreferenceScope.Project,
      initial: false
    },
    showExecutionCountVariables: {
      name: 'Playback.showExecutionCountVariables',
      scope: PreferenceScope.Project,
      initial: true
    },
    gamePadEnabled: {
      name: 'Playback.gamePadEnabled',
      initial: false,
    }
  },
  StaticAnalysis: {
    analysisMode: {
      name: 'StaticAnalysis.analysisMode',
      initial: ANALYSIS_MODES.MANUAL,
      values: [ANALYSIS_MODES.AUTO, ANALYSIS_MODES.MANUAL]
    },
    enabledChecks: {
      name: 'StaticAnalysis.enabledChecks',
      initial: INITIAL_CHECKS_CONFIG(),
    }
  },
  ProjectLoading: {
    projectDataCache: {
      scope: PreferenceScope.Project,
      name: 'ProjectLoading.projectDataCache',
      initial: false
    }
  }
}

export default Preferences