import { NormalizationError } from './constants'
import { convertInlineNodeInText } from './utils'

export const productionNormalize = (editor, { code, node }) => {
 
  if (code === NormalizationError.CHILD_TEXT_INVALID) {
    if (!editor.isVisible(node)) {
      editor.removeNodeByKey(node.key)
    } else {
      convertInlineNodeInText(editor, node)
    }
  }

}