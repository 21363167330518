import { Sys, parseRef, model, lang } from 'beanie-engine-api-js'
import { identity } from 'ramda'

import { canPasteChoicesAs } from '../../selectors/clipboard'
import createFromNodeInto from './createFromNodeInto'
import { toBNEAction } from './utils'
import { selectedObject } from 'selectors/objects'
import { nodeFromClipboard } from 'utils/clipboard'

const { types: { object: { isSomeChoices } } } = model
const { rule: { parser, utils: { makeSyntheticMatch } } } = lang

const rule = (source, transform = identity) => ({
  source,
  rule: transform(parser(source))
})

export const lastChosenFrom = id => rule(`last_chosen "${id}"`)
export const syntheticMatch = id => rule(`"${id}"`, makeSyntheticMatch)

export const createTruthTableFromChoices = choicesId => api => {
  const choicesLua = api.obj.get(choicesId)
  const truthTable = api.createObject({ sys: Sys.truth_table })

  truthTable.update_header(1, lastChosenFrom(choicesId))

  choicesLua.container_get_nodes().toJSONObject()
    .forEach((choiceRef, i) => {
      const choiceId = parseRef(choiceRef)
      const row = api.createObject({ sys: Sys.truth_table_row })
      row.add_cell(1, syntheticMatch(choiceId))

      truthTable.add_row(row.get_id(), i + 1)
    })

  const otherwiseRow = api.createObject({ sys: Sys.truth_table_otherwise_row })
  truthTable.set_otherwise(otherwiseRow)

  return truthTable
}

export const truthTableFromChoicesAction = toBNEAction(
  createFromNodeInto(createTruthTableFromChoices),
  'Truth Table from Choices'
)

// REVIEWME: this one is still coupled with inserting into a children
export const pasteAsTruthTableFromChoices = afterNode => async (dispatch, getState, { synchronizer }) => {
  if (!canPasteChoicesAs(getState())) {
    return
  }
  // canPasteChoicesAs already did all the checks. This is safe
  const choices = await nodeFromClipboard(isSomeChoices)
  if (!choices) return
  synchronizer.doSynchingBNE(
    'Paste as Truth Table from choices',
    createFromNodeInto(createTruthTableFromChoices)(choices.id, afterNode.id),
    afterNode
  )
}

export const pasteAsTruthTableFromChoicesAction = () => (dispatch, getState, args) =>
  pasteAsTruthTableFromChoices(selectedObject(getState()))(dispatch, getState, args)