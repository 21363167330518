import React from 'react'
import ReactDOM from 'react-dom'
import { Icon } from 'antd'
import uuid from 'uuid/v4'
import { iconTypeByCompletion } from './Extensions/Completion'

export const completionId = ({ label, type, detail }) => `${label}-${detail}-${type}-${uuid()}`

export const autocompleteIcon = registerAutocompletion => completion => {
  const containerId = completionId(completion)
  const container = document.createElement('div')
  container.classList.add('cm-completionIcon')
  container.setAttribute('id', containerId)
  registerAutocompletion(containerId, { completion, containerId })
  return container
}

export const AutocompleteIcon = ({ completion, containerId }) => {
  const container = document.getElementById(containerId)
  if (!container) return null

  return ReactDOM.createPortal(<Icon type={iconTypeByCompletion(completion)} />, container)
}
