import React from 'react'
import { Menu } from 'antd'

import { EMPTY_ARRAY } from 'utils/object'

import SetStoryboardModal from 'components/SetStoryboardModal/SetStoryboardModal'
import { ModalConsumer } from 'components/Commons/Modal'
import IconButton from 'components/Commons/IconButton'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'
import { EXTENSION_POINTS } from 'providers/Extensions/ExtensionPoint'

import styles from '../../../SlateTextEditor.scss'

const LineMenuContent = ({ lineId, onStoryboardChanged, onItemSelected, readOnly, onDelete }) => (
  <Menu onClick={onItemSelected} selectedKeys={EMPTY_ARRAY}>
    {!readOnly &&
      <Menu.Item>
        <ModalConsumer>
          {({ showModal }) => (
            <React.Fragment>
              <IconButton type="picture" onClick={() => { showModal(SetStoryboardModal, { onFileProvided: onStoryboardChanged }) }} />
              Set Storyboard
            </React.Fragment>
          )}
        </ModalConsumer>
      </Menu.Item>
    }
    <Menu.Item disabled={readOnly}>
      <IconButton type="delete" onClick={onDelete} />
      Delete line
    </Menu.Item>
    <ExtensionPoint
      className={styles.ExtensionPoint}
      point={EXTENSION_POINTS.LINE_HAMBURGER_MENU}
      renderExtension={({ id, label, action: extensionAction, icon }, { moreProps, extAsBneAction }) => (
        <Menu.Item key={id} disabled={readOnly} {...moreProps}>
          <IconButton type={icon} onClick={extAsBneAction(extensionAction, lineId)} />
          {label}
        </Menu.Item>
      )}
    />
  </Menu>
)

export default LineMenuContent