import { compose } from 'recompose'

import paginatedQuery from 'hocs/paginatedQuery'
import query from 'api/queries/organizations.graphql'

import OrganizationListPage from './OrganizationListPage'

export default compose(
  paginatedQuery({
    query,
    name: 'organizations',
    pageSize: 10,
  })
)(OrganizationListPage)