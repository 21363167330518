import { message } from 'antd'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { dispatchCommand } from '../actions/commands'

/**
 * Hook to create a callback to invoke a "command".
 * It internally uses the `dispatchCommand` thunk so it will fill args
 * from the ones you pass or from the state through selectors if the command
 * declares how to get them with "params".
 * Example usage
 *
 * const MyButton = () => {
 *   const downloadPDF = useCommand(DOWNLOAD_PDF)
 *
 *   return <a onClick={downloadPDF}>Download</a>
 * }
 *
 */
const useCommand = command => {
  const dispatch = useDispatch()

  return useCallback(async (...args) => {
    try {
      await dispatch(dispatchCommand(command, undefined, ...args))
    } catch (error) {
      message.error(`Oops, something went wrong! ${error.message}`)
      // eslint-disable-next-line no-console
      console.error('Error executing command', command, 'error is', error.message, `${error.stack}`)
    }
  }, [])
}

export default useCommand