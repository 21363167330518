import { sequence } from 'utils/promises'

/**
 * Like an txjs Subject but notification triggering are done
 * sequentially (same so far), but waiting for any promise there
 * before continuing to the next "next()"
 */
class WaitingSubject {

  constructor() {
    this.observers = []

    const subscribe = observer => {
      this.observers.push(observer)
      return () => {
        this.observers.splice(this.observers.indexOf(observer), 1)
      }
    }

    this.observable = { subscribe }
  }

  asObservable() { return this.observable }

  // we don't support the object passing version yet


  async next(value) {
    await sequence(this.observers.map(next => () => next(value)))
  }

}

export default WaitingSubject