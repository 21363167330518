import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Icon } from 'antd'
import { navigateToOrganization } from 'actions/routing'
import LinkButton from 'components/Commons/LinkButton'

import styles from './TeamBreadcrumb.scss'

const TeamBreadcrumb = ({ organizationName, teamName, goToOrg }) => (
  <h1 className={styles.breadcrumb}>
    <Breadcrumb>
      <Breadcrumb.Item>
        <Icon type="bank" />
        <LinkButton onClick={() => goToOrg(organizationName)}>{organizationName}</LinkButton>
      </Breadcrumb.Item>
      {teamName &&
        <Breadcrumb.Item>
          <Icon type="team" />
          <span>{teamName}</span>
        </Breadcrumb.Item>
      }
    </Breadcrumb>
  </h1>
)

export default connect(null, {
  goToOrg: navigateToOrganization
})(TeamBreadcrumb)