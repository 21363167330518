exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameSection__8WPP8{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}.nameSection__8WPP8>:nth-child(3){-ms-flex-positive:.9;flex-grow:.9}.nameSection__8WPP8>:nth-child(2) .ant-form-item-children>div>div:nth-child(1){margin-top:4px;height:32px}.nameSection__8WPP8>:nth-child(2) .ant-form-item-children>div>div:nth-child(1)>div{height:100%}", ""]);

// exports
exports.locals = {
	"nameSection": "nameSection__8WPP8"
};