import { indexBy, isNil, prop, propEq } from 'ramda'
import { propFrom } from '../../utils/object'
import parser from 'ua-parser-js'


/**
 * Identifies the type of client that connects to the Studio Platform.
 * Although the Sessions API allows any string here when if want to customise the experience
 * and integration between the frontend and other types of client apps then we need to be aware
 * and define specific values here.
 * Think of it like the "User-Agent" field for the web.
 */
const SessionClientType = {
  StudioFrontend: { code: 'STUDIO_FRONTEND', label: 'Studio' },
  Unreal: { code: 'STUDIO_UNREAL', label: 'Unreal' },
}

const SessionClientTypeByCode = indexBy(prop('code'), Object.values(SessionClientType))

export const fromCode = propFrom(SessionClientTypeByCode)

export const isUnrealSession = propEq('clientType', SessionClientType.Unreal.code)

export const isSessionFromUs = (user, session) => user && user._id === session.user._id

/**
 * Given a session it returns a string that simplifies the origin of that session.
 * For example for frontend sessions it tells the name of the browser
 * Or "Unreal" for unreal sessions
 */
export const origin = session => {
  if (isNil(session.clientType)) return undefined

  switch (session.clientType) {
    case SessionClientType.StudioFrontend.code: {
      if (isNil(session.userAgent)) return undefined
      const ua = parser(session.userAgent)
      return ua?.browser.name
    }
    case SessionClientType.Unreal.code: return 'Unreal'
    default: return 'Unknown'
  }
}

export const sessionTypeToLabel = type => SessionClientTypeByCode[type]?.label || type

export default SessionClientType