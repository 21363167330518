import { NODE_TYPES, OBJECT_TYPES } from '../Constants'
import { EMPTY_ARRAY } from 'utils/object'

export const dialogueLine = (actorName, dialogueText, line_id = undefined) => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.DIALOGUE_LINE,
  data: { line_id },
  nodes: [
    {
      object: OBJECT_TYPES.block,
      type: NODE_TYPES.ACTOR_PART,
      nodes: [
        {
          object: OBJECT_TYPES.text,
          text: actorName,
          marks: EMPTY_ARRAY
        }
      ]
    },
    dialogueLineTextPartWithText(dialogueText)
  ]
})

export const customDialogueLine = (customActorName, customText, line_id = undefined) => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.DIALOGUE_LINE,
  ...line_id && { data: { line_id } },
  nodes: [customActorName, customText]
})

export const dialogueLineTextPartWithText = text => dialogueLineTextPart([{
  object: OBJECT_TYPES.text,
  text,
  marks: EMPTY_ARRAY
}])
export const dialogueLineTextPart = nodes => ({
  object: OBJECT_TYPES.block,
  type: NODE_TYPES.TEXT_PART,
  nodes
})