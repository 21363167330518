import React from 'react'

import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { noop } from 'utils/functions'
import { EMPTY_OBJECT } from 'utils/object'

import CreateModal from 'components/Commons/CreateModal'
import deleteOrganizationMutation from 'api/mutations/deleteOrganization.graphql'

const DeleteOrganizationModal = ({ onDelete, hide, organization }) => (
  <CreateModal
    title={`Delete Organization "${organization.name}" ?`}
    titleIcon="warning"
    hide={hide}
    submit={onDelete}
    canSubmit
  >
    {() => (<p>Are you sure ? If you delete it you will lose all the content</p>)}
  </CreateModal>
)

export default compose(
  graphql(deleteOrganizationMutation, { name: 'deleteOrganization' }),
  withHandlers({
    onDelete: ({ deleteOrganization, organization, onDeleted = noop, onDeleteUpdate }) => async () => {
      await deleteOrganization({
        variables: { 
          id: organization._id },
        ...(onDeleteUpdate ? { update: onDeleteUpdate } : EMPTY_OBJECT)
      })
      onDeleted()
    }
  })
)(DeleteOrganizationModal)
