import { createReducer } from 'reduxsauce'
import { assoc, pipe, always } from 'ramda'

import { Types as WalkListActions } from 'actions/walklist'
import { LOGOUT } from 'actions/login'
import { isRevisionChangedAction, isRevisionClearedAction } from 'actions/project'
import { CLEAR_ACTION, SELECT_ACTION } from 'actions/selection'

const {
  SET_CURRENT_INDEX,
  CLOSE,

  START_WALKING,
} = WalkListActions

const INITIAL_STATE = {
  list: undefined, // WalkList
  current: -1
}

const setCurrentIndex = (state, { current }) => assoc('current', current)(state)

const startWalking = (state, { walkList }) => pipe(
  assoc('list', walkList),
  s => setCurrentIndex(s, { current: 0 })
)(state)

const close = always(INITIAL_STATE)

const projectChangedOrCleared = (state, action) =>
  (isRevisionChangedAction(action) || isRevisionClearedAction(action) ? INITIAL_STATE : state)

export const walklist = createReducer(INITIAL_STATE, {
  // RESET if project changes!!! hack but well :(
  [LOGOUT]: always(INITIAL_STATE),
  [SELECT_ACTION]: projectChangedOrCleared,
  [CLEAR_ACTION]: projectChangedOrCleared,

  // lifecycle
  [START_WALKING]: startWalking,
  [CLOSE]: close,

  // navigation
  [SET_CURRENT_INDEX]: setCurrentIndex,
})