exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editorContainer__2Ql8Y{display:-ms-flexbox;display:flex;border:none;width:100%;height:100%;padding-left:.5rem;padding-right:.5rem}.editorContainer__2Ql8Y .editor__2oGUW{display:-ms-flexbox;display:flex;border:none;width:100%;height:100%;font-size:small;color:#7c7874;border-color:#d3d3d3;border-radius:5px}.editorContainer__2Ql8Y .editor__2oGUW .CodeMirror{border-radius:5px;height:15rem}.editorContainer__2Ql8Y .editor__2oGUW .cm-tooltip-autocomplete{display:contents !important}.editorContainer__2Ql8Y .editor__2oGUW .cm-tooltip-hover{display:contents}", ""]);

// exports
exports.locals = {
	"editorContainer": "editorContainer__2Ql8Y",
	"editor": "editor__2oGUW"
};