import { append } from 'ramda'
import React from 'react'
import './ReactConsole.scss'
import ConsoleMessage from './ConsoleMessage'
import ConsolePrompt from './ConsolePrompt'

const TODO = () => {
  // TODO
}

// interface ConsolePromptProps {
//   point?: number;
//   value: string;
//   label: string;
//   argument?: string;
// }

/**
 * NOTE: we copied this from a library (https://github.com/astralarya/react-console)
 * They never updated the lib so it seems abandoned.
 * And it had a "react-key" error we wanted to fix.
 * Plus it might be interesting to implement some small features here for sake of usability.
 * This code is completely a mess. I fixed eslint errors but haven't really refactor it.
 * We should do it incrementally. Eventually migrating to use hooks and functional compponents.
 * Design the code better splitting git since it has a lot of lines !
 */


// // export interface LogMessage {
// //   type?: string;
// //   value: any[];
// // }
// // export interface LogEntry {
// //   label: string;
// //   command: string;
// //   message: LogMessage[];
// // }
//
// export interface ConsoleProps{
//   handler: (command: string)=>any;
//   cancel?: ()=>any;
//   complete?: (words: string[], curr: number, promptText: string)=>string[];
//   continue?: (promptText: string)=>boolean;
//   autofocus?: boolean;
//   promptLabel?: string | (()=>string);
//   welcomeMessage?: string;
// }
export const ConsoleCommand = {
  Default: 'Default',
  Search: 'Search',
  Kill: 'Kill',
  Yank: 'Yank',
}
export const SearchDirection = {
  Reverse: 'Reverse',
  Forward: 'Forward',
}
// export interface ConsoleState{
//   focus?: boolean;
//   acceptInput?: boolean;
//   typer?: string;
//   point?: number;
//   currLabel?: string;
//   promptText?: string;
//   restoreText?: string;
//   searchText?: string;
//   searchDirection?: SearchDirection;
//   searchInit?: boolean;
//   log?: LogEntry[];
//   history?: string[];
//   historyn?: number;
//   kill?: string[];
//   killn?: number;
//   argument?: string;
//   lastCommand?: ConsoleCommand;
// };
export default class extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      focus: false,
      acceptInput: true,
      typer: '',
      point: 0,
      currLabel: this.nextLabel(),
      promptText: '',
      restoreText: '',
      searchText: '',
      searchDirection: null,
      searchInit: false,
      log: [],
      history: [],
      historyn: 0,
      kill: [],
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Default,
    }

    this.keyHandler = createKeyHandler(this)
  }
  static defaultProps = {
    promptLabel: '> ',
    continue: () => false,
    cancel: () => {},
  }
  // {
  //   typer?: HTMLTextAreaElement;
  //   container?: HTMLElement;
  //   focus?: HTMLElement;
  // }
  child = {}

  //
  // Command API
  //

  log = (...messages) => {
    this.logX(null, ...messages)
  }
  logX = (type, ...messages) => {
    const { log } = this.state
    log[log.length - 1].message.push({ value: messages })
    this.setState({ log }, this.scrollIfBottom())
  }
  return = () => {
    this.setState({
      acceptInput: true,
      currLabel: this.nextLabel(),
    }, this.scrollIfBottom())
  }
  // Component Lifecycle
  componentDidMount() {
    if (this.props.autofocus) { this.focus() }
  }
  // Event Handlers
  focus = () => {
    if (!window.getSelection().toString()) {
      this.child.typer.focus()
      this.setStateScrolling({ focus: true })
    }
  }
  blur = () => {
    this.setState({ focus: false })
  }
  keyDown = e => {
    if (this.state.acceptInput) {
      dispatchKeyDown(this.keyHandler, e)
    }
  }
  change = () => {
    const { typer, searchInit, searchText, lastCommand } = this.state
    const textAreaValue = this.child.typer.value
    let idx = 0;
    for (;idx < typer.length && idx < textAreaValue.length; idx++) {
      if (typer[idx] !== textAreaValue[idx]) {
        break;
      }
    }
    const insert = textAreaValue.substring(idx)
    const replace = typer.length - idx
    if (lastCommand === ConsoleCommand.Search) {
      this.setState({
        searchText: searchInit ? insert : this.textInsert(insert, searchText, replace),
        typer: textAreaValue,
      }, this.triggerSearch)
    } else {
      this.setState(Object.assign(
        this.consoleInsert(insert, replace), {
          typer: textAreaValue,
          lastCommand: ConsoleCommand.Default,
        }), this.scrollToBottom
      )
    }
  }
  paste = e => {
    const { lastCommand, searchText, searchInit } = this.state
    const insert = e.clipboardData.getData('text')
    if (lastCommand === ConsoleCommand.Search) {
      this.setState({
        searchText: searchInit ? insert : this.textInsert(insert, searchText),
        typer: this.child.typer.value,
      }, this.triggerSearch)
    } else {
      this.setState(Object.assign(
        this.consoleInsert(insert), {
          lastCommand: ConsoleCommand.Default,
        }), this.scrollToBottom
      )
    }
    e.preventDefault()
  }

  //
  // Commands for Moving
  //

  _updatePoint = point => {
    this.setStateScrolling({
      point,
      argument: null,
      lastCommand: ConsoleCommand.Default,
    })
  }

  beginningOfLine = () => this._updatePoint(0)
  endOfLine = () => this._updatePoint(this.state.promptText.length)
  forwardChar = () => this._updatePoint(this.movePoint(1))
  backwardChar = () => this._updatePoint(this.movePoint(-1))
  forwardWord = () => this._updatePoint(this.nextWord())
  backwardWord = () => this._updatePoint(this.previousWord())

  //
  // Commands for Manipulating the History
  //

  acceptLine = () => {
    const { history, log, promptText: command, currLabel } = this.state
    const { continue: _continue, handler } = this.props

    this.child.typer.value = ''
    if (_continue(command)) {
      this.setStateScrolling({
        ...this.consoleInsert('\n'),
        ...{
          typer: '',
          lastCommand: ConsoleCommand.Default,
        }
      })
    } else {
      if (!history || history[history.length - 1] !== command) {
        history.push(command)
      }
      this.setState({
        acceptInput: false,
        typer: '',
        point: 0,
        promptText: '',
        restoreText: '',
        log: append({
          label: currLabel,
          command,
          message: []
        }, log),
        history,
        historyn: 0,
        argument: null,
        lastCommand: ConsoleCommand.Default,
      }, () => {
        this.scrollToBottom();
        (handler || this.return)(command)
      })
    }
  }
  previousHistory = () => { this.rotateHistory(-1) }
  nextHistory = () => { this.rotateHistory(1) }
  beginningOfHistory = () => { this.rotateHistory(-this.state.history.length) }
  endOfHistory = () => { this.rotateHistory(this.state.history.length) }
  triggerSearch = () => ((this.state.searchDirection === SearchDirection.Reverse ?
    this.reverseSearchHistory : this.forwardSearchHistory)()
  )

  setStateScrolling = newState => this.setState(newState, this.scrollToBottom)

  _searchHistory = (label, direction) => () => {
    const { lastCommand, searchText } = this.state
    const newState = lastCommand === ConsoleCommand.Search ?
      {
        ...this.searchHistory(direction, true),
        ...{
          argument: `(${label}-i-search)\`${searchText}': `,
          lastCommand: ConsoleCommand.Search,
        }
      }
      : {
        searchDirection: direction,
        searchInit: true,
        argument: `(${label}-i-search)\`': `,
        lastCommand: ConsoleCommand.Search,
      }

    this.setStateScrolling(newState)
  }
  reverseSearchHistory = this._searchHistory('reverse', SearchDirection.Reverse)
  forwardSearchHistory = this._searchHistory('forward', SearchDirection.Forward)

  nonIncrementalReverseSearchHistory = TODO
  nonIncrementalForwardSearchHistory = TODO
  historySearchBackward = TODO
  historySearchForward = TODO
  historySubstringSearchBackward = TODO
  historySubstringSearchForward = TODO
  yankNthArg = TODO
  yankLastArg = TODO

  //
  // Commands for Changing Text
  //

  deleteChar = () => {
    const { point, promptText } = this.state
    if (point < promptText.length) {
      this.setStateScrolling({
        promptText: promptText.substring(0, point) + promptText.substring(point + 1),
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    }
  }
  backwardDeleteChar = () => {
    const { promptText, lastCommand, searchText, point } = this.state
    if (lastCommand === ConsoleCommand.Search) {
      this.setState({
        searchText: searchText.substring(0, searchText.length - 1),
        typer: this.child.typer.value,
      }, this.triggerSearch)
    } else if (point > 0) {
      this.setState({
        point: this.movePoint(-1),
        promptText: promptText.substring(0, point - 1) + promptText.substring(point),
        argument: null,
        lastCommand: ConsoleCommand.Default,
      }, this.scrollToBottom)
    }
  }
  // Killing and Yanking
  killLine = () => {
    const { lastCommand, kill, promptText, point } = this.state
    if (lastCommand === ConsoleCommand.Kill) {
      kill[0] += promptText.substring(point)
    } else {
      kill.unshift(promptText.substring(point))
    }
    this.setStateScrolling({
      promptText: promptText.substring(0, point),
      kill,
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Kill,
    })
  }
  backwardKillLine = () => {
    const { kill, promptText, lastCommand, point } = this.state
    if (lastCommand === ConsoleCommand.Kill) {
      kill[0] = promptText.substring(0, point) + kill[0]
    } else {
      kill.unshift(promptText.substring(0, point))
    }
    this.setStateScrolling({
      point: 0,
      promptText: promptText.substring(point),
      kill,
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Kill,
    })
  }
  killWholeLine = () => {
    const { kill, lastCommand, promptText, point } = this.state
    if (lastCommand === ConsoleCommand.Kill) {
      kill[0] = promptText.substring(0, point) + kill[0] + promptText.substring(point)
    } else {
      kill.unshift(promptText)
    }
    this.setState({
      point: 0,
      promptText: '',
      kill,
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Kill,
    }, this.scrollToBottom)
  }
  killWord = () => {
    const { kill, lastCommand, promptText, point } = this.state
    if (lastCommand === ConsoleCommand.Kill) {
      kill[0] += promptText.substring(point, this.nextWord())
    } else {
      kill.unshift(promptText.substring(point, this.nextWord()))
    }
    this.setState({
      promptText: promptText.substring(0, point) + promptText.substring(this.nextWord()),
      kill,
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Kill,
    }, this.scrollToBottom)
  }
  backwardKillWord = () => {
    const { kill, lastCommand, promptText, point } = this.state
    if (lastCommand === ConsoleCommand.Kill) {
      kill[0] = promptText.substring(this.previousWord(), point) + kill[0]
    } else {
      kill.unshift(promptText.substring(this.previousWord(), point))
    }
    this.setState({
      point: this.previousWord(),
      promptText: promptText.substring(0, this.previousWord()) + promptText.substring(point),
      kill,
      killn: 0,
      argument: null,
      lastCommand: ConsoleCommand.Kill,
    }, this.scrollToBottom)
  }
  yank = () => {
    const { kill, killn } = this.state
    this.setState(Object.assign(
      this.consoleInsert(kill[killn]), {
        lastCommand: ConsoleCommand.Yank,
      }), this.scrollToBottom
    )
  }
  yankPop = () => {
    const { lastCommand, kill, killn } = this.state
    if (lastCommand === ConsoleCommand.Yank) {
      const newKilln = rotateRing(1, killn, kill.length)
      this.setState(Object.assign(
        this.consoleInsert(kill[newKilln], kill[killn].length), {
          killn: newKilln,
          lastCommand: ConsoleCommand.Yank,
        }), this.scrollToBottom
      )
    }
  }
  // Numeric Arguments
  // Completing
  complete = () => {
    const { promptText, point, log, currLabel } = this.state
    const { complete } = this.props
    if (complete) {
      // Split text and find current word
      const words = promptText.split(' ')
      let curr = 0
      let idx = words[0].length
      while (idx < point && curr + 1 < words.length) {
        idx += words[++curr].length + 1
      }

      const completions = complete(words, curr, promptText)
      let newState
      if (completions.length === 1) {
        // Perform completion
        // eslint-disable-next-line prefer-destructuring
        words[curr] = completions[0]
        let newPoint = -1
        for (let i = 0; i <= curr; i++) {
          newPoint += words[i].length + 1
        }
        newState = {
          point: newPoint,
          promptText: words.join(' '),
        }
      } else if (completions.length > 1) {
        // show completions
        newState = {
          currLabel: this.nextLabel(),
          log: append({
            label: currLabel,
            command: promptText,
            message: [{ type: 'completion', value: [completions.join('\t')] }]
          }, log),
        }
      }
      this.setStateScrolling({
        ...newState,
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    }
  }
  // Keyboard Macros
  // Miscellaneous
  prefixMeta = () => {
    if (this.state.lastCommand === ConsoleCommand.Search) {
      this.setState({
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    }
    // TODO Meta prefixed state
  }
  cancelCommand = () => {
    const { log, acceptInput, promptText, currLabel } = this.state
    if (acceptInput) { // Typing command
      this.child.typer.value = ''
      this.setStateScrolling({
        typer: '',
        point: 0,
        promptText: '',
        restoreText: '',
        log: append({
          label: currLabel,
          command: promptText,
          message: []
        }, log),
        historyn: 0,
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    } else { // command is executing, call handler
      this.props.cancel()
    }
  }

  // Helper functions

  textInsert = (insert, text, replace = 0, point = text.length) => (
    text.substring(0, point - replace) + insert + text.substring(point)
  )
  consoleInsert = (insert, replace = 0) => {
    const { point, promptText } = this.state
    const newPromptText = this.textInsert(insert, promptText, replace, point)
    return {
      // eslint-disable-next-line no-mixed-operators
      point: this.movePoint(insert.length - replace, insert.length - replace + promptText.length),
      promptText: newPromptText,
      restoreText: newPromptText,
      argument: null,
      lastCommand: ConsoleCommand.Default,
    }
  }
  movePoint = (n, max) => {
    const { promptText, point } = this.state
    return ensureNumberBetween(0, max || promptText.length, point + n)
  }

  nextWord() {
    const { promptText, point } = this.state
    // Find first alphanumeric char after first non-alphanumeric char
    const search = /\W\w/.exec(promptText.substring(point))
    return search ? search.index + point + 1 : promptText.length
  }
  previousWord() {
    const { promptText, point } = this.state
    // Find first non-alphanumeric char after first alphanumeric char in reverse
    const search = /\W\w(?!.*\W\w)/.exec(promptText.substring(0, point - 1))
    return search ? search.index + 1 : 0
  }

  rotateHistory = n => {
    const { history, historyn, restoreText } = this.state
    const new_historyn = rotateRing(n, historyn, history.length, false)
    if (new_historyn === 0) {
      this.setStateScrolling({
        point: restoreText.length,
        promptText: restoreText,
        historyn: new_historyn,
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    } else {
      const promptText = history[history.length - new_historyn]
      this.setStateScrolling({
        point: promptText.length,
        promptText,
        historyn: new_historyn,
        argument: null,
        lastCommand: ConsoleCommand.Default,
      })
    }
  }
  searchHistory = (direction = this.state.searchDirection, next = false) => {
    const { history, historyn, searchText } = this.state
    let idx = historyn
    const inc = (direction === SearchDirection.Reverse) ? 1 : -1
    if (next) {
      idx += inc
    }
    for (;idx > 0 && idx <= history.length; idx += inc) {
      const entry = history[history.length - idx]
      const point = entry.indexOf(searchText)
      if (point > -1) {
        return {
          point,
          promptText: entry,
          searchDirection: direction,
          searchInit: false,
          historyn: idx,
        }
      }
    }
    return {
      searchDirection: direction,
      searchInit: false,
    }
  }

  // DOM management
  scrollSemaphore = 0
  scrollIfBottom = () => {
    const { scrollTop, scrollHeight, offsetHeight } = this.child.container
    if (this.scrollSemaphore > 0 || scrollTop === scrollHeight - offsetHeight) {
      this.scrollSemaphore++
      return this.scrollIfBottomTrue
    } else {
      return null
    }
  }
  scrollIfBottomTrue = () => {
    this.scrollToBottom()
    this.scrollSemaphore--
  }
  scrollToBottom = () => { scrollToBottom(this.child) }
  nextLabel = () => {
    const { promptLabel } = this.props
    return typeof promptLabel === 'string' ? promptLabel : promptLabel()
  }

  render() {
    const { focus, currLabel, promptText, point, argument, acceptInput, log } = this.state
    const { welcomeMessage } = this.props

    return (
      <div
        ref={ref => { this.child.container = ref }}
        className={`react-console-container ${focus ? 'react-console-focus' : 'react-console-nofocus'}`}
        onClick={this.focus}
      >
        {welcomeMessage ?
          <div className="react-console-message react-console-welcome">
            {welcomeMessage}
          </div>
          : null
        }
        {log.map((val, i) => {
          return [
            <ConsolePrompt key={i} label={val.label} value={val.command} />,
            // eslint-disable-next-line no-shadow
            ...val.message.map((val, idx) => (
              <ConsoleMessage key={`${i}-${idx}`} type={val.type} value={val.value} />
            ))
          ]
        })}
        {acceptInput ?
          <ConsolePrompt
            label={currLabel}
            value={promptText}
            point={point}
            argument={argument}
          />
          : null
        }
        <div style={{ overflow: 'hidden', height: 1, width: 1 }}>
          <textarea
            ref={ref => { this.child.typer = ref }}
            className="react-console-typer"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            style={{ outline: 'none',
              color: 'transparent',
              backgroundColor: 'transparent',
              border: 'none',
              resize: 'none',
              overflow: 'hidden',
            }}
            onBlur={this.blur}
            onKeyDown={this.keyDown}
            onChange={this.change}
            onPaste={this.paste}
          />
        </div>
        <div ref={ref => { this.child.focus = ref }}>&nbsp;</div>
      </div>
    )
  }
}

//
// utilities

const scrollToBottom = child => {
  child.container.scrollTop = child.container.scrollHeight
  const rect = child.focus.getBoundingClientRect()
  if (rect.top < 0 || rect.left < 0 ||
    rect.bottom > (window.innerHeight || document.documentElement.clientHeight) ||
    rect.right > (window.innerWidth || document.documentElement.clientWidth)
  ) { child.typer.scrollIntoView(false) }
}

const rotateRing = (n, ringn, ring, circular = true) => {
  if (ring === 0) return 0

  return circular ?
    // eslint-disable-next-line no-mixed-operators
    (ring + (ringn + n) % ring) % ring
    : ensureNumberBetween(0, ring, ringn - n)
}

const ensureNumberBetween = (min, max, n) => (n < 0 ? 0 : (n > max) ? max : n)

/**
 * :^O
 */
const dispatchKeyDown = ({ metaCodes, ctrlCodes, keyCodes, metaCtrlCodes, metaShiftCodes }, e) => {
  if (e.altKey) {
    if (e.ctrlKey) {
      if (e.keyCode in metaCtrlCodes) {
        metaCtrlCodes[e.keyCode]()
      }
    } else if (e.shiftKey) {
      if (e.keyCode in metaShiftCodes) {
        metaShiftCodes[e.keyCode]()
      }
    } else if (e.keyCode in metaCodes) {
      metaCodes[e.keyCode]()
    }
    e.preventDefault()
  } else if (e.ctrlKey) {
    if (e.keyCode in ctrlCodes) {
      ctrlCodes[e.keyCode]()
      e.preventDefault()
    }
    e.preventDefault()
  } else if (e.keyCode in keyCodes) {
    keyCodes[e.keyCode]()
    e.preventDefault()
  }
}

const createKeyHandler = self => ({
  keyCodes: {
    // return
    13: self.acceptLine,
    // left
    37: self.backwardChar,
    // right
    39: self.forwardChar,
    // up
    38: self.previousHistory,
    // down
    40: self.nextHistory,
    // backspace
    8: self.backwardDeleteChar,
    // delete
    46: self.deleteChar,
    // end
    35: self.endOfLine,
    // start
    36: self.beginningOfLine,
    // tab
    9: self.complete,
    // esc
    27: self.prefixMeta,
  },
  ctrlCodes: {
    // C-a
    65: self.beginningOfLine,
    // C-e
    69: self.endOfLine,
    // C-f
    70: self.forwardChar,
    // C-b
    66: self.backwardChar,
    // C-l TODO
    // 76: self.clearScreen,
    // C-p
    80: self.previousHistory,
    // C-n
    78: self.nextHistory,
    // C-r
    82: self.reverseSearchHistory,
    // C-s
    83: self.forwardSearchHistory,
    // C-d
    68: self.deleteChar, // TODO EOF
    // C-q TODO
    // 81: self.quotedInsert,
    // C-v TODO
    // 86: self.quotedInsert,
    // C-t TODO
    // 84: self.transposeChars,
    // C-k
    75: self.killLine,
    // C-u
    85: self.backwardKillLine,
    // C-y TODO
    89: self.yank,
    // C-c
    67: self.cancelCommand,
    // C-w TODO
    // 87: self.killPreviousWhitespace,
    // C-] TODO
    // 221: self.characterSearch,
    // C-x TODO
    // 88: self.prefixCtrlX,
  },
  // ctrlXCodes: { // TODO state
  //   // C-x Rubout
  //   8: self.backwardKillLine,
  //   // C-x ( TODO
  //   // 57: self.startKbdMacro,
  //   // C-x ) TODO
  //   // 48: self.endKbdMacro,
  //   // C-x e TODO
  //   // 69: self.callLastKbdMacro,
  //   // C-x C-u TODO
  //   // 85: self.undo,
  //   // C-x C-x TODO
  //   // 88: self.exchangePointAndMark,
  // }
  // const ctrlShiftCodes = {
  //   // C-_ TODO
  //   // 189: self.undo,
  //   // C-@ TODO
  //   // 50: self.setMark,
  // }

  // alt-pressed
  metaCodes: {
    // alt/opt + left
    37: self.backwardWord,
    // alt/opt + up
    38: self.endOfLine,
    // alt/opt + right
    39: self.forwardWord,

    // alt/opt + f
    70: self.forwardWord,
    // alt/opt + b
    66: self.backwardWord,
    // alt/opt + p
    80: self.nonIncrementalReverseSearchHistory,
    // alt/opt + n
    78: self.nonIncrementalForwardSearchHistory,
    // M-.
    190: self.yankLastArg,
    // alt/opt + TAB TODO
    // 9: self.tabInsert,
    // alt/opt + t TODO
    // 84: self.transposeWords,
    // alt/opt + u TODO
    // 85: self.upcaseWord,
    // alt/opt + l TODO
    // 76: self.downcaseWord,
    // alt/opt + c TODO
    // 67: self.capitalizeWord,
    // alt/opt + d
    68: self.killWord,
    // alt/opt + backspace
    8: self.backwardKillWord,
    // alt/opt + w TODO
    // 87: self.unixWordRubout,
    // alt/opt + \ TODO
    // 220: self.deleteHorizontalSpace,
    // alt/opt + y
    89: self.yankPop,
    // M-0 TODO
    // 48: () => self.digitArgument(0),
    // M-1 TODO
    // 49: () => self.digitArgument(1),
    // M-2 TODO
    // 50: () => self.digitArgument(2),
    // M-3 TODO
    // 51: () => self.digitArgument(3),
    // M-4 TODO
    // 52: () => self.digitArgument(4),
    // M-5 TODO
    // 53: () => self.digitArgument(5),
    // M-6 TODO
    // 54: () => self.digitArgument(6),
    // M-7 TODO
    // 55: () => self.digitArgument(7),
    // M-8 TODO
    // 56: () => self.digitArgument(8),
    // M-9 TODO
    // 57: () => self.digitArgument(9),
    // M-- TODO
    // 189: () => self.digitArgument('-'),
    // M-f TODO
    // 71: () => self.abort,
    // M-r TODO
    // 82: self.revertLine,
    // M-SPACE TODO
    // 32: self.setMark,
  },
  metaShiftCodes: { // TODO hook in
    // M-<
    188: self.beginningOfHistory,
    // M->
    190: self.endOfHistory,
    // M-_
    189: self.yankLastArg,
    // M-? TODO
    // 191: self.possibleCompletions,
    // M-* TODO
    // 56: self.insertCompletions,
  },
  metaCtrlCodes: {
    // M-C-y
    89: self.yankNthArg,
    // M-C-] TODO
    // 221: self.characterSearchBackward,
    // M-C-j TODO !!!
    // 74: self.viEditingMode,
  },
})