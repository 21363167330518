exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__dAzn2{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.container__dAzn2 .header__3dzzr{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:1rem}.container__dAzn2 .header__3dzzr>div:nth-child(1){width:40%}.invitesTable__1QlFQ{background-color:#fff}", ""]);

// exports
exports.locals = {
	"container": "container__dAzn2",
	"header": "header__3dzzr",
	"invitesTable": "invitesTable__1QlFQ"
};