import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { getLoginDataFromLocalStorage } from 'utils/authentication'
import { Creators as NetworkActions } from 'actions/network'

const { connectionSucceeded, connectionError, reconnectionSucceeded, reconnectionStarted, connectingStarted } = NetworkActions

const CONNECT_MIN_TIMEOUT = 5000

// This is a hack to be able to disconnect/reconnect manually from a react button. We should have another way
let wsClient;
export const manualReconnect = () => () => { wsClient.connect() }
export const manualDisconnect = () => () => { wsClient.close(true, true) }


const { hostname } = window.location

const isSSL = window.location.protocol === 'https:'

const wsPort = 5001
export const webSocketURL = `${isSSL ? 'wss' : 'ws'}://${hostname}:${wsPort}/subscriptions`

/**
 * Consider we are disconnected when we didn't receive a backend ping/KA for this time.
 * This must me sync'ed with the server KA interval (50s)
 */
const KEEP_ALIVE_TIMEOUT = 60000

// creator

// TODO: it would be good to write a global error handler for ANY subscription
//   to log errors. Since they become difficult to find currently (only inspecting the network WS traffic)

const createLink = ({ store }) => {
  // subscriptions
  wsClient = new SubscriptionClient(webSocketURL, {
    timeout: KEEP_ALIVE_TIMEOUT,
    reconnect: true,
    lazy: true,
    connectionParams: () => {
      const authToken = getLoginDataFromLocalStorage()
      return { authToken: authToken && authToken.token }
    }
  })
  // WORKAROUND to https://github.com/apollographql/subscriptions-transport-ws/issues/377
  wsClient.maxConnectTimeGenerator.setMin(CONNECT_MIN_TIMEOUT)
  if (store) {
    hookActionsToWebSocket(wsClient, store)
  }

  return new WebSocketLink(wsClient)
}

export default createLink

// utils

const ReadyState = { CLOSED: 3 }
// exported just to test
export const hookActionsToWebSocket = (ws, store) => {

  ws.onDisconnected(() => {
    store.dispatch(connectionError('WS disconnected'))
  })
  ws.onConnected(() => {
    if (store.getState().network.lastError) {
      store.dispatch(reconnectionSucceeded())
    } else {
      store.dispatch(connectionSucceeded())
    }
  })
  ws.onConnecting(() => {
    store.dispatch(connectingStarted())
  })
  ws.onReconnecting(() => {
    store.dispatch(reconnectionStarted())
  })
  ws.onReconnected(() => {
    store.dispatch(reconnectionSucceeded())
  })
  ws.onError(e => {
    if (e.target.readyState === ReadyState.CLOSED) {
      store.dispatch(connectionError(`WS disconnected with error: ${e.message}`))
    }
  })

  // USE IT TO log subscriptions !
  // ws.use([{
  //   applyMiddleware(req, next) {
  //     console.log('WS MIDDLEWARE', req)
  //     return next()
  //   }
  // }])
}