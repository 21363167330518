import UnrealCommandDefinition from 'model/sessions/clients/unreal/UnrealCommandDefinition'
import requestSessionAction from '../requestSessionAction'

const playNode = requestSessionAction(
  () => node => ({
    command: UnrealCommandDefinition.PlayNode.name,
    params: node.id
  }),
)

export default playNode