import { connect } from 'react-redux'
import { makeAmISelectedSelector, makeIsCursorAtItSelector } from 'selectors/nodeSelection'
import { compose, withHandlers } from 'recompose'

import { nodeId } from 'selectors/props'

import { addRangeToSelection, selectToEnd, selectToBeginning, onNodeSelected, clearSelectedNodes } from 'actions/nodes'

const withNodeSelection = (idSelector = nodeId) => compose(
  connect(() => {
    // state
    const amISelected = makeAmISelectedSelector(idSelector)
    const isCursorAtIt = makeIsCursorAtItSelector(idSelector)
    return (state, props) => (
      {
        isSelected: amISelected(state, props),
        isCursorAtIt: isCursorAtIt(state, props),
      }
    )
  }, {
    // actions
    onNodeSelectedAction: onNodeSelected,
    addRangeToSelectionAction: addRangeToSelection,
    selectToEndAction: selectToEnd,
    selectToBeginningAction: selectToBeginning,
    clearSelected: clearSelectedNodes,
  }),
  withHandlers({

    onClicked: props => e => {
      const { onNodeSelectedAction, addRangeToSelectionAction, clearSelected } = props
      e.preventDefault()
      e.stopPropagation()

      if (e.target.getAttribute('data-selection') === 'noop') {
        // if some clickable element has the data-selection attribute with avoid value
        // then it will disable node selection for that click event.
        // This was added to avoid unwanted node selections (and potential screen relocations)
        // when clicking on empty spaces inside the truth table (or con TT's subchains)
        // so with this, clicking on TT's empty spaces (which in fact is the last column/td of the table)
        // would NOT have any effect on selection.
        return
      }
      if (e.target.getAttribute('data-selection') === 'clear') {
        clearSelected()
        return
      }

      const id = idSelector(undefined, props)
      if (e.shiftKey) {
        addRangeToSelectionAction(id)
      } else {
        onNodeSelectedAction(id, e.metaKey || e.ctrlKey)
      }
    },

  })
)

export default withNodeSelection