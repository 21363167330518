import { LABEL_MATCH_SOURCE_DATA } from './MatchSource'
import { getLabel } from 'selectors/objects/makeObjectLabelSelector'

/**
 * Searches in the object's label @see makeObjectLabelSelector
 */
const searchInLabelSource = (resolve, object, searcher) => {
  searcher(getLabel(object, resolve)?.value, LABEL_MATCH_SOURCE_DATA)
}

export default searchInLabelSource