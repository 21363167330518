import React, { useMemo } from 'react'
import { MenuItem } from '@blueprintjs/core'
import { connect } from 'react-redux'
import CommandsByClientType from '../../../commands/sessions/CommandsByClientType'
import { propFromProps } from '../../../selectors/props'
import { makeSessionForClientTypeSelector } from '../../../selectors/sessions/sessions'
import MenuItemCommand from '../MenuItemCommand'
import { selectedObjects } from 'selectors/objects'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'
import { CLIENT_CONTEXT_MENU_PREFIX } from 'providers/Extensions/ExtensionPoint'

/**
 * A generic context menu item for interacting with another user session, another app like
 * Unreal, Maya, etc.
 * This is for a single node selection.
 * For example it will show
 *
 *   ------------------
 *  | Edit             |
 *  | Delete           |
 *  | -------          |
 *  | Unreal         > |  --------------
 *  `------------------` | command 1    |
 *                       | command 2    |
 *                       `--------------`
 */
const SelectionOtherSessionMenuItem = ({ clientType, targetSession, selected }) => {
  const commands = useMemo(() => CommandsByClientType[clientType.code], [clientType])

  return (
    <MenuItem text={clientType.label}>
      {commands.map(command => (
        <MenuItemCommand
          key={command.label}
          command={command}
          params={[targetSession, selected]}
        />
      ))}
      <ExtensionPoint
        point={`${CLIENT_CONTEXT_MENU_PREFIX}/${clientType.code}`}
        params={[targetSession, selected]}
        useFragmentContainer
        renderExtension={({ id, label, action, icon }) => (
          <MenuItem
            key={id}
            text={label}
            onClick={() => action(targetSession, selected)}
            icon={icon}
          />)}
      />
    </MenuItem>
  )
}

export default connect(() => {
  const sessionSelector = makeSessionForClientTypeSelector(propFromProps('clientType'))
  return (state, props) => ({
    targetSession: sessionSelector(state, props),
    selected: selectedObjects(state),
  })
})(SelectionOtherSessionMenuItem)