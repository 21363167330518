exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ObjectHistory__1_mYv{overflow-y:auto;height:78vh}.ObjectHistory__1_mYv .ant-list-item{padding-left:1.5rem}.ObjectHistory__1_mYv .loading__2DbYi{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin-top:2rem;color:gray}.ObjectHistory__1_mYv .loading__2DbYi .ant-spin{margin-right:1rem}", ""]);

// exports
exports.locals = {
	"ObjectHistory": "ObjectHistory__1_mYv",
	"loading": "loading__2DbYi"
};