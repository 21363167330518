import { Decoration, ViewPlugin, PluginField } from '@codemirror/view'
import NodeWidget from './NodeWidget'
import { nodeRefs } from './TreeUtils'

const nodeDecoration = (id, from, to, register) => 
  Decoration.replace({ widget: new NodeWidget(id, from, to, register) })

const getNodeDecorations = ({ state }, register) => {
  const decorations = nodeRefs(state).map(({ id, from, to }) => 
    nodeDecoration(id, from, to, register).range(from, to)
  )

  return Decoration.set(decorations)
}

export const nodeDecorationPlugin = register => ViewPlugin.define(
  view => ({
    decorations: getNodeDecorations(view, register),
    update(update) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = getNodeDecorations(update.view, register)
      }
    },
  }), {
    decorations: plugin => plugin.decorations,
    provide: [PluginField.atomicRanges.from(v => v.decorations)]
  }
)

export default nodeDecorationPlugin