import { Observable } from 'rxjs/Rx'

class Notifications {

  constructor() {
    this.observers = []
    this.observable = Observable.create(observer => { this.observers.push(observer) })
  }

  subscribe(cb) {
    return this.observable.subscribe(cb)
  }

  unsubscribe(subscription) {
    subscription.unsubscribe()
  }

  notify(message) {
    this.observers.forEach(o => o.next(message))
  }
}

export default new Notifications()