exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".pageHeader__3--r8{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:start;justify-content:flex-start;padding-bottom:1rem;padding-top:1rem;background-color:#f9f9f9}.pageHeader__3--r8 .searchBox__2owNz{padding-right:4em;width:40rem}.pageHeader__3--r8 .searchBox__2owNz input{height:2.5em}", ""]);

// exports
exports.locals = {
	"pageHeader": "pageHeader__3--r8",
	"searchBox": "searchBox__2owNz"
};