import { makeSelector } from '@taskworld.com/rereselect'
import { Sys, parseRef } from 'beanie-engine-api-js'
import { path, pipe } from 'ramda'

import { resolver } from './rereselect'


import { getLabel } from './makeObjectLabelSelector'

const ref = aPath => pipe(path(aPath), parseRef)

const valueProviders = {

  [Sys.jump]: ref(['data', 'target']),

  [Sys.choice]: (object, resolve) => {
    const lineRef = path(['data', 'line'], object)
    if (!lineRef) return undefined

    const resolved = resolve(lineRef)
    if (!resolved) return undefined
    const label = getLabel(resolved, resolve)
    return label ? label.value : undefined
  }

}

const name = path(['data', 'name'])

const getValue = (object, resolve) => {
  const provider = valueProviders[object.sys]
  return provider ? provider(object, resolve) : name(object)
}

const makeObjectEditLabelSelector = id => makeSelector(query => {
  const resolve = resolver(query)
  return getValue(resolve(id), resolve)
})

export default makeObjectEditLabelSelector