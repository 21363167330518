exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".nameSection__4zsNI{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.nameSection__4zsNI>div{width:48%}.rolesSection__Z90JD{display:-ms-flexbox;display:flex}.rolesSection__Z90JD>div:first-child{padding-right:1rem}.rolesSection__Z90JD>div:nth-child(2){display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around}", ""]);

// exports
exports.locals = {
	"nameSection": "nameSection__4zsNI",
	"rolesSection": "rolesSection__Z90JD"
};