
import React from 'react'

const subscribedToQuery = ({ queryProp = 'data', subscription, variables, updateQuery }) => C =>
  class SubscribedToQuery extends React.Component {
    state = {
      unsubscribe: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const queryData = nextProps[queryProp]
      const { loading, error } = queryData

      if (!loading && !error) {
        if (prevState.unsubscribe) {
          prevState.unsubscribe()
        }

        return {
          unsubscribe: queryData.subscribeToMore({
            document: subscription,
            variables,
            updateQuery
          })
        }
      }

      return null
    }

    render() {
      return <C {...this.props} {...this.state} />
    }

  }

export default subscribedToQuery
