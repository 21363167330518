exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".LoadingProject__116TW .anticon{margin-right:.5rem}", ""]);

// exports
exports.locals = {
	"LoadingProject": "LoadingProject__116TW"
};