import { isNil } from 'ramda'
import ExtensionPoint, { EXTENSION_POINTS } from './ExtensionPoint'
import uuid from 'uuid/v4'

export default class ExtensionsContainer {

  // extension = {
  //   point: Point
  //   data: {
  //     label: String,
  //     icon: String,
  //     action: () => ()
  //   }
  // }

  constructor() {
    this.extensionsPointObservers = {} // { point: ExtensionPointInstance }

    this.extensionUuids = {} // { uuid: point }

    Object.values(EXTENSION_POINTS).forEach(point => {
      this.initPointObserver(point)
    })
  }

  resetExtensions() {
    Object.values(this.extensionsPointObservers).forEach(observer => {
      observer.reset()
    })
  }

  initPointObserver(point) {
    this.extensionsPointObservers[point] = new ExtensionPoint(point)
  }

  getExtensionPointObserver(point) {
    if (isNil(this.extensionsPointObservers[point])) {
      this.initPointObserver(point)
    }

    return this.extensionsPointObservers[point]
  }

  registerExtension({ point, data }) {
    const id = uuid()
    this.setPointFromUuid(id, point)
    this.getExtensionPointObserver(point).addExtension(id, data)
    return id
  }

  setPointFromUuid(id, point) {
    this.extensionUuids[id] = point
  }

  getPointFromUuid(id) {
    return this.extensionUuids[id]
  }

  unregisterExtension(id) {
    const point = this.getPointFromUuid(id)
    if (!isNil(this.extensionsPointObservers[point])) {
      this.getExtensionPointObserver(point).deleteExtension(id)
    }
    delete this.extensionUuids[id]
  }

  getExtensions() { return this.extensionsPointObservers }
  getExtensionIds() { return Object.keys(this.extensionUuids) }

}