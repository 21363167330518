import React from 'react'

import styles from './ContentAssistItem.scss'

export default function ({ text, icon, description = '(variable)' }) {
  const Icon = icon
  return (
    <div className={styles.item}>
      {icon && <div className={styles.iconPlaceHolder}>
        {Icon}
      </div>}
      <div className={styles.text}>
        {text}
      </div>
      {description &&
        <div className={styles.description}>{description}</div>}
    </div>
  )
}