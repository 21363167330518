import GraphiQL from '../GraphiQL/GraphiQL'
import Users from '../Users/Users'
import OrganizationListPage from '../Organizations/OrganizationListPage/OrganizationListPageContainer'
import QueueManagement from '../QueueManagement/QueueManagement'

export default {
  users: { name: 'users', label: 'Users', comp: Users, icon: 'idcard' },
  organizations: { name: 'organizations', label: 'Organizations', comp: OrganizationListPage, icon: 'bank' },
  queues: { name: 'queues', label: 'Queues', comp: QueueManagement, icon: 'mail' },
  graphiql: { name: 'graphiql', label: 'GraphiQL', comp: GraphiQL, icon: 'database' },
}
