exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__2J3Vd{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-size:1.3em}.container__2J3Vd i{color:#fff}.newProjectBtn__3Surk{-ms-flex-item-align:center;align-self:center;margin-right:1em;margin-left:1em}.modalContent__30QF5>*:not(:last-child){margin-bottom:1rem}.modalContent__30QF5 .ant-select{width:100%}.modalContent__30QF5 .openRightAway__2DMmP{font-size:.8rem;color:#959393}.options__1kCf2 .ant-avatar,.ant-select-selection-selected-value .ant-avatar{height:1.5rem;width:1.5rem}", ""]);

// exports
exports.locals = {
	"container": "container__2J3Vd",
	"newProjectBtn": "newProjectBtn__3Surk",
	"modalContent": "modalContent__30QF5",
	"openRightAway": "openRightAway__2DMmP",
	"options": "options__1kCf2"
};