import React from 'react'
import SearchBox from 'components/Commons/SearchBox'
import Secure from 'components/Commons/Secure'

import { teamWriteAccess } from 'security/team'

import InviteToTeamButton from './InviteToTeamButton'

import styles from './TeamMembersHeader.scss'

const TeamMembersHeader = ({ team, onSearch, refreshItems }) => (
  <div className={styles.TeamMembersHeader}>
    <SearchBox onSearch={onSearch} />
    {team && 
      <Secure check={teamWriteAccess}>
        <InviteToTeamButton team={team} onInvited={refreshItems} />
      </Secure>
    }
  </div>
)

export default TeamMembersHeader