import React from 'react'
import { Card } from 'antd'

import GrantsGroup from './GrantsGroup'

const GrantsList = ({ title, projectData: data, filter, revokeGrant, changeGrantRole, itemRenderer, menu }) => (
  <Card title={title} loading={data.loading} extra={data.projectWithId && menu}>
    {!data.loading && data.projectWithId && (
      <GrantsGroup
        project={data.projectWithId}
        grants={data.projectWithId.grants.filter(filter)}
        revokeGrant={revokeGrant}
        changeGrantRole={changeGrantRole}
        itemRenderer={itemRenderer}
      />
    )}
  </Card>
)

export default GrantsList