import { model } from 'beanie-engine-api-js'
import describeBNEAction from '../../../../__tests__/utils/describeBNEAction'
import { Creators } from 'actions/vm'
import { choicesGetSelectedChoice } from '../../../../model/ui/NodePlayback'
import { playbackNode } from '../../../../selectors/playback'
import choicesHandler from './choices'

const {
  types: { choices: { StateFields } },
  factory: { objects: { choice, choices2, withChoices, withAutoChoice, withObjects } }
} = model

const { startPlayback } = Creators

describeBNEAction('host / playback / handlers / choices', ({ createIntegrationContext }) => {

  describe('onEndExecuteNode', () => {

    // this is a mock test. I would have liked to create a test where we actually
    // play and then dispatch expired() and assert that we captured the value
    // but playing from the test in the frontend is very challenging.
    // our `describeBNEAction` already sets up the engine without presentation callbacks
    // so I would need to do it all manually or refactor every describeBNEAction :S
    it('should store the selected choice when auto-selected by the engine', async () => {
      const { dispatch, getState, engine } = createIntegrationContext({
        objects: withObjects({
          CHS: [choices2, withChoices(['C1', 'C2'])],
          C1: choice,
          C2: [choice, withAutoChoice(true)]
        })
      })
      // play
      await dispatch(startPlayback({ id: 'CHS' }))

      const api = engine.getAPI()

      // setup state in beanie
      api.state.set('CHS', StateFields.LAST_SELECTED_CHOICE_NODE_ID, 'C2')

      // call it
      choicesHandler.onEndExecuteNode(api.obj.get('CHS'), 'NODE_EXECUTION_ID', dispatch)

      // now the playbackNode has
      const execution = playbackNode('NODE_EXECUTION_ID')(getState())
      expect(choicesGetSelectedChoice(execution)).toEqual(1) // index is 1
    })

    it('should not fail if there is no selected option', async () => {
      const { dispatch, engine } = createIntegrationContext({
        objects: withObjects({
          CHS: choices2, // no choices !
        })
      })
      // play
      await dispatch(startPlayback({ id: 'CHS' }))

      // simulate calling the handler
      const api = engine.getAPI()
      choicesHandler.onEndExecuteNode(api.obj.get('CHS'), 'NODE_EXECUTION_ID', dispatch)
      // no assert but.. regression, it is enough that onEndExecuteNode doesn't fail
    })

    it('should not fail if get_enabled_choices returns an empty object instead of an array', async () => {
      const { dispatch } = createIntegrationContext({
        objects: withObjects({
          CHS: choices2, // no choices !
        })
      })
      // play
      await dispatch(startPlayback({ id: 'CHS' }))

      const mockedObject = {
        get_state: () => 'SOME_ID',
        get_enabled_choices: () => ({ toJSONObject: () => ({}) })
      }

      choicesHandler.onEndExecuteNode(mockedObject, 'NODE_EXECUTION_ID', dispatch)
      // no assert, just don't fail (regression test)
    })

    it('should not trigger recording the state if the choice cannot be found within enabled_choices', async () => {
      const { dispatch, engine, store } = createIntegrationContext({
        objects: withObjects({
          CHS: choices2, // no choices !
        })
      })
      // play
      await dispatch(startPlayback({ id: 'CHS' }))

      const api = engine.getAPI()
      const choices = api.obj.get('CHS')

      // simulate / hack the store to set last_selected to an invalid id
      engine.getAPI().state.set('CHS', StateFields.LAST_SELECTED_CHOICE_NODE_ID, 'UNKNOWN_ID')

      // call it
      store.clearActions() // so we can assert only dispatched action on the next line
      choicesHandler.onEndExecuteNode(choices, 'NODE_EXECUTION_ID', dispatch)

      expect(store.getActions()).toEqual([])
    })

  })

})