import { ApolloLink } from 'apollo-link'

import Headers from 'api/Headers'
import { getLoginDataFromLocalStorage } from 'utils/authentication'
import { sessionId } from 'selectors/project'

/*
 * Appends authorization headers
 */
const createLink = ({ getState }) => new ApolloLink((operation, forward) => {
  const authData = getLoginDataFromLocalStorage()
  const bneSessionId = sessionId(getState())

  operation.setContext({
    headers: {
      authorization: authData ? `Bearer ${authData.token}` : null,
      ...bneSessionId && { [Headers.SESSION_ID]: bneSessionId }
    }
  })
  return forward(operation)
})

export default createLink