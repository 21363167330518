import { difference, identity, isEmpty, isNil } from 'ramda'

const checkHasAllCases = (spec, mapping) => {
  const missingCases = difference(Object.keys(spec), Object.keys(mapping))
  if (!isEmpty(missingCases)) {
    throw new Error(`Missing mapping for: ${missingCases.join()}`)
  }
}

const defaultErrorFn = input => `Cannot find mapping for input ${JSON.stringify(input)}`
const createMappingFor = (spec, mapping, keyFn = identity, errorFn = defaultErrorFn) => {
  checkHasAllCases(spec, mapping)
  return {
    get: input => {
      const r = mapping[keyFn(input)]
      if (isNil(r)) {
        throw new Error(errorFn(input))
      }
      return r
    }
  }
}

export default createMappingFor