import React from 'react'
import { Icon, Tooltip } from 'antd'
import classnames from 'classnames'

import styles from './UserAvatarBadge.scss'

const DEFAULT_POSITION = 'bottomRight'

const icon = (type, theme) => (<Icon type={type} theme={theme} />)

const UserAvatarBadge = ({ children, type, theme = 'filled', position = DEFAULT_POSITION, tooltip }) => (
  <div className={styles.container}>
    {children}
    <div className={classnames(styles.overlay, styles[position])}>
      {tooltip ?
        <Tooltip title={tooltip}>{icon(type, theme)}</Tooltip>
        : icon(type, theme)
      }
    </div>
  </div>
)

export default UserAvatarBadge