import { allPass, anyPass, propEq } from 'ramda'

// Individual values

export const BOTTOM = 'bottom'
export const CENTER = 'center'
export const TOP = 'top'
export const LEFT = 'left'
export const RIGHT = 'right'

// per axis

export const HORIZONTAL_VALUES = [LEFT, CENTER, RIGHT]
export const VERTICAL_VALUES = [TOP, CENTER, BOTTOM]

/**
 * constructor
 */
export const newPosition = (vertical, horizontal) => ({ vertical, horizontal })

// all possible position objects (don't mutate them!)

export const TOP_LEFT = newPosition(TOP, LEFT)
export const TOP_CENTER = newPosition(TOP, CENTER)
export const TOP_RIGHT = newPosition(TOP, RIGHT)
export const BOTTOM_LEFT = newPosition(BOTTOM, LEFT)
export const BOTTOM_CENTER = newPosition(BOTTOM, CENTER)
export const BOTTOM_RIGHT = newPosition(BOTTOM, RIGHT)
export const CENTER_LEFT = newPosition(CENTER, LEFT)
export const CENTER_CENTER = newPosition(CENTER, CENTER)
export const CENTER_RIGHT = newPosition(CENTER, RIGHT)

export const ALL_POSITIONS = [
  TOP_LEFT, TOP_CENTER, TOP_RIGHT,
  CENTER_LEFT, CENTER_CENTER, CENTER_RIGHT,
  BOTTOM_LEFT, BOTTOM_CENTER, BOTTOM_RIGHT,
]

//
// behavior
//

export const arePositionsDifferent = (prevPos, newPos) =>
  !prevPos ||
  (prevPos.vertical !== newPos.vertical || prevPos.horizontal !== newPos.horizontal)

export const isHoveringVertically = propEq('vertical')
export const isHoveringHorizontally = propEq('horizontal')

export const isHoveringLeft = isHoveringHorizontally(LEFT)
export const isHoveringRight = isHoveringHorizontally(RIGHT)
export const isHoveringTop = isHoveringVertically(TOP)
export const isHoveringBottom = isHoveringVertically(BOTTOM)
export const isHoveringTopOrBottom = anyPass([isHoveringTop, isHoveringBottom])

export const isHoveringBottomLeft = allPass([isHoveringBottom, isHoveringLeft])
export const isHoveringBottomRight = allPass([isHoveringBottom, isHoveringRight])
export const isHoveringTopLeft = allPass([isHoveringTop, isHoveringLeft])
export const isHoveringTopRight = allPass([isHoveringTop, isHoveringRight])
