import jwt from 'jsonwebtoken'
import { pipe } from 'ramda'

// unix time is SECONDS from 1970
export /* for test */ const unixTimeNow = () => Math.floor(new Date().getTime() / 1000)

const decodeToken = token => jwt.decode(token, { json: true })

// checks if exp field is older than now
const alreadyExpired = decodedToken => unixTimeNow() > decodedToken.exp

export const isTokenExpired = pipe(decodeToken, alreadyExpired)