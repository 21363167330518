import React from 'react'
import { connect } from 'react-redux'

import { withProps, compose, withState } from 'recompose'
import { mapIndexed } from 'ramda-adjunct'
import toMaterialStyle from 'material-color-hash'

import { formatSeconds } from 'utils/dates'

import { sortedTimeline, videoLength as videoLengthSelector } from '../../selectors/project'

import HoverDetail from './HoverDetail'
import TimelineSegment from './TimelineSegment'

import styles from './VideoPlayerTimeline.scss'

const width = 1024

const VideoPlayerTimeline = ({ timeline, hoverClip, setHoverClip, videoLength }) => (
  <div className={styles.box}>
    <div className={styles.title}>
      <div>Timeline</div>
      <HoverDetail hoverClip={hoverClip} />
    </div>
    <svg height="10" width={width}>
      {timeline.map(clipSpec => (
        <TimelineSegment key={clipSpec.id} clip={clipSpec} onHover={setHoverClip} hoverClip={hoverClip} />
      ))
      },
    </svg>
    <div className={styles.subtitle}>
      <div>{formatSeconds(videoLength)}</div>
    </div>
  </div>
)


export default compose(
  connect(state => ({
    timeline: sortedTimeline(state),
    videoLength: videoLengthSelector(state)
  })),
  withProps(({ timeline, videoLength }) => {
    if (!timeline || timeline.length === 0) return { timeline: [] } 

    const ratio = l => (l * width) / videoLength

    return {
      width,
      timeline: mapIndexed(({ id, time }) => ({
        id,
        time,
        ui: {
          x1: ratio(time.start),
          x2: ratio(time.end),
          color: toMaterialStyle(id).backgroundColor
        }
      }))(timeline)
    }
  }),
  withState('hoverClip', 'setHoverClip')
)(VideoPlayerTimeline)