import React, { Fragment } from 'react'

import { Menu, Dropdown, Icon } from 'antd'
import { compose } from 'recompose'
import withUser from 'hocs/withUser'
import secure from 'hocs/secure'
import { orgWriteAccess } from 'security/organization'
import { noop } from 'utils/functions'
import InviteToOrganizationModal from 'components/Organizations/OrganizationMembers/InviteToOrganizationModal'
import RenameOrganizationModal from 'components/Organizations/Commons/RenameOrganizationModal'
import DeleteOrganizationModal from 'components/Organizations/OrganizationSettings/DeleteOrganizationModal'
import withBooleanState from 'hocs/withBooleanState'

import styles from './OrganizationCard.scss'

const menu = ({ organization, hasAccess, startShowingInviteModal, startShowingRenameModal, startShowingDeleteModal }) => (
  <Menu className={styles.organizationMenu}>
    {<Menu.Item disabled={!hasAccess} key="invite" onClick={startShowingInviteModal}>
      <Icon type="user-add" />
      Invite
    </Menu.Item>
    }
    <Menu.Divider />
    <Menu.Item key="edit" disabled={!hasAccess} onClick={startShowingRenameModal}>
      <Icon type="edit" />
      Rename
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="leave" disabled>
      <Icon type="user-delete" />
      Leave
    </Menu.Item>
    <Menu.Item key="delete" disabled={!hasAccess || organization.counters.projects > 0} onClick={startShowingDeleteModal}>
      <Icon type="delete" theme="filled" />
      Delete
    </Menu.Item>
  </Menu>
)

const OrganizationOptions = ({ organization, refresh, inviteModalVisible, renameModalVisible, stopShowingInviteModal, stopShowingRenameModal, startShowingInviteModal, startShowingRenameModal, hasAccess, deleteModalVisible, stopShowingDeleteModal, startShowingDeleteModal }) => (
  <Fragment>
    <Dropdown className={styles.organizationDropdown} overlay={menu({ organization, hasAccess, startShowingInviteModal, startShowingRenameModal, startShowingDeleteModal })} trigger={['click']}>
      <Icon className={styles.menuIcon} type="menu" />
    </Dropdown>
    {inviteModalVisible && <InviteToOrganizationModal refreshMembers={noop} hide={stopShowingInviteModal} organization={organization} />}
    {renameModalVisible && <RenameOrganizationModal organization={organization} hide={stopShowingRenameModal} />}
    {deleteModalVisible && <DeleteOrganizationModal organization={organization} hide={stopShowingDeleteModal} onDeleted={refresh} />}
  </Fragment>
)

export default compose(
  withUser,
  secure('hasAccess', orgWriteAccess, ['organization']),
  withBooleanState('inviteModalVisible', 'startShowingInviteModal', 'stopShowingInviteModal'),
  withBooleanState('renameModalVisible', 'startShowingRenameModal', 'stopShowingRenameModal'),
  withBooleanState('deleteModalVisible', 'startShowingDeleteModal', 'stopShowingDeleteModal'),
)(OrganizationOptions)
