import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'recompose'
import classNames from 'classnames'

import { equals } from 'ramda'
import { withLink } from 'hocs/withLinkToNodeRefract'

import { createObjectByIdSelector } from 'selectors/objects'
import makeObjectLabelSelector from 'selectors/objects/makeObjectLabelSelector'

import { onNodeSelected } from 'actions/nodes'

import NodePathPopover from 'components/TreeView/NodePathPopover/NodePathPopover'
import { noop } from 'utils/functions'

import { getComponentFor } from './Rule'

import styles from './Rule.scss'

const BneNodeOperation = ({ op, className, nodeRef, synthetic, level, ...others }) => {
  const Ref = getComponentFor(nodeRef)
  return (<span>{!synthetic && <div>
    <span className={className}>{op}</span>&nbsp;
    <Ref node={nodeRef} {...others} level={level + 1} />
    </div>}
  </span>)
}

export const ChooseNode = ({ node: { node }, ...others }) => <BneNodeOperation op="choose" className={styles.choose} nodeRef={node} {...others} />
export const ExecutedNode = ({ node: { node }, ...others }) => <BneNodeOperation op="executed" className={styles.executed} nodeRef={node} {...others} />
export const ExecutionCount = ({ node: { node }, ...others }) => <BneNodeOperation op="execution_count" className={styles.executionCount} nodeRef={node} {...others} />
export const LastChosen = ({ node: { node }, ...others }) => {
  const { level } = others

  if (equals(level, 0)) {
    const Ref = getComponentFor(node)
    return <Ref node={node} {...others} level={level + 1} />
  }

  return <BneNodeOperation op="last_chosen" className={styles.lastChosen} nodeRef={node} {...others} />
}

// NodeRef

const _NodeRef = ({ onMouseEnter, onClick, onMouseLeave, hoveringToNavigate, object, node, onHoveringToNavigate = noop }) => {
  const selector = useMemo(() => makeObjectLabelSelector(object?.id), [object?.id])
  const label = useSelector(selector)
  const labelToShow = label?.value || node.value

  const children = <span className={classNames(styles.nodeName, { [styles.hoveringToNavigate]: hoveringToNavigate })}>{labelToShow}</span>

  useEffect(() => {
    onHoveringToNavigate(hoveringToNavigate)
  }, [hoveringToNavigate])

  return (
    <span className={styles.nodeTitle} onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {hoveringToNavigate && object ? <NodePathPopover id={object.id}>{children}</NodePathPopover> : children }
      &nbsp;
    </span>
  )
}

export const NodeRef = compose(
  connect(() => {
    const objectSelector = createObjectByIdSelector((_, p) => p.node.value)()
    return (state, props) => ({
      object: objectSelector(state, props),
    })
  },
  { onNodeSelectedAction: onNodeSelected }),
  withLink({
    onClickToNavigate: ({ object, onNodeSelectedAction }) => {
      if (object) {
        onNodeSelectedAction(object.id)
      }
    }
  }),
)(_NodeRef)
