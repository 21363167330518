exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".ModalOpener__3fKep .titleIcon__3IXrW{margin-right:.5rem}", ""]);

// exports
exports.locals = {
	"ModalOpener": "ModalOpener__3fKep",
	"titleIcon": "titleIcon__3IXrW"
};