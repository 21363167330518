import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'antd'

import { currentCheckpointName } from 'selectors/project'
import withBooleanState from 'hocs/withBooleanState'

import RevisionsMenu from './RevisionsMenu'

import styles from './RevisionSelector.scss'

const RevisionSelector = ({ checkpointName, enableMenu, isVisible, closeMenu }) => (
  <div className={styles.RevisionSelector}>
    <Dropdown
      onClick={enableMenu}
      visible={isVisible}
      onVisibleChange={visible => (visible ? enableMenu() : closeMenu())}
      overlay={<RevisionsMenu closeMenu={closeMenu} isVisible={isVisible} />}
      trigger={['click']}>
      <div className={styles.version}>
        <div>{checkpointName}</div>
        <Icon type="down" />
      </div>
    </Dropdown>
  </div>
)

export default compose(
  connect(state => ({ checkpointName: currentCheckpointName(state) })),
  withBooleanState('isVisible', 'enableMenu', 'closeMenu'),
)(RevisionSelector)
