exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__27FxM{color:#add8e6;cursor:pointer}.iconAsLink__27FxM:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__JYzbA{font-style:italic}.formFieldsVerticalGutter__yWO8q{padding:.5rem}.formFieldsVerticalGutter__yWO8q>*{margin:.5rem !important}.tableBackground__2y3oQ .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__2y3oQ .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__CFIuB .ant-notification-notice-close{display:none}.ant-layout{height:100%}.ant-layout-header{line-height:42px;height:42px}.devColor__57_Jk{background:#503898 !important}.header__3G7z7{color:#fff;padding-left:.5rem;padding-right:.5rem;position:fixed;width:100%;height:2.6rem;z-index:10}.header__3G7z7 h1{color:#fff}.content__2kpKd{display:-ms-flexbox;display:flex;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-line-pack:stretch;align-content:stretch}.rightPanel__2va0H{display:-ms-flexbox;display:flex;-ms-flex:4 1 0%;flex:4 1 0%;margin-top:2.6rem}.leftPanel__oyhIL{display:-ms-flexbox;display:flex;-ms-flex:1 1 0%;flex:1 1 0%;margin-top:2.6rem}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__27FxM",
	"labelFromRef": "labelFromRef__JYzbA",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__yWO8q",
	"tableBackground": "tableBackground__2y3oQ",
	"notificationNotClosable": "notificationNotClosable__CFIuB",
	"devColor": "devColor__57_Jk",
	"header": "header__3G7z7",
	"content": "content__2kpKd",
	"rightPanel": "rightPanel__2va0H",
	"leftPanel": "leftPanel__oyhIL"
};