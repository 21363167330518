exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, "#app>div{height:100%}.iconAsLink__2lbaf{color:#add8e6;cursor:pointer}.iconAsLink__2lbaf:hover{-webkit-transition:all .5s ease;-o-transition:all .5s ease;transition:all .5s ease;color:#1890ff}.labelFromRef__LbYKk{font-style:italic}.formFieldsVerticalGutter__1mXz3{padding:.5rem}.formFieldsVerticalGutter__1mXz3>*{margin:.5rem !important}.tableBackground__SN5hn .ant-table-thead th{background-color:#f9f9f9 !important}.tableBackground__SN5hn .ant-table-body{background-color:#f9f9f9 !important}.notificationNotClosable__1yGAh .ant-notification-notice-close{display:none}.ant-layout{height:100%}.ant-layout-header{line-height:42px;height:42px}.devColor__2nKyZ{background:#503898 !important}.header__3ar7b{color:#fff;padding-left:.5rem;padding-right:.5rem;position:fixed;width:100%;height:2.6rem;z-index:20}.header__3ar7b h1{color:#fff}.footer__27rL3{width:100%;font-size:.8em;padding:.5vh 0 .5vh .5em}.content__3IJFR{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;height:100%}.content__3IJFR .leftPanel__3-KvV{background:#00142a;margin-top:2.6rem;z-index:100}.content__3IJFR .rightPanel__16STJ{-ms-flex-positive:3;flex-grow:3;height:100vh;overflow:scroll;padding-top:3rem}.content__3IJFR .rightPanel__16STJ>.ant-spin-nested-loading{height:100%}.content__3IJFR .rightPanel__16STJ>.ant-spin-nested-loading>.ant-spin-container{height:100%}", ""]);

// exports
exports.locals = {
	"iconAsLink": "iconAsLink__2lbaf",
	"labelFromRef": "labelFromRef__LbYKk",
	"formFieldsVerticalGutter": "formFieldsVerticalGutter__1mXz3",
	"tableBackground": "tableBackground__SN5hn",
	"notificationNotClosable": "notificationNotClosable__1yGAh",
	"devColor": "devColor__2nKyZ",
	"header": "header__3ar7b",
	"footer": "footer__27rL3",
	"content": "content__3IJFR",
	"leftPanel": "leftPanel__3-KvV",
	"rightPanel": "rightPanel__16STJ"
};