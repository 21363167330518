exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".suggestionPortal__1l3W3{display:none;position:absolute;z-index:10;width:200px;background-color:#fff;background-clip:padding-box;border-radius:4px;-webkit-box-shadow:0 2px 8px rgba(0,0,0,.15);box-shadow:0 2px 8px rgba(0,0,0,.15);margin-top:1.2rem;margin-left:-0.2rem;max-height:20rem;overflow:auto}.suggestionPortal__1l3W3 ul{list-style-type:none;margin-top:0;position:relative;padding-left:0;margin-bottom:0}.suggestionPortal__1l3W3 li{padding:5px 10px;cursor:pointer}", ""]);

// exports
exports.locals = {
	"suggestionPortal": "suggestionPortal__1l3W3"
};