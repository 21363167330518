exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".keyword__YgK68{font-style:italic}.operationContainer__1FOfu{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}.nodeTitle__3BgN6 .nodeName__2RoRd{font-style:italic}.nodeTitle__3BgN6 .nodeName__2RoRd.hoveringToNavigate__1KwzW{text-decoration:underline;color:#6363a9;cursor:pointer}.casesContainer__2Vlwx{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.whenBranch__3Ob5H{display:-ms-flexbox;display:flex}.otherwiseBranch__160eP{display:-ms-flexbox;display:flex}.effectContainer__2GgJM{display:-ms-flexbox;display:flex}", ""]);

// exports
exports.locals = {
	"keyword": "keyword__YgK68",
	"operationContainer": "operationContainer__1FOfu",
	"nodeTitle": "nodeTitle__3BgN6",
	"nodeName": "nodeName__2RoRd",
	"hoveringToNavigate": "hoveringToNavigate__1KwzW",
	"casesContainer": "casesContainer__2Vlwx",
	"whenBranch": "whenBranch__3Ob5H",
	"otherwiseBranch": "otherwiseBranch__160eP",
	"effectContainer": "effectContainer__2GgJM"
};