// TODO: we probably want to move this functions to a generic place
// A second ideal step would be that we don't need a function they we can get
// it from the schema "relationship"

/**
 * ConnectionFn that sets the parent/child relationship
 */
export const connectToChildren = (api, newObj, parent) => {
  newObj.insert_after(parent)
}

/**
 * ConnectionFn to set the "idle" chain of a choices
 */
export const connectToChoicesIdle = (api, newObj, parent) => {
  parent.set_idle(newObj)
}

const insertAfterId = (id, sys, connectionFn = connectToChildren) => api => {
  const newObj = api[sys].new()
  connectionFn(api, newObj, api.obj.get(id))
  return newObj
}

export default insertAfterId