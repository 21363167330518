import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from 'antd'
import classNames from 'classnames'

import { urlForImage } from 'model/resources'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { token as tokenSelector } from 'selectors/auth'
import { useAvatarCacheForFileName } from '../PlaybackView/CurrentPlayback/AvatarCache'

import styles from './ActorAvatar.scss'


const ActorAvatar = ({ token, revisionId, fileName, children, className, size = 'large', onError }) => (
  <Avatar
    shape="square"
    size={size}
    className={className}
    {...fileName && { src: urlForImage(token, revisionId, fileName) }}

    onError={onError}
  >
    {children}
  </Avatar>
)

const ActorAvatarContainer = ({ fileName, ...props }) => {
  const revisionId = useSelector(revisionIdSelector)
  const token = useSelector(tokenSelector)

  const [fileNameAvoidingErrored, onError] = useAvatarCacheForFileName(fileName)

  return (
    <ActorAvatar
      revisionId={revisionId}
      token={token}
      onError={onError}
      {...props}
      fileName={fileNameAvoidingErrored}
    />
  )
}

export default ActorAvatarContainer

// This should become the default component, the one up here is more "low-level"

export const ActorIcon = ({ actor, size, label = true, className, active = true }) => (actor.data.actorIcon ?
  <div className={classNames(styles.actorAvatar, className, { [styles.active]: active })}>
    <ActorAvatarContainer fileName={actor.data.actorIcon} className={styles.avatar} size={size} />
    {label && <span className={styles.actorAvatarLabel}>{actor.data.actorName}</span>}
  </div>
  : <ActorAvatarContainer className={classNames(styles.avatarNoIcon, { [styles.active]: active })} size={size}>{actor.data.actorName}</ActorAvatarContainer>
)