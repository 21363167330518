import React from 'react'
import { compose, withProps } from 'recompose'
import { propEq } from 'ramda'

import IconButton from 'components/Commons/IconButton'
import NodeText from '../../Commons/NodeText'
import ChainTextView from './ChainTextView'

import styles from './ContainerTextView.scss'

const _CurrentComponent = ({ component, moveComponent, moveRightEnabled, moveLeftEnabled, ...others }) => (
  <div className={styles.content}>
    <div className={styles.currentComponentPanel}>
      <IconButton className={styles.arrow} type="left" onClick={() => moveComponent(-1)} disabled={!moveLeftEnabled} />
      <div><NodeText node={component} /></div>
      <IconButton className={styles.arrow} type="right" onClick={() => moveComponent(+1)} disabled={!moveRightEnabled} />
    </div>
    <ChainTextView {...others} object={component} />
  </div>
)

export default compose(
  withProps(({ component, components }) => {
    const i = components.findIndex(propEq('id', component.id))
    return {
      moveRightEnabled: i < components.length - 1,
      moveLeftEnabled: i > 0,
    }
  })
)(_CurrentComponent)