import React from 'react'
import classNames from 'classnames'
import { Table } from 'antd'
import { isOrgOwner } from 'security/commons'
import { isSuperAdmin } from 'security/platform'

import UserAvatar from 'components/RevisionSessions/UserAvatar'
import TeamMemberActions from './TeamMemberActions'
import NoEffectWarning from './NoEffectWarning'

import SectionElementsTable from '../../Section/SectionElementsTable/SectionElementsTable'

import styles from './TeamMembersTable.scss'

const { Column } = Table

const TeamMembersTable = ({ data, pageSize, onPageChanged, refreshItems, handleTableChange }) => (
  <SectionElementsTable
    data={data}
    dataPath={['team', 'members']}
    rowKeyPath="user._id"

    rowClassName={member => classNames({
      [styles.hasNoEffect]: isOrgOwner(data.team.organization, member.user) || isSuperAdmin(member.user)
    })}
    actions={member => <TeamMemberActions team={data.team} member={member} refreshItems={refreshItems} />}

    pageSize={pageSize}
    onPageChanged={onPageChanged}
    handleTableChange={handleTableChange}
    refreshItems={refreshItems}
  >
    <Column
      title="User"
      key="user.firstName"
      sorter
      render={member => (
        <div className={styles.userColumn}>
          <UserAvatar user={member.user} contextMenuEnabled={false} />
          <span>{member.user.firstName} {member.user.lastName}</span>
          {isOrgOwner(data.team.organization, member.user) &&
            <span className={styles.orgOwnerLabel}>(Org Owner)</span>
          }
        </div>
      )}
    />
    <Column
      title="Role"
      key="role"
      sorter
      render={member => (
        <div className={styles.roleColumn}>
          <NoEffectWarning member={member} organization={data.team.organization} />Team {member.role}
        </div>
      )}
    />
  </SectionElementsTable>
)

export default TeamMembersTable