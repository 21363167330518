import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Input } from 'antd'
import { createDebugScenario } from 'actions/project/debuggingData'
import CreateModal from '../Commons/CreateModal'
import { withTargetValue } from 'utils/antd'

import styles from './PinsPanel.scss'

const CreateScenarioModal = ({ hide }) => {
  const [name, setName] = useState('')
  const dispatch = useDispatch()
  const submit = () => dispatch(createDebugScenario(name))
  return (
    <CreateModal
      title="Create a new debug scenario"
      hide={hide}
      submit={submit}
      canSubmit
    >
      {() =>
        (
          <div className={styles.createModalContent}>
            <span className={styles.rowTitle}>Name</span>
            <Input
              className={styles.input}
              placeholder="Scenario name"
              value={name}
              onChange={withTargetValue(setName)}
              onPressEnter={() => { submit(); hide() }} />
          </div>
        )}
    </CreateModal>
  )
}

export default CreateScenarioModal