import fetch from 'isomorphic-fetch'
import { indexBy, prop } from 'ramda'
import { fetchPaginated, FetchPolicy } from '../utils/graphql'
import { EMPTY_ARRAY } from '../utils/object'

import fetchRevisionQuery from 'api/queries/fetchRevision.graphql'
import fetchDeltaChangeSetsQuery from 'api/queries/fetchDeltaChangeSets.graphql'

/**
 * Encapsulates communication with the Studio API (backend)
 */
export default class StudioAPI {

  constructor(getClient, getToken) {
    this.getClient = getClient
    this.getToken = getToken
  }

  /**
   * Fetches revision data. Many things but NOT the BNEObjects !
   * Since we cache them and fetch them separated from other data of the revision.
   * BNEObjects can be fast-forwarded with changeSets but not other objects.
   */
  async getRevisionData(revisionId) {
    const { data: { revisionWithId: revision } } = await this.getClient().query({
      query: fetchRevisionQuery,
      variables: { revisionId },
      fetchPolicy: FetchPolicy.NO_CACHE
    })
    return revision
  }

  /**
   * We fetch objects using a REST endpoint which is faster than using graphql because
   * it allows us to "stream" objects from mongo.
   * Returns an index by id with all the objects
   */
  async getRevisionBNEObjects(revisionId) {
    const token = this.getToken()
    if (!token) throw new Error('You are not logged in (no token)!')
    // TODO: maybe only use window.location for tests ?
    const r = await fetch(`${window.location.origin}/api/projects/bneobjects/${revisionId}`, {
      method: 'GET',
      headers: {
        ...token && { Authorization: `Bearer ${token}` }
      }
    })

    if (r.status !== 200) {
      throw new Error((await r.json()).message || await r.body())
    }
    const objects = await r.json()

    return indexBy(prop('id'), objects || EMPTY_ARRAY)
  }

  /**
   * Fetches and processes a revision's ChangeSets after certain timestamp.
   * As there could be many and many ChangeSets this method doesn't return them
   * but instead if processes chunks (lists) of changeSets pushed to the given "mapper"
   */
  async mapRevisionChangeSets(revisionId, afterTimestamp, mapper) {
    await fetchPaginated(
      async page => {
        const { data: { revisionWithId: { changeSets } } } = await this.getClient().query({
          query: fetchDeltaChangeSetsQuery,
          variables: {
            revisionId,
            after: afterTimestamp,
            page,
          },
          fetchPolicy: FetchPolicy.NO_CACHE,
        })
        return changeSets
      },
      mapper
    )
  }

}