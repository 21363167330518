import React, { useMemo } from 'react'

import { Sys } from 'beanie-engine-api-js'
import { useSelector } from 'react-redux'
import { makePlaybackNodeById } from '../../../selectors/playback'
import CurrentClipPlayback from './nodes/clip/CurrentClipPlayback'
import CurrentChoicesPlayback from './nodes/choices/CurrentChoicesPlayback'
import CurrentChoices2Playback from './nodes/choices2/CurrentChoices2Playback'
import NonPresentablePlayback from './nodes/CurrentNonPresentablePlayback'

import styles from './CurrentNodePlayback.scss'

const ComponentsBySys = {
  [Sys.clip]: CurrentClipPlayback,
  [Sys.choices]: CurrentChoicesPlayback,
  [Sys.choices2]: CurrentChoices2Playback,
}

/**
 *
 */
const CurrentNode = props => {
  const { playbackId } = props
  const selector = useMemo(() => makePlaybackNodeById(playbackId), [playbackId])
  const playbackNode = useSelector(selector)

  // PERFORMANCE: disabled cause  useIsForcedToExecute (its selector) hurts playback perf on conditionals
  // const nodeId = playbackNode.id || playbackNode.nodeId
  // const isForcedExecution = useIsForcedToExecute(nodeId)
  const isForcedExecution = false

  const Component = ComponentsBySys[playbackNode.sys] || NonPresentablePlayback

  return isForcedExecution ? (
    <div className={styles.forcedExecutionContainer}>
      <span className={styles.pinnedNode}>pinned</span>
      <Component node={playbackNode} {...props} />
    </div>
  ) : <Component node={playbackNode} {...props} />
}

export default CurrentNode