import React from 'react'
import TeamBreadcrumb from './TeamBreadcrumb'
import EntityMenu from './EntityMenu'

import styles from './EntityHeader.scss'

const EntityHeader = ({ organizationName, teamName, currentSection, sections }) => (
  <div className={styles.teamHeader}>
    <TeamBreadcrumb organizationName={organizationName} teamName={teamName} />
    <EntityMenu currentSection={currentSection} sections={sections} />
  </div>
)

export default EntityHeader