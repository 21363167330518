import {
  pathEq,
  pathOr,
  filter,
  reject,
  propEq,
  pipe,
  map,
  descend,
  prop,
  sortWith,
  ascend,
  path
} from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'
import { createSelector } from 'selectors/reselect'
import { user } from 'selectors/auth'
import SessionClientType from 'model/constants/SessionClientType'
import RevisionConnectionState from '../../model/app/revision/RevisionConnectionState'
import { fromCode } from '../../model/constants/SessionClientType'

export const ourSession = pathOr(EMPTY_OBJECT, ['project', 'session'])
export const sessions = pathOr(EMPTY_OBJECT, ['project', 'sessions'])
export const sessionsList = createSelector('sessionsList', [sessions], Object.values)

export const otherUserSessions = createSelector(
  'otherUserSessions',
  [sessionsList, user],
  (allSessions, ourUser) => (ourUser ? reject(pathEq(['user', '_id'], ourUser._id), allSessions) : EMPTY_ARRAY)
)

// our session state
export const isRevisionSessionConnected = pathEq(['project', 'connectionState'], RevisionConnectionState.CONNECTED)
export const isRevisionSessionConnecting = pathEq(['project', 'connectionState'], RevisionConnectionState.CONNECTING)
export const isRevisionSessionDisconnected = pathEq(['project', 'connectionState'], RevisionConnectionState.DISCONNECTED)
export const isRevisionSessionReconnecting = pathEq(['project', 'connectionState'], RevisionConnectionState.RECONNECTING)

export const otherSessionsFromUs = createSelector(
  'otherSessionsFromUs',
  [sessionsList, user],
  (allSessions, ourUser) => (ourUser ?
    pipe(
      filter(pathEq(['user', '_id'], ourUser._id)),
      sortWith([
        ascend(prop('clientType')),
        descend(prop('createdAt'))
      ]),
    )(allSessions)
    : EMPTY_ARRAY
  )
)

/**
 * Returns a list of SessionClientType based on our current sessions (for this user)
 * Filters:
 *   - sessions from other users
 *   - frontend sessions
 *   - repeated
 *   - unknown clientTypes (for the front. Known ones are in 'SessionClientType')
 */
export const otherSessionClientTypes = createSelector(
  'otherSessionClientTypes',
  [otherSessionsFromUs],
  pipe(
    all => all.reduce((acc, session) => {
      if (session.clientType !== SessionClientType.StudioFrontend.code) {
        acc[session.clientType] = 1
      }
      return acc
    }, {}),
    Object.keys,
    // here we are already filtering those clientTypes that our unknown to us
    map(fromCode),
    filter(isNotNil),
  )
)

export const makeSessionForClientTypeSelector = clientTypeSelector => createSelector(
  [clientTypeSelector, otherSessionsFromUs],
  (clientType, otherSessions) => otherSessions.find(propEq('clientType', clientType.code))
)

export const ourUnrealSessions = createSelector(
  [otherSessionsFromUs],
  filter(propEq('clientType', SessionClientType.Unreal.code)),
)

const ourUnrealSessionId = path(['project', 'boundUnrealSession'])
export const ourUnrealSession = createSelector(
  [ourUnrealSessionId, sessions],
  (id, all) => id && all[id]
)