exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".source__2TL5v{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding-left:1rem;padding-top:1rem;-ms-flex-align:center;align-items:center}.source__2TL5v>div:first-child{font-size:1rem;margin-right:1rem}", ""]);

// exports
exports.locals = {
	"source": "source__2TL5v"
};