import { Sys, ref } from 'beanie-engine-api-js'
import { map, forEach, toString, cond, propEq, T, slice, allPass, isNil, pipe, prop, includes, equals, isEmpty, any } from 'ramda'
import { isString, isBoolean as isLiteralBoolean } from 'ramda-adjunct'
import {
  sourceContext,
  currentTextNode,
  isSpecialVariable,
  firstSibilingToLeft,
  isIsKeyword,
  isToKeyword,
  isProperty,
  isFactVariable,
  isNodeRef,
} from './TreeUtils'
import { EMPTY_ARRAY } from 'utils/object'
import { objectsIndex } from 'selectors/apollo'
import { isBoolean } from 'components/PropertiesEditor/Value/inferValue'
import { factCompletion, keywordCompletion, objCompletion, Types, valueCompletion, labelOption, variableCompletion, objCompletionWithDelimiters } from './Completion'
import { snippets } from './Snippets'
import { constantValues } from './RuleLanguageExtension'
import {
  isVariableTemplate,
  isNodeKeywordTemplate,
  isNodeReferenceTemplate,
  isVariableToSetTemplate,
  isVariableValueTemplate,
  isLastChoosenIsTemplate,
  isFactValueTemplate,
  isVariableValueToAssignTemplate
} from './AutocompletePatterns'

const variableOptions = (vars, ctx) => map(v => variableCompletion(v, ctx.isError), vars)
const factOptions = (facts, ctx) => map(f => factCompletion(f, ctx.isError), facts)
const keywordOptions = (keywords, upperCase, withSpace) => keywords.map(keywordCompletion(upperCase, withSpace))

const isSetType = propEq('type', 'Set')
const isUnionType = propEq('type', 'Union')

const hasAnySet = ({ types }) => any(isSetType, types)

const optionsByType = cond([
  [isSetType, prop('elements')],
  [allPass([isUnionType, hasAnySet]), ({ types }) => {
    const set = types.find(isSetType)
    return prop('elements', set)
  }],
  [T, () => EMPTY_ARRAY]
])

export const completeKeywords = (suggestions, upperCase = false) => editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  return makeSuggestion(ctx, keywordOptions(suggestions, upperCase, ctx.isError))
}

export const completionTemplates = editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  return makeSuggestion(ctx, snippets(ctx.isError))
}

export const exprLabelToString = (val = '') => {
  if (isNil(val)) return 'nothing'
  if (isEmpty(val)) return '""'
  if (isLiteralBoolean(val)) return toString(val)
  if (!isNaN(val)) return toString(parseInt(val))
  if (isBoolean(val)) return val
  if (isString(val)) return `"${val}"`

  return val
}

const autocompleteByOptions = (transform, detail = 'value', type = Types.Value) => (options, withSpace) =>
  map(valueCompletion(transform, detail, type, withSpace), options)

const autocompleteVariableOptions = autocompleteByOptions(exprLabelToString)

const autocompleteSuggestionsByType = (type, isError) => autocompleteVariableOptions(optionsByType(type), isError)

const autocompleteNodeRefsOptions = (labels, isValid, { isError }) => {
  const completions = []
  forEach(obj => {
    if (isValid(obj)) {
      completions.push(objCompletion(obj, isError))
      completions.push(objCompletionWithDelimiters(obj, isError))
    } 
  }, labels)
  return completions
}

const objectRefFilterByContext = (store, state, ctx) => {
  const { current, leftSibling } = ctx

  if (isNodeKeywordTemplate({ state }, ctx)) {
    const propertyText = state.sliceDoc(current.parent.from, current.from).trim()
    if (equals(propertyText, 'choose')) return propEq('sys', Sys.choice)
    if (equals(propertyText, 'last_chosen')) return propEq('sys', Sys.choices)

    return T
  }

  if (isLastChoosenIsTemplate({ state }, ctx)) {
    const refNode = firstSibilingToLeft(leftSibling, false)
    const id = currentTextNode(state, refNode).slice(1, -1)
    const choices = objectsIndex(store.getState())[id]

    if (isNil(choices)) return propEq('sys', Sys.choice)

    return allPass([
      propEq('sys', Sys.choice),
      pipe(prop('id'), _id => includes(ref(_id), choices.data.container_contents))
    ])
  }

  // isPropertyOfTemplate
  return T
}

const autocompleteNodeRefsOptionsByContext = (store, state, ctx, objectLabels) =>
  autocompleteNodeRefsOptions(objectLabels, objectRefFilterByContext(store, state, ctx), ctx)

export const makeSuggestion = ({ from, to }, options) => ({ from, to, options, span: /^[\w-]*/ })

const variableSource = (state, sibling) => {
  const text = currentTextNode(state, sibling)
  return isSpecialVariable(sibling) ? slice(2, -2, text) : text
}

export const variableValuesOptions = (editorContext, { isError, leftSibling }, varsValues) => {
  let options = EMPTY_ARRAY

  if (isIsKeyword(leftSibling) || isToKeyword(leftSibling)) {
    const nextLeftSibling = firstSibilingToLeft(leftSibling, true)
    const variableText = variableSource(editorContext.state, nextLeftSibling)
    options = varsValues?.[variableText] || EMPTY_ARRAY
  }

  return autocompleteVariableOptions(options, isError)
}

export const variableValueSuggestions = (editorContext, ctx, varsValues) =>
  makeSuggestion(ctx, variableValuesOptions(editorContext, ctx, varsValues))

export const factValuesSuggestions = (factTypesIndex, { state }, { isError, leftSibling }) => {
  const varNode = isFactVariable(leftSibling) ? leftSibling : firstSibilingToLeft(leftSibling, true)
  const factName = currentTextNode(state, varNode)
  return factName && factTypesIndex[factName] ? autocompleteSuggestionsByType(factTypesIndex[factName], isError) : EMPTY_ARRAY
}

export const nodeReferenceSuggestions = (store, { state }, ctx, findMatchRefsPromise) => {
  const { current } = ctx
  const text = isProperty(current) ? ' ' : currentTextNode(state, current)

  // findMatchRefs is a promise and here we are returning a new promise that returns a CompletionResult
  const textToMatch = isNodeRef(current) ? text.slice(1, -1) : text
  return findMatchRefsPromise(textToMatch).then(
    objectLabels => makeSuggestion(ctx, autocompleteNodeRefsOptionsByContext(store, state, ctx, objectLabels))
  )
}

const emptyAutocomplete = editorContext => {
  const { from, to } = sourceContext(editorContext.state, editorContext.pos)
  return ({ from, to, options: EMPTY_ARRAY })
}

// Autocompletes implementations

// TODO: these functions need a redesign wrapping the autocompletes funtions by a better abstraction.

export const contextCompletionOptions = (store, possibleStaticVariablesValues, findMatchRefsPromise) => editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  return cond([
    [isVariableValueTemplate, () => variableValueSuggestions(editorContext, ctx, possibleStaticVariablesValues)],
    [isNodeReferenceTemplate, () => nodeReferenceSuggestions(store, editorContext, ctx, findMatchRefsPromise)],
    [T, emptyAutocomplete],
  ])(editorContext, ctx)
}

export const factValuesCompletion = factTypesIndex => editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  let options = []

  if (isFactValueTemplate(editorContext, ctx)) {
    options = factValuesSuggestions(factTypesIndex, editorContext, ctx)
  }

  return makeSuggestion(ctx, options)
}

export const contextCompletionAssignments = (store, possibleStaticVariablesValues, variables) => editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  let options = []

  if (isVariableToSetTemplate(ctx)) {
    options = options.concat(variableOptions(variables, ctx))
  }

  if (isVariableValueToAssignTemplate(ctx)) {
    options = options.concat(
      variableValuesOptions(editorContext, ctx, possibleStaticVariablesValues)
    )
  }

  return makeSuggestion(ctx, options)
}

export const contextCompletionVariables = (variables, factNames) => editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  return cond([
    [isVariableTemplate, () => makeSuggestion(ctx, [
      ...variableOptions(variables, ctx),
      ...factOptions(factNames, ctx)])
    ],
    [T, emptyAutocomplete],
  ])(editorContext)
}

export const completeConstantValues = editorContext => {
  const ctx = sourceContext(editorContext.state, editorContext.pos)
  return makeSuggestion(ctx, constantValues.map(label => labelOption(label, Types.Value, Types.Value, ctx.isError)))
}
