import React from 'react'
import { Form, Select as AntdSelect } from 'antd'

const FormItem = Form.Item
const { Option } = AntdSelect

// TODO: use contexts to receive the getFieldDecorator

const Select = ({ placeholder, fieldName, initialValue, options, getFieldDecorator, required = false, labelRenderer }) => (
  <FormItem label={fieldName}>
    {getFieldDecorator(fieldName, {
      initialValue,
      rules: required ? [{ required: true, message: 'Required!' }] : [],
    })(
      <AntdSelect placeholder={placeholder}>
        {options.map(o => (
          <Option key={o.value} value={o.value}>{labelRenderer ? labelRenderer(o.label) : o.label}</Option>
        ))}
      </AntdSelect>
    )}
  </FormItem>
)

export default Select