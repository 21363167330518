export default Object.freeze({
  loaded: false,
  loading: false,

  playback: {
    // TODO: avoiding a circular dep by copying value. Maybe defaulting to 'INITIAL' when no value in the selector does the trick.
    status: 'INITIAL',
    playbackIndex: {}, // is a map of playbackId -> playbackItem (playbackItem is an object with nodeId, playbackId, status, state, startTime)
    playbackQueue: [], // should become a list of playbackIds like playbackQueue at electron app

    state: {
      /**
       * Current state as constructed according to beanie VM state events
       */
      current: {},
      /**
       * Keys which were modified since we loaded "current" and started to play.
       * Allows to identify which variables have changed and show feedback in the variables view.
       */
      currentDirty: {},

      initial: {},

      /**
       * All the state events we received from the beanie VM.
       * This gives us a lot of power to implement time-machine and move back and forth between
       * different playthrough points
       */
      events: [],
    },

    // the current storyboard which gets here sticky until reaching another line that has a storyboard set.
    currentStoryboard: {},

    /**
     * User configuration data to debug the playthrough for example with pause points.
     * It could also include variable breakpoints, watch expressions and stuff like.
     * Also being able to fast-forward until the next choices
     */
    debug: {
      on: true,

      pausePoints: {},

    }
  },

  transactions: {
    options: {
      use_dirty_interceptor: true
    },
  }
})