import React from 'react'

import { MenuItem } from '@blueprintjs/core'
import ExtensionPoint from 'components/ExtensionPoints/ExtensionPoint'

import styles from './ContextMenu.scss'

const ContextMenuExtensionPoint = ({ point }) => (
  <ExtensionPoint
    className={styles.ContextMenuExtensionPoint}
    point={point}
    renderExtension={({ id, label, action, icon }, { extAsBneAction }) => (
      <MenuItem
        key={id}
        text={label}
        onClick={extAsBneAction(action)}
        icon={icon}
    />)}
  />
)

export default ContextMenuExtensionPoint