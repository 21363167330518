import React from 'react'

import { Popconfirm } from 'antd'
import IconButton from 'components/IconButton/IconButton'
import TableRowActions from 'components/Commons/TableRowActions'
import EditBuildConfig from './EditBuildConfig'


const BuildConfigsRowActions = ({ buildConfig, onBuildConfigUpdated, deleteBuildConfig, runtimeData }) => {
  return (
    <TableRowActions>
      <EditBuildConfig buildConfig={buildConfig} onAccept={onBuildConfigUpdated} runtimeData={runtimeData} />
      <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => deleteBuildConfig(buildConfig._id)}>
        <IconButton icon="delete" tooltip={`Delete '${buildConfig.name}'`} tooltipPlacement="bottom" />
      </Popconfirm>
    </TableRowActions>
  )
}


export default BuildConfigsRowActions
