import { isEmpty } from 'ramda'

import { removedNodesFromChangeSet } from 'model/ChangeSet'
import { redoConflictingChangeSets } from 'model/changeSet/conflictDetection'

import { lastRedoableOwnChange, lastUndoFromChangeSet, futureCleanHistoryFrom } from 'selectors/undo'
import { canSafelyApplyChangeSet } from 'selectors/sync'
import { updateSelection } from '../selection/updateSelection'

import {
  REDO_SUCCESS_MESSAGE,
  REDO_OVERLAPS_MESSAGE,
  NOTHING_TO_REDO_ERROR,
  CONFLICT_ERROR,
  NOT_SYNCHRONIZED_ERROR,
} from './constants'
import { undoOrRedo } from './undo'

const redoRestoreSelection = async (changeSet, dispatch, getState) =>
  updateSelection(removedNodesFromChangeSet(changeSet), dispatch, getState)

export const redoAction = async (dispatch, getState, { synchronizer }) => {
  if (canSafelyApplyChangeSet(getState())) {
    const toRedo = lastRedoableOwnChange(getState())
    if (toRedo) {
      const lastUndoCS = lastUndoFromChangeSet(getState(), toRedo)
      const nextChangeSets = futureCleanHistoryFrom(getState(), (lastUndoCS || toRedo).timestamp)
      const redoConflicts = redoConflictingChangeSets(nextChangeSets, toRedo)
      if (isEmpty(redoConflicts)) {
        const redoChangeSet = await synchronizer.getServerStore().redo(toRedo._id)
        await redoRestoreSelection(toRedo, dispatch, getState)
        return redoChangeSet
      } else {
        throw CONFLICT_ERROR
        // RESOLVE REDO CONFLICTS
      }
    } else {
      throw NOTHING_TO_REDO_ERROR
    }
  } else {
    throw NOT_SYNCHRONIZED_ERROR
  }
}

export const redo = undoOrRedo(redoAction, REDO_SUCCESS_MESSAGE, REDO_OVERLAPS_MESSAGE, 'redo')