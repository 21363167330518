import { connect } from 'react-redux'
import { preference } from 'selectors/view'
import { mapObjIndexed, applySpec } from 'ramda'

/**
 * Injects one or many preferences (@see src/preferences/Preferences.js) as props to the decorated component.
 * Usage is:
 *    withPreference({
 *       [propName1]: preferenceDefinitionObject,
 *       [propName2]: preferenceDefinitionObject,
 *    }),
 *
 * For example
 *    withPreference({
 *       visibleMarkups: Preferences.TextEditor.visibleMarkups
 *    }),
 *
 * Inserts the prop with name `visibleMarkups`
 */
const withPreference = preferencesObject =>
  connect(() => applySpec(mapObjIndexed(preference, preferencesObject)))

export default withPreference