import { batch } from 'react-redux'
import { clone, flip, subtract, toLower } from 'ramda'

import { STATE_EVENT_TYPE } from 'beanie-engine-api-js'

import { FetchPolicy } from 'utils/graphql'
import { toBNEAction } from './utils'
import { selectedNode } from 'selectors/nodeSelection'
import { isExecutingChainExecutions } from 'selectors/state/executions'
import { Creators, createState } from 'actions/state'
import { Creators as vmCreators } from 'actions/vm'
import { stopAll as stopAllAudio } from 'actions/audio'
import { makeSavegameForPreset } from 'selectors/presets'
import { projectId } from 'selectors/project'
import { events, initialState } from 'selectors/state'

import savegameQuery from 'api/queries/savegame.graphql'
import { updatePath } from 'utils/object'

const { clearState: clearReduxState } = Creators

const _clearState = () => (api, dispatch) => {
  api.state.clear()
  dispatch(clearReduxState())
}
export const clearState = toBNEAction(_clearState)

export const setProjectVariable = toBNEAction((name, value) => api => {
  api.project.set(name, value)
}, 'Set project variable')

export const setSelectedNodeVariable = toBNEAction((name, value) => (api, dispatch, getState) => {
  api.state.set(selectedNode(getState()), name, value)
}, 'Set object variable'
)

export const importSavegame = toBNEAction(
  (slot, data) => async (api, dispatch, getState) =>
    batch(async () => {
      const gameId = projectId(getState())
      const savegame = await dispatch(createState(gameId, slot, data))
      await doLoadSavegame(savegame, false)(api, dispatch, getState)
    })
)

export const setBneState = toBNEAction(stateData =>
  doLoadingState(
    (state, dispatch) => {
      dispatch(Creators.setState(state))
    },
    stateData
  )
)

export const saveJobsState = toBNEAction(
  () => api => {
    api.execution_manager.update()
  }
)

const fetchSaveGame = async (client, { gameId, slot }) => {
  // TODO: error handling ? anyone ?
  const { data: { savegame } } = await client.query({
    query: savegameQuery,
    variables: { gameId, slot },
    fetchPolicy: FetchPolicy.NO_CACHE,
  })
  return savegame
}

const doLoadingState = (doWhileLoading = () => {}, state) => (api, dispatch, getState) => {
  batch(() => {
    dispatch(vmCreators.stopPlayback())
    api.state.restore(state)

    doWhileLoading(state, dispatch)

    if (isExecutingChainExecutions(getState())) {
      api.execution_manager.restore_from_state()
      dispatch(vmCreators.pausePlayback())
    }
    dispatch(vmCreators.playbackRewind()) // clears playback history
  })
}

const doLoadSavegame = (fullSaveGame, setCurrent = true) =>
  doLoadingState(
    (state, dispatch) => {
      if (setCurrent) {
        dispatch(Creators.setCurrentSavegame(fullSaveGame))
        dispatch(Creators.setInitialState(state))
      }
    },
    fullSaveGame.data
  )


export const loadSavegame = toBNEAction(savegame => async (api, dispatch, getState, { getApolloClient }) => {
  const fullSaveGame = savegame ? await fetchSaveGame(getApolloClient(), savegame) : undefined
  if (!fullSaveGame) return // TODO: error handling?
  doLoadSavegame(fullSaveGame)(api, dispatch, getState)
})

// regular thunk is placed here because putting it under src/actions/state.js would create a circular dep
export const loadSavegameFromPreset = preset => async (dispatch, getState) => {
  const savegame = makeSavegameForPreset()(getState(), { preset })
  if (!savegame) return
  await dispatch(loadSavegame(savegame))
}

// (playbackNode, dispatch, getState) -> newState
const recreatePlaybackStateAtPoint = (playbackNode, dispatch, getState) => {
  const { state: { atBeginNode: { stateEventsLength } } } = playbackNode
  const state = getState()
  dispatch(vmCreators.pausePlayback())
  dispatch(stopAllAudio(true))

  // TODO: Optimize how we reconstruct the state
  // we could do that using events from begin to end or from end to begin
  // or if we are time traveling from intermediate points we could reuse previous
  // state and apply events from that event on (or back)

  // calculate restored state
  return recreateStateAtPointInTime(events(state), stateEventsLength, initialState(state))
}

export const restoreStateToPointInHistory = toBNEAction(playbackNode => (api, dispatch, getState) => {
  batch(() => {
    const reconstructedState = recreatePlaybackStateAtPoint(playbackNode, dispatch, getState)
    dispatch(vmCreators.setCurrentPlaybackId(playbackNode.playbackId))
    dispatch(vmCreators.setRestoreInspecting(true))
    dispatch(Creators.setState(reconstructedState))
    // TODO: remove this when reconstructed state includes reconstructed dirty, also remove the action and reducer
    dispatch(Creators.setStateDirty({ __forceDirty: true }))
    api.state.restore(reconstructedState)
    api.execution_manager.restore_from_state()
  })
}, 'Restore Playback State')

// -1 to transform lengths into indexes
const lengthToIdx = flip(subtract)(1)
export /* to test */ const recreateStateAtPointInTime = (evts, stateEventsLengthAtPoint, baseState) => {
  const maxIndex = lengthToIdx(evts.length)
  const targetIndex = lengthToIdx(stateEventsLengthAtPoint)
  const newIndex = maxIndex - targetIndex

  return restoreState(evts, newIndex, maxIndex, baseState)
}

const restoreState = (evts, newIndex, maxIndex, newState = {}) => {
  let workingState = clone(newState)
  for (let i = maxIndex; i >= newIndex; i--) {
    // This fn mutates, but sometimes returns new (empty) instance
    workingState = applyEventToState(evts[i], workingState)
  }
  return workingState
}

const applyEventToState = ({ eventType, nodeId, field, value }, state) => {
  switch (eventType) {
    case STATE_EVENT_TYPE.UPDATE: updatePath(state, `${nodeId}.${toLower(field)}`, value); break
    case STATE_EVENT_TYPE.CLEAR_ALL: state = {}; break // DO NOT USE EMPTY_OBJECT BECAUSE state WILL BE MUTATED
    // CLEAR should be ignored to provide same history/accumulative effect than during playback
    case STATE_EVENT_TYPE.CLEAR: break
    default: throw new Error(`Unknown event type: ${eventType}`)
  }
  return state
}

