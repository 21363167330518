import React from 'react'
import { pathEq } from 'ramda'
import { Alert } from 'antd'
import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'

import { ProjectOwnerType, ProjectGrantToType } from 'utils/authentication'

import projectGrantAccessMutation from 'api/mutations/projectGrantAccess.graphql'
import projectRevokeGrantMutation from 'api/mutations/projectRevokeGrant.graphql'
import projectChangeGrantRoleMutation from 'api/mutations/projectChangeGrantRole.graphql'

import TeamSelectOption from 'components/Organizations/Teams/Commons/TeamSelectOption'
import UserSelectOption from 'components/Commons/UserSelectOption'
import GrantsList from '../GrantsList/GrantsList'
import TeamGrantsMenu from '../GrantsList/TeamGrantsMenu'
import UserGrantsMenu from '../GrantsList/UserGrantsMenu'

import styles from './AccessSection.scss'

const isOrgOwnedProject = pathEq(['projectWithId', 'owner', '__typename'], ProjectOwnerType.Organization)

const AccessSection = ({ project, projectData, grantAccess, revokeGrant, changeGrantRole, isOrgProject = isOrgOwnedProject(projectData) }) => (
  <div className={styles.content}>
    {!isOrgProject &&
      <Alert
        type="warning"
        message="Personal projects cannot be shared with other users anymore. You won't be able to invite new users to this project and we encourage you to revoke permissions. In order to share a project with other users you should use organizations and teams. Make sure that you transfer the project to the organization and then you will be able to manage permissions."
      />
    }
    {isOrgProject &&
      <GrantsList
        title="Teams"
        filter={pathEq(['to', '__typename'], ProjectGrantToType.Team)}
        project={project}
        projectData={projectData}
        revokeGrant={revokeGrant}
        changeGrantRole={changeGrantRole}
        itemRenderer={TeamGrant}
        menu={<TeamGrantsMenu project={projectData.projectWithId} grantAccess={grantAccess} />}
        />
    }
    <GrantsList
      title="Users"
      filter={pathEq(['to', '__typename'], ProjectGrantToType.User)}
      project={project}
      projectData={projectData}
      revokeGrant={revokeGrant}
      changeGrantRole={changeGrantRole}
      itemRenderer={UserGrant}
      menu={<UserGrantsMenu project={projectData.projectWithId} grantAccess={grantAccess} />}
    />
  </div>
)

const UserGrant = ({ grant }) => <UserSelectOption user={grant.to} />
const TeamGrant = ({ grant }) => <TeamSelectOption team={grant.to} />

export default compose(
  graphql(projectGrantAccessMutation, { name: 'projectGrantAccess' }),
  graphql(projectRevokeGrantMutation, { name: 'projectRevokeGrant' }),
  graphql(projectChangeGrantRoleMutation, { name: 'projectChangeGrantRole' }),
  withHandlers({
    grantAccess: ({ projectGrantAccess, refreshData }) => async (project, to, toType, role) => {
      await projectGrantAccess({
        variables: { input: { projectId: project._id, to, toType, role } }
      })
      await refreshData()
    },
    revokeGrant: ({ projectRevokeGrant, projectData, refreshData }) => async to => {
      await projectRevokeGrant({
        variables: { input: { projectId: projectData.projectWithId._id, to } }
      })
      await refreshData()
    },
    changeGrantRole: ({ projectChangeGrantRole, projectData: { projectWithId: { _id: projectId } }, refreshData }) => entityId => async ({ Role: role }) => {
      await projectChangeGrantRole({
        variables: { input: { projectId, to: entityId, role } }
      })
      await refreshData()
    }
    // TODO: change role here
  })
)(AccessSection)