import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Icon, Tooltip } from 'antd'

import useHover from 'hooks/useHover'
import { restoreStateToPointInHistory } from 'engine/actions/state'

import PlaybackNode from './PlaybackNode'

import styles from './PlaybackHistory.scss'
import classNames from 'classnames'

const PlaybackHistoryItem = ({ node, onContentAdded, onFinished, onNodeSelected, isAtFutureState, isFirstInFuture }) => {
  const dispatch = useDispatch()
  const [hoverRef, isHovered] = useHover()
  const handleJumpToHistory = useCallback(
    () => dispatch(restoreStateToPointInHistory(node)),
    [node]
  )

  return (
    <React.Fragment>
      <li ref={hoverRef} className={classNames({ [styles.onFuture]: isAtFutureState, [styles.firstOnFuture]: isFirstInFuture })}>
        <div className={styles.playbackListItemTail} />
        { isHovered ?
          <JumpToHistory onJumpToHistory={handleJumpToHistory} />
          : <div className={styles.playbackListItemHead} />
        }
        <PlaybackNode
          node={node}
          onContentAdded={onContentAdded}
          onFinished={onFinished}
          onNodeSelected={onNodeSelected}
          isAtFutureState={isAtFutureState}
        />
      </li>
    </React.Fragment>
  )
}

const JumpToHistory = ({ onJumpToHistory }) => (
  <Tooltip className={styles.jumpToHistory} mouseEnterDelay={1.5} title="Jump to this point in time" placement="bottom">
    <Icon type="history" className={styles.playbackListItemHeadHover} onClick={onJumpToHistory} />
  </Tooltip>
)

export default PlaybackHistoryItem