import { isSelectionAction } from 'actions/selection'

import { selectedNode } from 'selectors/nodeSelection'
import { isLoaded } from 'selectors/project'
import { LOAD_REVISION_END } from 'actions/project'

import updateCurrentObjectMutation from 'api/mutations/updateCurrentObject.graphql'
import { isInvalidRevisionSessionError } from '../graphql/links/queries-and-mutations/revisionSession/RevisionSessionExpiredCheckLink'

const middleware = getApolloClient => store => next => action => {
  const isSelection = isSelectionAction(action)

  const result = next(action)
  if (isSelection) {
    afterSelectionAction(action, store.getState(), getApolloClient())
  }

  // send cursor if we have one after loading
  if (action.type === LOAD_REVISION_END) {
    updateCursor(getApolloClient(), store.getState())
  }
  return result
}

const afterSelectionAction = (action, state, apolloClient) => {
  if (action.name === 'node') {
    updateCursorOnServer(apolloClient, state)
  }
}

const updateCursor = (client, state) => {
  const oid = selectedNode(state)
  if (oid) {
    client.mutate({
      mutation: updateCurrentObjectMutation,
      variables: {
        oid
      }
    }).catch(e => {
      if (isInvalidRevisionSessionError(e)) {
        // this might fail if our session expired.
        // RevisionSessionExpiredCheckLink already catches that and tries to recreate the session
        // but we don't "retry" the operation. That would be the right thing to do !
        // Meanwhile at least we don't want to spam sentry with it.
        // the consequence is that your first cursor change that triggered detecting reconnection
        // will get lost. You will reconnect but other users will still see your previous cursor location
      } else {
        throw e
      }
    })
  }
}

const updateCursorOnServer = (client, state) => {
  // we cannot send the cursor if we haven't loaded yet
  if (isLoaded(state)) {
    updateCursor(client, state)
  }
}

export default middleware