
export const syncSelectionWithNewContent = (selection, oldContent, newContent) => {
  const resolve = resolveNewKey(oldContent, newContent)
  const anchorKey = resolve(selection.getAnchorKey())
  const focusKey = resolve(selection.getFocusKey())

  // maybe we shouldn't keep the original if we were not able to resolve the keys !
  return selection.merge(anchorKey && focusKey ? { anchorKey, focusKey } : {})
}

const resolveNewKey = (oldContent, newContent) => key => {
  const anchorBlock = oldContent.getBlockForKey(key)
  if (!anchorBlock) {
    return undefined
  }
  // there was a line
  const line = anchorBlock.getData().get('line')
  if (line) {
    const newBlock = newContent.getBlocksAsArray().find(block => block.getData().get('line') === line)
    return newBlock && newBlock.getKey()
  }
  return undefined
}