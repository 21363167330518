import { compose, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'

import projectArchiveMutation from 'api/mutations/projectArchive.graphql'
import projectUnarchiveMutation from 'api/mutations/projectUnarchive.graphql'

const withArchiveUnarchive = compose(
  graphql(projectArchiveMutation, { name: 'archiveProject' }),
  graphql(projectUnarchiveMutation, { name: 'unarchiveProject' }),
  withHandlers({
    onArchiveUnarchive: ({ archiveProject, unarchiveProject, project }) => async () => {
      const mutation = project.archived ? unarchiveProject : archiveProject
      await mutation({
        variables: { input: { projectId: project._id } },
      })
    }
  })
)

export default withArchiveUnarchive