exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".hasNoEffect__1Xmm_{background-color:#f9f9f5}.userColumn__2U96I .ant-avatar{margin-right:.5rem}.roleColumn__3bKgx .anticon{margin-right:.3rem}.orgOwnerLabel__7J9xD{padding-left:1rem;color:gray;font-size:.7rem}", ""]);

// exports
exports.locals = {
	"hasNoEffect": "hasNoEffect__1Xmm_",
	"userColumn": "userColumn__2U96I",
	"roleColumn": "roleColumn__3bKgx",
	"orgOwnerLabel": "orgOwnerLabel__7J9xD"
};