import React from 'react'
import { Button } from 'antd'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { when, propEq, omit, map, pipe } from 'ramda'
import { model } from 'beanie-engine-api-js'

import markupDefinitionCreateMutation from 'api/mutations/markupDefinitionCreate.graphql'

import { revisionId, currentIsBranch } from 'selectors/project'
import { markupDefCreated as markupDefCreatedAction } from 'actions/project'

import ModalButton from 'components/Commons/ModalButton'
import CreateMarkupForm from './CreateMarkupForm'

const { types: { markup: { MarkupComplexTypesIndex } } } = model

import styles from './CreateMarkup.scss'

const CreateMarkup = ({ onCreate, isBranch }) => (
  <ModalButton title="Create Markup" onOk={onCreate} wrapClassName={styles.createModal}>
    <Button disabled={!isBranch} type="primary" icon="plus">Add</Button>
    <CreateMarkupForm />
  </ModalButton>
)

export default compose(
  connect(state => ({
    revision: revisionId(state),
    isBranch: currentIsBranch(state)
  }), {
    markupDefCreated: markupDefCreatedAction
  }),
  graphql(markupDefinitionCreateMutation, { name: 'markupDefinitionCreate' }),
  withHandlers({
    onCreate: ({ revision, markupDefinitionCreate, markupDefCreated }) => async ({ parameters, ...others }) => {
      const { data: { markupDefinitionCreate: created } } = await markupDefinitionCreate({ variables: { input: {
        projectId: revision,
        ...others,
        ...parameters && { parameters: transformParameters(parameters) }
      } } })
      markupDefCreated(created)
    }
  })
)(CreateMarkup)

// utilities

export const transformParameters = map(pipe(
  when(propEq('isList', true), ({ type, ...others }) => ({
    type: MarkupComplexTypesIndex.List.name,
    typeParams: [type],
    ...others
  })),
  omit(['isList', '__typename']),
))