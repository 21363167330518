import { createSelector } from 'reselect'
import { path, prop, any } from 'ramda'
import { isNotNil } from 'ramda-adjunct'

import { projectId as projectIdSelector } from 'selectors/project'
import { user } from 'selectors/auth'

export const presetIds = [1, 2, 3]

export const activePreset = path(['presets', 'active'])
export const presetsAssociations = createSelector(
  [user, projectIdSelector, prop('presets')],
  ({ _id: userId }, projectId, presets) => presetIds.map(preset => path([`preset${preset}`, userId, projectId], presets))
)
export const anyPresetAssociated = createSelector(
  [presetsAssociations],
  any(isNotNil)
)

const presetSavegame = (state, { preset }) => path(['presets', preset])(state)
export const makeSavegameForPreset = () => createSelector(
  [user, projectIdSelector, presetSavegame],
  ({ _id: userId }, projectId, presetSavegameValue) => path([userId, projectId], presetSavegameValue)
)
