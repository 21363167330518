import React from 'react'
import { Divider } from 'antd'
import { connect } from 'react-redux'
import { token as tokenSelector } from 'selectors/auth'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { assocPath } from 'ramda'

import { updateQueryValue } from 'graphql/updates'
import { updateProjectData } from 'actions/project'
import { uploadProjectImage } from 'actions/resources'
import { urlForImage } from 'model/resources'

import withProject from 'hocs/withProject'

import updateProjectMutation from 'api/mutations/updateProject.graphql'
import projectWithIdQuery from 'api/queries/projectWithId.graphql'

import EditableProjectData from './EditableProjectData'
import ProjectAvatar from './ProjectAvatar'
import ProjectCoverImage from './ProjectCoverImage'


import styles from './ProjectInformation.scss'

const ProjectInformation = ({ handleProjectNameChanged, projectData, handleProjectDescriptionChanged, handleProjectAvatarImageChanged, handleProjectCoverImageChanged, token }) => (
  <div className={styles.generalSection}>
    <div className={styles.generalHeader}>
      <h2 className={styles.title}>General</h2>
      <Divider />
    </div>
    {projectData.projectWithId &&
      <div className={styles.generalContainer}>
        <EditableProjectData handleProjectNameChanged={handleProjectNameChanged} handleProjectDescriptionChanged={handleProjectDescriptionChanged} projectData={projectData} />
        <ProjectAvatar projectData={projectData} token={token} handleProjectAvatarImageChanged={handleProjectAvatarImageChanged} />
        <ProjectCoverImage handleProjectCoverImageChanged={handleProjectCoverImageChanged} token={token} projectData={projectData} />
      </div>
    }
  </div>
)

const updateProjectField = field => ({ project, updateProject, updateProjectDataAction }) => async value => {
  await updateProject({
    variables: { input: { projectId: project._id, [field]: value } },
    update: updateQueryValue(
      { ...projectWithIdQuery, variables: { projectId: project._id } }, 
      assocPath(['projectWithId', field], value)
    )
  })
  updateProjectDataAction({ [field]: value })
}

export default compose(
  withProject,
  connect(state => ({ token: tokenSelector(state) }), ({ uploadProjectImageAction: uploadProjectImage, updateProjectDataAction: updateProjectData })),
  graphql(updateProjectMutation, { name: 'updateProject' }),
  withHandlers({
    handleProjectNameChanged: updateProjectField('name'),
    handleProjectDescriptionChanged: updateProjectField('description'),
    handleProjectAvatarImageChanged: ({ projectData, project, updateProject, updateProjectDataAction }) => async value => {
      await updateProject({ variables: { input: { projectId: project._id, avatarImage: value } } })
      updateProjectDataAction({ avatarImage: value })
      projectData.refetch({ name: project })
    },
    handleProjectCoverImageChanged: ({ uploadProjectImageAction, projectData, project, updateProject, updateProjectDataAction, token }) => async file => {
      const { projectWithId: { _id, id } } = projectData
      await uploadProjectImageAction(urlForImage(token, _id || id), file)
      await updateProject({ variables: { input: { projectId: project._id, coverImage: file.name } } })
      updateProjectDataAction({ coverImage: file.name })
      projectData.refetch({ name: project })
    },
  })
)(ProjectInformation)
