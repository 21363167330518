/**
 *
 */
export const TextEditorAutoMergePreference = {
  name: 'Studio.Frontend.TextEditor.AutoMerge',
  values: {
    AutoMergeEnable: { name: 'Enabled', value: true },
    AutoMergeDisable: { name: 'Disabled', value: false }
  },
  defaultValue: true
}

/**
 *
 */
export const StaticAnalysisEnablePreference = {
  name: 'Studio.Frontend.StaticAnalysis.Enable',
  values: {
    StaticAnalysisEnable: { name: 'Enabled', value: true },
    StaticAnalysisDisable: { name: 'Disabled', value: false }
  },
  defaultValue: false
}