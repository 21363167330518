import { collapseSelected, expandSelected, collapse, expand } from 'actions/view'
import Contexts from 'shortcuts/contexts'

import { collapsableRoots, lanes as lanesSelector } from 'selectors/objects'
import { revisionId } from 'selectors/project'

export const COLLAPSE = {
  shortcut: 'shift+minus',
  action: collapseSelected,
  context: Contexts.Tree
}

export const EXPAND = {
  shortcut: 'shift+plus',
  action: expandSelected,
  context: Contexts.Tree
}

export const COLLAPSE_ALL_ROOTS = {
  action: (roots, revId) => collapse(revId, ...roots),
  params: [collapsableRoots, revisionId],
  context: Contexts.Tree
}

export const EXPAND_ALL_ROOTS = {
  action: (roots, revId) => expand(revId, ...roots),
  params: [collapsableRoots, revisionId],
  context: Contexts.Tree
}

export const COLLAPSE_ALL_LANES = {
  action: (lanes, revId) => collapse(revId, ...lanes),
  params: [lanesSelector, revisionId],
  context: Contexts.Tree
}