import { always, concat, join, times, match, propOr, pipe, head } from 'ramda'

const splitNumber = string => {
  const matched = match(/\d+$/, string)
  const n = +head(matched)

  return [n ? string.slice(0, string.lastIndexOf(`${n}`)) : string, n]
}

export const nextOrderlyString = (string, defaultIndex = 1) => {
  const [prefix, n] = splitNumber(string)
  return prefix + (n ? n + 1 : defaultIndex)
}

export const proposeNextCheckpoint = pipe(propOr('', 'version'), nextOrderlyString)

export const proposeNextVersion = prevVersion => {
  if (isNaN(prevVersion)) throw new Error('prevVersion is not a Number, cant increase')
  return zeroPad(prevVersion, increase(prevVersion))
}

const decimalLength = number => Number(number).toFixed(0).toString().length

const zeroPad = (prev, next) => {
  if (prev === next) return prev
  const originalPadding = zeroPaddingCard(prev)
  const decimalLengthDiff = decimalLength(next) - decimalLength(prev)

  return join('', concat(
    times(always('0'), originalPadding - decimalLengthDiff),
    [next]
  ))
}

const zeroPaddingCard = prev => {
  const result = prev.match(/^(0{1,}).*/)
  if (!result) return 0
  return result[1].length
}

const increase = prev => {
  if (prev.includes('.')) {
    const version = Number(prev)
    const versionWithoutPaddingAsString = prev.replace(/^(0{1,})(.*)/, '$2')
    if (versionWithoutPaddingAsString === version.toFixed(1)) return (version + 0.1).toFixed(1)
    if (versionWithoutPaddingAsString === version.toFixed(2)) return (version + 0.01).toFixed(2)
    return prev // we won't support this type of suggestions, returning same previous version and the user should decide
  }
  return Number(prev) + 1
}