import React from 'react'
import classNames from 'classnames'
import { Icon } from 'antd'
import { isNotNil } from 'ramda-adjunct'
import { lang } from 'beanie-engine-api-js'

import LabelContent from '../../../TreeChain/NodeWithEnabledRule/LabelContent'
import { EMPTY_OBJECT } from 'utils/object'
import { EMPTY_STRING } from 'utils/string'
import IconButton from 'components/Commons/IconButton'

import styles from '../TruthTable.scss'

const { rule: { error: { isError, isStaticError } } } = lang

const CellLabel = props => {
  const { shouldEval, result } = props
  const Comp = shouldEval && isNotNil(result) && isError(result) ? CellError : CellSource
  return (
    <Comp {...props} />
  )
}

const CellError = ({ result: error, source = EMPTY_STRING, ...props }) => {
  const ErrorComp = isStaticError(error) ? StaticError : ExecutionError
  return (
    <ErrorComp error={error} source={source} {...props} />
  )
}

const StaticError = ({ source }) => (
  <div className={classNames(styles.cellLabel, styles.staticError)} >
    <IconButton
      className={styles.cellErrorIcon}
      style={{
        color: '#e56261',
        fontSize: '12px'
      }}
      type="exclamation-circle"
      theme="filled"
      disabled
      tooltip="Invalid Syntax!" />
    <LabelContent source={source} shouldShowToolTip />
  </div>
)

const ExecutionError = ({ error, source }) => (
  <div className={classNames(styles.cellLabel)} >
    <Icon className={styles.cellErrorIcon} type="exclamation-circle" />
    <LabelContent
      source={source}
      shouldShowToolTip
      customTooltip={error.data.message} />
  </div>
)

const CellSource = ({ source = EMPTY_STRING, iconComponent, issuesComponent, cellLabelCustomStyle = EMPTY_OBJECT }) => (
  <div className={classNames(styles.cellLabel, cellLabelCustomStyle)}>
    <LabelContent source={source} maxSize={20} />
    {iconComponent}
    {issuesComponent}
  </div>
)

export default CellLabel
