import { model } from 'beanie-engine-api-js'
import { one, delimiter } from '../schema-utils'
import { NODE_TYPES } from '../../Constants'
import { markupNormalize } from '../normalizers/markUp'

const { types: { markup: { DELIM_END, DELIM_START } } } = model

export default {
  nodes: [
    delimiter(DELIM_START),
    {
      match: [{ type: NODE_TYPES.MARK_UP_NAME }, { type: NODE_TYPES.MARK_UP_WITH_PARAMS }],
      ...one
    },
    delimiter(DELIM_END),
  ],
  normalize: markupNormalize
}