import React from 'react'
import { Tooltip, Icon } from 'antd'

import styles from './Counter.scss'

const Counter = ({ icon, value, tooltip }) => (
  <span className={styles.counter}>
    <Tooltip title={tooltip}><Icon type={icon} /></Tooltip>
    <span className={styles.value}>{value}</span>
  </span>
)

export default Counter