exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".actions__179wr{display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around}", ""]);

// exports
exports.locals = {
	"actions": "actions__179wr"
};