import React from 'react'
import { compose, withState, lifecycle, withHandlers, withStateHandlers } from 'recompose'
import { pipe, prop, identity, always } from 'ramda'
import { firstUpperCase } from 'utils/string'

/**
 * Introduces a state that is synchronized with a property.
 * That is, when it gets mounted it takes that property as initial value.
 * Internally the component can change that state.
 * But, it the property changes, it will re-sync the state with that prop value.
 * Similar to withState() but it restrict the "default" value (3rd param) with a specific sematic.
 * @param {*} stateName name of the state later passed as prop to the decorated component
 * @param {*} setterFunction name for a function that will be passed as prop to update the state
 * @param {*} sourcePropName name of the props to be synch'ed with
 */
export const withStateFromProp = (stateName, setterFunction, sourcePropName, transformer = identity) => compose(
  withState(stateName, setterFunction, pipe(prop(sourcePropName), transformer)),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { [sourcePropName]: source, [setterFunction]: updateState } = this.props
      if (prevProps[sourcePropName] !== source) {
        updateState(transformer(source))
      }
    }
  })
)


/**
 * Creates a react ref object and provides a handler, which is a function prop to get
 * that ref.
 * For example:
 *   Using it `withRef('myRef')`
 *
 * Then we can use it in a component (notice that you need to apply it to get the actual ref)
 *
 *   <div ref={props.myRef()} />
 *
 * And to access the value
 *
 *    props.myRef().current    // again apply to get the ref. Current is React ref's standard API
 * @param {*} name
 */
export const withRef = name => withHandlers(() => {
  const ownRefProvider = always(React.createRef())
  return ({
    [name]: ({ [name]: upperRef }) => upperRef || ownRefProvider
  })
})


const is = name => `is${firstUpperCase(name)}`
/**
 * Introduces a boolean state and two handlers for it using a naming convention which is the one usually used for booleans.
 *
 * So you provide the name for example 'xxx' then a state prop named 'isXxx' is created.
 * That property is mutated with two state handlers named 'setIsXxx' and 'setIsNotXxx'.
 * @param {*} name - the base name which will be used to create the new state prop and its handlers as explained.
 * @param {*} initial - you could specify if the state prop will be initialized as true, false or undefined (default)
 */
export const withBooleanState = (name, initial = undefined) => withStateHandlers(
  ({ initialValue = initial }) => ({
    [is(name)]: initialValue
  }),
  {
    [`setIs${firstUpperCase(name)}`]: always(always(({ [is(name)]: true }))),
    [`setIsNot${firstUpperCase(name)}`]: always(always(({ [is(name)]: false }))),
  }
)

export const withArrayOfRef = (name, initialize) => withHandlers(props => ({
  [name]: always(always(initialize(props)))
}))

export const handlerSet = name => ({ [`set${firstUpperCase(name)}`]: setter }) => value => {
  setter(value)
}
