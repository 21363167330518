import { DEFAULT_LOCALE, ref } from 'beanie-engine-api-js'


import { objectsIndex } from '../../selectors/apollo'
import { languageResourceForLine } from '../../selectors/lines'
import { getTimeAsString } from '../../utils/dates'
import { toBNEAction } from './utils'
import { uploadStoryboard } from 'actions/storyboard'
import { uploadAudio, deleteAudio } from 'actions/audio'
import { _enableObjects, _disableObjects } from 'engine/actions/objects'

const setClipLines = (api, clip, lines) => {
  api.update(clip.id, { lines })
}

export const moveLine = (clipId, lineId, delta) => (dispatch, getState, { synchronizer }) => synchronizer.doSynchingBNE('Move line',
  api => doWithLine(api, clipId, lineId, (clip, index) => {
    // remove when we disable the buttons
    if (index === 0 && delta === -1) { return }
    if (index === clip.data.lines.length - 1 && delta === +1) { return }
    const previous = clip.data.lines[index + delta]
    clip.data.lines[index + delta] = ref(lineId)
    clip.data.lines[index] = previous

    setClipLines(api, clip, clip.data.lines)
  })
  , { id: clipId })

export const deleteLine = (clipId, lineId) => (dispatch, getState, { synchronizer }) => synchronizer.doSynchingBNE('Delete clip line',
  api => doWithLine(api, clipId, lineId, (clip, index) => {
    // delete
    api.obj.delete(lineId)
    // update clip's lines
    clip.data.lines.splice(index, 1)
    setClipLines(api, clip, clip.data.lines)
  }), { id: clipId })

const doWithLine = (api, clipId, lineId, fn) => {
  const clip = api.obj.get(clipId).toJSONObject()
  const index = clip.data.lines.indexOf(ref(lineId))
  return fn(clip, index)
}

const setResource = (doUpload, doUpdateBne) => (lineId, file) => async (dispatch, getState, _) => {
  await dispatch(doUpload(file))
  await doUpdateBne(lineId, file.name)(dispatch, getState, _)
}

const getBneLangRes = (api, lineId) => {
  const line = api.obj.get(lineId)
  const take = line.get_selected_take()
  return take.get_locale_language_resource(DEFAULT_LOCALE)
}
const _updateAudioFile = (lineId, audio) => api => {
  const langRes = getBneLangRes(api, lineId)
  api.update(langRes.get_id(), { audio })
}
const updateAudioData = toBNEAction(_updateAudioFile, 'Set audio file')

/**
 * (lineId: String, file: File) => Promise<undefined>
 */
export const setAudio = setResource(uploadAudio, updateAudioData)

export const setRecordedAudioForLine = (lineId, blob) => async (dispatch, getState) => {
  const langRes = languageResourceForLine(lineId, objectsIndex(getState()))

  // we need to include timestamp otherwise it will set the exact same value further times
  // and the engine won't report it (no dirty update)
  const fileName = `mic_${langRes.id}-${getTimeAsString()}.mp3`
  await dispatch(setAudio(lineId, new File([blob], fileName)))
}

export const unsetAudio = lineId => (dispatch, getState, { synchronizer }) =>
  synchronizer.doSynchingBNE(`Unset audio file for ${lineId}`, async api => {
    const langRes = getBneLangRes(api, lineId)
    const fileName = langRes.get_audio()
    await dispatch(deleteAudio(fileName))
    await _updateAudioFile(lineId)(api)
  }, { id: lineId })

const _updateStoryboardFile = (lineId, storyboard) => api => api.update(lineId, { editor: { storyboard } })
const updateStoryboardData = toBNEAction(_updateStoryboardFile, 'Set storyboard image')

export const setStoryboard = setResource(uploadStoryboard, updateStoryboardData)

const _moveStoryboard = (fromLineId, toLineId, storyboard) => async api => {
  // set on target
  _updateStoryboardFile(toLineId, storyboard)(api)
  // reset from source
  _updateStoryboardFile(fromLineId, null)(api) // keep null here. BNE works with null and not with undefined !
}
export const moveStoryboard = toBNEAction(_moveStoryboard, 'Move storyboard')

const _removeStoryboard = lineId => async api => {
  await _updateStoryboardFile(lineId, null)(api)
}

export const removeStoryboard = toBNEAction(_removeStoryboard, 'Remove storyboard')

export const disableLines = _disableObjects('Disable lines')
export const enableLines = _enableObjects('Enable lines')

export const setDisabledSubtitles = toBNEAction((lineId, value) => async api => {
  const line = api.obj.get(lineId)
  line.set_disabled_subtitles(value)
}, 'Set line disabled_subtitles')
