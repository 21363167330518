import React, { Fragment } from 'react'
import { Sys } from 'beanie-engine-api-js'
import { prop } from 'ramda'
import { compose, withState, lifecycle } from 'recompose'

import createKeyboardDebugPlugin from './misc/keyboardDebug'
import createCursorDebugPlugin from './misc/cursorDebug'

import choicesPlugins from './config/choices'

import styles from './Plugins.scss'

// Turn this "on" to use some util plugins for debugging
const DEBUG = false
const debugPlugins = DEBUG ? [createKeyboardDebugPlugin, createCursorDebugPlugin] : []

const pluginCreatorsForSys = {
  [Sys.choices]: choicesPlugins,
  [Sys.choices2]: choicesPlugins,
}

const Plugins = ({ children, plugins }) => (
  <Fragment>
    {children(plugins)}
    <div key="contributions" className={styles.pluginContributions}>
      {plugins
        .filter(p => !!p.renderExtraComponent)
        .map((p, i) => p.renderExtraComponent(i))
      }
    </div>
  </Fragment>
)

const getSys = prop('sys')
const pluginsFor = object => debugPlugins.concat(pluginCreatorsForSys[object.sys] || [])
  .map(creator => creator())

export default compose(
  withState('plugins', 'setPlugins', ({ object }) => pluginsFor(object)),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { object, setPlugins } = this.props
      if (object !== prevProps.object && object && getSys(object) !== getSys(prevProps.object)) {
        setPlugins(pluginsFor(object))
      }
    }
  })
)(Plugins)