import React from 'react'
import { Avatar } from 'antd'
import { connect } from 'react-redux'

import { navigateToTeam } from 'actions/routing'
import LinkButton from 'components/Commons/LinkButton'

import { teams } from 'model/resources'
import withToken from 'hocs/withToken'

import styles from './TeamSelectOption.scss'

const TeamSelectOption = ({ team, goToTeam, navigable = true }) => (
  <div className={styles.option}>
    <TeamSimpleAvatar team={team} />
    {navigable ? <LinkButton onClick={goToTeam}>{team.name}</LinkButton> : team.name} <span className={styles.description}>{team.description}</span>
  </div>
)

const _TeamSimpleAvatar = ({ team, token }) => (
  <Avatar
    src={team.avatar && teams.get(team._id, team.avatar, token)}
  />
)
const TeamSimpleAvatar = withToken(_TeamSimpleAvatar)

export default connect(null, (dispatch, { team }) => ({
  goToTeam: () => dispatch(navigateToTeam(team))
}))(TeamSelectOption)