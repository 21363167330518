import { updateQueryValue, pullTransform } from 'graphql/updates'

import branchCheckpointsQuery from 'api/queries/branchCheckpoints.graphql'
import projectBranchesQuery from 'api/queries/projectBranches.graphql'

/**
 * Updates the apollo cache to remove the revision from checkpoints
 * and branches
 */
const projectRevisionDeleted = revision => (dispatch, getState, { getApolloClient }) => {
  (!revision.modifiable ? removeFromCheckpoints : removeFromBranches)(getApolloClient(), revision)
}

const removeFromCheckpoints = (client, checkpoint) => {
  updateQueryValue(
    {
      query: branchCheckpointsQuery,
      variables: { branchId: checkpoint.sourceBranch._id }
    },
    pullTransform()('branchCheckpoints', 'deletedCheckpoint'),
  )(client, { data: { deletedCheckpoint: checkpoint } })
}

const removeFromBranches = (client, branch) => {
  updateQueryValue(
    {
      query: projectBranchesQuery,
      variables: { projectId: branch.project._id }
    },
    pullTransform()(['projectWithId', 'branches'], 'deletedBranch')
  )(client, { data: { deletedBranch: branch } })
}

export default projectRevisionDeleted