import { identity } from 'ramda'
import { DragSource } from 'react-dnd'
import { connect } from 'react-redux'
import { compose, defaultProps } from 'recompose'
import whenRevisionModifiable from 'hocs/project/revision/whenRevisionModifiable'
import { DnDTypes } from 'dnd/model-dnd'

import beginDragAction from 'actions/nodes/dnd/beginDrag'
import canDragAction from 'actions/nodes/canDrag'
import isDraggingAction from 'actions/nodes/isDragging'

export const dragActions = {
  onCanDrag: canDragAction,
  onBeginDrag: beginDragAction,
  onIsDragging: isDraggingAction,
}


export const sourceConnector = (conn, monitor) => ({
  connectDragSource: conn.dragSource(),
  isDragging: monitor.isDragging() || false,

  // TODO: only when we need a custom drag layer
  connectDragPreview: conn.dragPreview(),
  currentOffset: monitor.getSourceClientOffset(),
})

/**
 * As a node that can be dragged.
 * Delegates and expects properties:
 *  - onBeginDrag: (Node, Relationship) => Item
 *  - canDrag: (Node, Relationship) => Boolean
 *  - isDragging: (Node Item) => Boolean
 */
export const _withNodeDragBehavior = DragSource(
  DnDTypes.Node,
  {
    beginDrag: ({ node, relationship, onBeginDrag }) => onBeginDrag(node, relationship),
    canDrag: ({ node, relationship, onCanDrag }) => onCanDrag(node, relationship),
    isDragging: ({ node, onIsDragging }, monitor) => onIsDragging(node, monitor.getItem()),
  },
  sourceConnector
)

/**
 * Connected version with actions
 */
export default whenRevisionModifiable(
  compose(
    connect(null, dragActions),
    _withNodeDragBehavior
  ),
  // when disabled
  defaultProps({
    connectDragSource: identity
  }),
)