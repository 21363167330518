import React from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'

export default handlers => C => {

  const Wrapped = ({ __onKeyPressHandler, ...props }) => (
    <C {...props} />
  )

  return compose(
    withHandlers({
      __onKeyPressHandler: props => e => {
        const handler = handlers[e.keyCode]
        if (handler) {
          return handler(props)(e)
        }
      }
    }),
    lifecycle({
      componentDidMount() {
        const { __onKeyPressHandler } = this.props
        document.addEventListener('keydown', __onKeyPressHandler, false)
      },
      componentWillUnmount() {
        const { __onKeyPressHandler } = this.props
        document.removeEventListener('keydown', __onKeyPressHandler, false)
      }
    })
  )(Wrapped)
}