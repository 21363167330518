
export const isUndoRedoEnabled = true
// WE SHOULD DEFINE A GOOD WAY TO MANAGE THIS LIMI
// MAYBE WE COULD REDUCE THIS NUMBER AND REMOVE ALL THE PAIRS (UNDO(A), A)
// FROM THE HISTORY AND ONLY FILTER THE "NEW STACK"
// AND... I GUESS THAT WE SHOULD DO THE SAME WITH REDO HISTORY
export const UNDO_HISTORY_LIMIT = 100

// just for dev. Set it to false to speed up project loading by avoiding loading beanie LUA VM/engine
export const loadEngineWhenLoadingProject = true

export const STORE_BATCHED_UPDATES = false
export const STORE_REDUX_DEV_TOOLS = true
export const STORE_LOG_ACTIONS = false

// make reduxDevTools lighter by trimming bne objects for big projects (doesn't affect the app, just the tool)
export const STORE_REDUX_DEV_TOOLS_TRIM_BNEOBJECTS = true

// profiling tools
export const PROFILE_STORE = false
export const PROFILE_SELECTORS = false
export const PROFILE_SELECTORS_ON_EACH_DISPATCH = false
export const WHY_DID_SELECTOR_RECOMPUTE = false

//
// Caching
//

// whether we should cache the engine's LUA file to avoid re-fetching every time we load a project
export const CACHE_ENGINE_FILES = false

/**
 * This makes the studio app cache project revision's BNEObjects in an IndexedDB database
 * and then on further loads just fetch the delta ChangeSet's an update the local cache.
 * Avoids having to re-fetch all bne objects
 */
export const CACHE_REVISION_BNE_OBJECTS = {
  ENABLED: true,
  DISABLED: false
}