import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Drawer, Icon, Input, Spin } from 'antd'
import { useStore } from 'react-redux'
import { model, lang } from 'beanie-engine-api-js'

import { isEmptyOrNull } from '../../utils/string'
import { withTargetValue } from '../../utils/antd'
import { Key } from '../../utils/keyboard'

import { facts } from '../../selectors/objects/facts'

import useExpressionEvaluator from '../../hooks/beanie/language/useExpressionEvaluator'

import BeanieLanguageEditor from '../Language/Rules/BeanieLanguageEditor'
import ExpressionValidFeedback from '../TreeView/TreeChain/NodeWithEnabledRule/ExpressionValidFeedback'
import ExpressionResult from './ExpressionResult'
import ExpressionTypeBadget from './ExpressionTypeBadget'

import styles from './FactDrawer.scss'
import FactDrawerTitle from './FactDrawerTitle'

const { types: { object: { getName }, fact: { getExpressionSource } } } = model
const { rule: { utils: { isEffect } } } = lang

const EFFECT_ERROR_MESSAGE = 'Error: cannot set effects to facts, it must be an expression.'

/**
 * Drawer to create/edit a "fact"
 */
const FactDrawer = ({ fact, title, onAccept, onClose, okButtonLabel }) => {
  const [data, setData] = useState(fact ? ({ name: getName(fact), expression: getExpressionSource(fact) }) : {})

  const setName = useCallback(name => setData(_data => ({ ..._data, name })), [data, setData])
  const setExpression = useCallback(expression => setData(_data => ({ ..._data, expression })), [data, setData])

  // UI
  const [onSaving, setOnSaving] = useState(false)
  const [nameError, setNameError] = useState()
  const [error, setError] = useState()

  // parsing & evaluation
  const { setValue, staticError, runtimeError, result, expressionType, shouldEval, parsedValue } = useExpressionEvaluator(data.expression, setExpression)

  // rough version of "mask" to prevent spaces
  const onKeyDown = useNoSpaces()

  const onSave = useCallback(async () => {
    setOnSaving(true)
    try {
      await onAccept({ name: data.name, expression: { source: data.expression, rule: parsedValue } })
    } catch (err) {
      setError(err.message)
    } finally {
      setOnSaving(false)
    }
  }, [onAccept, data, parsedValue, setError])

  const _isEffect = useMemo(() => parsedValue && isEffect(parsedValue), [parsedValue])

  useValidations(fact, data, _isEffect, setNameError)

  // place focus on mount/visible
  const [nameRef, expressionRef, afterVisibleChange] = useFocus(fact)

  // resizing
  const [width, onMouseDown] = useResizer(
    0.35 * document.documentElement.clientWidth /* 35 vw */,
    0.80 * document.documentElement.clientWidth /* 80 vw */
  )

  return (
    <Drawer
      className={styles.FactDrawer}
      title={<FactDrawerTitle title={title} ast={parsedValue} />}
      mask={false}
      closable={false}
      keyboard={false}
      width={width}
      placement="right"
      visible
      onClose={onClose}
      afterVisibleChange={afterVisibleChange}
    >
      <div
        className={styles.DrawerResizer}
        onMouseDown={onMouseDown}
      />
      <div className={styles.FactName}>
        <Input
          ref={nameRef}
          placeholder="Name"
          value={data.name}
          onChange={withTargetValue(setName)}
          onKeyDown={onKeyDown}
          focus="true"
        />
        <ErrorMessage error={nameError} />
      </div>

      <div className={styles.ExpressionHeader}>
        <div className={styles.ExpressionTitle}>
          Expression
          <ExpressionValidFeedback
            isValid={!staticError && !_isEffect}
            extraErrorMessage={_isEffect ?
              EFFECT_ERROR_MESSAGE :
              undefined
            }
          />
        </div>
      </div>

      <BeanieLanguageEditor
        innerRef={expressionRef}
        value={data.expression}
        setValue={setValue}
        placeholder="Rule Expression"
        className={styles.FactRuleEditor}
        onCancel={onClose}
      />

      <div className={styles.ExpressionType}>
        <div className={styles.TypeLabel}>Type</div>
        <ExpressionTypeBadget expressionType={expressionType} />
      </div>

      <div className={styles.ExpressionResultContainer}>
        <ExpressionResult result={result} shouldEval={shouldEval} runtimeError={runtimeError} staticError={staticError} />

        <div className={styles.buttons}>
          <Button onClick={onClose}>Cancel</Button>
          <Spin spinning={onSaving}><Button type="primary" onClick={onSave} disabled={!!error || !!nameError || onSaving}>{okButtonLabel}</Button></Spin>
        </div>

        <ErrorMessage error={error} />
      </div>
    </Drawer>
  )
}

const ErrorMessage = ({ error }) => (error ? (
  <div className={styles.ErrorMessage}>
    <Icon type="warning" />
    {error}
  </div>
) : null)

// hooks utils

const useNoSpaces = () => useCallback(e => {
  if (e.which === Key.SPACE) {
    e.stopPropagation()
    e.preventDefault()
    return false
  }
  return true
})

const useFocus = fact => {
  const nameRef = useRef()
  const expressionRef = useRef()
  const afterVisibleChange = useCallback(visible => {
    if (!visible) return

    // focus on name when creating
    if (!fact && nameRef.current) {
      nameRef.current.focus()
    }

    // focus on expression when editing
    if (fact && expressionRef.current) {
      expressionRef.current.focus()
    }

  }, [nameRef.current])

  return [nameRef, expressionRef, afterVisibleChange]
}

const useValidations = (fact, data, _isEffect, setNameError) => {
  const store = useStore()
  useEffect(() => {
    // name is required
    if (isEmptyOrNull(data.name)) {
      return setNameError('Name is required')
    }
    // unique name
    if (facts(store.getState()).some(f => getName(f) === data.name && (!fact || fact.id !== f.id))) {
      return setNameError(`There is already a fact with name ${data.name}`)
    }
    // handling effect definition
    if (_isEffect) {
      return setNameError(EFFECT_ERROR_MESSAGE)
    }

    setNameError(undefined)
  }, [data, _isEffect, setNameError])
}

const useResizer = (initialWidth, maxWidth) => {
  const [isResizing, setIsResizing] = useState(false)
  const [width, setWidth] = useState(initialWidth)

  const onMouseDown = () => { setIsResizing(true) }
  const onMouseUp = () => { setIsResizing(false) }

  const onMouseMove = e => {
    if (isResizing) {
      const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      if (offsetRight > initialWidth && offsetRight < maxWidth) {
        setWidth(offsetRight)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })
  return [width, onMouseDown]
}

export default FactDrawer