import { equals, forEach, keys } from 'ramda'

import { mapChanges, splitChanges } from 'model/ChangeSet'
import { OFF, ON } from './Checker'

export const ANALYSIS_MODES = {
  AUTO: 'auto',
  MANUAL: 'manual'
}

export const isAuto = e => ANALYSIS_MODES.AUTO === e
export const isManual = e => ANALYSIS_MODES.MANUAL === e

export const classByModeLabel = labelMode => (isAuto(labelMode) ? AutoMode : ManualMode)

class CheckMode {
  constructor(checker) {
    this.checker = checker
  }

  async setNewMode(modeLabel) {
    if (this.modeLabel !== modeLabel) {
      const ClassToInstance = classByModeLabel(modeLabel)
      const newMode = new ClassToInstance(this.checker)
      this.checker.mode = newMode
      await newMode.initialized(this)
    }
  }

  async refreshChecks () {
    if (equals(this.checker.status, ON)) {
      if (this.checker.checksMemory.isEmpty()) {
        // Case where there aren't checks
        await this.checker.runAllAsTransaction()
      } else { await this.refresh() }       
    }   
  }

}

class AutoMode extends CheckMode {
  constructor(checker) {
    super(checker)
    this.modeLabel = ANALYSIS_MODES.AUTO
  }

  async initialized(manualPrevMode) {
    if (equals(this.checker.status, ON)) {

      if (this.checker.checksMemory.isEmpty()) {
        return this.checker.runAllAsTransaction()
      }

      await this.checker.updateChecksByConfig(
        keys(manualPrevMode.checksToDelete),
        Object.values(manualPrevMode.checksToAddForNodes)
      )

      await this.checker.reRunAllWith({
        added: Object.values(manualPrevMode.added),
        deleted: Object.values(manualPrevMode.deleted)
      })
    }
  }

  async on() {
    if (equals(this.checker.status, OFF)) {
      this.checker.status = ON
      await this.checker.runAllAsTransaction()
    }
  }

  async receiveNewChangeSet({ changes }) {
    if (equals(this.checker.status, ON)) {
      const { added, deleted } = splitChanges(changes)
      await this.checker.reRunAllWith({ added, deleted })
    }
  }
  
  async refresh () {
    if (equals(this.checker.status, ON)) {
      await this.checker.reRunAllWith({ added: [], deleted: [] })
    }
  }

  async applyChecksUpdate(delCheckNames, addCheckNames) {
    if (equals(this.checker.status, ON)) {
      await this.checker.updateChecksByConfig(delCheckNames, addCheckNames)
    }
  }
}

class ManualMode extends CheckMode {
  constructor(checker) {
    super(checker)
    this.modeLabel = ANALYSIS_MODES.MANUAL
    this.added = {} // indexed { id: obj }
    this.deleted = {} // Indexed { id: obj }

    // Checks to add/remove by config
    this.checksToDelete = {} // indexed { checkName: true }
    this.checksToAddForNodes = {} // indexed { checkName: { type: type, checkName } }
  }
  
  async initialized() {
    // Nothing to do
  }

  async on() {
    this.checker.status = ON
    // Nothing to do
  }

  async refresh () {
    // Add or remove the checks changed by config
    await this.checker.updateChecksByConfig(
      keys(this.checksToDelete),
      Object.values(this.checksToAddForNodes)
    )
    
    // Run all checks adding and removing the saved changes
    await this.checker.reRunAllWith({
      added: Object.values(this.added),
      deleted: Object.values(this.deleted)
    })

    this.added = {}
    this.deleted = {}
    this.checksToDelete = {}
    this.checksToAddForNodes = {}
  }

  async receiveNewChangeSet({ changes }) {
    // We save the added and deleted changes to executed on next refresh
    mapChanges({
      onAdded: added => {
        if (this.deleted[added.id]) { // If was deleted in a previous changeSet
          delete this.deleted[added.id]
        } else {
          this.added[added.id] = added
        }
      },
      onDeleted: deleted => {
        if (this.added[deleted.id]) { // If was added in a previous changeSet
          delete this.added[deleted.id]
        } else {
          this.deleted[deleted.id] = deleted
        }
      },
    }, changes)
  }

  async applyChecksUpdate(delCheckNames, addCheckNames) {
    forEach(checkName => {
      if (this.checksToAddForNodes[checkName]) {
        delete this.checksToAddForNodes[checkName]
      } else {
        this.checksToDelete[checkName] = true
      }
    }, delCheckNames)

    forEach(({ type, checkName }) => {
      if (this.checksToDelete[checkName]) {
        delete this.checksToDelete[checkName]
      } else {
        this.checksToAddForNodes[checkName] = { type, checkName }
      }
    }, addCheckNames)
  }
}
