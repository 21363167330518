import { when, flip, concat, identity } from 'ramda'

import { takes, lines } from 'selectors/objects'
import { objectsIndex } from 'selectors/apollo'

export const setContext = (state = { project: { project: { objects: [] } } }) => ({
  index: objectsIndex(state),
  selectors: [takes(state), lines(state)]
})

// adapts a pipe to support two arguments, gets the object from objectsIndex, then doWithObject
export const withObject = (doWithObject = identity) => (id, getState) => {
  const resolved = objectsIndex(getState())[id]
  return resolved ? doWithObject(resolved) : undefined
}


export const createMetaChange = (conditionMetFor, makeChange) => (metaChangesForId, { field }) =>
  when(() => conditionMetFor(field), flip(concat)([makeChange(field)]))(metaChangesForId)

// ~exported for tests ends here
