import { List } from 'immutable'

// plugin

export default () => ({

  //
  // Queries
  //
  
  queries: {

    // 0.46.1 => 0.47.9
    // This function dropped recursive search
    getInlinesByTypeRecursively: (editor, self, type) => {
      const iterable = self.inlines({ onlyLeaves: false, onlyTypes: [type] })
      const array = Array.from(iterable, ([node]) => node)
      const list = List(array)
      return list
    },
  }
})
