export const START_ANALYSIS = 'START_ANALYSIS'
export const ANALYSIS_PROGRESS = 'ANALYSIS_PROGRESS'
export const END_ANALYSIS = 'END_ANALYSIS'

export const startCheckAnalysisAction = () => ({
  type: START_ANALYSIS
})

export const endCheckAnalysisAction = (issueEvents, replace) => ({
  type: END_ANALYSIS,
  issueEvents,
  replace
})

export const publishAnalysisProgress = progress => ({
  type: ANALYSIS_PROGRESS,
  progress
})
