import React from 'react'
import { useDispatch } from 'react-redux'
import IconButton from 'components/Commons/IconButton'
import { copySomethingToClipboard } from '../../actions/clipboard'
import { isEmptyOrNull } from '../../utils/string'

import styles from './NodeId.scss'

export const CopyIdButton = ({ id }) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      type="copy"
      tooltip="Copy Id"
      onClick={() => dispatch(copySomethingToClipboard(id))}
      disabled={isEmptyOrNull(id)}
    />
  )
}

const NodeId = ({ id }) => (
  <div className={styles.NodeId}>{id}<CopyIdButton id={id} /></div>
)

export default NodeId