import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'

import { model, Sys } from 'beanie-engine-api-js'

import { createObjectByPlaybackIdSelector } from 'selectors/objects'
import ClipPlayback from 'components/PlaybackView/PlaybackHistory/nodes/ClipPlayback'
import ChoicesPlayback from 'components/PlaybackView/PlaybackHistory/nodes/ChoicesPlayback'
import ReferencePlayback from 'components/PlaybackView/PlaybackHistory/nodes/ReferencePlayback'
import RootMarkerPlayback from 'components/PlaybackView/PlaybackHistory/nodes/RootMarkerPlayback'

import SimpleNode from 'components/Commons/SimpleNode'

import styles from './PlaybackHistory.scss'

const { types: { node: { isRootMarker } } } = model

const componentBySys = {
  [Sys.clip]: ClipPlayback,
  [Sys.choices]: ChoicesPlayback,
  [Sys.choices2]: ChoicesPlayback,
  [Sys.reference]: ReferencePlayback
}

const componentByCondition = o => (isRootMarker(o) ? RootMarkerPlayback : undefined)

const getComponent = o => componentBySys[o.sys] || componentByCondition(o)

const PlaybackNode = React.memo(
  ({ node, onNodeSelected, onContentAdded, onFinished }) => {

    const objectSelector = useMemo(createObjectByPlaybackIdSelector, [])
    const object = useSelector(state => objectSelector(state, { node }))
    const onNodeSelectedHandler = useCallback(
      () => onNodeSelected(node),
      [node]
    )

    // PERFORMANCE: disabled cause  useIsForcedToExecute (its selector) hurts playback perf on conditionals
    const isForcedExecution = false
    // const isForcedExecution = useIsForcedToExecute(node.nodeId)

    const PlaybackComponent = getComponent(object)
    return PlaybackComponent ?
      [
        <div key="startTime" className={styles.timeStamp}>
          {node.startTime}
        </div>,
        <div key="node" className={styles.miniNode}>
          <Tooltip title={`Select ${node.nodeId}`}>
            <SimpleNode node={object} onClick={onNodeSelectedHandler} showLabel={false} />
          </Tooltip>
        </div>,
        <div key="content" className={styles.playbackItem}>
          <PlaybackComponent
            playingObject={object}
            node={node}
            onContentAdded={onContentAdded}
            onFinished={onFinished} />
          {isForcedExecution && <span className={styles.pinnedNode}>pinned</span>}
        </div>
      ]
      : null
  },
  (props, nextProps) =>
    props.object === nextProps.object
        && props.node === nextProps.node
)

export default PlaybackNode
