import React from 'react'
import NotificationsRegistry from 'utils/Notifications'
import { notification } from 'antd'

/**
 *
 */
export default class Notifications extends React.Component {

  shouldComponentUpdate = () => false

  componentDidMount() {
    this.subscription = NotificationsRegistry.subscribe(messageConfig => {
      const type = (['success', 'info', 'warning', 'error'].includes(messageConfig.type) ? messageConfig.type : 'open')
      delete messageConfig.type
      notification[type](messageConfig)
    })
  }

  componentWillUnmount() {
    NotificationsRegistry.unsubscribe(this.subscription)
  }

  render () {
    return <div />
  }
}