import React from 'react'
import { useSelector } from 'react-redux'
import { otherSessionClientTypes } from '../../../selectors/sessions/sessions'
import MenuGroupCommand from '../MenuGroupCommand'
import SelectionOtherSessionMenuItem from './SelectionOtherSessionMenuItem'

/**
 * An autogenerated context menu group that shows an item for each current sessions
 * that this user has from other type of applications for which we (frontend)
 * know about.
 * It generates a SelectionOtherSessionMenuItem for each type.
 * Assuming we can only work with one app at a time (design limitation for the moment).
 * So if you connect from two different unreal apps, then it will just communicate with one of those !
 * (random).
 *
 * It generates something like
 *
 *   ------------------
 *  | Edit             |
 *  | Delete           |
 *  | -------          |
 *  | Unreal         > |
 *  | Blender        > |
 *  | Maya           > |
 *  `------------------`
 */
const SelectionOtherSessionsMenuGroup = props => {
  const clientTypes = useSelector(otherSessionClientTypes)

  return clientTypes.length > 0 ? (
    <MenuGroupCommand>
      {clientTypes.map(clientType => (
        <SelectionOtherSessionMenuItem
          {...props}
          key={clientType.code}
          clientType={clientType}
        />
      ))}
    </MenuGroupCommand>
  ) : null
}

export default SelectionOtherSessionsMenuGroup