import { connect } from 'react-redux'
import { makeIsCollapsedSelector } from 'selectors/folders'
import { revisionId as revisionIdSelector } from 'selectors/project'
import { toggleCollapse as toggleCollapseAction, collapse as collapseAction, expand as expandAction } from 'actions/view'

// TODO: test me

const collapsable = ({ id, defaultToCollapsed = false }) =>
  connect(
    () => {
      const isCollapsed = makeIsCollapsedSelector(id, defaultToCollapsed)
      return (state, props) => ({
        collapsed: isCollapsed(state, props),
        revisionId: revisionIdSelector(state)
      })
    },
    {
      toggleCollapse: toggleCollapseAction,
      collapse: collapseAction,
      expand: expandAction
    }
  )

export default collapsable