import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Creators } from 'actions/vm'
import { selectNode } from 'actions/nodes'
import { length } from 'ramda'

import { playbackNodes as playbackNodesSelector, currentPlaybackId as currentPlaybackIdSelector } from 'selectors/playback'

import Preferences from 'preferences/Preferences'
import { preference } from 'selectors/view'
import { EMPTY_ARRAY } from 'utils/object'

import { model } from 'beanie-engine-api-js'
import { AvatarCacheContext, useAvatarCacheContext } from '../CurrentPlayback/AvatarCache'

import PlaybackHistoryItem from './PlaybackHistoryItem'

import styles from './PlaybackHistory.scss'

const { types: { object: { isDisabled } } } = model

const { notPresentingNode } = Creators

/**
 *
 */
const PlaybackHistory = () => {
  const isScrollLocked = useSelector(preference(Preferences.Playback.lockScroll))
  const playbackNodes = useSelector(playbackNodesSelector) || EMPTY_ARRAY
  const currentPlaybackId = useSelector(currentPlaybackIdSelector)

  const dispatch = useDispatch()

  const playbackItems = useRef()
  const handleContentAdded = () => {
    if (playbackItems?.current && !isScrollLocked) {
      playbackItems.current.scroll({ top: playbackItems.current.scrollHeight + 100, behavior: 'smooth' })
    }
  }
  useEffect(handleContentAdded, [playbackItems, isScrollLocked])

  const handleComponentFinished = useCallback(node => {
    dispatch(notPresentingNode(node.playbackId))
    handleContentAdded()
  }, [notPresentingNode])

  const handleNodeSelected = useCallback(node => dispatch(selectNode(node.nodeId)), ['selectNode'])

  // avatar cache
  const avatarCache = useAvatarCacheContext(true)

  let currentPlaybackItemIndex

  const items = playbackNodes.reduce((acc, n, idx) => {

    if (isDisabled(n)) return acc

    let isFirstInFuture = false
    if (n.playbackId === currentPlaybackId) {
      currentPlaybackItemIndex = idx
    }

    if (currentPlaybackItemIndex === idx - 1 && currentPlaybackItemIndex !== playbackNodes.length) {
      // is the first item in the furture and it is not the last item
      isFirstInFuture = true
    }

    acc.push(
      <PlaybackHistoryItem
        key={n.playbackId}
        node={n}
        onContentAdded={handleContentAdded}
        onFinished={handleComponentFinished}
        onNodeSelected={handleNodeSelected}
        isAtFutureState={currentPlaybackItemIndex < idx}
        isFirstInFuture={isFirstInFuture}
      />
    )

    return acc
  }, [])

  return (
    <div className={styles.playbackItems} ref={playbackItems}>
      <AvatarCacheContext.Provider value={avatarCache}>
        {length(items) !== 0 &&
          <ol>
            {items}
          </ol>
        }
      </AvatarCacheContext.Provider>
    </div>
  )
}

export default PlaybackHistory
