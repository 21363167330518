import { prop } from 'ramda'
import { ProjectRevisionEventTypes } from 'model/constants'

import onChangeSetEvent from './onChangeSetEvent'
import onEngineExtensionAdded from './onEngineExtensionAdded'
import onEngineExtensionDeleted from './onEngineExtensionDeleted'
import onEngineExtensionRenamed from './onEngineExtensionRenamed'
import onDebuggingPinsAddedEvent from './onDebuggingPinsAddedEvent'
import onDebuggingPinsDeletedEvent from './onDebuggingPinsDeletedEvent'
import onDebuggingPinsReplaceEvent from './onDebuggingPinsReplaceEvent'
import onDebuggingPinsUpdatedEvent from './onDebuggingPinsUpdatedEvent'
import onSetUserDebugSettingsEvent from './onSetUserDebugSettingsEvent'
import onDebuggingScenariosUpdateEvent from './onDebuggingScenariosUpdateEvent'
import onReceiveRequestEvent from './onReceiveRequestEvent'
import onReceiveResponseEvent from './onReceiveResponseEvent'
import onRevisionSessionCreatedEvent from './onRevisionSessionCreatedEvent'
import onRevisionSessionDestroyedEvent from './onRevisionSessionDestroyedEvent'
import onUpdateUserCursorEvent from './onUpdateUserCursorEvent'

const EventTypeToAction = {
  // sessions lifecycle
  [ProjectRevisionEventTypes.RevisionSessionCreatedEvent]: {
    action: onRevisionSessionCreatedEvent,
  },
  [ProjectRevisionEventTypes.RevisionSessionDestroyedEvent]: {
    action: onRevisionSessionDestroyedEvent,
  },
  // state events
  [ProjectRevisionEventTypes.RevisionSessionUpdateUserCursorEvent]: {
    action: onUpdateUserCursorEvent,
  },
  [ProjectRevisionEventTypes.RevisionSessionChangeSetEvent]: {
    action: onChangeSetEvent,
    map: prop('changeSet'),
  },
  // request & response (communication API)
  [ProjectRevisionEventTypes.RevisionSessionReceiveRequestEvent]: {
    action: onReceiveRequestEvent,
    map: prop('request'),
  },
  [ProjectRevisionEventTypes.RevisionSessionReceiveResponseEvent]: {
    action: onReceiveResponseEvent,
    map: prop('response'),
  },

  // engine extensions
  [ProjectRevisionEventTypes.EngineExtensionAdded]: {
    action: onEngineExtensionAdded,
  },
  [ProjectRevisionEventTypes.EngineExtensionDeleted]: {
    action: onEngineExtensionDeleted,
  },
  [ProjectRevisionEventTypes.EngineExtensionRenamed]: {
    action: onEngineExtensionRenamed
  },

  // debugging data
  [ProjectRevisionEventTypes.DebuggingPinsAddedEvent]: {
    action: onDebuggingPinsAddedEvent,
  },
  [ProjectRevisionEventTypes.DebuggingPinsDeletedEvent]: {
    action: onDebuggingPinsDeletedEvent,
  },
  [ProjectRevisionEventTypes.DebuggingPinsUpdatedEvent]: {
    action: onDebuggingPinsUpdatedEvent,
  },
  [ProjectRevisionEventTypes.DebuggingPinsReplaceEvent]: {
    action: onDebuggingPinsReplaceEvent,
  },
  [ProjectRevisionEventTypes.UserDebugScenariosUpdateEvent]: {
    action: onDebuggingScenariosUpdateEvent,
  },
  [ProjectRevisionEventTypes.SetUserDebugSettingsEvent]: {
    action: onSetUserDebugSettingsEvent,
  }

}

export default EventTypeToAction
