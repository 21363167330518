import React from 'react'
import { getDisplayName } from 'recompose'

import SecureContext from 'components/Commons/SecureContext'

/**
 * Injects a "scope" prop with the current security scope
 */
const withSecurityScope = C => {
  const R = props => (
    <SecureContext.Consumer>
      {scope => <C {...props} scope={scope} />}
    </SecureContext.Consumer>
  )
  R.displayName = `withSecurityScope(${getDisplayName(C)})`

  return R
}

export default withSecurityScope