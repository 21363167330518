exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".bottomPanel__3gypc{display:-ms-flexbox;display:flex;height:100%;-ms-flex-direction:column;flex-direction:column}.fullScreenPlaybackPanel__2waUo{overflow:scroll;background:#fff;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-top:5px;-ms-flex:1 1 0%;flex:1 1 0%;height:100%}.bottomViewPanel__NXOyp{-ms-flex:1 1 0%;flex:1 1 0%;width:100%}", ""]);

// exports
exports.locals = {
	"bottomPanel": "bottomPanel__3gypc",
	"fullScreenPlaybackPanel": "fullScreenPlaybackPanel__2waUo",
	"bottomViewPanel": "bottomViewPanel__NXOyp"
};