import React from 'react'
import Popover from 'components/Commons/Popover'
import StringValue from 'components/PropertiesEditor/Value/StringValue'

export default class EditTextPopover extends Popover {
  state = {
    visible: false,
    value: this.props.value || ''
  }

  renderPopContent() {
    const { value } = this.state
    return (
      <div style={{ width: '200px' }}>
        <StringValue 
          value={value}
          isEditable
          isEditing
          onValueChanged={this.handleValueChanged}
          onCancel={this.hide}
          ref={e => { if (e) { e.focus(); e.select() } }}
        />
      </div>
    )
  }
}