import React from 'react'
import { compose, withHandlers } from 'recompose'

import StringValue from 'components/PropertiesEditor/Value/StringValue'

import styles from './ManageEngineExtensions.scss'
import withConfirm from 'hocs/withConfirm'

const renderExtension = params => elem => (
  <RenderExtensionWithConfirm
    {...params}
    elem={elem}
  />
)


const RenderExtension = ({ open, editable, onCancel, onValueChanged, elem }) => (
  <div
    className={styles.extension}>
    <StringValue
      isEditable={editable}
      value={elem.fileName}
      onCancel={onCancel}
      onValueChanged={newName => onValueChanged(elem.fileName, newName)}
      tooltipText="Edit name"
      onDisplayClick={open}
    />
  </div>
)

const RenderExtensionWithConfirm = compose(
  withConfirm,
  withHandlers({
    open: ({ confirm, hasChanges, setSelected, loadExtensionContent, setVisibleList, elem }) => async () => {
      const doOpen = async () => {
        setSelected(elem)
        if (!elem.content) await loadExtensionContent(elem)
        setVisibleList(false)
      }

      if (!hasChanges) {
        return doOpen()
      }

      confirm(async () => {
        await doOpen()
      }, (
        <div>
          You are currently editing an extension and it has unsaved changes. Are you sure to continue and <strong>loose changes</strong>?
        </div>
      ))
    }
  }),
)(RenderExtension)

export default renderExtension
