exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".header__dTlA8{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex}.header__dTlA8 .logo__3mI9Z{display:-ms-flexbox;display:flex}.header__dTlA8 .title__GDbH9{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-positive:.8;flex-grow:.8}", ""]);

// exports
exports.locals = {
	"header": "header__dTlA8",
	"logo": "logo__3mI9Z",
	"title": "title__GDbH9"
};