import { onError } from 'apollo-link-error'
import { logout } from 'actions/login'

/**
 * Check a query/mutation error to detect that we got kicked out in terms of authentication / token
 * then it forces us to login again
 */
const createLink = ({ store }) => onError(e => {
  const { networkError } = e
  if (networkError && networkError.statusCode === 401) {
    store.dispatch(logout(true))
  }
})

export default createLink

