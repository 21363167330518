import React from 'react'
import { has, isEmpty, length } from 'ramda'
import CreateModal from 'components/Commons/CreateModal'
import { useDispatch, useSelector } from 'react-redux'
import { putPinToNodes } from 'actions/project/debuggingData'
import { currentDebuggingPins } from 'selectors/debuggingData'
import { isArray } from 'utils/object'

const ImportDebuggingPinsModal = ({ pins, hide, modalAction }) => {
  if (!isArray(pins)) return null

  const indexedPins = useSelector(currentDebuggingPins)
  const filteredPins = pins.filter(id => !has(id, indexedPins))

  const dispatch = useDispatch()
  const submit = () => dispatch(putPinToNodes(filteredPins))

  return (
    <CreateModal
      title={`${modalAction} Debugging Pins`}
      hide={hide}
      submit={submit}
      canSubmit={!isEmpty(filteredPins)}
    >
      {() => (
        <p>{`You are going to import ${length(filteredPins)} pins`}</p>
      )}
    </CreateModal>
  )
}

export default ImportDebuggingPinsModal
