exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".pt-menu>.pt-menu-divider:last-child{display:none}.pt-menu .pt-menu-item{display:-ms-flexbox !important;display:flex !important}.ContextMenuExtensionPoint__2bWHB{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"ContextMenuExtensionPoint": "ContextMenuExtensionPoint__2bWHB"
};