import React from 'react'
import { Checkbox, Tooltip } from 'antd'

import styles from './ArchivedFilter.scss'

const ArchivedFilter = ({ archived, setArchived }) => (
  <div className={styles.ArchivedFilter}>
    <Tooltip title="Show/Hide Archived Projects">
      <Checkbox checked={archived} onChange={e => setArchived(e.target.checked)}>Archived</Checkbox>
    </Tooltip>
  </div>
)

export default ArchivedFilter