import React from 'react'
import { useDispatch } from 'react-redux'

import { forceLineAudioAsDirty } from 'engine/actions/objectsMeta'
import IconButton from 'components/Commons/IconButton'

// TODO: better icon !

const MarkAsDirtyAudioButton = ({ lineId, isAudioDirty }) => {
  const dispatch = useDispatch()
  return (
    <IconButton
      type="highlight"
      disabled={isAudioDirty}
      onClick={() => dispatch(forceLineAudioAsDirty(lineId))}
    />
  )
}

export default MarkAsDirtyAudioButton