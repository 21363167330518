import { NODE_TYPES } from '../Constants'
import { Key } from 'utils/keyboard'
import { Text } from 'slate'
import { customDialogueLine } from '../slateMocks/dialogueLine'
import { customActorPart } from '../slateMocks/actorPart'
import { textNode } from '../slateMocks/textNode'
import { customDirectorLine } from '../slateMocks/directorLine'
import { customTextPart } from '../slateMocks/actorTextPart'
import { findDescendentOfType } from '../nodeUtils'
import { cloneNodes } from '../slateMocks/clone'
import { isEmpty, EMPTY_ARRAY } from 'utils/object'
import { contains, map, prop, pipe } from 'ramda'
import { isActorName } from '../../textToFragment/textToFragment'
import { NEW_LINE, EMPTY_STRING } from 'utils/string'
import { splitElement } from '../list'

export default () => ({
  onKeyDown (event, editor, next) {
    if (event.keyCode === Key.DOWN && event.altKey) {
      event.preventDefault()
      event.stopPropagation()
      const linePath = editor.lineBlockPath()
      const line = editor.blockByPath(linePath)
      const { path, node } = SWITCH_BY_TYPE_AUX[line.type](editor, line, linePath)
      editor.replaceBlock(linePath, node)
      editor.moveToEndOfNode(editor.nodeByPath(path))
      return false
    }

    return next()
  },
})

const dialogueLineToDirectorLine = (editor, line, path) => ({ node: newDirectorLine(editor, line), path })
const directorLineToDialogueLine = (editor, line, linePath) => ({ 
  node: newDialogueLine(editor, line),
  path: [...linePath, isEmpty(line.text) ? 0 : 1]
})

export const SWITCH_BY_TYPE_AUX = {
  [NODE_TYPES.DIRECTOR_LINE]: directorLineToDialogueLine,
  [NODE_TYPES.DIALOGUE_LINE]: dialogueLineToDirectorLine,
}

const newDirectorLine = (editor, dialogueLine) => {
  const actorName = findDescendentOfType(NODE_TYPES.ACTOR_PART)(dialogueLine)
  const actorText = findDescendentOfType(NODE_TYPES.TEXT_PART)(dialogueLine)

  const oneIsEmpty = isEmpty(actorName.text) || isEmpty(actorText.text)

  return customDirectorLine([
    textNode(actorName.text.toUpperCase()),
    textNode(oneIsEmpty ? EMPTY_STRING : NEW_LINE),
    ...cloneNodes(actorText.nodes)
  ])
}

const newDialogueLine = (editor, { nodes }) => {
  const { left, right } = splitElement(nodes, hasNewLine, splitterFunction)

  const { actorNameNodes, actorTextNodes } = !isEmpty(right) ?
    { actorNameNodes: left, actorTextNodes: right } :
    nodesForSingleDialogueLine([...left, ...right])

  return customDialogueLine(
    customActorPart(cloneNodes(actorNameNodes)),
    customTextPart(cloneNodes(actorTextNodes)),
  )
}

const splitterFunction = node => map(text => Text.create(text), node.text.split(NEW_LINE))

const hasNewLine = pipe(prop('text'), contains(NEW_LINE))

const nodesForSingleDialogueLine = nodes => {
  const joinedTexts = map(prop('text'))(nodes)
  return (isActorName(joinedTexts.join(EMPTY_STRING)) ?
    { actorNameNodes: nodes, actorTextNodes: EMPTY_ARRAY } : 
    { actorNameNodes: EMPTY_ARRAY, actorTextNodes: nodes }
  )
}
