exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".NotificationsListHeader__JhJU2{width:100%;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.NotificationsListHeader__JhJU2 .title__16AZ9{color:#d2d2d2}.NotificationsListHeader__JhJU2 .markAllAsRead__7oPyk .anticon{color:#f0f0f0}.NotificationsListHeader__JhJU2 .markAllAsRead__7oPyk.buttonDisabled .anticon{color:rgba(210,210,210,.5)}", ""]);

// exports
exports.locals = {
	"NotificationsListHeader": "NotificationsListHeader__JhJU2",
	"title": "title__16AZ9",
	"markAllAsRead": "markAllAsRead__7oPyk"
};