import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import { Table } from 'antd'

import buildConfigDeleteMutation from 'api/mutations/buildConfigDelete.graphql'
import BuildConfigsRowActions from './BuildConfigsRowActions'

const { Column } = Table

const BuildConfigsTable = ({ list, onBuildConfigUpdated, deleteBuildConfig, runtimeData }) => (
  <Table
    rowKey={record => record.name}
    dataSource={list}
  >
    <Column
      title="Name"
      key="name"
      dataIndex="name"
      width="30%"
    />
    <Column
      title="Description"
      dataIndex="description"
      key="description"
      width="50%"
    />
    <Column
      width="20%"
      title="Actions"
      key="actions"
      render={(_, record) => (
        <BuildConfigsRowActions
          buildConfig={record}
          onBuildConfigUpdated={onBuildConfigUpdated}
          deleteBuildConfig={deleteBuildConfig}
          runtimeData={runtimeData}
        />
      )}
    />
  </Table>
)

export default compose(
  graphql(buildConfigDeleteMutation, { name: 'mutateBuildConfigDelete' }),
  withHandlers({
    deleteBuildConfig: ({ onBuildConfigUpdated, mutateBuildConfigDelete }) => async id => {
      await mutateBuildConfigDelete({ variables: { input: { id } } })
      await onBuildConfigUpdated()
    },
  })
)(BuildConfigsTable)
