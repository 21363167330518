import React from 'react'
import Popover from 'components/Commons/Popover'
import StringValue from 'components/PropertiesEditor/Value/StringValue'
import { Select } from 'antd'

import styles from './AddPropertyPopover.scss'

const { Option } = Select

// TODO: the Select for type doesn't work well with the popover in antd
// if you use the mouse then it will close the popover

/** type => defaultValue generator */
const types = {
  string: '',
  number: () => 0,
  boolean: true,
  object: () => ({}),
  array: () => []
}

export default class AddPropertyPopover extends Popover {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      value: '',
      type: 'string'
    }
  }

  renderPopContent() {
    const { value, type } = this.state
    const options = Object.keys(types)
    return (
      <div className={styles.content}>
      
        <StringValue
          value={value}
          isEditable
          isEditing
          onValueChanged={this.handleNameChanged}
          onCancel={this.hide}
          ref={e => { if (e) { e.focus(); e.select() } }}
        />

        <Select defaultValue={type} style={{ width: 120 }} onSelect={::this.handleTypeChanged}>
          {options.map(o => 
            <Option key={o} value={o} disabled={type === o}>{o}</Option>
          )}
        </Select>

      </div>
    )
  }

  handleTypeChanged = (type) => {
    // not sure why but selecting the combo triggers the popover unvisible. we need to force it
    this.setState(() => ({ visible: true, type }))
  }
  handleNameChanged = async (name) => {
    const valueGenerator = types[this.state.type]
    this.handleValueChanged(name, typeof valueGenerator === 'function' ? valueGenerator() : valueGenerator)
  }

}