import React from 'react'
import { anyPass, applySpec, complement, equals, when } from 'ramda'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Icon, Tooltip } from 'antd'

import { keyMap, shortcutOf } from 'shortcuts/keymap'
import { PlaybackStatus } from 'model/constants'
import { playbackStatus as playbackStatusSelector } from 'selectors/playback'
import { Creators } from 'actions/vm'

import styles from './StopButton.scss'

/* eslint import/no-webpack-loader-syntax: 0 */
import StopIcon from '-!svg-react-loader!./icons/stop.svg'

const { stopPlayback: stopPlaybackAction } = Creators

const isStopped = anyPass([equals(PlaybackStatus.STOPPED), equals(PlaybackStatus.INITIAL)])
const notStopped = complement(isStopped)

const StopButton = ({ playbackStatus, stopPlayback, className }) => (
  <Tooltip title={`Stop (${shortcutOf(keyMap.TreeView.STOP)})`}>
    <Icon
      className={classnames(className, { [styles.disabled]: isStopped(playbackStatus) })}
      component={StopIcon}
      onClick={when(() => notStopped(playbackStatus), stopPlayback)}
    />
  </Tooltip>
)

export default connect(
  applySpec({ playbackStatus: playbackStatusSelector }),
  { stopPlayback: stopPlaybackAction }
)(StopButton)
