import React from 'react'
import { Icon } from 'antd'

import styles from './MinimizedCurrentNodePlayback.scss'
import MinimizedNode from './minimized/MinimizedNode'

const MinimizedCurrentNodePlayback = ({ nodeIds, toggleVisible }) => (
  <div className={styles.minimizedPanel}>
    <div className={styles.minimizedBadge} onClick={toggleVisible}>
      <Icon type="up" />
    </div>
    <div className={styles.content}>
      {nodeIds.map(playbackId => (
        <MinimizedNode key={playbackId} playbackId={playbackId} />
      ))}
    </div>
  </div>
)

export default MinimizedCurrentNodePlayback