import MatchSourceType from './MatchSourceType'
// import searchInDerivedSource from './searchInDerivedSourceClassSolution'
import searchInDerivedSource from './searchInDerivedSource'
import searchInLabelSource from './searchInLabelSource'

/**
 *
 */
const SearchBySourceType = {

  [MatchSourceType.label]: searchInLabelSource,
  [MatchSourceType.derived]: searchInDerivedSource,
  // add others here
}

/**
 * Given a context it performs the "match" searching over a given MatchSource.
 * We have different implementations for different types of MatchSource's.
 */
const searchInSource = (resolve, object, searcher) => source => {
  const searchImpl = SearchBySourceType[source.type]
  if (!searchImpl) {
    throw new Error(`Unknown MatchSearchSource ${JSON.stringify(source)}`)
  }
  searchImpl(resolve, object, searcher, source)
}

export default searchInSource