import React from 'react'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'

import { firstLane } from 'selectors/lanes'
import { selectedNode } from 'selectors/nodeSelection'

import { selectNode } from 'actions/nodes'

import TextView from 'components/TextView/TextView'

import styles from './TextProjectSection.scss'

const TextProjectSection = () => (
  <div className={styles.container}>
    <TextView outsideClassName={styles.outside} insideClassName={styles.inside} />
  </div>
)

export default compose(
  connect(() => {
    return state => ({
      selected: selectedNode(state),
      lane: firstLane(state),
    })
  }, {
    select: selectNode
  }),
  lifecycle({
    componentDidMount() { selecteDefault(this.props) },
    componentDidUpdate() { selecteDefault(this.props) }
  }),
)(TextProjectSection)

const selecteDefault = ({ selected, lane, select }) => {
  if (!selected && lane && lane.roots.length > 0) {
    select(lane.roots[0])
  }
}