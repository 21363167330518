import React from 'react'
import { Icon, Tooltip } from 'antd'

import { isOrgOwner } from 'security/commons'
import { isSuperAdmin } from 'security/platform'
import UserName from 'components/Commons/UserName'

const NoEffectWarning = ({ member, organization }) => {
  if (isOrgOwner(organization, member.user)) {
    return <Warning user={member.user} reason="is already an organization OWNER" />
  }
  if (isSuperAdmin(member.user)) {
    return <Warning user={member.user} reason="is already a SUPER ADMIN" />
  }
  return null
}

const Warning = ({ user, reason }) => (
  <Tooltip title={`This role has no effect because ${UserName({ user })} ${reason}`}>
    <Icon type="warning" />
  </Tooltip>
)

export default NoEffectWarning