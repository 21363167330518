import React from 'react'
import classNames from 'classnames'
import { isNotEmpty } from 'ramda-adjunct'
import { map } from 'ramda'
import { Tooltip, Icon } from 'antd'
import ProblemSeverity from 'components/ProjectProblems/ProblemSeverity'

import styles from './TreeChain.scss'

const analysisReportAsString = issues => (
  <div className={styles.staticIssuesRows}>
    { map(({ severity, message, issueHash }) => (
      <div className={styles.staticIssue} key={issueHash}>
        <ProblemSeverity severity={severity} className={styles.issueSeverity} />
        {message}
      </div>
    )
    , issues)}
  </div>
)

const StaticProblemsFeedback = ({ issues, isWarning, isError, className }) =>
  (isNotEmpty(issues) ?
    <div className={classNames(styles.warningNodeBadge, className)}>
      <Tooltip title={analysisReportAsString(issues)}>
        <Icon
          className={classNames(styles.issueIcon,
            {
              [styles.issueError]: isError,
              [styles.issueInfo]: !isError && !isWarning,
              [styles.issueWarning]: isWarning,
            }
          )}
          type="exclamation-circle"
          theme="filled"
        />
      </Tooltip>
    </div>
    : null
  )

export default StaticProblemsFeedback
