import React from 'react'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import classnames from 'classnames'
import moment from 'moment'

import { input } from 'utils/graphql'
import { identity } from 'utils/functions'

import { updateOnMarkAsRead as update } from 'components/NotificationsIndicator/updateNotificationsCache'

import notificationMarkAsReadMutation from 'api/mutations/notificationMarkAsRead.graphql'
import styles from './Notification.scss'

// each action should be an IconButton
const _Notification = ({ actions = [], markAsRead, notification: { read, createdAt, payload: { title, message } }, message: Message, title: Title }) => (
  <div onClick={markAsRead} className={classnames(styles.Notification, { [styles.unread]: !read })}>
    <div>
      <div className={styles.title}>{title || Title}</div>
      <div className={styles.message}>{message || Message}</div>
    </div>
    <div className={styles.footer}>
      <span className={styles.time}>{moment(createdAt).fromNow()}</span>
      { actions.length > 0 &&
        <div className={styles.actions}>
          { actions }
        </div>
      }
    </div>
  </div>
)

const markAsRead = ({ markNotificationAsRead, notification: { _id } }) => () =>
  markNotificationAsRead(input(
    { ids: [_id] },
    identity,
    { update }
  ))

const Notification = compose(
  graphql(notificationMarkAsReadMutation, { name: 'markNotificationAsRead' }),
  withHandlers({ markAsRead })
)(_Notification)

export default Notification
