exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__fZCmR{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:2rem;height:90vh;overflow-y:scroll}.container__fZCmR>div:nth-child(2){background-color:#fff}", ""]);

// exports
exports.locals = {
	"container": "container__fZCmR"
};