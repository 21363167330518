exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".secondaryTitle__1h52z{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.content__ZO7gE{max-width:75%;width:75%;-ms-flex-item-align:start;align-self:flex-start}.content__ZO7gE .UpdateDescription__1I3JO{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.content__ZO7gE .UpdateDescription__1I3JO .summary__2Bt5W{-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-item-align:end;align-self:flex-end}.content__ZO7gE .UpdateDescription__1I3JO .summary__2Bt5W>span{font-weight:bold;margin-right:.5rem}.content__ZO7gE .UpdateDescription__1I3JO .summary__2Bt5W .anticon{margin-left:1rem}.content__ZO7gE .UpdateDescription__1I3JO .ChangesDetail__3vXB9{padding-top:.5rem}", ""]);

// exports
exports.locals = {
	"secondaryTitle": "secondaryTitle__1h52z",
	"content": "content__ZO7gE",
	"UpdateDescription": "UpdateDescription__1I3JO",
	"summary": "summary__2Bt5W",
	"ChangesDetail": "ChangesDetail__3vXB9"
};