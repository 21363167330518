import React from 'react'

import ViewPanel from 'components/ViewPanel/ViewPanel'
import ViewPanelPane from 'components/ViewPanel/ViewPanelPane/ViewPanelPane'
import Panes, { Disposition } from 'preferences/Panes'

import useSecurityScope from 'hooks/security/useSecurityScope'

import styles from './RightPanel.scss'

export const RightPanel = ({ panes }) => {
  const securityScope = useSecurityScope()
  return (
    <ViewPanel
      className={styles.RightPanel}
      pane={panes.vertical}
      paneName={Disposition.vertical}
    >
      {
        Object.values(Panes.vertical)
          .filter(pane => !pane.visibleOn || pane.visibleOn(securityScope))
          .map(pane => (
            <ViewPanelPane id={pane.id} key={pane.id} pane={pane} />
          ))
      }
    </ViewPanel>
  )
}

export default RightPanel