import React, { useMemo } from 'react'
import { T } from 'ramda'
import { Menu } from 'antd'
import { noop } from 'utils/functions'
import useCommand from 'hooks/useCommand'
import { EMPTY_ARRAY } from 'utils/object'
import classNames from 'classnames'

import withCommandPropsForMenu from 'hocs/withCommandPropsForMenu'

const { Item } = Menu

import styles from './Menu.scss'

/**
 * AntD backed version of the MenuItemCommand (one that uses a "command" as the main
 * model descriptor of model actions)
 */
const MenuItem = ({
  label,
  command,
  isEnabled,
  params = EMPTY_ARRAY,
  isVisible: _isVisible,
  className,

  onCloseMenu = noop, // REVIEWME: maybe we should use Context for this instead of props !

  // to omit and don't propagate
  dispatch,
  ...antdProps // REVIEWME: I don't like to spread all props but see here https://stackoverflow.com/questions/55164824/antd-submenu-typeerror-cannot-read-property-isrootmenu-of-undefined
}) => {
  const boundedAction = useCommand(command)

  const isDisabled = useMemo(() => !isEnabled || !(command.enabled || T)(...params), [isEnabled, command, ...params])

  return (
    _isVisible ? (
      <Item

        {...antdProps} // careful this must be first !

        className={classNames(styles.item, className)}
        key={label}
        disabled={isDisabled}
        onClick={() => {
          boundedAction(...params)
          onCloseMenu()
        }}
      >
        {label}
      </Item>
    ) : null)
}

export default withCommandPropsForMenu(MenuItem)