exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".panel__3VCOH{padding-right:.5em;height:100%}.panel__3VCOH>div:nth-child(2){height:calc(100% - 1.5em)}", ""]);

// exports
exports.locals = {
	"panel": "panel__3VCOH"
};