import React, { useMemo } from 'react'
import { model } from 'beanie-engine-api-js'
import { path } from 'ramda'
import useSelectorMaker from '../../hooks/selectors/useSelectorMaker'
import { NoteDelimiters } from '../../model/constants'
import { getNoteIndex } from '../../model/project/searches/collectors/match/MatchSourcesBySys'
import { makeLanguageResourceForLineIdSelector } from '../../selectors/lines'
import { splitMatch } from '../../utils/string'
import styles from './SearchObject.scss'

const { types: { object: { Paths } } } = model

/**
 * A MatchResult with MatchSource.{ type = derived, name = notes }
 * That is rendering the dropdown item for a match on a line's note (actually lang_res)
 * It shows the line's text interpolating/rendering the matching note (and only that note, not others).
 */
const NoteMatchResultText = ({ result }) => {
  const { offset, length } = result

  const noteIndex = useMemo(() => getNoteIndex(result), [result])

  const langRes = useSelectorMaker(makeLanguageResourceForLineIdSelector, [result.object.id])

  const lanResText = useMemo(() => path(Paths.language_resource.text, langRes), [langRes])
  const note = useMemo(() => path([...Paths.language_resource.notes, noteIndex], langRes), [langRes])
  const delimiters = NoteDelimiters[note.type]

  const [before, highlight, after] = splitMatch(note.text, offset, length)

  return (
    <div className={styles.MatchResultText}>
      <span>{lanResText.slice(0, note.offset)}</span>

      <span>{delimiters?.left}{before}</span>
      <span className={styles.matchToken}>{highlight}</span>
      <span>{after}{delimiters?.right}</span>

      <span>{lanResText.slice(note.offset)}</span>
    </div>
  )
}

export default NoteMatchResultText