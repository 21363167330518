import { applyChanges } from './applyChanges'
import { updateIndexesWithBaseChanges } from './lineChange'

export const rebase = (editor, initialValue, newBaseValue) => {
  // initialValue is the value that we going to use to compare our changes and the incoming changes
  // valueToMerge is the incoming value, in this case, the value that we want "rebase", we'll use them to update our local changes
  // editor.value is our current local changes that will be updated using the incoming changes
  const myChanges = editor.changes(initialValue, editor.value)
  const newBaseChanges = editor.changes(initialValue, newBaseValue)
  editor.restoringFocus(() => {
    editor.updateContent(newBaseValue)
    applyChanges(editor)(updateIndexesWithBaseChanges(myChanges, newBaseChanges))
  })
}
