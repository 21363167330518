import React from 'react'
import { Icon, Tooltip } from 'antd'
import { noop } from 'utils/functions'

const disabledStyle = {
  color: 'gray',
  cursor: 'default'
}

const IconButton = ({ className, tooltip, tooltipPlacement, icon, onClick = noop, disabled = false }) => (
  <a className={className}>
    <Tooltip title={tooltip} placement={tooltipPlacement}>
      <Icon
        type={icon}
        onClick={onClick}
        style={disabled ? disabledStyle : {}}
      />
    </Tooltip>
  </a>
)

export default IconButton