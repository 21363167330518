import { createActions } from 'reduxsauce'

import { appVersion } from 'selectors/appMeta'

export const appMetaInitialState = {}

/* eslint no-undef: 0 */
const packJsonVersion = VERSION

// TODO: test
const setMetaAtStartup = () => async (dispatch, getState) => {
  const stateVersion = appVersion(getState())

  // if the current version is different than the previous
  if (stateVersion !== packJsonVersion) {
    // then we have a new version
    dispatch(Creators.setVersion(packJsonVersion))
  }
}

export const { Types, Creators } = createActions({
  setVersion: ['version'],
  setMetaAtStartup
})