import React from 'react'
import { propEq } from 'ramda'
import pluralize from 'pluralize'

import { NotificationType } from 'model/constants'
import Notification from 'components/NotificationsIndicator/Notification'
import IconButton from 'components/Commons/IconButton'
import CreateModal from 'components/Commons/CreateModal'
import { ModalConsumer } from 'components/Commons/Modal'

const ShowTtsErrorsModal = errorMessages => ({ hide }) => (
  <CreateModal title="TTS Errors" hide={hide} noFooter>
    <div>
      {errorMessages.map((e, idx) => <p key={idx}>{e}</p>)}
    </div>
  </CreateModal>
)

const ShowTtsErrorsButton = ({ errorMessages }) => (
  <ModalConsumer>
    { ({ showModal }) => (
      <IconButton type="exclamation-circle" onClick={() => showModal(ShowTtsErrorsModal(errorMessages))} />
    )}
  </ModalConsumer>
)

const ttsJobFinishedNotifier = {
  accepts: propEq('type', NotificationType.TTS_FINISHED),
  render: notification => {
    const { payload: { Finished = 0, Errored = 0, errorMessages } } = notification
    return (<Notification
      key={notification._id}
      notification={notification}
      title={`TTS Synth finished${Errored ? ` with ${pluralize('error', Errored)}` : ''}`}
      message={`${Finished} ${pluralize('line', Finished)} OK, ${Errored} ${pluralize('line', Errored)} with error.`}
      actions={Errored ? [<ShowTtsErrorsButton key={`action_${notification._id}`} errorMessages={errorMessages} />] : []}
    />)
  }
}
export default ttsJobFinishedNotifier
