import jwt from 'jsonwebtoken'
import { path, pipe, assoc, dissoc, tap, over, lensProp, mergeDeepLeft } from 'ramda'

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_TOKEN_REFRESHED,
  LOGIN_ERROR,
  LOGOUT,
  UPDATE_USER_DATA,
  CLEAR_LOGIN_ERRORS,
} from 'actions/login'
import { getLoginDataFromLocalStorage, clearLoginDataFromLocalStorage, setLocalStorage } from 'utils/authentication'
import { createReducer } from './utils'
import tokenRefresher from 'services/tokenRefresher'
import sentry from 'services/sentry'
import logrocket from 'services/logrocket'

const UNAUTHORIZED = 'UNAUTHORIZED'

const loginStored = getLoginDataFromLocalStorage()

const propFromStorage = propName => (loginStored ? loginStored[propName] : undefined)
const loginInitialState = {
  logging: false,
  token: propFromStorage('token'),
  refreshToken: propFromStorage('refreshToken'),
  refreshExpires: propFromStorage('refreshExpires'),
  user: loginStored ? loginStored.user : undefined,
  error: undefined,
  expired: false
}

function _login(state = loginInitialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: return { ...state, logging: true, error: undefined, expired: false };
    case LOGIN_SUCCESS:
      const decodedToken = jwt.decode(action.data.refresh_token, { json: true })
      const refreshExpires = path(['exp'], decodedToken)
      setLocalStorage(action.data.token, action.data.user, action.data.refresh_token, refreshExpires)
      const newState = {
        ...state,
        logging: false,
        error: undefined,
        token: action.data.token,
        refreshToken: action.data.refresh_token,
        refreshExpires,
        user: action.data.user,
      }

      tokenRefresher.onAuthorize(newState)
      sentry.onAuthorize(action.data)
      logrocket.onAuthorize(action.data)

      return newState
    case LOGIN_TOKEN_REFRESHED:
      setLocalStorage(action.token, action.user)
      return {
        ...state,
        token: action.token,
        user: action.user
      }
    case LOGIN_ERROR: return pipe(
      assoc('logging', false),
      assoc('error', toHumanReadableLoginError(action.error))
    )
    case CLEAR_LOGIN_ERRORS: return dissoc('error')

    case LOGOUT:
    case UNAUTHORIZED:
      tokenRefresher.onDeauthorize()
      clearLoginDataFromLocalStorage()
      // TODO: change the return expr for a pick-based one so we don't need to mantain every time we add something to login state
      return pipe(
        ...['token', 'refreshToken', 'refreshExpires', 'user', 'error'].map(_ => dissoc(_)),
        assoc('expired', action.expired)
      )
    case UPDATE_USER_DATA: return pipe(
      over(lensProp('user'), mergeDeepLeft(action.data)),
      tap(({ token, user }) => setLocalStorage(token, user))
    )
    default: return state
  }
}

export const login = createReducer(_login)

const toHumanReadableLoginError = error => {
  if (error === 'Failed to fetch') return 'Error contacting server. Seems like you are offline'
  return error
}