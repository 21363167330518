import { identity, isNil, propEq } from 'ramda'
import winston from 'utils/logger'
import { ProjectRevisionEventTypes } from 'model/constants'
import { isDisconnected, isReconnecting } from '../../selectors/network'
import { isRevisionSessionDisconnected, isRevisionSessionReconnecting } from '../../selectors/sessions/sessions'

import { Creators as NetworkActions } from 'actions/network'
import EventTypeToAction from './events/EventTypeToAction'


const needsActiveSession = propEq('__typename', ProjectRevisionEventTypes.RevisionSessionChangeSetEvent)

/**
 * Receives an incoming event/message from the Studio API while working
 * on a given ProjectRevision (like a revision editing session)
 */
const receiveProjectRevisionEvent = event => async (dispatch, getState) => {
  const state = getState()

  // this is a way to quickly detect we are back online ! It would be good to do it in a WS middleware ! but not possible
  if (isDisconnected(state) || isReconnecting(state)) {
    dispatch(NetworkActions.reconnectionSucceeded())
    // ignore this one we will reconnect as part of the flow and get up to date with it anyway
    if (needsActiveSession(event)) { return }
  }

  // if we are reconnecting the session ignore it (will get up to date on reconnecting)
  if (needsActiveSession(event) && (isRevisionSessionDisconnected(state) || isRevisionSessionReconnecting(state))) {
    return
  }

  const handler = EventTypeToAction[event.__typename]

  if (isNil(handler)) {
    // eslint-disable-next-line no-console
    console.log('Unknown project event', event)
    winston.debug('Unknown project event', event)
    return
  }
  return dispatch(handler.action((handler.map || identity)(event)))
}

export default receiveProjectRevisionEvent
