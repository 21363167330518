import {
  always,
  assoc,
  defaultTo,
  dissoc,
  without,
  set,
  lensPath,
  mergeDeepLeft,
  mergeDeepRight,
  ifElse,
  path,
  pipe,
  over,
  split,
  append,
  lensProp,
  assocPath,
  dissocPath
} from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import moment from 'moment'

import { PlaybackStatus } from 'model/constants'
import { Types } from 'actions/vm'
import { Types as DebugTypes } from 'actions/vm/playback/debug'
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'utils/object'
import { formatDuration } from 'utils/dates'
import { NodePlaybackStatus } from 'beanie-engine-api-js'
import initState from './init'

const { UPDATE_PLAYBACK_STATUS, RECORD_NODE_STATE, SET_CURRENT_STORYBOARD, START_NODE_PLAYBACK, PLAYBACK_START, PLAYBACK_PAUSE, PLAYBACK_INITIAL, PLAYBACK_RESUME, PLAYBACK_STOP, PLAYBACK_REWIND, REENTER_PLAYBACK_NODE, SET_PLAYBACK_INDEX, SET_PLAYBACK_QUEUE, SET_CURRENT_PLAYBACK_ID, SET_RESTORE_INSPECTING } = Types
const { SET_PAUSE_POINT, UNSET_PAUSE_POINT } = DebugTypes

const Playback = {
  [START_NODE_PLAYBACK]: (action, state) => startNodePlayback(action, formatDuration(playbackDuration(state))),
  [UPDATE_PLAYBACK_STATUS]: action => setNodeStatus(action),
  [REENTER_PLAYBACK_NODE]: ({ playbackId, status }) => pipe(
    over(lensPath(['playback', 'playbackQueue']), pipe(
      without([playbackId]),
      append(playbackId)
    )),
    setStatusOf(playbackId, status)
  ),
  [RECORD_NODE_STATE]: ({ playbackId, propName, value }) => assocPath(
    ['playback', 'playbackIndex', playbackId, 'state', ...split('.', propName)],
    value
  ),
  [PLAYBACK_START]: () => over(lensProp('playback'), pipe( // STARTED === PLAYING
    // back to initial (but keep 'state')
    mergeDeepLeft(initState.playback),
    assoc('currentStoryboard', {}),
    assoc('startTime', new Date().toISOString()),
    assoc('status', PlaybackStatus.PLAYING),
    dissoc('stopTime'),
    // TODO: REVIEW, is it necessary here if it is on REWIND already?
    assocPath(['state', 'events'], EMPTY_ARRAY)
  )),
  [PLAYBACK_PAUSE]: () => over(lensProp('playback'), pipe(
    assoc('status', PlaybackStatus.PAUSED),
    dissoc('stopTime') // supports STOPPED -> PAUSED transition occurred when togglePreset
  )),
  [PLAYBACK_INITIAL]: () => over(lensProp('playback'),
    assoc('status', PlaybackStatus.INITIAL)
  ),
  [PLAYBACK_RESUME]: () => over(lensProp('playback'),
    assoc('status', PlaybackStatus.PLAYING)
  ),
  [PLAYBACK_STOP]: () => over(lensProp('playback'), pipe(
    assoc('stopTime', new Date().toISOString()),
    assoc('status', PlaybackStatus.STOPPED),
  )),
  [PLAYBACK_REWIND]: () => over(lensProp('playback'), pipe(
    dissoc('startTime'),
    dissoc('stopTime'),
    assoc('currentStoryboard', {}),
    assoc('playbackIndex', initState.playback.playbackIndex),
    assoc('playbackQueue', initState.playback.playbackQueue),
    assocPath(['state', 'events'], initState.playback.state.events),
    dissoc('currentPlaybackId')
  )),
  [SET_CURRENT_STORYBOARD]: ({ storyboard, playbackId, lineId }) => set(
    lensPath(['playback', 'currentStoryboard']),
    { storyboard, playbackId, lineId }
  ),
  [SET_PLAYBACK_QUEUE]: ({ playbackQueue }) => assocPath(
    ['playback', 'playbackQueue'],
    playbackQueue
  ),
  [SET_PLAYBACK_INDEX]: ({ playbackIndex }) => assocPath(
    ['playback', 'playbackIndex'],
    playbackIndex
  ),
  [SET_CURRENT_PLAYBACK_ID]: ({ playbackId }) => assocPath(
    ['playback', 'currentPlaybackId'],
    playbackId
  ),
  [SET_RESTORE_INSPECTING]: ({ isInspecting }) => assocPath(
    ['playback', 'restoreInspecting'],
    isInspecting
  ),

  //
  // debugging settings
  //
  [SET_PAUSE_POINT]: ({ nodeId }) => assocPath(
    ['playback', 'debug', 'pausePoints', nodeId],
    true
  ),
  [UNSET_PAUSE_POINT]: ({ nodeId }) => dissocPath(
    ['playback', 'debug', 'pausePoints', nodeId],
  )
}

const startNodePlayback = ({ sys, nodeId, playbackId }, startTime) => pipe(
  over(lensPath(['playback', 'playbackQueue']), append(playbackId)),
  over(lensPath(['playback', 'playbackIndex', playbackId]), pipe(
    defaultTo(EMPTY_OBJECT),
    mergeDeepRight({
      playbackId,
      nodeId,
      sys,
      status: NodePlaybackStatus.PRESENTING_INTERRUPTIBLE,
      startTime,
      state: {},
    })
  ))
)

const setStatusOf = (playbackId, status) => assocPath(['playback', 'playbackIndex', playbackId, 'status'], status)
const setNodeStatus = ({ newPlaybackStatus: { status, playbackId } }) => setStatusOf(playbackId, status)

export default Playback

// utils

export /* for test */ const playbackDuration = pipe(
  // watch out, this "selector" is called from the reducer so the state arg is not ALL state but just reducrer's part
  path(['playback', 'startTime']),
  ifElse(isNotNil, _ => moment().diff(_), always(0)),
  ::moment.duration
)
