import React from 'react'
import classNames from 'classnames'
import withNodeExecutionTracking from 'hocs/node/withNodeExecutionTracking'
import { defaultNodeState, NodeExecutionStateStatus } from 'selectors/execution'
import { propFromProps } from 'selectors/props'

import styles from './CompositeNode.scss'

const CompositeFillerLine = ({ trackExecutionEnabled, executionState, executionPath }) => (
  <div
    className={classNames(
      styles.CompositeFillerLine,
    )}
  >
    <div className={classNames(styles.executionPath, {
      [styles.executed]: (
        trackExecutionEnabled
        && executionState !== defaultNodeState
        && (executionState.status !== NodeExecutionStateStatus.not_executing || executionState.execution_count > 0)
        && executionPath.pathAfter
      ),
    })} />
    <div className={styles.filler} />
  </div>
)

export default withNodeExecutionTracking(propFromProps('id'))(CompositeFillerLine)