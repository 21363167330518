import React from 'react'
import { noop } from 'ramda-adjunct'
import { compose, withHandlers, withStateHandlers } from 'recompose'
import ModalOpener from 'components/Commons/ModalOpener'
import { Errors } from 'components/Commons/Error'
import { isFormException } from 'utils/exceptions'
import { parseGraphQLErrors } from 'utils/graphql'

/**
 *
 */
const CreateModal = ({ titleIcon, title, errors, canSubmit, onCreate, onCancel, confirmLoading, children, okText, noFooter = false, modalProps = {} }) => (
  <ModalOpener
    titleIcon={titleIcon}
    title={title}
    onOk={noFooter ? null : onCreate}
    onCancel={onCancel}
    okButtonProps={{ disabled: !canSubmit }}
    modalProps={{ destroyOnClose: true, confirmLoading, ...modalProps }}
    okText={okText}
  >
    {typeof children === 'function' ? children(onCreate) : children}

    {errors && errors.length > 0 &&
      <Errors items={errors} />
    }
  </ModalOpener>
)

export default compose(
  withStateHandlers(
    { errors: [], confirmLoading: false },
    {
      setErrors: prev => errors => ({ ...prev, errors, confirmLoading: false }),
      setConfirmLoading: prev => confirmLoading => ({ ...prev, confirmLoading })
    }
  ),
  withHandlers({
    onCreate: ({ submit, onCreated = noop, hide, setConfirmLoading, setErrors }) => async () => {
      try {
        setConfirmLoading(true)
        await submit()
        setConfirmLoading(false)
        hide()
        onCreated()
      } catch (e) {
        if (e.graphQLErrors) return setErrors(parseGraphQLErrors(e))
        if (isFormException(e)) return setErrors([e.message])

        setErrors([e.message])
      }
    },
    onCancel: ({ hide, setConfirmLoading }) => () => {
      setConfirmLoading(false)
      hide()
    }
  })
)(CreateModal)