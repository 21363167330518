/**
 * Functions for "NodePlaybackState" which are the objects
 * that model an object execution stored in redux under "vm.playback.playbackIndex"
 * Here is an example
 * {
 *   playbackId: '83809e23-257d-48ca-a4a1-39e57685430a',
 *   nodeId: '2ba471f6-54bd-4089-91f6-c70264bd63b7',
 *   sys: 'choices2',
 *   status: 'terminated',
 *   startTime: '00:00:00',
 *   state: {
 *     atBeginNode: {
 *       stateEventsLength: 38,
 *       playbackQueueLength: 2
 *     },
 *     enabledChoices: [
 *       'bne_obj_id_05415152-ed20-4086-9357-c72bf33a9f87',
 *       'bne_obj_id_df5efe0a-e598-44f3-81c5-6ae0929096c4'
 *     ],
 *     hasExpired: true
 *   }
 * }
 */
import { path } from 'ramda'


/**
 * Tells whether this choices execution has expired
 */
export const choicesIsExpired = path(['state', 'hasExpired'])
/**
 * Returns the selected choice for this choices execution if any.
 * Note: this only contains a value if it was selected manually by the user.
 * If the choice got automatically selected by the engine then it won't be here !
 */
export const choicesGetSelectedChoice = path(['state', 'selectedChoice'])