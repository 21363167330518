import fs from 'fs'
import ospath from 'path'

const fetch = (verb, uri, headers, body, cb) => {
  const parts = uri.split('/')
  const filePath = `${parts[parts.length - 2]}/${parts[parts.length - 1]}`
  fs.readFile(
    ospath.join(__dirname, '..', '..', '..', 'node_modules', '@brunerhouse', 'beanie-engine', 'lua', filePath),
    'utf-8',
    (error, data) => {
      if (error) {
        cb(null, error)
      } else {
        cb({ status: '200', body: data })
      }
    }
  )
}

export default fetch
