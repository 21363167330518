exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".editProjSectionRoute__2Rrzr,.panel__215Ny,.content__3rR35{height:100%;position:relative}.Pane.horizontal.Pane2{overflow:hidden}.content__3rR35{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex:1 1 90%;flex:1 1 90%;padding-left:1em;padding-right:.5em}.content__3rR35 .splitHorizontal__1SK_w{position:relative}.content__3rR35 .horizontal .vertical .Pane{padding-bottom:.5em}.content__3rR35 .Pane2.horizontal{padding-right:.5em}", ""]);

// exports
exports.locals = {
	"editProjSectionRoute": "editProjSectionRoute__2Rrzr",
	"panel": "panel__215Ny",
	"content": "content__3rR35",
	"splitHorizontal": "splitHorizontal__1SK_w"
};