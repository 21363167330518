import React from 'react'
import { connect } from 'react-redux'

import { ResultType } from 'model/project/searches/searches'
import makeObjectLabelSelector from 'selectors/objects/makeObjectLabelSelector'

import MatchResultText from './MatchResultText'

/**
 *
 */
const SearchResultItemCaption = ({ item, label }) => {
  switch (item.type) {
    case ResultType.match: return <MatchResultText result={item} text={label?.value} />
    default: return <div>{label?.value}</div>
  }
}

export default connect((initialState, initialProps) => {
  const label = makeObjectLabelSelector(initialProps.item.object.id)
  return (state, props) => ({
    label: label(state, props)
  })
})(SearchResultItemCaption)