import React from 'react'
import { compose } from 'recompose'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'

import SectionSwitcher from 'components/Commons/Sections/SectionSwitcher'

const { values } = Object

const SectionRouter = ({ match, basePath, sections, defaultSection, layout, ...extraProps }) => (
  <Switch>
    <Route
      path={`${basePath}/:subsection`}
      render={props => (
        <SectionSwitcher
          layout={layout}
          sections={values(sections)}
          routeParam="subsection"
          {...props}
          {...extraProps}
        />
      )} />
    <Redirect to={`${match.url}/${defaultSection.name}`} />
  </Switch>
)

export default compose(withRouter)(SectionRouter)