/**
 * REVIEW AFTER APOLLO CLIENT 3
 * hacky while we don't have a better graphql API reporting that will return errors with a structure
 * like a "code" and message or something
 */

export const REVISION_SESSION_EXPIRED_ERROR_PATTERN = '[001] Invalid sessionId'

export const REVISION_SESSION_ON_MUTATION_ERROR_PATTERN = '[SecurityException] [002]'

export const DESTROY_REVISION_SESSION_DOESNT_EXIST_ERROR_PATTERN = 'Could not destroy session ".*", it does not exist'

export const TARGET_SESSION_NOT_CONNECTED = "Target session '.*' is not connected ! You cannot send requests to it"