import { Avatar } from 'antd'
import React from 'react'
import UserAvatar from '../../RevisionSessions/UserAvatar'

export const userAvatarBaseStyle = { fontStyle: 'normal' }

const userInitial = user => (user?.name || user?.firstName || '?')[0].toUpperCase()

const SimpleAvatar = ({ user }) => {
  return user.profileImage ?
    <UserAvatar user={user} /> : (
      <Avatar style={userAvatarBaseStyle}>
        {userInitial(user)}
      </Avatar>
    )
}

export default SimpleAvatar