import { EMPTY_ARRAY } from 'utils/object'
import { over, lensProp, append, pathOr } from 'ramda'
import { DEFAULT_LOCALE } from 'beanie-engine-api-js'
import BlockType from '../BlockType'
import { EMPTY_STRING } from 'utils/string'

const choicesSerializer = choices => (choices.data.container_contents || EMPTY_ARRAY)
  .reduce((content, choice) => (
    choice.data ? serializeChoice(choice, content) : content
  ), { blocks: [createTitleBlock(choices)], entityMap: {} })

const serializeChoice = (choice, content) => over(lensProp('blocks'), append(choiceToBlock(choice)))(content)

const choiceToBlock = choice => ({
  type: BlockType.choice,
  text: getText(choice),
  inlineStyleRanges: EMPTY_ARRAY,
  entityRanges: EMPTY_ARRAY,
  data: {
    choice: choice.id,
    line: choice.data.line.id
  }
})
export const PATH_TO_TEXT = ['data', 'line', 'data', 'selected_take', 'data', 'locales', DEFAULT_LOCALE, 'data', 'text'];
export const getText = pathOr(EMPTY_STRING, PATH_TO_TEXT)

const createTitleBlock = choices => ({
  type: BlockType.choicesTitle,
  text: choices.data.name || EMPTY_STRING,
  inlineStyleRanges: EMPTY_ARRAY,
  entityRanges: EMPTY_ARRAY,
  data: {
    choices: choices.id,
  }
})

export default choicesSerializer