import { allPass, propEq } from 'ramda'
import React from 'react'
import { Spin } from 'antd'
import { MenuItem, MenuDivider, ContextMenu } from '@blueprintjs/core'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import SearchObject from 'components/SearchObject/SearchObject'
import { nodeFromClipboard } from 'utils/clipboard'
import { selectVisibleObject } from 'actions/nodes'
import { shortcutOf } from 'shortcuts/keymap'
import { revisionId } from 'selectors/project'
import NoContent from 'components/Commons/NoContent'
import queryToPredicate from '../../../model/project/searches/query/queryToPredicate'

import styles from './NodeAutoCompleteSelectionBlueprint.scss'

const renderNoContent = elementLabel => () => (<NoContent message={`no ${elementLabel}`} className={styles.noContent} />)

const NodeAutoCompleteSelection = ({ elementLabel, currentRevisionId, filter, onSelect, fromClipboardShortCut, nodeCopied, isLoading }) => {
  return (
    <div>
      <Spin spinning={isLoading}>
        {nodeCopied && nodeCopied.project === currentRevisionId ?
          (
            <div>
              <span className={styles.fromClipboardTitle}>from clipboard</span><MenuItem
                text={nodeCopied.data.name}
                onClick={() => onSelect(nodeCopied.id)}
                label={fromClipboardShortCut && shortcutOf(fromClipboardShortCut)} />
            </div>) :
            <MenuItem text={`(no ${elementLabel} in clipboard)`} disabled />
        }
      </Spin>

      <MenuDivider key="divider" />
      <SearchObject
        searchAtInit
        showInline
        placeholder={`Search ${elementLabel}`}
        minLengthToSearch={0}
        debounceTime={200}
        autoCompleteClassName={styles.searchBox}
        dropdownClassName={styles.options}
        renderNoContent={renderNoContent(elementLabel)}
        onValueChanged={onSelect}
        filterOption={filter}
        optionProps={{ showContextInfo: false }}
      />
    </div>
  )
}

export default compose(
  connect(
    () => state => ({ currentRevisionId: revisionId(state) }),
    dispatch => ({
      selectVisibleObjectAction: id => dispatch(selectVisibleObject(id)),
    })
  ),
  withState('isLoading', 'setLoading', true),
  withState('nodeCopied', 'setNodeCopied'),
  withHandlers({
    onSelect: ({ onSelect, selectVisibleObjectAction, currentNodeWithFocus: { id: focusId }, connectionFn }) => async nodeId => {
      const createdObjectId = await onSelect(nodeId, focusId, connectionFn)
      if (createdObjectId) { selectVisibleObjectAction(createdObjectId) }
      ContextMenu.hide()
    }
  }),
  lifecycle({
    componentDidMount() {
      const { filter, setNodeCopied, setLoading, currentRevisionId } = this.props
      nodeFromClipboard(allPass([queryToPredicate(filter), propEq('project', currentRevisionId)]))
        .then(setNodeCopied)
        .then(() => setLoading(false))
    }
  })
)(NodeAutoCompleteSelection)