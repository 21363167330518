import { createSelector } from 'reselect'
import { createSelector as createNamedSelector, createDeepEqualSelector, createArraySelector } from 'selectors/reselect'
import { equals, head, flatten } from 'ramda'

import { last } from 'utils/object'

import { objectsIndex } from 'selectors/apollo'
import { nodeId } from 'selectors/props'
import { nodeSelection } from 'selectors/selections'

import { nodeTraverseCollectingIds } from 'selections/graph'
import { fixSelectionsWithEdges } from 'model/selection'

/**
 * Keep the direction that the user used to select the range.
 * That is from -> (right), or <- (left)
 * As the rangeSelection always stores `start + end` in the graph logical direction
 */
export const RangeSelectionDirection = {
  LEFT: 'left',
  RIGHT: 'right'
}

export const SIMPLE_SELECTION = 'simple'
export const simpleSelection = id => ({ type: SIMPLE_SELECTION, id })
export const RANGE_SELECTION = 'range'
export const rangeSelection = (from, to) => ({ type: RANGE_SELECTION, from, to })

const getHeadAndTailFromRange = (selection, idx) => {
  const [{ left, right }] = fixSelectionsWithEdges([selection], idx)
  return { headId: left, tailId: right }
}
const getHeadAndTailFromSimpleSelection = selection => ({ headId: selection.id, tailId: selection.id })

// TODO: unitary test
// does not supports multiple selections
export const getHeadAndTailFromSelection = createNamedSelector('getHeadAndTailFromSelection',
  [nodeSelection, objectsIndex],
  (selection, index) => {
    const selectionHead = head(selection)
    const getHeadAndTailImpl = selectionHead.type === RANGE_SELECTION ? getHeadAndTailFromRange : getHeadAndTailFromSimpleSelection

    return getHeadAndTailImpl(selectionHead, index)
  }
)

export const isContiguosSelectionRoughVersion = createNamedSelector('isContiguosSelectionRoughVersion',
  [nodeSelection],
  selection => (selection?.length || 0) === 1
)

export const isMultipleSelection = state => Array.isArray(state.selection.node) && state.selection.node.length > 0 && (state.selection.node.length > 1 || state.selection.node[0].type === RANGE_SELECTION)

export const unknownSelectionTypeError = selectionElement => new Error(`Unknown selection type ${selectionElement.type} should be one of: ${SIMPLE_SELECTION}|${RANGE_SELECTION}. Dispatched selection objects ${JSON.stringify(selectionElement)}`)

export const nestedSelectedNodes = createArraySelector(
  [nodeSelection, objectsIndex],
  (selectionElement, index) => {
    switch (selectionElement.type) {
      case SIMPLE_SELECTION: return selectionElement.id
      case RANGE_SELECTION:
        const { from, to } = selectionElement
        return nodeTraverseCollectingIds(index)(index[from], index[to])
      default: throw unknownSelectionTypeError(selectionElement)
    }
  }
)

// As this selector computes a new array each time, we need
// a deep equal memoization
// see https://github.com/reactjs/reselect#q-why-is-my-selector-recomputing-when-the-input-state-stays-the-same
export const selectedNodes = createDeepEqualSelector('selectedNodes', nestedSelectedNodes, flatten)
export const selectedNodeIdsIndex = createSelector(
  [selectedNodes],
  ids => ids.reduce((acc, id) => {
    acc[id] = true
    return acc
  }, {})
)

export const makeAmISelectedSelector = (idSelector = nodeId) => createSelector(
  [idSelector, selectedNodeIdsIndex],
  (myNodeId, index) => index[myNodeId]
)
makeAmISelectedSelector.noDebug = true

export const makeIsCursorAtItSelector = (idSelector = nodeId) => createSelector([idSelector, selectedNode], equals)

export const selectedNode = createNamedSelector('selectedNode', selectedNodes, last)
export const selectedNodeObject = createNamedSelector('selectedNodeObject',
  [selectedNode, objectsIndex],
  (id, index) => (id ? index[id] : undefined)
)

// not selectors, just pure functions

/**
 * Given a selection it only returns those ids directly referenced by each selection
 * (it doesn't compute ranges intermediate nodes!)
 *
 * [selection] => [id]
 * */
export const selectionIds = selections => flatten(
  selections.map(i =>
    (i.type === SIMPLE_SELECTION ? [i.id] : [i.from, i.to])
  )
)


export const leftEdge = selection => {
  switch (selection.type) {
    case SIMPLE_SELECTION: return selection.id
    case RANGE_SELECTION: return selection.left
    default: throw unknownSelectionTypeError(selection)
  }
}

export const selectionToLeftAndRight = selection => {
  switch (selection.type) {
    case SIMPLE_SELECTION: return { left: selection.id, right: selection.id }
    case RANGE_SELECTION: return selection
    default: throw unknownSelectionTypeError(selection)
  }
}
