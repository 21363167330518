import { path } from 'ramda'
import { Sys, parseRef, model } from 'beanie-engine-api-js'
import { propFrom } from '../../../utils/object'

import { Value, and, createOr } from '../model/Cache'

const { types: { object: { Paths }, node: { childResolver }, container: { getContainerContentsIds } } } = model

const computeNextClips = (clipId, objects) => {
  const resolve = propFrom(objects)
  // start the current clip's child
  return nodeComputer(resolve(clipId), resolve)
}

// main recursive function
const _computeNextClips = (id, resolve) => {
  const object = resolve(id)
  return getComputer(object.sys)(object, resolve)
}

//
// computers by sys
//

const nodeComputer = (object, resolve) => {
  const child = childResolver(resolve)(object)
  return child ? _computeNextClips(child.id, resolve) : null
}

const clipComputer = object => new Value(object.id)

// [ child & [element1 | element2 | ... ] ]
const containerMutexElementsComputer = (object, resolve) => {
  // children
  const child = nodeComputer(object, resolve)
  // choice objects
  const childrenPaths = getContainerContentsIds(object).map(e => _computeNextClips(e, resolve))
  return and(child, createOr(childrenPaths))
}

const jumpComputer = (object, resolve) => {
  // children
  const childBranch = nodeComputer(object, resolve)

  // target
  let targetBranch = null;
  const targetRef = path(Paths.jump.target, object)
  if (targetRef) {
    targetBranch = _computeNextClips(parseRef(targetRef), resolve)
  }
  return and(childBranch, targetBranch)
}

// computers registry

const getComputer = sys => computersBySys[sys] || nodeComputer
const computersBySys = {
  [Sys.clip]: clipComputer,
  [Sys.jump]: jumpComputer,
  // or containers (mutex paths)
  [Sys.choices]: containerMutexElementsComputer,
  [Sys.conditional]: containerMutexElementsComputer,

  // TODO other nodes that need special treatment
}

export default computeNextClips

