import React from 'react'
import AceEditor from 'components/Commons/Editors/AceEditor/AceEditor'
import EditableValue from './EditableValue'

/**
 * Small lua editor for paths
 */
export default class LuaValue extends EditableValue {

  saveOnEnter = false

  handleChange = value => {
    this.setState({ value })
  }

  renderForEditing() {
    const { path } = this.props
    const { value } = this.state
    return (
      <AceEditor
        mode="lua"
        id={path}
        value={value}
        onChange={this.handleChange}
        height="6rem"
        width="100%"
      />
    )
  }

}