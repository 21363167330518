import { schema, isRef } from 'beanie-engine-api-js'

import ObjectRefValue from 'components/PropertiesEditor/Value/ObjectRefValue'

import ChooseValue from 'components/PropertiesEditor/Value/ChooseValue'
import { getComponentFor } from '../../model/metadata/UIMetadata'

const getPropType = (object, path) => object && schema.getPropertyType(object.sys, path)

const getEnumValues = (object, path) => {
  const propType = getPropType(object, path)
  return propType?.isFieldType && propType?.values
}

/**
 * A ValueProvider implementation for beanie built-in objects like timers, actors, etc.
 *
 * Contract is:
 * Takes precedence over the default property value types resolution (Array, Object and String).
 *
 * You can decide to provide a custom component to show/edit the property value.
 *
 * Example of custom components are: A reference to another object and enum-like values.
 *
 * Arguments:
 * @param {*} - a param object with =>
 *
 * name: the name of the field
 * value: the value of the field
 * object: the full object
 * path: the path to the field
 */
const bneCustomValueProvider = ({ value, object, path }) => {
  const uiMetadata = object && getComponentFor(object.sys, path)
  if (uiMetadata && uiMetadata.condition(value)) {
    return uiMetadata.component
  } else if (isRef(value)) {
    return ObjectRefValue
  } else if (getEnumValues?.(object, path)) {
    return ChooseValue(getEnumValues(object, path))
  }
}

export default bneCustomValueProvider