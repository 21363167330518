import { model, schema, Sys } from 'beanie-engine-api-js'

const { types: { object: { Paths } } } = model

// TODO: in the long-term we must implement parametric types in the schema and just get it from there
//   maybe move this at least to the engine repo.
const parametricTypes = {
  [Sys.choices]: Sys.choice,
  [Sys.choices2]: Sys.choice,
  [Sys.conditional]: Sys.condition,
  [Sys.sequencer]: Sys.sequence,
  [Sys.task_container]: Sys.task_item,
  [Sys.truth_table]: Sys.truth_table_row,
  [Sys.folder]: Sys.folder_item,
}

const getTypeOfArrayElements = (sys, path) => (
  path === Paths.container.container_content ?
    parametricTypes[sys]
    : schema.getPropertyType(sys, path)
)

const accepts = (object, path, element) => schema.isA(getTypeOfArrayElements(object.sys, path))(schema.getType(element.sys))
// REVIEWME: this extra condition made it impossible to sort elements in a container, because
//   clearly you are moving an element that already exists. I'
// we don't want repeated elements (probably this must be schema metadata)
// same as the fact that we are coupling with the ref shape `bne_obj_id`
// && !pathOr(EMPTY_ARRAY, path, object).includes(ref(element.id))

export default accepts