exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".generalView__G5o8D{margin:1rem;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.generalView__G5o8D .content__3aNiU{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;background-color:#fff;min-height:85vh;border-radius:5px;padding:1rem}.generalView__G5o8D .content__3aNiU .ant-menu{width:15rem;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;border:1px solid #d3d3d3}.generalView__G5o8D .content__3aNiU .ant-menu .ant-menu-item:not(:last-child){border-bottom:1px solid #d3d3d3}.generalView__G5o8D h1{padding-bottom:1em;padding-left:.5em}.generalView__G5o8D h1 .anticon{padding-right:.5em}.generalView__G5o8D .sections__2c81N{width:80vw;padding-left:2rem;padding-right:1rem}.section__1CXRS{width:auto !important}", ""]);

// exports
exports.locals = {
	"generalView": "generalView__G5o8D",
	"content": "content__3aNiU",
	"sections": "sections__2c81N",
	"section": "section__1CXRS"
};