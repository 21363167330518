import { NODE_TYPES } from '../Constants'
import { Key } from 'utils/keyboard'
import { customDialogueLine } from '../slateMocks/dialogueLine'
import { customActorPart } from '../slateMocks/actorPart'
import { customTextPart } from '../slateMocks/actorTextPart'
import { textNode } from '../slateMocks/textNode'

export default () => ({
  onKeyDown (event, editor, next) {
    if (event.shiftKey && event.keyCode === Key.ENTER) {
      const lineBlockPath = editor.lineBlockPath()
      const parentBlock = editor.blockByPath(editor.parentPath())
      if (parentBlock.type === NODE_TYPES.TEXT_PART && editor.focusIsAtEndOfNode(parentBlock)) {
        event.preventDefault()
        event.stopPropagation()
        insertNewDialogue(editor, lineBlockPath)
        return false
      }
    }

    return next()
  },
})

const insertNewDialogue = (editor, lineBlockPath) => {
  const index = editor.currentPath().first() + 1
  const { text } = editor.blockByPath([...lineBlockPath, 0])
  editor.createBlock([], index,
    customDialogueLine(
      customActorPart([textNode(text)]), 
      customTextPart([textNode('')])))
  editor.moveCursorToActorPartOfDialogue(index)
  editor.selectInRanges(0, text.length)
}
