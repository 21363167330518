import moment from 'moment'
import { match, nth, pipe, propOr } from 'ramda'
import sanitize from 'sanitize-filename'
import { removeNodeAfter } from 'dom/dom'
import { EMPTY_STRING } from 'utils/string'

const STATE_FILE_REGEX = new RegExp('(\\d{8}_\\d{6})\\.json$')
// If you change the TIMESTAMP_FORMAT then update the STATE_FILE_REGEX constant
const TIMESTAMP_FORMAT = 'YYYYMMDD_HHmmSS'
const format = ({ name, version, kind }) => `${name}_${version}-${kind}-${moment().format(TIMESTAMP_FORMAT)}.json`
const makeFileName = pipe(format, sanitize)

const parseTimestamp = pipe(match(STATE_FILE_REGEX), nth(1))

export const parseFilename = file => {
  const filename = propOr(EMPTY_STRING, 'name', file)
  const suggested = parseTimestamp(filename)
  return suggested || filename
}

export const exportAsJSON = ({ name }, version, obj, kind) => {
  const data = new Blob([JSON.stringify(obj)], { type: 'application/json' })
  // note: don't inline this variable !!
  const url = window.URL.createObjectURL(data)

  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', makeFileName({ name, version, kind }))

  tempLink.click()

  // cleanup
  removeNodeAfter(10000, tempLink)
}