import React from 'react'
import { path as rpath } from 'ramda'

const switchComponent = ({ path, cases, otherwise }) => {
  const SwitchComponent = props => {
    const value = rpath(path, props)
    const C = cases[value] || otherwise
    return <C {...props} />
  }
  return SwitchComponent
}

export default switchComponent