exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".WalkListPopover__149bk .title__3v3LT{-ms-flex-positive:1;flex-grow:1;margin-right:.5rem}.WalkListPopover__149bk .counter__26RhG{-ms-flex-positive:.2;flex-grow:.2;display:-ms-flexbox;display:flex;-ms-flex-pack:space-evenly;justify-content:space-evenly}.WalkListPopover__149bk .counter__26RhG>span:nth-child(2){color:#ceccb4}.WalkListPopover__149bk .openSearchTable__2U1dm{-ms-flex-item-align:center;align-self:center;margin-left:.5rem;margin-right:.5rem;color:#d8ce79}.WalkListPopover__149bk .divider__2zg2W{width:1px;height:100%;border-left:1px solid #e5dea3;padding-right:1px;margin-left:.5rem;margin-right:.5rem}.WalkListPopover__149bk span.button__t2BTh{color:#e4e2ca !important}", ""]);

// exports
exports.locals = {
	"WalkListPopover": "WalkListPopover__149bk",
	"title": "title__3v3LT",
	"counter": "counter__26RhG",
	"openSearchTable": "openSearchTable__2U1dm",
	"divider": "divider__2zg2W",
	"button": "button__t2BTh"
};