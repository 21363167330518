import React from 'react'

import { connect } from 'react-redux'
import { compose, withState, withProps } from 'recompose'
import { propEq } from 'ramda'
import { actors as actorsSelector } from 'selectors/objects'

import ActorSelector from './ActorSelector'
import CopyPropertiesModal from 'components/PropertiesEditor/CopyPropertiesModal'

import { model } from 'beanie-engine-api-js'

const { types: { object: { Paths } } } = model

const CopyActorPropertiesModal = ({ setSourceActorId, sourceActor, actorToCopyPropertiesTo, clearCopyProperties }) => (
  <CopyPropertiesModal
    visible
    lens={Paths.node.user}
    sourceObject={sourceActor}
    targetObject={actorToCopyPropertiesTo}
    onFinish={clearCopyProperties}
    getObjectName={obj => obj?.data?.actorName}
  >
    <h4> { 'Select source actor:' } </h4>
    <ActorSelector
      skipActor={actorToCopyPropertiesTo}
      setActorId={setSourceActorId}
    />
  </CopyPropertiesModal>
)

export default compose(
  connect(state => ({
    actors: actorsSelector(state)
  })),
  withState('sourceActorId', 'setSourceActorId'),
  withProps(({ actors, sourceActorId }) => ({
    sourceActor: actors.find(propEq('id', sourceActorId))
  })),
)(CopyActorPropertiesModal)
