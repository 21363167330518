import React from 'react'
import classNames from 'classnames'
import { take } from 'ramda'

import { changeType } from 'beanie-engine-api-js'

import styles from './ChangeSetChangesCells.scss'

const MAX_CELLS = 5

const ChangeSetChangesCells = ({ changeSet: { changes } }) => (
  <div className={styles.ChangeSetChangesCells}>
    {take(MAX_CELLS, changes).map((change, i) => (
      <ChangeCell key={i} change={change} />
    ))}
    {changes.length > 5 && <span>&#43;{changes.length - 5}</span>}
  </div>
) 

const ChangeCell = ({ change }) => (
  <div className={classNames(styles.ChangeCell, styles[changeType(change)])} />
)

export default ChangeSetChangesCells