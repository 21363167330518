import { model } from 'beanie-engine-api-js'
import { NODE_TYPES } from '../Constants'
import { inlineNode } from './inlineNode'
import { textNode } from './textNode'
import { markUpArgument } from '../../modelToText/MarkupSerializer'

const { types: { markup: { DELIM_END, DELIM_START } } } = model

const openDelimiter = () => textNode(DELIM_START)
const closeDelimiter = () => textNode(DELIM_END)

export const emptyArgument = () => markUpArgument('', '')

export const markUpName = name => inlineNode([textNode(name)], NODE_TYPES.MARK_UP_NAME)

export const markUpWithParams = (name = '') => (
  markUpWith(
    inlineNode([
      inlineNode([textNode(name)], NODE_TYPES.MARK_UP_NAME),
      inlineNode([emptyArgument()], NODE_TYPES.MARK_UP_PARAMETERS)
    ],
    NODE_TYPES.MARK_UP_WITH_PARAMS))
)

export const markUpWith = inline => (inlineNode([openDelimiter(), inline, closeDelimiter()], NODE_TYPES.MARK_UP))

export const emptyMarkUp = () => markUpWith(markUpName(''))

export const newMarkUpWithCustomNodes = (markUpNameInline, markUpParamNodes) =>
  markUpWith(
    inlineNode([markUpNameInline, inlineNode([...markUpParamNodes], NODE_TYPES.MARK_UP_PARAMETERS)],
      NODE_TYPES.MARK_UP_WITH_PARAMS)
  )