import React from 'react'
import { isCurrentRevisionWritable } from 'security/project'
import PropertiesEditor from 'components/PropertiesEditor/PropertiesEditor'
import useObjectUpdater from '../../hooks/beanie/useObjectUpdater'
import useSecureCheck from '../../hooks/security/useSecureCheck'

import bneCustomValueProvider from './bneCustomValueProvider'

const hiddenFields = ['__typename', 'project', '_id']
export const nonEditableBneObjectFields = [
  '_id',
  'id',
  'sys',
  'project',
  'child',
  'parent',
  'logic'
]

const BneObjectPropertiesEditor = props => {
  const { object } = props
  const onPropertyChanged = useObjectUpdater(object)

  const editable = useSecureCheck(isCurrentRevisionWritable)

  return (
    <PropertiesEditor
      editable={editable}
      onPropertyChanged={onPropertyChanged}
      hiddenFields={hiddenFields}
      nonEditableFields={nonEditableBneObjectFields}
      customValueProvider={bneCustomValueProvider}
      {...props}
    />
  )
}

export default BneObjectPropertiesEditor
