import React from 'react'
import styles from './Error.scss'

export const Errors = ({ items }) => (
  <div className={styles.errors}>
    {items.map((error, i) =>
      (<div key={i} className={styles.error}>{error.toString()}</div>)
    )}
  </div>
)

export default Errors