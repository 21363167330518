exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".choices__1FKxa{display:-ms-flexbox;display:flex;width:100%;height:100%;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.choices__1FKxa .ChoicesType__12L46{font-style:italic;letter-spacing:.1rem;color:gray;text-transform:uppercase;padding-bottom:.5rem}.choices__1FKxa>p{font-weight:600;margin-bottom:.4em}.choices__1FKxa>.choiceList__3GFSi{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;list-style-type:none;-ms-flex-pack:space-evenly;justify-content:space-evenly}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk>button{background:transparent;border-color:#d9d9d9}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk>button:hover:not(:disabled){background:#7b61ca}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk>button:disabled{opacity:.6;-webkit-filter:grayscale(1);filter:grayscale(1)}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk:not(:last-child){margin-right:.5rem}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk button.pinnedChoice__1U3r_:not(:hover):not(:disabled){background:#b39aff}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk.autoChoice__srlFP>button:not(:hover){border:1px solid #d9cdfb}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk.autoChoice__srlFP>button:not(:hover)>span{color:#d9cdfb}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk .autoChoiceLegend__rMq--{font-size:.6rem;color:#cdbefd}.choices__1FKxa>.choiceList__3GFSi>.choiceItem__2LLhk .pinnedChoiceLegend__1j_yx{font-size:.6rem;color:#00ff42}.progressBar__2Dnh9{width:40vw;padding-bottom:.5em}", ""]);

// exports
exports.locals = {
	"choices": "choices__1FKxa",
	"ChoicesType": "ChoicesType__12L46",
	"choiceList": "choiceList__3GFSi",
	"choiceItem": "choiceItem__2LLhk",
	"pinnedChoice": "pinnedChoice__1U3r_",
	"autoChoice": "autoChoice__srlFP",
	"autoChoiceLegend": "autoChoiceLegend__rMq--",
	"pinnedChoiceLegend": "pinnedChoiceLegend__1j_yx",
	"progressBar": "progressBar__2Dnh9"
};