import React from 'react'
import { connect } from 'react-redux'
import { Spin, Alert, Tabs } from 'antd'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'

import { simpleSelection } from 'selectors/nodeSelection'
import { selectedObject as selectedObjectSelector } from 'selectors/objects'

import { selectNode } from 'actions/nodes'
import { selectionFromRoute } from 'hocs/selection'

import ObjectsList from 'components/ObjectsList/ObjectsList'
import BneNodePropertiesEditor from 'components/BneObjectPropertiesEditor/BneNodePropertiesEditor'
import ObjectHistory from 'components/ObjectHistory/ObjectHistory'
import JsonEditor from 'components/Commons/Editors/JsonEditor/JsonEditor'

import GraphView from './GraphView'

import styles from './ObjectsSection.scss'

const { TabPane } = Tabs

const ObjectsSection = ({ error, loading, selectNodeAction, object }) => (
  <div className={styles.panel}>
    <div className={styles.content}>
      {error &&
        <Alert type="error" message={`Error loading: ${error}`} banner />
      }
      <Spin spinning={loading} wrapperClassName={styles.spinner}>
        <div className={styles.leftPanel}>
          <Tabs>
            <TabPane tab="Objects" key="1">
              <ObjectsList onSelect={selectNodeAction} />
            </TabPane>
          </Tabs>
        </div>
        <div className={styles.rightPanel}>
          <div className={styles.top}>
            <Tabs>
              <TabPane tab="Properties" key="properties">
                <div className={styles.properties}>
                  <BneNodePropertiesEditor />
                </div>
              </TabPane>
              <TabPane tab="History" key="history">
                <div className={styles.history}>
                  <ObjectHistory object={object} />
                </div>
              </TabPane>
              <TabPane tab="JSON" key="json">
                <div className={styles.json}>
                  <JsonEditor object={object} id="objects-json" />
                </div>
              </TabPane>
              <TabPane tab="Graph" key="graph">
                <div className={styles.graph}>
                  <GraphView onSelect={selectNodeAction} />
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Spin>
    </div>
  </div>
)

export default compose(
  selectionFromRoute({ name: 'node', param: 'objectId', transform: e => [simpleSelection(e)] }),
  connect(state => ({
    object: selectedObjectSelector(state),
  }), { selectNodeAction: selectNode }),
  withRouter
)(ObjectsSection)
