import { useCallback, useState } from 'react'

/**
 * A variation of useState for a boolean state.
 * Provides two functions to set to true and to false.
 * Just a shortcut
 */
const useBooleanState = initialValue => {
  const [value, setValue] = useState(initialValue)

  const setToTrue = useCallback(() => setValue(true), [setValue])
  const setToFalse = useCallback(() => setValue(false), [setValue])

  // it is important not to use the `value` as a dep and instead use the function-style call to setValue() in React
  // to access the current previous value instead. This way we keep `toggle` stable and not change when the value changes
  const toggle = useCallback(() => setValue(prev => !prev), [setValue])

  return [value, setToTrue, setToFalse, toggle]
}

export default useBooleanState