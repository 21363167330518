import React from 'react'
import classNames from 'classnames'
import { Layout } from 'antd'
import { compose, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'

import Heartbeat from 'components/Heartbeat/Heartbeat'
import HomeContent from './HomeContent'
import HomeUserInfo from './HomeUserInfo'
import BeanieHeader from 'components/Header/Header'
import ChangePasswordModal from 'components/Users/ChangePasswordModal'
import { logout as logoutAction } from 'actions/login'
import { isDev } from 'utils/browser'

import styles from './Homepage.scss'

const { Header, Content } = Layout

const HomeLayout = ({ isChangePasswordVisible, stopShowingModal, handleChangePassword, logout }) => (
  <Layout>
    <Header className={classNames({ [styles.header]: true, [styles.devColor]: isDev() })}>
      <BeanieHeader projectInformation={false} />
      <Heartbeat />
    </Header>
    <Content className={styles.content}>
      <div className={styles.leftPanel}>
        <HomeUserInfo handleChangePassword={handleChangePassword} logout={logout} />
        <ChangePasswordModal
          visible={isChangePasswordVisible}
          onFinish={stopShowingModal}
        />
      </div>
      <div className={styles.rightPanel}>
        <HomeContent />
      </div>
    </Content>
  </Layout>
)

export default compose(
  connect(null,
    { logout: logoutAction }
  ),
  withStateHandlers(
    { isChangePasswordVisible: false },
    {
      handleChangePassword: prev => () => ({ ...prev, isChangePasswordVisible: true }),
      stopShowingModal: prev => () => ({ ...prev, isChangePasswordVisible: false }),
    }
  )
)(HomeLayout)
