import { Sys } from 'beanie-engine-api-js'
import { propEq } from 'ramda'
import { selectJumpTarget } from 'actions/nodes'
import Contexts from 'shortcuts/contexts'
import { clearTarget } from '../engine/actions/jump'
import { isCurrentRevisionWritable } from '../security/project'

export const GO_TO_TARGET = ({
  label: 'Go to target',
  icon: 'anticon-export',
  action: selectJumpTarget,
  visible: propEq('sys', Sys.jump),
  context: Contexts.Tree
})

export const CLEAR_TARGET = ({
  label: 'Clear target',
  icon: 'anticon-close',
  action: clearTarget,
  check: isCurrentRevisionWritable,
  visible: propEq('sys', Sys.jump),
  context: Contexts.Tree
})