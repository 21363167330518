import React from 'react'
import { pipe } from 'ramda'
import { model } from 'beanie-engine-api-js'

import connectState from 'hocs/connectState'
import { markupColor } from './selectors'

import styles from './MarkUp.scss'

const { types: { markup: { markupColorToColorObject } } } = model

const lightColor = pipe(
  markupColorToColorObject,
  c => c.darken(0.1),
  c => c.hsl().string()
)

const MarkUpParameterValue = ({ attributes, children, color }) =>
  (<span
    {...attributes}
    className={styles.markUpParameterValue}
    {...color && { style: { color: lightColor(color) } }}
  >{children}</span>)

export default connectState({
  color: markupColor
})(MarkUpParameterValue)