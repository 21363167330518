import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { Creators } from 'actions/vm'

const { notPresentingNode: notPresentingNodeAction } = Creators

//
// Does not render, on didMount it just sets the node as NOT_PRESENTING as it has no presentation
//
export default compose(
  connect(null, {
    notPresentingNode: notPresentingNodeAction,
  }),
  lifecycle({
    componentDidMount() {
      const { notPresentingNode, node } = this.props
      notPresentingNode(node.playbackId)
    }
  })
)(() => null)
