import React, { useEffect } from 'react'
import classNames from 'classnames'
import { model } from 'beanie-engine-api-js'

import PlayCurrentLine from './PlayCurrentLine'

import LineText from './LineText'
import LineActor from './LineActor'

import CurrentClipPlaybackContainerCreator, { getActorAndResources } from './CurrentClipPlaybackContainerCreator'

import styles from './CurrentClipPlayback.scss'

const { types: { actor: { isDefaultActor } } } = model

export const CurrentClipPlaybackComp = ({ node, actor, langRes, disabledSubtitles, onFinishedLine, nosound, playDirectorComments, isAudioDirty }) => {
  if (!actor || !langRes) return null

  const isDirLine = isDefaultActor(actor)

  return (
    <div className={classNames(styles.currentLineCanvas, { [styles.defaultActorLine]: isDirLine })}>
      {!nosound &&
        <PlayCurrentLine node={node} actor={actor} langRes={langRes} onPlayed={onFinishedLine} />
      }
      {!disabledSubtitles && !isDirLine &&
        <LineActor actor={actor} />
      }
      {!disabledSubtitles && (!isDirLine || playDirectorComments) &&
        <LineText langRes={langRes} isAudioDirty={isAudioDirty} />
      }
    </div>
  )
}

const _CurrentClipPlaybackContainer = props => {
  const { node, playbackCurrentObject, onFinishedLine } = props
  // TODO: we should migrate getActorAndResources to be a real selector and avoid denormalizing
  const { actor, langRes, isDisabled, disabledSubtitles } = getActorAndResources(node, playbackCurrentObject)

  useEffect(() => {
    if (langRes && isDisabled) {
      onFinishedLine(node)
    }
  }, [langRes?.id, isDisabled])

  return isDisabled ? null : (
    <CurrentClipPlaybackComp
      {...props}
      node={node}
      actor={actor}
      langRes={langRes}
      disabledSubtitles={disabledSubtitles}
    />
  )
}

export default CurrentClipPlaybackContainerCreator(_CurrentClipPlaybackContainer)

