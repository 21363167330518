import React from 'react'

import { getComponentFor } from './Rule'

import styles from './Rule.scss'

const UnaryOp = (op, className) => ({ node: { expr }, synthetic, level, ...others }) => {
  const Expr = getComponentFor(expr)
  return (
    <span className={styles.operationContainer}>
      {!synthetic && <span className={className}>{op}</span>}&nbsp;
      <Expr node={expr} {...others} level={level + 1} />
    </span>
  )
}

const BinaryOp = (op, className) => ({ node: { left, right }, level, synthetic, ...others }) => {
  const Lexpr = getComponentFor(left)
  const Rexpr = getComponentFor(right)
  const _level = level + 1 
  return (<span className={styles.operationContainer}>
    <Lexpr node={left} {...others} level={_level} />
    {!synthetic && <span className={className}>{op}</span>}&nbsp;
    <Rexpr node={right} {...others} level={_level} />
  </span>
  )
}

export const NotOp = UnaryOp('not', styles.notOp)

export const AndOp = BinaryOp('and', styles.andOp)

export const OrOp = BinaryOp('or', styles.orOp)

export const GtOp = BinaryOp('>', styles.gtOp)

export const LtOp = BinaryOp('<', styles.ltOp)

export const GeOp = BinaryOp('>=', styles.geOp)

export const LeOp = BinaryOp('<=', styles.leOp)

export const EqOp = BinaryOp('is', styles.eqOp)

export const NeOp = BinaryOp('is not', styles.neOp)

export const PlusOp = BinaryOp('+', styles.plusOp)

export const MinusOp = BinaryOp('-', styles.minusOp)

export const MulOp = BinaryOp('*', styles.mulOp)

export const MaxOp = BinaryOp('max', styles.maxOp)

export const MinOp = BinaryOp('min', styles.minOp)

export const DivOp = BinaryOp('/', styles.divOp)

export const ModOp = BinaryOp('%', styles.modOp)

export const OfOp = BinaryOp('of', styles.ofOp)