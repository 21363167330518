import React from 'react'
import { compose } from 'recompose'
import { Button } from 'antd'

import withArchiveUnarchive from 'hocs/project/withArchiveUnarchive'

import { Item } from 'components/Commons/DangerZone/DangerZone'
import ModalButton from 'components/Commons/ModalButton'
import ArchiveProjectMessage from './ArchiveProjectMessage'

const ArchiveProjectItem = ({ project, onArchiveUnarchive }) => (project ? (
  <Item
    title="Archive Project" 
    paragraph={`
      Archiving a project still keeps all of its content. You are still able to access it
      and work with it. Just that you won't be able to see it by default from any list of projects.
      You will need to explicitly search for archived projects.
      You can always un-archive it later if you want.
    `}
  >
    <ModalButton
      title={`${project.archived ? 'Unarchive' : 'Archive'} Project`}
      onOk={({ organization }) => onArchiveUnarchive(organization)}
    >
      <Button type="danger" ghost>{project.archived ? 'Unarchive' : 'Archive'}</Button>
      <ArchiveProjectMessage project={project} />
    </ModalButton>
  </Item>
) : null)

export default compose(
  withArchiveUnarchive,
)(ArchiveProjectItem)