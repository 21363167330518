import UnrealCommandDefinition from 'model/sessions/clients/unreal/UnrealCommandDefinition'
import requestSessionAction from '../requestSessionAction'
import { current } from 'selectors/state'

/**
 * Sends the command `SetEngineState` providing the current playback state as param
 */
const setEngineState = requestSessionAction(
  (targetSession, getState) => () => ({
    command: UnrealCommandDefinition.SetEngineState.name,
    params: {
      state: current(getState)
    },
    noResponseExpected: true
  })
)

export default setEngineState