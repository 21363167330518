import { useMemo } from 'react'
import useSecurityScope from './useSecurityScope'

/**
 * Evaluates the given security "check" in the current secure scope.
 * Returns the result of the check. Memoize-s.
 *
 * This is the hook version of secure() hoc.
 *
 * TODO: this impl doesn't yet support adding props into the scope as @secure() hoc
 *   does. Lets add that whenever we need it
 *
 * @param check a permission Check function
 * @returns {Boolean} check result
 */
const useSecureCheck = check => {
  const scope = useSecurityScope()

  return useMemo(() => check(scope), [scope])
}

export default useSecureCheck