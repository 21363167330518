import React, { useMemo } from 'react'
import classNames from 'classnames'
import { equals, hasPath, path } from 'ramda'

import { model, schema, Sys } from 'beanie-engine-api-js'

import PropertyValue from 'components/PropertiesEditor/Value/PropertyValue'
import { CopyIdButton } from '../Commons/NodeId'

import styles from './PropertiesEditor.scss'

const { types: { object: { Paths } } } = model

const MODE_PATH = ['data', 'mode']

/**
 *
 */
const Prop = ({
  object,
  title,
  name,
  propPath,
  defaultValue,
  customValueProvider,
  editable,
  nonEditableFields,
  propClassName,
  onPropertyChanged,
  showAcceptCancelIcons = true
}) => {
  const value = useMemo(() => path(propPath, object) || defaultValue, [propPath, object])

  return (
    <div className={classNames(styles.prop, propClassName)}>
      {title && <div className={styles.propTitle}>{title}</div>}
      <div className={styles.propValue}>
        <PropertyValue
          name={name}
          path={propPath.join('.')}
          value={value}
          isEditable={editable}
          nonEditableFields={nonEditableFields}
          onPropertyChanged={onPropertyChanged}
          object={object}
          customValueProvider={customValueProvider}
          showAcceptCancelIcons={showAcceptCancelIcons}
        />
      </div>
    </div>
  )
}

const BasicProperty = ({ propPath, check = true, object, ...props }) => {
  const defaultValue = useMemo(() => schema.defaultValueOf(object.sys, propPath.join('.')), [object.sys, propPath])

  return (!check || (hasPath(propPath, object) || defaultValue) ?
    <div className={styles.basicProperty}>
      <Prop
        object={object}
        propPath={propPath}
        defaultValue={defaultValue}
        {...props}
      />
    </div> :
    null
  )
}

const BasicProperties = props => {
  const { object } = props
  return (
    object ? <div className={styles.basicProperties}>
      <div className={styles.basicProperty}>
        <Prop
          {...props}
          title="ID"
          name="id"
          propPath={Paths.object.id}
          propClassName={styles.idProp}
          editable={false} />
        <CopyIdButton id={object.id} />
      </div>
      <div className={styles.basicProperty}>
        <Prop
          {...props}
          title="Name"
          name="name"
          showAcceptCancelIcons={false}
          propPath={Paths.node.name} />
        <Prop
          {...props}
          title="Disabled"
          name="disabled"
          propPath={Paths.object.disabled} />
      </div>
      <BasicProperty {...props} title="Behavior" name="mode" propPath={MODE_PATH} />
      <BasicProperty {...props} title="Time" name="timeout" propPath={Paths.choices2.timeout} showAcceptCancelIcons={false} />
      <BasicProperty {...props} title="Time" name="time" propPath={Paths.timer.time} showAcceptCancelIcons={false} />
      <BasicProperty {...props} title="Reference" name="url" propPath={Paths.reference.url} />
      <BasicProperty {...props} title="Async" name="async" propPath={Paths.task_container.async} />
      {equals(object.sys, Sys.choice) && <BasicProperty {...props} check={false} title="Auto-Choice" name="auto_choice" propPath={Paths.choice.auto_choice} />}
    </div> : null
  )
}

export default BasicProperties
