import React, { useState } from 'react'

import useTransaction from 'hooks/beanie/useTransaction'

import IconButton from 'components/Commons/IconButton'
import LuaEditor from 'components/Commons/Editors/LuaEditor/LuaEditor'
import CreateModal from 'components/Commons/CreateModal'
import { ModalConsumer } from 'components/Commons/Modal'

import styles from './ImportLuaScriptButton.scss'

/**
 * A button that opens a modal to paste LUA code with a nice code editor
 * Importing the content executes the LUA code under a transaction and closes
 * the modal.
 * Notice that any return value from the script won't be shown anywhere !
 * Errors are correctly shown within the modal itself.
 * If the script calls print then it will be captured by the console panel
 */
const ImportLuaScriptButton = () => {

  const onImport = useTransaction(api => importedText => api.evaluateScript(importedText), [], 'Import LUA Script')

  return (
    <React.Fragment>
      <ModalConsumer>
        {({ showModal }) => (
          <IconButton
            type="file-text"
            tooltip="Import Code"
            onClick={() => showModal(ImportLuaCodeModal, { onImport })}
          />
        )}
      </ModalConsumer>
    </React.Fragment>
  )
}


const ImportLuaCodeModal = ({ hide, onImport }) => {
  const [importedText, setImportedText] = useState()
  return (
    <CreateModal
      title="Execute lua snippet"
      hide={hide}
      submit={() => onImport(importedText)}
      canSubmit
      okText="Run & Close"
      modalProps={{
        width: '65vw',
        keyboard: false, // don't make ESCape close the modal
      }}
    >
      {() => (
        <div className={styles.ImportLuaScriptModal}>
          <LuaEditor
            id="importingAceEditor"
            className={styles.CodeEditor}
            content={importedText}
            setContent={setImportedText}
            placeholder="-- Place your LUA code here"
            height="50vh"
            width="100%"
            focus
          />
        </div>
      )}
    </CreateModal>
  )
}

export default ImportLuaScriptButton