import React from 'react'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers, withProps } from 'recompose'
import { ifElse, when, propEq, over, lensProp, map, omit, dissoc, pipe, prop, isNil } from 'ramda'
import { model } from 'beanie-engine-api-js'

import ModalButton from 'components/Commons/ModalButton'
import IconButton from 'components/Commons/IconButton'
import CreateMarkupForm from './CreateMarkupForm'

import markupDefinitionEditMutation from 'api/mutations/markupDefinitionEdit.graphql'

import { markupDefCreated as markupDefCreatedAction } from 'actions/project'

import styles from './CreateMarkup.scss'
import { transformParameters } from './CreateMarkup'

const { types: { markup: { MarkupComplexTypesIndex, MarkupBasicTypesIndex } } } = model

const EditMarkup = ({ markup, onEdit }) => (
  <ModalButton title={`Edit Markup ${markup.name}`} onOk={onEdit} wrapClassName={styles.createModal}>
    <IconButton tooltip="Edit" type="edit" />
    <CreateMarkupForm markup={markup} />
  </ModalButton>
)

export default compose(
  connect(undefined, {
    markupDefCreated: markupDefCreatedAction
  }),
  withProps(({ markup }) => ({
    markup: adaptMarkupDefinition(markup)
  })),
  graphql(markupDefinitionEditMutation, { name: 'markupDefinitionEdit' }),
  withHandlers({
    onEdit: ({ markup, markupDefinitionEdit, markupDefEdited }) => async ({ parameters, ...fields }) => {
      const { data: { markupDefinitionEdit: updated } } = await markupDefinitionEdit({ variables: { input: {
        _id: markup._id,
        // updated values
        ...transformFields(fields),
        ...parameters && { parameters: transformParameters(parameters) }
      } } })
      markupDefEdited(updated)
    }
  })
)(EditMarkup)

const isList = propEq('type', MarkupComplexTypesIndex.List.name)
export const adaptMarkupDefinition = over(lensProp('parameters'),
  map(
    when(isList, ({ typeParams, ...others }) => ({
      ...others,
      isList: true,
      // TODO: should we infer it to "Any" ? we must model the Any type :S
      type: typeParams ? typeParams[0] : MarkupBasicTypesIndex.Text.name,
    }))
  )
)

export const transformFields = ifElse(
  pipe(prop('color'), isNil),
  dissoc('color'),
  over(lensProp('color'), omit(['__typename']))
)