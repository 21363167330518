import { updateQueryValue, pushTransform } from 'graphql/updates'
import projectBranchesQuery from 'api/queries/projectBranches.graphql'

/**
 * When a branch is created it updates the apollo cache used
 * from the branches selector
 */
const projectBranchCreated = branch => (dispatch, getState, { getApolloClient }) => {
  updateQueryValue(
    {
      query: projectBranchesQuery,
      variables: { projectId: branch.project._id }
    },
    pushTransform(['projectWithId', 'branches'], 'branch')
  )(getApolloClient(), { data: { branch } })
}

export default projectBranchCreated