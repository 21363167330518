import React from 'react'
import { Select } from 'antd'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withHandlers, withPropsOnChange } from 'recompose'

import withProjectId from 'hocs/withProjectId'

import { makeProjectPreferenceSelector } from 'selectors/project'
import projectSetPreferenceMutation from 'api/mutations/projectSetPreference.graphql'

import { updateProjectPreferences as updateProjectPreferencesAction } from 'actions/project'

const { Option } = Select

const SelectProjectPreference = ({ currentValue, options, onChange }) => {
  return (
    <Select
      value={`${currentValue}`}
      onChange={onChange}
    >
      {options.map((o, i) => (
        <Option key={i} value={`${o.value}`}>{o.name}</Option>
      ))}
    </Select>
  )
}

export default compose(
  connect(() => {
    const selector = makeProjectPreferenceSelector('preference')
    return (state, props) => ({
      currentValue: selector(state, props)
    })
  }, {
    updateProjectPreferences: updateProjectPreferencesAction
  }),
  withPropsOnChange(['preference'], ({ preference }) => ({
    options: Object.values(preference.values)
  })),
  withProjectId,
  graphql(projectSetPreferenceMutation, { name: 'setPreference' }),
  withHandlers({
    onChange: ({ setPreference, preference, options, projectId, updateProjectPreferences }) => async (selectedValue, option) => {
      const index = parseInt(option.key)
      const { value } = options[index]
      const { data: { projectSetPreference: { preferences } } } = await setPreference({ variables: { input: {
        projectId,
        name: preference.name,
        value
      } } })
      updateProjectPreferences(preferences)
    }
  })
)(SelectProjectPreference)