import React from 'react'

import UserName from 'components/Commons/UserName'
import LinkButton from 'components/Commons/LinkButton'
import ModalButton from 'components/Commons/ModalButton'
import SelectForm from 'components/Commons/Forms/SelectForm'

const ChangeRoleModal = ({ onChange, container, member, roles }) => (
  <ModalButton title="Change Role" onOk={onChange}>
    <LinkButton tooltip={<Tooltip container={container} member={member} />}>Change Role</LinkButton>
    <SelectForm
      fieldName="Role"
      initialValue={member.role}
      options={Object.values(roles).map(v => ({ label: v, value: v }))}
    />
  </ModalButton>
)

const Tooltip = ({ container, member }) => `Change ${UserName({ user: member.user || member.to })} role on ${container.name}`

export default ChangeRoleModal