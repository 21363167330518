exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".QueryError__2wfeo{padding-left:1rem;padding-right:1rem;margin:auto;padding-top:1rem}.QueryError__2wfeo pre{padding:1rem;margin-top:2rem;background:#f1f1f1;border-radius:10px}", ""]);

// exports
exports.locals = {
	"QueryError": "QueryError__2wfeo"
};