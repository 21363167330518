exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".overlay__28bvS{pointer-events:none;position:absolute;width:100%;height:100%;top:0;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:end;justify-content:flex-end}", ""]);

// exports
exports.locals = {
	"overlay": "overlay__28bvS"
};