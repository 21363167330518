exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".locale__30NhY{color:#9d9a9a;padding-left:.4em}.option__1wgNh .anticon{vertical-align:-0.2rem;margin-right:.4rem}.option__1wgNh .anticon-man{color:#9595d3}.option__1wgNh .anticon-woman{color:#f0a6a6}", ""]);

// exports
exports.locals = {
	"locale": "locale__30NhY",
	"option": "option__1wgNh"
};