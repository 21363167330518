import { Tasks, updateTaskProgress } from 'actions/tasks'
import { toBNEAction } from 'engine/actions/utils'

const __executeExtensionsOnLoad = () => api => {
  if (api.extension.exec_all_autorun) {
    api.extension.exec_all_autorun()
  }
}

const _executeExtensionsOnLoad = toBNEAction(__executeExtensionsOnLoad)

/**
 * Calls a bne.extension method that calls all "on project load" extensions
 */
const executeExtensionsOnLoad = async dispatch => {
  dispatch(updateTaskProgress(Tasks.EXEC_EXT_ON_LOAD.type, 40))
  await dispatch(_executeExtensionsOnLoad())
  dispatch(updateTaskProgress(Tasks.EXEC_EXT_ON_LOAD.type, 100))
}

export default executeExtensionsOnLoad