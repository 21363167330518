import React, { useCallback, useEffect, useState } from 'react'
import ReactConsole from 'components/Commons/ReactConsole/ReactConsole'
import { useDispatch } from 'react-redux'
import { evaluateScript } from 'engine/actions/actions'
import useEngine from '../../hooks/beanie/useEngine'
import { withRef } from '../../utils/recompose'

import styles from './Console.scss'

/**
 * A BNE engine console that is used to evaluate lua code under a transaction (safely).
 * It also captures all the engine std (print calls) and shows them.
 */
const Console = ({ consoleRef }) => {
  const dispatch = useDispatch()
  const engine = useEngine()


  // this is to fix a bug in the library component
  // https://github.com/astralarya/react-console/issues/8
  // sucks ! we should get another lib or code our own console
  // there's also a hack in Console.scss to hide the prompt that appears because of this
  // acceptLine call
  const [consoleInitialized, setConsoleInitialized] = useState(false)
  useEffect(() => {
    if (!consoleInitialized) {
      consoleRef().current.acceptLine()
      setConsoleInitialized(true)
    }
  }, [consoleRef().current])

  // on mount register to the engine to get the print output
  useEffect(() => {
    if (engine && engine.isInitialized()) {
      engine.setPrinter((...args) => {
        try {
          consoleRef().current.logX('print', ...args)
        } catch (error) {
          // This shouldn't happen, but we eat the error like this 'cause
          // an error in the Printer could make the whole lua code fail and it is
          // very difficult to debug ! JS -> LUA -> JS
          // eslint-disable-next-line no-console
          console.error('ERROR TRYING TO LOG ENGINE PRINTS !', args, 'error', error)
        }
      })
      return () => {
        if (engine && engine.isInitialized()) {
          engine.setPrinter(undefined)
        }
      }
    }
  }, [engine, engine.isInitialized(), consoleRef().current])

  const evaluate = useCallback(async script => {
    const log = consoleRef().current
    try {
      if (script && script.trim()) {
        const output = await dispatch(evaluateScript(script))
        if (output !== undefined) {
          const reply = output.toJSONObject ? output.toJSONObject() : JSON.stringify(output, null, 2)
          log.logX('return_value', reply)
        }
      }
      log.return()
    } catch (err) {
      log.logX('error', parseLUAError(err.message))
      log.return()
    }
  }, [consoleRef().current])

  return (
    <div className={styles.Console}>
      <ReactConsole
        ref={consoleRef()}
        handler={evaluate}
        autofocus
        welcomeMessage="Welcome to BNE console!"
      />
    </div>
  )
}

export default withRef('consoleRef')(Console)

//

// this is highly coupled with the FengariBNEVM code (_luaDo()) !
const LUA_ERROR_REGEX = /Error trying to load BNE lua VM \(.*\): \[.*\]:(.*)/
export const parseLUAError = string => {
  const match = LUA_ERROR_REGEX.exec(string)
  return match ? match[1] : string
}