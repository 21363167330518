import React from 'react'
import { Icon } from 'antd'

import { compose } from 'recompose'
import { connect } from 'react-redux'

import { selectNode } from 'actions/nodes'

import SimpleNode from 'components/Commons/SimpleNode'
import NodePath from 'components/Commons/NodePath/NodePath'

import styles from './AssignmentsPopover.scss'

const AssignmentsPopover = ({ assignments }) => (
  <div>
    {assignments.map(action => (<AssignmentItem key={action.id} action={action} />))}
  </div>
)

const _AssignmentItem = ({ action, selectNodeAction }) => (
  <div className={styles.assignmentItem}>
    <NodePath id={action.id} className={styles.path} />
    
    <Icon type="double-right" className={styles.separator} />

    <div className={styles.node}>
      <SimpleNode node={action} onClick={() => selectNodeAction(action.id)} />
    </div>
  </div>
)

const AssignmentItem = compose(
  connect(null, {
    selectNodeAction: selectNode
  })
)(_AssignmentItem)

export default AssignmentsPopover