import { Sys } from 'beanie-engine-api-js'
import ActionDSLEditor from './ActionDSLEditor/ActionDSLEditor'
import Action2Editor from './Action2Editor/Action2Editor'
import ConditionDSLEditor from './ConditionDSLEditor/ConditionDSLEditor'
import DefaultEditor from './DefaultEditor/DefaultEditor'
import JumpEditor from './JumpEditor/JumpEditor'
import switchComponent from 'hocs/switchComponent'

export default switchComponent({
  path: ['node', 'sys'],
  cases: {
    [Sys.jump]: JumpEditor,
    [Sys.action]: ActionDSLEditor,
    [Sys.action2]: Action2Editor,
    [Sys.condition]: ConditionDSLEditor,
  },
  otherwise: DefaultEditor
})