import { Creators } from 'actions/vm'
import { beginClip } from 'tts/tts'
import { isStopped } from 'selectors/playback'
import handlersBySys from './handlersBySys'

const { startNodePlayback, setCurrentPlaybackId } = Creators

/**
 * This callback is called by BNE to start presenting a node
 * It is the frontend impl if beanie "BNEHostBeginPresentNode" callback
 */
// eslint-disable-next-line no-unused-vars
const onBeginPresentNode = (dispatch, getState) => (bneNode, playbackId, speculative) => {
  const state = getState()
  if (speculative === undefined) speculative = false

  const id = bneNode.get_id()
  const sys = bneNode.get_sys()

  // TODO: maybe we should move this to the clip specific handler !
  // fixes tts playback problems
  beginClip(playbackId)

  const stopped = isStopped(state)
  if (!stopped) {
    // then we can play more nodes, this is needed because there is a difference between
    // the time you do stop/terminate_all playback and the onBeginPresentNode callback for the next node
    dispatch(startNodePlayback(id, sys, playbackId))
    dispatch(setCurrentPlaybackId(playbackId))

    // do any sys specifics
    handlersBySys[sys]?.onBeginPresentNode?.(bneNode, playbackId, dispatch, getState)
  }
}

export default onBeginPresentNode
