
/**
 * High-order function that wraps an action (thunk) into a BNE transaction
 * This is useful for the UI to delimit a transaction without coupling the action
 * code.
 * Can be used anywhere but mostly you will use it in `connect`
 * Like:
 *   const myThunk = () => (dispatch, getState, { synchronizer }) => {
 *     const api = synchronizer.getEngineStore().getEngine().getAPI()
 *
 *     // mutate things with the 'api' object
 *     ...
 *   }
 *
 *   connect(null, {
 *     myAction: asTransaction(myThunk, 'Some transaction title')
 *   })
 *
 *   Then on the component use `myAction`
 */
const asTransaction = (thunk, description) => (...args) => (dispatch, getState, { synchronizer }) => {
  return synchronizer.doSynchingBNE(
    description,
    () => { dispatch(thunk(...args)) },
    // TODO: maybe be able to resolve "affected objects" so we put the spinner on them
  )
}

export default asTransaction