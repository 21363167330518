exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".content__1Qu_P{display:-ms-flexbox;display:flex;position:relative;-ms-flex:0 0 20vw;flex:0 0 20vw;background-color:#fff;padding-top:4rem;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;border-right:1px solid #e8e8e8}.content__1Qu_P .fullName__xyu2F{font-size:1.5rem}.content__1Qu_P .email__1t_8n{margin-bottom:1rem}.avatar__2XBGY{display:-ms-flexbox;display:flex;margin-bottom:1.5rem}.beanieVersion__1ZzHC{position:absolute;padding-left:.5rem;font-size:.8rem;color:gray;bottom:0px;left:0px}", ""]);

// exports
exports.locals = {
	"content": "content__1Qu_P",
	"fullName": "fullName__xyu2F",
	"email": "email__1t_8n",
	"avatar": "avatar__2XBGY",
	"beanieVersion": "beanieVersion__1ZzHC"
};