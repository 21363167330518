import { Icon, Modal, notification } from 'antd'
import React, { Fragment } from 'react'
import { isEmpty, difference } from 'ramda'
import { compose, withState } from 'recompose'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { withApollo } from 'react-apollo'


import ReduxDataSource from 'denormalizer/datasources/ReduxDataSource'
import { loadingError } from 'selectors/project'

import loadSelectedRevisionAction from 'actions/project/loadSelectedRevision'
import closeRevisionAction from 'actions/project/closeRevision'
import { selectRevision as selectRevisionAction, loadRevisionStart } from 'actions/project'

import DenormalizerProvider from 'providers/DenormalizerProvider'
import withSynchronizer from 'hocs/withSynchronizer'

import { selectionFromRoute } from 'hocs/selection'

import ProjectScope from 'components/scopes/ProjectScope'
import AuthorizedRoute from 'containers/Auth/AuthorizedRoute'
import Layout from 'components/Layout/Layout'
import LoadingRevisionModal from 'components/LoadingRevisionModal/LoadingRevisionModal'

import ErrorPanel from 'components/EditProjectSection/ErrorPanel'
import editProjectSectionStyles from 'components/EditProjectSection/EditProjectSection.scss'

import { allItemsWithAlternateRoutes } from 'config/menu'
import { selectedRevisionId } from '../../selectors/selections'
import LoadProjectErrorModal from '../EditProjectSection/LoadProjectErrorModal'
import RevisionSession from '../RevisionSession/RevisionSession'
/* eslint import/no-named-as-default: 0 */
import GamePadController from './GamePadController/GamePadController'
import RevisionStaticAnalyzer from 'providers/RevisionStaticAnalyzer'
import RevisionExtensions from 'providers/RevisionExtensions'
import SearchHistory from './SearchHistory'

import ShortcutsNamespaces from 'shortcuts/Namespaces'
import withShortcuts from 'hocs/withShortcuts'

import styles from './WithRevisionLayout.scss'
import { Jobs } from 'actions/tasks'
import { doneTasks as doneTasksSelector } from 'selectors/tasks'

const heightStyle = editProjectSectionStyles.editProjSectionRoute

const requiredJobFor = location => {
  const currentItem = allItemsWithAlternateRoutes.find(item => (location?.pathname?.includes(`/${item.goTo}`)))
  return currentItem?.requiredJob || Jobs.LOAD_PROJECT
}

/**
 *
 */
class WithRevisionLayout extends React.Component {

  mounted = true

  dataSource = new ReduxDataSource(::this.props.synchronizer.store.getState)

  componentDidMount() {
    const { revisionId } = this.props

    if (revisionId) {
      this.loadSelectedRevision()
    }
  }

  componentDidUpdate(prevProps) {
    const { revisionId, doneTasks, location } = this.props

    const revisionIdChanged = revisionId !== prevProps.revisionId && revisionId
    if (revisionIdChanged) {
      // selected revision changed lets load
      this.loadSelectedRevision()
      return
    }

    const { location: prevLocation } = prevProps

    if (location !== prevLocation) {
      const locationTasks = requiredJobFor(location).tasks
      const requiredTasks = difference(locationTasks.map(({ type }) => type), doneTasks)
      if (!isEmpty(requiredTasks)) {
        this.loadSelectedRevision(this.props.loadRevisionStart)
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
    this.props.closeRevision()
  }

  loadSelectedRevision = action => {
    const { revisionId, selectRevision, loadSelectedRevision, client, setLoadingRevision } = this.props

    selectRevision(revisionId)

    setLoadingRevision(true)

    const fn = action || loadSelectedRevision

    fn(client)
      .then(this.endLoadingRevision)
      .catch(this.endLoadingRevision)
  }

  endLoadingRevision = () => {
    if (this.mounted) {
      const { setLoadingRevision } = this.props
      setLoadingRevision(false)
    }
  }

  onRevisionConnectionFailed = err => {
    Modal.error({
      title: 'Oh no!',
      centered: true,
      content: <LoadProjectErrorModal error={err} />,
      className: styles.LoadProjectErrorModal,
      width: '40vw'
    })
  }
  onRevisionCommunicationError = err => {
    notification.open({
      message: 'Communication Error',
      description: `${JSON.stringify(err)}`,
      icon: <Icon type="exclamation-circle" style={{ color: 'red' }} />,
    })
  }

  render() {
    const { computedMatch, match, location, revisionId, synchronizer, loadingRevision, error } = this.props
    const realMatch = computedMatch || match

    const renderWithProps = Component => props => (
      <DenormalizerProvider project={revisionId} synchronizer={synchronizer} datasource={this.dataSource} className={heightStyle}>
        {error && <ErrorPanel error={error.message} errorInfo={{ componentStack: error.stack }} />}
        {!error &&
          <Fragment>
            <LoadingRevisionModal />
            <Component {...props} loading={loadingRevision} />
          </Fragment>
        }
      </DenormalizerProvider>
    )

    return (
      <ProjectScope>
        <RevisionSession
          location={location}
          onConnectionFailed={::this.onRevisionConnectionFailed}
          onComunicationError={::this.onRevisionCommunicationError}
        >
          <RevisionExtensions className={styles.RevisionExtensions}>
            <Layout>
              <SearchHistory revisionId={revisionId} />
              <GamePadController />
              <RevisionStaticAnalyzer className={styles.RevisionStaticAnalyzer}>
                <Switch>
                  {allItemsWithAlternateRoutes.map(item => {
                    const C = item.requires ? AuthorizedRoute : Route
                    return (
                      <C
                        key={item.key}
                        path={`${realMatch.path}/${item.goTo}/`}
                        render={renderWithProps(item.component)}
                        requiresProject
                        {...item.requires && { requires: item.requires }}
                      />
                    )
                  })}
                </Switch>
              </RevisionStaticAnalyzer>
            </Layout>
          </RevisionExtensions>
        </RevisionSession>
      </ProjectScope>
    )
  }

}

export default compose(
  selectionFromRoute({ name: 'revisionId', detectUpdates: true }),
  connect(state => ({
    revisionId: selectedRevisionId(state),
    error: loadingError(state),
    doneTasks: doneTasksSelector(state)
  }),
  {
    selectRevision: selectRevisionAction,
    loadSelectedRevision: loadSelectedRevisionAction,
    closeRevision: closeRevisionAction,
  }),
  connect(null, (dispatch, ownProps) => ({
    loadRevisionStart: async () => dispatch(loadRevisionStart(ownProps.revisionId))
  })),
  withState('loadingRevision', 'setLoadingRevision', false),
  withRouter,
  withSynchronizer,
  withApollo,
  withShortcuts({ namespace: ShortcutsNamespaces.Project.Global, targetNodeSelector: 'body' }),
)(WithRevisionLayout)
