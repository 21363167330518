import { textNode as textToTextNode } from '../slateMocks/textNode'

/**
 * Slate extensions to mutate clip lines
 */
const ClipLineCommands = () => ({

  commands: {

    //
    // Note's commands
    //

    convertNoteToPlainText: (editor, noteNode) => {
      editor.replaceNodeWith(noteNode, textToTextNode(noteNode.getText()))
    },

    deleteNote: (editor, noteNode) => {
      editor.removeNode(noteNode)
    },

  }

})

export default ClipLineCommands