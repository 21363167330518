
import React from 'react'
import { Tooltip } from 'antd'

import InboundSynchIndicator from './InboundSynchIndicator'
import OutboundSynchIndicator from './OutboundSynchIndicator'

import styles from './SynchIndicator.scss'

const SynchIndicator = () => (
  <Tooltip title="Sending/Receiving changes from/to server indicator." >
    <div className={styles.SynchIndicator}>
      <InboundSynchIndicator />
      <OutboundSynchIndicator />
    </div>
  </Tooltip>
)
export default SynchIndicator
