import { NormalizationError } from './constants'
import { emptyDirectorLine, customDirectorLine } from '../../slateMocks/directorLine'
import { EMPTY_ARRAY } from 'utils/object'

export const clipNormalize = (editor, { code, child }) => {
  if (code === NormalizationError.CHILD_MIN_INVALID) {
    editor.insertNodeByPath(EMPTY_ARRAY, 0, emptyDirectorLine)
    editor.moveToEndOfNode(editor.nodeByPath([]))
  }

  //  pasted text in between (for example in an empty dir line)
  if (code === NormalizationError.CHILD_TYPE_INVALID && child.type === 'paragraph') {
    editor.replaceNodeWith(child, customDirectorLine(child.nodes))
  }
}