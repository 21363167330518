import { createSelector as prodCreateSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { createSelectorWithDependencies as debugCreateSelector } from 'reselect-tools'
import { createArraySelector as originalCreateArraySelector, createObjectSelector as originalCreateObjectSelector } from 'reselect-map'
import { equals, curry, pipe, nthArg } from 'ramda'
import { measure as _measure } from 'utils/timing'
import { mapLast } from 'utils/list'
import { PROFILE_SELECTORS } from 'model/features'

const measure = curry(_measure)

const _createSelector = PROFILE_SELECTORS ? debugCreateSelector : prodCreateSelector

const selectorCounters = {}
const nameForSelector = name => {
  const counter = (selectorCounters[name] || 0) + 1
  selectorCounters[name] = counter
  return counter > 1 ? `${name} (${counter - 1})` : name
}

const callCreateSelector = (create, name, args) =>
  create(...(PROFILE_SELECTORS ? mapLast(measure(nameForSelector(name)))(args) : args))

// wraps a createSelector instance to accept a first arg being a string, and then setting that as selectorName
const createSelectorDecorator = creator => (...args) => {
  if (typeof args[0] === 'string') {
    const [name, ...rest] = args

    const selector = callCreateSelector(creator, name, rest)
    selector.selectorName = name

    return selector
  } else {
    return creator(...args)
  }
}

export const createSelector = createSelectorDecorator(_createSelector)

export const createDeepEqualSelector = createSelectorDecorator(createSelectorCreator(
  defaultMemoize,
  equals
))

export const createArraySelector = createSelectorDecorator(originalCreateArraySelector)
export const createObjectSelector = createSelectorDecorator(originalCreateObjectSelector)


export const propSelector = (...steps) => pipe(nthArg(1), ...steps)

