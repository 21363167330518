
/**
 * Type value for Query objects
 */
const QueryType = {
  AND: 'and',
  OR: 'OR',
  NOT: 'not',

  // this is kind of too specific, we can refactor it in the future
  PROP_EQ: 'prop_eq',
  PATH_EQ: 'path_eq',
  ID: 'id',
  IS_BNE_NODE: 'is_bne_node',

  LINE_HAS_MARKUP: 'line_has_markup',
}

export default QueryType