import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Helmet } from 'react-helmet'

import { currentProject } from 'selectors/project'
import revisionForScope from 'selectors/security/scopes/revisionForScope'

import SecureScope from 'components/Commons/SecureScope'

const ProjectScope = ({ project, revision, children }) => {
  const scope = useMemo(() => ({ project, revision }), [project, revision])

  return (
    <SecureScope scope={scope}>
      { project && <Helmet title={`${project.name} | ${revision.version} | Beanie Studio`} /> }
      {children}
    </SecureScope>
  )
}

export default compose(
  connect(state => ({
    project: currentProject(state),
    revision: revisionForScope(state)
  }))
)(ProjectScope)