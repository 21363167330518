import { Sys } from 'beanie-engine-api-js'
import { BOTTOM, CENTER, LEFT, RIGHT, TOP } from '../../../utils/dnd/position'
import { isOverRoot, allCanBeRoot, appendContainerContent, replaceRoot, allValidContainerContentsOfTargetNode, isOverContainer, allValidContainerContents, isOverContained, isOverRegular, insertInParent, insertAbove, insertBelow, buildCases, isOverOtherwiseRow, allAreRegularNodes, insertAsChild, allAre, insertAboveOtherwiseRow, isOverIdleOfChoices, setIdle } from './connectors/OperationRulesUtils'
import { _ } from './OperationInferenceRule'


/**
 * The rules definitions to compute which operation can be done.
 * Order matters. It is evaluated top-down to find the first one that matches.
 *
 * Rules have this form:
 *  - 0: relationship condition: in which target node relationship is being over
 *  - 1: expected vertical position (or _ for ANY),
 *  - 2: expected horizontal position (or _ for ANY),
 *  - 3: selection condition: a constraining on the selections what's being dragged)
 *  - 4: [computed/output] the Operation that will be done
 *
 *  Note output also includes a hover position to be able to transform the visual feedback
 *  based on the operation.
 *  For example, when dragging a regular node into a choice there's only a single valid operation
 *  which is to insert as a child, and this operation has the "RIGHT" visual decoration even if the mouse
 *  is on the TOP-LEFT, for example.
 *
 *  TODO: all this rules and the "engine" could be avoided / replaced by a generic impl
 *  based on the relationships and the schema.
 *  There are some missing pieces there, like parametric types (@see note in oneToMany.js)
 *
 */
/* eslint no-multi-spaces: 0 */
const OperationsRule = buildCases([

  // *************************************************
  // ** special cases (at the top to have priority)
  // *************************************************

  //
  // TRUTH TABLES
  //

  [isOverOtherwiseRow, _, _, allAreRegularNodes, insertAsChild],
  [isOverOtherwiseRow, _, _, allAre(Sys.truth_table_row), insertAboveOtherwiseRow],

  //
  // choices2
  //

  [isOverIdleOfChoices, _, _, _, setIdle],


  // *************************************************
  // ** more general cases
  // *************************************************

  //
  // ROOTS
  //

  // selection is root-able
  [isOverRoot,        _,      LEFT,     allCanBeRoot,           replaceRoot],
  // TODO: write following 2 like
  // [isOverRoot, [TOP|BOTTOM],    CENTER, allCanBeRoot, insertAbove],
  [isOverRoot,       TOP,    CENTER,    allCanBeRoot,           insertAbove],
  [isOverRoot,     BOTTOM,   CENTER,    allCanBeRoot,           insertBelow],
  [isOverRoot,     CENTER,   CENTER,    allCanBeRoot,           insertAsChild],

  [isOverRoot,       _,        _,   allAreRegularNodes,         insertAsChild],

  //
  // REGULAR NODES
  //

  // TODO: write following 2 like
  // [isOverRegular, _, [RIGHT, CENTER], allAreNotContained, insertAsChild],
  [isOverRegular,    _,      RIGHT,    allAreRegularNodes,      insertAsChild],
  [isOverRegular,    _,      CENTER,   allAreRegularNodes,      insertAsChild],
  [isOverRegular,    _,      LEFT,     allAreRegularNodes,      insertInParent],

  //
  // CONTAINED
  //

  [isOverContained,  _,        _,      allAreRegularNodes,            insertAsChild],
  [isOverContained,  TOP,      _,      allValidContainerContents,     insertAbove],
  [isOverContained, BOTTOM,    _,      allValidContainerContents,     insertBelow],

  // CONTAINERS

  [isOverContainer,  _,        _,      allValidContainerContentsOfTargetNode, appendContainerContent]

])

export default OperationsRule