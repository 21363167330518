import { EMPTY_ARRAY } from 'utils/object'
import { NODE_TYPES } from '../utils/Constants'
import searchMatchesDecorations from './decorations/searchHighlight/searchMatchesDecorations'

const isLine = node => (
  node.type === NODE_TYPES.DIRECTOR_LINE
  || node.type === NODE_TYPES.DIALOGUE_LINE
)

// Note: we cannot use props from the first args (recompose)
// because Slate memoizes the inner function capturing the original scope !
const decorateNode = () => (node, editor, next) => {
  const { props: { searchMatch } } = editor

  const others = next() || EMPTY_ARRAY

  return searchMatch && isLine(node) ?
    others.concat(searchMatchesDecorations(node, editor, searchMatch))
    : others
}

export default decorateNode

