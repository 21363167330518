import { sum, median, mean, prop, last, equals, pipe } from 'ramda'
import { shallowEqual } from 'recompose'
import { WHY_DID_SELECTOR_RECOMPUTE } from 'model/features'

export default class FunctionStats {

  constructor(name) {
    this.name = name
    this.times = []
  }

  record(elapsed, args) {
    if (WHY_DID_SELECTOR_RECOMPUTE) {
      const lastArgs = pipe(last, prop('args'))(this.times)
      if (lastArgs) {
        const info = args.map((p, i) => {
          if (p === lastArgs[i]) return 'SAME'
          if (shallowEqual(lastArgs[i], p)) return 'SHALLOW'
          return equals(lastArgs[i], p) ? 'EQUALS!' : 'DIFF'
        })
        if (!info.some(equals('DIFF'))) {
          console.log(`⚠️⚠️⚠️ [${this.name}] recomputed with shallowEquals args!: `, ...info)
        }
      }
    }
    this.times.push({ t: elapsed, args })
  }
  nrOfRuns() { return this.times.length }
  minTime() { 
    return this.times.reduce((acc, call) => (call.t < acc ? call.t : acc), Infinity)
  }
  maxTime() {
    return this.times.reduce((acc, call) => (call.t > acc ? call.t : acc), 0.0)
  }
  medianTime() { return median(this.times.map(prop('t'))) }
  meanTime() { return mean(this.times.map(prop('t'))) }

  totalAccumulatedTime() { return sum(this.times.map(prop('t'))) }

  clone() {
    const c = new FunctionStats(this.name)
    c.times = [...this.times]
    return c
  }

  logCalls() {
    /* eslint no-console: 0 */
    console.table(this.times)
  }

}