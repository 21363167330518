import { routerMiddleware } from 'connected-react-router'
import { identity } from 'ramda'
import { unstable_batchedUpdates } from 'react-dom'
import { applyMiddleware, compose } from 'redux'
import { batchedSubscribe } from 'redux-batched-subscribe'
import profileStore from 'redux-profiler'
import thunkMiddleware from 'redux-thunk'
import createServices from '../application/services/createServices'
import loggerMiddleware from '../middlewares/logger'
import profileSelectorsMiddleware from '../middlewares/profileSelectors'
import selectionMiddleware from '../middlewares/selection'
import {
  PROFILE_SELECTORS_ON_EACH_DISPATCH,
  PROFILE_STORE,
  STORE_BATCHED_UPDATES,
  STORE_LOG_ACTIONS, STORE_REDUX_DEV_TOOLS_TRIM_BNEOBJECTS
} from '../model/features'
import { EMPTY_ARRAY } from '../utils/object'
import persistState from './enhancers/persistState/persistState'
import reduxDevToolsEnhancer from './enhancers/reduxDevTools'

/**
 *
 */
const createEnhancer = (history, getApolloClient, synchronizer, enhancer, getEditorModule, getStudioAPI) => compose(
  PROFILE_STORE ? profileStore() : identity,
  applyMiddleware(
    ...PROFILE_SELECTORS_ON_EACH_DISPATCH ? [profileSelectorsMiddleware] : EMPTY_ARRAY,
    ...STORE_LOG_ACTIONS ? [loggerMiddleware] : EMPTY_ARRAY,
    thunkMiddleware.withExtraArgument(createThunksExtraArgument(synchronizer, getApolloClient, getEditorModule, getStudioAPI)),
    routerMiddleware(history),
    selectionMiddleware(getApolloClient),
  ),
  STORE_BATCHED_UPDATES ? batchedSubscribe(unstable_batchedUpdates) : identity,
  localStorage && process.env.NODE_ENV !== 'test' ? persistState(['appMeta', 'view', 'presets']) : identity,
  // redux dev tools
  reduxDevToolsEnhancer(STORE_REDUX_DEV_TOOLS_TRIM_BNEOBJECTS),
  enhancer || identity,
)

/**
 * Creates the object later passed as 3rd param (context) to all thunks.
 * Kinds of the application context.
 */
const createThunksExtraArgument = (synchronizer, getApolloClient, getEditorModule, getStudioAPI) => ({
  // @deprecated: remove them by first updating all access to use "services"
  synchronizer,
  getApolloClient,
  getEditorModule,
  getStudioAPI,

  // the new way !
  services: createServices(getStudioAPI, getEditorModule, synchronizer),
})

export default createEnhancer