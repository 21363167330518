exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".OrganizationSettings__cKsxM{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;padding:1rem}.OrganizationSettings__cKsxM .ant-alert{margin-top:1rem}", ""]);

// exports
exports.locals = {
	"OrganizationSettings": "OrganizationSettings__cKsxM"
};