import { path } from 'ramda'
import { compose, withPropsOnChange } from 'recompose'
import { model } from 'beanie-engine-api-js'
import { BaseCurrentChoicesPlaybackContainer, CurrentChoicesPlaybackComp } from '../choices/CurrentChoicesPlayback'

const { types: { object: { Paths } } } = model

/**
 * Choices2 is the new way.
 * Difference is that it has the time out value within it as a field.
 * This is the component shown when the user must select an option.
 */
export default compose(
  BaseCurrentChoicesPlaybackContainer,
  withPropsOnChange(
    ['playbackCurrentObject'],
    ({ playbackCurrentObject }) => ({
      initialTime: path(Paths.choices2.timeout, playbackCurrentObject),
    })
  )
)(CurrentChoicesPlaybackComp)
