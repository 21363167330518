import { path, pipe } from 'ramda'
import { isArray } from './object';

export const withTargetValue = _do => pipe(path(['target', 'value']), _do)
export const withTargetChecked = _do => pipe(path(['target', 'checked']), _do)

// Custom validators for Form fields

export const arrayLengthMin = (min, label = 'elements') => (rule, value, callback) => {
  callback(isArray(value) && value.length >= min ? undefined : `Must have at least ${min} ${label}`)
}

export const ZIndex = {
  DropDown: 1050,
}

export const Themes = {
  light: 'light',
  default: 'light',
  dark: 'dark'
}
