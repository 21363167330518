import { head, assocPath } from 'ramda'
import { toBNEAction } from './utils'
import { Creators } from 'actions/ui'

import { ref } from 'beanie-engine-api-js'


const { startBlockingTask, endBlockingTask } = Creators

const cutPropertyPath = _path => (head(_path) === 'data' ? _path.slice(1) : _path)

export const editProperty = toBNEAction(
  (node, propertyPath, value) => api => {
    api.update(node.id, assocPath(
      cutPropertyPath(propertyPath),
      value,
      {}
    ))
  }, 'Edit Node property'
)

export const deleteProperty = toBNEAction(
  (node, propertyPath) => api => {
    api.update(node.id, assocPath(
      cutPropertyPath(propertyPath),
      undefined,
      {}
    ))
  }, 'Delete Node property'
)

export const editRule = toBNEAction(
  (node, ruleObject) => api => {
    api.obj.get(node.id).set_enabled_rule(ruleObject)
  }, 'Edit Enabled Rule'
)

export const deleteRule = toBNEAction(
  node => api => {
    api.update(node.id, { enabled_rule: undefined })
  }, 'Delete Enabled Rule'
)

export const purgeInaccessible = toBNEAction(
  () => api => api.obj.purge_inaccessible(),
  'Purge project',
  {
    before: dispatch => dispatch(startBlockingTask({ description: 'Cleaning Project' })),
    after: dispatch => dispatch(endBlockingTask()),
    onError: dispatch => dispatch(endBlockingTask()),
  }
)

export const _disableObjects = (msg = 'Disable objects') => toBNEAction(objIds => api => {
  objIds.forEach(id => api.obj.get(id)?.disable())
}, msg)
export const _enableObjects = (msg = 'Disable objects') => toBNEAction(objIds => api => {
  objIds.forEach(id => api.obj.get(id)?.enable())
}, msg)

export const disableObjects = _disableObjects()
export const enableObjects = _enableObjects()

export const _setProperties = (targetId, properties, path) => api => {
  api.update(ref(targetId), assocPath(
    cutPropertyPath(path),
    properties,
    {}
  ))
}

export const setProperties = toBNEAction(
  _setProperties, 'Set object properties'
)
