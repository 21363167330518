import React from 'react'
import PropTypes from 'prop-types'
import LocalStore from '../sync/reactive-stores/LocalStore'

class StateSynchronizerProvider extends React.Component {
  /* eslint react/forbid-prop-types:0 */
  static propTypes = {
    synchronizer: PropTypes.object.isRequired,
  }
  static childContextTypes = {
    synchronizer: PropTypes.object.isRequired,
  }

  getChildContext() {
    const { synchronizer, store } = this.props
    // HACK !
    if (!synchronizer.localStore) synchronizer.setLocalStore(new LocalStore(store))
    return { synchronizer }
  }

  render() {
    return this.props.children
  }
}

export default StateSynchronizerProvider
