exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__TzAhH{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.container__TzAhH>.anticon{margin-right:.5rem;color:#a5a1a1}.container__TzAhH .ant-switch-checked{background-color:#c1d3ae}", ""]);

// exports
exports.locals = {
	"container": "container__TzAhH"
};