import { OBJECT_TYPES } from '../Constants'

//
// Matchers
//

const fixed = text => ({ object: OBJECT_TYPES.text, text: new RegExp(`^${text}$`) })
const textByRegex = re => ({ object: OBJECT_TYPES.text, text: re })

// composed / estructured
export const Matchers = {

  Text: {
    fixed,
    byRegEx: textByRegex,
    id: textByRegex(/^([a-zA-Z]|_)*$/),
  }

}

//
// Common Rules
//

export const SPACE = {
  match: { object: OBJECT_TYPES.text, text: /\s*/ },
  min: 0,
  max: 1,
}
export const one = {
  min: 1,
  max: 1,
}

export const delimiter = text => ({
  match: fixed(text),
  ...one
})