import React from 'react'

import VideoPlayerChoicesControl from '../VideoPlayerChoicesControl/VideoPlayerChoicesControl'
import VideoPlayerTimeline from '../VideoPlayerTimeline/VideoPlayerTimeline'

import { DEBUG } from '../../config'

import styles from './VideoPlayerControlsOverlay.scss'

const VideoPlayerControlsOverlay = () => (
  <div className={styles.overlay}>
    {DEBUG && <VideoPlayerTimeline /> }

    <VideoPlayerChoicesControl />
  </div>
)

export default VideoPlayerControlsOverlay