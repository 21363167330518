import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose, withStateHandlers, withHandlers } from 'recompose'

import { Modal } from 'antd'

import moveLines from 'engine/actions/actor/moveLines'
import ActorSelector from './ActorSelector'


const MoveLinesModal = ({ handleMove, onFinish, visible, actor, lines, setTargetActorId, targetActorId, confirmLoading }) => {
  if (!visible) return null

  const numberOfLines = lines[actor.id]

  return (
    <Modal
      title={`Move lines from actor ${actor.data.actorName}`}
      visible={visible}
      confirmLoading={confirmLoading}
      onOk={handleMove}
      onCancel={onFinish}
      okType="danger"
      okButtonProps={{ disabled: !targetActorId }}
      okText="Confirm"
      destroyOnClose
    >
      {visible &&
        <Fragment>
          <h4>
            { `${actor.data.actorName} has ${numberOfLines} line${numberOfLines > 1 ? 's' : ''}` }
          </h4>
          <h4> { `Confirm you want to move ${actor.data.actorName}'s lines to:` } </h4>
          <ActorSelector
            skipActor={actor.id}
            setActorId={setTargetActorId}
          />
        </Fragment>
      }
    </Modal>
  )
}

export default compose(
  connect(null, {
    moveLinesAction: moveLines
  }),
  withStateHandlers(
    { targetActorId: null, confirmLoading: false },
    {
      setTargetActorId: prev => targetActorId => ({ ...prev, targetActorId }),
      setLoading: prev => confirmLoading => ({ ...prev, confirmLoading })
    }
  ),
  withHandlers({
    handleMove: ({ setLoading, onFinish, moveLinesAction, actor, targetActorId }) => async () => {
      setLoading(true)
      await moveLinesAction(actor.id, targetActorId)
      await onFinish()
      setLoading(false)
    }
  })
)(MoveLinesModal)