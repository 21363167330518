import { lang } from 'beanie-engine-api-js'

import { nodeSelection } from 'selectors/selections'
import { clipboard } from 'selectors/clipboard'
import { playbackStatus } from 'selectors/playback'
import { otherSessionsFromUs, otherUserSessions, ourSession, sessionsList, ourUnrealSession } from 'selectors/sessions/sessions'

import ttsJobCreateMutation from 'api/mutations/ttsJobCreate.graphql'
import ttsJobCreateSingleFileMutation from 'api/mutations/ttsJobCreateSingleFile.graphql'
import { Creators } from 'actions/vm'
import { Creators as UICreators } from 'actions/ui'
import { select, selectAsMultiple, selectMultiple, clear, addToSelection, removeFromSelection } from 'actions/selection'
import { Creators as WalkListCreators } from 'actions/walklist'
import searchMatchingText, { globalSearchQuery } from 'actions/nodes/search/searchMatchingText'

import { input } from 'utils/graphql'

import save from '../actions/nodes/search/history/save'
import performSearch from '../actions/nodes/search/performSearch'
import revisionSessionSendRequest from '../actions/sessions/revisionSessionSendRequest'
import { selectedNodes } from '../selectors/nodeSelection'
import { EMPTY_OBJECT } from '../utils/object'

const { startPlayback, pausePlayback, resumePlayback, stopPlayback, rewindPlayback } = Creators
const { rule: { parser } } = lang

export const EDITOR_MODULE_NAME = 'editor'

const proxyToJS = value => value?.toJSONObject?.() || value

/**
 * Instantiates the "Editor" object which is a facade to the frontend as a single API to
 *  - access its state
 *  - perform actions both in terms of UI but also actions that communicate with the backend
 *
 * This object is exposed to lua through `bne.editor`
 */
export const editorModule = (dispatch, getState, synchronizer, getClient, extensionsContainer) => {

  const editor = {

    selection: {
      /**
       * Get the list of ids in the current selection.
       */
      getSelectedNodes: () => selectedNodes(getState()),

      getSelection: () => getState().selection,
      getNodeSelection: () => nodeSelection(getState()),
      select: (name, value) => dispatch(select(name)(proxyToJS(value))),
      selectAsMultiple: (name, value) => dispatch(selectAsMultiple(name)(proxyToJS(value))),
      selectMultiple: (name, value) => dispatch(selectMultiple(name)(proxyToJS(value))),
      clearSelection: name => dispatch(clear(name)),
      addToSelection: (name, value) => dispatch(addToSelection(name)(proxyToJS(value))),
      removeFromSelection: (name, value) => dispatch(removeFromSelection(name)(proxyToJS(value))),
    },

    clipboard: {
      getClipboard: () => clipboard(getState()),
    },

    /**
     * Utilities for I/O like managing files, sockets, etc.
     */
    io: {

      /**
       * Saves the given text content as a file to the user's machine as a "download"
       * WARNING: this will become a performance bottleneck in terms of memory and CPU if you attempt
       * to use it with a large file.
       * TODO: we need an auxiliary API for larger files. Where one could create a Writer/Stream and
       *   write there content partially (like pushing content)
       */
      saveAsTextFile: (fileName, content, contentType = 'text/plain') => {
        const blob = new Blob([content], { type: `${contentType};charset=utf-8` })
        dispatch(UICreators.saveFile(fileName, blob))
      },

    },

    /**
     * API for searching and walking search results
     */
    search: {

      /** given a search and its results it starts walking it */
      walk: (search, result) => dispatch(WalkListCreators.walkList(search, result)),

      /** Starts walking a list of ids */
      walkNodes: (title, ids) => dispatch(WalkListCreators.walkNodes(title, ids.toJSONObject())),

      /** Given a text it does a full search and triggers both walking it and saving it to the history */
      walkByText: text => {
        const { search, result } = editor.search.createGlobalSearch(text)
        editor.search.history.save(search)
        editor.search.walk(search, result)
        return result
      },

      /** Creates a Search object. Id doesn't perform the actual search ! This is low-level You can later use it to save, walk, etc. */
      createGlobalSearch: text => dispatch(searchMatchingText(text, { filterOption: globalSearchQuery })),

      /** Executes the given search */
      perform: search => dispatch(performSearch(search)),

      /** Access to the search history which keeps a record of the last seen searches */
      history: {

        /** manually adds the given search to the history as performed right now (or pass the second arg) */
        save: (search, timestamp) => dispatch(save(search, timestamp))

      },

    },

    playback: {
      getPlayBackStatus: () => playbackStatus(getState()),
      play: object => dispatch(startPlayback({ id: object.get_id() })),
      pause: () => dispatch(pausePlayback()),
      resume: () => dispatch(resumePlayback()),
      stop: () => dispatch(stopPlayback()),
      rewind: () => dispatch(rewindPlayback()),
    },

    tts: {
      syncRevision: revisionId => getClient().mutate({
        mutation: ttsJobCreateMutation,
        ...input({ revisionId, autoStart: true })
      }),
      syncLine: (revisionId, lineId) => getClient().mutate({
        mutation: ttsJobCreateSingleFileMutation,
        ...input({ lineId, revisionId })
      }),
    },

    session: {
      getCurrent: () => ourSession(getState()),
      getOurs: () => otherSessionsFromUs(getState()),
      getOthers: () => otherUserSessions(getState()),
      getAll: () => sessionsList(getState()),

      sendToUnreal: (command, args) => {
        const unrealSession = ourUnrealSession(getState())

        if (unrealSession) {
          return dispatch(revisionSessionSendRequest({
            to: unrealSession._id,
            command,
            params: proxyToJS(args),
          }))
        }
      },
      sendToSession: (sessionId, command, args) => {
        return dispatch(revisionSessionSendRequest({
          to: sessionId,
          command,
          params: proxyToJS(args),
        }))
      }
    },

    extensions: {

      /**
       * Registers an extension to the Studio Frontend application through its Extension-Point API.
       * obj has the form:
       *   { point: String, data: Any }
       *
       * Where point must be one of the pre-defined Extension Points. And the `data` depends on each extension point
       * definition/contract.
       *
       * Returns a unique ID (string) to track this extension and be able to unregister it later
       */
      registerExtension: obj => {
        // TODO: there is a missing architecture feature here from beanie-engine-api-js
        //   ideally `obj` will be a JS proxy making completely transparent using it from JS
        //   but using the LUA object under the hood.
        //   But currently they don't support navigating nested structure like this `obj.data.method`
        //   specifically `obj.data` is not supported. Since it only supports calling methods (first level) like this
        //   `obj.method()`. But if we use `toJSONObject` of course the result only has data members but no functions
        //   So here this piece of code combines both solutions `toJSONObject` to convert it into a JS data-like and `getMember`
        //   to be able to get a JS-LUA function.
        //   The design-side-effect is that we are coupled here with the idea that all extensions have `data.action`
        //   which might not be the case for some extension-points if we are really generic.
        //   So this gets solved by doing more work on the JS-LUA proxy (we are not far)
        const json = obj.toJSONObject()
        const { action, enabledOn } = obj.getMember('data')
        return extensionsContainer.registerExtension({
          point: json.point,
          data: {
            ...json.data,
            // function parameters need special treatment, they cannot be part of JSON
            action,
            ...enabledOn ? { enabledOn } : EMPTY_OBJECT
          }
        })
      },

      /**
       * Given the ID returned by `registerExtension` this allows to unregister it from the UI
       */
      unregisterExtension: uuid => extensionsContainer.unregisterExtension(uuid),

      /** Returns information about the current points */
      getExtensions: () => extensionsContainer.getExtensions(),
      /** Return the ids of all registered extensions */
      getExtensionIds: () => extensionsContainer.getExtensionIds()

    },

    /**
     * Utilities related to the beanie languages development and API
     * For example it provides access to the "Beanie Lang" parser.
     */
    lang: {

      /**
       * Receives beanie lang code in a string and parses it.
       * Returns the parsed AST which might be an error.
       */
      parse: string => parser(string)

    }

  }
  return editor
}