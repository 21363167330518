import React from 'react'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { omit } from 'ramda'

import ModalButton from 'components/Commons/ModalButton'
import IconButton from 'components/Commons/IconButton'

import CreateCustomTypeForm from './CreateCustomTypeForm'

import markupCustomTypeEditMutation from 'api/mutations/markupCustomTypeEdit.graphql'

import { markupCustomTypeEdited as markupCustomTypeEditedAction } from 'actions/project'

const EditMarkupCustomType = ({ customType, onEdit }) => (
  <ModalButton title={`Edit Type ${customType.name}`} onOk={onEdit}>
    <IconButton tooltip="Edit" type="edit" />
    <CreateCustomTypeForm customType={customType} />
  </ModalButton>
)

export default compose(
  connect(null, {
    markupCustomTypeEdited: markupCustomTypeEditedAction
  }),
  graphql(markupCustomTypeEditMutation, { name: 'markupCustomTypeEdit' }),
  withHandlers({
    onEdit: ({ customType, markupCustomTypeEdit, markupCustomTypeEdited }) => async ({ name, description, values }) => {
      const { data: { markupCustomTypeEdit: updated } } = await markupCustomTypeEdit({ variables: { input: {
        _id: customType._id,
        name,
        description,
        values: values.map(omit(['__typename']))
      } } })
      markupCustomTypeEdited(updated)
    },
  })
)(EditMarkupCustomType)