import React from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { withStateFromProp } from 'utils/recompose'
import { connect } from 'react-redux'
import { Sys } from 'beanie-engine-api-js'
import { equals } from 'ramda'

import { selectedLaneRootIds } from 'selectors/lanes'

import NavigableTextView from './NavigableTextView'
import { Icon } from 'model/label'

import styles from '../TextViewItem.scss'

/**
 *
 */
const MarkerTextView = ({ selectedMarker: { data: { name } }, hasPrevious, hasNext, moveSelectedRoot }) => (
  <NavigableTextView
    className={styles.marker}
    hasNext={hasNext}
    hasPrevious={hasPrevious}
    moveLeft={() => moveSelectedRoot(-1)}
    moveRight={() => moveSelectedRoot(+1)}
  >
    <h1><Icon sys={Sys.marker} />{name}</h1>
  </NavigableTextView>
)

export default compose(
  withStateFromProp('selectedMarker', 'setSelectedMarker', 'object'),
  connect((state, props) => ({
    roots: selectedLaneRootIds(state, props)
  })),
  withPropsOnChange(
    ['roots', 'selectedMarker'],
    ({ roots, selectedMarker }) => ({
      hasPrevious: selectedMarker && !selectedMarker.parent && roots.findIndex(equals(selectedMarker.id)) > 0,
      hasNext: selectedMarker && !selectedMarker.parent && roots.findIndex(equals(selectedMarker.id)) < roots.length - 1
    })
  )
)(MarkerTextView)
