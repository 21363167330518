import { NODE_TYPES } from '../../../../Constants'
import { delimiter, SPACE } from '../../../schema-utils'

export default {
  nodes: [
    SPACE,
    {
      match: { type: NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT },
      min: 1,
    },
    {
      match: [delimiter(','), { type: NODE_TYPES.MARK_UP_PARAMETER_VALUE_LIST_ELEMENT }],
    },
  ]
}