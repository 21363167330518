import React from 'react'

import { compose, withHandlers, withState } from 'recompose'
import { connect } from 'react-redux'
import { concat } from 'ramda'
import { schema, Sys } from 'beanie-engine-api-js'
import { staticVariables } from 'selectors/state'

import AbstractDSLEditor, { createCompletion } from '../DSLEditor/AbstractDSLEditor'

import Bullet from '../DSLEditor/contentAssist/Bullet'

import styles from '../DSLEditor/AbstractDSLEditor.scss'

import { MODE } from '../DSLEditor/CodeMirror/condition-mode.js'

const ConditionEditor = ({ proposeCompletions, onApply, value, setValue, node, onCancel, others }) => (
  <AbstractDSLEditor
    mode={MODE}
    node={node}
    proposeCompletions={proposeCompletions}

    value={value}
    setValue={setValue}
    onBlur={onApply}
    onApply={onApply}
    onCancel={onCancel}

    autoFocus

    {...others}
  />)

export default compose(
  withState('value', 'setValue', ({ text }) => text || ''),
  connect(() => state => ({
    variableNames: staticVariables(state)
  }), {}),
  withHandlers({
    onApply: ({ onApply, value }) => () => onApply(value),
    proposeCompletions: ({ variableNames }) => () => {
      return concat(
        schema.enumValuesOf(Sys.condition, 'data.mode')
          .map(createCompletion({
            className: styles.keyword,
            description: 'operand'
          })),
        variableNames
          .map(createCompletion({
            className: styles.variable,
            icon: <Bullet styleName={styles.bulletVariable} />,
            description: 'variable'
          }))
      )
    }
  })
)(ConditionEditor)
