exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".firstButton__19a_m{margin-top:2rem}.playbackButton__2t9Ve{-ms-flex-item-align:center;align-self:center}.playbackButton__2t9Ve:not(.volume__IqP7W){font-size:1.5rem}.playbackButton__2t9Ve:not(.firstButton__19a_m){margin-top:1rem}.volume__IqP7W{font-size:1.2rem}", ""]);

// exports
exports.locals = {
	"firstButton": "firstButton__19a_m",
	"playbackButton": "playbackButton__2t9Ve",
	"volume": "volume__IqP7W"
};