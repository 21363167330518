exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".counter__3SZmr{padding-right:1.2rem}.counter__3SZmr .anticon{margin-right:.5rem}.counter__3SZmr .value__3ikQ7{font-size:.9rem}", ""]);

// exports
exports.locals = {
	"counter": "counter__3SZmr",
	"value": "value__3ikQ7"
};