import { connect } from 'react-redux'
import { compose, withProps } from 'recompose'
import { Sys } from 'beanie-engine-api-js'

import { createIsNodeUnderSyncOutboundOperation } from 'selectors/sync'

import { denormalizing } from 'hocs/denormalizing'
import secure from 'hocs/secure'
import { isTextEditable } from 'security/project'
import withErrorHandler from 'hocs/withErrorHandler'

import {
  setStoryboard,
  setAudio,
  moveStoryboard,
  removeStoryboard,
  disableLines as disableLinesAction,
  enableLines as enableLinesAction
} from 'engine/actions/lines'

import { commitChanges, performChecks, modelToModel } from 'engine/actions/texteditor'
import { onPasteAction } from './utils/plugins/CopyPastePlugin'

import ObjectTextEditor from './ObjectTextEditor'
import ObjectTextEditorWithError from 'components/SlateTextEditor/ObjectTextEditorWithError'

import Preferences from 'preferences/Preferences'
import { preference } from 'selectors/view'

const editableSyses = [Sys.clip, Sys.choices]
export const isEditable = sys => editableSyses.includes(sys)

export default compose(
  connect(() => {
    const isNodeUnderSyncOperation = createIsNodeUnderSyncOutboundOperation()
    const visibleMarkupsSelector = preference(Preferences.TextEditor.visibleMarkups)
    const visibleNotesSelector = preference(Preferences.TextEditor.visibleNotes)
    return (state, props) => ({
      isUnderSyncOperation: isNodeUnderSyncOperation(state, props),
      visibleMarkups: visibleMarkupsSelector(state),
      visibleNotes: visibleNotesSelector(state)
    })
  }, {
    // saving
    commitChangesAction: commitChanges,
    modelToModelAction: modelToModel,
    performChecksAction: performChecks,

    // audio + storyboards
    setLineAudio: setAudio,
    setLineStoryboard: setStoryboard,
    moveLineStoryboard: moveStoryboard,
    removeLineStoryboard: removeStoryboard,
    disableLines: disableLinesAction,
    enableLines: enableLinesAction,
    onPasteAction,
    // other actions
  }),
  denormalizing({
    prop: 'object',
    recurse: true,
  }),
  secure('hasWriteAccess', isTextEditable),
  withProps(({ object, hasWriteAccess = false, isUnderSyncOperation = false }) => ({
    readOnly: ((object && !isEditable(object.sys)) || !hasWriteAccess) || isUnderSyncOperation
  })),
  withErrorHandler(ObjectTextEditorWithError, true),
)(ObjectTextEditor)