import { sessionId } from '../../../selectors/project'

export const ON_UPDATE_USER_CURSOR_EVENT = 'ON_UPDATE_USER_CURSOR_EVENT'

const onUpdateUserCursorEvent = event => (dispatch, getState) => {
  const ourSessionId = sessionId(getState())

  // TODO: test
  if (event.session._id !== ourSessionId) {
    dispatch({
      type: ON_UPDATE_USER_CURSOR_EVENT,
      event,
    })
  }
}

export default onUpdateUserCursorEvent