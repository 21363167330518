import React from 'react'
import { pathEq, none } from 'ramda'
import { compose, withProps } from 'recompose'
import { graphql } from 'react-apollo'
import { Button } from 'antd'

import { ProjectGrantToType, ProjectOwnerType } from 'utils/authentication'

import organizationTeamsQuery from 'api/queries/organizationTeams.graphql'

import ModalButton from 'components/Commons/ModalButton'
import GrantTeamForm from './GrantTeamForm'

const _TeamGrantsMenu = ({ grantAccess, project, organization, teamsData, isOrgProject }) => (isOrgProject ? (
  <ModalButton
    title="Add Team"
    onOk={async ({ Team, Role }) => grantAccess(project, Team, ProjectGrantToType.Team, Role)}
  >
    <Button type="primary">Add Team</Button>
    <GrantTeamForm
      organization={organization}
      filter={entity => none(pathEq(['to', '_id'], entity._id), project.grants)}
      teamsData={teamsData}
    />
  </ModalButton>
) : null)

export default compose(
  withProps(({ project }) => ({
    isOrgProject: project && project.owner.__typename === ProjectOwnerType.Organization,
    organization: project && project.owner.__typename === ProjectOwnerType.Organization && project.owner
  })),
  graphql(organizationTeamsQuery, {
    name: 'teamsData',
    skip: ({ isOrgProject }) => !isOrgProject,
    options: ({ organization: { name: organizationName } }) => ({ variables: { organizationName } })
  })
)(_TeamGrantsMenu)