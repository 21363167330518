import { mergeDeepRight, path } from 'ramda'

import { dropTrailingSlash as dts } from 'utils/string'
import createPresenter from 'engine/host/playback/createPresenter'
import { token as tokenSelector } from 'selectors/auth'
import { Creators as VMCreators } from 'actions/vm'
import fetchWithToken from 'engine/host/io/fetchWithToken'
import sentry from 'services/sentry'
import fetchCacheWrapper from '../../../../engine/host/io/fetchCacheWrapper'
import { CACHE_ENGINE_FILES } from '../../../../model/features'
import disposeVM from '../../disposeVM'
import { EDITOR_MODULE_NAME } from 'api/Editor'

const { vmLoading, vmLoaded } = VMCreators

// trying to guarantee there's ALWAYS a *single* trailing slash here
const beanieBasePath = `${dts(path(['location', 'origin'], window))}/`

/**
 *
 */
export const restartVM = async (revisionId, services, dispatch, getState, synchronizer, moreOptions = {}, getEditorModule) => {
  dispatch(disposeVM())
  dispatch(vmLoading(revisionId))

  const { engineStore } = synchronizer

  const fetcher = fetchWithToken(() => tokenSelector(getState()))

  const newOptions = {
    journaling: true,
    disablePackages: false,
    beanieBasePath,
    fetchResource: CACHE_ENGINE_FILES ? fetchCacheWrapper(fetcher, services.localDB) : fetcher,
    presenter: createPresenter(dispatch, getState, engineStore.getEngine()),

    transactionErrorHandler: (message, data) => {
      sentry.handleError(message, data)
    },
    uiModules: [{ name: EDITOR_MODULE_NAME, moduleFactory: getEditorModule }]
  }

  const options = mergeDeepRight(newOptions, moreOptions)
  await engineStore.getEngine().init(options)

  dispatch(vmLoaded(revisionId))
}

export default restartVM
