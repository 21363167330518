exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".DropdownMenu__2ySMN{min-width:10rem}.dropdownIcon__1W3_S{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;font-size:smaller;position:absolute;width:.85rem;height:.8rem;border-radius:30%;color:gray;background:#d8cfc1}.dropdownIcon__1W3_S>.anticon{justify-self:center;padding:0 !important}.rowIcon__HfYpk{top:5px}.columnIcon__2O7zY{left:80%;top:5px}", ""]);

// exports
exports.locals = {
	"DropdownMenu": "DropdownMenu__2ySMN",
	"dropdownIcon": "dropdownIcon__1W3_S",
	"rowIcon": "rowIcon__HfYpk",
	"columnIcon": "columnIcon__2O7zY"
};