import React from 'react'

import { isCurrentRevisionWritable } from 'security/project'
import useSecureCheck from 'hooks/security/useSecureCheck'
import useObjectSelection from 'hooks/beanie/useObjectSelection'

import bneCustomValueProvider from '../../BneObjectPropertiesEditor/bneCustomValueProvider'
import { nonEditableBneObjectFields } from '../../BneObjectPropertiesEditor/BneObjectPropertiesEditor'
import UserPropertiesEditor from '../../PropertiesEditor/UserPropertiesEditor'

/**
 * User friendly panel to edit the currently selected object.
 */
const PropertiesPanel = props => {
  const editable = useSecureCheck(isCurrentRevisionWritable)
  const [object, selectObject, onPropertyChanged] = useObjectSelection()

  return (
    <UserPropertiesEditor
      object={object}
      editable={editable}
      onPropertyChanged={onPropertyChanged}
      onNavigateRef={selectObject}
      nonEditableFields={nonEditableBneObjectFields}
      customValueProvider={bneCustomValueProvider}
      {...props}
    />
  )
}

export default PropertiesPanel