import React, { useCallback, useMemo, useState } from 'react'
import { compose, withPropsOnChange } from 'recompose'
import { propEq, pipe, find, T, } from 'ramda'
import { Menu, Icon, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { allItems } from 'config/menu'
import { push } from 'connected-react-router'
import { EMPTY_ARRAY } from 'utils/object'

import { revisionId as revisionIdSelector } from 'selectors/project'
import { isCurrentlyAt } from '../../config/menu'
import { getCurrentProjectSection } from '../../config/menu-utils'
import useSecurityScope from '../../hooks/security/useSecurityScope'

import styles from './ProjectLeftMenu.scss'

const items = (allItems.map((e, i) => ({ ...e, key: `${i}` })))

/**
 * Main left menu when working on a project
 */
const ProjectLeftMenu = ({ currentlySelected }) => {

  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed, setCollapsed])

  const scope = useSecurityScope()

  const revisionId = useSelector(revisionIdSelector)
  const dispatch = useDispatch()

  const onItemSelected = useCallback(({ key }) => {
    const item = items.find(propEq('key', key))
    dispatch(push(`/p/${revisionId}/${item.goTo}/`))
  }, [revisionId])

  const isEnabled = useMemo(() => !!revisionId, [revisionId])

  return (
    <div className={styles.menuContainer}>
      <Button type="primary" onClick={toggleCollapsed} style={{ marginBottom: 16 }}>
        <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
      </Button>
      <Menu
        mode="inline"
        selectedKeys={currentlySelected}
        inlineCollapsed={collapsed}
        onSelect={onItemSelected}
        theme="dark"
      >
        {items
          .filter(({ requires = T }) => requires(scope))
          .map(item => MenuSectionItem(item, isEnabled))
        }
      </Menu>
    </div>
  )
}

const MenuSectionItem = (item, isEnabled) => (
  <Menu.Item key={item.key} disabled={!isEnabled}>
    <Icon type={item.icon} />
    <span>{item.label}</span>
  </Menu.Item>
)

export default compose(
  withRouter,
  withPropsOnChange(
    ['location'],
    ({ location }) => {
      const currentSection = getCurrentProjectSection(location)
      return ({
        currentlySelected: currentSection ? pipe(
          find(isCurrentlyAt(currentSection)),
          i => (i.key ? [i.key] : EMPTY_ARRAY)
        )(items) : undefined
      })
    }
  ),
)(ProjectLeftMenu)
