import FactsComputingCache from './FactsComputingCache'

/**
 * Generic function to process a list of facts with a mapper function that returns a value (Fact) => V
 * This does the grouping to return a list of entries like:
 * [
 *   { value: 'value1', facts: [f1, f2, f3] },
 *   { value: 'value2', facts: [f4] },
 *   { value: 'value3', facts: [f5, f6] }
 * ]
 *
 * For example
 *    groupFactsByMapper(f => getName(f)[0], 'startingLetter', 'DEFAULT')
 * Gives:
 *  [
 *     { startingLetter: 'a', facts: [{ data: { name: 'ascending' }, { data: { name: 'alreadyThere' }}}] },
 *     { startingLetter: 'b', facts: [{ data: { name: 'beyond' }, { data: { name: 'boldlyGo' }}}] },
 *  ]
 *
 * This allows us to have different criteria to group/visualize facts.
 * Handles caching results in case you need to evaluate facts dependencies
 */
const groupFactsByMapper = (mapper, valueName, defaultValue) => ({ facts: factsList, objectsIndex: index, indexBySys, factsByName: factsIndexByName, lanes, state }) => {
  const cache = new FactsComputingCache(mapper, defaultValue, factsIndexByName, state, index, indexBySys, lanes)

  const indexedFactsByMappedValue = factsList.reduce((acc, fact) => {
    const value = cache.computeFact(fact)
    if (!acc[value]) { acc[value] = [] }
    acc[value].push(fact)
    return acc
  }, {})

  // make it an array
  return Object.entries(indexedFactsByMappedValue)
    .map(([value, f]) => ({ [valueName]: value, facts: f }))
}

export default groupFactsByMapper