import React from 'react'
import { compose, withHandlers } from 'recompose'

import UpdatableImage from '../../../Commons/UpdatableImage'
import { noop } from 'utils/functions'
import { urlForStoryboard } from 'model/resources'

import styles from '../../SlateTextEditor.scss'

const StoryboardPreview = props => (
  <div className={styles.StoryboardPreview}>
    <UpdatableImage
      image={props.storyboard}
      imageAlt={storyboard => `Storyboard ${storyboard}`}
      urlResolver={urlForStoryboard}
      {...props}
    />
  </div>
)

export default compose(
  withHandlers({
    onBeginDrag: ({ lineId, storyboard }) => () => ({ lineId, storyboard }),
    onRemove: ({ lineId, removeLineStoryboard = noop }) => event => {
      event.stopPropagation()
      removeLineStoryboard(lineId)
    }
  })
)(StoryboardPreview)
