import memoize from 'memoize-state'
import { objectById } from 'selectors/objects'
import { path, prepend, hasPath, find, map, equals, has } from 'ramda'
import { lang, model } from 'beanie-engine-api-js'
import { resolveId } from './objects'
import { EMPTY_STRING } from 'utils/string'
import { EMPTY_ARRAY } from 'utils/object'
import { createContextSource, makeContextFromSource } from 'model/languague/rules/context'
import { EMPTY_RULE_WITH_PARSE_ERROR } from 'engine/actions/nodes'

const { rule: { interpreter: { evalRule, evalBooleanRule }, error: { customParseError, isError } } } = lang

const { types: { object: { Paths } } } = model

export const makeInheritedRuleSelector = (truthTableId, rowIndex, columnIndex) => memoize(state => {
  const ttNode = objectById(truthTableId)(state)
  const rowRefs = path(Paths.container.container_content, ttNode)
  const rulePath = ['data', 'cells', columnIndex, 'rule']
  // Returns the parent rows in bottom-up order
  const rows = rowRefs.reduce(
    (acc, id, index) => (index < rowIndex ? prepend(resolveId(id)(state), acc) : acc),
    EMPTY_ARRAY
  )

  const rowWithInheritedCell = find(row => hasPath(rulePath, row) && !isError(path(rulePath, row)), rows)

  return path(['data', 'cells', columnIndex], rowWithInheritedCell) || EMPTY_RULE_WITH_PARSE_ERROR
})

export const makeColumnSelector = (truthTableId, columnIndex) => memoize(state => {
  const ttNode = objectById(truthTableId)(state)
  const rowRefs = path(Paths.container.container_content, ttNode)
  const allRows = map(id => resolveId(id)(state), rowRefs)
  return {
    header: path([...Paths.truth_table.headers, columnIndex])(ttNode),
    cells: map(row => path([...Paths.truth_table_row.cells, columnIndex])(row), allRows)
  }
})

export const makeRowCellValueSelector = (truthTableId, rowIndex, columnIndex) => {
  const columnValueSelector = makeColumnValueSelector(truthTableId, columnIndex)
  const inheritedRuleSelector = makeInheritedRuleSelector(truthTableId, rowIndex, columnIndex)

  const mSelector = memoize((state, { cell: { source, rule }, source: cotextSource }) => {
    const ruleToEval = equals(EMPTY_STRING, source) ? inheritedRuleSelector(state).rule : rule

    /* eslint-disable space-infix-ops, no-unused-expressions, no-undef */

    // Access to avoid compute root path objects
    cotextSource.index.___fix_memoize___
    cotextSource.indexBySys.___fix_memoize___
    cotextSource.factIndex.___fix_memoize___

    /* eslint-enable space-infix-ops, no-unused-expressions, no-undef */

    return evalBooleanRule(makeContextFromSource(cotextSource), ruleToEval)
  })

  const selector = (state, props) => {
    const sourceSelector = createContextSource(columnValueSelector)
    return mSelector(state, {
      cell: props.cell,
      source: sourceSelector(state)
    })
  }

  selector.memoizingSelector = mSelector
  return selector
}

export const makeColumnValueSelector = (truthTableId, columnIndex) => {
  const columnSelector = makeColumnSelector(truthTableId, columnIndex)
  const mSelector = memoize(({ column: { header }, source }) => {
    /* eslint-disable space-infix-ops, no-unused-expressions, no-undef */

    // Access to avoid compute root path objects
    source.index.___fix_memoize___
    source.indexBySys.___fix_memoize___
    source.factIndex.___fix_memoize___

    /* eslint-enable space-infix-ops, no-unused-expressions, no-undef */

    return (
      has('rule', header) ?
        evalRule(makeContextFromSource(source))(header.rule) :
        customParseError('There is no value for header')
    )
  })

  const selector = state => {
    const sourceSelector = createContextSource(() => undefined)
    return mSelector({
      column: columnSelector(state),
      source: sourceSelector(state)
    })
  }

  selector.memoizingSelector = mSelector
  return selector
}