exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".container__3w5JF{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:5vw;-ms-flex-align:center;align-items:center;height:90%;padding-left:.3em}.container__3w5JF .ant-avatar{margin-top:.3em}.container__3w5JF .ant-avatar .ant-avatar-string{cursor:default}", ""]);

// exports
exports.locals = {
	"container": "container__3w5JF"
};