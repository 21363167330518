import React from 'react'
import { path } from 'ramda'
import { Table, Spin, Alert } from 'antd'
import { EMPTY_ARRAY } from 'utils/object'

import styles from './SectionElementsTable.scss'

const { Column } = Table

const SectionElementsTable = ({ handleTableChange, className, data, dataPath, pageSize, onPageChanged, children, actions, rowKeyPath = '_id', ...others }) => (
  <div className={className}>
    <Spin spinning={data.loading}>
      {data.error &&
        <Alert
          message="Error"
          description="Oops, there was an error fetching the data !"
          type="error"
          showIcon
        >{JSON.stringify(data.error, null, 2)}</Alert>
      }
      {!data.error &&
        <Table
          size="small"
          rowKey={path(rowKeyPath.split('.'))}
          dataSource={data.loading ? EMPTY_ARRAY : path(dataPath, data).list}
          pagination={({
            pageSize,
            onChange: onPageChanged,
            total: data.loading ? 0 : path(dataPath, data).paging.nrOfItems
          })}
          onChange={handleTableChange}
          {...others}
        >
          {children}
          {actions &&
            <Column
              title="Actions"
              key="actions"
              className={styles.actionsColumn}
              render={element => actions(element)}
            />
          }
        </Table>
      }
    </Spin>
  </div>
)

export default SectionElementsTable