exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(undefined);
// imports


// module
exports.push([module.id, ".unit__24t7I{padding-left:.5rem}", ""]);

// exports
exports.locals = {
	"unit": "unit__24t7I"
};