import { graphql } from 'react-apollo'
import { compose, withProps } from 'recompose'

import teamQuery from 'api/queries/team.graphql'

import TeamRouter from './TeamRouter'

export default compose(
  graphql(teamQuery, {
    name: 'team',
    options: ({ match: { params: { organizationName, teamName } } }) => ({
      variables: {
        organizationName,
        teamName
      },
    }),
  }),
  withProps(({ team }) => ({
    loading: team.loading,
    team: team.team,
    error: team.error
  }))
)(TeamRouter)