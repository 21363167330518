import { isMoveOrExpandToRight, isMoveOrExpandToLeft } from 'utils/keyboard'
import { path, pipe, keys, assocPath, dissocPath } from 'ramda'
import { isCrossTheWord, handler } from './MoveCursorPlugin'
import { IS_VISIBLE_BY_TYPE } from './core/nodePlugin'
import { cloneNode } from '../slateMocks/clone'

export const setCreatingState = assocPath(['data', 'creating'], true)
export const setCreatedState = dissocPath(['data', 'creating'])

const firstPossibleInvisibleNode = (editor, node) => editor.parentOfAnyType(keys(IS_VISIBLE_BY_TYPE), node)

const firstPossibleInvisibleNodeInDirection = (editor, direction) => {
  const textNode = editor.getTextOnFocus(direction.delta)
  return firstPossibleInvisibleNode(editor, textNode)
}

export const shouldChangeNodeState = (editor, previousFocusInline, node) => {
  if (!(previousFocusInline && editor.existsNode(previousFocusInline.key))) return false

  const invisibleFromPrevious = firstPossibleInvisibleNode(editor, previousFocusInline)
  const invisibleFromNode = firstPossibleInvisibleNode(editor, node)
  
  return !!(invisibleFromPrevious && previousFocusInline.key !== node.key && invisibleFromPrevious !== invisibleFromNode)
}

// plugin
export default () => ({

  onChange: (editor, next) => {
    if (editor && editor.currentPath()) {
      const previousFocusInline = path(['props', 'value', 'focusInline'], editor)
  
      if (previousFocusInline !== undefined && shouldChangeNodeState(editor, previousFocusInline, editor.currentNode())) {
        const node = firstPossibleInvisibleNode(editor, previousFocusInline)
        if (node && node.data && node.data.get('creating')) {
          editor.replaceBlockByKey(node.key, pipe(cloneNode, setCreatedState)(node))
        }
      }
    }

    next()
  },

  onKeyDown (event, editor, next) {
    if (isMoveOrExpandToRight(event)) {
      if (!handleMovement(Direction.RIGHT, event, editor)) {
        return false
      }
    } else if (isMoveOrExpandToLeft(event)) {
      if (!handleMovement(Direction.LEFT, event, editor)) {
        return false
      }
    }
    return next()
  },
})

// util/impl functions

const handleMovement = (direction, event, editor) => {
  const node = firstPossibleInvisibleNodeInDirection(editor, direction)

  if (node && !editor.isVisible(node)) {
    event.preventDefault()
    event.stopPropagation()
    const expandOrMove = editor.selectionIsExpanded() ? direction.expand : direction.move
    expandOrMove(editor, node)
    if (isCrossTheWord(event)) { direction.moveOne(editor, event) }
    return false
  }
  return true
}

const Direction = {
  RIGHT: {
    delta: +1,
    expand: (editor, node) => { expandSelectionToEndOfNode(editor, node) },
    move: (editor, node) => { moveCursorToEndOfNode(editor, node) },
    moveOne: (editor, event) => { handler(event).moveOneWordToRight(editor, event) }
  },
  LEFT: {
    delta: -1,
    expand: (editor, node) => { expandSelectionToStartOfNode(editor, node) },
    move: (editor, node) => { moveCursorToStartOfNode(editor, node) },
    moveOne: (editor, event) => { handler(event).moveOneWordToLeft(editor) }
  }
}

const moveCursorToEndOfNode = (editor, node) => {
  editor.moveToEndOfNode(node)
  editor.moveForward()
}

const moveCursorToStartOfNode = (editor, node) => {
  editor.moveToStartOfNode(node)
  editor.moveBackward()
}

const expandSelectionToEndOfNode = (editor, node) => {
  editor.moveFocusToEndOfNode(node)
  editor.moveFocusForward()
}

const expandSelectionToStartOfNode = (editor, node) => {
  editor.moveFocusToStartOfNode(node)
  editor.moveFocusBackward()
}
