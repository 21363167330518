import { sort, pipe } from 'ramda'
import './highlight.css'

export const Styles = {
  operand: 'operand',
  stateKey: 'stateKey',
  value: 'value',
}

export const startState = () => ({ operandProcessed: false })

export const createMode = operands => () => ({
  token: tokenizer(operands),
  startState
})

/* eslint no-useless-escape: 0 */
const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
export const createRegEx = operands => {
  const operandOptions = sort((a, b) => b.length - a.length, operands)
    .map(escapeRegExp)
    .join('|')
  return new RegExp(` (${operandOptions})($| )`)
}

export const tokenize = REGEX => (stream, state) => {
  // operand
  const matched = stream.match(REGEX)
  if (matched) {
    state.operandProcessed = true
    return Styles.operand
  }

  // key
  if (!state.operandProcessed) {
    stream.next()
    return Styles.stateKey
  }
  
  // value
  if (stream.match(/(true|false)/)) {
    return `${Styles.value} boolean`
  }
  stream.skipToEnd()
  return Styles.value
}

const tokenizer = pipe(createRegEx, tokenize)
