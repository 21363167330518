import { applyTo } from 'ramda'

// core
import traversingEditorPlugin from './core/traversingEditorPlugin'

//
import CreateNewBlockPlugin from './CreateNewBlockPlugin'
import SwitchBlockType from './SwitchBlockType'
import BackSpacePlugin from './BackSpacePlugin'
import SoftBreakPlugin from './SoftBreakPlugin'
import InsertNextDialoguePlugin from './InsertNextDialoguePlugin'
import PerformanceNotesPlugin from './PerformanceNotesPlugin'
import ProductionNotesPlugin from './ProductionNotesPlugin'
import ActorNameSuggestionPlugin from './ActorNameSuggestionPlugin'

import MarkUpCreatorPlugin from './MarkUpCreatorPlugin/plugin'
import CopyPastePlugin from './CopyPastePlugin'
import MarkUpNameSuggestionPlugin from './MarkUpNameSuggestionPlugin'
import MarkUpKeySuggestionPlugin from './MarkUpKeySuggestionPlugin'
import MarkUpValueSuggestionPlugin from './MarkUpValueSuggestionPlugin'
import HiddenNodesPlugin from './HiddenNodesPlugin'

import MarkupPlugin from './Markup/Markup'

import slateRetroCompatPlugin from './core/slateRetroCompatPlugin'
import historyPlugin from './core/historyPlugin'
import nodePlugin from './core/nodePlugin'
import bindingPlugin from './core/bindingPlugin'
import selectionPlugin from './core/selectionPlugin'
import enableDisablePlugin from './core/enableDisablePlugin'
import markPlugin from './core/markPlugin'

import ClipQueriesPlugin from './ClipQueries'
import ClipCommandsPlugin from './ClipCommands'
import ClipLineCommandsPlugin from './ClipLineCommands'
import MoveCursorPlugin from './MoveCursorPlugin'

import { isCurrentRevisionWritable } from 'security/project'

// CAREFUL, order matters !
export default (scope, bypassChecks = false) => [

  // core
  slateRetroCompatPlugin,
  historyPlugin,
  nodePlugin,
  bindingPlugin,
  selectionPlugin,
  enableDisablePlugin,
  markPlugin,

  ClipQueriesPlugin,
  ClipCommandsPlugin,
  ClipLineCommandsPlugin,

  // markups
  { plugin: MarkupPlugin, check: isCurrentRevisionWritable },
  // this 4 plugins must be composed within the "markupPlugin"
  { plugin: MarkUpNameSuggestionPlugin, check: isCurrentRevisionWritable },
  { plugin: MarkUpKeySuggestionPlugin, check: isCurrentRevisionWritable },
  { plugin: MarkUpValueSuggestionPlugin, check: isCurrentRevisionWritable },

  HiddenNodesPlugin,
  { plugin: MarkUpCreatorPlugin, check: isCurrentRevisionWritable },

  // notes
  { plugin: PerformanceNotesPlugin, check: isCurrentRevisionWritable },
  { plugin: ProductionNotesPlugin, check: isCurrentRevisionWritable },

  // clip dialogue
  { plugin: ActorNameSuggestionPlugin, check: isCurrentRevisionWritable },
  traversingEditorPlugin,
  InsertNextDialoguePlugin,
  SoftBreakPlugin,
  BackSpacePlugin,
  CreateNewBlockPlugin,
  SwitchBlockType,
  CopyPastePlugin,
  MoveCursorPlugin,
].filter(p => (bypassChecks || (!p.check || p.check(scope))))
  .map(p => p.plugin || p)
  .map(applyTo(null))

export const diffEditorPlugins = () => [
  nodePlugin,
  bindingPlugin,
  selectionPlugin,
  ClipQueriesPlugin,
  ClipCommandsPlugin,
  HiddenNodesPlugin,
  CopyPastePlugin,
].map(applyTo(null))