import { pipe, map, reject, isNil, pathOr } from 'ramda'
import { getLaneOfNode } from '../../selectors/paths'
import { EMPTY_ARRAY } from '../../utils/object'
import { toBNEAction } from './utils'
import { model, parseRef, ref } from 'beanie-engine-api-js'
import { IntermediateModelTypes } from 'components/TextEditor/choicesEditorModel'

const isNewChoice = line => !line.choice

const { types: { object: { Paths, getName } }, factory: { objects: { fact } } } = model

/**
 * Given a beanie choices object and a list of items coming from
 * editing it under a text editor (which is part of the IntermediateModel)
 * it updates the choices in the engine with the new model
 */
export const editChoicesImpl = (choices, items) => api => { // exported just for tests
  const choicesLua = api.obj.get(choices.id)

  const choicesIds = pipe(
    map(item => {
      switch (item.type) {
        case IntermediateModelTypes.title: return processTitle(item, choicesLua, api);
        case IntermediateModelTypes.choice: return processChoice(item, choicesLua, api);
        default: return null
      }
    }),
    reject(isNil)
  )(items)

  // now update the array of choices
  api.update(choices.id, { container_contents: choicesIds.map(ref) })
}

const processTitle = (item, choicesLua) => {
  choicesLua.set_name(item.text)
}

const processChoice = (choice, choicesLua, api) => {
  if (isNewChoice(choice)) {
    const newChoice = choicesLua.add_choice()
    newChoice.set_text(choice.text, { trim: false })
    return newChoice.get_id()
  } else {
    const choiceLua = api.obj.get(choice.choice)
    choiceLua.set_text(choice.text, { trim: false })
    return choiceLua.get_id()
  }
}

export const editChoices = toBNEAction(editChoicesImpl, 'Edit Choices')

/**
 * Given a lane prefix it returns its numbers separated by _
 * For example `1 - 2 -` -> `1_2`
 */
const extractNumbersFromPrefix = prefix => prefix.match(/(\d+)/g).join('_')

/** iex "1 - 2 - Start" -> "1_2" */
export const extractLanePrefix = lane => {
  const numericPrefix = (lane || '').match(/^(\d+[\W\s\d_]*)/)
  return numericPrefix ? extractNumbersFromPrefix(numericPrefix[0]) : undefined
}

const escapeChoicesName = s => s.replace(/\s/g, '_').replace(/-/g, '_')

/**
 * Given a choices it returns a name proposal for a new fact for this choices.
 * It is composed of the lane numeric prefix (if there is such) and the choices name (escaped)
 * iex: Lane = "1 - 2 - Start" + choices = "make a choice"
 *   Gives "1_2_make_a_choice"
 */
const generateFactNameFromChoices = (choices, state) => {
  const lane = extractLanePrefix(getLaneOfNode(choices.id)(state))
  return `${lane ? `${lane}_` : ''}${escapeChoicesName(getName(choices))}`
}
/**
 * Returns a new fact object derived from the given choices.
 * Notice that this is a pure function. It doesn't really create the object into the engine
 * it just returns the JSON representation you can use later to set to the engine.
 */
export const factFromChoices = choices => (dispatch, getState) => fact(
  // name
  generateFactNameFromChoices(choices, getState()),
  // source
  pathOr(EMPTY_ARRAY, Paths.container.container_content, choices)
    .map(choice => `when executed "${parseRef(choice)}" then ""`)
    .join('\n')
)(undefined) // no id