import React, { Fragment } from 'react'

import ProjectInformation from './Options/ProjectInformation/ProjectInformation'
import ProjectDangerZone from '../ProjectDangerZone/ProjectDangerZone'
import Preferences from './Options/Preferences/Preferences'

const OptionsSection = ({ project, projectData }) => (
  <Fragment>
    <ProjectInformation projectData={projectData} />
    <Preferences project={project} />
    <ProjectDangerZone project={project} projectData={projectData} />
  </Fragment>
)

export default OptionsSection
