import { flip, forEach, pipe, when } from 'ramda'
import { isNotNil } from 'ramda-adjunct'
import { batch } from 'react-redux'

export const dispatchAll = flip(forEach)

export const pipeThunk = fn => (...args) => (dispatch, getState) => pipe(
  getState,
  ...fn(...args),
  when(isNotNil, dispatch)
)()

/*
 * wraps an action function to be batched with react-redux batch()
 * Util to use in mapDispatchToProps
 */
export const batched = action => (...args) => batch(() => action(...args))
